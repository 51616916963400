import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ModalHeader from '../../../shared-component/formmodal-component/ModalHeader';


const SideDrawerWrapper = ({
    open,
    title,
    onClose,
    children,
    sideTitle,
    onBackArrowClick,
    sideButtonClickHandler,
}) => {
 
  return (
    <div>
          <SwipeableDrawer
            open={open}
            onClose={onClose}
            onOpen={open}
            anchor='right'
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                padding: '0px !important',
                width: 'calc(100% - 300px)'
              },
            }}
          >
            <ModalHeader 
               title={title}
               onCancel={onClose}
               isSideDrawer={true}
               onBackArrowClick={onBackArrowClick}
               sideTitle={sideTitle}
               sideButtonClickHandler={sideButtonClickHandler}
            />
            {children}
          </SwipeableDrawer>
    </div>
  );
}

export default SideDrawerWrapper
