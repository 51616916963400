import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import SessionModal from './components/SessionModal';
import TidyTreeExample from './components/TidyTreeExample';
import { apiService } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';
import useExit from '../../../../utils/useExit';

const rows = [
    { id:1, device_id: '#4545554', sessions:4, childrens: [
      {name:'Session #1'},
      {name:'Session #2'},
    ] },
    { id:2, device_id: '#6654454', sessions:4, childrens: [
      {name:'Session #1'},
      {name:'Session #2'},
    ]  },
    { id:3, device_id: '#8987887', sessions:4, childrens: [
      {name:'Session #1'},
      {name:'Session #2'},
    ]  },
]

const Sessions = () =>{
    const [sessionValues, setSessionValues] = useState(null);
    const [openSessionModal, setOpenSessionModal] = useState(false);
    const [sessionRows, setSessionRows] = useState([])
    const [loadSessions, setLoadSessions] = useState(false)
    const { exitFromPlatform } = useExit()

    const handleSessionsModal = () =>{
        setOpenSessionModal(!openSessionModal)
    }
//lead_device
//session_count
    const columns = [
        { field: 'lead_device', headerName: 'Device ID',minWidth: 250, renderCell: (params) => {
          return `#${params?.row?.lead_device.substring(params?.row?.lead_device.length, params?.row?.lead_device.length - 6).toUpperCase()}`
        }},
        { field: 'session_count', headerName: 'Session Count',minWidth: 250},
        { field: 'session_duration', headerName: 'Total Session Duration',minWidth: 250, renderCell: (params) =>{
          return params?.row?.session_duration ? `${params?.row?.session_duration}m` :  "-"
        }},
    ]

    const handleChange = (event, newValue) => {
        setSessionValues({
            ...event?.row,
            title: event?.row?.lead_device
        });
        handleSessionsModal()
      };

      const graphData = {
        nodes: [
          { id: 'Node 1' },
          { id: 'Node 2' },
          { id: 'Node 3' },
          { id: 'Node 4' },
          { id: 'Node 5' }
        ],
        links: [
          { source: 'Node 1', target: 'Node 2' },
          { source: 'Node 1', target: 'Node 3' },
          { source: 'Node 2', target: 'Node 4' },
          { source: 'Node 2', target: 'Node 5' }
        ]
      };

      const getSessions = () =>{
        setLoadSessions(true)
        apiService.get(`analytic/user-session/`).then((res) =>{
          const newSessions = res?.data?.map((e, i) =>{
            return {
              ...e,
              id: i + 1
            }
          })
          setSessionRows(newSessions)
          setLoadSessions(false)
        }).catch((error) =>{
          if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setLoadSessions(false)
              handleFilter()
          }
          }
        })
      }

    useEffect(() =>{
      getSessions()
    }, [])
    
    return (
      <>
        <div className=''>
            {loadSessions ?
             <div className='h-96 w-full flex items-center justify-center'>
                <CircularProgress size={20} />
             </div>
            :
            <DataGridTable 
             rows={sessionRows}
             columns={columns}
             handleEvent={handleChange}
             />}
             {/* <div style={{ width: '800px', height: '600px' }}>
           <TidyTreeExample data={graphData} widthPercent={100} heightPercent={100} />
        </div> */}
        </div>
        {openSessionModal && sessionValues !== null && (
            <SessionModal
              title={sessionValues?.title}
              open={openSessionModal}
              handleClose={handleSessionsModal}
              data={sessionValues}
            />
        )}
        
        
      </>

    )
}

export default Sessions

