import { Checkbox } from '@mui/material'

import React, { useState } from 'react'
import RegularCheckbox from '../../../shared-component/OverviewPage/overview-used-components/RegularCheckbox'

function TempDesign2({
  data=[],
  val,
  popupPosition,
  popupClose,
  isTemplateScreen,
  onCheckSubmit = () =>{},
  selectedTemplate,
  isSubmitting,
  unitData,
  loading,
  formatted,
  propertyName
}) {
  console.log('from data', formatted)
  const [itemNameColor, setItemNameColor] = useState(selectedTemplate?.item_name_color || '#8E8CA3');
    const [brandColor, setBrandColor] = useState( selectedTemplate?.title_color|| '#004FE8');
    const [itemDetailColor, setItemDetailColor] = useState(selectedTemplate?.item_details_color|| '#004FE8');
    const [backgroundColor, setBackgroundColor] = useState(selectedTemplate?.background_color || '#F5F5F5');

  return (
    <div key={val?.id} className='mx-6 my-4'>
        { val && <div
                    className='mb-6 p-2 flex justify-between items-center'
                    style={{
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        color: 'black',
                        width: '350px',
                        height: 'auto',
                        border: '2px solid #E1EBFE',
                        fontWeight: 400,
                    }}
                >Template - SM1 <div><Checkbox disabled={isSubmitting} checked={selectedTemplate?.template_chosen === "TEMPLATE1"} onChange={(e)=>{ //selectedTemplate?.template_chosen === "TEMPLATE1"
                  e.preventDefault()
                  onCheckSubmit('TEMPLATE1')
                }}/></div></div>}
                <div
                    style={{
                        borderRadius: '4px',
                        backgroundColor: `${backgroundColor}`,
                        width: isTemplateScreen ? '350px' : '200px',
                        height: 'auto',
                        border: '2px solid #A68C66',
                        position: isTemplateScreen ? '' : "absolute",
                        left:!val && `${popupPosition?.x}px`,
                        top: !val && `${popupPosition?.y}px`,
                        zIndex:  !val && 9999,
                        borderRadius: '8px'
                    }}
                >
                    <div className='flex items-center p-2' style={{ 
                      borderBottom: '2px solid #A68C66',
                      background:`${brandColor}`, 
                      borderBottomLeftRadius: '0px',
                      borderBottomRightRadius: '0px',
                      borderTopLeftRadius:'4px',
                      borderTopRightRadius:'4px',
                      justifyContent: isTemplateScreen ? 'center' : 'space-between'
                       }}>
                        <span style={{
                            fontFamily: 'Rubik',
                            fontSize: isTemplateScreen ? '18px' : '14px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'center',
                            color:'white',
                        }}>{isTemplateScreen ? 'Property Name' : propertyName}</span>
                        {!isTemplateScreen && <span onClick={popupClose} style={{ color: 'white', cursor: 'pointer' }}>&#10006;</span>}
              </div>
              <div className='p-4'>
                 {isTemplateScreen ?
                  <>
                        <div className='mx-6'>
                        <div className='my-2 flex justify-between'> 
                          <div style={{
                            fontWeight:500,
                            color:`${itemNameColor}`
                          }}>Front :</div>
                          <div style={{
                            color:`${itemDetailColor}`,
                            fontWeight:500
                          }}>79</div>
                          </div>
                          <div className='my-2 flex justify-between'> 
                          <div style={{
                            fontWeight:500,
                            color:`${itemNameColor}`
                          }}>Rear :</div>
                          <div style={{
                            color:`${itemDetailColor}`,
                            fontWeight:500
                          }}>110</div>
                          </div>
                          <div className='my-2 flex justify-between'> 
                          <div style={{
                            fontWeight:500,
                            color:`${itemNameColor}`
                          }}>Left :</div>
                          <div style={{
                           color:`${itemDetailColor}`,
                            fontWeight:500
                          }}>140</div>
                          </div>
                          <div className='my-2 flex justify-between'> 
                          <div style={{
                            fontWeight:500,
                            color:`${itemNameColor}`
                          }}>Right :</div>
                          <div style={{
                           color:`${itemDetailColor}`,
                            fontWeight:500
                          }}>127</div>
                          </div>
                           
                          <hr style={{border:'1px solid #777777'}} />
                          <div className='my-2 flex justify-between'> 
                          <div style={{
                            fontWeight:500,
                            color:`${itemNameColor}`
                          }}>Sqft :</div>
                          <div style={{
                           color:`${itemDetailColor}`,
                            fontWeight:500
                          }}>12337</div>
                          </div>
                          <hr style={{border:'1px solid #777777'}} />
                          <div className='my-2 flex justify-between'> 
                          <div style={{
                            fontWeight:500,
                            color:`${itemNameColor}`
                          }}>Grade :</div>
                          <div style={{
                           color:`${itemDetailColor}`,
                            fontWeight:500
                          }}>Flat</div>
                          </div>
                          <div className='my-2 flex justify-between'> 
                          <div style={{
                            fontWeight:500,
                            color:`${itemNameColor}`
                          }}>Type :</div>
                          <div style={{
                            color:`${itemDetailColor}`,
                            fontWeight:500
                          }}>Single Family</div>
                          </div>
                        </div>
                       <div className='mx-6 h-10 mt-4 flex items-center justify-center rounded-lg' style={{
                        background:`${brandColor}`,
                       }}>
                          <div style={{
                                color:`white`,
                                fontWeight:500
                              }}>Express Intrest</div>
                       </div>
                       
                  </>
                  :
                  <>
                 {unitData?.specs['Lot'] && (
                     <>
                      <div className='my-2'>
                     <div style={{ color:`${itemDetailColor}`,fontWeight:600 }}> Lot # {unitData?.specs['Lot']},<br/>
                         Block # {unitData?.specs['Block']}, {unitData?.specs['Location']}</div>
                 </div>
                 <hr style={{border:'1px solid #777777'}} />
                     </>
                  )}
                  {formatted?.map((val, index) => {
                    return (
                      <>
                         {val?.data?.map((val1, index1) => {
                          return (
                            <div className='my-2 flex justify-between' style={{width:'80%'}}> 
                            <div style={{
                              fontSize:'12px',
                              fontWeight:500,
                              color:`${itemNameColor}`
                            }}>{`${val1?.name} :`}</div>
                            <div style={{
                              fontSize:'12px',
                              color:`${itemDetailColor}`,
                              fontWeight:500
                            }}>{val1?.value}</div>
                            </div>
                          )
                         })}
                          <hr style={{border:'0.5px solid #777777'}} />
                      </>
                    )
                  })}
                  </>
                  }
                    </div>
                    <div className='flex items-center justify-center h-10 p-2' style={{ borderBottom: '2px solid #A68C66',background:`${brandColor}` }}>
                        {isTemplateScreen ? 
                        <span style={{
                          fontFamily: 'Rubik',
                          fontSize: '18px',
                          fontWeight: 400,
                          lineHeight: '24px',
                          textAlign: 'center',
                          color:'white',
                      }}>AVAILABLE</span>
                    :
                    <span style={{
                      fontFamily: 'Rubik',
                      fontSize: '18px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      textAlign: 'center',
                      color:'white',
                  }}>{unitData?.specs?.price === "" || unitData?.specs?.price === undefined 
                      ?  ""
                      : `$${unitData?.specs?.price}`}
                      </span>}

              </div>
                </div>
    </div>
  )
}

export default TempDesign2