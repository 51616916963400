import React from "react";
import ActionButton from "./ActionButton";

function SecondaryBtn(props){
    return(
        <ActionButton
            {...props}
            variant="contained"
            color="secondary"
            title={props.title || 'Secondary'}
            onClick={props.onClick}
        />
    )
}
export default SecondaryBtn;