import React, { useEffect, useState } from 'react';
import { PrimaryButton } from '@assystant/mui-buttons';
import { useParams } from 'react-router-dom';
import { apiService } from '../../../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import returnError from '../../../utils/ExportError';
import Loading from '../../shared-component/Loading/Loading';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotFound from './NotFound';

const UnSubscribe = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadScreen, setLoadScreen] = useState(false);
  const [details, setDetails] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  const retrieveOrganisationDetails = () => {
    setLoadScreen(true);
    apiService
      .get(`property/public_subscription/get_token_details/?uuid=${token}`)
      .then((response) => {
        setDetails(response.data);
        setLoadScreen(false);
      })
      .catch((error) => {
        if (error.response?.status === 404 || error.response?.data) {
          setLoadScreen(false);
          setIsNotFound(true);
        }
      });
  };

  const verifyToken = () =>{
    setLoadScreen(true);
    apiService
      .get(`property/public_subscription/verify_unsubscribe_token?uuid=${token}`)
      .then((response) => {
         if(response.status === 200){
          retrieveOrganisationDetails();
         }else{
            setIsNotFound(true)
            setLoadScreen(false);
         }
      })
      .catch((error) => {
        if (error.response?.status === 404 || error.response?.data) {
          setLoadScreen(false);
          setIsNotFound(true);
        }
      });
  }

  const unSubscribeHandler = () => {
    setLoading(true);
    apiService
      .post(`property/public_subscription/unsubscribe/`, {
        uuid: token,
      })
      .then((res) => {
        setLoading(false);
        setIsSuccess(true);
        enqueueSnackbar('Unsubscribed Successfully', { variant: 'success' });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response.data);
          enqueueSnackbar(returnedError, { variant: 'error' });
        }
      });
  };

  const unSubscribeAllHandler = () => {
    setLoading(true);
    apiService
      .post(`property/public_subscription/unsubscribe_all/`, {
        uuid: token,
      })
      .then((res) => {
        setLoading(false);
        setIsSuccess(true);
        enqueueSnackbar('Unsubscribed Successfully', { variant: 'success' });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response.data);
          enqueueSnackbar(returnedError, { variant: 'error' });
        }
      });
  };

  useEffect(() => {
    verifyToken();
  }, []);
  return (
    <div className="h-screen flex flex-col items-center justify-center mx-auto">
      {loadScreen ? (
        <Loading />
      ) : (
        <>
          {isSuccess && !isNotFound && (
            <div className="flex flex-col gap-[30px] items-center justify-center">
              <CheckCircleIcon className="!fill-[#1EAF11] !w-24 !h-24 !drop-shadow-[0_35px_35px_rgba(30, 175, 17, 0.25)]" />
              <div className="text-xl text-[#353349] font-medium">
                Unsubscribed Successfully!
              </div>
            </div>
          )}

          {isNotFound && !isSuccess && <NotFound hideButton />}

          {!isSuccess && details?.organization?.id && (
            <>
              <div
                className="text-[#353349]"
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '24px',
                  fontWeight: 300,
                  lineHeight: '28.44px',
                  textAlign: 'center',
                  margin: '0px 100px',
                }}
              >
                We're sorry to see you go! By clicking the button below, you
                will stop receiving property updates via email. If you change
                your mind in the future, you can always resubscribe to stay
                informed about the latest properties and updates.
              </div>
              <div className="my-6">
                <PrimaryBtn
                  title={`Unsubscribe from ${details?.linked_property?.name}`}
                  style={{
                    fontWeight: 400,
                    boxShadow: 'none',
                  }}
                  disbaled={loading}
                  onClick={unSubscribeHandler}
                />
              </div>

              <div className="">
                <PrimaryBtn
                  title={`Unsubscribe from ${details?.organization?.name}`}
                  style={{
                    fontWeight: 400,
                    boxShadow: 'none',
                  }}
                  disbaled={loading}
                  onClick={unSubscribeAllHandler}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UnSubscribe;
