import React from 'react';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import FormManager from '../../../shared-component/formrelated-component/FormManager';
import { neighboursIcons } from '../../../../Config/Constants';
import { apiService } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';


const EditNeighbourDetails = ({
    open,
    title,
    onClose,
    successCallback,
    data,
}) =>{
    const { id } = useParams()
    const { exitFromPlatform } = useExit();
    const defaultValues = {
        profile_icon: null,
        profile: ''
    }

    const detailSubmission = (e) =>{
        console.log('from payload', e)
        if(e?.profile === ''){
            enqueueSnackbar("Please add the position", { variant: 'error' });
            return;
        }else{
            let newData = {
                ...data,
                ...e,
                profile_icon: e?.profile_icon?.name ? e?.profile_icon?.name?.toLowerCase() : e?.profile_icon,
                property: data?.property?.id,
                unit: data?.unit?.id,
                form: data?.form?.id
            }

            if(data?.form?.id){
                newData['form'] = data?.form?.id
            }
            if(data?.employee?.id){
                newData['employee'] = data?.employee?.id
            }
            apiService.put(`booking/entries/${id}/`, newData).then((res) =>{
                if(res?.data){
                    enqueueSnackbar("Details Updated.", { variant: 'success' })
                    successCallback(res?.data)
                    onClose()
                }
            }).catch((error) =>{
                if (error.response?.data) {
                    const returnedError = returnError(error.response?.data);
                    if (returnedError === 'Given token not valid for any token type') {
                      exitFromPlatform();
                    } else {
                      enqueueSnackbar(returnedError, { variant: 'error' });
                    }
                }
            })
        }
    }

    const fieldMap = [
        {
            name:'profile_icon',
            type:'icon_selection',
            fieldProps: {
                listofIcons: neighboursIcons,
            }
        },
        {
            name:'profile',
            label:'Position',
            placeholder:'Position',
            type:'text'
        }
    ]
    return(
        <FormModal 
          open={open}
          title={title}
          onClose={onClose}
        >
            <FormManager 
              defaultValues={defaultValues}
              fieldMap={fieldMap}
              onCancel={onClose}
              data={data}
              formSubmit={detailSubmission}
            />
        </FormModal>
    )
}

export default EditNeighbourDetails