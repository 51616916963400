import { enqueueSnackbar } from 'notistack'
import React from 'react'


const InventoryMapContainer = ({
    mapHierarchy,
    openInNewTab,
    updateBreadcrumb,
    available_color = '#004FE8',
    sold_color = '#FF5C5C'
}) => {
    return (
        <div className='flex justify-center w-full' style={{
            height: '500px',
            position:"relative",
          }} >
            <svg style={{ 
              zIndex: 9,
              position:'absolute',
              width:'100%',
              height:'auto',
              marginLeft:'2px'
               }} viewBox={mapHierarchy?.viewbox} xmlns="http://www.w3.org/2000/svg">
                 {mapHierarchy?.hotspots?.map((val) => {
                    return <path className='cursor-pointer'  onClick={(e)=>{
                        if(val?.link_type === null){
                            enqueueSnackbar('No link available', { variant: 'default' })
                        }
                        if(val?.link_type === "MAP"){
                            updateBreadcrumb(val)
                        }
                        if(val?.link_type === "UNIT"){
                            openInNewTab(val?.unit_link?.id)
                        }
                    }} 
                    d={val.path} fill={
                        val?.link_type === "GREENSPACE" ? "#24D315" : (val?.unit_status === 'SOLD' ? sold_color : available_color)
                       
                    } fill-opacity={val?.link_type === "GREENSPACE" ? "1" : "1"} />
                 })}
          </svg>
          <img  
            style={{ 
              position: 'absolute',
              padding:0,
              width:'100%',         
              height:'auto',
            }} 
            src={mapHierarchy?.image || 'https://spotmink.com//category-backgrounds/V4_jq8wL3w.jpg'} 
            alt="map_image" />
        </div>
    )
}

export default InventoryMapContainer