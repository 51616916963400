import * as React from 'react';
 
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
 
 
import SecondaryBtn from '../../../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../../../shared-component/button-component/PrimaryBtn';
import FormModal from '../../../../shared-component/formmodal-component/FormModal';

export default function AlertModal({
  open,
  close,
  title,
  onSubmit=()=>{},
  description,
  isSecondary=false,
  primaryText,
  loading = false,
  isNotRemove = false,
  component,
  isAdditionalComponent = false,
  cancelText = "Cancel"
}) {

  return (
    <div>
       <FormModal
        open={open}
        title={title}
        onClose={close}
        removeCloseButton={true}
        >
       <DialogContent className='flex justify-between !min-h-28'>
          <DialogContentText id="alert-dialog-description" className='!text-[#353349] !my-2'>
          {description}
          {isAdditionalComponent && (
            component
          )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isSecondary &&(
            <SecondaryBtn
              title={cancelText}
              onClick={close} 
            />
          )}

        {!isNotRemove && (
          <PrimaryBtn 
          title={primaryText || 'Okay'} 
          onClick={onSubmit}
          disabled={loading}
          style={{
            padding:'5px 14px',
            boxShadow:'none'
          }}
          
           />
        )}
        </DialogActions>
       </FormModal>
    </div>
  );
}