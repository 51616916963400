import React from 'react';

const TwitterIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.948 7.99992C28.9214 8.46659 27.8147 8.77325 26.668 8.91992C27.8414 8.21325 28.748 7.09325 29.1747 5.74659C28.068 6.41325 26.8414 6.87992 25.548 7.14659C24.4947 5.99992 23.0147 5.33325 21.3347 5.33325C18.2014 5.33325 15.6414 7.89325 15.6414 11.0533C15.6414 11.5066 15.6947 11.9466 15.788 12.3599C11.0414 12.1199 6.81469 9.83992 4.00135 6.38659C3.50802 7.22659 3.22802 8.21325 3.22802 9.25325C3.22802 11.2399 4.22802 12.9999 5.77469 13.9999C4.82802 13.9999 3.94802 13.7333 3.17469 13.3333V13.3733C3.17469 16.1466 5.14802 18.4666 7.76135 18.9866C6.92232 19.2162 6.04148 19.2481 5.18802 19.0799C5.55016 20.2166 6.2594 21.2111 7.21604 21.9238C8.17268 22.6365 9.32862 23.0315 10.5214 23.0533C8.49953 24.6538 5.99335 25.519 3.41469 25.5066C2.96135 25.5066 2.50802 25.4799 2.05469 25.4266C4.58802 27.0532 7.60135 27.9999 10.828 27.9999C21.3347 27.9999 27.108 19.2799 27.108 11.7199C27.108 11.4666 27.108 11.2266 27.0947 10.9733C28.2147 10.1733 29.1747 9.15992 29.948 7.99992Z"
        fill="#004FE8"
      />
    </svg>
  );
};

export default TwitterIcon;
