// ----------------- Documentation ---------------------------
// this component used for edit the profile data 
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup


import React from "react";
import { apiService } from "../../../constant/AuthConstant";

import Form from "../../shared-component/formrelated-component/FormManager";
import FormModal from "../../shared-component/formmodal-component/FormModal";

function EditProfile({
    title,
    open,
    onClose,
}){
    const organizationvalue= {
        first_name:'Tom',
        last_name:'Cooper',
        position: 'Marketing Manager',
        email: 'tom.cooper@terezza.com',
        phone:'+1 718 591 6530',  
    }
    const defaultValues={
        first_name:'',
        last_name:'',
        position:'',
        email:'',
        phone:'',
        ...organizationvalue,
    }
    const fieldMap=[
            {
                name:'',
                label:'General Information',
                type:'box_layout',
                fieldProps:{
                    arrayFields:[
                        {
                            // name:'living_area',
                            // label:'Living Area',
                            // placeholder:'Living Area',
                            type:'avtar',
                        },
                        {
                            name:'first_name',
                            label:'First Name',
                            placeholder:'First Name',
                            type:'text',
                        },
                        {
                            name:'last_name',
                            label:'Last Name',
                            placeholder:'Last Name',
                            type:'text',
                        },
                        {
                            name:'position',
                            label:'Position',
                            placeholder:'Position',
                            type:'text',
                        },
                       
                    
                       
                    ]
                }
            },
            {
                name:'',
                label:'Contact Details',
                type:'box_layout',
                fieldProps:{
                    arrayFields:[
                        {
                            name:'email',
                            label:'Email',
                            placeholder:'Email',
                            type:'text',
                        },
                        {
                            name:'phone',
                            label:'Phone',
                            placeholder:'Phone',
                            type:'text',
                        },  
                    ]
                }
            },
           
    ]
    
    
    return(
        <>
        <FormModal title={title} open={open} onClose={onClose}>
            <Form
             defaultValues={defaultValues}
             fieldMap={fieldMap}
             onCancel={onClose}
             primaryBtnTitle="Save"
            />
        </FormModal>
        </>
    )
}
export default EditProfile;