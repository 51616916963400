// import React, { Component } from "react";
// import { render } from "react-dom";
// import { Stage, Layer, Shape, Text } from "react-konva";

// const RADIUS = 20;

// const Line = ({ points }) => {
//   return (
//     <Shape
//       points={points}
//       sceneFunc={(context, shape) => {
//         const width = points[1].x - points[0].x;
//         const height = points[1].y - points[0].y;
//         const dir = Math.sign(height);
//         const radius = Math.min(
//           RADIUS,
//           Math.abs(height / 2),
//           Math.abs(width / 2)
//         );

//         context.beginPath();
//         context.moveTo(points[0].x, points[0].y);
//         context.lineTo(points[0].x + width / 2 - RADIUS, points[0].y);
//         context.quadraticCurveTo(
//           points[0].x + width / 2,
//           points[0].y,
//           points[0].x + width / 2,
//           points[0].y + dir * radius
//         );
//         context.lineTo(points[0].x + width / 2, points[1].y - dir * radius);
//         context.quadraticCurveTo(
//           points[0].x + width / 2,
//           points[1].y,
//           points[0].x + width / 2 + radius,
//           points[1].y
//         );
//         context.lineTo(points[1].x, points[1].y);
//         context.fillStrokeShape(shape);
//       }}
//       stroke="black"
//       strokeWidth={2}
//     />
//   );
// };

// const CreateConnections = ({lines}) => {
// //   const [isDrawing, setDrawing] = React.useState(false);
// //   const [lines, setLines] = React.useState([
    
// //   ]);

//   return (
   
//         <>
//         {lines.map(l => (
//           <Line points={l} />
//         ))}
//         </>
     
//   );
// };


// export default CreateConnections;
import React, { useEffect, useState } from "react";
import { Stage, Layer, Line, Circle } from 'react-konva';

function CreateConnections({connectionPoints, setConnectionPoints,hotspots,setLines,lines,connectors,images}) {
//     const [hotspots, setHotspots] = useState([]);
// const [lines, setLines] = useState([]);
// const [connectionPoints, setConnectionPoints] = useState([]);
// const handleStageClick = (e) => {
//     const hotspot = { x: e.evt.clientX, y: e.evt.clientY };
//     setHotspots([...hotspots, hotspot]);
//     const point = { x: e.evt.clientX, y: e.evt.clientY };
//     setConnectionPoints([...connectionPoints, point]);
//   };
//   const handleStageClick = (e) => {
//     const point = { x: e.evt.clientX, y: e.evt.clientY };
//     setPoints([...points, point]);
//   };
  const drawLine = () => {
    if (hotspots.length >= 2) {
      const startPoint = hotspots[hotspots.length - 2];
      const endPoint = hotspots[hotspots.length - 1];
      const newLine = [...startPoint.x, startPoint.y, endPoint.x, endPoint.y];
      setLines([...lines, newLine]);
    }
  };
//   const [points, setPoints] = useState([]);
//   const [lines, setLines] = useState([]);
//   console.log('lines', lines)
//   const handleStageClick = (e) => {
//     const point = { x: e.evt.clientX, y: e.evt.clientY };
//     setPoints([...points, point]);
//   };
  const handlePointDrag = (index, e) => {
    const newPoints = [...connectionPoints];
    newPoints[index] = { x: e.target.x(), y: e.target.y() };
    if(connectionPoints.length <= 2){
    setConnectionPoints(newPoints);
    }
  };
//   useEffect(() => {
//     const newLines = [];
//     for (let i = 0; i < points.length - 1; i++) {
//       const linePoints = [{...points[i], ...points[i + 1]}];
//       newLines.push(linePoints);
//     }
//     setLines(newLines);
//     console.log('newLines', newLines)
//   }, [points]);
 
  return (
    <>
     
    {/* {connectionPoints.map((point, index) => (
            <Circle
              key={index}
              x={point.x}
              y={point.y}
              radius={5}
              fill="red"
              draggable
              onDragMove={(e) => handlePointDrag(index, e)}
            />
          ))}
      {connectionPoints.length === 2 && (
        <Line points={[connectionPoints[0].x, connectionPoints[0].y, connectionPoints[1].x, connectionPoints[1].y]} stroke="red" />
      )} */}
      {connectors.map(con => {
          const from = images.find(s => s.id === con.from);
          const to = images.find(s => s.id === con.to);

          return (
            <Line
              key={con.id}
              points={[from.x, from.y, to.x, to.y]}
              stroke="#1061CC"
            />
          );
        })}
    







      {/* <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        onClick={handleStageClick}
      >
        <Layer>
          {points.map((point, index) => (
            <Circle
              key={index}
              x={point.x}
              y={point.y}
              radius={5}
              fill="red"
              draggable
              onDragMove={(e) => handlePointDrag(index, e)}
            />
          ))}
          {lines.map((line, index) => (
            <Line key={index} points={line} stroke="black" />
          ))}
        </Layer>
      </Stage> */}
    </>
  );
}
export default CreateConnections;
