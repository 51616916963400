import React from 'react';

const NavigateIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8448_10453)">
        <path
          d="M15 0V12.1875H12.1875V15H0V2.8125H2.8125V0H15ZM14.0625 11.25V0.9375H3.75V2.8125H5.625V3.75H0.9375V14.0625H11.25V9.375H12.1875V11.25H14.0625ZM6.89209 8.76709L6.23291 8.10791L10.5835 3.75H7.5V2.8125H12.1875V7.5H11.25V4.4165L6.89209 8.76709Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8448_10453">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NavigateIcon;
