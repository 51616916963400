import { CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import { apiService } from '../../../constant/AuthConstant';
// import UserSignUp from './UserSignUp';
import NotFound from './NotFound';
import AffiliateSignup from './AffiliateSignup';
import { enqueueSnackbar } from 'notistack';

const UserInvitation = () =>{
    const navigate = useNavigate()
    const [isValidationRunning, setIsvalidationRunning] = React.useState(true)
    const [isValidated, setIsValidated] = React.useState(false)
    const [user, setUser] = React.useState({});
    const [invitation_code, setInvitation_code] = useState(null)
    const logedProfile = localStorage.getItem('profile')

    const validate = () =>{
        const code = window.location.href?.substring( window.location.href.lastIndexOf('n/') + 2,  window.location.href.length - 1)
        setInvitation_code(code)
        setIsvalidationRunning(true)
        apiService.get(`affiliates/verify_invitation/${code}/`)
        .then((res) =>{
            setIsvalidationRunning(false)
            console.log(res)
            if(res.status === 200){
                localStorage.setItem('invitation-code', JSON.stringify(code))
                setUser(res?.data)
                if(res?.data?.id){
                   setUser(res?.data)
                   if(logedProfile !== null){
                    navigate('/accept-invitation')
                   }else{
                    setIsValidated(true)
                   }
                }
            }
        }, (error) =>{
            console.log('from error response', error.response.status)
            if(error.response.status === 404){
                setIsvalidationRunning(false)
                localStorage.removeItem('invitation-code')
            }else if(error.response?.status === 401){
                if(logedProfile === null){
                    enqueueSnackbar("Please login first")
                    localStorage.setItem('invitation-code', JSON.stringify(code))
                    navigate('/')
                }else{
                    localStorage.setItem('invitation-code', JSON.stringify(code))
                    navigate('/accept-invitation')
                }
            }else{
                setIsvalidationRunning(false)
                localStorage.removeItem('invitation-code')
            }
        }).catch((error) =>{
            console.log('from error response', error.response.status)
            if(error.response?.status === 401){
                if(logedProfile === null){
                    enqueueSnackbar("Please login first")
                    localStorage.setItem('invitation-code', JSON.stringify(code))
                    navigate('/')
                }else{
                    localStorage.setItem('invitation-code', JSON.stringify(code))
                    navigate('/accept-invitation')
                }
            }else{
                setIsvalidationRunning(false)
                localStorage.removeItem('invitation-code')
            }
        })
    }

    React.useEffect(() =>{
        validate()
    },[])

    return(
        <>
         {isValidationRunning === true ?
            <div className='flex items-center justify-center h-screen w-full'>
                 <CircularProgress color='primary'/>
                 <Typography variant='subtitle1' className='!ml-8 !font-semibold'>Please wait...</Typography>
            </div>
            :
            <>
            {isValidated === false &&(
                 <NotFound />
            )}
            </>
         }
         {isValidated === true && (
            <AffiliateSignup 
             user={user}
             invitation_code={invitation_code}
            />
         )}
        </>
    )
}

export default UserInvitation