import { Radio, Typography } from '@mui/material';
import React from "react";
import { useFormContext } from 'react-hook-form';



function RadioButton(props){
    const { setValue, getValues} = useFormContext()
    return (
        <div className="flex items-center" style={{...props?.sx}}>
            <Radio checked={props.checked} disabled={props.disabled} onChange={(e) => setValue(props?.name, e.target.checked) } defaultChecked={getValues(props?.name)} />
            <Typography className="flex justify-center text-14 items-center">
               {props.label}
            </Typography>
        </div>
      );
}
export default RadioButton;