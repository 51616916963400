import React from 'react';

const MobileView = ({
    color = '#E1EBFE'
}) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4375 1.51367H6.5625C5.73516 1.51367 5.0625 2.21436 5.0625 3.07617V21.8262C5.0625 22.688 5.73516 23.3887 6.5625 23.3887H17.4375C18.2648 23.3887 18.9375 22.688 18.9375 21.8262V3.07617C18.9375 2.21436 18.2648 1.51367 17.4375 1.51367ZM17.25 21.6309H6.75V3.27148H17.25V21.6309ZM11.0625 19.1406C11.0625 19.3996 11.1613 19.648 11.3371 19.8312C11.5129 20.0143 11.7514 20.1172 12 20.1172C12.2486 20.1172 12.4871 20.0143 12.6629 19.8312C12.8387 19.648 12.9375 19.3996 12.9375 19.1406C12.9375 18.8816 12.8387 18.6332 12.6629 18.4501C12.4871 18.2669 12.2486 18.1641 12 18.1641C11.7514 18.1641 11.5129 18.2669 11.3371 18.4501C11.1613 18.6332 11.0625 18.8816 11.0625 19.1406Z"
        fill={color}
      />
    </svg>
  );
};

export default MobileView;
