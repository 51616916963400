import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SpotMink from '../../asset/Images/SpotMink.png'
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons'
import { apiService } from '../../constant/AuthConstant'
import useExit from '../../utils/useExit'
import returnError from '../../utils/ExportError'
import { enqueueSnackbar } from 'notistack'
import { CircularProgress, Typography } from '@mui/material'
import UserContext from '../../context/UserContext'
import SecondaryBtn from '../shared-component/button-component/SecondaryBtn'

const AcceptInvitation = () =>{
    const navigate = useNavigate();
    const { exitFromPlatform } = useExit();
    const [user, setUser] = useState(null);

    const {
        getAffiliateOrganizations
    } = useContext(UserContext);

    const navigateToDashboard = () =>{
        localStorage.removeItem('invitation-code')
        navigate('/dashboard')
    }

    const [loadScreen, setLoadScreen] = useState(false)

    const getInformation = () =>{
        setLoadScreen(true)
        const codeString = localStorage.getItem('invitation-code');
        const code = JSON.parse(codeString);
        apiService.get(`affiliates/verify_invitation/${code}/`)
        .then((res) =>{
            if(res.status === 200){
                if(res?.data?.id){
                   setUser(res?.data)
                  
                }
            }
            setLoadScreen(false)
        }, (error) =>{
             console.log(error)
             setLoadScreen(false)
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setLoadScreen(false)
              }
              }
        })
    }

    const acceptInvitation = () =>{
        const codeString = localStorage.getItem('invitation-code');
        const code = JSON.parse(codeString);
        apiService.post(`affiliates/accept_invite/`, {
            invitation_code: code
        }).then((response) =>{
            console.log('from response', response)
            enqueueSnackbar('Accepted', { variant: 'success' })
            getAffiliateOrganizations()
            setTimeout(() =>{
                navigateToDashboard()
            }, 2000)
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
              }
              }
        })
    }

    useEffect(() =>{
        getInformation()
    }, [])
 return(
        loadScreen ? 
        <div className='flex items-center justify-center h-screen w-full'>
                 <CircularProgress color='primary'/>
                 <Typography variant='subtitle1' className='!ml-8 !font-semibold'>Please wait...</Typography>
            </div>
            :
        <div className='w-full h-screen bg-[#cddbf7] flex justify-center my-auto'>
             <div className='w-2/4 bg-white flex flex-col items-center' style={{
                boxShadow: "0px 8px 10px #00000017, -10px 8px 15px #00000017, 10px 8px 15px #00000017",
                marginTop:'auto',
                marginBottom:'auto'
             }}>
                <div className="my-32">
                  <div className="flex justify-center mb-16">
                     <img src={SpotMink} alt='' width="180" height='50'/>
                  </div>
                  <div>
                     <div className="flex my-4" style={{
                        fontFamily: 'Rubik',
                        fontSize: '32px',
                        fontWeight: 500,
                        lineHeight: '37.92px',
                        color:"#353349"
                     }}>
                         New Affiliate Partership
                     </div>
                     <div className="flex my-8">
                        <div style={{
                               fontFamily: 'Rubik',
                               fontSize: '16px',
                               fontWeight: 500,
                               lineHeight: '18.96px',
                               color:"#353349"
                        }}>
                           {`${user?.organization?.name}`}
                        </div>
                        <div className='ml-1.5' style={{
                               fontFamily: 'Rubik',
                               fontSize: '16px',
                               fontWeight: 300,
                               lineHeight: '18.96px',
                               color:"#353349"
                        }}>
                          has invited you to become their affiliate partner
                        </div>
                     </div>
                     <div className='flex w-full'>
                         <div className="w-2/4">
                             <SecondaryBtn
                               title="Decline"
                               className="!ml-0"
                               style={{
                                boxShadow: 'none',
                                width:'100%',
                                marginLeft:'0px !important',
                                fontWeight:300
                               }}
                               onClick={navigateToDashboard}
                              />
                         </div>
                         <div className="w-2/4">
                             <PrimaryBtn
                               title="Accept"
                               style={{
                                boxShadow: 'none',
                                width:'100%',
                                fontWeight:300
                               }}
                               onClick={acceptInvitation}
                              />
                         </div>
                     </div>
                  </div> 
                  </div>
             </div>
        </div>
    )
}

export default AcceptInvitation