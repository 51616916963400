import React from 'react';

const DocumentsIcon = ({
    color = 'white'
}) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.77753 26.5625H20.2225C22.6485 26.5625 23.8555 25.332 23.8555 22.8945V12.254C23.8555 10.742 23.6915 10.086 22.754 9.125L16.285 2.551C15.395 1.6365 14.668 1.4375 13.344 1.4375H7.77753C5.36353 1.4375 4.14453 2.6795 4.14453 5.1175V22.8945C4.14453 25.3435 5.36353 26.5625 7.77753 26.5625ZM7.87103 24.676C6.66403 24.676 6.03103 24.031 6.03103 22.8595V5.1525C6.03103 3.9925 6.66403 3.324 7.88303 3.324H13.086V10.133C13.086 11.6095 13.836 12.336 15.289 12.336H21.969V22.8595C21.969 24.031 21.3475 24.676 20.129 24.676H7.87103ZM15.5 10.566C15.043 10.566 14.855 10.379 14.855 9.91V3.6875L21.605 10.5665L15.5 10.566Z"
        fill={color}
      />
    </svg>
  );
};

export default DocumentsIcon;
