import { AppBar, IconButton, Toolbar, Typography,TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
// import CloseButton from '../button-component/CloseButton';
import CloseBtn from '../button-component/CloseBtn';
import { elevation } from '../../../Config/Constants';
import DropDownButton from '../formrelated-component/DropDownButton';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../constant/AuthConstant';
import { useEffect } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ToolTipComponent from '../Tooltip/ToolTipComponent';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ExpandLess } from '@mui/icons-material';
import BackIcon from '../../../asset/icons/BackIcon';
import ModalSideButton from './ModalSideButton';

const ModalHeader = ({
  setTemp, 
  headerSelect,
  title, 
  Icon = React.Fragment, 
  canCancel = false, 
  onCancel = () => {},
  dropDownButton=false,
  dropDownbuttonOpt=[], 
  onDefaultOptionClick = () =>{},
  templates,
  updateFields = (e) => {},
  data={},
  defaultTemplate,
  info,
  setFields,
  setDefaultValues,
  setLoadForm,
  loadform,
  isCustomization,
  additionalDetails,
  description = "",
  onBackArrowClick = () => {},
  isSideDrawer = false,
  sideTitle = '',
  removeCloseButton = false,
  sideButtonClickHandler = () => {}
}) => {
 const [val, setVal] = useState(null);
 
 const handleChange = (e) =>{

  setVal(e.target.value)
  setTemp(e.target.value)
  updateFields(e.target.value)
 }

 useEffect(() =>{
   if(data?.template_name){
    const find = templates?.find((e) =>{
   
      return e?.name === data?.template_name
    })
    setVal(find)
   }
   if(data?.template){
    const find = templates?.find((e) =>{
      
      return e?.name === data?.template
    })
    setVal(find)
   }
 }, [data])

  useEffect(() =>{
    if(defaultTemplate !== null){
      setVal(defaultTemplate)
    }
  }, [defaultTemplate])


 return (
     <>
     {!isCustomization &&(
      <AppBar position="static" elevation={elevation.elevation}>
      <Toolbar className="flex w-full justify-between">
        <div className="w-full flex justify-between items-center">
          
         <div className='flex items-center'>
          <div className="flex gap-[30px]">
          {isSideDrawer && (
            <div className='flex items-center cursor-pointer' onClick={onBackArrowClick}>
               <BackIcon />
            </div>
          )}
          <Typography sx={{fontSize:'20px',fontWeight:500}} data-testid="title" variant="subtitle1" color="inherit" className="ml-5">
            {title}
          </Typography>
          </div>
           {info && (
            <div className='ml-4'>
                <ToolTipComponent title={info}>
                  <ErrorOutlineIcon fontSize='20' color='#FFFFFF' className='!fill-[#8E8CA3]' />
            </ToolTipComponent>
            </div>
           )
           }
         </div>

        <div className='my-2' style={{width:'210px',display:'flex',justifyContent:'space-between',alignItems:'center'}}> 
        <Box sx={{ minWidth: isSideDrawer ? 0 : 120 }}>
     {headerSelect && <FormControl sx={{width:'160px'}}>
        <InputLabel shrink={true} id="demo-simple-select-label">Fields templetes</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={val}
          label="Fields templetes"
          disabled={data?.groups?.length === 0}
          IconComponent={(props) =>{
            console.log('from selected', props?.className?.includes('MuiSelect-iconOpen'))
            if(props?.className?.includes('MuiSelect-iconOpen')){
                return <ExpandLess {...props} className='!fill-[#8E8CA3]' sx={{
                    pointerEvents:'none !important',
                    position:'absolute !important',
                    right:'5px !important'
                }}/>
            }
            return <ExpandMore {...props} sx={{
                pointerEvents:'none !important',
                position:'absolute !important',
                right:'5px !important'
            }} className='!fill-[#8E8CA3]'/>
            
          }}
          renderValue={(e) =>{
            return e.template_name || e.name 
          }}
          notched={true}
          onChange={(e)=>{
             
              if(e.target.value === "show fields"){
                onDefaultOptionClick()
              }else{
                handleChange(e)
              }

          }}
        >
          {templates?.map((val)=>{
            return <MenuItem value={val}>{val?.template_name ? val?.template_name : val?.name}</MenuItem>
          })}
          <Divider />
          <MenuItem value={'show fields'}>
            <Typography color={'#004FE8'}>{`Show Fields`}</Typography>
          </MenuItem>
        </Select>
      </FormControl>}
    </Box>
    {!isSideDrawer ? (
      <>
        {removeCloseButton === false && (
        <CloseIcon onClick={onCancel} sx={{cursor:'pointer'}}/>
      )}
      </>
    ) : (
      <ModalSideButton 
        sideTitle={sideTitle}
        clickHandler={sideButtonClickHandler}
      />
    )}
    
    </div>
        </div>
        {dropDownButton && (
          <DropDownButton 
            dropDownbuttonOpt={dropDownbuttonOpt}
          />
        )}
        {/* {canCancel && (
          <CloseBtn onCancel={onCancel}/>
        )} */}
      </Toolbar>
    </AppBar>
    )}
    {isCustomization &&(
         <AppBar position="static" elevation={elevation.elevation} className='!bg-transparent'>
         <Toolbar className="flex w-full justify-between">
           <div className="w-full flex justify-between items-center">
             
            <div className='flex items-center'>
            <Typography sx={{fontSize:'20px',fontWeight:400}} data-testid="title" variant="subtitle1" color="inherit" className="ml-5">
               {title}
             </Typography>
            </div>
   
           <div className='my-2' style={{width:'200px',display:'flex',justifyContent:'space-between',alignItems:'center'}}> 
           <Box className="!w-full">
              {additionalDetails}
          </Box>
      </div>
           </div>
         </Toolbar>
       </AppBar>
    )}
     </>
  );
};
export default ModalHeader;
