import React, { useEffect, useState } from 'react'
import { 
    Dialog
 } from '@mui/material'
 import CloseIcon from '@mui/icons-material/Close';
 import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TimelineComponent from './TimelineComponent';
import TimelineImagesCarousel from './TimelineImagesCarousel';

const TimelinePreview = ({
    open,
    onClose,
    timelineList
}) =>{
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedImages, setSelectedImages] = useState([]);
    const [openCarousel, setOpenCarousel] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleCarousel = () =>{
        setOpenCarousel(!openCarousel)
    }

    const sampleData = [
        {
            id:1,
            title: 'Completion of Phase 1',
            date:'15th September 2024',
            description:'Property is a system of rights that gives people legal control of valuable things,[1] and also refers to the valuable things themselves. Depending on the nature of the property, an owner of property may have the right to consume, alter,',
            images: [
                'https://images.unsplash.com/photo-1542687226-54a9a41eeafd?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1602941525421-8f8b81d3edbb?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1602941525421-8f8b81d3edbb?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1605276374104-dee2a0ed3cd6?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1505521586751-90af6a8d5efa?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
              ],
            icon: <AccountBalanceIcon />
        },
        {
            id:2,
            title: 'Completion of Phase 2',
            date:'15th September 2024',
            description:'Property is a system of rights that gives people legal control of valuable things,[1] and also refers to the valuable things themselves. Depending on the nature of the property, an owner of property may have the right to consume, alter,',
            images: [
                'https://images.unsplash.com/photo-1542687226-54a9a41eeafd?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1602941525421-8f8b81d3edbb?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1602941525421-8f8b81d3edbb?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1605276374104-dee2a0ed3cd6?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1505521586751-90af6a8d5efa?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
              ],
            icon: <AccountBalanceIcon />
        },
        {
            id:3,
            title: 'Completion of Phase 3',
            date:'15th September 2024',
            description:'Property is a system of rights that gives people legal control of valuable things,[1] and also refers to the valuable things themselves. Depending on the nature of the property, an owner of property may have the right to consume, alter,',
            images: [
                'https://images.unsplash.com/photo-1542687226-54a9a41eeafd?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1602941525421-8f8b81d3edbb?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1602941525421-8f8b81d3edbb?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1605276374104-dee2a0ed3cd6?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                'https://images.unsplash.com/photo-1505521586751-90af6a8d5efa?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
              ],
            icon: <AccountBalanceIcon />
        }
    ]

    const onImageClick = (index, images) =>{
        setActiveIndex(index)
        setSelectedImages(images)
        handleCarousel()
    }

    useEffect(() =>{
      if(timelineList?.length){
        setSelectedId(timelineList[0]?.id)
      }
    }, [timelineList])
  
    return (
       <>
         <Dialog
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
             width: '80%',
             maxWidth: '80%',
          },
        }}
      }
      >
        <div className=''>
           <div className='flex items-center justify-between ml-8 mr-4 pt-4 pb-4 sticky top-0 bg-white' style={{
             zIndex: 99
           }}>
                <div className='' style={{
                    fontFamily: 'Rubik',
                    fontSize: '24px',
                    fontWeight: 400,
                    lineHeight: '37.92px',
                    letterSpacing: '-0.022em',
                }}>
                    Project Updates
                </div>
                <div className='' onClick={onClose}>
                    <CloseIcon className='!fill-[#000000] cursor-pointer' />
                </div>
           </div>

           <div className='m-8'>
              {timelineList?.map((update) =>{
                 return(
                    <TimelineComponent
                    key={update?.id} 
                    timelineId={update?.id}
                    title={update?.title}
                    icon={update?.icon?.icon}
                    description={update?.description}
                    date={update?.date}
                    images={update?.images}
                    onImageClick={onImageClick}
                    setSelectedId={setSelectedId}
                    selectedId={selectedId}
                 />
                 )
              })}
           </div>
        </div>
      </Dialog>

      {openCarousel && (
         <TimelineImagesCarousel 
           open={openCarousel}
           onClose={handleCarousel}
           allImages={selectedImages}
           activeIndex={activeIndex}
         />
      )}
       </>
    )
}

export default TimelinePreview