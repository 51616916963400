import React, { useEffect, useState } from 'react';
import { apiService } from '../../../../constant/AuthConstant';
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import Loading from '../../../shared-component/Loading/Loading';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import { useSnackbar } from 'notistack';
import PopoverComponent from '../../../shared-component/Popover/PopoverComponent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import formatISOString from '../../../../utils/formatISOString';
import FilterFilledDocument from '../modals/FilterFilledDocument';
import getDateAgo from '../../../../utils/getDateAgo';

const tableData = [
  {
    id: 1,
    name: 'Land Agreement',
    created: '30th April 2023 10:30:07',
    responses: '1',
    linkedForms: ['From Name 1'],
    attachments: 'Land agreement...',
    action: 'delete',
    property: 'Park',
  },
  {
    id: 2,
    name: 'Land Agreement',
    created: '30th April 2023 10:30:07',
    responses: '-',
    linkedForms: ['From Name 1'],
    attachments: 'Land agreement...',
    action: 'delete',
    property: 'Park',
  },
  {
    id: 3,
    name: 'Buyer Agreement',
    created: '30th April 2023 10:30:07',
    responses: '5',
    linkedForms: ['From Name 1'],
    attachments: 'Land agreement...',
    action: 'delete',
    property: 'Park',
  },
];

const ActionComponent = ({
  link,
  fileName,
  downloadFile
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <PopoverComponent
      id={'simple-popover'}
      open={open}
      anchorEl={anchorEl}
      handleClose={handleClose}
      handleClick={handleClick}
      popHandler={
        <div onClick={handleClick}>
          <MoreVertIcon className="!fill-[#8E8CA3]" />
        </div>
      }
      component={<div className='p-4 text-[#8E8CA3] font-normal text-sm cursor-pointer' onClick={() => {
        downloadFile(link, fileName)
        handleClose()
      }}>
          Download editable
      </div>}
    />
  );
};

const FilledDocumentsTab = ({
  openFilterModal, 
  handleFilterModal
}) => {
  const { exitFromPlatform } = useExit();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [filledDocumentList, setFilledDocumentList] = useState(tableData);
  const [form, setForm] = useState(null)
  const [property, setProperty] = useState(null)
  const [document, setDocument] = useState(null);
  const [buttonSelected, setButtonSelected] = useState({
    id: 3,
    label: 'Last 7 days',
    value: '7',
  })

  const onFileClick = (file_link) =>{
    if(!file_link.includes('https')){
      const newString =  file_link.slice(0, 4) + 's' + file_link.slice(4);
      window.open(`${newString}`)
      return
     }
     window.open(`${newString}`)
  }

  const downloadFile = (link, fileName) => {
    if(!link.includes('https')){
     const newString =  link.slice(0, 4) + 's' + link.slice(4);
     saveAs(newString, fileName)
     return
    }
    saveAs(link, fileName)
  }

  const getFilledDocumentsList = () => {
    setLoading(true);
    apiService
      .get(`documents/filled_docs/`)
      .then((response) => {
        if (response?.data) {
          setFilledDocumentList(response?.data?.reverse());
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  useEffect(() =>{
    getFilledDocumentsList()
  }, [])

  const columns = [
    { field: 'name', headerName: 'Name', minWidth: 150, renderCell: (params) => {
      return params?.row?.org_doc?.title;
    },},
    { field: 'created', headerName: 'Filled Date', minWidth: 200, renderCell: (params) => {
      return formatISOString(params?.row?.created);
    },},
    { field: 'property', headerName: 'Property', minWidth: 250, renderCell: (params) => {
      return params?.row?.property_name;
    }, },
    {
      field: 'attachments',
      headerName: 'Attachments',
      minWidth: 300,
      renderCell: (params) => {
        const fileString = params?.row?.pdf_file?.substring(
          params?.row?.pdf_file.lastIndexOf('/') + 1,
          params?.row?.pdf_file?.length,
        );
        return (
          <div className="text-[#004FE8] font-normal" onClick={() => onFileClick(params?.row?.pdf_file)}>{fileString || '-'}</div>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 50,
      align: 'center',
      renderCell: (params) => {
        return <ActionComponent 
           fileName={params?.row?.org_doc?.title}
           link={params?.row?.filled_file}
           downloadFile={downloadFile}
        />;
      },
    },
  ];

  const returnEndDate = (buttonValue) =>{
    if(buttonValue === 'today'){
       return getDateAgo(0)
    }else if(buttonValue === 'yesterday'){
       return getDateAgo(1)
    }else if(buttonValue === '7'){
       return getDateAgo(7)
    }else if(buttonValue === '30'){
       return getDateAgo(30)
    }else if(buttonValue === '90'){
       return getDateAgo(90)
    }else if(buttonValue === '180'){
       return getDateAgo(180)
    }else{
     return getDateAgo(365)
    }
  }

  const onFilter = () =>{
    let newData = {
     property: property?.id ? property?.id : '',
     form: form?.id ? form?.id : '',
     start_date: returnEndDate(buttonSelected?.value),
     end_date: buttonSelected?.value === 'yesterday' ? getDateAgo(1) : getDateAgo(0),
     document: document?.id ? document?.id : '',
    }
     
    Object.entries(newData)?.map((e) =>{
     if(e[1] === '' || e[1] === null || e[1] === undefined){
       delete newData[e[0]]
     }
   })
    const searchParams = new URLSearchParams(newData).toString();

    setLoading(true)
    
    apiService.get(`documents/filled_docs/?${searchParams}`)
    .then((res) =>{
     if(res?.data){
         setFilledDocumentList(res?.data?.reverse())
     }
     setLoading(false)
     handleFilterModal()
    })
    .catch((error) =>{
      setLoading(false)
     if(error.response?.data){
       const returnedError = returnError(error.response?.data)
       if(returnedError === 'Given token not valid for any token type'){
         exitFromPlatform()
     }else{
         enqueueSnackbar(returnedError, {variant:'error'})
     }
     }})
 }

  return loading ? (
    <Loading />
  ) : (
    <>
      {filledDocumentList?.length > 0 ? (
        <DataGridTable
          columns={columns}
          rows={filledDocumentList}
          actionWidth={100}
        />
      ) : (
        <EmptyCard
          title="No Documents Available"
          subtitle="Click “Add Documents” to add a document"
        />
      )}

     {openFilterModal && (
        <FilterFilledDocument 
          title="Filter"
          form={form}
          setForm={setForm}
          property={property}
          setProperty={setProperty}
          document={document}
          setDocument={setDocument}
          open={openFilterModal}
          onClose={handleFilterModal}
          buttonSelected={buttonSelected}
          setButtonSelected={setButtonSelected}
          onSubmit={onFilter}
        />
      )}
    </>
  );
};

export default FilledDocumentsTab;
