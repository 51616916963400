import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material';
import { TokenStorage, apiService } from '../../../../constant/AuthConstant'
import { useSnackbar } from 'notistack';
import axios from 'axios';

const Images = ({ index, item , removeImage, setFilesUrl, filesUrl, setFiles, files, fieldProps }) =>{
    const { enqueueSnackbar } = useSnackbar()
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false)
    const uploadImage = (item) =>{
      if(item?.file?.name){
        setLoading(true)
         const formData = new FormData()
         
         let apiUrl = `property/image/`
         
         formData.append('image', item.file)
         console.log('from image', formData.getAll('image'), item)
         axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}${apiUrl}`,
            data: formData,
            headers: { 
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${TokenStorage.getToken()}`
             },
          })
         //apiService.post(apiUrl, formData)
         .then((response)=>{
            if(response.data){
                files[index] = response.data
                filesUrl[index] = response.data
                setFiles(files)
                setFilesUrl(filesUrl)
                setImage(response.data)
            }
            setTimeout(()=>{
                setLoading(false)
            }, 3000)
         }).catch((error)=>{
            setLoading(false)
            enqueueSnackbar('Image upload failed', { variant:"error" })
            console.log('from image error', error)
         })
      }
    }


    useEffect(()=>{
        uploadImage(item)
    }, [])

    useEffect(() =>{
        console.log('from updated items', image)
    },[image])

    
    return(
        <div className='mx-4 my-4' style={{
            width: '165px',
            height: '160px',
            borderRadius: '15px',
            position: 'relative', // Add this for positioning the image
        }}>
            <span className='' 
               style={{ position:'absolute',
                        backgroundColor:'white',
                        width:'25px',
                        height:'25px',
                        borderRadius:'25px',
                        right:'10px',
                        top:'10px',
                        cursor:'pointer',
                        color:'#FF5C5C',
                        zIndex:9999
                }} onClick={()=>removeImage(item)}>&#10006;</span>
            {loading ?
                <div className='h-full flex items-center justify-center'>
                    <CircularProgress size={20} color='primary'/>
                </div>
                :
                <img
                src={item?.image ? item?.image : image?.image}
                alt="invalid image"
                style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '15px',
                    objectFit: 'cover',
                }}
            />}
        </div>
    )
}

export default Images