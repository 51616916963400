// ----------------- Documentation ---------------------------
// this component used for add and edit the data from inventory list
// this form used the firebase database to store the data


import React, { useEffect } from "react";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import DataGridTable from "../../shared-component/table-component/DataGridTable";


import { useState } from "react";

import EmptyCard from "../../shared-component/card-component/EmptyCard";


 
// import LabTabs from "@assystant/mui-tabs"
import {useNavigate, useParams} from 'react-router-dom';

import { API_URL, apiService } from "../../../constant/AuthConstant";
import axios from "axios";
import Loading from "../../shared-component/Loading/Loading";

import { useSnackbar } from "notistack";
import RegularCheckbox from "../../shared-component/OverviewPage/overview-used-components/RegularCheckbox";
import DeleteIcon from "../../../asset/icons/DeleteIocn";
import LabTabs from "../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs";
import InventoryMapView from "./InventoryMapView";
import returnError from "../../../utils/ExportError";
import RemovePopup from "../../shared-component/RemovePopup/RemovePopup";
import useExit from "../../../utils/useExit";
import AddBooking from '../booking/component/AddBooking'
import { TextButton } from "@assystant/mui-buttons";
import ProfileContainer from "../../shared-component/table-component/Row/ProfileContainer";



 const StatusComponent = ({
  booking_status,
  changeStatus,
  data,
  changeUnitStatus
 }) =>{
    const [status, setStatus] = useState(booking_status);

    return(
      <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={status}
        label=""
        onChange={(e)=>{
          setStatus(e?.target.value)
          changeStatus({
            ...data?.confirmed_booking,
            unit: data?.id,
          }, e)
        }}
        sx={{height:'40px',
        background: 'white !important',
        color: data?.confirmed_booking.booking_status === 'confirmed' ? '#24D315' : '#FF5C5C',
        fontWeight:300,
      }}
      >
          <MenuItem value='opened' sx={{color:'#24D315 !important', fontWeight:300,}} >Booking Received</MenuItem>
          <MenuItem value='confirmed' sx={{color: '#24D315 !important', fontWeight: 300}}>Booking Confirmed</MenuItem>
          <MenuItem value='inactive' sx={{color:'#FF5C5C !important', fontWeight:300,}}>Booking Cancelled</MenuItem>
          <MenuItem value='waitlist' sx={{color:'#FF5C5C !important', fontWeight: 300}}>Waitlist</MenuItem>
      </Select>
    </FormControl>
    )
 }


// const rows = []

function Inventory({
  propertyDetails,
  fetch,
  fetchInventory,
  inventoryList,
  inventorySelection,
  setInventorySelection,
  loadInventory,
  fetchProperty,
  setInventoryList,
  userFromOrganization
}){
const { id } = useParams()  
const [load, setLoad] = useState(false);
const [fetchAgain, setFetchAgain] = useState(false)
const {enqueueSnackbar} = useSnackbar()
const navigate = useNavigate();
const [openConfirm, setOpenConfirm] = useState(false);
const [savedParams, setSavedParams] = useState({})
const { exitFromPlatform } = useExit()
const [openBooking, setOpenBooking] = useState(false)
const [selectedUnit, setSelectedUnit] = useState({});
const [organization, setOrganization] = useState({});
const [status, setStatus] = useState(null);


const [confirmStatus, setConfirmStatus] = useState(false);
const [bookingData, setBookingData] = useState({});

const handleConfirmStatus = () =>{
  setConfirmStatus(!confirmStatus)
}
  
const [value, setValue] = useState('1');

const handleBookingModal = () =>{
  setOpenBooking(!openBooking)
}


const handleConfirm = () =>{
  setOpenConfirm(!openConfirm)
}
  const deleteRow = (e,data) =>{
    e.preventDefault()
    e.stopPropagation()
    setLoad(true)
    apiService.delete(`property/unit/${savedParams?.id}`).then((res) => {
        setLoad(false)
        let list = [...inventoryList];
        list = list.filter((e) =>{
            return e.id !== savedParams.id
        })
        setInventoryList(list)
        handleConfirm()
      
        enqueueSnackbar('Unit deleted', { variant: "success"})
        console.log(res)
    }).catch((error) => {
       
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoad(false)
        }
        }
    })
  }
 
  useEffect(() => {
    if(localStorage.getItem('tabValue') === '3'){
      fetchProperty()
    }
    fetchInventory()
    
  }, [])

 
    function handelRowClick(e){
      navigate(`/properties/${id}/inventory/${e.id}`, {});
    }


    async function handleStatusChange(e, data) {
      if(e?.stopPropagation){
        e.stopPropagation();
      }
      
      try {
       apiService.patch(`property/unit/${data?.id}/`, {status: e.target.value}).then((res) =>{
        enqueueSnackbar('Inventory status changed!', { variant: `success` })
        fetchInventory()
        if(e.target.value === "SOLD"){
          handleBookingModal()
        }
        setLoad(false)
       }).catch((error) =>{
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoad(false)
        }
        }
       })
      } catch (error) {
         if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoad(false)
        }
        }
        

      }
      
  }

  async function fetchDataFromCollection() {
    apiService.get(`organization/get-user-org/`).then((res)=>{
        if(res.data){
            setOrganization(res.data)
        }
    }).catch((error)=>{
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
          }
          }
    })
  }
 


  useEffect(() =>{
    fetchDataFromCollection()
  }, [])
    const checkboxSelection = (item, state) =>{
       
        const check = inventorySelection?.some((e) =>{
            return e === item?.id
        })
         
        if(check === false && state?.target.checked){
          let selections = [...inventorySelection];
           selections = [...selections, item?.id]
          setInventorySelection(selections)
        }
        if(check === true){
          const selections = [...inventorySelection];
          const filter = selections?.filter((e) =>{
            return e !== item?.id
          })
          setInventorySelection(filter)
        }
    }
    console.log('from adsdsad', inventorySelection)
    async function changeBookingStatus(data, e) {
      apiService.put(`booking/entries/${data?.id}/`, {
        ...data,
        booking_status: e.target.value,
        property: id,
        unit: data?.unit,
      }).then((res) =>{
        enqueueSnackbar('Inventory status changed!', { variant: `success` })
        fetchInventory()
        handleConfirmStatus()
        setLoad(false)
       }).catch((error) =>{
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoad(false)
        }
        }
       })
  }


      const columns = [
        {
          field: 'checkbox',
          headerName: '',
          minWidth: 50,
          headerClassName: 'custom-header',
          renderCell: (params) => (
          <RegularCheckbox 
            row={params.row}
            defaultChecked={inventorySelection?.some((e) => e === params.row.id)}
            onClick={checkboxSelection}
            istable
             />
          ),
        },
        { field: 'id', headerName: 'ID',minWidth: 100},
        { field: 'name', headerName: 'Unit Name',minWidth: 200, renderCell: (params) => (params.row.name || '_')},
        { 
          field: 'linked_hotspots', 
          headerName: 'Map relation',
          minWidth: 200,
          renderCell: (params) =>{
            const mapRelation = params.row.linked_hotspots?.map((e) =>{
               return e?.name
            })
            return mapRelation?.join(', ') || '_'
          }
        },
       
        { 
        field: 'status', 
        headerName: 'Status',
        minWidth: 200,
        renderCell: (params) => (
            <>
             {params?.row?.sellable === false ? '-' 
             :
             <FormControl  fullWidth>
             <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={params.row.status}
               label=""
               disabled={userFromOrganization?.role?.name.toLowerCase() === 'admin' || userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true}
               onChange={(e)=>{
                setSelectedUnit(params?.row)
                console.log('from confirmed', params?.row?.confirmed_booking, params?.row)
                if(e.target.value === 'AVAILABLE'){
                
                  if(params?.row?.confirmed_booking?.id){
                    setBookingData(params?.row)
                    handleConfirmStatus()
                  }else{
                    handleStatusChange(e, params)
                  }
                }else{
                  setStatus(e)
                  handleBookingModal();
                }
               }}
               sx={{height:'40px',
               background: 'white !important',
               color:params.row.status === 'AVAILABLE' ? '#24D315' : '#FF5C5C',
               fontWeight:300,
             }}
             >
                <MenuItem value='AVAILABLE' sx={{color:'#24D315 !important', fontWeight:300}} >Available</MenuItem>
                <MenuItem value='SOLD' sx={{color:'#FF5C5C !important', fontWeight:300}}>Sold</MenuItem>
             </Select>
           </FormControl>
            }
            </>
            ),
        },
        { 
          field: 'booking_unit', 
          headerName: 'Booking',
          minWidth: 200,
          renderCell: (params) =>{
             if(params?.row?.sellable !== false){
             
              if(params?.row?.booking_unit?.length === 0){
                return  <div onClick={(e) =>{
                            e?.persist()
                            e?.stopPropagation()
                            if(userFromOrganization?.role?.name.toLowerCase() === 'admin' || userFromOrganization?.role?.name.toLowerCase() === 'developer'){
                              setSelectedUnit(params?.row)
                              setStatus(e)
                              handleBookingModal()
                            }
                          }}
                          style={{
                            fontFamily: 'Rubik',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '23.7px',
                            letterSpacing: '-0.022em',
                            textAlign: 'left',
                            textDecoration:'underline',
                            color:userFromOrganization?.role?.name.toLowerCase() === 'admin' || userFromOrganization?.role?.name.toLowerCase() === 'developer' ? '#1061CC' : '#8E8CA3'
                          }}
                          >
                          Add Booking
                     </div>
                }else{
                    return  <ProfileContainer profile={{
                           name: params?.row?.booking_unit[0]?.name
                     }} />
                }
             }else{
                return "-"
             }
          }
        },
       
          {
            field: 'actions',
            headerName: '',
            sortable: false,
            minWidth: 50,
            align:'right',
            renderCell: (params) => (
                <>
                {userFromOrganization?.role?.name.toLowerCase() === 'admin' || userFromOrganization?.role?.name.toLowerCase() === 'developer' ? 
                 <div className="cursor-pointer" onClick={(e)=>{
                  e.stopPropagation()
                  e.preventDefault()
                  setSavedParams(params.row)
                  handleConfirm()
                 }}>
                        <DeleteIcon color="error" className="!fill-[#FF5C5C]"  />
                    </div>
                    :
                    <></>}
                
                </>
                ),
          },
      ];
     
      const tabs = [
        {label:'List', value:'1', page:
        inventoryList.length === 0 ? 
            <EmptyCard title='No Inventory Available' subtitle='Click “Add New” to add Unit' /> 
        :
            <DataGridTable checkboxSelection columns={columns} actionWidth={100} rows={inventoryList} handleEvent={handelRowClick} />
        
        },
        {label:'Plan',value:'2', page:
        inventoryList.length === 0 ? 
            <EmptyCard title='No Inventory Available' subtitle='Click “Add New” to add Unit' /> 
        :
            <InventoryMapView propertyDetails={propertyDetails} />
        
        },
    ]
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    return(
        <>
           {loadInventory ? <Loading/> : <Box>
                <LabTabs 
                  tabs={tabs} 
                  setValue={setValue} 
                  value={value} 
                  handleChange={handleChange} 
                  tabContainerStyles={{
                    margin: '0px 0px 0px 5px !important'
                  }}
                  tabPanelStyles={{
                    padding: '24px 0px !important'
                  }}
                  />
            </Box>}
            {openConfirm &&(
              <RemovePopup 
                 open={openConfirm}
                 close={handleConfirm}
                 onSubmit={deleteRow}
                 description={`Are you sure you want to delete this unit.`}
              />
            )}
            {openBooking && (
              <AddBooking 
                title={"Add Booking"}
                open={openBooking}
                onClose={handleBookingModal}
                data={{
                  property: {name: propertyDetails?.name, id: propertyDetails?.id},
                  unit: {name: selectedUnit?.name, id: selectedUnit?.id}
                }}
                organization={organization}
                successCallback={fetchInventory}
                isUnit={true}
              />
            )}
            
            {confirmStatus && (
              <RemovePopup 
               open={confirmStatus}
               close={handleConfirmStatus}
               description="Are you sure you want to make this unit available? If so, please change the status below."
               isSecondary={handleConfirmStatus}
               isNotRemove={true}
               isAdditionalComponent={true}
               component={
                <>
                  <div className="my-8 w-52">
                    <StatusComponent 
                     data={bookingData}
                     changeStatus={changeBookingStatus}
                     booking_status={bookingData?.confirmed_booking.booking_status}
                    />
                  </div>
                </>
               }
              />
            )}
        </>
    )
}
export default Inventory;