import React, { useEffect } from 'react';
import Chart from "react-apexcharts";
import { Card, Typography } from '@mui/material';
import HorizontalBarChart from './HorizontalBarChart';

const BarChart = ({
  title,
  data,
  errorComponent,
}) =>{

  function truncateWithEllipses(text, max) 
{
    return text.substring(0,max-1)+(text.length>max?'...':''); 
}

    const state = {
        series: [{
          data: data?.map((e) =>{
            return e?.interested_leads
          })
        }],
        options: {
          chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
              },
       
          },
          grid:{
            show:false
          },
          colors:['#004FE8'],
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '70%',
              barWidth:'50%',
              dataLabels: {
                position: 'top',
              },
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: '12px',
              colors: ['#fff'],
              fontWeight: 400,
              fontFamily: 'Rubik',
            }
          },
          responsive: [{
           // breakpoint: 1050,
            options: {
              chart: {
                width: 800
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          xaxis: {
            show: true,
            categories: data?.map((e) =>{
              return e?.name
            }),
            labels: {
                show: false,
              },
              axisBorder: {
                show: true,
                color: '#8E8CA3',
                width: 0,
                height:0
              },
              axisTicks: {
                show: false
              }
          },
          yaxis:{
            show:true,
            labels: {
              show: true,
              trim: true,
              formatter: function(value, timestamp, opts) {
                return truncateWithEllipses(value.toString(), 8)
              },
              style: {
                 zIndex:9999
            },
            },
            axisBorder: {
              show: true,
              color: '#8E8CA3',
              width:2,
            },
            axisTicks: {
              show: true
            }
          }
        },
      };

    return(
        <Card className="border-none rounded-lg" sx={{
            backgroundColor: 'rgba(184, 183, 197, 0.2)',
            boxShadow:'none'
        }}> 
           <div style={{
            minHeight:'200px'
           }}>
            <div className='m-8'>
                <Typography sx={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                    letterSpacing:' -0.022em',
                    textAlign: 'left',
                    
                }}>
                    {title}
                </Typography>
            </div>
           {data?.length === 0 || data?.length === undefined ?
            <>
            {errorComponent}
            </>
           :
          //  <Chart
          //     options={state.options}
          //     series={state.series}
          //     type="bar"
          //     width="300"
          //   />
           <div className='mx-4'>
             <HorizontalBarChart data={data?.map((e) =>{
              return {
                 ...e,
                 value: e?.interested_leads
              }
             })} />
           </div>
            }
           </div>
        </Card>
       
    )
}

export default BarChart;