// ----------------- Documentation ---------------------------
// this component used for add and edit the data from assign inventory
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup
// edit                    boolead             used for the edit form
// edittitle               string              to maintain the edit form title of the popup
// data                    array               to show the default form value in the edit form

import React from "react";

import { enqueueSnackbar } from "notistack";
import FormModal from "../../../../shared-component/formmodal-component/FormModal";

import { TextField } from "@mui/material";
 
import PrimaryBtn from "../../../../shared-component/button-component/PrimaryBtn";
import SecondaryBtn from "../../../../shared-component/button-component/SecondaryBtn";

function Integration({
    open,
    onClose,
    text,
    title = "Integrate",
    children,
    rows = 16,
    message = 'Script Copied'
}) {
    return (
        <>
            <FormModal title={title} open={open} onClose={onClose}>
                 {children ? 
                 (children) :
                 (
                    <>
                <div className="w-full p-6">
                   <TextField
                    className="!w-full"
                    multiline
                    rows={rows}
                    value={text}
                    />
                </div>
                <div className="w-full flex justify-end px-8 pb-8">
                    <SecondaryBtn style={{ backgroundColor: '#EDECF9' }} onClick={onClose} title='Done' />
                    <PrimaryBtn title='Copy' onClick={() =>{
                        navigator.clipboard.writeText(text)
                        enqueueSnackbar(message, {variant:'success'})
                    }} />
                </div>
                 </>
                 )
                 }
            </FormModal>
        </>
    )
}
export default Integration;