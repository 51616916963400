import React from 'react';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import FormManager from '../../../shared-component/formrelated-component/FormManager';
import { useState } from 'react';

import { useSnackbar } from 'notistack';
import { apiService } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';

function HotSpotModal({ id, data, open, onClose, successCallback }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { exitFromPlatform } = useExit();

  let defaultValues = {
    name: '',
    svg_pathdata: '',
    map: id,
    link_type: null,
    unit_link: '',
    map_link: '',
  };

  async function handelAddHotspot(e) {
    if (e?.name === '') {
      enqueueSnackbar('Please enter hotspot name', { variant: 'error' });
      return;
    } else if (e?.svg_pathdata === '') {
      enqueueSnackbar('Please enter hotspot script', { variant: 'error' });
      return;
    } else {
      setLoading(true);
      let apiUrl;
      let service;

      if (data === null) {
        apiUrl = `property/hotspot/`;
        service = apiService.post;
      } else {
        apiUrl = `property/hotspot/${data?.id}/`;
        service = apiService.patch;
      }
      service(apiUrl, e)
        .then((res) => {
          if (data === null) {
            enqueueSnackbar('Hotspot added successfully', {
              variant: 'success',
            });
          } else {
            enqueueSnackbar('Hotspot updated successfully', {
              variant: 'success',
            });
          }
          successCallback();
          setLoading(false);
          onClose();
          console.log(res);
        })
        .catch((error) => {
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (
              returnedError === 'Given token not valid for any token type' ||
              returnedError === 'Request failed with status code 401'
            ) {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
              setLoading(false);
            }
          }
        });
    }
  }

  const fieldMap = [
    {
      name: 'name',
      label: 'Hotspot Name',
      placeholder: 'Name',
      type: 'text',
      fieldProps: {
        required: true,
      },
    },
    {
      name: 'svg_pathdata',
      label: 'Paste HTML Script',
      placeholder: 'Paste script here...',
      type: 'scriptPaste',
    },
  ];

  return (
    <>
      <FormModal
        title={data !== null ? 'Edit Hotspot' : 'Add Hotspot'}
        open={open}
        onClose={onClose}
      >
        <div className="my-4">
          <FormManager
            btntitle="upload plan"
            defaultValues={defaultValues}
            fieldMap={fieldMap}
            primaryBtnTitle="Save"
            onCancel={onClose}
            formSubmit={handelAddHotspot}
            loading={loading}
            data={data !== null ? data : {}}
          />
        </div>
      </FormModal>
    </>
  );
}
export default HotSpotModal;
