import React from 'react';
import GridComponent from '../../shared-component/grid-component/GridComponent';
import { Grid, Box, Typography } from '@mui/material';
import DetailsCard from '../../shared-component/card-component/DetailsCard';
import LabelAndValue from '../../shared-component/OverviewPage/overview-used-components/LabelAndValue';
import DescriptionComponent from '../forms/component/DescriptionComponent';
import CardComponent from '../forms/component/CardComponent';
import { grids } from '../../../Config/Constants';
import { formatTime, formatDate } from '../../../utils/helper';
import EmptyCard from '../../shared-component/card-component/EmptyCard';
import formatISOString from '../../../utils/formatISOString';

const FormSubmittedDetailsComponent = ({
  getFiles,
  sectionDetails,
  independentFields,
  details,
}) => {
  console.log('from details', details);
  const renderFile = (file) => {
    const fileType = file.title.split('.').pop().toLowerCase();

    switch (fileType) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return (
          <CardComponent
            name={file?.title}
            url={file.file}
            fileType={fileType}
          />
        );
      case 'pdf':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      case 'doc':
      case 'docx':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );

      case 'ppt':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      case 'xlsx':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      case 'dwg':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      case 'mp3':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      case 'mp4':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      default:
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
    }
  };

  const updateKeyName = (label) => {
    return label
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  console.log('from section details', sectionDetails);
  return (
    <div className="m-6">
      {details?.id ? (
        <GridComponent>
          <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid}>
            {sectionDetails?.length ? (
              sectionDetails?.map((e) => {
                return (
                  <div className="mb-4">
                    <DetailsCard cardHeader={e?.section_name}>
                      <DescriptionComponent
                        description={e?.section_description}
                      />
                      {e?.fields?.map((m) => {
                        return (
                          updateKeyName(m?.name) === 'Signature' || updateKeyName(m?.name)?.toLowerCase()?.includes('sign')  ? 
                          <div className="my-4">
                             <Typography sx={{ color:'#B1AED1', fontWeight:300 }}>
                                {updateKeyName(m?.name)}
                             </Typography>
                             <img src={m?.value} width={250} height={100} className='object-contain'/>
                          </div>
                          :
                          <div className="my-4">
                            <LabelAndValue
                              label={updateKeyName(m?.name)}
                              value={m?.value}
                              isCustomizingLabel
                              viewStyles={{
                                fontSize: '28px !important',
                              }}
                            />
                          </div>
                        );
                      })}
                      {e?.files?.length > 0 ? (
                        <>
                          <div className="flex items-center flex-wrap gap-[10px]">
                            {e?.files?.map((file, index) => (
                              <div key={index}>{renderFile(file)}</div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </DetailsCard>
                  </div>
                );
              })
            ) : (
              <>
                <div className="mb-4">
                  <DetailsCard cardHeader={'No Section'}>
                    {independentFields?.map((m) => {
                      return (
                        updateKeyName(m?.name) === 'Signature' || updateKeyName(m?.name)?.toLowerCase()?.includes('sign')  ? 
                        <div className="my-4">
                           <Typography sx={{ color:'#B1AED1', fontWeight:300 }}>
                              {updateKeyName(m?.name)}
                           </Typography>
                           <img src={m?.value} width={250} height={100} className='object-contain'/>
                        </div>
                        :
                        <div className="my-4">
                          <LabelAndValue
                            label={updateKeyName(m?.name)}
                            value={m?.value}
                            isCustomizingLabel
                            viewStyles={{
                              fontSize: '28px !important',
                            }}
                          />
                        </div>
                      );
                    })}
                    {getFiles?.length > 0 ? (
                      <>
                        <div className="flex items-center flex-wrap gap-[10px]">
                          {getFiles.map((file, index) => (
                            <div key={index}>{renderFile(file)}</div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </DetailsCard>
                </div>
              </>
            )}
          </Grid>

          <Grid item xs={grids.childgrid} lg={grids.childgrid}>
            <DetailsCard cardHeader={details?.submissions?.formDetails?.label}>
              <DescriptionComponent
                description={details?.submissions?.formDetails?.description}
              />
              <div className="my-4">
                <Box>
                  <Typography sx={{ color: '#B1AED1', fontWeight: 300 }}>
                    Submission On
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: '#353349',
                      fontSize: '20px',
                    }}
                  >
                    {formatISOString(details?.created)}
                  </Typography>
                </Box>
                
              </div>
              <div className="my-4">
              <Box>
                <Typography className='!mb-2' sx={{ color: '#B1AED1', fontWeight: 300 }}>
                    Updated By
                  </Typography>
                  {details?.source === 'USER_SUBMITTED' ? (
                    <div
                      className="w-9/12"
                      style={{
                        background: '#24D315',
                        borderRadius: '25px',
                        padding: '5px 10px',
                        color: '#fff',
                        textAlign: 'center',
                        fontFamily: 'Rubik',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 300,
                        lineHeight: 'normal',
                      }}
                    >
                      {`User Completed`}
                    </div>
                  ) : (
                    <div
                    className="w-9/12"
                      style={{
                        background: '#FAD200',
                        borderRadius: '25px',
                        padding: '5px ',
                        color: '#fff',
                        textAlign: 'center',
                        fontFamily: 'Rubik',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 300,
                        lineHeight: 'normal',
                      }}
                    >
                      {`AI Found`}
                    </div>
                  )}
                </Box>
              </div>
            </DetailsCard>
          </Grid>
        </GridComponent>
      ) : (
        <EmptyCard title="Form Details are not available" />
      )}
    </div>
  );
};

export default FormSubmittedDetailsComponent;
