import React, { useState } from 'react';
import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions';

import { BoxEffects, headerButton } from '../../../Config/Constants';

import Box from '@mui/material/Box';
import PropertyCard from '../../shared-component/propertycard/PropertyCard';
import AddProperty from './AddProperty';
import { useEffect } from 'react';
import { useContext } from 'react';
import UserContext from '../../../context/UserContext';
import { Stack } from '@mui/material';
import { apiService } from '../../../constant/AuthConstant';
import Loading from '../../shared-component/Loading/Loading';
import { useSnackbar } from 'notistack';
import returnError from '../../../utils/ExportError';
import EmptyCard from '../../shared-component/card-component/EmptyCard';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import useExit from '../../../utils/useExit';
const _ = require('lodash');

function Property() {
  const { enqueueSnackbar } = useSnackbar();
  const arr1 = [{ title: 'Properties', url: '/' }];

  const [openAddProperty, setOpenAddProperty] = useState(false);
  const { setFiles, setFilesUrl, filesUrl, userFromOrganization } =
    useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const { exitFromPlatform } = useExit();
  function handleOpenModal() {
    setOpenAddProperty(!openAddProperty);
    setFiles([]);
    setFilesUrl([]);
  }

  const [properties, setProperties] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [load, setLoad] = useState(false);

  const fetchProperty = () => {
    setLoad(true);
    apiService
      .get('property/prop/')
      .then((res) => {
        if (res?.data) {
          setProperties(res.data);
          setLoad(false);
        }
        console.log(res);
      })
      .catch((error) => {
        setLoad(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };
  useEffect(() => {
    localStorage.setItem('tabValue', '1');
    fetchProperty();
  }, []);

  async function handelAddProperty(data) {
    setLoading(true);
    if (data?.property_name === '') {
      enqueueSnackbar('Property name is required!', { variant: 'error' });
      setLoading(false);
      return;
    } else if (data?.description === '') {
      enqueueSnackbar('Description is required!', { variant: 'error' });
      setLoading(false);
      return;
    }
     else {
      const newData = {
        name: data?.property_name,
        description: data?.description,
        images: [],
        price: data?.price ? data?.price : 0,
        token_price: data?.token_price ? data?.token_price : 0,
      };
      console.log('payload', newData);
      apiService
        .post('property/prop/', newData)
        .then((res) => {
          console.log(res);
          enqueueSnackbar('Property added!', { variant: 'success' });
          setFiles([]);
          setFilesUrl([]);

          setTimeout(() => {
            fetchProperty();
            setLoading(false);
            setSubmit(false);
            setOpenAddProperty(false);
          }, 500);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (
              returnedError === 'Given token not valid for any token type' ||
              returnedError === 'Request failed with status code 401'
            ) {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
            }
          }
        });
    }
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '65px',
          backgroundColor: 'white',
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          <div className="flex justify-between items-center">
            {/* <SearchBar /> */}
            {userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
            userFromOrganization?.role?.name.toLowerCase() === 'developer' ? (
              <div
                style={{
                  marginLeft: '20px',
                }}
              >
                <PrimaryBtn
                  className="!mx-0"
                  style={{
                    boxShadow: 'none',
                    margin: '0px !important',
                    fontWeight: 300,
                    padding: '6px 16px',
                  }}
                  title="Add Property"
                  onClick={handleOpenModal}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </BreadCrumbWithActions>
      </Box>
      <div
        className="m-6 overflow-y-scroll"
        style={{
          maxHeight: '75vh',
        }}
      >
        {load ? (
          <Loading />
        ) : properties?.length === 0 ? (
          <EmptyCard
            title="No Property Added"
            subtitle="Click “Add Property to add the property"
          />
        ) : (
          <Stack
            className="!grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
            direction="row"
            spacing={2}
            sx={{
              rowGap: '20px',
              columnGap: '20px',
            }}
          >
            {properties?.map((itm) => {
              return (
                <PropertyCard data={itm} url={itm?.image_list[0]} id={itm.id} />
              );
            })}
          </Stack>
        )}
      </div>
      {openAddProperty && (
        <AddProperty
          title="Add Property"
          open={openAddProperty}
          onClose={() => setOpenAddProperty(false)}
          handelAddProperty={handelAddProperty}
          successCallback={fetchProperty}
          loading={loading}
        />
      )}
    </>
  );
}

export default Property;
