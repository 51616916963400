import { Typography } from '@mui/material';
import React from 'react';
import { PrimaryButton } from '@assystant/mui-buttons';
import { useNavigate } from 'react-router-dom';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';

const NotFound = ({ hideButton = false }) => {
  const navigate = useNavigate();
  const logedProfile = localStorage.getItem('profile');
  return (
    <>
      <div className="flex items-center justify-center h-screen">
        <div className="">
          <Typography
            variant="h3"
            className="!text-center !text-6xl !mb-4 !font-bold"
          >
            404
          </Typography>
          <div>
            <Typography
              variant="subtitle1"
              className="!text-center !text-[#414449] !font-semibold"
            >
              Page not found
            </Typography>
            <Typography
              variant="subtitle1"
              className="!text-center !text-[#909196]"
            >{`Sorry, we couldn't find the page you are looking for`}</Typography>
          </div>
          {!hideButton && (
            <>
              {logedProfile === null && (
                <div className="mt-4 flex items-center justify-center">
                  <PrimaryBtn
                    title="Go To Login"
                    onClick={() => {
                      navigate('/');
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NotFound;
