import React, { useCallback, useEffect, useState } from 'react'

import GridComponent from '../../../shared-component/grid-component/GridComponent';
import { apiService } from '../../../../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../utils/useExit';
import returnError from '../../../../utils/ExportError';
import { useParams } from 'react-router-dom';
import { BoxEffects, headerButton, grids } from '../../../../Config/Constants';
import { Grid, Box } from '@mui/material';
import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import Loading from '../../../shared-component/Loading/Loading';
import DetailsCard from '../../../shared-component/card-component/DetailsCard';
import LabelAndValue from '../../../shared-component/OverviewPage/overview-used-components/LabelAndValue';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
} from '@mui/material';
import Business from '@mui/icons-material/Business';
import formatISOString from '../../../../utils/formatISOString';
import DescriptionComponent from './DescriptionComponent';
import FormSubmittedDetailsComponent from '../../ParentComponents/FormSubmittedDetailsComponent';


const arr1 = [
    {title: 'Forms', url: '/forms'},
    {title: 'FormResponses', url: '/forms'},

]


const CardComponent = ({
     name,
     url,
     fileUrl,
     fileType
}) =>{
    return (
        <Card className='cursor-pointer' sx={{ 
             boxShadow:'none',
             border:'2px solid #E1EBFE',
             borderRadius:'8px',
             margin:'4px 0',
             width: '250px',
             height:'250px'
              }}>
      {url ? 
       <CardMedia
       sx={{ height: '150px' ,  objectFit: "contain"}}
       image={`${url}` ||  <>No Image</>}
       title="green iguana"
     />
      :
      <div
      sx={{ height: '150px' }}
      >
        <a
          className="w-full bg-[#EDECF9] flex items-center justify-center"
          href={fileUrl}
          style={{ 
            height: '150px', 
            objectFit: 'contain',
            fontfamily: 'Rubik',
            fontSize: '32px',
            fontWeight: 500,
            lineHeight: '',
            color: '#8E8CA3'
         }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {fileType.toUpperCase()}
        </a>
      </div>
     }
      {/* <img src={`${API_URL}media/${url.image}/`} style={{ height: '336px' ,  objectFit: "contain"}} /> */}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{
          fontWeight: 300,
          fontSize: '15px'
        }}>
         {name?.length > 15 ? `${name}...` : name  || 'Untitled'}
        </Typography>
       
      </CardContent>
    </Card>
    )
}

function removeTrailingSpaces(text) {
    return text.split('\n')  // Split the string into an array of lines
                .map(line => line.trimEnd())  // Remove trailing spaces from each line
                .join('\n');  // Join the lines back together
  }


const FormDetailPreview = () =>{
    const { id } = useParams()
    const { exitFromPlatform } = useExit()
    const [details, setDetails] = useState([])
    const [loading, setLoading] = useState(false);
    const [sectionDetails, setSectionDetails] = useState([])
    const [independentFields, setIndependentFields] = useState([]);
    const [breadcrumb, setBreadCrumb] = useState(arr1)
    const [getFiles, setGetFiles] = useState([])

    const updateKeyName = (label) => {
        return label.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }


    const retrieveFormSubmissionDetails = () =>{
        setLoading(true)
        apiService.get(`form_integration/submissions/${id}/`).then((res) =>{
            if(res?.data){
                 const newData = {
                    ...res?.data,
                    submissions: JSON.parse(res?.data?.submission)
                }
                console.log('from new data', newData, newData?.submissions?.sections?.length > 0)
                setBreadCrumb([...breadcrumb, {title: newData?.id, url: ''}])
                if(newData?.submissions?.sections?.length > 0){
                  
                   const update = newData?.submissions?.sections?.map((e) =>{
                    const nn = {...e};
                    delete nn?.source
                    return {
                        ...nn,
                        fields: e?.fields?.filter((f) =>{
                           return f?.name !== "files"
                        }),
                        files: e?.fields?.find((g) =>{
                            if(g.name === 'files'){
                                return g
                            }
                        })?.value
                    }
                   })
                   
                   setSectionDetails(update)
                   setDetails(newData) 
                   setLoading(false)
                }else{
                    const formData = {...newData?.submissions};
                    delete formData?.formDetails;
                    delete formData?.sections;
                    delete formData?.files;
                    delete formData?.unit;
                    delete formData?.property;
                    delete formData?.source;
                   
                    const newnData = Object.entries(formData)?.map((m) =>{
                        return {
                            name: updateKeyName(m[0]),
                            value: m[1]
                        }
                    })
                    const newFiles = newData?.submissions?.files?.length > 0 ? [...newData?.submissions?.files] : []
                    setGetFiles(newFiles)
                    setIndependentFields(newnData)
                    setDetails(newData) 
                    setLoading(false)
                }
               
                
            }
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setLoading(false)
              }
              }
        })
    }
    useEffect(() =>{
        retrieveFormSubmissionDetails()
    }, [])

    

    const renderDetails = useCallback(() =>{
        return details?.map((e) =>{
            return <div className='flex flex-col'>
                 <div className='text-[#B1AED1] mb-2' style={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                    letterSpacing: '-0.022em',
                 }}>{e?.name}</div>
                 <div className='text-[#353349] mb-2' style={{
                    fontFamily: 'Rubik',
                    fontSize: '18px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                    letterSpacing: '-0.022em',
                 }}>{e?.value}</div>
            </div>
          })
    }, [details])
    console.log('from sssss', details)

    const formatDate = (created) => {
        const date = new Date(created);
        if (isNaN(date.getTime())) {
            console.error('Invalid date');
            return null;
        }
       
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        };
        const formattedDate = date.toLocaleDateString('en-GB', options);
        return formattedDate;
    };
    
    const formatTime = (created) => {
        const date = new Date(created);
        if (isNaN(date.getTime())) {
            console.error('Invalid date');
            return null;
        }
       
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        let formattedTime = date.toLocaleTimeString('en-GB', options);
        formattedTime = formattedTime.replace(/:/g, '/');
        return formattedTime;
    };
    
    const renderFile = (file) => {
        const fileType = file.title.split('.').pop().toLowerCase();
    
        switch (fileType) {
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'gif':
            return <CardComponent name={file?.title} url={file.file} fileType={fileType} />
          case 'pdf':
            return (
                <CardComponent name={file?.title} fileUrl={file.file} fileType={fileType} />
            );
          case 'doc':
          case 'docx':
            return (
                <CardComponent name={file?.title} fileUrl={file.file} fileType={fileType} />
            );

          case 'ppt':
            return (
                <CardComponent name={file?.title} fileUrl={file.file} fileType={fileType} />
            )
          case 'xlsx':
            return (
                <CardComponent name={file?.title} fileUrl={file.file} fileType={fileType} />
            )
          case 'dwg':
            return (
                <CardComponent name={file?.title} fileUrl={file.file} fileType={fileType} />
            )
          case 'mp3':
            return (
                <CardComponent name={file?.title} fileUrl={file.file} fileType={fileType} />
            )
          case 'mp4':
             return (
                <CardComponent name={file?.title} fileUrl={file.file} fileType={fileType} />
             )
          default:
            return (
                <CardComponent name={file?.title} fileUrl={file.file} fileType={fileType} />
            );
        }
      };

    return (
        loading ? <Loading />
        : 
        <>
        <Box
         sx={{
           borderBottom: BoxEffects.borderBottom,
           borderColor: 'secondary.main',
           padding: BoxEffects.padding,
           marginBottom: BoxEffects.marginButton,
           paddingLeft: BoxEffects.margin,
           paddingRight: headerButton.marginRigt,
         }}
       >
         <BreadCrumbWithActions propArr={breadcrumb} />
       </Box>
       <FormSubmittedDetailsComponent 
            getFiles={getFiles}
            sectionDetails={sectionDetails}
            independentFields={independentFields}
            details={details}
        />
      </>
    )
}

export default FormDetailPreview