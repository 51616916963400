import React, { useState } from 'react';

const ImageLayout = ({
    allImages,
    onImageClick
}) => {
      const [openAllImages, setOpenAllImages] = useState(false)

      const onOpenClick = (e) =>{
        e.stopPropagation()
        setOpenAllImages(true)
      }
  const mainImages = allImages.slice(0, 3);
  const extraImages = allImages.slice(3);


      // return (
      //    allImages?.length > 0 ?
      //    <div className="grid grid-cols-2 gap-4 p-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
      //    {allImages?.map((src, index) => (
      //      <>
      //      {openAllImages && (
      //          <div key={index} className="relative cursor-pointer" 
      //            onClick={() =>{
      //              onImageClick(index, allImages)
      //            }}>
      //          <img src={src?.media} alt={`Grid ${index}`} className="w-full h-52 rounded-lg object-cover" style={{ objectFit: "cover"}} />
      //        </div>
      //      )}

      //      {!openAllImages && (
      //          <div key={index} className="relative cursor-pointer" 
      //            onClick={() =>{
      //              onImageClick(index, allImages)
      //            }} 
      //          >
      //          {index < 4 && (
      //            <img src={src?.media} alt={`Grid ${index}`} className="w-full h-52 rounded-lg object-cover" style={{ objectFit: "cover"}} />
      //          )}
      //          {index === 3 && (
      //             <div className='absolute bg-black opacity-60 w-full h-52 rounded-lg top-0 flex items-center justify-center cursor-pointer' 
      //               style={{ zIndex: 10 }}
      //               onClick={onOpenClick}
      //            >
      //             <div className='text-white' style={{
      //                fontFamily: "Rubik",
      //                fontSize: "16px",
      //                fontWeight: 500,
      //                lineHeight: "28.44px",
      //                zIndex:10,
      //             }}>{`+${allImages?.length - 3}`}</div>
      //          </div>
      //          )}
      //        </div>
      //      )}
      //      </>
      //    ))}
      //    </div>
      //  :
      //  <></>
      // );
      return (
        allImages?.length > 0 ?
       <>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div className="col-span-2">
          <img src={mainImages[0]?.media} alt="Main" className="w-full h-full object-cover rounded-lg" onClick={(e) =>{
            e.stopPropagation()
             onImageClick(0, allImages)
          }} />
        </div>
        <div className="grid grid-cols-1 gap-4">
          {mainImages[1]?.media && (
            <img src={mainImages[1]?.media} alt="Thumbnail 1" className="w-full h-full object-cover rounded-lg" onClick={(e) =>{
              e.stopPropagation()
              onImageClick(1, allImages)
           }} />
          )}
          {mainImages[2]?.media && (
            <div className="relative">
            <img src={mainImages[2]?.media} alt="Thumbnail 2" className="w-full h-full object-cover rounded-lg" onClick={(e) =>{
               e.stopPropagation()
             onImageClick(2, allImages)
          }}/>
            {extraImages.length > 0 && !openAllImages && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-lg cursor-pointer" onClick={onOpenClick}>
                <span className="text-white text-2xl">+{extraImages.length}</span>
              </div>
            )}
          </div>
          )}
        </div>
      </div>
      {extraImages.length > 0 && openAllImages && (
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
          {extraImages.map((image, index) => (
            <img key={index} src={image.media} alt={`Extra ${index + 1}`} className="w-full h-full object-cover rounded-lg" onClick={(e) =>{
              e.stopPropagation()
              onImageClick(index + 3, allImages)
           }} />
          ))}
        </div>
      )}
       </>
      :
      <></>
     );
};

export default ImageLayout;