import React, { useState } from "react";
import { navBar } from "../../../Config/Constants";
import useStore from "../../../store/useStore";
import MapPageNavBar from "../../shared-component/mapview-components/MapPageNavBar";
import Drawer from "../../shared-component/drawer/Drawer";
import { Box, Collapse, List, ListItemButton, ListItemText, Paper } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Dashboard from "../dashboard/Dashboard";
import MapPageLayout from "../../shared-component/mapview-components/MapPagelayout";
const drawerWidth = 300;


function MapPage({template}){
    const { name,updateName } = useStore((state) => state)
    const sidenav = [
        {id:'1',title:'Level 1', path:'/'},
    ]
    const [open, setOpen] = useState(true);
    const [images, setImages] = useState([]);

  const handleClick = () => {
    setOpen(!open);
  };
    return(
        <>
            <div style={{height:navBar.height, position:'relative'}}>
                <MapPageNavBar />
            </div>
            <Box className='flex'>
                <Box drawerWidth={drawerWidth} sx={{position:'fixed', left: 0, top:'67px', width:'300px', height:'100vh', background:'#fff', zIndex:'1111111111', border:'1px solid #e5e7eb'}}>
                {images?.map((e,i)=>{
                    return(
                <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                // subheader={
                //     <ListSubheader component="div" id="nested-list-subheader">
                //     Nested List Items
                //     </ListSubheader>
                // }
                >
                    

                   
                    <ListItemButton onClick={handleClick}>
                     <ListItemText primary={e.name} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                     <Collapse in={open} timeout="auto" unmountOnExit>
                        {e.hotspot.map((hotspot,i)=>{
                            return(
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 4 }}>
                                    {/* <ListItemIcon>
                                    <StarBorder />
                                    </ListItemIcon> */}
                                    <ListItemText primary={hotspot.name} />
                                </ListItemButton>
                            </List>
                            )
                        })}
                        {e.wires.map((wire,index)=>{
                            return(
                                <List component="div" disablePadding>
                                <ListItemButton>
                                    {/* <ListItemIcon>
                                    <StarBorder />
                                    </ListItemIcon> */}
                                    <ListItemText primary={wire.wire} />
                                </ListItemButton>
                            </List>
                            )
                        })}
                    
                </Collapse>
                    </List>
                    )
                     })}
                </Box>
                
                <Box sx={{width:'100%',position:'fixed', left: '300px', top:'67px', height:'100vh', background:'#fff', }}>
                    <MapPageLayout 
                        setImages={setImages}
                        images={images}
                        template={template}
                    />
                </Box>
            </Box>

        </>
    )
}
export default MapPage;