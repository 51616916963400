import React, { useEffect } from 'react';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import FormManager from '../../../shared-component/formrelated-component/FormManager';
import { useState } from 'react';

import { useSnackbar } from 'notistack';

import { useParams } from 'react-router-dom';
import axios from 'axios';
import { TokenStorage } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';

function MapFormModal({ title, open, onClose, successCallback, selectedData }) {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { exitFromPlatform } = useExit();

  const [loading, setLoading] = useState(false);

  let defaultValues = {
    name: '',
    image: '',
  };

  console.log('llllllllll', selectedData);

  useEffect(() => {
    if (selectedData !== null) {
      defaultValues = {
        name: selectedData?.name,
        image: selectedData?.image,
      };
    }
  }, [selectedData]);

  async function handelAddMap(e) {
    if (e?.name === '') {
      enqueueSnackbar('Please add Map name', { variant: 'error' });
    } else if (e?.image === '') {
      enqueueSnackbar('Please add Image', { variant: 'error' });
    } else {
      setLoading(true);
      const formData = new FormData();
      if (e?.image?.name) {
        formData.append('name', e.name);
        formData.append('image', e.image);
        formData.append('property', `${id}`);
      } else {
        formData.append('name', e.name);
        formData.append('property', `${id}`);
      }
      console.log('from map data', e);

      let apiUrl;
      if (selectedData === null) {
        apiUrl = `${process.env.REACT_APP_BASE_URL}property/mapplan/`;
      } else {
        apiUrl = `${process.env.REACT_APP_BASE_URL}property/mapplan/${selectedData?.id}/`;
      }

      axios({
        method:
          selectedData === null ? 'post' : e?.image.name ? 'put' : 'patch',
        url: apiUrl,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${TokenStorage.getToken()}`,
        },
      })
        .then((res) => {
          if (selectedData === null) {
            enqueueSnackbar('Map Added.', { variant: 'success' });
          } else {
            enqueueSnackbar('Map Updated.', { variant: 'success' });
          }
          setLoading(false);
          successCallback();
          onClose();
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (
              returnedError === 'Given token not valid for any token type' ||
              returnedError === 'Request failed with status code 401'
            ) {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
            }
          }
          console.log(error);
        });
    }
  }

  const fieldMap = [
    {
      name: 'name',
      label: 'Map Name',
      placeholder: 'Name',
      type: 'text',
      fieldProps: {
        required: true,
      },
    },
    {
      name: 'image',
      label: '',
      placeholder: '',
      type: 'single_image',
    },
  ];

  return (
    <>
      <FormModal title={title} open={open} onClose={onClose}>
        <div className="mt-4">
          <FormManager
            btntitle="upload plan"
            defaultValues={defaultValues}
            fieldMap={fieldMap}
            primaryBtnTitle={selectedData === null ? 'Add' : 'Save'}
            onCancel={onClose}
            formSubmit={handelAddMap}
            loading={loading}
            data={selectedData !== null ? selectedData : {}}
          />
        </div>
      </FormModal>
    </>
  );
}
export default MapFormModal;
