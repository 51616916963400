import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorBtn from '../button-component/ErrorBtn';
import SecondaryBtn from '../button-component/SecondaryBtn';

export default function RemovePopup({
  open,
  close,
  onSubmit=()=>{},
  description,
  isSecondary=false,
  removeText,
  loading = false,
  isNotRemove = false,
  component,
  isAdditionalComponent = false,
  cancelText = "Cancel"
}) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="!min-h-52"
      >
         
        <DialogContent className='flex justify-between !min-h-28'>
          <DialogContentText id="alert-dialog-description" className='!text-[#353349]'>
          {description}
          {isAdditionalComponent && (
            component
          )}
          </DialogContentText>
          <span style={{padding:'0px 16px 16px',cursor:'pointer'}} onClick={close}>&#10005;</span>
        </DialogContent>
        <DialogActions>
          {isSecondary &&(
            <SecondaryBtn
              title={cancelText}
              onClick={close} 
            />
          )}

        {!isNotRemove && (
          <ErrorBtn 
          title={removeText || 'Remove'} 
          onClick={onSubmit}
          disabled={loading}
          style={{
            padding:'5px 14px',
            boxShadow:'none'
          }}
          
           />
        )}
        </DialogActions>
      </Dialog>
    </div>
  );
}