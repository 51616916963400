import React, { useState } from 'react';
import { BASE_URI } from '../../../../constant/AuthConstant';

const ImageGridLayout = ({
    allImages,
    onImageClick
}) =>{
    const [openAllImages, setOpenAllImages] = useState(false)

    const onOpenClick = () =>{
      setOpenAllImages(true)
    }

    const mainImages = allImages.slice(0, 4);
    const extraImages = allImages.slice(4);
   console.log('from all images', allImages)
    return(
        <div className="grid grid-cols-2 gap-2 w-full max-h-80 overflow-y-scroll">
        {mainImages?.map((e, i) =>{
            return (
                <div className='relative'>
                    {e?.filetype === 'video' ? 
                    <video className="rounded-lg cursor-pointer" src={e?.media?.includes('http') ? `${e?.media}` : `${BASE_URI}${e?.media}`} style={{
                        width: '100%',
                        height:'150px',
                        objectFit: 'cover'
                    }} onClick={() =>{
                        onImageClick(i)
                    }} />
                    :
                    <img className="rounded-lg cursor-pointer" src={e?.media?.includes('http') ? `${e?.media}` : `${BASE_URI}${e?.media}`} style={{
                        width: '100%',
                        height:'150px',
                        objectFit: 'cover'
                    }} onClick={() =>{
                        onImageClick(i)
                    }} />}
                   {!openAllImages && extraImages?.length > 0 && i === 3  && (
                     <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-lg cursor-pointer" onClick={onOpenClick}>
                         <span className="text-white text-2xl">+{extraImages.length}</span>
                     </div>
                   )}
                </div>
            )
        })}
        {extraImages.length > 0 && openAllImages && (
          extraImages.map((e, index) => (
            <>
             {e?.filetype === 'video' ? 
             <video 
             key={index} 
             src={e?.media?.includes('http') ? `${e?.media}` : `${BASE_URI}${e?.media}`}
             alt={`Extra ${index + 1}`} 
             className="rounded-lg cursor-pointer"
            style={{
                width: '100%',
                height:'150px',
                objectFit: 'cover'
            }}
            onClick={() =>{
                onImageClick(index + 4)
            }}
             />
            :
            <img 
             key={index} 
             src={e?.media?.includes('http') ? `${e?.media}` : `${BASE_URI}${e?.media}`}
             alt={`Extra ${index + 1}`} 
             className="rounded-lg cursor-pointer"
            style={{
                width: '100%',
                height:'150px',
                objectFit: 'cover'
            }}
            onClick={() =>{
                onImageClick(index + 4)
            }}
             />}
            </>
          ))
      )}
    </div>
    )
}

export default ImageGridLayout