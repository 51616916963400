import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Permissions({
  children,
  children2,
  accessuser=false,
  permission
}) {
  //   const permissions = TokenStorage?.getProfile()?.data?.user_permissions;
  // const permissions = localStorage.getItem("profiledetails");
  const navigate = useNavigate();
  const permissions = accessuser;
  // const data = permissions?.find(
  //   (val) => val.permissions_code === permission_name
  // );
  // function convertPermissions(permission_level) {
  //   if (permission_level === "yes") {
  //     return 2;
  //   }
  //   if (permission_level === "no") {
  //     return 1;
  //   }
  //   return 0;
  // }
  // if (
  //   convertPermissions(permission_level) <=
  //   convertPermissions(data?.permission_level)
  // ) {
  //   return grid ? (
  //     <Grid item xs={12} md={6} xl={4} data-testid={dataTestId}>
  //       {children}
  //     </Grid>
  //   ) : tmInvite ? (
  //     <Grid item xs={18} md={10} xl={8} data-testid={dataTestId}>
  //       {children}
  //     </Grid>
  //   ) : (
  //     <></>
  //   );
  // }

  if( permission === 'true' ) {
    return(
      children
    )
  }else{
    return(
      navigate('/')
    )
  }
}
export default Permissions;
