import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import {
  BoxEffects,
  headerButton,
  grids,
} from '../../../../../../../Config/Constants';
import PrimaryBtn from '../../../../../../shared-component/button-component/PrimaryBtn';
import BreadCrumbWithActions from '../../../../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import GridComponent from '../../../../../../shared-component/grid-component/GridComponent';
import DetailsCard from '../../../../../../shared-component/card-component/DetailsCard';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import MainMenu from './components/MainMenu';

import { enqueueSnackbar } from 'notistack';
import { apiService } from '../../../../../../../constant/AuthConstant';
import returnError from '../../../../../../../utils/ExportError';
import useExit from '../../../../../../../utils/useExit';
import {
  SecondaryButton,
  ErrorButton,
  PrimaryButton,
} from '@assystant/mui-buttons';
import ActionButton from '../../../../../../shared-component/button-component/ActionButton';
import SecondaryBtn from '../../../../../../shared-component/button-component/SecondaryBtn';

const WebsiteMenuDetails = () => {
  const navigate = useNavigate();
  const { exitFromPlatform } = useExit();
  const { webId, menuId } = useParams();
  const [menuName, setMenuName] = useState('');
  const [menuLocation, setMenuLocation] = useState('');
  const [menuDetails, setMenuDetails] = useState([
    {
      id: '1',
      menu_name: 'Add Menu Name',
      dragId: '1',
      linked_url: '',
      linked_page:'',
      selected_url:'',
      menu_items: [],
    },
  ]);
  localStorage.setItem('website-tabs', '3')
  const [submitLoading, setSubmitLoading] = useState(false);
  const [details, setDetails] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menus, setMenus] = useState([]);
  const [allPagesUrls, setAllPagesUrls] = useState([])

  const webString = localStorage.getItem('website');

  const arr = [
    { title: 'Websites', url: '/websites' },
    { title: JSON.parse(webString), url: `/websites/${webId}` },
    { title: 'Menus', url: `/websites/${webId}` },
    { title: menuId === "add-menu" ? "Add Menu" : `${menuId}`, url: '/' },
  ];
  const [breadcrumb, setBreadCrumb] = useState(arr);

  const handleMenuName = (e) => {
    setMenuName(e.target.value);
  };

  const handleMenuLocation = (e) => {
    setMenuLocation(e.target.value);
  };

  const onNewMenuItem = () => {
    let cloned = [
      ...menuDetails,
      {
        id: `${menuDetails?.length + 1}`,
        dragId: `${menuDetails?.length + 1}`,
        menu_name: '',
        linked_url: '',
        selected_url:'',
        linked_page: '',
        menu_items: [],
      },
    ];

    setMenuDetails(cloned);
  };

  const fetchPagesUrls = () =>{
    apiService.get(`website/pages/choices/?website=${webId}`).then((res) =>{
      if(res?.data){
         setAllPagesUrls(res?.data)
      }
    })
    .catch((error) => {
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setLoading(false);
        }
      }
    });
  }


  const getMenus = () =>{
    apiService.get(`website/menu/?website=${webId}`).then((res) =>{
        if(res?.data?.length){
            setMenus(res?.data)
        }
        fetchPagesUrls()
        setLoading(false)
    }).catch((error) =>{
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false)
          }
        }
      })
}

const updateMenuItem = (item) => {
    let cloned = {...item}
    let selected_url = {}
    if(cloned?.linked_page?.id){
      selected_url = cloned.linked_page;
      cloned.linked_page = cloned.linked_page?.id
    }
    else{
      selected_url = {
        id: Math.ceil(Math.random() * 898989),
        page_title: cloned?.linked_url,
        url: cloned?.linked_url,
      };
      cloned.linked_page = ""
    }
    if (cloned.menu_items && cloned.menu_items.length > 0) {
      cloned.menu_items = cloned.menu_items.map((k) =>{
          return updateMenuItem(k)
      });
    }

    return {...cloned, selected_url: selected_url};
}

const addSelectedUrl = (menu) => {
  let clone = {...menu}
  clone.menu_items = clone.menu_items.map((e) =>{
     const updated = updateMenuItem(e)
     return {
      ...updated,
      dragId: e?.id?.toString()
     }
  });
  return clone.menu_items
}

  const getPageDetails = () =>{
    setLoading(true)
    apiService.get(`website/menu/${menuId}`).then((res) =>{
      if(res?.data){
        setMenuName(res?.data?.menu_title)
        setMenuLocation(res?.data?.menu_location)
        const newMenuItems = addSelectedUrl(res?.data)
        console.log('from new ......', newMenuItems)
        setMenuDetails(newMenuItems)
      }
      setDetails(res?.data)
      getMenus()
     }).catch((error) =>{
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setLoading(false)
        }
      }
    })
  }

  useEffect(() =>{
   if(menuId !== 'add-menu'){
       getPageDetails()
   }else{
    getMenus()
    fetchPagesUrls()
   }
  }, [])

  const submitMenus = () => {
    if (menuName === '') {
      enqueueSnackbar('Please add Menu name', { variant: 'error' });
    } else if (menuLocation === '') {
      enqueueSnackbar('Please add Menu location', { variant: 'error' });
    } else if (menuDetails?.length === 0) {
      enqueueSnackbar('Please add Menu name', { variant: 'error' });
    } else {
       const checkHeader = [...menus].some((e) =>{
        return e?.menu_location.toLowerCase() === 'header'
       })
       const checkFooter = [...menus].some((e) =>{
        return e?.menu_location.toLowerCase() === 'footer'
       })
       if(checkHeader === true && menuLocation?.toLowerCase() === 'header' && menuId === 'add-menu'){
        enqueueSnackbar('Header Location is already in the list, Please add different location.', { variant: 'error' });
       }
       else if(checkFooter === true && menuLocation?.toLowerCase() === 'footer' && menuId === 'add-menu'){
        enqueueSnackbar('Footer Location is already in the list, Please add different location.', { variant: 'error' });
       }
       else{
        setSubmitLoading(true);
        let service;
        let apiUrl;
  
        if (menuId === 'add-menu') {
          service = apiService.post;
          apiUrl = `website/menu/`;
        } else {
          service = apiService.put;
          apiUrl = `website/menu/${details?.id}/`;
        }
  
        const payload = {
          menu_title: menuName,
          menu_location: menuLocation,
          menu_items: menuDetails,
          website: webId
        };
  
        service(apiUrl, payload)
          .then((res) => {
            if (res?.data) {
              if (menuId === 'add-menu') {
                enqueueSnackbar('Added', { variant: 'success' });
              } else {
                enqueueSnackbar('Updated', { variant: 'success' });
              }
              setSubmitLoading(false);
              navigate(`/websites/${webId}`);
            }
          })
          .catch((error) => {
            if (error.response?.data) {
              const returnedError = returnError(error.response?.data);
              if (
                returnedError === 'Given token not valid for any token type' ||
                returnedError === 'Request failed with status code 401'
              ) {
                exitFromPlatform();
              } else {
                enqueueSnackbar(returnedError, { variant: 'error' });
                setSubmitLoading(false);
              }
            }
          });
       }
    }
  };

  const navigateToMenu = () => {
    navigate(`/websites/${webId}`);
  };

  const removeMenu = () =>{
    setDeleting(true)
    apiService.delete(`website/menu/${menuId}/?website=${webId}`).then((res) =>{
       if(res?.status === 204){
        setDeleting(false)
        enqueueSnackbar('Deleted', { variant: 'success' })
        navigate(`/websites/${webId}`);
       }
    }).catch((error) =>{
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setDeleting(false)
        }
      }
    })
  }

  return (
    <div>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '0px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={breadcrumb}>
          <div className="flex justify-between items-center">
            <div
              style={{
                marginLeft: '20px',
              }}
            >
              <div className="relative">
                <SecondaryBtn
                  title="Cancel"
                  style={{
                    boxShadow: 'none',
                    fontWeight: 300,
                  }}
                  onClick={navigateToMenu}
                />
                {menuId !== 'add-menu' && (
                  <ActionButton
                    title="Remove"
                    disabled={deleting}
                    style={{
                      boxShadow: 'none',
                      fontWeight: 300,
                      background: '#FF5C5C',
                      color: '#ffffff'
                    }}
                    onClick={removeMenu}
                  />
                )}
                <PrimaryBtn
                  className="!mx-0"
                  style={{
                    boxShadow: 'none',
                    margin: '0px !important',
                    fontWeight: 300,
                  }}
                  title="Save"
                  disabled={submitLoading}
                  onClick={submitMenus}
                />
              </div>
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>

      <div className="m-6">
        <GridComponent>
          <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid}>
            <MainMenu
              menuDetails={menuDetails}
              onAddClick={onNewMenuItem}
              setMenuDetails={setMenuDetails}
              allPagesUrls={allPagesUrls}
            />
            {/* <SampleComponent /> */}
          </Grid>
          <Grid
            className="!md:w-full max-[600px]:w-full"
            item
            lg={grids.childgrid}
          >
            <DetailsCard cardHeader="General Details">
              <div className="flex flex-col">
                <TextField
                  label="Menu Name"
                  placeholder="Menu Name"
                  value={menuName}
                  defaultValue={menuName}
                  onChange={handleMenuName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
                <TextField
                  label="Menu Location"
                  placeholder="Menu Location (Header or Footer)"
                  value={menuLocation}
                  defaultValue={menuLocation}
                  onChange={handleMenuLocation}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </div>
            </DetailsCard>
          </Grid>
        </GridComponent>
      </div>
    </div>
  );
};

export default WebsiteMenuDetails;

