import React, { useEffect, useState } from 'react'
import { 
    Box,
 } from '@mui/material';
 import { BoxEffects } from '../../../../Config/Constants';
 import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import { apiService } from '../../../../constant/AuthConstant';
import formatISOString from '../../../../utils/formatISOString'
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import TrandingIcon from '../../../../asset/icons/TrandingIcon';
import DataErrorIcon from '../../../../asset/icons/DataErrorIcon';
import Loading from '../../../shared-component/Loading/Loading';
import useExit from '../../../../utils/useExit';



const UsersList = ({
    loadList,
    usersList,
    setLoadList,
    setUsersList,
    isUserListHasData,
    setOriginalUsersList,
    allProperties,
    count,
    setCount,
}) =>{

const { exitFromPlatform } = useExit()

const columns = [
    { field: 'lead_session', headerName: 'User ID',minWidth: 250, renderCell: (params) => {
      return `#${params?.row?.lead_session.substring(params?.row?.lead_session.length, params?.row?.lead_session.length - 6).toUpperCase()}`
    }},
    { 
      field: 'Name & Phone', 
      headerName: 'Name & Phone',
      minWidth: 200,
      renderCell: (params) => {
          return (
              <div className='flex flex-col'>
                  {params?.row?.lead === null ? '-' : (params?.row?.lead?.name ?(
                    <>
                    <p>{params.row?.lead?.name || '-'}</p>
                    <p>{params.row?.lead?.phone || '-'}</p>
                    </>
                  ):
                  (
                    <>
                    <p>{params.row?.name || '-'}</p>
                    <p>{params.row?.phone || '-'}</p>
                    </>
                  ))
                }
              </div>
          )
      }
  },
    { 
      field: 'email', 
      headerName: 'Email',
      minWidth: 200,
      renderCell: (params) => {
          return params?.row?.lead?.email ? params?.row?.lead?.email : params?.row?.email || '-'
      }
  },
    { 
      field: 'property', 
      headerName: 'Property Name',
      minWidth: 200,
      renderCell: (params) => {
          return params?.row?.property?.name || '-'
      }
  },
    { 
      field: 'unit', 
      headerName: 'Unit Name',
      minWidth: 200,
      renderCell: (params) => {
          return params?.row?.unit?.name || '-'
      }
  },
    { 
      field: 'modified', 
      headerName: 'Date & Time',
      minWidth: 200,
      renderCell: (params) => {
          return formatISOString(params?.row?.modified) || '-'
      }
  },
    { 
        field: 'metric', 
        headerName: 'Action Taken',
        minWidth: 200,
        renderCell: (params) => {
            return params?.row?.metric === "IMPRESSION" 
             ? "Impression" 
             : 
             (params?.row?.metric === 'INTERACTION' ? "Interaction" 
             : (params?.row?.metric === 'ENGAGEMENT' ? "Engagement" 
             : (params?.row?.metric === 'USER_SUBMITTED' ? "Form Submitted" : (
                params?.row?.metric === 'FORM_SUBMISSION' ? "Form Submitted" : params?.row?.metric
             ))))
        }
    },
  ];

    const getUsersList = (page, numberOfItems, mode) =>{
       if(mode === ''){
          setLoadList(true)
       }
        apiService.get(`analytic/log/?page=${page}&page_size=${numberOfItems}`)
        .then((res) =>{
            setCount(res?.data?.count)
            setUsersList(res?.data?.results)
            setOriginalUsersList(res?.data?.results)
            
            setLoadList(false)
        })
        .catch((error) =>{
            setLoadList(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
              }
        }})
        
    }

    useEffect(() =>{
       getUsersList(1, 10, '')
    },[])
    return(
        <Box
            sx={{
            borderColor: "divider",
          // margin: BoxEffects.margin,
            position: "relative",
            }}
            >
            {/* {analysisList.length === 0 ? <Loading/> : <DataGridTable columns={columns} rows={analysisList} />} */}
            {loadList ? 
            <Loading />
            :
            <>
             {usersList?.length === 0 ? 
              <>
              {isUserListHasData === true ? 
                    <EmptyCard 
                    icon={
                        <div className='flex justify-center'>
                            <DataErrorIcon 
                        width={40}
                        height={40}
                        />
                        </div>
                    }
                    title="No Logs found in the selected filter"
                    subtitle="Use a different filte, We'll look again."
                    />
                    :
                    <EmptyCard 
                        icon={
                        <div className='flex justify-center'>
                            <TrandingIcon 
                            width={40}
                            height={40} 
                        />
                        </div>
                        }
                        title="No Logs Available"
                        subtitle="Stay tuned if analytics found will show up here"
                   />}
              </>
             :
             <DataGridTable 
               columns={columns} 
               rows={usersList}
               count={count}
               serverCall={getUsersList}
               />
             }
            </>
            }
            </Box>
    )
}

export default UsersList;