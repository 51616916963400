import React, { useState, useEffect } from "react";
import { DataGridPro, GridPagination } from '@mui/x-data-grid-pro';
import { datagridtable } from "../../../Config/Constants";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Checkbox, NativeSelect } from "@mui/material";

import './Datagrid.css'

function DataGridTable({
    rows=[],
    count=undefined,
    columns=[],
    handleEvent=(()=>{}),
    checkboxSelection=true,
    serverCall,
    actionWidth = 150
  }){
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [page, setPage] = useState(1);
  const [newRows, setNewRows] = useState(rows);
  const [mainRows, setMainRows] = useState(rows);
  const [pageSize, setPageSize] = useState(10);


 
  const [pagination, setPagination] = useState({
    count: 0,
    from:0,
    to:pageSize
  })
  useEffect(() =>{
       setMainRows(rows)
       setNewRows(rows)
       if(count === undefined){
        setPage(1)
       }
       setPagination({...pagination, from:0, to:pageSize})
  }, [rows])
  useEffect(() =>{
   if(count !== undefined){
    setPagination({...pagination, count: count})
   }else{
    setPagination({...pagination, count: mainRows?.length})
   }
   if(count === undefined){
    const newData = mainRows?.slice(pagination?.from, pagination?.to)
    setNewRows(newData)
   }
  }, [pagination?.from, pagination?.to, pageSize, mainRows])

  const handlePageChange = (event, newPage) =>{
    console.log('from page number', newPage)
    setPage(newPage)
    const from = (newPage - 1) * pageSize;
    const to = (newPage - 1) * pageSize + pageSize;
    setPagination({...pagination, from: from, to: to})
    if(serverCall){
        serverCall(newPage, pageSize, 'from server')
    }
 }

 const onPageChange = (event) =>{
  setPageSize(Number(event.target.value))
  setPagination({...pagination, from:0, to: Number(event.target.value)})
  setPage(1)
  if(serverCall){
    serverCall(1, event.target.value, 'from server')
  }
 }

    return (
      <div className="w-full p-2" style={{border:'2px solid #E1EBFE',borderRadius:'8px'}}>
          <DataGridPro
            rows={newRows}
            columns={columns}
            pageSize={datagridtable.pagesize}
            rowsPerPageOptions={[2,6,8]}
            component="div"
            rowsPerPage={rowsPerPage}
            pinnedColumns={{right: ['actions']}}
            initialState={{
              pinnedColumns: ['actions']
            }}
            rowHeight={56}
            sx={{
              cursor:'pointer',
              minHeight:'100px', 
              width: datagridtable.width, 
              '& .MuiDataGrid-columnHeader': {
                background:'#FFFFFF'
              },
              '& .MuiDataGrid-pinnedColumnHeaders':{
                   width:`${actionWidth}px !important`
              },
              '& .MuiDataGrid-columnHeaderTitle': {
              color: '#8E8CA3',
              fontWeight: 400,
            },
            '& .MuiDataGrid-cell':{
                fontFamily:'Rubik',
                fontSize: '14px',
                fontWeight:300,
                color:'#353349'
            },
            '& .MuiDataGrid-rowCount': {
              display: 'none',
            },
            '& .MuiDataGrid-footerContainer':{
              display:'none'
            },
            
          }}
            onRowClick={handleEvent}
            disableVirtualization
            
          />
          <div className="mb-2 mt-2.5" style={{display:'flex',justifyContent:'center', position:'relative'}}>
             <Pagination
                page={page}
                count={Math.ceil(pagination?.count / pageSize)}
                onChange={handlePageChange}
              />
              <div className="flex align-center" style={{
                 position:'absolute',
                 right:20
              }}>
                  <p className="mt-1" style={{
                    fontFamily:'Rubik',
                    fontSize: '13px',
                    fontWeight: 300,
                    color: '#353349',
                    marginRight: '10px'
                  }}>Rows per page:</p>
                  <div style={{
                    borderRadius: '4px',
                    border: '1px solid #E1EBFE',
                   
                  }}>
                  <NativeSelect 
                    variant="filled"
                    defaultValue={pageSize}
                    onChange={onPageChange}
                    disableUnderline
                    sx={{
                      fontSize:'13px',
                      paddingLeft:'10px',
                    }}
                    >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </NativeSelect>
                  </div>
              </div>
          </div>
          </div>
      );
}
export default DataGridTable;

