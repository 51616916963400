import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Avatar,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  FormControl,
  Chip,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { iconsList } from '../../../../../Config/Constants';

import GridComponent from '../../../../shared-component/grid-component/GridComponent';

import { grids } from '../../../../../Config/Constants';
import DetailsCard from '../../../../shared-component/card-component/DetailsCard';
import StandardImageList from '../../../../shared-component/OverviewPage/overview-used-components/StandardImageList';
import LabelAndValue from '../../../../shared-component/OverviewPage/overview-used-components/LabelAndValue';

import Loading from '../../../../shared-component/Loading/Loading';

import EmptyCard from '../../../../shared-component/card-component/EmptyCard';

import { BASE_URI, apiService } from '../../../../../constant/AuthConstant';
import GallaryModal from '../../../../shared-component/OverviewPage/components/GallaryModal';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../../utils/useExit';
import returnError from '../../../../../utils/ExportError';
import BreadCrumbWithActions from '../../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import { BoxEffects, headerButton } from '../../../../../Config/Constants';
import IconForUpload from '../../../../../asset/icons/IconForUpload';
import { ErrorButton, PrimaryButton } from '@assystant/mui-buttons';
import PrimaryBtn from '../../../../shared-component/button-component/PrimaryBtn';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import AddUpdates from '../components/AddUpdates';
import RemovePopup from '../../../../shared-component/RemovePopup/RemovePopup';
import ImageLayout from '../components/ImageLayout';
import ImageGridLayout from '../../../../shared-component/OverviewPage/components/ImageGiridLayout';
import GallaryDetailModal from '../../../../shared-component/OverviewPage/components/GallaryDetailModal';
import UserContext from '../../../../../context/UserContext';
import ErrorBtn from '../../../../shared-component/button-component/ErrorBtn';

const UpdatesDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { exitFromPlatform } = useExit();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [updateActive, setUpdateActive] = useState(false);
  const [unitList, setUnitList] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [timelineTitle, setTimelineTitle] = useState('');
  const [openGallaryModal, setOpenGallaryModal] = useState(false);
  const [gallaryItems, setGallaryItems] = useState([]);
  const [gallaryLoading, setGallaryLoading] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  localStorage.setItem('tabValue', '7');
  const [removeModal, setRemoveModal] = useState(false);
  const [allUnits, setAllUnits] = useState([]);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [analyticTags, setAnalyticTags] = useState([]);
  const { userFromOrganization } = useContext(UserContext)

  const handleOpenDetailModal = () => {
    setOpenDetailModal(!openDetailModal);
  };

  const onClickEdit = (index) => {
    setActiveIndex(index);
    handleOpenDetailModal();
  };

  const arr1 = [{ title: 'Properties', url: '/properties' }];

  const [breadcrumb, setBreadCrumb] = useState(arr1);
  const handleRemoveModal = () => {
    setRemoveModal(!removeModal);
  };

  const handleEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleGallaryModal = () => {
    setOpenGallaryModal(!openGallaryModal);
  };

  const handleTimelineTitle = (e) => {
    setTimelineTitle(e?.target?.value);
  };

  const handleUpdateStatus = () => {
    setUpdateActive(!updateActive);
  };

  const dateFormat = (isoDateString) => {
    const date = new Date(isoDateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const retreiveUnits = (propertyId, data) => {
    apiService
      .get(`property/unit/?property=${propertyId}`)
      .then((res) => {
        if (res?.data?.length) {
          setAllUnits(res?.data);
          setLoading(false);
        } else {
          setAllUnits([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };
  console.log('from details', details);
  const retrieveTimeline = () => {
    setLoading(true);

    apiService
      .get(`property/property_updates/${id}`)
      .then((res) => {
        if (res?.data) {
          const newData = {
            ...res?.data,
            publish_date: new Date(res?.data?.publish_date).toISOString(),
            date: res?.data?.publish_date?.split('-').reverse().join('/'),
            icon: iconsList?.find((e) => {
              return e?.name === res?.data?.icon;
            }),
            images: res?.data?.images?.map((e) => {
              return {
                ...e,
                tags: typeof e?.tags === 'string' ? JSON.parse(e?.tags) : [],
              };
            }),
          };
          setTimelineTitle(newData?.title);
          setUpdateActive(newData?.active);
          setSelectedUnits(newData?.units);
          setBreadCrumb([
            ...arr1,
            {
              title: 'Updates',
              url: `/properties/${newData?.linked_property}`,
            },
            { title: newData?.title, url: '' },
          ]);
          setDetails(newData);
          retreiveUnits(newData?.linked_property, newData);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  const retrieveGallary = () => {
    setGallaryLoading(true);
    apiService
      .get(`organization/gallery/`)
      .then((res) => {
        if (res?.data?.length > 0) {
          const updateData = res?.data?.map((e) => {
            return {
              ...e,
              tags: typeof e?.tags === 'string' ? JSON.parse(e?.tags) : [],
            };
          });
          setGallaryItems(updateData);
        } else {
          setGallaryItems([]);
        }
        setOpenDetailModal(false);
        setGallaryLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setGallaryLoading(false);
          }
        }
      });
  };

  const deleteUpdate = () => {
    apiService
      .delete(`property/property_updates/${id}`)
      .then((res) => {
        if (res?.status === 204) {
          enqueueSnackbar('Update Deleted', { variant: 'success' });
          handleRemoveModal();
          navigate(`/properties/${details?.linked_property}`);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  const getAnalyticTags = () => {
    apiService
      .get(`property/prop/get_spec_choices/`)
      .then((res) => {
        console.log('from analytic tags', res?.data);
        if (res?.data?.data?.length > 0) {
          const updateOptions = res?.data?.data?.map((e) => {
            return { name: e, id: e };
          });
          setAnalyticTags(updateOptions);
        } else {
          setAnalyticTags([]);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setSubmitLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    getAnalyticTags();
    retrieveTimeline();
  }, []);

  const contactDetails = [
    {
      label: '',
      value: (
        <>
          {details?.icon ? (
            <div className="h-24 w-24 bg-[#EDECF9] flex items-center justify-center rounded-full cursor-pointer">
              {details?.icon.icon}
            </div>
          ) : (
            <IconForUpload />
          )}
        </>
      ),
    },
    { label: 'Title', value: details?.title || 'Not Provided' },
    { label: 'Date', value: details?.date || 'Not Provided' },
    { label: 'Description', value: details?.description || 'Not Provided' },
  ];
  useEffect(() => {
    console.log('from selected units', selectedUnits);
  }, [selectedUnits]);

  const saveDataOnSubmit = () => {
    apiService
      .put(`property/property_updates/${details?.id}/`, {
        ...details,
        icon: details?.icon?.name,
        publish_date: dateFormat(details?.publish_date),
        units: selectedUnits?.map((e) => {
          return e?.id;
        }),
        images: details?.images?.map((e) => {
          return e?.id;
        }),
        title: timelineTitle,
        active: updateActive,
      })
      .then((res) => {
        if (res?.data) {
          enqueueSnackbar('Updated', { variant: 'success' });
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  const onTimelineUnlinking = (id) => {
    apiService
      .put(`property/property_updates/${details?.id}/`, {
        ...details,
        icon: details?.icon?.name,
        publish_date: dateFormat(details?.publish_date),
        images: details?.images
          ?.filter((e) => {
            return e?.id !== id;
          })
          ?.map((e) => {
            return e?.id;
          }),
        units:
          details?.units?.map((e) => {
            return e?.id;
          }) || [],
      })
      .then((res) => {
        if (res?.data) {
          enqueueSnackbar('Updated', { variant: 'success' });
          retrieveTimeline();
          handleGallaryModal();
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };
  const onTimelineSubmit = (files) => {
    apiService
      .put(`property/property_updates/${details?.id}/`, {
        ...details,
        icon: details?.icon?.name,
        publish_date: dateFormat(details?.publish_date),
        images: files?.map((e) => {
          return e?.id;
        }),
        units:
          details?.units?.map((e) => {
            return e?.id;
          }) || [],
      })
      .then((res) => {
        if (res?.data) {
          enqueueSnackbar('Updated', { variant: 'success' });
          retrieveTimeline();
          handleGallaryModal();
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <Box
        className="flex items-center justify-between"
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '65px',
          backgroundColor: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={breadcrumb} />

        <div className="flex items-center">
          <ErrorBtn
            title="Remove"
            style={{
              boxShadow: 'none',
              fontWeight: 300,
            }}
            disabled={userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
            userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true}
            onClick={handleRemoveModal}
          />
          <PrimaryBtn
            title="Edit"
            style={{
              boxShadow: 'none',
              fontWeight: 300,
            }}
            disabled={userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
            userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true}
            onClick={handleEditModal}
          />
          <PrimaryBtn
            title="Save"
            style={{
              boxShadow: 'none',
              marginRight: '0px !important',
              fontWeight: 300,
            }}
            disabled={userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
            userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true}
            onClick={saveDataOnSubmit}
          />
        </div>
      </Box>
      <div className="m-6">
        {loading ? (
          <Loading />
        ) : (
          <GridComponent>
            <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid}>
              <DetailsCard
                cardHeader="Basic Details"
                style={{ paddingBottom: '12px' }}
              >
                {contactDetails.map((e, i) => {
                  return (
                    <LabelAndValue
                      label={e.label}
                      value={e.value}
                      valueStyles={{
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontWeight: 300,
                      }}
                    />
                  );
                })}
                <Box className="flex justify-between items-center">
                  <Box></Box>
                </Box>
              </DetailsCard>
            </Grid>
            <Grid item lg={grids.childgrid}>
              <DetailsCard
                cardHeader="Media"
                isAdditionalButton={userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
                userFromOrganization?.role?.name.toLowerCase() === 'developer' ? true : false}
                buttonText="Add Media"
                onButtonClick={handleGallaryModal}
              >
                {details?.images?.length > 0 ? (
                  <ImageGridLayout
                    allImages={details?.images}
                    onImageClick={userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
                    userFromOrganization?.role?.name.toLowerCase() === 'developer' ? onClickEdit : () => {}}
                  />
                ) : (
                  <EmptyCard
                    title="No Media Available"
                    subtitle={`Click "Add Media" and upload images`}
                    noBorder
                    style={{
                      height: '250px !important',
                    }}
                  />
                )}
              </DetailsCard>
              <div className="mt-4">
                <DetailsCard cardHeader="General">
                  <div className="w-full">
                    <TextField
                      fullWidth
                      label="Title"
                      defaultValue={timelineTitle}
                      placeholder="Update Title"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        handleTimelineTitle(e);
                      }}
                      disabled={userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
                      userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true}
                    />
                  </div>

                  <div className="w-full">
                    <Autocomplete
                      multiple
                      value={selectedUnits}
                      onChange={(event, newValue) => {
                        setSelectedUnits(newValue);
                      }}
                      disabled={userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
                      userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true}
                      options={allUnits}
                      popupIcon={<ExpandMore className="!fill-[#8E8CA3]" />}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Units"
                          placeholder="Units"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="flex items-center mt-2">
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={updateActive === true ? 'Active' : 'Inactive'}
                        label=""
                        role="select-status"
                        disabled={userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
                        userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true}
                        IconComponent={(props) => {
                          if (
                            props?.className?.includes('MuiSelect-iconOpen')
                          ) {
                            return (
                              <ExpandLess
                                {...props}
                                className="!fill-[#8E8CA3]"
                                sx={{
                                  pointerEvents: 'none !important',
                                  position: 'absolute !important',
                                  right: '5px !important',
                                }}
                              />
                            );
                          }
                          return (
                            <ExpandMore
                              {...props}
                              sx={{
                                pointerEvents: 'none !important',
                                position: 'absolute !important',
                                right: '5px !important',
                              }}
                              className="!fill-[#8E8CA3]"
                            />
                          );
                        }}
                        onChange={(e) => handleUpdateStatus()}
                        sx={{
                          height: '56px',
                          background: 'white !important',
                          color: updateActive === true ? '#24D315' : '#FF5C5C',
                        }}
                      >
                        <MenuItem
                          value="Active"
                          sx={{ color: '#24D315 !important' }}
                        >
                          Active
                        </MenuItem>
                        <MenuItem
                          value="Inactive"
                          sx={{ color: '#FF5C5C !important' }}
                        >
                          Inactive
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </DetailsCard>
              </div>
            </Grid>
          </GridComponent>
        )}
      </div>

      {openGallaryModal && (
        <GallaryModal
          open={openGallaryModal}
          loading={gallaryLoading}
          onClose={handleGallaryModal}
          gallaryItems={gallaryItems}
          fetchProperty={retrieveTimeline}
          retrieveGallary={retrieveGallary}
          image_list={details?.images}
          isTimeline={true}
          onTimelineSubmit={onTimelineSubmit}
          onTimelineUnlinking={onTimelineUnlinking}
        />
      )}
      {openEditModal && (
        <AddUpdates
          title="Edit"
          open={openEditModal}
          onClose={handleEditModal}
          data={details}
          onSuccess={retrieveTimeline}
        />
      )}

      {removeModal && (
        <RemovePopup
          open={removeModal}
          close={handleRemoveModal}
          description={'Are you sure, you want to remove this update ?'}
          onSubmit={deleteUpdate}
        />
      )}

      {openDetailModal && (
        <GallaryDetailModal
          isTimeline={true}
          open={openDetailModal}
          onClose={handleOpenDetailModal}
          gallaryItems={details?.images}
          activeIndex={activeIndex}
          retrieveGallary={retrieveTimeline}
          analyticTags={analyticTags}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          onTimelineUnlinking={onTimelineUnlinking}
        />
      )}
    </>
  );
};

export default UpdatesDetails;
