import React, { useState } from 'react'
import {
Dialog,
FormControl,
InputLabel,
MenuItem,
Select,
Checkbox,
Typography,
Grid,
Accordion,
AccordionDetails,
AccordionSummary,
Divider,
Paper,
Button,
TextField
} from '@mui/material'
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { set } from 'react-hook-form';


const NestedShowAccordian = ({
    i,
    Items,
    isEdit,
    handleFields,
    handleParentField,
    template,
    setIsAllFieldChecked
}) =>{
    const StyledAccordion = styled(Accordion)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        color: theme.palette.text.secondary,
      }));

      const [expanded, setExpanded] = useState(false);
   

      const handleAccordian = () =>{
        setExpanded(!expanded)
      }
      console.log('from template list', expanded)
    return (
        <Grid className='!flex !my-2' item xs={8} style={{ paddingTop: 0 }}>
                     <Paper key={i+1} sx={{boxShadow:'none', width:'90% !important'}} > 
                       <StyledAccordion expanded={expanded} sx={{boxShadow:'none', border: isEdit ? '1px solid #B8B7C5' : '2px solid #E1EBFE'}}>
                          <AccordionSummary onClick={handleAccordian} className='!mt-0 !mb-0' 
                               expandIcon={
                                  <ExpandMoreIcon 
                                    className={isEdit ? '!fill-[#353349]' : ''}
                                    onClick={handleAccordian}  
                                  />}>
                               <div className='flex items-center'>
                               <Checkbox defaultChecked={Items?.group_selected} checked={Items?.group_selected} onChange={(e) =>{
                                 e.stopPropagation()
                                  if(template){
                                    handleParentField(e, Items, template, setIsAllFieldChecked)
                                  }else{
                                    handleParentField(e, Items)
                                  }
                               }}/>
                              <Typography sx={{//styleName: Sub H1;
                                  fontFamily: 'Rubik',
                                  fontSize: '16px',
                                  fontWeight: 400,
                                  lineHeight: '28px',
                                  textAlign: 'left',
                                  color:'#353349'
                                  }}>
                              {Items.group_name || Items.name}
                              </Typography>
                               </div>
                              </AccordionSummary>
                              <Divider />
                          <AccordionDetails>
                         
                               {Items.fields?.map((f, index) =>{
                                  return (
                                      <div className='flex items-center'>
                                          <Checkbox 
                                            defaultChecked={f?.selected} 
                                            checked={f?.selected} 
                                            onClick={(e) =>{
                                                e.stopPropagation()
                                              }}
                                            onChange={(e) =>{
                                                e.stopPropagation()
                                            e.preventDefault()
                                              if(template){
                                                handleFields(Items, e, f, template, setIsAllFieldChecked)
                                              }else{
                                                handleFields(Items, e, f)
                                              }
                                          }}
                                          
                                          />
                                          <Typography sx={{//styleName: Sub H1;
                                              fontFamily: 'Rubik',
                                              fontSize: '16px',
                                              fontWeight: 400,
                                              lineHeight: '28px',
                                              textAlign: 'left',
                                              color:'#353349'
                                              }}>
                                          {f.name}
                                          </Typography>
                                      </div>
                                  )
                               })}
                              
                          </AccordionDetails>
                  </StyledAccordion>
               </Paper>
               </Grid>
    )
}

export default NestedShowAccordian