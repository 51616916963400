import React, { useEffect, useState } from 'react';
import { BASE_URI, apiService } from '../../../../constant/AuthConstant';
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import Loading from '../../../shared-component/Loading/Loading';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import { useSnackbar } from 'notistack';
import AddDocument from '../modals/AddDocument';
import formatISOString from '../../../../utils/formatISOString';
import DeleteIcon from '../../../../asset/icons/DeleteIocn';
import RemovePopup from '../../../shared-component/RemovePopup/RemovePopup';
import { saveAs } from 'file-saver';

const tableData = [
  {
    id: 1,
    name: 'Land Agreement',
    created: '30th April 2023 10:30:07',
    responses: '1',
    linkedForms: ['From Name 1'],
    attachments: 'Land agreement...',
    action: 'delete',
    property: 'Park',
  },
  {
    id: 2,
    name: 'Land Agreement',
    created: '30th April 2023 10:30:07',
    responses: '-',
    linkedForms: ['From Name 1'],
    attachments: 'Land agreement...',
    action: 'delete',
    property: 'Park',
  },
  {
    id: 3,
    name: 'Buyer Agreement',
    created: '30th April 2023 10:30:07',
    responses: '5',
    linkedForms: ['From Name 1'],
    attachments: 'Land agreement...',
    action: 'delete',
    property: 'Park',
  },
];

const DocumentsTab = ({ openAddModal, handleAddModal }) => {
  const { exitFromPlatform } = useExit();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(false);


  const [confirmStatus, setConfirmStatus] = useState(false);

  const handleConfirmStatus = () => {
    setConfirmStatus(!confirmStatus);
  };

  const downloadFile = (link, fileName) => {
    if(!link.includes('https')){
     const newString =  link.slice(0, 4) + 's' + link.slice(4);
     saveAs(newString, fileName)
     return
    }
    saveAs(link, fileName)
  }

  const getDocumentsList = () => {
    setLoading(true);
    apiService
      .get(`documents/docs/`)
      .then((response) => {
        if (response?.data) {
          setDocumentList(response?.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  const removeDocument = () =>{
    apiService.delete(`documents/docs/${selectedDoc?.id}/`).then((res) =>{
      if(res?.status === 204){
        enqueueSnackbar('Deleted', {variant: 'success'})
        getDocumentsList()
        handleConfirmStatus()
      }
    }).catch((error) => {
      setLoading(false);
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
        }
      }
    });
  }

  useEffect(() => {
    getDocumentsList();
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      renderCell: (params) => {
        return params?.row?.document?.title;
      },
    },
    {
      field: 'created',
      headerName: 'Upload Date',
      minWidth: 250,
      renderCell: (params) => {
        return formatISOString(params?.row?.created);
      },
    },
    {
      field: 'responses',
      headerName: 'Responses',
      minWidth: 200,
      renderCell: (params) => {
        return params?.row?.responses || '-';
      },
    },
    {
      field: 'linkedForms',
      headerName: 'Linked Forms',
      minWidth: 200,
      renderCell: (params) => {
        if (params?.row?.linked_forms?.length) {
          const links = params.row.linked_forms
            ?.map((e) => {
              return e?.name || e;
            })
            ?.join(',');
          return (
            <div className="text-[#004FE8] font-normal">{links || '-'}</div>
          );
        } else {
          return <div className="text-[#004FE8] font-normal">{'-'}</div>;
        }
      },
    },
    {
      field: 'attachments',
      headerName: 'Attachments',
      minWidth: 300,
      renderCell: (params) => {
        const fileString = params?.row?.document?.file.substring(
          params?.row?.document?.file.lastIndexOf('/') + 1,
          params?.row?.document?.file?.length,
        );
        return (
          <div className="text-[#004FE8] font-normal" onClick={() => downloadFile(params?.row?.document?.file, fileString)}>{fileString || '-'}</div>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      minWidth: 50,
      align:'center',
      renderCell: (params) => (
        <div className="cursor-pointer" onClick={(e)=>{
          e.stopPropagation()
          e.preventDefault()
          setSelectedDoc(params.row)
          handleConfirmStatus()
         }}>
                <DeleteIcon color="error" className="!fill-[#FF5C5C]"  />
            </div>
          ),
    },
  ];
  return loading ? (
    <Loading />
  ) : (
    <>
      {documentList?.length > 0 ? (
        <DataGridTable columns={columns} rows={documentList} actionWidth={100} />
      ) : (
        <EmptyCard
          title="No Documents Available"
          subtitle="Click “Add Documents” to add a document"
        />
      )}

      {openAddModal && (
        <AddDocument
          title="Add"
          open={openAddModal}
          onClose={handleAddModal}
          successCallback={getDocumentsList}
        />
      )}

      {confirmStatus && (
        <RemovePopup
          open={confirmStatus}
          close={handleConfirmStatus}
          description="Are you sure you want to remove this document?"
          isSecondary={handleConfirmStatus}
          onSubmit={removeDocument}
        />
      )}
    </>
  );
};

export default DocumentsTab;
