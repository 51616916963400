import React from 'react';
import { TextField } from '@mui/material';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import FilterFields from '../../leads/Fields/FilterFields';
import { SecondaryButton, PrimaryButton } from '@assystant/mui-buttons';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';


const FilterConversion = ({
    title,
    open,
    onClose,
    affiliate,
    setAffiliate,
    type,
    setType,
    onSubmit,
    affiliateList
}) =>{

    const onReset = () =>{
        setAffiliate({name: '', id: ''})
        setType({name: '', id: ''})
    }
    return(
        <FormModal
           open={open}
           title={title}
           onClose={onClose}
        >
          <div className='m-8'>
          <FilterFields
               value={affiliate}
               setValue={setAffiliate}
               label="Affiliate"
               defaultOptions={affiliateList}
              />
             <FilterFields
               value={type}
               setValue={setType}
               label="Conversion Type"
               defaultOptions={[
                {'title': "Lead", 'id': 'lead'},
                {'title': "Booking", 'id': 'booking'},
               ]}
               titleKey="title"
              />
            
              <div className="w-full flex justify-end pt-4">
                    <SecondaryBtn style={{ backgroundColor: '#EDECF9', boxShadow: 'none', 
                                fontWeight:300,
                                padding:'6px 16px' }} onClick={onClose} title='Cancel' />
                    <SecondaryBtn style={{ backgroundColor: '#EDECF9', color:'#FF5C5C',  boxShadow: 'none', 
                                fontWeight:300,
                                padding:'6px 16px' }} onClick={onReset} title='Reset' />
                    <PrimaryBtn title='Filter' 
                    style={{
                        marginRight: '0px !important',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px'
                    }} onClick={() =>{
                        onSubmit({
                            affiliate: affiliate?.id ? affiliate?.id : '',
                            conversion_type: type?.id ? type?.id : '',
                        })
                    }}/>
                </div>
          </div>
        </FormModal>
    )
}

export default FilterConversion