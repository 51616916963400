// ----------------- Documentation ---------------------------
// this component used to handel analysis integration and code editor that code editor hander the code for user input


import React from "react";
import LabelAndValue from "../../../shared-component/OverviewPage/overview-used-components/LabelAndValue";
import { BoxEffects } from "../../../../Config/Constants";
import CodeEditor from "./integrationeditor/CodeEditor";
import { Box } from "@mui/material";

function AnalysisIntegration() {
  const propArr1 = [
    {
      label: "How to use",
      value:
        "This is the Global Site Tag (gtag.js) tracking code for this property. Copy and paste this code as the first item into the <HEAD> of every webpage you want to track. if you already have a Global site Tag in your page, simply add the config live from the snippet below to your existing Global Site Tag.",
    },
  ];
  return (
    <>
      {propArr1.map((e, i) => {
        return <LabelAndValue label={e.label} value={e.value} />;
      })}
      <Box sx={{ border: 1, borderColor: 'divider',margin:BoxEffects.padding, position:'relative',paddingLeft:'10px',paddingRight:'10px' }} >
        <CodeEditor />
      </Box>
    </>
  );
}
export default AnalysisIntegration;
