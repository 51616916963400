// ----------------- Documentation ---------------------------
// this component used for add and edit the data from add channel partner list
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup
// edit                    boolead             used for the edit form
// edittitle               string              to maintain the edit form title of the popup
// data                    array               to show the default form value in the edit form


import React from "react";
import FormModal from "../../shared-component/formmodal-component/FormModal";
import Form from "../../shared-component/formrelated-component/FormManager";



function AddChannelPartner({
    title,
    open,
    onClose,
    successCallback,
    setOpenChannelPartner
}){
    const defaultValues = {
        partner_name:'',
        legal_name:'',
        phone:'',
        email:'',
        location:'',
    }
    const fieldMap = [
         {
            name:'partner_name',
            label:'Partner Name',
            placeholder:'Partner Name',
            type:'text',
           
        },
        {
            name:'legal_name',
            label:'Legal Name',
            placeholder:'Legal Name',
            type:'text',
           
        },
        {
            name:'phone',
            label:'Phone',
            placeholder:'Phone',
            type:'text',
           
        },
        {
            name:'email',
            label:'Email ',
            placeholder:'Email',
            type:'email',
           
        },
        {
            name:'location',
            label:'Location ',
            placeholder:'Location',
            type:'select',
            fieldProps:{
                defaultOptions: [
                    {title: 'Odisha', id:''},
                ]
            }
        },
    ]
    
    const handelformsubmit = async (e) => {
       
    }
    return(
        <>
        <FormModal title={title} open={open} onClose={onClose}>
            <Form
             defaultValues={defaultValues}
             fieldMap={fieldMap}
             formSubmit={handelformsubmit}
            />
        </FormModal>
        </>
    )
}
export default AddChannelPartner;