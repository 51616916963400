// ----------------- Documentation ---------------------------
// this component used for show the data from key value of the integration page



import React from "react";
import { Box } from "@mui/material";
import LabelAndValue from "../../../shared-component/OverviewPage/overview-used-components/LabelAndValue";
import { BoxEffects } from "../../../../Config/Constants";
import IntegrationContent from "./IntegrationContent";

function AdsIntegration() {
  const propArr1 = [
    {
      label: "How to use",
      value:
        "Launch Google Shopping ads and get your products found online easily",
    },
  ];
  const propArr2 = [
    {
      label: "Campaign Dashboard",
      value:
        "Open your dashboard to review your campaign’s performance.",
    },
    {
        label: "Create a New Google Shopping Campaign",
        value:
          "Build a campaign in a few minutes, and sell to customers as they search for your products on Google.",
      },
      {
        label: "Manage campaigns",
        value:
          "Make changes to your active campaigns, purchase one you built, or reinstate your cancelled campaigns.",
      },
  ];
  return (
    <>
      {propArr1.map((e, i) => {
        return <LabelAndValue label={e.label} value={e.value} />;
      })}
      <Box sx={{ border: 1, borderColor: 'divider',margin:BoxEffects.padding, position:'relative',paddingLeft:'10px',paddingRight:'10px' }} >
        {propArr2.map((e, i) => {
            return <IntegrationContent label={e.label} value={e.value} />;
        })}
      </Box>
    </>
  );
}
export default AdsIntegration;
