// ----------------- Documentation ---------------------------
// this component used for add and edit the data from inventory overview
// this form used the firebase database to store the data

import { Box } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';

import { BoxEffects, headerButton } from '../../../../Config/Constants';

import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../shared-component/Loading/Loading';

import { apiService } from '../../../../constant/AuthConstant';

import { useMediaQuery } from 'react-responsive';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import { useSnackbar } from 'notistack';
import { neighboursIcons } from '../../../../Config/Constants';
import EditNeighbourDetails from '../Modal/EditNeighbourDetails';
import ActionButton from '../../../shared-component/button-component/ActionButton';
import BookingDetailComponent from '../../ParentComponents/BookingDetailComponent';
import AddBooking from './AddBooking';
import UserContext from '../../../../context/UserContext';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';

function BookingDetails() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { organization } = useContext(UserContext)
  const [booking, setBooking] = useState({});
  const [neighbourIcon, setNeighbourIcon] = useState(neighboursIcons[0]);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [details, setDetails] = useState([]);
  const [sectionDetails, setSectionDetails] = useState([]);
  const [independentFields, setIndependentFields] = useState([]);
  const [getFiles, setGetFiles] = useState([]);
  const [sideDrawer, setSideDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const handleSideDrawer = () => {
    setSideDrawer(!sideDrawer);
  };

  const updateKeyName = (label) => {
    return label
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const { enqueueSnackbar } = useSnackbar();

  const isMidScreen = useMediaQuery({ query: '(max-width: 1199px)' });
  const { exitFromPlatform } = useExit();

  const arr1 = [
    { title: 'Bookings', url: '/bookings' },
    { title: `Booking ID ${id}`, url: `/` },
  ];

  const getBooking = () => {
    if (id) {
      setLoading(true);
      apiService
        .get(`booking/entries/${id}/`)
        .then((res) => {
          if (res?.data) {
            if (res?.data?.profile_icon) {
              const findIcon = neighboursIcons?.find((e) => {
                return e?.name?.toLowerCase() === res?.data?.profile_icon;
              });
              setNeighbourIcon(findIcon);
              setBooking({ ...res?.data, profile_icon: findIcon });
            } else {
              setBooking({ ...res?.data });
            }
            if (res?.data?.form?.id) {
              const newData = {
                ...res?.data,
                submissions: JSON.parse(res?.data?.submission),
              };

              if (newData?.submissions?.sections?.length > 0) {
                const update = newData?.submissions?.sections?.map((e) => {
                  const nn = { ...e };
                  delete nn?.source;
                  return {
                    ...nn,
                    fields: e?.fields?.filter((f) => {
                      return f?.name !== 'files';
                    }),
                    files: e?.fields?.find((g) => {
                      if (g.name === 'files') {
                        return g;
                      }
                    })?.value,
                  };
                });

                setSectionDetails(update);
                setDetails(newData);
              } else {
                const formData = { ...newData?.submissions };
                delete formData?.formDetails;
                delete formData?.sections;
                delete formData?.files;
                delete formData?.unit;
                delete formData?.property;
                delete formData?.source;

                const newnData = Object.entries(formData)?.map((m) => {
                  return {
                    name: updateKeyName(m[0]),
                    value: m[1],
                  };
                });
                setGetFiles([...newData?.submissions?.files]);
                setIndependentFields(newnData);
                setDetails(newData);
              }
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (returnedError === 'Given token not valid for any token type') {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
            }
          }
        });
    }
  };
  const updateBooking = (data) => {
    console.log('from iiiidd', data);
    if (typeof data?.property === 'object') {
      data.property = data?.property?.id;
    }
    if (typeof data?.unit === 'object') {
      data.unit = data?.unit?.id;
    }
    if (typeof data?.employee === 'object') {
      data.employee = data?.employee?.id;
    }
    let newData = {
      ...data,
      profile_icon: data?.profile_icon?.name,
    };

    if (data?.form?.id) {
      newData['form'] = data?.form?.id;
    } else {
      delete newData?.form;
    }
    apiService
      .put(`booking/entries/${id}/`, newData)
      .then((res) => {
        getBooking();
        enqueueSnackbar('Booking Updated', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Failed to update', { variant: 'error' });
      });
  };
  useEffect(getBooking, [id]);

  const updateWithResponse = (response) => {
    if (response?.profile_icon) {
      const findIcon = neighboursIcons?.find((e) => {
        return e?.name?.toLowerCase() === response?.profile_icon;
      });
      setNeighbourIcon(findIcon);
      setBooking({
        ...booking,
        profile_icon: findIcon,
        profile: response?.profile,
      });
    } else {
      setBooking({ ...booking });
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Box
            className="!flex"
            sx={{
              borderBottom: BoxEffects.borderBottom,
              borderColor: 'secondary.main',
              padding: BoxEffects.padding,
              marginBottom: BoxEffects.marginButton,
              paddingLeft: BoxEffects.margin,
              paddingRight: headerButton.marginRigt,
            }}
          >
            <BreadCrumbWithActions propArr={arr1} />
            <div className="flex justify-between items-center">
              <div
                style={{
                  marginLeft: '20px',
                }}
              >
                <PrimaryBtn
                  className="!mx-0"
                  style={{
                    boxShadow: 'none',
                    margin: '0px !important',
                    fontWeight: 300,
                  }}
                  title="Edit"
                  onClick={handleModal}
                />
              </div>
            </div>
          </Box>
          <div className="m-6">
            <BookingDetailComponent
              booking={booking}
              setBooking={setBooking}
              neighbourIcon={neighbourIcon}
              updateBooking={updateBooking}
              handleOpenEditModal={handleOpenEditModal}
            />
          </div>
        </>
      )}

      {openEditModal && (
        <EditNeighbourDetails
          title="Edit"
          open={openEditModal}
          onClose={handleOpenEditModal}
          data={booking}
          successCallback={(response) => {
            updateWithResponse(response);
          }}
        />
      )}

      {openModal && (
        <AddBooking
          open={openModal}
          onClose={handleModal}
          title="Edit Booking"
          successCallback={() => {
            getBooking();
          }}
          data={booking}
          organization={organization}
        />
      )}
    </>
  );
}
export default BookingDetails;
