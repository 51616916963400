import * as React from "react";
import ImageList from "@mui/material/ImageList";
 
import ImageListItem from "@mui/material/ImageListItem";
 

function StandardImageList({ itemData = [], style }) {
  
  return (
    <ImageList cols={2}  
       sx={{ 
        display: "flex",
        flexWrap:'wrap' 
      }}>

      {itemData.length 
        ? 
      itemData.map((item) => (
        <>
       {item?.type === 'image' ?
        <ImageListItem key={item.id} sx={{position:'relative',margin:'0 4px'}}>
          
        <img
          src={`${item.image}`}
          alt={''}
          loading="lazy"
          style={{
            objectFit:'cover',
            ...style,height:'145px',
            width:'148px',
            borderRadius:'15px'
          }}
        />
      </ImageListItem>
      :
      <ImageListItem key={item.id} sx={{position:'relative',margin:'0 4px'}}>
      <video
        src={`${item.image}`}
        alt={''}
        loading="lazy"
        style={{
          objectFit:'cover',
          ...style,height:'',
          width:'150px',
          borderRadius:'15px'
        }}
      />
    </ImageListItem>}
    </>
      ))
      :
      itemData.map((item) => (
        <ImageListItem key={item.id} sx={{position:'relative',margin:'0 4px'}}>
          <img
            src={`${item.image}`}
            alt={''}
            loading="lazy"
            style={{
              objectFit:'cover',
              ...style,height:'',
              width:'150px',
              borderRadius:'15px'
            }}
          />
        </ImageListItem>
      ))} 
    </ImageList>
    
  );
}

export default StandardImageList;
