import React from "react";
import FormModal from "../../shared-component/formmodal-component/FormModal";
import Form from "../../shared-component/formrelated-component/FormManager";

function Link({
    title,
    open,
    onClose,
}){
    const defaultValues = {
        link:[],
        
    }
    const fieldMap = [
        {
            name:'link',
            label:'Links With',
            placeholder:'Links With',
            type:'select',
            fieldProps:{
                defaultOptions: [
                    {title: 'B Block - A Tower, B Tower, C Tower, D Tower', id:'2'},
                    {title: 'A Block - A Tower, B Tower, C Tower, D Tower', id:'3'},
                    {title: 'C Block - A Tower, B Tower, C Tower, D Tower', id:'4'},
                ]
            }
        },  
   ]
    return(
        <>
            <FormModal title={title} open={open} onClose={onClose} >
                <Form 
                    defaultValues={defaultValues}
                    fieldMap={fieldMap}
                    primaryBtnTitle='Link'
                    secondaryBtnTitle='Cancel'
                />
            </FormModal>
        </>
    )
}
export default Link;