import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { BoxEffects, headerButton } from '../../../../Config/Constants';
import LabTabs from '../../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs';
import HotspotComp from './HotspotComp';
import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import { PrimaryButton } from '@assystant/mui-buttons';
import HotSpotModal from './HotSpotModal';
import MapViewComp from './MapViewComp';
import AddLinkModal from './linkadd/AddLinkModal';

import { apiService } from '../../../../constant/AuthConstant';
import { useParams } from 'react-router';
import Loading from '../../../shared-component/Loading/Loading';

import Integration from './linkadd/Integration';
import { renderScript } from './Script';
import { returnPaths } from './ReturnPaths';
import { BASE_URI } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../utils/useExit';
import UserContext from '../../../../context/UserContext';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';

function HotspotTabs({ template }) {
  const [value, setValue] = useState('1');
  const { id } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [openLinkMod, setOpenLinkMod] = useState(false);

  const [submit, setSubmit] = useState(false);
  const [hotpotmodal, setHotpotModal] = useState(false);
  const [hotspotData, setHotSpotData] = useState('');
  const [mapImageData, setMapImageData] = useState({});
  const [allHotSpot, setHotSpot] = useState([]);
  const [mapList, setMapList] = useState([]);
  const [load, setLoad] = useState(false);
  const [mapData, setMapData] = useState(null);
  const [hotspotList, setHotspotList] = useState([]);
  const [allHotshpotPaths, setAllHotstopPaths] = useState([]);
  const [openIntegrate, setIntegarte] = useState(false);
  const [crumb, setCrumb] = useState([]);
  const [templateConfig, setTemplateConfig] = useState(null);
  const { exitFromPlatform } = useExit();
  const [selectedHotspotData, setSelectedHotspotData] = useState(null);
  localStorage.setItem('tabValue', '4');

  const { userFromOrganization } = useContext(UserContext);

  const toggleIntegrate = () => {
    setIntegarte(!openIntegrate);
  };

  useEffect(() => {
    if (mapImageData?.name) {
      const arr1 = [
        { title: 'Properties', url: '/properties' },
        {
          title: `${JSON.parse(nameString)}`,
          url: `/properties/${mapImageData?.property?.id}`,
        },
        { title: 'Plan', url: `/properties/${mapImageData?.property?.id}` },
        { title: `${mapImageData?.name}`, url: '/prop2' },
      ];
      setCrumb(arr1);
    }
  }, [mapImageData]);

  const handelCloseHotspot = () => {
    setHotpotModal(false);
  };
  const handelAddHotspot = (data) => {
    setSelectedHotspotData(data);
    setHotpotModal(true);
  };

  function OpenLinkMod(data) {
    setHotSpotData(data);
    setOpenLinkMod(true);
  }

  const fetchMapData = () => {
    setLoad(true);
    apiService
      .get(`property/mapplan/${id}/`)
      .then((res) => {
        setMapImageData(res?.data);
        const arr1 = [
          { title: 'Properties', url: '/properties' },
          {
            title: `${JSON.parse(nameString)}`,
            url: `/properties/${res?.data?.property?.id}`,
          },
          { title: 'Map', url: `/properties/${res?.data?.property?.id}` },
          { title: `${res?.data?.name}`, url: '/prop2' },
        ];
        const paths = res?.data?.hotspots?.map((e) => {
          return {
            ...e,
            mapId: e?.map,
            linked_url: e?.url_link,
            path: e?.svg_pathdata?.replaceAll('/n', '')?.replaceAll('/s/g', ''),
            linked_unit: e?.unit_link,
            linked_map: e?.map_link, //.replaceAll('/n', '').replaceAll(/\s/g,'')
            svg_pathdata: e?.svg_pathdata
              ?.replaceAll('/n', '')
              ?.replaceAll('/s/g', ''),
          };
        });
        const hotspotData = res?.data?.hotspots?.map((e) => {
          return {
            ...e,
            svg_pathdata: e?.svg_pathdata
              ?.replaceAll('/n', '')
              ?.replaceAll('/s/g', ''),
          };
        });

        setHotspotList(hotspotData);
        setAllHotstopPaths(paths);
        setCrumb(arr1);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoad(false);
          }
        }
      });
  };

  const getTemplateConfig = () => {
    apiService
      .get(`specs/template-config/`)
      .then((res) => {
        setTemplateConfig(res?.data);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoad(false);
          }
        }
      });
  };

  useEffect(() => {
    getTemplateConfig();
    fetchMapData();
  }, [id]);

  const deleteHotspot = (id) => {
    apiService
      .delete(`property/hotspot/${id}/`)
      .then((res) => {
        enqueueSnackbar('Hotspot Deleted.', { variant: 'success' });
        fetchMapData();
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoad(false);
          }
        }
      });
  };

  const modifyData = (data) => {
    let newData = { ...data };

    newData = {
      ...newData,
      unit_link: data?.unit_link?.id ? data?.unit_link?.id : null,
      map_link: data?.map_link?.id ? data?.map_link?.id : null,
    };

    return newData;
  };

  const tabs = [
    {
      label: 'List',
      value: '1',
      page: (
        <HotspotComp
          allHotSpot={hotspotList}
          setSubmit={setSubmit}
          submit={submit}
          OpenLinkMod={
            userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
            userFromOrganization?.role?.name.toLowerCase() === 'developer'
              ? OpenLinkMod
              : () => {}
          }
          handelAddHotspot={handelAddHotspot}
          deleteHotspot={deleteHotspot}
          userFromOrganization={userFromOrganization}
        />
      ),
    },
    {
      label: 'Plan',
      value: '2',
      page: (
        <MapViewComp
          template={template}
          allHotSpot={allHotSpot}
          mapData={mapImageData}
          hotspotPaths={allHotshpotPaths}
          templateConfig={templateConfig}
        />
      ),
    },
  ];

  const nameString = localStorage.getItem('property_name');

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <Box
          sx={{
            borderBottom: BoxEffects.borderBottom,
            borderColor: 'secondary.main',
            // padding: BoxEffects.padding,
            marginBottom: BoxEffects.marginButton,
            // paddingLeft: BoxEffects.margin,
            // paddingRight: headerButton.marginRigt
          }}
        >
          <Box
            className="w-full flex justify-between items-center"
            sx={{
              width: '100%',
              borderBottom: BoxEffects.borderBottom,
              borderColor: 'secondary.main',
              padding: BoxEffects.padding,
              marginBottom: BoxEffects.marginButton,
              paddingLeft: BoxEffects.margin,
              paddingRight: headerButton.marginRigt,
              position: 'sticky',
              top: '65px',
              backgroundColor: 'white',
              zIndex: 99,
            }}
          >
            <BreadCrumbWithActions propArr={crumb}>
              <>
                <SecondaryBtn
                  style={{ boxShadow: 'none', fontWeight: 300 }}
                  title="Integrate"
                  onClick={toggleIntegrate}
                />
                <PrimaryBtn
                  style={{ boxShadow: 'none', fontWeight: 300 }}
                  disabled={
                    userFromOrganization?.role?.name.toLowerCase() ===
                      'admin' ||
                    userFromOrganization?.role?.name.toLowerCase() ===
                      'developer'
                      ? false
                      : true
                  }
                  title="Add Hotspot"
                  onClick={() => {
                    handelAddHotspot(null);
                  }}
                />
              </>
            </BreadCrumbWithActions>
          </Box>
          <LabTabs
            tabs={tabs}
            setValue={setValue}
            value={value}
            handleChange={handleChange}
          />
        </Box>
      )}
      {hotpotmodal && (
        <HotSpotModal
          id={id}
          open={hotpotmodal}
          submit={submit}
          setSubmit={setSubmit}
          onClose={handelCloseHotspot}
          successCallback={fetchMapData}
          data={
            selectedHotspotData === null
              ? null
              : modifyData(selectedHotspotData)
          }
        />
      )}
      {openLinkMod && (
        <AddLinkModal
          data={hotspotData}
          setSubmit={setSubmit}
          open={openLinkMod}
          onClose={() => setOpenLinkMod(false)}
          mapImageData={mapImageData}
          successCallback={fetchMapData}
        />
      )}
      {openIntegrate && (
        <Integration
          open={openIntegrate}
          onClose={toggleIntegrate}
          text={renderScript(mapImageData?.uuid, BASE_URI)}
        />
      )}
    </>
  );
}

export default HotspotTabs;
