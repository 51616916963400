import { Checkbox } from '@mui/material'
import React from 'react'
import RegularCheckbox from '../../../shared-component/OverviewPage/overview-used-components/RegularCheckbox'

function TempDesign5({data=[],val,popupPosition,popupClose}) {
  return (
    <div key={val?.id} className='mx-6 my-4'>
        {val && <div
                    className='mb-6 p-2 flex justify-between items-center'
                    style={{
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        color: 'black',
                        width: '350px',
                        height: 'auto',
                        border: '2px solid #E1EBFE'
                    }}
                >Template - SM1 <div><RegularCheckbox checked={val?.selected === val?.id} onClick={(e)=>val.handleSelect(e,val?.id)}/></div></div>}
                <div
                    style={{
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        width: '200px',
                        height: 'auto',
                        border: '2px solid #E1EBFE',
                        position: !val && 'absolute',
                        left:!val && `${popupPosition.x}px`,
                        top: !val && `${popupPosition.y}px`,
                        zIndex:  !val && 9999
                       
                    }}
                >
                    <div className='flex items-center justify-between px-4 py-2' style={{ borderBottom: '2px solid #E1EBFE', }}>
                        <span style={{
                            fontFamily: 'Rubik',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                        }}>Linked details</span>
                        <span onClick={popupClose} style={{ color: 'gray', cursor: 'pointer' }}>&#10006;</span>
                    </div>
                    <div className='px-4 py-2'>
                        <div className='my-2'>
                            <div style={{ 
                                color: '#FF5C5C',
                                fontFamily: 'Rubik',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '24px',
                                textAlign: 'left',
                                 }}>Data is not linked</div>
                            {/* <div style={{ fontWeight: 'bold' }}>{data[0]?.unit_name.stringValue}</div> */}
                        </div>
                    </div>

                </div>
    </div>
  )
}

export default TempDesign5