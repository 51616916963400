import React from "react";

const HomeWork = () =>{
    return(
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_6045_6115" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
            <rect width="40" height="40" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_6045_6115)">
            <path d="M28.3334 15H31.6667V11.6667H28.3334V15ZM28.3334 21.6667H31.6667V18.3333H28.3334V21.6667ZM28.3334 28.3333H31.6667V25H28.3334V28.3333ZM28.3334 35V31.6667H35.0001V8.33333H20.0001V10.6667L16.6667 8.25V5H38.3334V35H28.3334ZM1.66675 35V18.3333L13.3334 10L25.0001 18.3333V35H15.0001V26.6667H11.6667V35H1.66675ZM5.00008 31.6667H8.33342V23.3333H18.3334V31.6667H21.6667V20L13.3334 14.0833L5.00008 20V31.6667Z" fill="#353349"/>
            </g>
        </svg>
    )
}

export default HomeWork;