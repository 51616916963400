import React, { useState } from 'react';
import FormModal from '../../../../shared-component/formmodal-component/FormModal';
import { TextField } from '@mui/material';
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons';
import { BASE_URI, apiService } from '../../../../../constant/AuthConstant';
import SecondaryBtn from '../../../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../../../shared-component/button-component/PrimaryBtn';

const IntegrateWebsiteModal = ({
  open,
  title,
  loading,
  onClose,
  onSubmit,
  currentWebData,
}) => {
  
  const [domainName, setDomainName] = useState(currentWebData?.cname || '');
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [fromError, setFromError] = useState('');
  const [fromVRError, setFromVRError] = useState('')


  console.log('from urls', BASE_URI)

  const instructions = [
    {
      id: 1,
      title: 'Login to your Website’s Domain Manager',
      sub_title: 'Go to your list of domains',
    },
    {
      id: 2,
      title: 'Select the domain you wish to edit',

      sub_title: 'Ensure that you are editing the DNS settings',
    },
    {
      id: 3,
      title: 'Create or edit a new record',
      sub_title: `Name/Host/Alias/Cname of the website should Point To(or Value/Destination) ${currentWebData?.subdomain}${process.env.REACT_APP_SITE_DOMAIN}`,
    },
  ];

  const veryDnsFirst = (value) =>{
    const domain = value;
    const subDoamin = currentWebData?.subdomain;
    setVerificationLoading(true)
    apiService.post(`website/verify_dns/`, {
      domain: domain,
      subdomain: subDoamin,
    }).then((res) =>{
      if(res?.status === 200){
         setFromVRError('')
         onSubmit(domainName)
      }
      setVerificationLoading(false)
    }).catch((error) =>{
      console.log(error.response.data)
      setVerificationLoading(false)
      setFromVRError(error.response.data)
    })
  }
  const onDomainChange = (e) =>{
    setFromVRError('')
    const regex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

    setDomainName(e.target.value)
    if(regex.test(e.target.value)){
      setFromError('')
    }else{
      setFromError("Please enter a valid domain")
    }
  }
 
  return (
    <FormModal open={open} title={title} onClose={onClose}>
      <div className="m-6">
        <div
          className="mb-4"
          style={{
            fontFamily: 'Rubik',
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '28.44px',
          }}
        >
          Enter your domain you want to connect
        </div>

        <div className="">
          <TextField
            label="Domain Name"
            placeholder="iuimoyl.spotmink.com"
            InputLabelProps={{
              shrink: true,
            }}
            value={domainName}
            sx={{
              width: '60%',
            }}
            onChange={onDomainChange}
            error={fromError}
            helperText={fromError}
          />
        </div>

        <div className="flex flex-col my-4">
          <div
            style={{
              fontFamily: 'Rubik',
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '28.44px',
            }}
          >
            Instructions
          </div>
          <div className="my-4">
            {instructions?.map((e, i) => {
              return (
                <div className="flex flex-col my-2" key={i}>
                  <p
                    className="!text-[#353349]"
                    style={{
                      fontFamily: 'Rubik',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '18.96px',
                    }}
                  >{`${i + 1}. ${e?.title}`}</p>
                  <p
                    className="!text-[#8E8CA3]"
                    style={{
                      fontFamily: 'Rubik',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '18.96px',
                    }}
                  >
                    {e?.sub_title}
                  </p>
                </div>
              );
            })}
          </div>
          <p className='text-sm text-[#eb3434] font-normal'>{fromVRError}</p>
        </div>

        <div className="w-full flex justify-end">
          <SecondaryBtn
            style={{
              backgroundColor: '#EDECF9',
              boxShadow: 'none',
              fontWeight: 300,
              padding: '6px 16px',
            }}
            onClick={onClose}
            title="Cancel"
          />
          <PrimaryBtn
            title="Verify & Submit"
            style={{
              marginRight: '0px !important',
              boxShadow: 'none',
              fontWeight: 300,
              padding: '6px 16px',
            }}
            disabled={loading || verificationLoading}
            onClick={() =>{
              if(fromError === ''){
                veryDnsFirst(domainName)
              }
              //onSubmit(domainName)
            }}
          />
        </div>
      </div>
    </FormModal>
  );
};

export default IntegrateWebsiteModal;
