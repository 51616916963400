import React from 'react';
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import { TextButton } from '@assystant/mui-buttons';
import DeleteIcon from '../../../../asset/icons/DeleteIocn';
import TextBtn from '../../../shared-component/button-component/TextBtn';

function HotspotComp({
  OpenLinkMod,
  allHotSpot,
  handelAddHotspot,
  deleteHotspot,
  userFromOrganization,
}) {
  const openEditMode = (e, data) => {
    console.log('from hotspot data', data);
    e?.persist();
    e?.stopPropagation();
    handelAddHotspot(data);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 100,
      renderCell: (params) => params.row.id || '-',
    },
    {
      field: 'name',
      headerName: 'Hotspot Name',
      minWidth: 220,
      renderCell: (params) => params.row?.name || '-',
    },
    {
      field: 'link_to',
      headerName: 'Link to',
      minWidth: 250,
      renderCell: (params) => (
        <>
          {params.row?.url_link ? (
            <a
              target="_blank"
              href={params.row.url_link}
              onClick={(e) => {
                e.stopPropagation();
              }}
              rel="noreferrer"
            >
              {params.row?.url_link}
            </a>
          ) : params.row?.link_name ? (
            <p>{params.row?.link_name}</p>
          ) : params.row?.greenspace_label ? (
            <p>{params.row?.greenspace_label}</p>
          ) : (
            <p>{'-'}</p>
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 150,
      align: 'right',
      renderCell: (params) => (
        <>
          {userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
          userFromOrganization?.role?.name.toLowerCase() === 'developer' ? (
            <div className="w-full flex items-center px-2 cursor-pointer text-right justify-end">
              <div
                onClick={(e) => {
                  openEditMode(e, params?.row);
                }}
              >
                <TextBtn
                  style={{
                    fontWeight: 300,
                  }}
                  title="Edit"
                />
              </div>
              <div
                onClick={(e) => {
                  e?.persist();
                  e?.stopPropagation();
                  deleteHotspot(params?.row?.id);
                }}
              >
                <DeleteIcon color="error" className="!fill-[#FF5C5C]" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="py-4">
        {allHotSpot.length === 0 ? (
          <EmptyCard
            title="No Hotspot Added"
            subtitle="Click “Add Hotspot” to add the map"
          />
        ) : (
          <DataGridTable
            columns={columns}
            rows={allHotSpot}
            handleEvent={OpenLinkMod}
          />
        )}
      </div>
    </>
  );
}

export default HotspotComp;
