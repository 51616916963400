import React, { useEffect, useMemo, useState } from 'react';
import PrimaryBtn from '../../../../shared-component/button-component/PrimaryBtn';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import MobileView from '../../../../../asset/icons/MobileView';
import TabletView from '../../../../../asset/icons/TabletView';
import DesktopView from '../../../../../asset/icons/DesktopView';
import WebsitePreview from '../Preview/WebsitePreview';
import returnError from '../../../../../utils/ExportError';
import useExit from '../../../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import { apiService } from '../../../../../constant/AuthConstant';
import RemovePopup from '../../../../shared-component/RemovePopup/RemovePopup';
import Loading from '../../../../shared-component/Loading/Loading';
 

const WebsitePreviewContainer = () => {
  const { webId } = useParams();
  const navigate = useNavigate();
  const { exitFromPlatform } = useExit();
  const [isShowingPreview, setIsShowingPreview] = useState(false);
  const [selectedView, setSelectedView] = useState('Desktop');
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [allThemesList, setAllThemesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [websiteDetails, setWebsiteDetails] = useState(null);
  const [menus, setMenus] = useState([]);
  const [pages, setPages] = useState([]);
  const [themeDetails, setThemeDetails] = useState(null)

  const handleRemoveModal = () => {
    setOpenRemoveModal(!openRemoveModal);
  };
  const viewList = [
    {
      id: 1,
      name: 'Mobile',
      icon: (e) => {
        return <MobileView color={e} />;
      },
    },
    {
      id: 2,
      name: 'Tablet',
      icon: (e) => {
        return <TabletView color={e} />;
      },
    },
    {
      id: 3,
      name: 'Desktop',
      icon: (e) => {
        return <DesktopView color={e} />;
      },
    },
  ];

  const handleViewSelection = (view) => {
    setSelectedView(view);
  };

  const goBackFromPreview = () => {
    setIsShowingPreview(false);
  };

  const handleBack = () => {
    if (isShowingPreview) {
      goBackFromPreview();
    } else {
      navigate('/websites');
    }
  };

  const retrievePagesFromWebsite = () => {
    apiService
      .get(`website/pages/?website=${webId}`)
      .then((res) => {
        if (res?.data?.length > 0) {
          setPages(res?.data);
        } else {
          enqueueSnackbar('Please add pages first');
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  const retrieveMenusFromWebsite = () => {
    apiService
      .get(`website/menu/?website=${webId}`)
      .then((res) => {
        if (res?.data?.length > 0) {
          setMenus(res?.data);
          retrievePagesFromWebsite();
        } else {
          enqueueSnackbar('Please add menus first');
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  const retrieveTheme = (themeId) =>{
    apiService.get(`website/templates/`).then((res)=>{
      if(res?.data?.length){
        const findTemp = res?.data?.find((e) =>{
          return e?.id === themeId
        })
        setThemeDetails(findTemp)
      }
      retrieveMenusFromWebsite();
    }).catch((error) =>{
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setLoading(false);
        }
      }
    })
  }

  const retrieveWebiteData = () =>{
    setLoading(true)
    apiService.get(`website/site/${webId}`).then((res)=>{
      if(res?.data?.id){
        setWebsiteDetails(res?.data)
        retrieveTheme(res?.data?.template)
      }
    }).catch((error) =>{
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setLoading(false);
        }
      }
    })
  }


  useEffect(() => {
    retrieveWebiteData()
  }, []);

  const deleteTheme = () => {
    apiService
      .delete(`websites/${webId}`)
      .then((res) => {
        if (res.status === 204) {
          navigate('/websites');
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  

  const Preview = useMemo(() => {
    return (
      <div className="flex justify-center w-full">
        <WebsitePreview
          menus={menus}
          pages={pages}
          themeDetails={themeDetails}
          selectedView={selectedView}
        />
      </div>
    );
  }, [selectedView, menus, pages, themeDetails]);

  return (
    <>
      <div className="relative h-screen">
        <div
          className="h-20 w-full bg-white flex items-center justify-between"
          style={{
            borderBottom: '1px solid #E1EBFE',
          }}
        >
          <div className="mx-8">
            <PrimaryBtn
              className="!mx-0"
              style={{
                boxShadow: 'none',
                margin: '0px !important',
                fontWeight: 300,
              }}
              title="Back"
              onClick={handleBack}
            />
          </div>
          <div
            className={`flex items-center mx-8 ${isShowingPreview ? 'gap-[80px]' : 'gap-[20px]'}`}
          >
            <div className="flex gap-[15px]">
              {viewList?.map((view) => {
                return (
                  <div
                    key={view.id}
                    className="rounded-md w-10 h-10 flex items-center justify-center cursor-pointer"
                    style={{
                      border:
                        selectedView === view?.name
                          ? '1px solid #8E8CA3'
                          : '1px solid #E1EBFE',
                    }}
                    onClick={() => handleViewSelection(view?.name)}
                  >
                    {view.icon(
                      selectedView === view?.name ? '#8E8CA3' : '#E1EBFE',
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className="rounded-md w-10 h-10 flex items-center justify-center cursor-pointer"
              style={{
                border: '1px solid #E1EBFE',
              }}
              onClick={handleBack}
            >
              <CloseIcon className="!fill-[#FF5C5C]" />
            </div>
          </div>
        </div>

        {loading ? (
          <Loading />
        ) : (
          menus?.length > 0 &&
          pages?.length > 0 && themeDetails !== null && <div className="flex h-full">{Preview}</div>
        )}
      </div>

      {openRemoveModal && (
        <RemovePopup
          open={openRemoveModal}
          close={handleRemoveModal}
          description={`Are you sure, you want to remove this website?`}
          onSubmit={deleteTheme}
        />
      )}
    </>
  );
};

export default WebsitePreviewContainer;
