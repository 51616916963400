import * as React from 'react';
import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';

import {themes} from '../../Config/themesConfig'
import { navLogo, stack, customBox } from '../../Config/Constants';
import { Stack } from '@mui/material';
import logoo from '../../asset/Images/article1.png';
import PopoverPopupState from './PopoverfornavIcon';
import MenuIcon from '../../asset/icons/MenuIcon';



function NavBar({
  logo,
  width,
  handleSidebar
}) {
  return (
    <AppBar position="fixed" sx={{background: themes.palette.primary.main}}>
        <Toolbar disableGutters className='flex justify-between items-center mr-5'>
           <div className='flex items-center justify-between' style={{ width: '300px' }}>
                {width === 300 &&(
                  <img className='ml-5 mt-2' src={logoo} alt='' width={navLogo.width} height={navLogo.height}/>
                )}
                <div className={width === 0 ? 'cursor-pointer px-4 transition ease-in-out delay-150' : 'cursor-pointer transition ease-in-out delay-150'} onClick={handleSidebar}>
                    <MenuIcon width="28" height="28" />
                </div>
           </div>
          <Stack direction="row" spacing={stack.space}>
            {/* <Avatar /> */}
            <PopoverPopupState />
          </Stack>
        </Toolbar>
        
    </AppBar>
  );
}
export default NavBar;