import React, { useEffect, useState } from 'react';
import { Box, Grid, Avatar } from '@mui/material';
import { headerButton, BoxEffects, grids } from '../../../../Config/Constants';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
import GridComponent from '../../../shared-component/grid-component/GridComponent';
import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import DetailsCard from '../../../shared-component/card-component/DetailsCard';
import LabelAndValue from '../../../shared-component/OverviewPage/overview-used-components/LabelAndValue';
import EditPartnerProfile from './modals/EditPartnerProfile';
import { BASE_URI, apiService } from '../../../../constant/AuthConstant';
import useExit from '../../../../utils/useExit';
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import Loading from '../../../shared-component/Loading/Loading';
import IconForUpload from '../../../../asset/icons/IconForUpload';

const arr1 = [{ title: 'Profile', url: '/' }];

const PartnerProfile = () => {
  const { exitFromPlatform } = useExit();
  const [details, setDetails] = useState({});
  const [payoutDetails, setPayoutDetails] = useState({})
  const [loading, setLoading] = useState(false);
  const [openEditProfile, setOpenEditProfile] = useState(false);

  const handleOpenEdit = () => {
    setOpenEditProfile(!openEditProfile);
  };

  const retreivePayoutDetails = () =>{
    apiService.get(`consulting_partner/profile/my_profile/`).then((res) =>{
      if(res?.data?.id){
        setPayoutDetails(res?.data)
      }
      setLoading(false);
    }).catch((error) =>{
      setLoading(false);
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
        }
      }
    });
  }

  const retreiveProfileDetails = () => {
    setLoading(true);
    apiService
      .get(`account/user/get_details/`)
      .then((res) => {
        if(res?.data !== null){
          const newDetails = {
            ...res?.data
          }
          newDetails.avatar = `${BASE_URI}${newDetails?.avatar}`
          setDetails(newDetails)
        }else{
          setDetails(res?.data)
        }
        retreivePayoutDetails(res?.data)
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  useEffect(() =>{
    retreiveProfileDetails()
  }, [])

  const contactDetails = [
    {
      label: '',
      value: (
        <>
          {details?.avatar === '' || details?.avatar === null ? (
            <IconForUpload />
          ) : (
            <Avatar
              src={`${details?.avatar}`}
              sx={{
                width: '60px',
                height: '60px',
                background: '#EDECF9',
              }}
            />
          )}
        </>
      ),
    },
    { label: 'Name', value: details?.full_name || 'Not Provided' },
    { label: 'Email', value: details?.email || 'Not Provided' },
  ];

  const payout_information = [
    { label: 'Commission', value: `${payoutDetails?.commission_percent}%` || 'Not Provided' },
    { label: 'Banking details', value: payoutDetails?.banking_details || 'Not Provided' },
  ]

  const modifyData = (data) =>{
    let newData = {...data};
    newData = {
      ...data,
      first_name: data?.full_name
    }

    return newData
  }
  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '65px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          <div className="flex justify-between items-center">
            <div
              style={{
                marginLeft: '20px',
              }}
            >
              <PrimaryBtn
                title="Edit"
                style={{
                  boxShadow: 'none',
                  marginRight: '0px !important',
                  fontWeight: 300,
                  padding: '6px 16px',
                }}
                disabled={loading}
                onClick={handleOpenEdit}
              />
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>
      <div className="m-6">
        {loading ? (
          <Loading />
        ) : (
          <GridComponent>
            <Grid
              item
              xs={grids.parrentgrid}
              lg={grids.overviewFirstGrid}
              sx={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <DetailsCard cardHeader="Contact Information">
                {contactDetails.map((e, i) => {
                  return (
                    <LabelAndValue
                      key={i}
                      label={e.label}
                      value={e.value}
                      valueStyles={{
                        fontFamily: 'Rubik',
                        fontSize: '20px',
                        fontWeight: 400,
                        lineHeight: '28.44px',
                      }}
                    />
                  );
                })}
              </DetailsCard>
            </Grid>
            <Grid className='!md:w-full' item xs={grids.childgrid} lg={grids.childgrid}>
            <DetailsCard cardHeader="Payout Information">
                {payout_information.map((e, i) => {
                  return (
                    <LabelAndValue
                      key={i}
                      label={e.label}
                      value={e.value}
                      valueStyles={{
                        fontFamily: 'Rubik',
                        fontSize: '20px',
                        fontWeight: 400,
                        lineHeight: '28.44px',
                      }}
                    />
                  );
                })}
              </DetailsCard>
            </Grid>
          </GridComponent>
        )}
      </div>

      {openEditProfile && (
        <EditPartnerProfile
          open={openEditProfile}
          title="Edit Profile"
          onClose={handleOpenEdit}
          data={details?.id ? modifyData(details) : {}}
          successCallback={retreiveProfileDetails}
        />
      )}
    </>
  );
};

export default PartnerProfile;
