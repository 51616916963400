import React from "react";
import { Box, Button, Toolbar } from "@mui/material";
import ColorizeIcon from '@mui/icons-material/Colorize';
import { iconButton } from "../../../Config/Constants";
import arrowselectortool from "../../../asset/Images/arrow_selector_tool.png";
import conversationpath from "../../../asset/Images/conversion_path.png";
import assignmentadd from "../../../asset/Images/assignment_add.png";
import drivefile from "../../../asset/Images/drive_file_rename_outline.png";
import elips from "../../../asset/Images/Ellipse_23.png";
import opacity from "../../../asset/Images/opacity.png";
import copy from "../../../asset/Images/content_copy.png";
import paste from "../../../asset/Images/content_paste.png";
import delette from "../../../asset/Images/delete.png";
import undo from "../../../asset/Images/google_plus_reshare.png";
import redo from "../../../asset/Images/google_plus_reshare (1).png";

function ToolbarIcons({handleUndo,handleRedo,handleCloneClick,handleDeleteClick,handelColorPicker,handelDrawCanvas,newhandelColorPicker,color,handleChangeColor,handelPasteClick,handelOpacitytooltip,handelDrawConnections}){
    const tools = [
        {icon: arrowselectortool},
        {icon: conversationpath,handler:handelDrawConnections},
        {icon: assignmentadd},
        {icon: drivefile,handler:handelDrawCanvas},
        {ele: <input type="color" style={{borderRadius:'50px', height:'30px', width:'30px', background:color}} value={color} onChange={handleChangeColor}  />, handler:newhandelColorPicker},
        {icon: opacity,handler:handelOpacitytooltip},
        {icon: copy,handler:handleCloneClick},
        {icon: paste,handler:handelPasteClick},
        {icon: delette,handler:handleDeleteClick},
        {icon: undo,handler:handleUndo},
        {icon: redo,handler:handleRedo},
    ]
    return(
        <>
            <Box className="flex justify-center">
                <Toolbar sx={{gridGap:'20px',position:'fixed', bottom:'67px', boxShadow:'0px 0px 7px 2px #00000014',}} className="justify-center">
                {tools.map((e,i)=>{
                        return(
                            <Button variant="outlined"
                            onClick={e.handler} 
                            size='small' 
                            sx={{
                                color:'#E1EBFE',
                                width:'50px',
                                height:'50px',
                                "&:hover": {
                                    background: '#353349',
                                }
                            }}
                            // sx={iconButton}
                            >
                                {e.icon ? <img src={e.icon}/> : e.ele}
                                
                            </Button>
                        )
                    })}
                    
                    
            </Toolbar>
            </Box>
        </>
    )
}
export default ToolbarIcons;