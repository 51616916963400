import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import './drawer.css';
import { TokenStorage } from '../../../constant/AuthConstant';
import { useNavigate } from 'react-router-dom';

const NestedList = ({
  path,
  openItem,
  selected,
  setSelected,
  setOpenItem,
  openNestItem,
  setOpenNestItem,
}) => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const [openNested, setOpenNested] = useState(false);

  const handleNested = () => {
    setOpenNested(!openNested);
  };

  React.useEffect(() => {
    if (TokenStorage.getProfile()?.is_affiliate === true && TokenStorage.getProfile()?.is_consulting_partner === false ) {
      if (window.location.pathname === '/') {
        setSelected({ id: '1' });
        navigate('/dashboard');
      } else if (window.location.href.split('/')[3] === 'dashboard') {
        setSelected({ id: '1' });
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'conversions') {
        setSelected({ id: '2' });
        navigate(window.location.pathname);
      }
    } else if(TokenStorage.getProfile()?.is_affiliate === false && TokenStorage.getProfile()?.is_consulting_partner === true ){
      if (window.location.pathname === '/') {
        setSelected({ id: '1' });
        navigate('/partner-dashboard');
      } else if (window.location.href.split('/')[3] === 'partner-dashboard') {
        setSelected({ id: '1' });
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'transactions') {
        setSelected({ id: '2' });
        navigate(window.location.pathname);
      }
    }
    else {
      if (window.location.pathname === '/') {
        setSelected({ id: '1' });
        setOpenItem(null);
        setOpenNestItem(null);
        setOpenNested(false);
        navigate('/dashboard');
      } else if (window.location.href.split('/')[3] === 'dashboard') {
        setSelected({ id: '1' });
        setOpenItem(null);
        setOpenNestItem(null);
        setOpenNested(false);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'properties') {
        setSelected({ id: '2' });
        setOpenItem(null);
        setOpenNestItem(null);
        setOpenNested(false);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'demo') {
        setSelected({ id: '2' });
        setOpenItem(null);
        setOpenNestItem(null);
        setOpenNested(false);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'leads') {
        setSelected({ id: '3' });
        setOpenItem('3');
        setOpenNestItem({ id: '1' });
        setOpenNested(true);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'bookings') {
        setSelected({ id: '3' });
        setOpenItem('3');
        setOpenNestItem({ id: '2' });
        setOpenNested(true);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'booking') {
        setSelected({ id: '3' });
        setOpenItem('3');
        setOpenNestItem({ id: '2' });
        setOpenNested(true);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'specification') {
        setSelected({ id: '5' });
        setOpenNestItem({ id: '2' });
        setOpenItem('5');
        setOpenNested(true);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'organization') {
        setSelected({ id: '5' });
        setOpenItem('5');
        setOpenNestItem({ id: '3' });
        setOpenNested(true);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'custom-code') {
        setSelected({ id: '5' });
        setOpenItem('5');
        setOpenNestItem({ id: '6' });
        setOpenNested(true);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'analytics') {
        setSelected({ id: '4' });
        setOpenItem('4');
        setOpenNested(false);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'template_design') {
        setSelected({ id: '5' });
        setOpenItem('5');
        setOpenNestItem({ id: '5' });
        setOpenNested(true);
        navigate(window.location.pathname);
      }
      else if (window.location.href.split('/')[3] === 'billing') {
        setSelected({ id: '5' });
        setOpenItem('5');
        setOpenNestItem({ id: '7' });
        setOpenNested(true);
        navigate(window.location.pathname);
      }
       else if (
        window.location.href.split('/')[3] === 'forms' ||
        window.location.href.split('/')[3] === 'form-detail-preview'
      ) {
        setSelected({ id: '7' });
        setOpenItem(null);
        setOpenNestItem(null);
        setOpenNested(false);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'affiliate') {
        setSelected({ id: '8' });
        setOpenItem(null);
        setOpenNestItem(null);
        setOpenNested(false);
        navigate(window.location.pathname);
      } else if (window.location.href.split('/')[3] === 'gallary') {
        setSelected({ id: '9' });
        setOpenItem(null);
        setOpenNestItem(null);
        setOpenNested(false);
        navigate(window.location.pathname);
      }
      else if (window.location.href.split('/')[3] === 'websites') {
        setSelected({ id: '10' });
        setOpenItem(null);
        setOpenNestItem(null);
        setOpenNested(false);
        navigate(window.location.pathname);
      }
      else if (window.location.href.split('/')[3] === 'partners') {
        setSelected({ id: '11' });
        setOpenItem(null);
        setOpenNestItem(null);
        setOpenNested(false);
        navigate(window.location.pathname);
      }
    }
  }, [window.location, url]);

  React.useEffect(() => {
    if (openItem === null) {
      setOpenNested(false);
    }
    if (openItem !== null) {
      if (openNested === false) {
        setOpenNested(true);
      }
    }
  }, [openItem]);

  console.log('from nested', openNested);

  const maintainMode = React.useCallback(() => {
    return openItem === path.id ? (
      <>
        {openNested ? (
          <ExpandLess
            sx={{
              color: 'white !important',
            }}
          />
        ) : (
          <ExpandMore
            sx={{
              color: 'white !important',
            }}
          />
        )}
      </>
    ) : (
      <ExpandMore
        sx={{
          color: 'grey !important',
        }}
      />
    );
  }, [openItem, openNested, path]);

  return (
    <div className="w-full" key={path.id}>
      <ListItemButton
        selected={selected?.id === path.id}
        onClick={() => {
          setSelected(path);
          setOpenItem(path.id);
          handleNested();
        }}
        id={path.id}
        key={path.id}
        sx={{
          backgroundColor: openItem === path.id ? '#004FE8' : 'white', // Change the background color here
          color: openItem === path.id ? 'white' : 'gray',
          paddingLeft: '26px',
          paddingRight: '26px',
        }}
      >
        <ListItemIcon
          style={{
            color: selected === path.id ? 'red' : 'black', // Change the colors here
          }}
        >
          {path.icon(openItem === path.id ? '#FFFFFF' : '#8E8CA3')}
        </ListItemIcon>
        <span
          style={{
            width: '100%',
            color: openItem === path.id ? 'white' : '#8E8CA3',
            fontWeight: 400,
            marginLeft: '-8px',
          }}
        >
          {path.title}
        </span>
        {maintainMode()}
        {/* {openNestItem === null && (
            openItem === path.id ? (
                <ExpandLess
                  sx={{
                    color: 'white !important',
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    color: 'grey !important',
                  }}
                />
              )
         )}
         {openNestItem !== null && (
            openItem === path.id && openNested ? (
                <ExpandLess
                  sx={{
                    color: 'white !important',
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    color: 'white !important',
                  }}
                />
              )
         )} */}
      </ListItemButton>
      <Collapse
        in={openItem === path.id && openNested}
        timeout="auto"
        key={path.id}
      >
        {path.subtitles.map((subtitle) => (
          <List component="div" disablePadding key={subtitle.id}>
            <ListItemButton
              key={subtitle.id}
              selected={openNestItem?.id === subtitle.id}
              onClick={() => {
                localStorage.setItem('currentIndex', `${subtitle.id}`);
                // setSelected(subtitles.id);
                setOpenNestItem(subtitle); // Open the accordion if it's closed
                navigate(subtitle.path);
              }}
              style={{
                width: '100%',
                backgroundColor: openNestItem === path.id ? '#004FE8' : 'white', // Change the background color here
                color:
                  openNestItem?.id === subtitle.id ? 'rgb(0, 79, 232)' : 'gray',
              }}
              className="m-12"
            >
              <span className="mx-12 my-1.5 pl-4 !font-normal hover:text-[#004FE8]">
                {subtitle.title}
              </span>
            </ListItemButton>
          </List>
        ))}
      </Collapse>
    </div>
  );
};

export default NestedList;
