// -----------Documentation-------------
// Sign up component used for create the new account

// props           type                    usedfor
// -------         -------                 ---------
// companyLogo     path                    logo image for authantication page
// publicSideImage path                    logo image for side authantication page
// fields          array                   array for fields
// formValues      object                  for formvalues
// onSubmit        function                for submit useCallback
// actionTitle     string                  action button label
// pageTitle       string                  label for forms
// checkboxAction  boolean                 for open remender me checkbox


import { useContext, useState } from "react";
import SpotMink from '../../../asset/Images/SpotMink.png'
import PublicGroup from '../../../asset/Images/PublicGroup.png'
import AuthLayout from "../../shared-component/AuthLayoutPage/AuthLayout";
import userContext from "../../../context/UserContext";
import { API_URL, apiService } from "../../../constant/AuthConstant";

import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useSnackbar } from "notistack";
import returnError from "../../../utils/ExportError";
import useExit from "../../../utils/useExit";


function AffiliateSignup({
    user,
    invitation_code,
}){
   
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    
    const { exitFromPlatform } = useExit()
    const formValues={
        name: user?.invitation?.name,
        email: user?.invitation?.email,
        password:""
    }
    const schema = yup.object().shape({
        name: yup.string().required('Please enter full name'),
        email: yup.string().email('You must enter a valid email').required('You must enter a email'),
        password: yup
          .string()
          .required('Please enter your password.')
          .min(8, 'Must contain min 8 characters with one lower case letter, one upper case letter and numbers')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
            "Must contain min 8 characters with one lower case letter, one upper case letter and numbers"
          ),
      });

    const field = [
        {id:1,label:'Full Name', type:"text", value: formValues?.name, name:'name', disabled: true},
        {id:2,label:'Email', type:"email", value: formValues?.email, name:'email', disabled: true},
        {id:3,label:'Password', type:"password", value:'',name:'password'},
    ]

    console.log('from user data', formValues, field)
  
    const onSumbitForSignUp = (data) =>{
        setLoading(true)
        const formData ={
            ...data,
            invitation_code: invitation_code
        }
        apiService.post(`affiliates/accept_invite/`, formData).then(()=>{
            setLoading(false)
            enqueueSnackbar('Registered Successfully', {variant:'success'})
            localStorage.removeItem('invitation-code')
            setTimeout(() =>{
                navigate('/')
            },2000)
        }).catch((error)=>{
            setLoading(false)
            console.log('from signup error', error.response)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
        })
    }
    return(
        <>
           <AuthLayout 
            companyLogo={SpotMink} 
            publicSideImage={PublicGroup} 
            fields={field} 
            formValues={formValues} 
            onSubmit={onSumbitForSignUp} 
            actionTitle='Sign Up'
            
            pageTitle='Sign Up'
            schema={schema}
            isSubmitting={loading}
            addtionaEndText={"Go back to"}
            additionalEndLink={"Login"}
            endLink={"/"}
            /> 
        </>
    )
}
export default AffiliateSignup;