import { useEffect, useState } from 'react';
import FormModal from '../formmodal-component/FormModal';
import Form from "../formrelated-component/FormManager";

const values = {
    object_name:'',
    object_type:'',
    link_group:''
  }
function AddObject({
  title = '',
  open,
  onClose,
  onSubmit,
  onCancel,
  data = {},
  successCallback,
  currentState,
  FieldMap,
}) {
  const [fieldMap, setFieldMap] = useState(FieldMap)
  const [defaultValues, setDefaultValues] = useState(values)
  useEffect(() =>{
    if(currentState === 'single'){
        const newValues = {
            ...defaultValues,
            object_choices:[]
        }
      fieldMap.splice(2, 0, {
        name: 'object_choices',
        type: 'draggableField',
        defaultValue: { drag_example: '' },
        fieldProps:{
          arrayFields: {
                  name: 'drag_example',
                  label: '',
                  placeholder: "Example",
                  type: 'text',
          },
          isEdit: data?.name ? true : false,
        }
      }, );
      setDefaultValues({...newValues})
      setFieldMap([...fieldMap])
    }else{
      setFieldMap([...FieldMap])
      setDefaultValues({...values})
    }
  },[ currentState ])
  return (
    <FormModal title={title} open={open} onClose={onClose}>
      <Form
        onCancel={onClose}
        fieldMap={fieldMap}
        defaultValues={defaultValues}
        formSubmit={onSubmit}
        data={data}
        primaryBtnTitle={data?.name ? 'Update' : 'Add'}
      />
    </FormModal>
  );
}
export default AddObject;