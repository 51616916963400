import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import ActionButton from '../../../shared-component/button-component/ActionButton';
import FilterFields from '../../leads/Fields/FilterFields';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons';

const FilterModal = ({
    open,
    handleClose,
    handleFilter,
    buttonFields,
    propertySelected,
    buttonSeleted,
    setPropertySelected,
    setButtonSeleted,
    onSubmit
}) =>{
    const [unitAPI, setUnitAPI] = useState('')
    
    const findBy = [
        {name: "User Completed", id: "USER_SUBMITTED"},
        {name: "Staff Added", id: "STAFF_ADDED"},
        {name: "AI Found", id: "USER_DRAFT"},
    ]
    const endButton = {
        label: 'Last 365 days',
        value: '365'
    }
    console.log('from selected buttons', buttonSeleted)
    const onReset = () =>{
        setPropertySelected({name:'', value:'', id:''})
        setButtonSeleted({
            id:3,
            label:'Last 7 days',
            value:'7',
        },)
    }

    return (
        <FormModal 
          title="Filter"
          open={open}
          onClose={handleClose}
        >
          <div className='flex flex-col mx-8 my-4'>
                     <FilterFields 
                      value={propertySelected}
                      setValue={setPropertySelected}
                      choicesAPI={'/property/prop/?filtered_data=true'}
                        label="Property"
                        placeholder="Property"
                        titleKey="name"
                   />
                    <Grid className='!mt-2' container spacing={2} columns={16}>
            {buttonFields?.map((item, index) =>{
                return(
                    <Grid key={index} item xs={8} style={{ paddingTop: 0 }}>
                        <ActionButton
                        variant="contained"
                        color={buttonSeleted?.value === item.value? 'primary':'secondary'}
                        title={item.label || 'Secondary'}
                        onClick={() =>{
                            setButtonSeleted(item)
                        }}
                        style={{marginRight:0, marginLeft:0, width:'100%', marginTop:'12px', marginButtom:'12px',padding:'20px'}}
                        />
                    </Grid>
                )
            })}
        </Grid>
            <ActionButton
            variant="contained"
            color={buttonSeleted.value === endButton?.value? 'primary':'secondary'}
            title={endButton.label || 'Secondary'}
            onClick={() =>{
                setButtonSeleted(endButton)
            }}
            style={{marginRight:0, marginLeft:0, width:'100%', marginTop:'12px', marginButtom:'12px',padding:'20px'}}
            />
          </div>
          <div className="w-full flex justify-end px-8 py-8">
                    <SecondaryBtn style={{ backgroundColor: '#EDECF9',  
                                boxShadow: 'none', 
                                fontWeight:300,
                                padding:'6px 16px' }} onClick={handleClose} title='Cancel' />
                    <SecondaryBtn style={{ 
                        backgroundColor: '#EDECF9', 
                        color:'#FF5C5C',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px' }} onClick={onReset} title='Reset' />
                    <PrimaryBtn title='Filter' 
                    style={{
                        marginRight: '0px !important',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px'
                    }} onClick={() =>{
                        onSubmit({
                            property: propertySelected,
                            buttonSelection: buttonSeleted?.value
                        })
                    }}/>
                </div>
        </FormModal>
    )
}

export default FilterModal