import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import FormModal from '../../shared-component/formmodal-component/FormModal';
import ActionButton from '../../shared-component/button-component/ActionButton';
import FilterFields from './Fields/FilterFields';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import SecondaryBtn from '../../shared-component/button-component/SecondaryBtn';
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons';

const FilterModal = ({
    open,
    handleClose,
    handleFilter,
    buttonFields,
    foundBy,
    setFoundBy,
    property,
    setProperty,
    employee,
    setEmployee,
    buttons,
    setButtons,
    unit,
    setUnit,
    onSubmit
}) =>{
    const [unitAPI, setUnitAPI] = useState('')
    
    const findBy = [
        {name: "User Completed", id: "USER_SUBMITTED"},
        {name: "Staff Added", id: "STAFF_ADDED"},
        {name: "AI Found", id: "USER_DRAFT"},
    ]
    const endButton = {
        label: 'Last 365 days',
        value: '365'
    }

    const onReset = () =>{
        setFoundBy({name:'', value:'', id:''})
        setProperty({name:'', value:'', id:''})
        setUnit({name:'', value:'', id:''})
        setEmployee({name:'', value:'', id:''})
        setButtons({
            id:3,
            label:'Last 7 days',
            value:'7',
        },)
    }

    useEffect(() =>{
        if(property?.id){
            setUnitAPI(`/property/unit/?filtered_data=true&property=${property?.id}`)
        }else{
            setUnitAPI('noAPI')
        }
    }, [property])
    return (
        <FormModal 
          title="Filter"
          open={open}
          onClose={handleClose}
        >
          <div className='flex flex-col mx-8 my-4'>
                   <FilterFields 
                      value={foundBy}
                      setValue={setFoundBy}
                      label="Found By"
                      placeholder="Found By"
                      defaultOptions={findBy}
                      titleKey="name"
                   />

                  {foundBy?.id === 'STAFF_ADDED' && (
                     <FilterFields 
                     value={employee}
                     setValue={setEmployee}
                     choicesAPI={'/account/user/'}
                        label="Employee"
                        placeholder="Employee"
                        titleKey="full_name"
                  />

                  )}
                     <FilterFields 
                      value={property}
                      setValue={setProperty}
                      choicesAPI={'/property/prop/?filtered_data=true'}
                        label="Property"
                        placeholder="Property"
                        titleKey="name"
                   />
                      <FilterFields 
                      value={unit}
                      setValue={setUnit}
                      choicesAPI={unitAPI}
                        label="Unit"
                        placeholder="Unit"
                        message={'Please select a property first'}
                        titleKey="name"
                   />   
                    <Grid className='!mt-2' container spacing={2} columns={16}>
            {buttonFields?.map((item, index) =>{
                return(
                    <Grid key={index} item xs={8} style={{ paddingTop: 0 }}>
                        <ActionButton
                        variant="contained"
                        color={buttons.value === item.value? 'primary':'secondary'}
                        title={item.label || 'Secondary'}
                        onClick={() =>{
                            setButtons(item)
                        }}
                        style={{marginRight:0, marginLeft:0, width:'100%', marginTop:'12px', marginButtom:'12px',padding:'20px'}}
                        />
                    </Grid>
                )
            })}
        </Grid>
            <ActionButton
            variant="contained"
            color={buttons.value === endButton?.value? 'primary':'secondary'}
            title={endButton.label || 'Secondary'}
            onClick={() =>{
                setButtons(endButton)
            }}
            style={{marginRight:0, marginLeft:0, width:'100%', marginTop:'12px', marginButtom:'12px',padding:'20px'}}
            />
          </div>
          <div className="w-full flex justify-end px-8 py-8">
                    <SecondaryBtn style={{ backgroundColor: '#EDECF9', boxShadow: 'none', 
                                fontWeight:300,
                                padding:'6px 16px' }} onClick={handleClose} title='Cancel' />
                    <SecondaryBtn style={{ backgroundColor: '#EDECF9', color:'#FF5C5C',  boxShadow: 'none', 
                                fontWeight:300,
                                padding:'6px 16px' }} onClick={onReset} title='Reset' />
                    <PrimaryBtn title='Filter' 
                    style={{
                        marginRight: '0px !important',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px'
                    }} onClick={() =>{
                        onSubmit({
                            buttonSelection: buttons?.value
                        })
                    }}/>
                </div>
        </FormModal>
    )
}

export default FilterModal