// ----------------- Documentation ---------------------------
// this component used for show the data from specification group list within the table the table handels edit and delete the data from the firebase database


import React, { useEffect, useState } from "react";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';

import DataGridTable from "../../../../shared-component/table-component/DataGridTable";
import EmptyCard from "../../../../shared-component/card-component/EmptyCard";

import AddGroup from "./AddGroup";

import RegularCheckbox from "../../../../shared-component/OverviewPage/overview-used-components/RegularCheckbox";
import DeleteIcon from '../../../../../asset/icons/DeleteIocn';
import TextBtn from "../../../../shared-component/button-component/TextBtn";


function SpecificationGroups({
  specificationGroups,
  handelDeleteModal,
  handleGroupEditData
}){
    const [openEditForm, setOpenEditForm] = useState(false);
    const [rowData, setRowData] = useState([])
    // console.log('rowData', rowData)
    function handleEditformopen(){
      setOpenEditForm(!openEditForm)
    }
    
    const columns = [
      {
        field: 'checkbox',
        headerName: '',
        minWidth: 50,
        headerClassName: 'custom-header',
        renderCell: (params) => (
            <RegularCheckbox />
        ),
    },
      { field: 'id', headerName: 'ID',width:180},
        { field: 'name', headerName: 'Group Name',width:180, renderCell:(params) =>{
          if(params?.row?.name === 'f2cc91d1-6c12-4a22-af81-e4a4aa0ba929'){
            return 'Location Details'
          }else{
            return params?.row?.name || '-'
          }
        }},
        
        {
          field: 'link_templates',
          headerName: 'Linked Template',
        //   type: 'number',
        width:250,
        renderCell: (params) => (
         params?.row.template_names?.join() || '-'
            ),
        },
        // {
        //   field: 'edit',
        //   headerName: '',
        // //   type: 'number',
        //     width:200,
        //     renderCell: (params) => (
        //       <div></div>
        //         ),
        // },
          {
            field: 'actions',
            headerName: '',
            sortable: false,
            width: 150,
            align:'right',
            renderCell: (params) => (
                <>
                  <div className='w-full flex items-center px-2 cursor-pointer text-right justify-end'>
                  <TextBtn 
                   style={{
                    fontWeight: 300,
                 }}
                  title='Edit' onClick={()=>{
                      handleGroupEditData(params?.row)
                    }} />
                          <div onClick={() => { handelDeleteModal(params.row,'Group') }}>
                        <DeleteIcon color="error" className="!fill-[#FF5C5C]"  />
                        </div>
                    </div>
                </>
                ),
          },
      ];

   
    return(
        <>
         {specificationGroups.length === 0 ? 
            <EmptyCard icon={<ContentPasteGoIcon fontSize='large' />} title='No Group Added' subtitle='Click “Add New” to add the Group' /> 
        :
            <DataGridTable columns={columns} rows={specificationGroups} checkboxSelection={false} />
         }
         {openEditForm && (
          <AddGroup 
            edit
            editTitle='Edit Group'
            open={openEditForm} 
            onClose={handleEditformopen} 
            data={rowData}
            setOpenEditForm={setOpenEditForm}
          />
         )}
        </>
    )
}
export default SpecificationGroups;