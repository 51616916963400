// ----------------- Documentation ---------------------------
// this component used for show the data from Property type list within the table the table handels edit and delete the data from the firebase database

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import { BoxEffects, headerButton } from "../../../../Config/Constants";
import BreadCrumbWithActions from "../../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import DataGridTable from "../../../shared-component/table-component/DataGridTable";
import Tabs from "@assystant/mui-tabs";
import { PrimaryButton, SecondaryButton } from "@assystant/mui-buttons";
import AddPropertyType from "./AddPropertyType";


const columns = [
  { field: "property_Name", headerName: "Object Name", width: 500 },
  { field: "linked_Groups", headerName: "Object Type", width: 500 },
];

function PropertyType() {
  const [value, setValue] = useState("1");
  const [propertyType, setPropertyType] = useState([]);
  const [openPropertyType, setOpenPropertyType] = useState(false);
  const arr1 = [{ title: "Poperty Types", url: "/" }];

  const tabs = [
    {
      label: "Overview",
      value: "1",
      page: <DataGridTable rows={propertyType} columns={columns} />,
    },
    { label: "Groups", value: "2", page: "" },
  ];

  function handelAddPropertyType() {
    setOpenPropertyType(!openPropertyType);
  }

  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: "secondary.main",
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          <SecondaryButton style={{backgroundColor:'#EDECF9',  fontWeight:400}} title="Filter" />
          <PrimaryButton title="Add New" onClick={handelAddPropertyType} style={{
             fontWeight:400
          }} />
        </BreadCrumbWithActions>
      </Box>
      <Box
        sx={{
          border: 1,
          borderColor: "divider",
          margin: BoxEffects.margin,
          position: "relative",
        }}
      >
        <Tabs tabs={tabs} setValue={setValue} value={value} />
      </Box>
      {openPropertyType && (
        <AddPropertyType
          title="Add Property Type"
          open={openPropertyType}
          onClose={handelAddPropertyType}
          setOpenPropertyType={setOpenPropertyType}
        />
      )}
    </>
  );
}
export default PropertyType;
