import React from 'react';

const DragIndicator = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_8659_10371"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect
          y="24"
          width="24"
          height="24"
          transform="rotate(-90 0 24)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_8659_10371)">
        <path
          d="M20 15C20 15.55 19.8043 16.0207 19.413 16.412C19.021 16.804 18.55 17 18 17C17.45 17 16.979 16.804 16.587 16.412C16.1957 16.0207 16 15.55 16 15C16 14.45 16.1957 13.979 16.587 13.587C16.979 13.1957 17.45 13 18 13C18.55 13 19.021 13.1957 19.413 13.587C19.8043 13.979 20 14.45 20 15ZM20 9C20 9.55 19.8043 10.0207 19.413 10.412C19.021 10.804 18.55 11 18 11C17.45 11 16.979 10.804 16.587 10.412C16.1957 10.0207 16 9.55 16 9C16 8.45 16.1957 7.979 16.587 7.587C16.979 7.19567 17.45 7 18 7C18.55 7 19.021 7.19567 19.413 7.587C19.8043 7.979 20 8.45 20 9ZM14 15C14 15.55 13.804 16.0207 13.412 16.412C13.0207 16.804 12.55 17 12 17C11.45 17 10.979 16.804 10.587 16.412C10.1957 16.0207 10 15.55 10 15C10 14.45 10.1957 13.979 10.587 13.587C10.979 13.1957 11.45 13 12 13C12.55 13 13.0207 13.1957 13.412 13.587C13.804 13.979 14 14.45 14 15ZM14 9C14 9.55 13.804 10.0207 13.412 10.412C13.0207 10.804 12.55 11 12 11C11.45 11 10.979 10.804 10.587 10.412C10.1957 10.0207 10 9.55 10 9C10 8.45 10.1957 7.979 10.587 7.587C10.979 7.19567 11.45 7 12 7C12.55 7 13.0207 7.19567 13.412 7.587C13.804 7.979 14 8.45 14 9ZM8 15C8 15.55 7.804 16.0207 7.412 16.412C7.02067 16.804 6.55 17 6 17C5.45 17 4.97933 16.804 4.588 16.412C4.196 16.0207 4 15.55 4 15C4 14.45 4.196 13.979 4.588 13.587C4.97933 13.1957 5.45 13 6 13C6.55 13 7.02067 13.1957 7.412 13.587C7.804 13.979 8 14.45 8 15ZM8 9C8 9.55 7.804 10.0207 7.412 10.412C7.02067 10.804 6.55 11 6 11C5.45 11 4.97933 10.804 4.588 10.412C4.196 10.0207 4 9.55 4 9C4 8.45 4.196 7.979 4.588 7.587C4.97933 7.19567 5.45 7 6 7C6.55 7 7.02067 7.19567 7.412 7.587C7.804 7.979 8 8.45 8 9Z"
          fill="#B8B7C5"
        />
      </g>
    </svg>
  );
};

export default DragIndicator
