import React from "react";
import ActionButton from "./ActionButton";

function TextBtn(props){
    return(
        <ActionButton
            {...props}
            variant="text"
            color="primary"
            title={props.title || 'Text'}
        />
    )
}
export default TextBtn;