
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useFormContext } from 'react-hook-form';
import { elevation } from '../../../Config/Constants';
import UserContext from '../../../context/UserContext';
import StatusDropDown from '../statusDropDown/StatusDropDown';

const StyledMenu = styled((props) => (
     <Menu
    elevation={elevation.elevation}
    anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
    }}
     transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
     }}
     {...props}
     />
))(({ theme }) => ({
     '& .MuiPaper-root': {
     borderRadius: 6,
     marginTop: theme.spacing(1),
     minWidth: 180,
     color:
     theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
     boxShadow:
     'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
     '& .MuiMenu-list': {
     padding: '4px 0',
     },
     '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
   },
   '&:active': {
    backgroundColor: alpha(
     theme.palette.primary.main,
     theme.palette.action.selectedOpacity,
    ),
   },
  },
 },
}));



function QuickMenuButton({ name, label, fieldProps= {}, sx }) {
 const { setValue, getValues } = useFormContext() 
 const [anchorEl, setAnchorEl] = React.useState(null);
 const open = Boolean(anchorEl);
 const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
 };
  console.log('from disableed', fieldProps)
 return (
  <div>
   <Button
    disableElevation
    onClick={handleClick}  
    id="demo-customized-button"
    
    sx={sx}
   >
    <StatusDropDown value="Available" name={name} options={fieldProps?.defaultOptions} disabled={fieldProps?.isDisable} style={{backgroundColor:'#004FE8',color:'white',height:'20px'}}/>
   </Button>
  
  </div>
 );
}

export default QuickMenuButton
