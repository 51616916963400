import { useState } from 'react';
import SpotMink from '../../../asset/Images/SpotMink.png';
import PublicGroup from '../../../asset/Images/PublicGroup.png';
import AuthLayout from '../../shared-component/AuthLayoutPage/AuthLayout';

import { apiService } from '../../../constant/AuthConstant';

import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import returnError from '../../../utils/ExportError';
import useExit from '../../../utils/useExit';
import { TokenStorage } from '../../../constant/AuthConstant';
 

function SignUp() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const referralCode = queryParams.get('referral');
  const pricing_plan = queryParams.get('pricing_plan');
  const price = queryParams.get('price');

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const { exitFromPlatform } = useExit();

  const formValues = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  };

  const schema = yup.object().shape({
    first_name: yup.string().required('Please enter first name'),
    last_name: yup.string().required('Please enter last name'),
    email: yup
      .string()
      .email('You must enter a valid email')
      .required('You must enter a email'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(
        8,
        'Must contain min 8 characters with one lower case letter, one upper case letter and numbers',
      )
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        'Must contain min 8 characters with one lower case letter, one upper case letter and numbers',
      ),
  });

  const field = [
    { id: 1, label: 'First Name', type: 'text', value: '', name: 'first_name' },
    { id: 2, label: 'Last Name', type: 'text', value: '', name: 'last_name' },
    { id: 1, label: 'Email', type: 'email', value: '', name: 'email' },
    { id: 2, label: 'Password', type: 'password', value: '', name: 'password' },
  ];

  const navigateToPricing = () =>{
   
    if(pricing_plan && typeof pricing_plan === 'string'){
      navigate(`/pricing/?pricing_plan=${pricing_plan}`)
    }else{
      navigate(`/pricing`)
    }
  }

  const onSumbitForSignUp = (data) => {
    if (referralCode?.length > 0) {
      data.referral_code = referralCode?.replaceAll('/', '');
    }
    setLoading(true);
    apiService
      .post(`account/signup/`, data)
      .then((response) => {
        setLoading(false);
        enqueueSnackbar('Registered Successfully', { variant: 'success' });
       
        if(response?.data?.access){
          localStorage.clear();
          TokenStorage.storeToken(response.data.access)
          TokenStorage.storeRefreshToken(response.data.refresh)
          localStorage.setItem('profile', response.data)
          TokenStorage.storeProfileData(response.data)
         // navigateToPricing();
        }else{
          navigate('/')
        }
        // setTimeout(() => {
        //   enqueueSnackbar('Please select a plan', { variant: 'success' });
        // }, 2000);
       // navigateToPricing(`${data?.first_name} ${data?.last_name}`, data?.email);
      })
      .catch((error) => {
        setLoading(false);
        console.log('from signup error', error.response);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  return (
    <>
      <AuthLayout
          companyLogo={SpotMink}
          publicSideImage={PublicGroup}
          fields={field}
          formValues={formValues}
          onSubmit={onSumbitForSignUp}
          actionTitle="Sign Up"
          pageTitle="Sign Up"
          schema={schema}
          isSubmitting={loading}
          addtionaEndText={'Go back to'}
          additionalEndLink={'Login'}
          endLink={'/'}
          bottomFooterFormTerms={() =>{
            return <div className="text-base font-normal text-center my-4">
            By signing up, I accept the 
            <span className="text-base text-[#004FE8] font-normal cursor-pointer mr-1.5" onClick={() =>{
              navigate('/terms-conditions')
            }}>
              Terms of Service
            </span>
             and 
            <span className="text-base text-[#004FE8] font-normal cursor-pointer" onClick={() =>{
              navigate('/privacy-policy')
            }}>
              Privacy Policy
            </span>
            .
          </div>
          }}
        />
    </>
  );
}
export default SignUp;
