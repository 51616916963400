 
import React, {useEffect, useState} from "react";
import { countryCodes } from "../../../Config/Constants";
import NativeSelectComponent from "../../components/forms/component/FormBuilderComponent/NativeSelectComponent";
import { TextField, InputAdornment } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ToolTipComponent from "../Tooltip/ToolTipComponent";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const PhoneField = ({
   name,
   error,
   helperText = "",
   label,
   tooltip
}) =>{
    const {
        setValue,
        getValues
    } = useFormContext()
    const [selectedCode, setSelectedCode] = useState("+91")
    useEffect(() =>{
       console.log('dk..........', error, selectedCode, getValues(name))
    }, [error, selectedCode])
    return (
        <TextField 
        className='mx-4 w-full'
        label={label}
        sx={{
         background: 'white',
         borderBottom: "none !important",
         "& .MuiInputBase-root.MuiInput-root:before": {
           borderBottom: "none !important",
           borderColor: "white !important"
         },
       }}
       defaultValue={getValues(name)}
       onChange={(e) =>{
          const value = `${selectedCode}${e.target.value}`
          setValue(name, value)
       }}
       placeholder='Phone'
       disabled={false}
       InputProps={{
         startAdornment: (
             <InputAdornment position="start" className='!pl-0'>
                  <NativeSelectComponent 
                    value={selectedCode}
                    disabled={false}
                    options={countryCodes}
                    onChange={(e) => {
                        setSelectedCode(e.target.value)
                    }}
                  />
             </InputAdornment>
         ),
         endAdornment: (
          <>
          {tooltip !== '' && (
            <ToolTipComponent title={tooltip} offset={[50, -50]}>
            <ErrorOutlineIcon color='#FFFFFF' className='!fill-[#8E8CA3]' fontSize='20' />
        </ToolTipComponent>
          )}
        </>
        )
     }}
     error={error}
     helperText={helperText}
     />
    )
}

export default PhoneField