import { Card, Grid, Typography } from '@mui/material';
import { CardRad } from '../../../Config/Constants';
import FieldWrapper from './FieldWrapper';
import FormFieldMapper from './FormFieldMapper';

const SubBoxLayout = ({
  name,
  label,
  fieldProps = {},
  cols = 1,
  borderType = 'border',
}) => {
  const { arrayFields } = fieldProps;
  return (
   label !== '' && (
    <FieldWrapper cols={cols}>
    <Card
      className={borderType === 'none' ? "mb-1 mt-1 px-0 rounded-sm" : "mb-1 mt-1 p-8 rounded-sm"}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      width="100%"
      sx={borderType === 'none' ? {
        boxShadow:'none',
        border:'none',
      } : {
        ...CardRad,
        borderRadius:'8px'
      }}
    >
      <div className='mb-4'>
      <Typography sx={{fontWeight:400, fontSize:'15px'}} variant='subtitle 1'>{label === "f2cc91d1-6c12-4a22-af81-e4a4aa0ba929" ? "Location Details" : label}</Typography>
      </div>
      
      <Grid container spacing={2} columns={16} style={{ paddingTop: 8, marginTop: -16 }}>
              {arrayFields.map(FormFieldMapper)}
      </Grid>
    </Card>
  </FieldWrapper>
   )
  );
};
export default SubBoxLayout;