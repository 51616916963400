import React from 'react'

const WebsiteIcon = ({
    color = 'white'
}) =>{
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 8.75C17.1685 8.75 16.8505 8.8817 16.6161 9.11612C16.3817 9.35054 16.25 9.66848 16.25 10V20C16.25 20.3315 16.3817 20.6495 16.6161 20.8839C16.8505 21.1183 17.1685 21.25 17.5 21.25H22.5C22.8315 21.25 23.1495 21.1183 23.3839 20.8839C23.6183 20.6495 23.75 20.3315 23.75 20V10C23.75 9.66848 23.6183 9.35054 23.3839 9.11612C23.1495 8.8817 22.8315 8.75 22.5 8.75H17.5ZM21.25 11.25H18.75V18.75H21.25V11.25Z" fill={color}/>
           <path d="M7.5 8.75C7.16848 8.75 6.85054 8.8817 6.61612 9.11612C6.3817 9.35054 6.25 9.66848 6.25 10C6.25 10.3315 6.3817 10.6495 6.61612 10.8839C6.85054 11.1183 7.16848 11.25 7.5 11.25H12.5C12.8315 11.25 13.1495 11.1183 13.3839 10.8839C13.6183 10.6495 13.75 10.3315 13.75 10C13.75 9.66848 13.6183 9.35054 13.3839 9.11612C13.1495 8.8817 12.8315 8.75 12.5 8.75H7.5ZM7.5 13.75C7.16848 13.75 6.85054 13.8817 6.61612 14.1161C6.3817 14.3505 6.25 14.6685 6.25 15C6.25 15.3315 6.3817 15.6495 6.61612 15.8839C6.85054 16.1183 7.16848 16.25 7.5 16.25H12.5C12.8315 16.25 13.1495 16.1183 13.3839 15.8839C13.6183 15.6495 13.75 15.3315 13.75 15C13.75 14.6685 13.6183 14.3505 13.3839 14.1161C13.1495 13.8817 12.8315 13.75 12.5 13.75H7.5ZM6.25 20C6.25 19.6685 6.3817 19.3505 6.61612 19.1161C6.85054 18.8817 7.16848 18.75 7.5 18.75H12.5C12.8315 18.75 13.1495 18.8817 13.3839 19.1161C13.6183 19.3505 13.75 19.6685 13.75 20C13.75 20.3315 13.6183 20.6495 13.3839 20.8839C13.1495 21.1183 12.8315 21.25 12.5 21.25H7.5C7.16848 21.25 6.85054 21.1183 6.61612 20.8839C6.3817 20.6495 6.25 20.3315 6.25 20Z" fill={color}/>
           <path fill-rule="evenodd" clip-rule="evenodd" d="M5 3.75C4.00544 3.75 3.05161 4.14509 2.34835 4.84835C1.64509 5.55161 1.25 6.50544 1.25 7.5V22.5C1.25 23.4946 1.64509 24.4484 2.34835 25.1517C3.05161 25.8549 4.00544 26.25 5 26.25H25C25.9946 26.25 26.9484 25.8549 27.6517 25.1517C28.3549 24.4484 28.75 23.4946 28.75 22.5V7.5C28.75 6.50544 28.3549 5.55161 27.6517 4.84835C26.9484 4.14509 25.9946 3.75 25 3.75H5ZM25 6.25H5C4.66848 6.25 4.35054 6.3817 4.11612 6.61612C3.8817 6.85054 3.75 7.16848 3.75 7.5V22.5C3.75 22.8315 3.8817 23.1495 4.11612 23.3839C4.35054 23.6183 4.66848 23.75 5 23.75H25C25.3315 23.75 25.6495 23.6183 25.8839 23.3839C26.1183 23.1495 26.25 22.8315 26.25 22.5V7.5C26.25 7.16848 26.1183 6.85054 25.8839 6.61612C25.6495 6.3817 25.3315 6.25 25 6.25Z" fill={color}/>
        </svg>
    )
}

export default WebsiteIcon