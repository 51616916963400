// ----------------- Documentation ---------------------------
// this component used for show the data from leads list within the table the table handels edit and delete the data from the firebase database


import React, { useContext, useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";

import { BoxEffects, headerButton } from "../../../Config/Constants";
import BreadCrumbWithActions from "../../shared-component/breadcrumb-component/BreadCrumbWithActions";

import { PrimaryButton } from "@assystant/mui-buttons";
import AddLeads from "./AddLeads";

import Loading from '../../shared-component/Loading/Loading'
import SearchBar from '../../shared-component/searchbar/SearchBar'
import UserContext from "../../../context/UserContext";

import { apiService } from "../../../constant/AuthConstant";
import returnError from "../../../utils/ExportError";
import { enqueueSnackbar } from "notistack";

import HistoryTable from "../../shared-component/table-component/HistoryTable";
import NotesModal from "./NotesModal";
import RemovePopup from "../../shared-component/RemovePopup/RemovePopup";

import SecondaryBtn from "../../shared-component/button-component/SecondaryBtn";
import AnalysisFilter from "../analysis/AnalysisFilter";
import EmptyCard from "../../shared-component/card-component/EmptyCard";
import { Iron } from "@mui/icons-material";
import useExit from "../../../utils/useExit";
import FilterModal from "./FilterModal";
import getDateAgo from "../../../utils/getDateAgo";
import { useNavigate } from "react-router-dom";
import DetailSideDrawer from "../ParentComponents/SideDrawer/DetailSideDrawer";
import PrimaryBtn from "../../shared-component/button-component/PrimaryBtn";


function Leads({property}) {
  const [openAddLead, setOpenAddLead] = useState(false);
  const [loading, setLoading] = useState(false)
  const [leadList, setLeadList] = useState([]);
  const [fetch, setFetch] = useState(false)
  const arr1 = [{ title: "Leads", url: "/" }];
  const [selectedLead, setSelectedLead] = useState(null);
  const {orgId} = useContext(UserContext);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [openSpecificationFilter, setOpenSpecificationFilter] = useState(false);
  const { exitFromPlatform } = useExit();

  const [selectedFoundBy, setSelectedFoundBy] = useState({name:'', value:'', id:''});
  const [selectedProp, setSelectedProp] = useState({name:'', value:'', id:''});
  const [selectedUnit, setSelectedUnit] = useState({name:'', value:'', id:''});
  const [selectedEmployee, setSelectedEmployee] = useState({name:'', value:'', id:''});
  const [selectedButton, setSelectedButton] = useState({
    id:3,
    label:'Last 7 days',
    value:'7',
},);

const [sideDrawer, setSideDrawer] = useState(false)
const [selectedForm, setSelectedForm] = useState(null);
const [propertyLoading, setPropertyLoading] = useState(false)
const [propertyDetails, setPropertyDetails] = useState(null);
const [selectedProperty, setSelectedProperty] = useState(null);
const [unitLoading, setUnitLoading] = useState(false)
const [unitDetails, setUnitDetails] = useState(null)
const [selectedInventory, setSelectedInventory] = useState(null);
const [bookingData, setBookingData] = useState(null);
const [unitStatus, setUnitStatus] = useState(null)

const handleSideDrawer = () => {
  setSideDrawer(!sideDrawer)
}

const openDrawerWithFormDetails = (form) =>{
  setSelectedProperty(null)
  setSelectedInventory(null)
  setSelectedForm(form)
  handleSideDrawer()
}

const openDrawerWithPropertyDetails = (pro) =>{
  setSelectedForm(null)
  setSelectedInventory(null)
  setSelectedProperty(pro)
  handleSideDrawer()
}

const openDrawerWithUnitDetails = (uni) =>{
  setSelectedProperty(null)
  setSelectedForm(null)
  setSelectedInventory(uni)
  handleSideDrawer()
}

const navigate = useNavigate()

  
  const buttonFields = [
    {
        id:1,
        label:'Today',
        value:'today',
    },
    {
        id:2,
        label:'Yesterday',
        value:'yesterday',
    },
    {
        id:3,
        label:'Last 7 days',
        value:'7',
    },
    {
        id:4,
        label:'Last 30 days',
        value:'30',
    },
    {
        id:5,
        label:'Last 90 days',
        value:'90',
    },
    {
        id:6,
        label:'Last 180 days',
        value:'180',
    },
  ]

  //for filter

  
  const defaultValuesForFilter = {
    found_by: '',
    unit: '',
    property: '',
    buttonSelection: '',
}

  const fieldMapForFilter = [
    {
        name:'found_by',
        label:'Found By',
        placeholder:'Found By',
        type:'select',
        fieldProps:{
            defaultOptions: [
                {name: "User Completed", id: "USER_SUBMITTED"},
                {name: "Staff Added", id: "STAFF_ADDED"},
                {name: "AI Found", id: "USER_DRAFT"},
            ],
            titleKey:'name',
            setState: setSelectedFoundBy
        }
    },
    {
      name:'property',
      label:'Property name',
      placeholder:'Property name',
      type:'select',
      fieldProps:{
        choicesAPI:"/property/prop/?filtered_data=true",
        titleKey:'name',
        setState: setSelectedProp
    }
  },
  {
    name:'unit',
    label:'Unit name',
    placeholder:'Unit Name',
    type:'select',
    fieldProps:{
      choicesAPI: !selectedProp?.id ? 'noAPI' : `/property/unit/?filtered_data=true&property=${selectedProp?.id}`,
      titleKey:'name',
      beforeCallMessage:'Please select valid property that have units',
  }
  },
    {
        name:'buttonSelection',
        label:'Today',
        placeholder:'Today',
        type:'button',
        fieldProps:{
          value:'today',
          buttonFields: buttonFields,
        }
       
    },
  ]
  const [defaultValues, setDefaultValues] = useState(defaultValuesForFilter);
  const [fieldsForFilter, setFieldsForFilter] = useState(fieldMapForFilter);
  const [formSubmissionList, setFormSubmissionsList] = useState([]);
  
  const [newLeadList, setNewLeadList] = useState([])
  
  const handleInputValue = (e) =>{
    if(e.target.value === ''){
      setNewLeadList(leadList)
    }else{
      const filterData = leadList?.filter((val) =>{
        return val?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val?.email?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val?.property?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val?.unit?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val?.employee?.name?.toLowerCase().includes(e.target.value.toLowerCase())
      })
      setNewLeadList(filterData)
    }
  }

  const toggleNotesModal = (data) => {
    setSelectedLead(data)
    setOpenNotesModal(!openNotesModal);
  }

  const toggleRemoveModal = (id) =>{
    setSelectedLeadId(id)
    setOpenRemoveModal(!openRemoveModal)
  }

  const handelSpecificationFilter = () => {
    console.log('from filter', defaultValues)
    setOpenSpecificationFilter(!openSpecificationFilter);
  };

  const headersForHistoryTable = [
    { id: "1", 
      header_name: "Name", 
      minWidth: 150 },
    { id: "2", 
      header_name: "Email", 
      minWidth: 180 },
    { id: "3", 
      header_name: "Phone No", 
      minWidth: 150 },
    { id: "4", 
      header_name: "Date & Time", 
      minWidth: 150 },
    { id: "5",  
      header_name: "Property", 
      minWidth: 150 },
    { id: "6", 
      header_name: "Unit", 
      minWidth: 150 },
    { id: "7", 
      header_name: "Source", 
      minWidth: 150 },
    { id: "8", 
      header_name: "Updated By", 
      minWidth: 200 },
  ];


const newArr = property?.map((val,index)=> { return {title:val.property_name,id:index+1}})
  const handelAddLead = () => {
    setSelectedLead(null)
    setOpenAddLead(!openAddLead);
  };


  const getFormSubmissions = () =>{
    apiService.get(`form_integration/submissions`).then((res) =>{
      setFormSubmissionsList(res?.data)
      localStorage.removeItem('form-tab')
      setLoading(false)
    }).catch((error) =>{
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setLoading(false)
          }
        }
    })
  }

  const fetchleadlist = () => {
     setLoading(true)
     apiService.get(`leads/prospect/`).then((res) =>{
      console.log(res)
      if(res?.data){
        const newList = res?.data?.map((e) =>{
          if(e?.length > 1){
            const arrayData = [...e];
            arrayData.shift();
            return {
              ...e[0],
              history: arrayData,
            }
          }else{
            return {
              ...e[0],
              history: []
            }
          }
        })
        setLeadList(newList)
        setNewLeadList(newList)
        getFormSubmissions()
      }
      setLoading(false)
     }).catch((error) =>{
      setLoading(false)
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
      }
    }
     })
  }
   console.log('from lead list', leadList, newLeadList)
    useEffect(() => {
    fetchleadlist();
  }, []);

  const handelDeleteModal = () => {
     apiService.delete(`leads/prospect/${selectedLeadId}/`).then((res) =>{
      enqueueSnackbar('Lead Deleted Successfully', {variant:'success'})
      fetchleadlist()
      setOpenRemoveModal(!openRemoveModal)
     })
     .catch((error) =>{
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
      }
      }})
  }

  const handleEditLead = (data) =>{
    setSelectedLead(data)
    setOpenAddLead(true)
  }

  const runOnSelectedFoundBy = (e) =>{
    if(selectedFoundBy?.id === "STAFF_ADDED"){
      const newDefaultValues = {
        ...defaultValues,
        employee: '',
      }
      setDefaultValues(newDefaultValues)
      const allFields = [...fieldsForFilter];
      const check = allFields.some((e) => e.name === 'employee');
      if(check === false){
        allFields.splice(1, 0, {
          name:'employee',
          label:'Employee',
          placeholder:'Employee',
          type:'select',
          fieldProps:{
            choicesAPI:"/account/user/",
            titleKey:'full_name',
          }
        })
        setFieldsForFilter(allFields)
      }
    }
    if(selectedFoundBy?.id !== "STAFF_ADDED"){
      const newDefaultValues = {
        ...defaultValues,
      }
      delete newDefaultValues?.employee;
      setDefaultValues(newDefaultValues)
      const filterItems = fieldsForFilter.filter((e) => e.name !== 'employee')
      setFieldsForFilter(filterItems)
    }
  }

  useEffect(() =>{
    if(selectedFoundBy?.id){
      runOnSelectedFoundBy()
    }
  }, [selectedFoundBy])

  useEffect(() =>{
     if(selectedProp?.id){
      const allFields = [...fieldsForFilter];
      if(allFields?.length){
        setFieldsForFilter(allFields)
      }
     }
  }, [selectedProp])

const modifyLeadData = (data) =>{
  console.log('from modify lead data', data)
   const newData = {
    ...data,
    property: data?.property?.id ? data?.property : '',
    unit: data?.unit?.id ? data?.unit : '',
   }
   return newData
}

const returnEndDate = (buttonValue) =>{
  if(buttonValue === 'today'){
     return getDateAgo(0)
  }else if(buttonValue === 'yesterday'){
     return getDateAgo(1)
  }else if(buttonValue === '7'){
     return getDateAgo(7)
  }else if(buttonValue === '30'){
     return getDateAgo(30)
  }else if(buttonValue === '90'){
     return getDateAgo(90)
  }else if(buttonValue === '180'){
     return getDateAgo(180)
  }else{
   return getDateAgo(365)
  }
}

const onFilter = (e) =>{
  
   let newData = {
    ...e,
    property: selectedProp?.id ? selectedProp?.id : '',
    unit: selectedUnit?.id ? selectedUnit?.id : '',
    start_date: returnEndDate(e?.buttonSelection),
    end_date: e?.buttonSelection === 'yesterday' ? getDateAgo(1) : getDateAgo(0),
    updated_by: selectedFoundBy?.id ? selectedFoundBy?.id : '',
   }
    
   if(selectedFoundBy?.id === 'STAFF_ADDED'){
    newData = {
      ...newData,
      employee: selectedEmployee?.id ? selectedEmployee?.id : '',
    }
   }
   Object.entries(newData)?.map((e) =>{
    if(e[1] === '' || e[1] === null || e[1] === undefined){
      delete newData[e[0]]
    }
  })
   delete newData?.buttonSelection
   const searchParams = new URLSearchParams(newData).toString();
   
   apiService.get(`leads/prospect/?${searchParams}`)
   .then((res) =>{
    if(res?.data){
      const newList = res?.data?.map((e) =>{
        if(e?.length > 1){
          const arrayData = [...e];
          arrayData.shift();
          return {
            ...e[0],
            history: arrayData,
          }
        }else{
          return {
            ...e[0],
            history: []
          }
        }
      })
      setLeadList(newList)
      setNewLeadList(newList)
    }
    handelSpecificationFilter()
      console.log(res)
   })
   .catch((error) =>{
    if(error.response?.data){
      const returnedError = returnError(error.response?.data)
      if(returnedError === 'Given token not valid for any token type'){
        exitFromPlatform()
    }else{
        enqueueSnackbar(returnedError, {variant:'error'})
    }
    }})
}

const navigateToDetails = (id) =>{
  navigate(`/leads/${id}`, {replace: true})
}

const updateKeyName = (label) => {
  return label.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const modifyFormData = (data) =>{
  const newData = {
    ...data,
    submissions: {...JSON.parse(data?.submission)}
  }

  const newFormData = {};

  if(newData?.submissions?.sections?.length > 0){
   const update = newData?.submissions?.sections?.map((e) =>{
    const nn = {...e};
    delete nn?.source
    return {
        ...nn,
        fields: e?.fields?.filter((f) =>{
           return f?.name !== "files"
        }),
        files: e?.fields?.find((g) =>{
            if(g.name === 'files'){
                return g
            }
        })?.value
    }
   })

   newFormData["sectionDetails"] = update;
   newFormData["details"] = newData
   newFormData["independentFields"] = [];
   newFormData["getFiles"] = [];
}else{
    const formData = {...newData?.submissions};
    delete formData?.formDetails;
    delete formData?.sections;
    delete formData?.files;
    delete formData?.unit;
    delete formData?.property;
    delete formData?.source;
   
    const newnData = Object.entries(formData)?.map((m) =>{
        return {
            name: updateKeyName(m[0]),
            value: m[1]
        }
    })
    newFormData["sectionDetails"] = [];
    newFormData["independentFields"] = newnData;
     newFormData["getFiles"] = newData?.submissions?.files?.length > 0 ? [...newData?.submissions?.files] : [];
    newFormData["details"] = newData
}
console.log('from form data', newFormData)
return newFormData
}

const fetchInventory = (unitId) => {
  setUnitLoading(true);
  apiService
    .get(`property/unit/${unitId}`)
    .then((res) => {
      if (res?.data?.output_specs?.groups) {
        const newData = [...res?.data?.output_specs?.groups];

        const updateSpecsGroups = newData?.map((e) => {
          const findGroup = res?.data?.specs?.groups?.find((f) => {
            return f?.grp_id === e?.grp_id;
          });

          return {
            grp_id: e?.grp_id,
            items: e?.items?.map((f) => {
              const findItem = findGroup?.items?.find((g) => {
                return g?.item_id === f?.item_id;
              });

              return {
                ...f,
                [f?.item?.name]: findItem?.item_value,
              };
            }),
          };
        });

        let newArray = [...updateSpecsGroups];
        const newData1 = {
          ...res?.data,
          images: res?.data?.images?.map((e) =>{
            return {
              ...e,
              tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
            }
          }),
          booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),
          specs: {
            template_id: res?.data?.specs?.template_id,
            groups: newArray,
          },
        }
        setUnitDetails(newData1);
      } else {
      setUnitDetails({
        ...res?.data,
        booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),});
       }
      setBookingData(res?.data)
      setUnitStatus(res?.data?.status);
      setUnitLoading(false);
    })
    .catch((error) => {
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setUnitLoading(false);
        }
      }
    });
};


const fetchPropertyDetails = (propertyId) => {
  setPropertyLoading(true);
  apiService
    .get(`property/prop/${propertyId}/`)
    .then(async (res) => {
      setPropertyDetails({
        ...res?.data,
        image_list: res?.data?.image_list?.map((e) =>{
          return {
            ...e,
            tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
          }
        }),
      });
      setPropertyLoading(false);
    })
    .catch((error) => {
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setPropertyLoading(false);
        }
      }
  });
}

const returnFormData = () =>{
  let dataObj = {}
  if(selectedProperty?.id){
    dataObj = {
        propertyData: propertyDetails,
      }
  }
  else if(selectedInventory?.unit?.id){
    dataObj = {
      unitStatus: unitStatus,
      inventoryData: unitDetails
    }
  }
  else{
    dataObj = modifyFormData(selectedForm)
  }

  return dataObj
}

const navigateToForm = (navigateTo) =>{
  navigate(navigateTo)
}

  return (
    <>
      {loading ? 
       <Loading />
       :
       <>
       <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: "secondary.main",
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position:'sticky',
          top:'65px',
          zIndex:99,
          backgroundColor:'white'
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          {/* <SecondaryButton title='Filter' /> */}
          <div className="flex items-center">
          
          <SearchBar 
            handleSearch={handleInputValue}
          />
        <div className="ml-2">
        <SecondaryBtn
                style={{ 
                  boxShadow: 'none', 
                  backgroundColor: '#EDECF9',
                  padding:'6px 16px',
                  fontSize:'15px',
                  fontWeight:300
                 }}
                title="Filter"
                onClick={handelSpecificationFilter}
              />
        </div>
          <PrimaryBtn 
            title="Add New" onClick={handelAddLead}
            style={{
              boxShadow: 'none', 
              marginRight:'0px !important',
              fontWeight:300,
              padding:'6px 16px'
            }}
             />
          </div>
        </BreadCrumbWithActions>
      </Box>
      
        <Paper sx={{padding:'16px', margin:'12px',boxShadow:'none'}}>
          {newLeadList.length === 0 ? 
             <EmptyCard 
               title="No Lead Added" 
               subtitle="Click “Add New to add the lead"/> 
            : 
            <HistoryTable 
              rowData={newLeadList?.length === 0 ? [] : newLeadList}
              editModalToggling={handleEditLead}
              deleteModalToggling={toggleRemoveModal}
              notesModalToggling={toggleNotesModal}
              headers={headersForHistoryTable}
              navigateToDetails={navigateToDetails}
              formSubmissionList={formSubmissionList}
              openDrawerWithFormDetails={openDrawerWithFormDetails}
              openDrawerWithUnitDetails={openDrawerWithUnitDetails}
              openDrawerWithPropertyDetails={openDrawerWithPropertyDetails}
              />
            }
            </Paper>
       </>}
      {openAddLead && (
        <AddLeads 
          orgId={orgId} 
          setFetch={setFetch} 
          newArr={newArr} 
          title={selectedLead === null ? 'Add Lead' : 'Edit Lead'}
          open={openAddLead} 
          onClose={handelAddLead} 
          setOpenAddLead={setOpenAddLead}
          data={selectedLead === null ? {} : modifyLeadData(selectedLead)} 
          successCallback={fetchleadlist}
          />
      )}
      {openNotesModal && (
        <NotesModal
          title={'Notes'}
          open={openNotesModal}
          onClose={toggleNotesModal} 
          data={selectedLead}
        />
      )}

      {openRemoveModal && (
        <RemovePopup 
          isSecondary={true}
          open={openRemoveModal}
          close={toggleRemoveModal}
          onSubmit={handelDeleteModal}
          description={'Are you sure you want to remove this lead?'}
        />
      )}

      {openSpecificationFilter && (
        <FilterModal 
          title="Filter"
          foundBy={selectedFoundBy}
          setFoundBy={setSelectedFoundBy}
          property={selectedProp}
          setProperty={setSelectedProp}
          employee={selectedEmployee}
          setEmployee={setSelectedEmployee}
          buttons={selectedButton}
          setButtons={setSelectedButton}
          unit={selectedUnit}
          setUnit={setSelectedUnit}
          buttonFields={buttonFields}
          open={openSpecificationFilter}
          handleClose={handelSpecificationFilter}

          onSubmit={onFilter}
        />
      )}

       {sideDrawer && (
           <DetailSideDrawer 
           sideTitle={selectedInventory?.unit?.id ? `See in Units` : (selectedProperty?.id ? `See in Properties` : "See in Forms")}
           sideButtonClickHandler={() =>{
             if(selectedProperty?.id){
                 navigateToForm(`/properties/${selectedProperty?.id}`)
            }else if(selectedInventory?.unit?.id){
              localStorage.setItem('property_name', JSON.stringify(selectedInventory?.property?.name))
                 navigateToForm(`/properties/${unitDetails?.id}/inventory/${selectedInventory?.unit?.id}`)
            }else{
               if(selectedForm?.id){
                 navigateToForm(`/form-detail-preview/${selectedForm?.id}`)
               }
            }
            
           }}
           title={selectedInventory?.unit?.id ? `Unit - ${unitDetails?.name || ''}` : (selectedProperty?.id ? `Property - ${propertyDetails?.name || ''}` : `Form Submission ${selectedForm?.id}`)}
              isFormComponent={selectedProperty?.id || selectedInventory?.unit?.id  ? false : true}
              open={sideDrawer}
              onClose={handleSideDrawer}
              formData={returnFormData()}
              isPropertyComponent={selectedProperty?.id ? true : false}
              loading={selectedProperty?.id ? propertyLoading : unitLoading}
              fetchCallback={() =>{
                 if(selectedProperty?.id){
                    fetchPropertyDetails(selectedProperty?.id)
                 }else{
                    if(selectedInventory?.unit?.id){
                      fetchInventory(selectedInventory?.unit?.id)
                    }
                 }
              }}
              isUnitComponent={selectedInventory?.unit?.id ? true : false}
           />
       )}
    </>
  );
}
export default Leads;
