// ----------------- Documentation ---------------------------
// this component used for show the data from objects list within the table the table handels edit and delete the data from the firebase database


import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import { PrimaryButton, SecondaryButton } from "@assystant/mui-buttons";
import Tabs from '@assystant/mui-tabs';
import ObjectGroup from "./ObjectGroups";
import { BoxEffects, headerButton } from "../../../../Config/Constants";
import BreadCrumbWithActions from "../../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import TextBtn from "../../../shared-component/button-component/TextBtn";
import DataGridTable from "../../../shared-component/table-component/DataGridTable";
import StatusLabel from "../../../shared-component/OverviewPage/overview-used-components/StatusLabel";
import PrimaryBtn from "../../../shared-component/button-component/PrimaryBtn";
import SecondaryBtn from "../../../shared-component/button-component/SecondaryBtn";




const columns = [
    { field: 'object_Name', headerName: 'Object Name',width:250},
    { field: 'object_Type', headerName: 'Object Type', width:100},
    { field: 'linked_Groups', headerName: 'Linked Groups',width:250},
    {
      field: 'linked_Properties',
      headerName: 'Linked Properties',
    //   type: 'number',
    width:300,
    renderCell: (params) => (
        <>
            <StatusLabel 
            status={params.formattedValue} 
            color='primary' />
        </>
        ),
    },
    {
        field: 'edit',
        headerName: '',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        // width: 160,
        renderCell: (params) => (
            <>
                <TextBtn title='edit' />
            </>
            ),
      },
  ];


function Objects(){
    const [value, setValue] = useState('1');
    const [objectoverviewList, setObjectOverviewList] = useState([]);
    const arr1 = [
        {title: 'Objects', url: '/'},
    ]

    const tabs = [
        {label:'Overview', value:'1', page:<DataGridTable rows={objectoverviewList} columns={columns} />
    },
        {label:'Groups', value:'2', page:<ObjectGroup />},
    ]

    return(
        <>
            <Box 
                sx={{
                    borderBottom: BoxEffects.borderBottom,
                    borderColor: "secondary.main",
                    padding: BoxEffects.padding,
                    marginBottom: BoxEffects.marginButton,
                    paddingLeft: BoxEffects.margin,
                    paddingRight: headerButton.marginRigt,
                  }}
            >
                <BreadCrumbWithActions propArr={arr1}>
                    <SecondaryBtn style={{backgroundColor:'#EDECF9',  fontWeight:300}} title='Filter' />
                    <PrimaryBtn title='Edit' style={{
                         fontWeight:300
                    }} />  
                </BreadCrumbWithActions>
            </Box>
            <Box 
                sx={{borderBottom: BoxEffects.borderBottom, borderColor: 'secondary.main',padding:BoxEffects.padding,marginBottom:BoxEffects.marginButton,paddingLeft:BoxEffects.margin,paddingRight:headerButton.marginRigt }}
            >
                <Tabs tabs={tabs}  setValue={setValue} value={value} />
            </Box>
        
        </>
    )
}
export default Objects;