import { Autocomplete, Chip, CircularProgress, Input, InputLabel, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import RegularCheckbox from '../OverviewPage/overview-used-components/RegularCheckbox';
import { useFormContext } from 'react-hook-form';
import { apiService } from '../../../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import ToolTipComponent from '../Tooltip/ToolTipComponent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMore from '@mui/icons-material/ExpandMore';
import returnError from '../../../utils/ExportError';
import useExit from '../../../utils/useExit';


function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Select = ({
  id,
  name,
  label = '',
  onChange,
  onBlur,
  placeholder = 'Select',
  defaultOptions = [],
  multiple = false,
  sx = {},
  value,
  disabled = false,
  //   loading = false,
  clearOnEscape = true,
  clearOnBlur = true,
  selectOnFocus = true,
  autoSelect = false,
  limitTags = 2,
  error,
  helperText,
  hint = '',
  choicesAPI = '',
  titleKey = 'title',
  dataKeyHandler = null,
  preChangeHandler = null,
  className,
  checkbox=false,
  checkboxtitle='',
  save = () => {},
  getId,
  beforeCallMessage = '',
  setState,
  tooltip = "",
  resetFields = [],
  isFilteringUnit = false,
  isCustomizingOptions = false,
  required = false
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = choicesAPI && open && options.length === 0;
  const { getValues, setValue } = useFormContext()
  const { exitFromPlatform } = useExit()

 
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    if (choicesAPI) {
     if(choicesAPI === 'noAPI'){
      enqueueSnackbar(beforeCallMessage, {variant:'error'})
     
     }else{
      
       apiService.get(choicesAPI).then((res) => {
        if(res?.data?.length > 0){
          if (active) {
            if (dataKeyHandler) {
              setOptions(dataKeyHandler(res.data));
            } else {
              if(isFilteringUnit === true){
                console.log('triggered 1')
                const list = [...res?.data]?.filter((e) =>{
                  return e?.sellable === true && e?.status === 'AVAILABLE'
                })
                
                if(list?.length > 0){
                  setOptions(list);
                }else{
                  setOptions([{id:'No Options', name:'No Options'}])
                }
              }else{
                console.log('triggered 1')
                setOptions(res.data);
              }
            }
          }
        }else{
          setOptions([{id:'No Options', name:'No Options'}])
        }
      }).catch((error) => {
        if(error.response){
          if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
          }
        }
        }
      });
     }
    }
    return () => {
      active = false;
    };
  }, [loading, choicesAPI, dataKeyHandler]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const getOptionLabel = (option) => {
    const labelList = [];
    if (option.code) labelList.push(option.code);
    if (option[titleKey]) labelList.push(option[titleKey]);
    const result = labelList.join(' - ');
    
    return result || value;
  };

  console.log('from tooltip', isCustomizingOptions, defaultOptions, value, tooltip, "AS")

  
  return (
    <>
    <div className="flex" style={{ alignItems: 'center', position:'relative' }}>
      <Autocomplete
        id={id}
        fullWidth
        name={name}
        label={label}
        defaultValue={defaultOptions[3]}
        open={open}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value || []}
        onChange={(e, newValue, reason) => {
          console.log('from reason', reason)
          if(reason === 'clear'){
            if(setState){
              setState('')
            }
            if (preChangeHandler) {
              preChangeHandler('');
            }
            onChange('');
            save('')
            if(resetFields.length) {
              resetFields.forEach(field => {
                setValue(field, '');
              })
            }
          }else{
            if(setState){
              setState(newValue)
            }
            if (preChangeHandler) {
              preChangeHandler(newValue);
            }
            onChange(newValue);
            save(newValue)

            if(resetFields.length) {
              resetFields.forEach(field => {
                setValue(field, '');
              })
            }
          }
          
        }}
        onBlur={onBlur}
        placeholder="Select"
        multiple={multiple}
        popupIcon={<ExpandMore className={disabled === true ? '!fill-[#B1AED1]' : '!fill-[#8E8CA3]'}/>}
        disablePortal={false}
        options={options && options.length ? options : defaultOptions}
        getOptionLabel={getOptionLabel}
        renderTags={(val, getTagProps) =>
          val.map((option, index) => (
            <Chip color="primary" label={option[titleKey]} {...getTagProps({ index })} />
          ))
        }
        getOptionDisabled={(option)=>{
          if(option?.name === "No Options" || option?.title === "No Options"){
              return true
          }
          console.log('from options', option)
      }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            required={required}
            placeholder={placeholder}
            InputLabelProps={{
              ...params.InputProps,
              shrink: true,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {placeholder}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        sx={{
          "& .MuiAutocomplete-endAdornment": {
            right: tooltip !== "" ? '30px !important' : "15px !important"
          },
          "& .autocomplete-dropdown": {
            position: "absolute",
            zIndex: 1000,
          }
        }}
        disabled={disabled}
        loading={loading}
        clearOnEscape={clearOnEscape}
        clearOnBlur={clearOnBlur}
        selectOnFocus={selectOnFocus}
        autoSelect={autoSelect}
        limitTags={limitTags}
        className={className}
        error={error}
        helperText={!hint ? helperText : hint}
      />
      <div style={{
        position: 'absolute',
        right:'14px'
      }}>
      {tooltip !== '' &&(
                      <ToolTipComponent title={tooltip} offset={[50, -50]}>
                        <ErrorOutlineIcon color='#FFFFFF' className='!fill-[#8E8CA3]' fontSize='20' />
                      </ToolTipComponent>
                    )}
      </div>
    </div>
    
    <div>
      {checkbox && (
          <RegularCheckbox title={checkboxtitle} />
        )}
    </div>
    </>
  );
};

export default Select;
