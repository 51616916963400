import React from 'react'

const Property = ({ color = "#8E8CA3"}) =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                <mask id="2" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
                    <rect x="0.0200195" y="0.312256" width="32" height="32" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_3270_3494)">
                    <path d="M22.6866 12.3123H25.3533V9.64559H22.6866V12.3123ZM22.6866 17.6456H25.3533V14.9789H22.6866V17.6456ZM22.6866 22.9789H25.3533V20.3123H22.6866V22.9789ZM22.6866 28.3123V25.6456H28.0199V6.97892H16.0199V8.84559L13.3533 6.91226V4.31226H30.6866V28.3123H22.6866ZM1.35327 28.3123V14.9789L10.6866 8.31226L20.0199 14.9789V28.3123H12.0199V21.6456H9.35327V28.3123H1.35327ZM4.01994 25.6456H6.6866V18.9789H14.6866V25.6456H17.3533V16.3123L10.6866 11.5789L4.01994 16.3123V25.6456Z" fill={color} />
                </g>
            </svg>
    )
}

export default Property