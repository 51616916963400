// ----------------- Documentation ---------------------------
// this component used for filter the data from specification list based on template, item type and group

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup

import React from "react";
import FormModal from "../../../shared-component/formmodal-component/FormModal";
import Form from "../../../shared-component/formrelated-component/FormManager";

function SpecificationFilter({
    title,
    open,
    onClose,
}){
    const filtervalue= {
        template:'',
        item_type:'',
        group:''
    }
    const defaultValues={
        template:'',
        item_type:'',
        group:'',
        ...filtervalue
    }
    const fieldMap=[
        {
            name:'item_type',
            label:'Item type',
            placeholder:'Item type',
            type:'select',
            fieldProps:{
                defaultOptions: [
                    {title: 5, id:''},
                ]
            }
        },
        {
            name:'group',
            label:'Group',
            placeholder:'Group',
            type:'select',
            fieldProps:{
                defaultOptions: [
                    {title: 5, id:''},
                ]
            }
        },
        {
            name:'template',
            label:'Template',
            placeholder:'Template',
            type:'select',
            fieldProps:{
                defaultOptions: [
                    {title: 5, id:''},
                ]
            }
        },
    ]
    return(
        <>
            <FormModal title={title} open={open} onClose={onClose} >
                <Form
                defaultValues={defaultValues}
                fieldMap={fieldMap}
                />
            </FormModal>
        </>
    )
}
export default SpecificationFilter;