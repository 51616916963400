import React, { useState } from "react";
import Editor from "./Editor";

function CodeEditor(){
    const [html, setHtml] = useState("")
    return(
        <>
            <div className="pane top-pane">
                <Editor 
                    language='xml'
                    displayName='HTML'
                    value={html}
                    onChange={setHtml}
                    
                />
            </div>
            {/* <div className="pane buttom-pane"></div> */}
        </>
    )
}
export default CodeEditor;