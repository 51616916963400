import React, { useContext, useEffect, useState } from "react";
 
import Loading from "../Loading/Loading";
import UserContext from "../../../context/UserContext";
import Integration from "../../components/properties/newMap/linkadd/Integration";
import { renderGallaryScript } from "../../components/properties/newMap/Script";
import { BASE_URI, apiService } from "../../../constant/AuthConstant";
import GallaryModal from "./components/GallaryModal";
import { enqueueSnackbar } from "notistack";
import useExit from "../../../utils/useExit";
import returnError from "../../../utils/ExportError";
 
import GallaryDetailModal from "./components/GallaryDetailModal";
import PropertyOverviewComponent from "../../components/ParentComponents/PropertyOverviewComponent";



function OverviewPage({
    load,
    propertyData,
    openIntegrationModal,
    handleModal,
    fetchProperty,
    userFromOrganization
}){
    const {
        selectedCurrency, 
        returnFormattedCurrency
    } = useContext(UserContext)
    const { exitFromPlatform } = useExit()
    const [openGallaryModal, setOpenGallaryModal] = useState(false);
    const [gallaryItems, setGallaryItems] = useState([]);
    const [gallaryLoading, setGallaryLoading] = useState(false);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [analyticTags, setAnalyticTags] = useState([]);

    const handleOpenDetailModal = () =>{
        setOpenDetailModal(!openDetailModal)
    }
    
    const handleGallaryModal = () =>{
        setOpenGallaryModal(!openGallaryModal)
    }
     

    const retrieveGallary = () =>{
        setGallaryLoading(true)
        apiService.get(`organization/gallery/`).then((res) =>{
            if(res?.data?.length > 0){
                const updateData = res?.data?.map((e) =>{
                    return {
                        ...e,
                        tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
                    }
                })
                setGallaryItems(updateData)
            }else{
                setGallaryItems([])
            }
            setTimeout(() =>{
                setGallaryLoading(false)
              }, 1000)
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setGallaryLoading(false)
              }
              }
        })
    }
   
    const itemData = propertyData?.image_list?.map((val) =>{
        const string = val?.image || val?.media;
        const y = string?.substring(string?.lastIndexOf('.'));
        return {
            ...val,
            tags: typeof val?.tags === 'string' ? [] : val?.tags,
            id:val.id,
            media: val.media?.substring('media', val?.media?.length),
            type: y === '.mp4' ? 'video' : (y === '.jpg' || y === '.jpeg' || y === '.png' ? 'image' : 'image'),
          }
    })

    const onClickEdit = (index) =>{
        setActiveIndex(index)
        handleOpenDetailModal()
      }

    const getAnalyticTags = () =>{
        apiService.get(`property/prop/get_spec_choices/`).then((res) =>{
        console.log('from analytic tags', res?.data)
        if(res?.data?.data?.length > 0){
           const updateOptions = res?.data?.data?.map((e) =>{
            return {name: e, id: e}
           })
           setAnalyticTags(updateOptions)
        }else{
          setAnalyticTags([])
        }
        }).catch((error) =>{
          if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
                setSubmitLoading(false)
            }
            }
      })
    }

    useEffect(() =>{
        getAnalyticTags()
    }, [])
     
    return(
        <>
            {load ? 
            <Loading/> 
            : 
            <PropertyOverviewComponent
                propertyData={propertyData}
                itemData={propertyData?.image_list}
                onClickEdit={onClickEdit}
                handleGallaryModal={handleGallaryModal}
                isReadOnly={userFromOrganization?.role?.name.toLowerCase() === 'admin' || userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true }
             />}

            {openIntegrationModal && (
              <Integration 
                open={openIntegrationModal}
                onClose={handleModal}
                text={renderGallaryScript(BASE_URI, propertyData?.id)}
              />
            )}

            {openGallaryModal && (
                <GallaryModal 
                  open={openGallaryModal}
                  loading={gallaryLoading}
                  onClose={handleGallaryModal}
                  gallaryItems={gallaryItems}
                  fetchProperty={fetchProperty}
                  retrieveGallary={retrieveGallary}
                  image_list={propertyData?.image_list}
                />
            )}

           {openDetailModal && (
                <GallaryDetailModal 
                    open={openDetailModal}
                    onClose={handleOpenDetailModal}
                    gallaryItems={itemData}
                    activeIndex={activeIndex}
                    retrieveGallary={fetchProperty}
                    analyticTags={analyticTags}
                    setSelectedTags={setSelectedTags}
                    selectedTags={selectedTags}
                />
           )}


        </>
    )
}
export default OverviewPage;