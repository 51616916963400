import React, { useEffect, useState } from 'react'
import FormModal from '../../../shared-component/formmodal-component/FormModal'
import FormManager from '../../../shared-component/formrelated-component/FormManager'
import { apiService } from '../../../../constant/AuthConstant'
import returnError from '../../../../utils/ExportError'
import useExit from '../../../../utils/useExit'
import { enqueueSnackbar } from 'notistack'
import { quickMenuConstant } from '../../../../Config/Constants'
import { useParams } from 'react-router-dom'

const AddAffiliate = ({
    open,
    title,
    onClose,
    data,
    organization,
    successCallback,
}) =>{
    const { id } = useParams()
    const { exitFromPlatform } = useExit()
    const [fields, setFields] = useState([])
    const [loading, setLoading] = useState(false)
    const defaultValues = {
        name:'',
        email:'',
        booking_commission_type:'',
        booking_commission_rate:'',
        lead_commission_type: "Fixed",
        lead_commission_rate:'',
        cookie_duration:'90',
        payout_address:'',
        active: false,
    }

    const handleFormSubmission = (e) =>{
        if(e?.name === '' && !data?.id){
            enqueueSnackbar('Name is required', {variant:'error'})
        }else if(e?.email === '' && !data?.id){
            enqueueSnackbar('Email is required', {variant:'error'})
        }
        else if(e?.booking_commission_type === ''){
            enqueueSnackbar('Please select booking commision type', {variant:'error'})
           }
        else if(e?.booking_commission_rate === ''){
        enqueueSnackbar('Please add booking commision rate', {variant:'error'})
        }
        else if(e?.lead_commission_rate === ''){
            enqueueSnackbar('Please add lead commision rate', {variant:'error'})
            }
            else if(e?.cookie_duration === ''){
                enqueueSnackbar('Please add cookie duration', {variant:'error'})
                }
                else if(e?.payout_address === ''){
                    enqueueSnackbar('Please add payout', {variant:'error'})
                    }
        else{
         let reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
         let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(emailReg.test(e?.email) === false && !data?.id){
          enqueueSnackbar('Email is invalid', {variant:'error'})
         }
        else{
            setLoading(true)
            let apiUrl;
            let service;
            if(data?.id){
                apiUrl = `affiliates/affiliates/${id}/`
                service = apiService.put
            }else{
                apiUrl = `affiliates/affiliates/`
                service = apiService.post
            }

            let payload;

            if(data?.id){
                payload = {
                    ...e,
                    booking_commission_type: e?.booking_commission_type?.id,
                    lead_commission_type: e?.lead_commission_type?.id ? e?.lead_commission_type?.id : 'fixed',
                    invitation: data?.invitation?.id,
                    organization: data?.organization?.id,
                    user: data?.user?.id ? data?.user?.id : null
                }
                delete payload?.photo
            }else{
                payload = {
                    ...e,
                    booking_commission_type: e?.booking_commission_type?.id,
                    lead_commission_type: e?.lead_commission_type?.id,
                    invitation: {
                        name: e?.name,
                        email: e?.email,
                        organization: organization?.id
                    },
                    organization: organization?.id
                }
            }
            service(apiUrl, payload).then((res) =>{
                if(data?.id){
                    enqueueSnackbar('Affiliate Updated', {variant:'success'})
                }else{
                    enqueueSnackbar('Affiliate Added', {variant:'success'})
                }
                successCallback()
                setLoading(false)
                onClose()
                console.log(res)
            }).catch((error) =>{
                setLoading(false)
                if(error.response?.data){
                    const returnedError = returnError(error.response?.data)
                    if(returnedError === 'Given token not valid for any token type'){
                        exitFromPlatform()
                    }else{
                        enqueueSnackbar(returnedError, {variant:'error'})
                    }
                }
            })
         }
        }
       
    }


    const fieldMap = [
       
        {
            name:'',
            type:'box_layout',
            label:'Commission Information',
            borderType:'none',
            fieldProps: {
                arrayFields:[
                    {
                        name:'',
                        type:'sub_box_layout',
                        label:'Booking Commission',
                        borderType:'none',
                        fieldProps: {
                            arrayFields:[
                                {
                                    name:'booking_commission_type',
                                    label:'Commission Type',
                                    placeholder:'Name',
                                    type:'select',
                                    cols:2,
                                    fieldProps:{
                                        defaultOptions: [
                                            {title: 'Fixed', id: 'fixed'},
                                            {title: 'Percent', id: 'percent'}
                                        ],
                                        required: true
                                    }
                                },
                                {
                                    name:'booking_commission_rate',
                                    label:'',
                                    placeholder:'Enter Amount',
                                    type:'text',
                                    cols:2,
                                    fieldProps: {
                                        required: true
                                    }
                                },
                            ]
                        }
                    },
                    {
                        name:'',
                        type:'sub_box_layout',
                        label:'Lead Commission',
                        borderType:'none',
                        fieldProps: {
                            arrayFields:[
                                {
                                    name:'lead_commission_type',
                                    label:'Commission Type',
                                    placeholder:'Name',
                                    type:'select',
                                    cols:2,
                                    fieldProps:{
                                        defaultOptions: [
                                            {title: 'Fixed', id: 'fixed'},
                                            {title: 'Percent', id: 'percent'}
                                        ],
                                        disabled:true,
                                        value: {title: 'Fixed', id: 'fixed'},
                                        required: true
                                    }
                                },
                                {
                                    name:'lead_commission_rate',
                                    label:'',
                                    placeholder:'Enter Amount',
                                    type:'text',
                                    cols:2,
                                    fieldProps: {
                                        required: true
                                    }
                                },
                            ]
                        }
                    },
                ]
            }
        },
        {
            name:'',
            type:'box_layout',
            label:'Cookies Duration',
            borderType:'none',
            fieldProps: {
                arrayFields:[
                    {
                        name:'cookie_duration',
                        label:'Cookies Duration (Days)',
                        placeholder:'In Days',
                        type:'text',
                        fieldProps: {
                            required: true
                        }
                    },
                ]
            }
        },
        {
            name:'',
            type:'box_layout',
            label:'Payout Information',
            borderType:'none',
            fieldProps: {
                arrayFields:[
                    {
                        name:'payout_address',
                        label:'Enter Payout Details',
                        placeholder:'upi@okaxis.com / paypal / bank account no.',
                        type:'textarea',
                        fieldProps: {
                            required: true
                        }
                    },
                ]
            }
        },
        {
            name:'active',
            label:'Active',
            placeholder:'',
            type:'checkbox',
            fieldProps:{
                sx: {
                    position: 'absolute',
                    left:'16px',
                    bottom: '24px'
                },
                elevation: quickMenuConstant
            }
        },
    ]

    useEffect(() =>{
      if(data?.id){
        const f = [...fieldMap];
        setFields(f)
      }else{
        const f = [{
            name:'',
            type:'box_layout',
            label:'Contact Information',
            borderType:'none',
            fieldProps: {
                arrayFields:[
                    {
                        name:'name',
                        label:'Name',
                        placeholder:'Enter Name',
                        type:'text',
                        cols:2,
                        fieldProps: {
                            required: true
                        }
                    },
                    {
                        name:'email',
                        label:'Email',
                        placeholder:'Enter Email',
                        type:'text',
                        cols:2,
                        fieldProps: {
                            required: true
                        }
                    },
                ]
            }
        },
        ...fieldMap];
        setFields(f)
      }
    }, [data])
    return(
        <FormModal
          open={open}
          title={title}
          onClose={onClose}
        >
           <div className='mt-3'>
           <FormManager
             fieldMap={fields}
             defaultValues={defaultValues}
             onCancel={onClose}
             formSubmit={handleFormSubmission}
             data={data?.id ? data : {}}
             loading={loading}
             />
           </div>
        </FormModal>
    )
}

export default AddAffiliate