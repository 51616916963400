import React from 'react';
import { Card, CardMedia, Typography, CardContent, CircularProgress } from '@mui/material';
import Loading from '../../../shared-component/Loading/Loading';

const CardComponent = ({ name, url, fileUrl, fileType, loading }) => {
  return (
    <Card
      className="cursor-pointer"
      sx={{
        boxShadow: 'none',
        border: '2px solid #E1EBFE',
        borderRadius: '8px',
        margin: '4px 0',
        width: '250px',
        height: '250px',
      }}
    >
      {url ? (
        <CardMedia
          sx={{ height: '150px', objectFit: 'contain' }}
          image={`${url}` || <>No Image</>}
          title="green iguana"
        />
      ) : (
        <div sx={{ height: '150px' }}>
          {loading ? 
           <div className="w-full h-[150px] bg-[#EDECF9] flex items-center justify-center"> 
            <CircularProgress size={20} />
           </div>
           
          :
          <a
            className="w-full bg-[#EDECF9] flex items-center justify-center"
            href={fileUrl}
            style={{
              height: '150px',
              objectFit: 'contain',
              fontfamily: 'Rubik',
              fontSize: '32px',
              fontWeight: 500,
              lineHeight: '',
              color: '#8E8CA3',
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fileType.toUpperCase()}
          </a>
          }
          
        </div>
      )}
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            fontWeight: 300,
            fontSize: '15px',
          }}
        >
          {name?.length > 15 ? `${name}...` : name || 'Untitled'}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
