import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import { ChromePicker } from 'react-color'
import PopoverComponent from '../../../shared-component/Popover/PopoverComponent';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';
import rgbHex from 'rgb-hex';
import hexToRgba from 'hex-to-rgba';

function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error('Bad Hex');
}


const ColorSelectionModal = ({
    open,
    title,
    handleClose,
    onSubmit,
    propertyData,
    loading,
    disabled
}) =>{
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElColor, setAnchorElColor] = useState(null);
    const [availableColor, setAvailableColor] = useState(hexToRgba(propertyData?.available_color))
    const [soldColor, setSoldColor] = useState(hexToRgba(propertyData?.sold_color))
    const [selectedColor, setSelectedColor] = useState(null)
    const openColor = Boolean(anchorElColor);
    const [selectedSection, setSelectedSection] = useState(null)

    const handleClickColor = (event, color) =>{
        setSelectedColor(color)
        setAnchorElColor(event.currentTarget)
    }

    const handleCloseColor = () =>{
        setAnchorElColor(null)
    }

    function rgbaStringToObject(rgbaString) {
        // Extract RGBA values from the string
        const matches = rgbaString.match(/rgba\((\d+),(\d+),(\d+),([\d.]+)\)/);
        if (!matches) {
            return null; // Return null if the string doesn't match the expected format
        }
    
        // Parse the extracted values
        const r = parseInt(matches[1], 10);
        const g = parseInt(matches[2], 10);
        const b = parseInt(matches[3], 10);
        const a = parseFloat(matches[4]);
    
        // Construct and return the object
        return { r, g, b, a };
    }

    function RGBAToHexA(r,g,b,a) {
        r = r.toString(16);
        g = g.toString(16);
        b = b.toString(16);
        a = Math.round(a * 255).toString(16);
      
        if (r.length == 1)
          r = "0" + r;
        if (g.length == 1)
          g = "0" + g;
        if (b.length == 1)
          b = "0" + b;
        if (a.length == 1)
          a = "0" + a;
      
        return "#" + r + g + b + a;
    }
    const onChangeColor = (color) =>{
        setSelectedColor(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
        if(selectedSection === 'Available'){
            setAvailableColor(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
        }else{
            setSoldColor(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
        }
    }

    const decimalToHex = (alpha) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16)

const handleColorChange = (color) => {
   const hexCode = `${color.hex}${decimalToHex(color.rgb.a)}` 
   console.log(hexCode)
}

 useEffect(() =>{
   console.log('from selected color', selectedColor)
 }, [selectedColor])




    return (
        <FormModal
          title={title}
          open={open}
          onClose={handleClose}
          width='510px !important'
        >
            <>
              <div className='flex flex-col mx-8 my-4'>
                   <div className='my-3'>
                       <Typography variant='subtitle1'>Available</Typography>
                        <div className={`w-full h-10 cursor-pointer`}
                            onClick={(e) =>{
                            setSelectedSection('Available')
                            handleClickColor(e, availableColor)
                            }}
                            style={{
                            backgroundColor: `${availableColor}`,
                            borderRadius: '4px',
                            border: "1px solid #8E8CA3",
                            }}
                        ></div>
                   </div>
                   <div className='my-3'>
                        <Typography variant='subtitle1'>Sold</Typography>
                        <div className={`w-full h-10 cursor-pointer`}
                            onClick={(e) =>{
                            setSelectedSection('Sold')
                            handleClickColor(e, soldColor)
                            }}
                            style={{
                            backgroundColor: `${soldColor}`,
                            borderRadius: '4px',
                            border: "1px solid #8E8CA3",
                            }}
                        ></div>
                   </div>
                   {anchorElColor && (
                        <PopoverComponent 
                            id={'simple-popover1'}
                            open={openColor}
                            anchorEl={anchorElColor}
                            handleClick={handleClickColor}
                            handleClose={handleCloseColor}
                            horizontal='center'
                            component={
                            <ChromePicker
                                color={selectedColor}
                                onChange={(color) => {
                                    console.log('from colornew', color)
                                    onChangeColor(color)
                                 }}
                            />
                            }
                        />
                        )}
              </div>
              <div className="w-full flex justify-end px-8 pb-6">
                    <SecondaryBtn style={{ backgroundColor: '#EDECF9' }} onClick={handleClose} title='Cancel' />
                    <PrimaryBtn title='Update' 
                    loading={loading}
                    disabled={disabled}
                    style={{
                        marginRight: '0px !important',
                    }} onClick={() =>{
                        const avail = rgbaStringToObject(availableColor)
                        const sol = rgbaStringToObject(soldColor)
                        onSubmit({
                            available_color: `#${rgbHex(`${availableColor}`)}`,
                            sold_color:  `#${rgbHex(`${soldColor}`)}`,
                        })
                    }}/>
                </div>
            </>
        </FormModal>
    )
}

export default ColorSelectionModal