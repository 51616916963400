/* eslint-disable no-restricted-syntax */
import { DialogActions, DialogContent, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
// import SecondaryBtn from '../button-component/SecondaryBtn'
import PrimaryBtn from '../button-component/PrimaryBtn'
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons';

import FormFieldMapper from './FormFieldMapper';
import RegularCheckbox from '../OverviewPage/overview-used-components/RegularCheckbox';
import { useEffect, useMemo } from 'react';
import SecondaryBtn from '../button-component/SecondaryBtn';
import { yupResolver } from '@hookform/resolvers/yup';

const FormManager = ({
  defaultValues = {},
  schema = {},
  onCancel,
  reload,
  apiUrl,
  data,
  search,
  handelSubmit,
  fieldMap = [],
  modalProps = {},
  successCallback = () => { },
  profile=false,
  btntitle='',
  leftBtn=false,
  primaryBtnTitle='Submit',
  secondaryBtnTitle='Cancel',
  defaultChecked,
  checkbox,
  title,
  formSubmit,
  loading,
  sx,
  loadForm,
  isUnitForm = false,
  onReset,
  updateValues,
  deleteBtnStyles = {},
  secondaryBtnStyles = {},
  onDelete,
  deleteTitle = "Delete",
  isDeleteButton = false,
  removeSecondaryButton = false
}) => {
 
  const formDefaultValues = {
    ...defaultValues,
    ...data,
     ...Object.keys(data || {})
      .filter((d) => d.endsWith('_card'))
      .reduce((obj, key) => {
        obj[key.slice(0, key.length - 5)] = data[key];
        return obj
      }, []),
  };

  let methods = useForm({
    mode: 'onBlur',
    defaultValues: formDefaultValues,
    delayError: 1000,
  });
  if(schema?.yupSchema){
    methods = useForm({
       mode: 'onBlur',
       reValidateMode: 'onChange',
       defaultValues: formDefaultValues,
       resolver: yupResolver(schema.yupSchema),
       criteriaMode: 'firstError',
       shouldFocusError: true,
       delayError: 1000,
     });
   }

  console.log('from form state', methods.formState)


  useEffect(() =>{
     if(loadForm){

      if(loadForm !== null){
        if(isUnitForm){
        const values = methods.getValues();
        const newValues = updateValues(values.specs, defaultValues.specs);
        if(!data.hasOwnProperty('name')){
          methods.reset({
            ...defaultValues,
            ...data,
             ...Object.keys(data || {})
              .filter((d) => d.endsWith('_card'))
              .reduce((obj, key) => {
                obj[key.slice(0, key.length - 5)] = data[key];
                return obj
              }, []),
             ...values,
             specs: newValues
           
 
          })
        }else{
          methods.reset({
            ...defaultValues,
            ...data,
             ...Object.keys(data || {})
              .filter((d) => d.endsWith('_card'))
              .reduce((obj, key) => {
                obj[key.slice(0, key.length - 5)] = data[key];
                return obj
              }, []),
              ...values,
              specs: newValues
          })
        }
        }
        else{
          const values = methods.getValues();
          const newValues = updateValues(values, defaultValues)

          if(!data.hasOwnProperty('specs')){
            methods.reset({
              ...data,
               ...Object.keys(data || {})
                .filter((d) => d.endsWith('_card'))
                .reduce((obj, key) => {
                  obj[key.slice(0, key.length - 5)] = data[key];
                  return obj
                }, []),
                ...defaultValues,
                ...newValues
            })
          }else{
            methods.reset({
              ...data,
               ...Object.keys(data || {})
                .filter((d) => d.endsWith('_card'))
                .reduce((obj, key) => {
                  obj[key.slice(0, key.length - 5)] = data[key];
                  return obj
                }, []),
                ...defaultValues,
                ...newValues
            })
          }
          console.log('from form values........', values)
        
        }
      }
     }
  }, [loadForm])

  


 
  return (
    <FormProvider {...methods}>
      <form 
     // onSubmit={methods.handleSubmit(onSubmit)}
        onSubmit={methods.handleSubmit(formSubmit)}
      >
        <DialogContent >
          <Grid container spacing={2} columns={16}>
            {fieldMap?.map(FormFieldMapper)}
          </Grid>
        </DialogContent>
        <DialogActions sx={{justifyContent:'space-between'}}>
          {leftBtn? 
          <PrimaryBtn title={btntitle} style={{
            boxShadow:'none',
            fontWeight:300
           }}/>
          :<>
          {
            checkbox? <RegularCheckbox defaultChecked={defaultChecked} title={title} />:<div></div>
          }
          </>
          }
          
          <div style={{
            ...sx
          }}>
           {!removeSecondaryButton && (
            <SecondaryBtn style={{
              boxShadow:'none',
              padding:'6px',
              backgroundColor:'#EDECF9',  
              fontWeight:300,
              ...secondaryBtnStyles
              }} 
              title={secondaryBtnTitle} 
              onClick={onCancel}
              />
           )}
           {onReset && (
            <SecondaryBtn style={{boxShadow:'none',padding:'6px',backgroundColor:'#EDECF9', color:'#FF5C5C',  fontWeight:300}} title='Reset' onClick={() =>{
              if(onReset){
                onReset()
              }
              methods.reset()
            }}/>
           )}
           {isDeleteButton && (
            <SecondaryBtn 
            className="!text-[#FF5C5C]"
            style={{
               boxShadow:'none',
               padding:'6px',
               backgroundColor:'#EDECF9', 
               color:'#FF5C5C !important',  
               fontWeight:300,
               ...deleteBtnStyles
              }} title={deleteTitle} onClick={() =>{
              if(onDelete){
                onDelete()
              }
            }}/>
           )}
           <PrimaryBtn 
             title={primaryBtnTitle} 
             disabled={loading} 
             loading={methods?.formState?.isSubmitting}
             style={{
              fontWeight:300
             }}
             />
           </div>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default FormManager;