import React, {
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  useContext,
} from 'react';
import OpenStreetView from './OpenStreetView';
import {
  ErrorButton,
  PrimaryButton,
  SecondaryButton,
} from '@assystant/mui-buttons';
import EditIcon from '../../../../asset/icons/EditIcon';
import AddTitle from '../Modal/AddTitle';
import { useParams, useNavigate } from 'react-router-dom';
import AccordianComponent from '../../setting/template_design/component/AccordianComponent';
import AddLocationComponent from './AddLocationComponent';
import Integration from '../../properties/newMap/linkadd/Integration';
import { renderMapScript } from '../../properties/newMap/Script';
import { BASE_URI, apiService } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import Loading from '../../../shared-component/Loading/Loading';
import RemovePopup from '../../../shared-component/RemovePopup/RemovePopup';
import { CircularProgress } from '@mui/material';
import { iconsList } from '../../../../Config/Constants';
import UserContext from '../../../../context/UserContext';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
import ErrorBtn from '../../../shared-component/button-component/ErrorBtn';

const Container = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('Untitled');
  const [openTitleModal, setOpenTitleModal] = useState(false);

  const [openAddLocation, setOpenAddLocation] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [openIntegration, setOpenIntegration] = useState(false);
  const [deletingMap, setDeletingMap] = useState(false);
  const [mapDetails, setMapDetails] = useState(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState({});
  const [loading, setLoading] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [loadingAmenities, setLoadingAmenities] = useState(false);
  const [markerPoints, setMarkerPoints] = useState([]);

  const [mapView, setMapView] = useState([12.9716, 77.5946]);
  const [defaultMarkerPosition, setDefaultMarkerPosition] = useState({
    lat: mapView[0],
    lng: mapView[1],
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const [cancelEditMap, setCancelEditMap] = useState(false);

  const [icon, setIcon] = useState('1');

  const [translate, setTranslate] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const elementRef = useRef(null);

  const [originalList, setOriginalList] = useState([]);
  const { userFromOrganization } = useContext(UserContext);

  const handleCancelEdit = () => {
    setCancelEditMap(!cancelEditMap);
  };

  useLayoutEffect(() => {
    if (elementRef.current) {
      setWidth(elementRef.current.offsetWidth);
    }
  }, []);

  const [dataToSave, setDataToSave] = useState([]);

  const [openEditConfirm, setOpenEditConfirm] = useState(false);

  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleClick = () => {
    if (translate === '31.5%') {
      setTranslate('0%');
      setIcon('2');
    } else {
      setTranslate('31.5%');

      setIcon('1');
    }
  };

  const handleEditConfirmModal = () => {
    setOpenEditConfirm(!openEditConfirm);
  };

  const handleRemove = () => {
    setOpenRemove(!openRemove);
  };

  const { exitFromPlatform } = useExit();

  localStorage.setItem('tabValue', '5');

  const handleIntegration = () => {
    setOpenIntegration(!openIntegration);
  };

  const handleAddLocation = () => {
    if (localStorage.getItem('Map_created_open_and_show_maps') !== null) {
      localStorage.removeItem('Map_created_open_and_show_maps');
    }
    setOpenAddLocation(!openAddLocation);
  };
  const handleModal = () => {
    setOpenTitleModal(!openTitleModal);
  };

  const retreiveMapData = () => {
    setLoading(true);
    apiService
      .get(`property/geomap/${id}/`)
      .then((res) => {
        if (res?.data) {
          setTitle(res?.data?.title);
          setMapDetails(res?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  const returnUpdatedList = (list) => {
    const amenities = [];
    list?.forEach((e) => {
      if (amenities?.some((f) => e?.place_type?.name === f) === false) {
        amenities.push(e?.place_type?.name);
      }
    });
    const updatedList = amenities?.map((e, i) => {
      return {
        group_name: e,
        accordianId: `${e}_${i}`,
        items: list
          ?.filter((f) => {
            return e === f?.place_type?.name;
          })
          ?.map((e) => {
            let obj = {
              ...e,
              accordianId: `${e}_${i}`,
              place_icon: iconsList?.find((r) => {
                if (typeof e?.icon === 'string') {
                  return r?.name === e?.icon;
                } else {
                  return r?.name === e?.place_type?.name;
                }
              })?.icon,
              title: e?.place_name,
            };

            if (typeof e?.icon === 'string') {
              obj['icon'] = iconsList?.find((r) => {
                return r?.name === e?.icon;
              });
            } else {
              obj['icon'] = iconsList?.find((r) => {
                return r?.name === e?.place_type?.name;
              });
            }
            return obj;
          }),
      };
    });

    return updatedList;
  };

  const returnIconsWithList = (list) => {
    const updateIconsWithList = list?.map((e, i) => {
      let obj = {
        ...e,
        accordianId: `${e?.place_name}_${i}`,
        place_icon: iconsList?.find((r) => {
          if (typeof e?.icon === 'string') {
            return r?.name === e?.icon;
          } else {
            return r?.name === e?.place_type?.name;
          }
        })?.icon,
        title: e?.place_name,
      };

      if (typeof e?.icon === 'string') {
        obj['icon'] = iconsList?.find((r) => {
          return r?.name === e?.icon;
        });
      } else {
        obj['icon'] = { name: e?.place_type?.name };
      }
      return obj;
    });

    return updateIconsWithList;
  };

  const retreiveAmenitiesList = () => {
    setLoadingAmenities(true);
    apiService
      .get(`property/geomap/${id}/amenities/`)
      .then((res) => {
        if (res?.data?.length > 0) {
          const list = [...res?.data];
          setOriginalList(list);
          const updatedList = returnUpdatedList(list);
          const updateIconsWithList = returnIconsWithList(list);
          setMapView([
            Number(updateIconsWithList[0]?.latitude),
            Number(updateIconsWithList[0]?.longitude),
          ]);
          setMarkerPoints(updateIconsWithList);
          setAmenitiesList(updatedList);
        } else {
          setOriginalList([]);
          setMarkerPoints([]);
          setAmenitiesList([]);
        }
        setLoadingAmenities(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    retreiveAmenitiesList();
    retreiveMapData();
  }, []);

  useEffect(() => {
    if (mapDetails?.id) {
      if (
        JSON.parse(localStorage.getItem('Map_created_open_and_show_maps'))?.id
      ) {
        setOpenAddLocation(true);
        setOpenTitleModal(true);
      }
    }
  }, [mapDetails]);

  const handleEdit = (item) => {
    setSelectedLocation(item);
    handleAddLocation();
  };

  const deleteMap = () => {
    setDeletingMap(true);
    apiService
      .delete(`property/geomap/${id}/`)
      .then((res) => {
        if (res?.status === 204) {
          enqueueSnackbar('Map Deleted', { variant: 'success' });

          setTimeout(() => {
            setDeletingMap(false);
            navigate(-1);
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setDeletingMap(false);
          }
        }
      });
  };

  const modifyData = (data) => {
    let newData = { ...data };
    newData = {
      ...newData,
      title: title,
    };
    return newData;
  };

  const updateList = (list, response) => {
    const newList = [...list];
    const filterItem = { ...response };

    newList.forEach((group) => {
      group.items = group.items.filter((item) => {
        return !(
          item.id === filterItem.id &&
          filterItem.place_type?.name !== group.group_name
        );
      });
    });

    for (let i = newList.length - 1; i >= 0; i--) {
      if (newList[i].items.length === 0) {
        newList.splice(i, 1);
      }
    }

    return newList;
  };

  const updateAmenitiesList = (response) => {
    const isGroupExist = amenitiesList?.some((e) => {
      return e?.group_name === response?.place_type?.name;
    });

    if (isGroupExist === false) {
      const newGroup = {
        group_name: response?.place_type?.name,
        items: [
          {
            ...response,
            place_icon: iconsList?.find((r) => {
              if (typeof response?.icon === 'string') {
                return r?.name === response?.icon;
              } else {
                return r?.name === response?.place_type?.name;
              }
            })?.icon,
            title: response?.place_name,
            icon:
              typeof response?.icon === 'string'
                ? iconsList?.find((r) => {
                    return r?.name === response?.icon;
                  })
                : iconsList?.find((r) => {
                    return r?.name === response?.place_type?.name;
                  }),
          },
        ],
      };

      const newObject = { ...newGroup?.items[0] };

      if (typeof response?.icon === 'string') {
        newObject['icon'] = iconsList?.find((r) => {
          return r?.name === response?.icon;
        });
      } else {
        newObject['icon'] = newObject['icon'] = iconsList?.find((r) => {
          return r?.name === response?.place_type?.name;
        });
      }
      const newAmenities = [...amenitiesList, newGroup];
      const withNewMarkers = [...markerPoints, newObject];

      const newAmenitiesWithGroups = updateList(newAmenities, response)?.filter(
        (e) => {
          return e?.items?.length !== 0;
        },
      );

      setAmenitiesList(newAmenitiesWithGroups);
      setMarkerPoints(withNewMarkers);
    } else {
      const newObject = {
        ...response,
        place_icon: iconsList?.find((r) => {
          if (typeof response?.icon === 'string') {
            return r?.name === response?.icon;
          } else {
            return r?.name === response?.place_type?.name;
          }
        })?.icon,
        title: response?.place_name,
        icon:
          typeof response?.icon === 'string'
            ? iconsList?.find((r) => {
                return r?.name === response?.icon;
              })
            : iconsList?.find((r) => {
                return r?.name === response?.place_type?.name;
              }),
      };

      if (typeof response?.icon === 'string') {
        newObject['icon'] = iconsList?.find((r) => {
          return r?.name === response?.icon;
        });
      } else {
        newObject['icon'] = { name: response?.place_type?.name };
      }

      const clonedList = [...amenitiesList];

      let findGroup = clonedList?.find((e) => {
        return e?.group_name === response?.place_type?.name;
      });
      const checkInsideGroupItems = findGroup?.items?.some((e) => {
        return e?.id === newObject?.id;
      });

      if (checkInsideGroupItems === false) {
        findGroup?.items?.push(newObject);
      } else {
        findGroup = {
          ...findGroup,
          items: findGroup?.items?.map((e) => {
            if (e?.id === newObject?.id) {
              return newObject;
            } else {
              return e;
            }
          }),
        };
      }

      const updateAmenities = clonedList?.map((e) => {
        if (e?.group_name === findGroup?.group_name) {
          return findGroup;
        } else {
          return e;
        }
      });

      const newAmenitiesWithGroups = updateList(
        updateAmenities,
        response,
      )?.filter((e) => {
        return e?.items?.length !== 0;
      });
      console.log('from......a', newAmenitiesWithGroups);

      const cloneMarkers = [...markerPoints];
      const checkIfMarkerExist = cloneMarkers?.some((e) => {
        return e?.id === newObject?.id;
      });
      let withNewMarkers = [];
      if (checkIfMarkerExist === false) {
        withNewMarkers = [...markerPoints, newObject];
      } else {
        withNewMarkers = cloneMarkers?.map((e) => {
          if (e?.id === newObject?.id) {
            return newObject;
          } else {
            return e;
          }
        });
      }

      setAmenitiesList(newAmenitiesWithGroups);
      setMarkerPoints(withNewMarkers);
    }
  };

  const saveAndCancelService = () => {
    setSubmitLoading(true);
    const list = [...dataToSave];

    originalList?.forEach((e) => {
      if (list?.some((f) => e?.id === f?.id) === false) {
        list.push(e);
      }
    });

    const newList = list?.map((e) => {
      if (typeof e?.id !== 'number') {
        delete e?.id;
        return {
          ...e,
          place_type: e?.place_type?.id,
          icon: e?.icon?.id ? e?.icon?.name : e?.icon,
          geo_map: Number(e?.geo_map),
        };
      } else {
        return {
          ...e,
          place_type: e?.place_type?.id,
          icon: e?.icon?.id ? e?.icon?.name : e?.icon,
          geo_map: Number(e?.geo_map),
        };
      }
    });
    apiService
      .post(`property/geomap/${id}/save/`, {
        locations: newList,
      })
      .then((res) => {
        setSubmitLoading(false);
        if (originalList?.length > 0) {
          enqueueSnackbar('Added', { variant: 'success' });
        } else {
          enqueueSnackbar('Updated', { variant: 'success' });
        }
        setTimeout(() => {
          navigate(-1);
        }, 500);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setSubmitLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    console.log('from marker points', markerPoints);
  }, [markerPoints]);

  const renderAmenitiesList = useCallback(() => {
    return (
      <div className="relative">
        {amenitiesList?.map((e) => {
          return (
            <AccordianComponent
              mainTitle={e?.group_name}
              accrodianStyles={{
                boxShadow: 'none',
                border: 'none',
                borderRadius: '0px !important',
              }}
              accordianSummaryStyles={{
                fontFamily: 'Rubik',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '23.7px',
                color: '#353349',
                borderBottom: '1px solid #EDECF9',
                minHeight: '50px !important',
                height: '50px',
                borderBottom: '0px !important',
              }}
              accordianDetailsStyles={{
                padding: '0px 16px 0px !important',
                borderBottom: '2px solid #EDECF9 !important',
              }}
              component={
                <>
                  {e?.items?.map((item) => {
                    return (
                      <div
                        className="group flex items-center justify-between mb-4"
                        style={{
                          background:
                            selectedMarker === item?.id
                              ? 'black'
                              : 'transparent',
                        }}
                      >
                        <div className="flex items-center">
                          <div className="h-12 w-12 bg-[#EDECF9] flex items-center justify-center rounded-full">
                            {item?.place_icon}
                          </div>
                          <div className="flex flex-col">
                            <div
                              className="ml-2"
                              style={{
                                fontFamily: 'Rubik',
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '23.7px',
                                color: '#8E8CA3',
                              }}
                            >
                              {item?.title?.length > 30
                                ? `${item?.title?.substring(0, 30)}...`
                                : item?.title}
                            </div>
                            <div
                              className="ml-2 hidden group-hover:block"
                              style={{
                                fontFamily: 'Rubik',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '16.59px',
                                color: '#B1AED1',
                              }}
                            >
                              {item?.distance}
                            </div>
                          </div>
                        </div>
                        {userFromOrganization?.role?.name.toLowerCase() ===
                          'admin' ||
                        userFromOrganization?.role?.name.toLowerCase() ===
                          'developer' ? (
                          <div
                            className="ml-2 invisible cursor-pointer group-hover:visible"
                            onClick={() => handleEdit(item)}
                          >
                            <EditIcon />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </>
              }
            />
          );
        })}
      </div>
    );
  }, [amenitiesList]);

  const removeItemFormList = (id) => {
    const cloned = [...dataToSave];
    const cloneOriginal = [...originalList];
    const checkItemExist = cloned?.some((e) => {
      return e?.id === id;
    });
    const newMarkerPoints = [...markerPoints]?.filter((e) => {
      return id !== e?.id;
    });

    setMarkerPoints(newMarkerPoints);

    const newAmenities = [...amenitiesList]?.map((e) => {
      return {
        ...e,
        items: e?.items?.filter((f) => {
          return f?.id !== id;
        }),
      };
    });
    const filterAmenities = newAmenities?.filter((e) => {
      return e?.items?.length > 0;
    });
    setAmenitiesList(filterAmenities);

    if (checkItemExist === true) {
      const filter = cloned?.filter((e) => {
        return e?.id !== id;
      });
      const itemExistInOriginal = cloneOriginal?.some((e) => {
        return e?.id === id;
      });
      if (itemExistInOriginal === true) {
        const newFilteredList = cloneOriginal?.filter((e) => {
          return e?.id !== id;
        });
        newFilteredList?.forEach((e) => {
          if (filter?.some((f) => f?.id === e?.id) === false) {
            filter.push(e);
          }
        });
        setDataToSave(filter);
        setOriginalList(filter);
      } else {
        setDataToSave(filter);
        setOriginalList(filter);
      }
    } else {
      const filter = cloneOriginal?.filter((e) => {
        return e?.id !== id;
      });

      const newList = [...filter];
      setDataToSave(newList);
      setOriginalList(newList);
    }
  };

  const updateDataToSave = (response) => {
    const check = [...dataToSave]?.some((e) => {
      return e?.id === response?.id;
    });
    if (check === true) {
      const newData = [...dataToSave]?.map((e) => {
        if (e?.id === response?.id) {
          return {
            ...response,
          };
        } else {
          return e;
        }
      });
      setDataToSave(newData);
    } else {
      const newList = [...dataToSave, response];
      setDataToSave(newList);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="relative">
        <div
          className="fixed top-0 w-full h-20 bg-white flex items-center justify-between"
          style={{ zIndex: !openIntegration ? 9999 : 0 }}
        >
          <div className="flex items-center ml-8">
            {isUpdating === true ? (
              <SecondaryBtn
                title="Cancel"
                style={{
                  background: '#EDECF9',
                  boxShadow: 'none',
                  color: '#353349',
                  fontWeight: '400',
                  fontSize: '14px',
                }}
                onClick={handleCancelEdit}
              />
            ) : (
              <SecondaryBtn
                title="Back"
                style={{
                  background: '#EDECF9',
                  boxShadow: 'none',
                  color: '#353349',
                  fontWeight: '400',
                  fontSize: '14px',
                }}
                onClick={() => {
                  if (
                    localStorage.getItem('Map_created_open_and_show_maps') !==
                    null
                  ) {
                    localStorage.removeItem('Map_created_open_and_show_maps');
                  }
                  navigate(-1);
                }}
              />
            )}

            {isUpdating === true ? (
              <PrimaryBtn
                title="Save"
                style={{
                  boxShadow: 'none',
                  fontWeight: '400',
                  fontSize: '14px',
                }}
                disabled={submitLoading}
                onClick={saveAndCancelService}
              />
            ) : (
              <PrimaryBtn
                title="Integrate"
                style={{
                  boxShadow: 'none',
                  fontWeight: '400',
                  fontSize: '14px',
                }}
                onClick={handleIntegration}
              />
            )}
            <div className="flex items-center ml-4">
              <div
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '32px',
                  fontWeight: '500',
                  lineHeight: '37.92px',
                  letterSpacing: '-0.022em',
                  color: '#353349',
                }}
              >
                {title}
              </div>
              {userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
              userFromOrganization?.role?.name.toLowerCase() === 'developer' ? (
                <div className="ml-2 cursor-pointer" onClick={handleModal}>
                  <EditIcon />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          {id !== undefined && (
            <div className="mr-8">
              <ErrorBtn
                title="Delete"
                style={{
                  boxShadow: 'none',
                  fontWeight: '400',
                  fontSize: '14px',
                }}
                disabled={
                  deletingMap === true ||
                  userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
                  userFromOrganization?.role?.name.toLowerCase() === 'developer'
                    ? false
                    : true
                }
                onClick={handleRemove}
              />
            </div>
          )}
        </div>
        <div>
          <OpenStreetView
            mapView={mapView}
            setMapView={setMapView}
            markerPoints={markerPoints}
            openAddLocation={openAddLocation}
            selectedLocation={selectedLocation}
            setSelectedMarker={setSelectedMarker}
            setSelectedLocation={setSelectedLocation}
            defaultMarkerPosition={defaultMarkerPosition}
            setSelectedCoordinates={setSelectedCoordinates}
            setDefaultMarkerPosition={setDefaultMarkerPosition}
          />
        </div>

        {!openAddLocation && !openTitleModal && !openRemove && (
          <>
            <div
              className={
                icon === '1'
                  ? 'absolute top-[20%] bg-transparent w-96 overflow-y-scroll max-h-[70vh] left-[5%] rounded-lg block'
                  : 'absolute top-[20%] bg-transparent w-96 overflow-y-scroll max-h-[70vh] left-[5%] rounded-lg hidden'
              }
              style={{ zIndex: !openIntegration ? 9999 : 0 }}
            >
              {loadingAmenities ? (
                <div className="w-full flex items-center justify-center h-96 bg-white">
                  <CircularProgress size={20} />
                </div>
              ) : (
                <>{amenitiesList?.length > 0 && renderAmenitiesList()}</>
              )}
            </div>
          </>
        )}
        {openAddLocation && (
          <div
            className="absolute flex flex-col w-96 bg-white top-[15%] left-[5%]"
            style={{
              zIndex: 999,
            }}
          >
            <div className="bg-[#EDECF9] h-20 flex items-center">
              <div
                className="ml-8"
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '32px',
                  fontWeight: 500,
                  lineHeight: '37.92px',
                }}
              >
                {selectedLocation?.id ? 'Edit' : 'Add'}
              </div>
            </div>
            <div className="mt-4">
              <AddLocationComponent
                openModal={openAddLocation}
                data={selectedLocation?.id ? selectedLocation : {}}
                onClose={handleAddLocation}
                onCancel={handleEditConfirmModal}
                selectedCoordinates={defaultMarkerPosition}
                onSuccessCallback={(response) => {
                  updateAmenitiesList(response);
                  updateDataToSave(response);
                  setSelectedLocation({});
                  setIsUpdating(true);
                  handleAddLocation();
                }}
                onDeleteSuccessCallback={() => {
                  setSelectedLocation({});
                  setIsUpdating(true);
                  handleAddLocation();
                }}
                removeItemFormList={removeItemFormList}
              />
            </div>
          </div>
        )}
        <div
          className="absolute left-[46%] bottom-[10px]"
          style={{ zIndex: !openIntegration ? 9999 : 0 }}
        >
          <PrimaryBtn
            title="Add Pin"
            style={{
              boxShadow: 'none',
              fontWeight: '400',
              fontSize: '14px',
            }}
            disabled={
              userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
              userFromOrganization?.role?.name.toLowerCase() === 'developer'
                ? false
                : true
            }
            onClick={(e) => {
              e.stopPropagation();
              if (!openAddLocation) {
                setDefaultMarkerPosition({
                  lat: mapView[0],
                  lng: mapView[1],
                });
                setSelectedLocation({});
                handleAddLocation();
              }
            }}
          />
        </div>
      </div>

      {openTitleModal && (
        <AddTitle
          open={openTitleModal}
          title="Title"
          data={modifyData(mapDetails)}
          onClose={handleModal}
          successCallback={(response) => {
            setTitle(response?.title);
            handleModal();
          }}
        />
      )}

      {openIntegration && (
        <Integration
          open={openIntegration}
          onClose={handleIntegration}
          text={renderMapScript(mapDetails?.uuid, BASE_URI)}
        />
      )}

      {openRemove && (
        <RemovePopup
          open={openRemove}
          close={handleRemove}
          onSubmit={deleteMap}
          loading={deletingMap}
          description={`Are you sure you want to Remove this Map?`}
        />
      )}

      {openEditConfirm && (
        <RemovePopup
          open={openEditConfirm}
          close={handleEditConfirmModal}
          onSubmit={() => {
            setSelectedLocation({});
            handleAddLocation();
            handleEditConfirmModal();
          }}
          cancelText="No"
          removeText="Yes"
          loading={deletingMap}
          isSecondary={true}
          description={
            <>
              <div className="flex flex-col">
                <div>Your changes will not be saved!</div>
                <div>Are you sure you want to proceed with this action?</div>
              </div>
            </>
          }
        />
      )}
      {cancelEditMap && (
        <RemovePopup
          open={cancelEditMap}
          close={handleCancelEdit}
          onSubmit={() => {
            if (
              localStorage.getItem('Map_created_open_and_show_maps') !== null
            ) {
              localStorage.removeItem('Map_created_open_and_show_maps');
            }
            navigate(-1);
          }}
          cancelText="No"
          removeText="Yes"
          loading={deletingMap}
          isSecondary={true}
          description={
            <>
              <div className="flex flex-col">
                <div>Your changes will not be saved!</div>
                <div>Are you sure you want to proceed with this action?</div>
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default Container;
