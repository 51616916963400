import React, { useEffect, useState } from "react";
 
import { useFormContext } from "react-hook-form";
import IconFieldModal from "./IconfieldModal";
import ImageIcon from '../../../asset/Images/ImageIcon.png'

const IconSelection = ({
    name,
    listofIcons
}) =>{
    const {
        setValue,
        getValues
    } = useFormContext();
    const [icon, setIcon] = useState(listofIcons[0])
    const [openModal, setOpenModal] = useState(false);
    const [mainIcon, setMainIcon] = useState(null)

    const handleModal = () =>{
        setOpenModal(!openModal)
    }

    useEffect(() =>{
        if(getValues(name)?.icon){
            setIcon(getValues(name))
            setMainIcon(getValues(name))
        }
    }, [getValues])

    const handleIconOnClick = (ic) =>{
       setValue(name, ic);
       setIcon(ic)
       setMainIcon(ic)
       handleModal()
    }

    return(
        <>
         <div data-testid="avtar" className="flex flex-col mt-2 mb-4"
          onClick={handleModal}
          style={{
            width:'96px',
          }}> 
                {mainIcon === null ? 
                <img src={ImageIcon} className="w-24 h-24 cursor-pointer" />
                :
                <div className='h-24 w-24 bg-[#EDECF9] flex items-center justify-center rounded-full cursor-pointer'>
                   {mainIcon?.icon}
               </div>
                }
            </div>

            {openModal && (
                <IconFieldModal
                   open={openModal}
                   onClose={handleModal} 
                   onClick={handleIconOnClick}
                   list={listofIcons}
                   icon={icon}
                   setIcon={setIcon}
                />
            )}
        </>
    )
}

export default IconSelection