import React, { useEffect, useState } from 'react'
import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions'
import { BoxEffects, headerButton } from '../../../Config/Constants'
import Box from '@mui/material/Box';
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons';
import LabTab from '../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs'
import AffiliateDashboard from './tabs/AffiliateDashboard';
import Conversations from './tabs/Conversations';
import Settings from './tabs/Settings';
import { apiService } from '../../../constant/AuthConstant';
import returnError from '../../../utils/ExportError';
import useExit from '../../../utils/useExit';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import SecondaryBtn from '../../shared-component/button-component/SecondaryBtn';



const Affiliate = () =>{
    const arr1 = [
        { title: 'Affiliate', url: '/' },
      ]
    const { exitFromPlatform } = useExit()
    const [openAffiliate, setOpenAffiliate] = useState(false);
    const [value, setValue] = useState('1');
    const [openFilter, setOpenFilter] = useState(false);
    const [openConFilter, setOpenConFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [affiliateList, setAffiliateList] = useState([])
    const [filterList, setFilterList] = useState([])

    const handleConFilter = () =>{
      setOpenConFilter(!openConFilter)
    }

    const handleFilter = () =>{
      setOpenFilter(!openFilter)
    }

    const handleTabChange = (event, newVal) =>{
      setValue(newVal)
    }
  
   
    const handleAffiliateModal = () =>{
        setOpenAffiliate(!openAffiliate)
    }

    const getAffiliateData = () =>{
      setLoading(true)
      apiService.get(`affiliates/affiliates/`).then((res) =>{
        const updatedData = res?.data?.map((e) =>{
          return {
            ...e,
            name: e?.user?.full_name ? e?.user?.full_name : e?.invitation?.name,
            email: e?.user?.email ? e?.user?.email : e?.invitation?.email,
            avatar: e?.user?.avatar,
          }
        })?.sort((a,b) => (a.created < b.created) ? 1 : ((b.created < a.created) ? -1 : 0))
        setAffiliateList(updatedData)
        setFilterList(updatedData)
        setLoading(false)
      }).catch((error) =>{
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoading(false)
        }
      }
      })
    }

    useEffect(() =>{
      getAffiliateData()
    }, [])

    const tabs = [
      {
          id:1, 
          label:'Affiliate', 
          value:'1',
          page: <AffiliateDashboard
             openFilter={openFilter}
             handleFilter={handleFilter}
             openAffiliate={openAffiliate}
             handleAffiliateModal={handleAffiliateModal}
             loading={loading}
             setLoading={setLoading}
             affiliateList={affiliateList}
             setAffiliateList={setAffiliateList}
             getAffiliateData={getAffiliateData}
             filterList={filterList}
           />
      },
      {
        id:2, 
        label:'Conversions', 
        value:'2',
        page: <Conversations
            openFilter={openConFilter}
            handleFilter={handleConFilter}
            affiliateList={filterList}
         />
    },
    //   {
    //     id:3, 
    //     label:'Settings', 
    //     value:'3',
    //     page: <Settings />
    // },
    ]

   
    
    return(
       <>
          <Box
            sx={{ 
            borderBottom: BoxEffects.borderBottom, 
            borderColor: 'secondary.main', 
            padding: BoxEffects.padding, 
            marginBottom: BoxEffects.marginButton, 
            paddingLeft: BoxEffects.margin, 
            paddingRight: headerButton.marginRigt,position:'sticky',
            top:'65px',
            background:'white',
            zIndex:99
            }}
        >
        <BreadCrumbWithActions propArr={arr1}>
          <div className='flex justify-between items-center'>
            {/* <SearchBar /> */}
            <div style={{
              marginLeft:'20px'
            }}>
            {value === '1' || value === '2' ? (
               <SecondaryBtn 
               title="Filter"
                  style={{
                      boxShadow: 'none', 
                     
                      fontWeight:300,
                      padding:'6px 16px'
                  }} 
                onClick={value === '1' ? handleFilter : handleConFilter}
                />
            ) : (<></>)}
            {value === '1' && (
               <PrimaryBtn 
               title="Add Affiliate"
               style={{
                   boxShadow: 'none', 
                   marginRight:'0px !important',
                   fontWeight:300,
                   padding:'6px 16px'
               }}
             onClick={handleAffiliateModal}
            />
            )}
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>
      <LabTab 
        value={value} 
        tabs={tabs} 
        handleChange={handleTabChange} 
      />
       </>
    )
}

export default Affiliate
