import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ChangePassword from "../components/profile/ChangePassword";
import { TokenStorage, apiService } from "../../constant/AuthConstant";
import { useSnackbar } from "notistack";
import returnError from "../../utils/ExportError";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as yup from 'yup';

export default function PopoverPopupState() {
  const {enqueueSnackbar} = useSnackbar();
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const handelProfileView = () => {
    // navigate("/profile");
  };
  const handelChangePassword = () => {
    setOpenChangePassword(!openChangePassword);
  };

  function logout(){
    TokenStorage.clear()
    localStorage.clear()
    navigate("/");
  }

  const schema = yup.object().shape({
    new_password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Must contain min 8 characters with alphabets and numbers')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        "Must contain min 8 characters with alphabets and numbers"
      ),
      re_new_password: yup.string().test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.new_password === value;
    }),
  });

  const formSubmit = (data, event) =>{
    setLoading(true)
    console.log(event, data, TokenStorage.getToken())
    event.preventDefault()
    apiService.put('account/change-password/', data).then(()=>{
      enqueueSnackbar('Password updated successfully', {variant:'success'})
      handelChangePassword()
      setLoading(false)
    }).catch((error)=>{
      setLoading(false)
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
      }
      }
    })
}

 const navigateToProfile = () => {
    if(TokenStorage.getProfile()?.is_affiliate === true && TokenStorage.getProfile()?.is_consulting_partner === false){
      navigate('/affiliate-profile')
    }else{
      navigate('/partner-profile')
    }
 }
  
  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            {/* <Button variant="contained" {...bindTrigger(popupState)}>
            Open Popover
          </Button> */}
            <IconButton className="!px-1" {...bindTrigger(popupState)}>
              <Avatar className="!fill-[#FFFFFF] !bg-[#FFFFFF]" />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Paper sx={{ 
                width: 200, 
                maxWidth: "100%",
                borderRadius: '8px',
                }}>
                {/* <MenuItem className="!my-1.5">
                  <ListItemIcon>
                    <AccountCircleOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText onClick={handelProfileView}>
                    Profile
                  </ListItemText>
                </MenuItem> */}
                {TokenStorage.getProfile()?.is_affiliate === true || TokenStorage.getProfile()?.is_consulting_partner === true  ? (
                  <MenuItem className="!my-1.5" onClick={navigateToProfile}>
                  <ListItemIcon>
                    <AccountCircleIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    Profile
                  </ListItemText>
                </MenuItem>
                ) : (<></>)}
                <MenuItem className="!my-1.5">
                  <ListItemIcon>
                    <KeyOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText onClick={handelChangePassword}>
                    Change Password
                  </ListItemText>
                </MenuItem>
                <MenuItem className="" onClick={logout}>
                  <ListItemIcon>
                    <LogoutOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </Paper>
            </Popover>
          </div>
        )}
      </PopupState>
      {openChangePassword && (
        <ChangePassword
          schema={schema}
          title="Change Password"
          open={openChangePassword}
          onClose={handelChangePassword}
          onSubmit={formSubmit}
          loading={loading}
        />
      )}
    </>
  );
}
