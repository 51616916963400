import React, { useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { TextField } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '../../../../../../../../asset/icons/DeleteIocn';
import DragIndicator from '../../../../../../../../asset/icons/DragIndicator';
import UrlSelection from './UrlSelection';

const Component = ({
  menu,
  provided,
  menuDetails,
  setMenuDetails,
  addSubMenuItem,
  component,
  updateMenuItemWithName,
  updateMenuItemWithURL,
  deleteMenuItem,
  allPagesUrls
}) => {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleClickOutside = (event) => {
    if (open === false) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsActive(false);
      }
    }
  };

  useEffect(() => {
    if (open === false) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (open === false) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [open]);

  const handleClickInside = (e) => {
    e.stopPropagation();
    setIsActive(true);
  };

  return (
    <>
      <div ref={menuRef} onClick={handleClickInside}>
        {isActive && (
          <>
            <div ref={provided.innerRef} {...provided.draggableProps}>
              <div className="flex flex-col bg-[#EDECF9] w-8/12 min-h-40 mb-6 ml-8">
                <div
                  className="w-full flex items-center justify-center"
                  {...provided.dragHandleProps}
                >
                  <DragIndicator />
                </div>
                <div className="mx-4 mb-4 flex flex-col">
                  <TextField
                    className="!w-full"
                    label="Menu Name"
                    placeholder="Menu Name"
                    sx={{
                      background: 'white',
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={menu?.menu_name}
                    onChange={(e) => {
                      setMenuDetails(
                        updateMenuItemWithName(
                          menu?.id,
                          menuDetails,
                          e.target.value,
                        ),
                      );
                    }}
                  />
                  {/* <TextField
                  className="!w-full"
                  label="URL"
                  placeholder="URL"
                  sx={{
                    background: 'white',
                  }}
                /> */}
                  <UrlSelection
                    list={allPagesUrls}
                    open={open}
                    value={
                      menu.selected_url?.id
                        ? menu?.selected_url
                        : { page_title: '', url: '' }
                    }
                    setOpen={setOpen}
                    onChange={(newValue) => {
                      setMenuDetails(
                        updateMenuItemWithURL(menu?.id, menuDetails, newValue),
                      );
                    }}
                  />
                </div>
                <hr className="mx-4 bg-[#8E8CA3]" style={{ height: '2px' }} />
                <div className="mx-4 flex items-centre justify-end my-2">
                  <div className="flex gap-[10px]">
                    <div
                      onClick={() => {
                        setMenuDetails(deleteMenuItem(menu?.id, menuDetails));
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                </div>
              </div>
              {component()}
            </div>
          </>
        )}
        {!isActive && (
          <>
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              className="ml-8"
            >
              <div className="rounded-lg w-8/12 flex flex-col mb-6 border border-[#8E8CA3] bg-[#FFFFFF]">
                <div
                  className="w-full flex items-center justify-center"
                  {...provided.dragHandleProps}
                >
                  <DragIndicator />
                </div>
                <div
                  className="text-[#353349] px-4"
                  style={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '23.7px',
                  }}
                >
                  {menu?.menu_name === '' ? 'Add Menu Name' : menu?.menu_name}
                </div>
                {provided.placeholder}
                <div className="flex items-center justify-between cursor-pointer mr-2">
                  <div></div>
                  <div
                    className="flex items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMenuDetails(addSubMenuItem(menu.id, menuDetails));
                    }}
                  >
                    <AddIcon className="!fill-[#004FE8] !w-4" />
                    <span
                      className="text-[#004FE8]"
                      style={{
                        fontFamily: 'Rubik',
                        fontSize: '12px',
                        fontWeight: 400,
                      }}
                    >
                      Add Sub Menu Items
                    </span>
                  </div>
                </div>
              </div>
              {component()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const MainMenu = ({ menuDetails, setMenuDetails, onAddClick, allPagesUrls }) => {
  const onDragEnd = (result, path = []) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId === destination.droppableId) {
      setMenuDetails((prevMenuItems) =>
        reorderNested(prevMenuItems, source, destination, path),
      );
    } else {
      setMenuDetails((prevMenuItems) =>
        moveNested(prevMenuItems, source, destination, path),
      );
    }
  };

  const reorderNested = (list, source, destination, path) => {
    const currentPath = [...path, source.index];
    const targetPath = [...path, destination.index];

    const currentParent = getItem(list, currentPath.slice(0, -1));
    const targetParent = getItem(list, targetPath.slice(0, -1));

    const [moved] = currentParent.splice(source.index, 1);
    targetParent.splice(destination.index, 0, moved);

    return [...list];
  };

  const moveNested = (list, source, destination, path) => {
    const currentPath = [...path, source.index];
    const targetPath = [...path, destination.index];

    const currentParent = getItem(list, currentPath.slice(0, -1));
    const targetParent = getItem(list, targetPath.slice(0, -1));

    const [moved] = currentParent.splice(source.index, 1);
    targetParent.splice(destination.index, 0, moved);

    return [...list];
  };

  const getItem = (list, path) => {
    return path.reduce((acc, part) => {
      return acc[part].items;
    }, list);
  };

  const addSubMenuItem = (itemId, items) => {
    return items.map((item) => {
      if (item.id === itemId) {
        const newSubItem = {
          id: `${item.id}-${item.menu_items.length + 1}`,
          menu_name: 'New Sub Menu',
          dragId: `${item.id}-${item.menu_items.length + 1}`,
          menu: '',
          linked_url: 'No url for now',
          selected_url: '',
          menu_items: [],
        };
        return { ...item, menu_items: [...item.menu_items, newSubItem] };
      } else if (item?.menu_items?.length > 0) {
        return { ...item, menu_items: addSubMenuItem(itemId, item.menu_items) };
      }
      return item;
    });
  };

  const updateMenuItemWithName = (itemId, items, newName) => {
    return items.map((item) => {
      if (item.id === itemId) {
        return { ...item, menu_name: newName, is_edited: true };
      } else if (item?.menu_items?.length > 0) {
        return {
          ...item,
          menu_items: updateMenuItemWithName(itemId, item.menu_items, newName),
        };
      }
      return item;
    });
  };

  const updateMenuItemWithURL = (itemId, items, newUrl) => {
    return items.map((item) => {
      if (item.id === itemId) {
        return { ...item, selected_url: newUrl, linked_page: newUrl?.linked_page ? '' : newUrl?.id, linked_url: newUrl?.linked_page ? newUrl?.linked_page : '', is_edited: true };
      } else if (item?.menu_items?.length > 0) {
        return {
          ...item,
          menu_items: updateMenuItemWithURL(itemId, item.menu_items, newUrl),
        };
      }
      return item;
    });
  };

  const deleteMenuItem = (itemId, items) => {
    return items.filter((item) => {
      if (item.id === itemId) {
        return false;
      } else if (item?.menu_items?.length > 0) {
        item.menu_items = deleteMenuItem(itemId, item.menu_items);
      }
      return true;
    });
  };

  const updateSubMenuItems = (itemId, items, nameString) => {
    return items.map((item) => {
      if (item.id === itemId) {
        const newSubItem = {
          ...item,
          name: nameString,
        };
        return { ...item, menu_items: [...item.menu_items, newSubItem] };
      } else if (item?.menu_items?.length > 0) {
        return { ...item, menu_items: updateSubMenuItems(itemId, item.menu_items) };
      }
      return item;
    });
  };

  const renderItems = (items, path = []) => {
    return items?.map((item, index) => {
      const currentPath = [...path, index];

      return (
        <Draggable key={item.dragId} draggableId={item.dragId} index={index}>
          {(provided) => (
            <Component
              menu={item}
              provided={provided}
              menuDetails={menuDetails}
              setMenuDetails={setMenuDetails}
              addSubMenuItem={addSubMenuItem}
              updateMenuItemWithName={updateMenuItemWithName}
              updateMenuItemWithURL={updateMenuItemWithURL}
              deleteMenuItem={deleteMenuItem}
              allPagesUrls={allPagesUrls}
              component={() => {
                return (
                  item?.menu_items?.length > 0 && (
                    <DragDropContext
                      onDragEnd={(result) => onDragEnd(result, currentPath)}
                    >
                      <Droppable droppableId={`${currentPath.join('-')}`}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="sub-items"
                          >
                            {renderItems(item.menu_items, currentPath)}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )
                );
              }}
            />
          )}
        </Draggable>
      );
    });
  };
  console.log('from mai', menuDetails)
  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          onDragEnd(result, []);
        }}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {renderItems(menuDetails)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div
        className="flex items-center w-8/12 justify-end cursor-pointer"
        onClick={onAddClick}
      >
        <AddIcon className="!fill-[#004FE8] !w-4" />
        <span
          className="text-[#004FE8]"
          style={{
            fontFamily: 'Rubik',
            fontSize: '12px',
            fontWeight: 400,
          }}
        >
          Add Menu Items
        </span>
      </div>
    </>
  );
};

export default MainMenu;
