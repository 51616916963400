function formatISOString(ISOString, mode) {

  function convertTimeToAMPM(timeString) {
    var timeTokens = timeString.split(':');
    var hours = parseInt(timeTokens[0], 10);
    var minutes = timeTokens[1];
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0:00)
    let updateMinutes = Number(minutes) < 10 ? `0${minutes}` : minutes
    var formattedTime = hours + ':' + updateMinutes + ' ' + ampm;
    return formattedTime;
}
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const dateObj = new Date(ISOString);
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();

    const formattedTime = convertTimeToAMPM(`${hours}:${minutes}:${seconds}`);
    let formattedDate;
    if(mode === ''){
       formattedDate = `${day}th ${month} ${year}`;
    }else if(mode === 'month_first') {
      formattedDate = `${month} ${day}, ${year}`;
    }
    else{
      formattedDate = `${day}th ${month} ${year}, ${formattedTime}`;
    }
    return formattedDate;
}

export default formatISOString