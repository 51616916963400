import React, { useEffect, useState } from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useParams } from 'react-router-dom';
import Loading from '../../shared-component/Loading/Loading';
import { apiService } from '../../../constant/AuthConstant';
import returnError from '../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import InventoryMapContainer from './InventoryMapContainer';
import useExit from '../../../utils/useExit';
import EmptyCard from '../../shared-component/card-component/EmptyCard';

const InventoryMapView = ({
  propertyDetails
}) => {
    const { id } = useParams()
    const [loadMaps, setLoadMaps] = useState(false)
    const [breadcrumb, setBreadcrumb] = useState([])
    const [mapHierarchy, setMapHierarchy] = useState([])
    const { exitFromPlatform } = useExit()

    const fetchMapHierarchy = () =>{
        setLoadMaps(true)
        apiService.get(`property/prop/${id}/get_map_hierarchy/`).then((res) =>{
          console.log('from map view', res)
          setMapHierarchy(res.data)
          setBreadcrumb([
            {
              map: res.data?.uuid,
              name: res.data?.name
            }
          ])
          setLoadMaps(false)
        }).catch((error) =>{
          setLoadMaps(false)
          if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setLoad(false)
          }
          }
        
        })
      }

    const openInNewTab = (unitId) =>{
      window.open(`/properties/${id}/inventory/${unitId}`, '_blank');
    }

    const updateMapHierarchy = (uuid) =>{
      setLoadMaps(true)
      apiService.get(`heritage/property-unit/?property=${uuid}`).then((res) =>{
        console.log('from map view', res)
        setMapHierarchy(res.data)
        setLoadMaps(false)
      }).catch((error) =>{
        setLoadMaps(false)
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoad(false)
        }
        }
      
      })
    }

     const updateBreadcrumb = (data) =>{
      console.log('from update breadcrumb', data)
        const clonedBread = [...breadcrumb];
        const check = clonedBread?.some((e) =>{
          return e?.map === data?.map_link?.uuid
        })
        if(!check){
          clonedBread.push({
            map: data?.map_link?.uuid,
            name: data?.map_link?.name
          })
          setBreadcrumb(clonedBread)
          updateMapHierarchy(data?.map_link?.uuid)
        }else{
          const index = clonedBread.findIndex((e) =>{
            return e?.map === data?.map_link?.uuid
          })
          const newBread = clonedBread?.slice(0, index + 1)
          setBreadcrumb(newBread)
        }
     }

     const updateBreadcrumbAtClick = (data, index) =>{
         const clonedBread = [...breadcrumb];
         const updated = clonedBread.slice(0, index + 1)
         console.log('from cloned breadcrumb', clonedBread, typeof index, clonedBread.slice(0, index + 1))
         setBreadcrumb(updated)
         updateMapHierarchy(data?.map)
     }

      const allPaths = (data) => {
        const updatePathsData = data?.hotspots?.map((e, index) => {
            return {
                ...e,
                id: `path_${index}_01`,
                spotId: e?.id,
                path: e?.svg_pathdata?.split("\n").join('\n')
            }
        })

        if (updatePathsData?.length > 0) {
            return {
              ...data,
              hotspots: updatePathsData
            }
        }
    }
    
    useEffect(() =>{
        fetchMapHierarchy()
    }, [])
    return (
        <div>
            {breadcrumb?.length > 0 && (
              <Breadcrumbs aria-label="breadcrumb">
              {breadcrumb?.map((e, index) =>{
               return (
                 <Link className='cursor-pointer' underline="hover" color="inherit" onClick={() =>{
                  updateBreadcrumbAtClick(e, index)
                 }}>
                   {e?.name}
             </Link>
               )
              })}
           </Breadcrumbs>
            )}
            {loadMaps ? <Loading />
              :
              <>
              {!mapHierarchy?.uuid && (
                <EmptyCard title="No Maps Available" subtitle='Click “Add New Map” to add the map inside Map tab'/> 
              )}
             {mapHierarchy?.uuid && (
               <InventoryMapContainer 
                openInNewTab={openInNewTab}
                updateBreadcrumb={updateBreadcrumb}
                mapHierarchy={allPaths(mapHierarchy)}
                available_color={propertyDetails?.available_color}
                sold_color={propertyDetails?.sold_color}
              />
             )}
              </>
            }
        </div>
    )
};

export default InventoryMapView;