import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Controller, useForm, useFormContext, FormProvider } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ThemeProvider } from '@mui/material/styles';

import { BoxEffects, elevationFromConstant, GridContainerWithFullHeights, specialEffectforImage,AuthBoxEffect, authLinks } from '../../../Config/Constants';
import { Typography } from '@mui/material';
import { themes } from '../../../Config/themesConfig';
import { useNavigate } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import { yupResolver } from '@hookform/resolvers/yup';
import packageJson from '../../../../../package.json'
import { useMediaQuery } from 'react-responsive'

function AuthLayout({
    login,
    fields,
    onSubmit,
    formValues,
    forgotLink,
    companyLogo,
    actionTitle,
    addtionalText,
    publicPageLink,
    checkboxAction,
    publicSideImage,
    pageTitle,
    addtionalText1,
    addtionalText2,
    signUpLink,
    addtionaEndText,
    additionalEndLink,
    endLink,
    onBackPress,
    preview,
    onFileSelection,
    schema,
    isSubmitting,
    handleCheckBox
}) {
    const methods = useForm({
    mode: 'onChange',
    defaultValues: formValues,
    resolver: yupResolver(schema),
    });
   
    
    const navigate = useNavigate();
    const isTablet = useMediaQuery({ query: '(max-width: 920px)'})

    console.log('from formstate', methods.getValues(), methods.getValues('full_name'))

   const gridMargin = {
  }
  
  const tabletMargin = {
      margin:"40px 0px"
  }

  return (
    <ThemeProvider theme={themes}>
      <FormProvider {...methods}>
      <div className={isTablet ? 'block' : 'flex'}>
        <CssBaseline />
        <div className={isTablet ? 'w-full flex justify-center relative' : 'w-2/4 flex justify-center relative'} style={isTablet ? tabletMargin : gridMargin}>
          <div>
          <Box
            sx={AuthBoxEffect}
            className="!border-none"
          >
            <img src={companyLogo} alt='' width="180" height='50'/>
           
            <Box component="form" onSubmit={methods?.handleSubmit(onSubmit)}  sx={{width:'400px'}}>
            <Typography variant='h6' className='!mt-8 !mb-4'>{pageTitle}</Typography>
                {fields?.map((item, i) =>{
                    return(
                       <>
                        {item.type === 'file' ?
                          <>
                          {preview !== null ?
                            <>
                            <img src={preview} width={'100%'} height={'50'} style={{
                              objectFit:'cover',
                              height:200
                            }}/>
                            </>
                          :
                        <>
                        <Controller
                          name={item.name}
                          control={methods?.control}
                         
                          render={({ field: {onChange} }) => (
                            <Dropzone onDrop={acceptedFiles => {
                              onFileSelection(acceptedFiles)
                            }}>
                            {({getRootProps, getInputProps}) => (
                              <section className='my-4'>
                                <div {...getRootProps()} className='h-24 w-full flex items-center justify-center border border-dashed bg-[#EDECF9] border-[#8E8CA3]'>
                                  <input {...getInputProps()} className=''/>
                                  <div className='flex flex-col items-center justify-center'>
                                    <Typography variant='subtitle1' className="text-[#8E8CA3]">Upload Brand Logo</Typography>
                                  <Typography variant='subtitle2' className='text-[#8E8CA3]'>Acceptable format JPEG, PNG</Typography>
                                  </div>
                                </div>
                              </section>
                            )}
                            </Dropzone>
                          )}
                        />
                        </>}
                          </>
                        :
                        <Controller
                        name={item.name}
                        control={methods?.control}
                        render={({ field: {onChange} }) => (
                            <TextField
                              margin="normal"
                              required
                              fullWidth
                              id={item.id}
                              label={item.label}
                              name={item.name}
                              type={item.type}
                              disabled={item.disabled}
                              defaultValue={item.value}
                              error={!!methods?.formState.errors[item.name]}
                              helperText={methods?.formState.errors[item.name]?.message}
                              multiline={item.type === "textarea" ? true : false}
                              minRows={4}
                              autoFocus
                              onChange={(e) =>{
                                  onChange(e.target.value)
                              }}
                          />
                        )}
                      />
                        }
                      </>
                    )
                }) }
             
             
            
              <Grid container className='mb-5'>
              {checkboxAction ? 
              <FormControlLabel
              control={<Checkbox value="remember" color="primary" onChange={handleCheckBox} />}
              label="Remember me"
            />
            :
            <></>
             }
                {login ? 
                <Grid item xs className='!flex !items-center justify-end'>
                <div className='!no-underline cursor-pointer' 
                style={authLinks}
                onClick={() =>{
                  navigate(forgotLink)
                }}>
                  Forgot password?
                </div> 
                </Grid>
                :
                <></>
                }
                <Grid item>
                  <div variant="body2" 
                  style={authLinks}
                  onClick={() =>{
                  navigate(publicPageLink)
                }}>
                    {addtionalText}
                  </div>
                </Grid>
              </Grid>
              {
                onSubmit ? 
                <Button
                type="submit"
                fullWidth
                variant="contained"
                className='mt-5 mb-2'
                disabled={isSubmitting}
              >
                {actionTitle}
              </Button>
              :
              <></>
             }
            </Box>
            <Box className="!flex !items-center !my-4">
              {additionalEndLink !== "" ?
              <>
              <Typography className='!mx-2'>{addtionaEndText}</Typography>
              <div className='!no-underline cursor-pointer' 
              style={authLinks}
              onClick={() =>{
                  navigate(endLink)
                }}>{additionalEndLink} </div>
              </>
              :
              <div className='cursor-pointer' onClick={onBackPress}><Typography className='!mx-4' >Back</Typography></div>
              }
            </Box>
            <Box className='h-20 flex items-center justify-center'>
                <div className='flex cursor-pointer' onClick={() => navigate(signUpLink)}>
                    <Typography className='text-[#909196]' variant='body1'>{addtionalText1}</Typography>
                    <Typography variant='body1'>{addtionalText2}</Typography>
                  </div>
              </Box>
          </Box>
          </div>
          <div className='w-full absolute bottom-0'>
          <div className='h-full flex justify-center mb-4 text-[#004FE8] font-normal text-xs'>
            {`Version ${packageJson?.version}`}
          </div>
          </div>
        </div>
        <div
          className={isTablet ? 'w-full' : 'w-2/4'}
        >
        <img src={publicSideImage} alt='' style={specialEffectforImage}
          />
        </div>
      </div>
      </FormProvider>
     
    </ThemeProvider>
  );
}

export default AuthLayout
/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
// import * as React from 'react';
// import { useRef } from 'react';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import _ from 'lodash'
// import { useNavigate } from 'react-router';
// import { Controller, useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import { useMediaQuery } from 'react-responsive'
// import Paper from '@mui/material/Paper';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import { BoxEffects, elevationFromConstant, GridContainerWithFullHeights, specialEffectforImage, gridMargin, tabletMargin } from '../../constants/constant/constant';
// import { Typography } from '@mui/material';
// import { MuiOtpInput } from 'mui-one-time-password-input'
// import { useStyles } from './AuthBreakPoints';
// import SmallButtons from '../Buttons/SmallButtons';
// import GoogleIcon from '../../assets/Images/GoogleIcon.svg'
// import FacebookIcon from '../../assets/Images/FacebookIcon.svg'
// import { useSelector } from 'react-redux';
// import Progress from '../Progress/CircularProgess';



// function AuthLayout({
//     login,
//     fields,
//     schema,
//     onSubmit,
//     formValues,
//     forgotLink,
//     companyLogo,
//     actionTitle,
//     addtionalText1,
//     addtionalText2,
//     publicPageLink,
//     checkboxAction,
//     publicSideImage,
//     pageTitle,
//     loading,
//     additionalButtons,
//     facebookButton,
//     googleButton,
//     facebookImage,
//     googleImage,
//     GoogleButton,
//     smallGoogleButton,
// }) {
//     const ref = useRef()
//     const { 
//     control,
//     formState,
//     handleSubmit, 
//     } = useForm({
//     mode: 'onChange',
//     formValues,
//     resolver: yupResolver(schema),
//     });
//     const navigate = useNavigate();
//     const { isValid, dirtyFields, errors } = formState;
//     const classes = useStyles()
   
//     const isTablet = useMediaQuery({ query: '(max-width: 920px)'})

//   return (
//       <div className={isTablet ? 'block' : 'flex'}>
//         <CssBaseline />
//         <div className={isTablet ? 'w-full flex justify-center' : 'w-2/5 flex justify-center'} style={isTablet ? tabletMargin : gridMargin}>
//         <Box component="form" className={classes.box} noValidate onSubmit={handleSubmit(onSubmit)}>
//           <Box className={'w-full'}>
//             <div className={isTablet ? 'flex justify-center mx-8' : 'flex justify-start'}>
//                 <img className={classes.img} src={companyLogo} alt=''/>
//             </div>
//             <div className={isTablet ? 'mt-16 mx-8' : 'mt-16'}>
//             <Typography className={isTablet ? 'flex items-center justify-center' : 'flex items-center'} variant='subtitle1'>{pageTitle}</Typography>
//             </div>
            
//            <div className='w-full'>
//                 {fields?.map((item, i) =>{
//                     return(
//                         <>
//                         {item.name !== 'otp' ? 
//                         <div className='mb-4'>
//                         <Controller
//                         key={i}
//                         name={item.name}
//                         control={control}
//                         render={({ field: {onChange} }) => (
//                             <TextField
//                             ref={ref}
//                             margin='normal'
//                             className={classes.textfield}
//                             required
//                             fullWidth
//                             id={item.id}
//                             label={item.label}
//                             name={item.name}
//                             type={item.type}
//                             error={!!errors[item.name]}
//                             helperText={errors[item.name]?.message}
//                             autoFocus
//                             onChange={(e) =>{
//                                 onChange(e.target.value)
//                             }}
//                           />
//                         )}
//                       />
//                       </div>
//                       :
//                        <Controller
//                         key={i}
//                         name={item.name}
//                         control={control}
//                         render={({ field: {onChange} }) => (
//                             <MuiOtpInput value={item.value} onChange={(e) =>{
//                                 onChange(e)
//                                 item.value = e
//                             }} />
//                         )}
//                       />
                      
//                     }
                        
//                    </>
                        
//                     )
//                 }) 
//             }
//             <Grid container className='mb-5'>
//               {checkboxAction ? 
//               <FormControlLabel
//               control={<Checkbox value="remember" color="primary" />}
//               label="Remember me"
//             />
//             :
//             <></>
//              }
//                 {login ? 
//                 <Grid item xs className='flex items-center justify-end'>
//                 <Typography variant="body2" className='cursor-pointer' onClick={() => navigate(forgotLink)}>
//                   Forgot password?
//                 </Typography> 
//                 </Grid>
//                 :
//                 <></>
//                 }
               
//               </Grid>
//               {
//                 onSubmit ? 
//                 <>
//                 {loading ? 
//                 <Progress
//                     variant="contained"
//                     disabled={loading}
//                     color="primary"
//                     circleSize={30}
//                 />
//                 :
//                 <Button
//                     type="submit"
//                     fullWidth
//                     size='large'
//                     variant="contained"
//                     color='primary'
//                     disabled={_.isEmpty(dirtyFields) || !isValid}
//                     className='h-11 mt-5 mb-2 !text-sm'
//               >
//                 {actionTitle}
//                 </Button>
//                }
//                 </>
                
//               :
//               <></>
//              }
//              {/*{isTablet ? 
//              <>
//              <div className='flex justify-center items-center'>
//                 <div className='block mt-8'>
//                 <Typography className='flex justify-center mt-8 text-[#909196]' variant='body2'>Or Signin with</Typography>
//                  <div className='flex justify-between mt-8 w-36'>
//                  <SmallButtons icon={
//                     <><img src={GoogleIcon} width='25'/></>
//                  } />
//                  <SmallButtons icon={
//                     <><img src={FacebookIcon} width='25'/></>
//                  } />
//                  </div>
//                 </div>
//              </div>
//              </>
//              :
//              <>
//               {
//                 additionalButtons ? 
//                 <>
//                 {GoogleButton}
//                 {facebookButton}
//                 </>
//                 :
//                 <></>
//                 }
//                 </>
//                 }*/}
//               <Box className='h-20 flex items-center justify-center'>
//                   <div className='flex cursor-pointer' onClick={() => navigate(publicPageLink)}>
//                     <Typography className='text-[#909196]' variant='body1'>{addtionalText1}</Typography>
//                     <Typography variant='body1'>{ addtionalText2}</Typography>
//                   </div>
//               </Box>
           
//            </div>
//           </Box>
//           </Box>
//         </div>
//         <div className={isTablet ? 'w-full flex justify-center items-center' : 'w-3/5 flex justify-center items-center'}>
//         <img src={publicSideImage} alt='' style={specialEffectforImage}
//           />
//         </div>
//       </div>
//   );
// }

// export default AuthLayout