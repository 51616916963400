 
import RegularCheckbox from "../../../../shared-component/OverviewPage/overview-used-components/RegularCheckbox";
 
import DeleteIcon from '../../../../../asset/icons/DeleteIocn';
import DataGridTable from "../../../../shared-component/table-component/DataGridTable";
import TextBtn from "../../../../shared-component/button-component/TextBtn";
import EmptyCard from "../../../../shared-component/card-component/EmptyCard";


function SpecificationTemplate({ 
    handelDeleteModal, 
    specificationTemplateList, 
    handleTemplateEditData,
 }) {
  console.log('specificationTemplateList',specificationTemplateList)


    const columns = [
        {
            field: 'checkbox',
            headerName: '',
            minWidth: 50,
            headerClassName: 'custom-header',
            renderCell: (params) => (
                <RegularCheckbox />
            ),
        },
        {

            field: 'id',
            headerName: 'ID',
            minWidth: 180,
            headerClassName: 'custom-header',
        },

        {
            field: "template_name", headerName: "Template Name", width: 180, renderCell: (params) => (
                params.row.name || '-'
            )
        },
        { field: "link_group", headerName: "Linked Groups", width: 250, valueGetter: (params) => {
            const newGroupNames = params.row?.group_names?.map((val,i)=>{
                if(val === 'f2cc91d1-6c12-4a22-af81-e4a4aa0ba929'){
                    return "Location Details"
                }else{
                    return val
                }
            })
            if(newGroupNames?.length > 0){
              return newGroupNames
            }else{
                return '-'
            }
        }},
        {
            field: "actions",
            headerName: "",
            sortable: false,
            width: 150,
            align: "right",
            renderCell: (params) => (
                <>
                 <div className='w-full flex items-center px-2 cursor-pointer text-right justify-end'>
                 <TextBtn 
                  style={{
                    fontWeight: 300,
                 }}
                 title="Edit" onClick={() =>{
                        handleTemplateEditData(params?.row)
                    }} />
                         <div onClick={() => { handelDeleteModal(params.row,'Template') }} >
                        <DeleteIcon color="error" className="!fill-[#FF5C5C]"  />
                        </div>
                    </div>
                </>
            ),
        },
    ];
    return (
        specificationTemplateList.length === 0 ? 
        <EmptyCard title="No Template Available" subtitle='Click “Add New" to add the Template'/> 
         :
         <DataGridTable
         rows={specificationTemplateList}
         columns={columns}
         checkboxSelection={false}
     />
        
    )
}

export default SpecificationTemplate