function getDateAgo(daysAgo) {
    const today = new Date();
    const pastDate = new Date(today);
  
    pastDate.setDate(today.getDate() - daysAgo);
  
    // Formatting the date to YYYY-MM-DD format
    const year = pastDate.getFullYear();
    const month = String(pastDate.getMonth() + 1).padStart(2, '0');
    const day = String(pastDate.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

export default getDateAgo;