import React, { useCallback, useEffect, useState } from 'react'

import {
    TextField,
 } from '@mui/material'
 import { v4 } from 'uuid'
 import FormToolbar from '../FormToolbar'
 import FieldComponent from '../FormBuilderComponent/FieldComponent';
 import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
 import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
 import MoreVertIcon from '@mui/icons-material/MoreVert';
import Collapse from '../../../../../asset/icons/Collapse';
import SectionMenu from '../FormBuilderComponent/SectionMenu';
 
   

const SectionContainer = ({
    section,
    formState,
    setFormState,
    sectionIndex,
    onClickFormSection
}) =>{
    const [openSection, setOpenSection] = useState(true);
    const [isActive, setIsActive] = useState(false)

    const handleOpen = () =>{
        setOpenSection(true)
    }

    const handleClose = () =>{
        setOpenSection(false)
        setIsActive(false)
    }

    const onSectionDuplicate = () =>{
        let cloned = {...formState};
        cloned = {
            ...cloned,
            sections: [...cloned?.sections, {...cloned?.sections[sectionIndex], id: v4()}]
        }
        setFormState(cloned)
    }

    const onMergeWithAbove = () =>{
        let cloned = {...formState};
        cloned = {
            ...cloned,
            sections: cloned?.sections?.map((s, index) =>{
                if(index === sectionIndex - 1){
                    return {
                        ...s,
                        fields: [...s?.fields, ...cloned?.sections[sectionIndex]?.fields]
                    }
                }else{
                    return s
                }
            })?.filter((s, index) =>  index !== sectionIndex)
        }

        setFormState(cloned)
    }

    const onSectionDelete = () =>{
        let cloned = {...formState};
        cloned = {
            ...cloned,
            sections: cloned?.sections?.filter((s, index) =>{
                return index !== sectionIndex
            })
        }

        setFormState(cloned)
    }
 
    const returnSection = useCallback(() => {
       
       return <FieldComponent
                section={section}
                sectionIndex={sectionIndex}
                formState={formState}
                setFormState={setFormState} 
                onClickFormSection={onClickFormSection}
                isSection={true}
              />
    }, [formState])
    return (
        <div className='my-8'>
            <div className='w-full'>
                <div className='w-2/4 flex'>
                    <div className='w-2/4 bg-[#004FE8] flex items-center justify-center rounded-md'>
                        <div className='text-[#FFFFFF]' style={{
                            fontFamily: 'Rubik',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '18.96px',
                        }}>
                           {`Section ${sectionIndex + 1} of ${formState?.sections?.length}`}
                        </div>
                    </div>
                    <div className='w-2/4 flex items-center'>
                        <div className='flex m-2'>
                            <div className='cursor-pointer' onClick={handleOpen}>
                                <KeyboardArrowDownIcon className='!fill-[#353349]' />
                            </div>
                            <div className='cursor-pointer' onClick={handleClose}>
                                <KeyboardArrowUpIcon className='!fill-[#353349]' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-2/4'></div>
            </div>
            
            <div className='flex flex-col'>
                {/* {openSection && (
                <>
                
                   </>
                )} */}
                <div className='flex justify-between group'>
                    {isActive && (
                        <div className='flex flex-col justify-center bg-[#EDECF9] w-11/12 h-40'>
                        <div className='flex justify-between w-full'>
                            <div className="mx-4 my-4 flex flex-col justify-center w-2/4">
                             <TextField 
                                 className='!w-full'
                                 defaultValue={section?.section_name}
                                 placeholder='Form Heading'
                                 sx={{
                                     background: 'white',
                                 }}
                                 onChange={(val) =>{
                                 let cloned = {...formState};
                                 cloned = {
                                     ...cloned,
                                     sections: cloned?.sections?.map((e, i) =>{
                                     if(i === sectionIndex){
                                         return {
                                         ...e,
                                         section_name: val?.target?.value
                                         }
                                     }else{
                                         return e
                                     }
                                     })
                                 }
                                 setFormState(cloned)
                                 }}
                             />
                             <TextField 
                                 className='!w-full'
                                 placeholder="Form Description (Optional)"
                                 defaultValue={section?.section_description}
                                 sx={{
                                     background: 'white'
                                 }}
                                 onChange={(val) =>{
                                 let cloned = {...formState};
                                 cloned = {
                                     ...cloned,
                                     sections: cloned?.sections?.map((e, i) =>{
                                     if(i === sectionIndex){
                                         return {
                                         ...e,
                                         section_description: val?.target?.value
                                         }
                                     }else{
                                         return e
                                     }
                                     })
                                 }
                                 setFormState(cloned)
                                 }}
                             />
                             </div>
                             <div className='w-2/4 my-4'>
                                 <div className='flex items-center justify-end mx-8 my-8'>
                                     <div className="flex flex-col mx-4 cursor-pointer" onClick={() => setIsActive(false)}>
                                         <Collapse />
                                     </div>
                                     <div>
                                       <SectionMenu 
                                         formState={formState}
                                         sectionIndex={sectionIndex}
                                         onSectionDelete={onSectionDelete}
                                         onDuplicate={onSectionDuplicate}
                                         onMergeWithAbove={onMergeWithAbove}
                                       />
                                     </div>
                                 </div>
                                 
                             </div>
                        </div>
                     </div> 
                    )}
                    {!isActive && (
                         <div className='rounded-lg w-11/12 flex' style={{
                            border: "1px solid #8E8CA3"
                          }}>
                              <div className='flex flex-col m-8 w-3/4'>
                                    <div className='text-[#353349] mb-4' style={{
                                      fontFamily: "Rubik",
                                      fontSize: "20px",
                                      fontWeight: 400,
                                      lineHeight: "23.7px",
                                    }}>
                                      {formState?.sections[sectionIndex]?.section_name === "" ? "Section Name" : formState?.sections[sectionIndex]?.section_name}
                                    </div>
                                    <div className='text-[#8E8CA3]' style={{
                                        fontFamily: "Rubik",
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        lineHeight: "18.96px",
                                    }}>
                                        {formState?.sections[sectionIndex]?.section_description === "" ? "Section Description (Optional)" : formState?.sections[sectionIndex]?.section_description}
                                    </div>
                              </div> 
                              <div className='my-4'>
                                 <div className='flex items-center justify-end mx-8 my-4'>
                                     <div className="flex flex-col mx-4 cursor-pointer" onClick={() => setIsActive(true)}>
                                         <Collapse />
                                     </div>
                                     <div>
                                     <div>
                                       <SectionMenu 
                                         formState={formState}
                                         sectionIndex={sectionIndex}
                                         onSectionDelete={onSectionDelete}
                                         onDuplicate={onSectionDuplicate}
                                         onMergeWithAbove={onMergeWithAbove}
                                       />
                                     </div>
                                     </div>
                                 </div>
                                 
                             </div>
                          </div>
                    )}
                    <div className='hidden group-hover:block'>
                        <FormToolbar 
                        onClickForNewFields={() =>{
                            let cloned = {...formState};
                            cloned = {
                              ...cloned,
                              sections: cloned?.sections?.map((e, i) =>{
                                if(i === sectionIndex){
                                  return {
                                    ...e,
                                    fields: [
                                        ...e?.fields,
                                        {name: '', field_type: '', id: v4(), mandatory: true, helper_text: '', allow_specific_file_type: false, file_types: [], maximum_files: 1, maximum_size: 1}
                                    ]
                                  }
                                }else{
                                  return e
                                }
                              })
                            }
                            setFormState(cloned)   
                        }}
                        onClickFormSection={onClickFormSection}
                        />
                    </div>
                </div>
                 {openSection && (
                    returnSection()
                 )}
               
            </div>
        </div>
    )
}



const StepperContainer = ({
    section,
    formState,
    sectionIndex,
    setFormState,
    onClickFormSection
}) =>{
    const [openStepper, setOpenStepper] = useState(true);

    // const handleOpen = () =>{
    //     setOpenStepper(true)
    // }

    // const handleClose = () =>{
    //     setOpenStepper(false)
    // }

    const returnSectionContainer = useCallback(() =>{
       return  <SectionContainer  
                section={section}
                sectionIndex={sectionIndex}
                formState={formState}
                setFormState={setFormState}
                onClickFormSection={onClickFormSection}
            />
    }, [formState])
 
    return(
        <div className='flex flex-col'>
            {/* <div className='my-8'>
              {formState?.sections?.length > 1 && (
                 <StepperComponent steps={formState?.sections} />
              )}
            </div> */}
             <div className='flex flex-col'>
              {returnSectionContainer()}
            </div>
        </div>
    )
}

export default StepperContainer