import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Pagination,
  NativeSelect,
} from '@mui/material';
import HistoryRow from './Row/HistoryRow';

const HistoryTable = ({
  headers,
  rowData,
  isDetails,
  editModalToggling,
  notesModalToggling,
  deleteModalToggling,
  navigateToDetails,
  isbooking,
  openDrawerWithFormDetails,
  openDrawerWithUnitDetails,
  openDrawerWithPropertyDetails,
  formSubmissionList,
  changeBookingStatus,
}) => {
  const [newRows, setNewRows] = useState(rowData);
  const [mainRows, setMainRows] = useState(rowData);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: pageSize,
  });
  console.log('from row Data', rowData);
  useEffect(() => {
    if (rowData?.length > 0) {
      setMainRows(rowData);
      setNewRows(rowData);
      setPage(1);
      setPagination({ ...pagination, from: 0, to: pageSize });
      console.log('from new 00pw,dldld')
    }
  }, [rowData]);

  useEffect(() => {
    if (mainRows?.length > 0) {
      setPagination({ ...pagination, count: mainRows?.length });
      const newData = mainRows?.slice(pagination?.from, pagination?.to);
      console.log('from new Data', newData, pagination?.from, pagination?.to);
      setNewRows(newData);
    }
  }, [pagination?.from, pagination?.to, rowData, mainRows]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    const from = (newPage - 1) * pageSize;
    const to = (newPage - 1) * pageSize + pageSize;
    setPagination({ ...pagination, from: from, to: to });
  };

  const onPageChange = (event) => {
    setPageSize(Number(event.target.value));
    setPagination({ ...pagination, from: 0, to: Number(event.target.value) });
    setPage(1);
  };

  return (
    newRows?.length > 0 && (
      <>
        <TableContainer
          component={Paper}
          sx={{
            borderBottomLeftRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
            borderTopLeftRadius: '8px !important',
            borderTopRightRadius: '8px !important',
            borderLeft: '2px solid #E1EBFE !important',
            borderTop: '2px solid #E1EBFE !important',
            borderRight: '2px solid #E1EBFE !important',
            borderBottom: 'none !important',
            boxShadow: 'none',
          }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: `${50}px !important`,
                  }}
                />
                {headers?.map((item) => {
                  return (
                    <TableCell
                      align={item?.align}
                      sx={{
                        minWidth: `${item?.minWidth}px !important`,
                        maxWidth: `${item?.minWidth}px !important`,
                        width: `${item?.minWidth}px !important`,
                        color: '#8E8CA3 !important',
                        fontWeight: 400,
                      }}
                    >
                      {item?.header_name}
                    </TableCell>
                  );
                })}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {newRows?.map((row) => (
                 <HistoryRow
                 isDetails={isDetails}
                 isbooking={isbooking}
                 key={row.id}
                 row={row}
                 formSubmissionList={formSubmissionList}
                 editModalToggling={editModalToggling}
                 deleteModalToggling={deleteModalToggling}
                 notesModalToggling={notesModalToggling}
                 navigateToDetails={navigateToDetails}
                 changeBookingStatus={changeBookingStatus}
                 openDrawerWithFormDetails={openDrawerWithFormDetails}
                 openDrawerWithUnitDetails={openDrawerWithUnitDetails}
                 openDrawerWithPropertyDetails={openDrawerWithPropertyDetails}
               />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="flex justify-center items-center"
          style={{
            borderTop: 'none',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            borderLeft: '2px solid #E1EBFE',
            borderBottom: '2px solid #E1EBFE',
            borderRight: '2px solid #E1EBFE',
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            position: 'relative',
          }}
        >
          <div className="my-1.5">
            <Pagination
              page={page}
              count={Math.ceil(pagination?.count / pageSize)}
              onChange={handlePageChange}
            />
            <div
              className="flex align-center"
              style={{
                position: 'absolute',
                right: 20,
                top: 7,
              }}
            >
              <p
                className="mt-1"
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '13px',
                  fontWeight: 300,
                  color: '#353349',
                  marginRight: '10px',
                }}
              >
                Rows per page:
              </p>
              <div
                style={{
                  borderRadius: '4px',
                  border: '1px solid #E1EBFE',
                }}
              >
                <NativeSelect
                  variant="filled"
                  defaultValue={pageSize}
                  onChange={onPageChange}
                  disableUnderline
                  sx={{
                    fontSize: '13px',
                    paddingLeft: '10px',
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </NativeSelect>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default HistoryTable;
