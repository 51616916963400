import React from 'react';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import FormManager from '../../../shared-component/formrelated-component/FormManager';
import { apiService } from '../../../../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import { useParams } from 'react-router-dom';

const AddTitle = ({
    open,
    data,
    title,
    onClose,
    successCallback
}) =>{
    const { id } = useParams()
    const { exitFromPlatform } = useExit()
    const defaultValues = {
        title: 'Untitled',
        map_source: "Open Streetmap"
    };

    const handleTitleSubmit = (e) =>{
        if(e?.title === ''){
            enqueueSnackbar('Plase add map name', {variant:'error'})
        }else{
            const payload = {
                ...e,
                map_source: e?.source?.id
            }
            apiService.put(`property/geomap/${id}/`, payload).then((res) =>{
                if(res?.data){
                   enqueueSnackbar("Title Updated and map added.", {variant:'success'})
                   successCallback(res?.data)
                }
            })
            .catch((error) =>{
                if(error.response?.data){
                    const returnedError = returnError(error.response?.data)
                    if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                      exitFromPlatform()
                  }else{
                      enqueueSnackbar(returnedError, {variant:'error'})
                      setLoading(false)
                  }
                  }
            })
        }
    }

    const fieldMap = [
        {
            name:'title',
            label:'Maps name',
            placeholder:'Untitled',
            type:'text',
            fieldProps: {
                required: true,
              },
        },
        {
            name:'map_source',
            label:'Maps Source',
            placeholder:'Untitled',
            type:'select',
            fieldProps: {
                defaultOptions: [
                    {title: 'Open Streetmap', id: 'openstreet'}
                ],
                titleKey: 'title',
                disabled: true
            }

        }
    ];

    return(
       <FormModal
         open={open}
         title={title}
         onClose={onClose}
       >
            <div className='mt-4'>
            <FormManager 
              data={data}
              defaultValues={defaultValues}
              fieldMap={fieldMap}
              onCancel={onClose}
              formSubmit={handleTitleSubmit}
            />
            </div>
       </FormModal>
    )
}

export default AddTitle