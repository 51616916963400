import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Typography } from '@mui/material';

const PieChart = ({
  donutColors,
  setCenterData,
  data
}) =>{
    const [centerValue, setCenterValue] = React.useState(0)
    const [pieState, setPieState] = React.useState(null)
    const state = {
        series: data,
        options: {
          chart: {
            type: 'donut',
            events: {
              dataPointMouseEnter: (event, chartContext, config) => { 
                    console.log('from selection', config?.w?.config?.chart, config)
                    setCenterData({
                      data: config?.w?.config?.series[config?.dataPointIndex],
                      color: config?.w?.globals?.fill?.colors[config?.dataPointIndex]
                    })
                }
            }
          },
          states: {
            active: {
              filter: {
                type: 'none' /* none, lighten, darken */
              }
            }
          },
          colors: donutColors,
          stroke: {
            width: 0
          },
          plotOptions: {
            pie: {
              size: 600,
              expandOnClick: false,
            }
          },
          tooltip:{
           enabled:false
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 800
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
      
      
      };
    
    useEffect(() =>{
      setPieState(state)
    }, [centerValue])
    return(
       <>
        {pieState !== null && (
                <ReactApexChart options={pieState.options} series={pieState.series} type="donut" />
            )}
       </>
    )
}

export default PieChart;