import React, { useState, useEffect } from 'react';
import Loading from '../../../../../shared-component/Loading/Loading';
import DataGridTable from '../../../../../shared-component/table-component/DataGridTable';
import EmptyCard from '../../../../../shared-component/card-component/EmptyCard';
import AddUser from '../modals/AddUser';
import FilterUsers from '../modals/FilterUsers';
import returnError from '../../../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../../../utils/useExit';
import { apiService } from '../../../../../../constant/AuthConstant';
import InviteUser from '../modals/InviteUser';

const sampleData = [
  {
    id: 1,
    name: 'Swapnil',
    position: 'Marketing',
    email: 'swapnil@gmail.com',
    role: 'Manager',
    active: true,
  },
];

const Users = ({
  openInviteModal,
  handleInviteModal,
  openFilterModal,
  handleFilterModal,
  setOpenInviteModal,
}) => {
  const { exitFromPlatform } = useExit();
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState({ name: '', id: '' });
  const [keyword, setKeyword] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const handlOpenAddUser = () =>{
    setOpenAddUserModal(!openAddUserModal)
  }

  const columns = [
    { field: 'id', headerName: 'User ID', minWidth: 100 },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 200,
      renderCell: (params) => params.row.full_name || '-',
    },
    {
      field: 'position',
      headerName: 'Position',
      minWidth: 200,
      renderCell: (params) => params.row.position || '-',
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 350,
      renderCell: (params) => params.row.email || '-',
    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 200,
      renderCell: (params) => params.row.userrole || '-',
    },
    {
      field: 'active',
      headerName: 'Status',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div
            style={{
              fontFamily: 'Rubik',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '23.7px',
              letterSpacing: '-0.022em',
              color: '#24D315',
            }}
          >
            {'Active'}
          </div>
        );
      },
    },
  ];

  const getUsers = () => {
    setLoading(true);
    apiService
      .get(`account/user/`)
      .then((res) => {
        if (res?.data?.length) {
         setUsersList(res?.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleEvent = (event) => {
    setSelectedUser(event?.row);
    setOpenInviteModal(true);
  };

  const submitToFilterUser = (payload) =>{
    const newData = {
       search: payload.query,
       role: payload?.role?.id
    }

    Object.entries(newData)?.map((e) =>{
      if(e[1] === '' || e[1] === null || e[1] === undefined){
        delete newData[e[0]]
      }
    })
     const searchParams = new URLSearchParams(newData).toString();
    setFilterLoading(true)
    apiService.get(`account/user/?${searchParams}`).then((res) =>{
      if(res?.data?.length){
        setUsersList(res?.data)
        handleFilterModal()
      }else{
        enqueueSnackbar("Not found. Please use different keyword", { variant:'error' })
      }
      setFilterLoading(false)
    }).catch((error) =>{
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setFilterLoading(false)
        }
      }
    })
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : usersList?.length > 0 ? (
        <DataGridTable
          rows={usersList}
          columns={columns}
          //handleEvent={handleEvent}
        />
      ) : (
        <EmptyCard
          title="No Users Available"
          subtitle="Click “Add New” to add the Users"
        />
      )}

      {openInviteModal && (
        <AddUser
          open={openInviteModal}
          title="Add"
          onClose={() => {
            setSelectedUser(null);
            handleInviteModal();
          }}
          data={selectedUser?.id ? selectedUser : {}}
        />
      )}

      {openFilterModal && (
        <FilterUsers
          role={role}
          setRole={setRole}
          keyword={keyword}
          open={openFilterModal}
          setKeyword={setKeyword}
          handleClose={handleFilterModal}
          onSubmit={submitToFilterUser}
          loading={filterLoading}
        />
      )}
    </>
  );
};

export default Users;
