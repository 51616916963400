import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Liquid } from 'liquidjs';
import './website_preview.css';
import UserContext from '../../../../../context/UserContext';
import { BASE_URI } from '../../../../../constant/AuthConstant';


const WebsitePreview = ({
  menus,
  pages,
  themeDetails,
  selectedView,
}) => {

  const { organization } = useContext(UserContext)
  const data = {
    code: themeDetails?.code,
    template_name: themeDetails?.template_name,
    header_code: themeDetails?.header_code,
    footer_code: themeDetails?.footer_code,
    global_css: themeDetails?.global_css,
    global_js: themeDetails?.global_js,
    template_type: themeDetails?.template_type,
    menus: menus,
    pages: pages,
  };
  const engine = new Liquid();
  const [htmlContent, setHtmlContent] = useState('');
  function renderTemplate(template, data) {
    return template
      .replace('{{ page.title }}', data?.pages[0]?.page_title)
      .replace('{{ page.title }}', data?.pages[0]?.page_title)
      .replace('{{ global_css }}', data?.global_css)
      .replace('{{ page_css }}', data?.pages[0]?.css_code)
      .replace('{{ header_code }}', data?.header_code)
      .replace('{{ page_content }}', data?.pages[0]?.liquid_code)
      .replace('{{ footer_code }}', data?.footer_code)
      .replace('{{ global_js }}', data?.global_js)
      .replace('{{ page_js }}', data?.pages[0]?.js_code)
      .replace('{{ website_image }}', `${BASE_URI}${organization?.brand_logo}`)
  }

  const runLiquidConvertor = async () => {
    const renderedHtml = await engine.parseAndRender(
      renderTemplate(data?.code, data),
      data,
    );
    setHtmlContent(renderedHtml);
  };

  useEffect(() => {
    runLiquidConvertor();
  }, []);
  
  return (
    menus?.length > 0 && pages?.length > 0 ?
    <div
    style={{
      height: '100vh',
      overflowY: 'scroll',
      width: selectedView === 'Desktop' ? '100%' : (selectedView === 'Tablet' ? '60%' : '30%')
    }}
    // dangerouslySetInnerHTML={{ __html: htmlContent }}
  >
    <iframe
      className='w-full !h-full'
      srcDoc={htmlContent}
      // style={{
      //   height: '100% !important',
      // }}
    />
  </div>
  :
  <></>
  );
};

export default WebsitePreview;

// const data = {
//   code: '<!DOCTYPE html><html lang="en"><head>    <meta charset="UTF-8">    <meta name="viewport" content="width=device-width, initial-scale=1.0">    <title>{{ page.title }}</title>    <link rel="preconnect" href="https://fonts.googleapis.com">    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>    <link href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap" rel="stylesheet">    <style class="apply-styles-globally">        {{ global_css }}    </style>  <style class="style-for-pages"> {{ page_css }} </style></head><body>   <nav class="top-nav__container">       {{ header_code }}    </nav>    <main id="website_main-content">       {{ page_content }}    </main>    <footer class="landing-footer__container">      {{ footer_code }}    </footer>    <script id="landing_global_script" defer> {{ global_js}} </script><script id="landing_page_script">{{ page_js }}</script></body></html>',
//   template_name: 'Template 1',
//   header_code: `        <div>
// <img class="top-nav__container_image" src={{ website_image }} alt="homez">
// </div>

// <div class="top-nav__container_breadcrumb" id="landing-ham_buger">
// <span class="top-nav__container_bread"></span>
// <span class="top-nav__container_bread"></span>
// <span class="top-nav__container_bread"></span>
// </div>

// <div  class="top-nav__container_burred_screen" id="ham-sidebar__blurred_screen">
// </div>

// <div class="top-nav__container_side_bar" id="landing-ham_sidebar">
// <div class="top-nav__container_side_bar_close_icon" id="landing-ham_sidebar_close_icon">
//  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none">
//    <mask id="mask0_4461_5389" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
//    <rect width="24" height="24" fill="#D9D9D9"/>
//    </mask>
//    <g mask="url(#mask0_4461_5389)">
//    <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#353349"/>
//    </g>
//    </svg>
// </div>
// <div class="top-nav__navigations" id="top-navigations">
// {% for item in menus -%}
//   {% if item.menu_location == "Header" %}
//      {% for menu in item.menu_items %}
//      {% assign menuItem = menu | json %}
//      {% assign menuItem_data = menuItem | base64_encode %}
//      {% assign pages_data = pages | json %}
//      {% assign pagesItems_data = pages_data | base64_encode %}
//         <a class="top-nav__navigations_items top-nav__navigations_items_font_style top_header_menu_items" 
//         data-menu-data="{{ menuItem | escape }}"
//         data-pages-data="{{ pagesItems_data | escape }}"
//         onclick="handleClick(this)">
//            {{ menu.menu_name }}
//         </a>
//      {%- endfor %}
//   {% endif %}
// {%- endfor %}
// </div>
// </div>

// `,
//   footer_code:
//     `
//     {% for item in menus -%}
//     {% if item.menu_location == "Footer" %}
//     <div class="footer-nav__navigations" id="top-navigations">
//          {% for menu in item.menu_items %}
//          {% assign menuItem = menu | json %}
//           {% assign menuItem_data = menuItem | base64_encode %}
//           {% assign pages_data = pages | json %}
//           {% assign pagesItems_data = pages_data | base64_encode %}
//             <a class="top-nav__navigations_items footer-nav__navigations_items_font_style"
//             data-menu-data="{{ menuItem | escape }}"
//             data-pages-data="{{ pagesItems_data | escape }}"
//             onclick="handleFooterMenuClick(this)"
//             >
//                {{ menu.menu_name }}
//             </a>
//          {%- endfor %}
//          </div>
//       {% endif %}
//     {%- endfor %}
// `,
//   global_css:
//     `body {
//  margin: 0 !important;
//  font-family: 'Rubik' !important;

// }

// :root {
//  --primary-color: #004FE8;
//  --secondary-color: #8E8CA3;
//  --text-black-color: #353349;
//  --text-secondary-color: #8E8CA3;
//  --text-white-color: #FFFFFF;
//  --shadow-black-color: rgba(0, 0, 0, 0.25);

// }

// .top-nav__container {
//  width: 100%;
//  height: 100px;
//  display: flex;
//  align-items: center;
//  justify-content: space-between;
//  box-shadow: 0px 0px 4px 0px var(--shadow-black-color);
//  margin-bottom: 50px;

// }



// .top-nav__navigations_items {
//  padding: 12px 18px;

// }

// .top-nav__navigations_items_font_style {
//  font-family: 'Rubik';
//  font-size: 16px;
//  font-weight: 400;
//  line-height: 18.96px;
//  letter-spacing: -0.022em;
//  text-align: left;
//  color: var(--text-secondary-color);
//  cursor: pointer;

// }

// .top-nav__container_image {
//  width: 200px;
//  height: 80px;
//  padding: 0px 50px;

// }

// .top-nav__navigations_button {
//  width: 118px;
//  height: 47px;
//  padding: 14px 16px 14px 16px;
//  color: var(--text-white-color);
//  border-radius: 4px;
//  opacity: 0px;
//  background: var(--primary-color);
//  margin: 0px 30px 0px 10px;
//  border: none;

// }

// .section-container__header {
//  font-size: 48px;
//  font-weight: 500;
//  line-height: 56.88px;
//  letter-spacing: -0.022em;
//  text-align: center;
//  color: var(--text-black-color);
//  padding: 60px 0px 20px 0px;

// }

// .section-container__main {
//  width: 100%;
//  height: 100vh;

// }

// iframe {
//  border: none
// }

// .section_frame {
//  width: 100%;
//  height: 100%;

// }

// .landing-footer__container {
//  width: 100%;
//  margin-top: 50px;
//  min-height: 200px;
//  background-color: #353349;

// }

// .footer-nav__navigations {
//  display: grid;
//  grid-template-columns: auto auto;
//  grid-auto-flow: row;
//  padding: 50px;
// }

// .footer-nav__navigations_items_font_style {
//  font-family: 'Rubik';
//  font-size: 16px;
//  font-weight: 400;
//  line-height: 18.96px;
//  letter-spacing: -0.022em;
//  text-align: left;
//  color: var(--text-white-color);
//  cursor: pointer;

// }

// .landing-footer__copyright {
//  font-family: 'Rubik';
//  font-size: 16px;
//  font-weight: 400;
//  line-height: 18.96px;
//  letter-spacing: -0.022em;
//  text-align: left;
//  color: var(--text-white-color);
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  padding: 10px 0px;

// }

// @media only screen and (max-width: 700px) {

// .top-nav__navigations {
//    display: none;
  
// }

//  .top-nav__container_image {
//    width: 150px;
//    padding: 0px 20px;
  
// }

//   .top-nav__container_breadcrumb {
//    display: flex;
//    flex-direction: column;
//    width: 30px;
//    margin: 0px 20px;
//    border: 1px solid var(--text-black-color);
//    padding: 5px;
//    border-radius: 4px;
//    cursor: pointer;
  
// }

//  .top-nav__container_bread {
//    width: 100%;
//    height: 3px;
//    border-radius: 25px;
//    margin: 1px 0px;
//    background-color: var(--text-black-color);
  
// }

//  .top-nav__container_side_bar {
//    display: none;
//    width: 300px;
//    height: 100vh;
//    position: absolute;
//    top: 0px;
//    right: 0px;
//    background-color: var(--text-white-color);
//    z-index: 9999;
//    transition: 3s ease-in-out;
  
// }

//  .top-nav__container_burred_screen {
//    display: none;
//    background-color: var(--shadow-black-color);
//    position: absolute;
//    top: 0px;
//    width: 100%;
//    height: 100vh;
//    z-index: 999;
  
// }

//  .top-nav__container_side_bar_close_icon {
//    display:flex;
//    margin: 10px 20px;
  
// }

// .top-nav__navigations {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   padding-right: 50px;
 
// }


// }

// @media only screen and (min-width: 701px) {

// .top-nav__container_side_bar_close_icon {
//   display:none;
//   margin: 10px 20px;
 
// }


// .top-nav__navigations {
//   display: flex;
//   align-items: center;
//   padding-right: 50px;
 
// }

// .top-nav__navigations {
//    display: flex;
  
// }

//  .top-nav__container_breadcrumb {
//    display: none;
  
// }

// }

// .top-nav__navigations_items_selected {
// color: var(--text-black-color) !important;
//  text-decoration: underline !important;
// }

// .top-nav__navigations_side_bar {
// display: block;
//  padding-right: 50px;
// }`,
//   global_js: `
// console.log("rendered")

// const parseContent = (encodedData) =>{
//   const decodedString = encodedData.replace(/&#34;/g, '"').replace(/&lt;/g, '<').replace(/&gt;/g, '>')
//   const parsedData = JSON.parse(decodedString);
//   return parsedData
// }
// const handleClick = (e) =>{
 
//   const menuD = e.getAttribute('data-menu-data');
//   const pages = e.getAttribute('data-pages-data');

//   const menus_parsed = JSON.parse(menuD);
//   const pages_parsed = JSON.parse(pages);


//   const $mainWebsiteContainer = document.querySelector('#website_main-content')
//   const $all_Menu_items = document.querySelectorAll('.top_header_menu_items');
//   const $styleTagForPages = document.querySelector('.style-for-pages');
//   const $pageScript = document.querySelector('#landing_page_script);

//   if($all_Menu_items?.length > 0){
//     $all_Menu_items.forEach((item) =>{
//       item.classList.remove('top-nav__navigations_items_selected');
//         e.classList.add('top-nav__navigations_items_selected');
//     })
//   }
//   console.log('from .....', menus_parsed, pages_parsed, $mainWebsiteContainer)
//   if($mainWebsiteContainer !== null){
//     if(menus_parsed?.linked_page?.url !== ""){
//       const findPage = pages_parsed?.find((page) =>{
//         return page?.url?.toLowerCase()?.includes(menus_parsed?.linked_page?.url?.toLowerCase());
//       })

//       if(findPage?.liquid_code !== ""){
//         if($styleTagForPages !== null){
//           $styleTagForPages.innerHTML = findPage.css_code
//         }
//         if($pageScript !== null){
//           $pageScript.innerHTML = findPage.js_code
//         }
//         $mainWebsiteContainer.innerHTML = findPage?.liquid_code
        
//       }
//       console.log('from filteredPage', findPage)
//     }
//     else if(menus_parsed?.linked_page?.url === ""){
//       const find_page = pages_parsed?.find((page) =>{
//         return page?.is_home_page === true
//       })
//       if(find_page?.liquid_code !== ""){
//         if($styleTagForPages !== null){
//           $styleTagForPages.innerHTML = find_page.css_code
//         }
//         if($pageScript !== null){
//           $pageScript.innerHTML = find_page.js_code
//         }
//         $mainWebsiteContainer.innerHTML = find_page?.liquid_code
//       }
//     }
//   }
 
// }

// const handleFooterMenuClick = (e) =>{
 
//   const menuD = e.getAttribute('data-menu-data');
//   const pages = e.getAttribute('data-pages-data');

//   const menus_parsed = JSON.parse(menuD);
//   const pages_parsed = JSON.parse(pages);


//   const $mainWebsiteContainer = document.querySelector('#website_main-content')
//   const $all_Menu_items = document.querySelectorAll('.top_header_menu_items');
//   const $styleTagForPages = document.querySelector('.style-for-pages');
//   const $pageScript = document.querySelector('#landing_page_script);
  
//   if($mainWebsiteContainer !== null){
//     if(menus_parsed?.linked_page?.url !== ""){
//       const findPage = pages_parsed?.find((page) =>{
//         return page?.url?.toLowerCase()?.includes(menus_parsed?.linked_page?.url?.toLowerCase());
//       })

//       if(findPage?.liquid_code !== ""){
//         if($styleTagForPages !== null){
//           $styleTagForPages.innerHTML = findPage.css_code
//         }
//         if($pageScript !== null){
//           $pageScript.innerHTML = findPage.js_code
//         }
//         $mainWebsiteContainer.innerHTML = findPage?.liquid_code
        
//       }
//       console.log('from filteredPage', findPage)
//     }
//     else if(menus_parsed?.linked_page?.url === ""){
//       const find_page = pages_parsed?.find((page) =>{
//         return page?.is_home_page === true
//       })
//       if(find_page?.liquid_code !== ""){
//         if($styleTagForPages !== null){
//           $styleTagForPages.innerHTML = find_page.css_code
//         }
//         if($pageScript !== null){
//           $pageScript.innerHTML = find_page.js_code
//         }
//         $mainWebsiteContainer.innerHTML = find_page?.liquid_code
//       }
//     }
//   }
 
// }

// document.addEventListener("DOMContentLoaded", function() {
//   const hamburger = document.querySelector('#landing-ham_buger');
//   const $FromHamToSidebar = document.querySelector('#landing-ham_sidebar');
//   const $BlurredScreenElement = document.querySelector('#ham-sidebar__blurred_screen');
//   const $CloseSideScreenElement = document.querySelector("#landing-ham_sidebar_close_icon");
//   const $allMenuItemsFromHeader = document.querySelectorAll(".top_header_menu_items");

//   const $_all_Menu_items = document.querySelector('.top_header_menu_items');
//   console.log('from active menu', $_all_Menu_items);
//   if($_all_Menu_items !== null){
//     $_all_Menu_items.classList.add('top-nav__navigations_items_selected');
//   }

//   if(hamburger !== null){
//       hamburger.addEventListener('click', () =>{
//           if($FromHamToSidebar !== null){
//               $BlurredScreenElement.style.display = 'flex'
//               $FromHamToSidebar.style.display = 'flex'
//           }
//           $BlurredScreenElement.addEventListener('click', () =>{
//               $BlurredScreenElement.style.display = 'none'
//               $FromHamToSidebar.style.display = 'none'
//           })
//       })

//       if($CloseSideScreenElement !== null){
//           $CloseSideScreenElement.addEventListener('click', () =>{
//               $BlurredScreenElement.style.display = 'none'
//               $FromHamToSidebar.style.display = 'none'
//           })
//       }
//   }

//   if($allMenuItemsFromHeader !== null){
//     $allMenuItemsFromHeader?.forEach((e) =>{
//       console.log('from', e)
//       const $dataFromMenu = e.getAttribute('data-menu');
//     })
//   }
// });`,
//   template_type: 'single-property',
//   menus: menus,
//   pages: pages,
// };



