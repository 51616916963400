import React from 'react';
import { TextField } from '@mui/material';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import FilterFields from '../../leads/Fields/FilterFields';
import { SecondaryButton, PrimaryButton } from '@assystant/mui-buttons';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';


const FilterAffiliate = ({
    title,
    open,
    onClose,
    search,
    setSearch,
    type,
    setType,
    active,
    setActive,
    onSubmit,
}) =>{

    const onReset = () =>{
        setSearch('')
        setType({name: '', id: ''})
        setActive({name: 'True', id: 'True'})
    }
    return(
        <FormModal
           open={open}
           title={title}
           onClose={onClose}
        >
          <div className='m-8'>
             <TextField
                fullWidth
                label="Search"
                type="text"
                value={search}
                defaultValue={search}
                onChange={(e) =>{
                    setSearch(e.target.value)
                }}
            />
             <FilterFields
               value={active}
               setValue={setActive}
               label="Active"
               defaultOptions={[{
                name: 'True',
                id:'True'
               }, {
                name:'False',
                id:'False'
               }]}
              />
              <div className="w-full flex justify-end pt-4">
                    <SecondaryBtn style={{ backgroundColor: '#EDECF9', boxShadow: 'none', 
                                fontWeight:300,
                                padding:'6px 16px' }} onClick={onClose} title='Cancel' />
                    <SecondaryBtn style={{ backgroundColor: '#EDECF9', color:'#FF5C5C',  boxShadow: 'none', 
                                fontWeight:300,
                                padding:'6px 16px' }} onClick={onReset} title='Reset' />
                    <PrimaryBtn title='Filter' 
                    style={{
                        marginRight: '0px !important',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px'
                    }} onClick={() =>{
                        onSubmit({
                            search: search,
                            active: active?.id ? active?.id : 'True'
                        })
                    }}/>
                </div>
          </div>
        </FormModal>
    )
}

export default FilterAffiliate