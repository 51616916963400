import React, { useState, useEffect } from 'react';
import Loading from '../../../../../shared-component/Loading/Loading';
import DataGridTable from '../../../../../shared-component/table-component/DataGridTable';
import EmptyCard from '../../../../../shared-component/card-component/EmptyCard';
import AddRole from '../modals/AddRole';
import DeleteIcon from '../../../../../../asset/icons/DeleteIocn';
import RemovePopup from '../../../../../shared-component/RemovePopup/RemovePopup';
import returnError from '../../../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../../../utils/useExit';
import { apiService } from '../../../../../../constant/AuthConstant';

const sampleData = [
  {
    id: 1,
    name: 'Marketing Manager',
  },
];

const Roles = ({ openRoleModal, handleRoleModal, setOpenRoleModal }) => {
  const { exitFromPlatform } = useExit();
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [removeLoading, setRemoveLoading] = useState(false)

  const handleRemoveModal = () =>{
    setRemoveModal(!removeModal)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 200,
      renderCell: (params) => params.row.name || '_',
    },
    // {
    //     field: 'actions',
    //     headerName: '',
    //     sortable: false,
    //     minWidth: 50,
    //     align:'center',
    //     renderCell: (params) => (
    //         <>
    //          <div className="cursor-pointer" onClick={(e)=>{
    //           e.stopPropagation()
    //           e.preventDefault()
    //           setSelectedRole(params?.row);
    //           handleRemoveModal()
    //          }}>
    //                 <DeleteIcon color="error" className="!fill-[#FF5C5C]"  />
    //             </div>
    //         </>
    //         ),
    //   },
  ];

  const getRoles = () => {
    setLoading(true);
    apiService
      .get(`rbac/role/`)
      .then((res) => {
        if (res?.data?.length) {
          setRoleList(res?.data)
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  // const handleEvent = (event) =>{
  //   setSelectedRole(event?.row);
  //   setOpenRoleModal(true)
  // }

  const removeRole = () =>{
    setRemoveLoading(true)
    apiService
      .delete(`rbac/role/${selectedRole?.id}/`)
      .then((res) => {
        if (res?.status === 204) {
         const updatedList = [...roleList]?.filter((e) =>{
          return e?.id !== selectedRole?.id
         })
         enqueueSnackbar("Deleted", { variant: 'success'})
         setSelectedRole(null);
         setRoleList(updatedList)
         handleRemoveModal()
        }
        setRemoveLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setRemoveLoading(false);
          }
        }
      });
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : roleList?.length > 0 ? (
        <DataGridTable rows={roleList} columns={columns} actionWidth={100} />
      ) : (
        <EmptyCard
          title="No Users Available"
          subtitle="Click “Add New” to add the Users"
        />
      )}

      {openRoleModal && (
        <AddRole
          open={openRoleModal}
          title="Add New"
          onClose={() =>{
            setSelectedRole(null)
            handleRoleModal()
          }}
          data={selectedRole?.id ? selectedRole : {}}
          successCallback={() =>{
            getRoles()
          }}
        />
      )}

      {removeModal && (
        <RemovePopup
          open={removeModal}
          close={() =>{
            setSelectedRole(null)
            handleRemoveModal()
          }}
          description={"Are you sure, You want to remove this role?"} 
          onSubmit={() => {
            removeRole()
          }}
          loading={removeLoading}
        />
      )}
    </>
  );
};

export default Roles;
