import React, { useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const SelectComponent = ({ row, changeStatus, booking_status }) => {
  const [status, setStatus] = useState(booking_status);
  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={status}
        label=""
        data-role="select-status"
        IconComponent={(props) => {
          if (props?.className?.includes('MuiSelect-iconOpen')) {
            return (
              <ExpandLess
                {...props}
                className="!fill-[#8E8CA3]"
                sx={{
                  pointerEvents: 'none !important',
                  position: 'absolute !important',
                  right: '5px !important',
                }}
              />
            );
          }
          return (
            <ExpandMore
              {...props}
              sx={{
                pointerEvents: 'none !important',
                position: 'absolute !important',
                right: '5px !important',
              }}
              className="!fill-[#8E8CA3]"
            />
          );
        }}
        onChange={(e) => {
          e?.stopPropagation();
          setStatus(e.target.value);
          changeStatus(row, e);
        }}
        onClick={(e) => {
          e?.stopPropagation();
        }}
        sx={{
          height: '40px',
          background: 'white !important',
          color:
            status === 'opened' || status === 'confirmed'
              ? '#24D315'
              : '#FF5C5C',
          fontWeight: 300,
        }}
      >
        <MenuItem
          value="opened"
          sx={{ color: '#24D315 !important', fontWeight: 300 }}
        >
          Booking Received
        </MenuItem>
        <MenuItem
          value="confirmed"
          sx={{ color: '#24D315 !important', fontWeight: 300 }}
        >
          Booking Confirmed
        </MenuItem>
        <MenuItem
          value="inactive"
          sx={{ color: '#FF5C5C !important', fontWeight: 300 }}
        >
          Booking Cancelled
        </MenuItem>
        <MenuItem
          value="waitlist"
          sx={{ color: '#FF5C5C !important', fontWeight: 300 }}
        >
          Waitlist
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectComponent;
