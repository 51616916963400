import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Stack, 
  Skeleton,
} from '@mui/material';
import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions'
import { BoxEffects, headerButton, stack, grids } from '../../../../Config/Constants'
import Box from '@mui/material/Box';
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons';
import { enqueueSnackbar } from 'notistack';
import DetailsCard from '../../../shared-component/card-component/DetailsCard';
import GridComponent from '../../../shared-component/grid-component/GridComponent';
import FilterFields from '../../leads/Fields/FilterFields';
import useExit from '../../../../utils/useExit';
import returnError from '../../../../utils/ExportError';
import { apiService } from '../../../../constant/AuthConstant';
import UserContext from '../../../../context/UserContext';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import Loading from '../../../shared-component/Loading/Loading';
import LabelAndValue from '../../../shared-component/OverviewPage/overview-used-components/LabelAndValue';
 
 
const arr1 = [
    { title: 'Dashboard', url: '/' },
  ]

const AffiliatePortalDashboard = () =>{
  const { exitFromPlatform } = useExit()
  const { 
    currencies,
    affiliateOrganizations,
    returnFormattedCurrency,
    affiliateOrgLoading
   } = useContext(UserContext);
   
  const [loadStats, setLoadStats] = useState(false);
  const [dashboardStats, setDashboardStats] = useState(null);
  const [affiliateDetails, setAffiliateDetails] = useState(null);
  const [selectedBuilder, setSelectedBuilder] = useState(affiliateOrganizations?.length > 0 ? affiliateOrganizations[0] : null);

  const [details, setDetails] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  

 

  const getAffiliateStats = (builderId, findCurrency) =>{
    setLoadStats(true)
     apiService.get(`affiliates/affiliate_stats/?builder=${builderId}`).then((res) =>{
      console.log('from response', res, findCurrency)
      let affiliateStats = [
        {
            label:'Total Earning',
            stat: `${returnFormattedCurrency(findCurrency?.locale, findCurrency?.currencyCode, res?.data?.total_earnings)}`
        },
        {
            label:'Total Leads',
            stat: `${res?.data?.total_leads}`
        },
        {
            label:'Total Bookings',
            stat: `${res?.data?.total_bookings}`
        },
        {
            label:'Unpaid',
            stat: `${returnFormattedCurrency(findCurrency?.locale, findCurrency?.currencyCode, res?.data?.total_unpaid)}`
        },
       ]
       console.log('from response1', affiliateStats)
       setDashboardStats(affiliateStats)
       setDetails(res?.data)
      setLoadStats(false)
     }, (error) =>{
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
      if(returnedError === 'Given token not valid for any token type'){
        exitFromPlatform()
    }else{
        enqueueSnackbar(returnedError, {variant:'error'})
        setLoadStats(false)
    }
      }
     }).catch((error) =>{
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
          setLoadStats(false)
      }
        }
    }
    )
  }

  const commissionDetails = [
    {label:'Booking Commission', value: details?.affiliate?.booking_commission_type === "percent" ? `${`Percentage - ${Number(details?.affiliate?.booking_commission_rate).toFixed(2)}%`}` : `${`Fixed Reward - ${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, details?.affiliate?.booking_commission_rate)}`}`},
    {label:'Lead Commission', value: details?.affiliate?.lead_commission_type === "percent" ?  `${`Percentage - ${Number(details?.affiliate?.lead_commission_rate).toFixed(2)}%`}` : `${`Fixed Reward - ${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, details?.affiliate?.lead_commission_rate)}`}`}
]

const otherDetails = [
    {label:'Cookie Duration', value: `${details?.affiliate?.cookie_duration} Days`},
    {label:'Payout Information', value:`${details?.affiliate?.payout_address}`}
]

  const updateStates = (builder) =>{
    const findCurrency = currencies?.find((e) =>{
      return e?.currencyCode === selectedBuilder?.organization?.default_currency
    })
    setSelectedCurrency(findCurrency)
    getAffiliateStats(builder?.organization?.id, findCurrency)
  }

  useEffect(() =>{
    if(selectedBuilder?.id){
      updateStates(selectedBuilder)
    }else{
      if(affiliateOrganizations?.length > 0){
        updateStates(affiliateOrganizations[0])
      }
    }
  }, [selectedBuilder, affiliateOrganizations])

   const copyLinks = () =>{
    if(details?.referral_links?.length > 0){
      navigator.clipboard.writeText(details?.referral_links.join(','))
      enqueueSnackbar('Links Copied')
    }
   }

    

    return(
        <>
           <Box
            sx={{ 
            borderBottom: BoxEffects.borderBottom, 
            borderColor: 'secondary.main', 
            padding: BoxEffects.padding, 
            marginBottom: BoxEffects.marginButton, 
            paddingLeft: BoxEffects.margin, 
            paddingRight: headerButton.marginRigt,position:'sticky',
            top:'65px',
            background:'white',
            zIndex:99
            }}
        >
        <BreadCrumbWithActions propArr={arr1}>
          <div className='flex justify-between items-center'>
            <div className='flex items-center w-72' style={{
              marginLeft:'20px'
            }}>
            {affiliateOrgLoading === false && (
              <FilterFields 
              value={selectedBuilder?.id ? selectedBuilder : affiliateOrganizations[0]}
              setValue={setSelectedBuilder}
              placeholder="Select Builder"
              isControllingHeight
              defaultOptions={affiliateOrganizations}
              titleKey="title"
              disable={affiliateOrgLoading}
           />
            )}
            {/* <PrimaryButton 
               title="Filter"
               style={{
                   boxShadow: 'none', 
                   marginRight:'0px !important',
                   fontWeight:300,
                   padding:'6px 16px'
               }}
            /> */}
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>
      <div className='m-6'>
         {loadStats || affiliateOrgLoading ? <Loading />
          :
          <>
            {details !== null ? 
          <>
            <Stack 
            className='!grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5'  
            direction="row" 
            spacing={stack.space}
            sx={{
                marginBottom: BoxEffects.margin,
                position: "relative",
                rowGap: '20px',
                columnGap: '20px',
            }}
            
            >
                {dashboardStats?.map((e, i) =>{
                    return (
                       <>
                         {loadStats ? 
                         <Card sx={{height:"125px", boxShadow:'none'}}>
                         <Skeleton animation="wave" variant="rectangular" className='w-full !h-full' />
                     </Card>
                       :
                         <div className='p-6 rounded-lg' style={{ minHeight: '125px', border:'2px solid #E1EBFE' }}>
                             <div className='flex flex-col'>
                                  <div style={{
                                     fontFamily: 'Rubik',
                                     fontSize: '40px',
                                     fontWeight: 500,
                                     lineHeight: '47.4px',
                                     letterSpacing: '-0.022em',
                                     color:'#353349'
                                  }}>{e?.stat}</div>
                                  <div style={{
                                     fontFamily: 'Rubik',
                                     fontSize: '24px',
                                     fontWeight: 400,
                                     lineHeight: '28.44px',
                                     letterSpacing: '-2.2%',
                                     color:'#8E8CA3'
                                  }}>{e?.label}</div>
                             </div>
                         </div>}
                       </>
                    )
                })}
            </Stack>
            <div className="mt-4">
                <GridComponent>
                  <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid} >
                    <DetailsCard 
                      cardHeader='Referral Links' 
                      isCopy={details?.referral_links?.length > 0}
                      onCopyClick={copyLinks}
                      >
                        <Grid item >
                           <div style={{
                            maxHeight: '150px',
                            overflowY:'scroll'
                           }}>
                           {details?.referral_links?.length > 0 ? (
                              details?.referral_links?.map((link) =>{
                                return <div className='my-2' style={{
                                  fontFamily: 'Rubik',
                                  fontSize: '16px',
                                  fontWeight: 400,
                                  lineHeight: '18.96px',
                                  letterSpacing: '-0.022em',
                                  color:'#004FE8'
                              }}>
                                  {link?.length > 50 ? `${link?.substring(0, 50)}...` : `${link?.substring(0, 50)}`}
                              </div>
                              })
                          )
                          :
                          (
                            <div style={{
                              fontFamily: 'Rubik',
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '18.96px',
                              letterSpacing: '-0.022em',
                              color:'#004FE8'
                          }}>
                              Links are not Available
                          </div>
                          )}
                           </div>
                        </Grid>
                    </DetailsCard>
                  </Grid>
                   
        <Grid  item xs={grids.childgrid} lg={grids.childgrid}>
            <div className="">
           <DetailsCard cardHeader='Commission Information'>
                <Grid item >
                    {commissionDetails.map((e,i)=>{
                        return(
                            <LabelAndValue 
                              label={e.label} 
                              value={e.value}  
                              valueStyles={{
                                fontFamily: 'Rubik',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28.44px',
                              }}
                            />
                        )
                    })}
                </Grid>
            </DetailsCard>
           </div>
            <div className="mt-4">
            <DetailsCard cardHeader='Other Information'>
                <Grid item >
                    {otherDetails.map((e,i)=>{
                        return(
                            <LabelAndValue 
                            label={e.label} 
                            value={e.value}  
                            valueStyles={{
                              fontFamily: 'Rubik',
                              fontSize: '20px',
                              fontWeight: 400,
                              lineHeight: '28.44px',
                            }}
                          />
                        )
                    })}
                </Grid>
            </DetailsCard>
            </div>
        </Grid>
                </GridComponent>
            </div>
          </>
         :
         <>
           <EmptyCard 
             title='Please select a builder'
           />
         </>}
          </>}
      </div>
        </>
    )
}

export default AffiliatePortalDashboard