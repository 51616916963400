import React, { useState } from 'react';
import FormModal from '../formmodal-component/FormModal';
import {
  Select,
  MenuItem,
  FormControl,
  Autocomplete,
  TextField,
  InputAdornment,
} from '@mui/material';
import { SecondaryButton, PrimaryButton } from '@assystant/mui-buttons';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import PrimaryBtn from '../button-component/PrimaryBtn';
import SecondaryBtn from '../button-component/SecondaryBtn';

const IconFieldModal = ({ open, onClose, onClick, list, icon, setIcon }) => {
  let currentIcon = null;
  const StyledListItem = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      '& .icon-container svg': {
        fill: '#8E8CA3 !important',
      },
    },
    '.icon-container': {
      width: '2rem',
      height: '2rem',
      marginRight: theme.spacing(1),
    },
  }));
  console.log('from icon', icon);
  return (
    <FormModal open={open} onClose={onClose}>
      <div
        className="m-4"
        style={{
          zIndex: 999999,
        }}
      >
        <Autocomplete
          value={icon}
          options={list}
          getOptionLabel={(option) => option.name}
          onChange={(e, newValue, reason) => {
            setIcon(newValue);
          }}
          disablePortal={false}
          isOptionEqualToValue={(option, val) => option.id === val.id}
          popupIcon={<ExpandMore className="!fill-[#8E8CA3]" />}
          renderOption={(props, option) => (
            <StyledListItem {...props}>
              <div className="icon-container">{option.icon}</div>
              <div>{option.name}</div>
            </StyledListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Icon"
              placeholder="Select"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: icon ? (
                  <InputAdornment position="start">{icon.icon}</InputAdornment>
                ) : null,
              }}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-end px-8 py-8">
        <SecondaryBtn
          style={{
            backgroundColor: '#EDECF9',
            boxShadow: 'none',
            fontWeight: 300,
            padding: '6px 16px',
          }}
          onClick={onClose}
          title="Cancel"
        />
        <PrimaryBtn
          title="Save"
          style={{
            marginRight: '0px !important',
            boxShadow: 'none',
            fontWeight: 300,
            padding: '6px 16px',
          }}
          onClick={() => {
            onClick(icon);
          }}
        />
      </div>
    </FormModal>
  );
};

export default IconFieldModal;
