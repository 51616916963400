import React from 'react'

const Collapse = () =>{
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
           <mask id="mask0_6401_38773" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
              <rect width="24" height="24" fill="#D9D9D9"/>
           </mask>
           <g mask="url(#mask0_6401_38773)">
              <path d="M7.4 22L6 20.6L12 14.6L18 20.6L16.6 22L12 17.4L7.4 22ZM12 9.4L6 3.4L7.4 2L12 6.6L16.6 2L18 3.4L12 9.4Z" fill="#8E8CA3"/>
           </g>
         </svg>
    )
}

export default Collapse