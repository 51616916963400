import React from 'react';
import { Stack } from '@mui/material';
import Loading from '../../../../../../shared-component/Loading/Loading';
import EmptyCard from '../../../../../../shared-component/card-component/EmptyCard';
import WebsiteThemeCard from '../../../Cards/WebsiteThemeCard';

const InternalThemeComponent = ({
  loading,
  selectedTheme,
  websiteDetails,
}) => {

  return loading ? (
    <Loading />
  ) : selectedTheme?.id ? (
    <div className="">
      <Stack
        className="!mx-0 !grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
        direction="row"
        spacing={2}
        sx={{
          rowGap: '20px',
          columnGap: '20px',
        }}
      >
        <WebsiteThemeCard
          website={selectedTheme}
          websiteDetails={websiteDetails}
        />
      </Stack>
    </div>
  ) : (
    <EmptyCard
      title="Theme is not selected for this website"
      subtitle="Select or change the theme through Change theme option at website main screen"
    />
  );
};

export default InternalThemeComponent;
