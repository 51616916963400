import React from 'react';
import AddCircle from '../../../../asset/icons/AddCircle';
import ViewStream from '../../../../asset/icons/ViewStream';

const FormToolbar = ({
    onClickFormSection,
    onClickForNewFields,
}) =>{
    return(
        <div className='rounded-lg flex flex-col items-center p-2' style={{
            border: '1px solid #8E8CA3',
        }}>
            <div className='mb-2 cursor-pointer' onClick={(e) =>{
                e.stopPropagation()
                onClickForNewFields()
            }}>
               <AddCircle />
            </div>
            <div className='cursor-pointer' onClick={(e) =>{
               e.stopPropagation()
               onClickFormSection()
            }}>
               <ViewStream />
            </div>
        </div>
    )
}

export default FormToolbar