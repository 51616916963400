import React from 'react'

const IconWrapper = ({children}) => {
    return(
        <div className='w-20 h-20 rounded-full bg-white flex items-center justify-center'>
            {children}
        </div>
    )
}

export default IconWrapper;