import React, { useEffect, useState } from 'react';
import FilterFields from '../../../../leads/Fields/FilterFields';
import FormModal from '../../../../../shared-component/formmodal-component/FormModal';
import { SecondaryButton, PrimaryButton } from '@assystant/mui-buttons';
import { TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PrimaryBtn from '../../../../../shared-component/button-component/PrimaryBtn';
import SecondaryBtn from '../../../../../shared-component/button-component/SecondaryBtn';

const FilterUsers = ({
  open,
  handleClose,
  keyword,
  setKeyword,
  role,
  setRole,
  onSubmit,
  loading
}) => {
  
  const onReset = () =>{
    setRole({ name:'', id:'' })
    setKeyword("")
  }

  return (
    <FormModal title="Filter" open={open} onClose={handleClose}>
      <div className="flex flex-col mx-8 mt-4">
        <TextField 
           label="Keyword"
           placeholder="Keyword"
           value={keyword}
           onChange={(e) =>{
            setKeyword(e.target.value)
           }}
           InputLabelProps={{
            shrink: true
           }}
        />
        {/* <FilterFields
          value={position}
          setValue={setPosition}
          label="Position"
          placeholder="Position"
          titleKey="name"
        /> */}
        <FilterFields
          value={role}
          setValue={setRole}
          label="Role"
          placeholder="Role"
          choicesAPI={'rbac/role/'}
          titleKey="name"
        />
      </div>
      <div className="w-full flex justify-end px-8 pb-8 pt-4">
        <SecondaryBtn
          style={{
            backgroundColor: 'transparent',
            boxShadow: 'none',
            fontWeight: 300,
            padding: '6px 16px',
          }}
          onClick={handleClose}
          title="Cancel"
        />
        <SecondaryBtn
          style={{
            backgroundColor: '#EDECF9',
            color: '#FF5C5C',
            boxShadow: 'none',
            fontWeight: 300,
            padding: '6px 16px',
          }}
          onClick={onReset}
          title="Reset"
        />
        <PrimaryBtn
          title="Filter"
          style={{
            marginRight: '0px !important',
            boxShadow: 'none',
            fontWeight: 300,
            padding: '6px 16px',
          }}
          disabled={loading}
          onClick={() => {
            if(keyword === '' && role?.id === ''){
               enqueueSnackbar("Please add keyword or select a role to filter users", { variant: 'error' })
            }else{
              onSubmit({
                query: keyword,
                role: role
              });
            }
          }}
        />
      </div>
    </FormModal>
  );
};

export default FilterUsers;
