import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import Paper from '@mui/material/Paper';
import { useContext } from 'react';
import UserContext from '../../../../context/UserContext';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import Images from './Images';
import { v4 as uuidv4 } from 'uuid'
import UnitMedia from './UnitMedia';
import UploadIcon from '../../../../asset/icons/UploadIcon';
import ToolTipComponent from "../../Tooltip/ToolTipComponent";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ImageUpload = ({
    key,
    tooltip = "",
    getFiles, 
    fieldProps = {},
}) => {
    const {enqueueSnackbar} = useSnackbar()
    const { register, handleSubmit, setValue } = useForm();
    const {files, setFiles, setFilesUrl, filesUrl} = useContext(UserContext);
     console.log('from fieldProps', fieldProps?.images_list)
    useEffect(()=>{
       if(fieldProps?.image_list?.length > 0){
        setFiles(fieldProps?.image_list)
        setFilesUrl(fieldProps?.image_list)
       }
    }, [])

    const megabytesToBytes = (mb) => {
        return mb * 1024 * 1024;
    }

    console.log('from fieldProps', fieldProps)

    useEffect(() =>{
       console.log('Images updated', files, filesUrl)
    }, [files, filesUrl])
    
    const onDrop = (acceptedFiles) => {
        let promises = [];

        if(fieldProps?.notToUpload === true){
            if(acceptedFiles?.length > Number(fieldProps?.maximum_files)){
                enqueueSnackbar(`File size should be less than ${fieldProps?.maximum_size}`)
            }else{
                let isAllFileSizeLesser = true
                acceptedFiles?.forEach((e) =>{
                    if(e?.size > megabytesToBytes(Number(fieldProps?.maximum_size))){
                        isAllFileSizeLesser = false
                        enqueueSnackbar(`File size should be less than ${fieldProps?.maximum_size}`)
                    }
                })
                if(isAllFileSizeLesser === true){
                    promises = acceptedFiles.map((file) => {
                        return {
                            id: uuidv4(),
                            file: file
                        }
                    });
                }
            }
        }else{
            if(!fieldProps?.fromUnit){
                const filterImages = acceptedFiles?.filter((e) =>{
                    return e?.type !== "video/mp4"
                })
                promises = filterImages.map((file) => {
                    if(!file.size){
                        enqueueSnackbar(acceptedFiles.length > 1 ? 'One of the image size is too large' : 'Image size too large',{variant:'error'})
                    }else{
                        return {
                            id: uuidv4(),
                            file: file
                        }
                    }
                });
            }else{
                promises = acceptedFiles.map((file) => {
                    if(!file.size){
                        enqueueSnackbar(acceptedFiles.length > 1 ? 'One of the image size is too large' : 'Image size too large',{variant:'error'})
                    }else{
                        return {
                            id: uuidv4(),
                            file: file
                        }
                    }
                });
            }
          
           if(promises?.length > 0){
            Promise.all(promises).then(async (dataUrls) => {
                setFiles([...files, ...dataUrls]);
                setFilesUrl([...filesUrl, ...dataUrls])
                console.log('from image urls', [...filesUrl, ...dataUrls])
                window.localStorage.setItem('property_images',JSON.stringify(acceptedFiles))
                getFiles && getFiles(dataUrls)
              });
           }
        }

       
      };
    
    useEffect(() =>{
       if(fieldProps?.notToUpload === true){
          if(files?.length > Number(fieldProps?.maximum_files)){
            enqueueSnackbar(`Number of files should not exceed ${Number(fieldProps?.maximum_files)}`)
          }
       }
    }, [fieldProps, files])
    

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: fieldProps?.fromUnit ? 'image/* video/mp4' : 'image/*', // Specify the file types you want to accept
    });

    const removeImage = (data, image) =>{
        if(data?.file?.name || data?.id){
        const newArr = files?.filter((val) => val.id !== data?.id);
        setFiles(newArr)
        const checkId = filesUrl?.some((e) =>{
            return e?.id === data?.id
        })
        if(checkId === true){
            const newData = filesUrl?.filter((val)=> val?.id !== data?.id)
            setFilesUrl(newData)
            console.log('from deleted', newData, data, image)
        }else{
            const newData = filesUrl?.filter((val)=> val?.id !== image?.id)
            setFilesUrl(newData)
            console.log('from deleted1', newData, data, image)
         }
        }
       
    }
      
    return (
        files?.length === 0 ? <div className='w-full ml-4 mb-12 text-center'>
            <form>
                {fieldProps?.notToUpload === true ?
                   <div className='flex items-center'>
                    <div className='flex items-center bg-[#EDECF9] rounded-lg p-3 my-2'>
                      <UploadIcon />
                        <div className='text-[#8E8CA3] ml-2' style={{
                        fontFamily: "Rubik",
                        fontSize: "18px",
                        fontWeight: 400,
                        lineHeight: "23.7px",
                        }}>
                            Add File
                        </div>
                        {fieldProps?.field?.file_types?.length > 0 ?
                        <div className='mx-2 text-[#8E8CA3]' style={{
                            fontFamily: "Rubik",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "23.7px",
                        }}>
                            {`( ${fieldProps.field?.file_types?.join(", ")} )`}
                        </div>
                  :
                  <></>}
              </div>
                <div className='ml-2'>
                {tooltip !== '' && (
                <ToolTipComponent title={tooltip} offset={[50, -50]}>
                <ErrorOutlineIcon color='#FFFFFF' className='!fill-[#8E8CA3]' fontSize='20' />
               </ToolTipComponent>
              )}
                </div>
                   </div>
                   :
                   <div style={{ border: '2px dotted gray', padding: '12px 0' }} {...getRootProps()} className="dropzone">
                    <input multiple {...getInputProps()} />
                    {fieldProps?.fromUnit ? 
                     <>
                      <div className='h-24 flex items-center justify-center'>
                      <p>Unit Image/Video/3D</p>
                      </div>
                     </>
                     :
                     <>
                      <p>Add Images</p>
                    <div style={{ fontSize: '12px', color: 'gray' }}>Acceptable format JPEG, PNG.</div>
                    <div style={{ fontSize: '12px', color: 'gray' }}> Min. 3 Images</div>
                     </>}
                   

                </div>
                   }
                

            </form>
        </div> : 
           <>
             <div className='w-full ml-4 flex flex-wrap text-center'>
            {files?.map((item, index) => {
                return (
                    <>
                    {fieldProps?.fromUnit ? 
                    <UnitMedia 
                    index={index}
                    item={item}
                    removeImage={removeImage}
                    setFilesUrl={setFilesUrl} 
                    filesUrl={filesUrl}
                    setFiles={setFiles}
                    files={files}
                    fieldProps={fieldProps}
                  /> 
                   :
                   <Images 
                      index={index}
                      item={item}
                      removeImage={removeImage}
                      setFilesUrl={setFilesUrl} 
                      filesUrl={filesUrl}
                      setFiles={setFiles}
                      files={files}
                      fieldProps={fieldProps}
                    />}
                    </>
                )
            })}
           {!fieldProps?.fromUnit &&(
             <div style={{
                height:'35px',
                position:"absolute",
                bottom:'20px',
                textAlign:'left'
            }} {...getRootProps()}>
                <Typography variant='subtitle2' color={'#004FE8'}>Add More Images</Typography>
                <input multiple {...getInputProps()} style={{ opacity:0 }} />
            </div>
           )}
          
        </div>
         {fieldProps?.fromUnit &&(
             <div className='ml-6 cursor-pointer' style={{
                height:'35px',
                textAlign:'left'
            }} {...getRootProps()}>
                <Typography variant='subtitle2' color={'#004FE8'}>Add More Images</Typography>
                <input multiple {...getInputProps()} style={{ opacity:0 }} />
            </div>
           )}
       
           </>
    );
};

export default ImageUpload;
