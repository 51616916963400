import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, Grid, TextField, Checkbox } from '@mui/material';
import {
  BoxEffects,
  headerButton,
  grids,
} from '../../../../../../../Config/Constants';
import PrimaryBtn from '../../../../../../shared-component/button-component/PrimaryBtn';
import BreadCrumbWithActions from '../../../../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import GridComponent from '../../../../../../shared-component/grid-component/GridComponent';
import DetailsCard from '../../../../../../shared-component/card-component/DetailsCard';
import PageEditor from './PageEditor';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SecondaryBtn from '../../../../../../shared-component/button-component/SecondaryBtn';
import ActionButton from '../../../../../../shared-component/button-component/ActionButton';
import useExit from '../../../../../../../utils/useExit';
import returnError from '../../../../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import {
  BASE_URI,
  apiService,
} from '../../../../../../../constant/AuthConstant';
import ShowCodeComponent from './ShowCodeComponent';
import Loading from '../../../../../../shared-component/Loading/Loading';
import NavigateIcon from '../../../../../../../asset/icons/NavigateIcon';

const WebsitePageDetails = () => {
  const { webId, pageId } = useParams();
  const navigate = useNavigate();
  const { exitFromPlatform } = useExit();
  const [pageTitle, setPageTitle] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [url, setURL] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [openPageEditor, setOpenPageEditor] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [details, setDetails] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [liquidCode, setLiquidCode] = useState('');
  const [cssCode, setCssCode] = useState('');
  const [javascriptCode, setJavascriptCode] = useState('');
  const [websiteDetails, setWebsiteDetails] = useState(null);
  const [pages_list, setPages_list] = useState([]);
  const [is_valid_url, setIs_valid_url] = useState('');
  const [pageDetails, setPageDetails] = useState({})

  localStorage.setItem('website-tabs', '2');

  const [isHomePage, setIsHomePage] = useState(false);

  const handleIsHomePage = (e) => {
    setURL('')
    setIsHomePage(e.target.checked);
  };

 

  const breadcrumb = useMemo(() => {
    const webString = localStorage.getItem('website');
    return [
      { title: 'Websites', url: '/websites' },
      { title: JSON.parse(webString), url: `/websites/${webId}` },
      { title: 'Pages', url: `/websites/${webId}` },
      { title: pageId === 'add-page' ? "Add Page" : `${pageId}`, url: '/' },
    ];
  }, [webId, pageId]);

  const handlePageEditor = () =>{
    setOpenPageEditor((prev) => !prev);
  }


  const handleOpenPageEditor = () =>{
    document.querySelectorAll('body script').forEach(script => script.remove());
    setOpenPageEditor(true)
  }

  const handleClosePageEditor = () =>{
    setOpenPageEditor(false)
  }


  const handlePageTitle = useCallback((e) => {
    setIs_valid_url('')
    setPageTitle(e.target.value);
    setURL(e.target.value);
  }, []);

  const handleSEOTitle = useCallback((e) => {
    setSeoTitle(e.target.value);
  }, []);

  const handleURLTitle = useCallback((e) => {
     const checkifSameUrl = pages_list?.some((s) => {
      if(pageId === 'add-page'){
       return s?.url.toLowerCase() === e?.target?.value.toLowerCase()
      }
      return s?.url.toLowerCase() === e?.target?.value.toLowerCase() && s?.id !== Number(pageId)
    });
    if(checkifSameUrl){
      setIs_valid_url('This path already exist')
    }else if(e.target.value?.startsWith('/') && e?.target?.value !== ''){
      setIs_valid_url('Slash is not required at the beginning of the url')
    }
    else{
      setIs_valid_url('')
    }
    setURL(e.target.value);
  }, [pages_list]);

  const handleMetaDescription = useCallback((e) => {
    setMetaDescription(e.target.value);
  }, []);

  const getPages = () =>{
    apiService.get(`website/pages/?website=${webId}`).then((res) =>{
        if(res?.data?.length){
            setPages_list(res?.data)
            localStorage.setItem('website-tabs', null)
        }
    }).catch((error) =>{
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
             
          }
        }
      })
}

  const getWebsiteDetails = () => {
    apiService
      .get(`website/site/${webId}/`)
      .then((res) => {
        if (res?.data) {
          setWebsiteDetails(res?.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };


  const getPageDetails = () => {
    setLoading(true);
    apiService
      .get(`website/pages/${pageId}`)
      .then((res) => {
        if (res?.data) {
          setPageTitle(res?.data?.page_title);
          setSeoTitle(res?.data?.seo_title);
          setURL(res?.data?.url);
          setMetaDescription(res?.data?.meta_description);
          setLiquidCode(res?.data?.liquid_code);
          setCssCode(res?.data?.css_code);
          setJavascriptCode(res?.data?.js_code);
          setIsHomePage(res?.data?.is_home_page);
          setPageDetails(res?.data)
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    if (pageId !== 'add-page') {
      getPageDetails();
    }
    getPages();
    getWebsiteDetails()
  }, []);

  const submitPageDetails = () => {
    if (pageTitle === '') {
      enqueueSnackbar('Please add page title', { variant: 'error' });
    } else if (seoTitle === '') {
      enqueueSnackbar('Please add SEO title', { variant: 'error' });
    } else if (metaDescription === '') {
      enqueueSnackbar('Please add meta description', { variant: 'error' });
    } else {
      const findHomePage = pages_list?.some((s) => s?.is_home_page === true && s?.url === '' && s?.id !== Number(pageDetails?.id));
      if(findHomePage && url === ''){
        enqueueSnackbar('Url should not be empty, There is another page that is marked as home page', { variant: 'error' });
      }else if(findHomePage && isHomePage && url !== ''){
        enqueueSnackbar('There is another page that is marked as home page. You have to update the url of that page first.', { variant: 'error' });
      }
      else{
        setSubmitLoading(true);
        let service;
        let apiUrl;
        let payload = {};
  
        if (pageId === 'add-page') {
          service = apiService.post;
          apiUrl = `website/pages/`;
          payload = {
            page_title: pageTitle,
            seo_title: seoTitle,
            url: isHomePage ? "" : (url === "" ? pageTitle.toLowerCase() : url),
            meta_description: metaDescription,
            liquid_code: liquidCode,
            css_code: cssCode,
            js_code: javascriptCode,
            website: Number(webId),
            is_home_page: isHomePage || false,
            is_edited: true
          };
        } else {
          service = apiService.put;
          apiUrl = `website/pages/${pageId}/`;
          payload = {
            page_title: pageTitle,
            seo_title: seoTitle,
            url: isHomePage ? "" : (url === "" ? pageTitle.toLowerCase() : url),
            meta_description: metaDescription,
            liquid_code: liquidCode,
            css_code: cssCode,
            js_code: javascriptCode,
            website: Number(webId),
            is_home_page: isHomePage || false,
            is_edited: true
          };
        }
       
  
        service(apiUrl, payload)
          .then((res) => {
            if (res?.data) {
              if (pageId === 'add-page') {
                enqueueSnackbar('Added', { variant: 'success' });
              } else {
                enqueueSnackbar('Updated', { variant: 'success' });
              }
              setSubmitLoading(false);
              navigate(`/websites/${webId}`);
            }
          })
          .catch((error) => {
            if (error.response?.data) {
              const returnedError = returnError(error.response?.data);
              if (
                returnedError === 'Given token not valid for any token type' ||
                returnedError === 'Request failed with status code 401'
              ) {
                exitFromPlatform();
              } else {
                enqueueSnackbar(returnedError, { variant: 'error' });
                setSubmitLoading(false);
              }
            }
          });
      }
      }
     
  };

  const removeMenu = () => {
    setDeleting(true);
    apiService
      .delete(`website/pages/${pageId}`)
      .then((res) => {
        if (res?.status === 204) {
          setDeleting(false);
          enqueueSnackbar('Deleted', { variant: 'success' });
          navigate(`/websites/${webId}`);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setDeleting(false);
          }
        }
      });
  };

  const navigateBack = () => {
    navigate(`/websites/${webId}`);
  };

  const codeComponent = useCallback(() => {
    return (
      <div className="flex flex-col h-96">
        <ShowCodeComponent
          codeObject={{
            liquid_code: liquidCode || '',
            css_object: cssCode || '',
            js_object: javascriptCode || '',
          }}
          liquidCode={liquidCode}
          cssCode={cssCode}
          javascriptCode={javascriptCode}
        />
      </div>
    );
  }, [liquidCode, cssCode, javascriptCode]);

  const navigateToPreview = () =>{
    window.open(`https://${websiteDetails?.subdomain}${process.env.REACT_APP_SITE_DOMAIN}/${url}`, '_blank', 'noopener,noreferrer');
  }

  

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Box
            sx={{
              borderBottom: BoxEffects.borderBottom,
              borderColor: 'secondary.main',
              padding: BoxEffects.padding,
              marginBottom: BoxEffects.marginButton,
              paddingLeft: BoxEffects.margin,
              paddingRight: headerButton.marginRigt,
              position: 'sticky',
              top: '0px',
              background: 'white',
              zIndex: 99,
            }}
          >
            <BreadCrumbWithActions propArr={breadcrumb}>
              <div className="flex justify-between items-center">
                <div
                  style={{
                    marginLeft: '20px',
                  }}
                >
                  <div className="relative">
                  <ActionButton
                    style={{
                      boxShadow: 'none',
                      margin: '0px !important',
                      fontWeight: 300,
                    }}
                    exicon={<NavigateIcon />}
                    title="Visit Page"
                    disabled={!websiteDetails?.id || !url || pageDetails?.url !== url}
                    onClick={navigateToPreview}
                  />
                    <SecondaryBtn
                      style={{
                        boxShadow: 'none',
                        margin: '0px !important',
                        fontWeight: 300,
                      }}
                      title="Cancel"
                      onClick={navigateBack}
                    />
                    {pageId !== 'add-page' && (
                      <ActionButton
                        title="Remove"
                        disabled={deleting}
                        style={{
                          boxShadow: 'none',
                          fontWeight: 300,
                          background: '#FF5C5C',
                          color: '#ffffff',
                        }}
                        onClick={removeMenu}
                      />
                    )}
                    <PrimaryBtn
                      className="!mr-0"
                      style={{
                        boxShadow: 'none',
                        margin: '0px !important',
                        fontWeight: 300,
                      }}
                      disabled={submitLoading || is_valid_url !== ''}
                      title="Save"
                      onClick={submitPageDetails}
                    />
                  </div>
                </div>
              </div>
            </BreadCrumbWithActions>
          </Box>

          <div className="m-6">
            <GridComponent>
              <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid}>
                <DetailsCard
                  cardHeader="Page Details"
                  component={
                    <div
                      className="mx-4 cursor-pointer"
                      onClick={handleOpenPageEditor}
                    >
                      <EditIcon className="!fill-[#353349]" />
                    </div>
                  }
                >
                  {liquidCode === '' && (
                    <div className="flex flex-col items-center justify-center h-96">
                      <div
                        className="text-[#353349]"
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '18px',
                          fontWeight: 400,
                          lineHeight: '21.33px',
                          letterSpacing: '-2.2%',
                        }}
                      >
                        Please add code to update the page details
                      </div>
                    </div>
                  )}

                  {liquidCode !== '' && (
                    <div className="flex flex-col h-96">
                      {codeComponent()}
                    </div>
                  )}
                </DetailsCard>
              </Grid>
              <Grid
                className="!md:w-full max-[600px]:w-full"
                item
                lg={grids.childgrid}
              >
                <DetailsCard cardHeader="General Details">
                  <div className="flex flex-col">
                    <TextField
                      label="Page Title"
                      placeholder="Page Title"
                      value={pageTitle}
                      defaultValue={pageTitle}
                      onChange={handlePageTitle}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                    <TextField
                      label="SEO Title"
                      placeholder="SEO Title"
                      value={seoTitle}
                      defaultValue={seoTitle}
                      onChange={handleSEOTitle}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                    />
                    <TextField
                      label="URL"
                      placeholder="URL"
                      value={url}
                      defaultValue={url}
                      onChange={handleURLTitle}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={is_valid_url !== ''}
                      helperText={is_valid_url}
                    />
                    <TextField
                      label="Meta Description"
                      placeholder="Meta Description"
                      value={metaDescription}
                      defaultValue={metaDescription}
                      onChange={handleMetaDescription}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      multiline
                      rows={2}
                    />

                    <div className="w-full ml-[-10px] flex items-center">
                      <Checkbox
                        checked={isHomePage}
                        onChange={handleIsHomePage}
                      />
                      <div
                        className="ml-2 text-[#353349]"
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        Mark it as Home Page
                      </div>
                    </div>
                  </div>
                </DetailsCard>
                <div className="mt-4">
                  <DetailsCard cardHeader="SEO Details">
                    {pageTitle === '' ? (
                      <div className="flex flex-col items-center justify-center h-28">
                        <div
                          className="text-[#353349]"
                          style={{
                            fontFamily: 'Rubik',
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '21.33px',
                            letterSpacing: '-2.2%',
                          }}
                        >
                          Please add General Details
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col">
                        <div
                          className="text-[#1061CC]"
                          style={{
                            fontFamily: 'Rubik',
                            fontSize: '22px',
                            fontWeight: 400,
                            lineHeight: '26.07px',
                            letterSpacing: '-0.022em',
                          }}
                        >
                          {seoTitle}
                        </div>
                        <p
                          className="text-[#1EAF11]"
                          style={{
                            fontFamily: 'Rubik',
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '21.33px',
                            letterSpacing: '-2.2%',
                          }}
                        >
                          {`https://${websiteDetails?.subdomain}.spotmink.com/${url}`}
                        </p>
                        <p
                          className="text-[#353349]"
                          style={{
                            fontFamily: 'Rubik',
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '21.33px',
                            letterSpacing: '-2.2%',
                          }}
                        >
                          {metaDescription?.length > 150
                            ? `${metaDescription.substring(0, 150)}...`
                            : metaDescription}
                        </p>
                      </div>
                    )}
                  </DetailsCard>
                </div>
              </Grid>
            </GridComponent>
          </div>

          {openPageEditor && (
            <PageEditor
              title={'Code Editor'}
              open={openPageEditor}
              onClose={handleClosePageEditor}
              cssCode={cssCode}
              setCssCode={setCssCode}
              liquidCode={liquidCode}
              setLiquidCode={setLiquidCode}
              javascriptCode={javascriptCode}
              setJavascriptCode={setJavascriptCode}
            />
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(WebsitePageDetails);

