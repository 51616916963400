// -----------Documentation-------------
// Forgot Password component used for forgot password

// props           type                    usedfor
// -------         -------                 ---------
// companyLogo     path                    logo image for authantication page
// publicSideImage path                    logo image for side authantication page
// fields          array                   array for fields
// formValues      object                  for formvalues
// onSubmit        function                for submit useCallback
// actionTitle     string                  action button label
// pageTitle       string                  label for forms


import React, { useContext, useState } from "react";
import SpotMink from '../../../asset/Images/SpotMink.png'
import PublicGroup from '../../../asset/Images/PublicGroup.png'
import AuthLayout from "../../shared-component/AuthLayoutPage/AuthLayout";
import { useNavigate } from "react-router-dom";
import { apiService } from "../../../constant/AuthConstant";
import * as yup from 'yup';
import { useSnackbar } from "notistack";
import returnError from "../../../utils/ExportError";
import useExit from "../../../utils/useExit";
// import userContext from "../../../context/UserContext";

function Otp(){
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const { exitFromPlatform } = useExit()
    // const {userResetpassword} = useContext(userContext);
    const formValues={
        otp:"",
    }

    const schema = yup.object().shape({
        otp: yup
          .string()
          .required('Please enter OTP.')
          .max(6, 'OTP should contain 6 digits')
          .matches(/^\d{6}$/, 'OTP should contain exactly 6 digits')
        //   .matches(
        //     /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/,
        //     "Must contain min 8 characters with alphabets and numbers"
        //   ),
      });
    const field = [
        // {id:1,label:'Enter Otp', type:"text", value:'',name:'otp'},
        {id:1,label:'Enter Otp', type:"text", value:'',name:'otp'},
        // {id:2,label:'Password', type:"password", value:'',name:'password'},
    ]
    function onSubmit(data){
        // userResetpassword(data.email)
      }

      const onSubmitForOTP = (data) =>{
        setLoading(true)
        const em = localStorage.getItem("forgot-email")
        console.log(JSON.parse(em))
        apiService.post('account/verify-otp/', {
            ...data,
            email:JSON.parse(em).email
        }).then((response)=>{
            setLoading(false)
            navigate('/createnewpassword')
        }).catch((error)=>{
            setLoading(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
        })
    }
    return(
        <>
            <AuthLayout 
            companyLogo={SpotMink} 
            publicSideImage={PublicGroup} 
            fields={field} 
            formValues={formValues} 
            onSubmit={onSubmitForOTP} 
            actionTitle='Continue'
            pageTitle='Enter Otp'
            schema={schema}
            isSubmitting={loading}
            // checkboxAction
            /> 
        </>
    )
}
export default Otp;