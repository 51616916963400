import { TextField } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import DetailsCard from '../card-component/DetailsCard'

function ScriptPaste({
  name,
  label,
}) {

  const { getValues, setValue } = useFormContext()
 
  return (
    <div className='my-4'>
      <DetailsCard cardHeader='Add Script' style={{ fontSize: '18px', fontWeight: 'bold', color: '#004FE8',borderRadius:'5px',border:'1px solid #D3D3D3' }} >
        <TextField variant="standard"
          InputProps={{
            disableUnderline: true
          }} sx={{
            borderTopLeftRadius: '5px', borderTopRightRadius: '5px', margin: '0 0', fontWeight: '300', 
          }} name={name} defaultValue={getValues(name)} onChange={(e) => setValue(name, e.target.value)} multiline rows={12} fullWidth placeholder='Add script here...' />
      </DetailsCard>
    </div>
  )
}

export default ScriptPaste