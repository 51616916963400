import React from 'react'
import { Grid } from '@mui/material';
import FormModal from '../../../../shared-component/formmodal-component/FormModal';
import ActionButton from '../../../../shared-component/button-component/ActionButton';
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons';
import PrimaryBtn from '../../../../shared-component/button-component/PrimaryBtn';
import SecondaryBtn from '../../../../shared-component/button-component/SecondaryBtn';

const FormAnalyticsFilter = ({
    open,
    handleClose,
    buttonFields,
    onSubmit,
    loading,
    setSelectedButton,
    selectedButton,
}) =>{
    
    const endButton = {
        id:8,
        label: 'Last 365 days',
        value: '365'
    }

    const onReset = () =>{
        setSelectedButton({
            id:1,
            label:'Today',
            value:'today',
        })
    }

    console.log('from selected button', selectedButton)
    return (
        <FormModal 
          title="Filter"
          open={open}
          onClose={handleClose}
        >
          <div className='flex flex-col mx-8 my-4'>
          <Grid className='!mt-2' container spacing={2} columns={16}>
            {buttonFields?.map((item, index) =>{
                return(
                    <Grid key={index} item xs={8} style={{ paddingTop: 0 }}>
                        <ActionButton
                        variant="contained"
                        color={selectedButton?.value === item.value? 'primary':'secondary'}
                        title={item.label || 'Secondary'}
                        onClick={() =>{
                            setSelectedButton(item)
                        }}
                        style={{marginRight:0, marginLeft:0, width:'100%', marginTop:'12px', marginButtom:'12px',padding:'20px'}}
                        />
                    </Grid>
                )
            })}
        </Grid>
            <ActionButton
            variant="contained"
            color={selectedButton.value === endButton?.value ? 'primary':'secondary'}
            title={endButton.label || 'Secondary'}
            onClick={() =>{
                setSelectedButton(endButton)
            }}
            style={{marginRight:0, marginLeft:0, width:'100%', marginTop:'12px', marginButtom:'12px',padding:'20px'}}
            />
          </div>
          <div className="w-full flex justify-end px-8 py-8">
                    <SecondaryBtn style={{ 
                        backgroundColor: '#EDECF9',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px' }} onClick={handleClose} title='Cancel' />
                    <SecondaryBtn style={{ 
                        backgroundColor: '#EDECF9', 
                        color:'#FF5C5C',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px' }} onClick={onReset} title='Reset' />
                    <PrimaryBtn title='Filter' 
                    disabled={loading}
                    style={{
                        marginRight: '0px !important',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px'
                    }} onClick={() =>{
                        onSubmit({
                            buttonSelection: selectedButton?.value
                        })
                        
                    }}/>
                </div>
        </FormModal>
    )
}

export default FormAnalyticsFilter
 