import { useEffect, useState } from "react";
import { 
    Autocomplete,
    TextField,
 } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { apiService } from "../../../../constant/AuthConstant";
import { enqueueSnackbar } from "notistack";
import useExit from "../../../../utils/useExit";
import returnError from "../../../../utils/ExportError";

const FilterFields = ({
    value,
    label,
    setValue = () => {},
    choicesAPI,
    placeholder,
    defaultOptions,
    message,
    titleKey,
    disable = false,
    addtionalAction,
    isControllingHeight,
    multiple = false,
    fieldStyles = {},
    className = "",
    selectRef = null,
    handleSelectFocus = () => {},
    selectFieldStyles = {},
    popupIconColor = '!fill-[#8E8CA3]'
}) =>{
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([]);
    const { exitFromPlatform } = useExit()
    const loading = choicesAPI && open && options.length === 0;
    useEffect(() => {
        let active = true;
    
        if (!loading) {
          return undefined;
        }
        if (choicesAPI) {
         if(choicesAPI === 'noAPI'){
          enqueueSnackbar(message, {variant:'error'})
         }else{
           apiService.get(choicesAPI).then((res) => {
            if(res?.data?.length > 0){
              if (active) {
                if(choicesAPI?.includes('device-choices') || choicesAPI?.includes('session-choices')){
                  const list = [...res?.data]?.map((e) =>{
                    return {
                      ...e,
                      id: `#${e?.id?.substring(e?.id?.length, e?.id?.length - 6).toUpperCase()}`
                    }
                  })
                  setOptions(list);
                }else{
                  setOptions(res?.data);
                }
                
              }
            }else{
              setOptions([{id:'No Options', name:'No Options'}])
            }
          }).catch((error) => {
            if(error.response){
              if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
              }
            }
            }
          });
         }
        }
        return () => {
          active = false;
        };
      }, [loading, choicesAPI]);
    
      useEffect(() => {
        if (!open) {
          setOptions([]);
        }
      }, [open]);

      console.log('klasjdlsajdlkjaskld', setValue)
    
    return (
        <Autocomplete
            fullWidth
            loading={loading}
            id="combo-box-demo"
            value={value}
            open={open}
            onOpen={() => {
              setOpen(true);
              if(addtionalAction){
                addtionalAction()
              }
            }}
            onClose={() => {
              setOpen(false);
            }}
            className={className}
            multiple={multiple}
            disabled={disable}
            isOptionEqualToValue={(option, val) => option.id === val.id}
            popupIcon={<ExpandMore className={`${popupIconColor}`}/>}
            options={defaultOptions?.length > 0 ? defaultOptions : options}
            renderOption={(props, option) => (
                <li {...props}>{option?.name ? option?.name : (option?.full_name ? option?.full_name : (option?.title ? option?.title : (option?.template ? option?.template_name : option?.id ? option?.id : option?.name)))}</li>
            )}
            onChange={(e, newVal) => {
              e?.stopPropagation()
              console.log('from e', e)
              setValue(newVal)
            }}
            getOptionLabel={(option) => {
              if(option?.name){
                return option?.name
              }else if(option?.title){
               return option?.title
              }
              else if(option?.template_name){
                return option?.template_name
               }
              else if(option?.full_name){
                return option?.full_name
              }else if(option?.id){
                return option?.id
              }else{
                return option?.name
              }
            }}
            sx={{
              "& .MuiOutlinedInput-root" : {
                padding: isControllingHeight ? "3px 2px 3px 0px !important" : "9px !important",
                ...selectFieldStyles,
            },
           
            }}
            renderInput={(params) => (
                <TextField 
                  fullWidth 
                  label={label}
                    placeholder={placeholder}
                    InputLabelProps={{
                    ...params.InputProps,
                    shrink: true,
                    }}
                  sx={{ 
                    marginBottom: '15px', 
                    marginTop: '5px',
                    ...fieldStyles
                   }} 
                   size="small"
                  {...params} />
            )}
        />
    )
}

export default FilterFields