import { useState } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { CardRad } from '../../../Config/Constants';
import FieldWrapper from './FieldWrapper';
import FormFieldMapper from './FormFieldMapper';
import { border } from '@mui/system';

const BoxLayout = ({
  name,
  label,
  fieldProps = {},
  cols = 1,
  borderType = 'border',
  description = '',
  cardClassName
}) => {
  const { arrayFields } = fieldProps;
  const [readMore, setReadMore] = useState(false)

  const handleReadMore = () =>{
    setReadMore(!readMore)
  }
  return (
   label !== '' && (
    <FieldWrapper cols={cols}>
    <Card
      className={borderType === 'none' ? "mb-1 mt-1 py-1.5 px-0 rounded-sm" : `mb-1 mt-1 p-8 rounded-sm ${cardClassName}`}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      width="100%"
      sx={borderType === 'none' ? {
        boxShadow:'none',
        border:'none'
      } : {
        ...CardRad,
        borderRadius:'8px'
      }}
    >
      <div className={description !== "" ? 'mb-2' : 'mb-4'}>
      <Typography sx={{fontWeight:400, fontSize:'18px'}} variant='subtitle 1'>{label === "f2cc91d1-6c12-4a22-af81-e4a4aa0ba929" ? "Location Details" : label}</Typography>
      </div>

      {description !== "" && (
        <>
         {description?.length > 100 ? 
                 <>
                   {readMore === false ? 
                   <div className="mb-6" style={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                  }}>
                    {description?.substring(0, 99) }
                    <span className='cursor-pointer' style={{
                      color: '#004FE8'
                    }} onClick={handleReadMore}>Read More</span>
                  </div>
                  :
                  <div className="mb-6" style={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                  }}>
                    {description || ""}
                    <span className='cursor-pointer ml-2' style={{
                      color: '#004FE8'
                    }} onClick={handleReadMore}>Read Less</span>
                  </div>}
                 </>
               : <>
               <div className="mb-6" style={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                  }}>
                    {description || ""}
                  </div>
               </>}
        </>
      )}
      
      <Grid container spacing={2} columns={16} style={{ paddingTop: 8 }}>
              {arrayFields.map(FormFieldMapper)}
      </Grid>
    </Card>
  </FieldWrapper>
   )
  );
};
export default BoxLayout;