// ----------------- Documentation ---------------------------
// this component handels the tabs for analysis integration and ads integration


import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import LabTabs from "../../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs";
import { BoxEffects, headerButton } from "../../../../Config/Constants";
import BreadCrumbWithActions from "../../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "@assystant/mui-buttons";
import { PrimaryButton } from "@assystant/mui-buttons";
import CustomCodeList from './CustomCodeComponents/CustomCodeList'
import './css/Integration.css'
import { apiService } from "../../../../constant/AuthConstant";
import returnError from "../../../../utils/ExportError";
import Loading from "../../../shared-component/Loading/Loading";
import { enqueueSnackbar } from "notistack";
import AnalysisFilter from "../../analysis/AnalysisFilter";
import Documentation from "./Modal/Documentation";
import useExit from "../../../../utils/useExit";
import SecondaryBtn from "../../../shared-component/button-component/SecondaryBtn";
import PrimaryBtn from "../../../shared-component/button-component/PrimaryBtn";

function CustomCode(){
    const navigate = useNavigate();
    const [value, setValue] = useState('1');
    const [loading, setLoading] = useState(false);
    const [codeList, setCodeList] = useState([])
    const [isAddDocuments, setIsAllDocuments] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [isFiltering, setIsFiltering] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const { exitFromPlatform } = useExit()

    // for documentation
    const [openDocumentation, setOpenDocumentation] = useState(false);

    const toggleDocumentation = () =>{
        setOpenDocumentation(!openDocumentation)
    }

    const toggleFilterModal = () =>{
        setOpenFilter(!openFilter)
    }

    const defaultValues = {
        type:'',
        employee:''
    }

    const fieldMap = [
        {
            name:'type',
            label:'Type',
            placeholder:'Type',
            type:'select',
            fieldProps:{
                defaultOptions: [
                    {title:'Javascript', id:'JS'},
                    {title:'CSS', id:'CSS'}
                ],
                titleKey:'title',
                setState: setSelectedType,
                value: selectedType
            }
        },
        {
            name:'employee',
            label:'Added By',
            placeholder:'Added By',
            type:'select',
            fieldProps:{
              choicesAPI:"/account/user/",
              titleKey:'full_name',
              setState: setSelectedEmployee,
              value: selectedEmployee
            }
          }
    ]
    
    const toggleIsAddDocuments = () => {
        setIsAllDocuments(!isAddDocuments);
    }

    const onAddCss = () =>{
       navigate('/custom-code/code/css')
    }

    const onAddJS = () =>{
        navigate('/custom-code/code/javascript')
    }

    useEffect(() =>{
        console.log('from code list', codeList)
    }, [codeList])

    const getCustomCodeList = () =>{
        setLoading(true)
        apiService.get(`code-injection/`)
        .then((res) =>{
            setLoading(false)
            const newList = res?.data?.map((item, index) =>{
                return {
                    ...item,
                    properties: item?.properties?.map((prop) =>{
                        return prop.name
                    })
                }
            })
            setCodeList(newList)
            console.log(res)
        })
        .catch((error) =>{
            setLoading(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
        })
    }

    const changeStatus = (itemId, e) =>{
        console.log('from status submission', e.target.value)
        apiService.patch(`code-injection/${itemId}/`, {
         active: e.target.value === 'Active' ? true : false
        })
        .then((res) =>{
         enqueueSnackbar('Status Updated', {variant:'success'})
         getCustomCodeList()
        })
        .catch((error) =>{
         if(error.response?.data){
             const returnedError = returnError(error.response?.data)
             if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
         }
        })
     }

    useEffect(() =>{
       getCustomCodeList()
    },[])

    const arr1 = [
        {title: 'Custom Code', url: '/'},
    ]

    
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const onFilterSubmit = (e) =>{
        setLoading(true)
        setIsFiltering(true)
        const newData = {
            ...e,
            type: selectedType?.id ? selectedType?.id : '',
            employee: selectedEmployee?.id ? selectedEmployee?.id : '',
           }
           Object.entries(newData)?.map((e) =>{
            if(e[1] === '' || e[1] === null || e[1] === undefined){
              delete newData[e[0]]
            }
          })
           
        const searchParams = new URLSearchParams(newData).toString();

        apiService.get(`code-injection/?${searchParams}`)
        .then((res) =>{
            const newList = res?.data?.map((item, index) =>{
                return {
                    ...item,
                    properties: item?.properties?.map((prop) =>{
                        return prop.name
                    })
                }
            })
            setCodeList(newList)
            setIsFiltering(false)
            setLoading(false)
            toggleFilterModal()
        })
        .catch((error) =>{
            setLoading(false)
            setIsFiltering(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
        })
    }

 
    return(
        <>
            <Box 
                sx={{
                    borderBottom: BoxEffects.borderBottom, 
                    borderColor: 'secondary.main',
                    padding:BoxEffects.padding,
                    marginBottom:BoxEffects.marginButton,
                    paddingLeft:BoxEffects.margin,
                    paddingRight:headerButton.marginRigt,
                    position:'sticky',
                    top:'65px',
                    backgroundColor:'white',
                    zIndex:99 }}
            >
                <BreadCrumbWithActions propArr={arr1}>
                    <div className="flex items-center w-full">
                        <div 
                          className="documentation mx-4 cursor-pointer"
                          onClick={toggleDocumentation}
                          >{`Documentation`}</div>
                        <SecondaryBtn 
                           className="!shadow-none" 
                           title='Filter' 
                           onClick={toggleFilterModal}
                           style={{
                            boxShadow:'none',
                            fontWeight:300,
                            padding:'6px 16px'
                           }}
                           />
                        <PrimaryBtn 
                          title='Add CSS' 
                          onClick={onAddCss} 
                          style={{
                            boxShadow:'none',
                            fontWeight:300,
                            padding:'6px 16px'
                           }}
                          />
                        <PrimaryBtn title='Add JS' onClick={onAddJS} style={{
                            boxShadow:'none',
                            marginRight:'0px !important',
                            fontWeight:300,
                            padding:'6px 16px'
                           }}/>
                    </div>
                </BreadCrumbWithActions>
            </Box>
            <Box
            sx={{
                borderBottom: BoxEffects.borderBottom, 
                borderColor: 'secondary.main',
                //padding:BoxEffects.padding,
                marginBottom:BoxEffects.marginButton,
                // paddingLeft:BoxEffects.margin,
                // paddingRight:headerButton.marginRigt
             }}
            >
               <div className="m-6">
                    {loading ? <Loading />
                    :
                    <CustomCodeList
                        codeList={codeList}
                        changeStatus={changeStatus}
                        isAddDocuments={isAddDocuments}
                        toggleIsAddDocuments={toggleIsAddDocuments}
                    />}
               </div>
            </Box>

            {openFilter &&(
                <AnalysisFilter 
                  title="Filter"
                  open={openFilter}
                  fieldMap={fieldMap}
                  defaultValues={defaultValues}
                  onClose={toggleFilterModal}
                  onSubmit={onFilterSubmit}
                  loading={isFiltering}
                  onReset={() =>{
                    setSelectedType('')
                    setSelectedEmployee('')
                  }}
                />
            )}

            {openDocumentation &&(
                <Documentation 
                   open={openDocumentation}
                   title={'Documentation'}
                   onClose={toggleDocumentation}
                />
            )}
        </>
    )
}
export default CustomCode;