import React from 'react';
import FormManager from '../../../shared-component/formrelated-component/FormManager';

import { enqueueSnackbar } from 'notistack';
 
import { useParams } from 'react-router-dom';
import { iconsList } from '../../../../Config/Constants';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { v4 as uuidv4 } from 'uuid';

const AddLocationComponent = ({
  data,
  onClose,
  onSuccessCallback,
  selectedCoordinates,
  onCancel,
  removeItemFormList,
  onDeleteSuccessCallback,
}) => {
  const { id } = useParams();
 
  const defaultValues = {
    icon: JSON.parse(localStorage.getItem('Map_created_open_and_show_maps'))
      ?.name
      ? {
          id: 8,
          icon: <AddBusinessOutlinedIcon />,
          name: 'Property',
        }
      : null,
    distance: '',
    place_name: JSON.parse(
      localStorage.getItem('Map_created_open_and_show_maps'),
    )?.name
      ? JSON.parse(localStorage.getItem('Map_created_open_and_show_maps'))?.name
      : '',
    place_type: JSON.parse(
      localStorage.getItem('Map_created_open_and_show_maps'),
    )?.name
      ? {
          created: '2024-05-29T07:23:06.154583Z',
          default_icon: 'property',
          id: 8,
          modified: '2024-05-29T07:23:06.154679Z',
          name: 'Property',
        }
      : '',
    latitude: selectedCoordinates?.lat,
    longitude: selectedCoordinates?.lng,
    show_distance: false,
  };

  const createLocationWithPin = (payload) => {
    if (payload?.distance === '' && payload?.show_distance === true) {
      enqueueSnackbar('Please add distance', { variant: 'error' });
      return;
    } else if (payload?.place_name === '') {
      enqueueSnackbar('Please add place name', { variant: 'error' });
      return;
    } else if (payload?.place_type === '') {
      enqueueSnackbar('Please select a place type', { variant: 'error' });
      return;
    } else {
      let response = {
        id: data?.id ? data?.id : uuidv4(),
        icon:
          payload.icon !== null ? payload.icon?.name : payload.place_type?.name,
        distance: payload.distance,
        place_name: payload.place_name,
        place_type: payload.place_type,
        latitude: data?.latitude
          ? Number(data?.latitude).toFixed(9)
          : Number(selectedCoordinates?.lat).toFixed(9),
        longitude: data?.longitude
          ? Number(data?.longitude).toFixed(9)
          : Number(selectedCoordinates?.lng).toFixed(9),
        show_distance: payload.show_distance,
        geo_map: Number(id),
      };

      if (data?.created) {
        response = {
          ...response,
          created: data?.created,
          modified: data?.modified,
        };
      }
      onSuccessCallback(response);
    }
  };

  const deleteMapItem = () => {
    removeItemFormList(data?.id);
    onDeleteSuccessCallback();
  };

  const fieldMap = [
    {
      name: 'icon',
      type: 'icon_selection',
      fieldProps: {
        listofIcons: iconsList,
      },
    },
    {
      name: 'distance',
      label: 'Distance',
      placeholder: 'Enter Distance with unit',
      type: 'text',
      fieldProps: {
        required: true,
      },
    },
    {
      name: 'place_name',
      label: 'Place Name',
      placeholder: 'Enter name',
      type: 'text',
      fieldProps: {
        required: true,
      },
    },
    {
      name: 'place_type',
      label: 'Place Type',
      placeholder: 'Select',
      type: 'select',
      fieldProps: {
        choicesAPI: `property/geo_map_amenity_type/`,
        titleKey: 'name',
        required: true,
      },
    },
    {
      name: 'show_distance',
      label: 'Show Distance',
      placeholder: '',
      type: 'checkbox',
    },
  ];

  return (
    <div
      style={{
        zIndex: 9999,
      }}
    >
      <FormManager
        data={data?.id ? data : {}}
        onCancel={data?.id ? onCancel : onClose}
        defaultValues={defaultValues}
        fieldMap={fieldMap}
        deleteBtnStyles={{
          color: '#FF5C5C !important',
        }}
        secondaryBtnStyles={{
          backgroundColor: 'transparent !important',
          color: '#353349 !important',
        }}
        isDeleteButton={data?.id ? true : false}
        onDelete={deleteMapItem}
        secondaryBtnTitle="Back"
        formSubmit={createLocationWithPin}
      />
    </div>
    // </FormModal>
  );
};

export default AddLocationComponent;

const payload = [
  {
    distance: '',
    geo_map: '48',
    icon: 'Leisure and Entertainment',
    latitude: '12.997183569',
    longitude: '77.618228081',
    place_name: 'Cox Town',
    place_type: 6,
    show_distance: false,
  },
];
