import React, { useState } from 'react';
import {
    FormControl,
    Select,
    MenuItem
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ProfileContainer from '../../../shared-component/table-component/Row/ProfileContainer';
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import formatISOString from '../../../../utils/formatISOString';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../shared-component/Loading/Loading';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import { apiService } from '../../../../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import HistoryTable from '../../../shared-component/table-component/HistoryTable';
import DetailSideDrawer from '../../ParentComponents/SideDrawer/DetailSideDrawer';
import SelectComponent from '../Modal/SelectComponent';
 



const headersForHistoryTable = [
  { id: "1", 
    header_name: "Name", 
    minWidth: 150 },
  { id: "2", 
    header_name: "Email", 
    minWidth: 180 },
  { id: "3", 
    header_name: "Phone No", 
    minWidth: 150 },
  { id: "4", 
    header_name: "Date & Time", 
    minWidth: 150 },
  { id: "5",  
    header_name: "Property", 
    minWidth: 150 },
  { id: "6", 
    header_name: "Unit", 
    minWidth: 150 },
  { id: "7", 
    header_name: "Source", 
    minWidth: 150 },
  { id: "8", 
    header_name: "Updated By", 
    minWidth: 200 
  },
  { id: "9", 
    header_name: "Booking Status", 
    minWidth: 200 
  },
];

const BookingComponent = ({
    rows,
    loading,
    count,
    listBooking,
    formSubmissionList
}) =>{
  const navigate = useNavigate()
  const { exitFromPlatform } = useExit()
 // const [status, setStatus] = useState('')
  const [load, setLoad] = useState(false);

  const [sideDrawer, setSideDrawer] = useState(false)
  const [selectedForm, setSelectedForm] = useState(null);
  
  const [propertyLoading, setPropertyLoading] = useState(false)
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [unitLoading, setUnitLoading] = useState(false)
  const [unitDetails, setUnitDetails] = useState(null)
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [unitStatus, setUnitStatus] = useState(null)
  localStorage.setItem('tabValue', '1');
  const handleSideDrawer = () => {
    setSideDrawer(!sideDrawer)
  }
  
  const openDrawerWithFormDetails = (form) =>{
    setSelectedProperty(null)
    setSelectedInventory(null)
    setSelectedForm(form)
    handleSideDrawer()
  }
  
  const openDrawerWithPropertyDetails = (pro) =>{
    setSelectedForm(null)
    setSelectedInventory(null)
    setSelectedProperty(pro)
    handleSideDrawer()
  }
  
  const openDrawerWithUnitDetails = (uni) =>{
    setSelectedProperty(null)
    setSelectedForm(null)
    setSelectedInventory(uni)
    handleSideDrawer()
  }


  async function changeStatus(data, e) {
    
    let newData = {
      ...data,
      booking_status: e.target.value,
      property: data?.property?.id,
      unit: data?.unit?.id,
      employee: data?.employee?.id ? data?.employee?.id : null
    }

    if(data?.form?.id){
      newData["form"] = data?.form?.id
    }else{
      delete newData?.form
    }
    apiService.put(`booking/entries/${data?.id}/`, newData).then((res) =>{
      enqueueSnackbar('Status changed!', { variant: `success` })
      listBooking(1, 10, '')
      setLoad(false)
     }).catch((error) =>{
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
          setLoad(false)
      }
      }
     })
} 


const updateKeyName = (label) => {
  return label.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
const modifyFormData = (data) =>{
  const newData = {
    ...data,
    submissions: {...JSON.parse(data?.submission)}
  }

  const newFormData = {};

  if(newData?.submissions?.sections?.length > 0){
   const update = newData?.submissions?.sections?.map((e) =>{
    const nn = {...e};
    delete nn?.source
    return {
        ...nn,
        fields: e?.fields?.filter((f) =>{
           return f?.name !== "files"
        }),
        files: e?.fields?.find((g) =>{
            if(g.name === 'files'){
                return g
            }
        })?.value
    }
   })

   newFormData["sectionDetails"] = update;
   newFormData["details"] = newData
   newFormData["independentFields"] = [];
   newFormData["getFiles"] = [];
}else{
    const formData = {...newData?.submissions};
    delete formData?.formDetails;
    delete formData?.sections;
    delete formData?.files;
    delete formData?.unit;
    delete formData?.property;
    delete formData?.source;
   
    const newnData = Object.entries(formData)?.map((m) =>{
        return {
            name: updateKeyName(m[0]),
            value: m[1]
        }
    })
    newFormData["sectionDetails"] = [];
    newFormData["independentFields"] = newnData;
     newFormData["getFiles"] = newData?.submissions?.files?.length > 0 ? [...newData?.submissions?.files] : [];
    newFormData["details"] = newData
}
console.log('from form data', newFormData)
return newFormData
}
    const columns = [
        { field: 'id', headerName: 'Booking ID',minWidth: 150},
        { field: 'created', headerName: 'Booked Date',minWidth: 200, renderCell: (params) =>{

            return (params?.row?.created) ? formatISOString(params?.row?.created) : "__"
        }},
        { 
            field: 'name', 
            headerName: 'Customer Details',
            minWidth: 200,
            renderCell: (params) => {
              return <ProfileContainer profile={{name: params?.row?.name, position: params?.row?.email}} />
            },
        },
        { field: 'property', headerName: 'Property',minWidth: 200, renderCell: (params) =>{
          
            return <div onClick={(e) =>{
              e.stopPropagation()
              if(params?.row?.property?.id){
                openDrawerWithPropertyDetails(params?.row?.property)
               }
            }}>
              {params.row?.property?.name || '-'}
            </div>
        }},
        { field: 'unit', headerName: 'Unit',minWidth: 200, renderCell: (params) =>{
         
          return <div onClick={(e) =>{
            e.stopPropagation()
            if(params?.row?.unit?.id){
              openDrawerWithUnitDetails(params?.row)
           }
          }}>
            {params.row?.unit?.name || '-'}
          </div>
      }},
        // { field: 'source', headerName: 'Source',minWidth: 200, renderCell: (params) => {
        //     return <ProfileContainer profile={{
        //       name: params?.row?.name
        //     }} />
        //   },},
        { field: 'payment_status', headerName: 'Payment Status',minWidth: 150, renderCell: (params) => {
          return `${params.row.payment_status?.split('')[0].toUpperCase()}${params.row.payment_status?.substring(1, params.row.payment_status?.length)}`
        }},
        { field: 'booking_status', headerName: 'Booking Status',minWidth: 200, renderCell: (params) => {
            
          return <SelectComponent 
                  row={params?.row}
                  booking_status={params.row.booking_status}
                  changeStatus={changeStatus}
                  />
        },},
         { field: 'actions', headerName: '',minWidth: 120, renderCell: (params) => {
            
          return params?.row?.form?.id ? (
          <div 
          className='text-[#004FE8]'
          style={{
            fontFamily: 'Rubik',
            fontSize: '14px',
            fontWeight: 300,
            lineHeight: '23.7px',
            letterSpacing: '-0.022em',
          }} onClick={(e) =>{
            e.stopPropagation()
            if(params?.row?.form?.id){
              openDrawerWithFormDetails(params?.row?.form)
            }
          }}>Form Details</div>
          ) : (
            <div 
          className='text-[#8E8CA3]'
          style={{
            fontFamily: 'Rubik',
            fontSize: '14px',
            fontWeight: 300,
            lineHeight: '23.7px',
            letterSpacing: '-0.022em',
          }} onClick={(e) =>{
            e.stopPropagation()
          }}>Form Details</div>
          )
        },},
    ];

    function handelRowClick(id){
      navigate(`/booking/${id}`, {});
    }

    const fetchInventory = (unitId) => {
      setUnitLoading(true);
      apiService
        .get(`property/unit/${unitId}`)
        .then((res) => {
          if (res?.data?.output_specs?.groups) {
            const newData = [...res?.data?.output_specs?.groups];
    
            const updateSpecsGroups = newData?.map((e) => {
              const findGroup = res?.data?.specs?.groups?.find((f) => {
                return f?.grp_id === e?.grp_id;
              });
    
              return {
                grp_id: e?.grp_id,
                items: e?.items?.map((f) => {
                  const findItem = findGroup?.items?.find((g) => {
                    return g?.item_id === f?.item_id;
                  });
    
                  return {
                    ...f,
                    [f?.item?.name]: findItem?.item_value,
                  };
                }),
              };
            });
    
            let newArray = [...updateSpecsGroups];
            const newData1 = {
              ...res?.data,
              images: res?.data?.images?.map((e) =>{
                return {
                  ...e,
                  tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
                }
              }),
              booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),
              specs: {
                template_id: res?.data?.specs?.template_id,
                groups: newArray,
              },
            }
            setUnitDetails(newData1);
          } else {
          setUnitDetails({
            ...res?.data,
            booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),});
           }
          setBookingData(res?.data)
          setUnitStatus(res?.data?.status);
          setUnitLoading(false);
        })
        .catch((error) => {
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (
              returnedError === 'Given token not valid for any token type' ||
              returnedError === 'Request failed with status code 401'
            ) {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
              setUnitLoading(false);
            }
          }
        });
    };
    
    
    const fetchPropertyDetails = (propertyId) => {
      setPropertyLoading(true);
      apiService
        .get(`property/prop/${propertyId}/`)
        .then(async (res) => {
          setPropertyDetails({
            ...res?.data,
            image_list: res?.data?.image_list?.map((e) =>{
              return {
                ...e,
                tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
              }
            }),
          });
          setPropertyLoading(false);
        })
        .catch((error) => {
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (
              returnedError === 'Given token not valid for any token type' ||
              returnedError === 'Request failed with status code 401'
            ) {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
              setPropertyLoading(false);
            }
          }
      });
    }
    
    const returnFormData = () =>{
      let dataObj = {}
      if(selectedProperty?.id){
        dataObj = {
            propertyData: propertyDetails,
          }
      }
      else if(selectedInventory?.unit?.id){
        dataObj = {
          unitStatus: unitStatus,
          inventoryData: unitDetails
        }
      }
      else{
        dataObj = modifyFormData(selectedForm)
      }
    
      return dataObj
    }
    
    const navigateToForm = (navigateTo) =>{
      navigate(navigateTo)
    }

    return(
       <>
       {loading ? <Loading />
        :
        <>
        {rows?.length > 0 ?
          <HistoryTable 
             rowData={rows?.length === 0 ? [] : rows}
             headers={headersForHistoryTable}
             isbooking={true}
             changeBookingStatus={changeStatus}
             openDrawerWithFormDetails={openDrawerWithFormDetails}
             openDrawerWithPropertyDetails={openDrawerWithPropertyDetails}
             openDrawerWithUnitDetails={openDrawerWithUnitDetails}
             formSubmissionList={formSubmissionList}
             navigateToDetails={handelRowClick}
          />
        :
        <EmptyCard 
          title='No Bookings Found'
          subtitle='Please click at "Add New" to add a new booking'
        />}
        </>
       }

      {sideDrawer && (
           <DetailSideDrawer 
              sideTitle={selectedInventory?.unit?.id ? `See in Units` : (selectedProperty?.id ? `See in Properties` : "See in Forms")}
              sideButtonClickHandler={() =>{
                if(selectedProperty?.id){
                    navigateToForm(`/properties/${selectedProperty?.id}`)
               }else if(selectedInventory?.unit?.id){
                   localStorage.setItem('property_name', JSON.stringify(selectedInventory?.property?.name))
                    navigateToForm(`/properties/${unitDetails?.id}/inventory/${selectedInventory?.unit?.id}`)
               }else{
                  if(selectedForm?.id){
                    navigateToForm(`/form-detail-preview/${selectedForm?.id}`)
                  }
               }
               
              }}
              title={selectedInventory?.unit?.id ? `Unit - ${unitDetails?.name || ''}` : (selectedProperty?.id ? `Property - ${propertyDetails?.name || ''}` : `Form Submission ${selectedForm?.id}`)}
              isFormComponent={selectedProperty?.id || selectedInventory?.unit?.id  ? false : true}
              open={sideDrawer}
              onClose={handleSideDrawer}
              formData={returnFormData()}
              isPropertyComponent={selectedProperty?.id ? true : false}
              loading={selectedProperty?.id ? propertyLoading : unitLoading}
              fetchCallback={() =>{
                 if(selectedProperty?.id){
                    fetchPropertyDetails(selectedProperty?.id)
                 }else{
                    if(selectedInventory?.unit?.id){
                      fetchInventory(selectedInventory?.unit?.id)
                    }
                 }
              }}
              isUnitComponent={selectedInventory?.unit?.id ? true : false}
           />
       )}
       </>
    )
}

export default BookingComponent;
