import React, { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import FormManager from '../../../shared-component/formrelated-component/FormManager';
import useExit from '../../../../utils/useExit';
import returnError from '../../../../utils/ExportError';
 
import { TokenStorage } from '../../../../constant/AuthConstant';

const AddDocument = ({
    open,
    title,
    onClose,
    selectedData = null,
    successCallback
}) =>{
    const { exitFromPlatform } = useExit()
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
   
    const defaultValues = {
        name: '',
        image: ''
    }
    const fieldMap = [
        {
          name: 'name',
          label: 'Document Title',
          placeholder: 'Name',
          type: 'text',
          fieldProps: {
            required: true,
          },
        },
        {
          name: 'image',
          label: '',
          placeholder: '',
          type: 'single_image',
          upload_title: 'Upload Document',
          upload_description: 'Acceptable format Docx, Maximum 5MB',
          fileTypes: {'application/vnd.openxmlformats-officedocument.wordprocessingml.document': []},
        },
      ];

      const handleAddDocument = (e) => {
       
        if (e?.name === '') {
          enqueueSnackbar('Please add document name', { variant: 'error' });
        } else if (e?.image === '') {
          enqueueSnackbar('Please add Document', { variant: 'error' });
        } else {
          setLoading(true);
          const formData = new FormData();
          
          if (e?.image?.name) {
            formData.append('title', e?.name)
            formData.append('file', e?.image)
          } 
    
          const apiUrl = `${process.env.REACT_APP_BASE_URL}documents/docs/`;
    
          axios({
            method: 'post',
            url: apiUrl,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${TokenStorage.getToken()}`,
            },
          })
            .then((res) => {
              enqueueSnackbar('Document Added.', { variant: 'success' });
              setLoading(false);
              if(successCallback){
                successCallback();
              }
              onClose();
            })
            .catch((error) => {
              setLoading(false);
              if (error.response?.data) {
                const returnedError = returnError(error.response?.data);
                if (
                  returnedError === 'Given token not valid for any token type' ||
                  returnedError === 'Request failed with status code 401'
                ) {
                  exitFromPlatform();
                } else {
                  enqueueSnackbar(returnedError, { variant: 'error' });
                }
              }
              console.log(error);
            });
        }
      }

    return (
        <FormModal
         open={open}
         title={title}
         onClose={onClose}
        >
            <div className='mt-4'>
               <FormManager 
                  onCancel={onClose}
                  fieldMap={fieldMap}
                  defaultValues={defaultValues}
                  formSubmit={handleAddDocument}
                  loading={loading}
               />
            </div>
        </FormModal>
    )
}

export default AddDocument
