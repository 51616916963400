import React from 'react';

const DataErrorIcon = ({
    width,
    height
}) =>{
    return (
            <svg width={width} height={height} viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_4954_28315" maskUnits="userSpaceOnUse" x="0" y="0" width="74" height="74">
                <rect width="74" height="74" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_4954_28315)">
                <path d="M9.25 61.6667V55.5H46.25V61.6667H9.25ZM49.3333 40.0833C45.0681 40.0833 41.4323 38.5802 38.426 35.574C35.4198 32.5677 33.9167 28.9319 33.9167 24.6667C33.9167 20.4014 35.4198 16.7656 38.426 13.7594C41.4323 10.7531 45.0681 9.25 49.3333 9.25C53.5986 9.25 57.2344 10.7531 60.2406 13.7594C63.2469 16.7656 64.75 20.4014 64.75 24.6667C64.75 28.9319 63.2469 32.5677 60.2406 35.574C57.2344 38.5802 53.5986 40.0833 49.3333 40.0833ZM9.25 37V30.8333H28.675C29.0347 31.9639 29.4458 33.0431 29.9083 34.0708C30.3708 35.0986 30.9361 36.075 31.6042 37H9.25ZM9.25 49.3333V43.1667H38.2333C39.4153 43.8861 40.6743 44.4899 42.0104 44.9781C43.3465 45.4663 44.7597 45.8132 46.25 46.0188V49.3333H9.25ZM47.7917 27.75H50.875V15.4167H47.7917V27.75ZM49.3333 33.9167C49.7444 33.9167 50.1042 33.7625 50.4125 33.4542C50.7208 33.1458 50.875 32.7861 50.875 32.375C50.875 31.9639 50.7208 31.6042 50.4125 31.2958C50.1042 30.9875 49.7444 30.8333 49.3333 30.8333C48.9222 30.8333 48.5625 30.9875 48.2542 31.2958C47.9458 31.6042 47.7917 31.9639 47.7917 32.375C47.7917 32.7861 47.9458 33.1458 48.2542 33.4542C48.5625 33.7625 48.9222 33.9167 49.3333 33.9167Z" fill="#B8B7C5"/>
                </g>
            </svg>
    )
}

export default DataErrorIcon