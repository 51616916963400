import React, { useState } from 'react'
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


const TimelineImagesCarousel = ({
    open,
    onClose,
    allImages,
    activeIndex
}) =>{
    const [index, setIndex] = useState(activeIndex)

    const onNextPress = () =>{
        if(index !== allImages?.length - 1){
          const i = index + 1;
          setIndex(i)
        }
      }
  
      const onPrevPress = () =>{
         if(index !== 0){
          const i = index - 1;
          setIndex(i)
         }
      }

    return(
        <Dialog
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
             width: '80%',
             maxWidth: '80%',
          },
        }}
      }
      >
         <div className='bg-white relative'>
             <div className=''> 
                <div className='flex items-center justify-between ml-8 mr-2'>
                  <div></div>
                  <div className='' onClick={onClose}>
                      <CloseIcon className='!fill-[#000000] cursor-pointer' fontSize='medium' />
                  </div>
               </div>
               <div className='h-full mx-8 my-2'>
                      <div className='flex justify-between gap-[20px]'>
                         <div className='w-full mb-4'>
                             <img src={allImages[index]?.media} style={{
                              width:'100%',
                              height:'500px',
                              objectFit:'contain'
                             }} />
                             <div className='flex w-full items-center justify-center gap-[10px] mt-4'>
                                  <div className='bg-[#EDECF9] px-2' onClick={onPrevPress}>
                                      <KeyboardArrowLeftIcon className='!fill-[#000000] cursor-pointer' />
                                  </div>
                                  <div className='bg-[#EDECF9] px-2' onClick={onNextPress}>
                                      <KeyboardArrowRightIcon className='!fill-[#000000] cursor-pointer' />
                                  </div>
                              </div>
                         </div>
                      </div>
                  </div>
             </div>
         </div>
      </Dialog>
    )
}

export default TimelineImagesCarousel
