import React from 'react'

const BackIcon = () =>{
    return (
        <svg width="35" height="30" viewBox="0 0 35 30" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M7.65625 14.0625H29.5312C29.8213 14.0625 30.0995 14.1613 30.3046 14.3371C30.5098 14.5129 30.625 14.7514 30.625 15C30.625 15.2486 30.5098 15.4871 30.3046 15.6629C30.0995 15.8387 29.8213 15.9375 29.5312 15.9375H7.65625C7.36617 15.9375 7.08797 15.8387 6.88285 15.6629C6.67773 15.4871 6.5625 15.2486 6.5625 15C6.5625 14.7514 6.67773 14.5129 6.88285 14.3371C7.08797 14.1613 7.36617 14.0625 7.65625 14.0625Z" fill="black"/>
           <path d="M8.10906 15.0002L17.1806 22.774C17.386 22.95 17.5014 23.1888 17.5014 23.4377C17.5014 23.6867 17.386 23.9254 17.1806 24.1015C16.9753 24.2775 16.6967 24.3764 16.4063 24.3764C16.1158 24.3764 15.8373 24.2775 15.6319 24.1015L5.78813 15.664C5.68627 15.5769 5.60546 15.4734 5.55032 15.3595C5.49518 15.2456 5.4668 15.1235 5.4668 15.0002C5.4668 14.8769 5.49518 14.7548 5.55032 14.6409C5.60546 14.527 5.68627 14.4235 5.78813 14.3365L15.6319 5.89896C15.8373 5.72292 16.1158 5.62402 16.4063 5.62402C16.6967 5.62402 16.9753 5.72292 17.1806 5.89896C17.386 6.07499 17.5014 6.31375 17.5014 6.56271C17.5014 6.81166 17.386 7.05042 17.1806 7.22646L8.10906 15.0002Z" fill="black"/>
        </svg>
    )
}

export default BackIcon