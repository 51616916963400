import { Box, Card, CardActionArea, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import { cardheight } from "../../../Config/Constants";
import DomainAddIcon from '@mui/icons-material/DomainAdd';

function EmptyCard({
    title='',
    subtitle='',
    icon,
    noBorder = false,
    style
}){
    return(
        <>
            <div className={noBorder ? "rounded-[8px] flex flex-col justify-center items-center" 
             : "h-[392px] rounded-[8px] flex flex-col justify-center items-center"
            } style={{ 
                border: noBorder ? 'none' : '2px solid #E1EBFE',
                ...style
                }}>
                <CardActionArea sx={{
                    pointerEvents: 'none'
                }}>
                    <CardContent>
                        <Typography align='center' color="text.cardTitle">
                        {/* {icon} */}
                        {icon ? 
                        icon
                         :
                         <DomainAddIcon className="!fill-[#B8B7C5]" sx={{
                            width:'50px',
                            height:'50px'
                        }} />}
                        </Typography>
                        
                        <Typography align='center' variant='h5' className="!my-1.5" color="text.cardTitle" sx={{
                            fontFamily: 'Rubik',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: 'normal',
                            letterSpacing: '-0.44px',
                            color:'#8E8CA3'
                        }}>{title}</Typography>
                        <Typography align='center' color="text.cardTitle" sx={{
                            fontFamily: 'Rubik',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: 'normal',
                            letterSpacing: '-0.44px',
                            color:'#8E8CA3'
                        }}>{subtitle}</Typography>
                    </CardContent>
                </CardActionArea>
            </div>
        </>
    )
}
export default EmptyCard;