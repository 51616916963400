import React, { useState } from 'react';
import { Box } from '@mui/material';

import { BoxEffects, headerButton } from '../../../../Config/Constants';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';
import Users from './components/tabs/Users';
import Roles from './components/tabs/Roles';
import LabTabs from '../../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs';
import Permissions from '../../userManagement/permissions/Permissions';
import FilterFields from '../../leads/Fields/FilterFields';
 

const array = [{ title: 'User Management', url: '/' }];


const adminList = [
  {
    label: 'Dashbaord',
    value: '',
    radioList: [
      { label: '', value: ''},
      { label: '', value: ''},
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Properties',
    value:'',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Analytics',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Sales',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Gallary',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Forms',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Affiliates',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Websites',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'User Management',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Specifications',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Organization',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Design Template',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Custom Code',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
];

const devloperList = [
  {
    label: 'Dashbaord',
    value: '',
    radioList: [
      { label: '', value: 'no-acess'},
      { label: '', value: ''},
      { label: '', value: '' },
    ],
  },
  {
    label: 'Properties',
    value:'',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Analytics',
    value: '',
    radioList: [
      { label: '', value: 'no-acess' },
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Sales',
    value: '',
    radioList: [
      { label: '', value: 'no-acess' },
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Gallary',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Forms',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Affiliates',
    value: '',
    radioList: [
      { label: '', value: 'no-acess' },
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Websites',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'User Management',
    value: '',
    radioList: [
      { label: '', value: 'no-acess' },
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Specifications',
    value: '',
    radioList: [
      { label: '', value: 'no-acess' },
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Organization',
    value: '',
    radioList: [
      { label: '', value: 'no-acess' },
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Design Template',
    value: '',
    radioList: [
      { label: '', value: 'no-acess' },
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Custom Code',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
];

const salesList = [
  {
    label: 'Dashbaord',
    value: '',
    radioList: [
      { label: '', value: ''},
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Properties',
    value:'',
    radioList: [
      { label: '', value: '' },
      { label: '', value: 'read' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Analytics',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Sales',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Gallary',
    value: '',
    radioList: [
      { label: '', value: 'no-acess'},
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Forms',
    value: '',
    radioList: [
      { label: '', value: 'no-acess'},
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Affiliates',
    value: '',
    radioList: [
      { label: '', value: 'no-acess'},
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Websites',
    value: '',
    radioList: [
      { label: '', value: 'no-acess'},
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'User Management',
    value: '',
    radioList: [
      { label: '', value: 'no-acess'},
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Specifications',
    value: '',
    radioList: [
      { label: '', value: 'no-acess'},
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Organization',
    value: '',
    radioList: [
      { label: '', value: 'no-acess'},
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Design Template',
    value: '',
    radioList: [
      { label: '', value: 'no-acess'},
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Custom Code',
    value: '',
    radioList: [
      { label: '', value: 'no-acess'},
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
];

const marketingList = [
  {
    label: 'Dashbaord',
    value: '',
    radioList: [
      { label: '', value: ''},
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Properties',
    value:'',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Analytics',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Sales',
    value: '',
    radioList: [
      { label: '', value: 'no-acess' },
      { label: '', value: '' },
      { label: '', value: '' },
    ],
  },
  {
    label: 'Gallary',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Forms',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Affiliates',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Websites',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'User Management',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Specifications',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Organization',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Design Template',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
  {
    label: 'Custom Code',
    value: '',
    radioList: [
      { label: '', value: '' },
      { label: '', value: '' },
      { label: '', value: 'write' },
    ],
  },
];

const UserManagementComponent = () => {
  const [value, setValue] = useState('1');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState({name: '', id: ''});

  const [permissionsList, setPermissionsList] = useState(adminList)

  const handleRoleModal = () =>{
      setOpenRoleModal(!openRoleModal)
  }

  const handleInviteModal = () => {
    setOpenInviteModal(!openInviteModal);
  };

  const handleFilterModal = () => {
    setOpenFilterModal(!openFilterModal);
  };

  const handleValueChange = (event, newValue) => {
    setValue(newValue);
  };

  const changeListing = (newValue) =>{
      if(newValue?.name?.toLowerCase() === 'admin'){
        setPermissionsList(adminList)
      }else if(newValue?.name?.toLowerCase() === 'developer'){
        setPermissionsList(devloperList)
      }else if(newValue?.name?.toLowerCase() === 'sales'){
        setPermissionsList(salesList)
      }else if(newValue?.name?.toLowerCase() === 'marketing'){
        setPermissionsList(marketingList)
      }
  }

  const tabs = [
    {
      id: 1,
      value: '1',
      label:'Users',
      page: <Users 
      openInviteModal={openInviteModal}
      handleInviteModal={handleInviteModal}
      openFilterModal={openFilterModal}
      handleFilterModal={handleFilterModal}
      setOpenInviteModal={setOpenInviteModal}
      />,
    },
    {
      id: 2,
      value: '2',
      label:'Roles',
      page: <Roles 
      openRoleModal={openRoleModal}
      handleRoleModal={handleRoleModal}
      setOpenRoleModal={setOpenRoleModal}
      />,
    },
    {
      id: 3,
      value: '3',
      label:'Permissions',
      page: <Permissions 
      selectedRole ={selectedRole}
      permissionsList={permissionsList}
      />,
    },
  ];

  

  return (
    <div>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '0px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={array}>
          <div className="flex justify-between items-center">
            <div
              style={{
                marginLeft: '20px',
              }}
            >
              <div className="relative">
                {value === '1' && (
                  <SecondaryBtn
                    className="!mr-4"
                    style={{
                      boxShadow: 'none',
                      fontWeight: 300,
                    }}
                    title="Filter"
                    onClick={handleFilterModal}
                  />
                )}
                {value === '1' ? (
                  <PrimaryBtn
                  className="!mx-0"
                  style={{
                    boxShadow: 'none',
                    margin: '0px !important',
                    fontWeight: 300,
                  }}
                  title={"Add New"}
                  onClick={value === '1' ? handleInviteModal : handleRoleModal}
                />
                ): (<></>)}
                {value === '3' && (
                  <FilterFields
                     value={selectedRole}
                     setValue={(newValue) =>{
                      changeListing(newValue)
                      setSelectedRole(newValue)
                     }}
                     choicesAPI={`rbac/role/`} 
                     titleKey={"name"}
                     fieldStyles={{
                      width:'150px',
                      background: '#EDECF9',
                      borderRadius: '4px'
                     }}
                     selectFieldStyles={{
                      borderColor: 'none',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border:'none',
                      }
                     }}
                  />
                )}
              </div>
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>

      <LabTabs tabs={tabs} value={value} handleChange={handleValueChange} />
    </div>
  );
};

export default UserManagementComponent;
