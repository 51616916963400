// ----------------- Documentation ---------------------------
// this component handels the tabs for analysis integration and ads integration


import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Tabs from "@assystant/mui-tabs";
import { BoxEffects, headerButton } from "../../../../Config/Constants";
import BreadCrumbWithActions from "../../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import AnalysisIntegration from "./AnalysisIntegration";
import AdsIntegration from "./AdsIntegration";
import { SecondaryButton } from "@assystant/mui-buttons";
import { PrimaryButton } from "@assystant/mui-buttons";
import SecondaryBtn from "../../../shared-component/button-component/SecondaryBtn";
import PrimaryBtn from "../../../shared-component/button-component/PrimaryBtn";


function Integrations(){
    const [value, setValue] = useState('1');
    const arr1 = [
        {title: 'Integrations', url: '/'},
    ]
    const tabs = [
        {label:'Code Injection', value:'1', page:<AnalysisIntegration />},
        {label:'Ads integration',value:'2',page:<AdsIntegration />},
    ]
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    return(
        <>
            <Box 
                sx={{borderBottom: BoxEffects.borderBottom, borderColor: 'secondary.main',padding:BoxEffects.padding,marginBottom:BoxEffects.marginButton,paddingLeft:BoxEffects.margin,paddingRight:headerButton.marginRigt,position:'sticky',top:'65px',backgroundColor:'white',zIndex:99 }}
            >
                <BreadCrumbWithActions propArr={arr1}>
                    <div className="flex items-center w-full px-4">
                        <div className="documentation mx-4">{`Documentation`}</div>
                        <SecondaryBtn title='Filter' style={{
                             fontWeight:400
                        }} />
                        <PrimaryBtn title='Add CSS' style={{
                             fontWeight:400
                        }} />
                        <PrimaryBtn title='Add JS' style={{
                             fontWeight:400
                        }} />
                    </div>
                </BreadCrumbWithActions>
            </Box>
            <Box
            sx={{borderBottom: BoxEffects.borderBottom, borderColor: 'secondary.main',padding:BoxEffects.padding,marginBottom:BoxEffects.marginButton,paddingLeft:BoxEffects.margin,paddingRight:headerButton.marginRigt }}
            >
                <Tabs tabs={tabs} setValue={setValue} value={value} handleChange={handleChange}  />
            </Box>
        </>
    )
}
export default Integrations;