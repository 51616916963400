import React from 'react'

const PremiumLabel = ({ label = 'Premium'}) =>{
    return(
        <div className='ml-6 rounded-full bg-[#FAD200] w-20 h-6 flex items-center justify-center'>
            <span className='text-[#353349] font-normal' style={{
                fontSize: '11px'
            }}>{label}</span>
        </div>
    )
}

export default PremiumLabel 

