import React from 'react'

const MenuIcon = ({
    height,
    width
}) =>{
    return (
        <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_258_11361" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
            <rect width="32" height="32" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_258_11361)">
            <path d="M4 24V21.3333H21.3333V24H4ZM26.1333 22.6667L19.4667 16L26.1333 9.33333L28 11.2L23.2 16L28 20.8L26.1333 22.6667ZM4 17.3333V14.6667H17.3333V17.3333H4ZM4 10.6667V8H21.3333V10.6667H4Z" fill="white"/>
            </g>
       </svg>
    )
}

export default MenuIcon