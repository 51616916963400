// ----------------- Documentation ---------------------------
// this component used for add and edit the data from property overview
// this form used the firebase database to store the data

import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { useState } from 'react';
import OverviewPage from '../../shared-component/OverviewPage/OverviewPage';
import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import { BoxEffects } from '../../../Config/Constants';
import Inventory from './Inventory';
import MasonryWithToogle from './MesonaryWithToggle';
import EditProperty from './EditProperty';
import AddPlan2 from './AddPlan2';
import Link from './Link';
import AddUnit from './AddUnit';
import { apiService, BASE_URI } from '../../../constant/AuthConstant';
import { useEffect } from 'react';
import AddProperty from './AddProperty';
import AssignSpecification from './AssignSpecification';
import PropertySettings from './PropertySettings';
import ShowFields from './ShowFilelds';
import { useNavigate, useParams } from 'react-router-dom';
import LabTabs from '../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs';
import MapContainer from './newMap/MapContainer';
import RemovePopup from '../../shared-component/RemovePopup/RemovePopup';
import UserContext from '../../../context/UserContext';
import Loading from '../../shared-component/Loading/Loading';
import { enqueueSnackbar } from 'notistack';
import AddSpecifications2 from './Modal/AddSpecifications2';
import returnError from '../../../utils/ExportError';
import useExit from '../../../utils/useExit';
import Integration from './newMap/linkadd/Integration';
import { renderSpecScript } from './newMap/SpecScript';
import MapsList from '../Maps/MapsList';
import Updates from './PropertyUpdates/Updates';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import ErrorBtn from '../../shared-component/button-component/ErrorBtn';
import SecondaryBtn from '../../shared-component/button-component/SecondaryBtn';

function Overview({ template }) {
  const { id } = useParams();
  const [properties, setProperties] = useState([]);
  const { exitFromPlatform } = useExit();

  const tabValue =
    localStorage.getItem('tabValue') !== null
      ? localStorage.getItem('tabValue')
      : '1';
  const navigate = useNavigate();
  const [showScript, setShowScript] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddPlan, setopenAddPlan] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [value, setValue] = useState(tabValue);
  const [openAddunitModal, setOpenAddunitModal] = useState(false);
  const [specificationList, setSpecificationList] = useState([]);
  const [openAddProperty, setOpenAddProperty] = useState(false);
  const [openAssignSpecification, setAssignSpecification] = useState(false);
  const [addSpecification, setAddSpecification] = useState(false);
  const [openShowFields, setOpenShowFields] = useState(false);
  const [openAssignInventoryModal, setOpenAssignInventoryModal] =
    useState(false);
  const [openAddMapModal, setOpenAddMapModal] = useState(false);
  const [remProp, setRemProp] = useState(false);
  const {
    setFiles,
    setFilesUrl,
    organization,
    userFromOrganization,
  } = useContext(UserContext);
  const [fetch, setfetch] = useState(false);
  const [propLoad, setPropLoad] = useState(false);
  const [propertyData, setPropertyData] = useState(null);
  const [allItems, setItems] = useState([]);
  const [allGroups, setGroups] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [inventorySelection, setInventorySelection] = useState([]);
  const [templateConfig, setTemplateConfig] = useState(null);
  const [loadInventory, setLoadInventory] = useState(false);
  const [deletingProperty, setDeletingProperty] = useState(false);
  const [creatingMap, setCreatingMap] = useState(false);

  const [maps, setMaps] = useState([]);
  const [loadingMap, setLoadingMap] = useState(false);
  const [openIntegrationModal, setOpenIntegrationModal] = useState(false);

  const [integrateUpdates, setIntegrateUpdates] = useState(false);
  const [openAddUpdates, setOpenAddUpdates] = useState(false);
  const [timelineList, setTimeLineList] = useState([]);

  const handleAddUpdates = () => {
    setOpenAddUpdates(!openAddUpdates);
  };

  const [timelinePreview, setTimelinePreview] = useState(false);

  const handleTimelinePreview = () => {
    if (timelineList?.length > 0) {
      setTimelinePreview(!timelinePreview);
    } else {
      enqueueSnackbar('Please add an update first');
    }
  };

  const handleIntegrationUpdates = () => {
    setIntegrateUpdates(!integrateUpdates);
  };

  const handleModal = () => {
    setOpenIntegrationModal(!openIntegrationModal);
  };

  const arr1 = [
    { title: 'Properties', url: '/properties' },
    { title: propertyData?.name, url: `/properties/${id}` },
  ];

  const newProp = properties?.map((val, index) => {
    return { id: index + 1, ...val };
  });
  const fetchSpecs = async () => {
    const response = await apiService.get(`property/prop/${id}/get_specs/`);
    const data = await response.data;
    return data;
  };
  const fetchProperty = async () => {
    setPropLoad(true);
    apiService
      .get(`property/prop/${id}/`)
      .then(async (res) => {
        localStorage.setItem('tabValue', '1');
        const specData = await fetchSpecs();
        localStorage.setItem('property_name', JSON.stringify(res?.data?.name));
        if (specData?.output_specs?.groups) {
          const newData = [...specData?.output_specs?.groups];

          const updateSpecsGroups = newData?.map((e) => {
            const findGroup = specData?.specs?.groups?.find((f) => {
              return f?.grp_id === e?.grp_id;
            });
            console.log('from itemNameg', findGroup);
            return {
              grp_id: e?.grp_id,
              items: e?.items?.map((f) => {
                const findItem = findGroup?.items?.find((g) => {
                  return g?.item_id === f?.item_id;
                });
                console.log('from itemName', findItem);
                return {
                  ...f,
                  [f?.item?.name]: findItem?.item_value,
                };
              }),
            };
          });

          let newArray = [...updateSpecsGroups];
          console.log(
            'from updated data',
            updateSpecsGroups,
            res?.data?.output_specs,
          );
          setPropertyData({
            ...res?.data,
            image_list: res?.data?.image_list?.map((e) => {
              return {
                ...e,
                tags: typeof e?.tags === 'string' ? JSON.parse(e?.tags) : [],
              };
            }),
            output_specs: specData?.output_specs || {},
            specs:
              {
                template_id: specData?.specs?.template_id,
                groups: newArray,
              } || {},
          });
          setPropLoad(false);
        } else {
          setPropertyData({
            ...res?.data,
            image_list: res?.data?.image_list?.map((e) => {
              return {
                ...e,
                tags: typeof e?.tags === 'string' ? JSON.parse(e?.tags) : [],
              };
            }),
          });
          setPropLoad(false);
        }
      })
      .catch((error) => {
        setPropLoad(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };
  const getTemplates = () => {
    apiService
      .get('specs/template/')
      .then((res) => {
        setTemplates(res.data);
        console.log(res);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };
  const getItems = () => {
    apiService
      .get('specs/item/')
      .then((res) => {
        setItems(res.data);
        console.log(res);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };
  const getGroups = () => {
    apiService
      .get('specs/group/')
      .then((res) => {
        setGroups(res.data);
        console.log(res);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  const fetchInventory = () => {
    setLoadInventory(true);
    apiService
      .get(`property/unit/?property=${id}`)
      .then((res) => {
        const listUpdated = res?.data.map((inventory) => ({
          ...inventory,
          booking_unit: inventory.booking_unit.filter(
            (unit) => unit.booking_status === 'confirmed',
          ),
        }));
        const list = [...listUpdated]?.sort((a, b) =>
          a.modified < b.modified ? 1 : b.modified < a.modified ? -1 : 0,
        );
        setInventoryList(list);
        setLoadInventory(false);
      })
      .catch((error) => {
        setLoadInventory(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  useEffect(() => {
    fetchSpecs();
    getTemplates();
    getGroups();
    getItems();
    fetchProperty();
  }, [fetch]);

  const removeProperty = () => {
    setDeletingProperty(true);
    apiService
      .delete(`property/prop/${id}`)
      .then((res) => {
        setRemProp(false);
        setDeletingProperty(false);
        navigate('/properties');
      })
      .catch((error) => {
        setRemProp(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setDeletingProperty(false);
          }
        }
      });
  };

  const getTemplateConfig = () => {
    apiService
      .get('specs/template-config/')
      .then((res) => {
        setTemplateConfig(res.data);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  useEffect(() => {
    getTemplateConfig();
  }, []);

  const masonarydata = [
    {
      header: 'Interior',
      data: [
        { name: 'Living Area', value: '282 m2' },
        { name: 'Living Room Surface', value: '20 m2' },
        { name: 'Bedrooms', value: '5' },
        { name: 'Bathrooms', value: '3' },
        { name: 'Furnished', value: 'No' },
      ],
    },
    {
      header: 'Exterior',
      data: [
        { name: 'Terrace Surface', value: '15 m2' },
        { name: 'Garden Surface', value: '38 m2' },
        { name: 'Gas', value: 'Yes' },
        { name: 'Water 24x7', value: 'Yes' },
        { name: 'Electricity 24x7', value: 'Yes' },
      ],
    },
    {
      header: 'Financial',
      data: [
        { name: 'Price', value: '$ 2,900,000' },
        { name: 'Cadastral Income', value: '$ 2,900' },
        { name: 'Tenement Building', value: 'Yes' },
      ],
    },
    {
      header: 'Financial',
      data: [
        { name: 'Price', value: '$ 2,900,000' },
        { name: 'Cadastral Income', value: '$ 2,900' },
        { name: 'Tenement Building', value: 'Yes' },
      ],
    },
    {
      header: 'Financial',
      data: [
        { name: 'Price', value: '$ 2,900,000' },
        { name: 'Cadastral Income', value: '$ 2,900' },
        { name: 'Tenement Building', value: 'Yes' },
      ],
    },
  ];
  const closeMapform = () => {
    setOpenAddMapModal(false);
    setFiles([]);
  };

  const updateMasonaryData = (newData) => {
    if (newData?.output_specs) {
      const data = { ...newData.specs };
      delete data?.template_id;
      delete data?.template_name;
      const getDataWithHeader = data?.groups?.map((e) => {
        return {
          header: newData?.output_specs?.groups?.find(
            (f) => f.grp_id === e?.grp_id,
          )?.group?.name
            ? newData?.output_specs?.groups?.find((f) => f.grp_id === e?.grp_id)
                ?.group?.name
            : allGroups?.find((f) => f.id === e?.grp_id)?.name,
          data: e?.items?.map((f) => {
            console.log('from lables', f);
            delete f?.item_id;
            delete f?.id;
            return {
              name: f?.item?.name,
              value: Object?.entries(f)[0][1]?.id
                ? Object?.entries(f)[0][1]?.id
                : Object?.entries(f)[0][1],
            };
          }),
        };
      });
      return getDataWithHeader;
    } else {
      return [];
    }
  };

  const getMapLists = () => {
    setLoadingMap(true);
    apiService
      .get(`property/geomap/`)
      .then((res) => {
        console.log(res);
        if (res?.data) {
          const filterMaps = [...res?.data]?.filter((e) => {
            return e?.linked_property === Number(id);
          });
          setMaps(filterMaps);
        }
        setLoadingMap(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoadingMap(false);
          }
        }
      });
  };

  const createMap = () => {
    setCreatingMap(true);
    apiService
      .post(`property/geomap/`, {
        title: 'Untitled',
        linked_property: id,
        organization: organization?.id,
      })
      .then((res) => {
        if (res?.data) {
          localStorage.setItem(
            'Map_created_open_and_show_maps',
            JSON.stringify(propertyData),
          );
          enqueueSnackbar('Map Added', { variant: 'success' });
          setTimeout(() => {
            navigate(`/map/${res?.data?.id}`, {});
          }, 1500);
        }
        setCreatingMap(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setCreatingMap(false);
          }
        }
      });
  };

  const tabs = [
    {
      label: 'Overview',
      value: '1',
      page: (
        <OverviewPage
          load={propLoad}
          propertyData={propertyData}
          handleModal={handleModal}
          fetchProperty={fetchProperty}
          openIntegrationModal={openIntegrationModal}
          userFromOrganization={userFromOrganization}
        />
      ),
    },
    // {label:'Master Plan',value:'2',page:<MasterPlan /> },
    {
      label: 'Specification',
      value: '2',
      page: (
        <MasonryWithToogle masonarydata={updateMasonaryData(propertyData)} />
      ),
    },
    {
      label: 'Inventory',
      value: '3',
      page: (
        <Inventory
          fetch={fetch}
          fetchInventory={fetchInventory}
          inventoryList={inventoryList}
          inventorySelection={inventorySelection}
          setInventorySelection={setInventorySelection}
          loadInventory={loadInventory}
          fetchProperty={fetchProperty}
          setInventoryList={setInventoryList}
          propertyDetails={propertyData}
          userFromOrganization={userFromOrganization}
        />
      ),
    },
    {
      label: 'Plan',
      value: '4',
      page: (
        <MapContainer
          template={template}
          id={id}
          closeMapform={closeMapform}
          openAddMapModal={openAddMapModal}
          propertyData={propertyData}
          setOpenAddMapModal={setOpenAddMapModal}
          userFromOrganization={userFromOrganization}
        />
      ),
    },
    {
      label: 'Map',
      value: '5',
      page: (
        <MapsList
          maps={maps}
          setMaps={setMaps}
          loading={loadingMap}
          getMapLists={getMapLists}
          userFromOrganization={userFromOrganization}
        />
      ),
    },
    {
      label: 'Updates',
      value: '7',
      page: (
        <Updates
          timelineList={timelineList}
          setTimeLineList={setTimeLineList}
          openAddUpdates={openAddUpdates}
          handleAddUpdates={handleAddUpdates}
          integrateUpdates={integrateUpdates}
          openTimelinePreview={timelinePreview}
          handleTimelinePreview={handleTimelinePreview}
          handleIntegrationUpdates={handleIntegrationUpdates}
          userFromOrganization={userFromOrganization}
        />
      ),
    },
    {
      label: 'Settings',
      value: '6',
      page: (
        <PropertySettings
          templates={templates}
          groups={allGroups}
          items={allItems}
          propertyData={propertyData}
          selectedTemplate={templateConfig}
          fetchProperty={fetchProperty}
          setPropertyData={setPropertyData}
          userFromOrganization={userFromOrganization}
        />
      ),
    },
  ];
  function handelEditProperty() {
    setOpenEditModal(!openEditModal);
  }
  function handelAddPlan() {
    setopenAddPlan(!openAddPlan);
  }
  function handelAddunitModal() {
    if (
      templates?.length === 0 ||
      allGroups?.length === 0 ||
      allItems?.length === 0
    ) {
      enqueueSnackbar('Please update the specification settings.', {
        variant: 'default',
      });
    } else {
      setFiles([]);
      setFilesUrl([]);
      setOpenAddunitModal(!openAddunitModal);
    }
  }
  const handleChange = (event, newValue) => {
    localStorage.setItem('tabValue', JSON.stringify(newValue));
    setValue(newValue);
  };
  function handelAddProperty() {
    setOpenAddProperty(!openAddProperty);
  }
  function handelAssignModal() {
    if (inventorySelection?.length === 0) {
      enqueueSnackbar('Please select the units to assign the specifications.', {
        variant: 'default',
      });
    } else {
      setAssignSpecification(!openAssignSpecification);
    }
  }
  function handelAddSpecification() {
    if (
      templates?.length === 0 ||
      allGroups?.length === 0 ||
      allItems?.length === 0
    ) {
      enqueueSnackbar('Please update the specification settings.', {
        variant: 'default',
      });
    } else {
      setAddSpecification(!addSpecification);
    }
  }
  function handelShowFields() {
    setOpenShowFields(!openShowFields);
  }
  function handelAssignInventoryModal() {
    setOpenAssignInventoryModal(!openAssignInventoryModal);
  }
  function handelAddMap() {
    setOpenAddMapModal(!openAddMapModal);
  }

  const specSubmit = (data) => {
    console.log(data);
  };

  const RemoveProperty = () => {
    setRemProp(true);
  };

  const RemoveCloseProperty = () => {
    setRemProp(false);
  };

  const updateGroups = () => {
    const getTemplates = templates?.map((e) => {
      return {
        ...e,
        groups: allGroups?.filter((val) => {
          const checkId = val?.templates?.some((s) => {
            return s === e?.id;
          });
          if (checkId === true) {
            return val;
          }
        }),
      };
    });
    const updateTemplatesWithGroups = getTemplates?.map((t) => {
      return {
        ...t,
        groups: t?.groups?.map((e) => {
          return {
            ...e,
            items: allItems?.filter((f) => {
              return f?.groups?.some((g) => g === e?.id);
            }),
          };
        }),
      };
    });
    return updateTemplatesWithGroups;
  };

  console.log(updateGroups());

  function a() {
    const updateItemsInTemplate = getTemplates?.map((e) => {
      return {
        ...e,
        groups: e?.groups?.map((val) => {
          return {
            ...val,
            items: allItems?.filter((d) => {
              const checkId = d?.groups?.some((s) => {
                return s === val?.id;
              });
              if (checkId === true) {
                return d;
              }
            }),
          };
        }),
      };
    });

    return updateItemsInTemplate;
  }

  const sampleData = {
    id: 13,
    image_list: [
      {
        id: 47,
        image: 'inventory_images/Screenshot_20231220-121055_mRX5XKV.png',
      },
    ],
    name: 'New Property',
    description: 'New Property',
  };
  const modifyData = (data) => {
    const newData = {
      ...data,
      property_name: data?.name,
    };
    return newData;
  };

  const modifySpecData = (data) => {
    const updatedTempGroups = updateGroups();

    let newData = {};
    if (data?.output_specs?.template_id) {
      const findTemplate = updatedTempGroups?.find((e) => {
        return e?.id === data?.output_specs?.template_id;
      });
      const originalSpecsData = data?.specs?.groups?.map((e) => {
        return {
          ...e,
          items: e?.items?.map((f) => {
            return {
              item_id: f?.item?.id,
              [f?.item?.name]:
                f?.item?.type === 'TEXTFIELD'
                  ? f?.item_value
                  : { title: f?.item_value, id: f?.item_value },
            };
          }),
        };
      });
      const newDa = findTemplate?.groups?.map((e) => {
        const findGroup = originalSpecsData?.find((f) => {
          return f?.grp_id === e?.id;
        });
        return {
          grp_id: e?.id,
          items: e?.items?.map((f) => {
            const checkItem = findGroup?.items?.some((g) => {
              return g?.item_id === f?.id;
            });
            if (checkItem === true) {
              const findItem = findGroup?.items?.find((g) => {
                return g?.item_id === f?.id;
              });
              return findItem;
            } else {
              return {
                item_id: f?.id,
                [f?.name]: '',
              };
            }
          }),
        };
      });
      newData = {
        template_id: data?.specs?.template_id,
        groups: newDa,
      };
    } else {
      newData = {
        ...data?.specs,
      };
    }
    console.log('from nnnnnnnnn', newData, data);
    return newData;
  };

  const modifyPropertyData = (data) => {
    const updatedTempGroups = updateGroups();
    console.log('from nnnnnnnnn', updatedTempGroups);

    let newData = {};
    if (data?.output_specs?.template_id) {
      const findTemplate = updatedTempGroups?.find((e) => {
        return e?.id === data?.output_specs?.template_id;
      });

      const originalSpecsData = data?.specs?.groups?.map((e) => {
        return {
          ...e,
          items: e?.items?.map((f) => {
            return {
              item_id: f?.item?.id,
              [f?.item?.name]:
                f?.item?.type === 'TEXTFIELD'
                  ? f?.item_value
                  : { title: f?.item_value, id: f?.item_value },
            };
          }),
        };
      });

      const newDa = findTemplate?.groups?.map((e) => {
        const findGroup = originalSpecsData?.find((f) => {
          return f?.grp_id === e?.id;
        });
        return {
          grp_id: e?.id,
          items: e?.items?.map((f) => {
            const checkItem = findGroup?.items?.some((g) => {
              return g?.item_id === f?.id;
            });
            console.log('from ddaaaaaa', checkItem);
            if (checkItem === true) {
              const findItem = findGroup?.items?.find((g) => {
                return g?.item_id === f?.id;
              });
              return findItem;
            } else {
              return {
                item_id: f?.id,
                [f?.name]: '',
              };
            }
          }),
        };
      });
      newData = {
        template_id: data?.specs?.template_id,
        groups: newDa,
      };
    }
    console.log('from price data', {
      ...data,
      price: data?.price ? data?.price : '',
      specs: newData,
    });
    return {
      ...data,
      price: data?.price ? Number(data?.price).toFixed(0).toString() : '',
      specs: newData,
    };
  };

  const showIntegrateScript = () => setShowScript(true);
  const closeIntegrateScript = () => setShowScript(false);

  return (
    <>
      <Box
        className="w-full flex justify-between items-center"
        sx={{
          width: '100%',
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          position: 'sticky',
          top: '65px',
          backgroundColor: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={arr1} />

        {userFromOrganization?.role?.name?.toLowerCase() === 'admin' ||
        userFromOrganization?.role?.name?.toLowerCase() === 'developer' ? (
          <div
            className="flex items-center"
            style={{
              marginLeft: '20px',
            }}
          >
            {value === '2' ? (
              <>
                {!propertyData?.specs?.template_id ? (
                  <PrimaryBtn
                    style={{
                      boxShadow: 'none',
                      marginRight: '0px !important',
                      fontWeight: 300,
                    }}
                    title="Add"
                    onClick={handelAddSpecification}
                  />
                ) : (
                  <>
                    <SecondaryBtn
                      title="Integrate"
                      onClick={showIntegrateScript}
                      style={{
                        fontWeight: 400,
                      }}
                    />
                    {showScript && (
                      <Integration
                        open={showIntegrateScript}
                        onClose={closeIntegrateScript}
                        text={renderSpecScript(propertyData.id, BASE_URI)}
                      />
                    )}
                    <PrimaryBtn
                      style={{
                        boxShadow: 'none',
                        marginRight: '0px !important',
                        fontWeight: 300,
                      }}
                      title="Edit"
                      disabled={
                        allGroups?.length === 0 ||
                        allItems?.length === 0 ||
                        templates?.length === 0
                      }
                      onClick={handelAddSpecification}
                    />
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {value === '3' ? (
              <div style={{ display: 'flex' }}>
                <PrimaryBtn
                  title="Assign Specifications"
                  onClick={handelAssignModal}
                  disabled={inventorySelection?.length === 0}
                  style={{
                    boxShadow: 'none',
                    fontWeight: 300,
                  }}
                />
                <PrimaryBtn
                  style={{
                    boxShadow: 'none',
                    marginRight: '0px !important',
                    fontWeight: 300,
                  }}
                  title="Add New"
                  onClick={handelAddunitModal}
                />
              </div>
            ) : (
              <></>
            )}
            {value === '4' ? (
              <>
                <PrimaryBtn
                  style={{
                    boxShadow: 'none',
                    marginRight: '0px !important',
                    fontWeight: 300,
                  }}
                  title="Add New Plan"
                  onClick={handelAddMap}
                />
              </>
            ) : (
              <></>
            )}
            {value === '1' ? (
              <>
                <SecondaryBtn
                  style={{ boxShadow: 'none', fontWeight: 300 }}
                  title="Integrate"
                  onClick={handleModal}
                />
                <ErrorBtn
                  style={{ boxShadow: 'none', fontWeight: 300 }}
                  title="Remove"
                  onClick={RemoveProperty}
                />
                <PrimaryBtn
                  style={{
                    boxShadow: 'none',
                    marginRight: '0px !important',
                    fontWeight: 300,
                  }}
                  title="Edit"
                  onClick={handelEditProperty}
                />
                <></>
              </>
            ) : (
              <></>
            )}
            {value === '5' ? (
              <>
                <PrimaryBtn
                  style={{
                    boxShadow: 'none',
                    marginRight: '0px !important',
                    fontWeight: 300,
                  }}
                  title="Add New Map"
                  disabled={creatingMap}
                  onClick={createMap}
                />
              </>
            ) : (
              <></>
            )}
            {value === '7' ? (
              <>
                <SecondaryBtn
                  style={{
                    boxShadow: 'none',
                    fontWeight: 300,
                  }}
                  title="Integrate"
                  onClick={handleIntegrationUpdates}
                />

                <SecondaryBtn
                  style={{
                    boxShadow: 'none',
                    fontWeight: 300,
                  }}
                  title="Preview"
                  onClick={handleTimelinePreview}
                />

                <PrimaryBtn
                  style={{
                    boxShadow: 'none',
                    marginRight: '0px !important',
                    fontWeight: 300,
                  }}
                  title="Add"
                  onClick={handleAddUpdates}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div
            className="flex items-center"
            style={{
              marginLeft: '20px',
            }}
          >
            {value === '2' ? (
              <>
                {!propertyData?.specs?.template_id ? (
                  <></>
                ) : (
                  <>
                    <SecondaryBtn
                      title="Integrate"
                      onClick={showIntegrateScript}
                      style={{
                        fontWeight: 400,
                      }}
                    />
                    {showScript && (
                      <Integration
                        open={showIntegrateScript}
                        onClose={closeIntegrateScript}
                        text={renderSpecScript(propertyData.id, BASE_URI)}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
            {value === '3' ? <div style={{ display: 'flex' }}></div> : <></>}
            {value === '4' ? <></> : <></>}
            {value === '1' ? (
              <>
                <SecondaryBtn
                  style={{ boxShadow: 'none', fontWeight: 300 }}
                  title="Integrate"
                  onClick={handleModal}
                />
                <></>
              </>
            ) : (
              <></>
            )}
            {value === '7' ? (
              <>
                <SecondaryBtn
                  style={{
                    boxShadow: 'none',
                    marginRight: '0px !important',
                    fontWeight: 300,
                  }}
                  title="Integrate"
                  onClick={handleIntegrationUpdates}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </Box>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          marginBottom: BoxEffects.marginButton,
        }}
      >
        {propLoad ? (
          <Loading />
        ) : (
          <LabTabs tabs={tabs} value={value} handleChange={handleChange} />
        )}
      </Box>
      {openEditModal && (
        <EditProperty
          title="Edit Property"
          setfetch={setfetch}
          data={modifyData(propertyData)}
          open={openEditModal}
          onClose={handelEditProperty}
          fetchProperty={fetchProperty}
        />
      )}
      {openAddunitModal && (
        <AddUnit
          title="Add Unit"
          open={openAddunitModal}
          onClose={handelAddunitModal}
          setFetch={setfetch}
          successCallback={fetchInventory}
          templates={templates}
          groups={allGroups}
          items={allItems}
          price={
            propertyData?.price
              ? propertyData?.price === '0.00'
                ? ''
                : propertyData?.price
              : ''
          }
          propertyData={
            propertyData?.specs?.template_id
              ? modifyPropertyData(propertyData)
              : {}
          }
          token_price={propertyData?.token_price
            ? propertyData?.token_price === '0.00'
              ? ''
              : propertyData?.token_price
            : ''}
          setInventoryList={setInventoryList}
          inveqntoryList={inventoryList}
          data={null}
        />
      )}
      {openAddPlan && (
        <AddPlan2 title="Add Plan" open={openAddPlan} onClose={handelAddPlan} />
      )}
      {openLinkModal && (
        <Link title="Link" open={openLinkModal} onClose={handelAddPlan} />
      )}
      {openAddProperty && (
        <AddProperty
          title="Add Property"
          open={openAddProperty}
          onClose={handelAddProperty}
          properties={newProp}
          data={modifyData(sampleData)}
        />
      )}
      {openAssignSpecification && (
        <AssignSpecification
          title="Assign Specifications"
          open={openAssignSpecification}
          onClose={handelAssignModal}
          submit={specSubmit}
          templates={templates}
          groups={allGroups}
          items={allItems}
          inventorySelection={inventorySelection}
          setInventorySelection={setInventorySelection}
          successCallback={fetchInventory}
          data={
            propertyData?.specs?.template_id ? modifySpecData(propertyData) : {}
          }
        />
      )}
      {addSpecification && (
        <AddSpecifications2
          title={
            propertyData?.specs?.template_id
              ? 'Edit Specification'
              : 'Add Specification'
          }
          open={addSpecification}
          onClose={handelAddSpecification}
          submit={specSubmit}
          templates={templates}
          groups={allGroups}
          items={allItems}
          successCallback={fetchProperty}
          data={
            propertyData?.specs?.template_id ? modifySpecData(propertyData) : {}
          }
        />
      )}
      {openShowFields && (
        <ShowFields
          title="Show Fields"
          open={openShowFields}
          onClose={handelShowFields}
        />
      )}
      {remProp && (
        <RemovePopup
          onSubmit={removeProperty}
          open={remProp}
          close={RemoveCloseProperty}
          loading={deletingProperty}
          description={`Are you sure you want to Remove this property?
                After removing, it cannot be restored`}
        />
      )}
    </>
  );
}
export default Overview;
