import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import ActionButton from '../../../shared-component/button-component/ActionButton';
import FilterFields from '../../leads/Fields/FilterFields';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons';

const UserLogsFilter = ({
    open,
    handleClose,
    handleFilter,
    buttonFields,
    setPropertySelected,
    setSelectedEvent,
    setSelectedUnit,
    selectedUnit,
    selectedEvent,
    propertySelected,
    onSubmit,
    setSelectedButton,
    selectedButton,
}) =>{
    const [unitAPI, setUnitAPI] = useState('')
    
    const findBy = [
        {name: "Impression", id: "IMPRESSION"},
        {name: "Interaction", id: "INTERACTION"},
        {name: "Engagement", id: "ENGAGEMENT"},
        {name: "Form Submitted", id: "USER_SUBMITTED"},
    ]
    const endButton = {
        id:8,
        label: 'Last 365 days',
        value: '365'
    }

    const onReset = () =>{
        setPropertySelected({name:'', value:'', id:''})
        setSelectedEvent({name:'', value:'', id:''})
        setSelectedUnit({name:'', value:'', id:''})
        setSelectedButton({
            id:3,
            label:'Last 7 days',
            value:'7',
        },)
    }
   console.log('from selected buttons', selectedButton)
    useEffect(() =>{
        if(propertySelected?.id){
            setUnitAPI(`/property/unit/?filtered_data=true&property=${propertySelected?.id}`)
        }else{
            setUnitAPI('noAPI')
        }
    }, [propertySelected])
    return (
        <FormModal 
          title="Filter"
          open={open}
          onClose={handleClose}
        >
          <div className='flex flex-col mx-8 my-4'>
                   
                     <FilterFields 
                      value={propertySelected}
                      setValue={setPropertySelected}
                      choicesAPI={'/property/prop/?filtered_data=true'}
                        label="Property"
                        placeholder="Property"
                        titleKey="name"
                   />
                      <FilterFields 
                      value={selectedUnit}
                      setValue={setSelectedUnit}
                      choicesAPI={unitAPI}
                        label="Unit"
                        placeholder="Unit"
                        message={'Please select a property first'}
                        titleKey="name"
                   />   
                   <FilterFields 
                      value={selectedEvent}
                      setValue={setSelectedEvent}
                      label="Action Taken"
                      placeholder="Action Taken"
                      defaultOptions={findBy}
                      titleKey="name"
                   />
                    <Grid className='!mt-2' container spacing={2} columns={16}>
            {buttonFields?.map((item, index) =>{
                return(
                    <Grid key={index} item xs={8} style={{ paddingTop: 0 }}>
                        <ActionButton
                        variant="contained"
                        color={selectedButton?.value === item.value? 'primary':'secondary'}
                        title={item.label || 'Secondary'}
                        onClick={() =>{
                            setSelectedButton(item)
                        }}
                        style={{marginRight:0, marginLeft:0, width:'100%', marginTop:'12px', marginButtom:'12px',padding:'20px'}}
                        />
                    </Grid>
                )
            })}
        </Grid>
            <ActionButton
            variant="contained"
            color={selectedButton.value === endButton?.value? 'primary':'secondary'}
            title={endButton.label || 'Secondary'}
            onClick={() =>{
                setSelectedButton(endButton)
            }}
            style={{marginRight:0, marginLeft:0, width:'100%', marginTop:'12px', marginButtom:'12px',padding:'20px'}}
            />
          </div>
          <div className="w-full flex justify-end px-8 py-8">
                    <SecondaryBtn style={{ 
                        backgroundColor: '#EDECF9',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px' }} onClick={handleClose} title='Cancel' />
                    <SecondaryBtn style={{ 
                        backgroundColor: '#EDECF9', 
                        color:'#FF5C5C',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px' }} onClick={onReset} title='Reset' />
                    <PrimaryBtn title='Filter' 
                    style={{
                        marginRight: '0px !important',
                        boxShadow: 'none', 
                        fontWeight:300,
                        padding:'6px 16px'
                    }} onClick={() =>{
                        onSubmit({
                            property: propertySelected,
                            unit: selectedUnit,
                            event: selectedEvent,
                            buttonSelection: selectedButton?.value
                        })
                    }}/>
                </div>
        </FormModal>
    )
}

export default UserLogsFilter