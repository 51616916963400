import { Button } from '@mui/material';

function ActionButton({
  onClick = () => {},
  title = '',
  toggle = {},
  color = 'primary',
  loading = false,
  icon = null,
  endIcon = null,
  dataTestId = '',
  disabled = false,
  variant = 'contained',
  type = 'button',
  style = {},
  className,
  exicon,
  labelClassName
}) {
  return (
    <>
      <Button
        className={`whitespace-nowrap flex mx-2 ${className}`}
        variant={variant}
        color={color}
        style={{ marginLeft: 8, marginRight: 8,boxShadow:'none', ...style }}
        disabled={disabled || loading}
        onClick={onClick}
        data-testid={dataTestId}
        sx={toggle || {}}
        type={type}
        startIcon={icon}
        endIcon={endIcon}
      >
        <div className="flex items-center justify-center" style={{ opacity: loading ? 0 : 1 }}>
          {icon && <div className="mr-4">{icon}</div>}
          <div className={`text-12 md:text-12 ${labelClassName}`} style={{
            fontWeight: 300,
          }}>{title}</div>
          {exicon && <div className="ml-4">{exicon}</div>}
        </div>
      </Button>
    </>
  );
}
export default ActionButton;