import React from 'react';

const DesktopView = ({
    color = '#E1EBFE'
}) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.8125 22.8438H29.2188V9.5625C29.2188 8.85802 28.9389 8.18239 28.4408 7.68425C27.9426 7.1861 27.267 6.90625 26.5625 6.90625H7.4375C6.73302 6.90625 6.05739 7.1861 5.55925 7.68425C5.0611 8.18239 4.78125 8.85802 4.78125 9.5625V22.8438H3.1875C3.0466 22.8438 2.91148 22.8997 2.81185 22.9993C2.71222 23.099 2.65625 23.2341 2.65625 23.375V25.5C2.65625 26.2045 2.9361 26.8801 3.43425 27.3783C3.93239 27.8764 4.60802 28.1562 5.3125 28.1562H28.6875C29.392 28.1562 30.0676 27.8764 30.5658 27.3783C31.0639 26.8801 31.3438 26.2045 31.3438 25.5V23.375C31.3438 23.2341 31.2878 23.099 31.1882 22.9993C31.0885 22.8997 30.9534 22.8438 30.8125 22.8438ZM5.84375 9.5625C5.84375 9.13981 6.01166 8.73443 6.31055 8.43555C6.60943 8.13666 7.01481 7.96875 7.4375 7.96875H26.5625C26.9852 7.96875 27.3906 8.13666 27.6895 8.43555C27.9883 8.73443 28.1562 9.13981 28.1562 9.5625V22.8438H5.84375V9.5625ZM30.2812 25.5C30.2812 25.9227 30.1133 26.3281 29.8145 26.627C29.5156 26.9258 29.1102 27.0938 28.6875 27.0938H5.3125C4.88981 27.0938 4.48443 26.9258 4.18555 26.627C3.88666 26.3281 3.71875 25.9227 3.71875 25.5V23.9062H30.2812V25.5ZM19.6562 11.6875C19.6562 11.8284 19.6003 11.9635 19.5007 12.0632C19.401 12.1628 19.2659 12.2188 19.125 12.2188H14.875C14.7341 12.2188 14.599 12.1628 14.4993 12.0632C14.3997 11.9635 14.3438 11.8284 14.3438 11.6875C14.3438 11.5466 14.3997 11.4115 14.4993 11.3118C14.599 11.2122 14.7341 11.1562 14.875 11.1562H19.125C19.2659 11.1562 19.401 11.2122 19.5007 11.3118C19.6003 11.4115 19.6562 11.5466 19.6562 11.6875Z"
        fill={color}
      />
    </svg>
  );
};

export default DesktopView;
