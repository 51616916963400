import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ToolTipComponent from "../Tooltip/ToolTipComponent";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const RadioButtonsGroup = ({
    name,
    fieldProps = {},
    tooltip,
}) => {
  return (
     <>
     <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
       {fieldProps?.options?.map((e) =>{
        return <FormControlLabel value={e?.id} control={<Radio />} label={e?.value} />
       })}
      </RadioGroup>
    </FormControl>
     {tooltip !== '' && (
      <ToolTipComponent title={tooltip} offset={[50, -50]}>
      <ErrorOutlineIcon color='#FFFFFF' className='!fill-[#8E8CA3]' fontSize='20' />
    </ToolTipComponent>
    )}
     </>
  );
}


export default RadioButtonsGroup