import React from 'react'

const Bot = () =>{
    return (
        <svg width="169" height="172" viewBox="0 0 169 172" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M88.7443 141.446H99.4986L140.769 117.585L104.54 96.2083L78.6469 110.432L88.7443 141.446Z" fill="#CFC4B8"/>
        <path d="M49.3006 108.441L51.225 107.323L53.8344 108.828L51.91 109.946L49.3006 108.441Z" fill="#E4E3EE"/>
        <path d="M54.2306 107.603L56.1503 106.485L54.184 109.7L52.2596 110.819L54.2306 107.603Z" fill="#B7B6E3"/>
        <path d="M58.7782 109.561L60.7027 108.447L60.698 109.113L58.7782 110.227V109.561Z" fill="#919DD6"/>
        <path d="M53.8764 106.732L55.8008 105.613L60.7027 108.447L58.7783 109.561L53.8764 106.732Z" fill="#E4E3EE"/>
        <path d="M58.7782 109.56V110.227L54.2305 107.603L52.2595 110.819L49.3006 109.108V108.442L51.91 109.947L53.8763 106.731L58.7782 109.56Z" fill="#919DD6"/>
        <path d="M56.4438 104.373L58.3682 103.254L60.9776 104.76L59.0531 105.878L56.4438 104.373Z" fill="#E4E3EE"/>
        <path d="M61.3738 103.534L63.2935 102.416L61.3272 105.631L59.4028 106.75L61.3738 103.534Z" fill="#B7B6E3"/>
        <path d="M65.9215 105.492L67.8459 104.373L67.8413 105.039L65.9215 106.158V105.492Z" fill="#919DD6"/>
        <path d="M61.0196 102.662L62.944 101.544L67.8459 104.373L65.9215 105.491L61.0196 102.662Z" fill="#E4E3EE"/>
        <path d="M65.9214 105.492V106.159L61.3736 103.535L59.4026 106.75L56.4438 105.04V104.374L59.0531 105.879L61.0195 102.663L65.9214 105.492Z" fill="#919DD6"/>
        <path d="M63.2841 100.296L65.2085 99.1772L67.8179 100.683L65.8935 101.801L63.2841 100.296Z" fill="#E4E3EE"/>
        <path d="M68.214 99.4569L70.1384 98.3384L68.1674 101.554L66.2476 102.673L68.214 99.4569Z" fill="#B7B6E3"/>
        <path d="M72.7617 101.419L74.6861 100.301V100.967L72.7617 102.085V101.419Z" fill="#919DD6"/>
        <path d="M67.8599 98.585L69.7843 97.4712L74.6862 100.3L72.7618 101.419L67.8599 98.585Z" fill="#E4E3EE"/>
        <path d="M72.7617 101.419V102.085L68.2139 99.4567L66.2476 102.672L63.2841 100.962V100.296L65.8935 101.801L67.8598 98.5852L72.7617 101.419Z" fill="#919DD6"/>
        <path d="M42.2413 112.865L44.1611 111.751L46.7705 113.256L44.8461 114.37L42.2413 112.865Z" fill="#E4E3EE"/>
        <path d="M47.1667 112.031L49.0911 110.912L47.1247 114.128L45.2003 115.246L47.1667 112.031Z" fill="#B7B6E3"/>
        <path d="M51.719 113.988L53.6387 112.869V113.536L51.7143 114.654L51.719 113.988Z" fill="#919DD6"/>
        <path d="M46.8172 111.159L48.7416 110.041L53.6388 112.869L51.719 113.988L46.8172 111.159Z" fill="#E4E3EE"/>
        <path d="M51.719 113.988L51.7143 114.654L47.1665 112.031L45.2002 115.246L42.2367 113.536L42.2414 112.865L44.8461 114.37L46.8171 111.159L51.719 113.988Z" fill="#919DD6"/>
        <path d="M70.8886 95.9996L72.813 94.8811L75.4224 96.3864L73.498 97.5049L70.8886 95.9996Z" fill="#E4E3EE"/>
        <path d="M75.8186 95.1602L77.743 94.0417L75.772 97.2574L73.8522 98.3759L75.8186 95.1602Z" fill="#B7B6E3"/>
        <path d="M80.3662 97.1177L82.2906 96.0039V96.6703L80.3662 97.7888V97.1177Z" fill="#919DD6"/>
        <path d="M75.4643 94.2889L77.3887 93.1704L82.2906 96.0039L80.3662 97.1177L75.4643 94.2889Z" fill="#E4E3EE"/>
        <path d="M80.3662 97.1169V97.788L75.8184 95.1596L73.8521 98.3752L70.8886 96.6649V95.9984L73.498 97.5037L75.4643 94.2881L80.3662 97.1169Z" fill="#919DD6"/>
        <path d="M78.1995 91.8468L80.124 90.7283L82.7287 92.2336L80.8089 93.3521L78.1995 91.8468Z" fill="#E4E3EE"/>
        <path d="M83.1294 91.0071L85.0492 89.8933L83.0828 93.109L81.1584 94.2228L83.1294 91.0071Z" fill="#B7B6E3"/>
        <path d="M87.6772 92.9696L89.6016 91.8511L89.5969 92.5175L87.6772 93.636V92.9696Z" fill="#919DD6"/>
        <path d="M82.7753 90.1405L84.6997 89.022L89.6016 91.8508L87.6771 92.9693L82.7753 90.1405Z" fill="#E4E3EE"/>
        <path d="M87.6772 92.9692V93.6357L83.1294 91.0072L81.1584 94.2229L78.1995 92.5125V91.8461L80.8089 93.3514L82.7753 90.1404L87.6772 92.9692Z" fill="#919DD6"/>
        <path d="M127.587 108.54L125.662 107.421L123.057 108.926L124.977 110.045L127.587 108.54Z" fill="#E4E3EE"/>
        <path d="M122.661 107.7L120.737 106.582L122.703 109.797L124.628 110.916L122.661 107.7Z" fill="#B7B6E3"/>
        <path d="M118.109 109.662L116.189 108.544V109.21L118.114 110.329L118.109 109.662Z" fill="#919DD6"/>
        <path d="M123.011 106.829L121.086 105.716L116.189 108.544L118.109 109.663L123.011 106.829Z" fill="#E4E3EE"/>
        <path d="M118.109 109.662L118.114 110.329L122.661 107.7L124.628 110.916L127.591 109.206L127.587 108.539L124.977 110.045L123.011 106.829L118.109 109.662Z" fill="#919DD6"/>
        <path d="M120.443 104.471L118.519 103.353L115.914 104.858L117.834 105.977L120.443 104.471Z" fill="#E4E3EE"/>
        <path d="M115.518 103.633L113.594 102.514L115.56 105.73L117.485 106.848L115.518 103.633Z" fill="#B7B6E3"/>
        <path d="M110.966 105.594L109.046 104.476V105.142L110.97 106.261L110.966 105.594Z" fill="#919DD6"/>
        <path d="M115.868 102.761L113.943 101.647L109.046 104.476L110.966 105.595L115.868 102.761Z" fill="#E4E3EE"/>
        <path d="M110.966 105.595L110.97 106.261L115.518 103.633L117.485 106.848L120.448 105.138L120.443 104.472L117.834 105.977L115.868 102.761L110.966 105.595Z" fill="#919DD6"/>
        <path d="M113.603 100.393L111.679 99.2793L109.074 100.785L110.994 101.903L113.603 100.393Z" fill="#E4E3EE"/>
        <path d="M108.673 99.5594L106.753 98.4409L108.72 101.657L110.644 102.775L108.673 99.5594Z" fill="#B7B6E3"/>
        <path d="M104.125 101.516L102.201 100.398L102.206 101.064L104.125 102.183V101.516Z" fill="#919DD6"/>
        <path d="M109.027 98.6873L107.103 97.5688L102.201 100.398L104.126 101.516L109.027 98.6873Z" fill="#E4E3EE"/>
        <path d="M104.126 101.517V102.183L108.673 99.5595L110.644 102.775L113.603 101.065V100.394L110.994 101.904L109.027 98.688L104.126 101.517Z" fill="#919DD6"/>
        <path d="M134.651 112.967L132.726 111.849L130.117 113.354L132.041 114.473L134.651 112.967Z" fill="#E4E3EE"/>
        <path d="M129.721 112.128L127.796 111.01L129.767 114.225L131.687 115.344L129.721 112.128Z" fill="#B7B6E3"/>
        <path d="M125.173 114.086L123.248 112.972V113.638L125.173 114.757V114.086Z" fill="#919DD6"/>
        <path d="M130.075 111.257L128.15 110.138L123.249 112.972L125.173 114.086L130.075 111.257Z" fill="#E4E3EE"/>
        <path d="M125.173 114.086V114.757L129.721 112.128L131.687 115.344L134.651 113.634V112.967L132.041 114.472L130.075 111.257L125.173 114.086Z" fill="#919DD6"/>
        <path d="M105.999 96.0968L104.074 94.9783L101.465 96.4836L103.389 97.602L105.999 96.0968Z" fill="#E4E3EE"/>
        <path d="M101.069 95.2576L99.1491 94.1438L101.115 97.3595L103.04 98.4733L101.069 95.2576Z" fill="#B7B6E3"/>
        <path d="M96.5211 97.2196L94.5966 96.1011L94.6013 96.7675L96.5211 97.886V97.2196Z" fill="#919DD6"/>
        <path d="M101.423 94.3909L99.4986 93.2725L94.5967 96.1013L96.5211 97.2198L101.423 94.3909Z" fill="#E4E3EE"/>
        <path d="M96.521 97.2197V97.8861L101.069 95.2577L103.04 98.4733L105.999 96.763V96.0966L103.389 97.6019L101.423 94.3909L96.521 97.2197Z" fill="#919DD6"/>
        <path d="M98.6879 91.9488L96.7681 90.8303L94.1587 92.3356L96.0832 93.4541L98.6879 91.9488Z" fill="#E4E3EE"/>
        <path d="M93.7626 91.1097L91.8382 89.9912L93.8046 93.2069L95.729 94.3253L93.7626 91.1097Z" fill="#B7B6E3"/>
        <path d="M89.2102 93.067L87.2905 91.9485V92.6196L89.2149 93.7334L89.2102 93.067Z" fill="#919DD6"/>
        <path d="M94.1121 90.2386L92.1923 89.1201L87.2904 91.949L89.2102 93.0674L94.1121 90.2386Z" fill="#E4E3EE"/>
        <path d="M89.2103 93.0671L89.2149 93.7336L93.7627 91.1098L95.729 94.3254L98.6925 92.6151L98.6879 91.9486L96.0832 93.4539L94.1121 90.2383L89.2103 93.0671Z" fill="#919DD6"/>
        <path d="M129.977 112.888L129.963 117.492L88.7489 141.447L88.7629 136.842L129.977 112.888Z" fill="#12B0F3"/>
        <path d="M88.7629 136.842L88.7489 141.447L47.2646 117.492L47.2786 112.888L88.7629 136.842Z" fill="#1393F2"/>
        <path d="M129.977 112.888L88.7629 136.843L47.2786 112.888L88.4927 88.9341L129.977 112.888Z" fill="#05D4F5"/>
        <path d="M120.099 111.718L120.094 114.02L88.7211 132.256L88.7257 129.954L120.099 111.718Z" fill="#D4D5F0"/>
        <path d="M88.7258 129.954L88.7212 132.256L57.1431 114.02L57.1477 111.718L88.7258 129.954Z" fill="#C4C3EC"/>
        <path d="M120.099 111.718L88.7258 129.954L57.1478 111.718L88.5208 93.4868L120.099 111.718Z" fill="white"/>
        <path d="M43.8957 124.768L41.9713 123.649L43.9143 118.178L45.8387 119.296L43.8957 124.768Z" fill="#C4C3EC"/>
        <path d="M45.8388 119.296L43.9143 118.177L48.8162 115.349L50.736 116.462L45.8388 119.296Z" fill="#E4E3EE"/>
        <path d="M41.2863 126.273L39.3619 125.159L41.9713 123.649L43.8957 124.767L41.2863 126.273Z" fill="#E4E3EE"/>
        <path d="M41.2863 126.944L39.3619 125.825V125.159L41.2863 126.273V126.944Z" fill="#B7B6E3"/>
        <path d="M50.7359 116.463L50.7406 117.134L46.1928 119.758L44.2498 125.234L41.2863 126.944V126.273L43.8956 124.768L45.8387 119.296L50.7359 116.463Z" fill="#919DD6"/>
        <path d="M51.1227 128.905L49.2029 127.787L51.1459 122.316L53.0657 123.434L51.1227 128.905Z" fill="#C4C3EC"/>
        <path d="M53.0656 123.434L51.1459 122.316L56.0478 119.487L57.9675 120.606L53.0656 123.434Z" fill="#E4E3EE"/>
        <path d="M48.5132 130.416L46.5934 129.297L49.2028 127.787L51.1226 128.906L48.5132 130.416Z" fill="#E4E3EE"/>
        <path d="M48.5178 131.083L46.5934 129.964V129.298L48.5132 130.416L48.5178 131.083Z" fill="#B7B6E3"/>
        <path d="M57.9676 120.605L57.9723 121.272L53.4199 123.896L51.4815 129.372L48.518 131.082L48.5133 130.416L51.1227 128.906L53.0657 123.434L57.9676 120.605Z" fill="#919DD6"/>
        <path d="M58.2984 133.091L56.374 131.973L58.317 126.497L60.2414 127.615L58.2984 133.091Z" fill="#C4C3EC"/>
        <path d="M60.2415 127.615L58.317 126.497L63.2189 123.668L65.1434 124.786L60.2415 127.615Z" fill="#E4E3EE"/>
        <path d="M55.689 134.596L53.7646 133.478L56.374 131.973L58.2984 133.091L55.689 134.596Z" fill="#E4E3EE"/>
        <path d="M55.689 135.263L53.7693 134.144L53.7646 133.478L55.689 134.596V135.263Z" fill="#B7B6E3"/>
        <path d="M65.1433 124.786V125.453L60.5955 128.081L58.6525 133.553L55.6889 135.263V134.596L58.2983 133.091L60.2414 127.615L65.1433 124.786Z" fill="#919DD6"/>
        <path d="M65.4697 137.243L63.5453 136.125L65.4883 130.649L67.4127 131.767L65.4697 137.243Z" fill="#C4C3EC"/>
        <path d="M67.4127 131.767L65.4883 130.649L70.3902 127.82L72.3146 128.939L67.4127 131.767Z" fill="#E4E3EE"/>
        <path d="M62.8603 138.748L60.9359 137.629L63.5453 136.124L65.4697 137.243L62.8603 138.748Z" fill="#E4E3EE"/>
        <path d="M62.8603 139.415L60.9359 138.296V137.63L62.8603 138.748V139.415Z" fill="#B7B6E3"/>
        <path d="M72.3145 128.938V129.605L67.7668 132.233L65.8237 137.704L62.8602 139.415V138.748L65.4696 137.243L67.4126 131.767L72.3145 128.938Z" fill="#919DD6"/>
        <path d="M72.7292 141.363L70.8048 140.244L72.7478 134.773L74.6722 135.892L72.7292 141.363Z" fill="#C4C3EC"/>
        <path d="M74.6723 135.892L72.7478 134.773L77.6497 131.945L79.5742 133.063L74.6723 135.892Z" fill="#E4E3EE"/>
        <path d="M70.1198 142.873L68.1954 141.755L70.8048 140.245L72.7292 141.363L70.1198 142.873Z" fill="#E4E3EE"/>
        <path d="M70.1245 143.539L68.2001 142.421L68.1954 141.754L70.1198 142.873L70.1245 143.539Z" fill="#B7B6E3"/>
        <path d="M79.5742 133.062L79.5788 133.729L75.0264 136.353L73.0834 141.829L70.1245 143.539L70.1199 142.873L72.7292 141.363L74.6723 135.891L79.5742 133.062Z" fill="#919DD6"/>
        <path d="M79.8538 145.524L77.9293 144.405L79.8724 138.929L81.7968 140.048L79.8538 145.524Z" fill="#C4C3EC"/>
        <path d="M81.7968 140.049L79.8724 138.93L84.7743 136.101L86.6987 137.22L81.7968 140.049Z" fill="#E4E3EE"/>
        <path d="M77.2444 147.029L75.32 145.911L77.9294 144.406L79.8538 145.524L77.2444 147.029Z" fill="#E4E3EE"/>
        <path d="M77.2444 147.696L75.32 146.577V145.911L77.2444 147.029V147.696Z" fill="#B7B6E3"/>
        <path d="M86.6987 137.22V137.886L82.151 140.515L80.2079 145.986L77.2444 147.696V147.03L79.8538 145.524L81.7968 140.049L86.6987 137.22Z" fill="#919DD6"/>
        <path d="M133.505 124.841L135.429 123.727L133.486 118.251L131.561 119.37L133.505 124.841Z" fill="#C4C3EC"/>
        <path d="M131.561 119.371L133.486 118.252L128.584 115.423L126.659 116.542L131.561 119.371Z" fill="#E4E3EE"/>
        <path d="M136.114 126.352L138.034 125.233L135.429 123.728L133.505 124.842L136.114 126.352Z" fill="#E4E3EE"/>
        <path d="M136.109 127.018L138.034 125.899V125.233L136.114 126.351L136.109 127.018Z" fill="#B7B6E3"/>
        <path d="M126.66 116.541V117.207L131.207 119.831L133.15 125.307L136.109 127.017L136.114 126.351L133.505 124.841L131.561 119.37L126.66 116.541Z" fill="#919DD6"/>
        <path d="M126.273 128.984L128.197 127.866L126.254 122.39L124.33 123.508L126.273 128.984Z" fill="#C4C3EC"/>
        <path d="M124.33 123.509L126.254 122.39L121.352 119.562L119.428 120.68L124.33 123.509Z" fill="#E4E3EE"/>
        <path d="M128.882 130.49L130.806 129.371L128.197 127.866L126.273 128.984L128.882 130.49Z" fill="#E4E3EE"/>
        <path d="M128.882 131.156L130.802 130.038L130.806 129.371L128.882 130.49V131.156Z" fill="#B7B6E3"/>
        <path d="M119.428 120.68V121.346L123.975 123.975L125.918 129.446L128.882 131.156V130.49L126.273 128.984L124.33 123.509L119.428 120.68Z" fill="#919DD6"/>
        <path d="M119.102 133.165L121.021 132.047L119.078 126.576L117.159 127.694L119.102 133.165Z" fill="#C4C3EC"/>
        <path d="M117.159 127.693L119.078 126.575L114.177 123.741L112.257 124.86L117.159 127.693Z" fill="#E4E3EE"/>
        <path d="M121.711 134.67L123.631 133.552L121.021 132.046L119.102 133.165L121.711 134.67Z" fill="#E4E3EE"/>
        <path d="M121.707 135.337L123.631 134.223V133.552L121.711 134.67L121.707 135.337Z" fill="#B7B6E3"/>
        <path d="M112.257 124.86L112.252 125.527L116.805 128.155L118.743 133.626L121.707 135.337L121.711 134.67L119.102 133.165L117.159 127.694L112.257 124.86Z" fill="#919DD6"/>
        <path d="M111.931 137.317L113.85 136.199L111.907 130.728L109.988 131.841L111.931 137.317Z" fill="#C4C3EC"/>
        <path d="M109.988 131.841L111.907 130.727L107.006 127.894L105.086 129.012L109.988 131.841Z" fill="#E4E3EE"/>
        <path d="M114.54 138.823L116.46 137.704L113.85 136.199L111.931 137.317L114.54 138.823Z" fill="#E4E3EE"/>
        <path d="M114.535 139.489L116.46 138.37V137.704L114.54 138.822L114.535 139.489Z" fill="#B7B6E3"/>
        <path d="M105.086 129.012L105.081 129.679L109.633 132.307L111.572 137.779L114.535 139.489L114.54 138.823L111.931 137.317L109.988 131.841L105.086 129.012Z" fill="#919DD6"/>
        <path d="M104.666 141.441L106.591 140.323L104.648 134.847L102.723 135.965L104.666 141.441Z" fill="#C4C3EC"/>
        <path d="M102.723 135.966L104.648 134.847L99.7457 132.019L97.8213 133.137L102.723 135.966Z" fill="#E4E3EE"/>
        <path d="M107.276 142.947L109.2 141.828L106.591 140.323L104.666 141.441L107.276 142.947Z" fill="#E4E3EE"/>
        <path d="M107.276 143.614L109.2 142.495V141.829L107.276 142.947V143.614Z" fill="#B7B6E3"/>
        <path d="M97.8214 133.137V133.803L102.369 136.427L104.312 141.903L107.276 143.613V142.947L104.666 141.441L102.723 135.966L97.8214 133.137Z" fill="#919DD6"/>
        <path d="M97.5464 145.599L99.4709 144.481L97.5231 139.009L95.6034 140.128L97.5464 145.599Z" fill="#C4C3EC"/>
        <path d="M95.6034 140.128L97.5232 139.009L92.6259 136.176L90.7015 137.294L95.6034 140.128Z" fill="#E4E3EE"/>
        <path d="M100.156 147.104L102.075 145.986L99.4708 144.481L97.5464 145.599L100.156 147.104Z" fill="#E4E3EE"/>
        <path d="M100.151 147.771L102.076 146.657V145.986L100.156 147.105L100.151 147.771Z" fill="#B7B6E3"/>
        <path d="M90.7015 137.294L90.6968 137.96L95.2492 140.589L97.1876 146.06L100.151 147.77L100.156 147.104L97.5464 145.598L95.6034 140.127L90.7015 137.294Z" fill="#919DD6"/>
        <path d="M68.0417 111.718L69.584 112.608L88.5578 123.565V99.7922L68.0417 111.718Z" fill="#D4D5F0"/>
        <path d="M88.5579 99.792V123.564L88.6884 123.644L107.667 112.613L109.205 111.718L88.5579 99.792Z" fill="#C4C3EC"/>
        <path d="M107.667 112.613L88.6884 123.644L69.5841 112.608L88.5579 101.577L107.667 112.613Z" fill="#05D4F5"/>
        <path d="M107.667 112.613L88.6883 123.644L69.584 112.608L88.5579 101.577L107.667 112.613Z" fill="#05D4F5"/>
        <path d="M98.8837 110.967L98.879 112.122L96.6284 113.427V112.276L98.8837 110.967Z" fill="#D4D5F0"/>
        <path d="M96.6284 112.277V113.428L86.559 107.617L86.5636 106.466L96.6284 112.277Z" fill="#C4C3EC"/>
        <path d="M98.8838 110.967L96.6286 112.277L86.5638 106.465L88.8144 105.156L98.8838 110.967Z" fill="white"/>
        <path d="M87.6774 112.869V114.02C87.0857 113.857 86.5172 113.703 85.9767 113.559C85.4362 113.414 84.9376 113.284 84.4763 113.167C84.015 113.051 83.6143 112.948 83.2648 112.86C82.9153 112.771 82.6404 112.701 82.4354 112.655V111.504C82.6404 111.55 82.9153 111.62 83.2648 111.709C83.6143 111.797 84.015 111.9 84.4763 112.016C84.9376 112.133 85.4362 112.263 85.9767 112.408C86.5172 112.552 87.081 112.711 87.6728 112.869H87.6774Z" fill="#1393F2"/>
        <mask id="mask0_6057_6221"  maskUnits="userSpaceOnUse" x="82" y="111" width="6" height="4">
        <path d="M87.6774 112.869V114.02C87.0857 113.857 86.5172 113.703 85.9767 113.559C85.4362 113.414 84.9376 113.284 84.4763 113.167C84.015 113.051 83.6143 112.948 83.2648 112.86C82.9153 112.771 82.6404 112.701 82.4354 112.655V111.504C82.6404 111.55 82.9153 111.62 83.2648 111.709C83.6143 111.797 84.015 111.9 84.4763 112.016C84.9376 112.133 85.4362 112.263 85.9767 112.408C86.5172 112.552 87.081 112.711 87.6728 112.869H87.6774Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_6057_6221)">
        <path d="M87.6774 112.869V114.02C87.0857 113.857 86.5172 113.703 85.9767 113.559C85.4362 113.414 84.9376 113.284 84.4763 113.167C84.015 113.051 83.6143 112.948 83.2648 112.86C82.9153 112.771 82.6404 112.701 82.4354 112.655V111.504C82.6404 111.55 82.9153 111.62 83.2648 111.709C83.6143 111.797 84.015 111.9 84.4763 112.016C84.9376 112.133 85.4362 112.263 85.9767 112.408C86.5172 112.552 87.081 112.711 87.6728 112.869H87.6774Z" fill="#C4C3EC"/>
        </g>
        <path d="M91.4982 114.011V115.162C90.9997 115.023 90.5011 114.887 90.0118 114.748V113.596C90.5058 113.736 90.9997 113.871 91.4982 114.011Z" fill="#1393F2"/>
        <mask id="mask1_6057_6221"  maskUnits="userSpaceOnUse" x="90" y="113" width="2" height="3">
        <path d="M91.4982 114.011V115.162C90.9997 115.023 90.5011 114.887 90.0118 114.748V113.596C90.5058 113.736 90.9997 113.871 91.4982 114.011Z" fill="white"/>
        </mask>
        <g mask="url(#mask1_6057_6221)">
        <path d="M91.4982 114.011V115.162C90.9997 115.023 90.5011 114.887 90.0118 114.748V113.596C90.5058 113.736 90.9997 113.871 91.4982 114.011Z" fill="#C4C3EC"/>
        </g>
        <path d="M92.9334 114.425V115.576C92.4674 115.441 91.9921 115.301 91.4935 115.162V114.01C91.9921 114.15 92.4721 114.285 92.9334 114.425Z" fill="#1393F2"/>
        <mask id="mask2_6057_6221"  maskUnits="userSpaceOnUse" x="91" y="114" width="2" height="2">
        <path d="M92.9334 114.425V115.576C92.4674 115.441 91.9921 115.301 91.4935 115.162V114.01C91.9921 114.15 92.4721 114.285 92.9334 114.425Z" fill="white"/>
        </mask>
        <g mask="url(#mask2_6057_6221)">
        <path d="M92.9334 114.425V115.576C92.4674 115.441 91.9921 115.301 91.4935 115.162V114.01C91.9921 114.15 92.4721 114.285 92.9334 114.425Z" fill="#C4C3EC"/>
        </g>
        <path d="M95.3471 113.023L95.3425 114.179L92.9288 115.577L92.9335 114.426L95.3471 113.023Z" fill="#D4D5F0"/>
        <path d="M90.0165 113.601L90.0118 114.752L86.1024 117.022L86.1071 115.871L90.0165 113.601Z" fill="#D4D5F0"/>
        <path d="M85.2124 118.914V120.065C84.4995 119.221 83.8565 118.434 83.274 117.707C82.6916 116.98 82.1557 116.285 81.6665 115.628C81.1772 114.971 80.7206 114.337 80.3059 113.736C79.8912 113.13 79.4998 112.529 79.141 111.933V110.781C79.5044 111.378 79.8912 111.984 80.3105 112.585C80.7299 113.191 81.1819 113.82 81.6711 114.477C82.1604 115.134 82.6962 115.829 83.2787 116.556C83.8611 117.283 84.5042 118.07 85.2124 118.914Z" fill="#1393F2"/>
        <mask id="mask3_6057_6221"  maskUnits="userSpaceOnUse" x="79" y="110" width="7" height="11">
        <path d="M85.2124 118.914V120.065C84.4995 119.221 83.8565 118.434 83.274 117.707C82.6916 116.98 82.1557 116.285 81.6665 115.628C81.1772 114.971 80.7206 114.337 80.3059 113.736C79.8912 113.13 79.4998 112.529 79.141 111.933V110.781C79.5044 111.378 79.8912 111.984 80.3105 112.585C80.7299 113.191 81.1819 113.82 81.6711 114.477C82.1604 115.134 82.6962 115.829 83.2787 116.556C83.8611 117.283 84.5042 118.07 85.2124 118.914Z" fill="white"/>
        </mask>
        <g mask="url(#mask3_6057_6221)">
        <path d="M85.2124 118.914V120.065C84.4995 119.221 83.8565 118.434 83.274 117.707C82.6916 116.98 82.1557 116.285 81.6665 115.628C81.1772 114.971 80.7206 114.337 80.3059 113.736C79.8912 113.13 79.4998 112.529 79.141 111.933V110.781C79.5044 111.378 79.8912 111.984 80.3105 112.585C80.7299 113.191 81.1819 113.82 81.6711 114.477C82.1604 115.134 82.6962 115.829 83.2787 116.556C83.8611 117.283 84.5042 118.07 85.2124 118.914Z" fill="#C4C3EC"/>
        </g>
        <path d="M87.5516 117.553L87.5469 118.704L85.2124 120.065V118.914L87.5516 117.553Z" fill="#D4D5F0"/>
        <path d="M91.4982 114.01C90.9996 113.871 90.5057 113.735 90.0165 113.596L86.1117 115.865C86.354 116.149 86.5917 116.438 86.834 116.723C87.0763 117.012 87.3186 117.287 87.5562 117.548L85.2171 118.908C84.5088 118.065 83.8658 117.277 83.2834 116.55C82.7009 115.823 82.1651 115.129 81.6758 114.472C81.1865 113.815 80.7299 113.181 80.3152 112.58C79.9005 111.974 79.5091 111.373 79.1503 110.776L81.2844 109.536C82.3095 109.746 83.3486 109.974 84.397 110.207C85.4454 110.445 86.5404 110.701 87.6774 110.986C88.8143 111.265 90.0118 111.578 91.2699 111.909C92.528 112.244 93.8886 112.612 95.3471 113.022L92.938 114.425C92.4767 114.29 91.9968 114.15 91.5029 114.01H91.4982ZM84.821 114.528L87.682 112.864C87.0903 112.701 86.5264 112.547 85.9859 112.403C85.4454 112.258 84.9468 112.128 84.4855 112.011C84.0242 111.895 83.6235 111.792 83.274 111.703C82.9246 111.615 82.6497 111.545 82.4446 111.498C82.5425 111.61 82.6683 111.769 82.8221 111.969C82.9758 112.169 83.1576 112.403 83.3626 112.663C83.5676 112.924 83.7959 113.218 84.0382 113.535C84.2805 113.852 84.5415 114.183 84.821 114.528Z" fill="white"/>
        <path d="M88.8467 159.645L20.6535 120.265L88.4087 80.885L156.602 120.265L88.8467 159.645ZM22.4615 120.26L88.842 158.592L154.789 120.26L88.4087 81.9289L22.4615 120.26Z" fill="#05D4F5"/>
        <path d="M31.2307 125.848L26.1891 128.76C26.1145 128.802 26.0213 128.802 25.9514 128.76L20.7793 125.773L3.99541 135.467L2.42979 136.371C2.39251 136.394 2.35057 136.403 2.30864 136.403C2.22476 136.403 2.14555 136.362 2.10361 136.282C2.03838 136.17 2.07566 136.021 2.19215 135.956L3.51081 135.196L20.6581 125.293C20.7327 125.251 20.8212 125.251 20.8958 125.293L26.0679 128.28L30.7508 125.577L31.2307 125.852V125.848Z" fill="#05D4F5"/>
        <path d="M71.8857 148.866L71.0889 149.323L66.4992 151.974C66.4246 152.016 66.3361 152.016 66.2616 151.974L61.0941 148.987L54.566 152.757L54.086 153.032L44.1704 158.76L42.7399 159.585C42.7026 159.608 42.6607 159.617 42.6188 159.617C42.5349 159.617 42.4557 159.575 42.4137 159.496C42.3485 159.38 42.3858 159.235 42.5023 159.17L43.7744 158.434L53.6061 152.757L54.086 152.482L60.9729 148.507C61.0475 148.465 61.136 148.465 61.2106 148.507L66.378 151.494L70.609 149.052L71.4057 148.591L71.8857 148.866Z" fill="#05D4F5"/>
        <path d="M56.1969 139.806L55.4001 140.267L50.9409 142.844C50.8663 142.886 50.7778 142.886 50.7032 142.844L45.5358 139.857L38.8819 143.697L38.4019 143.972L28.873 149.476L27.1863 150.45C27.149 150.469 27.1071 150.483 27.0651 150.483C26.9812 150.483 26.902 150.441 26.8601 150.362C26.7949 150.245 26.8321 150.101 26.9486 150.035L28.491 149.145L37.9266 143.697L38.4066 143.422L45.4193 139.373C45.4938 139.331 45.5823 139.331 45.6569 139.373L50.8244 142.36L54.9248 139.992L55.7216 139.531L56.2016 139.806H56.1969Z" fill="#05D4F5"/>
        <path d="M43.6812 132.578L42.8844 133.039L26.0213 142.775L24.7539 143.506C24.7167 143.53 24.6747 143.539 24.6328 143.539C24.5489 143.539 24.4697 143.497 24.4278 143.418C24.3625 143.306 24.3998 143.157 24.5163 143.092L25.5694 142.481L42.4091 132.755L43.2059 132.293L43.6859 132.568L43.6812 132.578Z" fill="#05D4F5"/>
        <path d="M77.659 152.198L76.8622 152.655L60.2321 162.26L58.946 163.001C58.9087 163.019 58.8668 163.033 58.8249 163.033C58.741 163.033 58.6618 162.991 58.6198 162.912C58.5546 162.796 58.5919 162.651 58.7084 162.586L59.7521 161.985L76.3869 152.375L77.1837 151.914L77.6636 152.189L77.659 152.198Z" fill="#05D4F5"/>
        <path d="M61.5648 142.905L60.768 143.366L49.5337 149.853L49.0538 150.128L43.9608 153.069L42.6282 153.838C42.5909 153.861 42.549 153.87 42.507 153.87C42.4232 153.87 42.3439 153.829 42.302 153.749C42.2368 153.637 42.2741 153.488 42.3905 153.423L43.4949 152.785L48.5831 149.849L49.0584 149.574L60.2927 143.086L61.0895 142.625L61.5694 142.9L61.5648 142.905Z" fill="#05D4F5"/>
        <path d="M61.3178 156.658L55.018 160.293L53.2567 161.309L53.0191 160.894L54.6033 159.981L60.3626 156.658L54.0908 153.032L53.6108 152.757L49.0584 150.129L48.5831 149.849L38.4019 143.972L37.922 143.697L33.7843 141.307L45.41 134.498L46.2021 134.032L46.6774 134.307L45.8852 134.773L34.7348 141.302L38.4019 143.422L38.8772 143.697L49.0584 149.574L49.5337 149.849L54.0908 152.482L54.5661 152.757L61.3178 156.658Z" fill="#05D4F5"/>
        <path d="M3.7904 135.327C4.50332 135.737 4.50332 136.404 3.7904 136.814C3.07748 137.224 1.92656 137.224 1.21364 136.814C0.500726 136.404 0.500726 135.737 1.21364 135.327C1.92656 134.917 3.07748 134.917 3.7904 135.327Z" fill="#05D4F5"/>
        <path d="M35.0887 134.61C35.0887 134.694 35.0421 134.773 34.9676 134.815L20.5182 143.162L19.1948 143.926C19.1576 143.949 19.1156 143.959 19.0737 143.959C18.9898 143.959 18.9106 143.917 18.8687 143.837C18.8034 143.726 18.8407 143.576 18.9572 143.511L20.0569 142.877L34.3758 134.61L29.0592 131.539C28.9847 131.497 28.9381 131.418 28.9381 131.334C28.9381 131.25 28.9847 131.166 29.0592 131.129L34.3199 128.09L35.1167 127.629L35.5966 127.904L34.7998 128.365L29.6603 131.334L34.9769 134.4C35.0514 134.442 35.098 134.521 35.098 134.605L35.0887 134.61Z" fill="#05D4F5"/>
        <path d="M20.2759 142.985C20.9888 143.395 20.9888 144.061 20.2759 144.471C19.563 144.881 18.4121 144.881 17.6991 144.471C16.9862 144.061 16.9862 143.395 17.6991 142.985C18.4121 142.574 19.563 142.574 20.2759 142.985Z" fill="#05D4F5"/>
        <path d="M80.7716 160.666C80.7716 160.75 80.725 160.829 80.6505 160.871L66.5785 168.998L64.8777 169.982C64.8405 170 64.7985 170.014 64.7566 170.014C64.6774 170.014 64.5935 169.972 64.5516 169.893C64.4863 169.777 64.5283 169.632 64.6401 169.567L66.1219 168.714L80.0587 160.666L74.7421 157.595C74.6675 157.553 74.6209 157.473 74.6209 157.39C74.6209 157.306 74.6675 157.226 74.7421 157.184L79.7185 154.309L80.5153 153.852L80.9906 154.127L80.1938 154.584L75.3385 157.39L80.6551 160.461C80.7297 160.503 80.7763 160.582 80.7763 160.666H80.7716Z" fill="#05D4F5"/>
        <path d="M66.3222 168.812C67.0351 169.222 67.0351 169.889 66.3222 170.299C65.6092 170.709 64.4583 170.709 63.7454 170.299C63.0325 169.889 63.0325 169.222 63.7454 168.812C64.4583 168.402 65.6092 168.402 66.3222 168.812Z" fill="#05D4F5"/>
        <path d="M25.7465 142.574C26.4594 142.984 26.4594 143.65 25.7465 144.06C25.0336 144.47 23.8827 144.47 23.1698 144.06C22.4568 143.65 22.4568 142.984 23.1698 142.574C23.8827 142.164 25.0336 142.164 25.7465 142.574Z" fill="#05D4F5"/>
        <path d="M28.4817 149.141C29.1946 149.551 29.1946 150.218 28.4817 150.628C27.7688 151.038 26.6178 151.038 25.9049 150.628C25.192 150.218 25.192 149.551 25.9049 149.141C26.6178 148.731 27.7688 148.731 28.4817 149.141Z" fill="#05D4F5"/>
        <path d="M43.7325 152.906C44.4454 153.317 44.4454 153.983 43.7325 154.393C43.0196 154.803 41.8687 154.803 41.1558 154.393C40.4428 153.983 40.4428 153.317 41.1558 152.906C41.8687 152.496 43.0196 152.496 43.7325 152.906Z" fill="#05D4F5"/>
        <path d="M43.8024 158.443C44.5154 158.853 44.5154 159.519 43.8024 159.929C43.0895 160.34 41.9386 160.34 41.2257 159.929C40.5128 159.519 40.5128 158.853 41.2257 158.443C41.9386 158.033 43.0895 158.033 43.8024 158.443Z" fill="#05D4F5"/>
        <path d="M54.6732 160.018C55.3861 160.428 55.3861 161.094 54.6732 161.504C53.9603 161.915 52.8094 161.915 52.0965 161.504C51.3835 161.094 51.3835 160.428 52.0965 160.018C52.8094 159.608 53.9603 159.608 54.6732 160.018Z" fill="#05D4F5"/>
        <path d="M60.0317 162.124C60.7446 162.535 60.7446 163.201 60.0317 163.611C59.3188 164.021 58.1679 164.021 57.455 163.611C56.742 163.201 56.742 162.535 57.455 162.124C58.1679 161.714 59.3188 161.714 60.0317 162.124Z" fill="#05D4F5"/>
        <path d="M160.357 142.313C160.311 142.388 160.232 142.435 160.152 142.435C160.111 142.435 160.073 142.425 160.031 142.402L158.577 141.563L144.259 133.291C144.184 133.249 144.137 133.17 144.137 133.086C144.137 133.002 144.184 132.923 144.259 132.881L149.575 129.81L144.804 127.055L144.007 126.599L144.482 126.324L145.279 126.78L150.172 129.605C150.246 129.647 150.293 129.726 150.293 129.81C150.293 129.894 150.246 129.973 150.172 130.015L144.855 133.086L159.113 141.321L160.269 141.987C160.385 142.052 160.423 142.197 160.357 142.313Z" fill="#05D4F5"/>
        <path d="M110.891 170.952C110.845 171.026 110.765 171.073 110.686 171.073C110.644 171.073 110.607 171.063 110.565 171.04L109.158 170.229L94.7923 161.934C94.7177 161.892 94.6711 161.813 94.6711 161.729C94.6711 161.645 94.7177 161.566 94.7923 161.524L100.109 158.452L95.44 155.754L94.6478 155.297L95.1231 155.022L95.9153 155.479L100.705 158.247C100.78 158.289 100.826 158.369 100.826 158.452C100.826 158.536 100.78 158.616 100.705 158.658L95.3887 161.729L109.712 170.001L110.803 170.63C110.919 170.695 110.956 170.84 110.891 170.956V170.952Z" fill="#05D4F5"/>
        <path d="M135.279 155.572C135.233 155.647 135.154 155.693 135.074 155.693C135.033 155.693 134.991 155.684 134.953 155.661L133.635 154.896L122.764 148.619C122.689 148.577 122.643 148.498 122.643 148.414C122.643 148.33 122.689 148.251 122.764 148.209L126.794 145.883L123.165 143.749L122.689 143.469L119.647 141.679L118.859 141.213L119.334 140.938L120.122 141.4L123.165 143.189L123.64 143.469L127.391 145.673C127.465 145.715 127.507 145.795 127.507 145.878C127.507 145.962 127.461 146.042 127.386 146.084L123.36 148.409L134.278 154.714L135.191 155.241C135.303 155.306 135.345 155.451 135.279 155.567V155.572Z" fill="#05D4F5"/>
        <path d="M155.022 154.943C154.976 155.018 154.896 155.064 154.817 155.064C154.775 155.064 154.738 155.055 154.696 155.032L153.289 154.221L133.472 142.775C133.397 142.733 133.35 142.654 133.35 142.57C133.35 142.486 133.397 142.407 133.472 142.365L140.75 138.161L133.206 133.804L132.409 133.347L132.885 133.072L133.681 133.529L141.351 137.956C141.426 137.998 141.472 138.077 141.472 138.161C141.472 138.245 141.426 138.324 141.351 138.366L134.073 142.57L153.843 153.988L154.934 154.617C155.05 154.682 155.087 154.827 155.022 154.943Z" fill="#05D4F5"/>
        <path d="M133.756 160.917C133.714 160.992 133.63 161.038 133.551 161.038C133.509 161.038 133.472 161.029 133.43 161.006L132.218 160.307L118.193 152.207L110.453 156.676C110.379 156.718 110.285 156.718 110.216 156.676L102.001 151.932L101.209 151.475L101.684 151.2L102.476 151.657L110.337 156.196L118.076 151.727C118.151 151.685 118.244 151.685 118.314 151.727L132.698 160.032L133.667 160.591C133.784 160.656 133.821 160.801 133.756 160.917Z" fill="#05D4F5"/>
        <path d="M154.803 141.898C154.757 141.973 154.677 142.02 154.598 142.02C154.556 142.02 154.519 142.01 154.477 141.987L153.112 141.199L136.738 131.743L135.941 131.282L136.416 131.007L137.213 131.464L153.624 140.938L154.715 141.567C154.831 141.633 154.868 141.777 154.803 141.894V141.898Z" fill="#05D4F5"/>
        <path d="M167.459 134.484C167.412 134.559 167.333 134.605 167.254 134.605C167.212 134.605 167.174 134.596 167.133 134.573L165.632 133.706L149.445 124.357L148.648 123.9L149.123 123.625L149.92 124.082L166.089 133.422L167.37 134.163C167.487 134.228 167.524 134.372 167.459 134.489V134.484Z" fill="#05D4F5"/>
        <path d="M140.098 150.268C140.051 150.343 139.972 150.389 139.893 150.389C139.851 150.389 139.809 150.38 139.771 150.357L138.29 149.504L125.741 142.257L125.262 141.982L122.182 140.202L121.385 139.745L121.86 139.47L122.657 139.927L125.737 141.707L126.217 141.982L138.718 149.201L140.004 149.942C140.116 150.007 140.158 150.152 140.093 150.268H140.098Z" fill="#05D4F5"/>
        <path d="M116.343 164.12C116.301 164.194 116.217 164.241 116.138 164.241C116.096 164.241 116.059 164.232 116.017 164.208L114.642 163.416L98.3943 154.035L97.6022 153.578L98.0774 153.303L98.8696 153.76L115.155 163.16L116.259 163.798C116.376 163.863 116.413 164.012 116.348 164.124L116.343 164.12Z" fill="#05D4F5"/>
        <path d="M133.868 137.565L126.217 141.983L125.741 142.257L123.64 143.469L123.16 143.744L113.575 149.281L112.14 150.11L111.902 149.695L113.179 148.959L122.689 143.469L123.165 143.19L125.261 141.983L125.741 141.703L132.913 137.565L129.805 135.77L129.012 135.314L129.488 135.034L130.285 135.495L133.868 137.565Z" fill="#05D4F5"/>
        <path d="M111.767 170.205C112.48 170.615 112.48 171.282 111.767 171.692C111.054 172.102 109.903 172.102 109.191 171.692C108.478 171.282 108.478 170.615 109.191 170.205C109.903 169.795 111.054 169.795 111.767 170.205Z" fill="#05D4F5"/>
        <path d="M117.359 163.318C118.072 163.728 118.072 164.394 117.359 164.804C116.646 165.214 115.495 165.214 114.782 164.804C114.069 164.394 114.069 163.728 114.782 163.318C115.495 162.907 116.646 162.907 117.359 163.318Z" fill="#05D4F5"/>
        <path d="M113.202 148.973C113.915 149.383 113.915 150.05 113.202 150.46C112.489 150.87 111.339 150.87 110.626 150.46C109.913 150.05 109.913 149.383 110.626 148.973C111.339 148.563 112.489 148.563 113.202 148.973Z" fill="#05D4F5"/>
        <path d="M135.005 160.162C135.718 160.572 135.718 161.239 135.005 161.649C134.292 162.059 133.141 162.059 132.428 161.649C131.715 161.239 131.715 160.572 132.428 160.162C133.141 159.752 134.292 159.752 135.005 160.162Z" fill="#05D4F5"/>
        <path d="M136.011 154.994C136.724 155.404 136.724 156.071 136.011 156.481C135.298 156.891 134.147 156.891 133.434 156.481C132.722 156.071 132.722 155.404 133.434 154.994C134.147 154.584 135.298 154.584 136.011 154.994Z" fill="#05D4F5"/>
        <path d="M141.174 149.262C141.887 149.672 141.887 150.338 141.174 150.748C140.461 151.158 139.31 151.158 138.597 150.748C137.884 150.338 137.884 149.672 138.597 149.262C139.31 148.852 140.461 148.852 141.174 149.262Z" fill="#05D4F5"/>
        <path d="M155.912 154.188C156.625 154.598 156.625 155.264 155.912 155.674C155.199 156.084 154.048 156.084 153.336 155.674C152.623 155.264 152.623 154.598 153.336 154.188C154.048 153.778 155.199 153.778 155.912 154.188Z" fill="#05D4F5"/>
        <path d="M155.828 141.106C156.541 141.516 156.541 142.183 155.828 142.593C155.115 143.003 153.965 143.003 153.252 142.593C152.539 142.183 152.539 141.516 153.252 141.106C153.965 140.696 155.115 140.696 155.828 141.106Z" fill="#05D4F5"/>
        <path d="M161.238 141.512C161.951 141.922 161.951 142.589 161.238 142.999C160.525 143.409 159.374 143.409 158.661 142.999C157.949 142.589 157.949 141.922 158.661 141.512C159.374 141.102 160.525 141.102 161.238 141.512Z" fill="#05D4F5"/>
        <path d="M168.465 133.515C169.178 133.925 169.178 134.591 168.465 135.002C167.752 135.412 166.601 135.412 165.888 135.002C165.176 134.591 165.176 133.925 165.888 133.515C166.601 133.105 167.752 133.105 168.465 133.515Z" fill="#05D4F5"/>
        <path d="M71.1916 76.0664C71.1916 76.1503 71.1496 76.2295 71.0751 76.2715L67.2915 78.5364L66.8209 78.8207L62.0727 81.6682L61.6021 81.9478L57.1476 84.6182L69.6586 91.8417L70.4554 92.2985L69.9801 92.5734L69.1833 92.1167L56.5604 84.8279C56.4859 84.786 56.444 84.7067 56.4393 84.6228C56.4393 84.5389 56.4812 84.4597 56.5558 84.4178L61.1269 81.6775L61.5975 81.3932L66.3456 78.5457L66.8162 78.2614L70.4787 76.0664L63.4566 72.0119L62.2685 71.3268C62.1566 71.2616 62.1147 71.1171 62.1799 71.0006C62.2452 70.8841 62.3896 70.8468 62.5061 70.9121L63.9133 71.723L71.0658 75.852C71.1403 75.894 71.1823 75.9732 71.1869 76.0571L71.1916 76.0664Z" fill="#05D4F5"/>
        <path d="M28.4213 116.276L16.1339 109.182L21.4505 106.111C21.5251 106.069 21.5717 105.99 21.5717 105.906C21.5717 105.822 21.5251 105.743 21.4505 105.701L14.2328 101.535L12.8908 100.761C12.7744 100.696 12.6299 100.733 12.5647 100.85C12.4994 100.962 12.5367 101.111 12.6532 101.176L13.6737 101.768L20.8541 105.911L15.5375 108.982C15.4629 109.024 15.4163 109.103 15.4163 109.187C15.4163 109.271 15.4629 109.35 15.5375 109.392L27.946 116.555L28.4213 116.28V116.276Z" fill="#05D4F5"/>
        <path d="M53.4199 102.206L52.9447 102.481L52.1479 102.024L43.6022 97.089C43.5276 97.0471 43.481 96.9679 43.481 96.884C43.481 96.8001 43.5276 96.7162 43.6022 96.6789L47.6327 94.3487L37.7684 88.5466L36.8318 87.9967C36.7153 87.9314 36.678 87.7823 36.7479 87.6704C36.8132 87.5586 36.9623 87.5213 37.0741 87.5865L38.2669 88.2903L48.2245 94.1483C48.2991 94.1903 48.3456 94.2695 48.341 94.3534C48.341 94.4373 48.2944 94.5165 48.2198 94.5584L44.1939 96.884L52.6185 101.749L53.4153 102.211L53.4199 102.206Z" fill="#05D4F5"/>
        <path d="M64.0998 95.9986L63.6246 96.2736L62.8278 95.8169L49.5339 88.1413C49.4594 88.0993 49.4128 88.0201 49.4128 87.9362C49.4128 87.8523 49.4594 87.7731 49.5339 87.7311L55.4004 84.3431L47.945 80.0369L46.3701 79.1235C46.2536 79.0582 46.2163 78.9137 46.2815 78.7972C46.3468 78.6807 46.4912 78.6434 46.6077 78.7087L48.4063 79.7479L55.9968 84.1333C56.0714 84.1753 56.118 84.2545 56.118 84.3384C56.118 84.4223 56.0714 84.5015 55.9968 84.5435L50.1304 87.9315L63.303 95.5372L64.0998 95.9986Z" fill="#05D4F5"/>
        <path d="M47.4046 105.702L46.9293 105.977L46.1325 105.52L37.4517 100.51L31.2451 104.094C31.1706 104.136 31.082 104.136 31.0075 104.094L22.6109 99.2471L21.5765 98.6506C21.46 98.5853 21.4227 98.4408 21.4879 98.3243C21.5532 98.2078 21.6976 98.1705 21.8141 98.2358L23.0489 98.9488L31.124 103.614L37.3305 100.03C37.4051 99.9881 37.4983 99.9881 37.5682 100.03L46.6031 105.245L47.3999 105.702H47.4046Z" fill="#05D4F5"/>
        <path d="M75.4599 89.3948L74.9846 89.6697L74.1878 89.2083L61.6069 81.9428L61.1316 81.6725L57.2362 79.4216L56.2251 78.839C56.1086 78.7738 56.0713 78.6293 56.1365 78.5128C56.2018 78.3963 56.3462 78.359 56.4627 78.4243L57.7674 79.1792L61.6022 81.3929L62.0775 81.6679L74.6677 88.938L75.4645 89.3994L75.4599 89.3948Z" fill="#05D4F5"/>
        <path d="M80.7625 86.3096L80.2873 86.5846L79.4905 86.1279L66.821 78.8204L66.3458 78.5455L51.6913 70.0916L50.9318 69.6535C50.8153 69.5882 50.7781 69.4438 50.8433 69.3273C50.9085 69.2108 51.053 69.1735 51.1695 69.2387L52.2831 69.8818L66.8117 78.2658L67.287 78.5408L79.9611 85.8529L80.7579 86.3096H80.7625Z" fill="#05D4F5"/>
        <path d="M61.5138 97.4991L61.0385 97.7741L60.2417 97.3174L43.4485 87.6192L42.3256 86.9667C42.2091 86.9015 42.1718 86.7524 42.237 86.6405C42.3023 86.524 42.4467 86.4867 42.5632 86.552L43.9704 87.3629L60.717 97.0331L61.5138 97.4898V97.4991Z" fill="#05D4F5"/>
        <path d="M37.913 111.219L37.4377 111.494L36.6409 111.037L29.1436 106.708L27.345 105.669C27.2332 105.603 27.1912 105.459 27.2611 105.342C27.3264 105.231 27.4708 105.189 27.5873 105.254L29.6468 106.442L37.1208 110.758L37.9176 111.214L37.913 111.219Z" fill="#05D4F5"/>
        <path d="M14.1956 100.063C14.9085 100.473 14.9085 101.139 14.1956 101.549C13.4826 101.959 12.3317 101.959 11.6188 101.549C10.9059 101.139 10.9059 100.473 11.6188 100.063C12.3317 99.6526 13.4826 99.6526 14.1956 100.063Z" fill="#05D4F5"/>
        <path d="M29.4743 105.068C30.1872 105.478 30.1872 106.145 29.4743 106.555C28.7614 106.965 27.6105 106.965 26.8976 106.555C26.1847 106.145 26.1847 105.478 26.8976 105.068C27.6105 104.658 28.7614 104.658 29.4743 105.068Z" fill="#05D4F5"/>
        <path d="M22.7553 97.6809C23.4682 98.091 23.4682 98.7574 22.7553 99.1675C22.0424 99.5776 20.8914 99.5776 20.1785 99.1675C19.4656 98.7574 19.4656 98.091 20.1785 97.6809C20.8914 97.2708 22.0424 97.2708 22.7553 97.6809Z" fill="#05D4F5"/>
        <path d="M38.104 86.9018C38.8169 87.3119 38.8169 87.9784 38.104 88.3885C37.391 88.7986 36.2401 88.7986 35.5272 88.3885C34.8143 87.9784 34.8143 87.3119 35.5272 86.9018C36.2401 86.4917 37.391 86.4917 38.104 86.9018Z" fill="#05D4F5"/>
        <path d="M25.5789 94.1482C26.2918 94.5583 26.2918 95.2247 25.5789 95.6348C24.866 96.0449 23.7151 96.0449 23.0022 95.6348C22.2892 95.2247 22.2892 94.5583 23.0022 94.1482C23.7151 93.738 24.866 93.738 25.5789 94.1482Z" fill="#05D4F5"/>
        <path d="M50.5497 103.875L50.0744 104.15L49.2776 103.693L29.3951 92.2097L25.7886 94.2929L24.428 95.0758C24.3162 95.1411 24.1671 95.1038 24.1018 94.9873C24.0366 94.8754 24.0786 94.7263 24.1904 94.6611L25.3087 94.0179L29.274 91.7297C29.3486 91.6878 29.4371 91.6878 29.5116 91.7297L49.7529 103.418L50.5497 103.875Z" fill="#05D4F5"/>
        <path d="M83.7726 84.562L83.2973 84.837L82.5005 84.3803L73.8616 79.389C73.7871 79.3471 73.7405 79.2679 73.7405 79.184C73.7405 79.1001 73.7871 79.0209 73.8616 78.9789L77.8922 76.6487L68.0278 70.8466L67.0912 70.2967C66.9794 70.2314 66.9421 70.0823 67.0073 69.9704C67.0726 69.8539 67.2217 69.8166 67.3335 69.8865L68.5264 70.5856L78.4839 76.4483C78.5585 76.4903 78.6004 76.5695 78.6004 76.6534C78.6004 76.7373 78.5538 76.8165 78.4793 76.8584L74.4534 79.184L82.9758 84.1053L83.7726 84.562Z" fill="#05D4F5"/>
        <path d="M68.3586 69.2063C69.0716 69.6164 69.0716 70.2828 68.3586 70.6929C67.6457 71.103 66.4948 71.103 65.7819 70.6929C65.069 70.2828 65.069 69.6164 65.7819 69.2063C66.4948 68.7962 67.6457 68.7962 68.3586 69.2063Z" fill="#05D4F5"/>
        <path d="M43.8584 85.955C44.5714 86.3652 44.5714 87.0316 43.8584 87.4417C43.1455 87.8518 41.9946 87.8518 41.2817 87.4417C40.5688 87.0316 40.5688 86.3652 41.2817 85.955C41.9946 85.5449 43.1455 85.5449 43.8584 85.955Z" fill="#05D4F5"/>
        <path d="M48.1547 78.4387C48.8676 78.8488 48.8676 79.5152 48.1547 79.9253C47.4417 80.3355 46.2908 80.3355 45.5779 79.9253C44.865 79.5152 44.865 78.8488 45.5779 78.4387C46.2908 78.0286 47.4417 78.0286 48.1547 78.4387Z" fill="#05D4F5"/>
        <path d="M52.3483 68.3676C53.0612 68.7778 53.0612 69.4442 52.3483 69.8543C51.6354 70.2644 50.4844 70.2644 49.7715 69.8543C49.0586 69.4442 49.0586 68.7778 49.7715 68.3676C50.4844 67.9575 51.6354 67.9575 52.3483 68.3676Z" fill="#05D4F5"/>
        <path d="M63.657 70.4319C64.3699 70.842 64.3699 71.5084 63.657 71.9185C62.9441 72.3286 61.7932 72.3286 61.0803 71.9185C60.3673 71.5084 60.3673 70.842 61.0803 70.4319C61.7932 70.0217 62.9441 70.0217 63.657 70.4319Z" fill="#05D4F5"/>
        <path d="M57.6743 77.7487C58.3872 78.1589 58.3872 78.8253 57.6743 79.2354C56.9614 79.6455 55.8105 79.6455 55.0975 79.2354C54.3846 78.8253 54.3846 78.1589 55.0975 77.7487C55.8105 77.3386 56.9614 77.3386 57.6743 77.7487Z" fill="#05D4F5"/>
        <path d="M151.63 103.47C151.63 103.554 151.584 103.633 151.509 103.675L139.636 110.53L138.84 110.987L138.36 110.712L139.156 110.251L150.917 103.46L140.126 96.9918L139.66 96.7076L136.999 95.1137C136.925 95.0718 136.883 94.9925 136.883 94.9087C136.883 94.8248 136.929 94.7456 137.004 94.7036L144.156 90.5745L145.563 89.7636C145.675 89.6984 145.824 89.7357 145.89 89.8522C145.955 89.9687 145.918 90.1132 145.806 90.1784L144.618 90.8635L137.596 94.918L140.135 96.4419L140.606 96.7215L151.518 103.26C151.588 103.307 151.635 103.381 151.635 103.465L151.63 103.47Z" fill="#05D4F5"/>
        <path d="M140.252 88.4068L139.315 88.9568L129.451 94.7589L133.481 97.0844C133.556 97.1264 133.602 97.2056 133.602 97.2895C133.602 97.3734 133.556 97.4526 133.481 97.4945L125.276 102.234L124.479 102.691L124.004 102.416L124.8 101.959L132.885 97.2895L128.859 94.964C128.784 94.922 128.738 94.8428 128.738 94.7589C128.738 94.675 128.784 94.5958 128.854 94.5539L138.812 88.6911L140.005 87.9921C140.121 87.9268 140.266 87.9641 140.331 88.076C140.396 88.1878 140.359 88.3369 140.247 88.4022L140.252 88.4068Z" fill="#05D4F5"/>
        <path d="M130.066 78.0517L128.533 78.9371L120.835 83.3831L126.837 86.8458C126.911 86.8877 126.958 86.9669 126.958 87.0508C126.958 87.1347 126.911 87.2139 126.837 87.2559L113.091 95.1925L112.294 95.6538L111.814 95.3789L112.611 94.9222L126.24 87.0508L120.239 83.5882C120.164 83.5462 120.117 83.467 120.117 83.3831C120.117 83.2992 120.164 83.22 120.239 83.1781L128.118 78.6295L129.833 77.6416C129.949 77.5763 130.094 77.6136 130.159 77.7301C130.224 77.8466 130.187 77.9911 130.07 78.0563L130.066 78.0517Z" fill="#05D4F5"/>
        <path d="M164.463 108.19L163.438 108.782L156.509 112.781C156.434 112.823 156.346 112.823 156.271 112.781L151.057 109.77L144.687 113.447L143.891 113.909L143.411 113.634L144.208 113.177L150.936 109.295C151.011 109.253 151.104 109.253 151.174 109.295L156.388 112.305L162.911 108.54L164.221 107.785C164.337 107.72 164.481 107.757 164.547 107.873C164.612 107.99 164.575 108.134 164.458 108.2L164.463 108.19Z" fill="#05D4F5"/>
        <path d="M120.383 79.3332L119.372 79.9157L103.003 89.3716L102.206 89.8283L101.726 89.5534L102.523 89.0966L118.845 79.6734L120.15 78.9184C120.267 78.8532 120.411 78.8904 120.476 79.007C120.541 79.1235 120.504 79.2679 120.388 79.3332H120.383Z" fill="#05D4F5"/>
        <path d="M126.152 70.0734L125.392 70.5115L97.8445 86.3987L97.0477 86.8554L96.5678 86.5804L97.3646 86.1237L124.796 70.3018L125.909 69.6586C126.026 69.5934 126.17 69.6307 126.235 69.7472C126.301 69.8637 126.263 70.0082 126.147 70.0734H126.152Z" fill="#05D4F5"/>
        <path d="M134.762 87.3866L133.635 88.0344L117.158 97.5509L116.362 98.0076L115.882 97.7326L116.679 97.2759L133.113 87.7874L134.525 86.9719C134.641 86.9066 134.786 86.9439 134.851 87.0604C134.916 87.1769 134.879 87.3214 134.762 87.3866Z" fill="#05D4F5"/>
        <path d="M116.907 87.1718L115.108 88.211L108.04 92.2935L107.243 92.7502L106.763 92.4753L107.56 92.0186L114.605 87.9501L116.66 86.7617C116.777 86.6964 116.921 86.7337 116.986 86.8502C117.052 86.9667 117.014 87.1112 116.898 87.1764L116.907 87.1718Z" fill="#05D4F5"/>
        <path d="M114.777 86.5664C114.065 86.9765 114.065 87.6429 114.777 88.053C115.49 88.4631 116.641 88.4631 117.354 88.053C118.067 87.6429 118.067 86.9765 117.354 86.5664C116.641 86.1563 115.49 86.1563 114.777 86.5664Z" fill="#05D4F5"/>
        <path d="M163.023 107.11C162.31 107.52 162.31 108.186 163.023 108.596C163.736 109.006 164.887 109.006 165.6 108.596C166.313 108.186 166.313 107.52 165.6 107.11C164.887 106.699 163.736 106.699 163.023 107.11Z" fill="#05D4F5"/>
        <path d="M138.984 87.3164C138.271 87.7265 138.271 88.3929 138.984 88.803C139.697 89.2131 140.848 89.2131 141.561 88.803C142.274 88.3929 142.274 87.7265 141.561 87.3164C140.848 86.9063 139.697 86.9063 138.984 87.3164Z" fill="#05D4F5"/>
        <path d="M151.509 94.5632C150.796 94.9733 150.796 95.6397 151.509 96.0499C152.222 96.46 153.373 96.46 154.086 96.0499C154.799 95.6397 154.799 94.9733 154.086 94.5632C153.373 94.1531 152.222 94.1531 151.509 94.5632Z" fill="#05D4F5"/>
        <path d="M152.986 95.4023C152.921 95.5188 152.777 95.5561 152.66 95.4909L151.304 94.7079L147.698 92.6248L140.606 96.7212L140.13 96.9962L128.169 103.903L127.372 104.36L126.897 104.085L127.694 103.628L139.664 96.7119L140.14 96.4416L147.586 92.1447C147.66 92.1028 147.754 92.1028 147.823 92.1447L151.789 94.4376L152.907 95.0808C153.019 95.146 153.061 95.2905 152.996 95.407L152.986 95.4023Z" fill="#05D4F5"/>
        <path d="M110.001 70.7115L109.069 71.2615L99.2051 77.0636L103.236 79.3891C103.31 79.4311 103.357 79.5103 103.357 79.5942C103.357 79.6781 103.31 79.7573 103.236 79.7992L94.8344 84.6507L94.0376 85.1074L91.0415 86.8364C91.0042 86.8597 90.9623 86.869 90.925 86.869C90.8412 86.869 90.7619 86.8271 90.72 86.7478C90.6548 86.6313 90.6921 86.4869 90.8039 86.4216L93.5577 84.8278L94.3545 84.3711L102.635 79.5895L98.6087 77.264C98.5342 77.2221 98.4922 77.1428 98.4876 77.059C98.4876 76.9751 98.5341 76.8958 98.604 76.8539L108.562 70.9912L109.754 70.2921C109.866 70.2269 110.015 70.2641 110.081 70.376C110.146 70.4925 110.109 70.637 109.997 70.7022L110.001 70.7115Z" fill="#05D4F5"/>
        <path d="M108.729 69.6213C108.016 70.0314 108.016 70.6978 108.729 71.108C109.442 71.5181 110.593 71.5181 111.306 71.108C112.019 70.6978 112.019 70.0314 111.306 69.6213C110.593 69.2112 109.442 69.2112 108.729 69.6213Z" fill="#05D4F5"/>
        <path d="M133.234 86.3706C132.521 86.7807 132.521 87.4471 133.234 87.8572C133.947 88.2673 135.098 88.2673 135.811 87.8572C136.524 87.4471 136.524 86.7807 135.811 86.3706C135.098 85.9605 133.947 85.9605 133.234 86.3706Z" fill="#05D4F5"/>
        <path d="M128.449 77.4038C127.736 77.8139 127.736 78.4803 128.449 78.8904C129.162 79.3005 130.313 79.3005 131.026 78.8904C131.738 78.4803 131.738 77.8139 131.026 77.4038C130.313 76.9937 129.162 76.9937 128.449 77.4038Z" fill="#05D4F5"/>
        <path d="M124.74 68.7822C124.027 69.1923 124.027 69.8587 124.74 70.2688C125.453 70.679 126.604 70.679 127.316 70.2688C128.029 69.8587 128.029 69.1923 127.316 68.7822C126.604 68.3721 125.453 68.3721 124.74 68.7822Z" fill="#05D4F5"/>
        <path d="M144.412 89.2785C143.7 89.6886 143.7 90.3551 144.412 90.7652C145.125 91.1753 146.276 91.1753 146.989 90.7652C147.702 90.3551 147.702 89.6886 146.989 89.2785C146.276 88.8684 145.125 88.8684 144.412 89.2785Z" fill="#05D4F5"/>
        <path d="M118.929 78.2424C118.216 78.6525 118.216 79.3189 118.929 79.7291C119.642 80.1392 120.793 80.1392 121.506 79.7291C122.219 79.3189 122.219 78.6525 121.506 78.2424C120.793 77.8323 119.642 77.8323 118.929 78.2424Z" fill="#05D4F5"/>
        <path d="M64.1789 86.3282L60.2229 84.0306C59.7896 83.7789 59.4681 83.2803 59.3423 82.4927L63.2983 84.7902C63.4241 85.5778 63.7409 86.0765 64.1789 86.3282Z" fill="#DAD9E8"/>
        <mask id="mask4_6057_6221"  maskUnits="userSpaceOnUse" x="59" y="82" width="6" height="5">
        <path d="M64.1789 86.3282L60.2229 84.0306C59.7896 83.7789 59.4681 83.2803 59.3423 82.4927L63.2983 84.7902C63.4241 85.5778 63.7409 86.0765 64.1789 86.3282Z" fill="white"/>
        </mask>
        <g mask="url(#mask4_6057_6221)">
        <path d="M64.1791 86.3286L60.2231 84.031C59.7898 83.7794 59.4729 83.2854 59.3471 82.5071L63.3031 84.8046C63.4289 85.5829 63.7458 86.0769 64.1791 86.3286Z" fill="#DAD9E8"/>
        <path d="M63.303 84.8045L59.347 82.5069C59.347 82.5069 59.347 82.4976 59.347 82.4929L63.303 84.7905C63.303 84.7905 63.303 84.7998 63.303 84.8045Z" fill="#DAD9E8"/>
        </g>
        <path d="M67.5851 45.0463L71.5411 47.3438C70.3855 46.6728 68.7872 46.7566 66.9933 47.7912L63.0373 45.4937C64.8312 44.4591 66.4295 44.3752 67.5851 45.0463Z" fill="#DAD9E8"/>
        <mask id="mask5_6057_6221"  maskUnits="userSpaceOnUse" x="63" y="44" width="9" height="4">
        <path d="M67.5851 45.0463L71.5411 47.3438C70.3855 46.6728 68.7872 46.7566 66.9933 47.7912L63.0373 45.4937C64.8312 44.4591 66.4295 44.3752 67.5851 45.0463Z" fill="white"/>
        </mask>
        <g mask="url(#mask5_6057_6221)">
        <path d="M66.9933 47.7912L63.0373 45.4937C64.8312 44.4591 66.4295 44.3752 67.5851 45.0463L71.5411 47.3438C70.3855 46.6728 68.7872 46.7566 66.9933 47.7912Z" fill="#DAD9E8"/>
        </g>
        <path d="M63.3029 84.79L59.3469 82.4877L55.5446 58.3657L59.5006 60.6679L63.3029 84.79Z" fill="#DAD9E8"/>
        <path d="M65.7866 48.4908L61.8306 46.1885L63.0374 45.4941L66.9934 47.7917L65.7866 48.4908Z" fill="#EBEBF1"/>
        <path d="M59.5007 60.6684L55.5447 58.3709C54.897 54.2651 57.8931 48.4676 61.8352 46.1934L65.7911 48.4909C61.8491 50.7652 58.853 56.5627 59.5007 60.6684Z" fill="#EBEBF1"/>
        <mask id="mask6_6057_6221"  maskUnits="userSpaceOnUse" x="55" y="46" width="11" height="15">
        <path d="M59.5007 60.6684L55.5447 58.3709C54.897 54.2651 57.8931 48.4676 61.8352 46.1934L65.7911 48.4909C61.8491 50.7652 58.853 56.5627 59.5007 60.6684Z" fill="white"/>
        </mask>
        <g mask="url(#mask6_6057_6221)">
        <path d="M59.5008 60.6685L55.5448 58.371C55.3491 57.122 55.4889 55.7192 55.8896 54.3025L59.8456 56.6C59.4449 58.0121 59.3051 59.4196 59.5008 60.6685Z" fill="#EBEBF1"/>
        <path d="M59.8456 56.5995L55.8896 54.3019C56.3416 52.7081 57.1197 51.1049 58.1262 49.6975L62.0822 51.9951C61.0804 53.4025 60.2976 55.0057 59.8456 56.5995Z" fill="#EBEBF1"/>
        <path d="M62.0775 51.9951L58.1215 49.6976C58.8904 48.621 59.785 47.661 60.7635 46.906L64.7195 49.2036C63.741 49.9585 62.8417 50.9186 62.0775 51.9951Z" fill="#EBEBF1"/>
        <path d="M64.7241 49.204L60.7681 46.9064C61.113 46.6408 61.4671 46.3984 61.8352 46.1887L65.7912 48.4863C65.4277 48.696 65.0736 48.9383 64.7241 49.204Z" fill="#EBEBF1"/>
        </g>
        <path d="M66.9934 47.7917C70.9354 45.5174 73.9502 47.843 73.3304 52.6804L69.6773 81.1039C69.3884 83.3502 67.9439 85.2237 66.4995 86.0626C65.0503 86.8968 63.6012 86.6964 63.303 84.7903L59.5008 60.6683C58.8531 56.5625 61.8492 50.765 65.7912 48.4908L66.998 47.7964L66.9934 47.7917Z" fill="white"/>
        <path d="M71.3688 47.9975L74.1226 49.6007C72.953 48.9203 71.3315 49.0181 69.5469 50.0481L66.793 48.4449C68.5823 47.4103 70.1992 47.3171 71.3688 47.9975Z" fill="#05D4F5"/>
        <mask id="mask7_6057_6221"  maskUnits="userSpaceOnUse" x="66" y="47" width="9" height="4">
        <path d="M71.3688 47.9975L74.1226 49.6007C72.953 48.9203 71.3315 49.0181 69.5469 50.0481L66.793 48.4449C68.5823 47.4103 70.1992 47.3171 71.3688 47.9975Z" fill="white"/>
        </mask>
        <g mask="url(#mask7_6057_6221)">
        <path d="M69.5469 50.0481L66.793 48.4449C68.5823 47.4103 70.1992 47.3171 71.3688 47.9975L74.1226 49.6007C72.953 48.9203 71.3315 49.0181 69.5469 50.0481Z" fill="#05D4F5"/>
        </g>
        <path d="M70.1898 52.7409C72.6221 51.3335 74.6071 52.4659 74.6164 55.2668C74.6257 58.063 72.6547 61.4791 70.2178 62.8818C67.7854 64.2893 65.7958 63.1568 65.7911 60.3606C65.7818 57.5644 67.7575 54.1437 70.1898 52.7409Z" fill="#05D4F5"/>
        <path d="M65.013 65.2864L62.2592 63.6832C61.1036 63.0121 60.3861 61.5861 60.3814 59.5542C60.3721 55.4717 63.2424 50.4944 66.7977 48.4392L69.5515 50.0424C65.9962 52.0929 63.1259 57.0702 63.1352 61.1573C63.1399 63.1846 63.8575 64.6107 65.013 65.2817V65.2864Z" fill="#05D4F5"/>
        <mask id="mask8_6057_6221"  maskUnits="userSpaceOnUse" x="60" y="48" width="10" height="18">
        <path d="M65.013 65.2864L62.2592 63.6832C61.1036 63.0121 60.3861 61.5861 60.3814 59.5542C60.3721 55.4717 63.2424 50.4944 66.7977 48.4392L69.5515 50.0424C65.9962 52.0929 63.1259 57.0702 63.1352 61.1573C63.1399 63.1846 63.8575 64.6107 65.013 65.2817V65.2864Z" fill="white"/>
        </mask>
        <g mask="url(#mask8_6057_6221)">
        <path d="M65.013 65.2868L62.2592 63.6836C61.2341 63.0871 60.5538 61.894 60.4093 60.2163L63.1632 61.8195C63.3076 63.4972 63.9879 64.6902 65.013 65.2868Z" fill="#05D4F5"/>
        <path d="M63.1632 61.8193L60.4094 60.2161C60.3908 60.0064 60.3815 59.7827 60.3815 59.559C60.3721 56.0358 62.5062 51.8508 65.3719 49.4507L68.1257 51.0538C65.2601 53.4539 63.126 57.6389 63.1353 61.1622C63.1353 61.3905 63.1446 61.6096 63.1632 61.8193Z" fill="#05D4F5"/>
        <path d="M68.1256 51.0529L65.3718 49.4498C65.8284 49.0676 66.3084 48.7274 66.7976 48.4431L69.5515 50.0463C69.0622 50.3306 68.5869 50.6661 68.1256 51.0529Z" fill="#05D4F5"/>
        </g>
        <path d="M70.1898 52.7409L69.5515 50.0472C73.1067 47.9967 75.9957 49.6418 76.005 53.7289C76.0143 57.8114 73.144 62.7886 69.5934 64.8392C66.0381 66.8898 63.1492 65.2447 63.1352 61.1622C63.1259 57.0797 65.9962 52.1024 69.5515 50.0472L70.1898 52.7409C67.7575 54.1437 65.7818 57.5644 65.7912 60.3606C65.7958 63.1568 67.7855 64.2893 70.2178 62.8818C72.6501 61.4791 74.6258 58.063 74.6164 55.2668C74.6118 52.4659 72.6268 51.3335 70.1898 52.7409Z" fill="#05D4F5"/>
        <mask id="mask9_6057_6221"  maskUnits="userSpaceOnUse" x="63" y="49" width="14" height="17">
        <path d="M70.1898 52.7409L69.5515 50.0472C73.1067 47.9967 75.9957 49.6418 76.005 53.7289C76.0143 57.8114 73.144 62.7886 69.5934 64.8392C66.0381 66.8898 63.1492 65.2447 63.1352 61.1622C63.1259 57.0797 65.9962 52.1024 69.5515 50.0472L70.1898 52.7409C67.7575 54.1437 65.7818 57.5644 65.7912 60.3606C65.7958 63.1568 67.7855 64.2893 70.2178 62.8818C72.6501 61.4791 74.6258 58.063 74.6164 55.2668C74.6118 52.4659 72.6268 51.3335 70.1898 52.7409Z" fill="white"/>
        </mask>
        <g mask="url(#mask9_6057_6221)">
        <path d="M70.1898 52.7409L69.5515 50.0472C73.1067 47.9967 75.9957 49.6418 76.005 53.7289C76.0143 57.8114 73.144 62.7886 69.5934 64.8392C66.0381 66.8898 63.1492 65.2447 63.1352 61.1622C63.1259 57.0797 65.9962 52.1024 69.5515 50.0472L70.1898 52.7409C67.7575 54.1437 65.7818 57.5644 65.7912 60.3606C65.7958 63.1568 67.7855 64.2893 70.2178 62.8818C72.6501 61.4791 74.6258 58.063 74.6164 55.2668C74.6118 52.4659 72.6268 51.3335 70.1898 52.7409Z" fill="#12B0F3"/>
        </g>
        <path d="M62.6734 3.5772L61.3324 3.98804L68.475 27.3108L69.8161 26.9L62.6734 3.5772Z" fill="#171C29"/>
        <path d="M77.6547 100.337C71.1033 96.2876 69.6262 90.8536 68.9971 88.5328C69.505 90.7604 71.2477 92.9741 74.2205 94.7637C81.6479 99.233 93.9446 99.3588 101.689 95.0433C105.011 93.1885 106.959 90.8164 107.504 88.3604C107.001 90.2851 106.241 93.1978 103.338 96.5626C102.239 97.8349 99.8157 100.207 95.9436 101.759C90.0538 104.122 82.8781 103.562 77.6547 100.337Z" fill="#919DD6"/>
        <path d="M101.815 47.233C109.056 51.5904 108.809 58.539 101.26 62.7473C93.7116 66.9557 81.7271 66.8345 74.4861 62.477C67.2451 58.1196 67.4921 51.171 75.0406 46.9627C82.5892 42.7544 94.5736 42.8756 101.815 47.233Z" fill="#E4E3EE"/>
        <path d="M68.9832 88.4676L63.1447 62.9521C63.825 65.9254 66.0895 68.8055 69.9524 71.131C79.6024 76.9425 95.5801 77.1009 105.64 71.4899C109.96 69.0805 112.485 66 113.198 62.8076L107.504 88.3557C106.954 90.8117 105.011 93.1838 101.689 95.0387C93.9493 99.3541 81.6479 99.233 74.2205 94.759C71.2477 92.9695 69.505 90.7558 68.9832 88.4629V88.4676Z" fill="white"/>
        <path d="M71.9325 46.5567C81.7922 41.2019 95.7524 41.3604 105.127 46.9342C104.293 46.4309 103.124 46.4682 101.81 47.1998C94.5688 42.8424 82.5797 42.7212 75.0358 46.9295C67.4873 51.1378 67.2403 58.0818 74.4813 62.4439C81.7223 66.8013 93.7115 66.9225 101.255 62.7142C108.804 58.5059 109.051 51.5619 101.81 47.1998C103.124 46.4682 104.293 46.4309 105.127 46.9342C107.089 48.2018 110.057 50.1219 112.122 54.1577C114.046 57.9233 113.463 61.3021 113.212 62.7468C112.48 66.023 109.955 69.1035 105.635 71.5083C95.5753 77.1193 79.5976 76.9562 69.9475 71.1494C66.0894 68.8286 63.8248 65.9531 63.1399 62.9798C62.8976 61.6842 62.6693 60.4586 62.9022 58.5385C63.1911 56.1337 64.151 54.3162 64.1929 54.2416C64.9105 52.8994 66.8209 49.3343 71.9279 46.5614L71.9325 46.5567Z" fill="white"/>
        <path d="M98.6551 15.7379C104.209 19.0793 104.018 24.4108 98.2311 27.6357C92.4439 30.8654 83.2459 30.7722 77.6916 27.426C72.1374 24.0846 72.3284 18.7578 78.1156 15.5281C83.9029 12.2985 93.1009 12.3917 98.6551 15.7379Z" fill="#EBEBF1"/>
        <path d="M70.3575 55.5978C61.4157 50.066 59.454 42.7446 59.3841 37.5762C59.3981 41.7286 62.0634 45.8763 67.4126 49.0966C78.4372 55.733 96.6935 55.9194 108.184 49.5114C114.097 46.2119 117.07 41.8125 117.056 37.4084C116.958 40.1115 116.809 41.5096 115.723 44.5388C114.871 46.9109 113.352 48.9289 112.531 50.0147C112.149 50.5227 108.603 55.0386 101.758 57.709C89.8858 62.3321 77.4354 59.9832 70.3575 55.6025V55.5978Z" fill="#B7B6E3"/>
        <path d="M71.1823 14.6936C76.4197 11.8415 82.2954 10.4387 88.6511 10.5226C95.1792 10.6065 101.041 12.1584 106.069 15.141C104.186 14.0085 101.558 14.1157 98.6553 15.7375C93.101 12.3961 83.9076 12.3029 78.1158 15.5278C72.3286 18.7574 72.1375 24.0842 77.6918 27.4257C83.246 30.7672 92.4394 30.8604 98.2312 27.6354C104.018 24.4058 104.209 19.079 98.6553 15.7375C101.558 14.1157 104.186 14.0085 106.069 15.141C108.72 16.8514 116.786 22.0616 117.042 33.1626C117.056 37.562 114.083 41.966 108.17 45.2656C96.6796 51.6736 78.4233 51.4918 67.3987 44.8555C62.0495 41.6352 59.3842 37.4828 59.3702 33.3351C59.4308 31.7226 59.496 29.891 60.2043 27.6587C61.0384 25.0117 61.7606 23.8745 62.9535 21.9917C63.8947 20.5051 66.8256 17.0704 71.1823 14.6983V14.6936Z" fill="white"/>
        <path d="M93.8791 49.6187C84.5412 50.7185 74.4765 49.1154 67.3986 44.8511C62.0773 41.6495 59.412 37.5251 59.3701 33.4006C59.3608 30.7209 60.4651 28.2463 62.3243 26.2656C62.3616 29.9659 64.7519 33.6616 69.5234 36.5371C75.8744 40.3586 84.9 41.7939 93.2733 40.8106C93.6461 43.8398 93.8697 46.939 93.8791 49.6187Z" fill="#042F8F"/>
        <path d="M59.384 37.5764L59.37 33.3355C59.384 37.4879 62.0493 41.6356 67.3985 44.8559C78.4231 51.4923 96.6794 51.6787 108.17 45.266C114.083 41.9665 117.056 37.5671 117.042 33.1631L117.056 37.404C117.07 41.8034 114.097 46.2074 108.184 49.507C96.6934 55.915 78.4371 55.7332 67.4125 49.0922C62.0633 45.8719 59.398 41.7195 59.384 37.5718V37.5764Z" fill="#DAD9E8"/>
        <path d="M64.0577 37.7025C63.4892 39.3057 62.52 39.4129 61.849 37.9449C60.7261 35.4982 61.8677 32.5435 63.3261 32.9629C63.5917 33.0375 63.7315 33.1913 63.876 33.3544C64.8079 34.6826 64.3093 36.9942 64.0577 37.7025Z" fill="#05D4F5"/>
        <path d="M80.4036 44.9539C79.9796 47.3587 76.9555 47.7641 75.4272 45.6204C73.8336 43.3834 75.581 40.8016 77.8129 41.0812C77.8968 41.0905 78.004 41.1138 78.0832 41.1278C80.0495 41.6964 80.6226 43.7096 80.4036 44.9539Z" fill="#05D4F5"/>
        <path d="M107.336 34.6077C107.336 33.1817 106.334 32.6084 105.104 33.3168C103.874 34.0252 102.872 35.7589 102.872 37.1849C102.872 38.611 103.874 39.1842 105.104 38.4758C106.339 37.7628 107.336 36.0338 107.336 34.6077Z" fill="#1393F2"/>
        <path d="M105.393 36.6307C105.179 36.6307 104.969 36.5328 104.829 36.3511C104.596 36.0388 104.661 35.6008 104.969 35.3677L121.245 23.1483C121.552 22.9153 121.995 22.9758 122.228 23.2881C122.461 23.5957 122.396 24.0384 122.088 24.2714L105.812 36.4909C105.687 36.5841 105.537 36.6307 105.393 36.6307Z" fill="#171C29"/>
        <path d="M125.802 22.8357C125.802 20.7432 124.106 19.0515 122.018 19.0515C119.931 19.0515 118.235 20.7479 118.235 22.8357C118.235 24.9236 119.931 26.6199 122.018 26.6199C124.106 26.6199 125.802 24.9236 125.802 22.8357Z" fill="#1393F2"/>
        <path d="M65.4464 3.78421C65.4464 1.69171 63.7503 0 61.6628 0C59.5753 0 57.8792 1.69637 57.8792 3.78421C57.8792 5.87205 59.5753 7.56842 61.6628 7.56842C63.7503 7.56842 65.4464 5.87205 65.4464 3.78421Z" fill="#1393F2"/>
        <path d="M110.682 61.5864L113.435 63.1895C112.266 62.5091 110.644 62.607 108.86 63.6369L106.106 62.0338C107.895 60.9992 109.512 60.906 110.682 61.5864Z" fill="#05D4F5"/>
        <mask id="mask10_6057_6221"  maskUnits="userSpaceOnUse" x="106" y="61" width="8" height="3">
        <path d="M110.682 61.5864L113.435 63.1895C112.266 62.5091 110.644 62.607 108.86 63.6369L106.106 62.0338C107.895 60.9992 109.512 60.906 110.682 61.5864Z" fill="white"/>
        </mask>
        <g mask="url(#mask10_6057_6221)">
        <path d="M108.86 63.6369L106.106 62.0338C107.895 60.9992 109.512 60.906 110.682 61.5864L113.435 63.1895C112.266 62.5091 110.644 62.607 108.86 63.6369Z" fill="#05D4F5"/>
        </g>
        <path d="M109.498 66.3305C111.93 64.9231 113.915 66.0555 113.925 68.8564C113.934 71.6526 111.963 75.0687 109.526 76.4714C107.089 77.8789 105.104 76.7464 105.099 73.9502C105.09 71.154 107.066 67.7333 109.498 66.3305Z" fill="#05D4F5"/>
        <path d="M104.321 78.8765L101.567 77.2733C100.412 76.6022 99.6943 75.1762 99.6896 73.1489C99.6803 69.0664 102.551 64.0892 106.106 62.0339L108.86 63.6371C105.304 65.6877 102.434 70.6649 102.443 74.7521C102.448 76.7793 103.166 78.2054 104.321 78.8765Z" fill="#05D4F5"/>
        <mask id="mask11_6057_6221"  maskUnits="userSpaceOnUse" x="99" y="62" width="10" height="17">
        <path d="M104.321 78.8765L101.567 77.2733C100.412 76.6022 99.6943 75.1762 99.6896 73.1489C99.6803 69.0664 102.551 64.0892 106.106 62.0339L108.86 63.6371C105.304 65.6877 102.434 70.6649 102.443 74.7521C102.448 76.7793 103.166 78.2054 104.321 78.8765Z" fill="white"/>
        </mask>
        <g mask="url(#mask11_6057_6221)">
        <path d="M104.321 78.8771L101.567 77.2739C100.542 76.6774 99.862 75.4844 99.7175 73.8066L102.471 75.4098C102.616 77.0875 103.296 78.2806 104.321 78.8771Z" fill="#05D4F5"/>
        <path d="M102.476 75.4098L99.7222 73.8067C99.7036 73.597 99.6943 73.3733 99.6943 73.1496C99.685 69.6263 101.819 65.4413 104.685 63.0413L107.439 64.6444C104.573 67.0445 102.439 71.2295 102.448 74.7527C102.448 74.9811 102.457 75.2001 102.476 75.4098Z" fill="#05D4F5"/>
        <path d="M107.439 64.6393L104.685 63.0362C105.141 62.654 105.621 62.3138 106.111 62.0295L108.864 63.6327C108.375 63.917 107.9 64.2525 107.439 64.6393Z" fill="#05D4F5"/>
        </g>
        <path d="M109.498 66.3306L108.86 63.637C112.415 61.5864 115.304 63.2362 115.313 67.3186C115.323 71.4011 112.452 76.3737 108.902 78.4289C105.346 80.4795 102.457 78.8344 102.443 74.7519C102.434 70.6694 105.304 65.6922 108.86 63.637L109.498 66.3306C107.066 67.7334 105.09 71.1541 105.099 73.9503C105.104 76.7465 107.094 77.879 109.526 76.4716C111.958 75.0688 113.934 71.6528 113.925 68.8566C113.92 66.0557 111.935 64.9232 109.498 66.3306Z" fill="#05D4F5"/>
        <mask id="mask12_6057_6221"  maskUnits="userSpaceOnUse" x="102" y="62" width="14" height="18">
        <path d="M109.498 66.3306L108.86 63.637C112.415 61.5864 115.304 63.2362 115.313 67.3186C115.323 71.4011 112.452 76.3737 108.902 78.4289C105.346 80.4795 102.457 78.8344 102.443 74.7519C102.434 70.6694 105.304 65.6922 108.86 63.637L109.498 66.3306C107.066 67.7334 105.09 71.1541 105.099 73.9503C105.104 76.7465 107.094 77.879 109.526 76.4716C111.958 75.0688 113.934 71.6528 113.925 68.8566C113.92 66.0557 111.935 64.9232 109.498 66.3306Z" fill="white"/>
        </mask>
        <g mask="url(#mask12_6057_6221)">
        <path d="M109.498 66.3306L108.86 63.637C112.415 61.5864 115.304 63.2362 115.313 67.3186C115.323 71.4011 112.452 76.3737 108.902 78.4289C105.346 80.4795 102.457 78.8344 102.443 74.7519C102.434 70.6694 105.304 65.6922 108.86 63.637L109.498 66.3306C107.066 67.7334 105.09 71.1541 105.099 73.9503C105.104 76.7465 107.094 77.879 109.526 76.4716C111.958 75.0688 113.934 71.6528 113.925 68.8566C113.92 66.0557 111.935 64.9232 109.498 66.3306Z" fill="#12B0F3"/>
        </g>
        <path d="M110.714 104.107L106.758 101.81C106.325 101.558 106.004 101.06 105.878 100.272L109.834 102.57C109.96 103.357 110.276 103.856 110.714 104.107Z" fill="#DAD9E8"/>
        <mask id="mask13_6057_6221"  maskUnits="userSpaceOnUse" x="105" y="100" width="6" height="5">
        <path d="M110.714 104.107L106.758 101.81C106.325 101.558 106.004 101.06 105.878 100.272L109.834 102.57C109.96 103.357 110.276 103.856 110.714 104.107Z" fill="white"/>
        </mask>
        <g mask="url(#mask13_6057_6221)">
        <path d="M110.714 104.108L106.758 101.81C106.325 101.558 106.008 101.064 105.882 100.286L109.838 102.584C109.964 103.362 110.281 103.856 110.714 104.108Z" fill="#DAD9E8"/>
        <path d="M109.838 102.584L105.882 100.286C105.882 100.286 105.882 100.277 105.882 100.272L109.838 102.57C109.838 102.57 109.838 102.579 109.838 102.584Z" fill="#DAD9E8"/>
        </g>
        <path d="M114.121 62.8261L118.077 65.1236C116.921 64.4525 115.323 64.5364 113.529 65.571L109.573 63.2735C111.367 62.2389 112.965 62.155 114.121 62.8261Z" fill="#DAD9E8"/>
        <mask id="mask14_6057_6221"  maskUnits="userSpaceOnUse" x="109" y="62" width="10" height="4">
        <path d="M114.121 62.8261L118.077 65.1236C116.921 64.4525 115.323 64.5364 113.529 65.571L109.573 63.2735C111.367 62.2389 112.965 62.155 114.121 62.8261Z" fill="white"/>
        </mask>
        <g mask="url(#mask14_6057_6221)">
        <path d="M113.529 65.571L109.573 63.2735C111.367 62.2389 112.965 62.155 114.121 62.8261L118.077 65.1236C116.921 64.4525 115.323 64.5364 113.529 65.571Z" fill="#DAD9E8"/>
        </g>
        <path d="M109.834 102.57L105.878 100.268L102.076 76.1455L106.032 78.4477L109.834 102.57Z" fill="#DAD9E8"/>
        <path d="M112.322 66.2705L108.366 63.9683L109.573 63.2739L113.529 65.5715L112.322 66.2705Z" fill="#EBEBF1"/>
        <path d="M106.032 78.4475L102.076 76.1499C101.428 72.0441 104.424 66.2467 108.366 63.9724L112.322 66.27C108.38 68.5442 105.384 74.3417 106.032 78.4475Z" fill="#EBEBF1"/>
        <mask id="mask15_6057_6221"  maskUnits="userSpaceOnUse" x="101" y="63" width="12" height="16">
        <path d="M106.032 78.4475L102.076 76.1499C101.428 72.0441 104.424 66.2467 108.366 63.9724L112.322 66.27C108.38 68.5442 105.384 74.3417 106.032 78.4475Z" fill="white"/>
        </mask>
        <g mask="url(#mask15_6057_6221)">
        <path d="M106.032 78.4478L102.076 76.1503C101.88 74.9013 102.02 73.4985 102.421 72.0818L106.377 74.3793C105.976 75.7914 105.836 77.1989 106.032 78.4478Z" fill="#EBEBF1"/>
        <path d="M106.377 74.379L102.421 72.0815C102.873 70.4876 103.651 68.8845 104.657 67.4771L108.613 69.7746C107.611 71.182 106.829 72.7852 106.377 74.379Z" fill="#EBEBF1"/>
        <path d="M108.613 69.7749L104.657 67.4773C105.426 66.4008 106.321 65.4408 107.299 64.6858L111.255 66.9833C110.277 67.7383 109.377 68.6984 108.613 69.7749Z" fill="#EBEBF1"/>
        <path d="M111.255 66.9828L107.299 64.6852C107.644 64.4196 108.003 64.1772 108.366 63.9675L112.322 66.2651C111.959 66.4748 111.605 66.7171 111.255 66.9828Z" fill="#EBEBF1"/>
        </g>
        <path d="M113.529 65.5705C117.471 63.2963 120.486 65.6218 119.866 70.4592L116.213 98.8828C115.924 101.129 114.479 103.003 113.035 103.841C111.586 104.676 110.137 104.475 109.838 102.569L106.036 78.4471C105.389 74.3413 108.385 68.5485 112.327 66.2696L113.534 65.5752L113.529 65.5705Z" fill="white"/>
        </svg>
        
    )
}

export default Bot