import React from "react";
import ActionButton from "./ActionButton";

function PrimaryBtn(props){
    return(
        <ActionButton
            {...props}
            variant="contained"
            color="primary"
            title={props.title || 'Primary'}
            //disabled={props.disabled}
            type="submit"
        />
    )
}
export default PrimaryBtn;