import React, { useState } from "react";
import { Circle, Line, Rect } from "react-konva";
// import uuid from "react-uuid";
import { useUID } from 'react-uid';

function Anchor(props) {
  const [strokeWidth, setStrokeWidth] = useState(2);
//   const uid = useUID();

  return (
    <Circle
      x={props.point.x}
      y={props.point.y}
      radius={5}
      stroke="#666"
      fill={props.fill}
      strokeWidth={strokeWidth}
      onMouseOver={() => {
        document.body.style.cursor = "pointer";
        setStrokeWidth(3);
        props.onMouseOver();
      }}
      onMouseOut={() => {
        document.body.style.cursor = "pointer";
        setStrokeWidth(2);
        props.onMouseOut();
      }}
      onClick={() => {
        document.body.style.cursor = "pointer";
        props.onClick();
      }}
    />
  );
}

function PolygonOriginAnchor(props) {
  const isValid = props.validateMouseEvents();
  const [fill, setFill] = useState("transparent");

  return (
    <Anchor
      point={props.point}
      fill={fill}
      onClick={() => {
        if (isValid) {
          props.onValidClick();
        }
      }}
      onMouseOver={() => {
        if (isValid) {
          document.body.style.cursor = "pointer";
          setFill("green");
          props.onValidMouseOver();
        } else {
          document.body.style.cursor = "not-allowed";
          setFill("red");
        }
      }}
      onMouseOut={() => {
        setFill("transparent");
      }}
    />
  );
}

function PolygonConstructor(props) {
  const [polygons, setPolygons] = useState([]);
//   const polygonId = uuid();
const polygonId = useUID();
  const [points, setPoints] = useState([]);
  const [nextPoint, setNextPoint] = useState({ x: 0, y: 0 });
  const [isComplete, setIsComplete] = useState(false);
  const [state, setState] = useState({
    isDragging: false,
    x: 0,
    y: 0,
  })
console.log('polygons',polygons)
  const handleClick = ({ x, y }) => {

      setPoints(points.concat({ x, y }));
  };
console.log(points)
  const handlePolygonComplete = () => {
    if (points.length > 2) {
      setPolygons([...polygons, points]);
      setPoints([]);
      setIsComplete(false);
    }
  };

  return (
    <div draggable onDragStart={(e) => {
      setState({
        isDragging: true,
      });
    }}
      onDragEnd={(e) => {
        setState({
          isDragging: false,
          x: e.target.x(),
          y: e.target.y(),
        });
        setNextPoint({ x: 0, y: 0 })
      }}>
      {polygons.map((polygon, index) => (
        <Line
          key={index}
          strokeWidth={2}
          stroke="blue"
          lineJoin="round"
          closed
          fill="green"
          opacity={0.3}
          points={polygon?.flatMap((point) => [point.x, point.y])}
        />
      ))}

      <Line
        strokeWidth={2}
        stroke="blue"
        lineJoin="round"
        fill="green"
        opacity={0.3}
        closed={isComplete}
        points={points
          .flatMap((point) => [point.x, point.y])
          .concat([nextPoint.x, nextPoint.y])}
      />

      <Rect
        x={0}
        y={0}
        width={window.innerWidth}
        height={400}
        onClick={(event) => {
          if (!isComplete) {
            const x = event.evt.offsetX;
            const y = event.evt.offsetY;
            handleClick({ x, y });
          }
        }}
        onMouseMove={(event) => {
          if (!isComplete) {
            const x = event.evt.offsetX;
            const y = event.evt.offsetY;
            setNextPoint({ x, y });
          }
        }}
      />

      {points[0] && !isComplete && (
        <PolygonOriginAnchor
          point={points[0]}
          onValidClick={() => {
            handlePolygonComplete();
            setNextPoint(points[0]);
          }}
          onValidMouseOver={() => {
            setNextPoint(points[0]);
          }}
          validateMouseEvents={() => {
            return points.length > 2;
          }}
        />
      )}
    </div>
  );
}

export default PolygonConstructor;
