import React, { useEffect, useState } from 'react'
import FormModal from '../../shared-component/formmodal-component/FormModal'
import SecondaryBtn from '../../shared-component/button-component/SecondaryBtn'
import { Avatar, CircularProgress, TextField, Typography } from '@mui/material'
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn'
import NoteComponent from './NoteComponent'
import { apiService } from '../../../constant/AuthConstant'
import { enqueueSnackbar } from 'notistack'
import returnError from '../../../utils/ExportError'
import useExit from '../../../utils/useExit'

const NotesModal = ({
    open,
    title,
    onClose,
    NotesData,
    data
}) =>{
    const [notes, setNotes] = useState('');
    const [notesList, setNoteList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedNoteToEdit, setSelectedNoteToEdit] = useState(null);
    const { exitFromPlatform } = useExit()
    const handleNotes = (e) =>{
        setNotes(e.target.value)
    }
    const onCancelField = () =>{
        setNotes('')
        setSelectedNoteToEdit(null)
    }

    const getNotes = () =>{
        setLoading(true)
        apiService?.get(`leads/note/${data?.session}/`).then((res) =>{
            console.log(res)
            setLoading(false)
            setNotes('')
            setNoteList(res?.data)
        })
        .catch((error) =>{
            setLoading(false)
            if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
            }})
    }

    const editNote = (noteData, editedNote) =>{
        apiService.put(`leads/note/${noteData?.id}/`, {
            notes: editedNote
        }).then((res) =>{
            console.log(res)
            onCancelField()
            getNotes()
        })
        .catch((error) =>{
            if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
            }})
    }

    const deleteNote = (noteData) =>{
        onCancelField()
        apiService.delete(`leads/note/${noteData?.id}/`).then((res) =>{
            console.log(res)
            
            getNotes()
        })
        .catch((error) =>{
            if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
            }})
    }

    const addNote = (note) =>{
        apiService.post(`leads/note/${data?.session}/`, {
            notes: note
          }).then((res) =>{
            console.log(res)
            onCancelField()
            getNotes()
          })
          .catch((error) =>{
            if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
            }})
    }

    const onNoteSubmit = (note) =>{
        if(selectedNoteToEdit !== null){
            editNote(selectedNoteToEdit, note)
        }else{
            addNote(note)
        }
      }

    useEffect(() =>{
        getNotes()
    }, [])
    return (
        <FormModal
         open={open}
         title={title}
         onClose={onClose}
         >
         <div className='m-8'>
             {loading ? 
              <div className='h-72 flex items-center justify-center'>
                <CircularProgress size={20} />
              </div>
              : 
               notesList?.length === 0 ? 
               <div className='h-72 flex items-center justify-center'>
                 <Typography variant='subtitle2'>Please add a note.</Typography>
             </div>
            :
            <div className='h-72' style={{
              overflowY:'scroll',
              overflowX:'hidden'
            }}>
              {notesList?.map((item, index) =>{
                return (
                    <div>
                      <NoteComponent 
                      key={index} 
                      item={item} 
                      editNote={editNote}
                      setNotes={setNotes}
                      deleteNote={deleteNote}
                      setSelectedNoteToEdit={setSelectedNoteToEdit}
                      />
                    </div>
                )
              })}
          </div>}
              <div style={{
                height:'30px !important',
              }}>
                  <TextField 
                    label='Add Note'
                    placeholder='Add Note'
                    fullWidth
                    defaultValue={notes}
                    value={notes}
                    onChange={handleNotes}
                    multiline
                    inputProps={{ style: { resize: "both" } }}
                  />
              </div>
              {notes !== '' &&(
                <div className="w-full flex justify-start">
                <PrimaryBtn title="Save" onClick={() =>{
                    onNoteSubmit(notes)
                }}/>
                <SecondaryBtn onClick={onCancelField} title='Cancel' />
             </div>
              )}
         </div>
         <div className="w-full flex justify-end px-8 pb-8">
            <SecondaryBtn className="!mr-0" style={{ backgroundColor: '#EDECF9', marginRight: '0px !important' }} onClick={onClose} title='Close' />
        </div>
        </FormModal>
    )
}

export default NotesModal