import React from "react";
import BreadCrumb from "./BreadCrumb";


function BreadCrumbWithActions({
    propArr=[],
    children
}){
     
    return(
        <>
        <BreadCrumb propArr={propArr} actions>
            {children}
        </BreadCrumb>      
        </>
    )
}
export default BreadCrumbWithActions;