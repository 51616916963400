import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';

export default function PropertyCard({
  data,
  url,
  id
}) {

const navigate = useNavigate()
 
const redirectPropOverview = () =>{
  navigate(`/properties/${id}`)
}

  return (
    <Card className='cursor-pointer' key={id} sx={{ boxShadow:'none',border:'2px solid #E1EBFE',borderRadius:'8px' }} onClick={redirectPropOverview}>
      {url?.media ? 
       <CardMedia
       sx={{ height: '250px' ,  objectFit: "contain"}}
       image={`${url?.media}` ||  <>No Image</>}
       title="green iguana"
     />
      :
      <CardMedia
      sx={{ height: '250px' ,  objectFit: "contain"}}
      image={<>NoImage</>}
      component={() =>{
        return(
          <>
       <div className='w-full bg-[#EDECF9] flex items-center justify-center' style={{ height: '250px' ,  objectFit: "contain"}}>
          <BusinessIcon className="!fill-[#8E8CA3]" style={{
            width:'80px',
            height:'80px'
          }} />
       </div>
      </>
        )
      }}
      title="green iguana"
    />}
      {/* <img src={`${API_URL}media/${url.image}/`} style={{ height: '336px' ,  objectFit: "contain"}} /> */}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{
          fontWeight: 300
        }}>
         {data?.name?.length > 15 ? `${data?.name}...` : data?.name  || 'Untitled'}
        </Typography>
       
      </CardContent>
    </Card>
  );
}