import React from 'react';
import { CircularProgress } from '@mui/material';

const PopoverTemplates = ({
    loading,
    templates,
    isSubmitting,
    selectedTemplate,
    submitSelectedTemplate
}) =>{
    return(
        loading ? 
            <div className='flex items-center justify-center h-96'>
             <CircularProgress size={30} />
        </div>
        :
        <div className='flex flex-wrap max-h-[60vh] overflow-y-scroll'>
        {templates?.map((val)=>{
            return <val.element 
                     val={val} 
                     isTemplateScreen
                     isSubmitting={isSubmitting}
                     selectedTemplate={selectedTemplate}
                     onCheckSubmit={submitSelectedTemplate}
                   />
        })}
    </div>
    )
}

export default PopoverTemplates