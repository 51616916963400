import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { BoxEffects, headerButton } from '../../../../../Config/Constants';

import BreadCrumbWithActions from '../../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import LabTabs from '../../../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs';
import WebsitePages from './tabs/Pages/WebsitePages';
import WebsiteMenus from './tabs/Menus/WebsiteMenus';
import InternalThemeComponent from './tabs/Themes/InternalThemeComponent';
import RemovePopup from '../../../../shared-component/RemovePopup/RemovePopup';

import ActionButton from '../../../../shared-component/button-component/ActionButton';
import { apiService } from '../../../../../constant/AuthConstant';

import NavigateIcon from '../../../../../asset/icons/NavigateIcon';
import returnError from '../../../../../utils/ExportError';
import useExit from '../../../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';

const WebsiteInternalComponent = () => {
  const { webId } = useParams();
  const { exitFromPlatform } = useExit();
  const navigate = useNavigate();
  const tabValue = JSON.parse(localStorage.getItem('website-tabs'));
  const tab = tabValue !== null ? tabValue.toString() : '1';

  const [value, setValue] = useState(tab);
  const [websiteDetails, setWebsiteDetails] = useState(null);

  const webString = localStorage.getItem('website');
  const [loading, setLoading] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [originalThemes, setOriginalThemes] = useState([]);
  const [screenshot, setScreenshot] = useState('')

  const arr = [
    { title: 'Websites', url: '/websites' },
    { title: JSON.parse(webString), url: '/websites' },
    { title: 'Theme', url: '/' },
  ];
  const [breadcrumb, setBreadCrumb] = useState(arr);

  const handleRemoveModal = () => {
    setOpenRemoveModal(!openRemoveModal);
  };

  const handleChange = (event, newValue) => {
    const bb = [...breadcrumb];
    bb[2] = { title: event.target.innerText, url: '/' };
    console.log('from bb', bb);
    setBreadCrumb(bb);

    setValue(newValue);
  };

  const tabs = [
    {
      id: 1,
      value: '1',
      label: 'Theme',
      page: (
        <InternalThemeComponent
          loading={loading}
          websiteDetails={websiteDetails}
          selectedTheme={selectedTheme}
          originalThemes={originalThemes}
        />
      ),
    },
    {
      id: 2,
      value: '2',
      label: 'Pages',
      page: <WebsitePages />,
    },
    {
      id: 3,
      value: '3',
      label: 'Menus',
      page: <WebsiteMenus />,
    },
  ];

  const handlePageClick = () => {
    navigate(`/websites/${webId}/page/add-page`);
  };

  const handleMenuClick = () => {
    navigate(`/websites/${webId}/menu/add-menu`);
  };

  const deleteTheme = () => {
    apiService
      .delete(`website/site/${webId}`)
      .then((res) => {
        if (res.status === 204) {
          navigate('/websites');
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  const apiKeys = `${process.env.REACT_APP_APIKEY}`;

  const getScreenshot = async (web) => {
    try {
      const apiKey = apiKeys;
      const url = encodeURIComponent(`https://${web?.subdomain}${process.env.REACT_APP_SITE_DOMAIN}/`);
      const apiUrl = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${url}&key=${apiKey}`;

      const response = await axios.get(
        apiUrl
      );
      const screenshotData =
        response.data.lighthouseResult.audits['final-screenshot'].details.data;
      console.log('screenshotData', screenshotData)
      setScreenshot(screenshotData)
    } catch (error) {
      console.error('Error fetching the screenshot', error);
    }
  };

  const getWebsiteDetails = (themes) => {
    setLoading(true);
    apiService
      .get(`website/site/${webId}/`)
      .then((res) => {
        if (res?.data) {
          setWebsiteDetails(res?.data);
         //¯ getScreenshot(res?.data)
          const filterTheme = themes?.find((e) => {
            return e?.id === res?.data?.template;
          });

          if (filterTheme?.id) {
            setSelectedTheme(filterTheme);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  const retrieveAllThemes = () => {
    setLoading(true);
    apiService
      .get(`website/templates/`)
      .then((res) => {
        if (res?.data) {
          setOriginalThemes(res?.data);
          getWebsiteDetails(res?.data);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    retrieveAllThemes();
  }, []);

  const navigateToPreview = (web) => {
    window.open(
      `https://${websiteDetails?.subdomain}${process.env.REACT_APP_SITE_DOMAIN}/`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  return (
    <>
      <div>
        <Box
          sx={{
            borderBottom: BoxEffects.borderBottom,
            borderColor: 'secondary.main',
            padding: BoxEffects.padding,
            marginBottom: BoxEffects.marginButton,
            paddingLeft: BoxEffects.margin,
            paddingRight: headerButton.marginRigt,
            position: 'sticky',
            top: '0px',
            background: 'white',
            zIndex: 99,
          }}
        >
          <BreadCrumbWithActions propArr={breadcrumb}>
            <div className="flex justify-between items-center">
              <div
                style={{
                  marginLeft: '20px',
                }}
              >
                <div className="relative">
                  <ActionButton
                    style={{
                      boxShadow: 'none',
                      margin: '0px !important',
                      fontWeight: 300,
                    }}
                    exicon={<NavigateIcon />}
                    title="Visit Website"
                    disabled={!websiteDetails?.id}
                    onClick={navigateToPreview}
                  />
                  <ActionButton
                    style={{
                      boxShadow: 'none',
                      margin: '0px !important',
                      fontWeight: 300,
                      background: '#FF5C5C',
                    }}
                    title="Remove"
                    onClick={handleRemoveModal}
                  />
                  {value === '2' && (
                    <ActionButton
                      className="!mr-0"
                      style={{
                        boxShadow: 'none',
                        margin: '0px !important',
                        fontWeight: 300,
                      }}
                      title="Add New Page"
                      onClick={handlePageClick}
                    />
                  )}
                  {value === '3' && (
                    <ActionButton
                      className="!mr-0"
                      style={{
                        boxShadow: 'none',
                        margin: '0px !important',
                        fontWeight: 300,
                      }}
                      title="Add Menu"
                      onClick={handleMenuClick}
                    />
                  )}
                </div>
              </div>
            </div>
          </BreadCrumbWithActions>
        </Box>
        <LabTabs tabs={tabs} value={value || '1'} handleChange={handleChange} />
        {/* {screenshot && (
            <img src={screenshot} />
          )} */}
      </div>
      {openRemoveModal && (
        <RemovePopup
          open={openRemoveModal}
          close={handleRemoveModal}
          description={`Are you sure, you want to remove this website?`}
          onSubmit={deleteTheme}
        />
      )}
    </>
  );
};

export default WebsiteInternalComponent;
