// ----------------- Documentation ---------------------------
// this component used for show the data from analysis list within the table the table handels edit and delete the data from the firebase database


import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import { BoxEffects, headerButton, stack } from "../../../Config/Constants";
import BreadCrumbWithActions from "../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import AnalysisFilter from "./Filter/AnalysisFilter";

import LabTab from '../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs'
import Analytics from "./AnalysisTabs/Analytics";
import UsersList from "./AnalysisTabs/UserList";
import { apiService } from "../../../constant/AuthConstant";
import { enqueueSnackbar } from "notistack";
import returnError from "../../../utils/ExportError";
import SearchBar from "../../shared-component/searchbar/SearchBar";
import getDateAgo from "../../../utils/getDateAgo";
import SecondaryBtn from "../../shared-component/button-component/SecondaryBtn";
import useExit from "../../../utils/useExit";
import UserLogsFilter from "./Filter/UserLogsFilter";
import UserJourney from "./Journey/UserJourney";
 
import Sessions from "./Sessions/Sessions";
 
import FormAnalytics from "./FormAnalytics/FormAnalytics";
 


const columns = [
    { field: 'unit', headerName: 'Units/Locations',minWidth: 200},
    { field: 'property', headerName: 'Property Name',minWidth: 200},
    { field: 'impression', headerName: 'Impressions',minWidth: 200},
    { field: 'interaction', headerName: 'Interactions',minWidth: 200},
    { field: 'engagement', headerName: 'Engagements',minWidth: 200},
    { field: 'form_submission', headerName: 'Form Submissions',minWidth: 200},
  ];

  const buttonFields = [
    {
        id:1,
        label:'Today',
        value:'today',
    },
    {
        id:2,
        label:'Yesterday',
        value:'yesterday',
    },
    {
        id:3,
        label:'Last 7 days',
        value:'7',
    },
    {
        id:4,
        label:'Last 30 days',
        value:'30',
    },
    {
        id:5,
        label:'Last 90 days',
        value:'90',
    },
    {
        id:6,
        label:'Last 180 days',
        value:'180',
    },
  ]

const runForDummyData = () =>{
  const rows = [];

for (let i = 1; i <= 100; i++) {
  const lead = {
    name: `Lead ${i}`,
    phone: generatePhoneNumber(),
    email: `lead${i}@example.com`,
  };

  const property = {
    name: `Property ${i}`,
  };

  const unit = {
    name: `Unit ${i}`,
  };

  const modified = new Date(new Date().getTime() - Math.random() * 10000000000).toISOString();

  const metric = Math.random() < 0.5 ? 'IMPRESSION' : 'INTERACTION';

  const source = 'USER_SUBMITTED';

  rows.push({
    id: i.toString(),
    lead,
    property,
    unit,
    modified,
    metric,
    source,
  });
}

 

function generatePhoneNumber() {
  const firstPart = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  const secondPart = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  const thirdPart = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
  return `${firstPart}-${secondPart}-${thirdPart}`;
}
return rows
}


function Analysis(){
    const [ openAnalysisFilter,setOpenAnalysisFilter] = useState(false);
    const [ analysisList, setAnalysisList ] = useState([]);
    const [selectedProp, setSelectedProp] = useState({name:'', value:'', id:''});
    const [value, setValue] = useState('1');
    const [loadList, setLoadList] = useState(false);
    const [loadLineChart, setLoadLineChart] = useState([]);
    const [isAnalysisHasData, setIsAnalysisHasData] = useState(false);
    const [usersList, setUsersList] = useState([])
    const [openUserListFilter, setOpenUserListFilter] = useState(false);
    const [loadUserList, setLoadUserList] = useState(false);
    const [isUserListHasData, setIsUserListHasData] = useState(false);
    const [originalAnalyticsList, setOriginalAnalyticsList] = useState([])
    const [originalUsersList, setOriginalUsersList] = useState([])
    const { exitFromPlatform } = useExit()
    

    // for user list
    const [selectedUnit, setSelectedUnit] = useState({name:'', value:'', id:''});
    const [selectedEvent, setSelectedEvent] = useState({name:'', value:'', id:''});
    const [selectedButton, setSelectedButton] = useState({
      id:3,
      label:'Last 7 days',
      value:'7',
  },);
    const [isFilteringUserList, setIsFilteringUserList] = useState(false);
    const [count, setCount] = useState(1);

    // for analysis
    const [buttonSeleted, setButtonSeleted] = useState({
      id:3,
      label:'Last 7 days',
      value:'7',
  },);
    const [propertySelected, setPropertySelected] = useState({name:'', value:'', id:''});

    // for line chart 
    const [lineChartData, setLineChartData] = useState(null)
    const [impressionData, setImpressionData] = useState([])
    const [interactionData, setInteractionData] = useState([])
    const [engagementData, setEngagementData] = useState([])
    const [leadsData, setLeadsData] = useState([])
    const [formsData, setFormsData] = useState([])
    const [loadLineChartData, setLoadLineChartData] = useState(false)
    const [selectedCount, setSelectedCount] = useState(7)
    const [allProperties, setAllProperties] = useState([])
    const [percentageData, setPercentageData] = useState({})
    const [impressionStat, setImpressionStat] = useState(0);
    const [interactionStat, setInteractionStat] = useState(0);
    const [engageStat, setEngageStat] = useState(0);
    const [leadsStat, setLeadsStat] = useState(0);
    const [formStat, setFormStat] = useState(0);

    const [formAnalytics, setFormAnalytics] = useState([]);
    const [originalFormAnalytics, setOriginalFormAnalytics] = useState([]);

    const [barDetails, setBarDetails] = useState([]);
    const [faLoading, setFaLoading] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);


    // for user journey

    const [openJourneyFilter, setOpenJourneyFilter] = useState(false)
    const [journeyProperty, setJourneyProperty] = useState({name:'', id:'', value:''});


    //Form Analytics
    const [openFormAnalyticsFilter, setOpenFormAnalyticsFilter] = useState(false)

    const handleFormAnalyticsModal = () =>{
      if(selectedForm?.id){
        setOpenFormAnalyticsFilter(!openFormAnalyticsFilter)
      }else{
        enqueueSnackbar("Plaese select a form first", { variant: 'default' })
      }
    }

    const handleFilterForJourney = () =>{
      if(journeyProperty?.id === ''){
         enqueueSnackbar("Please select the property first.")
      }else{
        setOpenJourneyFilter(!openJourneyFilter)
      }
    }

    
    const defaultValuesUserList = {
      event: selectedEvent?.id ? selectedEvent : '',
      unit: selectedUnit?.id ? selectedUnit : '',
      property:isFilteringUserList === true && selectedProp?.id ? selectedProp : '',
      buttonSelection:selectedButton !== null ? selectedButton : '',
  }
    const fieldMapForUserList  = [
      
      {
        name:'property',
        label:'Property name',
        placeholder:'Property name',
        type:'select',
        fieldProps:{
          choicesAPI:"/property/prop/?filtered_data=true",
          titleKey:'name',
          setState: setSelectedProp
      }
    },
    {
      name:'unit',
      label:'Unit name',
      placeholder:'Unit Name',
      type:'select',
      fieldProps:{
        choicesAPI: selectedProp === null ? 'noAPI' : `/property/unit/?filtered_data=true&property=${selectedProp?.id}`,
        titleKey:'name',
        beforeCallMessage:'Please select valid property that have units',
    }
    },
    {
      name:'event',
      label:'Action Taken',
      placeholder:'Action Taken',
      type:'select',
      fieldProps:{
          defaultOptions: [
              {name: "Impression", id: "IMPRESSION"},
              {name: "Interaction", id: "INTERACTION"},
              {name: "Engagement", id: "ENGAGEMENT"},
              {name: "Form Submitted", id: "USER_SUBMITTED"},
          ],
          titleKey:'name',
      }
  },
      {
          name:'buttonSelection',
          label:'Today',
          placeholder:'Today',
          type:'button',
          fieldProps:{
            value:'today',
            buttonFields: buttonFields,
          }
         
      },
    ]
    const [fieldMap, setFieldMap] = useState(fieldMapForUserList);

    const toggleUserListFilter = () =>{
      setOpenUserListFilter(!openUserListFilter)
    
    }


    useEffect(() =>{
      if(selectedProp?.id){
        setFieldMap(fieldMapForUserList)
      }
    }, [selectedProp])

    const handleTabChange = (event, newVal) =>{
        setValue(newVal)
    }
    const arr1 = [
        {title: 'Analytics', url: '/'},
    ]

    let defaultValuesForFilter = {
      property:propertySelected?.id ? propertySelected : '',
      buttonSelection:buttonSeleted !== null ? buttonSeleted : 'today',
  }
    const fieldMapForFilter = [
      {
        name:'property',
        label:'Property name',
        placeholder:'Property name',
        type:'select',
        fieldProps:{
          choicesAPI:"/property/prop/?filtered_data=true",
          titleKey:'name',
      }
    },
      {
          name:'buttonSelection',
          label:'Today',
          placeholder:'Today',
          type:'button',
          fieldProps:{
            value:'today',
            buttonFields: buttonFields,
          }
         
      },
    ]


  const chartArr = [
        {
          stats: impressionStat || 0,
          statsSubtitle:'Impressions',
          lineData:impressionData,
          percentage:percentageData?.impression
        },
        {
          stats:interactionStat || 0,
          statsSubtitle:'Interactions',
          lineData:interactionData,
          percentage:percentageData?.interaction
        },
        {
          stats:engageStat || 0,
          statsSubtitle:'Engagements',
          lineData:engagementData,
          percentage:percentageData?.engagement
        },
        {
          stats: leadsStat || 0,
          statsSubtitle:'Users',
          lineData:leadsData,
          percentage:percentageData?.lead
        },
        {
          stats:formStat || 0,
          statsSubtitle:'Form Submission',
          lineData:formsData,
          percentage:percentageData?.form_submission
        },
        // {stats:'50',statsSubtitle:'Users',},
    ]
  const returnTwoYearOfData = (data) =>{
    function generateRandomData(startDate, endDate) {
      const data = {};
      const currentDate = new Date(startDate);
      const end = new Date(endDate);
      
      while (currentDate <= end) {
          const dateString = currentDate.toISOString().split('T')[0];
         
          const randomNumber = 0; // Generating a random number between 0 and 100
          data[dateString] = randomNumber;
          currentDate.setDate(currentDate.getDate() + 1); // Moving to the next day
      }
      
      return data;
  }
  
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const twoYearsAgo = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate());
  const lastTwoYearsData = generateRandomData(twoYearsAgo, today);
  
  const updatedData = Object.entries(lastTwoYearsData)?.reverse().map((e) =>{
    const find = Object.entries(data).find(([key, value]) =>{
      return key === e[0]
    })
     
    if(find){
      return [e[0], find[1]]
    }
    return [e[0], 0]
  })
 
  return Object.fromEntries(updatedData)
  }

  const getDataByDate = (data) =>{
    const itemCountByDate = data.reduce((acc, obj) => {
      const date = obj.created.slice(0, 10); // Extracting only date portion
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});
    const sortedItemCountByDate = Object.fromEntries(
      Object.entries(itemCountByDate)
        .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
    );
    return sortedItemCountByDate
  }

  const findLast7DaysData = (data) => {
    const result = {};
    
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6); // Subtract 6 to get 7 days including today
    
    for (const category in data) {
      result[category] = data[category]?.filter(item => {
        const itemDate = new Date(item?.created);
        return itemDate >= sevenDaysAgo && itemDate <= today;
      });
    }
    return result
  }
    
    function handelAnalysisFilter(){
        setOpenAnalysisFilter(!openAnalysisFilter)
    }

    const fetchProperties = () =>{
      apiService.get('property/prop/')
      .then((res) =>{
        setAllProperties(res?.data)
      })
      .catch((error) =>{
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
        }
      }
      })
    }

    const getAnalyticsLineChartData = () =>{
      setLoadLineChartData(true)
      apiService.get(`analytic/summary/`)
       .then((res) =>{
        const newD = {...res?.data}
        setPercentageData(newD?.percentage_change)
        delete newD?.comparision_data
        const newResponseData = newD //findLast7DaysData(newD)
        
         if(res?.data?.chart_data?.impression?.length > 0){
           setImpressionStat(res?.data?.impression?.length)
           const updateResponse = getDataByDate(newResponseData?.chart_data?.impression)
           setImpressionData(returnTwoYearOfData(updateResponse))
         }else{
          setImpressionStat(0)
         }
         if(res?.data?.chart_data?.interaction?.length > 0){
          setInteractionStat(res?.data?.interaction?.length)
          const updateResponse = getDataByDate(newResponseData?.chart_data?.interaction)
            setInteractionData(returnTwoYearOfData(updateResponse))
         }else{
          setInteractionStat(0)
         }
         if(res?.data?.chart_data?.engagement?.length > 0){
          setEngageStat(res?.data?.engagement?.length)
          const updateResponse = getDataByDate(newResponseData?.chart_data?.engagement)
            setEngagementData(returnTwoYearOfData(updateResponse))
         }else{
          setEngageStat(0)
         }
         if(res?.data?.chart_data?.lead?.length > 0){
          setLeadsStat(res?.data?.lead?.length)
          const updateResponse = getDataByDate(newResponseData?.chart_data?.lead)
            setLeadsData(returnTwoYearOfData(updateResponse))
         }else{
          setLeadsStat(0)
         }
          if(res?.data?.chart_data?.form_submission?.length > 0){
            setFormStat(res?.data?.form_submission?.length)
            const updateResponse = getDataByDate(newResponseData?.chart_data?.form_submission)
            setFormsData(returnTwoYearOfData(updateResponse))
          }else{
            setFormStat(0)
          }
         setLineChartData(res?.data)
         setLoadLineChartData(false)
       }) 
       .catch((error) =>{
        setLoadLineChartData(false)
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
        }
      }
       })
    }

    const getAnalyticsListData = () =>{
      setLoadList(true)
      apiService.get(`analytic/unit-count/`)
      .then((res) =>{
        const updatedData = res?.data?.map((e, i) =>{
          return {
            ...e,
            id: i + 1
          }
        })
        setAnalysisList(updatedData)
        setOriginalAnalyticsList(updatedData)
        setLoadList(false)
      })
      .catch((error) =>{
        setLoadList(false)
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
        }
      }
      })
    }

    useEffect(() =>{
      fetchProperties()
      getAnalyticsLineChartData()
      getAnalyticsListData()
    },[])


    const returnEndDate = (buttonValue) =>{
      if(buttonValue === 'today'){
         return getDateAgo(0)
      }else if(buttonValue === 'yesterday'){
         return getDateAgo(1)
      }else if(buttonValue === '7'){
         return getDateAgo(7)
      }else if(buttonValue === '30'){
         return getDateAgo(30)
      }else if(buttonValue === '90'){
         return getDateAgo(90)
      }else if(buttonValue === '180'){
         return getDateAgo(180)
      }else{
       return getDateAgo(365)
      }
 }

 const handleInputValueForAnalytics = (e) =>{
  if(e.target.value === ''){
    setAnalysisList(originalAnalyticsList)
  }else{
    const filterData = originalAnalyticsList?.filter((val) =>{
      return val?.unit?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.property?.toLowerCase().includes(e.target.value.toLowerCase())
    })
   
    setAnalysisList(filterData)
  }
}
const handleInputValueForUsers = (e) =>{
  if(e.target.value === ''){
    setUsersList(originalUsersList)
  }else{
    const filterData = originalUsersList?.filter((val) =>{
      return val?.lead?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.lead?.email?.toLowerCase().includes(e.target.value.toLowerCase()) ||
     
      val?.unit?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.lead?.phone?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.property?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.metric?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.email?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.phone?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.source?.toLowerCase().includes(e.target.value.toLowerCase())
    })
    setUsersList(filterData)
  }
}

const handleInputValueForFormAna = (e) =>{
  if(e.target.value === ''){
    setFormAnalytics(originalFormAnalytics)
  }else{
    const filterData = originalFormAnalytics?.filter((val) =>{
      console.log('from val', val)
      return val?.submission?.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.submission?.email?.toLowerCase().includes(e.target.value.toLowerCase()) ||
      val?.type?.toLowerCase().includes(e.target.value.toLowerCase())
    })
    setFormAnalytics(filterData)
  }
}

    const tabs = [
      {
        id:1, 
        label:'Analytics', 
        value:'1', 
        page: <Analytics
        arr1={arr1}
        columns={columns}
        chartArr={chartArr}
        analysisList={analysisList}
        handelAnalysisFilter={handelAnalysisFilter}
        loadList={loadList}
        isAnalysisHasData={isAnalysisHasData}
        loadLineChartData={loadLineChartData}
        selectedCount={selectedCount}
         />
      },
      {
        id:2, 
        label:'Users Logs', 
        value:'2', 
        page: <UsersList 
             loadList={loadUserList}
             usersList={usersList}
             setUsersList={setUsersList}
             setLoadList={setLoadUserList}
             isUserListHasData={isUserListHasData}
             setOriginalUsersList={setOriginalUsersList}
             allProperties={allProperties}
             count={count}
             setCount={setCount}
            />
      },
      {
        id:4, 
        label:'Sessions', 
        value:'4', 
        page: <Sessions />
      },
      // {
      //   id:3, 
      //   label:'User Journey', 
      //   value:'3', 
      //   page: <UserJourney 
      //           property={journeyProperty}
      //           setProperty={setJourneyProperty}
      //           buttonFields={buttonFields} 
      //           returnEndDate={returnEndDate}
      //           openJourneyFilter={openJourneyFilter}
      //           handleFilterForJourney={handleFilterForJourney}
      //           />
      // },
      {
        id:5, 
        label:'Form Analytics', 
        value:'5', 
        page: <FormAnalytics 
           list={formAnalytics}
           setList={setFormAnalytics}
           barDetails={barDetails}
           setBarDetails={setBarDetails}
           buttonFields={buttonFields}
           openModal={openFormAnalyticsFilter}
           closeModal={handleFormAnalyticsModal}
           loading={faLoading}
           setLoading={setFaLoading}
           setOriginalFormAnalytics={setOriginalFormAnalytics}
           setSelectedForm={setSelectedForm}
           selectedForm={selectedForm}
        />
      },
    ]

    const getPreviousDate = (datee, buttonValue) =>{
      let days;
      if(buttonValue === 'today'){
        days = 1
     }else if(buttonValue === 'yesterday'){
        days = 2
     }else if(buttonValue === '7'){
        days = 7
     }else if(buttonValue === '30'){
        days = 30
     }else if(buttonValue === '90'){
        days = 90
     }else if(buttonValue === '180'){
        days = 180
     }else{
        days = 365
     }
      var date = new Date(datee);
      date.setDate(date.getDate() - days);
      var formattedDate = date.toISOString().slice(0, 10);
      return formattedDate
    }

    const onFilterSummary = (e) =>{
      setLoadLineChartData(true)
      const newData = {
        ...e,
        property: e?.property?.id ? e?.property?.id : '',
        start_date: returnEndDate(e?.buttonSelection),
        end_date: e?.buttonSelection === 'yesterday' ? getDateAgo(1) : getDateAgo(0),
        previous_start_date: e?.buttonSelection === 'today' ? getDateAgo(1) : (e?.buttonSelection === 'yesterday' ? getDateAgo(2) : getPreviousDate(returnEndDate(e?.buttonSelection), e?.buttonSelection)),
        previous_end_date: e?.buttonSelection === 'today' ? getDateAgo(1) : (e?.buttonSelection === 'yesterday' ? getDateAgo(2) : returnEndDate(e?.buttonSelection))
       }
       Object.entries(newData)?.map((e) =>{
        if(e[1] === '' || e[1] === null || e[1] === undefined){
          delete newData[e[0]]
        }
      })
      if(e?.buttonSelection === '365'){
        setButtonSeleted({
          id:8,
          label: 'Last 365 days',
          value: '365'
        })
      }else{
        const findButton = buttonFields?.find((f) =>{
          return f?.value === e?.buttonSelection
        })
         setButtonSeleted(findButton)
      }
      if(e?.buttonSelection === 'today' || e?.buttonSelection === 'yesterday'){
        setSelectedCount(7)
      }
      if(e?.buttonSelection !== 'today' && e?.buttonSelection !== 'yesterday'){
        setSelectedCount(Number(e?.buttonSelection))
      }
      setPropertySelected(e?.property)
       delete newData?.buttonSelection
       const searchParams = new URLSearchParams(newData).toString();
       setIsFilteringUserList(true)
       apiService.get(`analytic/summary/?${searchParams}`)
      .then((res) =>{
        if(res?.data){
          const newD = {...res?.data}
          setPercentageData(newD?.percentage_change)
          delete newD?.comparision_data
          const newResponseData = newD
          if(res?.data?.chart_data?.impression?.length > 0){
            setImpressionStat(res?.data?.impression?.length)
            const updateResponse = getDataByDate(newResponseData?.chart_data?.impression)
            setImpressionData(returnTwoYearOfData(updateResponse))
          }else{
            setImpressionStat(0)
            setImpressionData({})
          }
          if(res?.data?.chart_data?.interaction?.length > 0){
            setInteractionStat(res?.data?.interaction?.length)
            const updateResponse = getDataByDate(newResponseData?.chart_data?.interaction)
              setInteractionData(returnTwoYearOfData(updateResponse))
          }else{
            setInteractionStat(0)
            setInteractionData({})
          }
          if(res?.data?.chart_data?.engagement?.length > 0){
            setEngageStat(res?.data?.engagement?.length)
            const updateResponse = getDataByDate(newResponseData?.chart_data?.engagement)
              setEngagementData(returnTwoYearOfData(updateResponse))
          }else{
            setEngageStat(0)
            setEngagementData({})
          }
          if(res?.data?.chart_data?.lead?.length > 0){
            setLeadsStat(res?.data?.lead?.length)
            const updateResponse = getDataByDate(newResponseData?.chart_data?.lead)
              setLeadsData(returnTwoYearOfData(updateResponse))
          }else{
            setLeadsStat(0)
            setLeadsData({})
          }
            if(res?.data?.chart_data?.form_submission?.length > 0){
              setFormStat(res?.data?.form_submission?.length)
              const updateResponse = getDataByDate(newResponseData?.chart_data?.form_submission)
              setFormsData(returnTwoYearOfData(updateResponse))
            }else{
              setFormStat(0)
              setFormsData({})
            }
          setLineChartData(res?.data)
         
          setLoadLineChartData(false)
        }
      })
      .catch((error) =>{
        setLoadLineChartData(false)
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
        }
        }})
    }
   
    const onFilterSubmitForTable = (e) =>{
      setLoadList(true)
      const newData = {
        ...e,
        property: e?.property?.id ? e?.property?.id : '',
        start_date: returnEndDate(e?.buttonSelection),
        end_date: e?.buttonSelection === 'yesterday' ? getDateAgo(1) : getDateAgo(0),
       }
       Object.entries(newData)?.map((e) =>{
        if(e[1] === '' || e[1] === null || e[1] === undefined){
          delete newData[e[0]]
        }
      })
       delete newData?.buttonSelection
       const searchParams = new URLSearchParams(newData).toString();
       if(e?.buttonSelection === '365'){
        setButtonSeleted({
          id:8,
          label: 'Last 365 days',
          value: '365'
        })
      }else{
        const findButton = buttonFields?.find((f) =>{
          return f?.value === e?.buttonSelection
        })
         setButtonSeleted(findButton)
      }
       setPropertySelected(e?.property)
       apiService.get(`analytic/unit-count/?${searchParams}`)
      .then((res) =>{
        //Object?.values(res?.data?.results)?.length > 0
        if(res?.data?.length > 0){
          setIsAnalysisHasData(false)
          const updatedData = res?.data?.map((e, i) =>{
            return {
              ...e,
              id: i + 1
            }
          })
          setAnalysisList(updatedData)
          handelAnalysisFilter()
          setLoadList(false)
        }else{
          setIsAnalysisHasData(true)
          setAnalysisList([])
          handelAnalysisFilter()
          setLoadList(false)
        }
      })
      .catch((error) =>{
        setLoadList(false)
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
        }
        }})
    }

    const onFilterSubmitForUser = (e) =>{
      setLoadUserList(true)
      const newData = {
        ...e,
        property: selectedProp?.id ? selectedProp?.id : '',
        start_date: returnEndDate(e?.buttonSelection),
        end_date: e?.buttonSelection === 'yesterday' ? getDateAgo(1) : getDateAgo(0),
        unit: e?.unit?.id ? e?.unit?.id : '',
        event: e?.event?.id ? e?.event?.id : '',
       }
       Object.entries(newData)?.map((e) =>{
        if(e[1] === '' || e[1] === null || e[1] === undefined){
          delete newData[e[0]]
        }
      })
      setSelectedUnit(e?.unit)
      setSelectedEvent(e?.event)
      if(e?.buttonSelection === '365'){
        setSelectedButton({
          id:8,
          label: 'Last 365 days',
          value: '365'
        })
      }else{
        const findButton = buttonFields?.find((f) =>{
          return f?.value === e?.buttonSelection
        })
        setSelectedButton(findButton)
      }
       delete newData?.buttonSelection
       const searchParams = new URLSearchParams(newData).toString();
       setIsFilteringUserList(true)
       apiService.get(`analytic/log/?${searchParams}`)
      .then((res) =>{
        if(res?.data?.results?.length > 0){
          setCount(res?.data?.count)
          setIsUserListHasData(false)
          setUsersList(res?.data?.results)
          toggleUserListFilter()
          setLoadUserList(false)
        }else{
          setIsUserListHasData(true)
          setUsersList([])
          toggleUserListFilter()
          setLoadUserList(false)
        }
      })
      .catch((error) =>{
        setLoadUserList(false)
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
        }
        }})
    }

  const onFilter = (e) =>{
    onFilterSummary(e)
    onFilterSubmitForTable(e)
  }

  // const filterUserJourney = () =>{
  //   apiService.get(``)
  // }

    return(
        <>
        <Box 
            sx={{
                borderBottom: BoxEffects.borderBottom,
                borderColor: "secondary.main",
                padding: BoxEffects.padding,
                marginBottom: BoxEffects.marginButton,
                paddingLeft: BoxEffects.margin,
                paddingRight: headerButton.marginRigt,
                position:'sticky',top:'65px',backgroundColor:'white' 
                ,zIndex:99
              }}
            >
              <BreadCrumbWithActions propArr={arr1}>
                    <div className="flex items-center">
                      {value !== "4" && (
                        <>
                          {value !== '3' &&(
                             <div className="mr-2.5">
                              <SearchBar handleSearch={
                              value === '1' ?
                              handleInputValueForAnalytics
                              :
                              (value === '5' ? handleInputValueForFormAna : handleInputValueForUsers)
                            } />
                             </div>
                          )}
                          <SecondaryBtn 
                            style={{
                              padding:'6px 16px',
                              fontSize:'15px',
                              marginRight: '0px !important',
                              boxShadow:'none',
                              marginLeft: '8px !important',
                              backgroundColor:'#EDECF9',
                              fontWeight:300
                            }} 
                            title='Filter' 
                            onClick={
                              value === '1' ?
                              handelAnalysisFilter
                              :
                              (value === '2' ? toggleUserListFilter : (value === '5' ? handleFormAnalyticsModal : handleFilterForJourney))
                            } 
                            />
                        </>
                      )}
                    </div>
                </BreadCrumbWithActions>
              
            </Box>
            <LabTab 
                value={value} 
                tabs={tabs} 
                handleChange={handleTabChange} />
           
            {/* {openAnalysisFilter && (
                <AnalysisFilter 
                    title='Filter'
                    open={openAnalysisFilter}
                    fieldMap={fieldMapForFilter}
                    onClose={handelAnalysisFilter}
                    defaultValues={defaultValuesForFilter}
                    onSubmit={onFilter}
                    onReset={onReset}
                />
            )} */}
            {openAnalysisFilter && (
              <AnalysisFilter 
                title='Filter'
                open={openAnalysisFilter}
                fieldMap={fieldMapForFilter}
                handleClose={handelAnalysisFilter}
                defaultValues={defaultValuesForFilter}
                propertySelected={propertySelected}
                buttonSeleted={buttonSeleted}
                setPropertySelected={setPropertySelected}
                setButtonSeleted={setButtonSeleted}
                onSubmit={onFilter}
                buttonFields={buttonFields}
              />
            )}
            {openUserListFilter && (
              <UserLogsFilter 
                title='Filter'
                open={openUserListFilter}
                fieldMap={fieldMap}
                handleClose={toggleUserListFilter}
                defaultValues={defaultValuesUserList}
                setPropertySelected={setSelectedProp}
                setSelectedEvent={setSelectedEvent}
                setSelectedUnit={setSelectedUnit}
                selectedUnit={selectedUnit}
                selectedEvent={selectedEvent}
                propertySelected={selectedProp}
                onSubmit={onFilterSubmitForUser}
                setSelectedButton={setSelectedButton}
                selectedButton={selectedButton}
                buttonFields={buttonFields}s
              />
            )}

           

        </>
    )
}
export default Analysis;