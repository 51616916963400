import React from 'react';
import { Box, CircularProgress, Skeleton, Stack, Card } from "@mui/material";

import { BoxEffects, headerButton, stack } from "../../../../Config/Constants";
import CardWithApex from "../../../shared-component/apex-card/CardWithApex";
import BreadCrumbWithActions from "../../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import DataGridTable from "../../../shared-component/table-component/DataGridTable";
import { SecondaryButton } from "@assystant/mui-buttons";

import Loading from '../../../shared-component/Loading/Loading';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import TrandingIcon from '../../../../asset/icons/TrandingIcon';
import DataErrorIcon from '../../../../asset/icons/DataErrorIcon';


const Analytics = ({
    arr1,
    loadList,
    columns,
    chartArr,
    analysisList,
    handelAnalysisFilter,
    isAnalysisHasData,
    loadLineChartData,
    selectedCount
}) =>{
  console.log('from new Data', chartArr)
    return (
        <>
         <div className='w-full'>
         {loadList ? 
         <Loading />
         :
          <>
          {analysisList?.length === 0 ?  
          <>
           {isAnalysisHasData === true ? 
           <EmptyCard 
           icon={
            <div className='flex justify-center'>
                <DataErrorIcon 
               width={40}
               height={40} 
             />
            </div>
           }
           title="No Analytics found in the selected filter"
           subtitle="Use a different filte, We'll look again."
         />
        :
        <EmptyCard 
            icon={
             <div className='flex justify-center'>
                 <TrandingIcon 
                width={40}
                height={40} 
              />
             </div>
            }
            title="No Analytics Available"
            subtitle="Stay tuned if analytics found will show up here"
          />}
          </>
         :
         <>
           <Stack className={selectedCount === 180 || selectedCount === 365 ? '!grid grid-cols-1'
           :
           '!grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3'}  direction="row" spacing={stack.space}
                 sx={{
                    margin: BoxEffects.margin,
                    position: "relative",
                    // display:'grid',
                    // gridTemplateColumns: 'auto auto auto auto auto',
                     rowGap: '20px',
                     columnGap: '20px',
                  }}
            
            >
            {chartArr.map((e,i)=>{
                return(
                    <>
                     {loadLineChartData ?
                     <Card sx={{height:"250px", boxShadow:'none'}}>
                       <Skeleton animation="wave" variant="rectangular" className='w-full !h-full' />
                     </Card>
                     :
                     <CardWithApex 
                      stats={e.stats} 
                      statsSubtitle={e.statsSubtitle} 
                      lineData={e?.lineData}
                      selectedCount={selectedCount}
                      percentage={e.percentage}
                    />}
                    </>
                )
            })}
           
                
            </Stack>
            <Box
                 sx={{
                    margin: BoxEffects.margin,
                    position: "relative",
                  }}
            >
            {/* {analysisList.length === 0 ? <Loading/> : <DataGridTable columns={columns} rows={analysisList} />} */}
            {loadList ? <Loading />
              :
              <DataGridTable columns={columns} rows={analysisList}/>
            }
            </Box>
            </>}
          </>
         }
            
         </div>
        </>
    )
}

export default Analytics;