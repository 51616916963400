import React from 'react';
import { TableRow, TableCell, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import formatISOString from '../../../../utils/formatISOString';
import Chat from '../../../../asset/icons/Chat';
import Note from '../../../../asset/icons/Note';
import DeleteIcon from '../../../../asset/icons/DeleteIocn';
import ProfileContainer from './ProfileContainer';
import SelectComponent from '../../../components/booking/Modal/SelectComponent';

const HistoryRow = ({
  row,
  isbooking = false,
  isDetails = false,
  editModalToggling,
  notesModalToggling,
  deleteModalToggling,
  navigateToDetails,
  openDrawerWithFormDetails,
  openDrawerWithUnitDetails,
  openDrawerWithPropertyDetails,
  formSubmissionList,
  changeBookingStatus
}) => {
  const [open, setOpen] = React.useState(false);

  const checkIsAvailable = (id) => {
    const check = formSubmissionList?.some((e) => {
      return e?.id === id;
    });
    return check;
  };
 
  return (
    <>
      <TableRow
        className="cursor-pointer"
        sx={{ '& > *': { borderBottom: 'unset' } }}
        onClick={() => {
          if (navigateToDetails) {
            if(isbooking === true){
              navigateToDetails(row?.id);
            }else{
              navigateToDetails(row?.session);
            }
          }
        }}
      >
        <TableCell className={!open ? `` : `!border-b-0`}>
          {row?.history?.length > 0 ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={(e) => {
                e?.stopPropagation();
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={(e) => {
                  e?.stopPropagation();
                  setOpen(!open);
                }}
                sx={{ visibility: 'hidden' }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="left">{row.email || '-'}</TableCell>
        <TableCell align="left">{row.phone || '-'}</TableCell>
        <TableCell align="left">
          {formatISOString(row.created) || '-'}
        </TableCell>
        <TableCell
          align="left"
          onClick={(e) => {
            e.stopPropagation();
            if (row?.property?.name) {
              if(openDrawerWithPropertyDetails){
                openDrawerWithPropertyDetails(row.property);
              }
            }
          }}
        >
          {row.property?.name || '-'}
        </TableCell>
        <TableCell
          align="left"
          onClick={(e) => {
            e.stopPropagation();
            if (row?.unit?.name) {
              if(openDrawerWithUnitDetails){
                openDrawerWithUnitDetails(row);
              }
            }
          }}
        >
          {row.unit?.name || '-'}
        </TableCell>
        <TableCell align="left">
          {isbooking === false ? (
            <>
              {row?.lead_source?.name ? (
                <ProfileContainer
                  profile={{
                    name: row?.lead_source?.name,
                    email: row?.lead_source?.email,
                  }}
                />
              ) : row?.lead_source && !row?.lead_source?.name ? (
                <div
                  style={{
                    fontFamily: 'Rubik',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: 'normal',
                  }}
                >
                  {row?.lead_source}
                </div>
              ) : (
                '-'
              )}
            </>
          ) : (
            <>
              {row?.booking_source?.name ? (
                <ProfileContainer
                  profile={{
                    name: row?.booking_source?.name,
                    email: row?.booking_source?.email,
                  }}
                />
              ) : row?.booking_source && !row?.booking_source?.name ? (
                <div
                  style={{
                    fontFamily: 'Rubik',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: 'normal',
                  }}
                >
                  {row?.booking_source}
                </div>
              ) : (
                '-'
              )}
            </>
          )}
        </TableCell>
        <TableCell align="left">
          {isbooking === false ? (
            <>
              {row?.source === 'USER_SUBMITTED' ? (
                <div
                  style={{
                    background: '#24D315',
                    borderRadius: '25px',
                    padding: '5px 10px',
                    color: '#fff',
                    textAlign: 'center',
                    fontFamily: 'Rubik',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: 'normal',
                  }}
                >
                  {`User Completed`}
                </div>
              ) : row?.source === 'STAFF_ADDED' ? (
                <ProfileContainer profile={row?.employee} />
              ) : (
                <div
                  style={{
                    background: '#FAD200',
                    borderRadius: '25px',
                    padding: '5px 10px',
                    color: '#fff',
                    textAlign: 'center',
                    fontFamily: 'Rubik',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: 'normal',
                  }}
                >
                  {`AI Found`}
                </div>
              )}
            </>
          ) : (
            <>
              {row?.employee?.name && row?.source === 'USER_SUBMITTED' ? (
               <ProfileContainer profile={row?.employee} />
              ) : row?.source === 'USER_SUBMITTED' ? (
                <div
                style={{
                  background: '#24D315',
                  borderRadius: '25px',
                  padding: '5px 10px',
                  color: '#fff',
                  textAlign: 'center',
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                }}
              >
                {`User Completed`}
              </div>
              ) : (
                <div
                  style={{
                    background: '#FAD200',
                    borderRadius: '25px',
                    padding: '5px 10px',
                    color: '#fff',
                    textAlign: 'center',
                    fontFamily: 'Rubik',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: 'normal',
                  }}
                >
                  {`AI Found`}
                </div>
              )}
            </>
          )}
        </TableCell>
        
        {isbooking === true  && (
          <>
          {row?.source === 'USER_SUBMITTED' || row?.employee?.id ? (
            <TableCell>
               <SelectComponent row={row} changeStatus={changeBookingStatus} booking_status={row?.booking_status} />
            </TableCell>
          ) : (<></>)}
           {row?.source !== 'USER_SUBMITTED' && !row?.employee?.id ? (
            <TableCell>
               {'-'}
            </TableCell>
          ) : (<></>)}
          </>
          
        )}
        {editModalToggling && (
          <TableCell>
            <div className="flex items-center justify-center">
              <div
                className="mx-2 cursor-pointer"
                onClick={(e) => {
                  e?.stopPropagation();
                  notesModalToggling(row);
                }}
              >
                <Chat />
              </div>
              <div
                className="mx-2 cursor-pointer"
                onClick={(e) => {
                  e?.stopPropagation();
                  editModalToggling(row);
                }}
              >
                <Note />
              </div>
              <div
                className="mx-2 cursor-pointer"
                onClick={(e) => {
                  e?.stopPropagation();
                  deleteModalToggling(row?.id);
                }}
              >
                <DeleteIcon />
              </div>
            </div>
          </TableCell>
        )}
        {row?.source === 'USER_SUBMITTED' &&
        row?.form?.id &&
        !isDetails &&
        checkIsAvailable(row?.form?.id) === true ? (
          <TableCell className="border-t">
            <div
              className="text-[#004FE8]"
              style={{
                fontFamily: 'Rubik',
                fontSize: '14px',
                fontWeight: 300,
                lineHeight: '23.7px',
                letterSpacing: '-0.022em',
                width: '100px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (row?.form?.id) {
                  if(openDrawerWithFormDetails){
                    openDrawerWithFormDetails(row?.form);
                  }
                }
              }}
            >
              Form Details
            </div>
          </TableCell>
        ) : (
          <TableCell className="border-t">
            <div
              className="text-[#8E8CA3]"
              style={{
                fontFamily: 'Rubik',
                fontSize: '14px',
                fontWeight: 300,
                lineHeight: '23.7px',
                letterSpacing: '-0.022em',
                width: '100px',
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Form Details
            </div>
          </TableCell>
        )}
      </TableRow>
      {open === true ? (
        <>
          {row?.history?.map((item, index) => {
            return (
              <>
                <TableRow
                  className="cursor-pointer"
                  onClick={() => {
                     if (navigateToDetails) {
                         if(isbooking === false){
                            navigateToDetails(item?.session);
                         }
                    }
                  }}
                >
                  <TableCell
                    className={
                      index === row?.history?.length - 1
                        ? `!border-t-0`
                        : `!border-t-0 !border-b-0`
                    }
                  />
                  <TableCell component="th">{item?.name || '-'}</TableCell>
                  <TableCell>{item?.email || '-'}</TableCell>
                  <TableCell>{item?.phone || '-'}</TableCell>
                  <TableCell>
                    {formatISOString(item?.modified) || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item?.property?.name) {
                        if(openDrawerWithPropertyDetails){
                          openDrawerWithPropertyDetails(item.property);
                        }
                      }
                    }}
                  >
                    {item.property?.name || '-'}
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item?.unit?.name) {
                        if(openDrawerWithUnitDetails){
                          openDrawerWithUnitDetails(item);
                        }
                      }
                    }}
                  >
                    {item.unit?.name || '-'}
                  </TableCell>
                  <TableCell align="left">
                    {item?.lead_source?.name ? (
                      <ProfileContainer
                        profile={{
                          name: item?.lead_source?.name,
                          email: item?.lead_source?.email,
                        }}
                      />
                    ) : item?.lead_source && !item?.lead_source?.name ? (
                      <div
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 300,
                          lineHeight: 'normal',
                        }}
                      >
                        {item?.lead_source}
                      </div>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {item?.source === 'USER_SUBMITTED' ? (
                      <div
                        style={{
                          background: '#24D315',
                          borderRadius: '25px',
                          padding: '5px 10px',
                          color: '#fff',
                          textAlign: 'center',
                          fontFamily: 'Rubik',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 300,
                          lineHeight: 'normal',
                        }}
                      >
                        {`User Completed`}
                      </div>
                    ) : item?.source === 'STAFF_ADDED' ? (
                      <ProfileContainer profile={item?.employee} />
                    ) : (
                      <div
                        style={{
                          background: '#FAD200',
                          borderRadius: '25px',
                          padding: '5px ',
                          color: '#fff',
                          textAlign: 'center',
                          fontFamily: 'Rubik',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 300,
                          lineHeight: 'normal',
                        }}
                      >
                        {`AI Found`}
                      </div>
                    )}
                  </TableCell>
                  {isbooking === true && (
                    <TableCell>
                        {'-'}
                    </TableCell>
                  )}
                  {deleteModalToggling && (
                    <TableCell>
                      <div className="flex items-center justify-center">
                        <div className="mx-5"></div>
                        <div className="mx-5"></div>
                        <div
                          className="mx-2 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            deleteModalToggling(item?.id);
                          }}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </TableCell>
                  )}
                  {item?.source === 'USER_SUBMITTED' &&
                  item?.form?.id &&
                  !isDetails ? (
                    <TableCell className="border-t">
                      <div
                        className="text-[#004FE8]"
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '14px',
                          fontWeight: 300,
                          lineHeight: '23.7px',
                          letterSpacing: '-0.022em',
                          width: '100px',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item?.form?.id) {
                            if(openDrawerWithFormDetails){
                              openDrawerWithFormDetails(item?.form);
                            }
                          }
                        }}
                      >
                        Form Details
                      </div>
                    </TableCell>
                  ) : (
                    <TableCell className="border-t">
                      <div
                        className="text-[#8E8CA3]"
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '14px',
                          fontWeight: 300,
                          lineHeight: '23.7px',
                          letterSpacing: '-0.022em',
                          width: '100px',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        Form Details
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              </>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default HistoryRow;
