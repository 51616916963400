import React, { useContext, useState } from 'react'
import FormModal from '../../../../../shared-component/formmodal-component/FormModal'
import FormManager from '../../../../../shared-component/formrelated-component/FormManager'
import { quickMenuConstant } from '../../../../../../Config/Constants'
import { apiService } from '../../../../../../constant/AuthConstant'
import { enqueueSnackbar } from 'notistack'
import returnError from '../../../../../../utils/ExportError'
import useExit from '../../../../../../utils/useExit'
import UserContext from '../../../../../../context/UserContext'

const AddUser = ({
    open,
    data,
    title,
    onClose,
}) => {
    const { exitFromPlatform } = useExit()
    const [loading, setLoading] = useState(false)
    const { organization } = useContext(UserContext)
    const defaultValues = {
        full_name: '',
        email:'',
        position:'',
        role:'',
    }

    const submitNewUser = (payload) =>{
        if(payload?.full_name === ''){{
            enqueueSnackbar('Plaese add Name', { variant: 'error' })
        }}
        else if(payload?.email === ''){
            enqueueSnackbar('Plaese add Email', { variant: 'error' })
        }
        else if(payload?.position === ''){
            enqueueSnackbar('Plaese add Position', { variant: 'error' })
        }
        else if(payload?.role === ''){
            enqueueSnackbar('Plaese add Role', { variant: 'error' })
        }
        else{
            setLoading(true)
            let service;
            let apiUrl;

            if(data?.id){
                service = apiService.put;
                apiUrl = `organization/invite-user/${data?.id}/`
            }else{
                service = apiService.post;
                apiUrl = `organization/invite-user/`
            }

            service(apiUrl, {
                ...payload,
                role: payload?.role?.id,
                organization: organization?.id

            }).then((res) =>{
                setLoading(false)
                if(data?.id){
                    enqueueSnackbar('Updates', { variant: 'success' })
                }else{
                    enqueueSnackbar('Invited', { variant: 'success' })
                }
                onClose()
            }).catch((error) =>{
                if (error.response?.data) {
                    const returnedError = returnError(error.response?.data);
                    if (
                      returnedError === 'Given token not valid for any token type' ||
                      returnedError === 'Request failed with status code 401'
                    ) {
                      exitFromPlatform();
                    } else {
                      enqueueSnackbar(returnedError, { variant: 'error' });
                      setLoading(false)
                    }
                  }
            })
        }
    }

    const fieldMap = [
        {
            name: 'full_name',
            label:'Name',
            placeholder:'Name',
            type:'text',
            fieldProps: {
                required: true
            }
        },
        {
            name: 'email',
            label:'Email',
            placeholder:'Email',
            type:'text',
            fieldProps: {
                required: true
            }
        },
        {
            name: 'position',
            label:'Position',
            placeholder:'Position',
            type:'text',
            fieldProps: {
                required: true
            }
        },
        {
            name: 'role',
            label:'Role',
            placeholder:'Role',
            type:'select',
            fieldProps: {
                choicesAPI: `/rbac/role/`,
                titleKey: 'name',
                required: true
            }

        },
    ]
    return(
        <FormModal
          open={open}
          title={title}
          onClose={onClose}
        >
            <div className='mt-4'>
                <FormManager
                  onCancel={onClose}
                  defaultValues={defaultValues}
                  fieldMap={fieldMap} 
                  data={data?.id ? data : {}}
                  primaryBtnTitle='Invite'
                  formSubmit={submitNewUser}
                  loading={loading}
                />
            </div>
        </FormModal>
    )
}

export default AddUser
