import React, { useState } from 'react';
import { Box } from '@mui/material';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import { BoxEffects, headerButton } from '../../../Config/Constants';
import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import LabTab from '../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs';
import DocumentsTab from './tabs/DocumentsTab';
import FilledDocumentsTab from './tabs/FilledDocumentsTab';
import FilterFilledDocument from './modals/FilterFilledDocument';
import AddDocument from './modals/AddDocument';

const DocumentsModule = () => {
  const [value, setValue] = useState('1');
  const arr1 = [{ title: 'Documents', url: '/' }];
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false)

  const handleAddModal = () =>{
    setOpenAddModal(!openAddModal)
  }

  const handleFilterModal = () =>{
    setOpenFilterModal(!openFilterModal)
  }

  const handleTabChange = (event, newVal) => {
    setValue(newVal);
  };

  const tabs = [
    {
      id: 1,
      label: 'Documents',
      value: '1',
      page: <DocumentsTab openAddModal={openAddModal} handleAddModal={handleAddModal}  />,
    },
    {
      id: 2,
      label: 'Filled Documents',
      value: '2',
      page: <FilledDocumentsTab openFilterModal={openFilterModal} handleFilterModal={handleFilterModal} />,
    },
  ];
  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '65px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          <div className="flex justify-between items-center">
            <div
              style={{
                marginLeft: '20px',
              }}
            >
              {value === '1' && (
                <>
                  <PrimaryBtn
                    className="!mx-0"
                    style={{
                      boxShadow: 'none',
                      margin: '0px !important',
                      fontWeight: 300,
                    }}
                    title="Add Document"
                    onClick={handleAddModal}
                  />
                </>
              )}
               {value === '2' && (
                <>
                  <PrimaryBtn
                    className="!mx-0"
                    style={{
                      boxShadow: 'none',
                      margin: '0px !important',
                      fontWeight: 300,
                    }}
                    title="Filter"
                    onClick={handleFilterModal}
                  />
                </>
              )}
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>
      <LabTab value={value} tabs={tabs} handleChange={handleTabChange} />
    </>
  );
};

export default DocumentsModule;
