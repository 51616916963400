import React from 'react'
import { 
    Grid,
    Paper,
    Avatar,
    TextField,
    Typography,
    CircularProgress,
 } from '@mui/material'
import { grids } from '../../../Config/Constants'
import NoteComponent from '../leads/NoteComponent'
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons'
import EmptyCard from '../../shared-component/card-component/EmptyCard'
import DetailsCard from '../../shared-component/card-component/DetailsCard'
import HistoryTable from '../../shared-component/table-component/HistoryTable'
import GridComponent from '../../shared-component/grid-component/GridComponent'
import ProfileContainer from '../../shared-component/table-component/Row/ProfileContainer'
import LabelAndValue from '../../shared-component/OverviewPage/overview-used-components/LabelAndValue'
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn'
import SecondaryBtn from '../../shared-component/button-component/SecondaryBtn'

const headersForHistoryTable = [
    { id: "1", 
      header_name: "Name", 
      minWidth: 150 },
    { id: "2", 
      header_name: "Email", 
      minWidth: 180 },
    { id: "3", 
      header_name: "Phone No", 
      minWidth: 150 },
    { id: "4", 
      header_name: "Date & Time", 
      minWidth: 150 },
    { id: "5",  
      header_name: "Property", 
      minWidth: 150 },
    { id: "6", 
      header_name: "Unit", 
      minWidth: 150 },
    { id: "7", 
      header_name: "Source", 
      minWidth: 150 },
    { id: "8", 
      header_name: "Updated By", 
      minWidth: 200 },
  ];

const LeadDetailComponent = ({
    notes,
    details,
    leadList,
    setNotes,
    editNote,
    notesList,
    deleteNote,
    handleNotes,
    onNoteSubmit,
    notesLoading,
    onCancelField,
    setSelectedNoteToEdit,
    isReadOnly = false
}) =>{

    const contactDetails = [
        {label:'Name', value: details?.name ? details?.name : "Anonymous"},
        {label:'Email', value: details?.email ? details?.email : "Not Provided"},
        {label:'Property', value: details?.email ? details?.property?.name : "Not Provided"},
        {label:'Unit', value: details?.email ? details?.unit?.name : "Not Provided"},
        {label:'Source', value: details?.email ? details?.lead_source : "Not Provided"},
        {label: 'Updated By', value: details?.source === 'USER_SUBMITTED' ? (
          <div
            className='w-56'
            style={{
              background: '#24D315',
              borderRadius: '25px',
              padding: '5px 10px',
              color: '#fff',
              textAlign: 'center',
              fontFamily: 'Rubik',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: 'normal',
            }}
          >
            {`User Completed`}
          </div>
         ) : details?.source === 'STAFF_ADDED' ? (
          <ProfileContainer profile={details?.employee} />
        ) : (
          <div
            className='w-56'
            style={{
              background: '#FAD200',
              borderRadius: '25px',
              padding: '5px ',
              color: '#fff',
              textAlign: 'center',
              fontFamily: 'Rubik',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: 'normal',
            }}
          >
            {`AI Found`}
          </div>
        )}
    ]

   return(
       details?.id ? 
       <>
        <GridComponent>
          <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid} >
              <DetailsCard cardHeader='Contact Information'> 
                  <Grid className='!max-w-full' item xs={grids.parrentgrid} lg={grids.halfGrid} sx={{paddingLeft:0,paddingRight:0}} >
                     <LabelAndValue 
                        label=''
                        value={
                          <Avatar 
                          src={details?.user?.avatar}
                          sx={{
                          width:'60px',
                          height:'60px', 
                      }}>{details?.name?.substring(0, 1) 
                          ? details?.name?.substring(0, 1) 
                    : "Anonymous"}</Avatar>
                        }
                     />
                     <Grid container spacing={0} columns={grids.parrentgrid}>
                     {contactDetails.map((e,i)=>{
                          return(
                            <Grid
                            item
                            xs={grids.parrentgrid}
                            lg={grids.halfGrid}
                            sx={{ paddingLeft: 0, paddingRight: 0 }}
                          >
                            <LabelAndValue 
                                label={e.label} 
                                value={e.value}  
                                valueStyles={{
                                  fontFamily: 'Rubik',
                                  fontSize: '16px',
                                  fontWeight: 300,
                                  lineHeight: '28.44px',
                                }}
                              />
                          </Grid>
                          )
                      })}
                     </Grid>
                    
                      
                  </Grid>
              </DetailsCard>
              
          </Grid>
          <Grid  item xs={grids.childgrid} lg={grids.childgrid}>
              <DetailsCard cardHeader='Notes'>
                  <Grid item >
                  <div className=''>
                      {notesLoading ? 
                      <div className='flex items-center justify-center' style={{
                        height: '156px',
                      }}>
                          <CircularProgress size={20} />
                      </div>
                      : 
                      notesList?.length === 0 ? 
                      <div className='flex items-center justify-center' style={{
                        height: '156px',
                      }}>
                          <Typography variant='subtitle1' className='!font-light'>{isReadOnly ? 'Not Available' : 'Please add a note'}</Typography>
                      </div>
                      :
                      <div className='' style={{
                      overflowY:'scroll',
                      overflowX:'hidden',
                      height: '156px'
                      }}>
                      {notesList?.map((item, index) =>{
                          return (
                              <div>
                              <NoteComponent 
                              key={index} 
                              item={item} 
                              editNote={editNote}
                              setNotes={setNotes}
                              deleteNote={deleteNote}
                              setSelectedNoteToEdit={setSelectedNoteToEdit}
                              disabled={isReadOnly}
                              />
                              </div>
                          )
                      })}
                  </div>}
                      <div style={{
                          height:'30px !important',
                      }}>
                          <TextField 
                              label='Add Note'
                              placeholder='Add Note'
                              fullWidth
                              defaultValue={notes}
                              value={notes}
                              onChange={handleNotes}
                              multiline
                              disabled={isReadOnly}
                              inputProps={{ style: { resize: "both" } }}
                          />
                      </div>
                      {notes !== '' &&(
                          <div className="w-full flex justify-start">
                          <PrimaryBtn style={{
                                      boxShadow:'none',
                                      marginRight:'0px !important',
                                      fontWeight:300,
                                      padding:'6px 16px'
                                  }} title="Save" 
                            disabled={isReadOnly}
                            onClick={() =>{
                              onNoteSubmit(notes)
                          }}/>
                          <SecondaryBtn style={{ 
                              backgroundColor: '#EDECF9',
                              boxShadow:'none',
                              marginRight:'0px !important',
                              fontWeight:300,
                              padding:'6px 16px'
                              }} 
                              disabled={isReadOnly}
                              onClick={onCancelField} title='Cancel' />
                      </div>
                      )}
                  </div>
                  </Grid>
              </DetailsCard>
          </Grid>
        </GridComponent>

        <Paper className='!my-4' sx={{boxShadow:'none'}}>
        {leadList?.length === 0 ? 
           <EmptyCard 
             title="No Lead Added" 
             subtitle="Click “Add New to add the lead"/> 
          : 
          <HistoryTable 
            rowData={leadList?.length === 0 ? [] : leadList}
            headers={headersForHistoryTable}
            isDetails={true}
            />
          }
        </Paper>
       </>
       :
       <EmptyCard title='Lead Details are not available' />
   )
}

export default LeadDetailComponent