import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SpotMink from '../../../asset/Images/SpotMink.png';
import PublicGroup from '../../../asset/Images/PublicGroup.png';
import AuthLayout from '../../shared-component/AuthLayoutPage/AuthLayout';
import userContext from '../../../context/UserContext';
import { apiService } from '../../../constant/AuthConstant';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import returnError from '../../../utils/ExportError';
import useExit from '../../../utils/useExit';
import Loading from '../../shared-component/Loading/Loading';

const ActivateAccount = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { userResetpassword } = useContext(userContext);
  const { exitFromPlatform } = useExit();
  const [screenLoading, setScreenLoading] = useState(false);
  const formValues = {
    new_password: '',
    re_new_password: '',
  };
  const schema = yup.object().shape({
    new_password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Must contain min 8 characters with alphabets and numbers')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        'Must contain min 8 characters with alphabets and numbers',
      ),
    re_new_password: yup
      .string()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.new_password === value;
      }),
  });
  const field = [
    {
      id: 1,
      label: 'New Password',
      type: 'password',
      value: '',
      name: 'new_password',
    },
    {
      id: 2,
      label: 'Confirm New Password',
      type: 'password',
      value: '',
      name: 're_new_password',
    },
  ];

  const getDetails = () => {
    setScreenLoading(true);
    apiService
      .get(`account/activate/${token}/`)
      .then((res) => {
        console.log(res.data);
        setScreenLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data?.msg, { variant: 'error' })
        setScreenLoading(false);
      });
  };

  useEffect(() => {
    getDetails();
  }, []);

  const onSubmitForChange = (data) => {
    setLoading(true);
    console.log({
      ...data,
    });
    apiService
      .post(`account/activate/${token}/`, {
        ...data,
      })
      .then((response) => {
        setLoading(false);
        enqueueSnackbar('Updated', { variant: 'success' });
        setTimeout(() => {
          navigate('/');
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };
  return screenLoading ? (
    <div className="h-screen flex flex-col items-center justify-center mx-auto">
      <Loading />
    </div>
  ) : (
    <AuthLayout
      companyLogo={SpotMink}
      publicSideImage={PublicGroup}
      fields={field}
      formValues={formValues}
      onSubmit={onSubmitForChange}
      actionTitle="Submit"
      pageTitle="Change Password"
      schema={schema}
      isSubmitting={loading}
      // checkboxAction
    />
  );
};

export default ActivateAccount;
