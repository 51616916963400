// ----------------- Documentation ---------------------------
// this component used for add and edit the data from dashboad
// this component contains the card with chat
// this form used the firebase database to store the data

import React, { useContext, useEffect, useState } from "react";

import {
    Box,
    Stack,
    Typography
} from "@mui/material";
import Loading from "../../shared-component/Loading/Loading";
 
import BarChart from "./components/BarChart";
import PieChart from "./components/PieChart";
import { BoxEffects, stack } from "../../../Config/Constants";
import ContentWrapper from "./components/ContentWrapper";
import IconWrapper from "./components/IconWrapper";
import HomeWork from "../../../asset/icons/HomeWork";
import Emoji from "../../../asset/icons/Emoji";
import Groups from "../../../asset/icons/Groups";
import Group from "../../../asset/icons/Group";
import { dashboardTitles, dashboardSubTitles } from "../../../Config/Constants";
import Legends from "./components/Legends";
 
import BreadCrumbWithActions from "../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import { 
    headerButton } from "../../../Config/Constants";
 
import DataErrorIcon from "../../../asset/icons/DataErrorIcon";
 
import DashboardFilter from "./components/DashboardFilter";
import { apiService } from "../../../constant/AuthConstant";
import returnError from "../../../utils/ExportError";
import { enqueueSnackbar } from "notistack";
import useExit from "../../../utils/useExit";
import CircleOff from "../../../asset/icons/CircleOff";
import getDateAgo from "../../../utils/getDateAgo";
 
import { useMediaQuery } from 'react-responsive'
import AIbackground from '../../../asset/Images/AIbackground.png'
import Bot from "../../../asset/icons/Bot";
import { SecondaryButton } from "@assystant/mui-buttons";
import PrimaryBtn from "../../shared-component/button-component/PrimaryBtn";
import SecondaryBtn from "../../shared-component/button-component/SecondaryBtn";


function Dashboard({
    loading
}){
    const [selectedLead, setSelectedLead] = useState('')
    const [selectedInventory, setSelectedInventory] = useState('');
    const [dashboardData, setDashboardData] = useState({});
    const [openFilter, setOpenFilter] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState({name:'', value:'', id:''});
    const [buttonSelected, setButtonSeleted] = useState({
        id:3,
        label:'Last 7 days',
        value:'7',
    })
    const [loadDashboard, setLoadDashboard] = useState(false)
    const { exitFromPlatform } = useExit()
    const [inventoryColors, setInventoryColors] = useState([])
    const [leadsColors, setLeadsColors] = useState([])
    const [totalOnline, setTotalOnline] = useState('')
    const isBigScreen = useMediaQuery({ query: '(min-width: 1700px)' })

    const buttonFields = [
        {
            id:1,
            label:'Today',
            value:'today',
        },
        {
            id:2,
            label:'Yesterday',
            value:'yesterday',
        },
        {
            id:3,
            label:'Last 7 days',
            value:'7',
        },
        {
            id:4,
            label:'Last 30 days',
            value:'30',
        },
        {
            id:5,
            label:'Last 90 days',
            value:'90',
        },
        {
            id:6,
            label:'Last 180 days',
            value:'180',
        },
      ]


    const leadsLegends = [
        {id:1, title:'Added By Admin', color: '#FAD200'},
        {id:2, title:'Form Submitted', color: '#24D315'},
        {id:3, title:'AI Generated', color: '#004FE8'},
    ]

    const inventoryLegends = [
        {id:1, title:'Not Sold, Enquiry Received', color: '#FAD200'},
        {id:2, title:'Sold', color: '#FF5C5C'},
        {id:3, title:'Available', color: '#004FE8'},
    ]

    const onlineProperties = [
        {id:6, title:'Property 6',},
        {id:5, title:'Property 5',},
        {id:4, title:'Property 4',},
    ]

    const arr1 = [
        { title: 'Dashboard', url: '/' },
      ]
    
    const handleFilter = () =>{
        setOpenFilter(!openFilter)
    }

    const getDashboardData = () =>{
        setLoadDashboard(true)
        apiService.get('analytic/property-dashboard/')
        .then((res) =>{
            let newColors = [];
            if(res?.data?.units){
                if(res?.data?.units?.enquired_available !== 0){
                    newColors.push('#FAD200')
                }
                if(res?.data?.units?.sold !== 0){
                    newColors.push('#FF5C5C')
                }
                if(res?.data?.units?.available !== 0){
                    newColors.push('#004FE8')
                }
            }
            setInventoryColors(newColors)
            let colors = [];
            if(res?.data?.leads){
                if(res?.data?.leads?.staff_added !== 0){
                    colors.push('#FAD200')
                }
                if(res?.data?.leads?.user_submitted !== 0){
                    colors.push('#24D315')
                }
                if(res?.data?.leads?.user_draft !== 0){
                    colors.push('#004FE8')
                }
            }

            if(res?.data?.online_users_per_property?.length){
               if(res?.data?.online_users_per_property?.length > 1){
                const online = res?.data?.online_users_per_property?.map((e) =>{
                    return e?.active_sessions
                  }).reduce((a,b) =>{
                     return a + b
                  }, 0)
                 if(online > 0){
                    setTotalOnline(online)
                 }
               }else{
                setTotalOnline(res?.data?.online_users_per_property[0]?.active_sessions)
               }
            }
           setLeadsColors(colors)
            
           setDashboardData(res?.data)
           setLoadDashboard(false)
        })
        .catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                  exitFromPlatform()
              }else{
                  setLoadDashboard(false)
                  enqueueSnackbar(returnedError, {variant:'error'})
              }
            }
        })
    }

    useEffect(() =>{
        getDashboardData()
    }, [])

    const returnInventoryPie = (dashboad) =>{
        let data = [];
        if(dashboad?.units){
            if(dashboad?.units?.enquired_available !== 0){
                data?.push(dashboardData?.units?.enquired_available)
             }
             if(dashboad?.units?.sold !== 0){
                 data?.push(dashboardData?.units?.sold)
             }
             if(dashboad?.units?.available !== 0){
                 data?.push(dashboardData?.units?.available)
             }
        }
        return data
    }

    const returnLeadsPie = (dashboad) =>{
        let data = [];
        if(dashboad?.leads){
            if(dashboad?.leads?.staff_added !== 0){
                data?.push(dashboardData?.leads?.staff_added)
             }
             if(dashboad?.leads?.user_submitted !== 0){
                 data?.push(dashboardData?.leads?.user_submitted)
             }
             if(dashboad?.leads?.user_draft !== 0){
                 data?.push(dashboardData?.leads?.user_draft)
             }
        }
        console.log('from pie1', data)
        return data
    }

    const returnEndDate = (buttonValue) =>{
        if(buttonValue === 'today'){
           return getDateAgo(0)
        }else if(buttonValue === 'yesterday'){
           return getDateAgo(1)
        }else if(buttonValue === '7'){
           return getDateAgo(7)
        }else if(buttonValue === '30'){
           return getDateAgo(30)
        }else if(buttonValue === '90'){
           return getDateAgo(90)
        }else if(buttonValue === '180'){
           return getDateAgo(180)
        }else{
         return getDateAgo(365)
        }
   }
   
    const filterDashboard = (e) =>{
        setLoadDashboard(true)
        const newData = {
            ...e,
            property: e?.property?.id ? e?.property?.id : '',
            start_date: returnEndDate(e?.buttonSelection),
            end_date: e?.buttonSelection === 'yesterday' ? getDateAgo(1) : getDateAgo(0),
           }
           Object.entries(newData)?.map((e) =>{
            if(e[1] === '' || e[1] === null || e[1] === undefined){
              delete newData[e[0]]
            }
          })
           delete newData?.buttonSelection
           const searchParams = new URLSearchParams(newData).toString();
           if(e?.buttonSelection === '365'){
            setButtonSeleted({
              id:8,
              label: 'Last 365 days',
              value: '365'
            })
            console.log('from selected button111')
          }else{
            const findButton = buttonFields?.find((f) =>{
              return f?.value === e?.buttonSelection
            })
            console.log('from selected button', findButton)
            setButtonSeleted(findButton)
          }
          setSelectedProperty(e?.property)
           apiService.get(`analytic/property-dashboard/?${searchParams}`)
           .then((res) =>{
            // if(res?.data?.units){
            //     if(res?.data?.units?.enquired_available !== 0 && res?.data?.units?.available === 0 && res?.data?.units?.sold === 0){
            //         setSelectedInventory({
            //             data: res?.data?.units?.enquired_available,
            //             color: '#FAD200'
            //         })
            //     }
            //     else if(res?.data?.units?.enquired_available === 0 && res?.data?.units?.available !== 0 && res?.data?.units?.sold === 0){
            //         setSelectedInventory({
            //             data: res?.data?.units?.available,
            //             color: '#004FE8'
            //         })
            //     }
            //     else if(res?.data?.units?.enquired_available === 0 && res?.data?.units?.available === 0 && res?.data?.units?.sold !== 0){
            //         setSelectedInventory({
            //             data: res?.data?.units?.sold,
            //             color: '#24D315'
            //         })
            //     }
            //     else{
            //         setSelectedInventory({
            //             data: res?.data?.units?.enquired_available,
            //             color: '#FAD200'
            //         })
            //     }
            // }
            // if(res?.data?.leads){
            //     if(res?.data?.leads?.staff_added !== 0 && res?.data?.leads?.user_submitted !== 0 && res?.data?.leads?.user_draft !== 0){
            //         setSelectedLead({
            //             data: res?.data?.leads?.staff_added,
            //             color: '#FAD200'
            //         })
            //     }if(res?.data?.leads?.staff_added === 0 && res?.data?.leads?.user_submitted !== 0 && res?.data?.leads?.user_draft === 0){
            //         setSelectedLead({
            //             data: res?.data?.leads?.user_submitted,
            //             color: '#24D315'
            //         })
            //     }if(res?.data?.leads?.staff_added === 0 && res?.data?.leads?.user_submitted === 0 && res?.data?.leads?.user_draft !== 0){
            //         setSelectedLead({
            //             data: res?.data?.leads?.user_draft,
            //             color: '#004FE8'
            //         })
            //     }else{
            //         setSelectedLead({
            //             data: res?.data?.leads?.staff_added,
            //             color: '#FAD200'
            //         })
            //     }
            // }
            let newColors = [];
            if(res?.data?.units){
                if(res?.data?.units?.enquired_available !== 0){
                    newColors.push('#FAD200')
                }
                if(res?.data?.units?.sold !== 0){
                    newColors.push('#FF5C5C')
                }
                if(res?.data?.units?.available !== 0){
                    newColors.push('#004FE8')
                }
            }
            setInventoryColors(newColors)
            let colors = [];
            if(res?.data?.leads){
                if(res?.data?.leads?.staff_added !== 0){
                    colors.push('#FAD200')
                }
                if(res?.data?.leads?.user_submitted !== 0){
                    colors.push('#24D315')
                }
                if(res?.data?.leads?.user_draft !== 0){
                    colors.push('#004FE8')
                }
            }
            setLeadsColors(colors)
            if(res?.data?.online_users_per_property?.length){
                const online = data?.online_users_per_property?.map((e) =>{
                    return e?.active_sessions
                  }).reduce((a,b) =>{
                     return a + b
                  }, 0)
                 if(online > 0){
                    setTotalOnline(online)
                 }
            }
            setDashboardData(res?.data)
            setLoadDashboard(false)
            handleFilter()
           }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setLoadDashboard(false)
                  handleFilter()
              }
              }
           })
    }

    const returnTotal = (data) =>{
        console.log('from data', data)
        if(data !== undefined){
            return Object.entries(data).map((e) =>{
                if(e[0] === 'total'){
                  return 0
                }else{
                  return e[1]
                }
              })?.reduce((a, b) =>{
                 return a + b
              }, 0)
        }
    }
    const ErrorComponent = ({ title, subTitle, isOnline }) =>{
        return (
            <div className="flex flex-col justify-center items-center">
                 {isOnline ?
                  <CircleOff width={40} height={40}  />
                :
                <DataErrorIcon width={40} height={40}  />}
                  <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '20.21px',
                    letterSpacing: '-0.022em',
                    color:'#8E8CA3'
                  }}>{title}</Typography>
                  <Typography sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '15.16px',
                    letterSpacing: '-0.022em',
                    color:'#8E8CA3'
                  }}>{subTitle}</Typography>
            </div>
        )
    }
    return(
        <>{loadDashboard ? <Loading/> : 
          <>
            <Box
                sx={{ 
                    borderBottom: BoxEffects.borderBottom, 
                    borderColor: 'secondary.main', 
                    padding: BoxEffects.padding, 
                    marginBottom: BoxEffects.marginButton, 
                    paddingLeft: BoxEffects.margin, 
                    paddingRight: headerButton.marginRigt,
                    position:'sticky',
                    top:'65px',
                    backgroundColor:'white',
                    zIndex:999
                }}
            >
                    <BreadCrumbWithActions propArr={arr1}>
                        <div className='flex justify-between items-center'>
                            {/* <SearchBar /> */}
                            <div style={{
                            marginLeft:'20px'
                            }}>
                            <SecondaryBtn 
                              className="!mx-0" 
                              style={{ 
                                boxShadow: 'none', 
                                margin:'0px !important', 
                                fontWeight:300,
                                padding:'6px 16px'
                            }} 
                              title='Filter' 
                              onClick={handleFilter}
                              />
                            </div>
                        </div>
                    </BreadCrumbWithActions>
            </Box>
            <Stack className={`
            !grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 
            ${isBigScreen ? '2xl:grid-cols-2' : ''}
            `} spacing={stack.space}
              sx={{
               margin: '24px',
               position: "relative",
               rowGap: '24px',
               columnGap: '24px',
            }}>
                <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-6">
                    <ContentWrapper>
                        <div className="flex flex-col ">
                            <IconWrapper>
                                <HomeWork />
                            </IconWrapper>
                            <div className="flex items-center justify-between mt-8">
                                <div>
                                    <Typography sx={{...dashboardTitles}}>Total Properties</Typography>
                                    <Typography sx={{...dashboardSubTitles, color:'#004FE8'}}>{dashboardData?.properties?.total || 0}</Typography>
                                </div>
                                <div className="ml-6">
                                    <Typography sx={{...dashboardTitles}}>Sold Out Properties</Typography>
                                    <Typography sx={{...dashboardSubTitles, color:'#24D315'}}>{dashboardData?.properties?.sold || 0}</Typography>
                                </div>
                            </div>
                        </div>
                    </ContentWrapper>
                    <BarChart 
                      title="Hottest Properties" 
                      data={dashboardData?.interested_leads_per_property?.filter((e) =>{
                        return e?.interested_leads !== 0
                      })?.slice(0, 5)}
                      errorComponent={<div className="h-full flex items-center justify-center mt-16">
                      <ErrorComponent 
                               title="No Data Found In The Selected Filter"
                               subTitle="Use A Different Filter. We Will Look Again."
                             />
                 </div>}
                      />
                </div>
                <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-6">
                    <BarChart 
                      title="Hottest Units" 
                      data={dashboardData?.interested_leads_per_unit?.filter((e) =>{ 
                        return e?.interested_leads !== 0
                    })?.slice(0, 5)
                    }
                      errorComponent={
                        <div className="h-full flex items-center justify-center mt-16">
                             <ErrorComponent 
                               title="No Data Found In The Selected Filter"
                               subTitle="Use A Different Filter. We Will Look Again."
                             />
                        </div>
                      }
                      />
                   <div className="w-full relative" style={{
                    height:'350px'
                   }}>
                    <div style={{
                        fontFamily: 'Rubik',
                        fontSize: '32px',
                        fontWeight: 500,
                        lineHeight: '37.92px',
                        letterSpacing: '-0.022em',
                        color:'#FFFFFF',
                        position: 'absolute',
                        top:30,
                        left:30,
                        width:'35%'
                    }}>
                       {`${Number(dashboardData?.ai_advantage).toFixed(2) || 0}%`} More Leads Generated By AI.
                    </div>
                   {/* <LeadGenerator 
                       width={'100%'}
                       height={300}
                     /> */}
                     <img src={AIbackground} style={{
                        width:'100%',
                        height:'350px'
                     }} />
                    <div className="absolute" style={{
                        bottom:'40px',
                        right:'20px'
                    }}>
                       <Bot />
                    </div>
                   </div>
                </div>
                <div className="grid">
                    <ContentWrapper>
                        <div className="flex justify-between">
                            <div className="flex flex-col">
                                <IconWrapper>
                                    <Groups />
                                </IconWrapper>
                                <div className="flex items-center justify-between mt-8">
                                    <div>
                                        <Typography sx={{...dashboardTitles}}>Total Leads</Typography>
                                        <Typography sx={{...dashboardSubTitles, color:'#353349'}}>{returnTotal(dashboardData?.leads) || 0}</Typography>
                                    </div>
                                </div>
                            </div>
                            {returnLeadsPie(dashboardData)?.length === 0 ?
                             <ErrorComponent 
                             title="No Data Found In The Selected Filter"
                             subTitle="Use A Different Filter. We Will Look Again."
                           />
                            :
                            <div className="flex">
                            <div className="relative flex items-center justify-center">
                                <div className="absolute" style={{
                                    fontSize:'28px',
                                    color:selectedLead?.color
                                }}>
                                    {selectedLead?.data}
                                </div>
                                
                                <PieChart 
                                 donutColors={leadsColors}
                                 setCenterData={setSelectedLead} 
                                 data={returnLeadsPie(dashboardData)}
                                 />
                                </div>
                            <div>
                                {leadsLegends?.map((e) =>{
                                    return (
                                        <Legends 
                                           color={e?.color}
                                           title={e?.title}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                            } 
                        </div>
                    </ContentWrapper>
                </div>
                <div className="grid">
                    <ContentWrapper>
                    <div className="flex justify-between">
                            <div className="flex flex-col">
                                <IconWrapper>
                                    <Emoji />
                                </IconWrapper>
                                <div className="flex items-center justify-between mt-8">
                                    <div>
                                        <Typography sx={{...dashboardTitles}}>Total Inventory</Typography>
                                        <Typography sx={{...dashboardSubTitles, color:'#353349'}}>{returnTotal(dashboardData?.units) || 0}</Typography>
                                    </div>
                                </div>
                            </div>
                            {returnInventoryPie(dashboardData)?.length === 0 ?
                             <ErrorComponent 
                               title="No Data Found In The Selected Filter"
                               subTitle="Use A Different Filter. We Will Look Again."
                             />
                            :
                            <div className="flex">
                                <div className="relative flex items-center justify-center">
                                    <div className="absolute" style={{
                                        fontSize:'28px',
                                        color: selectedInventory?.color
                                    }}>
                                        {selectedInventory?.data}
                                    </div>
                                    
                                    <PieChart 
                                      donutColors={inventoryColors}
                                      setCenterData={setSelectedInventory} 
                                      data={returnInventoryPie(dashboardData)}
                                      />
                                    </div>
                                <div>
                                    {inventoryLegends?.map((e) =>{
                                        return (
                                            <Legends 
                                               color={e?.color}
                                               title={e?.title}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                            }
                            
                        </div>
                    </ContentWrapper>
                </div>
                <div className="grid">
                    <ContentWrapper>
                        <div className="flex justify-between">
                            <div className="flex flex-col ">
                                <IconWrapper>
                                    <div className="w-20 h-20 relative flex justify-center items-center">
                                    {dashboardData?.online_users_per_property?.length !== 0 && (
                                        <div className="w-6 h-6 rounded-full bg-[#24D315] absolute top-0 right-1" style={{
                                            border: '5px solid white'
                                        }}></div>
                                    )}
                                        <Group />
                                    </div>
                                </IconWrapper>
                                <div className="flex items-center justify-between mt-8">
                                        <div>
                                            <Typography sx={{...dashboardTitles}}>Total Online Users</Typography>
                                            <Typography sx={{...dashboardSubTitles, color:'#353349'}}>{totalOnline === '' ? 0 : totalOnline}</Typography>
                                        </div>
                                </div>
                            </div>
                            {dashboardData?.online_users_per_property === undefined || dashboardData?.online_users_per_property?.length === 0  ?
                             <ErrorComponent 
                               title="No Online Users At This Time"
                               subTitle="Stay Tuned! If Users Are Found They Will Show Up Here"
                               isOnline
                              />
                            :
                            <div>
                            {dashboardData?.online_users_per_property?.map((e) =>{
                                return(
                                     <div className="flex my-2.5">
                                         <Typography className="!mx-2" sx={{
                                            fontFamily: 'Rubik',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '18.96px',
                                            letterSpacing:' -0.022em',
                                            color:'#004FE8'
                                         }}>{e?.active_sessions}</Typography>
                                         <Typography sx={{
                                            fontFamily: 'Rubik',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            lineHeight: '18.96px',
                                            letterSpacing:' -0.022em'
                                         }}>{e?.property__name}</Typography>
                                     </div>
                                )
                            })}
                        </div>
                            }
                           
                        </div>
                    </ContentWrapper>
                </div>
            </Stack>

            {openFilter &&(
                <DashboardFilter 
                  open={openFilter}
                  handleClose={handleFilter}
                  buttonFields={buttonFields}
                  propertySelected={selectedProperty}
                  setPropertySelected={setSelectedProperty}
                  setButtonSeleted={setButtonSeleted}
                  buttonSeleted={buttonSelected}
                  onSubmit={filterDashboard}
                />
            )}
          </>
        }
        </>
    )
}
export default Dashboard;