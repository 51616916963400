import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import formatISOString from '../../../../../utils/formatISOString';

const TimelineComponent = ({
    sessionData,
}) => {

    const returnColor = (event) =>{
       if(event === "INTERACTION"){
         return "#004FE8"
       }else if(event === "ENGAGEMENT"){
        return "#FAD200"
       }else{
        return "#24D315"
       }
    } 
    const returnEventByUser = (event) =>{
        if(event === "INTERACTION"){
          return "Hovered"
        }else if(event === "ENGAGEMENT"){
         return "Clicked"
        }else{
         return "Form Submitted"
        }
     } 
  return (
    <Timeline className="!items-start">
       {sessionData?.map((e) =>{
        return (
           <div className='flex justify-between w-3/4'>
             <TimelineItem>
                <TimelineSeparator color='#8E8CA3'>
                <TimelineDot sx={{
                    backgroundColor: returnColor(e?.event),
                    boxShadow:'none'
                }} />
                <TimelineConnector className="!bg-[#8E8CA3]" />
                </TimelineSeparator>
                <TimelineContent className='!py-0'>
                    <div className='flex flex-col'>
                        <div className='mb' style={{
                            fontFamily: 'Rubik',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '16.59px',
                            letterSpacing: '-0.022em',
                            color:'#353349',
                        }}>{e?.hotspot_name}</div>
                        <div style={{
                            fontFamily: 'Rubik',
                            fontSize: '12px',
                            fontWeight: 300,
                            lineHeight: '16.59px',
                            letterSpacing: '-0.022em',
                            color:'#353349',
                            
                        }}>{returnEventByUser(e?.event)}</div>
                    </div>
                </TimelineContent>
          </TimelineItem>
               <div style={{
                            fontFamily: 'Rubik',
                            fontSize: '12px',
                            fontWeight: 300,
                            lineHeight: '16.59px',
                            letterSpacing: '-0.022em',
                            color:'#353349',
                            marginTop:'6px'
                        }}>{
                        formatISOString(e?.created)}
               </div>
           </div>
        )
       })}
    </Timeline>
  );
}

export default TimelineComponent
