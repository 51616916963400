import { Checkbox, CircularProgress, Grid } from '@mui/material'
import React, { useState } from 'react'
import {grids} from '../../../../Config/Constants'
import RegularCheckbox from '../../../shared-component/OverviewPage/overview-used-components/RegularCheckbox'

function TempDesign1({
    val,
    popupPosition,
    loading,
    unitData,
    formatted,
    popupClose,
    isTemplateScreen,
    onCheckSubmit = () =>{},
    selectedTemplate,
    isSubmitting
}) {

    const [primaryColor, setPrimaryColor] = useState('#8E8CA3');
    const [secondaryColor, setSecondaryColor] = useState('#353349');

  return (
    <div key={val?.id} className='mx-6 my-4'>
        {val && <div
                    className='mb-6 p-2 flex justify-between items-center'
                    style={{
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        color: 'black',
                        width: '350px',
                        height: 'auto',
                        border: '2px solid #E1EBFE'
                    }}
                >Template - SM1 <div><Checkbox disabled={isSubmitting} checked={selectedTemplate?.template_chosen === "TEMPLATE1"} onChange={(e)=> {
                    e.preventDefault()
                    onCheckSubmit('TEMPLATE1')
                }}/></div></div>}
                <div
                    style={{
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        width: '350px',
                        height: 'auto',
                        border: '2px solid #E1EBFE',
                        position: !val && 'absolute',
                        left:!val && `${popupPosition.x}px`,
                        top: !val && `${popupPosition.y}px`,
                        zIndex:  !val && 9999,
                        borderRadius: '8px'
                       
                    }}
                >
                    <div className='flex items-center justify-between p-4' style={{ borderRadius: '8px' }}>
                        <span style={{
                            fontFamily: 'Rubik',
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                        }}>Property Name</span>
                        <span onClick={popupClose} style={{ color: 'gray', cursor: 'pointer' }}>&#10006;</span>
                    </div>
                   {isTemplateScreen ? 
                   <>
                   <div className='p-4'>
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Unit Name</div>
                            <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                color:`${secondaryColor}`,
                                fontWeight:500,
                                width:'150px',
                                textAlign:'center'
                            }}>A101</div>
                    </div>
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`,
                                width:'150px',
                            }}>Unit Price</div>
                             <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                color:`${secondaryColor}`,
                                fontWeight:500,
                                width:'150px',
                                textAlign:'center'
                            }}>1.2Cr</div>
                    </div>
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Total Sqft.</div>
                             <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                color:`${secondaryColor}`,
                                fontWeight:500,
                                width:'150px',
                                textAlign:'center'
                            }}>1450 sqft.</div>
                    </div>
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Block</div>
                             <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                 color:`${secondaryColor}`,
                                fontWeight:500,
                                width:'150px',
                                textAlign:'center'
                            }}>A Block</div>
                    </div>
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Floor</div>
                             <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                 color:`${secondaryColor}`,
                                fontWeight:500,
                                width:'150px',
                                textAlign:'center'
                            }}>1st Floor</div>
                    </div>
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Facing</div>
                             <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                 color:`${secondaryColor}`,
                                fontWeight:500,
                                width:'150px',
                                textAlign:'center'
                            }}>East</div>
                    </div>
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Unit Type</div>
                             <div style={{
                                fontWeight:500,
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                 color:`${secondaryColor}`,
                                fontWeight:500,
                                width:'150px',
                                textAlign:'center'
                            }}>2 BHK</div>
                    </div>
                   </div>
                   </>
                   :
                   <>
                    <div className='p-4'>
                        {loading ? (
                            <div>
                                <CircularProgress size={20} />
                            </div>
                        )
                        : 
                        (
                       <>
                        <div className='my-2'>
                            <div style={{ color: 'gray' }}> Unit Name</div>
                            <div style={{ fontWeight: 500 }}>{unitData?.name}</div>
                        </div>
                        <div className='my-2'>
                            Specifications
                        </div>
                        <hr />
                        <Grid container spacing={0} columns={grids.parrentgrid}>
                            {formatted?.map((e) =>{
                            return (
                                <Grid
                                item
                                xs={grids.parrentgrid}
                                lg={grids.halfGrid}
                                sx={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <div className='my-2'>
                                    <div style={{ color: 'gray' }}>{e?.name}</div>
                                    <div style={{ fontWeight: 500 }}>{e?.value}</div>
                                </div>
                            </Grid>
                            )
                            })}
                        </Grid>
                        <hr />
                        <div className='my-2'>
                            <div style={{ color: 'gray' }}> Status</div>
                            {unitData?.status === "AVAILABLE" ? (
                                <div style={{ fontWeight:500, color: '#24D315' }}>{unitData?.status} </div>
                            )
                           :
                           (
                            <div style={{ fontWeight: 500, color: '#FF5C5C' }}>{unitData?.status} </div>
                           )}
                        </div>
                       </>
                        )}
                       
                    </div>
                   </>}

                </div>
    </div>
  )
}

export default TempDesign1