import React, { useEffect } from 'react';
import SideDrawerWrapper from '../../booking/component/SideDrawerWrapper';
import FormSubmittedDetailsComponent from '../FormSubmittedDetailsComponent';
import BookingDetailComponent from '../BookingDetailComponent';
import Loading from '../../../shared-component/Loading/Loading';
import LeadDetailComponent from '../LeadDetailComponent';
import PropertyOverviewComponent from '../PropertyOverviewComponent';
import UnitOverviewComponent from '../UnitOverviewComponent';

const DetailSideDrawer = ({
    title,
    open,
    onClose,
    formData,
    loading = false,
    fetchCallback,
    isFormComponent,
    isBookingComponent,
    isLeadComponent,
    isPropertyComponent,
    isUnitComponent,
    sideTitle,
    sideButtonClickHandler,
}) =>{
    useEffect(() =>{
        if(fetchCallback){
            fetchCallback()
        }
    }, [])
    console.log('from formdata', formData)
    return (
        <SideDrawerWrapper 
          title={title}
          open={open}
          onClose={onClose}
          onBackArrowClick={onClose}
          sideTitle={sideTitle}
          sideButtonClickHandler={sideButtonClickHandler}
          >
            {loading && (
                <Loading />
            )}

            {isFormComponent && !loading && (
                <FormSubmittedDetailsComponent 
                   sectionDetails={formData?.sectionDetails}
                   independentFields={formData?.independentFields}
                   details={formData?.details}
                   getFiles={formData?.getFiles}
                />
            )}

            {isBookingComponent && !loading && (
                <div className='m-6'>
                 <BookingDetailComponent 
                  isReadOnly={true}
                  booking={formData?.booking}
                  neighbourIcon={formData?.neighbourIcon}
                />
                </div>
            )}

            {isLeadComponent && !loading && (
                <div className='m-6'>
                    <LeadDetailComponent 
                    notes={''}
                    isReadOnly={true}
                    details={formData?.details}
                    leadList={formData?.leadList}
                    notesLoading={formData?.notesLoading}
                    notesList={formData?.notesList}
                    />
                </div>
            )}

            {isPropertyComponent && !loading && (
                <div className='m-6'>
                    <PropertyOverviewComponent
                   isReadOnly={true}
                   propertyData={formData?.propertyData}
                   itemData={formData?.propertyData?.image_list} 
                />
                </div>
            )}

            {isUnitComponent && !loading && (
                <div className='m-6'>
                 <UnitOverviewComponent 
                   isReadOnly={true}
                   unitStatus={formData?.unitStatus}
                   inventoryData={formData?.inventoryData}
                 />
                </div>
            )}
        </SideDrawerWrapper>
    )
}

export default DetailSideDrawer