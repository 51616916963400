import React from "react";
import ActionButton from "../button-component/ActionButton";
import { useFormContext } from "react-hook-form";
import { Margin } from "@mui/icons-material";
import { Grid } from "@mui/material";

function FilterButton(props){
    const { fieldProps } = props;
    const {setValue, getValues}= useFormContext();
    const handelValue = (e)=>{
        setValue(props.name, e.value)
    }
    console.log('from value', props.value)
    const endButton = {
        label: 'Last 365 days',
        value: '365'
    }
    return(
        <>
        <Grid container spacing={2} columns={16} >
            {fieldProps?.buttonFields?.map((item, index) =>{
                return(
                    <Grid key={index} item xs={8} style={{ paddingTop: 0 }}>
                        <ActionButton
                        {...props}
                        variant="contained"
                        color={getValues(props.name) === item.value? 'primary':'secondary'}
                        title={item.label || 'Secondary'}
                        onClick={() =>{
                            handelValue(item)
                        }}
                        style={{marginRight:0, marginLeft:0, width:'100%', marginTop:'12px', marginButtom:'12px',padding:'20px'}}
                        />
                    </Grid>
                )
            })}
        </Grid>
            <ActionButton
            {...props}
            variant="contained"
            color={getValues(props.name) === endButton?.value? 'primary':'secondary'}
            title={endButton.label || 'Secondary'}
            onClick={() =>{
                handelValue(endButton)
            }}
            style={{marginRight:0, marginLeft:0, width:'100%', marginTop:'12px', marginButtom:'12px',padding:'20px'}}
            />
        </>
    )
}
export default FilterButton;

// example={name:'',label:'',type:'button',value:'today'}
