import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    ZoomControl,
    useMapEvents,
    GeoJSON
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'; 
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import TimeToLeaveOutlinedIcon from '@mui/icons-material/TimeToLeaveOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { indiaBoundayLines } from '../../../../Config/Constants';


delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: 'https://img.icons8.com/?size=100&id=9Deeqjb8MjFH&format=png&color=000000',
});

const LocationComponent = ({
  e
}) =>{
  return (
    <div className='flex items-center'>
      <div className='h-10 w-10 bg-[#EDECF9] flex items-center justify-center rounded-full'>
        {e?.place_icon}
      </div>
      <div className='flex flex-col'>
          <div className='ml-2' style={{
            fontFamily: 'Rubik',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '23.7px',
            color:'#8E8CA3'
          }}>
          {e?.title?.length > 30 ? `${e?.title?.substring(0, 30)}...` : e?.title}
      </div> 
      {e?.show_distance === true && (
        <div className='ml-2' style={{
            fontFamily: 'Rubik',
            fontSize: '10px',
            fontWeight: 400,
            lineHeight: '16.59px',
            color:'#B1AED1'
          }}>
          {e?.distance}
        </div> 
      )}
    </div>
  </div>
  )
}

const OpenStreetView = ({
    mapView,
    isTemplate = false,
    mapHeight = '100vh',
    markerPoints,
    setMapView,
    selectedLocation,
    openAddLocation,
    setSelectedLocation,
    defaultMarkerPosition,
    setSelectedCoordinates,
    setDefaultMarkerPosition,
    setSelectedMarker
}) =>{
    const markerRef = useRef();
    const existedMarkerRef = useRef();
    let locationRef = {}

    const [zoom, setZoom] = useState(4);
    const [boundsKey, setBoundsKey] = useState(0);
    
  
    useEffect(() => {
      // Force re-render of boundaries on zoom change
      setBoundsKey(boundsKey + 1);
    }, [zoom]);
    
    const MapClickHandler = () => {
        useMapEvents({
          click: (e) => {
            if(!isTemplate){
              setSelectedCoordinates(e?.latlng)
            }
          },
          dragend: (e) => {
            if(!isTemplate){
              setMapView([e.target.getCenter()?.lat, e.target.getCenter()?.lng])
            }
          }
        });
        return null;
      };

      const eventHandlers = useMemo(
        () => ({
          dragend() {
            const marker = markerRef.current
            if (marker != null) {
             
               setDefaultMarkerPosition({
                  lat: marker._latlng?.lat,
                  lng: marker._latlng?.lng
                })
            }
          },
        }),
        [],
      )

      const eventHandlersForExistedLocation = useMemo(
        () => ({
          dragend() {
            const marker = existedMarkerRef.current
            if (marker != null) {
             
               if(selectedLocation?.id){
                let newData = {...selectedLocation};
                newData = {
                  ...newData,
                  latitude: marker._latlng?.lat,
                  longitude: marker._latlng?.lng
                }
                
                 setSelectedLocation(newData)
               }
               setDefaultMarkerPosition({
                  lat: marker._latlng?.lat,
                  lng: marker._latlng?.lng
               })
            }
          },
        }),
        [selectedLocation],
      )

    const returnIconAsString = (iconName, color) =>{
      const addColor = color === "#c8c8cc" ? "!fill-[#c8c8cc]" : "!fill-[#E53935]"
      if(iconName === 'Schools' || iconName === 'Colleges' || iconName === 'Education'){
        const iconHtml = renderToString(
          <SchoolOutlinedIcon className={`!h-6 !w-6 ${addColor}`} fontSize="small" sx={{
            fontSize: 10,
            fill: `${color} !important`
          }}/>
        );
        return iconHtml
      }else if(iconName === 'Offices'){
        const iconHtml = renderToString(
          <MapsHomeWorkOutlinedIcon className={`h-6 !w-6 ${addColor}`} fontSize="small" sx={{
            fontSize: 10,
            fill: `${color} !important`
          }}/>
        );
        return iconHtml
      }else if(iconName === 'Government'){
        const iconHtml = renderToString(
          <AccountBalanceOutlinedIcon className={`!h-6 !w-6 ${addColor}`} fontSize="small" sx={{
            fontSize: 10,
            fill: `${color} !important`
          }}/>
        );
        return iconHtml
      }else if(iconName === 'Leisure and Entertainment'){
        const iconHtml = renderToString(
          <ConfirmationNumberOutlinedIcon className={`!h-6 !w-6 ${addColor}`} fontSize="small" />
        );
        return iconHtml
      }else if(iconName === 'Shopping'){
        const iconHtml = renderToString(
          <ShoppingCartOutlinedIcon className={`!h-6 !w-6 ${addColor}`} fontSize="small" sx={{
            fontSize: 10,
            fill: `${color} !important`
          }}/>
        );
        return iconHtml
      }else if(iconName === 'Property'){
        const iconHtml = renderToString(
          <AddBusinessOutlinedIcon className={`!h-6 !w-6 ${addColor}`} fontSize="small" sx={{
            fontSize: 10,
            fill: `${color} !important`
          }}/>
        );
        return iconHtml
      }else if(iconName === 'Transport'){
        const iconHtml = renderToString(
          <TimeToLeaveOutlinedIcon className={`!h-6 !w-6 ${addColor}`} fontSize="small" sx={{
            fontSize: 10,
            fill: `${color} !important`
          }}/>
        );
        return iconHtml
      }else if(iconName === 'Prime areas'){
        const iconHtml = renderToString(
          <FlagOutlinedIcon className={`!h-6 !w-6 ${addColor}`} fontSize="small" sx={{
            fontSize: 10,
            fill: `${color} !important`
          }}/>
        );
        return iconHtml
      }
    }

    const returnCustomIcon = (color, mode, iconName) => {
      if(mode === 'edit'){
        const customIcon = new L.DivIcon({
          html: `<div className='relative' style="position: relative">
                    <div class='icon_container'>
                      ${returnIconAsString(iconName, color)}
                    </div>
                    <svg enable-background="new 0 0 128 128" height="48px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="48px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g>
                    <g>
                  <path d="M64,0C39.699,0,20,19.699,20,44s44,84,44,84s44-59.699,44-84S88.301,0,64,0z M28,44    C28,24.148,44.148,8,64,8s36,16.148,36,36c0,13.828-20.008,47.211-36,70.238C48.008,91.211,28,57.828,28,44z M64,24" fill="${color}"/>
                </g>
              </g>
            </svg>
            </div>`,
          className: 'custom-div-icon',
          iconSize: [38, 41],
          iconAnchor: [10, 71],
          popupAnchor: [2, -40],
          
        });
        return customIcon
      }else{
        const customIcon = new L.DivIcon({
          html: `<svg enable-background="new 0 0 128 128" height="48px" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="48px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g>
           <g>
             <path d="M64,0C39.699,0,20,19.699,20,44s44,84,44,84s44-59.699,44-84S88.301,0,64,0z M28,44    C28,24.148,44.148,8,64,8s36,16.148,36,36c0,13.828-20.008,47.211-36,70.238C48.008,91.211,28,57.828,28,44z M64,24    c-11.047,0-20,8.953-20,20s8.953,20,20,20s20-8.953,20-20S75.047,24,64,24z M64,56c-6.617,0-12-5.383-12-12s5.383-12,12-12    s12,5.383,12,12S70.617,56,64,56z" fill="#E53935"/>
           </g>
          </g>
        </svg>`,
          className: '', // Optional: Add a custom class name for styling if needed
          iconSize: [38, 41],
          iconAnchor: [10, 41],
          popupAnchor: [2, -40],
        });
        return customIcon
      }
    }
    
    const handlkeMouseOver = (e) =>{
        setSelectedMarker(e)
    }
 

    const BoundaryLayer = ({ data, zoom }) => {
      
    
      const boundaryStyle = (feature) => {
        let wt = zoom / 4;
        let style = {
          color: '',
          weight: wt
        };
    
        switch (feature.properties.boundary) {
          case 'disputed':
            style.color = "#f2efea";
            style.weight = wt * 2;
            break;
          case 'claimed':
            style.color = "#ead6d8";
            style.weight = wt * 3;
            break;
          default:
            style.color = "#000";
        }
    
        // Adding shadow properties
        style.shadowColor = 'rgba(0, 0, 0, 0.5)';
        style.shadowBlur = 20; 
        style.shadowOffset = [4, 4]; 
    
        return style;
      };
    
      return <GeoJSON data={data} style={boundaryStyle} />;
    };

    const markerPointsToRender = useCallback(() =>{
        console.log('from mmmmmm', markerPoints)
        return markerPoints?.map((e) =>{
          return (
           <>
             {selectedLocation?.id === e?.id ?
             <>
             
             <Marker position={[Number(selectedLocation?.latitude), Number(selectedLocation?.longitude)]} 
                icon={returnCustomIcon('#E53935', 'edit', e?.icon?.name)}
                eventHandlers={eventHandlersForExistedLocation}
                draggable={true}
                ref={existedMarkerRef}
              >
              <Popup>
                <LocationComponent e={e} />
              </Popup>
            </Marker> 
             </>
            :
            
           <>
            <Marker position={[Number(e?.latitude), Number(e?.longitude)]} 
                icon={openAddLocation ? returnCustomIcon('#c8c8cc', 'edit', e?.icon?.name) : returnCustomIcon('#E53935', 'edit', e?.icon?.name)}
                eventHandlers={{
                  mouseover: () =>{
                    handlkeMouseOver(e)
                  }
                }}
              >
              <Popup offset={[12, 10]}>
                <LocationComponent e={e} />
              </Popup>
            </Marker>
           </>}
           </>
          )
      })
    }, [markerPoints, selectedLocation, openAddLocation])

    return (
        <MapContainer
            center={mapView} 
            zoom={13} 
            style={{ height: mapHeight, width: "100%" }}
            zoomControl={false}
            
        >
         <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          />
         <MapClickHandler />
         <BoundaryLayer key={boundsKey} data={indiaBoundayLines} zoom={zoom} />
         {openAddLocation && !selectedLocation?.id && (
           <Marker 
            position={[defaultMarkerPosition?.lat, defaultMarkerPosition?.lng]} 
            icon={returnCustomIcon('#E53935', '')} 
            draggable={true}
            ref={markerRef}
            eventHandlers={eventHandlers}
          />
         )}

             {markerPoints?.length > 0 && (
               markerPointsToRender()
            )}
         
         <ZoomControl position="bottomright" />
     </MapContainer>
    )
}

export default OpenStreetView
