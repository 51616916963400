import React, { useEffect, useState } from 'react'
import {
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Paper,
    } from '@mui/material'
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import TidyTreeExample from './TidyTreeExample';
import TimelineComponent from './TimelineComponent';

const SessionAccordian = ({
    index,
    session
}) =>{
    const StyledAccordion = styled(Accordion)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        color: theme.palette.text.secondary,
      }));
      const [expanded, setExpanded] = useState(false)
      

      const handleAccordian = () =>{
        setExpanded(!expanded)
      }

    return (
        <Paper key={`paper-${index}`} sx={{boxShadow:'none', marginBottom:'16px'}}> 
            <StyledAccordion expanded={expanded} sx={{boxShadow:'none', border:'1px solid #D9D9D9'}}>
                <AccordionSummary onClick={handleAccordian} className='!mt-0 !mb-0' expandIcon={<ExpandMoreIcon className='!fill-[#353349]' onClick={handleAccordian}  />}>
                        <div className='flex items-center w-full'>
                            <div className='flex items-center'>
                            <Typography 
                                sx={{
                                    fontFamily: "Rubik",
                                    fontSize: "15px",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    lineHeight: "normal",
                                    color: "#8E8CA3"
                                }}
                                >
                                    {`Session ID`}
                                </Typography>
                            <Typography 
                                 className='!ml-2'
                                sx={{
                                    fontFamily: "Rubik",
                                    fontSize: "15px",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    lineHeight: "normal",
                                    color: "#353349"
                                }}
                                >
                                    {`#${session?.session_name.substring(session?.session_name.length, session?.session_name.length - 6).toUpperCase()}`}
                                </Typography>
                            </div>
                            <div className='mx-2' style={{
                                width:'1px',
                                height:'100%',
                                background:"#8E8CA3"
                            }}></div>
                             <div className='flex items-center'>
                            <Typography 
                                sx={{
                                    fontFamily: "Rubik",
                                    fontSize: "15px",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    lineHeight: "normal",
                                    color: "#8E8CA3"
                                }}
                                >
                                    {`Duration`}
                                </Typography>
                            <Typography 
                                 className='!ml-2'
                                sx={{
                                    fontFamily: "Rubik",
                                    fontSize: "15px",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    lineHeight: "normal",
                                    color: "#353349"
                                }}
                                >
                                    {session?.time_spent}
                                </Typography>
                            </div>
                        </div>
                    </AccordionSummary>
                <AccordionDetails className='!my-2.5'>
                    <>
                 {session?.relatedData?.length > 0 ?
                    <div style={{ width: '100%', maxHeight: '400px', overflow:'auto' }}>
                     <TimelineComponent sessionData={session?.relatedData}  />
                </div> :
                <div className="flex items-center justify-center" style={{ width: '100%', height: '400px' }}>
                       <div style={{
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontWeight: 300,
                        lineHeight: "23.7px",
                        letterSpacing: "-0.022em",
                        color: '#8E8CA3'
                       }}>
                           No Data Found In This Session
                       </div>
                </div>
                }
                    
                    </>
                </AccordionDetails>
        </StyledAccordion>
        </Paper>
    )
}

export default SessionAccordian