import React from 'react';
import { Card, CardMedia, CardContent } from '@mui/material';
import PrimaryBtn from '../../../../shared-component/button-component/PrimaryBtn';
import BusinessIcon from '@mui/icons-material/Business';

const WebsiteThemeCard = ({
    website,
    websiteDetails,
    cardClickHandler,
    updateThemeForWebsite,
    templateSelectionLoading
}) => {
 
  return (
    <Card
      className="cursor-pointer"
      sx={{
        boxShadow: 'none',
        border: '2px solid #E1EBFE',
        borderRadius: '8px',
        margin: '4px 0',
      }}
      onClick={cardClickHandler}
    >
      {website?.media ? (
        <CardMedia
          sx={{ height: '250px', objectFit: 'contain' }}
          image={`${website?.media}`}
          title="green iguana"
        />
      ) : (
        <CardMedia
          sx={{ height: '250px', objectFit: 'contain' }}
          image={<>NoImage</>}
          component={() => {
            return (
              <>
                <div
                  className="w-full bg-[#EDECF9] flex items-center justify-center"
                  style={{ height: '250px', objectFit: 'contain' }}
                >
                  <BusinessIcon
                    className="!fill-[#8E8CA3]"
                    style={{
                      width: '80px',
                      height: '80px',
                    }}
                  />
                </div>
              </>
            );
          }}
          title="green iguana"
        />
      )}
      <CardContent>
            <div className="flex items-center justify-between">
                 <div className='flex flex-col'>
                    <div className='text-[#353349] my-2' style={{
                        fontFamily: 'Rubik',
                        fontSize: '20px',
                        fontWeight: 400,
                        lineHeight: '23.7px',
                        letterSpacing: '-0.022em',
                    }}>{website?.template_name}</div>
                    <div className='flex items-center gap-[10px]'>
                        <span className='text-[#353349]' style={{
                            fontFamily: 'Rubik',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '18.96px',
                            letterSpacing: '-0.022em',
                        }}>By</span>
                        <span className='text-[#8E8CA3]' style={{
                            fontFamily: 'Rubik',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '18.96px',
                            letterSpacing: '-0.022em',
                        }}>Spotmink</span>
                    </div>
                 </div>
                 <div>
                     {websiteDetails?.template === website?.id ?
                     <PrimaryBtn 
                     className="!mx-0"
                     style={{
                     boxShadow: 'none',
                     margin: '0px !important',
                     fontWeight: 300,
                     }}
                     disabled={true}
                     title="Customize"
                  />
                     :
                     <PrimaryBtn 
                        className="!mx-0"
                        style={{
                        boxShadow: 'none',
                        margin: '0px !important',
                        fontWeight: 300,
                        }}
                        title="Use This"
                        disabled={templateSelectionLoading}
                        onClick={() =>{
                          updateThemeForWebsite(website?.id)
                        }}
                     />}
                 </div>
            </div>
      </CardContent>
    </Card>
  );
};

export default WebsiteThemeCard;
