import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
 
import './drawer.css';
import PremiumLabel from '../PremiumLabel/PremiumLabel';
import packageJson from '../../../../../package.json';
 
import NestedList from './NestedList';

function ResponsiveDrawer({ navList, drawerWidth }) {
  const [selected, setSelected] = useState('1');
  const [openItem, setOpenItem] = useState(null);
  const [openNestItem, setOpenNestItem] = useState(null);
 

 

  const drawer = (
    <div className="pt-20">
      <List>
        {navList.map((path) => {
          return (
            <div className="flex m-2  ">
              {path.subtitles.length === 0 ? (
                <NavLink
                  key={path.path}
                  to={path.path}
                  className={`menu-item ${selected?.id === path?.id ? 'active' : 'noActive'} !hover:bg-[#EDECF9]`}
                  onClick={() => {
                    setSelected(path);
                    setOpenItem(null);
                    setOpenNestItem(null);
                   
                  }}
                >
                  <div className="mx-4">
                    {path.icon(
                      selected?.id === path.id ? '#FFFFFF' : '#8E8CA3',
                    )}
                  </div>
                  <span
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {path.title}
                  </span>
                  {path?.id === '4' && <PremiumLabel />}
                </NavLink>
              ) : (
                 <NestedList 
                 path={path}
                 openItem={openItem}
                 selected={selected}
                 setSelected={setSelected}
                 setOpenItem={setOpenItem}
                 openNestItem={openNestItem}
                 setOpenNestItem={setOpenNestItem}
                 />
              )}
            </div>
          );
        })}
      </List>
    </div>
  );

  return (
    <Box
      className="flex !relative"
      sx={{
        height: `${window.innerHeight}px !important`,
      }}
    >
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              zIndex: 0,
            },
          }}
          open
        >
          {drawer}
          <div className="h-full flex justify-center items-end mb-4 text-[#004FE8] font-normal text-sm">
            {`Version ${packageJson?.version}`}
          </div>
        </Drawer>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
