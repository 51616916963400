import React, { useEffect, useState } from 'react';
import DataGridTable from '../../shared-component/table-component/DataGridTable';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '../../../asset/icons/DeleteIocn';
import { apiService } from '../../../constant/AuthConstant';
import returnError from '../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../utils/useExit';
import Loading from '../../shared-component/Loading/Loading';
import EmptyCard from '../../shared-component/card-component/EmptyCard';
import RemovePopup from '../../shared-component/RemovePopup/RemovePopup';
import { capitalizeFirstLetter } from '../../../utils/helper';

const rows = [
  {
    id: 1,
    name: 'Map 1',
    source: 'Open Streetmap',
  },
];

const MapsList = ({
  maps,
  setMaps,
  loading,
  getMapLists,
  userFromOrganization,
}) => {
  const navigate = useNavigate();
  const { exitFromPlatform } = useExit();
  const [deletingMap, setDeletingMap] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const [selectedMapId, setSelectedMapId] = useState(null);

  const handleRemove = () => {
    setOpenRemove(!openRemove);
  };

  useEffect(() => {
    if (localStorage.getItem('Map_created_open_and_show_maps') !== null) {
      localStorage.removeItem('Map_created_open_and_show_maps');
    }
    getMapLists();
  }, []);

  const deleteMap = (id) => {
    setDeletingMap(true);
    apiService
      .delete(`property/geomap/${id}/`)
      .then((res) => {
        if (res?.status === 204) {
          const list = [...maps];
          const updatedList = list?.filter((e) => {
            return e?.id !== id;
          });
          setMaps(updatedList);
          enqueueSnackbar('Map Deleted', { variant: 'success' });
          setDeletingMap(false);
          handleRemove();
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setDeletingMap(false);
          }
        }
      });
  };

  const columns = [
    { field: 'id', headerName: 'ID', minWidth: 100 },
    { field: 'title', headerName: 'Name', minWidth: 200 },
    {
      field: 'map_source',
      headerName: 'Source',
      minWidth: 200,
      renderCell: (params) => {
        return capitalizeFirstLetter(params?.row?.map_source);
      },
    },
    {
      field: 'actions',
      headerName: '',
      minWidth: 100,
      align: 'center',
      renderCell: (params) => {
        return userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
          userFromOrganization?.role?.name.toLowerCase() === 'developer' ? (
          <div
            className="cursor-pointer align-center"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setSelectedMapId(params?.row?.id);
              handleRemove();
            }}
          >
            <DeleteIcon color="error" className="!fill-[#FF5C5C]" />
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  function handelRowClick(e) {
    navigate(`/map/${e?.id}`, {});
  }

  return loading ? (
    <Loading />
  ) : (
    <>
      {maps?.length > 0 ? (
        <DataGridTable
          rows={maps}
          columns={columns}
          handleEvent={handelRowClick}
          actionWidth={100}
        />
      ) : (
        <EmptyCard
          title="No Maps Available"
          subtitle="Click “Add New” to add the map"
        />
      )}

      {openRemove && (
        <RemovePopup
          open={openRemove}
          close={handleRemove}
          onSubmit={() => {
            deleteMap(selectedMapId);
          }}
          loading={deletingMap}
          description={`Are you sure you want to Remove this Map?`}
        />
      )}
    </>
  );
};

export default MapsList;
