// ----------------- Documentation ---------------------------
// this component used for add and edit the data from property type list
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup
// edit                    boolead             used for the edit form
// edittitle               string              to maintain the edit form title of the popup

import React from "react";
import { apiService } from "../../../../constant/AuthConstant";
import FormModal from "../../../shared-component/formmodal-component/FormModal";
import Form from "../../../shared-component/formrelated-component/FormManager";


function AddPropertyType({
    title,
    open,
    onClose,
    successCallback,
    setOpenPropertyType
}){
    const defaultValues = {
        title:'',
        link_group:'',
    }
    const fieldMap = [
         {
            name:'title',
            label:'Title',
            placeholder:'Title',
            type:'text',
           
        },
        {
            name:'link_group',
            label:'Link Group ',
            placeholder:'Link Group',
            type:'select',
            fieldProps:{
                defaultOptions: [
                    {title: 'Odisha', id:''},
                    {title: 'a', id:''},
                    {title: 'c', id:''},
                ]
            }
        },
    ]
 
    const handelformsubmit = async (e) => {
       
    }
    return(
        <>
        <FormModal title={title} open={open} onClose={onClose}>
            <Form
             defaultValues={defaultValues}
             fieldMap={fieldMap}
             onCancel={onClose}
             formSubmit={handelformsubmit}
            />
        </FormModal>
        </>
    )
}
export default AddPropertyType;