import React from 'react';
import FormModal from '../../../../shared-component/formmodal-component/FormModal';
import FormManager from '../../../../shared-component/formrelated-component/FormManager';
import { enqueueSnackbar } from 'notistack';
import returnError from '../../../../../utils/ExportError';
import useExit from '../../../../../utils/useExit';
import { TokenStorage } from '../../../../../constant/AuthConstant';
import axios from 'axios';

const EditPartnerProfile = ({
  open,
  title,
  onClose,
  data,
  successCallback,
}) => {
  const { exitFromPlatform } = useExit();
  const defaultValues = {
    avatar: '',
    first_name: '',
    email: '',
    ...data,
  };

  const submitPartnerProfileData = (payload) => {
    if (payload?.avatar === '') {
      enqueueSnackbar('Please add profile picture', { variant: 'error' });
    } else if (payload?.full_name === '') {
      enqueueSnackbar('Please enter name', { variant: 'error' });
    } else {
      const formData = new FormData();
      if(payload.avatar?.name){
        formData.append('avatar', payload.avatar);
        formData.append('first_name', payload.first_name)
        formData.append('email', payload.email)
        formData.append('last_name', '')
      }else{
          formData.append('first_name', payload.first_name)
          formData.append('email', payload.email)
          formData.append('last_name', '')
      }
      axios({
        method: data?.id ? 'put' : 'patch',
        url: `${process.env.REACT_APP_BASE_URL}account/user/update_details/`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${TokenStorage.getToken()}`,
        },
      })
        .then((response) => {
          enqueueSnackbar('Organization updated', { variant: 'success' });
          successCallback(response.data);
          onClose();
        })
        .catch((error) => {
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (returnedError === 'Given token not valid for any token type') {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
            }
          }
        });
    }
  };

  const fieldMap = [
    {
      name: 'avatar',
      type: 'avtar',
    },
    {
      name: '',
      type: 'box_layout',
      label: 'Contact Information',
      borderType: 'none',
      fieldProps: {
        arrayFields: [
          {
            name: 'first_name',
            label: 'Name',
            placeholder: 'Enter name',
            type: 'text',
            cols: 2,
          },
          {
            name: 'email',
            label: 'Email',
            placeholder: 'Enter email',
            type: 'text',
            cols: 2,
            fieldProps: {
              disabled: true,
            },
          },
        ],
      },
    },
  ];
  return (
    <FormModal open={open} title={title} onClose={onClose}>
      <div className="mt-6">
        <FormManager
          fieldMap={fieldMap}
          defaultValues={defaultValues}
          onCancel={onClose}
          data={data?.id ? data : {}}
          formSubmit={submitPartnerProfileData}
        />
      </div>
    </FormModal>
  );
};

export default EditPartnerProfile;
