import React, { useContext, useEffect, useState } from 'react';
import { 
    Dialog,
    TextField,
 } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SecondaryBtn from '../../button-component/SecondaryBtn';
 
import DetailsCard from '../../card-component/DetailsCard';
import LabelAndValue from '../overview-used-components/LabelAndValue';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FilterFields from '../../../components/leads/Fields/FilterFields';
import formatISOString from '../../../../utils/formatISOString';
import { BASE_URI, apiService } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../utils/useExit';
import UserContext from '../../../../context/UserContext';
 
import { useParams } from 'react-router-dom';
import ActionButton from '../../button-component/ActionButton';

const GallaryDetailModal = ({
    open,
    image,
    onClose,
    gallaryItems,
    activeIndex,
    retrieveGallary,
    isGallaryModule,
    fetchProperty,
    analyticTags,
    selectedTags,
    setSelectedTags,
    isTimeline,
    onTimelineUnlinking,
    isUnit,
    submitImagesForUnit,
    unlinkingForUnit,
}) =>{
    const { id } = useParams()
    const { exitFromPlatform } = useExit()
    const [index, setIndex] = useState(activeIndex)
    const [dimensions, setDimensions] = useState('')
    const [imageTitle, setImageTitle] = useState('');
    const [altText, setAltText] = useState('');
    const [editLoading, setEditLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const { organization } = useContext(UserContext);
    const [unlinkLoading, setUnlinkLoading] = useState(false)
    

    const setImageSize = (imageUrl) => {
        let dimensions;
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
        setDimensions(`${img.width} × ${img.height}`)
        };
        return dimensions
    };

    useEffect(() =>{
        setImageSize(gallaryItems[index]?.media)
        setImageTitle(gallaryItems[index]?.name)
        setAltText(gallaryItems[index]?.alt_text)
        setSelectedTags(gallaryItems[index]?.tags)
    }, [index])

    const itemDetails = [
        {label: "File Name", value: gallaryItems[index]?.name?.length > 20 ? `${gallaryItems[index]?.name?.substring(0, 20)}...` : gallaryItems[index]?.name},
        // {label: "Size", value: gallaryItems[index]?.size || "30MB"},
        {label: "Dimensions", value: dimensions},
        {label: "Last Modified", value: formatISOString(gallaryItems[index]?.modified)},
    ]

    const onNextPress = () =>{
        if(index !== gallaryItems?.length - 1){
          const i = index + 1;
          setIndex(i)
          setImageTitle(gallaryItems[i]?.name)
          setAltText(gallaryItems[i]?.alt_text)
          setSelectedTags(gallaryItems[index]?.tags)
        }
      }
  
      const onPrevPress = () =>{
         if(index !== 0){
          const i = index - 1;
          setIndex(i)
          setImageTitle(gallaryItems[i]?.name)
          setAltText(gallaryItems[i]?.alt_text)
          setSelectedTags(gallaryItems[index]?.tags)
         }
      }

    const editItemDetails = () =>{
       if(imageTitle?.trim() === ''){
        enqueueSnackbar("Image name is required", { variant: 'error' })
       }
       else if(altText?.trim() === ''){
        enqueueSnackbar("Alt text is required", { variant: 'error' })
       }
       else{
        setEditLoading(true)
        const formData = new FormData()
        formData.append('name', imageTitle);
        formData.append('alt_text', altText);
        formData.append('tags', JSON.stringify(selectedTags))
        formData.append('org', organization?.id)
        formData.append('id', gallaryItems[index]?.id)
        apiService.patch(`organization/gallery/${gallaryItems[index]?.id}/`, formData).then((res) =>{
            setEditLoading(false)
            enqueueSnackbar('Updated', { variant: 'success' })
            retrieveGallary()
            onClose()
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setEditLoading(false)
              }
              }
        })
       }
    }

    const deleteItem = () =>{
        setDeleteLoading(true)
        apiService.delete(`organization/gallery/${gallaryItems[index]?.id}/`).then((res) =>{
            setDeleteLoading(false)
            retrieveGallary()
            onClose()
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setDeleteLoading(false)
              }
              }
        })
    }

    const unlinkImageFromProperty = () =>{
        setUnlinkLoading(true)
        apiService.post(`property/prop/${id}/remove_media/`, {
            image: gallaryItems[index]?.id
        }).then((res) =>{
            enqueueSnackbar('Removed image from property', { variant: 'success' })
            setUnlinkLoading(false)
            retrieveGallary()
            fetchProperty()
            onClose()
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setUnlinkLoading(false)
              }
              }
        })
    }

   
    return(
        <Dialog
          open={open}
          onClose={onClose}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
               width: '80%',
               maxWidth: '80%',
            },
          }}
        }
        >
           <div className='bg-white relative'>
               <div className=''> 
                  <div className='flex items-center justify-between ml-8 mr-4'>
                    <div></div>
                    <div className='mt-2' onClick={onClose}>
                        <CloseIcon className='!fill-[#000000] cursor-pointer' fontSize='medium' />
                    </div>
                 </div>
                 <div className='h-full mx-5 my-2 relative'>
                        <div className='flex justify-between gap-[20px]'>
                           <div className='w-[70%]'>
                               {gallaryItems[index]?.filetype === 'video' ?
                                 <video 
                                 style={{
                                 width:'100%',
                                 height:'500px',
                                 objectFit: 'cover'
                                }} 
                                controls
                                >
                                <source 
                                 src={gallaryItems[index]?.media?.includes('http') ? `${gallaryItems[index]?.media}` : `${BASE_URI}${gallaryItems[index]?.media}`}  
                                 type={'video/mp4'} />
                                 Your browser does not support the video tag.
                                </video>
                                :
                                <img 
                                src={gallaryItems[index]?.media?.includes('http') ? `${gallaryItems[index]?.media}` : `${BASE_URI}${gallaryItems[index]?.media}`} 
                                style={{
                                width:'100%',
                                height:'500px',
                                objectFit: 'contain'
                               }} />}
                               <div className='flex w-full items-center justify-center gap-[10px] mt-4 absolute bottom-[6px] left-[-16%]'>
                                    <div className='bg-[#EDECF9] px-2' onClick={onPrevPress}>
                                        <KeyboardArrowLeftIcon className='!fill-[#000000] cursor-pointer' />
                                    </div>
                                    <div className='bg-[#EDECF9] px-2' onClick={onNextPress}>
                                        <KeyboardArrowRightIcon className='!fill-[#000000] cursor-pointer' />
                                    </div>
                                </div>
                           </div>
                           <div className='w-[30%]'>
                              <DetailsCard 
                                 cardHeader='Items Details'
                                 style={{
                                    width:'100% !important',
                                    maxWidth: '100% !important',
                                    marginBottom: '10px !important'
                                 }}
                                 contentStyles={{
                                    paddingTop: '8px !important',
                                    paddingBottom: '8px !important',
                                 }}
                                 headerFontStyle={{
                                    fontSize: '16px !important',
                                    padding: '16px 8px 16px 8px !important'
                                 }}
                                 >
                                  {itemDetails?.map((e) =>{
                                    return(
                                        <LabelAndValue isCustomizing label={e?.label} value={e?.value} />
                                    )
                                  })}
                              </DetailsCard>
                               <div className='w-full'>
                                   <TextField 
                                     label="Image Name"
                                     placeholder='Image Name'
                                     type="text"
                                     sx={{
                                        width:'100%',
                                     }}
                                     required={true}
                                     defaultValue={imageTitle}
                                     value={imageTitle}
                                     onChange={(e) =>{
                                        setImageTitle(e?.target.value)
                                     }
                                    }
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                   />
                               </div>
                               <div className='w-full'>
                                   <TextField 
                                    label="Alt Text"
                                    placeholder='Alt Text'
                                    type="text"
                                    sx={{
                                        width:'100%'
                                     }}
                                     required={true}
                                     defaultValue={altText}
                                     value={altText}
                                     onChange={(e) =>{
                                        setAltText(e?.target.value)
                                     }
                                    }
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                   />
                               </div>
                               <div>
                                   <FilterFields
                                      multiple
                                      label="Analytics tags"
                                      placeholder="Analytics tags"
                                      defaultOptions={analyticTags}
                                      titleKey={"name"}
                                      value={selectedTags}
                                      setValue={setSelectedTags}
                                   />
                               </div>
                           </div>
                        </div>
                        {isGallaryModule === true ?
                        <div className='flex items-center justify-end mt-4 mb-6'>
                            <ActionButton title="Delete"
                              style={{ 
                                boxShadow: 'none', 
                                fontWeight: 300,
                                padding: '5px 22px !important',
                                background: "#FF5C5C"
                             }}  
                              onClick={deleteItem}
                            />
                            <SecondaryBtn 
                              title="Cancel" 
                              onClick={onClose}
                              style={{ boxShadow: 'none', fontWeight: 300, background: '#EDECF9' }} 
                            />
                            <ActionButton 
                              title="Save" 
                              style={{ boxShadow: 'none', fontWeight: 300, marginRight: '0px !important' }} 
                              disabled={editLoading}
                              onClick={editItemDetails}
                            />
                        </div>
                         :
                         <div className='flex items-center justify-end mt-4 mb-6'>
                         {isTimeline === true ?
                         <SecondaryBtn 
                            disabled={unlinkLoading}
                            title="Remove from Updates" 
                            onClick={() =>{
                                onTimelineUnlinking(gallaryItems[index]?.id)
                            }}
                            style={{ boxShadow: 'none', fontWeight: 300, background: '#EDECF9' }} 
                         /> 
                        :
                        (isUnit === true ? 
                            <SecondaryBtn 
                                disabled={unlinkLoading}
                                title="Remove from Unit" 
                                onClick={() =>{
                                    unlinkingForUnit(gallaryItems[index]?.id, setUnlinkLoading)
                                }}
                                style={{ boxShadow: 'none', fontWeight: 300, background: '#EDECF9' }} 
                            />
                        :
                        <SecondaryBtn 
                           disabled={unlinkLoading}
                           title="Remove from Property" 
                           onClick={unlinkImageFromProperty}
                           style={{ boxShadow: 'none', fontWeight: 300, background: '#EDECF9' }} 
                           />)}
                         <ActionButton 
                           title="Save" 
                           style={{ boxShadow: 'none', fontWeight: 300, marginRight: '0px !important' }} 
                           disabled={editLoading}
                           onClick={editItemDetails}
                           />
                     </div>}
                    </div>
               </div>
           </div>
        </Dialog>
    )
}

export default GallaryDetailModal