import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { FC, useState,useEffect } from "react";
import { customBox } from '../Config/Constant';


function LabTabs({
  tabs=[],
  value=1,
  handleChange=()=>{},
  tabContainerStyles = {},
  tabPanelStyles = {},
}) {
  // const [value, setValue] = useState('1');

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <Box sx={{ width: customBox.width, typography: 'body1' }}>
      <TabContext value={value} >
        <Box sx={{ 
           borderBottom: customBox.borderBottom, 
           borderColor: 'divider', 
           margin:'0 28px',
           ...tabContainerStyles
           }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabs.map((e,i)=>{
              return(
                <Tab label={e.label} value={e.value} />
              ) 
            })}
          </TabList>
        </Box>
        {tabs.map((e,i)=>{
          return(
            <TabPanel 
              value={e.value}
              sx={{
                ...tabPanelStyles
              }}
              >
                {e.page}
            </TabPanel>
          ) 
        })}
      </TabContext>
    </Box>
  );
}
export default LabTabs;