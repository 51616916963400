import React, { useState } from 'react';
import ImageLayout from './ImageLayout';

const TimelineComponent = ({
    timelineId,
    title,
    icon,
    description,
    date,
    images,
    onImageClick,
    selectedId,
    setSelectedId
}) =>{

   const [readMore, setReadMore] = useState(false)

   const handleReadMore = () =>{
     setReadMore(!readMore)
   }
 
    return(
        <div className='flex my-8'>
             <div className='w-1/12 flex justify-center my-2'>
                {icon}
             </div>

             <div className='h-40 border border-l-[#000000]'></div>

             <div className='flex flex-col w-11/12 pl-8 cursor-pointer' onClick={(e) =>{
                e.stopPropagation()
               if(selectedId === timelineId){
                setSelectedId(null)
               }else{
                setSelectedId(timelineId)
               }
             }}>
                 <div className='text-[#353349] mb-2' style={{
                    fontFamily: "Rubik",
                    fontSize: "24px",
                    fontWeight: 400,
                    lineHeight: "37.92px",
                 }}>
                    {title}
                 </div>

                 <div className='text-[#353349] mb-2' style={{
                    fontFamily: "Rubik",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "28.44px",
                 }}>
                    {date}
                 </div>



                 {selectedId === timelineId ?
                <>
                <div className='text-[#8E8CA3]' style={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                    whiteSpace: 'break-spaces'
                  }}>
                    {description}
                </div>
                <div className='my-8'>
                 <ImageLayout 
                   allImages={images} 
                   onImageClick={onImageClick}
                 />
              </div>
                </>
               :
               <>
              {description?.length > 100 ? 
                 <>
                   <div className='text-[#8E8CA3]' style={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                    whiteSpace: 'break-spaces'
                  }}>
                    {description?.substring(0, 99) }
                    <span className='text-[#8E8CA3]'>...</span>
                  </div>
                 </>
               : <>
                <div className='text-[#8E8CA3]' style={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                    whiteSpace: 'break-spaces'
                  }}>
                    {description}
                </div>
               </>}
               </>
               }
             </div>
        </div>
    )
}

export default TimelineComponent