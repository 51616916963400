import React, { useContext } from 'react'
import FormModal from '../../../../../shared-component/formmodal-component/FormModal'
import FormManager from '../../../../../shared-component/formrelated-component/FormManager'
import returnError from '../../../../../../utils/ExportError'
import { enqueueSnackbar } from 'notistack'
import useExit from '../../../../../../utils/useExit'
import UserContext from '../../../../../../context/UserContext'
import { apiService } from '../../../../../../constant/AuthConstant'
 

const AddRole = ({
    open,
    data,
    title,
    onClose,
    successCallback
}) => {
    const { exitFromPlatform } = useExit();
    const { organization } = useContext(UserContext)
    const defaultValues = {
        name: '',
    }

    const submitNewRole = (payload) =>{
        if(payload?.name === ''){{
            enqueueSnackbar('Plaese add Name', { variant: 'error' })
        }}
        else{

            let service;
            let apiUrl;

            if(data?.id){
                service = apiService.put;
                apiUrl = `rbac/role/${data?.id}/`
            }else{
                service = apiService.post;
                apiUrl = `rbac/role/`
            }
            service(apiUrl, {
                ...payload,
                organization: organization?.id
            }).then((res) =>{
                if(data?.id){
                    enqueueSnackbar('Updated', { variant: 'success' })
                }else{
                    enqueueSnackbar('Added', { variant: 'success' })
                }
                successCallback()
                onClose()
            }).catch((error) =>{
                if (error.response?.data) {
                    const returnedError = returnError(error.response?.data);
                    if (
                      returnedError === 'Given token not valid for any token type' ||
                      returnedError === 'Request failed with status code 401'
                    ) {
                      exitFromPlatform();
                    } else {
                      enqueueSnackbar(returnedError, { variant: 'error' });
                    }
                  }
            })
        }
    }

    const fieldMap = [
        {
            name: 'name',
            label:'Role Name',
            placeholder:'Role Name',
            type:'text'
        }
    ]
    return(
        <FormModal
          open={open}
          title={title}
          onClose={onClose}
        >
            <div className='mt-4'>
                <FormManager
                  onCancel={onClose}
                  defaultValues={defaultValues}
                  fieldMap={fieldMap} 
                  primaryBtnTitle={data?.id ? 'Update' : 'Add'}
                  data={data?.id ? data : {}}
                  formSubmit={submitNewRole}
                />
            </div>
        </FormModal>
    )
}

export default AddRole