import React from 'react';
import FormModal from '../../../../shared-component/formmodal-component/FormModal';
import FormManager from '../../../../shared-component/formrelated-component/FormManager';
import { enqueueSnackbar } from 'notistack';
import returnError from '../../../../../utils/ExportError';
import useExit from '../../../../../utils/useExit';
import axios from 'axios';
import { TokenStorage } from '../../../../../constant/AuthConstant';

const EditModal = ({
    open,
    title,
    onClose,
    data,
    successCallback
}) =>{

    const { exitFromPlatform } = useExit();

    const defaultValues = {
        avatar: '',
        first_name: '',
        email: '',
        ...data
    }
    let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
    const handleformSubmit = async (formD) => {
        if(formD?.first_name === ''){
            enqueueSnackbar('Please add name', {variant:'error'})
            return;
        }
        else if(emailReg.test(formD?.email) === false){
            enqueueSnackbar('Email is invalid', {variant:'error'})
            return;
        }
        else{
            const formData = new FormData();
        if(formD.avatar?.name){
        formData.append('avatar', formD.avatar);
        formData.append('first_name', formD.first_name)
        formData.append('email', formD.email)
        }else{
            formData.append('first_name', formD.first_name)
            formData.append('email', formD.email)
        }
        axios({
            method: formD?.avatar?.name ? "put" : "patch",
            url: `${process.env.REACT_APP_BASE_URL}account/user/update_details/`,
            data: formData,
            headers: { 
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${TokenStorage.getToken()}`
             },
          }).then((response)=>{
        enqueueSnackbar('Profile updated', { variant: 'success'})
        successCallback(response.data)
        onClose()
       }).catch((error)=>{
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
          }
          }
       })
        }
    }

    const fieldMap = [
        {
            name:"avatar",
            type:'avtar',
        },
        {
            name:'',
            type:'box_layout',
            label:'Contact Information',
            borderType:'none',
            fieldProps: {
                arrayFields:[
                    {
                        name:'first_name',
                        label:'Name',
                        placeholder:'Enter',
                        type:'text',
                        cols:2
                    },
                    {
                        name:'email',
                        label:'Email',
                        placeholder:'Enter',
                        type:'text',
                        cols:2,
                        fieldProps: {
                            disabled: true
                        }
                    },
                ]
            }
        },
    ]
    return(
       <FormModal
         open={open}
         title={title}
         onClose={onClose}
       >
         <div className='mt-8'>
         <FormManager 
             fieldMap={fieldMap}
             defaultValues={defaultValues}
             onCancel={onClose}
             formSubmit={handleformSubmit}
             data={data?.id ? data : {}}
          />
         </div>
       </FormModal>
    )
}

export default EditModal