import { Checkbox, Typography } from '@mui/material';
import React from "react";
import { useFormContext } from 'react-hook-form';
import ToolTipComponent from "../Tooltip/ToolTipComponent";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';



function RegularCheckbox(props){
    const { setValue, getValues} = useFormContext()
    console.log('fropm props', props)
    return (
        <div className="flex items-center" style={{...props?.sx}}>
            <Checkbox checked={props.checked} disabled={props.disabled} onChange={(e) => setValue(props?.name, e.target.checked) } defaultChecked={getValues(props?.name)} />
            <Typography className="flex justify-center text-14 items-center" >
            {/* {startCase(title)} */}{props.label}
            </Typography>
            {props?.fieldProps?.tooltip && props?.fieldProps?.tooltip !== '' && (
             <div className='ml-2'>
             <ToolTipComponent title={props?.fieldProps?.tooltip} offset={[50, -50]}>
                <ErrorOutlineIcon color='#FFFFFF' className='!fill-[#8E8CA3]' fontSize='20' />
             </ToolTipComponent>
             </div>
          )}
        </div>
      );
}
export default RegularCheckbox;