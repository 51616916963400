import { Typography } from '@mui/material'
import React from 'react'

const Info = ({ info }) =>{
    return (
        <div className='w-full h-48 flex items-center justify-center'>
              <div>
                  <Typography variant='subtitle1'>{info}</Typography>
              </div>
        </div>
    )
}

export default Info