import React, { useState } from 'react';
import { Card, CardMedia, CardContent } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PrimaryBtn from '../../../../shared-component/button-component/PrimaryBtn';
import PopoverComponent from '../../../../shared-component/Popover/PopoverComponent';
import BusinessIcon from '@mui/icons-material/Business';

const WebsiteCard = ({
  website,
  allThemesList,
  cardClickHandler = () => {},
  menuClickHandler = () => {},
  renameClickHandler = (e) => {},
  changeThemeClickHandler = (e) => {},
  clickToShowPreviewHandler = (e) => {},
  clickToOpenIntegrationHandler = () => {},
  clickToChangeStatus = (e) => {}
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionMenu = [
    {
      id: 1,
      name: 'Preview',
      onClick: (e) => {
       clickToShowPreviewHandler(e);
       handleClose();
      },
    },
    {
      id: 2,
      name: 'Rename',
      onClick: (e) => {
        renameClickHandler(e);
        handleClose();
      },
    },
    {
      id: 3,
      name: website?.draft === false ? 'Change to Draft' : 'Change to Published',
      onClick: (e) => {
        clickToChangeStatus(e);
        handleClose();
      },
    },
    {
      id: 4,
      name: 'Change Theme',
      onClick: (e) => {
        changeThemeClickHandler(e?.id);
        handleClose();
      },
    },
    {
      id: 5,
      name: 'Integrate',
      onClick: (e) => {
        clickToOpenIntegrationHandler(e)
        handleClose();
      },
    },
  ];
  const template = allThemesList?.find((e) =>{
    return e?.id === website?.template
  })
  return (
    <Card
      className="cursor-pointer"
      sx={{
        boxShadow: 'none',
        border: '2px solid #E1EBFE',
        borderRadius: '8px',
      }}
      onClick={() => {
        cardClickHandler(website);
      }}
    >
      {website?.website_image ? (
        <CardMedia
          sx={{ height: '250px', objectFit: 'contain' }}
          image={`${website?.website_image}`}
          title="green iguana"
        />
      ) : (
        <CardMedia
          sx={{ height: '250px', objectFit: 'contain' }}
          image={<>NoImage</>}
          component={() => {
            return (
              <>
                <div
                  className="w-full bg-[#EDECF9] flex items-center justify-center"
                  style={{ height: '250px', objectFit: 'contain' }}
                >
                  <BusinessIcon
                    className="!fill-[#8E8CA3]"
                    style={{
                      width: '80px',
                      height: '80px',
                    }}
                  />
                </div>
              </>
            );
          }}
          title="green iguana"
        />
      )}
      <CardContent>
      <div className="flex flex-col items-center justify-center gap-[10px] md:flex-row md:items-center md:justify-between lg:items-center">
          <div className="flex flex-col">
            <div
              className="text-[#353349] my-2"
              style={{
                fontFamily: 'Rubik',
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '23.7px',
                letterSpacing: '-0.022em',
              }}
            >
              {website?.title}
            </div>

            <div className="flex items-center gap-[10px]">
              <span
                className="text-[#353349]"
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18.96px',
                  letterSpacing: '-0.022em',
                }}
              >
                Theme
              </span>
              <span
                className="text-[#8E8CA3]"
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18.96px',
                  letterSpacing: '-0.022em',
                }}
              >
                {template?.id ? template?.template_name : 'None'}
              </span>
            </div>

            <div className="flex items-center gap-[10px]">
              <span
                className="text-[#353349]"
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18.96px',
                  letterSpacing: '-0.022em',
                }}
              >
                Status
              </span>
              <span
                className="text-[#8E8CA3]"
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '18.96px',
                  letterSpacing: '-0.022em',
                }}
              >
                {website?.draft === false
                  ? 'Published'
                  : 'Draft'}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-[10px]">
            <PrimaryBtn
              className="!mx-0"
              style={{
                boxShadow: 'none',
                margin: '0px !important',
                fontWeight: 300,
              }}
              title="Customize"
              disabled={true}
            />
            <PopoverComponent
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleClick={handleClick}
              popHandler={
                <div onClick={handleClick}>
                  <MoreVertIcon className="!fill-[#353349]" />
                </div>
              }
              component={
                <div className="flex flex-col pt-3">
                  {actionMenu?.map((menu) => {
                    return (
                      <div
                        className="text-[#8E8CA3] px-4 pb-3 cursor-pointer"
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '23.7px',
                          letterSpacing: '-0.022em',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          menu.onClick(website);
                        }}
                      >
                        {menu.name}
                      </div>
                    );
                  })}
                </div>
              }
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default WebsiteCard;
