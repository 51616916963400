import React, { useState, useEffect, useCallback } from 'react';
import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import { BoxEffects, headerButton } from '../../../Config/Constants';
import Box from '@mui/material/Box';
import { apiService } from '../../../constant/AuthConstant';
import returnError from '../../../utils/ExportError';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import useExit from '../../../utils/useExit';
import Loading from '../../shared-component/Loading/Loading';
import EditIcon from '@mui/icons-material/Edit';
import { useDropzone } from 'react-dropzone';
import DefaultImagesWithProgress from '../../shared-component/OverviewPage/components/DefaultImagesWithProgress';
import GallaryDetailModal from '../../shared-component/OverviewPage/components/GallaryDetailModal';
import EmptyCard from '../../shared-component/card-component/EmptyCard';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { enqueueSnackbar } from 'notistack';
import { v4 } from 'uuid';

const arr1 = [{ title: 'Gallery', url: '/' }];

const GallaryModule = () =>{
  const { exitFromPlatform } = useExit();
  const [gallaryItems, setGallaryItems] = useState([]);
  const [gallaryLoading, setGallaryLoading] = useState(false)
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [uploadedImage, setUploadedImages] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [analyticTags, setAnalyticTags] = useState([]);
  
  const handleOpenDetailModal = () =>{
      setOpenDetailModal(!openDetailModal)
  }

  const onDrop = (files) =>{
    console.log('from images', files)
    const newFiles = files?.map((e) =>{
      return {
        file: e,
        id: v4()
      }
    })
    setImagesToUpload(newFiles)
}

const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'image/webp': ['.webp'],
      'image/apng': ['.apng'],
      'video/mp4': ['.mp4'],
    }
});

  const retrieveGallary = () =>{
    setGallaryLoading(true)
    apiService.get(`organization/gallery/`).then((res) =>{
      if(res?.data?.length > 0){
        const updateData = res?.data?.map((e) =>{
            return {
                ...e,
                tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
            }
        })
        setGallaryItems(updateData)
    }else{
        setGallaryItems([])
    }
    setGallaryLoading(false)
    }).catch((error) =>{
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setGallaryLoading(false)
          }
          }
    })
}

  // useEffect(() =>{
  //   if(imagesToUpload?.length === 0){
  //     retrieveGallary()
  //   }
  // }, [imagesToUpload])

  useEffect(() =>{
    retrieveGallary()
  }, [])


  const getAnalyticTags = () =>{
    apiService.get(`property/prop/get_spec_choices/`).then((res) =>{
    console.log('from analytic tags', res?.data)
    if(res?.data?.data?.length > 0){
       const updateOptions = res?.data?.data?.map((e) =>{
        return {name: e, id: e}
       })
       setAnalyticTags(updateOptions)
    }else{
      setAnalyticTags([])
    }
    }).catch((error) =>{
      if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setSubmitLoading(false)
        }
        }
  })
  }
 
  useEffect(() =>{
    getAnalyticTags()
 }, [])


  const onClickEdit = (index) =>{
    setActiveIndex(index)
    handleOpenDetailModal()
  }

  const imageToUploadContainer = useCallback(() =>{
    return imagesToUpload?.map((e) =>{
      return (
          <DefaultImagesWithProgress 
            key={e?.id}
            image={e} 
            imageList={gallaryItems}
            setImagesList={setGallaryItems}
            imagesToUpload={imagesToUpload}
            setImagesToUpload={setImagesToUpload}
            setUploadedImages={setUploadedImages}
            uploadedImage={uploadedImage}
          />
      )
    })
  }, []);

  useEffect(() =>{
    console.log('from imagesTo', uploadedImage, gallaryItems)
  }, [uploadedImage, gallaryItems])


    return (
       <>
        <div className='overflow-x-hidden'>
        <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '0px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          <div className="flex justify-between items-center">
            {/* <SearchBar /> */}
            <div
              style={{
                marginLeft: '20px',
              }}
            >
              <div {...getRootProps()} className='relative'>
                <PrimaryBtn
                    className="!mx-0"
                    style={{
                      boxShadow: 'none',
                      margin: '0px !important',
                      fontWeight: 300,
                    }}
                    title="Add Item"
                  />
                <input className='absolute' {...getInputProps()} style={{ opacity:0 }} />
              </div>
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>

      {gallaryLoading ? <Loading />
       : 
       <>
       <div className='m-6'>
        {gallaryItems?.length > 0 || imagesToUpload?.length > 0 ?
         
         <div className='overflow-y-scroll flex flex-wrap gap-[15px]'>
              {gallaryItems?.map((e, i) =>{
                return(
                  <div key={i} className="relative group">
                    {e?.filetype === 'video' ?
                    <video className='object-cover cursor-pointer' 
                    src={e?.media} 
                    style={{
                        width: '150px', 
                        height:'150px', 
                        borderRadius:'4px'
                    }} 
                  />
                  :
                   <img className='object-cover cursor-pointer' 
                      src={e?.media} 
                      style={{
                          width: '150px', 
                          height:'150px', 
                          //border: selectedFile?.some((s) => s?.id === e?.id) === true ? "2px solid #24D315" : "none", 
                          borderRadius:'4px'
                      }} 
                  />}
                    <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg" onClick={() =>{
                                  onClickEdit(i)
                              }}>
                          <div className='flex items-center justify-between m-1.5'>
                              <div></div>
                              <div className='cursor-pointer' 
                              onClick={() =>{
                                  onClickEdit(i)
                              }}
                              >
                                    <EditIcon className='!fill-white' />
                              </div>
                          </div>
                    </div>
                  </div>
                )
              })}
              {imagesToUpload?.length > 0 && (
                   imagesToUpload?.map((e) =>{
                    return (
                        <DefaultImagesWithProgress 
                          key={e?.id}
                          image={e} 
                          imageList={gallaryItems}
                          setImagesList={setGallaryItems}
                          imagesToUpload={imagesToUpload}
                          setImagesToUpload={setImagesToUpload}
                          setUploadedImages={setUploadedImages}
                          uploadedImage={uploadedImage}
                        />
                    )
                  })
                )}
          </div>
     
      :
      <EmptyCard 
        icon={<CropOriginalIcon 
           className="!fill-[#B8B7C5]"
           sx={{
            width:'50px',
            height:'50px'
           }}
         />}
        title='There are no images in Gallary'
        subtitle='Please click "Add Item" to add new Images to Gallary'
      />}
       </div>
       </>
      }
        {openDetailModal && (
            <GallaryDetailModal 
              isGallaryModule={true}
              open={openDetailModal}
              onClose={handleOpenDetailModal}
              gallaryItems={gallaryItems}
              activeIndex={activeIndex}
              retrieveGallary={retrieveGallary}
              analyticTags={analyticTags}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
        )}
        </div>
       </>
    )
}

export default GallaryModule;
