import React from 'react'
import { NativeSelect } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore';

const NativeSelectComponent = ({
    value,
    options,
    onChange,
    disabled = false
}) =>{
    return (
        <NativeSelect 
            variant="filled"
            defaultValue={value}
            disableUnderline
            onChange={(e) =>{
                onChange(e)
            }}
            disabled={disabled}
            IconComponent={ExpandMore}
            sx={{
                fontSize:'13px',
                paddingLeft:'0px',
                background: 'transparent',
                "& .MuiInputBase-root-MuiOutlinedInput-root": {
                    paddingLeft: '0px !important'
                 },
                 "& .MuiNativeSelect-select-MuiInputBase-input-MuiInput-input": {
                   minWidth: "20px !important"
                 },
                 "& .MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:focus": {
                   backgroundColor: 'transparent !important'
                 },
                 "& .MuiNativeSelect-select": {
                    paddingRight: "0 !important",
                    width: "60px", // Increase width of the text container
                  },
            }}
            >
            {options?.map((option) =>{
                return <option value={option?.value}>{option?.name}</option>
            })}
      </NativeSelect>
    )
}

export default NativeSelectComponent