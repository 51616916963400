import React from 'react';

const TabletView = ({
    color = '#E1EBFEs'
}) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 1.5625H5.25C4.42266 1.5625 3.75 2.26318 3.75 3.125V21.875C3.75 22.7368 4.42266 23.4375 5.25 23.4375H18.75C19.5773 23.4375 20.25 22.7368 20.25 21.875V3.125C20.25 2.26318 19.5773 1.5625 18.75 1.5625ZM18.5625 21.6797H5.4375V3.32031H18.5625V21.6797ZM11.0625 19.1406C11.0625 19.3996 11.1613 19.648 11.3371 19.8312C11.5129 20.0143 11.7514 20.1172 12 20.1172C12.2486 20.1172 12.4871 20.0143 12.6629 19.8312C12.8387 19.648 12.9375 19.3996 12.9375 19.1406C12.9375 18.8816 12.8387 18.6332 12.6629 18.4501C12.4871 18.2669 12.2486 18.1641 12 18.1641C11.7514 18.1641 11.5129 18.2669 11.3371 18.4501C11.1613 18.6332 11.0625 18.8816 11.0625 19.1406Z"
        fill={color}
      />
    </svg>
  );
};

export default TabletView;
