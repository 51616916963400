import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material';
import { apiService } from '../../../../constant/AuthConstant'
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { TokenStorage } from '../../../../constant/AuthConstant';

const UnitMedia = ({ 
    index, 
    item , 
    removeImage, 
    setFilesUrl, 
    filesUrl,
    setFiles, 
    files, 
    fieldProps
 }) =>{
    const { enqueueSnackbar } = useSnackbar()
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false)

    const uploadImage = (item) =>{
      if(item?.file?.name){
        setLoading(true)
         const formData = new FormData()
         let apiUrl = `property/unitmedia/`;
         formData.append('media', item.file)
         axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}${apiUrl}`,
            data: formData,
            headers: { 
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${TokenStorage.getToken()}`
             },
          })
         //apiService.post(apiUrl, formData)
         .then((response)=>{
            if(response.data){
                if(response.data?.media){
                    const string = response.data?.media;
                    const y = string.substring(string.lastIndexOf('.'))
                    console.log('from yyyyyyyy', y)
                    if(y === '.mp4'){
                        files[index] = {
                            ...response.data,
                            type: 'video'
                        }
                        filesUrl[index] = {
                            ...response.data,
                            type: 'video'
                        }
                        setFiles(files)
                        setFilesUrl(filesUrl)
                        
                        setImage({
                            ...response.data,
                            type: 'video'
                        })
                        setTimeout(()=>{
                            setLoading(false)
                        }, 3000)
                    }
                    if(y === '.jpg' || y === '.jpeg' || y === '.png'){
                        files[index] = {
                            ...response.data,
                            type: 'image'
                        }
                        filesUrl[index] = {
                            ...response.data,
                            type: 'image'
                        }
                        setFiles(files)
                        setFilesUrl(filesUrl)
                        
                        setImage({
                            ...response.data,
                            type: 'image'
                        })
                        setTimeout(()=>{
                            setLoading(false)
                        }, 3000)
                    }
                }
            }
            //setLoading(false)
         }).catch((error)=>{
            setLoading(false)
            enqueueSnackbar('Image upload failed', { variant:"error" })
            console.log('from image error', error)
         })
      }
    }


    useEffect(()=>{
        uploadImage(item)
    }, [])

    useEffect(() =>{
        console.log('from updated items', image)
    },[image, files, filesUrl])

    
    return(
        <div className='mx-4 my-4' style={{
            width: '165px',
            height: '160px',
            borderRadius: '15px',
            position: 'relative', // Add this for positioning the image
        }}>
            <span className='' 
               style={{ position:'absolute',
                        backgroundColor:'white',
                        width:'25px',
                        height:'25px',
                        borderRadius:'25px',
                        right:'10px',
                        top:'10px',
                        cursor:'pointer',
                        color:'#FF5C5C',
                        zIndex:9999
                }} onClick={()=>removeImage(item, image)}>&#10006;</span>
            {fieldProps?.notToUpload ? 
            <>
            <img
                    src={URL.createObjectURL(item?.file)}
                    alt="invalid image"
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '15px',
                        objectFit: 'cover',
                    }}
                />
            </>
             :
             <>
             {loading ?
                <div className='h-full flex items-center justify-center'>
                    <CircularProgress size={20} color='primary'/>
                </div>
                :
               <>
              {item?.type === 'image' || image?.type === 'image' ? 
                    <img
                    src={item?.media ? item?.media : image?.media}
                    alt="invalid image"
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '15px',
                        objectFit: 'cover',
                    }}
                />
                 :
                    <video
                        src={item?.media ? item?.media : image?.media}
                        alt="invalid image"
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '15px',
                            objectFit: 'cover',
                        }}
                    />}
               </>}
             </>}
        </div>
    )
}

export default UnitMedia