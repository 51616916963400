import React from 'react'

const Sales = ({ color = "#8E8CA3"}) =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                <mask id="3" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
                    <rect x="0.750977" y="0.675049" width="32" height="32" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_3238_2231)">
                    <path d="M26.0842 19.3417V10.675L19.4176 6.00838L12.7509 10.675V12.675H10.0842V9.34172L19.4176 2.67505L28.7509 9.34172V19.3417H26.0842ZM20.0842 11.3417H21.4176V10.0084H20.0842V11.3417ZM17.4176 11.3417H18.7509V10.0084H17.4176V11.3417ZM20.0842 14.0084H21.4176V12.675H20.0842V14.0084ZM17.4176 14.0084H18.7509V12.675H17.4176V14.0084ZM19.4176 30.675L10.0842 28.075V30.0084H2.08423V15.3417H12.6842L20.9509 18.4084C21.6842 18.675 22.2789 19.1417 22.7349 19.8084C23.19 20.475 23.4176 21.2084 23.4176 22.0084H26.0842C27.1953 22.0084 28.1398 22.375 28.9176 23.1084C29.6953 23.8417 30.0842 24.8084 30.0842 26.0084V27.3417L19.4176 30.675ZM4.7509 27.3417H7.41756V18.0084H4.7509V27.3417ZM19.3509 27.875L27.2842 25.4084C27.2176 25.1639 27.0673 24.9804 26.8336 24.8577C26.6007 24.7359 26.3509 24.675 26.0842 24.675H19.6842C18.9953 24.675 18.3731 24.6306 17.8176 24.5417C17.262 24.4528 16.662 24.2973 16.0176 24.075L13.7176 23.2751L14.4842 20.7417L17.1509 21.6084C17.5509 21.7417 18.0176 21.8417 18.5509 21.9084C19.0842 21.975 19.8176 22.0084 20.7509 22.0084C20.7509 21.7639 20.6789 21.5306 20.5349 21.3084C20.39 21.0862 20.2176 20.9417 20.0176 20.875L12.2176 18.0084H10.0842V25.3417L19.3509 27.875Z" fill={color} />
                </g>
            </svg>
    )
}

export default Sales