import { Card, CardContent, CardHeader, Container, Divider, Typography } from "@mui/material";
import React from "react";
import { masonarySeperator } from "../../../Config/Constants";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function DetailsCard({
    children,
    cardHeader='',
    style, 
    isCopy = false, 
    onCopyClick,
    isAdditionalButton,
    onButtonClick,
    buttonText = "Edit",
    contentStyles = {},
    component,
    additionalButtonClassName
}){
    return(
        <>
            {/* <Container> */}
                <Card sx={{boxShadow:'none',border:'2px solid #E1EBFE', ...style,   borderRadius:'8px'}} >
                    <div className="flex items-center justify-between">
                      <div style={{
                        padding:'16px',
                        fontSize: '20px',
                        ...style,
                        borderBottom:'none',
                        borderBottomLeftRadius:'0',
                        borderBottomRightRadius:'0',
                        border:'none',
                        fontWeight: 300,
                        lineHeight: '24px',
                        letterSpacing: '-0.022em',
                        }}>{cardHeader}</div>
                       {isCopy === true && (
                        <div onClick={onCopyClick}>
                        <ContentCopyIcon className="!fill-[#353349] mr-2 cursor-pointer" sx={{
                            width: 25, 
                            height: 25
                        }} />
                     </div>
                       )}
                        {isAdditionalButton === true && (
                            <div className={`mr-4 text-[#004FE8] cursor-pointer ${additionalButtonClassName}`} style={{
                                fontFamily: 'Rubik',
                                fontSize: '18px',
                                fontWeight: 400,
                                lineHeight: '23.7px',
                                letterSpacing: '-0.022em',
                             }} onClick={onButtonClick}>
                               {buttonText}
                             </div>
                        )}

                        {component && (
                            component
                        )}
                    </div>
                    <Divider sx={{...masonarySeperator}} />

                    <CardContent sx={{ ...contentStyles, paddingBottom: '16px !important' }}>
                        {children} 
                    </CardContent>
                </Card>
            {/* </Container> */}
        </>
    )
}
export default DetailsCard;