import React, { useState } from 'react'; 

function removeTrailingSpaces(text) {
    return text.split('\n')   
                .map(line => line.trimEnd())  
                .join('\n');  
}


const DescriptionComponent = ({
    description = ""
}) =>{
    const [readMore, setReadMore] = useState(false)
  

    const handleReadMore = () =>{
        setReadMore(!readMore)
    }
    localStorage.setItem('form-tab', '2')
    return (
        description.length > 51 ? 
            <>
             {readMore === false && (
                <div className='mb-4' style={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                    color: '#353349'
                 }}>
                    {`${removeTrailingSpaces(description?.substring(0, 50))}`}
                    <span className='cursor-pointer ml-2' style={{
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '18.96px',
                        color: "#004FE8"
                    }} onClick={handleReadMore}>Read More</span>
                 </div>
             )}
             {readMore === true && (
                <div className='mb-4' style={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                    color: '#353349'
                 }}>
                    {removeTrailingSpaces(description)}
                    <span className='cursor-pointer ml-2' style={{
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '18.96px',
                        color: "#004FE8"
                    }} onClick={handleReadMore}>Read Less</span>
                 </div>
             )}
            </>
            :
            <>
              <div style={{
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '18.96px',
                color: '#B1AED1'
             }}>{description}</div>
            </>
    )
}

export default DescriptionComponent