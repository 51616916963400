import React from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadIcon from '../../../../../asset/icons/UploadIcon';
import ToolTipComponent from '../../../../shared-component/Tooltip/ToolTipComponent';
import { TextField, InputAdornment, Radio } from '@mui/material';
import NativeSelectComponent from './NativeSelectComponent';
import { countryCodes, currencyCodes } from '../../../../../Config/Constants';

const ContainerForFieldType = ({ field }) => {
  return (
    <>
      <div className="flex items-center">
        {field?.field_type?.id === '' && (
          <div
            className="text-[#8E8CA3]"
            style={{
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18.96px',
            }}
          >
            {field?.field_type?.id === '' ? 'TEXT' : field?.field_type?.id}
          </div>
        )}
        {field?.field_type?.id === 'TEXT' && (
          <div
            className="text-[#8E8CA3]"
            style={{
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18.96px',
            }}
          >
            {field?.field_type?.id === '' ? 'Text' : field?.field_type?.id}
          </div>
        )}
        {field?.field_type?.id === 'MULTISELECT' && (
          <div
            className="text-[#8E8CA3]"
            style={{
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18.96px',
            }}
          >
            {field?.field_options?.length
              ? field?.field_options?.map((e, i) => {
                  return <div className="my-2">{`${i + 1}. ${e?.value}`}</div>;
                })
              : 'Multiple Choice Selection'}
          </div>
        )}
        {field?.field_type?.id === 'DROPDOWN' && (
          <div
            className="text-[#8E8CA3]"
            style={{
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18.96px',
            }}
          >
            {field?.field_options?.length
              ? field?.field_options?.map((e, i) => {
                  return <div className="my-2">{`${i + 1}. ${e?.value}`}</div>;
                })
              : 'Singla Choice Selection'}
          </div>
        )}
        {field?.field_type?.id === 'CHECKBOX' && (
          <div
            className="text-[#8E8CA3]"
            style={{
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18.96px',
            }}
          >
            Checkbox
          </div>
        )}
        {field?.field_type?.id === 'TEXTAREA' && (
          <div
            className="text-[#8E8CA3]"
            style={{
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18.96px',
            }}
          >
            Text Area
          </div>
        )}
        {field?.field_type?.id === 'FILEUPLOAD' && (
          <div className="flex items-center bg-[#EDECF9] rounded-lg p-3">
            <UploadIcon />
            <div
              className="text-[#8E8CA3]"
              style={{
                fontFamily: 'Rubik',
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '23.7px',
              }}
            >
              Add File
            </div>
            {field?.file_types?.length > 0 ? (
              <div
                className="mx-2 text-[#8E8CA3]"
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '23.7px',
                }}
              >
                {`( ${field?.file_types?.join(',')} )`}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}

        {field?.field_type?.id === 'CURRENCY' && (
          <TextField
            className="mx-4 w-2/4"
            sx={{
              background: 'white',
              borderBottom: 'none !important',
              '& .MuiInputBase-root.MuiInput-root:before': {
                borderBottom: 'none !important',
                borderColor: 'white !important',
              },
            }}
            placeholder="CURRENCY"
            variant="standard"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className="!pl-0">
                  <NativeSelectComponent
                    value={'INR'}
                    disabled={true}
                    options={currencyCodes}
                    onChange={(e) => {}}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
        {field?.field_type?.id === 'PHONE' && (
          <TextField
            className="mx-4 w-2/4"
            sx={{
              background: 'white',
              borderBottom: 'none !important',
              '& .MuiInputBase-root.MuiInput-root:before': {
                borderBottom: 'none !important',
                borderColor: 'white !important',
              },
            }}
            placeholder="Phone"
            variant="standard"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className="!pl-0">
                  <NativeSelectComponent
                    value={'+91'}
                    disabled={true}
                    options={countryCodes}
                    onChange={(e) => {}}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}

        {field?.field_type?.id === 'NAME' && (
          <div
            className="text-[#8E8CA3]"
            style={{
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18.96px',
            }}
          >
            NAME
          </div>
        )}

        {field?.field_type?.id === 'EMAIL' && (
          <div
            className="text-[#8E8CA3]"
            style={{
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18.96px',
            }}
          >
            EMAIL
          </div>
        )}

        {field?.field_type?.id === 'SIGNATURE' && (
          <div
            className="text-[#8E8CA3]"
            style={{
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18.96px',
            }}
          >
            SIGNATURE
          </div>
        )}

        {field?.field_type?.id === 'RADIO' && (
          <div
            className="text-[#8E8CA3]"
            style={{
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18.96px',
            }}
          >
            {field?.field_options?.length
              ? field?.field_options?.map((e, i) => {
                  return (
                    <div className="my-2 flex items-center">
                      <Radio />
                      <div className="mx-2">{`${i + 1}. ${e?.value}`}</div>
                    </div>
                  );
                })
              : 'Singla Choice Selection'}
          </div>
        )}

        {field?.helper_text !== '' && (
          <div className="flex items-center ml-4">
            <ToolTipComponent title={field?.helper_text} offset={[50, -50]}>
              <ErrorOutlineIcon
                color="#FFFFFF"
                className="!fill-[#8E8CA3]"
                fontSize="20"
              />
            </ToolTipComponent>
          </div>
        )}
      </div>
    </>
  );
};

export default ContainerForFieldType;
