import React, { useEffect } from "react";
import Dropzone from "react-dropzone";
import { commonText } from "../../../Config/themesConfig";
import { Typography, Box } from "@mui/material";
import { useState } from "react";
import { dropbackground, dropzone } from "../../../Config/Constants";
import { useFormContext } from "react-hook-form";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { enqueueSnackbar } from "notistack";

function SingleAddImage({
        name,
        progress,
        multiFiles,
        selectedFiles=[],
        uploadingFile,
        setMultiFiles,
        setSelectedFiles,
        upload_title,
        upload_description,
        fileTypes = { '*': [] }
}){
    const [files, setFiles]= useState([])
    const [preview, setPreview] = useState(null)

    const { setValue, getValues } = useFormContext()
    
    const updateFile = (file) =>{
        if (file) {
            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > 5) {
              enqueueSnackbar("File size should not exceed 5 MB", { variant: 'error' });
            } else {
                setValue(name, file[0])
                const p = URL.createObjectURL(file[0])
                if(file[0]?.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                    setPreview(p)
                }else{
                    setPreview(file[0])
                }
            }
          }
    }

    useEffect(() =>{
       if(getValues(name) !== ''){
         setPreview(getValues(name))
       }
    }, [getValues])

    const removeImage = () =>{
        setValue(name, '')
        setPreview(null)
    }
   console.log('from preiovew', preview)
    return(
        <>
            {preview === null ?
            <Dropzone accept={fileTypes} onDrop={acceptedFiles => updateFile(acceptedFiles)}>
            {({getRootProps, getInputProps}) => (
             <Box className='border-dashed border h-28 flex justify-center items-center w-full ml-4 my-4 rounded-md' style={dropbackground}>
             <div {...getRootProps()}>
                 <input {...getInputProps()} />
                 <Box className="flex flex-col items-center justify-center cursor-pointer">
                     <Typography variant='subtitle1' className="!font-semibold" color='inherit.main'>{upload_title || 'Add Map'}</Typography >
                     <Typography variant='body1' color='inherit.main' sx={{
                        fontFamily: 'Rubik',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20.54px',
                     }}>{upload_description || 'Acceptable format JPEG, PNG.'}</Typography ><br />
                     {/* <Typography variant='p' color='inherit.main'>Min. 3 Images</Typography > */}
                 </Box>
             </div>
             </Box>
         )}
             </Dropzone>
             :
             <div className="w-full mt-4" style={{
                marginLeft:'17px',
                position:'relative'
             }}>
            <span className='' 
               style={{ position:'absolute',
                        backgroundColor:'white',
                        width:'25px',
                        height:'25px',
                        borderRadius:'25px',
                        background:'#EDECF9',
                        alignSelf:'center',
                        right:'10px',
                        top:'10px',
                        cursor:'pointer',
                        color:'#FF5C5C',
                        zIndex:9999,
                        textAlign:'center'
                }} onClick={removeImage}>&#10006;</span>
                {!preview?.name && (
                     <img src={preview} style={{
                        width:'100%',
                        height:'250px',
                        objectFit:'contain'
                      }} />
                )}
                {preview?.name && (
                    <div className="flex items-center flex-col justify-center" style={{
                        width:'100%',
                        height:'250px'
                      }}>
                          <InsertDriveFileIcon style={{ width: '60px', height: '60px'}} />
                          <div className="text-[#6b7280] font-medium my-2">{preview?.name}</div>
                      </div>
                )}
             </div>
             }
        </>
    )
}
export default SingleAddImage;