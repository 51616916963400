import React, { useState, useEffect } from 'react';
import { 
    Box,
    Grid,
    Paper,
    Avatar,
    TextField,
    Typography,
    CircularProgress
 } from '@mui/material';
 import { PrimaryButton, SecondaryButton, ErrorButton } from '@assystant/mui-buttons';
 import { BoxEffects, headerButton, grids } from '../../../../Config/Constants';
 import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
 import GridComponent from '../../../shared-component/grid-component/GridComponent';
 import DetailsCard from '../../../shared-component/card-component/DetailsCard';
 import LabelAndValue from '../../../shared-component/OverviewPage/overview-used-components/LabelAndValue';
 import returnError from '../../../../utils/ExportError';
 import { enqueueSnackbar } from 'notistack';
 import useExit from '../../../../utils/useExit';
 import { apiService } from '../../../../constant/AuthConstant';
 import { useParams } from 'react-router-dom';
 import NoteComponent from '../NoteComponent';
 import EmptyCard from '../../../shared-component/card-component/EmptyCard';
 import HistoryTable from '../../../shared-component/table-component/HistoryTable';
import Loading from '../../../shared-component/Loading/Loading';
import RemovePopup from '../../../shared-component/RemovePopup/RemovePopup';
import { useNavigate } from 'react-router-dom';
import AddLeads from '../AddLeads';
import LeadDetailComponent from '../../ParentComponents/LeadDetailComponent';
import ErrorBtn from '../../../shared-component/button-component/ErrorBtn';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
 



 


 const arr1 = [
    { title: 'Lead', url: '/leads' },
  ]

const LeadDetails = () =>{
    const { id } = useParams()
    const navigate = useNavigate()
    const { exitFromPlatform } = useExit()
    const [breadCrumb, setBreadcrumb] = useState(arr1);
    const [notes, setNotes] = useState('');
    const [notesList, setNoteList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedNoteToEdit, setSelectedNoteToEdit] = useState(null);
    const [details, setDetails] = useState({});
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [openEditLead, setOpenEditLead] = useState(false);

    const handelEditLead = () => {
        setOpenEditLead(!openEditLead);
      };

    const toggleRemoveModal = (id) =>{
        setOpenRemoveModal(!openRemoveModal)
      }

    const headersForHistoryTable = [
        { id: "1", 
          header_name: "Name", 
          minWidth: 150 },
        { id: "2", 
          header_name: "Email", 
          minWidth: 180 },
        { id: "3", 
          header_name: "Phone No", 
          minWidth: 150 },
        { id: "4", 
          header_name: "Date & Time", 
          minWidth: 150 },
        { id: "5",  
          header_name: "Property", 
          minWidth: 150 },
        { id: "6", 
          header_name: "Unit", 
          minWidth: 150 },
        { id: "7", 
          header_name: "Source", 
          minWidth: 150 },
        { id: "8", 
          header_name: "Updated By", 
          minWidth: 200 },
      ];

    const [leadList, setLeadList] = useState([])
    const [notesLoading, setNotesLoading] = useState(false)

    const handleNotes = (e) =>{
        setNotes(e.target.value)
    }
    const onCancelField = () =>{
        setNotes('')
        setSelectedNoteToEdit(null)
    }

    const getNotes = () =>{
        setNotesLoading(true)
        apiService?.get(`leads/note/${id}/`).then((res) =>{
            console.log(res)
            setNotesLoading(false)
            setNotes('')
            setNoteList(res?.data)
        })
        .catch((error) =>{
            setNotesLoading(false)
            if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
            }})
    }

    const editNote = (noteData, editedNote) =>{
        apiService.put(`leads/note/${noteData?.id}/`, {
            notes: editedNote
        }).then((res) =>{
            console.log(res)
            onCancelField()
            getNotes()
        })
        .catch((error) =>{
            if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
            }})
    }

    const deleteNote = (noteData) =>{
        onCancelField()
        apiService.delete(`leads/note/${noteData?.id}/`).then((res) =>{
            console.log(res)
            
            getNotes()
        })
        .catch((error) =>{
            if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
            }})
    }

    const addNote = (note) =>{
        apiService.post(`leads/note/${id}/`, {
            notes: note
          }).then((res) =>{
            console.log(res)
            onCancelField()
            getNotes()
          })
          .catch((error) =>{
            if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
            }})
    }

    const onNoteSubmit = (note) =>{
        if(selectedNoteToEdit !== null){
            editNote(selectedNoteToEdit, note)
        }else{
            addNote(note)
        }
    }

    const fetchleadDetails = () => {
        setLoading(true)
        apiService.get(`leads/prospect/${id}/`).then((res) =>{
            if(res?.data?.length === 0){
                setLeadList([])
             }
            else if(res?.data?.length > 1){
            const arrayData = [...res?.data];
            arrayData.shift();
             const updateObj = {
                 ...res?.data[0],
                 history: arrayData
             }
             const list = [updateObj];
             console.log('fro,,,,,,,', list)
           const bread = {title: `${res?.data[0]?.name}`, url: '/'};
           setBreadcrumb([...arr1, bread])
           setDetails(res?.data[0])
           setLeadList(list)
         }else{
            const bread = {title: `${res?.data[0]?.name}`, url: '/'};
            setBreadcrumb([...arr1, bread])
            setDetails(res?.data[0])
            setLeadList(res?.data)
         }
         setLoading(false)
        }).catch((error) =>{
         setLoading(false)
         if(error.response?.data){
           const returnedError = returnError(error.response?.data)
           if(returnedError === 'Given token not valid for any token type'){
             exitFromPlatform()
         }else{
             enqueueSnackbar(returnedError, {variant:'error'})
         }
       }
        })
     }

    useEffect(() =>{
        getNotes()
        fetchleadDetails()
    }, [])

    console.log('from lead List', leadList)



    const handelDeleteModal = () => {
        apiService.delete(`leads/prospect/${details?.id}/`).then((res) =>{
         enqueueSnackbar('Lead Deleted Successfully', {variant:'success'})
         
         setOpenRemoveModal(!openRemoveModal)
         setTimeout(() =>{
            navigate('/leads')
         }, 1500)
        })
        .catch((error) =>{
         if(error.response?.data){
           const returnedError = returnError(error.response?.data)
           if(returnedError === 'Given token not valid for any token type'){
             exitFromPlatform()
         }else{
             enqueueSnackbar(returnedError, {variant:'error'})
         }
         }})
     }

     const modifyLeadData = (data) =>{
         const newData = {
          ...data,
          property: data?.property?.id ? data?.property : '',
          unit: data?.unit?.id ? data?.unit : '',
         }
         return newData
      }
    return(
      loading ? <Loading />
      :
      <>
      <Box
          sx={{ 
              borderBottom: BoxEffects.borderBottom, 
              borderColor: 'secondary.main', 
              padding: BoxEffects.padding, 
              marginBottom: BoxEffects.marginButton, 
              paddingLeft: BoxEffects.margin, 
              paddingRight: headerButton.marginRigt,position:'sticky',
              top:'65px',
              background:'white',
          zIndex:99
          }}
          >
          <BreadCrumbWithActions propArr={breadCrumb}>
          <div className='flex justify-between items-center'>
              <div style={{
              marginLeft:'20px'
              }}>
              <ErrorBtn 
                style={{boxShadow:'none', fontWeight:300 }} 
                title='Remove' 
                onClick={toggleRemoveModal} 
                />
              <PrimaryBtn 
                  title="Edit"
                  style={{
                      boxShadow: 'none', 
                      marginRight:'0px !important',
                      fontWeight:300,
                      padding:'6px 16px'
                  }}
              onClick={handelEditLead}
              />
              </div>
          </div>
          </BreadCrumbWithActions>
      </Box>

      <Box 
          sx={{
              borderColor: 'secondary.main',
              paddingTop:BoxEffects.padding,
              paddingLeft:BoxEffects.margin,
              paddingRight:headerButton.marginRigt 
          }}
      >
        <LeadDetailComponent 
           notes={notes}
           details={details}
           leadList={leadList}
           setNotes={setNotes}
           editNote={editNote}
           notesList={notesList}
           deleteNote={deleteNote}
           handleNotes={handleNotes}
           onNoteSubmit={onNoteSubmit}
           notesLoading={notesLoading}
           onCancelField={onCancelField}
           setSelectedNoteToEdit={setSelectedNoteToEdit}
           headersForHistoryTable={headersForHistoryTable}
         />
      </Box>

    

          {openEditLead && (
             <AddLeads 
               title="Edit"
               open={openEditLead}
               onClose={handelEditLead}
               data={modifyLeadData(details)}
               successCallback={fetchleadDetails}
             />
          )}

          {openRemoveModal && (
            <RemovePopup 
            isSecondary={true}
            open={openRemoveModal}
            close={toggleRemoveModal}
            onSubmit={handelDeleteModal}
            description={'Are you sure you want to remove this lead?'}
            />
        )}

     </>

    )
}

export default LeadDetails