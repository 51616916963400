import React, { useState } from 'react'
import { Avatar, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import formatISOString from '../../../utils/formatISOString';

const NoteComponent = ({
    item,
    editNote,
    setNotes,
    deleteNote,
    disabled,
    setSelectedNoteToEdit
}) =>{

    const [openNestedButton, setOpenNestedButtons] = useState(false);
    const toggleNestedButton = () =>{
        setOpenNestedButtons(!openNestedButton)
    }

    return (
        <div className='flex my-6' onClick={toggleNestedButton}>
            <Avatar>{item?.employee?.name?.substring(0, 1)}</Avatar>
            <div className='cursor-pointer'>
            <div className=''>
            <Typography variant='subtitle2' className='!mx-2 !w-full !text-[#353349]' sx={{
                width: '500px !important',
                wordWrap: "break-word",
                fontWeight: 300,
            }}>{item?.notes}</Typography>
            <Typography variant='subtitle2' className='!mx-2 !text-[#8E8CA3] !w-full' sx={{
                fontWeight: 300,
            }}>{formatISOString(item?.modified)}</Typography>
            </div>
            {!disabled && (
                 <div className='flex items-center mt-2'>
                 <div className='cursor-pointer' onClick={() =>{
                     setNotes(item?.notes)
                     setSelectedNoteToEdit(item)
                 }}>
                     <Typography variant='subtitle2' className='!mx-2 !text-[#004FE8]'>{'Edit'}</Typography>
                 </div>
                 <div className='cursor-pointer' onClick={() =>{
                     deleteNote(item)
                 }}>
                     <Typography variant='subtitle2' className='!mx-2 !text-[#FF5C5C]'>{'Delete'}</Typography>
                 </div>
          </div>
            )}
            </div>
        </div>
    )
}

export default NoteComponent