import React from 'react'

const NoteIcon = ({
    width,
    height
}) =>{
    return (
        <svg width={width} height={height} viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_5086_8847" maskUnits="userSpaceOnUse" x="0" y="0" width="74" height="74">
            <rect width="74" height="74" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_5086_8847)">
            <path d="M21.5833 52.4168H28.0583L46.5583 34.071L39.9292 27.4418L21.5833 45.7877V52.4168ZM48.7167 31.9127L51.9542 28.521C52.2625 28.2127 52.4167 27.8529 52.4167 27.4418C52.4167 27.0307 52.2625 26.671 51.9542 26.3627L47.6375 22.046C47.3292 21.7377 46.9694 21.5835 46.5583 21.5835C46.1472 21.5835 45.7875 21.7377 45.4792 22.046L42.0875 25.2835L48.7167 31.9127ZM15.4167 64.7502C13.7208 64.7502 12.2686 64.1469 11.0599 62.9403C9.85331 61.7316 9.25 60.2793 9.25 58.5835V15.4168C9.25 13.721 9.85331 12.2687 11.0599 11.0601C12.2686 9.85347 13.7208 9.25016 15.4167 9.25016H28.3667C29.0347 7.40016 30.1529 5.90988 31.7213 4.77933C33.2877 3.64877 35.0472 3.0835 37 3.0835C38.9528 3.0835 40.7134 3.64877 42.2817 4.77933C43.8481 5.90988 44.9653 7.40016 45.6333 9.25016H58.5833C60.2792 9.25016 61.7314 9.85347 62.9401 11.0601C64.1467 12.2687 64.75 13.721 64.75 15.4168V58.5835C64.75 60.2793 64.1467 61.7316 62.9401 62.9403C61.7314 64.1469 60.2792 64.7502 58.5833 64.7502H15.4167ZM15.4167 58.5835H58.5833V15.4168H15.4167V58.5835ZM37 13.1043C37.6681 13.1043 38.221 12.8854 38.6588 12.4476C39.0946 12.0118 39.3125 11.4599 39.3125 10.7918C39.3125 10.1238 39.0946 9.57083 38.6588 9.133C38.221 8.69722 37.6681 8.47933 37 8.47933C36.3319 8.47933 35.78 8.69722 35.3442 9.133C34.9064 9.57083 34.6875 10.1238 34.6875 10.7918C34.6875 11.4599 34.9064 12.0118 35.3442 12.4476C35.78 12.8854 36.3319 13.1043 37 13.1043Z" fill="#B8B7C5"/>
            </g>
        </svg>
    )
}

export default NoteIcon