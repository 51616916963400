// ----------------- Documentation ---------------------------
// this component used for add and edit the data from specification group list
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup
// edit                    boolead             used for the edit form
// edittitle               string              to maintain the edit form title of the popup
// data                    array               to show the default form value in the edit form

import React, { useContext, useEffect, useState } from "react";
import FormModal from "../../../../shared-component/formmodal-component/FormModal";
import Form from "../../../../shared-component/formrelated-component/FormManager";

import { useSnackbar } from "notistack";

import UserContext from "../../../../../context/UserContext";
import { apiService } from "../../../../../constant/AuthConstant";
import returnError from "../../../../../utils/ExportError";
import useExit from "../../../../../utils/useExit";
 
function AddGroup({
    title,
    open,
    onClose,
    edit,
    editTitle,
    data,
    successCallback,
    setGroups,
    groups,
}){
    const {enqueueSnackbar} = useSnackbar();
   
    const [loading, setLoading] = useState(false)
    const { exitFromPlatform } = useExit()
 
    const defaultValues={
        name:'',
        templates:[],
    }
    const fieldMap=[
        {
            name:'name',
            label:'Group Name',
            placeholder:'Group Name',
            type:'text',
            fieldProps: {
              required: true
            }
        },
        {
            name:'templates',
            label:'Link to template',
            placeholder:'Link to items',
            type:'select',
            fieldProps:{
            multiple:true,
            choicesAPI:"/specs/template/",
            titleKey:'name'
            }
        },
       
    ]

   
    const handelformsubmit = async (e) => {
      setLoading(true)
        if(e?.name === ''){
          enqueueSnackbar('Group Name is required', {variant:'error'})
          setLoading(false)
        }else{
          
          const newData = {
            name: e?.name,
            templates: e?.templates?.map((e) =>{
              return e.id
            }) || []
          }
          let service;
          let apiUrl;
          if(data?.id){
            service = apiService.patch
            apiUrl = `specs/group/${data?.id}/`
          }else{
            service = apiService.post
            apiUrl = `specs/group/`
          }
          service(apiUrl, newData).then((res) =>{
            if(data?.id){
              enqueueSnackbar('Group Updated', {variant:'success'})
              let newList = [...groups];
              newList = newList.map((item) =>{
                if(item.id === res.data.id){
                  return res.data
                }else{
                  return item
                }
              })
              setGroups(newList)
              successCallback()
              onClose()
            }else{
              enqueueSnackbar('Group Added', {variant:'success'})
              const newList = [...groups, res.data];
              setGroups(newList)
              successCallback()
              onClose()
            }
            setLoading(false)
           
          }).catch((error) =>{
            setLoading(false)
            if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
          }
          })
        }
    }



    return(
        <>
            <FormModal title={edit? editTitle:title} open={open} onClose={onClose} >
                <Form
                    defaultValues={defaultValues}
                    fieldMap={fieldMap}
                    primaryBtnTitle={data?.name? 'Update':'Add'}
                    formSubmit={handelformsubmit}
                    data={data}
                    loading={loading}
                />
            </FormModal>
        </>
    )
}
export default AddGroup;