import React, { useEffect, useContext, useState } from 'react'
import { 
    Box,
    Grid,
 } from '@mui/material';
 import { useMediaQuery } from 'react-responsive';
import { grids } from '../../../Config/Constants';
import UserContext from '../../../context/UserContext';
import { PrimaryButton } from '@assystant/mui-buttons';
import ActionButton from '../../shared-component/button-component/ActionButton';
import DetailsCard from '../../shared-component/card-component/DetailsCard';
import ImageGridLayout from '../../shared-component/OverviewPage/components/ImageGiridLayout';
import GridComponent from '../../shared-component/grid-component/GridComponent';
import LabelAndValue from '../../shared-component/OverviewPage/overview-used-components/LabelAndValue';
import ProfileContainer from '../../shared-component/table-component/Row/ProfileContainer';
import Status from '../../shared-component/statusDropDown/Status';
import EmptyCard from '../../shared-component/card-component/EmptyCard';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';

const UnitOverviewComponent = ({
    isReadOnly = false,
    setStatus,
    unitStatus,
    onClickEdit,
    inventoryData,
    handleSideDrawer,
    toggleRemoveModal,
    handleBookingModal,
    handleStatusChange,
    handleGallaryModal,
    handleConfirmStatus,
    confirmCancellation,
    toggleEditInventoryModal
}) =>{

   const [formatted, setFormatted] = useState(null);

   const { selectedCurrency, returnFormattedCurrency } = useContext(UserContext);

   const updateMasonaryData = (newData) => {
    if (newData?.output_specs) {
      const data = { ...newData.specs };
      delete data?.template_id;
      delete data?.template_name;

      const getDataWithHeader = data?.groups?.map((e) => {
        return {
          header: newData?.output_specs?.groups?.find(
            (f) => f.grp_id === e?.grp_id,
          )?.group?.name
            ? newData?.output_specs?.groups?.find((f) => f.grp_id === e?.grp_id)
                ?.group?.name
            : groups?.find((f) => f.id === e?.grp_id)?.name,
          data: e?.items?.map((f) => {
            delete f?.item_id;

            return {
              name: f?.item?.name,
              value: Object?.entries(f)[0][1]?.id
                ? Object?.entries(f)[0][1]?.id
                : Object?.entries(f)[0][1],
            };
          }),
        };
      });
      const removeHeaders = getDataWithHeader?.map((val) => {
        return val?.data;
      });
      const concat = [].concat(...removeHeaders);
      setFormatted(concat);
    } else {
      return [];
    }
  };

   useEffect(() =>{
      updateMasonaryData(inventoryData)
   }, [inventoryData])

    const isMidScreen = useMediaQuery({ query: '(max-width: 1199px)' });

    const returnLinked = (data) => {
      const mapRelation = data?.map((e) => {
        return e?.name;
      });
      return mapRelation?.join(', ');
    };

    const unitdetails = [
      { label: 'Unit Name', value: inventoryData?.name || 'none' },
      {
        label: 'Price',
        value: inventoryData?.sellable
          ? `${returnFormattedCurrency(
              selectedCurrency?.locale,
              selectedCurrency?.currencyCode,
              inventoryData?.price,
            )}` || 'Not Provided'
          : 'Not Applicable',
      },
      {
        label: 'Token Amount',
        value: inventoryData?.sellable
          ? `${returnFormattedCurrency(
              selectedCurrency?.locale,
              selectedCurrency?.currencyCode,
              inventoryData?.token_price,
            )}` || 'Not Provided'
          : 'Not Applicable',
      },
      {
        label: 'Map Relation',
        value: returnLinked(inventoryData?.linked_hotspots) || 'none',
      },
    ];

    return(
       inventoryData?.id ?
       <GridComponent>
              <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid}>
                <DetailsCard
                  cardHeader="Unit Details"
                  style={{
                    minWidth: '100px',
                  }}
                >
                  <Grid container spacing={0} columns={grids.parrentgrid}>
                    {unitdetails.map((e, i) => {
                      return (
                        <Grid
                          item
                          xs={grids.parrentgrid}
                          lg={grids.halfGrid}
                          sx={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <LabelAndValue label={e?.label} value={e?.value} />
                        </Grid>
                      );
                    })}
                    <LabelAndValue
                    label={'Description'}
                    value={inventoryData?.description}
                  />
                  </Grid>
                </DetailsCard>
                <Box sx={{ marginTop: '20px' }}>
                <DetailsCard cardHeader="Specifications">
                  <Grid container spacing={2} columns={grids.parrentgrid} sx={{
                    maxHeight: '300px',
                    overflowY: 'scroll'
                  }}>
                    {formatted?.map((e) => {
                      return (
                        <Grid
                          item
                          xs={grids.parrentgrid}
                          lg={grids.halfGrid}
                          sx={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <LabelAndValue label={e?.name} value={e?.value} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </DetailsCard>
                </Box>
              </Grid>
              <Grid
                item
                className="!md:w-full max-[600px]:w-full"
                lg={grids.childgrid}
                sx={
                  isMidScreen
                    ? {
                        maxWidth: '50% !important',
                      }
                    : {}
                }
              >
                <Box
                  sx={{ marginBottom: '20px' }}
                  className="flex justify-start items-center !w-full"
                >
                  {/* <StatusDropDown style={{padding: '15px',width:'180px',color:'white' }} value="Available" /> */}

                  {inventoryData?.sellable === true && (
                    <div className="mr-2 w-full">
                      <Status
                        unitStatus={unitStatus}
                        handleStatusChange={(e) => {
                          if (e.target.value === 'AVAILABLE') {
                            if(inventoryData?.confirmed_booking?.id){
                              handleConfirmStatus()
                            }else{
                              handleStatusChange(e);
                            }
                           
                          } else {
                            setStatus(e);
                            handleBookingModal();
                          }
                        }}
                        disabled={isReadOnly ? true : false}
                        successStatus="AVAILABLE"
                        style={{
                          paddingTop: '27px',
                          paddingBottom: '30px',
                          minWidth: '140px',
                        }}
                      />
                    </div>
                  )}
                  {/* <StatusDropdown  /> */}
                  <PrimaryBtn
                    title="Edit"
                    style={{
                      padding: '15px',
                      boxShadow: 'none',
                      fontWeight: 300,
                    }}
                    disabled={isReadOnly}
                    onClick={toggleEditInventoryModal}
                  />
                  <ActionButton
                    title="Remove"
                    style={{
                      padding: '15px 26px',
                      boxShadow: 'none',
                      marginRight:'0px !important',
                      background: '#FF5C5C',
                    }}
                    disabled={isReadOnly}
                    onClick={toggleRemoveModal}
                  />
                </Box>
               
                <DetailsCard 
                    cardHeader="Images / videos / 3D"
                    isAdditionalButton={isReadOnly ? false : true}
                    buttonText="Add Images"
                    onButtonClick={handleGallaryModal}
                  >
                    {inventoryData?.images?.length > 0 ? (
                   <ImageGridLayout 
                    allImages={inventoryData?.images} 
                    onImageClick={isReadOnly ? () => {}  : onClickEdit}
                    />
                    ) : (
                      <div
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '18.96px',
                          letterSpacing: '-0.022em',
                          color: '#004FE8',
                        }}
                      >
                        Images have not been provided.
                      </div>
                    )}
                  </DetailsCard>
                
                  {inventoryData?.sellable !== false && (
                  <div className="my-5">
                    <DetailsCard cardHeader="Booking">
                      {inventoryData?.booking_unit?.length === 0 ? (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            if(isReadOnly){
                              return;
                            }
                            else{
                              setStatus({
                                target: {
                                  value: 'SOLD',
                                },
                              });
                              handleBookingModal();
                            }
                          }}
                          style={{
                            fontFamily: 'Rubik',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '23.7px',
                            letterSpacing: '-0.022em',
                            textAlign: 'left',
                            textDecoration: 'underline',
                            color: isReadOnly ? '#8E8CA3' : '#004FE8',
                          }}
                        >
                          Add Booking
                        </div>
                      ) : (
                        <ProfileContainer
                          profile={{
                            name: inventoryData?.booking_unit[0]?.name,
                          }}
                          cancellable={isReadOnly ? false : true}
                          onCancel={confirmCancellation}
                          onLabelClick={isReadOnly ? () => {} : handleSideDrawer}
                        />
                      )}
                    </DetailsCard>
                  </div>
                )}
               
              </Grid>
      </GridComponent>
      :
      <EmptyCard title='Unit Details are not available' />
    )
}

export default UnitOverviewComponent