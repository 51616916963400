// import React, {useCallback} from 'react'
// import Dropzone from 'react-dropzone'
// import { Box,Typography  } from '@mui/material';
// import { useState } from 'react';
// import { dropzone } from '../../../Config/Constants';
// import { themes } from '../../../Config/themesConfig';

// function DropZoneField({name}){
//     const [files, setFiles]= useState([])
    
    
//     return (
//         <Dropzone onDrop={acceptedFiles => setFiles(acceptedFiles)}>
//         {({getRootProps, getInputProps}) => (
//             <Box sx={{height: dropzone.height, border:dropzone.border, backgroundColor: dropzone.backgroundColor}} className='flex justify-center items-center'>
//             <div {...getRootProps()}>
//                 <input {...getInputProps()} />
//                 <Box sx={{textAlign:'center'}}>
//                     <Typography variant='h4' color='inherit.main'>Add Image</Typography >
//                     <Typography variant='p' color='inherit.main'>Acceptable format JPEG, PNG.</Typography ><br />
//                     <Typography variant='p' color='inherit.main'>Min. 3 Images</Typography >
//                 </Box>
//             </div>
//             </Box>
//         )}
// </Dropzone>
//       )
// }
// export default DropZoneField
import Dropzone from 'react-dropzone';
import { uniqueId } from 'lodash';
import { Button, Typography } from '@mui/material';
import { commonText } from '../../../Config/themesConfig';
import { dropbackground } from '../../../Config/Constants';
const DropZoneField = ({
    progress,
    multiFiles,
    selectedFiles=[],
    uploadingFile,
    setMultiFiles,
    setSelectedFiles,
}) =>{
    return(
        <>
        <Dropzone
          multiple={true}
          onDrop={(acceptedFiles) => {
            setMultiFiles([...multiFiles, ...acceptedFiles]);
            const newFiles = acceptedFiles?.map(item =>{
                return {
                    "uid": uniqueId(item),
                    "progress": progress,
                    "failed": false,
                    "successed": false,
                    "uploaded": uploadingFile,
                    "fileName": item.name,
                    ...item
                }
            })
            setSelectedFiles([...selectedFiles, ...newFiles])
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <>
             {
             selectedFiles.length !== 0 ?
             <>
            <section className='absolute bottom-5 '>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button color="primary" variant="contained" align="center" >
                    Add more images
                </Button>
              </div>
            </section>
             </>
             :
             <>
            <div className='border-dashed border-2 h-28 flex justify-center items-center w-full' style={dropbackground}>
             <div {...getRootProps()}>
               <input {...getInputProps()} />
               <Typography
                 variant="subtitle1"
                 align="center"
                 sx={{ color: commonText.grey}}
               >
                 Add Images
               </Typography>
               <Typography
                 variant="captionText"
                 align="center"
                 sx={{ color: commonText.grey}}
               >
                 Acceptable Format JPEG, PNG
               </Typography>
               
               <Typography
                 variant="captionText"
                 align="center"
                 sx={{ color: commonText.grey}}
               >
                 Min. 3 Images
               </Typography>
             </div>
           </div>
             </>
            }
            </>
          )}
        </Dropzone>
        </>
    )
}
export default DropZoneField
