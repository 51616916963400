import React, { useEffect, useState } from 'react';
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import ProfileContainer from '../../../shared-component/table-component/Row/ProfileContainer';
import { 
    Select,
    MenuItem,
    FormControl,
 } from '@mui/material';
 import { ExpandLess, ExpandMore } from '@mui/icons-material';
 import formatISOString from '../../../../utils/formatISOString';
 import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../utils/useExit';
import Loading from '../../../shared-component/Loading/Loading'
import EmptyCard from '../../../shared-component/card-component/EmptyCard';


const FormComponent = () =>{
    const navigate = useNavigate();
    const [formList, setFormList] = useState([]);
    const { exitFromPlatform } = useExit()
    const [loading, setLoading] = useState(false)

    const getForms = () =>{
      setLoading(true)
      apiService.get(`form_integration/forms/`)
      .then((res) =>{
         const newData = res?.data?.map((e) =>{
          return {
            ...e,
            title: e?.template?.label
          }
         })
         setFormList(newData)
         localStorage.removeItem('form-tab')
         setLoading(false)
      }).catch((error) =>{
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoading(false)
        }
        }
      })
    }

    const updateForm = (data, status) =>{
      apiService.patch(`form_integration/forms/${data?.id}/status_update/`, {
        // ...data,
        form_status: status,
        // linked_properties: data?.linked_properties?.map((e) =>{
        //   return e?.id
        // }),
        // default_name_field: data?.default_name_field?.id,
        // default_email_field: data?.default_email_field?.id,
        // default_phone_field: data?.default_phone_field?.id,
        // template: {
        //   ...data?.template,
        //   fields: data?.template?.fields?.map((e) =>{
        //     let newdata = {...e, options: e?.options?.map(o => o?.id)};
        //     delete newdata?.id
        //     return newdata
        //   })
        // }
      })
      .then((res) =>{
        if(res){
              getForms()
        }
      }).catch((error) =>{
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoading(false)
        }
        }
      })
    }

    useEffect(() =>{
      getForms()
    }, [])
    
    const columns = [
        { field: 'template_name', headerName: 'Form Title',minWidth: 150},
        { field: 'created', headerName: 'Created Date',minWidth: 200, renderCell: (params) =>{
            return formatISOString(params?.row?.created)
        }},
        { field: 'linked_properties', headerName: 'Integrated With',minWidth: 200, renderCell: (params) =>{
           if(params?.row?.linked_properties?.length){
               return params.row.linked_properties?.map((e) =>{
                return e?.name
               })?.join(',')
           }else{
            return '-'
           }
        }},
        { field: 'form_submission_counts', headerName: 'Form Submissions',minWidth: 200, renderCell: (params) =>{
          return params?.row?.form_submission_counts || '-'
        }},
        // { field: 'page_views', headerName: 'Page Views',minWidth: 200, renderCell: (params) =>{
        //   return '-'
        // }},
      
        { field: 
            'form_status', 
            headerName: 'Form Status',
            minWidth: 200,
            renderCell: (params) => (
                <>
                <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={params.row.form_status}
                      label=""
                      data-role='select-status'
                      IconComponent={(props) =>{
                        if(props?.className?.includes('MuiSelect-iconOpen')){
                            return <ExpandLess {...props} className='!fill-[#8E8CA3]' sx={{
                                pointerEvents:'none !important',
                                position:'absolute !important',
                                right:'5px !important'
                            }}/>
                        }
                        return <ExpandMore {...props} sx={{
                            pointerEvents:'none !important',
                            position:'absolute !important',
                            right:'5px !important'
                        }} className='!fill-[#8E8CA3]'/>
                        
                      }}
                      onChange={(e)=>updateForm(params.row, e.target.value)}
                        sx={{height:'40px',
                        background: 'white !important',
                        color:params.row.form_status === 'active' ? '#24D315' : '#FF5C5C',
                        fontWeight:300,
                      }}
                    >
                      <MenuItem value='active' sx={{color:'#24D315 !important', fontWeight:300,}} >Active</MenuItem>
                      <MenuItem value='inactive' sx={{color:'#FF5C5C !important', fontWeight:300,}}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </>
                ),
        },
        { 
          field: 'added_by', 
          headerName: 'Created By',
          minWidth: 400,
          renderCell: (params) => {
            return <ProfileContainer profile={{
              name: params?.row?.created_by?.full_name,
              email: params?.row?.created_by?.email,
            }} />
          },
         
        },
       
    ];
    function handelRowClick(e){
        navigate(`/forms/${e.id}`, {});
      }
  
    return (
        loading ? <Loading />
        :
        <>
          {formList?.length > 0 ?
            <DataGridTable 
            columns={columns}
            rows={formList}
            handleEvent={handelRowClick}
          />
          :
           <EmptyCard 
             title='There are no forms available'
           />
          }
        </>
    )
}

export default FormComponent