export const renderSpecScript = (uuid, url) =>{
    return `<div id="spotmink_specification_container" style="width: 100%;position: relative;"> 
         </div> 
         <script defer src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script> 
<script defer> 
var pagePath = "heritage/property-unit/?property=${uuid}"; 
var containerId = 'spotmink_specification_container'; 
</script> 
<script defer src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js" crossorigin="anonymous"></script> 
<script defer src="${url}static/js/spec_embed.js"></script>`
}
