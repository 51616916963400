import React, { useMemo, useRef, useState, useEffect } from "react";
import ToolbarIcons from "./ToolbarIcons";
import {
  Stage,
  Layer,
  Image,
  Rect,
  Transformer,
  Group,
  Line,
} from "react-konva";
import useImage from "use-image";
import { useUID } from "react-uid";
// import ToolBarActionButtons from "./ToolBarActionButtons";
import { Box, Button, Slider } from "@mui/material";
// import SingleAddImage from "./AddMap";
import { Portal } from "react-konva-utils";
// import CreatePolygon from "./newPolygon/CreatePolygon";
// import AddMap from "../../components/properties/map/AddMap";
import { SIZE, INITIAL_STATE } from "../../../Config/Constants";
import { ImageHotspot } from "./ImageHotspot";
import CreateConnections from "./CreateConnections";
import PolygonConstructor from "./polygons/PolygonConstructor";
const videoSource = "./sampleImage.jpg";

// setSelectedColorImageColor('#004FE8')
const URLImage = ({
  setSelectedColorImageColor,
  selectedImageColor,
  image,
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  newOpacity,
  setImages,
  images,
  setHistory,
  setHistoryStep,
  setPosition,
  history,
  historyStep,
  pos,
  position,
  handleObjectClick,
}) => {
  const dragUrl = React.useRef();
  const [img] = useImage(image?.src);
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      // trRef.current.nodes([shapeRef.current]);
      // trRef.current.getLayer().batchDraw();
      // setSelectedColorImageColor('#004FE8')
    }
  }, [isSelected]);
  return (
    <React.Fragment>
      <Image
        image={img}
        x={image?.x}
        y={image?.y}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        // I will use offset to set origin to the center of the image
        offsetX={img ? img.width / 2 : 0}
        offsetY={img ? img.height / 2 : 0}
        // draggable="true"
        // onDragEnd={e => {
        //   // onChange({ x: e.target.x(), y: e.target.y()})
        //   dragUrl.current = e.target.x;
        //   const id = e.target.id();
        //   const items = images.slice();
        //   const item = items.find((i) => i.id === id);
        //   const index = images.indexOf(item);
        //   const updatedHistory = history.slice(0, historyStep + 1);
        //   const pos = {
        //     x: e.target.x(),
        //     y: e.target.y(),
        //   };
        //   const newHistory = [...updatedHistory, pos];
        //   setHistory(newHistory);
        //   setHistoryStep(updatedHistory.length);
        //   setPosition(pos);

        //   onChange({
        //     ...shapeProps,
        //     x: e.target.x(),
        //     y: e.target.y(),
        //   });
        //   // update item position
        //   images[index] = {
        //   ...item,
        //   x: e.target.x(),
        //   y: e.target.y()
        // };
        // setImages(images)
        // }}

        // onTransformEnd={(e) => {
        //   // transformer is changing scale of the node
        //   // and NOT its width or height
        //   // but in the store we have only width and height
        //   // to match the data better we will reset scale on transform end
        //   const node = shapeRef.current;
        //   const scaleX = node.scaleX();
        //   const scaleY = node.scaleY();

        //   // we will reset it back
        //   node.scaleX(1);
        //   node.scaleY(1);
        //   onChange({
        //     ...shapeProps,
        //     x: node.x(),
        //     y: node.y(),
        //     // set minimal value
        //     width: Math.max(5, node.width() * scaleX),
        //     height: Math.max(node.height() * scaleY),
        //   });
        // }}
        opacity={newOpacity}
        // onDragStart= {(e) => {
        //   const id = e.target.id();
        //   const items = images.slice();
        //   const item = items.find((i) => i.id === id);
        //   const index = images.indexOf(item);
        //   images.splice(index, 1);
        //   // add to the top
        //   images.push(item);
        //   // images.unshift(item);
        //   setImages(images)
        // }}
        strokeWidth={2}
        // stroke={selectedImageColor}
        stroke={isSelected ? "#004FE8" : "#353349"}
        height="312"
        width="416"
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

function MapPageLayout({ images, setImages, template }) {
  const dragUrl = React.useRef();
  const stageRef = React.useRef();

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [openaddMapModal, setOpenaddMapModal] = useState(false);
  const [file, setFile] = useState([]);
  const [dragImages, setDragImages] = useState([]);
  const [pos, setPos] = React.useState();
  const [selectedId, selectShape] = React.useState(null);
  const [toggleImage, setToggleImage] = React.useState(true);
  const [newOpacity, setNewOpacity] = React.useState(1);
  const [position, setPosition] = useState(pos);
  const [historyStep, setHistoryStep] = useState(0);
  // const [history, setHistory] = useState(images)
  const [isDragging, setDragging] = useState(false);
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [selectedObj, setSelectedObj] = useState(null);
  const [clonedObject, setClonedObject] = useState(null);
  // const [showCloneobject, setShowCloneobject] = useState(false)
  // const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState("#000");
  const [drawCanvas, setDrawCanvas] = useState(false);
  const [newCloned, SetNewCloned] = useState({});
  const [openOpacitySlider, setOpenOpacitySlider] = useState(false);

  const imageRef = useRef(null);
  const dataRef = useRef(null);
  const [points, setPoints] = useState([]);
  const [size, setSize] = useState({});
  const [flattenedPoints, setFlattenedPoints] = useState();
  // const [position, setPosition] = useState([0, 0])
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [isPolyComplete, setPolyComplete] = useState(false);
  const [selectedImageColor, setSelectedColorImageColor] = useState("#353349");
  const [newPoints, setNewPoints] = useState([]);
  const [isDrawing, setDrawing] = React.useState(false);
  const [lines, setLines] = React.useState([
    
  ]);
  const uid = useUID();
  function handeldelete() {
    setPoints([]);
  }
  // const newPoints = points.map((e,i)=>{
  //   return(
  //     {
  //       id:uid,
  //       points:e,
  //       selected:false
  //     }
  //   )
  // })
  //   useEffect(()=>{
  //     if(isPolyComplete === true){

  //     }
  //   },[isPolyComplete, points])

  // console.log('newPoints', newPoints)

  const handelOpacitytooltip = () => {
    setOpenOpacitySlider(!openOpacitySlider);
  };

  const handleCloneClick = () => {
    if (selectedObj) {
      // setShowCloneobject(true)
      // Clone the selected object
      const cloned = Object.assign(selectedObj);
      const { id, x, y, src, imageshown } = cloned;
      SetNewCloned({
        id: uid,
        x: cloned.x,
        y: cloned.y + 350,
        src: cloned.src,
        imageshown: cloned.imageshown,
        name: cloned.name,
        hotspot: cloned.hotspot,
        wires: cloned.wires,
      });

      // Set the cloned object
      // images.push(newCloned);
    }
  };

  const handelPasteClick = () => {
    images.push(newCloned);
  };

  // const handleImageClick = (image) => {
  //   setSelectedImage(image);
  // };
  function handelModal() {
    setOpenaddMapModal(!openaddMapModal);
  }

  // const handleDragEnd = (e) => {
  //   console.log('e for history', e)
  //   setDragging(false)
  //   const updatedHistory = history.slice(0, historyStep + 1);
  //    setPos({
  //       id: e.target.id(),
  //      x: e.target.x(),
  //      y: e.target.y(),
  //    });
  //    const newHistory = [...updatedHistory, pos];
  //    setHistory(newHistory);
  //   setHistoryStep(updatedHistory.length);
  //   setPosition(pos);
  // };
  React.useEffect(() => {
    setPos({ x: 250, y: 0 });
  }, [images]);
  const checkDeselect = (e) => {
    console.log("e====", e);

    const pos = e.target.getStage().getPointerPosition();
          const newLines = lines.concat([[pos, pos]]);
          setLines(newLines);
          setDrawing(true);
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
      // setSelectedColorImageColor('#353349')
    }
    if (isPolyComplete) return;
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isMouseOverPoint && points.length >= 3) {
      setPolyComplete(true);
      if (points.length >= 3) {
        const a = {
          id: uid,
          points: points,
          selected: false,
        };
        setNewPoints([...newPoints, a]);
      }
    } else {
      setPoints([...points, mousePos]);
    }
  };

  // async function handelToggle(){
  //   const newObj = images.find((e,i)=>
  //     e?.id === selectedId
  //   )
  //   if(newObj?.imageshown === true){
  //     newObj.imageshown= false
  //   }else{
  //     newObj.imageshown= true
  //   }
  //   // newObj.imageshown? false : true;
  //   // console.log('newObj', newObj)
  // }

  // const handleUndo = (e) => {
  //   console.log('e for undo', e)
  //   // console.log('historyStep-2', historyStep)
  //   // console.log('position-2', position)
  //   if (historyStep === 0) {
  //     console.log('historyStep-3', historyStep)
  //     // console.log('position-3', position)
  //     return;
  //   }
  //   const newimg = images.map((e,i)=>{
  //     if(e.id === position.id){
  //       return{
  //         ...e, x:position.x, y:position.y
  //       }
  //     }else{
  //       return e;
  //     }
  //   })
  //   setImages(newimg);
  //   setHistoryStep((prevStep) => prevStep - 1);
  //   const previous = history[historyStep - 1];
  //   setPosition(previous);
  // };
  // const handleRedo = () => {
  //   if (historyStep === history.length - 1) {
  //     return;
  //   }
  //   const newimg = images.map((e,i)=>{
  //     if(e.id === position.id){
  //       return{
  //         ...e, x:position.x, y:position.y
  //       }
  //     }else{
  //       return e;
  //     }
  //   })
  //   setImages(newimg);
  //   setHistoryStep((nextStep) => nextStep + 1);
  //   const next = history[historyStep + 1];
  //   setPosition(next);
  //   // historyStep += 1;
  //   // const next = history[historyStep];
  //   // setPosition({
  //   //   position: next,
  //   // });
  // };
  function handelStorage() {
    localStorage.setItem("storedobject", JSON.stringify(images));
  }

  const storeItem = localStorage.getItem("storedobject");
  const newStoreItem = JSON.parse(storeItem);

  function handelShowOldImage() {
    setImages(newStoreItem);
  }
  React.useEffect(() => {
    const storeItem = localStorage.getItem("storedobject");
    const newStoreItem = JSON.parse(storeItem);
    if (images === null) {
      setImages(newStoreItem);
    }
    // setImages(newStoreItem)
  }, []);

  const handleObjectClick = (e) => {
    const clickedObject = e.target;
    console.log("clickedObject", clickedObject);
    // Set the selected object
    setSelectedImage(clickedObject);

    // Reset the cloned object
    // setClonedObject(null);
  };

  const onDeleteImage = (index) => {
    console.log("index", index);
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    // const lists = images.filter(x => {
    //   return x.Id !== selectedId;
    // })
    // setImages(lists)
  };

  // function handelColorPicker(){
  //   setShowColorPicker(!showColorPicker)
  // }
  // const handlePaletteColorChanged = (color) => {
  //   setColor( color );
  // };
  const handleChangeColor = (event) => {
    setColor(event.target.value);
  };
  const newhandelColorPicker = (e) => {
    setColor(e.target.value);
  };
  function handelDrawCanvas() {
    // setDrawCanvas(!drawCanvas)
    setDrawRect(!drawRect);
  }

  // create polygons
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const handleStageClick = (e) => {
    console.log("e=== target", e);
    const { target } = e;

    // Check if the clicked target is the stage itself or a polygon
    const clickedPolygon = target.name() === "polygon" ? target : null;

    // Deselect the currently selected polygon if clicked outside of it
    if (!clickedPolygon && selectedPolygon) {
      setSelectedPolygon(null);
    }
  };

  const videoElement = useMemo(() => {
    const element = new window.Image();
    element.width = 480;
    element.height = 360;
    element.src = videoSource;
    return element;
  }, [videoSource]); //it may come from redux
  useEffect(() => {
    const onload = function () {
      setSize({
        width: videoElement.width,
        height: videoElement.height,
      });
      setImages(videoElement);
      imageRef.current = videoElement;
    };
    videoElement.addEventListener("load", onload);
    return () => {
      videoElement.removeEventListener("load", onload);
    };
  }, [videoElement]);

  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };
  //drawing begins when mousedown event fires.
  // const handleMouseDown = (e) => {
  //   if (isPolyComplete) return
  //   const stage = e.target.getStage()
  //   const mousePos = getMousePos(stage)
  //   if (isMouseOverPoint && points.length >= 3) {
  //     setPolyComplete(true)
  //   } else {
  //     setPoints([...points, mousePos])
  //   }
  // }
  const handleMouseMove = (e) => {



    if (!isDrawing) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    const lastLine = lines[lines.length - 1].slice();
    lastLine[1] = pos;

    const newLines = lines.slice();
    newLines[newLines.length - 1] = lastLine;
    setLines(newLines);



    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    setPosition(mousePos);
  };
  const handleMouseOverStartPoint = (e) => {
    if (isPolyComplete || points.length < 3) return;
    e.target.scale({ x: 3, y: 3 });
    setMouseOverPoint(true);
  };
  const handleMouseOutStartPoint = (e) => {
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };
  const handlePointDragMove = (e) => {
    const stage = e.target.getStage();
    const index = e.target.index - 1;
    const pos = [e.target._lastPos.x, e.target._lastPos.y];
    if (pos[0] < 0) pos[0] = 0;
    if (pos[1] < 0) pos[1] = 0;
    if (pos[0] > stage.width()) pos[0] = stage.width();
    if (pos[1] > stage.height()) pos[1] = stage.height();
    setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
  };

  useEffect(() => {
    setFlattenedPoints(
      points
        .concat(isPolyComplete ? [] : position)
        .reduce((a, b) => a.concat(b), [])
    );
  }, [points]);
  const undo = () => {
    setPoints(points.slice(0, -1));
    setPolyComplete(false);
  };
  const handleGroupDragEnd = (e) => {
    //drag end listens other children circles' drag end event
    //...that's, why 'name' attr is added, see in polygon annotation part
    if (e.target.name() === "polygon") {
      let result = [];
      let copyPoints = [...points];
      copyPoints.map((point) =>
        result.push([point[0] + e.target.x(), point[1] + e.target.y()])
      );
      e.target.position({ x: 0, y: 0 }); //needs for mouse position otherwise when click undo you will see that mouse click position is not normal:)
      setPoints(result);
      // points.push(copyPoints)
    }
  };
  // const showCoordinates = () => {
  //   if (isPolyComplete) dataRef.current.style.display = ''
  // }

  // -----------------image hotspot created------------------

  // function createConnectionPoints(source, destination) {
  //   return [source.x, source.y, destination.x, destination.y];
  // }

  // function hasIntersection(position, step) {
  //   return !(
  //     step.x > position.x ||
  //     step.x + SIZE < position.x ||
  //     step.y > position.y ||
  //     step.y + SIZE < position.y
  //   );
  // }
  // function detectConnection(position, id, images) {
  //   const intersectingStep = Object.keys().find((key) => {
  //     return key !== id && hasIntersection(position, steps[key]);
  //   });
  //   if (intersectingStep) {
  //     return intersectingStep;
  //   }
  //   return null;
  // }

  // const [selectedStep, setSelectedStep] = useState(null);
  // const [connectionPreview, setConnectionPreview] = useState(null);
  // const [connections, setConnections] = useState([]);
  // function handleSelection(id) {
  //   if (selectedStep === id) {
  //     setSelectedStep(null);
  //   } else {
  //     setSelectedStep(id);
  //   }
  // }

  // function handleStepDrag(e, key) {
  //   const position = e.target.position();
  //   setSteps({
  //     ...steps,
  //     [key]: {
  //       ...steps[key],
  //       ...position
  //     }
  //   });
  // }

  // function handleAnchorDragStart(e) {
  //   const position = e.target.position();
  //   setConnectionPreview(
  //     <Line
  //       x={position.x}
  //       y={position.y}
  //       points={createConnectionPoints(position, position)}
  //       stroke="black"
  //       strokeWidth={2}
  //     />
  //   );
  // }
  // // function getMousePos(e) {
  // //   const position = e.target.position();
  // //   const stage = e.target.getStage();
  // //   const pointerPosition = stage.getPointerPosition();
  // //   return {
  // //     x: pointerPosition.x - position.x,
  // //     y: pointerPosition.y - position.y
  // //   };
  // // }

  // function handleAnchorDragMove(e) {
  //   const position = e.target.position();
  //   const mousePos = getMousePos(e);
  //   setConnectionPreview(
  //     <Line
  //       x={position.x}
  //       y={position.y}
  //       points={createConnectionPoints({ x: 0, y: 0 }, mousePos)}
  //       stroke="black"
  //       strokeWidth={2}
  //     />
  //   );
  // }
  // function handleAnchorDragEnd(e, id) {
  //   setConnectionPreview(null);
  //   const stage = e.target.getStage();
  //   const mousePos = stage.getPointerPosition();
  //   const connectionTo = detectConnection(mousePos, id, steps);
  //   if (connectionTo !== null) {
  //     setConnections([
  //       ...connections,
  //       {
  //         to: connectionTo,
  //         from: id
  //       }
  //     ]);
  //   }
  // }

  function createConnectionPoints(source, destination) {
    return [source.x, source.y, destination.x, destination.y];
  }

  function hasIntersection(position, step) {
    return !(
      step.x > position.x ||
      step.x + SIZE < position.x ||
      step.y > position.y ||
      step.y + SIZE < position.y
    );
  }

  function detectConnection(position, id, steps) {
    const intersectingStep = Object.keys(steps).find((key) => {
      return key !== id && hasIntersection(position, steps[key]);
    });
    if (intersectingStep) {
      return intersectingStep;
    }
    return null;
  }

  function dragBounds(ref) {
    if (ref.current !== null) {
      return ref.current.getAbsolutePosition();
    }
    return {
      x: 0,
      y: 0,
    };
  }

  //   function Anchor({ x, y, id }) {
  //     const anchor = useRef(null);
  //     return (
  //       <Circle
  //         x={x}
  //         y={y}
  //         radius={5}
  //         fill='black'
  //         draggable
  //         dragBoundFunc={() => dragBounds(anchor)}
  //         perfectDrawEnabled={false}
  //         ref={anchor}
  //       />
  //     )
  //   }

  const [selectedStep, setSelectedStep] = useState(null);
  const [connectionPreview, setConnectionPreview] = useState(null);
  const [connections, setConnections] = useState([]);
  const [steps, setSteps] = useState(INITIAL_STATE.steps);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [drawRect, setDrawRect] = useState(false);
  const [connectors, setConnectors] = React.useState([]);

  const [fromShapeId, setFromShapeId] = React.useState(null);
  const [drawConnections, setDrawConnections] = useState(false);

  function handleSelection(id) {
    if (selectedStep === id) {
      setSelectedStep(null);
    } else {
      setSelectedStep(id);
    }
  }

  function handleStepDrag(e, key) {
    const position = e.target.position();
    setSteps({
      ...steps,
      [key]: {
        ...steps[key],
        ...position,
      },
    });
  }

  function handleAnchorDragStart(e) {
    const position = e.target.position();
    setConnectionPreview(
      <Line
        x={position.x}
        y={position.y}
        points={createConnectionPoints(position, position)}
        stroke="black"
        strokeWidth={2}
      />
    );
  }

  function getMousePoss(e) {
    const position = e.target.position();
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    return {
      x: pointerPosition.x - position.x,
      y: pointerPosition.y - position.y,
    };
  }

  function handleAnchorDragMove(e) {
    const position = e.target.position();
    const mousePos = getMousePoss(e);
    setConnectionPreview(
      <Line
        x={position.x}
        y={position.y}
        points={createConnectionPoints({ x: 0, y: 0 }, mousePos)}
        stroke="black"
        strokeWidth={2}
      />
    );
  }

  function handleAnchorDragEnd(e, id) {
    setConnectionPreview(null);
    const stage = e.target.getStage();
    const mousePos = stage.getPointerPosition();
    const connectionTo = detectConnection(mousePos, id, steps);
    if (connectionTo !== null) {
      setConnections([
        ...connections,
        {
          to: connectionTo,
          from: id,
        },
      ]);
    }
  }

  const stepObjs = Object.keys(steps).map((key) => {
    const { x, y, colour } = steps[key];
    return (
      <Rect
        key={key}
        x={x}
        y={y}
        width={SIZE}
        height={SIZE}
        fill={colour}
        onClick={() => handleSelection(key)}
        draggable
        onDragMove={(e) => handleStepDrag(e, key)}
        perfectDrawEnabled={false}
      />
    );
  });
  const connectionObjs = connections.map((connection) => {
    console.log("connection", connection);
    const fromStep = steps[connection.from];
    const toStep = steps[connection.to];
    const lineEnd = {
      x: toStep.x - fromStep.x * 2,
      y: toStep.y - fromStep.y,
    };
    const points = createConnectionPoints({ x: SIZE / 2, y: 0 }, lineEnd);
    console.log("points", points);
    return (
      <Line
        x={fromStep.x + SIZE / 2}
        y={fromStep.y + SIZE / 2}
        points={points}
        stroke="orange"
        strokeWidth={5}
      />
    );
  });
  const borders =
    selectedStep !== null ? (
      <ImageHotspot
        id={selectedStep}
        step={steps[selectedStep]}
        onAnchorDragEnd={(e) => handleAnchorDragEnd(e, selectedStep)}
        onAnchorDragMove={handleAnchorDragMove}
        onAnchorDragStart={handleAnchorDragStart}
      />
    ) : null;

  // ----------------end image hotspot created----------------

  const handelDrawConnections = () => {
    setDrawConnections(!drawConnections);
  };

  console.log("points", points);
  const newlength = images?.length;
  console.log("size", size);
  console.log("selectedImage", selectedImage);
  console.log("images", images);



  return (
    <>
      <Button onClick={handelStorage}>store</Button>
      <Button onClick={handelShowOldImage}>show old image</Button>
      <Button onClick={handelModal}>add</Button>
      <Button onClick={handeldelete}>delete</Button>
      {/* <Button onClick={handelToggle}>toggle</Button> */}
      {/* <input type="color" value={color} onChange={handleChangeColor} /> */}
      {/* <Slider
        size="small"
        defaultValue={0}
        aria-label="Small"
        valueLabelDisplay="auto"
        min={0}
        max={1}
        onChange={e => {
          setNewOpacity(parseFloat(e.target.value));
        }}
      /> */}
      {/* <input
            id="slider"
            type="range"
            min="0"
            max="1"
            step='0.5'
            defaultValue="0"
            onChange={e => {
              setNewOpacity(parseFloat(e.target.value));
            }}
          /> */}
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        style={{ border: "1px solid grey", position: "relative" }}
        // x={200}
        // y={200}
        ref={stageRef}
        // ref={stageRef}
        // width={stageWidth}
        // height={stageHeight}
        // onDragEnd={handleDragEnd}
        onMouseDown={checkDeselect}
        // onTouchStart={checkDeselect}
        onMouseMove={handleMouseMove}
        onClick={handleStageClick}
        // onMouseDown={handleMouseDown}
        // handleDragStart={handleDragStart}

        // onMouseDown={e => {
        //   const pos = e.target.getStage().getPointerPosition();
        //   const newLines = lines.concat([[pos, pos]]);
        //   setLines(newLines);
        //   setDrawing(true);
        // }}
      //   onMouseMove={e => {
      //     if (!isDrawing) {
      //       return;
      //     }
      //     const pos = e.target.getStage().getPointerPosition();
      //     const lastLine = lines[lines.length - 1].slice();
      //     lastLine[1] = pos;
  
      //     const newLines = lines.slice();
      //     newLines[newLines.length - 1] = lastLine;
      //     setLines(newLines);
      //   }}
        onMouseUp={e => {
          setDrawing(false);
        }}
      >
        <Layer>
          {drawConnections && (
            <CreateConnections
              // connectionPoints={connectionPoints}
              // setConnectionPoints={setConnectionPoints}
              // hotspots={hotspots}
              // setLines={setLines}
              // lines={lines}
              connectors={connectors}
              images={images}
              lines={lines}
            />
          )}
          

          <Portal selector=".top-layer" enabled={isDragging}>
            {images?.map((image, index) => {
              // console.log('draging image', image)
              // console.log('index', index)

              return image?.imageshown ? (
                <React.Fragment key={index}>
                  <URLImage
                    image={image}
                    // onClick={() => handleImageClick(image)}
                    // onClick={handleObjectClick}

                    onChange={(newProps) => {
                      const newImage = { ...image, ...newProps };
                      // copy array of state
                      const newImages = images.slice();
                      newImages[index] = newImage;
                      setDragImages(newImages);

                      const rects = images.slice();
                      rects[index] = newProps;
                      setImages(rects);
                    }}
                    shapeProps={image}
                    isSelected={image.id === selectedId}
                    onSelect={() => {
                      selectShape(image.id);
                      setSelectedObj(image);
                      setSelectedImage(image);

                      if (fromShapeId) {
                        const newConnector = {
                          from: fromShapeId,
                          to: image.id,
                          id: connectors.length,
                        };
                        setConnectors(connectors.concat([newConnector]));
                        setFromShapeId(null);
                      } else {
                        setFromShapeId(image.id);
                      }
                    }}
                    onDelete={onDeleteImage}
                    newOpacity={newOpacity}
                    // onDelete={onDeleteImage}
                    setImages={setImages}
                    images={images}
                    setHistory={setHistory}
                    setHistoryStep={setHistoryStep}
                    setPosition={setPosition}
                    history={history}
                    historyStep={historyStep}
                    pos={pos}
                    position={position}
                    handleObjectClick={handleObjectClick}
                    selectedImageColor={selectedImageColor}
                    setSelectedColorImageColor={setSelectedColorImageColor}
                    // onChange={(newAttrs) => {
                    //   const rects = rectangles.slice();
                    //   rects[i] = newAttrs;
                    //   setRectangles(rects);
                    // }}
                  />
                  {drawRect && (
                    <PolygonConstructor
                      onComplete={(points) => {
                        setPoints(points);
                      }}
                
                    />
                  )}
                  {selectedImage === image && (
                    <>
                    <Rect
                      x={image.x}
                      y={image.y}
                      // width={416}
                      // height={312}
                      stroke="blue"
                      strokeWidth={2}
                      // onClick={() => handleImageClick(image)}
                      onClick={handleObjectClick}
                      fill="#E2E6EA"
                      // onDragMove={(e) => handleStepDrag(e, key)}
                    />
                   
           </>
                  )}
                </React.Fragment>
              ) : (
                ""
              );
            })}
            {/* Render the cloned object */}
          </Portal>
          {/* {showCloneobject && (
            <Rect
            x={clonedObject.x}
            y={clonedObject.y}
            width={200}
            height={200}
            stroke="blue"
            strokeWidth={2}
            // onClick={() => handleImageClick(image)}
            onClick={handleObjectClick}
          
          
        />
        )} */}

          {/* {showColorPicker && (
                <ColorsPicker
                x={300}
                y={50}
                width={50}
                height={50}
                colors={["#ff0000", "#00ff00", "#0000ff"]}
                onChange={handlePaletteColorChanged}
              />
              )} */}
          {/* {drawCanvas && (
                <CreatePolygon
                points={points}
                flattenedPoints={flattenedPoints}
                handlePointDragMove={handlePointDragMove}
                handleGroupDragEnd={handleGroupDragEnd}
                handleMouseOverStartPoint={handleMouseOverStartPoint}
                handleMouseOutStartPoint={handleMouseOutStartPoint}
                isPolyComplete={isPolyComplete}
                color={color}
                newPoints={newPoints}
                checkDeselect={checkDeselect}
                setSelectedPolygon={setSelectedPolygon}
                selectedPolygon={selectedPolygon}
                />
              )} */}
         
        </Layer>
      </Stage>
      {openOpacitySlider && (
        <Box
          sx={{
            boxShadow: "0px 1px 2px 2px #00000012",
            position: "absolute",
            bottom: "72px",
            left: "50%",
            padding: "10px",
          }}
          className="flex justify-center items-center"
        >
          <input
            id="slider"
            type="range"
            min="0"
            max="1"
            step="0.1"
            defaultValue="0"
            onChange={(e) => {
              setNewOpacity(parseFloat(e.target.value));
            }}
          />
        </Box>
      )}
      <ToolbarIcons
        // handleUndo={handleUndo}
        // handleRedo={handleRedo}
        handleCloneClick={handleCloneClick}
        handleDeleteClick={onDeleteImage}
        // handelColorPicker={handelColorPicker}
        handelDrawCanvas={handelDrawCanvas}
        newhandelColorPicker={newhandelColorPicker}
        color={color}
        handleChangeColor={handleChangeColor}
        handelPasteClick={handelPasteClick}
        handelOpacitytooltip={handelOpacitytooltip}
        handelDrawConnections={handelDrawConnections}
      />
      {/* </div> */}
      {/* {openaddMapModal && (
        <AddMap
          open={openaddMapModal}
          onClose={handelModal}
          setFile={setFile}
          setImages={setImages}
          images={images}
          toggleImage={toggleImage}
          newlength={newlength}
        />
      )} */}

      {/* <ToolbarIcons /> */}
    </>
  );
}
export default MapPageLayout;
