import React, { useEffect, useState } from "react"
import { Card, Typography, keyframes } from "@mui/material"
import ReactApexChart from "react-apexcharts"
// import PercentageLabel from "../share-component/Label/PercentageLabel"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const CardWithApex = ({
  stats, 
  statsSubtitle, 
  lineData,
  selectedCount,
  percentage
}) =>{
  
 
  const [changeDetail, setChangeDetail] = useState("remained unchanged");
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [actualStats, setActualStats] = useState(stats);
  

 
  function formatDate(dateString) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const parts = dateString.split('-');
    const day = parseInt(parts[2]);
    const month = parseInt(parts[1]);
    
    const year = new Date(dateString)?.getFullYear()
    
    return `${day} ${months[month - 1]} ${year}`;
}


function generaeData(count) {
  let data = [];
  let startDate;
  
  if (count === 7) {
      startDate = new Date();
      //startDate.setDate(startDate.getDate() - (count - 1));
      for (let i = 0; i < count; i++) {
        let currentDate = new Date(startDate);
        currentDate.setDate(currentDate.getDate() - i);
        let value = 0 //Math.floor(Math.random() * 100); // Example random value
        data.push({ date: currentDate.toISOString().split('T')[0], value: 0 });
    }
  } else if (count === 30) {
      startDate = new Date();
     // startDate.setDate(startDate.getDate() - (count - 1));

      for (let i = 0; i < count; i += 30) {
          let chunk = [];
          for (let j = 0; j < 30; j++) {
              let currentDate = new Date(startDate);
              currentDate.setDate(currentDate.getDate() - i - j);
              let value = Math.floor(Math.random() * 100); // Example random value
              chunk.push({ date: currentDate.toISOString().split('T')[0], value, label: 5 * (j + 1) });
          }
          
          data.push(chunk);
      }
  }
  else if(count === 90){
    startDate = new Date();
   // startDate.setDate(startDate.getDate() - (count - 1));

    for (let i = 0; i < count; i += 90) {
        let chunk = [];
        for (let j = 0; j < 90; j++) {
            let currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() - i - j);
            let value = Math.floor(Math.random() * 100); // Example random value
            chunk.push({ date: currentDate.toISOString().split('T')[0], value, label: 15 * (j + 1) });
        }
        data.push(chunk);
    }
  }
  else if(count === 180){
    startDate = new Date();
   // startDate.setDate(startDate.getDate() - (count - 1));

    for (let i = 0; i < count; i += 180) {
        let chunk = [];
        for (let j = 0; j < 180; j++) {
            let currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() - i - j);
            let value = Math.floor(Math.random() * 100); // Example random value
            chunk.push({ date: currentDate.toISOString().split('T')[0], value, label: 30 * (j + 1) });
        }
        data.push(chunk);
    }
  }
  else if(count === 365){
    startDate = new Date();
   // startDate.setDate(startDate.getDate() - (count - 1));

    for (let i = 0; i < count; i += 365) {
        let chunk = [];
        for (let j = 0; j < 365; j++) {
            let currentDate = new Date(startDate);
            currentDate.setDate(currentDate.getDate() - i - j);
            let value = Math.floor(Math.random() * 100); // Example random value
            chunk.push({ date: currentDate.toISOString().split('T')[0], value, label: 11 * (j + 1) });
        }
        data.push(chunk);
    }
  }
  return data;
}


useEffect(() =>{
    if(selectedCount === 7){
      const updateData = generaeData(7)?.map((e) =>{
        const find = Object.entries(lineData).find(([key, value]) => {
          return key === e.date
        })
      
        if(find){
          return {
            date: e.date,
            value: find[1]
          }
        }
        return {
          date: e.date,
          value: 0
        }
      })
    //  console.log('updateData', updateData)
      const yu = [...updateData];
      const newData = yu?.map((e, i) =>{
        return e.value
      })?.reduce((a,c) =>{
        return a + c
      }, 0)
     // setActualStats(newData)
     
      const chartData = [{
        name: statsSubtitle,
        data: yu?.map((e, i) =>{
          return e.value
        }).reverse(),
      }];
      
      const chartOptions = {
        chart: {
          id: 'line-chart',
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 3,
          colors: undefined,
          strokeColors: '#fff',
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: ['#004FE8'],
        width: 10,
        dashArray: 0, 
    },
      grid:{
        show:false
      },
      xaxis: {
        show: false,
        categories: yu?.map((e, i) =>{
            return formatDate(e?.date)
        }).reverse(),
        tooltip: {
          enabled: false
        },
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
      },
      yaxis: {
        show: false
      }
      };
      setOptions(chartOptions)
      setSeries(chartData)
    }
    else if(selectedCount === 30){
      
      const newData = generaeData(30)?.map((e) =>{
         return e.map((f, i) =>{
           const find = Object.entries(lineData).find(([key, value]) => {
              return key === f.date
           })
           if(find){
             return {
               ...f,
               date: f.date,
               value: find[1],
               label: e[i]?.date
             }
           }else{
              return {
                ...f,
                date: f.date,
                value: 0,
                label: e[i]?.date
              }
           }
         })
      })

      const newStats = newData[0]?.map((e) =>{
        console.log('from kkkkkkkkkk', e)
        return formatDate(e?.label)
      })?.reverse()
    
      const chartData = [{
        name: statsSubtitle,
        data: newData[0]?.map((e, i) =>{
          return e?.value
        }).reverse(),
      }];
      
      const chartOptions = {
        chart: {
          id: 'line-chart',
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 3,
          colors: undefined,
          strokeColors: '#fff',
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: ['#004FE8'],
        width: 10,
        dashArray: 0, 
    },
      grid:{
        show:false
      },
        xaxis: {
          show: false,
          categories: newStats,
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          tooltip: {
            enabled: false
          },
          axisTicks: {
            show: false,
        },
        },
        yaxis: {
          show: false
        }

      };
      setOptions(chartOptions)
      setSeries(chartData)
    }
    else if(selectedCount === 90){
      const newData = generaeData(90)?.map((e) =>{
        return e.map((f, i) =>{
          const find = Object.entries(lineData).find(([key, value]) => {
             return key === f.date
          })
          if(find){
            return {
              ...f,
              date: f.date,
              value: find[1],
              label: e[i]?.date
            }
          }else{
             return {
               ...f,
               date: f.date,
               value: 0,
               label: e[i]?.date
             }
          }
        })
     })
    
     const newStats = newData[0]?.map((e) =>{
      return formatDate(e?.label)
    })?.reverse()
    //setActualStats(newStats)
      // Grouped values of last 30 days
      
      const chartData = [{
        name: statsSubtitle,
        data: newData[0]?.map((e, i) =>{
          return e?.value
        }).reverse(),
      }];
      
      const chartOptions = {
        chart: {
          id: 'line-chart',
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 3,
          colors: undefined,
          strokeColors: '#fff',
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: ['#004FE8'],
        width: 10,
        dashArray: 0, 
    },
      grid:{
        show:false
      },
        xaxis: {
          categories: newStats,
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          tooltip: {
            enabled: false
          },
          axisTicks: {
            show: false,
        },
        },
        yaxis: {
          show: false
        }
      };
      setOptions(chartOptions)
      setSeries(chartData)
    }
    else if(selectedCount === 180){
      const newData = generaeData(180)?.map((e) =>{
        return e.map((f, i) =>{
          const find = Object.entries(lineData).find(([key, value]) => {
             return key === f.date
          })
          if(find){
            return {
              ...f,
              date: f.date,
              value: find[1],
              label: e[i]?.date
            }
          }else{
             return {
               ...f,
               date: f.date,
               value: 0,
               label: e[i]?.date
             }
          }
        })
     })
   
    
     const newStats = newData[0]?.map((e) =>{
      return formatDate(e?.label)
    })?.reverse()
   // setActualStats(newStats)
      // Grouped values of last 30 days
      
      const chartData = [{
        name: statsSubtitle,
        data: newData[0]?.map((e, i) =>{
          return e?.value
        }).reverse(),
      }];
      
      const chartOptions = {
        chart: {
          id: 'line-chart',
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 3,
          colors: undefined,
          strokeColors: '#fff',
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: ['#004FE8'],
        width: 10,
        dashArray: 0, 
    },
    
      grid:{
        show:false
      },
        xaxis: {
          categories: newStats,
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          tooltip: {
            enabled: false
          },
          axisTicks: {
            show: false,
        },
        },
        yaxis: {
          show: false
        }
      };
      setOptions(chartOptions)
      setSeries(chartData)
    }
    else if(selectedCount === 365){
      const newData = generaeData(365)?.map((e) =>{
        return e.map((f, i) =>{
          const find = Object.entries(lineData).find(([key, value]) => {
             return key === f.date
          })
          if(find){
            return {
              ...f,
              date: f.date,
              value: find[1],
              label: e[i]?.date
            }
          }else{
             return {
               ...f,
               date: f.date,
               value: 0,
               label: e[i]?.date
             }
          }
        })
     })
  
    

     const newStats = newData[0]?.map((e) =>{
      return formatDate(e?.label)
    })?.reverse()
   // setActualStats(newStats)
      
      // Grouped values of last 30 days
      
      const chartData = [{
        name: statsSubtitle,
        data: newData[0]?.map((e, i) =>{
          return e?.value
        }).reverse(),
      }];
      
      const chartOptions = {
        chart: {
          id: 'line-chart',
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 3,
          colors: undefined,
          strokeColors: '#fff',
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
      },
      stroke: {
        show: true,
        curve: 'straight',
        lineCap: 'butt',
        colors: ['#004FE8'],
        width: 10,
        dashArray: 0, 
    },
      grid:{
        show:false
      },
        xaxis: {
          categories: newStats,
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          tooltip: {
            enabled: false
          },
          axisTicks: {
            show: false,
        },
        },
        yaxis: {
          show: false
        }
      };
      setOptions(chartOptions)
      setSeries(chartData)
    }
},[selectedCount])


 

   return (
    <>
    <Card sx={{height:"250px",border:'2px solid #E1EBFE', boxShadow:'none'}}>
       <div className="m-4">
         <div className="flex items-center">
         <Typography className='m-4' variant='h5' sx={{
          fontFamily: 'Rubik',
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          letterSpacing: "-0.88px",
         }}>
              {actualStats}
          </Typography>
          <div className="ml-2">
           <div className="flex items-center">
              <Typography variant="subtitle1" sx={{ 
                color: percentage < 0 ? '#FF5C5C' : '#24D315',
                fontSize:'12px',
                fontFamily:'Rubik',
                fontWeight:300,
              }}>{`(${Number(percentage).toFixed(2)}%)`}</Typography>
              {percentage < 0 ?
              <ArrowDownwardIcon sx={{color:'#FF5C5C', width:'12px', height:'12px'}} />
               : 
               <ArrowUpwardIcon sx={{color:'#24D315', width:'12px', height:'12px'}} /> }
              
           </div>
          <div></div>
          </div>
         </div>
         <div>
         <Typography className='mt-4' variant='body1'
         sx={{
          fontFamily: 'Rubik',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 300,
          lineHeight: 'normal',
          letterSpacing: "-0.88px",
         }}>
              {statsSubtitle}
          </Typography>
         </div>
       </div>
       <div className="mb-8">
        {/* {Object.keys(lineData).length > 0 ?  <ReactApexChart options={data?.options} series={data?.series} type='line' height={150} /> : <></>} */}
        {options && series ?  <ReactApexChart options={options} series={series} type='line' height={150} /> : <></>}
       </div>
    </Card>
    </>
   )
}
export default CardWithApex;
