// ----------------- Documentation ---------------------------
// this component used for add and edit the data from lead list
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup
// edit                    boolead             used for the edit form
// edittitle               string              to maintain the edit form title of the popup
// data                    array               to show the default form value in the edit form


import React, { useState } from "react";

import Form from "../../shared-component/formrelated-component/FormManager";
import FormModal from "../../shared-component/formmodal-component/FormModal";

import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { apiService } from "../../../constant/AuthConstant";
import returnError from "../../../utils/ExportError";
import { enqueueSnackbar } from "notistack";
import useExit from "../../../utils/useExit";



function AddLeads({
    title,
    open,
    onClose,
    setOpenAddLead,
    newArr,
    setFetch,
    orgId,
    data,
    successCallback
    
}){
    const {enqueueSnackbar} = useSnackbar(); 
    const [loading, setLoading] = useState(false);
    const [selectedProp, setSelectedProp] = useState(null);
    const { exitFromPlatform } = useExit()
    const filtervalue= {
        name:'',
        email:'',
        phone:'',
        unit:'',
        property:'',
    }
    const defaultValues={
        name:'',
        email:'',
        phone:'',
        unit:'',
        property:'',
        ...filtervalue
    }
    
    const fieldMap=[
        {
            name:'name',
            label:'Name',
            placeholder:'Name',
            cols:2,
            type:'text',
            fieldProps: {
                required: true
            }
        },
        {
            name:'email',
            label:'Email',
            placeholder:'Email',
            cols:2,
            type:'text',
            fieldProps: {
                required: true
            }
        },
        {
            name:'phone',
            label:'Phone',
            placeholder:'Phone',
            cols:2,
            type:'number',
            fieldProps:{
               fieldType: 'number',
               required: true
            }
        },
        {
            name:'property',
            label:'property Name',
            placeholder:'Property Name',
            type:'select',
            cols:2,
            fieldProps:{
                choicesAPI:"/property/prop/?filtered_data=true",
                titleKey:'name',
                setState: setSelectedProp,
                required: true
            }
        },
        {
            name:'unit',
            label:'Unit',
            placeholder:'Unit',
            type:'select',
            fieldProps:{
                choicesAPI: selectedProp === null ? 'noAPI' : `/property/unit/?filtered_data=true&property=${selectedProp?.id}`,
                titleKey:'name',
                beforeCallMessage:'Please select property first',
                required: true
            }
        },
       
    ]

    useEffect(() =>{
     if(data?.id){
        setSelectedProp(data?.property)
     }
    }, [data])
    const handelformsubmit = (e) => {
       if(e?.name === ''){
              enqueueSnackbar('Name is required', {variant:'error'})
       }else if(e?.email === ''){
            enqueueSnackbar('Email is required', {variant:'error'})
       }else if(e?.phone === ''){
            enqueueSnackbar('Phone is required', {variant:'error'})
       }else{
           let reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
           let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
           if(reg.test(e?.phone) === false){
            enqueueSnackbar('Phone is invalid', {variant:'error'})

           }else if(emailReg.test(e?.email) === false){
            enqueueSnackbar('Email is invalid', {variant:'error'})
           }
           else{
            console.log('from email an phone data', e)
            const newData = {
                name: e?.name,
                email: e?.email,
                phone: e?.phone,
                unit: e?.unit?.id,
            }
            let apiUrl;
            let service;
            if(data?.id){
                apiUrl = `leads/prospect/${data?.session}/`
                service = apiService.put;
            }else{
                apiUrl = `leads/prospect/`
                service = apiService.post;
            }
            setLoading(true)
            service(apiUrl, newData).then((res) =>{
                console.log(res)
                setLoading(false)
                if(data?.id){
                    enqueueSnackbar('Lead Updated Successfully', {variant:'success'})
                }else{
                    enqueueSnackbar("Lead Added", {variant:'success'})
                }
                successCallback()
                onClose()
            }).catch((error) =>{
                console.log(error)
                setLoading(false)
                if(error.response?.data){
                    const returnedError = returnError(error.response?.data)
                    if(returnedError === 'Given token not valid for any token type'){
                        exitFromPlatform()
                    }else{
                        enqueueSnackbar(returnedError, {variant:'error'})
                    }
                }
            })
           }
       }
    }
 

    return(
        <>
            <FormModal title={title} open={open} onClose={onClose} >
                <Form
                    defaultValues={defaultValues}
                    fieldMap={fieldMap}
                    primaryBtnTitle={data?.name ? 'Update' : 'Add'}
                    formSubmit={handelformsubmit}
                    loading={loading}
                    data={data}
                    onCancel={onClose}
                />
            </FormModal>
        </>
    )
}
export default AddLeads;