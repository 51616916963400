import React, {useState} from "react";
import { currencyCodes } from "../../../Config/Constants";
import NativeSelectComponent from "../../components/forms/component/FormBuilderComponent/NativeSelectComponent";
import { TextField, InputAdornment } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ToolTipComponent from "../Tooltip/ToolTipComponent";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const CurrencyField = ({
   name,
   error,
   tooltip,
   helperText,
}) =>{
    const {
        setValue,
        getValues
    } = useFormContext()
    const [selectedCode, setSelectedCode] = useState("INR")
    const [selectValue, setSelectedValue] = useState('');
    return (
        <TextField 
        className='mx-4 w-full'
        sx={{
         background: 'white',
         borderBottom: "none !important",
         "& .MuiInputBase-root.MuiInput-root:before": {
           borderBottom: "none !important",
           borderColor: "white !important"
         },
       }}
       defaultValue={getValues(name).replace(/[a-z][A-Z]/, '')}
       onChange={(e) =>{
          const value = `${selectedCode}${e.target.value}`
          setSelectedValue(e.target.value)
          setValue(name, value)
       }}
       placeholder='Currency'
       disabled={false}
       error={error}
       helperText={helperText}
       InputProps={{
         startAdornment: (
             <InputAdornment position="start" className='!pl-0'>
                  <NativeSelectComponent 
                    value={selectedCode}
                    disabled={false}
                    options={currencyCodes}
                    onChange={(e) => {
                      const value = `${e.target.value}${selectValue}`
                      setSelectedCode(e.target.value)
                      setValue(name, value)
                    }}
                  />
             </InputAdornment>
         ),
         endAdornment: (
          <>
          {tooltip !== '' && (
            <ToolTipComponent title={tooltip} offset={[50, -50]}>
            <ErrorOutlineIcon color='#FFFFFF' className='!fill-[#8E8CA3]' fontSize='20' />
        </ToolTipComponent>
          )}
        </>
        )
     }}
     />
    )
}

export default CurrencyField