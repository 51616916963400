import React from 'react';
import FormModal from '../../../../shared-component/formmodal-component/FormModal';
import FormManager from '../../../../shared-component/formrelated-component/FormManager';
import { apiService } from '../../../../../constant/AuthConstant';
import { useParams } from 'react-router-dom';
import { iconsList } from '../../../../../Config/Constants';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../../utils/useExit';
import returnError from '../../../../../utils/ExportError';

const AddUpdates = ({ data, open, title, onClose, onSuccess }) => {
  const { id } = useParams();
  const { exitFromPlatform } = useExit();
  const defaultValues = {
    title: '',
    description: '',
    date: '',
    publish_date: new Date().toISOString(),
    icon: '',
  };

  const fieldMap = [
    {
      name: 'icon',
      type: 'icon_selection',
      fieldProps: {
        listofIcons: iconsList,
      },
    },
    {
      name: 'title',
      label: 'Title',
      placeholder: 'Title',
      type: 'text',
      fieldProps: {
        required: true,
      },
    },
    {
      name: 'publish_date',
      label: 'Date',
      placeholder: 'Date',
      type: 'date',
      fieldProps: {
        required: true,
      },
    },
    {
      name: 'description',
      label: 'Description',
      placeholder: 'Description',
      type: 'textarea',
      fieldProps: {
        required: true,
      },
    },
  ];

  const dateFormat = (isoDateString) => {
    const date = new Date(isoDateString);

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const submitUpdate = (e) => {
    if (e?.title === '') {
      enqueueSnackbar('Please add title', { variant: 'error' });
      return;
    } else if (e?.description === '') {
      enqueueSnackbar('Please add description', { variant: 'error' });
      return;
    } else if (e?.publish_date === '') {
      enqueueSnackbar('Please select date', { variant: 'error' });
      return;
    } else if (e?.icon === '') {
      enqueueSnackbar('Please add icon', { variant: 'error' });
      return;
    } else {
      let service;
      let apiUrl;

      if (data?.id) {
        service = apiService.put;
        apiUrl = `property/property_updates/${data?.id}/`;
      } else {
        service = apiService.post;
        apiUrl = `property/property_updates/`;
      }
      service(apiUrl, {
        ...e,
        icon: e?.icon?.name || 'Schools',
        linked_property: data?.id ? data?.linked_property : id,
        publish_date: dateFormat(e?.publish_date),
        units:
          data?.id && data?.units?.length > 0
            ? data?.units?.map((u) => {
                return u?.id;
              })
            : [],
        images:
          data?.id && data?.images?.length > 0
            ? data?.images?.map((u) => {
                return u?.id;
              })
            : [],
      })
        .then((res) => {
          if (data?.id) {
            enqueueSnackbar('Update Updated', { variant: 'success' });
          } else {
            enqueueSnackbar('Update Added', { variant: 'success' });
          }
          if (onSuccess) {
            onSuccess();
          }
          onClose();
        })
        .catch((error) => {
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (
              returnedError === 'Given token not valid for any token type' ||
              returnedError === 'Request failed with status code 401'
            ) {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
            }
          }
        });
    }
  };
  return (
    <FormModal title={title} open={open} onClose={onClose}>
      <div className="mt-4">
        <FormManager
          onCancel={onClose}
          defaultValues={defaultValues}
          fieldMap={fieldMap}
          data={data?.id ? data : {}}
          formSubmit={submitUpdate}
        />
      </div>
    </FormModal>
  );
};

export default AddUpdates;
