// --------------documentation----------------------
// GridComponent Components used for fullpage of grid system

// props                   type                usedfor
// -------                 -------             --------
// children                any             to maintain the childern components


import { Grid } from "@mui/material";
import React from "react";
import { grids } from "../../../Config/Constants";

function GridComponent({
    children,
    gridSpacing = grids.spacing,
    gridColumns = grids.parrentgrid
}){
    return(
        <>
        <Grid container spacing={gridSpacing} columns={gridColumns}>
            {children}
        </Grid>
        </>
    )
}
export default GridComponent;