import React, { useState, useEffect } from 'react'
import { 
  Box } from '@mui/material'
import Tabs from "@assystant/mui-tabs";
import { BoxEffects, headerButton } from "../../../../../Config/Constants";
import BreadCrumbWithActions from "../../../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorButton } from "@assystant/mui-buttons";
import { PrimaryButton } from "@assystant/mui-buttons";
import CodeComponent from './CodeComponent'
import RemovePopup from '../../../../shared-component/RemovePopup/RemovePopup'
import { apiService } from '../../../../../constant/AuthConstant'
import returnError from '../../../../../utils/ExportError'
import Loading from '../../../../shared-component/Loading/Loading'
import { enqueueSnackbar } from 'notistack';
import formatCodeString from '../../../../../utils/formatCodeString';
import useExit from '../../../../../utils/useExit';
import PrimaryBtn from '../../../../shared-component/button-component/PrimaryBtn';
import ErrorBtn from '../../../../shared-component/button-component/ErrorBtn';

const CustomOverview = () =>{
    const { id } = useParams(); 
    const navigate = useNavigate() 
    const [tabValue, setTabValue] = useState('1');
    const [status, setStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [codeTitle, setCodeTitle] = useState('');
    const [linkedProperties, setLinkedProperties] = useState([]);
    const [codeStatus, setCodeStatus] = useState(true);
    const [scriptDetail, setScriptDetail] = useState(null);
    const [enteredCode, setEnteredCode] = useState('');
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [allProperties, setAllProperties] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { exitFromPlatform } = useExit()
    
    const toggleRemove = () =>{
      setOpenRemoveModal(!openRemoveModal)
    }

    const handleTitle = (e) =>{
      setCodeTitle(e.target.value)
    }

    const handleLinkedProperties = (newValues) =>{
      setLinkedProperties(newValues)
    };

    const handleEnteredCode = (e) =>{
      setEnteredCode(e.target.value)
    }

    const getProperties = () =>{
      setLoading(true)
      apiService.get(`property/prop/`)
      .then((res) =>{
        setAllProperties(res?.data);
        setLoading(false)
      })
      .catch((error) =>{
        setLoading(false)
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
          }
        }
      
      })
    }

    const getScript = (codeId) =>{
      setLoading(true)
      apiService.get(`code-injection/${codeId}`)
      .then((res) =>{
        setScriptDetail(res?.data)
        const formatCode = formatCodeString(res?.data?.code);
        setEnteredCode(formatCode)
        setCodeTitle(res?.data?.title)
        setCodeStatus(res?.data?.active)
        setLinkedProperties(res?.data?.properties)
        setLoading(false)
      }).catch((error) =>{
        setLoading(false)
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
          }
        }
      })
    }

    useEffect(() =>{
      getProperties();
      console.log(id === 'javascript')
       if(id === 'css' || id === 'javascript'){
        console.log('Not Edit page')
       }else{
        getScript(id)
       }
    }, [])

    const submitCustomCode = () =>{
      if(codeTitle === ''){
        enqueueSnackbar('Title is required', {variant:'error'})
        return
      }else if(enteredCode === ''){
        enqueueSnackbar('Code is required', {variant:'error'})
        return
      }else{
        setIsSubmitting(true)
        let apiUrl;
        let service;
  
        if(id === 'css' || id === 'javascript'){
          apiUrl = `code-injection/`;
          service = apiService.post
        }else{
          apiUrl = `code-injection/${id}/`;
          service = apiService.patch
        }
        service(apiUrl, {
          title: codeTitle,
          code: enteredCode,
          properties: linkedProperties?.map((item) => item.id),
          active: codeStatus,
          code_type: scriptDetail?.code_type ? scriptDetail?.code_type : (id === 'css' ? 'CSS' : "JS"),
        })
        .then((res) =>{
          setIsSubmitting(false)
          if(id === 'css' || id === 'javascript'){
            enqueueSnackbar('Custom Code Added', {variant:'success'});
          }else{
            enqueueSnackbar('Custom Code Updated', {variant:'success'});
          }
          navigate('/custom-code');
        })
        .catch((error)=>{
          setIsSubmitting(false)
          if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
          }
        }
        })
      }
    }
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

    const arr1 = [
      {title: 'Custom Code', url: '/custom-code'},
      {title:id === 'css' || id === 'javascript' ? capitalizeFirstLetter(id) :`${codeTitle}`, url: '/'}
  ]


  const deleteScript = () =>{
    apiService.delete(`code-injection/${id}`)
     .then((res) =>{
      console.log(res)
      enqueueSnackbar('Custom Code Deleted', {variant:'success'});
      navigate('/custom-code');
     }).catch((error) =>{
      if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
        }
      }
     })
  }

    return (
        <>
         <Box 
                sx={{borderBottom: BoxEffects.borderBottom, borderColor: 'secondary.main',padding:BoxEffects.padding,marginBottom:BoxEffects.marginButton,paddingLeft:BoxEffects.margin,paddingRight:headerButton.marginRigt,position:'sticky',top:'65px',backgroundColor:'white',zIndex:99 }}
            >
                <BreadCrumbWithActions propArr={arr1}>
                    <div className="flex items-center w-full">
                        {id === 'css' || id === 'javascript' ?
                        <PrimaryBtn 
                           title='Add' 
                           disabled={isSubmitting}
                           onClick={submitCustomCode}  
                           style={{
                            boxShadow:'none',
                            marginRight: '0px !important',
                            fontWeight:300
                           }}
                        />
                        :
                        <>
                        <ErrorBtn title="Remove" onClick={toggleRemove} style={{
                            boxShadow:'none',
                            fontWeight:300
                           }}/>
                        <PrimaryBtn 
                          title='Update'  
                          disabled={isSubmitting}
                          onClick={submitCustomCode}  
                          style={{
                            boxShadow:'none',
                            marginRight: '0px !important',
                            fontWeight:300
                           }}
                        />
                        </>
                      }
                    </div>
                </BreadCrumbWithActions>
            </Box>
            <Box
            sx={{
              borderBottom: BoxEffects.borderBottom, 
              borderColor: 'secondary.main',
              padding:'8px',
              marginBottom:BoxEffects.marginButton,
              paddingLeft:'24px',
              paddingRight:headerButton.marginRigt 
            }}
            >
              <div className="">
                      {loading ?
                        <Loading />
                        :
                        <CodeComponent 
                            codeTitle={codeTitle}
                            codeStatus={codeStatus}
                            enteredCode={enteredCode}
                            handleTitle={handleTitle}
                            allProperties={allProperties}
                            setCodeStatus={setCodeStatus}
                            linkedProperties={linkedProperties}
                            handleEnteredCode={handleEnteredCode}
                            handleLinkedProperties={handleLinkedProperties}
                        />}
              </div>
              
            </Box>
            
            {openRemoveModal && (
              <RemovePopup 
                open={openRemoveModal}
                close={toggleRemove}
                description="Are you sure you want to delete this Code Block?"
                removeText="Yes, Delete"
                isSecondary
                onSubmit={deleteScript}
              />
            )}
       
        </>
    )
}

export default CustomOverview