// -----------Documentation-------------
// Login component used for log in the account

// props           type                    usedfor
// -------         -------                 ---------
// companyLogo     path                    logo image for authantication page
// publicSideImage path                    logo image for side authantication page
// fields          array                   array for fields
// formValues      object                  for formvalues
// onSubmit        function                for submit useCallback
// actionTitle     string                  action button label
// pageTitle       string                  label for forms
// login           boolean                 for open forgot password link
// checkboxAction  boolean                 for open remender me checkbox

import React, { useState, useContext, useEffect } from "react";
import SpotMink from '../../../asset/Images/SpotMink.png'
import PublicGroup from '../../../asset/Images/PublicGroup.png'
import AuthLayout from "../../shared-component/AuthLayoutPage/AuthLayout";
import { API_URL, apiService, TokenStorage } from "../../../constant/AuthConstant";
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import { useSnackbar } from "notistack";
import usePersistantTimer from "../../../utils/Timer";
import returnError from "../../../utils/ExportError";
import useExit from "../../../utils/useExit";


function Login(){
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const [count,start,pause,reset] = usePersistantTimer()
    const [isRemember, setIsRemember] = useState(false)
    const { exitFromPlatform } = useExit()
  
    
    const formValues={
        email:'',
        password:'',
    }

    const schema = yup.object().shape({
        email: yup.string().email('You must enter a valid email').required('You must enter a email'),
        password: yup
          .string()
          .required('Please enter your password.')
          .min(4, 'Password is too short - should be 4 chars minimum.'),
      });
    const field = [
        {id:1,label:'User Name', type:"text", value:'',name:'email'},
        {id:2,label:'Password', type:"password", value:'',name:'password'},
    ]
    
    const onSubmitForLogin = (data) =>{
        setLoading(true)
        apiService.post('jwt/create/', {
            ...data,
            remember_me: isRemember
        }).then((response)=>{
            setLoading(false)
            if(response.data?.is_affiliate === false && response.data?.is_consulting_partner === false){
                localStorage.clear();
                TokenStorage.storeToken(response.data.access)
                TokenStorage.storeRefreshToken(response.data.refresh)
                localStorage.setItem('profile', response.data)
                TokenStorage.storeProfileData(response.data)
                navigate('/dashboard')
                // if(response?.data?.subscription_id !== null){
                //     navigate('/dashboard')
                // }else{
                //     navigate('/pricing')
                // }
            }
            else if(response.data?.is_affiliate === false && response.data?.is_consulting_partner === true){
                localStorage.clear();
                TokenStorage.storeToken(response.data.access)
                TokenStorage.storeRefreshToken(response.data.refresh)
                localStorage.setItem('profile', response.data)
                TokenStorage.storeProfileData(response.data)
                navigate('/partner-dashboard')
            }
            else{
                if(localStorage.getItem('invitation-code') !== null){
                    TokenStorage.storeToken(response.data.access)
                    TokenStorage.storeRefreshToken(response.data.refresh)
                    localStorage.setItem('profile', response.data)
                    TokenStorage.storeProfileData(response.data)
                    navigate('/accept-invitation')
                }else{
                    TokenStorage.storeToken(response.data.access)
                    TokenStorage.storeRefreshToken(response.data.refresh)
                    TokenStorage.storeProfileData(response.data)
                    localStorage.setItem('profile', response.data)
                    navigate('/dashboard')
                }
            }
            
        }).catch((error)=>{
            setLoading(false)
            if(error.response?.data){
                const returnedError = returnError(error.response.data)
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    console.log('from login', returnedError)
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
            
            console.log('from login', error.response)
        })
    }

    const handleCheckBox = (e) =>{
        console.log(e.target.checked)
       setIsRemember(e.target.checked)
    }

    useEffect(() =>{
       if(localStorage.getItem('isloggedOut') === 'yes'){
          enqueueSnackbar('Session Expired', { variant: 'success' });
          localStorage.removeItem('isloggedOut')
       }
    }, [])

   
    return(
        <AuthLayout 
            companyLogo={SpotMink} 
            publicSideImage={PublicGroup} 
            fields={field} 
            formValues={formValues} 
            onSubmit={onSubmitForLogin} 
            actionTitle='Log In'
            pageTitle='Login'
          //  addtionaEndText={"Don’t have an account?"}
          //  additionalEndLink={"Sign Up"}
           // endLink={"/signUp"}
            forgotLink='/forgotPassword'
            login
            checkboxAction
            //checkboxAction
            schema={schema}
            isSubmitting={loading}
            handleCheckBox={handleCheckBox}
            />
    )
}
export default Login;