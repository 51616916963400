import React from 'react';

const Gallary = ({
    color = "#8E8CA3"
}) =>{
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M6.66667 28C5.93333 28 5.30578 27.7391 4.784 27.2173C4.26222 26.6956 4.00089 26.0676 4 25.3333V6.66667C4 5.93333 4.26133 5.30578 4.784 4.784C5.30667 4.26222 5.93422 4.00089 6.66667 4H25.3333C26.0667 4 26.6947 4.26133 27.2173 4.784C27.74 5.30667 28.0009 5.93422 28 6.66667V25.3333C28 26.0667 27.7391 26.6947 27.2173 27.2173C26.6956 27.74 26.0676 28.0009 25.3333 28H6.66667ZM6.66667 25.3333H25.3333V6.66667H6.66667V25.3333ZM8 22.6667H24L19 16L15 21.3333L12 17.3333L8 22.6667Z" fill={color}/>
        </svg>
    )
}

export default Gallary