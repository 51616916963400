import React, { useEffect, useState } from 'react';
import DeleteIcon from '../../../../../../../asset/icons/DeleteIocn';
import Loading from '../../../../../../shared-component/Loading/Loading';
import DataGridTable from '../../../../../../shared-component/table-component/DataGridTable';
import { useNavigate, useParams } from 'react-router-dom';
import { apiService } from '../../../../../../../constant/AuthConstant';
import useExit from '../../../../../../../utils/useExit';
import returnError from '../../../../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import RemovePopup from '../../../../../../shared-component/RemovePopup/RemovePopup';
import EmptyCard from '../../../../../../shared-component/card-component/EmptyCard';

const sampleData = [
    {id: 1, name: 'Home', page_path: '/home'},
]
const WebsitePages = () =>{
    const { webId } = useParams()
    const navigate = useNavigate()
    const { exitFromPlatform } = useExit()
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null);

    const handleOpenRemoveModal = (id) =>{
      setSelectedPage(id)
      setRemoveModal(true)
    }

    const handleCloseRemoveModal = () =>{
      setRemoveModal(false)
    }

    const columns = [
        {
          field: 'id',
          headerName: 'ID',
          minWidth: 50,
        },
        { field: 'page_title', headerName: 'Page name', minWidth: 300, renderCell: (params) =>{
          const checkIsHomeIsAvailable = [...pages].some((e) =>{
            return e?.is_home_page === true
          })
          return (
            <div className='flex items-center gap-[10px]'>
              <div style={{
                fontSize: '14px',
                fontFamily: 'Rubik',
                fontWeight: 300,
                color: '#353349'
              }}>{params?.row?.page_title}</div>
                {checkIsHomeIsAvailable === false ? 
                params?.row?.id === pages[0]?.id && (
                  <div style={{
                    background: "#24D315",
                    borderRadius:'14px',
                    padding:'5px 10px',
                    color:'#fff',
                    textAlign:'center',
                    fontFamily: "Rubik",
                    fontSize: "14px",
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: 'normal',
                  }}>
                  {`Home Page`}
                  </div>
                )
                :
                <>
                {params?.row?.is_home_page === true && (
                  <div style={{
                    background: "#24D315",
                    borderRadius:'14px',
                    padding:'5px 10px',
                    color:'#fff',
                    textAlign:'center',
                    fontFamily: "Rubik",
                    fontSize: "14px",
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: 'normal',
                  }}>
                  {`Home Page`}
                  </div>
                )}
                </>}
            </div>
          )
        }},
        { field: 'url', headerName: 'Page URL',minWidth: 200, renderCell: (params) =>{
          return params?.row?.url || '-'
        }},
        {
        field: 'actions',
        headerName: '',
        sortable: false,
        minWidth: 50,
        align:'center',
        renderCell: (params) => (
            <>
                <div className="cursor-pointer" onClick={(e)=>{
                e.stopPropagation()
                e.preventDefault()
                handleOpenRemoveModal(params?.row?.id)
                }}>
                    <DeleteIcon color="error" className="!fill-[#FF5C5C]"  />
                </div>
            </>
            ),
        },
      ];

    const handleRowClick = (event) =>{
        navigate(`/websites/${webId}/page/${event?.id}`)
    }

    const getPages = () =>{
        setLoading(true)
        apiService.get(`website/pages/?website=${webId}`).then((res) =>{
            if(res?.data?.length){
                setPages(res?.data)
                localStorage.setItem('website-tabs', null)
            }
            setLoading(false)
        }).catch((error) =>{
            if (error.response?.data) {
              const returnedError = returnError(error.response?.data);
              if (
                returnedError === 'Given token not valid for any token type' ||
                returnedError === 'Request failed with status code 401'
              ) {
                exitFromPlatform();
              } else {
                enqueueSnackbar(returnedError, { variant: 'error' });
                setLoading(false)
              }
            }
          })
    }

    useEffect(() =>{
        getPages()
    }, [])

    const removePage = () =>{
      apiService.delete(`website/pages/${selectedPage}`).then((res) =>{
         if(res?.status === 204){
          enqueueSnackbar('Deleted', { variant: 'success' })
          const removeItem = [...pages].filter((e) =>{
            return selectedPage !== e?.id
          })
          setPages(removeItem)
          handleCloseRemoveModal()
         }
      }).catch((error) =>{
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      })
    }

    return (
      <>
      { loading ? <Loading />
       :
       <>
       {pages?.length === 0 ?
            <EmptyCard 
               title='There are no pages'
               subtitle="Click “Add New Page” to add a menu"

            />
          :
          <DataGridTable
          rows={pages} 
          columns={columns}
          actionWidth={100}
          handleEvent={handleRowClick}
       />}
       </>}

       {removeModal && (
         <RemovePopup
         open={removeModal}
         close={handleCloseRemoveModal}
         description={"Are you sure, You want remove this page?"} 
         onSubmit={removePage}
       />
       )}
      </>
    )
}

export default WebsitePages