import { useController, useFormContext } from 'react-hook-form';
import FieldWrapper from './FieldWrapper';

const FormField = ({ Component, name, label, placeholder, fieldProps = {}, cols = 1, checkbox, checkboxtitle, update }) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty: fieldIsDirty, error },
    formState: {
      isDirty: formIsDirty,
      touchedFields,
      dirtyFields,
      defaultValues,
      isSubmitted,
      errors,
      isSubmitSuccessful,
      isSubmitting,
      isValid,
      isValidating,
      submitCount,
    },
  } = useController({ name, control, defaultValue: '' });
  return (
    <FieldWrapper cols={cols}>
      <Component
        id={name}
        name={name}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeHolder={placeholder}
        error={!!errors[name]}
        helperText={errors[name]?.message}
        fieldProps={fieldProps}
        checkbox={checkbox}
        update={update}
        checkboxtitle={checkboxtitle}
        {...fieldProps}
      />
    </FieldWrapper>
  );
};
export default FormField;
