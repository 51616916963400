
import React, { useState } from 'react'
import { 
    Card, 
    Grid, 
    TextField, 
    Autocomplete, 
    Chip, 
    Box,
    Select,
    MenuItem,
    FormControl,
   } from '@mui/material'
import { grids } from '../../../../../Config/Constants'
import GridComponent from '../../../../shared-component/grid-component/GridComponent'
import DetailsCard from '../../../../shared-component/card-component/DetailsCard'
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ExpandLess } from '@mui/icons-material';


const CodeComponent = ({
  codeTitle,
  codeStatus,
  enteredCode,
  handleTitle,
  setCodeStatus,
  allProperties,
  linkedProperties,
  handleEnteredCode,
  handleLinkedProperties,
}) =>{
    
    const toggleStatus = () =>{
      setCodeStatus(!codeStatus)
    }
   
    return (
        <GridComponent>
        <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid}>
          <Card sx={{
              boxShadow:'none',
              border:'2px solid #E1EBFE', 
              borderRadius:'8px',
              minHeight:'500px',
              height:'500px'
              }}>
              <TextField
                fullWidth
                multiline
                rows={20}
                placeholder='Type or paste your code here'
                className='!mt-0 !pt-0'
                defaultValue={enteredCode}
                sx={{
                  "& fieldset": { border: 'none !important' },
                }}
                onChange={(e) =>{
                  handleEnteredCode(e)
                }}
              />
          </Card>
        </Grid>
        <Grid className='!md:w-full' item xs={grids.childgrid} lg={grids.childgrid}>
          <DetailsCard cardHeader="Actions">
            <div className='w-full'>
                <TextField 
                   fullWidth
                   label="Title"
                   value={codeTitle}
                   defaultValue={codeTitle}
                   placeholder='Enter Title'
                   InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>{
                      handleTitle(e)
                    }}
                    required
                />
            </div>
            <div className='w-full'>
            <Autocomplete
              multiple
              id="property"
              value={linkedProperties}
              onChange={(event, newValue) => {
                  handleLinkedProperties(newValue)
              }}
              options={allProperties}
              popupIcon={<ExpandMore className='!fill-[#8E8CA3]'/>}
              getOptionLabel={(option) => {
                console.log('from all properties', option, allProperties)
                return option.name
              }}
              renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                  <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                  />
                  ))
              }
              renderInput={(params) => (
                  <TextField 
                     {...params} 
                     label="Property" 
                     placeholder="Property" 
                     InputLabelProps={{
                      shrink: true,
                    }}
                    required
                     />
              )}
              />
            </div>
            
            <div className='flex items-center mb-2.5 mt-2.5'>
            <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={codeStatus === true ? "Active" : "Inactive"}
                    label=""
                    role='select-status'
                    IconComponent={(props) =>{
                      if(props?.className?.includes('MuiSelect-iconOpen')){
                          return <ExpandLess {...props} className='!fill-[#8E8CA3]' sx={{
                              pointerEvents:'none !important',
                              position:'absolute !important',
                              right:'5px !important'
                          }}/>
                      }
                      return <ExpandMore {...props} sx={{
                          pointerEvents:'none !important',
                          position:'absolute !important',
                          right:'5px !important'
                      }} className='!fill-[#8E8CA3]'/>
                      
                    }}
                    onChange={(e)=>toggleStatus()}
                      sx={{height:'56px',
                      background: 'white !important',
                      color:codeStatus === true ? '#24D315' : '#FF5C5C',
                    }}
                    
                  >
                    <MenuItem value='Active' sx={{color:'#24D315 !important'}} >Active</MenuItem>
                    <MenuItem value='Inactive' sx={{color:'#FF5C5C !important'}}>Inactive</MenuItem>
                  </Select>
                </FormControl>
            </div>
          </DetailsCard>
        </Grid>
      </GridComponent>
    )
}

export default CodeComponent