import React, { useState } from 'react';
import Popover from '@mui/material/Popover';

import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const PopoverComponent = ({
  id,
  open,
  title,
  anchorEl,
  component,
  handleClick,
  handleClose,
  horizontal = 'left',
  popHandler,
  isCustomizing = false,
}) => {
  const closePopover = (event, reason) => {
    event.stopPropagation()
    if (reason === 'backdropClick') {
      handleClose()
    }else{
      handleClose()
    }
  }
  return (
    <>
      {title ? (
        <Button
          className="w-32 font-xl !shadow-none !font-normal"
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
        >
          {title}
        </Button>
      ) : (
        popHandler
      )}
      {isCustomizing ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: horizontal,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              transform: 'translate(20px, 25px)',
            },
          }}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [20, 30],
                },
              },
            ],
          }}
        >
          {component}
        </Popover>
      ) : (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: horizontal,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {component}
        </Popover>
      )}
    </>
  );
};

export default PopoverComponent;
