// ----------------- Documentation ---------------------------
// this component used for add and edit the data from assign inventory list
// this form used the firebase database to store the data


import React from "react";
import {Box,Typography} from "@mui/material"
// import FileField from "../../shared-component/formrelated-component/FileFields";
import FormField from "../../shared-component/formrelated-component/FormField";
import Select from "../../shared-component/formrelated-component/Select";
import { PropaneSharp } from "@mui/icons-material";


function AssignInventory(props){
    return(
        <>
            <Box className='flex justify-between items-center !w-full'>
                <Box className='w-1/2 ml-4'>
                    <Typography>{props.label}</Typography>
                </Box>
                <Box className='w-1/2'>
                    {props.name ?
                <FormField
                    Component={Select}
                    key={props.name}
                    {...props}
                    // fieldProps={{
                    //   multiple: true,
                    //   ...props.fieldProps,
                    // }}
                />
                    :
                    <Typography>{props.label2}</Typography>
                    }
                </Box>
            </Box>
        </>
    )
}
export default AssignInventory;