// ----------------- Documentation ---------------------------
// this component used for add and edit the data from specification list with   object_name:'',object_type:'',link_group:'' kyeys
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup
// edit                    boolead             used for the edit form
// edittitle               string              to maintain the edit form title of the popup

import { useContext, useEffect, useState } from "react"
import AddObject from "../../../shared-component/formrelated-component/AddObject";

import { useSnackbar } from "notistack";
import UserContext from "../../../../context/UserContext";
import { apiService } from "../../../../constant/AuthConstant";
import returnError from "../../../../utils/ExportError";
import useExit from "../../../../utils/useExit";
const SpecificationAddUnit = ({
    open,
    onClose,
    setOpenAddUnit,
    setFetch,
    specificationGroups,
    successCallback,
    data,
    title,
    specificationItemList,
    setItems,
}) =>{
    const [currentState, setCurrentState] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const {orgId} = useContext(UserContext);
    const [loading, setLoading] = useState(false)
    const { exitFromPlatform } = useExit();

    const saveState = (item) =>{
      setCurrentState(item.id)
    }
    const defaultValues = {
      name:'',
      type:'',
      groups:[],
      dropdown_values:[],
      tooltip:''
  }

   useEffect(() =>{
     if(data?.id){
      if(data?.type?.id === 'single'){
        setCurrentState('single')
      }else{
        setCurrentState('TEXTFIELD')
      }
     }
   }, [data])
  
    let FieldMap = [
      {
        name: 'name',
        label: 'Item Name',
        placeholder: "Item Name",
        type: 'text',
        fieldProps: {
          required: true
        }
      },
      {
        name: 'tooltip',
        label: 'Description',
        placeholder: "Description",
        type:'textarea',
        fieldProps: {
          required: true
        }
      },
      {
        name: 'type',
        label: 'Item Type',
        placeholder: 'Item Type',
        type: 'select',
        fieldProps: {
          defaultOptions: [
              { title: 'TextField', id: 'TEXTFIELD' },
              { title: 'Dropdown - Single Selection', id: 'single' },
            ],
          titleKey: 'title',
          defaultValue:'',
          save:saveState,
          required: true
        },
      },
      
      {
          name: 'groups',
          label: 'Link to Group',
          placeholder: 'Link to Group',
          type: 'select',
          fieldProps: {
            multiple:true,
            choicesAPI:"/specs/group/",
            titleKey:'name'
          },
          
        },
    ];


  const submitAfterValidation = (e) =>{
    const newFields = e?.object_choices?.map((e) =>{
      if(e.drag_example !== ''){
        return e.drag_example
      }
    })
    console.log('from ee', newFields)
    const filterNewFields = newFields?.filter((e) =>{
      if(e !== null || e !== undefined){
        return e
      }
    })
    const items = {
      name:e?.name?.replace(/\./g, ''),
      type:e.type?.id === 'single' ? 'DROPDOWN' : e.type.id,
      dropdown_values: JSON.stringify(filterNewFields),
      groups: Array.isArray(e?.groups) ? e?.groups?.map((e) =>{
        return e?.id
      }) : [],
      tooltip:e?.tooltip,
    }
    let service;
      let apiUrl;
      if(data?.id){
        service = apiService.patch
        apiUrl = `specs/item/${data?.id}/`
      }else{
        service = apiService.post
        apiUrl = `specs/item/`
      }
    console.log('from e', items)
    service(apiUrl, items).then((response)=>{
      console.log(response)
      if(data?.id){
        enqueueSnackbar('Item Updated', {variant: "success"})
        let newList = [...specificationItemList];
        newList = newList.map((item) =>{
          if(item.id === response.data.id){
            return response.data
          }else{
            return item
          }
        })
        setItems(newList)
        successCallback()
      }else{
        const newList = [...specificationItemList, response.data];
        setItems(newList)
        enqueueSnackbar('Item Added', {variant: "success"})
        successCallback()
      }
      
      setLoading(false)
      onClose()
    }).catch((error)=>{
      setLoading(false)
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
      }
    }
      console.log(error)
    })
  }
     
    const handelformsubmit = async (e) => {
     setLoading(true)
     
      if(e?.name === ''){
        enqueueSnackbar('Item Name is required', {variant:'error'})
        setLoading(false)
      }
      else if(e?.type === ''){
        enqueueSnackbar('Item Type is required', {variant:'error'})
        setLoading(false)
      }
      else{
        if(e?.type?.id === 'single'){
          const checkString = e?.object_choices?.every((e) =>{
            return e.drag_example === ''
        })
        if(checkString === true){
          enqueueSnackbar('Dropdown Value is required', {variant:'error'})
          setLoading(false)
        }else{
          submitAfterValidation(e)
        }
        }else{
          submitAfterValidation(e)
        }

      }
  }
    
    return(
        <>
        <AddObject 
        title={title}
        defaultValues={defaultValues}
        open={open} 
        onClose={onClose} 
        onCancel={onClose}
        currentState={currentState}
        FieldMap={FieldMap}
        onSubmit={handelformsubmit}
        loading={loading}
        data={data?.name ? data : {}}
        />
        </>
    )
}
export default SpecificationAddUnit