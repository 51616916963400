import * as React from 'react';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Masonry from '@mui/lab/Masonry';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import GridComponent from '../../shared-component/grid-component/GridComponent';
import { grids, masonaryText, masonarySeperator } from '../../../Config/Constants';
import EmptyCard from '../../shared-component/card-component/EmptyCard';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  color: theme.palette.text.secondary,
}));

function MasonryWithToogle({
    masonarydata=[],
}) {
  return (
    <>
          {masonarydata?.length !== 0 ?
      <Box sx={{width:'100%',boxShadow:'none', display:'flex',flexWrap:'wrap',gap:'12px'}}>
      {/* <Masonry columns={masonary.columns} spacing={masonary.spacing}> */}
        {/* {heights.map((height, index) => ( */}
      {masonarydata?.length !== 0 ?(
         <>
          {masonarydata.map((e,i)=>{
            return(
            <Paper key={i+1} sx={{boxShadow:'none',width:'32%'}} > 
              <StyledAccordion sx={{boxShadow:'none', border:'2px solid #E1EBFE'}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon className='!fill-[#8E8CA3]' />}>
                  <Typography sx={{//styleName: Sub H1;
                        fontFamily: 'Rubik',
                        fontSize: '24px',
                        fontWeight: 500,
                        lineHeight: '28.44px',
                        textAlign: 'left',
                        color:'#353349'
                        }}>
                    {e.header}
                  </Typography>
                </AccordionSummary>
                <Divider sx={{...masonarySeperator}} />
                <AccordionDetails>
                  {e.data.map((item, index)=>{
                    return(
                    <GridComponent>
                      <Grid item className='!my-1.5' xs={grids.halfGrid} sx={{
                       ...masonaryText
                      }}>
                        {item.name}
                      </Grid>
                      <Grid className='!my-1.5' item xs={grids.halfGrid} sx={{
                       ...masonaryText,
                       wordWrap: 'break-word'
                      }}>
                        {item.value}
                      </Grid>
                    </GridComponent>
                    )
                  })}
              
                </AccordionDetails>
              </StyledAccordion>
            </Paper>
            )
          })}
         </>
      )
      :
       (
        <EmptyCard
         title='No Specifications Found' 
        subtitle='Click "Add" to add new specifications'
         />
       )}
          
        {/* ))} */}
      {/* </Masonry> */}
    </Box>
    :
    <EmptyCard
         title='No Specifications Found' 
        subtitle='Click "Add" to add new specifications'
         />
    }
    
    </>
  );
}
export default MasonryWithToogle;