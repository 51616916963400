import { Box, Dialog } from '@mui/material';
import React from 'react';
// import SecondaryBtn from '../button-component/SecondaryBtn';
import SecondaryBtn from '../button-component/SecondaryBtn';
import ModalHeader from './ModalHeader';
import ShowFieldsModal from '../../components/properties/ShowFieldsModal';
// import {SecondaryButton} from '@assystant/mui-buttons';

const FormModal = ({
  title,
  canCancel = true,
  Icon = React.Fragment,
  open,
  onClose,
  closeOnBackdrop = true,
  children,
  large,
  Btn= React.Fragment,
  doneAction= false,
  dropDownbuttonOpt=[],
  dropDownButton=false,
  headerSelect=false,
  setVal,
  onDefaultOptionClick = () => {},
  showFields = false,
  templates = [],
  updateFields = (e) => {},
  withFields,
  setTemplateList,
  data,
  updatetemplateData,
  defaultTemplate,
  setFields,
  setDefaultValues,
  setLoadForm,
  loadform,
  isCustomization = false,
  width = '100%',
  additionalDetails,
  description,
  info,
  removeCloseButton
}) => {
  return (
      <>
      <Dialog
        open={open}
        onClose={closeOnBackdrop ? onClose : null}
        aria-labelledby="form-dialog-title"
        scroll="body"
        className='relative'
        sx={{
                '& .MuiDialog-container': {
                  '& .MuiPaper-root': {
                    width: width,
                  },
                },}
        }
      >
        <ModalHeader 
         setTemp={setVal} 
         headerSelect={headerSelect} 
         Icon={Icon} 
         canCancel={canCancel} 
         title={title} 
         onCancel={onClose} 
         dropDownbuttonOpt={dropDownbuttonOpt} 
         dropDownButton={dropDownButton} 
         onDefaultOptionClick={onDefaultOptionClick}
         templates={templates}
         updateFields={updateFields}
         data={data}
         defaultTemplate={defaultTemplate}
         setFields={setFields}
         setDefaultValues={setDefaultValues}
         setLoadForm={setLoadForm}
         loadform={loadform}
         isCustomization={isCustomization}
         additionalDetails={additionalDetails}
         description={description}
         info={info}
         removeCloseButton={removeCloseButton}
         />
        {children}
        {doneAction ?
        <Box className='flex justify-end items-center m-2'>
          <SecondaryBtn title='Done' />
        </Box>
        :''
        
        }
      </Dialog>
      
      {showFields && (
        <ShowFieldsModal 
          open={showFields} 
          templateList={withFields}
          setTemplateList={setTemplateList}
          onClose={onDefaultOptionClick}
          updatetemplateData={updatetemplateData}
          defaultTemplate={defaultTemplate}
          setVal={setVal}
          setLoadForm={setLoadForm}
          />
      )}
      </>
     
  );
};
export default FormModal;
