import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';

function DropDownButton({
    dropDownbuttonOpt=[],
}) {
  const [buttonVal, setButtonVal] = useState("");

  const handleChange = (event) => {
    setButtonVal(event.target.value);
  };
  return (
    <>
      <div>
        <FormControl  sx={{ m: 1, minWidth: 183 }}>
          <InputLabel id="demo-simple-select-standard-label">Fields template</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={buttonVal}
            onChange={handleChange}
            label="Age"
            sx={{margin: '0px'}}
          >
            {dropDownbuttonOpt.map((e,i)=>{
                return(
                    <MenuItem value={e.value}>{e.label}</MenuItem>
                )
            })}
          </Select>
        </FormControl>
      </div>
    </>
  );
}
export default DropDownButton;
