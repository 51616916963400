// ----------------- Documentation ---------------------------
// this component used for add and edit the data from unit list
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup
// edit                    boolead             used for the edit form
// edittitle               string              to maintain the edit form title of the popup
// data                    array               to show the default form value in the edit form

import React, { useContext, useEffect, useState } from "react";
import { quickMenuButtonPosition, quickMenuConstant } from "../../../Config/Constants";
import FormModal from "../../shared-component/formmodal-component/FormModal";
import Form from "../../shared-component/formrelated-component/FormManager";
import { apiService } from "../../../constant/AuthConstant";

import { useSnackbar } from "notistack";
import UserContext from "../../../context/UserContext";
import { useParams } from "react-router-dom";
import Info from "./Info";
 
import returnError from "../../../utils/ExportError";
import useExit from "../../../utils/useExit";


function AddUnit({
    title,
    open,
    onClose,
    data,
    templates,
    groups,
    items,
    propertyData,
    price,
    token_price,
    setInventoryList,
    inveqntoryList,
    successCallback,
}){
 
 const { id } = useParams()
 console.log('from property data', propertyData)
 const {files, setFiles, status, filesUrl, setFilesUrl} = useContext(UserContext);
 const [showFields, setShowFields] = useState(false)
 const [templatesList, setTemplateList] = useState(templates);
 const [selectTemplate, setSelectTemplate] = useState(null);
 const [defaultValues, setDefaultValues] = useState({});
 const [fields, setFields] = useState([]);
 const [loading, setLoading] = useState(false)
 const [loadForm, setLoadForm] = useState(null);
 const { exitFromPlatform } = useExit()

const {enqueueSnackbar} = useSnackbar()

const closeandClear = () =>{
  setFiles([])
  setFilesUrl([])
  onClose()
}

const handleShowFields = () =>{
    setShowFields(!showFields)
}


  async function handelAddProperty(e) {
   // setLoading(true)
   const updateFormData = {
    ...e,
    specs: {
        ...e?.specs,
        groups: e?.specs?.groups?.filter((e) =>{
            return e.items !== undefined
        })
    }
}
  
   const isValidData = updateFormData?.specs?.groups?.every(group =>
    group.items.every(item =>
      Object.values(item).every(value =>
        value !== null && value !== undefined && value !== ''
      )
    )
  );
 
    if(e?.unit_name === ''){
        setLoading(false)
        enqueueSnackbar('Please enter the unit name', {variant:'error'})
        return;
    }else if(e?.description === ''){
        setLoading(false)
        enqueueSnackbar('Please enter the description', {variant:'error'})
        return;

    }else if(e?.price === ''){
        setLoading(false)
        enqueueSnackbar('Please enter the price', {variant:'error'})
        return;
    }
    else if(e?.token_price === ''){
        setLoading(false)
        enqueueSnackbar('Please enter the token price', {variant:'error'})
        return;
    }
    else if(isValidData === false){
        setLoading(false)
        enqueueSnackbar('Please add specifications for this unit', {variant:'error'})
        return;
    }
    else{
        const updatedGroups = updateFormData?.specs?.groups?.map(group => ({
            ...group,
            items: group.items.map(item => ({
              ...item,
              [Object.keys(item)[1]]: typeof item[Object.keys(item)[1]] === 'object'
                ? item[Object.keys(item)[1]].id
                : item[Object.keys(item)[1]],
            })),
          })).map((e) =>{
            return {
                ...e,
                items: e?.items?.map((d) =>{
                    return {
                        item_id: d?.item_id,
                        item_value: Object.values(d)[1]
                    }
                })
            }
          })

        let newData = {
            name:e.unit_name,
           
            description:e.description,
            uploads:filesUrl?.map((e)=>e.id) || [],
            property:data?.property ? data?.property : Number(id),
            specs: {
                groups: updatedGroups,
                template_id: selectTemplate?.id,
            },
            special: e?.special || false,
            price: e?.price || '',
            token_price: e?.token_price || '',
            sellable: e?.sellable === true ? false : true
          }
          if(e?.sellable === false){
            newData = {
                ...newData,
                status:status,
            }
          }
          delete newData?.groups
       
        const url = document.location.href;
        const regex = /\/overview\/(\d+)\/inventory-details\//;
        const match = url.match(regex);
    
        const unitId = match ? match[1] : null;
        let apiUrl = data?.property ? `property/unit/${data?.id}/` : `property/unit/`
        let service;
        if(data?.name){
            service = apiService.put
        }else{
            service = apiService.post
        }
        service(`${apiUrl}`,newData)
        .then((res)=>{
            setLoading(false)
         
          enqueueSnackbar("Inventory Unit added successfully", { variant:"success" })
          if(!data?.name){
              const updatedList = [res?.data, ...inveqntoryList,];
              setInventoryList(updatedList)
          }
          if(data?.name){
            successCallback()
          }
          closeandClear()
        })
        .catch((error)=>{
            setLoading(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
        })
       }
    }
  
  const updateGroups = () =>{
    const getTemplates = templates?.map((e) =>{
        return {
            ...e,
            groups : groups?.filter((val) =>{
                const checkId = val?.templates?.some((s) =>{
                    return s === e?.id
                })
                if(checkId === true){
                    return val
                }
            })
        }
    })
    

    const updateItemsInTemplate = getTemplates?.map((e) =>{
        return {
            ...e,
            groups: e?.groups?.map((val) =>{
                return {
                    ...val,
                    group_selected: true,
                    is_all_selected: true,
                    fields: items?.filter((d) =>{
                        const checkId = d?.groups?.some((s) =>{
                            return s === val?.id
                        })
                        if(checkId === true){
                            return d
                        }
                    }),
                    items: items?.filter((d) =>{
                        const checkId = d?.groups?.some((s) =>{
                            return s === val?.id
                        })
                        if(checkId === true){
                            return d
                        }
                    }),
                }
            })
        }
    })
    const updatesItemsWithBoolean = updateItemsInTemplate?.map((e) =>{
        return {
            ...e,
            groups: e?.groups?.map((val) =>{
                return {
                    ...val,
                    fields: val?.fields?.map((d) =>{
                        return {
                            ...d,
                            selected: true
                        }
                    }),
                    items: val?.items?.map((d) =>{
                        return {
                            ...d,
                            selected: true
                        }
                    }),
                }
            })
        }
    })
   
    return updatesItemsWithBoolean
}

const updateFieldsAndDefaultValues2 = (temp) =>{
    setDefaultValues({})
    setFields([])
    const getDefaultValues = temp?.groups?.filter((e) =>{
        return e?.group_selected === true
    
    })?.map((e) =>{
        return {
            ...e,
            fields: e?.fields?.filter((f) =>{
                return f?.selected === true
            })
        }
    }).map((e) =>{
        return {
            grp_id: e?.id,
            items: e?.fields?.map((f) =>{
                return {
                    item_id: f?.id,
                    [f?.name]:""
                }
            })
        }
    })
    setDefaultValues({
        unit_name:'',
        unit:'',
        description:'',
        status:'',
        updated_image:files,
        specs:{
            groups: getDefaultValues
        },
        special: false,
        price: price,
        token_price: token_price,
        sellable:false
    })
    
    
    const getFieldValues = temp?.groups?.filter((e) =>{
        return e?.group_selected === true
    
    })?.map((e) =>{
        return {
            ...e,
            fields: e?.fields?.filter((f) =>{
                return f?.selected === true
            })
        }
    })?.map((e, index) =>{
        return {
            name: e?.name,
            label: e?.name,
            type:'box_layout',
            fieldProps:{
                arrayFields: e?.fields?.map((f,i) =>{
                    if(f?.type === 'DROPDOWN'){
                        return {
                            name: `specs.groups[${index}].items[${[i]}].${f?.name}`,
                            label: f?.name,
                            type: f?.type === "DROPDOWN" ? 'select' : 'text',
                            fieldProps: {
                                defaultOptions: JSON.parse(f?.dropdown_values).map((r) =>{
                                    return {title: r, id: r}
                                }),
                                titleKey: 'title',
                                tooltip: f?.tooltip || ''
                            }
                        }
                    }else{
                        return {
                            name: `specs.groups[${index}].items[${[i]}].${f?.name}`,
                            label: f?.name,
                            type: f.type === "DROPDOWN" ? 'select' : 'text',
                            fieldProps:{
                                tooltip: f.tooltip || ''
                            }
                        }
                    }
                })
            }
        }
    })
    setFields([
        {
          name:'unit_name',
          label:'Unit Name',
          placeholder:'Unit Name',
          type:'text',
          fieldProps: {
            required: true,
          },
      },
      {
        name:'sellable',
        label:'Non Sellable Unit',
        placeholder:'',
        type:'checkbox',
    },
      {
        name:'price',
        label:'Price per unit',
        placeholder:'Price per unit',
        type:'text',
        fieldProps: {
            required: true,
        },
    },
    {
        name:'token_price',
        label:'Token Amount',
        placeholder:'Token Amount',
        type:'text',
        fieldProps: {
            required: true,
          },
    },
      ...getFieldValues,
      {
        name:'description',
        label:'Description',
        placeholder:'Description',
        type:'textarea',
        fieldProps: {
            required: true,
        },
    },
    {
        name:'status',
        label:'Status',
        type:'quick_menu',
        fieldProps:{
            defaultOptions: [
                {title:'Available', value: 'AVAILABLE'},
                {title:'Sold', value: 'SOLD'}
            ],
            sx: quickMenuButtonPosition,
            elevation: quickMenuConstant
        }
    },
    {
        name:'special',
        label:'Special',
        placeholder:'',
        type:'checkbox',
        fieldProps:{
            sx: {
                position: 'absolute',
                left:'165px',
                bottom: '24px'
            },
            elevation: quickMenuConstant
        }
    },
       ])

    if(loadForm === null){
        setLoadForm(1)
    }else{
        setLoadForm(loadForm + 1)
    }
  
}

const updateFieldsAndDefaultValues = (temp) =>{
    setDefaultValues({})
    setFields([])
    const getDefaultValues = temp?.groups?.map((e) =>{
        return {
            grp_id: e?.id,
            items: e?.fields?.map((f) =>{
                return {
                    item_id: f?.id,
                    [f?.name]:""
                }
            })
        }
    })
   if(data === null){
    setDefaultValues({
        unit_name:'',
        unit:'',
        description:'',
        status:'',
        updated_image:files,
        specs:{
            groups: getDefaultValues
        },
        special: false,
        price: price,
        token_price: token_price,
        sellable:false
    })
   }else{
    if(data?.specs?.groups?.length > 0 && data !== null){
        setDefaultValues({
            unit_name:'',
            unit:'',
            description:'',
            status:'',
            updated_image:files,
            specs:{
                groups: getDefaultValues
            },
            special: false,
            price: price,
            token_price: token_price,
            sellable:false
        })
    }else{
        setDefaultValues({
            unit_name:'',
            unit:'',
            description:'',
            status:'',
            updated_image:files,
            specs:{},
            special: false,
            price: price,
            token_price: token_price,
            sellable:false
        })
    }
   }
   
    const getFieldValues = temp?.groups?.map((e, index) =>{
        return {
            name: e?.name,
            label: e?.name,
            type:'box_layout',
            fieldProps:{
                arrayFields: e?.fields?.map((f,i) =>{
                    if(f?.type === 'DROPDOWN'){
                        return {
                            name: `specs.groups[${index}].items[${[i]}].${f?.name}`,
                            label: f?.name,
                            type: f?.type === "DROPDOWN" ? 'select' : 'text',
                            fieldProps: {
                                defaultOptions: JSON.parse(f?.dropdown_values).map((r) =>{
                                    return {title: r, id: r}
                                }),
                                titleKey: 'title',
                                tooltip: f?.tooltip || ''
                            }
                        }
                    }else{
                        return {
                            name: `specs.groups[${index}].items[${[i]}].${f?.name}`,
                            label: f?.name,
                            type: f.type === "DROPDOWN" ? 'select' : 'text',
                            fieldProps:{
                                tooltip: f.tooltip || ''
                            }
                        }
                    }
                })
            }
        }
    })

    if(data === null){
        setFields([
            {
              name:'unit_name',
              label:'Unit Name',
              placeholder:'Unit Name',
              type:'text',
              fieldProps: {
                required: true,
              },
          },
          {
            name:'sellable',
            label:'Non Sellable Unit',
            placeholder:'',
            type:'checkbox',
        },
          {
            name:'price',
            label:'Price per unit',
            placeholder:'Price per unit',
            type:'text',
            fieldProps: {
                required: true,
              },
        },
        {
            name:'token_price',
            label:'Token Amount',
            placeholder:'Token Amount',
            type:'text',
            fieldProps: {
                required: true,
              },
        },
        ...getFieldValues,
          {
            name:'description',
            label:'Description',
            placeholder:'Description',
            type:'textarea',
            fieldProps: {
                required: true,
              },
        },
        {
            name:'status',
            label:'Status',
            type:'quick_menu',
            fieldProps:{
                defaultOptions: [
                    {title:'Available', value: 'AVAILABLE'},
                    {title:'Sold', value: 'SOLD'}
                ],
                sx: quickMenuButtonPosition,
                elevation: quickMenuConstant,
            }
        },
        {
            name:'special',
            label:'Special',
            placeholder:'',
            type:'checkbox',
            fieldProps:{
                sx: {
                    position: 'absolute',
                    left:'165px',
                    bottom: '24px'
                },
                elevation: quickMenuConstant
            }
        },
        ])
    }else{
        if(data?.specs?.groups?.length > 0){
            setFields([
                {
                  name:'unit_name',
                  label:'Unit Name',
                  placeholder:'Unit Name',
                  type:'text',
                  fieldProps: {
                    required: true,
                  },
              },
              {
                name:'sellable',
                label:'Non Sellable Unit',
                placeholder:'',
                type:'checkbox',
            },
              {
                name:'price',
                label:'Price per unit',
                placeholder:'Price per unit',
                type:'text',
                fieldProps: {
                    required: true,
                  },
            },
            {
                name:'token_price',
                label:'Token Amount',
                placeholder:'Token Amount',
                type:'text',
                fieldProps: {
                    required: true,
                  },
            },
            ...getFieldValues,
              {
                name:'description',
                label:'Description',
                placeholder:'Description',
                type:'textarea',
                fieldProps: {
                    required: true,
                  },
            },
            {
                name:'status',
                label:'Status',
                type:'quick_menu',
                fieldProps:{
                    defaultOptions: [
                        {title:'Available', value: 'AVAILABLE'},
                        {title:'Sold', value: 'SOLD'}
                    ],
                    sx: quickMenuButtonPosition,
                    elevation: quickMenuConstant,
                    isDisable: data?.sellable === true ? true : false
                }
            },
            {
                name:'special',
                label:'Special',
                placeholder:'',
                type:'checkbox',
                fieldProps:{
                    sx: {
                        position: 'absolute',
                        left:'165px',
                        bottom: '24px'
                    },
                    elevation: quickMenuConstant
                }
            },
            ])
        }else{
            setFields([
                {
                  name:'unit_name',
                  label:'Unit Name',
                  placeholder:'Unit Name',
                  type:'text',
                  fieldProps: {
                    required: true,
                  },
              },
              {
                name:'sellable',
                label:'Non Sellable Unit',
                placeholder:'',
                type:'checkbox',
            },
              {
                name:'price',
                label:'Price per unit',
                placeholder:'Price per unit',
                type:'text',
                fieldProps: {
                    required: true,
                  },
            },
            {
                name:'token_price',
                label:'Token Amount',
                placeholder:'Token Amount',
                type:'text',
                fieldProps: {
                    required: true,
                  },
            },
              {
                name:'description',
                label:'Description',
                placeholder:'Description',
                type:'textarea',
                fieldProps: {
                    required: true,
                  },
            },
            {
                name:'status',
                label:'Status',
                type:'quick_menu',
                fieldProps:{
                    defaultOptions: [
                        {title:'Available', value: 'AVAILABLE'},
                        {title:'Sold', value: 'SOLD'}
                    ],
                    sx: quickMenuButtonPosition,
                    elevation: quickMenuConstant,
                    isDisable: data?.sellable === true ? true : false
                    
                }
            },
            {
                name:'special',
                label:'Special',
                placeholder:'',
                type:'checkbox',
                fieldProps:{
                    sx: {
                        position: 'absolute',
                        left:'165px',
                        bottom: '24px'
                    },
                    elevation: quickMenuConstant
                }
            },
            ])
        }
    }

    

   

}

const updateTemplateAfterUpdate = (list) =>{
    if(list?.length > 0){
        const updateTemplate = list?.map((e) =>{
            return {
                ...e,
                name: e?.name,
            }
        })
       
        if(selectTemplate?.name){
            const find = updateTemplate?.find((e) =>{
                return e.name === selectTemplate?.name
            }) 
            const filterData = find?.groups?.filter((e) =>{
                return e?.group_selected === true
            
            })?.map((e) =>{
                return {
                    ...e,
                    fields: e?.fields?.filter((f) =>{
                        return f?.selected === true
                    })
                }
            })
            
            updateFieldsAndDefaultValues2({
                ...find,
                groups: filterData
            })
        }
        setTemplateList(updateTemplate)
      }
}


const updateAndCreateFields = () =>{
    const returnedTemplate = updateGroups();
    setTemplateList(returnedTemplate)
   
    if(data?.specs.template_id || data?.specs.template){
        const find = returnedTemplate?.find((e) =>{
            if(data?.tempale_name){
                return e.template_name === data?.specs?.template
            }else{
                return e.id === data?.specs?.template_id
            }
        })
       
        setSelectTemplate(find)
        updateFieldsAndDefaultValues(find)
        
    }else if(propertyData?.specs?.template_id){
        const find = returnedTemplate?.find((e) =>{
            return e.id === propertyData?.specs?.template_id
        })
        setSelectTemplate(find)
        updateFieldsAndDefaultValues(find)
    }
    else{
        setSelectTemplate(returnedTemplate[returnedTemplate?.length - 1])
        updateFieldsAndDefaultValues(returnedTemplate[returnedTemplate?.length - 1])
      }
    if(selectTemplate?.template_name){
        updateFieldsAndDefaultValues(selectTemplate)
    }
}


useEffect(()=>{
    updateAndCreateFields()
}, [])
  


   const returnData = () =>{
      if(data?.name){
            return data
      }else if(propertyData?.name){
            return propertyData
      }else{
        return {}
      }
   }

   const returnKeys = (d) => {
    let keys = Object.keys(d);
      if (keys.length > 0) {
          if(keys.length > 2){
          let lastKey = keys[keys.length - 1];
          delete d[lastKey];
          }
      }
    return d
  }

  const checkkeys = (item1, item2) =>{
    if(item2?.length > 0){
      item2.forEach((item2Obj) => {
        const item1Obj = item1.find((item1Obj) => item1Obj.item_id === item2Obj.item_id);
        if (item1Obj) {
          Object.keys(item2Obj).forEach((key) => {
            if (key !== 'item_id') { 
              item2Obj[key] = item1Obj[key];
            }
          });
        }
      });
      return item2
    }
  }

  const updateAndReturnNewValues = (values, defaultV) =>{
    const updateValues = values?.groups?.map((e) =>{
        const fields = Object.entries(e?.items).map((f) =>{
          return [f[0], returnKeys(f[1])]
      })
        return {
          ...e,
          items: Object.values(Object.fromEntries(fields))
        }
      })
      
      const newValues = {
        ...values,
        groups: updateValues?.map((e) =>{
          const findGroups = defaultV?.groups?.find((f) =>{
            return f?.grp_id === e?.grp_id
          })
          return {
            ...e,
            items: checkkeys(e?.items, findGroups?.items)
          }
        })?.filter((e) =>{
            return e?.items !== undefined
        })
      }

      return newValues
  }

    return(
        <>
        <FormModal 
          title={title} 
          open={open} 
          onClose={closeandClear} 
          data={data?.specs}
          showFields={showFields}
          headerSelect
          onDefaultOptionClick={handleShowFields}
          templates={templatesList}
          setVal={setSelectTemplate}
          updateFields={updateFieldsAndDefaultValues2}
          withFields={templatesList}
          setTemplateList={setTemplateList}
          updatetemplateData={updateTemplateAfterUpdate}
          defaultTemplate={selectTemplate}
          setFields={setFields}
          setDefaultValues={setDefaultValues}
          >
             {fields?.length > 0 ? (
                 <Form
                 defaultValues={defaultValues}
                 fieldMap={fields}
                 data={returnData()}
                 onCancel={closeandClear}
                 formSubmit={handelAddProperty}
                 loading={loading}
                 loadForm={loadForm}
                 isUnitForm={true}
                 updateValues={updateAndReturnNewValues}
                />
              ): (
                <Info info={`Please select a template`} />
              )}
           
        </FormModal>
        </>
    )
}
export default AddUnit;