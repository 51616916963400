import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import UserContext from "../../../context/UserContext";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";

const StatusDropDown = ({
     style,
     value,
     options,
     height='55px',
     name,
     disabled
    }) => {
    const {status, setStatus} = useContext(UserContext)
    const [propStatus, setPropStatus] = useState('AVAILABLE')
    const { setValue, getValues } = useFormContext() 
    // Define a function to change the status
    const handleStatusChange = (event) => {
      console.log('from status', name, event.target.value)
      setPropStatus(event.target.value)
      setStatus(event.target.value);
      setValue(name,event.target.value)
    };

    useEffect(() =>{
      if(status){
        setStatus(status);
      setPropStatus(status)
      setValue(name, status)
      }else{
        setStatus("AVAILABLE");
      setPropStatus("AVAILABLE")
      setValue(name, "AVAILABLE")
      }
    }, [])
  
    // Define a function to determine the background color based on status
    const getStatusColor = () => {
      switch (status) {
        case 'Available':
          return '#24D315';
        case 'Sold':
          return '#FF5C5C';
        default:
          return 'gray';
      }
    };
     console.log('from value', options, propStatus)
    return (
      <div >
        <FormControl >
          <Select
            value={propStatus}
            onChange={handleStatusChange}
            sx={{ 
              background: 'white !important',
              ...style, 
              height: height ,
              color: propStatus === 'AVAILABLE' ? '#24D315 !important' : '#FF5C5C !important',
            }}
            disabled={disabled}
          >
            {options.length !== 0 ? options?.map((val)=>{
             return  <MenuItem value={val.value} sx={{
              color: val.value === 'AVAILABLE' ? '#24D315' : '#FF5C5C',
             }}>{val.title}</MenuItem>
            }): <>
            <MenuItem value="available">Available</MenuItem>
            <MenuItem value="sold">Sold</MenuItem> 
            </>}
           
          </Select>
        </FormControl>
      </div>
    );
  };

  export default StatusDropDown;
  