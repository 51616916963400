import React, { useCallback, useState } from 'react';
import { Dialog } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';

const GallaryDesignOneModal = ({
    list,
    open,
    onClose,
    activeIndex,
}) =>{
    const [index, setIndex] = useState(activeIndex)
    const onNextPress = () =>{
      if(index !== list?.length - 1){
        const i = index + 1;
        setIndex(i)
      }
    }

    const onPrevPress = () =>{
       if(index !== 0){
        const i = index - 1;
        setIndex(i)
       }
    }

    const carousel = useCallback(() =>{
       return (
        <div className='mx-8 my-2'>
            <img className='object-cover' src={list[index]?.image} style={{
            width: '100%',
            height: '500px',
            }} />
            <div className='flex w-full items-center justify-center gap-[10px] my-4'>
                <div className='bg-[#EDECF9] px-2' onClick={onPrevPress}>
                    <KeyboardArrowLeftIcon className='!fill-[#000000] cursor-pointer' />
                </div>
                <div className='bg-[#EDECF9] px-2' onClick={onNextPress}>
                    <KeyboardArrowRightIcon className='!fill-[#000000] cursor-pointer' />
                </div>
            </div>
        </div>
       )
    }, [index])
    
    return(
        <Dialog
          open={open}
          onClose={onClose}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
               width: '80%',
               maxWidth: '80%',
            },
          }}
        }
        >
           <div className='bg-white h-screen relative'>
               <div className='mx-8 mt-8' style={{
                fontFamily: 'Rubik',
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '23.7px',
               }}>
                  Image Name
               </div>
               {carousel()}
               <div className='absolute top-[5px] right-[5px]' onClick={onClose}>
                   <CloseIcon className='!fill-[#000000] cursor-pointer' />
               </div>
           </div>
        </Dialog>
    )
}

export default GallaryDesignOneModal