import React from "react";
import ActionButton from "./ActionButton";
// import ActionButton from "../button/ActionButton";

function ErrorBtn(props){
    return(
        <ActionButton
            {...props}
            variant="contained"
            color="error"
            title={props.title || 'Error'}
                    // disabled
                    // type="submit"
        />
    )
}
export default ErrorBtn;