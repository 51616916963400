import React from 'react';

import DataGridTable from '../../../shared-component/table-component/DataGridTable';

import MapFormModal from './MapFormModal';
import { useState } from 'react';

import { useEffect } from 'react';
import { apiService } from '../../../../constant/AuthConstant';

import { useNavigate } from 'react-router-dom';
import Loading from '../../../shared-component/Loading/Loading';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../utils/useExit';
import { TextButton } from '@assystant/mui-buttons';
import DeleteIcon from '../../../../asset/icons/DeleteIocn';
import TextBtn from '../../../shared-component/button-component/TextBtn';

function MapContainer({
  openAddMapModal,
  closeMapform,
  id,
  template,
  propertyDetails,
  propertyData,
  setOpenAddMapModal,
  userFromOrganization,
}) {
  const [mapList, setMapList] = useState([]);
  const [submit, setSubmit] = useState(true);
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const { exitFromPlatform } = useExit();
  const [selectedMapData, setSelectedMapData] = useState(null);

  const fetchMapList = () => {
    apiService
      .get(`property/mapplan/?property=${id}`)
      .then((res) => {
        setMapList(res?.data);
        console.log('from res map', res);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoad(false);
          }
        }
      });
  };
  useEffect(() => {
    fetchMapList();
  }, [submit]);

  const handleMapModal = (data) => {
    setSelectedMapData(data);
    setOpenAddMapModal(true);
  };

  const deleteMapplan = (id) => {
    apiService
      .delete(`property/mapplan/${id}/`)
      .then((res) => {
        enqueueSnackbar('Map Deleted.', { variant: 'success' });
        fetchMapList();
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoad(false);
          }
        }
      });
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 100,
      renderCell: (params) => params.row.id || '_',
    },
    {
      field: 'map_name',
      headerName: 'Plan Name',
      minWidth: 200,
      renderCell: (params) => params.row.name || '_',
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 150,
      align: 'right',
      renderCell: (params) => (
        <>
          {userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
          userFromOrganization?.role?.name.toLowerCase() === 'developer' ? (
            <div className="w-full flex items-center px-2 cursor-pointer text-right justify-end">
              <div
                onClick={(e) => {
                  e?.persist();
                  e?.stopPropagation();
                  handleMapModal(params?.row);
                }}
              >
                <TextBtn
                  style={{
                    fontWeight: 300,
                  }}
                  title="Edit"
                />
              </div>
              <div
                onClick={(e) => {
                  e?.persist();
                  e?.stopPropagation();
                  deleteMapplan(params?.row?.id);
                }}
              >
                <DeleteIcon color="error" className="!fill-[#FF5C5C]" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const redirectHostpot = (data) => {
    localStorage.setItem('propertyData', JSON.stringify(propertyData));
    navigate(`/properties/${propertyData?.id}/plan/${data.id}`);
  };

  return (
    <>
      {load ? (
        <Loading />
      ) : mapList?.length === 0 ? (
        <EmptyCard
          title="No Maps Available"
          subtitle="Click “Add New Map” to add the map"
        />
      ) : (
        <div>
          <DataGridTable
            handleEvent={redirectHostpot}
            columns={columns}
            rows={mapList}
          />
        </div>
      )}
      {openAddMapModal && (
        <MapFormModal
          open={openAddMapModal}
          title={selectedMapData == null ? 'Add map' : 'Edit Map'}
          onClose={() => {
            setSelectedMapData(null);
            closeMapform();
          }}
          setSubmit={setSubmit}
          propertyDetails={propertyDetails}
          successCallback={fetchMapList}
          selectedData={selectedMapData == null ? null : selectedMapData}
        />
      )}
    </>
  );
}

export default MapContainer;
