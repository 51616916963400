import React, { useEffect, useState, useRef } from 'react';
import {
  TextField,
  NativeSelect,
  Radio,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import LabelCheckbox from '../../../../shared-component/formrelated-component/LabelCheckbox';

import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import ContainerForFieldType from './ContainerForFieldType';
import FilterFields from '../../../leads/Fields/FilterFields';
import FormToolbar from '../FormToolbar';
import DeleteIcon from '../../../../../asset/icons/DeleteIocn';
import { v4 } from 'uuid';
import NativeSelectComponent from './NativeSelectComponent';
import {
  countryCodes,
  currencyCodes,
  numberOfFiles,
  fileSizes,
} from '../../../../../Config/Constants';
import { fileUploadSectionStyles } from '../../../../../Config/Constants';
import SampleFilterField from '../../../leads/Fields/SampleFilterField';
import SignatureField from '../../../Documents/components/SignatureField';
import SignatureComponent from '../../../Documents/components/SignatureComponent';
import { enqueueSnackbar } from 'notistack';

const fieldTypes = [
  { name: 'Name', id: 'NAME' },
  { name: 'Email', id: 'EMAIL' },
  { name: 'Phone', id: 'PHONE' },
  { name: 'Textfield', id: 'TEXT' },
  { name: 'Dropdown - Single Selection', id: 'DROPDOWN' },
  { name: 'Dropdown - Multiple Selection', id: 'MULTISELECT' },
  { name: 'File Upload', id: 'FILEUPLOAD' },
  { name: 'Currency', id: 'CURRENCY' },
  { name: 'Checkbox', id: 'CHECKBOX' },
  { name: 'Radio', id: 'RADIO' },
  { name: 'Text Area', id: 'TEXTAREA' },
  { name: 'Signature', id: 'SIGNATURE' },
];

const fileTypes = [
  { name: 'Document', value: 'docx' },
  { name: 'Presentation', value: 'ppt' },
  { name: 'Spreadsheet', value: 'xlsx' },
  { name: 'Drawing', value: 'dwg' },
  { name: 'PDF', value: 'pdf' },
  { name: 'Image', value: 'jpeg' },
  { name: 'Video', value: 'mp4' },
  { name: 'Audio', value: 'mp3' },
];

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light' ? theme.palette.grey[100] : '#8E8CA3',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      background: '#8E8CA3',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const DraggableItems = ({
  index,
  provided,
  field,
  formState,
  setFormState,
  renderDraggableOptions,
  addOptions,
  onDeleteItem,
  sectionIndex,
  onClickFormSection,
  isSection,
  formTitle,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [openHelperField, setOpenHelperField] = useState(false);
  const sectionRef = useRef(null);
  const selectRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [formFieldType, setFormFieldType] = useState('' || field?.field_type);
  const [openSignature, setOpenSignature] = useState(false);

  const clickAndCopy = (event, variable) =>{
    event.stopPropagation();
    navigator.clipboard.writeText(variable)
    enqueueSnackbar('Variable copied', { variant: 'success' })
  }

  const handleModal = () => {
    setOpenSignature(!openSignature);
  };

  useEffect(() => {
    if (formFieldType?.id) {
      if (
        formFieldType?.id !== 'DROPDOWN' ||
        formFieldType?.id !== 'MULTISELECT'
      ) {
        if (isSection === true) {
          let cloned = { ...formState };
          const newFieldsOptions = cloned?.sections[sectionIndex]?.fields?.map(
            (e) => {
              const clonedObject = { ...e };
              delete clonedObject?.field_options;
              return {
                ...e,
              };
            },
          );
          cloned = {
            ...cloned,
            sections: cloned?.sections?.map((s, i) => {
              if (i === sectionIndex) {
                return {
                  ...s,
                  fields: newFieldsOptions,
                };
              } else {
                return s;
              }
            }),
          };
          setFormState(cloned);
        } else {
          let cloned = { ...formState };
          const newFieldsOptions = cloned?.fields?.map((e) => {
            const clonedObject = { ...e };
            delete clonedObject?.field_options;
            return {
              ...e,
            };
          });
          cloned = {
            ...cloned,
            fields: newFieldsOptions,
          };
          setFormState(cloned);
        }
      }
    }
  }, [formFieldType]);

  const handleHelperField = () => {
    setOpenHelperField(!openHelperField);
  };

  const handleClickInside = () => {
    setIsActive(true);
  };

  const handleClickOutside = (event) => {
    if (open === false) {
      if (sectionRef.current && !sectionRef.current.contains(event.target)) {
        setIsActive(false);
      }
    }
  };

  useEffect(() => {
    if (open === false) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (open === false) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [open]);

  const handleSelectFocus = () => {
    setIsActive(true);
  };

  const onFileTypeSelection = (e) => {
    let fileTypes;
    console.log('from vdsf;dks;f', formState?.fields[index], e);
    if (isSection === true) {
      fileTypes = [
        ...formState?.sections[sectionIndex]?.fields[index]?.file_types,
      ];
    } else {
      fileTypes = [...formState?.fields[index]?.file_types];
    }

    const checkType = fileTypes?.some((s) => e?.value === s);

    if (checkType === true) {
      fileTypes = fileTypes?.filter((f) => f?.value === f);
    } else {
      fileTypes = [...fileTypes, e?.value];
    }

    if (isSection === true) {
      let cloned = { ...formState };
      cloned = {
        ...cloned,
        sections: cloned?.sections?.map((s, i) => {
          if (i === sectionIndex) {
            return {
              ...s,
              fields: s?.fields?.map((f, o) => {
                if (o === index) {
                  return {
                    ...f,
                    file_types: fileTypes,
                  };
                } else {
                  return f;
                }
              }),
            };
          } else {
            return s;
          }
        }),
      };
      setFormState(cloned);
    } else {
      let cloneFormState = [...formState?.fields];
      cloneFormState[index] = {
        ...cloneFormState[index],
        file_types: fileTypes,
      };
      setFormState({
        ...formState,
        fields: cloneFormState,
      });
    }
  };

  const onAllowFileTypes = (e) => {
    if (isSection === true) {
      let cloned = { ...formState };
      cloned = {
        ...cloned,
        sections: cloned?.sections?.map((s, i) => {
          if (i === sectionIndex) {
            return {
              ...s,
              fields: s?.fields?.map((f, o) => {
                if (o === index) {
                  return {
                    ...f,
                    allow_specific_file_type: e?.target?.checked,
                  };
                } else {
                  return f;
                }
              }),
            };
          } else {
            return s;
          }
        }),
      };
      setFormState(cloned);
    } else {
      let cloneFormState = [...formState?.fields];
      cloneFormState[index] = {
        ...cloneFormState[index],
        allow_specific_file_type: e?.target?.checked,
      };
      setFormState({
        ...formState,
        fields: cloneFormState,
      });
    }
  };

  const onMandatoryChange = (e) => {
    if (isSection === true) {
      let cloned = { ...formState };
      cloned = {
        ...cloned,
        sections: cloned?.sections?.map((s, i) => {
          if (i === sectionIndex) {
            return {
              ...s,
              fields: s?.fields?.map((f, o) => {
                if (o === index) {
                  return {
                    ...f,
                    mandatory: e?.target?.checked,
                  };
                } else {
                  return f;
                }
              }),
            };
          } else {
            return s;
          }
        }),
      };
      setFormState(cloned);
    } else {
      let cloneFormState = [...formState?.fields];
      cloneFormState[index] = {
        ...cloneFormState[index],
        mandatory: e?.target?.checked,
      };
      setFormState({
        ...formState,
        fields: cloneFormState,
      });
    }
  };

  const inputChange = (e) => {
    if (isSection === true) {
      let cloned = { ...formState };

      cloned = {
        ...cloned,
        sections: cloned?.sections?.map((s, i) => {
          if (i === sectionIndex) {
            return {
              ...s,
              fields: s?.fields?.map((f, o) => {
                if (o === index) {
                  return {
                    ...f,
                    name: e?.target?.value,
                  };
                } else {
                  return f;
                }
              }),
            };
          } else {
            return s;
          }
        }),
      };
      setFormState(cloned);
    } else {
      let cloneFormState = [...formState?.fields];
      cloneFormState[index] = {
        ...cloneFormState[index],
        name: e?.target?.value,
      };
      setFormState({
        ...formState,
        fields: cloneFormState,
      });
    }
  };

  const onFieldTypeChange = (e) => {
    if (isSection === true) {
      let cloned = { ...formState };
      cloned = {
        ...cloned,
        sections: cloned?.sections?.map((s, i) => {
          if (i === sectionIndex) {
            return {
              ...s,
              fields: s?.fields?.map((f, o) => {
                if (o === index) {
                  return {
                    ...f,
                    field_type: e,
                    field_options:
                      e?.id === 'DROPDOWN' || e?.id === 'MULTISELECT'
                        ? [{ value: '', id: v4() }]
                        : [],
                  };
                } else {
                  return f;
                }
              }),
            };
          } else {
            return s;
          }
        }),
      };
      console.log('from field select', e, cloned);
      setFormState(cloned);
    } else {
      let cloneFormState = [...formState?.fields];
      cloneFormState[index] = {
        ...cloneFormState[index],
        field_type: e,
        field_options:
          e?.id === 'DROPDOWN' || e?.id === 'MULTISELECT'
            ? [{ value: '', id: v4() }]
            : [],
      };
      setFormFieldType(e);
      setFormState({
        ...formState,
        fields: cloneFormState,
      });
    }
  };

  const onMaximumFileChange = (e) => {
    if (isSection === true) {
      let cloned = { ...formState };
      cloned = {
        ...cloned,
        sections: cloned?.sections?.map((s, i) => {
          if (i === sectionIndex) {
            return {
              ...s,
              fields: s?.fields?.map((f, o) => {
                if (o === index) {
                  return {
                    ...f,
                    maximum_files: e?.target?.value,
                  };
                } else {
                  return f;
                }
              }),
            };
          } else {
            return s;
          }
        }),
      };
      setFormState(cloned);
    } else {
      let cloneFormState = [...formState?.fields];
      cloneFormState[index] = {
        ...cloneFormState[index],
        maximum_files: e?.target?.value,
      };
      setFormState({
        ...formState,
        fields: cloneFormState,
      });
    }
  };

  const onMaximumFileSizeChange = (e) => {
    if (isSection === true) {
      let cloned = { ...formState };
      cloned = {
        ...cloned,
        sections: cloned?.sections?.map((s, i) => {
          if (i === sectionIndex) {
            return {
              ...s,
              fields: s?.fields?.map((f, o) => {
                if (o === index) {
                  return {
                    ...f,
                    maximum_size: e?.target?.value,
                  };
                } else {
                  return f;
                }
              }),
            };
          } else {
            return s;
          }
        }),
      };
      setFormState(cloned);
    } else {
      let cloneFormState = [...formState?.fields];
      cloneFormState[index] = {
        ...cloneFormState[index],
        maximum_size: e?.target?.value,
      };
      setFormState({
        ...formState,
        fields: cloneFormState,
      });
    }
  };

  const onHelperTextChange = (e) => {
    if (isSection === true) {
      let cloned = { ...formState };
      cloned = {
        ...cloned,
        sections: cloned?.sections?.map((s, i) => {
          if (i === sectionIndex) {
            return {
              ...s,
              fields: s?.fields?.map((f, o) => {
                if (o === index) {
                  return {
                    ...f,
                    helper_text: e?.target?.value,
                  };
                } else {
                  return f;
                }
              }),
            };
          } else {
            return s;
          }
        }),
      };
      setFormState(cloned);
    } else {
      let cloneFormState = [...formState?.fields];
      cloneFormState[index] = {
        ...cloneFormState[index],
        helper_text: e?.target?.value,
      };
      setFormState({
        ...formState,
        fields: cloneFormState,
      });
    }
  };

  useEffect(() => {
    if (field?.helper_text !== '') {
      setOpenHelperField(true);
    }
  }, [field?.helper_text]);

  function updateFieldName(str) {
    str = str
      .replace(/[^a-zA-Z_\s]/g, '')
      .toLowerCase()
      .replace(/\s/g, '_');
    return str;
  }

  console.log('from field', field);
  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div
          className="flex justify-between group my-4"
          ref={sectionRef}
          tabIndex="0"
          onClick={(e) => {
            e?.stopPropagation();
            handleClickInside(e);
          }}
        >
          {isActive && (
            <>
              <div className="flex flex-col bg-[#EDECF9] w-11/12 min-h-40">
                <div className="mx-4 my-4 flex gap-[20px]">
                  <TextField
                    className="!w-2/4"
                    value={field?.name}
                    defaultValue={field?.name}
                    placeholder="Input name"
                    sx={{
                      background: 'white',
                    }}
                    onChange={(e) => {
                      inputChange(e);
                    }}
                  />
                  <SampleFilterField
                    value={field?.field_type || { name: '', id: '' }}
                    setValue={(e) => {
                      setFormFieldType(e);
                      onFieldTypeChange(e);
                    }}
                    placeholder="Select field type"
                    defaultOptions={fieldTypes}
                    titleKey={'name'}
                    className="!w-2/4"
                    fieldStyles={{
                      background: 'white',
                    }}
                    selectRef={selectRef}
                    handleSelectFocus={handleSelectFocus}
                    open={open}
                    setOpen={setOpen}
                  />
                </div>
                <div className="flex flex-col">
                  {formFieldType?.id === 'DROPDOWN' ||
                  formFieldType?.id === 'MULTISELECT' ||
                  formFieldType?.id === 'RADIO' ? (
                    renderDraggableOptions(field, index)
                  ) : (
                    <></>
                  )}
                  {formFieldType?.id === 'DROPDOWN' ||
                  formFieldType?.id === 'MULTISELECT' ||
                  formFieldType?.id === 'RADIO' ? (
                    <div className="flex gap-[10px]">
                      <div
                        className="text-[#004FE8] cursor-pointer"
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '14px',
                          fontWeight: 300,
                          lineHeight: '16.59px',
                          margin: '12px 72px',
                        }}
                        onClick={() => {
                          addOptions(field);
                        }}
                      >
                        Add Option
                      </div>
                      {field?.variable_name && (
                        <div className="text-[#8E8CA3] text-[12px] font-normal cursor-pointer z-[99999]" onClick={(e) => clickAndCopy(e, `{${field?.variable_name}}`)}>
                          {`{${field?.variable_name}}`}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {formFieldType?.id === 'CHECKBOX' ? (
                    <div className="mx-4 flex gap-[10px] items-center">
                      <LabelCheckbox />
                      {field?.variable_name && (
                        <div className="text-[#8E8CA3] text-[12px] font-normal cursor-pointer z-[99999]" onClick={(e) => clickAndCopy(e, `{${field?.variable_name}}`)}>
                          {`{${field?.variable_name}}`}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {formFieldType?.id === 'TEXTAREA' ? (
                    <div className="mx-4 flex gap-[10px] items-center">
                      <TextField
                        className="mx-4 w-2/4"
                        sx={{
                          background: 'white',
                        }}
                      />
                       {field?.variable_name && (
                        <div className="text-[#8E8CA3] text-[12px] font-normal cursor-pointer z-[99999]" onClick={(e) => clickAndCopy(e, `{${field?.variable_name}}`)}>
                          {`{${field?.variable_name}}`}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {formFieldType?.id === 'PHONE' ? (
                    <div className="mx-4 flex gap-[10px] items-center">
                      <TextField
                        className="mx-4 w-2/4"
                        sx={{
                          background: 'white',
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className="!pl-0">
                              <NativeSelectComponent
                                value={'+91'}
                                disabled={false}
                                options={countryCodes}
                                onChange={(e) => {}}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                       {field?.variable_name && (
                        <div className="text-[#8E8CA3] text-[12px] font-normal cursor-pointer z-[99999]" onClick={(e) =>{
                           clickAndCopy(e, `{${field?.variable_name}}`)
                        }}>
                          {`{${field?.variable_name}}`}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {formFieldType?.id === 'CURRENCY' ? (
                    <div className="mx-4 flex gap-[10px] items-center">
                      <TextField
                        className="mx-4 w-2/4"
                        sx={{
                          background: 'white',
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className="!pl-0">
                              <NativeSelectComponent
                                value={'INR'}
                                disabled={false}
                                options={currencyCodes}
                                onChange={(e) => {}}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                       {field?.variable_name && (
                        <div className="text-[#8E8CA3] text-[12px] font-normal cursor-pointer z-[99999]" onClick={(e) => clickAndCopy(e, `{${field?.variable_name}}`)}>
                          {`{${field?.variable_name}}`}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {formFieldType?.id === 'TEXT' ||
                  formFieldType?.id === 'EMAIL' ||
                  formFieldType?.id === 'NAME' ? (
                    <div className="mx-4 flex gap-[10px] items-center">
                      <TextField
                        className="mx-4 w-2/4"
                        sx={{
                          background: 'white',
                        }}
                      />
                       {field?.variable_name && (
                        <div className="text-[#8E8CA3] text-[12px] font-normal cursor-pointer z-[99999]" onClick={(e) => clickAndCopy(e, `{${field?.variable_name}}`)}>
                          {`{${field?.variable_name}}`}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {formFieldType?.id === 'FILEUPLOAD' ? (
                    <div className='flex gap-[10px]'>
                       <div className="flex flex-col mx-4 w-2/4">
                      <div className="my-2">
                        <div className="flex items-center justify-between">
                          <div
                            style={{
                              ...fileUploadSectionStyles,
                            }}
                          >
                            Allow only specific file types
                          </div>
                          <div>
                            <IOSSwitch
                              sx={{ m: 1 }}
                              defaultChecked={field?.allow_specific_file_type}
                              onChange={(e) => {
                                onAllowFileTypes(e);
                              }}
                            />
                          </div>
                        </div>
                        {field?.allow_specific_file_type === true ? (
                          <div className="grid grid-rows-4 grid-flow-col gap-4">
                            {fileTypes?.map((type) => {
                              return (
                                <LabelCheckbox
                                  label={type.name}
                                  defaultChecked={field?.file_types?.some(
                                    (g) => g === type?.value,
                                  )}
                                  row={type}
                                  handleCheckbox={(e, val) => {
                                    onFileTypeSelection(val);
                                  }}
                                />
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="flex items-center justify-between my-2">
                        <div
                          style={{
                            ...fileUploadSectionStyles,
                          }}
                        >
                          Maximum number of files
                        </div>
                        <div>
                          <NativeSelect
                            variant="filled"
                            defaultValue={field?.maximum_files}
                            disableUnderline
                            onChange={(e) => {
                              onMaximumFileChange(e);
                            }}
                            sx={{
                              fontSize: '13px',
                              paddingLeft: '10px',
                              background: 'transparent',
                            }}
                          >
                            {numberOfFiles?.map((option) => {
                              return (
                                <option value={option.value}>
                                  {option?.name}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        </div>
                      </div>
                      <div className="flex items-center justify-between my-2">
                        <div
                          style={{
                            ...fileUploadSectionStyles,
                          }}
                        >
                          Maximum file size
                        </div>
                        <div>
                          <NativeSelect
                            variant="filled"
                            defaultValue={field?.maximum_size}
                            disableUnderline
                            onChange={(e) => {
                              onMaximumFileSizeChange(e);
                            }}
                            sx={{
                              fontSize: '13px',
                              paddingLeft: '10px',
                              background: 'transparent',
                            }}
                          >
                            {fileSizes?.map((option) => {
                              return (
                                <option value={option.value}>
                                  {option?.name}
                                </option>
                              );
                            })}
                          </NativeSelect>
                        </div>
                      </div>
                    </div>
                    {field?.variable_name && (
                        <div className="text-[#8E8CA3] text-[12px] font-normal cursor-pointer z-[99999]" onClick={(e) => clickAndCopy(e, `{${field?.variable_name}}`)}>
                          {`{${field?.variable_name}}`}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {formFieldType?.id === 'SIGNATURE' ? (
                    <div className="mx-4 flex gap-[10px] items-center">
                      <SignatureField onClick={handleModal} />
                      {field?.variable_name && (
                        <div className="text-[#8E8CA3] text-[12px] font-normal cursor-pointer z-[99999]" onClick={(e) => clickAndCopy(e, `{${field?.variable_name}}`)}>
                          {`{${field?.variable_name}}`}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mx-4 h-px bg-[#8E8CA3] mt-4" />
                <div className="flex items-center justify-between m-4">
                  {!openHelperField && (
                    <div
                      className="text-[#004FE8] cursor-pointer"
                      style={{
                        fontFamily: 'Rubik',
                        fontSize: '14px',
                        fontWeight: 300,
                        lineHeight: '16.59px',
                      }}
                      onClick={handleHelperField}
                    >
                      Add help text
                    </div>
                  )}
                  {openHelperField && (
                    <TextField
                      className="!w-2/4"
                      value={field?.helper_text}
                      defaultValue={field?.helper_text}
                      placeholder="Helper Text"
                      sx={{
                        background: 'white',
                      }}
                      onChange={(e) => {
                        onHelperTextChange(e);
                      }}
                    />
                  )}
                  <div className="flex items-center mx-2">
                    <IOSSwitch
                      sx={{ m: 1 }}
                      defaultChecked={field?.mandatory}
                      onChange={(e) => {
                        onMandatoryChange(e);
                      }}
                    />
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        onDeleteItem(field);
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden group-hover:block">
                <FormToolbar
                  onClickForNewFields={() => {
                    if (isSection === true) {
                      let cloneFormState = { ...formState };
                      cloneFormState = {
                        ...cloneFormState,
                        sections: cloneFormState?.sections?.map((s, i) => {
                          if (i === sectionIndex) {
                            return {
                              ...s,
                              fields: [
                                ...s?.fields,
                                {
                                  name: '',
                                  field_type: '',
                                  id: v4(),
                                  mandatory: true,
                                  helper_text: '',
                                  allow_specific_file_type: false,
                                  file_types: [],
                                  maximum_files: 1,
                                  maximum_size: 1,
                                },
                              ],
                            };
                          } else {
                            return s;
                          }
                        }),
                      };
                      setFormState(cloneFormState);
                    } else {
                      let cloneFormState = { ...formState };
                      cloneFormState = {
                        ...cloneFormState,
                        fields: [
                          ...cloneFormState?.fields,
                          {
                            name: '',
                            field_type: '',
                            id: v4(),
                            mandatory: true,
                            helper_text: '',
                            allow_specific_file_type: false,
                            file_types: [],
                            maximum_files: 1,
                            maximum_size: 1,
                          },
                        ],
                      };
                      setFormState(cloneFormState);
                    }
                  }}
                  onClickFormSection={onClickFormSection}
                />
              </div>
            </>
          )}
          {!isActive && (
            <>
              <div
                className="rounded-lg w-11/12 bg-white"
                style={{
                  border: '1px solid #8E8CA3',
                }}
              >
                <div className="flex flex-col m-8">
                  <div
                    className="text-[#353349] mb-4"
                    style={{
                      fontFamily: 'Rubik',
                      fontSize: '20px',
                      fontWeight: 400,
                      lineHeight: '23.7px',
                    }}
                  >
                    {field?.name === '' ? 'Input Name' : field?.name}
                  </div>
                  <ContainerForFieldType field={field} />
                </div>
              </div>
              <div className="hidden group-hover:block">
                <FormToolbar
                  onClickForNewFields={() => {
                    if (isSection === true) {
                      let cloneFormState = { ...formState };
                      cloneFormState = {
                        ...cloneFormState,
                        sections: cloneFormState?.sections?.map((s, i) => {
                          if (i === sectionIndex) {
                            return {
                              ...s,
                              fields: [
                                ...s?.fields,
                                {
                                  name: '',
                                  field_type: '',
                                  id: v4(),
                                  mandatory: true,
                                  helper_text: '',
                                  allow_specific_file_type: false,
                                  file_types: [],
                                  maximum_files: 1,
                                  maximum_size: 1,
                                },
                              ],
                            };
                          } else {
                            return s;
                          }
                        }),
                      };
                      setFormState(cloneFormState);
                    } else {
                      let cloneFormState = { ...formState };
                      cloneFormState = {
                        ...cloneFormState,
                        fields: [
                          ...cloneFormState?.fields,
                          {
                            name: '',
                            field_type: '',
                            id: v4(),
                            mandatory: true,
                            helper_text: '',
                            allow_specific_file_type: false,
                            file_types: [],
                            maximum_files: 1,
                            maximum_size: 1,
                          },
                        ],
                      };
                      setFormState(cloneFormState);
                    }
                  }}
                  onClickFormSection={onClickFormSection}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {openSignature && (
        <SignatureComponent open={openSignature} onClose={handleModal} />
      )}
    </>
  );
};

export default DraggableItems;
