// ----------------- Documentation ---------------------------
// this component used for show the data from permissions list

import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Typography } from '@mui/material';
import RadioCells from '../../../shared-component/OverviewPage/overview-used-components/RadioCells';
import Loading from '../../../shared-component/Loading/Loading';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';

function Permissions({
  selectedRole,
  permissionsList
}) {
  const [loading, setLoading] = useState(false);

  return (
    <>
     {selectedRole?.id !== '' ?
         loading ?
         <Loading />
         :
         permissionsList?.length > 0 ?
         <div
         className="px-4 py-6"
         style={{ border: '2px solid #E1EBFE', borderRadius: '8px' }}
       >
         <Box
           className="flex justify-between items-center pb-4"
           sx={{ width: '100%', borderBottom: '1px solid #8E8CA3' }}
         >
           <Typography className="!text-[#8E8CA3]">Module Name</Typography>
           <Box sx={{ width: '82%' }} className="flex justify-around items-center">
             <Typography className="!text-[#8E8CA3]" sx={{ marginLeft: '10px' }}>
               No Access
             </Typography>
             <Typography className="!text-[#8E8CA3]" sx={{ marginRight: '25px' }}>
               Read
             </Typography>
             <Typography className="!text-[#8E8CA3]" sx={{ marginRight: '10px' }}>
               Write
             </Typography>
           </Box>
         </Box>
         {permissionsList.map((e, i) => {
           return (
             <Box
               className="flex justify-between items-center py-2"
               sx={{ width: '100%', borderBottom: '1px solid #8E8CA3' }}
             >
               <FormLabel
                 id="demo-row-radio-buttons-group-label"
                 className="!text-[#353349]"
                 sx={{ width: '20%' }}
               >
                 {e.label}
               </FormLabel>
               <Box sx={{ width: '80%' }}>
                 <RadioCells radioList={e?.radioList} />
               </Box>
             </Box>
           );
         })}
       </div>
       :
       <EmptyCard 
          title='Permissions not fount for this Role'
       />
      :
      <EmptyCard 
          title='Please select a role'
          subtitle='Role selection will display the permissions related to the role'
      />
      }
    </>
  );
}
export default Permissions;
