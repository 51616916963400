import { Box, Divider, Grid, ImageList, ImageListItem, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { BoxEffects, grids, headerButton } from "../../../Config/Constants";
// import BreadCrumbWithActions from "../share-component/breadCrumb-components/BreadCrumbWithActions";
// import PrimaryBtn from "../share-component/button-component/PrimaryBtn";
// import SecondaryBtn from "../share-component/button-component/SecondaryBtn";
// import IntegratedCard from "./embed-modals/IntegratedCard";
import BreadCrumbWithActions from "../../shared-component/breadcrumb-component/BreadCrumbWithActions";
// import PrimaryBtn from "../../shared-component/button-component/PrimaryBtn";
// import SecondaryBtn from "../../shared-component/button-component/SecondaryBtn";
import Map from "../../../asset/Images/map.png"
import "./PlanMap.css";
import CustomPopover from "./CustomPopover";
// import ContentDrawer from "../shared-component/drawer-component/ContentDrawer";
// import LabelAndValue from "../shared-component/overviewcards/LabelAndValue";
// import GridComponent from "../shared-component/grid-component/GridComponent";
// import StatusLabel from "../shared-component/StatusLabel";
// import Labels from "../shared-component/Labels";
// import CustomPopover from "../shared-component/CustomPopover";
// import UnitDetailPage from "./UnitDetailPage";
import ContentDrawer from "../../shared-component/drawer-component/ContentDrawer";
import LabelAndValue from "../../shared-component/OverviewPage/overview-used-components/LabelAndValue";
import GridComponent from "../../shared-component/grid-component/GridComponent";
import StatusLabel from "../../shared-component/OverviewPage/overview-used-components/StatusLabel";
import Labels from "../../shared-component/OverviewPage/overview-used-components/Labels";
import IntegratedCard from "../../shared-component/integrated-component/IntegratedCard";
import { PrimaryButton, SecondaryButton } from "@assystant/mui-buttons";
import UnitDetailPage from "./UnitDetailPage";


function PlanMap(){
    const arr1 = [
        {title: 'Properties', url: '/'},
        {title: 'Anika Redwood Homes', url: '/'},
        {title: 'Plans', url: '/'},
        {title: 'Plan1', url: '/'},
    ]
    const rows = [
        { id: 1, unit_no: 'A 101',block:'A Block',floor:'1st Floor',unit_type:'2 BHK',total_sqft:'1450 sqft.',unit_price:'₹ 1.20 Cr *',specification:[{label:'Parking',color:'secondary'},{label:'Swimming Pool Facing',color:'secondary'},{label:'raj',color:'secondary'},{label:'raj',color:'secondary'},{label:'raj',color:'secondary'}],status:'Available'},
        { id: 2, unit_no: 'A 107',block:'A Block',floor:'2nd Floors',unit_type:'2 BHK',total_sqft:'1450 sqft.',unit_price:'₹ 1.20 Cr *',specification:[{label:'Parking',color:'secondary'},{label:'Swimming Pool Facing',color:'secondary'},{label:'Swimming Pool Facing',color:'secondary'}],status:'Available'},
        { id: 3, unit_no: 'A 113',block:'A Block',floor:'3rd Floors',unit_type:'2 BHK',total_sqft:'1450 sqft.',unit_price:'₹ 1.20 Cr *',specification:[{label:'Parking',color:'secondary'},{label:'Swimming Pool Facing',color:'secondary'}],status:'Available'},
        { id: 4, unit_no: 'A 125',block:'A Block',floor:'4th Floors',unit_type:'2 BHK',total_sqft:'1450 sqft.',unit_price:'₹ 1.20 Cr *',specification:[{label:'Swimming Pool Facing',color:'secondary'}],status:'Available'},
        
      ];
    const [openModal, setOpenModal] = useState(false);
    const [openDraw, setOpenDraw] = useState(false);
    const [drawData, setDrawData] = useState({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [popupData, setPopupData] = useState({});
	const [openUnitDetail, setOpenUnitDetail] = useState(false);

	const handlePopoverOpen = (event) => {
		event.stopPropagation();
	  setAnchorEl(event.currentTarget);
	  setPopupData(event._targetInst.memoizedProps)
	//   console.log('event', event._targetInst.memoizedProps)
	//   setPopupData(event.target.__reactProps$djohbvivv9n.id)
	};

	const handlePopoverClose = () => {
	  setAnchorEl(null);
	};
  
	
     function handleEvent(e){
        setOpenDraw(!openDraw);
        // setDrawData(e.row);
        setDrawData(rows[0])
    }
    function btnclk(){
        setOpenModal(!openModal);
    }
    function closeclk(){
        setOpenModal(!openModal);
    }
	function handelUnitDetail(){
		setOpenUnitDetail(!openUnitDetail);
	}
    const pathdata = [
        {id:'1',unit:'A 106',color:'Ivory & Blue',bathroom:'3 bathroom',floor:'1st Floor',balcony:'2 balcony',facing:'East',block:'A Block',total_sqft:'1450 sqft.',unit_type:'2 BHK',unit_price:'₹ 1.20 Cr *',path:"M 571.73,73.91 C 571.73,73.91 567.45,126.45 567.45,126.45 567.45,126.45 508.64,104.27 508.64,104.27 508.64,104.27 514.45,65.45 514.45,65.45 514.45,65.45 571.73,73.91 571.73,73.91 Z"},
        {id:'2',unit:'A 107',color:'Ivory & Blue',bathroom:'3 bathroom',floor:'2nd Floor',balcony:'2 balcony',facing:'south',block:'B Block',total_sqft:'1450 sqft.',unit_type:'3 BHK',unit_price:'₹ 1.20 Cr *',path:"M 428.73,74.82 C 428.73,74.82 429.64,68.64 429.64,68.64 429.64,68.64 431.64,58.36 431.64,58.36 431.64,58.36 433.64,47.36 433.64,47.36 433.64,47.36 435.36,37.55 435.36,37.55 435.36,37.55 435.82,35.55 435.82,35.55 435.82,35.55 442.82,36.55 442.82,36.55 442.82,36.55 451.64,38.36 451.64,38.36 451.64,38.36 461.45,40.55 461.45,40.55 461.45,40.55 470.55,42.36 470.55,42.36 470.55,42.36 480.18,44.55 480.18,44.55 480.18,44.55 492.18,47.00 492.18,47.00 492.18,47.00 485.00,95.73 485.00,95.73 485.00,95.73 428.73,74.82 428.73,74.82 Z"},
        {id:'3',unit:'A 108',color:'Ivory & Blue',bathroom:'3 bathroom',floor:'3rd Floor',balcony:'2 balcony',facing:'North',block:'C Block',total_sqft:'1450 sqft.',unit_type:'1 BHK',unit_price:'₹ 1.20 Cr *',path:"M 534.55,521.27 C 534.55,521.27 480.00,540.82 480.00,540.82  480.00,540.82 481.55,546.45 481.55,546.45  481.55,546.45 483.45,555.27 483.45,555.27  483.45,555.27 484.64,562.00 484.64,562.00  484.64,562.00 484.73,572.82 484.73,572.82  484.73,572.82 547.45,573.00 547.45,573.00  547.45,573.00 547.27,570.82 547.27,570.82  547.27,570.82 546.55,567.64 546.55,567.64  546.55,567.64 545.55,564.45 545.55,564.45  545.55,564.45 544.64,560.18 544.64,560.18  544.64,560.18 543.55,557.00 543.55,557.00  543.55,557.00 542.36,553.09 542.36,553.09  542.36,553.09 541.45,548.91 541.45,548.91  541.45,548.91 539.55,541.91 539.55,541.91  539.55,541.91 538.18,536.00 538.18,536.00  538.18,536.00 536.82,530.36 536.82,530.36  536.82,530.36 535.73,526.45 535.73,526.45  535.73,526.45 534.55,521.27 534.55,521.27 Z"},
        {id:'4',unit:'A 109',color:'Ivory & Blue',bathroom:'3 bathroom',floor:'4th Floor',balcony:'2 balcony',facing:'East',block:'D Block',total_sqft:'1450 sqft.',unit_type:'2 BHK',unit_price:'₹ 1.20 Cr *',path:"M 514.45,477.45 C 514.45,477.45 534.45,521.27 534.45,521.27  534.45,521.27 479.73,540.91 480.00,540.82  480.27,540.73 477.55,534.91 477.55,534.91  477.55,534.91 475.45,530.73 475.45,530.73  475.45,530.73 473.64,527.00 473.64,527.00  473.64,527.00 471.64,523.64 471.64,523.64  471.64,523.64 470.09,520.91 470.09,520.91  470.09,520.91 468.00,517.82 468.00,517.82  468.00,517.82 466.09,514.82 466.09,514.82  466.09,514.82 514.45,477.45 514.45,477.45 Z"},
    ]
    return(
        <>
            <Box 
                sx={{borderBottom: BoxEffects.borderBottom, borderColor: 'secondary.main',padding:BoxEffects.padding,marginBottom:BoxEffects.marginButton,paddingLeft:BoxEffects.margin,paddingRight:headerButton.marginRigt }}
            >
                <BreadCrumbWithActions propArr={arr1}>
                    <PrimaryButton title='Integrate'  onClick={btnclk} style={{
                       fontWeight:400
                    }} />
                </BreadCrumbWithActions>
            </Box>
            <Box sx={{ border: 1, borderColor: 'divider',margin:BoxEffects.margin, position:'relative' }}>
            
                {/* <ImageList> */}
                {/* <ImageListItem> */}
                    <img
                        style={{
                            position: 'relative',
                            width: '100%',
                        }}
                        src={'https://spotmink.com//category-backgrounds/V4_jq8wL3w.jpg'}
                        // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        // alt={item.title}
                        // loading="lazy"
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5760 3600" 
                    style={{
                            position: 'absolute',
                            width: '100%',
                            zIndex: 1,
                            marginLeft: '0.35%',
                            top:0,
                        }}>

        {pathdata.map((e)=>{
            return(
				<path
				id={e.id} 
				unit={e.unit}
				floor={e.floor}
				unit_type={e.unit_type}
				unit_price={e.unit_price}
				total_sqft={e.total_sqft}
				balcony={e.balcony}
				block={e.block}
				facing={e.facing}
				bathroom={e.bathroom}
				color={e.color}
				data-toggle="popover" 
				onMouseEnter={handlePopoverOpen}
				onBlur={handlePopoverClose}
				onClick={(e)=>handleEvent(e)} 
				data-trigger="hover focus" 
				className="otherside" 
                d={e.path}></path>
            )
        })}

      
<path onclick="getvalue(this.id)" id="unit_32" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 2920.00,454.00
        C 2926.73,457.93 2934.89,466.34 2941.00,471.72
          2941.00,471.72 2988.00,513.84 2988.00,513.84
          2988.00,513.84 3076.00,592.83 3076.00,592.83
          3076.00,592.83 3122.00,633.98 3122.00,633.98
          3122.00,633.98 3168.00,675.16 3168.00,675.16
          3171.13,677.96 3182.80,687.24 3182.80,691.00
          3182.80,693.86 3177.95,698.02 3176.00,700.00
          3171.34,704.73 3164.71,712.63 3160.75,718.00
          3152.45,729.30 3141.72,747.19 3136.04,760.00
          3136.04,760.00 3126.00,786.00 3126.00,786.00
          3126.00,786.00 3064.00,769.15 3064.00,769.15
          3064.00,769.15 2968.00,743.00 2968.00,743.00
          2968.00,743.00 2935.00,734.00 2935.00,734.00
          2935.00,734.00 2922.57,729.91 2922.57,729.91
          2922.57,729.91 2921.00,722.00 2921.00,722.00
          2921.00,722.00 2921.00,672.00 2921.00,672.00
          2921.00,672.00 2920.00,655.00 2920.00,655.00
          2920.00,655.00 2920.00,454.00 2920.00,454.00 Z" data-front="73" data-rear="187" data-left="238.42" data-lot="4" data-street="Community Loop" data-right="142" data-sqft="21,121" data-type="Single family" data-unit_type="Acre" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_35" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 2921.00,730.00
        C 2921.00,730.00 2971.00,743.58 2971.00,743.58
          2971.00,743.58 3072.00,771.12 3072.00,771.12
          3072.00,771.12 3111.00,781.71 3111.00,781.71
          3111.00,781.71 3124.96,786.70 3124.96,786.70
          3124.96,786.70 3124.96,795.00 3124.96,795.00
          3124.96,795.00 3121.95,818.00 3121.95,818.00
          3121.95,818.00 3120.04,838.00 3120.04,838.00
          3120.04,838.00 3120.04,854.00 3120.04,854.00
          3120.04,854.00 3121.00,866.00 3121.00,866.00
          3121.00,866.00 3121.00,900.00 3121.00,900.00
          3121.00,900.00 2922.00,900.00 2922.00,900.00
          2922.00,900.00 2922.00,846.00 2922.00,846.00
          2922.00,846.00 2921.00,829.00 2921.00,829.00
          2921.00,829.00 2921.00,730.00 2921.00,730.00 Z" data-front="78" data-rear="112" data-left="142" data-lot="3" data-street="Community Loop" data-right="132" data-sqft="12,823" data-type="Single Family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_36" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 2922.00,900.00
        C 2922.00,900.00 3121.00,900.00 3121.00,900.00
          3121.00,900.00 3121.00,1025.00 3121.00,1025.00
          3121.00,1025.00 3069.00,1025.00 3069.00,1025.00
          3069.00,1025.00 3052.00,1026.00 3052.00,1026.00
          3052.00,1026.00 2997.00,1026.00 2997.00,1026.00
          2997.00,1026.00 2982.00,1027.00 2982.00,1027.00
          2982.00,1027.00 2923.00,1027.00 2923.00,1027.00
          2923.00,1027.00 2923.00,957.00 2923.00,957.00
          2923.00,957.00 2922.00,940.00 2922.00,940.00
          2922.00,940.00 2922.00,900.00 2922.00,900.00 Z" data-front="83" data-rear="83" data-left="132" data-lot="2" data-street="Community Loop" data-right="132" data-sqft="11,117" data-type="Single Family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_37" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 2924.00,1027.00
        C 2924.00,1027.00 3002.00,1027.00 3002.00,1027.00
          3002.00,1027.00 3017.00,1026.00 3017.00,1026.00
          3017.00,1026.00 3098.00,1026.00 3098.00,1026.00
          3098.00,1026.00 3113.00,1025.00 3113.00,1025.00
          3113.00,1025.00 3121.00,1025.00 3121.00,1025.00
          3121.00,1025.00 3121.00,1054.00 3121.00,1054.00
          3121.00,1054.00 3122.00,1071.00 3122.00,1071.00
          3122.00,1071.00 3122.00,1175.00 3122.00,1175.00
          3122.00,1175.00 3093.00,1177.00 3093.00,1177.00
          3093.00,1177.00 3052.00,1179.00 3052.00,1179.00
          3035.88,1179.03 3019.86,1182.29 3004.00,1184.92
          3004.00,1184.92 2924.00,1198.00 2924.00,1198.00
          2924.00,1198.00 2924.00,1027.00 2924.00,1027.00 Z" data-front="100" data-rear="114" data-left="133" data-lot="1" data-street="Community Loop" data-right="132" data-sqft="13,942" data-type="Single family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_38" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="99,900" data-placement="left" d="M 2925.00,1429.00
           C 2925.00,1429.00 2925.00,1402.00 2925.00,1402.00
             2925.00,1402.00 2924.00,1385.00 2924.00,1385.00
             2924.00,1385.00 2924.00,1287.00 2924.00,1287.00
             2924.00,1287.00 2925.60,1279.02 2925.60,1279.02
             2925.60,1279.02 2938.00,1276.58 2938.00,1276.58
             2938.00,1276.58 2970.00,1271.73 2970.00,1271.73
             2970.00,1271.73 3036.00,1261.73 3036.00,1261.73
             3036.00,1261.73 3072.00,1257.00 3072.00,1257.00
             3072.00,1257.00 3121.00,1257.00 3121.00,1257.00
             3121.00,1257.00 3121.00,1432.00 3121.00,1432.00
             3121.00,1432.00 3092.00,1432.00 3092.00,1432.00
             3092.00,1432.00 3075.00,1431.00 3075.00,1431.00
             3075.00,1431.00 3021.00,1431.00 3021.00,1431.00
             3021.00,1431.00 3004.00,1430.00 3004.00,1430.00
             3004.00,1430.00 2943.00,1430.00 2943.00,1430.00
             2943.00,1430.00 2929.00,1429.00 2929.00,1429.00
             2929.00,1429.00 2925.00,1429.00 2925.00,1429.00 Z" data-front="116" data-rear="101" data-left="131" data-lot="1" data-street="Community Loop" data-right="132" data-sqft="14,557" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_39" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="99,900" data-placement="left" d="M 2925.00,1429.00
           C 2925.00,1429.00 2958.00,1429.00 2958.00,1429.00
             2958.00,1429.00 2975.00,1430.00 2975.00,1430.00
             2975.00,1430.00 3105.00,1432.00 3105.00,1432.00
             3105.00,1432.00 3121.00,1433.00 3121.00,1433.00
             3121.00,1433.00 3121.00,1567.00 3121.00,1567.00
             3121.00,1567.00 3006.00,1567.00 3006.00,1567.00
             3006.00,1567.00 2991.00,1568.00 2991.00,1568.00
             2991.00,1568.00 2925.00,1568.00 2925.00,1568.00
             2925.00,1568.00 2925.00,1429.00 2925.00,1429.00 Z" data-front="90" data-rear="90" data-left="131" data-lot="2" data-street="Community Loop" data-right="131" data-sqft="11,799" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_40" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="99,900" data-placement="left" d="M 2916.00,1699.00
           C 2916.00,1699.00 2935.00,1700.00 2935.00,1700.00
             2935.00,1700.00 2982.00,1700.00 2982.00,1700.00
             2982.00,1700.00 2999.00,1701.00 2999.00,1701.00
             2999.00,1701.00 3041.00,1701.00 3041.00,1701.00
             3041.00,1701.00 3058.00,1702.00 3058.00,1702.00
             3058.00,1702.00 3102.00,1702.00 3102.00,1702.00
             3102.00,1702.00 3117.00,1703.00 3117.00,1703.00
             3117.00,1703.00 3121.00,1703.00 3121.00,1703.00
             3121.00,1703.00 3120.00,1718.00 3120.00,1718.00
             3120.00,1718.00 3119.00,1781.00 3119.00,1781.00
             3119.00,1781.00 3118.00,1797.00 3118.00,1797.00
             3118.00,1797.00 3118.00,1820.00 3118.00,1820.00
             3118.00,1820.00 3117.00,1836.00 3117.00,1836.00
             3117.00,1836.00 3117.00,1861.00 3117.00,1861.00
             3117.00,1861.00 3072.00,1858.09 3072.00,1858.09
             3072.00,1858.09 2985.00,1852.09 2985.00,1852.09
             2985.00,1852.09 2929.00,1848.17 2929.00,1848.17
             2929.00,1848.17 2905.00,1846.00 2905.00,1846.00
             2905.00,1846.00 2910.09,1778.00 2910.09,1778.00
             2910.09,1778.00 2914.09,1723.00 2914.09,1723.00
             2914.09,1723.00 2916.00,1699.00 2916.00,1699.00 Z" data-front="107" data-rear="196" data-left="136" data-lot="4" data-street="Community Loop" data-right="138" data-sqft="14,032" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_41" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 2906.00,1847.00
           C 2906.00,1847.00 2966.00,1851.09 2966.00,1851.09
             2966.00,1851.09 3064.00,1857.91 3064.00,1857.91
             3064.00,1857.91 3087.00,1859.09 3087.00,1859.09
             3087.00,1859.09 3095.00,1859.96 3095.00,1859.96
             3095.00,1859.96 3117.00,1862.00 3117.00,1862.00
             3117.00,1862.00 3113.91,1896.00 3113.91,1896.00
             3113.91,1896.00 3111.17,1925.00 3111.17,1925.00
             3111.17,1925.00 3108.28,1960.00 3108.28,1960.00
             3108.28,1960.00 3106.28,1984.00 3106.28,1984.00
             3105.99,1986.47 3106.25,1991.62 3104.83,1993.43
             3102.51,1996.37 3093.36,1994.49 3090.00,1994.17
             3090.00,1994.17 3050.00,1991.42 3050.00,1991.42
             3050.00,1991.42 3025.00,1989.91 3025.00,1989.91
             3025.00,1989.91 2982.00,1986.91 2982.00,1986.91
             2982.00,1986.91 2925.00,1982.91 2925.00,1982.91
             2925.00,1982.91 2896.00,1981.00 2896.00,1981.00
             2896.00,1981.00 2897.91,1955.00 2897.91,1955.00
             2897.91,1955.00 2901.91,1898.00 2901.91,1898.00
             2901.91,1898.00 2903.91,1869.00 2903.91,1869.00
             2903.91,1869.00 2906.00,1847.00 2906.00,1847.00 Z" data-front="90" data-rear="90" data-left="138" data-lot="5" data-street="Community Loop" data-right="137" data-sqft="12,414" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_42" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 2887.00,2114.00
           C 2887.00,2114.00 2890.09,2071.00 2890.09,2071.00
             2890.09,2071.00 2893.09,2028.00 2893.09,2028.00
             2893.09,2028.00 2893.96,2020.00 2893.96,2020.00
             2893.96,2020.00 2895.91,1993.00 2895.91,1993.00
             2896.11,1990.15 2895.51,1983.68 2897.17,1981.57
             2899.37,1978.78 2906.72,1980.82 2910.00,1980.96
             2910.00,1980.96 2949.00,1983.96 2949.00,1983.96
             2949.00,1983.96 2958.00,1983.96 2958.00,1983.96
             2958.00,1983.96 2987.00,1986.09 2987.00,1986.09
             2987.00,1986.09 3050.00,1990.91 3050.00,1990.91
             3050.00,1990.91 3105.00,1995.00 3105.00,1995.00
             3105.00,1995.00 3103.83,2009.00 3103.83,2009.00
             3103.83,2009.00 3100.96,2042.00 3100.96,2042.00
             3100.96,2042.00 3100.96,2050.00 3100.96,2050.00
             3100.96,2050.00 3097.91,2090.00 3097.91,2090.00
             3097.91,2090.00 3095.00,2131.00 3095.00,2131.00
             3095.00,2131.00 3034.00,2126.17 3034.00,2126.17
             3034.00,2126.17 2943.00,2118.83 2943.00,2118.83
             2943.00,2118.83 2907.00,2115.83 2907.00,2115.83
             2907.00,2115.83 2887.00,2114.00 2887.00,2114.00 Z" data-front="90" data-rear="90" data-left="137" data-lot="6" data-street="Community Loop" data-right="136" data-sqft="12,293" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_43" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="99,900" data-placement="left" d="M 2925.00,1569.00
           C 2925.00,1569.00 2942.00,1569.00 2942.00,1569.00
             2942.00,1569.00 2958.00,1568.00 2958.00,1568.00
             2958.00,1568.00 3090.00,1568.00 3090.00,1568.00
             3090.00,1568.00 3105.00,1567.00 3105.00,1567.00
             3105.00,1567.00 3121.00,1567.00 3121.00,1567.00
             3121.00,1567.00 3121.00,1702.00 3121.00,1702.00
             3121.00,1702.00 3091.00,1702.00 3091.00,1702.00
             3091.00,1702.00 3074.00,1701.00 3074.00,1701.00
             3074.00,1701.00 3016.00,1701.00 3016.00,1701.00
             3016.00,1701.00 2999.00,1700.00 2999.00,1700.00
             2999.00,1700.00 2935.00,1700.00 2935.00,1700.00
             2935.00,1700.00 2920.00,1699.00 2920.00,1699.00
             2920.00,1699.00 2916.00,1699.00 2916.00,1699.00
             2916.00,1699.00 2917.91,1674.00 2917.91,1674.00
             2917.91,1674.00 2922.09,1611.00 2922.09,1611.00
             2922.09,1611.00 2925.00,1569.00 2925.00,1569.00 Z" data-front="90" data-rear="90" data-left="130" data-lot="3" data-street="Community Loop" data-right="136" data-sqft="12,031" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_44" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="200000" data-placement="left" d="M 3992.00,439.00
        C 3992.00,439.00 4005.00,440.17 4005.00,440.17
          4005.00,440.17 4048.00,443.91 4048.00,443.91
          4048.00,443.91 4068.00,445.17 4068.00,445.17
          4068.00,445.17 4130.00,450.17 4130.00,450.17
          4130.00,450.17 4155.00,452.09 4155.00,452.09
          4155.00,452.09 4167.00,453.00 4167.00,453.00
          4167.00,453.00 4160.28,509.00 4160.28,509.00
          4160.28,509.00 4145.85,622.00 4145.85,622.00
          4145.85,622.00 4139.00,677.00 4139.00,677.00
          4139.00,677.00 4093.00,675.00 4093.00,675.00
          4093.00,675.00 4076.00,674.00 4076.00,674.00
          4076.00,674.00 3993.00,674.00 3993.00,674.00
          3993.00,674.00 3993.00,584.00 3993.00,584.00
          3993.00,584.00 3992.00,569.00 3992.00,569.00
          3992.00,569.00 3992.00,509.00 3992.00,509.00
          3992.00,509.00 3992.00,469.00 3992.00,469.00
          3991.99,459.60 3989.41,448.12 3992.00,439.00 Z" data-front="97" data-rear="116" data-left="151" data-lot="12" data-street="Community Loop" data-right="156" data-sqft="16,394" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_45" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3874.00,431.00
        C 3874.00,431.00 3928.00,435.09 3928.00,435.09
          3928.00,435.09 3982.00,439.09 3982.00,439.09
          3982.00,439.09 3991.04,440.00 3991.04,440.00
          3991.04,440.00 3991.04,455.00 3991.04,455.00
          3991.04,455.00 3992.00,467.00 3992.00,467.00
          3992.00,467.00 3992.00,570.00 3992.00,570.00
          3992.00,570.00 3993.00,585.00 3993.00,585.00
          3993.00,585.00 3993.00,675.00 3993.00,675.00
          3993.00,675.00 3873.00,675.00 3873.00,675.00
          3873.00,675.00 3873.00,509.00 3873.00,509.00
          3873.00,509.00 3873.00,458.00 3873.00,458.00
          3873.00,458.00 3874.00,431.00 3874.00,431.00 Z" data-front="80" data-rear="80" data-left="156" data-lot="11" data-street="Community Loop" data-right="163" data-sqft="12,833" data-type="Single Family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_46" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="109,900" data-placement="left" d="M 3752.00,431.00
        C 3752.00,431.00 3873.00,431.00 3873.00,431.00
          3873.00,431.00 3873.00,675.00 3873.00,675.00
          3873.00,675.00 3754.00,675.00 3754.00,675.00
          3754.00,675.00 3754.00,624.00 3754.00,624.00
          3754.00,624.00 3753.00,607.00 3753.00,607.00
          3753.00,607.00 3753.00,500.00 3753.00,500.00
          3753.00,500.00 3752.00,485.00 3752.00,485.00
          3752.00,485.00 3752.00,431.00 3752.00,431.00 Z" data-front="80" data-rear="80" data-left="163" data-lot="10" data-street="Community Loop" data-right="163" data-sqft="13,040" data-type="Single family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_47" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3634.00,432.00
        C 3634.00,432.00 3713.00,432.00 3713.00,432.00
          3713.00,432.00 3729.00,431.00 3729.00,431.00
          3729.00,431.00 3752.00,431.00 3752.00,431.00
          3752.00,431.00 3752.00,483.00 3752.00,483.00
          3752.00,483.00 3753.00,500.00 3753.00,500.00
          3753.00,500.00 3753.00,607.00 3753.00,607.00
          3753.00,607.00 3754.00,622.00 3754.00,622.00
          3754.00,622.00 3754.00,675.00 3754.00,675.00
          3754.00,675.00 3633.00,675.00 3633.00,675.00
          3633.00,675.00 3633.00,620.00 3633.00,620.00
          3633.00,620.00 3634.00,603.00 3634.00,603.00
          3634.00,603.00 3634.00,432.00 3634.00,432.00 Z" data-front="80" data-rear="80" data-left="163" data-lot="9" data-street="Community Loop" data-right="163" data-sqft="13,040" data-type="Single family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_48" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3507.00,433.00
        C 3507.00,433.00 3530.00,433.00 3530.00,433.00
          3530.00,433.00 3547.00,432.00 3547.00,432.00
          3547.00,432.00 3633.00,432.00 3633.00,432.00
          3633.00,432.00 3633.00,675.00 3633.00,675.00
          3633.00,675.00 3605.00,674.00 3605.00,674.00
          3605.00,674.00 3586.00,673.00 3586.00,673.00
          3586.00,673.00 3576.00,672.09 3576.00,672.09
          3576.00,672.09 3510.00,666.00 3510.00,666.00
          3510.00,666.00 3510.00,632.00 3510.00,632.00
          3510.00,632.00 3509.00,615.00 3509.00,615.00
          3509.00,615.00 3509.00,542.00 3509.00,542.00
          3509.00,542.00 3508.00,527.00 3508.00,527.00
          3508.00,527.00 3508.00,455.00 3508.00,455.00
          3508.00,455.00 3507.00,440.00 3507.00,440.00
          3507.00,440.00 3507.00,433.00 3507.00,433.00 Z" data-front="83" data-rear="83" data-left="163" data-lot="8" data-street="Community Loop" data-right="156" data-sqft="13,359" data-type="Single family" data-unit_type="Sqft" data-block="2" data-grade="3" data-original-title="1"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_49" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3383.00,433.00
        C 3383.00,433.00 3405.00,433.00 3405.00,433.00
          3405.00,433.00 3422.00,432.00 3422.00,432.00
          3422.00,432.00 3507.04,432.00 3507.04,432.00
          3507.04,432.00 3507.04,447.00 3507.04,447.00
          3507.04,447.00 3508.00,459.00 3508.00,459.00
          3508.00,459.00 3508.00,561.00 3508.00,561.00
          3508.00,561.00 3509.00,576.00 3509.00,576.00
          3509.00,576.00 3509.00,666.00 3509.00,666.00
          3509.00,666.00 3413.00,651.73 3413.00,651.73
          3413.00,651.73 3393.00,648.73 3393.00,648.73
          3393.00,648.73 3385.02,646.40 3385.02,646.40
          3385.02,646.40 3384.00,639.00 3384.00,639.00
          3384.00,639.00 3384.00,496.00 3384.00,496.00
          3384.00,496.00 3383.00,481.00 3383.00,481.00
          3383.00,481.00 3383.00,433.00 3383.00,433.00 Z" data-front="84" data-rear="83" data-left="156" data-lot="7" data-street="Community Loop" data-right="143" data-sqft="12,446" data-type="Single Family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_50" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3204.00,434.00
        C 3204.00,434.00 3262.00,434.00 3262.00,434.00
          3262.00,434.00 3279.00,433.00 3279.00,433.00
          3279.00,433.00 3383.00,433.00 3383.00,433.00
          3383.00,433.00 3383.00,515.00 3383.00,515.00
          3383.00,515.00 3384.00,530.00 3384.00,530.00
          3384.00,530.00 3384.00,622.00 3384.00,622.00
          3384.00,622.00 3385.00,637.00 3385.00,637.00
          3385.00,637.00 3385.00,647.00 3385.00,647.00
          3385.00,647.00 3335.00,640.99 3335.00,640.99
          3335.00,640.99 3296.00,640.99 3296.00,640.99
          3296.00,640.99 3278.51,640.99 3278.51,640.99
          3278.51,640.99 3273.19,629.00 3273.19,629.00
          3273.19,629.00 3260.66,594.00 3260.66,594.00
          3260.66,594.00 3222.95,489.00 3222.95,489.00
          3222.95,489.00 3209.66,452.00 3209.66,452.00
          3209.66,452.00 3204.00,434.00 3204.00,434.00 Z" data-front="71" data-rear="120" data-left="143" data-lot="6" data-street="Community Loop" data-right="148" data-sqft="13,397" data-type="Single Family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_51" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3211.00,990.00
           C 3211.00,990.00 3211.00,941.00 3211.00,941.00
             3211.00,941.00 3210.00,924.00 3210.00,924.00
             3210.00,924.00 3210.00,882.00 3210.00,882.00
             3210.00,882.00 3209.00,865.00 3209.00,865.00
             3209.00,865.00 3209.00,821.00 3209.00,821.00
             3209.00,821.00 3209.65,813.00 3209.65,813.00
             3209.65,813.00 3222.99,789.00 3222.99,789.00
             3222.99,789.00 3241.38,758.21 3241.38,758.21
             3241.38,758.21 3257.00,748.70 3257.00,748.70
             3257.00,748.70 3285.00,733.65 3285.00,733.65
             3285.00,733.65 3297.00,732.83 3297.00,732.83
             3297.00,732.83 3330.00,731.00 3330.00,731.00
             3330.00,731.00 3330.00,765.00 3330.00,765.00
             3330.00,765.00 3331.00,782.00 3331.00,782.00
             3331.00,782.00 3331.00,853.00 3331.00,853.00
             3331.00,853.00 3332.00,868.00 3332.00,868.00
             3332.00,868.00 3332.00,940.00 3332.00,940.00
             3332.00,940.00 3333.00,955.00 3333.00,955.00
             3333.00,955.00 3333.00,990.00 3333.00,990.00
             3333.00,990.00 3211.00,990.00 3211.00,990.00 Z" data-front="120" data-rear="81" data-left="174" data-lot="1" data-street="Community Loop" data-right="105" data-sqft="13,144" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_52" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3329.00,729.00
           C 3329.00,729.00 3378.00,736.92 3378.00,736.92
             3378.00,736.92 3422.00,744.00 3422.00,744.00
             3422.00,744.00 3422.00,898.00 3422.00,898.00
             3422.00,898.00 3423.00,913.00 3423.00,913.00
             3423.00,913.00 3423.00,987.00 3423.00,987.00
             3423.00,987.00 3404.00,987.00 3404.00,987.00
             3404.00,987.00 3388.00,988.00 3388.00,988.00
             3388.00,988.00 3334.00,988.00 3334.00,988.00
             3334.00,988.00 3334.00,955.00 3334.00,955.00
             3334.00,955.00 3333.00,939.00 3333.00,939.00
             3333.00,939.00 3333.00,911.00 3333.00,911.00
             3333.00,911.00 3332.00,894.00 3332.00,894.00
             3332.00,894.00 3332.00,868.00 3332.00,868.00
             3332.00,868.00 3331.00,851.00 3331.00,851.00
             3331.00,851.00 3330.00,782.00 3330.00,782.00
             3330.00,782.00 3329.00,765.00 3329.00,765.00
             3329.00,765.00 3329.00,729.00 3329.00,729.00 Z" data-front="59" data-rear="59" data-left="174" data-lot="2" data-street="Community Loop" data-right="165" data-sqft="10,054" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_53" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3421.00,744.00
     C 3421.00,744.00 3501.00,754.00 3501.00,754.00
       3501.00,754.00 3501.00,990.00 3501.00,990.00
       3501.00,990.00 3423.00,990.00 3423.00,990.00
       3423.00,990.00 3423.00,968.00 3423.00,968.00
       3423.00,968.00 3422.00,952.00 3422.00,952.00
       3422.00,952.00 3422.00,783.00 3422.00,783.00
       3422.00,783.00 3421.00,768.00 3421.00,768.00
       3421.00,768.00 3421.00,744.00 3421.00,744.00 Z" data-front="52" data-rear="151" data-left="165" data-lot="3" data-street="Community Loop" data-right="156" data-sqft="8,340" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_54" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3499.00,753.00
           C 3499.00,753.00 3577.00,764.00 3577.00,764.00
             3577.00,764.00 3577.00,811.00 3577.00,811.00
             3577.00,811.00 3578.00,828.00 3578.00,828.00
             3578.00,828.00 3578.00,925.00 3578.00,925.00
             3578.00,925.00 3579.00,940.00 3579.00,940.00
             3579.00,940.00 3579.00,989.00 3579.00,989.00
             3579.00,989.00 3499.00,990.00 3499.00,990.00
             3499.00,990.00 3499.00,753.00 3499.00,753.00 Z" data-front="51" data-rear="151" data-left="156" data-lot="4" data-street="Community Loop" data-right="151" data-sqft="7,955" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_55" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3577.00,763.00
           C 3577.00,763.00 3613.00,763.00 3613.00,763.00
             3613.00,763.00 3630.00,764.00 3630.00,764.00
             3630.00,764.00 3664.00,764.00 3664.00,764.00
             3664.00,764.00 3664.00,793.00 3664.00,793.00
             3664.00,793.00 3665.00,810.00 3665.00,810.00
             3665.00,810.00 3665.00,869.00 3665.00,869.00
             3665.00,869.00 3666.00,886.00 3666.00,886.00
             3666.00,886.00 3666.00,947.00 3666.00,947.00
             3666.00,947.00 3667.00,962.00 3667.00,962.00
             3667.00,962.00 3667.00,992.00 3667.00,992.00
             3667.00,992.00 3631.00,992.00 3631.00,992.00
             3631.00,992.00 3614.00,991.00 3614.00,991.00
             3614.00,991.00 3579.00,991.00 3579.00,991.00
             3579.00,991.00 3579.00,944.00 3579.00,944.00
             3579.00,944.00 3578.00,927.00 3578.00,927.00
             3578.00,927.00 3578.00,828.00 3578.00,828.00
             3578.00,828.00 3577.00,813.00 3577.00,813.00
             3577.00,813.00 3577.00,763.00 3577.00,763.00 Z" data-front="59" data-rear="59" data-left="151" data-lot="5" data-street="Community Loop" data-right="150" data-sqft="8,899" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_56" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3666.00,764.00
           C 3666.00,764.00 3754.00,764.00 3754.00,764.00
             3754.00,764.00 3754.00,869.00 3754.00,869.00
             3754.00,869.00 3755.00,884.00 3755.00,884.00
             3755.00,884.00 3755.00,989.00 3755.00,989.00
             3755.00,989.00 3666.00,989.00 3666.00,989.00
             3666.00,989.00 3666.00,764.00 3666.00,764.00 Z" data-front="59" data-rear="59" data-left="150" data-lot="6" data-street="Community Loop" data-right="150" data-sqft="8,888" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_57" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3753.00,763.00
           C 3753.00,763.00 3832.00,763.00 3832.00,763.00
             3832.00,763.00 3832.00,906.00 3832.00,906.00
             3832.00,906.00 3833.00,921.00 3833.00,921.00
             3833.00,921.00 3833.00,989.00 3833.00,989.00
             3833.00,989.00 3753.00,989.00 3753.00,989.00
             3753.00,989.00 3753.00,763.00 3753.00,763.00 Z" data-front="52" data-rear="52" data-left="150" data-lot="7" data-street="Community Loop" data-right="150" data-sqft="7,837" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_58" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3832.00,763.00
           C 3832.00,763.00 3913.00,764.00 3913.00,764.00
             3913.00,764.00 3913.00,988.00 3913.00,988.00
             3913.00,988.00 3869.00,988.00 3869.00,988.00
             3869.00,988.00 3854.00,989.00 3854.00,989.00
             3854.00,989.00 3834.00,989.00 3834.00,989.00
             3834.00,989.00 3834.00,953.00 3834.00,953.00
             3834.00,953.00 3833.00,936.00 3833.00,936.00
             3833.00,936.00 3833.00,861.00 3833.00,861.00
             3833.00,861.00 3832.00,846.00 3832.00,846.00
             3832.00,846.00 3832.00,763.00 3832.00,763.00 Z" data-front="52" data-rear="52" data-left="150" data-lot="8" data-street="Community Loop" data-right="150" data-sqft="7,837" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_59" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3912.00,763.00
     C 3912.00,763.00 4001.00,763.00 4001.00,763.00
       4001.00,763.00 4001.00,896.00 4001.00,896.00
       4001.00,896.00 4002.00,911.00 4002.00,911.00
       4002.00,911.00 4002.00,989.00 4002.00,989.00
       4002.00,989.00 3986.00,989.00 3986.00,989.00
       3986.00,989.00 3970.00,988.00 3970.00,988.00
       3970.00,988.00 3913.00,988.00 3913.00,988.00
       3913.00,988.00 3913.00,943.00 3913.00,943.00
       3913.00,943.00 3912.00,926.00 3912.00,926.00
       3912.00,926.00 3912.00,763.00 3912.00,763.00 Z" data-front="59" data-rear="59" data-left="150" data-lot="9" data-street="Community Loop" data-right="150" data-sqft="8887" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_61" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 4001.00,764.00
           C 4001.00,764.00 4067.00,764.00 4067.00,764.00
             4067.00,764.00 4126.00,769.00 4126.00,769.00
             4126.00,769.00 4124.09,801.00 4124.09,801.00
             4124.09,801.00 4120.09,855.00 4120.09,855.00
             4120.09,855.00 4117.91,889.00 4117.91,889.00
             4117.91,889.00 4116.00,915.00 4116.00,915.00
             4116.00,915.00 4115.00,964.00 4115.00,964.00
             4115.00,964.00 4114.00,979.00 4114.00,979.00
             4114.00,979.00 4114.00,988.00 4114.00,988.00
             4114.00,988.00 4001.00,988.00 4001.00,988.00
             4001.00,988.00 4001.00,764.00 4001.00,764.00 Z" data-front="85" data-rear="76" data-left="150" data-lot="10" data-street="Community Loop" data-right="147" data-sqft="11,815" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_62" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 4001.00,988.00
           C 4001.00,988.00 4088.00,988.00 4088.00,988.00
             4088.00,988.00 4108.00,988.00 4108.00,988.00
             4108.00,988.00 4113.98,989.60 4113.98,989.60
             4113.98,989.60 4115.00,997.00 4115.00,997.00
             4115.00,997.00 4115.00,1043.00 4115.00,1043.00
             4115.00,1043.00 4116.00,1060.00 4116.00,1060.00
             4116.00,1060.00 4116.00,1109.00 4116.00,1109.00
             4116.00,1109.00 4117.00,1124.00 4117.00,1124.00
             4117.00,1124.00 4117.00,1173.00 4117.00,1173.00
             4117.00,1173.00 4118.00,1188.00 4118.00,1188.00
             4118.00,1188.00 4118.00,1213.00 4118.00,1213.00
             4118.00,1213.00 4003.00,1213.00 4003.00,1213.00
             4003.00,1213.00 4003.00,1131.00 4003.00,1131.00
             4003.00,1131.00 4002.00,1116.00 4002.00,1116.00
             4002.00,1116.00 4002.00,1040.00 4002.00,1040.00
             4002.00,1040.00 4001.00,1025.00 4001.00,1025.00
             4001.00,1025.00 4001.00,988.00 4001.00,988.00 Z" data-front="76" data-rear="76" data-left="150" data-lot="11" data-street="Collective Lane" data-right="150" data-sqft="11,458" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_64" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3913.00,989.00
           C 3913.00,989.00 4003.00,989.00 4003.00,989.00
             4003.00,989.00 4003.00,1140.00 4003.00,1140.00
             4003.00,1140.00 4003.00,1187.00 4003.00,1187.00
             4003.00,1187.00 4004.00,1213.00 4004.00,1213.00
             4004.00,1213.00 3982.00,1213.00 3982.00,1213.00
             3982.00,1213.00 3966.00,1214.00 3966.00,1214.00
             3966.00,1214.00 3914.00,1214.00 3914.00,1214.00
             3914.00,1214.00 3914.00,1062.00 3914.00,1062.00
             3914.00,1062.00 3914.00,1015.00 3914.00,1015.00
             3914.00,1015.00 3913.00,989.00 3913.00,989.00 Z" data-front="59" data-rear="59" data-left="150" data-lot="12" data-street="Collective Lane" data-right="150" data-sqft="8,888" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_66" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3834.00,989.00
           C 3834.00,989.00 3864.00,989.00 3864.00,989.00
             3864.00,989.00 3881.00,988.00 3881.00,988.00
             3881.00,988.00 3913.00,988.00 3913.00,988.00
             3913.00,988.00 3913.00,1071.00 3913.00,1071.00
             3913.00,1071.00 3914.00,1086.00 3914.00,1086.00
             3914.00,1086.00 3914.00,1161.00 3914.00,1161.00
             3914.00,1161.00 3915.00,1176.00 3915.00,1176.00
             3915.00,1176.00 3915.00,1213.00 3915.00,1213.00
             3915.00,1213.00 3835.00,1214.00 3835.00,1214.00
             3835.00,1214.00 3835.00,1072.00 3835.00,1072.00
             3835.00,1072.00 3834.00,1057.00 3834.00,1057.00
             3834.00,1057.00 3834.00,989.00 3834.00,989.00 Z" data-front="52" data-rear="52" data-left="150" data-lot="13" data-street="Collective Lane" data-right="150" data-sqft="7,837" data-type="Twin Home" data-unit_type="Sqft" data-block="1" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_67" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3754.00,990.00
     C 3754.00,990.00 3804.00,990.00 3804.00,990.00
       3804.00,990.00 3816.00,989.04 3816.00,989.04
       3816.00,989.04 3833.04,989.04 3833.04,989.04
       3833.04,989.04 3833.04,1004.00 3833.04,1004.00
       3833.04,1004.00 3834.00,1016.00 3834.00,1016.00
       3834.00,1016.00 3834.00,1113.00 3834.00,1113.00
       3834.00,1113.00 3835.00,1128.00 3835.00,1128.00
       3835.00,1128.00 3835.00,1214.00 3835.00,1214.00
       3835.00,1214.00 3785.00,1214.00 3785.00,1214.00
       3785.00,1214.00 3768.00,1215.00 3768.00,1215.00
       3755.61,1215.02 3755.02,1215.81 3755.00,1206.00
       3755.00,1206.00 3755.00,1082.00 3755.00,1082.00
       3755.00,1082.00 3754.00,1067.00 3754.00,1067.00
       3754.00,1067.00 3754.00,990.00 3754.00,990.00 Z" data-front="52" data-rear="59" data-left="150" data-lot="14" data-street="Collective Lane" data-right="150" data-sqft="7,838" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_69" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3667.00,990.00
           C 3667.00,990.00 3755.00,990.00 3755.00,990.00
             3755.00,990.00 3755.00,1215.00 3755.00,1215.00
             3755.00,1215.00 3668.00,1215.00 3668.00,1215.00
             3668.00,1215.00 3668.00,1073.00 3668.00,1073.00
             3668.00,1073.00 3667.00,1058.00 3667.00,1058.00
             3667.00,1058.00 3667.00,990.00 3667.00,990.00 ZS" data-front="59" data-rear="59" data-left="150" data-lot="15" data-street="Collective Lane" data-right="150" data-sqft="8,887" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_71" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3579.00,990.00
           C 3579.00,990.00 3667.00,990.00 3667.00,990.00
             3667.00,990.00 3667.00,1132.00 3667.00,1132.00
             3667.00,1132.00 3668.00,1147.00 3668.00,1147.00
             3668.00,1147.00 3668.00,1214.00 3668.00,1214.00
             3668.00,1214.00 3579.00,1214.00 3579.00,1214.00
             3579.00,1214.00 3579.00,990.00 3579.00,990.00 Z" data-front="59" data-rear="59" data-left="150" data-lot="16" data-street="Collective Lane" data-right="150" data-sqft="8,888" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_72" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3500.00,990.96
        C 3500.00,990.96 3516.00,990.96 3516.00,990.96
        3516.00,990.96 3528.00,990.00 3528.00,990.00
        3528.00,990.00 3578.00,990.00 3578.00,990.00
        3578.00,990.00 3578.00,1067.00 3578.00,1067.00
        3578.00,1067.00 3579.00,1082.00 3579.00,1082.00
        3579.00,1082.00 3579.00,1215.00 3579.00,1215.00
        3579.00,1215.00 3501.00,1215.00 3501.00,1215.00
        3501.00,1215.00 3501.00,1169.00 3501.00,1169.00
        3501.00,1169.00 3500.00,1152.00 3500.00,1152.00
        3500.00,1152.00 3500.00,990.96 3500.00,990.96 Z" data-front="51" data-rear="51" data-left="150" data-lot="17" data-street="Collective Lane" data-right="149" data-sqft="7,773" data-type="Twin Home" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_74" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3422.00,990.00
           C 3422.00,990.00 3500.00,990.00 3500.00,990.00
             3500.00,990.00 3500.00,1142.00 3500.00,1142.00
             3500.00,1142.00 3500.00,1189.00 3500.00,1189.00
             3500.00,1189.00 3501.00,1216.00 3501.00,1216.00
             3501.00,1216.00 3442.00,1216.00 3442.00,1216.00
             3438.42,1216.00 3425.03,1216.93 3423.02,1214.40
             3421.77,1212.81 3422.00,1208.99 3422.00,1207.00
             3422.00,1207.00 3422.00,990.00 3422.00,990.00 Z" data-front="51" data-rear="51" data-left="150" data-lot="18" data-street="Collective Lane" data-right="149" data-sqft="7,761" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_76" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3334.00,990.00
           C 3334.00,990.00 3423.00,990.00 3423.00,990.00
             3423.00,990.00 3423.00,1216.00 3423.00,1216.00
             3423.00,1216.00 3403.00,1214.17 3403.00,1214.17
             3403.00,1214.17 3356.00,1210.17 3356.00,1210.17
             3356.00,1210.17 3342.00,1209.03 3342.00,1209.03
             3342.00,1209.03 3336.02,1207.40 3336.02,1207.40
             3336.02,1207.40 3335.00,1200.00 3335.00,1200.00
             3335.00,1200.00 3335.00,1071.00 3335.00,1071.00
             3335.00,1071.00 3334.00,1056.00 3334.00,1056.00
             3334.00,1056.00 3334.00,990.00 3334.00,990.00 Z" data-front="59" data-rear="59" data-left="149" data-lot="19" data-street="Collective Lane" data-right="144" data-sqft="8,707" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_77" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3210.00,991.00
           C 3210.00,991.00 3334.00,991.00 3334.00,991.00
             3334.00,991.00 3334.00,1053.00 3334.00,1053.00
             3334.00,1053.00 3335.00,1070.00 3335.00,1070.00
             3335.00,1070.00 3335.00,1208.00 3335.00,1208.00
             3335.00,1208.00 3264.00,1197.57 3264.00,1197.57
             3264.00,1197.57 3239.00,1192.40 3239.00,1192.40
             3239.00,1192.40 3219.00,1188.40 3219.00,1188.40
             3219.00,1188.40 3211.02,1185.44 3211.02,1185.44
             3211.02,1185.44 3210.00,1178.00 3210.00,1178.00
             3210.00,1178.00 3210.00,991.00 3210.00,991.00 Z" data-front="83" data-rear="81" data-left="144" data-lot="20" data-street="Collective Lane" data-right="130" data-sqft="11,255" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_80" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3211.00,1271.00
     C 3211.00,1271.00 3254.00,1277.13 3254.00,1277.13
       3254.00,1277.13 3323.00,1286.72 3323.00,1286.72
       3323.00,1286.72 3361.00,1292.00 3361.00,1292.00
       3361.00,1292.00 3361.00,1480.00 3361.00,1480.00
       3361.00,1480.00 3246.00,1464.15 3246.00,1464.15
       3246.00,1464.15 3222.00,1460.95 3222.00,1460.95
       3222.00,1460.95 3212.02,1458.40 3212.02,1458.40
       3212.02,1458.40 3211.00,1451.00 3211.00,1451.00
       3211.00,1451.00 3211.00,1271.00 3211.00,1271.00 Z" data-front="100" data-rear="101" data-left="126" data-lot="1" data-street="Collective Lane" data-right="125" data-sqft="15,036" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_82" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3361.00,1292.00
      C 3361.00,1292.00 3384.00,1293.00 3384.00,1293.00
        3384.00,1293.00 3419.00,1294.00 3419.00,1294.00
        3419.00,1294.00 3436.00,1295.04 3436.00,1295.04
        3436.00,1295.04 3449.00,1295.04 3449.00,1295.04
        3449.00,1295.04 3460.00,1296.00 3460.00,1296.00
        3460.00,1296.00 3482.00,1297.00 3482.00,1297.00
        3482.00,1297.00 3482.00,1439.00 3482.00,1439.00
        3482.00,1439.00 3483.00,1454.00 3483.00,1454.00
        3483.01,1462.81 3483.91,1490.29 3482.00,1497.00
        3482.00,1497.00 3440.00,1490.87 3440.00,1490.87
        3440.00,1490.87 3362.00,1480.00 3362.00,1480.00
        3362.00,1480.00 3362.00,1419.00 3362.00,1419.00
        3362.00,1419.00 3361.00,1402.00 3361.00,1402.00
        3361.00,1402.00 3361.00,1292.00 3361.00,1292.00 Z" data-front="80" data-rear="81" data-left="125" data-lot="2" data-street="Collective Lane" data-right="133" data-sqft="10,439" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_83" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3481.00,1297.00
     C 3481.00,1297.00 3601.00,1297.00 3601.00,1297.00
       3601.00,1297.00 3602.00,1318.00 3602.00,1318.00
       3602.00,1318.00 3602.00,1383.00 3602.00,1383.00
       3602.00,1383.00 3603.00,1400.00 3603.00,1400.00
       3603.00,1400.00 3603.00,1466.00 3603.00,1466.00
       3603.00,1466.00 3604.00,1481.00 3604.00,1481.00
       3604.00,1481.00 3604.00,1514.00 3604.00,1514.00
       3604.00,1514.00 3571.00,1509.27 3571.00,1509.27
       3571.00,1509.27 3512.00,1500.87 3512.00,1500.87
       3512.00,1500.87 3483.00,1497.00 3483.00,1497.00
       3483.00,1497.00 3483.00,1481.00 3483.00,1481.00
       3483.00,1481.00 3482.00,1465.00 3482.00,1465.00
       3482.00,1465.00 3482.00,1330.00 3482.00,1330.00
       3482.00,1330.00 3481.00,1315.00 3481.00,1315.00
       3481.00,1315.00 3481.00,1297.00 3481.00,1297.00 Z" data-front="80" data-rear="80" data-left="133" data-lot="3" data-street="Collective Lane" data-right="144" data-sqft="11,142" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_85" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3599.00,1295.00
           C 3599.00,1295.00 3624.00,1295.00 3624.00,1295.00
             3624.00,1295.00 3641.00,1296.00 3641.00,1296.00
             3641.00,1296.00 3683.00,1296.00 3683.00,1296.00
             3683.00,1296.00 3698.00,1297.00 3698.00,1297.00
             3698.00,1297.00 3721.00,1297.00 3721.00,1297.00
             3721.00,1297.00 3721.00,1405.00 3721.00,1405.00
             3721.00,1405.00 3722.00,1420.00 3722.00,1420.00
             3722.00,1420.00 3722.00,1529.00 3722.00,1529.00
             3722.00,1529.00 3678.00,1523.28 3678.00,1523.28
             3678.00,1523.28 3606.00,1514.00 3606.00,1514.00
             3606.00,1514.00 3605.00,1491.00 3605.00,1491.00
             3605.00,1491.00 3605.00,1475.00 3605.00,1475.00
             3605.00,1475.00 3604.00,1460.00 3604.00,1460.00
             3604.00,1460.00 3604.00,1443.00 3604.00,1443.00
             3604.00,1443.00 3603.00,1428.00 3603.00,1428.00
             3603.00,1428.00 3603.00,1413.00 3603.00,1413.00
             3603.00,1413.00 3602.00,1397.00 3602.00,1397.00
             3602.00,1397.00 3600.00,1320.00 3600.00,1320.00
             3600.00,1320.00 3599.00,1305.00 3599.00,1305.00
             3599.00,1305.00 3599.00,1295.00 3599.00,1295.00 Z" data-front="80" data-rear="80" data-left="144" data-lot="4" data-street="Collective Lane" data-right="156" data-sqft="12,052" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_86" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="89,900" data-placement="left" d="M 3721.00,1296.00
           C 3721.00,1296.00 3775.00,1296.00 3775.00,1296.00
             3775.00,1296.00 3792.00,1297.00 3792.00,1297.00
             3792.00,1297.00 3845.00,1297.00 3845.00,1297.00
             3845.00,1297.00 3845.00,1350.00 3845.00,1350.00
             3845.00,1350.00 3846.00,1367.00 3846.00,1367.00
             3846.00,1367.00 3846.00,1477.00 3846.00,1477.00
             3846.00,1477.00 3847.00,1492.00 3847.00,1492.00
             3847.00,1492.00 3847.00,1547.00 3847.00,1547.00
             3847.00,1547.00 3809.00,1542.15 3809.00,1542.15
             3809.00,1542.15 3721.00,1531.00 3721.00,1531.00
             3721.00,1531.00 3721.00,1296.00 3721.00,1296.00 Z" data-front="82" data-rear="82" data-left="156" data-lot="5" data-street="Collective Lane" data-right="167" data-sqft="13,297" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_88" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3843.00,1296.00
           C 3843.00,1296.00 3968.00,1296.00 3968.00,1296.00
             3968.00,1296.00 3968.00,1377.00 3968.00,1377.00
             3968.00,1377.00 3969.00,1392.00 3969.00,1392.00
             3969.00,1392.00 3969.00,1466.00 3969.00,1466.00
             3969.00,1466.00 3970.00,1481.00 3970.00,1481.00
             3970.00,1481.00 3970.00,1561.00 3970.00,1561.00
             3970.00,1561.00 3936.00,1557.28 3936.00,1557.28
             3936.00,1557.28 3873.00,1549.85 3873.00,1549.85
             3873.00,1549.85 3846.00,1547.00 3846.00,1547.00
             3846.00,1547.00 3846.00,1514.00 3846.00,1514.00
             3846.00,1514.00 3845.00,1497.00 3845.00,1497.00
             3845.00,1497.00 3845.00,1431.00 3845.00,1431.00
             3845.00,1431.00 3844.00,1414.00 3844.00,1414.00
             3844.00,1414.00 3844.00,1345.00 3844.00,1345.00
             3844.00,1345.00 3843.00,1330.00 3843.00,1330.00
             3843.00,1330.00 3843.00,1296.00 3843.00,1296.00 Z" data-front="81" data-rear="81" data-left="16&amp;" data-lot="6" data-street="Collective Lane" data-right="179" data-sqft="14,097" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_90" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="89,900" data-placement="left" d="M 3964.00,1295.00
           C 3964.00,1295.00 3983.00,1294.00 3983.00,1294.00
             3983.00,1294.00 4016.00,1294.00 4016.00,1294.00
             4016.00,1294.00 4057.00,1294.00 4057.00,1294.00
             4057.00,1294.00 4074.00,1293.00 4074.00,1293.00
             4074.00,1293.00 4118.00,1293.00 4118.00,1293.00
             4118.00,1293.00 4118.00,1461.00 4118.00,1461.00
             4118.00,1461.00 4119.00,1476.00 4119.00,1476.00
             4119.00,1476.00 4119.00,1557.00 4119.00,1557.00
             4119.00,1557.00 4106.00,1558.00 4106.00,1558.00
             4106.00,1558.00 4082.00,1559.09 4082.00,1559.09
             4082.00,1559.09 4071.00,1560.00 4071.00,1560.00
             4071.00,1560.00 4055.00,1561.00 4055.00,1561.00
             4055.00,1561.00 4034.00,1562.00 4034.00,1562.00
             4034.00,1562.00 4021.00,1563.00 4021.00,1563.00
             4021.00,1563.00 4003.00,1564.00 4003.00,1564.00
             4003.00,1564.00 3983.00,1565.00 3983.00,1565.00
             3983.00,1565.00 3966.00,1566.00 3966.00,1566.00
             3966.00,1566.00 3966.00,1484.00 3966.00,1484.00
             3966.00,1484.00 3965.00,1469.00 3965.00,1469.00
             3965.00,1469.00 3965.00,1385.00 3965.00,1385.00
             3965.00,1385.00 3965.00,1326.00 3965.00,1326.00
             3965.00,1326.00 3964.00,1295.00 3964.00,1295.00 Z" data-front="100" data-rear="100" data-left="175" data-lot="7" data-street="Collective Lane" data-right="179" data-sqft="17,767" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_91" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3969.00,1565.00
           C 3969.00,1565.00 3990.00,1564.00 3990.00,1564.00
             3990.00,1564.00 4000.00,1563.00 4000.00,1563.00
             4000.00,1563.00 4011.00,1563.00 4011.00,1563.00
             4011.00,1563.00 4025.00,1562.00 4025.00,1562.00
             4025.00,1562.00 4041.00,1561.00 4041.00,1561.00
             4041.00,1561.00 4058.00,1560.00 4058.00,1560.00
             4058.00,1560.00 4078.00,1559.00 4078.00,1559.00
             4078.00,1559.00 4097.00,1558.00 4097.00,1558.00
             4097.00,1558.00 4117.00,1557.00 4117.00,1557.00
             4117.00,1557.00 4116.00,1577.00 4116.00,1577.00
             4116.00,1577.00 4115.00,1598.00 4115.00,1598.00
             4115.00,1598.00 4114.00,1627.00 4114.00,1627.00
             4114.00,1627.00 4113.00,1641.00 4113.00,1641.00
             4113.00,1641.00 4112.00,1671.00 4112.00,1671.00
             4112.00,1671.00 4111.00,1684.00 4111.00,1684.00
             4111.00,1684.00 4110.00,1713.00 4110.00,1713.00
             4110.00,1713.00 4109.00,1730.00 4109.00,1730.00
             4109.00,1730.00 4108.00,1751.00 4108.00,1751.00
             4108.00,1751.00 4107.00,1780.00 4107.00,1780.00
             4107.00,1780.00 4106.00,1793.00 4106.00,1793.00
             4106.00,1793.00 4105.00,1818.96 4105.00,1818.96
             4105.00,1818.96 4093.00,1818.96 4093.00,1818.96
             4093.00,1818.96 4081.00,1818.00 4081.00,1818.00
             4081.00,1818.00 4006.00,1816.00 4006.00,1816.00
             4006.00,1816.00 3989.00,1815.00 3989.00,1815.00
             3989.00,1815.00 3974.00,1815.00 3974.00,1815.00
             3974.00,1815.00 3958.00,1814.00 3958.00,1814.00
             3958.00,1814.00 3952.00,1814.00 3952.00,1814.00
             3952.00,1814.00 3952.91,1803.00 3952.91,1803.00
             3952.91,1803.00 3955.91,1759.00 3955.91,1759.00
             3955.91,1759.00 3964.09,1634.00 3964.09,1634.00
             3964.09,1634.00 3967.09,1590.00 3967.09,1590.00
             3967.09,1590.00 3969.00,1565.00 3969.00,1565.00 Z" data-front="100" data-rear="100" data-left="140" data-lot="8" data-street="Collective Lane" data-right="166" data-sqft="17,237" data-type="Single family" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_93" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3846.00,1547.00
     C 3846.00,1547.00 3875.00,1551.27 3875.00,1551.27
       3875.00,1551.27 3935.00,1559.73 3935.00,1559.73
       3935.00,1559.73 3958.00,1562.87 3958.00,1562.87
       3960.19,1563.17 3966.90,1563.80 3968.40,1565.02
       3970.81,1566.98 3969.12,1577.56 3968.96,1581.00
       3968.96,1581.00 3967.09,1603.00 3967.09,1603.00
       3967.09,1603.00 3965.10,1629.00 3965.10,1629.00
       3965.10,1629.00 3965.10,1637.00 3965.10,1637.00
       3965.10,1637.00 3962.91,1665.00 3962.91,1665.00
       3962.91,1665.00 3958.91,1720.00 3958.91,1720.00
       3958.91,1720.00 3953.91,1789.00 3953.91,1789.00
       3953.91,1789.00 3952.00,1813.00 3952.00,1813.00
       3952.00,1813.00 3937.00,1812.00 3937.00,1812.00
       3937.00,1812.00 3922.00,1811.00 3922.00,1811.00
       3922.00,1811.00 3901.00,1810.00 3901.00,1810.00
       3901.00,1810.00 3887.00,1809.00 3887.00,1809.00
       3887.00,1809.00 3849.00,1807.00 3849.00,1807.00
       3849.00,1807.00 3832.00,1806.00 3832.00,1806.00
       3832.00,1806.00 3833.00,1786.00 3833.00,1786.00
       3833.00,1786.00 3834.00,1770.00 3834.00,1770.00
       3834.00,1770.00 3836.00,1731.00 3836.00,1731.00
       3836.00,1731.00 3837.00,1714.00 3837.00,1714.00
       3837.00,1714.00 3839.00,1673.00 3839.00,1673.00
       3839.00,1673.00 3840.00,1657.00 3840.00,1657.00
       3840.00,1657.00 3841.00,1645.00 3841.00,1645.00
       3841.00,1645.00 3841.00,1633.00 3841.00,1633.00
       3841.00,1633.00 3842.00,1620.00 3842.00,1620.00
       3842.00,1620.00 3843.00,1605.00 3843.00,1605.00
       3843.00,1605.00 3844.00,1581.00 3844.00,1581.00
       3844.00,1581.00 3845.00,1567.00 3845.00,1567.00
       3845.00,1567.00 3846.00,1547.00 3846.00,1547.00 Z" data-front="81" data-rear="81" data-left="166" data-lot="9" data-street="Collective Lane" data-right="172" data-sqft="13,828" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_95" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3759.00,1536.00
        C 3759.00,1536.00 3793.00,1540.58 3793.00,1540.58
        3793.00,1540.58 3847.00,1548.00 3847.00,1548.00
        3847.00,1548.00 3845.91,1566.00 3845.91,1566.00
        3845.91,1566.00 3845.00,1576.00 3845.00,1576.00
        3845.00,1576.00 3843.00,1614.00 3843.00,1614.00
        3843.00,1614.00 3842.00,1630.00 3842.00,1630.00
        3842.00,1630.00 3841.00,1647.00 3841.00,1647.00
        3841.00,1647.00 3839.00,1686.00 3839.00,1686.00
        3839.00,1686.00 3838.00,1700.00 3838.00,1700.00
        3838.00,1700.00 3836.00,1736.00 3836.00,1736.00
        3836.00,1736.00 3835.00,1752.00 3835.00,1752.00
        3835.00,1752.00 3834.00,1774.00 3834.00,1774.00
        3834.00,1774.00 3833.00,1788.00 3833.00,1788.00
        3833.00,1788.00 3832.00,1807.00 3832.00,1807.00
        3832.00,1807.00 3808.00,1804.83 3808.00,1804.83
        3808.00,1804.83 3771.00,1799.34 3771.00,1799.34
        3771.00,1799.34 3710.00,1782.28 3710.00,1782.28
        3710.00,1782.28 3686.00,1775.00 3686.00,1775.00
        3686.00,1775.00 3706.98,1706.00 3706.98,1706.00
        3706.98,1706.00 3743.72,1586.00 3743.72,1586.00
        3743.72,1586.00 3752.34,1558.00 3752.34,1558.00
        3752.34,1558.00 3759.00,1536.00 3759.00,1536.00 Z" data-front="98" data-rear="58" data-left="172" data-lot="10" data-street="10" data-right="167" data-sqft="13,381" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_97" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3639.00,1519.00
           C 3639.00,1519.00 3682.00,1525.15 3682.00,1525.15
             3682.00,1525.15 3714.00,1529.72 3714.00,1529.72
             3714.00,1529.72 3759.00,1536.00 3759.00,1536.00
             3759.00,1536.00 3752.72,1558.00 3752.72,1558.00
             3752.72,1558.00 3739.02,1603.00 3739.02,1603.00
             3739.02,1603.00 3704.34,1717.00 3704.34,1717.00
             3704.34,1717.00 3691.66,1759.00 3691.66,1759.00
             3690.69,1762.04 3688.36,1772.26 3685.58,1773.34
             3683.46,1774.16 3678.24,1772.35 3676.00,1771.71
             3676.00,1771.71 3654.00,1765.34 3654.00,1765.34
             3654.00,1765.34 3572.00,1742.00 3572.00,1742.00
             3572.00,1742.00 3578.58,1717.00 3578.58,1717.00
             3578.58,1717.00 3595.28,1662.00 3595.28,1662.00
             3595.28,1662.00 3622.28,1573.00 3622.28,1573.00
             3622.28,1573.00 3639.00,1519.00 3639.00,1519.00 Z" data-front="80" data-rear="81" data-left="154" data-lot="11" data-street="Collective Lane" data-right="167" data-sqft="12,878" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_99" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3456.00,1705.00
           C 3456.00,1705.00 3460.28,1691.00 3460.28,1691.00
             3460.28,1691.00 3470.98,1657.00 3470.98,1657.00
             3470.98,1657.00 3502.98,1555.00 3502.98,1555.00
             3502.98,1555.00 3514.86,1517.00 3514.86,1517.00
             3514.86,1517.00 3520.70,1502.22 3520.70,1502.22
             3520.70,1502.22 3530.00,1502.22 3530.00,1502.22
             3530.00,1502.22 3552.00,1505.73 3552.00,1505.73
             3552.00,1505.73 3640.00,1519.00 3640.00,1519.00
             3640.00,1519.00 3613.02,1607.00 3613.02,1607.00
             3613.02,1607.00 3572.00,1740.00 3572.00,1740.00
             3572.00,1740.00 3531.00,1727.72 3531.00,1727.72
             3531.00,1727.72 3456.00,1705.00 3456.00,1705.00 Z" data-front="80" data-rear="81" data-left="154" data-lot="12" data-street="Collective Lane" data-right="141" data-sqft="11,863" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_100" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3335.00,1668.00
           C 3335.00,1668.00 3351.67,1617.00 3351.67,1617.00
             3351.67,1617.00 3379.33,1532.00 3379.33,1532.00
             3379.33,1532.00 3390.67,1498.00 3390.67,1498.00
             3390.67,1498.00 3395.56,1486.17 3395.56,1486.17
             3395.56,1486.17 3405.00,1486.17 3405.00,1486.17
             3405.00,1486.17 3429.00,1489.58 3429.00,1489.58
             3429.00,1489.58 3494.00,1498.73 3494.00,1498.73
             3494.00,1498.73 3524.00,1503.00 3524.00,1503.00
             3524.00,1503.00 3499.67,1578.00 3499.67,1578.00
             3499.67,1578.00 3458.00,1706.00 3458.00,1706.00
             3458.00,1706.00 3406.00,1690.02 3406.00,1690.02
             3406.00,1690.02 3335.00,1668.00 3335.00,1668.00 Z" data-front="80" data-rear="81" data-left="141" data-lot="13" data-street="Collective Lane" data-right="129" data-sqft="11,234" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_102" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3212.00,1458.00
           C 3212.00,1458.00 3239.00,1461.73 3239.00,1461.73
             3239.00,1461.73 3288.00,1468.73 3288.00,1468.73
             3288.00,1468.73 3358.00,1478.73 3358.00,1478.73
             3358.00,1478.73 3394.00,1484.00 3394.00,1484.00
             3394.00,1484.00 3371.02,1559.00 3371.02,1559.00
             3371.02,1559.00 3337.00,1669.00 3337.00,1669.00
             3337.00,1669.00 3313.00,1664.61 3313.00,1664.61
             3313.00,1664.61 3258.00,1654.43 3258.00,1654.43
             3258.00,1654.43 3209.00,1648.00 3209.00,1648.00
             3209.00,1648.00 3209.00,1624.00 3209.00,1624.00
             3209.00,1624.00 3210.00,1607.00 3210.00,1607.00
             3210.00,1607.00 3210.00,1561.00 3210.00,1561.00
             3210.00,1561.00 3211.00,1544.00 3211.00,1544.00
             3211.00,1544.00 3211.00,1495.00 3211.00,1495.00
             3211.00,1495.00 3212.00,1480.00 3212.00,1480.00
             3212.00,1480.00 3212.00,1458.00 3212.00,1458.00 Z" data-front="86" data-rear="101" data-left="129" data-lot="14" data-street="Collective Lane" data-right="126" data-sqft="13,056" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_106" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3213.00,1732.00
           C 3213.00,1732.00 3257.00,1735.29 3257.00,1735.29
             3257.00,1735.29 3323.00,1748.48 3323.00,1748.48
             3323.00,1748.48 3356.00,1760.00 3356.00,1760.00
             3356.00,1760.00 3338.71,1821.00 3338.71,1821.00
             3338.71,1821.00 3305.29,1937.00 3305.29,1937.00
             3305.29,1937.00 3293.28,1979.00 3293.28,1979.00
             3291.34,1985.46 3291.41,1992.48 3283.00,1991.83
             3283.00,1991.83 3269.00,1991.00 3269.00,1991.00
             3269.00,1991.00 3252.00,1990.00 3252.00,1990.00
             3252.00,1990.00 3238.00,1989.00 3238.00,1989.00
             3238.00,1989.00 3196.00,1987.00 3196.00,1987.00
             3196.00,1987.00 3197.83,1965.00 3197.83,1965.00
             3197.83,1965.00 3199.83,1941.00 3199.83,1941.00
             3199.83,1941.00 3204.17,1884.00 3204.17,1884.00
             3204.17,1884.00 3209.00,1816.00 3209.00,1816.00
             3209.00,1816.00 3210.04,1803.00 3210.04,1803.00
             3210.04,1803.00 3210.04,1789.00 3210.04,1789.00
             3210.04,1789.00 3211.04,1777.00 3211.04,1777.00
             3211.04,1777.00 3211.04,1764.00 3211.04,1764.00
             3211.04,1764.00 3212.00,1753.00 3212.00,1753.00
             3212.00,1753.00 3213.00,1732.00 3213.00,1732.00 Z" data-front="129" data-rear="60" data-left="115" data-lot="1" data-street="Collective Lane" data-right="161" data-sqft="13,125" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_108" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3357.00,1761.00
     C 3357.00,1761.00 3382.00,1767.98 3382.00,1767.98
       3382.00,1767.98 3409.00,1776.02 3409.00,1776.02
       3409.00,1776.02 3436.00,1784.00 3436.00,1784.00
       3436.00,1784.00 3429.34,1808.00 3429.34,1808.00
       3429.34,1808.00 3417.67,1845.00 3417.67,1845.00
       3417.67,1845.00 3386.00,1945.00 3386.00,1945.00
       3386.00,1945.00 3373.67,1984.00 3373.67,1984.00
       3373.67,1984.00 3368.30,1998.01 3368.30,1998.01
       3368.30,1998.01 3361.00,1998.83 3361.00,1998.83
       3361.00,1998.83 3345.00,1997.42 3345.00,1997.42
       3345.00,1997.42 3287.00,1993.00 3287.00,1993.00
       3287.00,1993.00 3294.98,1965.00 3294.98,1965.00
       3294.98,1965.00 3309.28,1918.00 3309.28,1918.00
       3309.28,1918.00 3357.00,1761.00 3357.00,1761.00 Z" data-front="54" data-rear="56" data-left="161" data-lot="2" data-street="Collective Lane" data-right="149" data-sqft="8,568" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_110" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3436.00,1785.00
     C 3436.00,1785.00 3462.00,1792.33 3462.00,1792.33
       3462.00,1792.33 3510.00,1807.00 3510.00,1807.00
       3510.00,1807.00 3499.42,1843.00 3499.42,1843.00
       3499.42,1843.00 3467.28,1951.00 3467.28,1951.00
       3467.28,1951.00 3451.00,2006.00 3451.00,2006.00
       3451.00,2006.00 3441.00,2005.09 3441.00,2005.09
       3441.00,2005.09 3410.00,2002.83 3410.00,2002.83
       3410.00,2002.83 3391.00,2001.04 3391.00,2001.04
       3391.00,2001.04 3377.00,2000.00 3377.00,2000.00
       3375.19,1999.94 3372.27,2000.12 3371.11,1998.40
       3369.42,1995.90 3373.05,1986.87 3374.00,1984.00
       3374.00,1984.00 3386.28,1945.00 3386.28,1945.00
       3386.28,1945.00 3420.02,1837.00 3420.02,1837.00
       3420.02,1837.00 3436.00,1785.00 3436.00,1785.00 Z" data-front="53" data-rear="54" data-left="149" data-lot="3" data-street="Collective Lane" data-right="138" data-sqft="7,631" data-type="Twin Home " data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_112" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3510.00,1806.00
           C 3510.00,1806.00 3568.00,1825.34 3568.00,1825.34
             3568.00,1825.34 3582.00,1830.33 3582.00,1830.33
             3583.80,1830.95 3586.87,1831.79 3587.89,1833.51
             3589.35,1835.99 3585.98,1844.08 3585.14,1847.00
             3585.14,1847.00 3573.33,1884.00 3573.33,1884.00
             3573.33,1884.00 3541.98,1982.00 3541.98,1982.00
             3541.98,1982.00 3527.00,2029.00 3527.00,2029.00
             3527.00,2029.00 3503.00,2022.42 3503.00,2022.42
             3503.00,2022.42 3453.00,2007.00 3453.00,2007.00
             3453.00,2007.00 3460.58,1980.00 3460.58,1980.00
             3460.58,1980.00 3479.58,1913.00 3479.58,1913.00
             3479.58,1913.00 3498.58,1846.00 3498.58,1846.00
             3498.58,1846.00 3510.00,1806.00 3510.00,1806.00 Z" data-front="53" data-rear="53" data-left="149" data-lot="4" data-street="Collective Lane" data-right="138" data-sqft="7,342" data-type="Single family" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_114" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3528.00,2030.00
           C 3528.00,2030.00 3542.28,1982.00 3542.28,1982.00
             3542.28,1982.00 3572.28,1883.00 3572.28,1883.00
             3572.28,1883.00 3583.14,1847.00 3583.14,1847.00
             3583.95,1844.19 3586.30,1834.45 3588.51,1833.11
             3591.67,1831.19 3605.14,1836.78 3609.00,1837.98
             3609.00,1837.98 3669.00,1857.00 3669.00,1857.00
             3669.00,1857.00 3663.72,1875.00 3663.72,1875.00
             3663.72,1875.00 3654.72,1905.00 3654.72,1905.00
             3654.72,1905.00 3623.72,2008.00 3623.72,2008.00
             3623.72,2008.00 3612.98,2044.00 3612.98,2044.00
             3612.11,2046.82 3610.65,2054.31 3607.72,2055.22
             3605.91,2055.78 3602.77,2054.58 3601.00,2054.00
             3601.00,2054.00 3586.00,2049.00 3586.00,2049.00
             3586.00,2049.00 3528.00,2030.00 3528.00,2030.00 Z" data-front="58" data-rear="58" data-left="138" data-lot="5" data-street="Collective Lane" data-right="139" data-sqft="8,082" data-type="Single Family" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_115" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3669.00,1854.00
           C 3669.00,1854.00 3692.00,1860.58 3692.00,1860.58
             3692.00,1860.58 3729.00,1871.29 3729.00,1871.29
             3729.00,1871.29 3744.00,1875.66 3744.00,1875.66
             3745.79,1876.23 3748.85,1876.97 3749.96,1878.56
             3751.63,1880.94 3749.29,1889.92 3748.75,1893.00
             3748.75,1893.00 3741.20,1932.00 3741.20,1932.00
             3741.20,1932.00 3722.42,2030.00 3722.42,2030.00
             3722.42,2030.00 3711.00,2089.00 3711.00,2089.00
             3704.45,2087.99 3690.96,2082.99 3684.00,2080.67
             3684.00,2080.67 3635.00,2064.33 3635.00,2064.33
             3635.00,2064.33 3616.00,2058.00 3616.00,2058.00
             3614.01,2057.33 3610.23,2056.35 3609.11,2054.49
             3607.48,2051.78 3610.71,2043.96 3611.66,2041.00
             3611.66,2041.00 3622.98,2004.00 3622.98,2004.00
             3622.98,2004.00 3656.98,1893.00 3656.98,1893.00
             3656.98,1893.00 3669.00,1854.00 3669.00,1854.00 Z" data-front="56" data-rear="71" data-left="139" data-lot="6" data-street="Collective Lane" data-right="143" data-sqft="9,001" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_117" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3711.00,2089.00
           C 3711.00,2089.00 3742.60,1933.00 3742.60,1933.00
             3742.60,1933.00 3750.80,1893.00 3750.80,1893.00
             3750.80,1893.00 3754.73,1879.03 3754.73,1879.03
             3754.73,1879.03 3770.00,1879.95 3770.00,1879.95
             3770.00,1879.95 3816.00,1886.00 3816.00,1886.00
             3816.00,1886.00 3814.09,1913.00 3814.09,1913.00
             3814.09,1913.00 3811.00,1958.00 3811.00,1958.00
             3811.00,1958.00 3811.00,1967.00 3811.00,1967.00
             3811.00,1967.00 3806.09,2034.00 3806.09,2034.00
             3806.09,2034.00 3804.10,2063.00 3804.10,2063.00
             3804.10,2063.00 3804.10,2073.00 3804.10,2073.00
             3804.10,2073.00 3801.96,2095.00 3801.96,2095.00
             3801.96,2095.00 3801.96,2104.00 3801.96,2104.00
             3801.14,2111.30 3800.52,2117.65 3791.00,2116.39
             3791.00,2116.39 3761.00,2106.34 3761.00,2106.34
             3761.00,2106.34 3711.00,2089.00 3711.00,2089.00 Z" data-front="41" data-rear="60" data-left="143" data-lot="7" data-street="Collective Lane" data-right="156" data-sqft="7,441" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_119" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3816.00,1886.04
           C 3816.00,1886.04 3830.00,1886.04 3830.00,1886.04
             3830.00,1886.04 3843.00,1887.00 3843.00,1887.00
             3843.00,1887.00 3860.00,1888.00 3860.00,1888.00
             3860.00,1888.00 3874.00,1889.00 3874.00,1889.00
             3874.00,1889.00 3890.96,1890.00 3890.96,1890.00
             3890.96,1890.00 3890.96,1903.00 3890.96,1903.00
             3890.96,1903.00 3890.00,1915.00 3890.00,1915.00
             3890.00,1915.00 3889.00,1964.00 3889.00,1964.00
             3889.00,1964.00 3888.00,1980.00 3888.00,1980.00
             3888.00,1980.00 3886.00,2060.00 3886.00,2060.00
             3886.00,2060.00 3885.00,2075.00 3885.00,2075.00
             3885.00,2075.00 3885.00,2093.00 3885.00,2093.00
             3885.00,2093.00 3884.00,2108.00 3884.00,2108.00
             3884.00,2108.00 3884.00,2124.00 3884.00,2124.00
             3884.00,2124.00 3882.98,2131.36 3882.98,2131.36
             3882.98,2131.36 3877.00,2132.08 3877.00,2132.08
             3877.00,2132.08 3863.00,2129.75 3863.00,2129.75
             3863.00,2129.75 3801.00,2120.00 3801.00,2120.00
             3801.00,2120.00 3801.96,2107.00 3801.96,2107.00
             3801.96,2107.00 3805.00,2061.00 3805.00,2061.00
             3805.00,2061.00 3805.00,2052.00 3805.00,2052.00
             3805.00,2052.00 3812.00,1952.00 3812.00,1952.00
             3812.00,1952.00 3812.00,1943.00 3812.00,1943.00
             3812.00,1943.00 3815.00,1905.00 3815.00,1905.00
             3815.00,1905.00 3815.00,1896.00 3815.00,1896.00
             3815.00,1896.00 3816.00,1886.04 3816.00,1886.04 Z" data-front="54" data-rear="57" data-left="153" data-lot="8" data-street="Collective Lane" data-right="163" data-sqft="8,802" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_121" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3894.00,1889.00
           C 3894.00,1889.00 3920.00,1890.83 3920.00,1890.83
             3920.00,1890.83 3979.00,1896.00 3979.00,1896.00
             3979.00,1896.00 3979.00,1908.00 3979.00,1908.00
             3979.00,1908.00 3978.00,1927.00 3978.00,1927.00
             3978.00,1927.00 3977.00,1943.00 3977.00,1943.00
             3977.00,1943.00 3976.00,1967.00 3976.00,1967.00
             3976.00,1967.00 3975.04,1977.00 3975.04,1977.00
             3975.04,1977.00 3975.04,1986.00 3975.04,1986.00
             3975.04,1986.00 3974.00,2005.00 3974.00,2005.00
             3974.00,2005.00 3973.00,2018.00 3973.00,2018.00
             3973.00,2018.00 3972.00,2044.00 3972.00,2044.00
             3972.00,2044.00 3971.00,2061.00 3971.00,2061.00
             3971.00,2061.00 3970.00,2078.00 3970.00,2078.00
             3970.00,2078.00 3969.00,2095.00 3969.00,2095.00
             3969.00,2095.00 3968.00,2121.00 3968.00,2121.00
             3968.00,2121.00 3967.00,2131.00 3967.00,2131.00
             3967.00,2131.00 3967.00,2143.00 3967.00,2143.00
             3967.00,2143.00 3966.00,2155.00 3966.00,2155.00
             3966.00,2155.00 3902.00,2141.00 3902.00,2141.00
             3902.00,2141.00 3881.99,2135.28 3881.99,2135.28
             3881.99,2135.28 3881.00,2126.00 3881.00,2126.00
             3881.00,2126.00 3882.00,2105.00 3882.00,2105.00
             3882.00,2105.00 3883.00,2091.00 3883.00,2091.00
             3883.00,2091.00 3885.00,2050.00 3885.00,2050.00
             3885.00,2050.00 3885.96,2040.00 3885.96,2040.00
             3885.96,2040.00 3885.96,2032.00 3885.96,2032.00
             3885.96,2032.00 3887.00,2014.00 3887.00,2014.00
             3887.00,2014.00 3888.00,1995.00 3888.00,1995.00
             3888.00,1995.00 3889.00,1978.00 3889.00,1978.00
             3889.00,1978.00 3890.00,1963.00 3890.00,1963.00
             3890.00,1963.00 3892.00,1922.00 3892.00,1922.00
             3892.00,1922.00 3893.00,1912.00 3893.00,1912.00
             3893.00,1912.00 3893.00,1901.00 3893.00,1901.00
             3893.00,1901.00 3894.00,1889.00 3894.00,1889.00 Z" data-front="56" data-rear="56" data-left="163" data-lot="9" data-street="Collective Lane" data-right="174" data-sqft="9,465" data-type="Single Family" data-unit_type="Sqft" data-block="5" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_123" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3979.00,1895.00
           C 3979.00,1895.00 4031.00,1897.91 4031.00,1897.91
             4031.00,1897.91 4071.00,1900.09 4071.00,1900.09
             4071.00,1900.09 4096.00,1902.00 4096.00,1902.00
             4096.00,1902.00 4096.00,1914.00 4096.00,1914.00
             4096.00,1914.00 4095.00,1932.00 4095.00,1932.00
             4095.00,1932.00 4094.00,1948.00 4094.00,1948.00
             4094.00,1948.00 4093.00,1970.00 4093.00,1970.00
             4093.00,1970.00 4092.04,1980.00 4092.04,1980.00
             4092.04,1980.00 4092.04,1989.00 4092.04,1989.00
             4092.04,1989.00 4091.00,2008.00 4091.00,2008.00
             4091.00,2008.00 4090.04,2018.00 4090.04,2018.00
             4090.04,2018.00 4090.04,2026.00 4090.04,2026.00
             4090.04,2026.00 4089.00,2045.00 4089.00,2045.00
             4089.00,2045.00 4088.00,2062.00 4088.00,2062.00
             4088.00,2062.00 4087.00,2078.00 4087.00,2078.00
             4087.00,2078.00 4085.00,2119.00 4085.00,2119.00
             4085.00,2119.00 4084.00,2134.00 4084.00,2134.00
             4084.00,2134.00 4083.00,2151.00 4083.00,2151.00
             4083.00,2151.00 4082.00,2181.00 4082.00,2181.00
             4082.00,2181.00 3994.00,2161.58 3994.00,2161.58
             3994.00,2161.58 3968.00,2156.00 3968.00,2156.00
             3968.00,2156.00 3970.00,2096.00 3970.00,2096.00
             3970.00,2096.00 3971.00,2083.00 3971.00,2083.00
             3971.00,2083.00 3972.00,2053.00 3972.00,2053.00
             3972.00,2053.00 3973.00,2040.00 3973.00,2040.00
             3973.00,2040.00 3974.00,2011.00 3974.00,2011.00
             3974.00,2011.00 3975.00,1994.00 3975.00,1994.00
             3975.00,1994.00 3976.00,1973.00 3976.00,1973.00
             3976.00,1973.00 3977.00,1944.00 3977.00,1944.00
             3977.00,1944.00 3978.00,1929.00 3978.00,1929.00
             3978.00,1929.00 3979.00,1909.00 3979.00,1909.00
             3979.00,1909.00 3979.00,1895.00 3979.00,1895.00 Z" data-front="80" data-rear="81" data-left="174" data-lot="10" data-street="Collective Lane" data-right="188" data-sqft="14,520" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_126" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3711.00,2089.00
           C 3711.00,2089.00 3762.00,2106.31 3762.00,2106.31
             3762.00,2106.31 3790.00,2116.00 3790.00,2116.00
             3790.00,2116.00 3770.86,2160.00 3770.86,2160.00
             3770.86,2160.00 3738.86,2233.00 3738.86,2233.00
             3738.86,2233.00 3713.86,2290.00 3713.86,2290.00
             3713.86,2290.00 3701.00,2319.00 3701.00,2319.00
             3701.00,2319.00 3625.00,2288.00 3625.00,2288.00
             3625.00,2288.00 3645.58,2240.00 3645.58,2240.00
             3645.58,2240.00 3680.14,2160.00 3680.14,2160.00
             3680.14,2160.00 3699.58,2115.00 3699.58,2115.00
             3699.58,2115.00 3711.00,2089.00 3711.00,2089.00 Z" data-front="55" data-rear="55" data-left="149" data-lot="11" data-street="Collective Lane" data-right="149" data-sqft="8,110" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_128" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3632.00,2064.00
     C 3632.00,2064.00 3674.00,2077.33 3674.00,2077.33
       3674.00,2077.33 3711.00,2090.00 3711.00,2090.00
       3711.00,2090.00 3692.85,2133.00 3692.85,2133.00
       3692.85,2133.00 3647.72,2237.00 3647.72,2237.00
       3647.72,2237.00 3626.00,2288.00 3626.00,2288.00
       3626.00,2288.00 3595.00,2276.20 3595.00,2276.20
       3595.00,2276.20 3551.00,2259.00 3551.00,2259.00
       3551.00,2259.00 3570.58,2211.00 3570.58,2211.00
       3570.58,2211.00 3613.01,2110.00 3613.01,2110.00
       3613.01,2110.00 3632.00,2064.00 3632.00,2064.00 Z" data-front="55" data-rear="55" data-left="149" data-lot="12" data-street="Collective Lane" data-right="140" data-sqft="7,839" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_130" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3553.00,2039.00
           C 3553.00,2039.00 3584.00,2048.98 3584.00,2048.98
             3584.00,2048.98 3633.00,2065.00 3633.00,2065.00
             3633.00,2065.00 3617.42,2103.00 3617.42,2103.00
             3617.42,2103.00 3595.42,2156.00 3595.42,2156.00
             3595.42,2156.00 3566.42,2226.00 3566.42,2226.00
             3566.42,2226.00 3553.00,2258.00 3553.00,2258.00
             3553.00,2258.00 3474.00,2229.00 3474.00,2229.00
             3474.00,2229.00 3487.58,2196.00 3487.58,2196.00
             3487.58,2196.00 3509.58,2143.00 3509.58,2143.00
             3509.58,2143.00 3536.99,2077.00 3536.99,2077.00
             3536.99,2077.00 3553.00,2039.00 3553.00,2039.00 Z" data-front="54" data-rear="54" data-left="140" data-lot="13" data-street="Collective Lane" data-right="135" data-sqft="7,488" data-type="Twin Home " data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_132" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3450.00,2006.00
     C 3458.69,2006.78 3479.64,2014.56 3489.00,2017.67
       3489.00,2017.67 3555.00,2039.00 3555.00,2039.00
       3555.00,2039.00 3535.01,2088.00 3535.01,2088.00
       3535.01,2088.00 3492.99,2188.00 3492.99,2188.00
       3492.99,2188.00 3476.00,2229.00 3476.00,2229.00
       3476.00,2229.00 3458.00,2222.68 3458.00,2222.68
       3458.00,2222.68 3399.00,2200.00 3399.00,2200.00
       3399.00,2200.00 3404.58,2177.00 3404.58,2177.00
       3404.58,2177.00 3412.58,2147.00 3412.58,2147.00
       3412.58,2147.00 3436.88,2056.00 3436.88,2056.00
       3436.88,2056.00 3450.00,2006.00 3450.00,2006.00 Z" data-front="53" data-rear="72" data-left="135" data-lot="14" data-street="Collective Lane" data-right="133" data-sqft="8,397" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_135" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3195.00,1985.00
           C 3195.00,1985.00 3205.00,1985.91 3205.00,1985.91
             3205.00,1985.91 3229.00,1987.83 3229.00,1987.83
             3229.00,1987.83 3277.00,1991.83 3277.00,1991.83
             3277.00,1991.83 3333.00,1996.17 3333.00,1996.17
             3333.00,1996.17 3449.00,2006.00 3449.00,2006.00
             3449.00,2006.00 3435.37,2060.00 3435.37,2060.00
             3435.37,2060.00 3429.28,2079.98 3429.28,2079.98
             3429.28,2079.98 3413.00,2079.98 3413.00,2079.98
             3413.00,2079.98 3391.00,2078.09 3391.00,2078.09
             3391.00,2078.09 3364.00,2076.28 3364.00,2076.28
             3364.00,2076.28 3341.00,2074.91 3341.00,2074.91
             3341.00,2074.91 3271.00,2069.91 3271.00,2069.91
             3271.00,2069.91 3214.00,2065.91 3214.00,2065.91
             3214.00,2065.91 3190.00,2064.00 3190.00,2064.00
             3190.00,2064.00 3190.91,2052.00 3190.91,2052.00
             3190.91,2052.00 3193.09,2012.00 3193.09,2012.00
             3193.09,2012.00 3195.00,1985.00 3195.00,1985.00 Z" data-front="52" data-rear="82" data-left="161" data-lot="15" data-street="Collective Lane" data-right="170" data-sqft="8,637" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_137" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3189.00,2065.00
           C 3189.00,2065.00 3232.00,2067.91 3232.00,2067.91
           3232.00,2067.91 3319.00,2074.09 3319.00,2074.09
           3319.00,2074.09 3371.00,2078.01 3371.00,2078.01
           3371.00,2078.01 3416.00,2081.42 3416.00,2081.42
           3419.16,2081.84 3428.11,2081.36 3429.34,2084.43
           3430.17,2086.51 3428.34,2091.79 3427.71,2094.00
           3427.71,2094.00 3421.88,2115.00 3421.88,2115.00
           3421.88,2115.00 3406.58,2171.00 3406.58,2171.00
           3406.58,2171.00 3399.00,2199.00 3399.00,2199.00
           3399.00,2199.00 3342.00,2189.27 3342.00,2189.27
           3342.00,2189.27 3254.00,2178.83 3254.00,2178.83
           3254.00,2178.83 3220.00,2176.00 3220.00,2176.00
           3220.00,2176.00 3204.00,2175.00 3204.00,2175.00
           3204.00,2175.00 3200.00,2175.00 3200.00,2175.00
           3200.00,2175.00 3183.00,2174.00 3183.00,2174.00
           3183.00,2174.00 3184.00,2159.00 3184.00,2159.00
           3184.00,2159.00 3185.00,2142.00 3185.00,2142.00
           3185.00,2142.00 3186.00,2127.00 3186.00,2127.00
           3186.00,2127.00 3189.00,2065.00 3189.00,2065.00 Z" data-front="73" data-rear="80" data-left="147" data-lot="16" data-street="Collective Lane" data-right="161" data-sqft="11,493" data-type="Single family" data-unit_type="Sqft" data-block="5" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_139" class="has-hover greenery" data-toggle="popover" data-trigger="hover focus" data-price="1" data-placement="left" d="M 4235.00,1533.00
           C 4235.00,1533.00 4252.00,1534.00 4252.00,1534.00
             4252.00,1534.00 4276.00,1535.00 4276.00,1535.00
             4276.00,1535.00 4286.00,1535.96 4286.00,1535.96
             4286.00,1535.96 4294.00,1535.96 4294.00,1535.96
             4294.00,1535.96 4312.00,1537.00 4312.00,1537.00
             4312.00,1537.00 4322.00,1537.96 4322.00,1537.96
             4322.00,1537.96 4330.00,1537.96 4330.00,1537.96
             4330.00,1537.96 4348.00,1539.00 4348.00,1539.00
             4348.00,1539.00 4362.00,1540.00 4362.00,1540.00
             4362.00,1540.00 4404.00,1542.00 4404.00,1542.00
             4404.00,1542.00 4417.00,1543.00 4417.00,1543.00
             4417.00,1543.00 4443.00,1544.00 4443.00,1544.00
             4443.00,1544.00 4459.00,1545.00 4459.00,1545.00
             4459.00,1545.00 4476.00,1546.00 4476.00,1546.00
             4476.00,1546.00 4503.00,1547.00 4503.00,1547.00
             4503.00,1547.00 4518.00,1548.00 4518.00,1548.00
             4518.00,1548.00 4536.00,1549.00 4536.00,1549.00
             4536.00,1549.00 4562.00,1550.00 4562.00,1550.00
             4562.00,1550.00 4577.00,1551.00 4577.00,1551.00
             4577.00,1551.00 4595.00,1552.00 4595.00,1552.00
             4595.00,1552.00 4621.00,1553.00 4621.00,1553.00
             4621.00,1553.00 4634.00,1554.00 4634.00,1554.00
             4634.00,1554.00 4661.00,1555.00 4661.00,1555.00
             4661.00,1555.00 4676.00,1556.00 4676.00,1556.00
             4676.00,1556.00 4696.00,1557.00 4696.00,1557.00
             4696.00,1557.00 4713.00,1558.00 4713.00,1558.00
             4713.00,1558.00 4739.00,1559.00 4739.00,1559.00
             4739.00,1559.00 4753.00,1560.00 4753.00,1560.00
             4753.00,1560.00 4778.00,1561.00 4778.00,1561.00
             4778.00,1561.00 4794.00,1562.00 4794.00,1562.00
             4794.00,1562.00 4794.00,1622.00 4794.00,1622.00
             4794.00,1622.00 4793.00,1639.00 4793.00,1639.00
             4793.00,1639.00 4793.00,1758.00 4793.00,1758.00
             4793.00,1758.00 4792.00,1773.00 4792.00,1773.00
             4792.00,1773.00 4792.00,1892.00 4792.00,1892.00
             4792.00,1892.00 4791.00,1907.00 4791.00,1907.00
             4791.00,1907.00 4791.00,1965.00 4791.00,1965.00
             4791.00,1965.00 4775.00,1964.00 4775.00,1964.00
             4775.00,1964.00 4752.00,1963.00 4752.00,1963.00
             4752.00,1963.00 4737.00,1962.00 4737.00,1962.00
             4737.00,1962.00 4700.00,1960.00 4700.00,1960.00
             4700.00,1960.00 4685.00,1959.00 4685.00,1959.00
             4685.00,1959.00 4662.00,1958.00 4662.00,1958.00
             4662.00,1958.00 4648.00,1957.00 4648.00,1957.00
             4648.00,1957.00 4630.00,1956.00 4630.00,1956.00
             4630.00,1956.00 4614.00,1955.00 4614.00,1955.00
             4614.00,1955.00 4592.00,1954.00 4592.00,1954.00
             4592.00,1954.00 4575.00,1953.00 4575.00,1953.00
             4575.00,1953.00 4560.00,1952.00 4560.00,1952.00
             4560.00,1952.00 4542.00,1951.00 4542.00,1951.00
             4542.00,1951.00 4519.00,1950.00 4519.00,1950.00
             4519.00,1950.00 4502.00,1949.00 4502.00,1949.00
             4502.00,1949.00 4487.00,1948.00 4487.00,1948.00
             4487.00,1948.00 4449.00,1946.00 4449.00,1946.00
             4449.00,1946.00 4433.00,1945.00 4433.00,1945.00
             4433.00,1945.00 4393.00,1943.00 4393.00,1943.00
             4393.00,1943.00 4383.00,1942.04 4383.00,1942.04
             4383.00,1942.04 4375.00,1942.04 4375.00,1942.04
             4375.00,1942.04 4357.00,1941.00 4357.00,1941.00
             4357.00,1941.00 4347.00,1940.04 4347.00,1940.04
             4347.00,1940.04 4339.00,1940.04 4339.00,1940.04
             4339.00,1940.04 4321.00,1939.00 4321.00,1939.00
             4321.00,1939.00 4307.00,1938.00 4307.00,1938.00
             4307.00,1938.00 4267.00,1936.00 4267.00,1936.00
             4267.00,1936.00 4253.00,1935.00 4253.00,1935.00
             4253.00,1935.00 4231.00,1934.00 4231.00,1934.00
             4231.00,1934.00 4216.00,1933.00 4216.00,1933.00
             4216.00,1933.00 4217.00,1916.00 4217.00,1916.00
             4217.00,1916.00 4219.00,1876.00 4219.00,1876.00
             4219.00,1876.00 4219.96,1866.00 4219.96,1866.00
             4219.96,1866.00 4219.96,1858.00 4219.96,1858.00
             4219.96,1858.00 4221.00,1840.00 4221.00,1840.00
             4221.00,1840.00 4222.00,1822.00 4222.00,1822.00
             4222.00,1822.00 4223.00,1807.00 4223.00,1807.00
             4223.00,1807.00 4225.00,1770.00 4225.00,1770.00
             4225.00,1770.00 4226.00,1755.00 4226.00,1755.00
             4226.28,1730.38 4229.79,1704.59 4232.17,1680.00
             4232.17,1680.00 4234.00,1659.00 4234.00,1659.00
             4234.00,1659.00 4234.00,1605.00 4234.00,1605.00
             4234.00,1605.00 4235.00,1588.00 4235.00,1588.00
             4235.00,1588.00 4235.00,1533.00 4235.00,1533.00 Z" data-front="201" data-rear="502" data-left="282" data-lot="20" data-street="Collective Circle" data-right="371" data-sqft="100,832" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="3" data-original-title="1"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_142" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4239.00,1131.00
           C 4239.00,1131.00 4326.00,1131.00 4326.00,1131.00
             4326.00,1131.00 4341.00,1132.00 4341.00,1132.00
             4341.00,1132.00 4516.00,1132.00 4516.00,1132.00
             4516.00,1132.00 4516.00,1148.00 4516.00,1148.00
             4516.00,1148.00 4515.00,1169.00 4515.00,1169.00
             4515.00,1169.00 4514.00,1192.00 4514.00,1192.00
             4514.00,1192.00 4513.04,1204.00 4513.04,1204.00
             4513.04,1204.00 4513.04,1216.00 4513.04,1216.00
             4513.04,1216.00 4512.00,1229.00 4512.00,1229.00
             4512.00,1229.00 4510.09,1255.00 4510.09,1255.00
             4509.78,1259.40 4509.85,1265.95 4508.07,1270.00
             4506.91,1272.64 4501.64,1279.32 4499.63,1282.00
             4497.45,1284.91 4493.82,1290.28 4490.91,1292.15
             4488.18,1293.90 4474.34,1296.07 4471.00,1295.63
             4468.30,1295.28 4463.80,1293.31 4461.00,1292.34
             4461.00,1292.34 4410.00,1274.05 4410.00,1274.05
             4410.00,1274.05 4371.00,1260.52 4371.00,1260.52
             4366.18,1259.49 4350.50,1259.01 4345.00,1259.00
             4345.00,1259.00 4333.00,1258.04 4333.00,1258.04
             4333.00,1258.04 4321.00,1258.04 4321.00,1258.04
             4321.00,1258.04 4307.00,1257.00 4307.00,1257.00
             4307.00,1257.00 4255.00,1255.00 4255.00,1255.00
             4255.00,1255.00 4236.00,1254.00 4236.00,1254.00
             4236.00,1254.00 4236.00,1169.00 4236.00,1169.00
             4236.00,1169.00 4239.00,1131.00 4239.00,1131.00 Z" data-front="79" data-rear="88" data-left="187" data-lot="1" data-street="Sonora Way" data-right="187" data-sqft="16,631" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_144" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4235.00,1061.00
           C 4235.00,1061.00 4522.00,1061.00 4522.00,1061.00
             4522.00,1061.00 4521.00,1080.00 4521.00,1080.00
             4521.00,1080.00 4520.00,1094.00 4520.00,1094.00
             4520.00,1094.00 4518.00,1133.00 4518.00,1133.00
             4518.00,1133.00 4235.00,1133.00 4235.00,1133.00
             4235.00,1133.00 4235.00,1061.00 4235.00,1061.00 Z" data-front="47" data-rear="47" data-left="187" data-lot="2" data-street="Sonora Way" data-right="190" data-sqft="8,931" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_146" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4235.00,1062.00
           C 4235.00,1062.00 4235.00,1002.00 4235.00,1002.00
             4235.02,993.36 4235.36,993.02 4244.00,993.00
             4244.00,993.00 4422.00,993.00 4422.00,993.00
             4422.00,993.00 4437.00,992.00 4437.00,992.00
             4437.00,992.00 4526.00,992.00 4526.00,992.00
             4526.00,992.00 4525.00,1011.00 4525.00,1011.00
             4525.00,1011.00 4523.00,1051.00 4523.00,1051.00
             4522.98,1059.64 4522.64,1059.98 4514.00,1060.00
             4514.00,1060.00 4415.00,1060.00 4415.00,1060.00
             4415.00,1060.00 4400.00,1061.00 4400.00,1061.00
             4400.00,1061.00 4300.00,1061.00 4300.00,1061.00
             4300.00,1061.00 4285.00,1062.00 4285.00,1062.00
             4285.00,1062.00 4235.00,1062.00 4235.00,1062.00 Z" data-front="47" data-rear="47" data-left="190" data-lot="3" data-street="Sonora Way" data-right="193" data-sqft="9,075" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_148" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4235.00,917.00
           C 4235.00,917.00 4272.00,917.00 4272.00,917.00
             4272.00,917.00 4289.00,918.00 4289.00,918.00
             4289.00,918.00 4353.00,918.00 4353.00,918.00
             4353.00,918.00 4370.00,919.00 4370.00,919.00
             4370.00,919.00 4439.00,919.00 4439.00,919.00
             4439.00,919.00 4454.00,920.00 4454.00,920.00
             4454.00,920.00 4529.00,920.00 4529.00,920.00
             4529.00,920.00 4527.00,993.00 4527.00,993.00
             4527.00,993.00 4235.00,993.00 4235.00,993.00
             4235.00,993.00 4235.00,917.00 4235.00,917.00 Z" data-front="50" data-rear="45" data-left="193" data-lot="4" data-street="Sonora Way" data-right="196" data-sqft="9,294" data-type="Twin Home" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_150" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4244.00,834.00
           C 4244.00,834.00 4280.00,837.84 4280.00,837.84
             4280.00,837.84 4354.00,845.83 4354.00,845.83
             4354.00,845.83 4457.00,856.83 4457.00,856.83
             4457.00,856.83 4533.00,865.00 4533.00,865.00
             4533.00,865.00 4532.00,877.00 4532.00,877.00
             4532.00,877.00 4532.00,886.00 4532.00,886.00
             4532.00,886.00 4530.00,921.00 4530.00,921.00
             4530.00,921.00 4513.00,921.00 4513.00,921.00
             4513.00,921.00 4497.00,920.00 4497.00,920.00
             4497.00,920.00 4467.00,920.00 4467.00,920.00
             4467.00,920.00 4450.00,919.00 4450.00,919.00
             4450.00,919.00 4374.00,919.00 4374.00,919.00
             4374.00,919.00 4359.00,918.00 4359.00,918.00
             4359.00,918.00 4235.00,918.00 4235.00,918.00
             4235.00,918.00 4236.83,901.00 4236.83,901.00
             4236.83,901.00 4244.00,834.00 4244.00,834.00 Z" data-front="57" data-rear="38" data-left="196" data-lot="5" data-street="Sonora Way" data-right="195" data-sqft="9,447" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_152" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4252.00,760.00
           C 4252.00,760.00 4322.00,767.72 4322.00,767.72
             4322.00,767.72 4378.00,774.28 4378.00,774.28
             4378.00,774.28 4469.00,785.16 4469.00,785.16
             4469.00,785.16 4537.00,793.00 4537.00,793.00
             4537.00,793.00 4535.00,829.00 4535.00,829.00
             4535.00,829.00 4535.00,838.00 4535.00,838.00
             4535.00,838.00 4533.00,864.00 4533.00,864.00
             4533.00,864.00 4515.00,862.17 4515.00,862.17
             4515.00,862.17 4463.00,857.17 4463.00,857.17
             4463.00,857.17 4297.00,839.83 4297.00,839.83
             4297.00,839.83 4244.00,834.00 4244.00,834.00
             4244.00,834.00 4252.00,760.00 4252.00,760.00 Z" data-front="47" data-rear="47" data-left="195" data-lot="6" data-street="Sonora Way" data-right="192" data-sqft="9,179" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_154" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4260.00,690.00
           C 4260.00,690.00 4330.00,697.84 4330.00,697.84
             4330.00,697.84 4483.00,716.16 4483.00,716.16
             4483.00,716.16 4541.00,723.00 4541.00,723.00
             4541.00,723.00 4540.00,746.00 4540.00,746.00
             4540.00,746.00 4538.00,793.00 4538.00,793.00
             4538.00,793.00 4499.00,789.16 4499.00,789.16
             4499.00,789.16 4437.00,782.16 4437.00,782.16
             4437.00,782.16 4346.00,771.28 4346.00,771.28
             4346.00,771.28 4252.00,760.00 4252.00,760.00
             4252.00,760.00 4260.00,690.00 4260.00,690.00 Z" data-front="47" data-rear="47" data-left="192" data-lot="7" data-street="Sonora Way" data-right="189" data-sqft="9,043" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_156" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4268.00,618.00
           C 4268.00,618.00 4283.00,619.85 4283.00,619.85
             4283.00,619.85 4338.00,626.85 4338.00,626.85
             4338.00,626.85 4475.00,644.28 4475.00,644.28
             4475.00,644.28 4521.00,650.15 4521.00,650.15
             4521.00,650.15 4546.00,653.00 4546.00,653.00
             4546.00,653.00 4545.00,673.00 4545.00,673.00
             4545.00,673.00 4544.00,690.00 4544.00,690.00
             4544.00,690.00 4543.00,709.00 4543.00,709.00
             4543.00,709.00 4543.00,723.00 4543.00,723.00
             4543.00,723.00 4474.00,715.28 4474.00,715.28
             4474.00,715.28 4410.00,707.72 4410.00,707.72
             4410.00,707.72 4328.00,697.84 4328.00,697.84
             4328.00,697.84 4260.00,690.00 4260.00,690.00
             4260.00,690.00 4266.15,636.00 4266.15,636.00
             4266.15,636.00 4268.00,618.00 4268.00,618.00 Z" data-front="47" data-rear="47" data-left="189" data-lot="8" data-street="Sonora Way" data-right="187" data-sqft="8,907" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_158" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4275.00,550.00
           C 4275.00,550.00 4291.00,551.84 4291.00,551.84
             4291.00,551.84 4350.00,558.72 4350.00,558.72
             4350.00,558.72 4416.00,566.16 4416.00,566.16
             4416.00,566.16 4486.00,574.17 4486.00,574.17
             4486.00,574.17 4549.00,581.00 4549.00,581.00
             4549.00,581.00 4549.00,595.00 4549.00,595.00
             4549.00,595.00 4548.00,615.00 4548.00,615.00
             4548.00,615.00 4547.00,632.00 4547.00,632.00
             4547.00,632.00 4546.00,653.00 4546.00,653.00
             4546.00,653.00 4488.00,646.16 4488.00,646.16
             4488.00,646.16 4432.00,639.15 4432.00,639.15
             4432.00,639.15 4340.00,627.72 4340.00,627.72
             4340.00,627.72 4267.00,619.00 4267.00,619.00
             4267.00,619.00 4275.00,550.00 4275.00,550.00 Z" data-front="47" data-rear="47" data-left="187" data-lot="9" data-street="Sonora Way" data-right="184" data-sqft="8,771" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_161" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4276.00,550.00
           C 4276.00,550.00 4278.83,523.00 4278.83,523.00
             4278.83,523.00 4283.16,480.00 4283.16,480.00
             4283.16,480.00 4284.84,465.00 4284.84,465.00
             4284.84,465.00 4286.60,459.02 4286.60,459.02
             4286.60,459.02 4294.00,458.00 4294.00,458.00
             4294.00,458.00 4395.00,458.00 4395.00,458.00
             4395.00,458.00 4410.00,457.00 4410.00,457.00
             4410.00,457.00 4484.00,457.00 4484.00,457.00
             4484.00,457.00 4499.00,456.00 4499.00,456.00
             4499.00,456.00 4558.00,456.00 4558.00,456.00
             4558.00,456.00 4557.00,472.00 4557.00,472.00
             4557.00,472.00 4556.00,495.00 4556.00,495.00
             4556.00,495.00 4555.00,513.00 4555.00,513.00
             4555.00,513.00 4554.00,529.00 4554.00,529.00
             4554.00,529.00 4553.00,545.00 4553.00,545.00
             4553.00,545.00 4552.00,567.00 4552.00,567.00
             4552.00,567.00 4551.00,581.00 4551.00,581.00
             4551.00,581.00 4510.00,577.17 4510.00,577.17
             4510.00,577.17 4437.00,569.28 4437.00,569.28
             4437.00,569.28 4276.00,550.00 4276.00,550.00 Z" data-front="61" data-rear="83" data-left="184" data-lot="10" data-street="Sonora Way" data-right="180" data-sqft="13,176" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_163" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4558.00,456.00
     C 4558.00,456.00 4610.00,456.00 4610.00,456.00
       4610.00,456.00 4627.00,455.00 4627.00,455.00
       4627.00,455.00 4734.00,455.00 4734.00,455.00
       4734.00,455.00 4749.00,454.00 4749.00,454.00
       4749.00,454.00 4802.96,454.00 4802.96,454.00
       4802.96,454.00 4802.96,469.00 4802.96,469.00
       4802.96,469.00 4802.00,481.00 4802.00,481.00
       4802.00,481.00 4802.00,572.00 4802.00,572.00
       4802.00,572.00 4801.00,590.00 4801.00,590.00
       4801.00,590.00 4799.98,597.44 4799.98,597.44
       4799.98,597.44 4786.00,602.58 4786.00,602.58
       4786.00,602.58 4744.00,614.42 4744.00,614.42
       4744.00,614.42 4607.00,653.15 4607.00,653.15
       4607.00,653.15 4569.00,664.00 4569.00,664.00
       4569.00,664.00 4545.00,670.00 4545.00,670.00
       4545.00,670.00 4545.96,652.00 4545.96,652.00
       4545.96,652.00 4548.91,605.00 4548.91,605.00
       4548.91,605.00 4550.09,580.00 4550.09,580.00
       4550.09,580.00 4555.00,508.00 4555.00,508.00
       4555.00,508.00 4555.00,499.00 4555.00,499.00
       4555.00,499.00 4557.00,476.00 4557.00,476.00
       4557.00,476.00 4557.00,467.00 4557.00,467.00
       4557.00,467.00 4558.00,456.00 4558.00,456.00 Z" data-front="95" data-rear="143" data-left="163" data-lot="11" data-street="Collective Circle" data-right="177" data-sqft="19,827" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_165" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4801.00,597.00
           C 4802.76,601.43 4802.00,610.90 4802.00,616.00
             4802.00,616.00 4802.00,655.00 4802.00,655.00
             4802.00,655.00 4801.00,670.00 4801.00,670.00
             4801.00,670.00 4801.00,728.00 4801.00,728.00
             4801.00,736.53 4801.92,760.23 4800.00,767.00
             4800.00,767.00 4770.00,764.91 4770.00,764.91
             4770.00,764.91 4716.00,761.09 4716.00,761.09
             4716.00,761.09 4691.00,759.91 4691.00,759.91
             4691.00,759.91 4622.00,755.09 4622.00,755.09
             4622.00,755.09 4597.00,753.91 4597.00,753.91
             4597.00,753.91 4551.00,750.96 4551.00,750.96
             4551.00,750.96 4539.00,750.00 4539.00,750.00
             4539.00,750.00 4540.17,736.00 4540.17,736.00
             4540.17,736.00 4543.72,692.00 4543.72,692.00
             4543.72,692.00 4546.02,671.56 4546.02,671.56
             4546.02,671.56 4564.00,665.29 4564.00,665.29
             4564.00,665.29 4614.00,650.86 4614.00,650.86
             4614.00,650.86 4738.00,615.42 4738.00,615.42
             4738.00,615.42 4801.00,597.00 4801.00,597.00 Z" data-front="112" data-rear="53" data-left="177" data-lot="12" data-street="Collective Circle" data-right="173" data-sqft="14,163" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_167" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4539.00,750.00
           C 4539.00,750.00 4551.00,750.91 4551.00,750.91
             4551.00,750.91 4581.00,753.00 4581.00,753.00
             4581.00,753.00 4590.00,753.00 4590.00,753.00
             4590.00,753.00 4611.00,755.00 4611.00,755.00
             4611.00,755.00 4620.00,755.00 4620.00,755.00
             4620.00,755.00 4680.00,759.09 4680.00,759.09
             4680.00,759.09 4726.00,762.09 4726.00,762.09
             4726.00,762.09 4738.00,763.01 4738.00,763.01
             4738.00,763.01 4747.00,763.91 4747.00,763.91
             4747.00,763.91 4786.00,766.09 4786.00,766.09
             4786.00,766.09 4793.00,766.91 4793.00,766.91
             4800.22,767.46 4800.95,767.10 4801.00,775.00
             4801.00,775.00 4801.00,792.00 4801.00,792.00
             4801.00,792.00 4800.00,805.00 4800.00,805.00
             4800.00,805.00 4800.00,885.00 4800.00,885.00
             4800.00,885.00 4745.00,883.00 4745.00,883.00
             4745.00,883.00 4729.00,882.00 4729.00,882.00
             4729.00,882.00 4709.00,880.96 4709.00,880.96
             4709.00,880.96 4696.00,880.96 4696.00,880.96
             4696.00,880.96 4685.00,880.00 4685.00,880.00
             4685.00,880.00 4662.00,879.00 4662.00,879.00
             4662.00,879.00 4640.00,878.00 4640.00,878.00
             4640.00,878.00 4619.00,877.00 4619.00,877.00
             4619.00,877.00 4590.00,876.00 4590.00,876.00
             4590.00,876.00 4574.00,875.00 4574.00,875.00
             4574.00,875.00 4553.00,874.00 4553.00,874.00
             4553.00,874.00 4535.00,873.00 4535.00,873.00
             4535.00,873.00 4536.00,825.00 4536.00,825.00
             4536.00,825.00 4537.00,808.00 4537.00,808.00
             4537.00,808.00 4537.00,793.00 4537.00,793.00
             4537.00,793.00 4538.00,777.00 4538.00,777.00
             4538.00,777.00 4539.00,750.00 4539.00,750.00 Z" data-front="80" data-rear="80" data-left="173" data-lot="13" data-street="Collective Circle" data-right="177" data-sqft="14,050" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_168" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4533.00,872.00
           C 4533.00,872.00 4574.00,874.00 4574.00,874.00
             4574.00,874.00 4589.00,875.00 4589.00,875.00
             4589.00,875.00 4607.00,876.00 4607.00,876.00
             4607.00,876.00 4629.00,877.00 4629.00,877.00
             4629.00,877.00 4646.00,878.00 4646.00,878.00
             4646.00,878.00 4671.00,879.00 4671.00,879.00
             4671.00,879.00 4681.00,879.96 4681.00,879.96
             4681.00,879.96 4690.00,879.96 4690.00,879.96
             4690.00,879.96 4709.00,881.00 4709.00,881.00
             4709.00,881.00 4719.00,882.00 4719.00,882.00
             4719.00,882.00 4730.00,882.00 4730.00,882.00
             4730.00,882.00 4745.00,883.00 4745.00,883.00
             4745.00,883.00 4761.00,884.00 4761.00,884.00
             4761.00,884.00 4785.00,885.00 4785.00,885.00
             4785.00,885.00 4800.00,886.00 4800.00,886.00
             4800.00,886.00 4800.00,923.00 4800.00,923.00
             4800.00,923.00 4799.00,940.00 4799.00,940.00
             4799.00,940.00 4799.00,1006.00 4799.00,1006.00
             4799.00,1006.00 4781.00,1005.00 4781.00,1005.00
             4781.00,1005.00 4753.00,1004.00 4753.00,1004.00
             4753.00,1004.00 4736.00,1003.00 4736.00,1003.00
             4736.00,1003.00 4716.00,1001.96 4716.00,1001.96
             4716.00,1001.96 4705.00,1001.96 4705.00,1001.96
             4705.00,1001.96 4694.00,1001.00 4694.00,1001.00
             4694.00,1001.00 4672.00,1000.00 4672.00,1000.00
             4672.00,1000.00 4650.00,999.00 4650.00,999.00
             4650.00,999.00 4628.00,998.00 4628.00,998.00
             4628.00,998.00 4581.00,996.00 4581.00,996.00
             4581.00,996.00 4564.00,995.00 4564.00,995.00
             4564.00,995.00 4544.00,994.00 4544.00,994.00
             4544.00,994.00 4526.00,993.00 4526.00,993.00
             4526.00,993.00 4527.00,973.00 4527.00,973.00
             4527.00,973.00 4528.00,958.00 4528.00,958.00
             4528.00,958.00 4530.00,917.00 4530.00,917.00
             4530.00,917.00 4531.00,904.00 4531.00,904.00
             4531.00,904.00 4533.00,872.00 4533.00,872.00 Z" data-front="80" data-rear="80" data-left="177" data-lot="14" data-street="Collective Circle" data-right="181" data-sqft="14,382" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_169" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4526.00,994.00
           C 4526.00,994.00 4571.00,996.00 4571.00,996.00
             4571.00,996.00 4587.00,997.00 4587.00,997.00
             4587.00,997.00 4608.00,998.00 4608.00,998.00
             4608.00,998.00 4627.00,999.00 4627.00,999.00
             4627.00,999.00 4655.00,1000.00 4655.00,1000.00
             4655.00,1000.00 4668.00,1001.00 4668.00,1001.00
             4668.00,1001.00 4696.00,1002.00 4696.00,1002.00
             4696.00,1002.00 4709.00,1003.00 4709.00,1003.00
             4709.00,1003.00 4735.00,1004.00 4735.00,1004.00
             4735.00,1004.00 4751.00,1005.00 4751.00,1005.00
             4751.00,1005.00 4800.00,1007.00 4800.00,1007.00
             4800.00,1007.00 4800.00,1029.00 4800.00,1029.00
             4800.00,1029.00 4799.00,1045.00 4799.00,1045.00
             4799.00,1045.00 4799.00,1086.00 4799.00,1086.00
             4799.00,1086.00 4798.00,1101.00 4798.00,1101.00
             4798.00,1101.00 4798.00,1123.00 4798.00,1123.00
             4798.00,1123.00 4778.00,1122.00 4778.00,1122.00
             4778.00,1122.00 4766.00,1121.04 4766.00,1121.04
             4766.00,1121.04 4753.00,1121.04 4753.00,1121.04
             4753.00,1121.04 4741.00,1120.04 4741.00,1120.04
             4741.00,1120.04 4727.00,1120.04 4727.00,1120.04
             4727.00,1120.04 4711.00,1118.96 4711.00,1118.96
             4711.00,1118.96 4699.00,1118.96 4699.00,1118.96
             4699.00,1118.96 4687.00,1118.00 4687.00,1118.00
             4687.00,1118.00 4653.00,1117.00 4653.00,1117.00
             4653.00,1117.00 4637.00,1116.00 4637.00,1116.00
             4637.00,1116.00 4580.00,1114.00 4580.00,1114.00
             4580.00,1114.00 4565.00,1112.96 4565.00,1112.96
             4565.00,1112.96 4551.00,1112.96 4551.00,1112.96
             4551.00,1112.96 4540.00,1112.00 4540.00,1112.00
             4540.00,1112.00 4519.00,1111.00 4519.00,1111.00
             4519.00,1111.00 4521.00,1064.00 4521.00,1064.00
             4521.00,1064.00 4522.83,1041.00 4522.83,1041.00
             4522.83,1041.00 4524.09,1018.00 4524.09,1018.00
             4524.09,1018.00 4526.00,994.00 4526.00,994.00 Z" data-front="80" data-rear="80.41" data-left="181" data-lot="15" data-street="Collective Lane" data-right="186" data-sqft="14,792" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_170" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4519.00,1111.00
     C 4519.00,1111.00 4539.00,1112.00 4539.00,1112.00
       4539.00,1112.00 4562.00,1113.00 4562.00,1113.00
       4562.00,1113.00 4574.00,1113.96 4574.00,1113.96
       4574.00,1113.96 4587.00,1113.96 4587.00,1113.96
       4587.00,1113.96 4603.00,1115.00 4603.00,1115.00
       4603.00,1115.00 4625.00,1116.04 4625.00,1116.04
       4625.00,1116.04 4637.00,1116.04 4637.00,1116.04
       4637.00,1116.04 4649.00,1117.00 4649.00,1117.00
       4649.00,1117.00 4680.00,1118.00 4680.00,1118.00
       4680.00,1118.00 4695.00,1119.00 4695.00,1119.00
       4695.00,1119.00 4724.00,1120.00 4724.00,1120.00
       4724.00,1120.00 4741.00,1121.00 4741.00,1121.00
       4741.00,1121.00 4797.00,1123.00 4797.00,1123.00
       4797.00,1123.00 4797.00,1208.00 4797.00,1208.00
       4797.00,1216.63 4797.90,1242.32 4796.00,1249.00
       4796.00,1249.00 4751.00,1245.91 4751.00,1245.91
       4751.00,1245.91 4675.00,1240.91 4675.00,1240.91
       4675.00,1240.91 4568.00,1233.91 4568.00,1233.91
       4568.00,1233.91 4522.00,1230.91 4522.00,1230.91
       4522.00,1230.91 4511.00,1230.00 4511.00,1230.00
       4511.00,1230.00 4513.09,1198.00 4513.09,1198.00
       4513.09,1198.00 4516.91,1142.00 4516.91,1142.00
       4516.91,1142.00 4519.00,1111.00 4519.00,1111.00 Z" data-front="80" data-rear="80" data-left="186" data-lot="16" data-street="Collective Circle" data-right="190" data-sqft="15,049" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_171" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4512.00,1230.00
     C 4512.00,1230.00 4541.00,1232.00 4541.00,1232.00
       4541.00,1232.00 4550.00,1232.00 4550.00,1232.00
       4550.00,1232.00 4589.00,1234.91 4589.00,1234.91
       4589.00,1234.91 4700.00,1241.91 4700.00,1241.91
       4700.00,1241.91 4725.00,1243.09 4725.00,1243.09
       4725.00,1243.09 4749.00,1245.00 4749.00,1245.00
       4749.00,1245.00 4772.00,1246.09 4772.00,1246.09
       4772.00,1246.09 4798.00,1248.00 4798.00,1248.00
       4798.00,1248.00 4798.00,1287.00 4798.00,1287.00
       4798.00,1287.00 4797.00,1304.00 4797.00,1304.00
       4797.00,1304.00 4797.00,1384.00 4797.00,1384.00
       4797.00,1384.00 4796.00,1399.00 4796.00,1399.00
       4796.00,1399.00 4796.00,1478.00 4796.00,1478.00
       4796.00,1478.00 4795.00,1493.00 4795.00,1493.00
       4795.00,1493.00 4795.00,1561.00 4795.00,1561.00
       4788.26,1557.81 4778.75,1546.63 4773.00,1541.01
       4773.00,1541.01 4755.00,1524.00 4755.00,1524.00
       4755.00,1524.00 4729.00,1498.28 4729.00,1498.28
       4729.00,1498.28 4693.00,1463.04 4693.00,1463.04
       4693.00,1463.04 4683.00,1453.99 4683.00,1453.99
       4683.00,1453.99 4653.00,1424.04 4653.00,1424.04
       4653.00,1424.04 4643.00,1414.99 4643.00,1414.99
       4643.00,1414.99 4614.00,1386.04 4614.00,1386.04
       4614.00,1386.04 4604.00,1376.99 4604.00,1376.99
       4604.00,1376.99 4575.00,1348.04 4575.00,1348.04
       4575.00,1348.04 4565.00,1338.99 4565.00,1338.99
       4565.00,1338.99 4535.00,1309.04 4535.00,1309.04
       4535.00,1309.04 4525.00,1299.99 4525.00,1299.99
       4521.44,1296.51 4507.77,1283.77 4506.69,1280.00
       4505.68,1276.48 4509.26,1269.83 4510.24,1266.00
       4511.43,1261.35 4510.94,1254.02 4511.00,1249.00
       4511.00,1249.00 4512.00,1230.00 4512.00,1230.00 Z" data-front="38" data-rear="260" data-left="190" data-lot="17" data-street="Collective Circle" data-right="266" data-sqft="23,682" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_172" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4415.00,1277.00
     C 4415.00,1277.00 4455.00,1289.08 4455.00,1289.08
       4466.18,1292.92 4469.56,1297.25 4482.00,1294.63
       4497.21,1291.43 4496.69,1286.98 4506.00,1280.00
       4506.00,1280.00 4565.00,1337.04 4565.00,1337.04
       4565.00,1337.04 4590.00,1361.96 4590.00,1361.96
       4590.00,1361.96 4600.00,1371.01 4600.00,1371.01
       4600.00,1371.01 4657.00,1426.96 4657.00,1426.96
       4657.00,1426.96 4666.00,1435.04 4666.00,1435.04
       4666.00,1435.04 4691.00,1459.91 4691.00,1459.91
       4691.00,1459.91 4757.00,1523.96 4757.00,1523.96
       4766.64,1533.18 4786.84,1551.00 4794.00,1561.00
       4794.00,1561.00 4764.00,1560.00 4764.00,1560.00
       4764.00,1560.00 4749.00,1559.00 4749.00,1559.00
       4749.00,1559.00 4728.00,1558.00 4728.00,1558.00
       4728.00,1558.00 4710.00,1557.00 4710.00,1557.00
       4710.00,1557.00 4682.00,1556.00 4682.00,1556.00
       4682.00,1556.00 4667.00,1555.00 4667.00,1555.00
       4667.00,1555.00 4649.00,1554.00 4649.00,1554.00
       4649.00,1554.00 4621.00,1553.00 4621.00,1553.00
       4621.00,1553.00 4608.00,1552.00 4608.00,1552.00
       4608.00,1552.00 4580.00,1551.00 4580.00,1551.00
       4580.00,1551.00 4567.00,1550.00 4567.00,1550.00
       4567.00,1550.00 4539.00,1549.00 4539.00,1549.00
       4539.00,1549.00 4521.00,1548.00 4521.00,1548.00
       4521.00,1548.00 4504.00,1547.00 4504.00,1547.00
       4504.00,1547.00 4485.00,1546.00 4485.00,1546.00
       4485.00,1546.00 4457.00,1545.00 4457.00,1545.00
       4457.00,1545.00 4443.00,1544.00 4443.00,1544.00
       4443.00,1544.00 4417.00,1543.00 4417.00,1543.00
       4417.00,1543.00 4400.00,1542.00 4400.00,1542.00
       4400.00,1542.00 4401.00,1525.00 4401.00,1525.00
       4401.00,1525.00 4403.00,1485.00 4403.00,1485.00
       4403.00,1485.00 4403.96,1475.00 4403.96,1475.00
       4403.96,1475.00 4403.96,1466.00 4403.96,1466.00
       4403.96,1466.00 4405.00,1448.00 4405.00,1448.00
       4405.00,1448.00 4406.00,1430.00 4406.00,1430.00
       4406.00,1430.00 4407.00,1413.00 4407.00,1413.00
       4407.00,1413.00 4408.00,1398.00 4408.00,1398.00
       4408.00,1398.00 4410.00,1359.00 4410.00,1359.00
       4410.00,1359.00 4411.00,1344.00 4411.00,1344.00
       4411.00,1344.00 4413.00,1305.00 4413.00,1305.00
       4413.00,1305.00 4415.00,1277.00 4415.00,1277.00 Z" data-front="77" data-rear="170" data-left="266" data-lot="18" data-street="Collective Circle" data-right="170" data-sqft="24,055" data-type="Single family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_173" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4236.00,1255.00
     C 4236.00,1255.00 4250.00,1255.00 4250.00,1255.00
       4250.00,1255.00 4266.00,1256.00 4266.00,1256.00
       4266.00,1256.00 4328.00,1256.00 4328.00,1256.00
       4342.79,1256.02 4370.72,1260.80 4385.00,1265.04
       4385.00,1265.04 4415.00,1275.00 4415.00,1275.00
       4413.93,1283.13 4414.09,1281.92 4414.00,1290.00
       4414.00,1290.00 4413.00,1304.00 4413.00,1304.00
       4413.00,1304.00 4412.00,1324.00 4412.00,1324.00
       4412.00,1324.00 4411.00,1338.00 4411.00,1338.00
       4411.00,1338.00 4410.00,1356.00 4410.00,1356.00
       4410.00,1356.00 4408.00,1394.00 4408.00,1394.00
       4408.00,1394.00 4407.00,1404.00 4407.00,1404.00
       4407.00,1404.00 4407.00,1414.00 4407.00,1414.00
       4407.00,1414.00 4406.00,1428.00 4406.00,1428.00
       4406.00,1428.00 4405.00,1445.00 4405.00,1445.00
       4405.00,1445.00 4404.00,1460.00 4404.00,1460.00
       4404.00,1460.00 4402.00,1498.00 4402.00,1498.00
       4402.00,1498.00 4401.00,1508.00 4401.00,1508.00
       4401.00,1508.00 4401.00,1518.00 4401.00,1518.00
       4401.00,1518.00 4400.00,1531.00 4400.00,1531.00
       4400.00,1533.50 4400.38,1538.30 4398.26,1540.01
       4396.95,1541.07 4394.59,1540.97 4393.00,1541.00
       4393.00,1541.00 4375.00,1540.00 4375.00,1540.00
       4375.00,1540.00 4371.00,1540.00 4371.00,1540.00
       4371.00,1540.00 4358.00,1539.00 4358.00,1539.00
       4358.00,1539.00 4317.00,1537.00 4317.00,1537.00
       4317.00,1537.00 4302.00,1536.00 4302.00,1536.00
       4302.00,1536.00 4278.00,1535.00 4278.00,1535.00
       4278.00,1535.00 4263.00,1534.00 4263.00,1534.00
       4263.00,1534.00 4237.00,1533.00 4237.00,1533.00
       4237.00,1533.00 4237.00,1475.00 4237.00,1475.00
       4237.00,1475.00 4236.00,1458.00 4236.00,1458.00
       4236.00,1458.00 4236.00,1255.00 4236.00,1255.00 Z" data-front="146" data-rear="132" data-left="170" data-lot="19" data-street="Collective Circle" data-right="185" data-sqft="25,313" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_174" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="54,900" data-placement="left" d="M 4218.00,1933.00
     C 4218.00,1933.00 4246.00,1935.00 4246.00,1935.00
       4246.00,1935.00 4283.00,1937.00 4283.00,1937.00
       4283.00,1937.00 4301.00,1938.00 4301.00,1938.00
       4301.00,1938.00 4332.00,1940.00 4332.00,1940.00
       4332.00,1940.00 4345.00,1941.00 4345.00,1941.00
       4345.00,1941.00 4354.00,1941.00 4354.00,1941.00
       4354.00,1941.00 4364.00,1942.00 4364.00,1942.00
       4364.00,1942.00 4375.43,1943.17 4375.43,1943.17
       4375.43,1943.17 4377.00,1950.00 4377.00,1950.00
       4377.00,1950.00 4376.00,1969.00 4376.00,1969.00
       4376.00,1969.00 4375.00,1986.00 4375.00,1986.00
       4375.00,1986.00 4374.00,2000.00 4374.00,2000.00
       4374.00,2000.00 4372.00,2039.00 4372.00,2039.00
       4372.00,2039.00 4371.00,2052.00 4371.00,2052.00
       4371.00,2052.00 4369.00,2083.00 4369.00,2083.00
       4369.00,2083.00 4310.00,2078.91 4310.00,2078.91
       4310.00,2078.91 4234.00,2073.91 4234.00,2073.91
       4234.00,2073.91 4209.00,2072.00 4209.00,2072.00
       4209.00,2072.00 4210.91,2039.00 4210.91,2039.00
       4210.91,2039.00 4212.09,2014.00 4212.09,2014.00
       4212.09,2014.00 4213.96,1990.00 4213.96,1990.00
       4213.96,1990.00 4215.00,1968.00 4215.00,1968.00
       4215.00,1968.00 4215.96,1957.00 4215.96,1957.00
       4215.96,1957.00 4218.00,1933.00 4218.00,1933.00 Z" data-front="94" data-rear="94" data-left="187" data-lot="21" data-street="Sonora Way" data-right="187" data-sqft="17,644" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_175" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="54,900" data-placement="left" d="M 4205.00,2122.00
        C 4205.00,2122.00 4207.91,2086.00 4207.91,2086.00
        4208.40,2078.84 4206.05,2073.33 4214.02,2073.02
        4214.02,2073.02 4229.00,2074.00 4229.00,2074.00
        4229.00,2074.00 4259.00,2076.00 4259.00,2076.00
        4259.00,2076.00 4268.00,2076.00 4268.00,2076.00
        4268.00,2076.00 4307.00,2078.91 4307.00,2078.91
        4307.00,2078.91 4369.00,2083.00 4369.00,2083.00
        4369.00,2083.00 4368.00,2099.00 4368.00,2099.00
        4368.00,2099.00 4367.00,2116.00 4367.00,2116.00
        4367.00,2116.00 4366.00,2133.00 4366.00,2133.00
        4366.00,2133.00 4334.00,2131.09 4334.00,2131.09
        4334.00,2131.09 4259.00,2126.09 4259.00,2126.09
        4259.00,2126.09 4236.00,2124.91 4236.00,2124.91
        4236.00,2124.91 4228.00,2124.04 4228.00,2124.04
        4228.00,2124.04 4205.00,2122.00 4205.00,2122.00 Z" data-front="41" data-rear="41" data-left="187" data-lot="22" data-street="Sonora Way" data-right="187" data-sqft="7,696" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_176" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="54,900" data-placement="left" d="M 4205.00,2123.00
         C 4205.00,2123.00 4250.00,2125.91 4250.00,2125.91
         4250.00,2125.91 4328.00,2131.09 4328.00,2131.09
         4328.00,2131.09 4366.00,2134.00 4366.00,2134.00
         4366.00,2134.00 4364.00,2171.00 4364.00,2171.00
         4364.00,2171.00 4363.00,2187.00 4363.00,2187.00
         4363.00,2187.00 4362.00,2207.00 4362.00,2207.00
         4362.00,2207.00 4346.00,2205.91 4346.00,2205.91
         4346.00,2205.91 4323.00,2204.00 4323.00,2204.00
         4323.00,2204.00 4314.00,2204.00 4314.00,2204.00
         4314.00,2204.00 4275.00,2201.00 4275.00,2201.00
         4275.00,2201.00 4266.00,2201.00 4266.00,2201.00
         4266.00,2201.00 4242.00,2199.04 4242.00,2199.04
         4242.00,2199.04 4212.00,2197.17 4212.00,2197.17
         4212.00,2197.17 4202.06,2195.40 4202.06,2195.40
         4202.06,2195.40 4202.06,2181.00 4202.06,2181.00
         4202.06,2181.00 4203.00,2167.00 4203.00,2167.00
         4203.00,2167.00 4205.00,2123.00 4205.00,2123.00 Z" data-front="41" data-rear="41" data-left="187" data-lot="23" data-street="Sonora Way" data-right="187" data-sqft="7,696" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_177" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="54,900" data-placement="left" d="M 4202.00,2197.00
         C 4202.00,2197.00 4227.00,2199.00 4227.00,2199.00
         4227.00,2199.00 4241.00,2199.91 4241.00,2199.91
         4241.00,2199.91 4274.00,2201.91 4274.00,2201.91
         4274.00,2201.91 4321.00,2204.96 4321.00,2204.96
         4321.00,2204.96 4331.00,2204.96 4331.00,2204.96
         4331.00,2204.96 4352.00,2206.83 4352.00,2206.83
         4352.00,2206.83 4361.94,2208.60 4361.94,2208.60
         4361.94,2208.60 4361.94,2224.00 4361.94,2224.00
         4361.94,2224.00 4361.00,2239.00 4361.00,2239.00
         4361.00,2239.00 4360.00,2256.00 4360.00,2256.00
         4360.00,2256.00 4359.00,2283.00 4359.00,2283.00
         4359.00,2283.00 4325.00,2281.09 4325.00,2281.09
         4325.00,2281.09 4284.00,2278.91 4284.00,2278.91
         4284.00,2278.91 4254.00,2277.00 4254.00,2277.00
         4254.00,2277.00 4228.00,2275.09 4228.00,2275.09
         4228.00,2275.09 4197.00,2273.00 4197.00,2273.00
         4197.00,2273.00 4198.00,2257.00 4198.00,2257.00
         4198.00,2257.00 4199.00,2240.00 4199.00,2240.00
         4199.00,2240.00 4200.00,2220.00 4200.00,2220.00
         4200.00,2220.00 4202.00,2197.00 4202.00,2197.00 Z" data-front="51" data-rear="51" data-left="187" data-lot="24" data-street="Sonora Way" data-right="187" data-sqft="9,573" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_178" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 4362.00,2207.00
         C 4362.00,2207.00 4389.00,2208.00 4389.00,2208.00
         4389.00,2208.00 4404.00,2209.00 4404.00,2209.00
         4404.00,2209.00 4421.00,2210.00 4421.00,2210.00
         4421.00,2210.00 4439.00,2211.00 4439.00,2211.00
         4439.00,2211.00 4478.00,2213.00 4478.00,2213.00
         4478.00,2213.00 4488.00,2213.96 4488.00,2213.96
         4488.00,2213.96 4497.00,2213.96 4497.00,2213.96
         4497.00,2213.96 4514.00,2215.00 4514.00,2215.00
         4514.00,2215.00 4531.00,2216.00 4531.00,2216.00
         4531.00,2216.00 4546.00,2217.00 4546.00,2217.00
         4546.00,2217.00 4586.00,2219.00 4586.00,2219.00
         4586.00,2219.00 4617.00,2221.00 4617.00,2221.00
         4617.00,2221.00 4639.00,2222.00 4639.00,2222.00
         4639.00,2222.00 4653.00,2223.00 4653.00,2223.00
         4653.00,2223.00 4675.00,2224.00 4675.00,2224.00
         4675.00,2224.00 4692.00,2225.00 4692.00,2225.00
         4692.00,2225.00 4706.00,2226.00 4706.00,2226.00
         4706.00,2226.00 4746.00,2228.00 4746.00,2228.00
         4746.00,2228.00 4759.00,2229.00 4759.00,2229.00
         4759.00,2229.00 4789.00,2231.00 4789.00,2231.00
         4789.00,2231.00 4789.00,2308.00 4789.00,2308.00
         4789.00,2308.00 4769.00,2307.00 4769.00,2307.00
         4769.00,2307.00 4752.00,2306.00 4752.00,2306.00
         4752.00,2306.00 4738.00,2305.00 4738.00,2305.00
         4738.00,2305.00 4699.00,2303.00 4699.00,2303.00
         4699.00,2303.00 4685.00,2302.00 4685.00,2302.00
         4685.00,2302.00 4648.00,2300.00 4648.00,2300.00
         4648.00,2300.00 4634.00,2299.00 4634.00,2299.00
         4634.00,2299.00 4595.00,2297.00 4595.00,2297.00
         4595.00,2297.00 4585.00,2296.04 4585.00,2296.04
         4585.00,2296.04 4577.00,2296.04 4577.00,2296.04
         4577.00,2296.04 4560.00,2295.00 4560.00,2295.00
         4560.00,2295.00 4543.00,2294.00 4543.00,2294.00
         4543.00,2294.00 4528.00,2293.00 4528.00,2293.00
         4528.00,2293.00 4490.00,2291.00 4490.00,2291.00
         4490.00,2291.00 4472.00,2290.00 4472.00,2290.00
         4472.00,2290.00 4455.00,2289.00 4455.00,2289.00
         4455.00,2289.00 4441.00,2288.00 4441.00,2288.00
         4441.00,2288.00 4403.00,2286.00 4403.00,2286.00
         4403.00,2286.00 4390.00,2285.00 4390.00,2285.00
         4390.00,2285.00 4359.00,2283.00 4359.00,2283.00
         4359.00,2283.00 4360.00,2249.00 4360.00,2249.00
         4360.00,2249.00 4361.00,2226.00 4361.00,2226.00
         4361.00,2226.00 4362.00,2207.00 4362.00,2207.00 Z" data-front="51" data-rear="51" data-left="204" data-lot="25" data-street="Sonora Way" data-right="206" data-sqft="10,484" data-type="Townhome" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_179" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 4362.00,2206.00
       C 4362.00,2206.00 4363.91,2179.00 4363.91,2179.00
         4363.91,2179.00 4365.19,2163.00 4365.19,2163.00
         4365.19,2163.00 4365.19,2152.00 4365.19,2152.00
         4367.11,2139.20 4363.40,2135.17 4373.00,2135.00
         4373.00,2135.00 4377.00,2135.00 4377.00,2135.00
         4377.00,2135.00 4388.00,2135.96 4388.00,2135.96
         4388.00,2135.96 4398.00,2135.96 4398.00,2135.96
         4398.00,2135.96 4413.00,2137.00 4413.00,2137.00
         4413.00,2137.00 4457.00,2139.00 4457.00,2139.00
         4457.00,2139.00 4474.00,2140.00 4474.00,2140.00
         4474.00,2140.00 4489.00,2141.00 4489.00,2141.00
         4489.00,2141.00 4517.00,2142.00 4517.00,2142.00
         4517.00,2142.00 4532.00,2143.00 4532.00,2143.00
         4532.00,2143.00 4550.00,2144.00 4550.00,2144.00
         4550.00,2144.00 4577.00,2145.00 4577.00,2145.00
         4577.00,2145.00 4590.00,2146.00 4590.00,2146.00
         4590.00,2146.00 4616.00,2147.00 4616.00,2147.00
         4616.00,2147.00 4629.00,2148.00 4629.00,2148.00
         4629.00,2148.00 4655.00,2149.00 4655.00,2149.00
         4655.00,2149.00 4671.00,2150.00 4671.00,2150.00
         4671.00,2150.00 4689.00,2151.00 4689.00,2151.00
         4689.00,2151.00 4716.00,2152.00 4716.00,2152.00
         4716.00,2152.00 4726.00,2152.96 4726.00,2152.96
         4726.00,2152.96 4734.00,2152.96 4734.00,2152.96
         4734.00,2152.96 4754.00,2154.00 4754.00,2154.00
         4754.00,2154.00 4765.00,2154.96 4765.00,2154.96
         4765.00,2154.96 4775.00,2154.96 4775.00,2154.96
         4775.00,2154.96 4790.00,2156.00 4790.00,2156.00
         4790.00,2156.00 4790.00,2231.00 4790.00,2231.00
         4790.00,2231.00 4761.00,2229.00 4761.00,2229.00
         4761.00,2229.00 4741.00,2228.00 4741.00,2228.00
         4741.00,2228.00 4728.00,2227.00 4728.00,2227.00
         4728.00,2227.00 4672.00,2224.00 4672.00,2224.00
         4672.00,2224.00 4662.00,2223.04 4662.00,2223.04
         4662.00,2223.04 4654.00,2223.04 4654.00,2223.04
         4654.00,2223.04 4637.00,2222.00 4637.00,2222.00
         4637.00,2222.00 4620.00,2221.00 4620.00,2221.00
         4620.00,2221.00 4606.00,2220.00 4606.00,2220.00
         4606.00,2220.00 4568.00,2218.00 4568.00,2218.00
         4568.00,2218.00 4554.00,2217.00 4554.00,2217.00
         4554.00,2217.00 4516.00,2215.00 4516.00,2215.00
         4516.00,2215.00 4506.00,2214.04 4506.00,2214.04
         4506.00,2214.04 4498.00,2214.04 4498.00,2214.04
         4498.00,2214.04 4481.00,2213.00 4481.00,2213.00
         4481.00,2213.00 4471.00,2212.00 4471.00,2212.00
         4471.00,2212.00 4461.00,2212.00 4461.00,2212.00
         4461.00,2212.00 4447.00,2211.00 4447.00,2211.00
         4447.00,2211.00 4432.00,2210.00 4432.00,2210.00
         4432.00,2210.00 4394.00,2208.00 4394.00,2208.00
         4394.00,2208.00 4362.00,2206.00 4362.00,2206.00 Z" data-front="41" data-rear="41" data-left="202" data-lot="26" data-street="Collective Circle" data-right="204" data-sqft="8,330" data-type="Townhome" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_180" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 4369.00,2083.00
         C 4369.00,2083.00 4403.00,2085.00 4403.00,2085.00
         4403.00,2085.00 4412.00,2085.00 4412.00,2085.00
         4412.00,2085.00 4451.00,2088.00 4451.00,2088.00
         4451.00,2088.00 4460.00,2088.00 4460.00,2088.00
         4460.00,2088.00 4488.00,2090.00 4488.00,2090.00
         4488.00,2090.00 4514.00,2091.91 4514.00,2091.91
         4514.00,2091.91 4570.00,2095.09 4570.00,2095.09
         4570.00,2095.09 4601.00,2097.09 4601.00,2097.09
         4601.00,2097.09 4611.00,2098.00 4611.00,2098.00
         4611.00,2098.00 4640.00,2099.91 4640.00,2099.91
         4640.00,2099.91 4680.00,2102.04 4680.00,2102.04
         4680.00,2102.04 4693.00,2103.00 4693.00,2103.00
         4693.00,2103.00 4719.00,2104.91 4719.00,2104.91
         4719.00,2104.91 4766.00,2107.96 4766.00,2107.96
         4766.00,2107.96 4776.00,2107.96 4776.00,2107.96
         4776.00,2107.96 4783.00,2108.91 4783.00,2108.91
         4785.16,2109.08 4788.49,2108.72 4789.99,2110.60
         4792.02,2113.17 4790.01,2125.92 4789.99,2130.00
         4789.99,2130.00 4789.99,2156.00 4789.99,2156.00
         4789.99,2156.00 4773.00,2155.00 4773.00,2155.00
         4773.00,2155.00 4746.00,2154.00 4746.00,2154.00
         4746.00,2154.00 4728.00,2153.00 4728.00,2153.00
         4728.00,2153.00 4712.00,2152.00 4712.00,2152.00
         4712.00,2152.00 4694.00,2151.00 4694.00,2151.00
         4694.00,2151.00 4667.00,2150.00 4667.00,2150.00
         4667.00,2150.00 4652.00,2149.00 4652.00,2149.00
         4652.00,2149.00 4634.00,2148.00 4634.00,2148.00
         4634.00,2148.00 4607.00,2147.00 4607.00,2147.00
         4607.00,2147.00 4594.00,2146.00 4594.00,2146.00
         4594.00,2146.00 4568.00,2145.00 4568.00,2145.00
         4568.00,2145.00 4555.00,2144.00 4555.00,2144.00
         4555.00,2144.00 4528.00,2143.00 4528.00,2143.00
         4528.00,2143.00 4515.00,2142.00 4515.00,2142.00
         4515.00,2142.00 4489.00,2141.00 4489.00,2141.00
         4489.00,2141.00 4473.00,2140.00 4473.00,2140.00
         4473.00,2140.00 4456.00,2139.00 4456.00,2139.00
         4456.00,2139.00 4429.00,2138.00 4429.00,2138.00
         4429.00,2138.00 4414.00,2137.00 4414.00,2137.00
         4414.00,2137.00 4396.00,2136.00 4396.00,2136.00
         4396.00,2136.00 4367.00,2135.00 4367.00,2135.00
         4367.00,2135.00 4369.00,2083.00 4369.00,2083.00 Z" data-front="41" data-rear="41" data-left="199" data-lot="27" data-street="Collective Circle" data-right="202" data-sqft="8,243" data-type="Townhome" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_181" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 4377.00,1942.00
           C 4377.00,1942.00 4415.00,1944.00 4415.00,1944.00
             4415.00,1944.00 4447.00,1946.00 4447.00,1946.00
             4447.00,1946.00 4460.00,1947.00 4460.00,1947.00
             4460.00,1947.00 4469.00,1947.00 4469.00,1947.00
             4469.00,1947.00 4479.00,1948.00 4479.00,1948.00
             4479.00,1948.00 4502.00,1949.00 4502.00,1949.00
             4502.00,1949.00 4516.00,1950.00 4516.00,1950.00
             4516.00,1950.00 4536.00,1951.00 4536.00,1951.00
             4536.00,1951.00 4550.00,1952.00 4550.00,1952.00
             4550.00,1952.00 4588.00,1954.00 4588.00,1954.00
             4588.00,1954.00 4598.00,1954.91 4598.00,1954.91
             4598.00,1954.91 4623.00,1956.00 4623.00,1956.00
             4623.00,1956.00 4633.00,1957.00 4633.00,1957.00
             4633.00,1957.00 4643.00,1957.00 4643.00,1957.00
             4643.00,1957.00 4657.00,1958.00 4657.00,1958.00
             4657.00,1958.00 4667.00,1958.96 4667.00,1958.96
             4667.00,1958.96 4675.00,1958.96 4675.00,1958.96
             4675.00,1958.96 4692.00,1960.00 4692.00,1960.00
             4692.00,1960.00 4709.00,1961.00 4709.00,1961.00
             4709.00,1961.00 4723.00,1962.00 4723.00,1962.00
             4723.00,1962.00 4761.00,1964.00 4761.00,1964.00
             4761.00,1964.00 4792.00,1966.00 4792.00,1966.00
             4792.00,1966.00 4792.00,1993.00 4792.00,1993.00
             4792.00,1993.00 4791.00,2010.00 4791.00,2010.00
             4791.00,2010.00 4791.00,2064.00 4791.00,2064.00
             4791.00,2064.00 4790.00,2080.00 4790.00,2080.00
             4790.00,2080.00 4790.00,2107.00 4790.00,2107.00
             4790.00,2107.00 4778.00,2106.00 4778.00,2106.00
             4778.00,2106.00 4768.00,2106.00 4768.00,2106.00
             4768.00,2106.00 4754.00,2105.00 4754.00,2105.00
             4754.00,2105.00 4744.00,2104.00 4744.00,2104.00
             4744.00,2104.00 4734.00,2104.00 4734.00,2104.00
             4734.00,2104.00 4720.00,2103.00 4720.00,2103.00
             4720.00,2103.00 4706.00,2102.00 4706.00,2102.00
             4706.00,2102.00 4693.00,2101.00 4693.00,2101.00
             4693.00,2101.00 4684.00,2101.00 4684.00,2101.00
             4684.00,2101.00 4671.00,2100.00 4671.00,2100.00
             4671.00,2100.00 4653.00,2099.00 4653.00,2099.00
             4653.00,2099.00 4639.00,2098.00 4639.00,2098.00
             4639.00,2098.00 4602.00,2096.00 4602.00,2096.00
             4602.00,2096.00 4592.00,2095.00 4592.00,2095.00
             4592.00,2095.00 4583.00,2095.00 4583.00,2095.00
             4583.00,2095.00 4570.00,2094.00 4570.00,2094.00
             4570.00,2094.00 4552.00,2093.00 4552.00,2093.00
             4552.00,2093.00 4542.00,2092.00 4542.00,2092.00
             4542.00,2092.00 4532.00,2092.00 4532.00,2092.00
             4532.00,2092.00 4518.00,2091.00 4518.00,2091.00
             4518.00,2091.00 4501.00,2090.00 4501.00,2090.00
             4501.00,2090.00 4487.00,2089.00 4487.00,2089.00
             4487.00,2089.00 4474.00,2088.00 4474.00,2088.00
             4474.00,2088.00 4465.00,2088.00 4465.00,2088.00
             4465.00,2088.00 4452.00,2087.00 4452.00,2087.00
             4452.00,2087.00 4434.00,2086.00 4434.00,2086.00
             4434.00,2086.00 4404.00,2084.00 4404.00,2084.00
             4404.00,2084.00 4383.00,2083.00 4383.00,2083.00
             4383.00,2083.00 4369.00,2082.00 4369.00,2082.00
             4369.00,2082.00 4371.00,2044.00 4371.00,2044.00
             4371.00,2044.00 4372.00,2027.00 4372.00,2027.00
             4372.00,2027.00 4373.00,2010.00 4373.00,2010.00
             4373.00,2010.00 4374.00,1995.00 4374.00,1995.00
             4374.00,1995.00 4376.00,1958.00 4376.00,1958.00
             4376.00,1958.00 4377.00,1942.00 4377.00,1942.00 Z" data-front="94" data-rear="94" data-left="195" data-lot="28" data-street="Collective Circle" data-right="199" data-sqft="18,568" data-type="Townhome" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_183" class="has-hover greenery" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 934.00,1274.00
          C 934.00,1274.00 1027.00,1274.00 1027.00,1274.00
            1027.00,1274.00 1042.00,1273.00 1042.00,1273.00
            1042.00,1273.00 1228.00,1273.00 1228.00,1273.00
            1228.00,1273.00 1243.00,1272.00 1243.00,1272.00
            1243.00,1272.00 1429.00,1272.00 1429.00,1272.00
            1429.00,1272.00 1444.00,1271.00 1444.00,1271.00
            1444.00,1271.00 1630.00,1271.00 1630.00,1271.00
            1630.00,1271.00 1645.00,1270.00 1645.00,1270.00
            1645.00,1270.00 1738.00,1270.00 1738.00,1270.00
            1738.00,1270.00 1738.00,1355.00 1738.00,1355.00
            1738.00,1355.00 1739.00,1370.00 1739.00,1370.00
            1739.00,1370.00 1739.00,1539.00 1739.00,1539.00
            1739.00,1539.00 1740.00,1554.00 1740.00,1554.00
            1740.00,1554.00 1740.00,1724.00 1740.00,1724.00
            1740.00,1724.00 1741.00,1739.00 1741.00,1739.00
            1741.00,1739.00 1741.00,1906.00 1741.00,1906.00
            1741.00,1906.00 1742.00,1923.00 1742.00,1923.00
            1742.00,1923.00 1742.00,2001.00 1742.00,2001.00
            1741.99,2003.68 1742.15,2006.46 1741.15,2009.00
            1741.15,2009.00 1721.42,2038.00 1721.42,2038.00
            1721.42,2038.00 1664.71,2118.00 1664.71,2118.00
            1664.71,2118.00 1649.91,2137.96 1649.91,2137.96
            1649.91,2137.96 1609.00,2171.20 1609.00,2171.20
            1609.00,2171.20 1543.00,2224.20 1543.00,2224.20
            1543.00,2224.20 1364.00,2367.60 1364.00,2367.60
            1364.00,2367.60 1303.00,2416.40 1303.00,2416.40
            1303.00,2416.40 1271.01,2442.17 1271.01,2442.17
            1265.35,2447.40 1256.07,2462.82 1251.58,2470.00
            1251.58,2470.00 1222.58,2515.00 1222.58,2515.00
            1222.58,2515.00 1194.69,2558.00 1194.69,2558.00
            1194.69,2558.00 1134.69,2651.00 1134.69,2651.00
            1134.69,2651.00 1108.22,2693.00 1108.22,2693.00
            1108.22,2693.00 1100.58,2726.00 1100.58,2726.00
            1100.58,2726.00 1086.58,2789.00 1086.58,2789.00
            1086.58,2789.00 1060.21,2909.00 1060.21,2909.00
            1060.21,2909.00 1049.42,2960.00 1049.42,2960.00
            1049.42,2960.00 1046.75,2973.00 1046.75,2973.00
            1045.22,2978.87 1037.64,2990.27 1034.20,2996.00
            1034.20,2996.00 1008.80,3038.00 1008.80,3038.00
            1008.80,3038.00 950.00,3135.00 950.00,3135.00
            950.00,3135.00 931.20,3166.00 931.20,3166.00
            928.11,3171.16 924.13,3178.95 920.00,3183.00
            920.00,3183.00 920.00,3123.00 920.00,3123.00
            920.00,3123.00 921.00,3106.00 921.00,3106.00
            921.00,3106.00 921.00,2984.00 921.00,2984.00
            921.00,2984.00 922.00,2969.00 922.00,2969.00
            922.00,2969.00 922.00,2848.00 922.00,2848.00
            922.00,2848.00 923.00,2833.00 923.00,2833.00
            923.00,2833.00 923.00,2711.00 923.00,2711.00
            923.00,2711.00 924.00,2696.00 924.00,2696.00
            924.00,2696.00 924.00,2575.00 924.00,2575.00
            924.00,2575.00 925.00,2560.00 925.00,2560.00
            925.00,2560.00 925.00,2439.00 925.00,2439.00
            925.00,2439.00 926.00,2424.00 926.00,2424.00
            926.00,2424.00 926.00,2302.00 926.00,2302.00
            926.00,2302.00 927.00,2287.00 927.00,2287.00
            927.00,2287.00 927.00,2166.00 927.00,2166.00
            927.00,2166.00 928.00,2151.00 928.00,2151.00
            928.00,2151.00 928.00,2029.00 928.00,2029.00
            928.00,2029.00 929.00,2014.00 929.00,2014.00
            929.00,2014.00 929.00,1893.00 929.00,1893.00
            929.00,1893.00 930.00,1878.00 930.00,1878.00
            930.00,1878.00 930.00,1756.00 930.00,1756.00
            930.00,1756.00 931.00,1741.00 931.00,1741.00
            931.00,1741.00 931.00,1620.00 931.00,1620.00
            931.00,1620.00 932.00,1605.00 932.00,1605.00
            932.00,1605.00 932.00,1484.00 932.00,1484.00
            932.00,1484.00 933.00,1469.00 933.00,1469.00
            933.00,1469.00 933.00,1347.00 933.00,1347.00
            933.00,1347.00 934.00,1332.00 934.00,1332.00
            934.00,1332.00 934.00,1274.00 934.00,1274.00 Z" data-front="1275" data-rear="491" data-left="430" data-lot="15" data-street="15TH STREET NW" data-right="535" data-sqft="425,410" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="1" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_186" class="has-hover greenery" data-toggle="popover" data-trigger="hover focus" data-price="1" data-placement="left" d="M 940.00,444.00
      C 940.00,444.00 1065.00,444.00 1065.00,444.00
        1065.00,444.00 1080.00,443.00 1080.00,443.00
        1080.00,443.00 1327.00,443.00 1327.00,443.00
        1327.00,443.00 1344.00,442.00 1344.00,442.00
        1344.00,442.00 1515.00,442.00 1515.00,442.00
        1515.00,442.00 1593.00,442.00 1593.00,442.00
        1593.00,442.00 1608.00,441.00 1608.00,441.00
        1608.00,441.00 1733.00,441.00 1733.00,441.00
        1733.00,441.00 1733.00,618.00 1733.00,618.00
        1733.00,618.00 1734.00,633.00 1734.00,633.00
        1734.00,633.00 1734.00,803.00 1734.00,803.00
        1734.00,803.00 1735.00,818.00 1735.00,818.00
        1735.00,818.00 1735.00,987.00 1735.00,987.00
        1735.00,987.00 1736.00,1002.00 1736.00,1002.00
        1736.00,1002.00 1736.00,1172.00 1736.00,1172.00
        1736.00,1172.00 1737.00,1187.00 1737.00,1187.00
        1737.00,1187.00 1737.00,1272.00 1737.00,1272.00
        1737.00,1272.00 1563.00,1272.00 1563.00,1272.00
        1563.00,1272.00 1476.00,1272.00 1476.00,1272.00
        1476.00,1272.00 1461.00,1273.00 1461.00,1273.00
        1461.00,1273.00 933.00,1273.00 933.00,1273.00
        933.00,1273.00 933.00,1225.00 933.00,1225.00
        933.00,1225.00 934.00,1208.00 934.00,1208.00
        934.00,1208.00 934.00,1113.00 934.00,1113.00
        934.00,1113.00 935.00,1098.00 935.00,1098.00
        935.00,1098.00 935.00,1002.00 935.00,1002.00
        935.00,1002.00 936.00,987.00 936.00,987.00
        936.00,987.00 936.00,892.00 936.00,892.00
        936.00,892.00 937.00,877.00 937.00,877.00
        937.00,877.00 937.00,781.00 937.00,781.00
        937.00,781.00 938.00,766.00 938.00,766.00
        938.00,766.00 938.00,670.00 938.00,670.00
        938.00,670.00 939.00,655.00 939.00,655.00
        939.00,655.00 939.00,560.00 939.00,560.00
        939.00,560.00 940.00,545.00 940.00,545.00
        940.00,545.00 940.00,444.00 940.00,444.00 Z" data-front="555" data-rear="555" data-left="535" data-lot="14" data-street="15TH STREET NW" data-right="538" data-sqft="294,953" data-type="Single family" data-unit_type="Sqft" data-block="1" data-grade="1" data-original-title="Heritage Park 2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_28" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3992.00,439.00
        C 3992.00,439.00 4005.00,440.17 4005.00,440.17
          4005.00,440.17 4048.00,443.91 4048.00,443.91
          4048.00,443.91 4068.00,445.17 4068.00,445.17
          4068.00,445.17 4130.00,450.17 4130.00,450.17
          4130.00,450.17 4155.00,452.09 4155.00,452.09
          4155.00,452.09 4167.00,453.00 4167.00,453.00
          4167.00,453.00 4160.28,509.00 4160.28,509.00
          4160.28,509.00 4145.85,622.00 4145.85,622.00
          4145.85,622.00 4139.00,677.00 4139.00,677.00
          4139.00,677.00 4093.00,675.00 4093.00,675.00
          4093.00,675.00 4076.00,674.00 4076.00,674.00
          4076.00,674.00 3993.00,674.00 3993.00,674.00
          3993.00,674.00 3993.00,584.00 3993.00,584.00
          3993.00,584.00 3992.00,569.00 3992.00,569.00
          3992.00,569.00 3992.00,509.00 3992.00,509.00
          3992.00,509.00 3992.00,469.00 3992.00,469.00
          3991.99,459.60 3989.41,448.12 3992.00,439.00 Z" data-front="97" data-rear="116" data-left="151" data-lot="12" data-street="Community Loop" data-right="156" data-sqft="16,394" data-type="Single" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Pak2"></path>
                
                
        
                <path onclick="getvalue(this.id)" id="unit_25" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3278.00,643.00
        C 3278.00,643.00 3258.00,648.52 3258.00,648.52
          3239.89,654.42 3223.55,661.78 3208.00,672.86
          3208.00,672.86 3191.00,685.92 3191.00,685.92
          3188.87,687.65 3185.99,690.47 3183.00,689.68
          3180.26,688.96 3168.00,677.36 3165.00,674.72
          3165.00,674.72 3120.00,634.17 3120.00,634.17
          3120.00,634.17 2966.00,495.83 2966.00,495.83
          2966.00,495.83 2934.00,467.02 2934.00,467.02
          2930.60,463.86 2923.87,458.59 2921.60,455.00
          2919.49,451.67 2919.97,445.17 2919.59,441.00
          2919.44,439.25 2919.05,437.31 2920.61,436.02
          2922.14,434.77 2926.06,435.00 2928.00,435.00
          2928.00,435.00 3090.00,435.00 3090.00,435.00
          3090.00,435.00 3105.00,434.00 3105.00,434.00
          3105.00,434.00 3204.00,434.00 3204.00,434.00
          3204.00,434.00 3225.31,495.00 3225.31,495.00
          3225.31,495.00 3257.69,586.00 3257.69,586.00
          3257.69,586.00 3278.00,643.00 3278.00,643.00 Z" data-front="70" data-rear="188" data-left="148" data-lot="5" data-street="Community Loop" data-right="248" data-sqft="20,039" data-type="Single family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Unit 1"></path>
	    
	    {/* <path data-toggle="popover" data-trigger="hover focus" className="otherside"
                 d="M 428.73,74.82
	           C 428.73,74.82 429.64,68.64 429.64,68.64
	             429.64,68.64 431.64,58.36 431.64,58.36
	             431.64,58.36 433.64,47.36 433.64,47.36
	             433.64,47.36 435.36,37.55 435.36,37.55
	             435.36,37.55 435.82,35.55 435.82,35.55
	             435.82,35.55 442.82,36.55 442.82,36.55
	             442.82,36.55 451.64,38.36 451.64,38.36
	             451.64,38.36 461.45,40.55 461.45,40.55
	             461.45,40.55 470.55,42.36 470.55,42.36
	             470.55,42.36 480.18,44.55 480.18,44.55
	             480.18,44.55 492.18,47.00 492.18,47.00
	             492.18,47.00 485.00,95.73 485.00,95.73
	             485.00,95.73 428.73,74.82 428.73,74.82 Z"></path> */}
	    {/* <path data-toggle="popover" data-trigger="hover focus" className="otherside" 
        d="M 534.55,521.27
	           C 534.55,521.27 480.00,540.82 480.00,540.82
	             480.00,540.82 481.55,546.45 481.55,546.45
	             481.55,546.45 483.45,555.27 483.45,555.27
	             483.45,555.27 484.64,562.00 484.64,562.00
	             484.64,562.00 484.73,572.82 484.73,572.82
	             484.73,572.82 547.45,573.00 547.45,573.00
	             547.45,573.00 547.27,570.82 547.27,570.82
	             547.27,570.82 546.55,567.64 546.55,567.64
	             546.55,567.64 545.55,564.45 545.55,564.45
	             545.55,564.45 544.64,560.18 544.64,560.18
	             544.64,560.18 543.55,557.00 543.55,557.00
	             543.55,557.00 542.36,553.09 542.36,553.09
	             542.36,553.09 541.45,548.91 541.45,548.91
	             541.45,548.91 539.55,541.91 539.55,541.91
	             539.55,541.91 538.18,536.00 538.18,536.00
	             538.18,536.00 536.82,530.36 536.82,530.36
	             536.82,530.36 535.73,526.45 535.73,526.45
	             535.73,526.45 534.55,521.27 534.55,521.27 Z"></path> */}
	    {/* <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 514.45,477.45
	           C 514.45,477.45 534.45,521.27 534.45,521.27
	             534.45,521.27 479.73,540.91 480.00,540.82
	             480.27,540.73 477.55,534.91 477.55,534.91
	             477.55,534.91 475.45,530.73 475.45,530.73
	             475.45,530.73 473.64,527.00 473.64,527.00
	             473.64,527.00 471.64,523.64 471.64,523.64
	             471.64,523.64 470.09,520.91 470.09,520.91
	             470.09,520.91 468.00,517.82 468.00,517.82
	             468.00,517.82 466.09,514.82 466.09,514.82
	             466.09,514.82 514.45,477.45 514.45,477.45 Z"></path> */}
	    {/* <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 473.82,450.73
	           C 473.82,450.73 514.09,477.73 514.09,477.73
	             514.09,477.73 466.27,514.64 466.27,514.64
	             466.27,514.64 461.91,510.09 461.91,510.09
	             461.91,510.09 458.82,506.91 458.82,506.91
	             458.82,506.91 455.91,503.91 455.91,503.91
	             455.91,503.91 453.09,501.00 453.09,501.00
	             453.09,501.00 450.36,498.82 450.36,498.82
	             450.36,498.82 447.00,496.45 447.00,496.45
	             447.00,496.45 443.36,494.00 443.36,494.00
	             443.36,494.00 473.82,450.73 473.82,450.73 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 430.00,427.38
	           C 430.00,427.38 437.36,428.27 437.36,428.27
	             437.36,428.27 444.45,430.36 444.45,430.36
	             444.45,430.36 458.82,440.09 458.82,440.09
	             458.82,440.09 473.82,450.73 473.82,450.73
	             473.82,450.73 443.36,493.91 443.36,493.91
	             443.36,493.91 439.73,491.45 439.64,491.55
	             439.55,491.64 436.18,489.82 436.18,489.82
	             436.18,489.82 431.91,487.64 431.91,487.64
	             431.91,487.64 427.82,485.55 427.82,485.55
	             427.82,485.55 422.82,483.64 422.82,483.64
	             422.82,483.64 418.64,482.09 418.64,482.09
	             418.64,482.09 415.27,481.09 415.27,481.09
	             415.27,481.09 416.45,475.91 416.45,475.91
	             416.45,475.91 420.73,461.82 420.73,461.82
	             420.73,461.82 422.55,454.73 422.55,454.73
	             422.55,454.73 424.82,447.09 424.82,447.09
	             424.82,447.09 427.45,436.91 427.45,436.91
	             427.45,436.91 430.00,427.38 430.00,427.38 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 395.75,477.88
	           C 395.75,477.88 399.63,478.13 399.63,478.13
	             399.63,478.13 405.25,478.88 405.25,478.88
	             405.25,478.88 408.38,479.38 408.38,479.38
	             408.38,479.38 411.75,480.13 411.88,480.25
	             412.00,480.38 415.38,480.88 415.38,480.88
	             415.38,480.88 416.38,475.88 416.38,475.88
	             416.38,475.88 418.63,468.63 418.63,468.63
	             418.63,468.63 420.75,461.50 420.75,461.50
	             420.75,461.50 422.50,454.50 422.50,454.50
	             422.50,454.50 424.63,447.50 424.63,447.50
	             424.63,447.50 427.63,435.88 427.63,435.88
	             427.63,435.88 429.75,427.38 429.75,427.38
	             429.75,427.38 386.63,417.13 386.63,417.13
	             386.63,417.13 386.13,477.75 386.13,477.75
	             386.13,477.75 395.75,477.88 395.75,477.88 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 350.75,415.00
	           C 350.75,415.00 350.75,477.63 350.75,477.63
	             350.75,477.63 386.00,477.63 386.00,477.63
	             386.00,477.63 386.63,417.00 386.63,417.00
	             386.63,417.00 375.13,413.50 375.13,413.50
	             375.13,413.50 350.75,415.00 350.75,415.00 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 310.25,406.63
	           C 310.25,406.63 350.75,415.13 350.75,415.13
	             350.75,415.13 350.50,477.63 350.50,477.63
	             350.50,477.63 296.25,477.50 296.25,477.50
	             296.25,477.50 296.63,468.75 296.63,468.75
	             296.63,468.75 298.63,456.50 298.63,456.50
	             298.63,456.50 300.50,447.38 300.50,447.38
	             300.50,447.38 302.63,438.63 302.50,438.50
	             302.38,438.38 304.38,429.63 304.38,429.63
	             304.38,429.63 306.38,420.50 306.38,420.50
	             306.38,420.50 308.38,411.38 308.38,411.38
	             308.38,411.38 310.25,406.63 310.25,406.63 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 318.50,369.88
	           C 318.50,369.88 387.63,385.00 387.63,385.00
	             387.63,385.00 375.00,413.38 375.00,413.38
	             375.00,413.38 350.88,415.13 350.88,415.13
	             350.88,415.13 310.50,406.63 310.50,406.63
	             310.50,406.63 310.63,403.50 310.63,403.50
	             310.63,403.50 312.50,393.88 312.50,393.88
	             312.50,393.88 314.50,385.00 314.50,385.00
	             314.50,385.00 316.63,376.38 316.63,376.38
	             316.63,376.38 318.50,369.88 318.50,369.88 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 387.75,384.75
	           C 387.63,385.13 318.50,369.88 318.50,369.88
	             318.50,369.88 318.75,366.50 318.75,366.50
	             318.75,366.50 320.38,359.38 320.50,359.25
	             320.63,359.13 322.50,352.50 322.50,352.50
	             322.50,352.50 324.63,347.63 324.63,347.63
	             324.63,347.63 326.63,343.50 326.63,343.50
	             326.63,343.50 328.63,338.88 328.63,338.88
	             328.63,338.88 330.63,335.38 330.63,335.38
	             330.63,335.38 332.50,331.63 332.50,331.63
	             332.50,331.63 334.63,328.50 334.63,328.50
	             334.63,328.50 336.63,325.50 336.63,325.50
	             336.63,325.50 338.50,322.25 338.75,322.25
	             339.00,322.25 396.13,365.63 396.13,365.63
	             396.13,365.63 387.88,384.38 387.75,384.75 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 369.88,292.50
	           C 369.88,292.50 409.38,336.50 409.38,336.50
	             409.38,336.50 396.25,365.75 396.25,365.75
	             396.25,365.75 339.13,322.50 339.13,322.50
	             339.13,322.50 340.38,320.50 340.38,320.50
	             340.38,320.50 346.75,313.38 346.75,313.38
	             346.75,313.38 351.63,308.25 351.63,308.25
	             351.63,308.25 359.00,301.75 359.00,301.75
	             359.00,301.75 369.88,292.50 369.88,292.50 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 396.13,268.88
	           C 396.13,268.88 370.00,292.63 370.00,292.38
	             370.00,292.13 409.25,336.38 409.25,336.38
	             409.25,336.38 410.75,333.75 410.75,333.75
	             410.75,333.75 411.75,331.50 411.75,331.50
	             411.75,331.50 412.50,329.75 412.50,329.75
	             412.50,329.75 413.63,327.63 413.63,327.63
	             413.63,327.63 414.38,325.63 414.38,325.63
	             414.38,325.63 415.13,323.75 415.13,323.75
	             415.13,323.75 417.25,321.88 417.25,321.88
	             417.25,321.88 419.38,319.50 419.38,319.50
	             419.38,319.50 421.50,317.75 421.50,317.75
	             421.50,317.75 423.38,316.00 423.38,316.00
	             423.38,316.00 425.25,314.25 425.25,314.25
	             425.25,314.25 431.38,308.88 431.38,308.88
	             431.38,308.88 396.13,268.88 396.13,268.88 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 422.63,245.00
	           C 422.75,244.88 396.13,268.88 396.13,268.88
	             396.13,268.88 431.25,308.75 431.25,308.75
	             431.25,308.75 458.25,285.13 458.25,285.13
	             458.25,285.13 422.50,245.13 422.63,245.00 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 448.82,222.18
	           C 448.82,222.18 422.64,245.09 422.64,245.09
	             422.64,245.09 458.09,285.00 458.09,285.00
	             458.09,285.00 484.55,261.64 484.55,261.64
	             484.55,261.64 448.82,222.18 448.82,222.18 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 473.82,196.45
	           C 473.82,196.45 469.45,201.91 469.45,201.91
	             469.45,201.91 464.64,207.36 464.64,207.36
	             464.64,207.36 457.18,214.73 457.18,214.73
	             457.18,214.73 450.73,220.45 450.73,220.45
	             450.73,220.45 448.91,222.27 448.91,222.27
	             448.91,222.27 484.73,261.82 484.73,261.82
	             484.73,261.82 501.64,245.45 501.64,245.45
	             501.64,245.45 514.09,229.82 514.09,229.82
	             514.09,229.82 473.82,196.45 473.82,196.45 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 492.18,167.18
	           C 492.18,167.18 490.18,170.91 490.18,170.91
	             490.18,170.91 488.45,174.27 488.45,174.27
	             488.45,174.27 485.36,179.45 485.36,179.45
	             485.36,179.45 482.36,184.55 482.36,184.55
	             482.36,184.55 479.55,188.55 479.55,188.55
	             479.55,188.55 478.36,190.73 478.36,190.73
	             478.36,190.73 475.45,194.64 475.45,194.64
	             475.45,194.64 474.00,196.64 474.00,196.64
	             474.00,196.64 514.00,229.73 514.00,229.73
	             514.00,229.73 544.18,191.36 544.18,191.36
	             544.18,191.36 492.18,167.18 492.18,167.18 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 505.18,129.55
	           C 505.18,129.55 503.45,134.55 503.45,134.55
	             503.45,134.55 501.36,142.45 501.36,142.45
	             501.36,142.45 499.45,148.45 499.45,148.45
	             499.45,148.45 497.64,154.27 497.64,154.27
	             497.64,154.27 495.36,159.18 495.36,159.18
	             495.36,159.18 493.45,164.18 493.45,164.18
	             493.45,164.18 492.36,167.27 492.36,167.27
	             492.36,167.27 544.00,191.27 544.00,191.27
	             544.00,191.27 572.73,154.82 572.73,154.82
	             572.73,154.82 505.18,129.55 505.18,129.55 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 277.27,438.00
	           C 277.27,438.00 275.09,446.55 275.09,446.55
	             275.09,446.55 273.27,456.00 273.27,456.00
	             273.27,456.00 271.64,469.82 271.64,469.82
	             271.64,469.82 271.27,477.82 271.27,477.82
	             271.27,477.82 225.09,477.27 225.09,477.27
	             225.09,477.27 219.09,424.18 219.09,424.18
	             219.09,424.18 278.18,432.00 278.18,432.00
	             278.18,432.00 277.27,438.00 277.27,438.00 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 278.55,432.18
	           C 278.55,432.18 286.91,394.18 286.91,394.18
	             286.91,394.18 228.55,381.27 228.55,381.27
	             228.55,381.27 218.73,424.18 218.73,424.18
	             218.73,424.18 278.55,432.18 278.55,432.18 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 236.73,348.18
	           C 236.73,348.18 228.73,381.27 228.73,381.27
	             228.73,381.27 286.91,394.18 286.91,394.18
	             286.91,394.18 289.27,385.27 289.27,385.27
	             289.27,385.27 291.64,375.45 291.64,375.45
	             291.64,375.45 293.64,367.27 293.64,367.27
	             293.64,367.27 294.73,360.00 294.73,360.00
	             294.73,360.00 236.73,348.18 236.73,348.18 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 306.00,328.55
	           C 306.00,328.55 304.36,332.91 304.36,332.91
	             304.36,332.91 302.55,337.09 302.55,337.09
	             302.55,337.09 300.55,341.82 300.55,341.82
	             300.55,341.82 298.36,347.09 298.36,347.09
	             298.36,347.09 296.36,353.82 296.36,353.82
	             296.36,353.82 294.73,360.00 294.73,360.00
	             294.73,360.00 236.55,348.18 236.55,348.18
	             236.55,348.18 248.00,300.00 248.00,300.00
	             248.00,300.00 306.00,328.55 306.00,328.55 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 325.09,300.55
	           C 325.09,300.55 319.09,307.82 319.09,307.82
	             319.09,307.82 314.18,315.09 314.18,315.09
	             314.18,315.09 310.73,320.55 310.73,320.55
	             310.73,320.55 307.45,326.00 307.45,326.00
	             307.45,326.00 306.00,328.55 306.00,328.55
	             306.00,328.55 248.18,300.00 248.18,300.00
	             248.18,300.00 250.55,288.73 250.55,288.73
	             250.55,288.73 266.55,267.45 266.55,267.45
	             266.55,267.45 276.55,258.00 276.55,258.00
	             276.55,258.00 325.09,300.55 325.09,300.55 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 276.73,258.18
	           C 276.73,258.18 324.73,300.18 324.73,300.18
	             324.73,300.18 328.73,296.55 328.73,296.55
	             328.73,296.55 333.09,292.18 333.09,292.18
	             333.09,292.18 336.73,288.55 336.73,288.55
	             336.73,288.55 340.55,285.82 340.55,285.82
	             340.55,285.82 344.18,281.82 344.18,281.82
	             344.18,281.82 349.27,277.45 349.27,277.45
	             349.27,277.45 307.64,230.18 307.64,230.18
	             307.64,230.18 276.73,258.18 276.73,258.18 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 336.00,205.09
	           C 336.00,205.09 378.91,251.82 378.55,251.82
	             378.18,251.82 349.45,277.27 349.45,277.27
	             349.45,277.27 307.64,230.18 307.64,230.18
	             307.64,230.18 336.00,205.09 336.00,205.09 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 364.18,180.18
	           C 364.18,180.18 406.36,227.09 406.55,226.91
	             406.73,226.73 378.36,251.45 378.36,251.45
	             378.36,251.45 336.18,205.27 336.18,205.27
	             336.18,205.27 364.18,180.18 364.18,180.18 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 383.27,154.91
	           C 383.27,154.91 438.55,198.55 438.55,198.55
	             438.55,198.55 406.36,226.73 406.36,226.73
	             406.36,226.73 364.55,180.36 364.55,180.36
	             364.55,180.36 366.73,178.18 366.73,178.18
	             366.73,178.18 383.27,154.91 383.27,154.91 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 383.45,155.09
	           C 383.45,155.09 402.18,127.64 402.18,127.64
	             402.18,127.64 464.00,167.27 464.00,167.27
	             464.00,167.27 461.27,171.64 461.27,171.64
	             461.27,171.64 458.36,176.36 458.36,176.36
	             458.36,176.36 454.73,181.09 454.73,181.09
	             454.73,181.09 451.64,184.36 451.64,184.36
	             451.64,184.36 448.18,188.73 448.18,188.73
	             448.18,188.73 445.64,191.82 445.64,191.82
	             445.64,191.82 442.18,194.55 442.18,194.55
	             442.18,194.55 439.27,197.45 439.27,197.45
	             439.27,197.45 438.18,198.18 438.18,198.18
	             438.18,198.18 383.45,155.09 383.45,155.09 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 422.55,98.91
	           C 422.55,98.91 480.73,120.55 480.91,120.73
	             481.09,120.91 479.82,127.09 479.82,127.09
	             479.82,127.09 478.00,134.91 478.00,134.91
	             478.00,134.91 476.00,141.45 476.00,141.45
	             476.00,141.45 473.82,147.27 473.64,147.45
	             473.45,147.64 471.09,153.09 471.27,152.91
	             471.45,152.73 469.09,157.64 469.09,157.64
	             469.09,157.64 466.00,163.64 466.00,163.64
	             466.00,163.64 464.00,167.27 464.00,167.27
	             464.00,167.27 402.36,127.82 402.36,127.82
	             402.36,127.82 422.55,98.91 422.55,98.91 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 319.45,132.55
	           C 319.45,132.55 338.91,110.55 338.91,110.55
	             338.91,110.55 393.45,140.36 393.45,140.36
	             393.45,140.36 367.09,177.82 367.09,177.82
	             367.09,177.82 319.45,132.55 319.45,132.55 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 293.82,156.36
	           C 293.82,156.36 298.55,152.36 298.55,152.36
	             298.55,152.36 306.36,145.82 306.36,145.82
	             306.36,145.82 311.27,141.09 311.27,141.09
	             311.27,141.09 315.27,136.91 315.27,136.91
	             315.27,136.91 319.64,132.91 319.64,132.91
	             319.64,132.91 366.91,177.64 366.91,177.64
	             366.91,177.64 334.55,206.73 334.55,206.73
	             334.55,206.73 293.82,156.36 293.82,156.36 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 266.00,176.00
	           C 266.00,176.00 304.36,233.09 304.36,233.09
	             304.36,233.09 334.36,206.55 334.36,206.55
	             334.36,206.55 293.64,156.18 293.64,156.18
	             293.64,156.18 286.91,161.45 286.91,161.45
	             286.91,161.45 280.18,166.36 280.18,166.36
	             280.18,166.36 273.45,171.09 273.45,171.09
	             273.45,171.09 266.00,176.00 266.00,176.00 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 282.36,253.09
	           C 282.36,253.09 304.36,233.09 304.36,233.09
	             304.36,233.09 266.00,176.00 266.00,176.00
	             266.00,176.00 259.09,180.91 259.09,180.91
	             259.09,180.91 253.27,184.36 253.27,184.36
	             253.27,184.36 246.55,188.55 246.55,188.55
	             246.55,188.55 240.91,192.36 240.91,192.36
	             240.91,192.36 236.55,195.27 236.55,195.27
	             236.55,195.27 232.18,198.36 232.18,198.36
	             232.18,198.36 282.36,253.09 282.36,253.09 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 266.91,267.09
	           C 266.91,267.09 282.00,252.73 282.00,252.73
	             282.00,252.73 232.36,198.55 232.36,198.55
	             232.36,198.55 229.64,200.36 229.64,200.36
	             229.64,200.36 226.36,202.73 226.36,202.73
	             226.36,202.73 223.27,205.09 223.27,205.09
	             223.27,205.09 221.45,206.18 221.45,206.18
	             221.45,206.18 218.55,209.45 218.55,209.45
	             218.55,209.45 215.09,213.27 215.09,213.27
	             215.09,213.27 213.27,215.45 213.27,215.45
	             213.27,215.45 211.45,218.00 211.45,218.00
	             211.45,218.00 209.27,221.27 209.27,221.27
	             209.27,221.27 207.45,224.00 207.45,224.00
	             207.45,224.00 205.64,227.45 205.64,227.45
	             205.64,227.45 203.82,231.09 203.82,231.09
	             203.82,231.09 202.55,235.64 202.55,235.64
	             202.55,235.64 266.91,267.09 266.91,267.09 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 250.18,288.91
	           C 250.18,288.91 266.55,266.91 266.55,266.91
	             266.55,266.91 202.91,235.82 202.91,235.82
	             202.91,235.82 200.73,240.00 200.73,240.00
	             200.73,240.00 199.45,246.73 199.45,246.73
	             199.45,246.73 198.36,252.55 198.36,252.55
	             198.36,252.55 197.09,258.36 197.27,258.18
	             197.45,258.00 196.18,264.00 196.18,264.00
	             196.18,264.00 195.45,269.09 195.45,269.09
	             195.45,269.09 194.55,274.36 194.55,274.36
	             194.55,274.36 194.18,279.09 194.18,279.09
	             194.18,279.09 245.27,287.64 245.27,287.64
	             245.27,287.64 250.18,288.91 250.18,288.91 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 240.36,331.64
	           C 240.36,331.64 241.64,327.45 241.64,327.45
	             241.64,327.45 243.27,319.27 243.27,319.27
	             243.27,319.27 245.45,310.00 245.45,310.00
	             245.45,310.00 247.45,302.18 247.45,302.18
	             247.45,302.18 249.64,293.45 249.64,293.45
	             249.64,293.45 250.18,288.91 250.18,288.91
	             250.18,288.91 244.73,287.45 244.73,287.45
	             244.73,287.45 230.91,285.27 230.91,285.27
	             230.91,285.27 222.18,283.82 222.18,283.82
	             222.18,283.82 210.18,281.82 210.18,281.82
	             210.18,281.82 198.36,279.82 198.73,279.82
	             199.09,279.82 194.36,279.09 194.36,279.09
	             194.36,279.09 192.18,288.00 192.18,288.00
	             192.18,288.00 191.09,294.18 191.09,294.18
	             191.09,294.18 189.82,300.00 189.82,300.00
	             189.82,300.00 187.82,305.45 187.82,305.45
	             187.82,305.45 186.18,311.45 186.18,311.45
	             186.18,311.45 240.36,331.64 240.36,331.64 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 169.82,340.91
	           C 169.82,340.91 229.64,377.64 229.64,377.64
	             229.64,377.64 240.36,331.82 240.36,331.82
	             240.36,331.82 186.18,311.45 186.18,311.45
	             186.18,311.45 184.18,316.36 184.18,316.36
	             184.18,316.36 181.45,321.09 181.45,321.09
	             181.45,321.09 179.27,325.45 179.27,325.45
	             179.27,325.45 177.45,328.00 177.45,328.00
	             177.45,328.00 175.45,331.27 175.45,331.27
	             175.45,331.27 173.27,334.73 173.27,334.73
	             173.27,334.73 171.27,338.36 171.27,338.36
	             171.27,338.36 169.82,340.91 169.82,340.91 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 223.45,403.45
	           C 223.45,403.45 229.27,377.45 229.27,377.45
	             229.27,377.45 169.82,340.91 169.82,340.91
	             169.82,340.91 152.73,368.91 152.73,368.91
	             152.73,368.91 150.73,374.00 150.91,373.82
	             151.09,373.64 149.64,377.45 149.64,377.45
	             149.64,377.45 148.91,381.09 148.91,381.09
	             148.91,381.09 223.45,403.45 223.45,403.45 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 218.73,424.36
	           C 218.73,424.36 223.09,403.64 223.09,403.64
	             223.09,403.64 148.91,381.09 148.91,381.09
	             148.91,381.09 147.45,385.64 147.45,385.64
	             147.45,385.64 146.55,393.82 146.55,393.82
	             146.55,393.82 146.73,405.45 146.73,405.45
	             146.73,405.45 147.45,410.36 147.45,410.36
	             147.45,410.36 149.09,416.36 149.09,416.36
	             149.09,416.36 150.91,422.00 150.91,422.00
	             150.91,422.00 153.45,428.00 153.45,428.00
	             153.45,428.00 218.73,424.36 218.73,424.36 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 153.82,428.18
	           C 153.82,428.18 165.27,427.27 165.27,427.27
	             165.27,427.27 175.64,426.91 175.64,426.91
	             175.64,426.91 187.09,426.55 187.09,426.55
	             187.09,426.55 201.45,425.45 201.45,425.45
	             201.45,425.45 212.36,424.91 212.36,424.91
	             212.36,424.91 218.91,424.55 218.91,424.55
	             218.91,424.55 225.09,477.45 225.09,477.45
	             225.09,477.45 189.09,477.45 189.09,477.45
	             189.09,477.45 183.82,476.00 183.82,476.00
	             183.82,476.00 179.82,474.00 179.82,474.00
	             179.82,474.00 176.18,471.64 176.18,471.64
	             176.18,471.64 173.82,470.00 173.82,470.00
	             173.82,470.00 171.27,467.09 171.27,467.09
	             171.27,467.09 169.64,464.73 169.64,464.73
	             169.64,464.73 168.18,462.00 168.18,462.00
	             168.18,462.00 166.55,459.09 166.55,459.09
	             166.55,459.09 165.45,455.82 165.45,455.82
	             165.45,455.82 164.36,453.09 164.36,453.09
	             164.36,453.09 163.09,450.00 163.09,450.00
	             163.09,450.00 161.82,446.91 161.82,446.91
	             161.82,446.91 160.55,444.36 160.55,444.36
	             160.55,444.36 159.27,441.45 159.27,441.45
	             159.27,441.45 158.18,438.91 158.18,438.91
	             158.18,438.91 156.73,435.82 156.73,435.82
	             156.73,435.82 155.09,432.73 155.09,432.73
	             155.09,432.73 153.82,428.18 153.82,428.18 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 388.73,502.73
	           C 388.73,502.73 387.82,572.55 387.82,572.55
	             387.82,572.55 449.27,572.73 449.27,572.73
	             449.27,572.73 449.45,566.73 449.45,566.73
	             449.45,566.73 449.09,563.82 449.09,563.82
	             449.09,563.82 448.55,559.45 448.55,559.45
	             448.55,559.45 447.64,556.18 447.64,556.18
	             447.64,556.18 446.73,552.91 446.73,552.91
	             446.73,552.91 445.45,549.64 445.45,549.64
	             445.45,549.64 444.55,546.55 444.55,546.55
	             444.55,546.55 443.82,544.00 443.82,544.00
	             443.82,544.00 442.36,541.09 442.36,541.09
	             442.36,541.09 440.55,538.36 440.55,538.36
	             440.55,538.36 439.27,536.00 439.27,536.00
	             439.27,536.00 437.82,533.64 437.82,533.64
	             437.82,533.64 436.00,530.91 436.00,530.91
	             436.00,530.91 434.00,528.18 434.00,528.18
	             434.00,528.18 432.00,526.00 432.00,526.00
	             432.00,526.00 430.18,523.82 430.18,523.82
	             430.18,523.82 427.82,521.64 427.82,521.64
	             427.82,521.64 425.09,518.91 425.09,518.91
	             425.09,518.91 420.55,515.64 420.73,515.64
	             420.91,515.64 416.91,513.09 416.91,513.09
	             416.91,513.09 412.91,510.91 412.91,510.91
	             412.91,510.91 410.00,509.09 410.00,509.09
	             410.00,509.09 406.73,507.64 406.73,507.64
	             406.73,507.64 403.64,506.73 403.64,506.73
	             403.64,506.73 400.73,505.64 400.73,505.64
	             400.73,505.64 398.00,504.73 398.00,504.73
	             398.00,504.73 394.73,503.82 394.73,503.82
	             394.73,503.82 391.82,503.27 391.82,503.27
	             391.82,503.27 388.73,502.73 388.73,502.73 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 351.45,502.36
	           C 351.45,502.36 388.55,502.73 388.55,502.73
	             388.55,502.73 387.27,572.36 387.27,572.36
	             387.27,572.36 350.73,572.36 350.73,572.36
	             350.73,572.36 351.45,502.36 351.45,502.36 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 314.00,502.55
	           C 314.00,502.55 351.09,502.36 351.09,502.36
	             351.09,502.36 350.55,572.55 350.55,572.55
	             350.55,572.55 313.82,572.55 313.82,572.55
	             313.82,572.55 314.00,502.55 314.00,502.55 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 277.27,502.36
	           C 277.27,502.36 314.00,502.55 314.00,502.55
	             314.00,502.55 313.64,572.55 313.64,572.55
	             313.64,572.55 277.27,572.55 277.27,572.55
	             277.27,572.55 277.27,502.36 277.27,502.36 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 240.00,502.18
	           C 240.00,502.18 277.09,502.18 277.09,502.18
	             277.09,502.18 277.09,572.36 277.09,572.36
	             277.09,572.36 239.82,572.55 239.82,572.55
	             239.82,572.55 240.00,502.18 240.00,502.18 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 202.36,502.00
	           C 202.36,502.00 239.82,502.18 239.82,502.18
	             239.82,502.18 239.45,572.18 239.45,572.18
	             239.45,572.18 202.00,572.00 202.00,572.00
	             202.00,572.00 202.36,502.00 202.36,502.00 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 149.45,572.00
	           C 149.45,572.00 201.64,572.00 201.64,572.00
	             201.64,572.00 202.18,502.18 202.18,502.18
	             202.18,502.18 189.64,502.00 189.64,502.00
	             189.64,502.00 184.91,501.27 184.91,501.27
	             184.91,501.27 180.91,500.36 180.91,500.36
	             180.91,500.36 176.91,499.82 177.09,499.64
	             177.27,499.45 174.18,498.73 174.18,498.73
	             174.18,498.73 149.45,572.00 149.45,572.00 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 45.27,571.64
	           C 45.27,571.64 149.27,571.82 149.27,571.82
	             149.27,571.82 173.82,498.91 173.82,498.91
	             173.82,498.91 170.00,496.73 170.00,496.73
	             170.00,496.73 167.09,495.27 166.91,495.09
	             166.73,494.91 163.64,492.73 163.64,492.73
	             163.64,492.73 160.18,490.18 160.18,490.18
	             160.18,490.18 157.82,487.64 157.64,487.82
	             157.45,488.00 155.09,485.64 155.09,485.64
	             155.09,485.64 152.73,483.09 152.73,483.09
	             152.73,483.09 45.27,571.64 45.27,571.64 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 27.64,505.45
	           C 27.64,505.45 26.91,572.36 26.91,572.36
	             26.91,572.36 45.45,571.64 45.27,571.45
	             45.09,571.27 152.36,482.91 152.36,482.91
	             152.36,482.91 150.55,480.73 150.55,480.73
	             150.55,480.73 148.91,478.18 148.91,478.18
	             148.91,478.18 146.91,475.27 146.91,475.27
	             146.91,475.27 145.09,471.82 145.09,471.82
	             145.09,471.82 144.00,469.64 144.00,469.64
	             144.00,469.64 142.91,466.91 142.91,466.91
	             142.91,466.91 142.00,464.00 142.00,464.00
	             142.00,464.00 140.73,461.27 140.73,461.27
	             140.73,461.27 139.64,458.73 139.64,458.73
	             139.64,458.73 138.36,456.73 138.36,456.73
	             138.36,456.73 27.64,505.45 27.64,505.45 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 28.36,456.73
	           C 28.36,456.73 28.00,505.09 28.00,505.09
	             28.00,505.09 138.36,456.36 138.36,456.36
	             138.36,456.36 124.73,422.55 124.73,422.55
	             124.73,422.55 28.36,456.73 28.36,456.73 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 30.00,397.09
	           C 30.00,397.09 28.55,456.36 28.55,456.36
	             28.55,456.36 124.55,422.18 124.55,422.18
	             124.55,422.18 123.45,417.09 123.45,417.09
	             123.45,417.09 122.36,411.64 122.36,411.64
	             122.36,411.64 121.64,404.36 121.64,404.36
	             121.64,404.36 121.82,396.00 121.82,396.00
	             121.82,396.00 30.00,397.09 30.00,397.09 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 31.45,322.73
	           C 31.45,322.73 30.36,396.91 30.36,396.91
	             30.36,396.91 122.36,395.64 122.36,395.64
	             122.36,395.64 122.36,388.91 122.36,388.91
	             122.36,388.91 122.91,385.64 122.91,385.64
	             122.91,385.64 123.45,381.45 123.45,381.45
	             123.45,381.45 124.00,378.18 124.00,378.18
	             124.00,378.18 124.73,374.55 124.73,374.55
	             124.73,374.55 125.45,372.00 125.45,372.00
	             125.45,372.00 31.45,322.73 31.45,322.73 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 53.64,286.73
	           C 53.64,286.73 31.82,322.73 31.82,322.73
	             31.82,322.73 125.45,371.64 125.45,371.64
	             125.45,371.64 126.73,368.36 126.73,368.36
	             126.73,368.36 128.18,363.64 128.18,363.64
	             128.18,363.64 130.55,359.27 130.55,359.27
	             130.55,359.27 132.18,355.82 132.18,355.64
	             132.18,355.45 134.00,351.64 134.00,351.64
	             134.00,351.64 136.00,348.73 136.00,348.73
	             136.00,348.73 137.82,346.18 137.82,346.18
	             137.82,346.18 139.27,343.45 139.27,343.45
	             139.27,343.45 140.73,341.09 140.73,341.09
	             140.73,341.09 141.45,339.64 141.45,339.64
	             141.45,339.64 53.64,286.73 53.64,286.73 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 66.55,266.00
	           C 66.55,266.00 54.00,286.55 54.00,286.55
	             54.00,286.55 141.64,339.09 141.64,339.09
	             141.64,339.09 162.18,305.64 162.18,305.64
	             162.18,305.64 162.00,304.00 162.00,304.00
	             162.00,304.00 66.55,266.00 66.55,266.00 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 73.64,234.55
	           C 73.64,234.55 66.73,265.64 66.73,265.64
	             66.73,265.64 162.18,303.45 162.18,303.45
	             162.18,303.45 164.00,300.18 164.00,300.18
	             164.00,300.18 164.91,296.55 164.91,296.55
	             164.91,296.55 166.18,292.55 166.18,292.55
	             166.18,292.55 167.09,286.91 167.09,286.91
	             167.09,286.91 167.64,281.64 167.64,281.64
	             167.64,281.64 168.91,275.64 168.91,275.64
	             168.91,275.64 169.82,270.73 169.82,270.73
	             169.82,270.73 170.73,264.73 170.73,264.73
	             170.73,264.73 73.64,234.55 73.64,234.55 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 84.36,188.00
	           C 84.36,188.00 73.64,234.55 73.64,234.55
	             73.64,234.55 170.73,264.36 170.73,264.36
	             170.73,264.36 177.27,232.36 177.27,232.36
	             177.27,232.36 84.36,188.00 84.36,188.00 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 130.73,118.36
	           C 130.73,118.36 84.36,187.64 84.36,187.64
	             84.36,187.64 177.64,232.00 177.64,232.00
	             177.64,232.00 178.18,229.45 178.18,229.45
	             178.18,229.45 178.73,227.09 178.73,227.09
	             178.73,227.09 179.64,224.73 179.64,224.73
	             179.64,224.73 180.55,222.36 180.55,222.36
	             180.55,222.36 181.82,220.00 181.82,220.18
	             181.82,220.36 182.91,217.64 182.91,217.64
	             182.91,217.64 183.82,215.45 183.82,215.45
	             183.82,215.45 184.91,213.27 184.91,213.27
	             184.91,213.27 186.36,211.27 186.36,211.27
	             186.36,211.27 187.64,209.45 187.64,209.45
	             187.64,209.45 188.73,207.09 188.73,207.09
	             188.73,207.09 190.00,205.45 190.00,205.45
	             190.00,205.45 191.27,203.64 191.27,203.64
	             191.27,203.64 192.73,201.64 192.73,201.64
	             192.73,201.64 194.36,199.82 194.36,199.82
	             194.36,199.82 195.64,197.64 195.64,197.64
	             195.64,197.64 196.36,196.73 196.55,196.55
	             196.73,196.36 130.73,118.36 130.73,118.36 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 168.18,88.18
	           C 168.18,88.18 131.27,118.36 131.27,118.36
	             131.27,118.36 196.91,196.18 196.91,196.18
	             196.91,196.18 198.73,194.18 198.73,194.18
	             198.73,194.18 200.91,191.64 200.91,191.64
	             200.91,191.64 203.27,190.00 203.27,190.00
	             203.27,190.00 205.27,188.00 205.27,188.00
	             205.27,188.00 207.27,186.36 207.27,186.36
	             207.27,186.36 209.27,184.73 209.27,184.73
	             209.27,184.73 211.09,183.09 211.09,183.09
	             211.09,183.09 213.09,181.82 213.09,181.82
	             213.09,181.82 215.09,180.55 215.09,180.55
	             215.09,180.55 217.45,178.91 217.45,178.91
	             217.45,178.91 219.64,177.64 219.64,177.64
	             219.64,177.64 221.64,176.18 221.64,176.18
	             221.64,176.18 223.45,174.91 223.45,174.91
	             223.45,174.91 224.91,173.64 224.91,173.64
	             224.91,173.64 168.18,88.18 168.18,88.18 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 197.27,65.64
	           C 197.27,65.64 168.36,88.18 168.36,88.18
	             168.36,88.18 225.27,173.45 225.27,173.45
	             225.27,173.45 255.82,152.73 255.82,152.73
	             255.82,152.73 197.27,65.64 197.27,65.64 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 216.73,50.00
	           C 216.73,50.00 197.45,65.45 197.45,65.45
	             197.45,65.45 256.18,152.36 256.18,152.36
	             256.18,152.36 272.91,141.27 272.91,141.27
	             272.91,141.27 287.64,129.45 287.64,129.45
	             287.64,129.45 216.73,50.00 216.73,50.00 Z"></path>
	    <path data-toggle="popover" data-trigger="hover focus" className="otherside" d="M 236.00,35.09
	           C 236.00,35.09 216.91,50.18 216.91,50.18
	             216.91,50.18 287.64,129.09 287.64,129.09
	             287.64,129.09 304.55,112.91 304.55,112.91
	             304.55,112.91 316.18,99.27 316.18,99.27
	             316.18,99.27 317.45,96.36 317.45,96.36
	             317.45,96.36 236.00,35.09 236.00,35.09 Z"></path> */}



	    {/* <path id="8-26" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="26" data-block="8" data-street="Colony Loop" data-front="68" data-rear="68" data-left="188" data-right="188" data-sqft="12,764" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 932.00,100.36
	           C 932.00,100.36 934.00,72.73 934.00,72.73
	             934.00,72.73 1010.18,77.09 1010.36,76.91
	             1010.55,76.73 1009.09,104.73 1009.09,104.73
	             1009.09,104.73 932.00,100.36 932.00,100.36 Z" data-original-title="Heritage Park"></path>
	    <path id="8-25" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="25" data-block="8" data-street="Colony Loop" data-front="30" data-rear="30" data-left="188" data-right="188" data-sqft="5,631" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 930.91,112.73
	           C 930.91,112.73 932.00,100.36 932.00,100.36
	             932.00,100.36 1008.91,104.73 1008.91,104.73
	             1008.91,104.73 1008.18,117.27 1008.18,117.27
	             1008.18,117.27 930.91,112.73 930.91,112.73 Z" data-original-title="Heritage Park"></path>
	    <path id="8-24" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="24" data-block="8" data-street="Colony Loop" data-front="30" data-rear="30" data-left="188" data-right="188" data-sqft="5,631" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 930.36,125.45
	           C 930.36,125.45 931.09,112.73 931.09,112.73
	             931.09,112.73 1008.18,117.27 1008.18,117.27
	             1008.18,117.27 1007.27,129.64 1007.27,129.64
	             1007.27,129.64 930.36,125.45 930.36,125.45 Z" data-original-title="Heritage Park"></path>
	    <path id="8-23" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="23" data-block="8" data-street="Colony Loop" data-front="50" data-rear="50" data-left="188" data-right="188" data-sqft="9,385" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 929.45,145.64
	           C 929.45,145.64 930.36,125.45 930.36,125.45
	             930.36,125.45 1007.27,129.64 1007.27,129.64
	             1007.27,129.64 1006.00,150.00 1006.00,150.00
	             1006.00,150.00 929.45,145.64 929.45,145.64 Z" data-original-title="Heritage Park"></path>
	    <path id="8-22" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="22" data-block="8" data-street="Colony Loop" data-front="50" data-rear="50" data-left="188" data-right="188" data-sqft="9,385" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 927.82,166.55
	           C 927.82,166.55 929.45,145.64 929.45,145.64
	             929.45,145.64 1005.82,150.00 1005.82,150.00
	             1005.82,150.00 1004.91,170.55 1004.91,170.55
	             1004.91,170.55 927.82,166.55 927.82,166.55 Z" data-original-title="Heritage Park"></path>
	    <path id="8-21" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="21" data-block="8" data-street="Colony Loop" data-front="30" data-rear="30" data-left="190" data-right="188" data-sqft="5,656" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 926.18,178.55
	           C 926.18,178.55 927.82,166.55 927.82,166.55
	             927.82,166.55 1004.55,170.55 1004.55,170.55
	             1004.55,170.55 1004.18,182.55 1004.18,182.55
	             1004.18,182.55 926.18,178.55 926.18,178.55 Z" data-original-title="Heritage Park"></path>
	    <path id="8-20" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="20" data-block="8" data-street="Colony Loop" data-front="30" data-rear="30" data-left="193" data-right="190" data-sqft="5,741" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1003.82,182.55
	           C 1003.82,182.55 1003.27,194.91 1003.27,194.91
	             1003.27,194.91 923.82,190.91 923.82,190.91
	             923.82,190.91 926.18,178.55 926.18,178.55
	             926.18,178.55 1003.82,182.55 1003.82,182.55 Z" data-original-title="Heritage Park"></path>
	    <path id="8-19" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="19" data-block="8" data-street="Colony Loop" data-front="59" data-rear="86" data-left="212" data-right="193" data-sqft="16,353" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 924.00,190.91
	           C 924.00,190.91 1003.09,194.91 1003.09,194.91
	             1003.09,194.91 1000.73,230.18 1000.73,230.18
	             1000.73,230.18 961.45,230.73 961.45,230.73
	             961.45,230.73 916.55,213.82 916.73,213.64
	             916.72,214.04 914.93,218.97 924.00,190.91 Z" data-original-title="Heritage Park"></path>
	    <path id="8-18" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="18" data-block="8" data-street="Colony Loop" data-front="103" data-rear="113" data-left="213" data-right="223" data-sqft="23,183" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 951.82,226.91
	           C 951.64,227.09 961.45,230.73 961.45,230.73
	             961.45,230.73 993.45,230.55 993.45,230.55
	             993.45,230.55 993.45,318.55 993.45,318.55
	             993.45,318.55 946.55,318.18 946.55,318.18
	             946.55,318.18 952.00,226.73 951.82,226.91 Z" data-original-title="Heritage Park"></path>
	    <path id="8-17" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="17" data-block="8" data-street="Colony Loop" data-front="31" data-rear="30" data-left="223" data-right="234" data-sqft="6,848" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 940.00,222.73
	           C 940.00,222.73 951.82,227.09 951.82,227.09
	             951.82,227.09 946.55,318.18 946.55,318.18
	             946.55,318.18 934.18,318.00 934.18,318.00
	             934.18,318.00 940.00,222.73 940.00,222.73 Z" data-original-title="Heritage Park"></path>
	    <path id="8-16" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="16" data-block="8" data-street="Colony Loop" data-front="31" data-rear="30" data-left="234" data-right="245" data-sqft="7,175" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 927.82,218.18
	           C 927.82,218.18 940.00,222.91 940.00,222.91
	             940.00,222.91 934.18,318.00 934.18,318.00
	             934.18,318.00 922.00,318.00 922.00,318.00
	             922.00,318.00 927.82,218.18 927.82,218.18 Z" data-original-title="Heritage Park"></path>
	    <path id="8-15" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="15" data-block="8" data-street="Sonora Way" data-front="29" data-rear="81" data-left="245" data-right="272" data-sqft="17,601" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 889.09,262.18
	           C 889.09,262.18 888.91,318.00 888.91,318.00
	             888.91,318.00 922.00,318.00 922.00,318.00
	             922.00,318.00 927.82,218.18 927.82,218.18
	             927.82,218.18 916.73,213.64 916.73,213.64
	             909.51,235.10 889.08,262.22 889.09,262.18 Z" data-original-title="Heritage Park"></path>
	    <path id="8-12" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="12" data-block="8" data-street="Colony Loop" data-front="72" data-rear="72" data-left="214" data-right="213" data-sqft="15,353" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1023.00,230.13
	           C 1023.00,230.13 993.50,230.38 993.50,230.38
	             993.50,230.38 993.50,318.50 993.50,318.50
	             993.50,318.50 1023.13,318.38 1023.13,318.38
	             1023.13,318.38 1023.00,230.13 1023.00,230.13 Z" data-original-title="Heritage Park"></path>
	    <path id="8-11" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="11" data-block="8" data-street="Colony Loop" data-front="30" data-rear="30" data-left="214" data-right="214" data-sqft="6,414" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1035.38,230.13
	           C 1035.38,230.13 1023.00,230.13 1023.00,230.13
	             1023.00,230.13 1023.13,318.38 1023.13,318.38
	             1023.13,318.38 1034.88,318.50 1034.88,318.50
	             1034.88,318.50 1035.38,230.13 1035.38,230.13 Z" data-original-title="Heritage Park"></path>
	    <path id="8-10" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="10" data-block="8" data-street="Colony Loop" data-front="30" data-rear="30" data-left="214" data-right="214" data-sqft="6,424" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1035.38,230.13
	           C 1035.50,230.00 1047.38,230.00 1047.38,230.00
	             1047.38,230.00 1047.75,318.63 1047.75,318.63
	             1047.75,318.63 1035.00,318.63 1035.00,318.63
	             1035.00,318.63 1035.25,230.25 1035.38,230.13 Z" data-original-title="Heritage Park"></path>
	    <path id="8-9" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="9" data-block="8" data-street="Colony Loop" data-front="112" data-rear="110" data-left="216" data-right="214" data-sqft="23,799" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1093.13,230.00
	           C 1093.13,230.00 1092.38,318.88 1092.38,318.88
	             1092.38,318.88 1047.75,318.75 1047.75,318.75
	             1047.75,318.75 1047.38,230.00 1047.38,230.00
	             1047.38,230.00 1093.13,230.00 1093.13,230.00 Z" data-original-title="Heritage Park"></path>
	    <path id="8-8" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="8" data-block="8" data-street="Colony Loop" data-front="104" data-rear="89" data-left="221" data-right="226" data-sqft="21,525" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1093.88,193.88
	           C 1093.88,193.88 1093.38,230.00 1093.38,230.00
	             1093.38,230.00 1000.63,230.13 1000.63,230.13
	             1000.63,230.13 1003.75,187.75 1003.75,187.75
	             1003.75,187.75 1093.88,193.88 1093.88,193.88 Z" data-original-title="Heritage Park"></path>
	    <path id="8-7" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="7" data-block="8" data-street="Colony Loop" data-front="30" data-rear="30" data-left="219" data-right="221" data-sqft="6,604" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1004.55,175.45
	           C 1004.55,175.45 1003.82,187.82 1003.82,187.82
	             1003.82,187.82 1094.00,193.82 1094.00,193.82
	             1094.00,193.82 1093.82,180.91 1093.82,180.91
	             1093.82,180.91 1004.55,175.45 1004.55,175.45 Z" data-original-title="Heritage Park"></path>
	    <path id="8-6" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="6" data-block="8" data-street="Colony Loop" data-front="30" data-rear="30" data-left="218" data-right="219" data-sqft="6,558" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1005.27,163.64
	           C 1005.27,163.64 1094.18,168.55 1094.18,168.55
	             1094.18,168.55 1093.82,180.91 1093.82,180.91
	             1093.82,180.91 1004.55,175.45 1004.55,175.45
	             1004.55,175.45 1005.27,163.64 1005.27,163.64 Z" data-original-title="Heritage Park"></path>
	    <path id="8-5" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="5" data-block="8" data-street="Colony Loop" data-front="50" data-rear="50" data-left="215" data-right="218" data-sqft="10,825" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1006.36,142.91
	           C 1006.55,142.73 1094.36,148.00 1094.36,148.00
	             1094.36,148.00 1094.18,168.55 1094.18,168.55
	             1094.18,168.55 1005.27,163.64 1005.27,163.64
	             1005.27,163.64 1006.18,143.09 1006.36,142.91 Z" data-original-title="Heritage Park"></path>
	    <path id="8-4" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="4" data-block="8" data-street="Colony Loop" data-front="50" data-rear="50" data-left="213" data-right="215" data-sqft="10,695" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1094.36,127.45
	           C 1094.36,127.45 1094.36,148.00 1094.36,148.00
	             1094.36,148.00 1006.36,142.91 1006.36,142.91
	             1006.36,142.91 1007.82,122.18 1007.82,122.18
	             1007.82,122.18 1094.36,127.45 1094.36,127.45 Z" data-original-title="Heritage Park"></path>
	    <path id="8-3" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="3" data-block="8" data-street="Colony Loop" data-front="30" data-rear="30" data-left="211" data-right="213" data-sqft="6,355" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1094.73,115.27
	           C 1094.73,115.27 1094.55,127.45 1094.55,127.45
	             1094.55,127.45 1007.82,122.18 1007.82,122.18
	             1007.82,122.18 1008.55,109.64 1008.55,109.64
	             1008.55,109.64 1094.73,115.27 1094.73,115.27 Z" data-original-title="Heritage Park"></path>
	    <path id="8-2" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="2" data-block="8" data-street="Colony Loop" data-front="30" data-rear="30" data-left="209" data-right="211" data-sqft="6,308" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1094.73,102.55
	           C 1094.73,102.55 1094.73,115.27 1094.73,115.27
	             1094.73,115.27 1008.55,109.64 1008.55,109.64
	             1008.55,109.64 1009.45,97.64 1009.45,97.64
	             1009.45,97.64 1094.73,102.55 1094.73,102.55 Z" data-original-title="Heritage Park"></path>
	    <path id="8-1" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="1" data-block="8" data-street="Colony Loop" data-front="50" data-rear="50" data-left="207" data-right="209" data-sqft="10,410" data-grade="3" data-price="0" data-placement="left" data-type="Townhome" d="M 1095.64,82.36
	           C 1095.64,82.36 1094.91,102.55 1094.91,102.55
	             1094.91,102.55 1009.45,97.64 1009.45,97.64
	             1009.45,97.64 1010.36,77.09 1010.36,77.09
	             1010.36,77.09 1095.64,82.36 1095.64,82.36 Z" data-original-title="Heritage Park"></path>
	    <path id="7-16" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="16" data-block="7" data-street="Community Loop" data-front="105" data-rear="105" data-left="135" data-right="137" data-sqft="14,268" data-grade="4" data-price="0" data-placement="left" data-type="Single Family <br> Estero-Office" d="M 664.75,516.75
	           C 664.75,516.75 707.50,517.50 707.50,517.50
	             707.50,517.50 706.75,573.63 706.75,573.63
	             706.75,573.63 664.13,573.38 664.13,573.38
	             664.13,573.38 664.75,516.75 664.75,516.75 Z" data-original-title="Heritage Park"></path>
	    <path id="7-15" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="15" data-block="7" data-street="Community Loop" data-front="82" data-rear="84" data-left="137" data-right="138" data-sqft="11,416" data-grade="3" data-price="0" data-placement="left" data-type="Single Family <br> Estero" d="M 630.63,515.88
	           C 630.63,515.88 664.75,516.88 664.75,516.88
	             664.75,516.88 664.13,573.25 664.13,573.25
	             664.13,573.25 629.88,573.25 629.88,573.25
	             629.88,573.25 630.63,515.88 630.63,515.88 Z" data-original-title="Heritage Park"></path>
	    <path id="7-14" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="14" data-block="7" data-street="Community Loop" data-front="69" data-rear="140" data-left="138" data-right="175" data-sqft="15,412" data-grade="3" data-price="0" data-placement="left" data-type="Single Family <br> Sanibel" d="M 572.75,573.38
	           C 572.75,573.38 629.88,573.13 629.88,573.13
	             629.88,573.13 630.63,515.75 630.63,515.75
	             618.10,516.05 604.12,508.50 604.13,508.50
	             604.13,508.50 572.75,573.38 572.75,573.38 Z" data-original-title="Heritage Park"></path>
	    <path id="7-13" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="13" data-block="7" data-street="Community Loop" data-front="65" data-rear="151" data-left="175" data-right="154" data-sqft="17,368" data-grade="3" data-price="0" data-placement="left" data-type="Single Family <br> Captiva" d="M 583.50,491.88
	           C 583.50,491.88 538.38,536.75 538.38,536.75
	             538.38,536.75 547.63,573.25 547.63,573.25
	             547.63,573.25 572.88,573.25 572.88,573.25
	             572.88,573.25 604.13,508.50 604.13,508.50
	             595.67,503.82 590.01,498.92 583.50,491.88 Z" data-original-title="Heritage Park"></path>
	    <path id="7-12" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="12" data-block="7" data-street="Community Loop" data-front="80" data-rear="96" data-left="154" data-right="135" data-sqft="11,650" data-grade="3" data-price="0" data-placement="left" data-type="Single Family <br> Marco" d="M 524.50,499.38
	           C 528.84,509.35 535.50,520.75 538.38,536.75
	             538.38,536.75 583.38,492.00 583.38,492.00
	             583.38,492.02 575.92,483.21 566.88,464.13
	             566.88,464.13 524.50,499.38 524.50,499.38 Z" data-original-title="Heritage Park"></path>
	    <path id="7-11" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="11" data-block="7" data-street="Community Loop" data-front="90" data-rear="75" data-left="135" data-right="131" data-sqft="10,773" data-grade="3" data-price="0" data-placement="left" data-type="Single Family <br> Naples" d="M 509.00,474.13
	           C 509.00,474.13 514.00,477.88 514.00,477.88
	             514.00,477.88 524.38,499.50 524.38,499.50
	             524.38,499.50 566.88,464.00 566.88,464.00
	             564.35,453.42 558.34,443.18 546.50,434.50
	             546.50,434.50 509.00,474.13 509.00,474.13 Z" data-original-title="Heritage Park"></path>
	    <path id="7-10" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="10" data-block="7" data-street="Community Loop" data-front="89" data-rear="67" data-left="131" data-right="129" data-sqft="10,111" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 515.88,414.63
	           C 515.88,414.63 546.38,434.63 546.38,434.63
	             546.38,434.63 509.00,474.13 509.00,474.13
	             509.00,474.13 486.25,458.75 486.25,458.75
	             486.25,458.75 515.88,414.63 515.88,414.63 Z" data-original-title="Heritage Park"></path>
	    <path id="7-9" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="9" data-block="7" data-street="Community Loop" data-front="76" data-rear="80" data-left="129" data-right="129" data-sqft="10,066" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 490.25,397.25
	           C 490.25,397.25 515.88,414.63 515.88,414.63
	             515.88,414.63 486.13,458.88 486.13,458.88
	             486.13,458.88 459.00,440.25 459.00,440.25
	             459.00,440.25 490.25,397.25 490.25,397.25 Z" data-original-title="Heritage Park"></path>
	    <path id="7-8" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="8" data-block="7" data-street="Community Loop" data-front="54" data-rear="187" data-left="129" data-right="236" data-sqft="16,234" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 388.13,416.63
	           C 388.13,416.63 430.25,427.50 430.25,427.50
	             430.25,427.50 443.63,430.00 443.63,430.00
	             443.63,430.00 459.13,440.13 459.13,440.13
	             459.13,440.13 490.25,397.25 490.25,397.25
	             487.29,397.25 476.29,381.47 476.63,379.88
	             476.63,379.88 388.13,416.63 388.13,416.63 Z" data-original-title="Heritage Park"></path>
	    <path id="7-7" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="7" data-block="7" data-street="Community Loop" data-front="58" data-rear="193" data-left="236" data-right="176" data-sqft="22,590" data-grade="3" data-price="0" data-placement="left" data-type="Single Family <br> Connelly - Patio" d="M 401.88,353.00
	           C 401.88,353.00 374.63,413.75 374.63,413.75
	             374.63,413.75 388.25,416.63 388.38,416.50
	             388.50,416.38 476.25,380.00 476.25,380.00
	             476.25,380.00 475.88,376.63 475.88,376.63
	             475.88,376.63 474.63,372.50 474.63,372.50
	             474.63,372.50 473.88,368.38 473.88,368.38
	             473.88,368.38 474.13,356.50 474.13,356.50
	             474.13,356.50 401.88,353.00 401.88,353.00 Z" data-original-title="Heritage Park"></path>
	    <path id="7-6" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="6" data-block="7" data-street="Community Loop" data-front="63" data-rear="147" data-left="176" data-right="140" data-sqft="15,918" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 436.75,304.25
	           C 436.75,304.25 415.75,322.88 415.75,322.88
	             415.75,322.88 402.00,353.00 402.00,353.00
	             402.00,353.00 474.25,356.50 474.25,356.50
	             474.25,356.50 474.75,352.88 474.75,352.88
	             474.75,352.88 476.00,349.63 476.00,349.63
	             476.00,349.63 477.38,346.13 477.38,346.13
	             477.38,346.13 478.75,343.13 478.75,343.13
	             478.75,343.13 480.13,341.00 480.13,341.00
	             480.13,341.00 481.50,338.88 481.50,338.88
	             481.50,338.88 483.13,337.00 483.13,337.00
	             483.13,337.00 485.13,334.00 485.13,334.00
	             485.13,334.00 436.75,304.25 436.75,304.25 Z" data-original-title="Heritage Park"></path>
	    <path id="7-5" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="5" data-block="7" data-street="Community Loop" data-front="79" data-rear="110" data-left="140" data-right="127" data-sqft="12,337" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 470.27,274.18
	           C 470.27,274.18 508.18,310.09 508.18,310.09
	             508.18,310.09 485.09,333.91 485.09,333.91
	             485.09,333.91 436.82,304.27 436.82,304.27
	             436.82,304.27 470.27,274.18 470.27,274.18 Z" data-original-title="Heritage Park"></path>
	    <path id="7-4" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="4" data-block="7" data-street="Community Loop" data-front="80" data-rear="80" data-left="127" data-right="121" data-sqft="9,927" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 530.91,286.00
	           C 530.91,286.00 508.18,310.09 508.18,310.09
	             508.18,310.09 470.45,274.09 470.45,274.09
	             470.45,274.09 495.45,252.09 495.55,252.09
	             495.64,252.09 530.91,286.00 530.91,286.00 Z" data-original-title="Heritage Park"></path>
	    <path id="7-3" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="3" data-block="7" data-street="Community Loop" data-front="79" data-rear="79" data-left="121" data-right="126" data-sqft="9,629" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 553.18,262.27
	           C 553.18,262.27 530.82,285.91 530.82,285.91
	             530.82,285.91 495.64,252.18 495.64,252.18
	             495.64,252.18 501.55,246.00 501.55,246.00
	             501.55,246.00 516.18,226.82 516.18,226.82" data-original-title="Heritage Park"></path>
	    <path id="7-2" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="2" data-block="7" data-street="Community Loop" data-front="82" data-rear="89" data-left="126" data-right="138" data-sqft="11,233" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 538.73,198.55
	           C 538.73,198.55 577.45,238.91 577.45,238.91
	             577.45,238.91 553.27,262.18 553.27,262.18
	             553.27,262.18 516.18,226.91 516.18,226.91
	             516.18,226.91 538.73,198.55 538.73,198.55 Z" data-original-title="Heritage Park"></path>
	    <path id="7-1" className="has-hover greenery" title="" data-toggle="popover" data-trigger="hover focus" data-lot="1" data-block="7" data-street="Community Loop" data-placement="left" d="M 572.73,154.55
	           C 572.73,154.55 538.73,198.55 538.73,198.55
	             538.73,198.55 577.64,239.09 577.64,239.09
	             577.64,239.09 582.73,234.36 582.73,234.36
	             582.73,234.36 587.82,229.09 587.82,229.09
	             587.82,229.09 593.09,223.09 593.09,223.09
	             593.09,223.09 598.91,215.64 598.91,215.64
	             598.91,215.64 604.18,206.55 604.18,206.55
	             604.18,206.55 608.73,197.27 608.73,197.27
	             608.73,197.27 611.09,190.73 611.09,190.73
	             611.09,190.73 613.45,183.64 613.45,183.64
	             613.45,183.64 614.55,178.00 614.55,178.00
	             614.55,178.00 616.18,171.09 616.18,171.09
	             616.18,171.09 572.73,154.55 572.73,154.55 Z" data-original-title="Heritage Park"></path>
	    <path id="G-2" className="has-hover greenery no-address" title="" data-toggle="popover" data-trigger="hover focus" data-placement="left" d="M 485.00,574.33
           C 485.00,574.33 707.33,575.33 707.33,575.33
             707.33,575.33 706.67,604.33 706.67,604.33
             706.67,604.33 484.67,604.00 484.67,604.00
             484.67,604.00 485.00,574.33 485.00,574.33 Z" data-original-title="Landscape Buffer and Walking Trail"></path>
        <path id="G-1" className="has-hover greenery no-address" title="" data-toggle="popover" data-trigger="hover focus" d="M 26.58,572.76
           C 26.58,572.76 449.33,574.00 449.33,574.00
             449.33,574.00 449.30,603.95 449.30,603.95
             449.30,603.95 26.25,603.53 26.25,603.53
             26.25,603.53 26.58,572.76 26.58,572.76 Z" data-original-title="Landscape Buffer and Walking Trail"></path>
        <path id="Park" className="has-hover greenery no-address" title="" data-toggle="popover" data-trigger="hover focus" data-placement="left" d="M 888.67,262.67
           C 888.67,262.67 883.33,604.67 883.33,604.67
             883.33,604.67 747.33,604.33 747.33,604.33
             747.33,604.33 749.33,467.33 749.33,467.33
             749.33,467.33 747.00,404.33 802.33,355.00
             822.00,333.00 888.67,262.67 888.67,262.67 Z" data-original-title="Neighborhood Park"></path>
        <path id="Daba 1" data-price="1" d="M 900.00,340.00
           C 900.00,340.00 940.00,340.00 940.00,340.00
             940.00,340.00 940.00,360.00 940.00,360.00
             940.00,360.00 900.00,360.00 900.00,360.00
             900.00,360.00 900.00,340.00 900.00,340.00 Z"></path>
             <text x="950.00" y="355.00">Available</text>
        <path id="Daba 2" data-price="-1" d="M 900.00,370.00
           C 900.00,370.00 940.00,370.00 940.00,370.00
             940.00,370.00 940.00,390.00 940.00,390.00
             940.00,390.00 900.00,390.00 900.00,390.00
             900.00,390.00 900.00,370.00 900.00,370.00 Z"></path>
             <text x="950.00" y="385.00">SPEC</text>		
        <path id="Daba 3" data-price="0" d="M 900.00,400.00
           C 900.00,400.00 900.00,420.00 900.00,420.00
             900.00,420.00 940.00,420.00 940.00,420.00
             940.00,420.00 940.00,400.00 940.00,400.00
             940.00,400.00 900.00,400.00 900.00,400.00 Z"></path>
             <text x="950.00" y="415.00">SOLD</text>		
	    <path id="6-13" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="13" data-block="6" data-street="Community Loop" data-front="74" data-rear="74" data-left="130" data-right="130" data-sqft="9,578" data-grade="1" data-price="0" data-placement="left" data-type="Single Family" d="M 538.72,313.97
	           C 538.72,313.97 517.78,335.72 517.78,335.72
	             517.78,335.72 556.13,372.47 556.13,372.47
	             556.13,372.47 577.00,350.38 577.00,350.38
	             577.00,350.38 538.72,313.97 538.72,313.97 Z" data-original-title="Heritage Park"></path>
	    <path id="6-12" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="12" data-block="6" data-street="Community Loop" data-front="206" data-rear="0" data-left="130" data-right="80" data-sqft="11,254" data-grade="2" data-price="0" data-placement="left" data-type="Single Family" d="M 556.13,372.50
	           C 556.13,372.50 533.50,396.50 533.50,396.50
	             533.50,396.50 505.50,377.50 505.50,377.50
	             505.50,377.50 503.69,375.94 503.69,375.94
	             503.69,375.94 501.75,373.81 501.75,373.81
	             501.75,373.81 500.56,371.75 500.56,371.75
	             500.56,371.75 499.50,369.81 499.50,369.81
	             499.50,369.81 498.81,368.13 498.81,368.13
	             498.81,368.13 498.38,366.81 498.38,366.81
	             498.38,366.81 498.38,359.13 498.38,359.13
	             498.38,359.13 499.56,356.88 499.56,356.88
	             499.56,356.88 501.69,353.06 501.69,353.06
	             501.69,353.06 503.50,350.69 503.50,350.69
	             503.50,350.69 517.81,335.75 517.81,335.75
	             517.81,335.75 556.13,372.50 556.13,372.50 Z" data-original-title="Heritage Park"></path>
	    <path id="6-11" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="11" data-block="6" data-street="Community Loop" data-front="78" data-rear="73" data-left="126" data-right="113" data-sqft="9,012" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 590.75,379.00
	           C 590.75,379.00 569.25,358.50 569.25,358.50
	             569.25,358.50 533.50,396.50 533.50,396.50
	             533.50,396.50 559.88,414.25 559.88,414.25
	             559.88,414.25 590.75,379.00 590.75,379.00 Z" data-original-title="Heritage Park"></path>
	    <path id="6-10" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="10" data-block="6" data-street="Community Loop" data-front="87" data-rear="74" data-left="113" data-right="120" data-sqft="8,975" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 613.00,400.09
	           C 613.00,400.09 590.82,379.00 590.82,379.00
	             590.82,379.00 559.73,414.36 559.73,414.36
	             572.98,423.25 577.03,430.09 583.55,439.45
	             583.55,439.45 613.00,400.09 613.00,400.09 Z" data-original-title="Heritage Park"></path>
	    <path id="6-9" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="9" data-block="6" data-street="Community Loop" data-front="93" data-rear="66" data-left="120" data-right="155" data-sqft="10,100" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 632.97,418.73
	           C 632.97,418.73 600.49,473.64 600.49,473.64
	             592.61,463.44 589.23,449.59 583.59,439.44
	             583.59,439.44 613.04,400.05 613.04,400.05
	             613.04,400.05 632.97,418.73 632.97,418.73 Z" data-original-title="Heritage Park"></path>
	    <path id="6-8" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="8" data-block="6" data-street="Community Loop" data-front="92" data-rear="48" data-left="155" data-right="165" data-sqft="11,432" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 600.55,473.55
	           C 600.55,473.55 633.00,418.73 633.00,418.73
	             633.00,418.73 650.73,426.73 650.73,426.73
	             650.73,426.73 633.18,491.64 633.18,491.64
	             633.18,491.64 611.29,489.37 600.55,473.55 Z" data-original-title="Heritage Park"></path>
	    <path id="6-7" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="7" data-block="6" data-street="Community Loop" data-front="93" data-rear="58" data-left="165" data-right="139" data-sqft="11,179" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 671.64,492.55
	           C 671.64,492.55 633.18,491.73 633.18,491.73
	             633.18,491.73 650.73,426.73 650.73,426.73
	             650.73,426.73 672.55,435.64 672.55,435.64
	             672.55,435.64 671.64,492.55 671.64,492.55 Z" data-original-title="Heritage Park"></path>
	    <path id="6-6" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="6" data-block="6" data-street="Community Loop" data-front="90" data-rear="100" data-left="139" data-right="101" data-sqft="10,857" data-grade="4" data-price="0" data-placement="left" data-type="Single Family" d="M 710.64,451.73
	           C 709.53,451.50 706.69,492.45 708.00,492.73
	             708.00,492.73 671.64,492.45 671.64,492.45
	             671.64,492.45 672.55,435.73 672.55,435.73
	             672.55,435.73 710.64,451.73 710.64,451.73 Z" data-original-title="Heritage Park"></path>
	    <path id="6-5" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="5" data-block="6" data-street="Reserve Lane" data-front="92" data-rear="133" data-left="123" data-right="133" data-sqft="13,849" data-grade="4" data-price="0" data-placement="left" data-type="Single Family" d="M 691.00,385.55
	           C 691.00,385.55 724.73,403.36 724.73,403.36
	             722.46,404.97 708.74,445.73 710.64,451.73
	             710.64,451.73 660.09,430.45 660.09,430.45
	             660.09,430.45 691.00,385.55 691.00,385.55 Z" data-original-title="Heritage Park"></path>
	    <path id="6-4" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="4" data-block="6" data-street="Reserve Lane" data-front="89" data-rear="99" data-left="133" data-right="134" data-sqft="12,672" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 658.91,368.18
	           C 659.16,367.81 691.52,384.73 690.91,385.64
	             690.91,385.64 660.18,430.36 660.18,430.36
	             660.18,430.36 650.82,426.73 650.82,426.73
	             650.82,426.73 633.00,418.73 633.00,418.73
	             633.00,418.73 625.09,411.27 625.09,411.27
	             625.09,411.27 658.91,368.18 658.91,368.18 Z" data-original-title="Heritage Park"></path>
	    <path id="6-3" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="3" data-block="6" data-street="Reserve Lane" data-front="90" data-rear="97" data-left="134" data-right="117" data-sqft="11,505" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 628.09,348.36
	           C 628.09,348.36 658.73,368.36 658.73,368.36
	             658.73,368.36 625.09,411.18 625.09,411.18
	             625.09,411.18 596.00,384.09 596.00,384.09
	             596.00,384.09 628.09,348.36 628.09,348.36 Z" data-original-title="Heritage Park"></path>
	    <path id="6-2" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="2" data-block="6" data-street="Reserve Lane" data-front="87" data-rear="90" data-left="117" data-right="118" data-sqft="10,364" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 602.82,323.18
	           C 602.08,322.86 626.10,347.49 628.09,348.36
	             628.09,348.36 596.09,384.00 596.09,384.00
	             596.09,384.00 569.27,358.55 569.27,358.55
	             569.27,358.55 602.82,323.18 602.82,323.18 Z" data-original-title="Heritage Park"></path>
	    <path id="6-1" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="1" data-block="6" data-street="Reserve Lane" data-front="96" data-rear="91" data-left="130" data-right="130" data-sqft="12,123" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 565.63,285.25
	           C 565.63,285.25 538.75,314.00 538.75,314.00
	             538.75,314.00 577.00,350.38 577.00,350.38
	             577.00,350.38 602.88,323.13 602.88,323.13
	             602.88,323.13 565.63,285.25 565.63,285.25 Z" data-original-title="Heritage Park"></path>
	    <path id="5-11" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="11" data-block="5" data-street="Community Loop" data-front="75" data-rear="101" data-left="120" data-right="114" data-sqft="10,106" data-grade="1" data-price="0" data-placement="left" data-type="Single Family" d="M 643.75,273.00
	           C 643.75,273.00 669.25,240.25 669.25,240.25
	             669.25,240.25 625.75,217.00 625.75,217.00
	             626.44,217.18 609.59,242.88 608.63,242.63
	             608.63,242.63 643.75,273.00 643.75,273.00 Z" data-original-title="Heritage Park"></path>
	    <path id="5-10" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="10" data-block="5" data-street="Community Loop" data-front="87" data-rear="97" data-left="114" data-right="125" data-sqft="10,875" data-grade="2" data-price="0" data-placement="left" data-type="Single Family" d="M 618.88,304.50
	           C 618.88,304.50 583.25,268.00 583.25,268.00
	             583.25,268.00 608.75,242.75 608.75,242.75
	             608.75,242.75 643.75,273.00 643.75,273.00
	             643.75,273.00 618.88,304.50 618.88,304.50 Z" data-original-title="Heritage Park"></path>
	    <path id="5-9" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="9" data-block="5" data-street="Reserve Lane" data-front="85" data-rear="77" data-left="134" data-right="149" data-sqft="11,440" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 679.13,278.88
	           C 679.13,278.88 653.38,260.75 653.38,260.75
	             653.38,260.75 618.75,304.63 618.75,304.63
	             618.75,304.63 643.25,329.00 643.25,329.00
	             643.25,329.00 679.13,278.88 679.13,278.88 Z" data-original-title="Heritage Park"></path>
	    <path id="5-8" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="8" data-block="5" data-street="Reserve Lane" data-front="83" data-rear="82" data-left="149" data-right="146" data-sqft="12,254" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 706.63,297.63
	           C 706.63,297.63 679.13,278.88 679.13,278.88
	             679.13,278.88 643.50,328.63 643.50,328.63
	             643.50,328.63 652.38,335.88 652.38,335.88
	             652.38,335.88 660.13,340.75 660.13,340.75
	             660.13,340.75 672.25,346.88 672.25,346.88
	             672.25,346.88 706.63,297.63 706.63,297.63 Z" data-original-title="Heritage Park"></path>
	    <path id="5-7" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="7" data-block="5" data-street="Reserve Lane" data-front="83" data-rear="82" data-left="146" data-right="137" data-sqft="11,577" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 734.63,317.00
	           C 734.63,317.00 706.63,297.63 706.63,297.63
	             706.63,297.63 672.13,347.13 672.13,347.13
	             672.13,347.13 701.63,362.88 701.63,362.88
	             701.63,362.88 734.63,317.00 734.63,317.00 Z" data-original-title="Heritage Park"></path>
	    <path id="5-6" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="6" data-block="5" data-street="Reserve Lane" data-front="96" data-rear="105" data-left="137" data-right="127" data-sqft="12,779" data-grade="4" data-price="0" data-placement="left" data-type="Single Family" d="M 770.25,341.63
	           C 770.25,341.63 760.88,351.25 760.88,351.25
	             760.88,351.25 752.75,359.25 752.75,359.25
	             752.75,359.25 746.25,367.25 746.25,367.25
	             746.25,367.25 741.88,373.63 741.88,373.63
	             741.88,373.63 736.50,381.50 736.50,381.50
	             736.50,381.50 701.75,362.75 701.75,362.75
	             701.75,362.75 734.63,317.00 734.63,317.00
	             734.63,317.00 770.25,341.63 770.25,341.63 Z" data-original-title="Heritage Park"></path>
	    <path id="5-5" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="5" data-block="5" data-street="Limited Lane" data-front="106" data-rear="85" data-left="138" data-right="151" data-sqft="13,758" data-grade="2" data-price="0" data-placement="left" data-type="Single Family <br> Bonita Springs" d="M 777.88,270.38
	           C 777.88,270.38 741.13,321.63 741.13,321.63
	             741.13,321.63 770.13,341.50 770.13,341.50
	             770.13,341.50 809.38,299.75 809.38,299.75
	             809.38,299.75 777.88,270.38 777.88,270.38 Z" data-original-title="Heritage Park"></path>
	    <path id="5-4" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="4" data-block="5" data-street="Limited Lane" data-front="85" data-rear="79" data-left="151" data-right="150" data-sqft="12,384" data-grade="1" data-price="0" data-placement="left" data-type="Single Family <br> Cape Coral" d="M 748.13,251.38
	           C 748.13,251.38 777.75,270.50 777.75,270.50
	             777.75,270.50 741.25,321.50 741.25,321.50
	             741.25,321.50 714.50,303.13 714.50,303.13
	             714.50,303.13 748.13,251.38 748.13,251.38 Z" data-original-title="Heritage Park"></path>
	    <path id="5-3" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="3" data-block="5" data-street="Limited Lane" data-front="85" data-rear="85" data-left="150" data-right="148" data-sqft="12,656" data-grade="1" data-price="0" data-placement="left" data-type="Single Family <br> Miromar" d="M 718.88,232.25
	           C 718.88,232.25 748.13,251.38 748.13,251.38
	             748.13,251.38 714.63,303.00 714.63,303.00
	             714.63,303.00 685.63,283.38 685.63,283.38
	             685.63,283.38 718.88,232.25 718.88,232.25 Z" data-original-title="Heritage Park"></path>
	    <path id="5-2" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="2" data-block="5" data-street="Limited Lane" data-front="84" data-rear="97" data-left="148" data-right="145" data-sqft="13,221" data-grade="1" data-price="0" data-placement="left" data-type="Single Family <br> San Carlos" d="M 690.13,213.75
	           C 690.13,213.75 718.75,232.38 718.75,232.38
	             718.75,232.38 685.63,283.38 685.63,283.38
	             685.63,283.38 653.50,260.88 653.38,260.75
	             653.25,260.63 690.13,213.75 690.13,213.75 Z" data-original-title="Heritage Park"></path>
	    <path id="5-1" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="1" data-block="5" data-street="Community Loop" data-front="93" data-rear="82" data-left="148" data-right="120" data-sqft="11,500" data-grade="2" data-price="0" data-placement="left" data-type="Single Family" d="M 639.50,181.31
	           C 639.50,181.31 636.75,189.81 636.75,189.81
	             636.75,189.81 634.50,196.75 634.50,196.75
	             634.50,196.75 632.56,202.69 632.56,202.69
	             632.56,202.69 630.44,207.38 630.44,207.38
	             630.44,207.38 628.56,211.63 628.56,211.63
	             628.56,211.63 626.13,217.25 626.13,217.25
	             626.13,217.25 669.31,240.31 669.31,240.31
	             669.31,240.31 690.06,213.88 690.06,213.88
	             690.06,213.88 639.50,181.31 639.50,181.31 Z" data-original-title="Heritage Park"></path>
	    <path id="4-20" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="20" data-block="4" data-street="Community Loop" data-front="51" data-rear="51" data-left="150" data-right="150" data-sqft="7,723" data-grade="1" data-price="0" data-placement="left" data-type="Twinhome" d="M 651.64,96.18
	           C 651.64,96.18 649.64,117.36 649.64,117.36
	             649.64,117.36 711.27,121.64 711.27,121.64
	             711.27,121.64 712.82,100.36 712.82,100.36
	             712.82,100.36 651.64,96.18 651.64,96.18 Z" data-original-title="Heritage Park"></path>
	    <path id="4-19" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="19" data-block="4" data-street="Community Loop" data-front="51" data-rear="55" data-left="150" data-right="135" data-sqft="7,468" data-grade="1" data-price="0" data-placement="left" data-type="Twinhome" d="M 702.73,142.82
	           C 702.73,142.82 677.91,140.64 677.91,140.64
	             677.91,140.64 654.91,138.64 654.91,138.64
	             654.91,138.64 647.82,138.36 647.82,138.36
	             647.82,138.36 649.64,117.36 649.64,117.36
	             649.64,117.36 711.18,121.64 711.18,121.64
	             711.18,121.64 710.55,129.45 710.55,129.45
	             710.55,129.45 702.73,142.82 702.73,142.82 Z" data-original-title="Heritage Park"></path>
	    <path id="4-18" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="18" data-block="4" data-street="Community Loop" data-front="45" data-rear="102" data-left="135" data-right="105" data-sqft="8,214" data-grade="2" data-price="0" data-placement="left" data-type="Twinhome" d="M 681.73,178.73
	           C 681.73,178.73 678.27,176.45 678.27,176.45
	             678.27,176.45 675.27,174.55 675.27,174.55
	             675.27,174.55 670.55,171.45 670.55,171.45
	             670.55,171.45 664.36,167.64 664.36,167.64
	             664.36,167.64 659.45,164.64 659.45,164.64
	             659.45,164.64 656.55,162.45 656.55,162.45
	             656.55,162.45 653.00,160.55 653.00,160.55
	             653.00,160.55 646.00,156.64 646.00,156.64
	             646.00,156.64 646.73,148.09 646.73,148.09
	             646.73,148.09 647.82,138.36 647.82,138.36
	             647.82,138.36 656.09,138.73 656.09,138.73
	             656.09,138.73 677.73,140.64 677.73,140.64
	             677.73,140.64 702.55,142.82 702.64,142.91
	             702.73,143.00 681.73,178.73 681.73,178.73 Z" data-original-title="Heritage Park"></path>
	    <path id="4-17" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="17" data-block="4" data-street="Limited Lane" data-front="53" data-rear="60" data-left="138" data-right="139" data-sqft="7,847" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome <br> Key West (L)" d="M 731.25,142.63
	           C 731.13,142.63 699.63,190.63 699.63,190.63
	             699.63,190.63 681.75,178.75 681.75,178.75
	             681.75,178.75 710.50,129.50 710.50,129.50
	             710.50,129.50 731.38,142.63 731.25,142.63 Z" data-original-title="Heritage Park"></path>
	    <path id="4-16" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="16" data-block="4" data-street="Limited Lane" data-front="53" data-rear="53" data-left="139" data-right="141" data-sqft="7,413" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome <br> Key West (R)" d="M 750.00,154.00
	           C 750.00,154.00 731.25,142.63 731.25,142.63
	             731.25,142.63 699.63,190.50 699.63,190.50
	             699.63,190.50 718.00,202.38 718.00,202.38
	             718.00,202.38 750.00,154.00 750.00,154.00 Z" data-original-title="Heritage Park"></path>
	    <path id="4-15" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="15" data-block="4" data-street="Limited Lane" data-front="53" data-rear="53" data-left="141" data-right="142" data-sqft="7,484" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome <br> Boca Raton (L)" d="M 750.00,154.00
	           C 750.00,154.00 718.25,202.00 718.13,202.13
	             718.00,202.25 736.38,214.38 736.38,214.38
	             736.38,214.38 768.50,165.25 768.50,165.25
	             768.50,165.25 750.00,154.00 750.00,154.00 Z" data-original-title="Heritage Park"></path>
	    <path id="4-14" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="14" data-block="4" data-street="Limited Lane" data-front="53" data-rear="53" data-left="142" data-right="143" data-sqft="7,556" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome <br> Boca Raton (R)" d="M 768.50,165.38
	           C 768.50,165.38 736.50,214.25 736.50,214.25
	             736.50,214.25 754.88,226.00 754.88,226.00
	             754.88,226.00 786.88,176.63 786.88,176.63
	             786.88,176.63 768.50,165.38 768.50,165.38 Z" data-original-title="Heritage Park"></path>
	    <path id="4-13" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="13" data-block="4" data-street="Limited Lane" data-front="53" data-rear="54" data-left="143" data-right="152" data-sqft="7,835" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome <br> Key West (L)" d="M 807.50,185.38
	           C 807.50,185.38 773.00,237.50 773.00,237.50
	             773.00,237.50 755.00,225.75 755.00,225.75
	             755.00,225.75 787.00,176.50 787.00,176.50
	             787.00,176.50 807.50,185.38 807.50,185.38 Z" data-original-title="Heritage Park"></path>
	    <path id="4-12" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="12" data-block="4" data-street="Limited Lane" data-front="53" data-rear="54" data-left="152" data-right="162" data-sqft="8,329" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome <br> Key West (R)" d="M 827.50,194.00
	           C 827.50,194.00 791.25,249.88 791.25,249.88
	             791.25,249.88 773.13,237.38 773.13,237.38
	             773.13,237.38 807.50,185.25 807.50,185.25
	             807.50,185.25 827.50,194.00 827.50,194.00 Z" data-original-title="Heritage Park"></path>
	    <path id="4-11" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="11" data-block="4" data-street="Limited Lane" data-front="55" data-rear="66" data-left="162" data-right="174" data-sqft="9,936" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome <br> Boca Raton (L)" d="M 808.00,264.13
	           C 808.00,264.13 791.38,249.75 791.38,249.75
	             791.38,249.75 827.50,194.00 827.50,194.00
	             827.50,194.00 850.50,207.38 850.50,207.38
	             850.50,207.38 808.00,264.13 808.00,264.13 Z" data-original-title="Heritage Park"></path>
	    <path id="4-10" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="10" data-block="4" data-street="Limited Lane" data-front="61" data-rear="73" data-left="174" data-right="191" data-sqft="12,921" data-grade="4" data-price="0" data-placement="left" data-type="Twinhome <br> Boca Raton (R)" d="M 850.45,207.45
	           C 850.45,207.45 876.64,221.82 876.64,221.82
	             876.64,221.82 874.64,225.91 874.64,225.91
	             874.64,225.91 871.27,231.18 871.27,231.18
	             871.27,231.18 868.09,235.91 868.09,235.91
	             868.09,235.91 864.00,241.55 864.00,241.55
	             864.00,241.55 858.82,247.55 858.82,247.55
	             858.82,247.55 853.91,252.82 853.91,252.82
	             853.91,252.82 848.45,258.73 848.45,258.73
	             848.45,258.73 842.27,265.27 842.27,265.27
	             842.27,265.27 836.73,270.91 836.73,270.91
	             836.73,270.91 826.27,282.00 826.27,282.00
	             826.27,282.00 808.00,264.00 808.00,264.00
	             808.00,264.00 850.45,207.45 850.45,207.45 Z" data-original-title="Heritage Park"></path>
	    <path id="4-9" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="9" data-block="4" data-street="Sonora Way" data-front="55" data-rear="73" data-left="184" data-right="162" data-sqft="12,128" data-grade="2" data-price="0" data-placement="left" data-type="Twinhome <br> Fort Meyers (L)" d="M 874.00,144.63
	           C 874.00,144.63 878.38,145.63 878.38,145.63
	             878.38,145.63 881.50,146.50 881.50,146.50
	             881.50,146.50 890.63,148.50 890.63,148.50
	             890.63,148.50 895.63,149.25 895.75,149.38
	             895.88,149.50 895.25,160.63 895.25,160.63
	             895.25,160.63 892.75,177.38 892.75,177.38
	             892.75,177.38 890.75,186.88 890.75,186.88
	             890.75,186.88 889.00,192.88 889.00,192.88
	             889.00,192.88 886.63,200.38 886.63,200.38
	             886.63,200.38 885.38,203.75 885.38,203.75
	             885.38,203.75 883.13,208.88 883.13,208.88
	             883.13,208.88 881.00,213.50 881.00,213.50
	             881.00,213.50 879.00,217.38 879.00,217.38
	             879.00,217.38 876.50,221.75 876.50,221.75
	             876.50,221.75 850.50,207.50 850.50,207.50
	             850.50,207.50 874.00,144.63 874.00,144.63 Z" data-original-title="Heritage Park"></path>
	    <path id="4-8" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="8" data-block="4" data-street="Select Lane" data-front="62" data-rear="70" data-left="162" data-right="154" data-sqft="10,342" data-grade="1" data-price="0" data-placement="left" data-type="Twinhome <br> Fort Meyers (R)" d="M 850.63,134.50
	           C 850.63,134.50 874.00,144.63 874.00,144.63
	             874.00,144.63 850.50,207.38 850.50,207.38
	             850.50,207.38 825.63,192.88 825.63,192.88
	             825.63,192.88 850.63,134.50 850.63,134.50 Z" data-original-title="Heritage Park"></path>
	    <path id="4-7" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="7" data-block="4" data-street="Select Lane" data-front="55" data-rear="55" data-left="154" data-right="154" data-sqft="8,467" data-grade="1" data-price="0" data-placement="left" data-type="Twinhome <br> Fort Meyers (L)" d="M 829.75,125.88
	           C 829.75,125.88 850.63,134.50 850.63,134.50
	             850.63,134.50 825.63,192.75 825.63,192.75
	             825.63,192.75 804.88,184.25 804.88,184.25
	             804.88,184.25 829.75,125.88 829.75,125.88 Z" data-original-title="Heritage Park"></path>
	    <path id="4-6" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="6" data-block="4" data-street="Select Lane" data-front="53" data-rear="53" data-left="154" data-right="153" data-sqft="8,157" data-grade="1" data-price="0" data-placement="left" data-type="Twinhome <br> Fort Meyers (R)" d="M 810.00,117.50
	           C 810.00,117.50 829.63,126.13 829.63,126.13
	             829.63,126.13 805.00,184.00 805.00,184.00
	             805.00,184.00 784.63,175.50 784.63,175.50
	             784.63,175.50 810.00,117.50 810.00,117.50 Z" data-original-title="Heritage Park"></path>
	    <path id="4-5" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="5" data-block="4" data-street="Select Lane" data-front="53" data-rear="54" data-left="153" data-right="145" data-sqft="7,907" data-grade="1" data-price="0" data-placement="left" data-type="Twinhome" d="M 789.88,108.88
	           C 789.88,108.88 810.00,117.50 810.00,117.50
	             810.00,117.50 784.75,175.25 784.75,175.25
	             784.75,175.25 766.13,163.88 766.13,163.88
	             766.13,163.88 789.88,108.88 789.88,108.88 Z" data-original-title="Heritage Park"></path>
	    <path id="4-4" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="4" data-block="4" data-street="Select Lane" data-front="53" data-rear="54" data-left="145" data-right="137" data-sqft="7,491" data-grade="1" data-price="0" data-placement="left" data-type="Twinhome" d="M 769.88,100.38
	           C 769.88,100.38 789.75,109.00 789.75,109.00
	             789.75,109.00 766.13,163.75 766.13,163.75
	             766.13,163.75 747.25,152.38 747.25,152.38
	             747.25,152.38 769.88,100.38 769.88,100.38 Z" data-original-title="Heritage Park"></path>
	    <path id="4-3" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="3" data-block="4" data-street="Select Lane" data-front="53" data-rear="54" data-left="137" data-right="130" data-sqft="7,076" data-grade="1" data-price="0" data-placement="left" data-type="Twinhome" d="M 749.38,91.75
	           C 749.38,91.75 769.88,100.38 769.88,100.38
	             769.88,100.38 747.25,152.25 747.25,152.25
	             747.25,152.25 728.38,140.88 728.38,140.88
	             728.38,140.88 735.38,125.50 735.38,125.50
	             735.38,125.50 742.13,109.50 742.13,109.50
	             742.13,109.50 749.38,91.75 749.38,91.75 Z" data-original-title="Heritage Park"></path>
	    <path id="4-2" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="2" data-block="4" data-street="Select Lane" data-front="92" data-rear="53" data-left="130" data-right="145" data-sqft="9,028" data-grade="1" data-price="0" data-placement="left" data-type="Twinhome" d="M 714.38,77.13
	           C 714.38,77.13 749.00,91.50 749.00,91.50
	             749.00,91.50 747.50,96.50 747.50,96.50
	             747.50,96.50 744.00,105.13 744.00,105.13
	             744.00,105.13 739.63,115.13 739.63,115.13
	             739.63,115.13 734.75,127.00 734.75,127.00
	             734.75,127.00 728.50,140.63 728.50,140.63
	             728.50,140.63 710.88,129.75 710.88,129.75
	             710.88,129.75 714.38,77.13 714.38,77.13 Z" data-original-title="Heritage Park"></path>
	    <path id="4-1" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="1" data-block="4" data-street="Community Loop" data-front="65" data-rear="57" data-left="151" data-right="150" data-sqft="9,563" data-grade="2" data-price="0" data-placement="left" data-type="Twinhome" d="M 653.27,69.82
	           C 653.27,69.82 670.18,70.45 670.18,70.45
	             670.18,70.45 692.55,72.27 692.55,72.27
	             692.55,72.27 703.64,74.64 703.64,74.64
	             703.64,74.64 711.55,76.64 711.55,76.64
	             711.55,76.64 714.27,77.55 714.27,77.55
	             714.27,77.55 712.82,100.36 712.82,100.36
	             712.82,100.36 651.73,96.18 651.73,96.18
	             651.73,96.18 653.27,69.82 653.27,69.82 Z" data-original-title="Heritage Park"></path>
	    <path id="3-4" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="4" data-block="3" data-street="Select Lane" data-front="101" data-rear="40" data-left="175" data-right="186" data-sqft="12,666" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome" d="M 886.69,44.50
	           C 886.69,44.50 902.75,48.38 902.75,48.38
	             902.75,48.38 897.88,124.38 897.88,124.38
	             897.88,124.38 892.50,123.81 892.50,123.81
	             892.50,123.81 884.44,121.63 884.44,121.63
	             884.44,121.63 858.06,111.13 858.06,111.13
	             858.06,111.13 886.69,44.50 886.69,44.50 Z" data-original-title="Heritage Park"></path>
	    <path id="3-3" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="3" data-block="3" data-street="Select Lane" data-front="53" data-rear="54" data-left="167" data-right="175" data-sqft="9,059" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome" d="M 838.38,102.63
	           C 838.38,102.63 858.13,110.88 858.13,110.88
	             858.13,110.88 886.63,44.50 886.63,44.50
	             886.63,44.50 865.63,39.13 865.63,39.13
	             865.63,39.13 838.38,102.63 838.38,102.63 Z" data-original-title="Heritage Park"></path>
	    <path id="3-2" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="2" data-block="3" data-street="Select Lane" data-front="53" data-rear="54" data-left="158" data-right="167" data-sqft="8,614" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome" d="M 844.25,34.13
	           C 844.25,34.13 865.50,39.38 865.50,39.38
	             865.50,39.38 838.38,102.63 838.38,102.63
	             838.38,102.63 818.13,93.75 818.13,93.75
	             818.13,93.75 844.25,34.13 844.25,34.13 Z" data-original-title="Heritage Park"></path>
	    <path id="3-1" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="1" data-block="3" data-street="Select Lane" data-front="53" data-rear="54" data-left="150" data-right="158" data-sqft="8,169" data-grade="3" data-price="0" data-placement="left" data-type="Twinhome" d="M 822.73,28.73
	           C 822.73,28.73 844.25,34.11 844.25,34.11
	             844.25,34.11 818.18,93.64 818.18,93.64
	             818.18,93.64 798.25,85.22 798.25,85.22
	             798.25,85.22 822.73,28.73 822.73,28.73 Z" data-original-title="Heritage Park"></path>
	    <path id="2-3" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="3" data-block="2" data-street="Community Loop" data-front="113" data-rear="74" data-left="143" data-right="138" data-sqft="12,927" data-grade="4" data-price="0" data-placement="left" data-type="Single Family" d="M 569.73,96.09
	           C 569.73,96.09 567.45,126.45 567.45,126.45
	             567.45,126.45 622.18,146.82 622.18,146.82
	             622.18,146.82 626.45,100.27 626.45,100.27
	             626.45,100.27 569.73,96.09 569.73,96.09 Z" data-original-title="Heritage Park"></path>
	    <path id="2-2" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="2" data-block="2" data-street="Community Loop" data-front="84" data-rear="84" data-left="138" data-right="138" data-sqft="11,547" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 626.55,100.27
	           C 626.55,100.27 569.82,96.09 569.82,96.09
	             569.82,96.09 573.18,61.73 573.18,61.73
	             573.18,61.73 628.91,66.18 628.91,66.18
	             628.91,66.18 626.55,100.27 626.55,100.27 Z" data-original-title="Heritage Park"></path>
	    <path id="2-1" className="has-hover parkProperty" title="" data-toggle="popover" data-trigger="hover focus" data-lot="1" data-block="2" data-street="Community Loop" data-front="81" data-rear="81" data-left="138" data-right="136" data-sqft="11,155" data-grade="3" data-price="0" data-placement="left" data-type="Single Family" d="M 632.09,32.64
	           C 632.09,32.64 629.00,66.09 629.00,66.18
	             629.00,66.27 573.18,61.73 573.18,61.73
	             573.18,61.73 576.00,29.18 576.00,29.18
	             576.00,29.18 632.09,32.64 632.09,32.64 Z" data-original-title="Heritage Park"></path> */}
	</svg>
                {/* </ImageListItem> */}
                {/* </ImageList> */}
		
              <CustomPopover
			  anchorEl={anchorEl}
			  popData={popupData}
			  onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
				onClick={handelUnitDetail}
			  />
            </Box>
			{openUnitDetail && (
				<UnitDetailPage
				open={openUnitDetail}
				onClose={handelUnitDetail}
				popData={popupData}
				openUnitDetail={openUnitDetail}
				setOpenUnitDetail={setOpenUnitDetail}
				/>
			)}
            {openModal && (
            <IntegratedCard
                open={openModal}
                onClose={closeclk}
                integratedCode='<!Doctype html>'
            />
        )}
        {openDraw && (
            <ContentDrawer title='Unit details' openDrawer={openDraw} toggleDrawer={handleEvent}>
                
                    <LabelAndValue label='Unit Name' value={drawData?.unit_name}  />
                
                <Divider />
                <GridComponent>
                    <Grid item xs={grids.halfGrid} >
                        <LabelAndValue label='Unit Price' value={drawData?.sellable ? drawData?.unit_price : 'Not Applicable'}  />
                    </Grid>
                    <Grid item xs={grids.halfGrid} >
                        <LabelAndValue label='Total Sqft.' value={drawData?.total_sqft}  />
                    </Grid>
                </GridComponent>
                <Divider />
                <GridComponent>
                    <Grid item xs={grids.halfGrid} >
                        <LabelAndValue label='Block' value={drawData?.block}  />
                    </Grid>
                    <Grid item xs={grids.halfGrid} >
                        <LabelAndValue label='Floor' value={drawData?.floor}  />
                    </Grid>
                </GridComponent>
                <Divider />
                <GridComponent>
                    <Grid item xs={grids.halfGrid} >
                        <LabelAndValue label='Facing' value={drawData?.unit_price}  />
                    </Grid>
                    <Grid item xs={grids.halfGrid} >
                        <LabelAndValue label='Unit Type' value={drawData?.unit_type}  />
                    </Grid>
                </GridComponent>
                <Divider />
                <GridComponent>
                    <Grid item xs={grids.halfGrid} >
                        <LabelAndValue label='Carpet Area' value={drawData?.unit_price}  />
                    </Grid>
                    <Grid item xs={grids.halfGrid} >
                        <LabelAndValue label='Balcony' value={drawData?.unit_type}  />
                    </Grid>
                </GridComponent>
                <Divider />
                <GridComponent>
                    <Grid item xs={grids.halfGrid} >
                        <LabelAndValue label='Bathroom' value={drawData?.unit_price}  />
                    </Grid>
                    <Grid item xs={grids.halfGrid} >
                        <LabelAndValue label='Color' value={drawData?.unit_type}  />
                    </Grid>
                </GridComponent>
                <Divider />
                <GridComponent>
                    <Grid item >
                        <Labels header list title='Specifications' label={drawData?.specification}  />
                    </Grid>
                </GridComponent>
                <br />
                <GridComponent>
                    <Grid item xs={grids.halfGrid} >
                        <StatusLabel header title='Status' status='Available' color='success.main' />
                    </Grid>
                    <Grid item xs={grids.halfGrid} >
                        <SecondaryButton title='Edit' />
                    </Grid>
                </GridComponent>
                
            </ContentDrawer>
        )}
        </>
    )
}
export default PlanMap;





// <html><head><link rel="stylesheet" type="text/css" href="https://spotmink.com//static/css/bootstrap.min.css"><link rel="stylesheet" type="text/css" href="https://spotmink.com//static/css/style.css"><link rel="stylesheet" type="text/css" href="https://spotmink.com//static/css/haritage.css"><link rel="stylesheet" type="text/css" href="https://spotmink.com//static/css/login.css"><link rel="stylesheet" type="text/css" href="https://spotmink.com//static/css/menupage.css"></head><body>
//     <div id="spotmink_container" style="width: 100%;position: relative;">



//     <meta charset="UTF-8">
//     <meta name="viewport" content="width=device-width, initial-scale=1.0">
//     <meta http-equiv="X-UA-Compatible" content="ie=edge">
//     <title>Heritage Ridge</title>
//     <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
   


//     <div class="map-full-page">
    
//         <div>
//                 <div class="top-title-bar">
//                     <!-- <script src="https://code.jquery.com/jquery-3.1.1.min.js"></script>  -->

//                     <!-- <script src="js/navbar-fixed.js"></script> -->
            
//                     <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script> 
//                 </div>
//                 <div class="image-section" id="Park2"> 
//                 <img src="https://spotmink.com//category-backgrounds/V4_jq8wL3w.jpg" alt=" this is Ankit Sharma " srcset="">
//                 <!-- <img src="http://127.0.0.1:8000/category-backgrounds/V4.jpg" alt=" this is Ankit Sharma "  srcset=""> -->
//                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5760 3600">
                    
                
        
//                 <path onclick="getvalue(this.id)" id="unit_32" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 2920.00,454.00
//         C 2926.73,457.93 2934.89,466.34 2941.00,471.72
//           2941.00,471.72 2988.00,513.84 2988.00,513.84
//           2988.00,513.84 3076.00,592.83 3076.00,592.83
//           3076.00,592.83 3122.00,633.98 3122.00,633.98
//           3122.00,633.98 3168.00,675.16 3168.00,675.16
//           3171.13,677.96 3182.80,687.24 3182.80,691.00
//           3182.80,693.86 3177.95,698.02 3176.00,700.00
//           3171.34,704.73 3164.71,712.63 3160.75,718.00
//           3152.45,729.30 3141.72,747.19 3136.04,760.00
//           3136.04,760.00 3126.00,786.00 3126.00,786.00
//           3126.00,786.00 3064.00,769.15 3064.00,769.15
//           3064.00,769.15 2968.00,743.00 2968.00,743.00
//           2968.00,743.00 2935.00,734.00 2935.00,734.00
//           2935.00,734.00 2922.57,729.91 2922.57,729.91
//           2922.57,729.91 2921.00,722.00 2921.00,722.00
//           2921.00,722.00 2921.00,672.00 2921.00,672.00
//           2921.00,672.00 2920.00,655.00 2920.00,655.00
//           2920.00,655.00 2920.00,454.00 2920.00,454.00 Z" data-front="73" data-rear="187" data-left="238.42" data-lot="4" data-street="Community Loop" data-right="142" data-sqft="21,121" data-type="Single family" data-unit_type="Acre" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_35" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 2921.00,730.00
//         C 2921.00,730.00 2971.00,743.58 2971.00,743.58
//           2971.00,743.58 3072.00,771.12 3072.00,771.12
//           3072.00,771.12 3111.00,781.71 3111.00,781.71
//           3111.00,781.71 3124.96,786.70 3124.96,786.70
//           3124.96,786.70 3124.96,795.00 3124.96,795.00
//           3124.96,795.00 3121.95,818.00 3121.95,818.00
//           3121.95,818.00 3120.04,838.00 3120.04,838.00
//           3120.04,838.00 3120.04,854.00 3120.04,854.00
//           3120.04,854.00 3121.00,866.00 3121.00,866.00
//           3121.00,866.00 3121.00,900.00 3121.00,900.00
//           3121.00,900.00 2922.00,900.00 2922.00,900.00
//           2922.00,900.00 2922.00,846.00 2922.00,846.00
//           2922.00,846.00 2921.00,829.00 2921.00,829.00
//           2921.00,829.00 2921.00,730.00 2921.00,730.00 Z" data-front="78" data-rear="112" data-left="142" data-lot="3" data-street="Community Loop" data-right="132" data-sqft="12,823" data-type="Single Family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_36" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 2922.00,900.00
//         C 2922.00,900.00 3121.00,900.00 3121.00,900.00
//           3121.00,900.00 3121.00,1025.00 3121.00,1025.00
//           3121.00,1025.00 3069.00,1025.00 3069.00,1025.00
//           3069.00,1025.00 3052.00,1026.00 3052.00,1026.00
//           3052.00,1026.00 2997.00,1026.00 2997.00,1026.00
//           2997.00,1026.00 2982.00,1027.00 2982.00,1027.00
//           2982.00,1027.00 2923.00,1027.00 2923.00,1027.00
//           2923.00,1027.00 2923.00,957.00 2923.00,957.00
//           2923.00,957.00 2922.00,940.00 2922.00,940.00
//           2922.00,940.00 2922.00,900.00 2922.00,900.00 Z" data-front="83" data-rear="83" data-left="132" data-lot="2" data-street="Community Loop" data-right="132" data-sqft="11,117" data-type="Single Family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_37" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 2924.00,1027.00
//         C 2924.00,1027.00 3002.00,1027.00 3002.00,1027.00
//           3002.00,1027.00 3017.00,1026.00 3017.00,1026.00
//           3017.00,1026.00 3098.00,1026.00 3098.00,1026.00
//           3098.00,1026.00 3113.00,1025.00 3113.00,1025.00
//           3113.00,1025.00 3121.00,1025.00 3121.00,1025.00
//           3121.00,1025.00 3121.00,1054.00 3121.00,1054.00
//           3121.00,1054.00 3122.00,1071.00 3122.00,1071.00
//           3122.00,1071.00 3122.00,1175.00 3122.00,1175.00
//           3122.00,1175.00 3093.00,1177.00 3093.00,1177.00
//           3093.00,1177.00 3052.00,1179.00 3052.00,1179.00
//           3035.88,1179.03 3019.86,1182.29 3004.00,1184.92
//           3004.00,1184.92 2924.00,1198.00 2924.00,1198.00
//           2924.00,1198.00 2924.00,1027.00 2924.00,1027.00 Z" data-front="100" data-rear="114" data-left="133" data-lot="1" data-street="Community Loop" data-right="132" data-sqft="13,942" data-type="Single family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_38" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="99,900" data-placement="left" d="M 2925.00,1429.00
//            C 2925.00,1429.00 2925.00,1402.00 2925.00,1402.00
//              2925.00,1402.00 2924.00,1385.00 2924.00,1385.00
//              2924.00,1385.00 2924.00,1287.00 2924.00,1287.00
//              2924.00,1287.00 2925.60,1279.02 2925.60,1279.02
//              2925.60,1279.02 2938.00,1276.58 2938.00,1276.58
//              2938.00,1276.58 2970.00,1271.73 2970.00,1271.73
//              2970.00,1271.73 3036.00,1261.73 3036.00,1261.73
//              3036.00,1261.73 3072.00,1257.00 3072.00,1257.00
//              3072.00,1257.00 3121.00,1257.00 3121.00,1257.00
//              3121.00,1257.00 3121.00,1432.00 3121.00,1432.00
//              3121.00,1432.00 3092.00,1432.00 3092.00,1432.00
//              3092.00,1432.00 3075.00,1431.00 3075.00,1431.00
//              3075.00,1431.00 3021.00,1431.00 3021.00,1431.00
//              3021.00,1431.00 3004.00,1430.00 3004.00,1430.00
//              3004.00,1430.00 2943.00,1430.00 2943.00,1430.00
//              2943.00,1430.00 2929.00,1429.00 2929.00,1429.00
//              2929.00,1429.00 2925.00,1429.00 2925.00,1429.00 Z" data-front="116" data-rear="101" data-left="131" data-lot="1" data-street="Community Loop" data-right="132" data-sqft="14,557" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_39" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="99,900" data-placement="left" d="M 2925.00,1429.00
//            C 2925.00,1429.00 2958.00,1429.00 2958.00,1429.00
//              2958.00,1429.00 2975.00,1430.00 2975.00,1430.00
//              2975.00,1430.00 3105.00,1432.00 3105.00,1432.00
//              3105.00,1432.00 3121.00,1433.00 3121.00,1433.00
//              3121.00,1433.00 3121.00,1567.00 3121.00,1567.00
//              3121.00,1567.00 3006.00,1567.00 3006.00,1567.00
//              3006.00,1567.00 2991.00,1568.00 2991.00,1568.00
//              2991.00,1568.00 2925.00,1568.00 2925.00,1568.00
//              2925.00,1568.00 2925.00,1429.00 2925.00,1429.00 Z" data-front="90" data-rear="90" data-left="131" data-lot="2" data-street="Community Loop" data-right="131" data-sqft="11,799" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_40" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="99,900" data-placement="left" d="M 2916.00,1699.00
//            C 2916.00,1699.00 2935.00,1700.00 2935.00,1700.00
//              2935.00,1700.00 2982.00,1700.00 2982.00,1700.00
//              2982.00,1700.00 2999.00,1701.00 2999.00,1701.00
//              2999.00,1701.00 3041.00,1701.00 3041.00,1701.00
//              3041.00,1701.00 3058.00,1702.00 3058.00,1702.00
//              3058.00,1702.00 3102.00,1702.00 3102.00,1702.00
//              3102.00,1702.00 3117.00,1703.00 3117.00,1703.00
//              3117.00,1703.00 3121.00,1703.00 3121.00,1703.00
//              3121.00,1703.00 3120.00,1718.00 3120.00,1718.00
//              3120.00,1718.00 3119.00,1781.00 3119.00,1781.00
//              3119.00,1781.00 3118.00,1797.00 3118.00,1797.00
//              3118.00,1797.00 3118.00,1820.00 3118.00,1820.00
//              3118.00,1820.00 3117.00,1836.00 3117.00,1836.00
//              3117.00,1836.00 3117.00,1861.00 3117.00,1861.00
//              3117.00,1861.00 3072.00,1858.09 3072.00,1858.09
//              3072.00,1858.09 2985.00,1852.09 2985.00,1852.09
//              2985.00,1852.09 2929.00,1848.17 2929.00,1848.17
//              2929.00,1848.17 2905.00,1846.00 2905.00,1846.00
//              2905.00,1846.00 2910.09,1778.00 2910.09,1778.00
//              2910.09,1778.00 2914.09,1723.00 2914.09,1723.00
//              2914.09,1723.00 2916.00,1699.00 2916.00,1699.00 Z" data-front="107" data-rear="196" data-left="136" data-lot="4" data-street="Community Loop" data-right="138" data-sqft="14,032" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_41" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 2906.00,1847.00
//            C 2906.00,1847.00 2966.00,1851.09 2966.00,1851.09
//              2966.00,1851.09 3064.00,1857.91 3064.00,1857.91
//              3064.00,1857.91 3087.00,1859.09 3087.00,1859.09
//              3087.00,1859.09 3095.00,1859.96 3095.00,1859.96
//              3095.00,1859.96 3117.00,1862.00 3117.00,1862.00
//              3117.00,1862.00 3113.91,1896.00 3113.91,1896.00
//              3113.91,1896.00 3111.17,1925.00 3111.17,1925.00
//              3111.17,1925.00 3108.28,1960.00 3108.28,1960.00
//              3108.28,1960.00 3106.28,1984.00 3106.28,1984.00
//              3105.99,1986.47 3106.25,1991.62 3104.83,1993.43
//              3102.51,1996.37 3093.36,1994.49 3090.00,1994.17
//              3090.00,1994.17 3050.00,1991.42 3050.00,1991.42
//              3050.00,1991.42 3025.00,1989.91 3025.00,1989.91
//              3025.00,1989.91 2982.00,1986.91 2982.00,1986.91
//              2982.00,1986.91 2925.00,1982.91 2925.00,1982.91
//              2925.00,1982.91 2896.00,1981.00 2896.00,1981.00
//              2896.00,1981.00 2897.91,1955.00 2897.91,1955.00
//              2897.91,1955.00 2901.91,1898.00 2901.91,1898.00
//              2901.91,1898.00 2903.91,1869.00 2903.91,1869.00
//              2903.91,1869.00 2906.00,1847.00 2906.00,1847.00 Z" data-front="90" data-rear="90" data-left="138" data-lot="5" data-street="Community Loop" data-right="137" data-sqft="12,414" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_42" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 2887.00,2114.00
//            C 2887.00,2114.00 2890.09,2071.00 2890.09,2071.00
//              2890.09,2071.00 2893.09,2028.00 2893.09,2028.00
//              2893.09,2028.00 2893.96,2020.00 2893.96,2020.00
//              2893.96,2020.00 2895.91,1993.00 2895.91,1993.00
//              2896.11,1990.15 2895.51,1983.68 2897.17,1981.57
//              2899.37,1978.78 2906.72,1980.82 2910.00,1980.96
//              2910.00,1980.96 2949.00,1983.96 2949.00,1983.96
//              2949.00,1983.96 2958.00,1983.96 2958.00,1983.96
//              2958.00,1983.96 2987.00,1986.09 2987.00,1986.09
//              2987.00,1986.09 3050.00,1990.91 3050.00,1990.91
//              3050.00,1990.91 3105.00,1995.00 3105.00,1995.00
//              3105.00,1995.00 3103.83,2009.00 3103.83,2009.00
//              3103.83,2009.00 3100.96,2042.00 3100.96,2042.00
//              3100.96,2042.00 3100.96,2050.00 3100.96,2050.00
//              3100.96,2050.00 3097.91,2090.00 3097.91,2090.00
//              3097.91,2090.00 3095.00,2131.00 3095.00,2131.00
//              3095.00,2131.00 3034.00,2126.17 3034.00,2126.17
//              3034.00,2126.17 2943.00,2118.83 2943.00,2118.83
//              2943.00,2118.83 2907.00,2115.83 2907.00,2115.83
//              2907.00,2115.83 2887.00,2114.00 2887.00,2114.00 Z" data-front="90" data-rear="90" data-left="137" data-lot="6" data-street="Community Loop" data-right="136" data-sqft="12,293" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_43" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="99,900" data-placement="left" d="M 2925.00,1569.00
//            C 2925.00,1569.00 2942.00,1569.00 2942.00,1569.00
//              2942.00,1569.00 2958.00,1568.00 2958.00,1568.00
//              2958.00,1568.00 3090.00,1568.00 3090.00,1568.00
//              3090.00,1568.00 3105.00,1567.00 3105.00,1567.00
//              3105.00,1567.00 3121.00,1567.00 3121.00,1567.00
//              3121.00,1567.00 3121.00,1702.00 3121.00,1702.00
//              3121.00,1702.00 3091.00,1702.00 3091.00,1702.00
//              3091.00,1702.00 3074.00,1701.00 3074.00,1701.00
//              3074.00,1701.00 3016.00,1701.00 3016.00,1701.00
//              3016.00,1701.00 2999.00,1700.00 2999.00,1700.00
//              2999.00,1700.00 2935.00,1700.00 2935.00,1700.00
//              2935.00,1700.00 2920.00,1699.00 2920.00,1699.00
//              2920.00,1699.00 2916.00,1699.00 2916.00,1699.00
//              2916.00,1699.00 2917.91,1674.00 2917.91,1674.00
//              2917.91,1674.00 2922.09,1611.00 2922.09,1611.00
//              2922.09,1611.00 2925.00,1569.00 2925.00,1569.00 Z" data-front="90" data-rear="90" data-left="130" data-lot="3" data-street="Community Loop" data-right="136" data-sqft="12,031" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_44" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="200000" data-placement="left" d="M 3992.00,439.00
//         C 3992.00,439.00 4005.00,440.17 4005.00,440.17
//           4005.00,440.17 4048.00,443.91 4048.00,443.91
//           4048.00,443.91 4068.00,445.17 4068.00,445.17
//           4068.00,445.17 4130.00,450.17 4130.00,450.17
//           4130.00,450.17 4155.00,452.09 4155.00,452.09
//           4155.00,452.09 4167.00,453.00 4167.00,453.00
//           4167.00,453.00 4160.28,509.00 4160.28,509.00
//           4160.28,509.00 4145.85,622.00 4145.85,622.00
//           4145.85,622.00 4139.00,677.00 4139.00,677.00
//           4139.00,677.00 4093.00,675.00 4093.00,675.00
//           4093.00,675.00 4076.00,674.00 4076.00,674.00
//           4076.00,674.00 3993.00,674.00 3993.00,674.00
//           3993.00,674.00 3993.00,584.00 3993.00,584.00
//           3993.00,584.00 3992.00,569.00 3992.00,569.00
//           3992.00,569.00 3992.00,509.00 3992.00,509.00
//           3992.00,509.00 3992.00,469.00 3992.00,469.00
//           3991.99,459.60 3989.41,448.12 3992.00,439.00 Z" data-front="97" data-rear="116" data-left="151" data-lot="12" data-street="Community Loop" data-right="156" data-sqft="16,394" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_45" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3874.00,431.00
//         C 3874.00,431.00 3928.00,435.09 3928.00,435.09
//           3928.00,435.09 3982.00,439.09 3982.00,439.09
//           3982.00,439.09 3991.04,440.00 3991.04,440.00
//           3991.04,440.00 3991.04,455.00 3991.04,455.00
//           3991.04,455.00 3992.00,467.00 3992.00,467.00
//           3992.00,467.00 3992.00,570.00 3992.00,570.00
//           3992.00,570.00 3993.00,585.00 3993.00,585.00
//           3993.00,585.00 3993.00,675.00 3993.00,675.00
//           3993.00,675.00 3873.00,675.00 3873.00,675.00
//           3873.00,675.00 3873.00,509.00 3873.00,509.00
//           3873.00,509.00 3873.00,458.00 3873.00,458.00
//           3873.00,458.00 3874.00,431.00 3874.00,431.00 Z" data-front="80" data-rear="80" data-left="156" data-lot="11" data-street="Community Loop" data-right="163" data-sqft="12,833" data-type="Single Family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_46" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="109,900" data-placement="left" d="M 3752.00,431.00
//         C 3752.00,431.00 3873.00,431.00 3873.00,431.00
//           3873.00,431.00 3873.00,675.00 3873.00,675.00
//           3873.00,675.00 3754.00,675.00 3754.00,675.00
//           3754.00,675.00 3754.00,624.00 3754.00,624.00
//           3754.00,624.00 3753.00,607.00 3753.00,607.00
//           3753.00,607.00 3753.00,500.00 3753.00,500.00
//           3753.00,500.00 3752.00,485.00 3752.00,485.00
//           3752.00,485.00 3752.00,431.00 3752.00,431.00 Z" data-front="80" data-rear="80" data-left="163" data-lot="10" data-street="Community Loop" data-right="163" data-sqft="13,040" data-type="Single family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_47" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3634.00,432.00
//         C 3634.00,432.00 3713.00,432.00 3713.00,432.00
//           3713.00,432.00 3729.00,431.00 3729.00,431.00
//           3729.00,431.00 3752.00,431.00 3752.00,431.00
//           3752.00,431.00 3752.00,483.00 3752.00,483.00
//           3752.00,483.00 3753.00,500.00 3753.00,500.00
//           3753.00,500.00 3753.00,607.00 3753.00,607.00
//           3753.00,607.00 3754.00,622.00 3754.00,622.00
//           3754.00,622.00 3754.00,675.00 3754.00,675.00
//           3754.00,675.00 3633.00,675.00 3633.00,675.00
//           3633.00,675.00 3633.00,620.00 3633.00,620.00
//           3633.00,620.00 3634.00,603.00 3634.00,603.00
//           3634.00,603.00 3634.00,432.00 3634.00,432.00 Z" data-front="80" data-rear="80" data-left="163" data-lot="9" data-street="Community Loop" data-right="163" data-sqft="13,040" data-type="Single family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_48" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3507.00,433.00
//         C 3507.00,433.00 3530.00,433.00 3530.00,433.00
//           3530.00,433.00 3547.00,432.00 3547.00,432.00
//           3547.00,432.00 3633.00,432.00 3633.00,432.00
//           3633.00,432.00 3633.00,675.00 3633.00,675.00
//           3633.00,675.00 3605.00,674.00 3605.00,674.00
//           3605.00,674.00 3586.00,673.00 3586.00,673.00
//           3586.00,673.00 3576.00,672.09 3576.00,672.09
//           3576.00,672.09 3510.00,666.00 3510.00,666.00
//           3510.00,666.00 3510.00,632.00 3510.00,632.00
//           3510.00,632.00 3509.00,615.00 3509.00,615.00
//           3509.00,615.00 3509.00,542.00 3509.00,542.00
//           3509.00,542.00 3508.00,527.00 3508.00,527.00
//           3508.00,527.00 3508.00,455.00 3508.00,455.00
//           3508.00,455.00 3507.00,440.00 3507.00,440.00
//           3507.00,440.00 3507.00,433.00 3507.00,433.00 Z" data-front="83" data-rear="83" data-left="163" data-lot="8" data-street="Community Loop" data-right="156" data-sqft="13,359" data-type="Single family" data-unit_type="Sqft" data-block="2" data-grade="3" data-original-title="1"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_49" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3383.00,433.00
//         C 3383.00,433.00 3405.00,433.00 3405.00,433.00
//           3405.00,433.00 3422.00,432.00 3422.00,432.00
//           3422.00,432.00 3507.04,432.00 3507.04,432.00
//           3507.04,432.00 3507.04,447.00 3507.04,447.00
//           3507.04,447.00 3508.00,459.00 3508.00,459.00
//           3508.00,459.00 3508.00,561.00 3508.00,561.00
//           3508.00,561.00 3509.00,576.00 3509.00,576.00
//           3509.00,576.00 3509.00,666.00 3509.00,666.00
//           3509.00,666.00 3413.00,651.73 3413.00,651.73
//           3413.00,651.73 3393.00,648.73 3393.00,648.73
//           3393.00,648.73 3385.02,646.40 3385.02,646.40
//           3385.02,646.40 3384.00,639.00 3384.00,639.00
//           3384.00,639.00 3384.00,496.00 3384.00,496.00
//           3384.00,496.00 3383.00,481.00 3383.00,481.00
//           3383.00,481.00 3383.00,433.00 3383.00,433.00 Z" data-front="84" data-rear="83" data-left="156" data-lot="7" data-street="Community Loop" data-right="143" data-sqft="12,446" data-type="Single Family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_50" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3204.00,434.00
//         C 3204.00,434.00 3262.00,434.00 3262.00,434.00
//           3262.00,434.00 3279.00,433.00 3279.00,433.00
//           3279.00,433.00 3383.00,433.00 3383.00,433.00
//           3383.00,433.00 3383.00,515.00 3383.00,515.00
//           3383.00,515.00 3384.00,530.00 3384.00,530.00
//           3384.00,530.00 3384.00,622.00 3384.00,622.00
//           3384.00,622.00 3385.00,637.00 3385.00,637.00
//           3385.00,637.00 3385.00,647.00 3385.00,647.00
//           3385.00,647.00 3335.00,640.99 3335.00,640.99
//           3335.00,640.99 3296.00,640.99 3296.00,640.99
//           3296.00,640.99 3278.51,640.99 3278.51,640.99
//           3278.51,640.99 3273.19,629.00 3273.19,629.00
//           3273.19,629.00 3260.66,594.00 3260.66,594.00
//           3260.66,594.00 3222.95,489.00 3222.95,489.00
//           3222.95,489.00 3209.66,452.00 3209.66,452.00
//           3209.66,452.00 3204.00,434.00 3204.00,434.00 Z" data-front="71" data-rear="120" data-left="143" data-lot="6" data-street="Community Loop" data-right="148" data-sqft="13,397" data-type="Single Family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_51" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3211.00,990.00
//            C 3211.00,990.00 3211.00,941.00 3211.00,941.00
//              3211.00,941.00 3210.00,924.00 3210.00,924.00
//              3210.00,924.00 3210.00,882.00 3210.00,882.00
//              3210.00,882.00 3209.00,865.00 3209.00,865.00
//              3209.00,865.00 3209.00,821.00 3209.00,821.00
//              3209.00,821.00 3209.65,813.00 3209.65,813.00
//              3209.65,813.00 3222.99,789.00 3222.99,789.00
//              3222.99,789.00 3241.38,758.21 3241.38,758.21
//              3241.38,758.21 3257.00,748.70 3257.00,748.70
//              3257.00,748.70 3285.00,733.65 3285.00,733.65
//              3285.00,733.65 3297.00,732.83 3297.00,732.83
//              3297.00,732.83 3330.00,731.00 3330.00,731.00
//              3330.00,731.00 3330.00,765.00 3330.00,765.00
//              3330.00,765.00 3331.00,782.00 3331.00,782.00
//              3331.00,782.00 3331.00,853.00 3331.00,853.00
//              3331.00,853.00 3332.00,868.00 3332.00,868.00
//              3332.00,868.00 3332.00,940.00 3332.00,940.00
//              3332.00,940.00 3333.00,955.00 3333.00,955.00
//              3333.00,955.00 3333.00,990.00 3333.00,990.00
//              3333.00,990.00 3211.00,990.00 3211.00,990.00 Z" data-front="120" data-rear="81" data-left="174" data-lot="1" data-street="Community Loop" data-right="105" data-sqft="13,144" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_52" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3329.00,729.00
//            C 3329.00,729.00 3378.00,736.92 3378.00,736.92
//              3378.00,736.92 3422.00,744.00 3422.00,744.00
//              3422.00,744.00 3422.00,898.00 3422.00,898.00
//              3422.00,898.00 3423.00,913.00 3423.00,913.00
//              3423.00,913.00 3423.00,987.00 3423.00,987.00
//              3423.00,987.00 3404.00,987.00 3404.00,987.00
//              3404.00,987.00 3388.00,988.00 3388.00,988.00
//              3388.00,988.00 3334.00,988.00 3334.00,988.00
//              3334.00,988.00 3334.00,955.00 3334.00,955.00
//              3334.00,955.00 3333.00,939.00 3333.00,939.00
//              3333.00,939.00 3333.00,911.00 3333.00,911.00
//              3333.00,911.00 3332.00,894.00 3332.00,894.00
//              3332.00,894.00 3332.00,868.00 3332.00,868.00
//              3332.00,868.00 3331.00,851.00 3331.00,851.00
//              3331.00,851.00 3330.00,782.00 3330.00,782.00
//              3330.00,782.00 3329.00,765.00 3329.00,765.00
//              3329.00,765.00 3329.00,729.00 3329.00,729.00 Z" data-front="59" data-rear="59" data-left="174" data-lot="2" data-street="Community Loop" data-right="165" data-sqft="10,054" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_53" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3421.00,744.00
//      C 3421.00,744.00 3501.00,754.00 3501.00,754.00
//        3501.00,754.00 3501.00,990.00 3501.00,990.00
//        3501.00,990.00 3423.00,990.00 3423.00,990.00
//        3423.00,990.00 3423.00,968.00 3423.00,968.00
//        3423.00,968.00 3422.00,952.00 3422.00,952.00
//        3422.00,952.00 3422.00,783.00 3422.00,783.00
//        3422.00,783.00 3421.00,768.00 3421.00,768.00
//        3421.00,768.00 3421.00,744.00 3421.00,744.00 Z" data-front="52" data-rear="151" data-left="165" data-lot="3" data-street="Community Loop" data-right="156" data-sqft="8,340" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_54" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3499.00,753.00
//            C 3499.00,753.00 3577.00,764.00 3577.00,764.00
//              3577.00,764.00 3577.00,811.00 3577.00,811.00
//              3577.00,811.00 3578.00,828.00 3578.00,828.00
//              3578.00,828.00 3578.00,925.00 3578.00,925.00
//              3578.00,925.00 3579.00,940.00 3579.00,940.00
//              3579.00,940.00 3579.00,989.00 3579.00,989.00
//              3579.00,989.00 3499.00,990.00 3499.00,990.00
//              3499.00,990.00 3499.00,753.00 3499.00,753.00 Z" data-front="51" data-rear="151" data-left="156" data-lot="4" data-street="Community Loop" data-right="151" data-sqft="7,955" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_55" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3577.00,763.00
//            C 3577.00,763.00 3613.00,763.00 3613.00,763.00
//              3613.00,763.00 3630.00,764.00 3630.00,764.00
//              3630.00,764.00 3664.00,764.00 3664.00,764.00
//              3664.00,764.00 3664.00,793.00 3664.00,793.00
//              3664.00,793.00 3665.00,810.00 3665.00,810.00
//              3665.00,810.00 3665.00,869.00 3665.00,869.00
//              3665.00,869.00 3666.00,886.00 3666.00,886.00
//              3666.00,886.00 3666.00,947.00 3666.00,947.00
//              3666.00,947.00 3667.00,962.00 3667.00,962.00
//              3667.00,962.00 3667.00,992.00 3667.00,992.00
//              3667.00,992.00 3631.00,992.00 3631.00,992.00
//              3631.00,992.00 3614.00,991.00 3614.00,991.00
//              3614.00,991.00 3579.00,991.00 3579.00,991.00
//              3579.00,991.00 3579.00,944.00 3579.00,944.00
//              3579.00,944.00 3578.00,927.00 3578.00,927.00
//              3578.00,927.00 3578.00,828.00 3578.00,828.00
//              3578.00,828.00 3577.00,813.00 3577.00,813.00
//              3577.00,813.00 3577.00,763.00 3577.00,763.00 Z" data-front="59" data-rear="59" data-left="151" data-lot="5" data-street="Community Loop" data-right="150" data-sqft="8,899" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_56" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3666.00,764.00
//            C 3666.00,764.00 3754.00,764.00 3754.00,764.00
//              3754.00,764.00 3754.00,869.00 3754.00,869.00
//              3754.00,869.00 3755.00,884.00 3755.00,884.00
//              3755.00,884.00 3755.00,989.00 3755.00,989.00
//              3755.00,989.00 3666.00,989.00 3666.00,989.00
//              3666.00,989.00 3666.00,764.00 3666.00,764.00 Z" data-front="59" data-rear="59" data-left="150" data-lot="6" data-street="Community Loop" data-right="150" data-sqft="8,888" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_57" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3753.00,763.00
//            C 3753.00,763.00 3832.00,763.00 3832.00,763.00
//              3832.00,763.00 3832.00,906.00 3832.00,906.00
//              3832.00,906.00 3833.00,921.00 3833.00,921.00
//              3833.00,921.00 3833.00,989.00 3833.00,989.00
//              3833.00,989.00 3753.00,989.00 3753.00,989.00
//              3753.00,989.00 3753.00,763.00 3753.00,763.00 Z" data-front="52" data-rear="52" data-left="150" data-lot="7" data-street="Community Loop" data-right="150" data-sqft="7,837" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_58" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3832.00,763.00
//            C 3832.00,763.00 3913.00,764.00 3913.00,764.00
//              3913.00,764.00 3913.00,988.00 3913.00,988.00
//              3913.00,988.00 3869.00,988.00 3869.00,988.00
//              3869.00,988.00 3854.00,989.00 3854.00,989.00
//              3854.00,989.00 3834.00,989.00 3834.00,989.00
//              3834.00,989.00 3834.00,953.00 3834.00,953.00
//              3834.00,953.00 3833.00,936.00 3833.00,936.00
//              3833.00,936.00 3833.00,861.00 3833.00,861.00
//              3833.00,861.00 3832.00,846.00 3832.00,846.00
//              3832.00,846.00 3832.00,763.00 3832.00,763.00 Z" data-front="52" data-rear="52" data-left="150" data-lot="8" data-street="Community Loop" data-right="150" data-sqft="7,837" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_59" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 3912.00,763.00
//      C 3912.00,763.00 4001.00,763.00 4001.00,763.00
//        4001.00,763.00 4001.00,896.00 4001.00,896.00
//        4001.00,896.00 4002.00,911.00 4002.00,911.00
//        4002.00,911.00 4002.00,989.00 4002.00,989.00
//        4002.00,989.00 3986.00,989.00 3986.00,989.00
//        3986.00,989.00 3970.00,988.00 3970.00,988.00
//        3970.00,988.00 3913.00,988.00 3913.00,988.00
//        3913.00,988.00 3913.00,943.00 3913.00,943.00
//        3913.00,943.00 3912.00,926.00 3912.00,926.00
//        3912.00,926.00 3912.00,763.00 3912.00,763.00 Z" data-front="59" data-rear="59" data-left="150" data-lot="9" data-street="Community Loop" data-right="150" data-sqft="8887" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_61" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="69,900" data-placement="left" d="M 4001.00,764.00
//            C 4001.00,764.00 4067.00,764.00 4067.00,764.00
//              4067.00,764.00 4126.00,769.00 4126.00,769.00
//              4126.00,769.00 4124.09,801.00 4124.09,801.00
//              4124.09,801.00 4120.09,855.00 4120.09,855.00
//              4120.09,855.00 4117.91,889.00 4117.91,889.00
//              4117.91,889.00 4116.00,915.00 4116.00,915.00
//              4116.00,915.00 4115.00,964.00 4115.00,964.00
//              4115.00,964.00 4114.00,979.00 4114.00,979.00
//              4114.00,979.00 4114.00,988.00 4114.00,988.00
//              4114.00,988.00 4001.00,988.00 4001.00,988.00
//              4001.00,988.00 4001.00,764.00 4001.00,764.00 Z" data-front="85" data-rear="76" data-left="150" data-lot="10" data-street="Community Loop" data-right="147" data-sqft="11,815" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_62" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 4001.00,988.00
//            C 4001.00,988.00 4088.00,988.00 4088.00,988.00
//              4088.00,988.00 4108.00,988.00 4108.00,988.00
//              4108.00,988.00 4113.98,989.60 4113.98,989.60
//              4113.98,989.60 4115.00,997.00 4115.00,997.00
//              4115.00,997.00 4115.00,1043.00 4115.00,1043.00
//              4115.00,1043.00 4116.00,1060.00 4116.00,1060.00
//              4116.00,1060.00 4116.00,1109.00 4116.00,1109.00
//              4116.00,1109.00 4117.00,1124.00 4117.00,1124.00
//              4117.00,1124.00 4117.00,1173.00 4117.00,1173.00
//              4117.00,1173.00 4118.00,1188.00 4118.00,1188.00
//              4118.00,1188.00 4118.00,1213.00 4118.00,1213.00
//              4118.00,1213.00 4003.00,1213.00 4003.00,1213.00
//              4003.00,1213.00 4003.00,1131.00 4003.00,1131.00
//              4003.00,1131.00 4002.00,1116.00 4002.00,1116.00
//              4002.00,1116.00 4002.00,1040.00 4002.00,1040.00
//              4002.00,1040.00 4001.00,1025.00 4001.00,1025.00
//              4001.00,1025.00 4001.00,988.00 4001.00,988.00 Z" data-front="76" data-rear="76" data-left="150" data-lot="11" data-street="Collective Lane" data-right="150" data-sqft="11,458" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_64" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3913.00,989.00
//            C 3913.00,989.00 4003.00,989.00 4003.00,989.00
//              4003.00,989.00 4003.00,1140.00 4003.00,1140.00
//              4003.00,1140.00 4003.00,1187.00 4003.00,1187.00
//              4003.00,1187.00 4004.00,1213.00 4004.00,1213.00
//              4004.00,1213.00 3982.00,1213.00 3982.00,1213.00
//              3982.00,1213.00 3966.00,1214.00 3966.00,1214.00
//              3966.00,1214.00 3914.00,1214.00 3914.00,1214.00
//              3914.00,1214.00 3914.00,1062.00 3914.00,1062.00
//              3914.00,1062.00 3914.00,1015.00 3914.00,1015.00
//              3914.00,1015.00 3913.00,989.00 3913.00,989.00 Z" data-front="59" data-rear="59" data-left="150" data-lot="12" data-street="Collective Lane" data-right="150" data-sqft="8,888" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_66" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3834.00,989.00
//            C 3834.00,989.00 3864.00,989.00 3864.00,989.00
//              3864.00,989.00 3881.00,988.00 3881.00,988.00
//              3881.00,988.00 3913.00,988.00 3913.00,988.00
//              3913.00,988.00 3913.00,1071.00 3913.00,1071.00
//              3913.00,1071.00 3914.00,1086.00 3914.00,1086.00
//              3914.00,1086.00 3914.00,1161.00 3914.00,1161.00
//              3914.00,1161.00 3915.00,1176.00 3915.00,1176.00
//              3915.00,1176.00 3915.00,1213.00 3915.00,1213.00
//              3915.00,1213.00 3835.00,1214.00 3835.00,1214.00
//              3835.00,1214.00 3835.00,1072.00 3835.00,1072.00
//              3835.00,1072.00 3834.00,1057.00 3834.00,1057.00
//              3834.00,1057.00 3834.00,989.00 3834.00,989.00 Z" data-front="52" data-rear="52" data-left="150" data-lot="13" data-street="Collective Lane" data-right="150" data-sqft="7,837" data-type="Twin Home" data-unit_type="Sqft" data-block="1" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_67" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3754.00,990.00
//      C 3754.00,990.00 3804.00,990.00 3804.00,990.00
//        3804.00,990.00 3816.00,989.04 3816.00,989.04
//        3816.00,989.04 3833.04,989.04 3833.04,989.04
//        3833.04,989.04 3833.04,1004.00 3833.04,1004.00
//        3833.04,1004.00 3834.00,1016.00 3834.00,1016.00
//        3834.00,1016.00 3834.00,1113.00 3834.00,1113.00
//        3834.00,1113.00 3835.00,1128.00 3835.00,1128.00
//        3835.00,1128.00 3835.00,1214.00 3835.00,1214.00
//        3835.00,1214.00 3785.00,1214.00 3785.00,1214.00
//        3785.00,1214.00 3768.00,1215.00 3768.00,1215.00
//        3755.61,1215.02 3755.02,1215.81 3755.00,1206.00
//        3755.00,1206.00 3755.00,1082.00 3755.00,1082.00
//        3755.00,1082.00 3754.00,1067.00 3754.00,1067.00
//        3754.00,1067.00 3754.00,990.00 3754.00,990.00 Z" data-front="52" data-rear="59" data-left="150" data-lot="14" data-street="Collective Lane" data-right="150" data-sqft="7,838" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_69" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3667.00,990.00
//            C 3667.00,990.00 3755.00,990.00 3755.00,990.00
//              3755.00,990.00 3755.00,1215.00 3755.00,1215.00
//              3755.00,1215.00 3668.00,1215.00 3668.00,1215.00
//              3668.00,1215.00 3668.00,1073.00 3668.00,1073.00
//              3668.00,1073.00 3667.00,1058.00 3667.00,1058.00
//              3667.00,1058.00 3667.00,990.00 3667.00,990.00 ZS" data-front="59" data-rear="59" data-left="150" data-lot="15" data-street="Collective Lane" data-right="150" data-sqft="8,887" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_71" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3579.00,990.00
//            C 3579.00,990.00 3667.00,990.00 3667.00,990.00
//              3667.00,990.00 3667.00,1132.00 3667.00,1132.00
//              3667.00,1132.00 3668.00,1147.00 3668.00,1147.00
//              3668.00,1147.00 3668.00,1214.00 3668.00,1214.00
//              3668.00,1214.00 3579.00,1214.00 3579.00,1214.00
//              3579.00,1214.00 3579.00,990.00 3579.00,990.00 Z" data-front="59" data-rear="59" data-left="150" data-lot="16" data-street="Collective Lane" data-right="150" data-sqft="8,888" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_72" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3500.00,990.96
//         C 3500.00,990.96 3516.00,990.96 3516.00,990.96
//         3516.00,990.96 3528.00,990.00 3528.00,990.00
//         3528.00,990.00 3578.00,990.00 3578.00,990.00
//         3578.00,990.00 3578.00,1067.00 3578.00,1067.00
//         3578.00,1067.00 3579.00,1082.00 3579.00,1082.00
//         3579.00,1082.00 3579.00,1215.00 3579.00,1215.00
//         3579.00,1215.00 3501.00,1215.00 3501.00,1215.00
//         3501.00,1215.00 3501.00,1169.00 3501.00,1169.00
//         3501.00,1169.00 3500.00,1152.00 3500.00,1152.00
//         3500.00,1152.00 3500.00,990.96 3500.00,990.96 Z" data-front="51" data-rear="51" data-left="150" data-lot="17" data-street="Collective Lane" data-right="149" data-sqft="7,773" data-type="Twin Home" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_74" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3422.00,990.00
//            C 3422.00,990.00 3500.00,990.00 3500.00,990.00
//              3500.00,990.00 3500.00,1142.00 3500.00,1142.00
//              3500.00,1142.00 3500.00,1189.00 3500.00,1189.00
//              3500.00,1189.00 3501.00,1216.00 3501.00,1216.00
//              3501.00,1216.00 3442.00,1216.00 3442.00,1216.00
//              3438.42,1216.00 3425.03,1216.93 3423.02,1214.40
//              3421.77,1212.81 3422.00,1208.99 3422.00,1207.00
//              3422.00,1207.00 3422.00,990.00 3422.00,990.00 Z" data-front="51" data-rear="51" data-left="150" data-lot="18" data-street="Collective Lane" data-right="149" data-sqft="7,761" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_76" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3334.00,990.00
//            C 3334.00,990.00 3423.00,990.00 3423.00,990.00
//              3423.00,990.00 3423.00,1216.00 3423.00,1216.00
//              3423.00,1216.00 3403.00,1214.17 3403.00,1214.17
//              3403.00,1214.17 3356.00,1210.17 3356.00,1210.17
//              3356.00,1210.17 3342.00,1209.03 3342.00,1209.03
//              3342.00,1209.03 3336.02,1207.40 3336.02,1207.40
//              3336.02,1207.40 3335.00,1200.00 3335.00,1200.00
//              3335.00,1200.00 3335.00,1071.00 3335.00,1071.00
//              3335.00,1071.00 3334.00,1056.00 3334.00,1056.00
//              3334.00,1056.00 3334.00,990.00 3334.00,990.00 Z" data-front="59" data-rear="59" data-left="149" data-lot="19" data-street="Collective Lane" data-right="144" data-sqft="8,707" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_77" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3210.00,991.00
//            C 3210.00,991.00 3334.00,991.00 3334.00,991.00
//              3334.00,991.00 3334.00,1053.00 3334.00,1053.00
//              3334.00,1053.00 3335.00,1070.00 3335.00,1070.00
//              3335.00,1070.00 3335.00,1208.00 3335.00,1208.00
//              3335.00,1208.00 3264.00,1197.57 3264.00,1197.57
//              3264.00,1197.57 3239.00,1192.40 3239.00,1192.40
//              3239.00,1192.40 3219.00,1188.40 3219.00,1188.40
//              3219.00,1188.40 3211.02,1185.44 3211.02,1185.44
//              3211.02,1185.44 3210.00,1178.00 3210.00,1178.00
//              3210.00,1178.00 3210.00,991.00 3210.00,991.00 Z" data-front="83" data-rear="81" data-left="144" data-lot="20" data-street="Collective Lane" data-right="130" data-sqft="11,255" data-type="Twin Home" data-unit_type="Sqft" data-block="3" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_80" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3211.00,1271.00
//      C 3211.00,1271.00 3254.00,1277.13 3254.00,1277.13
//        3254.00,1277.13 3323.00,1286.72 3323.00,1286.72
//        3323.00,1286.72 3361.00,1292.00 3361.00,1292.00
//        3361.00,1292.00 3361.00,1480.00 3361.00,1480.00
//        3361.00,1480.00 3246.00,1464.15 3246.00,1464.15
//        3246.00,1464.15 3222.00,1460.95 3222.00,1460.95
//        3222.00,1460.95 3212.02,1458.40 3212.02,1458.40
//        3212.02,1458.40 3211.00,1451.00 3211.00,1451.00
//        3211.00,1451.00 3211.00,1271.00 3211.00,1271.00 Z" data-front="100" data-rear="101" data-left="126" data-lot="1" data-street="Collective Lane" data-right="125" data-sqft="15,036" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_82" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3361.00,1292.00
//       C 3361.00,1292.00 3384.00,1293.00 3384.00,1293.00
//         3384.00,1293.00 3419.00,1294.00 3419.00,1294.00
//         3419.00,1294.00 3436.00,1295.04 3436.00,1295.04
//         3436.00,1295.04 3449.00,1295.04 3449.00,1295.04
//         3449.00,1295.04 3460.00,1296.00 3460.00,1296.00
//         3460.00,1296.00 3482.00,1297.00 3482.00,1297.00
//         3482.00,1297.00 3482.00,1439.00 3482.00,1439.00
//         3482.00,1439.00 3483.00,1454.00 3483.00,1454.00
//         3483.01,1462.81 3483.91,1490.29 3482.00,1497.00
//         3482.00,1497.00 3440.00,1490.87 3440.00,1490.87
//         3440.00,1490.87 3362.00,1480.00 3362.00,1480.00
//         3362.00,1480.00 3362.00,1419.00 3362.00,1419.00
//         3362.00,1419.00 3361.00,1402.00 3361.00,1402.00
//         3361.00,1402.00 3361.00,1292.00 3361.00,1292.00 Z" data-front="80" data-rear="81" data-left="125" data-lot="2" data-street="Collective Lane" data-right="133" data-sqft="10,439" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_83" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3481.00,1297.00
//      C 3481.00,1297.00 3601.00,1297.00 3601.00,1297.00
//        3601.00,1297.00 3602.00,1318.00 3602.00,1318.00
//        3602.00,1318.00 3602.00,1383.00 3602.00,1383.00
//        3602.00,1383.00 3603.00,1400.00 3603.00,1400.00
//        3603.00,1400.00 3603.00,1466.00 3603.00,1466.00
//        3603.00,1466.00 3604.00,1481.00 3604.00,1481.00
//        3604.00,1481.00 3604.00,1514.00 3604.00,1514.00
//        3604.00,1514.00 3571.00,1509.27 3571.00,1509.27
//        3571.00,1509.27 3512.00,1500.87 3512.00,1500.87
//        3512.00,1500.87 3483.00,1497.00 3483.00,1497.00
//        3483.00,1497.00 3483.00,1481.00 3483.00,1481.00
//        3483.00,1481.00 3482.00,1465.00 3482.00,1465.00
//        3482.00,1465.00 3482.00,1330.00 3482.00,1330.00
//        3482.00,1330.00 3481.00,1315.00 3481.00,1315.00
//        3481.00,1315.00 3481.00,1297.00 3481.00,1297.00 Z" data-front="80" data-rear="80" data-left="133" data-lot="3" data-street="Collective Lane" data-right="144" data-sqft="11,142" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_85" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3599.00,1295.00
//            C 3599.00,1295.00 3624.00,1295.00 3624.00,1295.00
//              3624.00,1295.00 3641.00,1296.00 3641.00,1296.00
//              3641.00,1296.00 3683.00,1296.00 3683.00,1296.00
//              3683.00,1296.00 3698.00,1297.00 3698.00,1297.00
//              3698.00,1297.00 3721.00,1297.00 3721.00,1297.00
//              3721.00,1297.00 3721.00,1405.00 3721.00,1405.00
//              3721.00,1405.00 3722.00,1420.00 3722.00,1420.00
//              3722.00,1420.00 3722.00,1529.00 3722.00,1529.00
//              3722.00,1529.00 3678.00,1523.28 3678.00,1523.28
//              3678.00,1523.28 3606.00,1514.00 3606.00,1514.00
//              3606.00,1514.00 3605.00,1491.00 3605.00,1491.00
//              3605.00,1491.00 3605.00,1475.00 3605.00,1475.00
//              3605.00,1475.00 3604.00,1460.00 3604.00,1460.00
//              3604.00,1460.00 3604.00,1443.00 3604.00,1443.00
//              3604.00,1443.00 3603.00,1428.00 3603.00,1428.00
//              3603.00,1428.00 3603.00,1413.00 3603.00,1413.00
//              3603.00,1413.00 3602.00,1397.00 3602.00,1397.00
//              3602.00,1397.00 3600.00,1320.00 3600.00,1320.00
//              3600.00,1320.00 3599.00,1305.00 3599.00,1305.00
//              3599.00,1305.00 3599.00,1295.00 3599.00,1295.00 Z" data-front="80" data-rear="80" data-left="144" data-lot="4" data-street="Collective Lane" data-right="156" data-sqft="12,052" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_86" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="89,900" data-placement="left" d="M 3721.00,1296.00
//            C 3721.00,1296.00 3775.00,1296.00 3775.00,1296.00
//              3775.00,1296.00 3792.00,1297.00 3792.00,1297.00
//              3792.00,1297.00 3845.00,1297.00 3845.00,1297.00
//              3845.00,1297.00 3845.00,1350.00 3845.00,1350.00
//              3845.00,1350.00 3846.00,1367.00 3846.00,1367.00
//              3846.00,1367.00 3846.00,1477.00 3846.00,1477.00
//              3846.00,1477.00 3847.00,1492.00 3847.00,1492.00
//              3847.00,1492.00 3847.00,1547.00 3847.00,1547.00
//              3847.00,1547.00 3809.00,1542.15 3809.00,1542.15
//              3809.00,1542.15 3721.00,1531.00 3721.00,1531.00
//              3721.00,1531.00 3721.00,1296.00 3721.00,1296.00 Z" data-front="82" data-rear="82" data-left="156" data-lot="5" data-street="Collective Lane" data-right="167" data-sqft="13,297" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_88" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3843.00,1296.00
//            C 3843.00,1296.00 3968.00,1296.00 3968.00,1296.00
//              3968.00,1296.00 3968.00,1377.00 3968.00,1377.00
//              3968.00,1377.00 3969.00,1392.00 3969.00,1392.00
//              3969.00,1392.00 3969.00,1466.00 3969.00,1466.00
//              3969.00,1466.00 3970.00,1481.00 3970.00,1481.00
//              3970.00,1481.00 3970.00,1561.00 3970.00,1561.00
//              3970.00,1561.00 3936.00,1557.28 3936.00,1557.28
//              3936.00,1557.28 3873.00,1549.85 3873.00,1549.85
//              3873.00,1549.85 3846.00,1547.00 3846.00,1547.00
//              3846.00,1547.00 3846.00,1514.00 3846.00,1514.00
//              3846.00,1514.00 3845.00,1497.00 3845.00,1497.00
//              3845.00,1497.00 3845.00,1431.00 3845.00,1431.00
//              3845.00,1431.00 3844.00,1414.00 3844.00,1414.00
//              3844.00,1414.00 3844.00,1345.00 3844.00,1345.00
//              3844.00,1345.00 3843.00,1330.00 3843.00,1330.00
//              3843.00,1330.00 3843.00,1296.00 3843.00,1296.00 Z" data-front="81" data-rear="81" data-left="16&amp;" data-lot="6" data-street="Collective Lane" data-right="179" data-sqft="14,097" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_90" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="89,900" data-placement="left" d="M 3964.00,1295.00
//            C 3964.00,1295.00 3983.00,1294.00 3983.00,1294.00
//              3983.00,1294.00 4016.00,1294.00 4016.00,1294.00
//              4016.00,1294.00 4057.00,1294.00 4057.00,1294.00
//              4057.00,1294.00 4074.00,1293.00 4074.00,1293.00
//              4074.00,1293.00 4118.00,1293.00 4118.00,1293.00
//              4118.00,1293.00 4118.00,1461.00 4118.00,1461.00
//              4118.00,1461.00 4119.00,1476.00 4119.00,1476.00
//              4119.00,1476.00 4119.00,1557.00 4119.00,1557.00
//              4119.00,1557.00 4106.00,1558.00 4106.00,1558.00
//              4106.00,1558.00 4082.00,1559.09 4082.00,1559.09
//              4082.00,1559.09 4071.00,1560.00 4071.00,1560.00
//              4071.00,1560.00 4055.00,1561.00 4055.00,1561.00
//              4055.00,1561.00 4034.00,1562.00 4034.00,1562.00
//              4034.00,1562.00 4021.00,1563.00 4021.00,1563.00
//              4021.00,1563.00 4003.00,1564.00 4003.00,1564.00
//              4003.00,1564.00 3983.00,1565.00 3983.00,1565.00
//              3983.00,1565.00 3966.00,1566.00 3966.00,1566.00
//              3966.00,1566.00 3966.00,1484.00 3966.00,1484.00
//              3966.00,1484.00 3965.00,1469.00 3965.00,1469.00
//              3965.00,1469.00 3965.00,1385.00 3965.00,1385.00
//              3965.00,1385.00 3965.00,1326.00 3965.00,1326.00
//              3965.00,1326.00 3964.00,1295.00 3964.00,1295.00 Z" data-front="100" data-rear="100" data-left="175" data-lot="7" data-street="Collective Lane" data-right="179" data-sqft="17,767" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_91" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3969.00,1565.00
//            C 3969.00,1565.00 3990.00,1564.00 3990.00,1564.00
//              3990.00,1564.00 4000.00,1563.00 4000.00,1563.00
//              4000.00,1563.00 4011.00,1563.00 4011.00,1563.00
//              4011.00,1563.00 4025.00,1562.00 4025.00,1562.00
//              4025.00,1562.00 4041.00,1561.00 4041.00,1561.00
//              4041.00,1561.00 4058.00,1560.00 4058.00,1560.00
//              4058.00,1560.00 4078.00,1559.00 4078.00,1559.00
//              4078.00,1559.00 4097.00,1558.00 4097.00,1558.00
//              4097.00,1558.00 4117.00,1557.00 4117.00,1557.00
//              4117.00,1557.00 4116.00,1577.00 4116.00,1577.00
//              4116.00,1577.00 4115.00,1598.00 4115.00,1598.00
//              4115.00,1598.00 4114.00,1627.00 4114.00,1627.00
//              4114.00,1627.00 4113.00,1641.00 4113.00,1641.00
//              4113.00,1641.00 4112.00,1671.00 4112.00,1671.00
//              4112.00,1671.00 4111.00,1684.00 4111.00,1684.00
//              4111.00,1684.00 4110.00,1713.00 4110.00,1713.00
//              4110.00,1713.00 4109.00,1730.00 4109.00,1730.00
//              4109.00,1730.00 4108.00,1751.00 4108.00,1751.00
//              4108.00,1751.00 4107.00,1780.00 4107.00,1780.00
//              4107.00,1780.00 4106.00,1793.00 4106.00,1793.00
//              4106.00,1793.00 4105.00,1818.96 4105.00,1818.96
//              4105.00,1818.96 4093.00,1818.96 4093.00,1818.96
//              4093.00,1818.96 4081.00,1818.00 4081.00,1818.00
//              4081.00,1818.00 4006.00,1816.00 4006.00,1816.00
//              4006.00,1816.00 3989.00,1815.00 3989.00,1815.00
//              3989.00,1815.00 3974.00,1815.00 3974.00,1815.00
//              3974.00,1815.00 3958.00,1814.00 3958.00,1814.00
//              3958.00,1814.00 3952.00,1814.00 3952.00,1814.00
//              3952.00,1814.00 3952.91,1803.00 3952.91,1803.00
//              3952.91,1803.00 3955.91,1759.00 3955.91,1759.00
//              3955.91,1759.00 3964.09,1634.00 3964.09,1634.00
//              3964.09,1634.00 3967.09,1590.00 3967.09,1590.00
//              3967.09,1590.00 3969.00,1565.00 3969.00,1565.00 Z" data-front="100" data-rear="100" data-left="140" data-lot="8" data-street="Collective Lane" data-right="166" data-sqft="17,237" data-type="Single family" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_93" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3846.00,1547.00
//      C 3846.00,1547.00 3875.00,1551.27 3875.00,1551.27
//        3875.00,1551.27 3935.00,1559.73 3935.00,1559.73
//        3935.00,1559.73 3958.00,1562.87 3958.00,1562.87
//        3960.19,1563.17 3966.90,1563.80 3968.40,1565.02
//        3970.81,1566.98 3969.12,1577.56 3968.96,1581.00
//        3968.96,1581.00 3967.09,1603.00 3967.09,1603.00
//        3967.09,1603.00 3965.10,1629.00 3965.10,1629.00
//        3965.10,1629.00 3965.10,1637.00 3965.10,1637.00
//        3965.10,1637.00 3962.91,1665.00 3962.91,1665.00
//        3962.91,1665.00 3958.91,1720.00 3958.91,1720.00
//        3958.91,1720.00 3953.91,1789.00 3953.91,1789.00
//        3953.91,1789.00 3952.00,1813.00 3952.00,1813.00
//        3952.00,1813.00 3937.00,1812.00 3937.00,1812.00
//        3937.00,1812.00 3922.00,1811.00 3922.00,1811.00
//        3922.00,1811.00 3901.00,1810.00 3901.00,1810.00
//        3901.00,1810.00 3887.00,1809.00 3887.00,1809.00
//        3887.00,1809.00 3849.00,1807.00 3849.00,1807.00
//        3849.00,1807.00 3832.00,1806.00 3832.00,1806.00
//        3832.00,1806.00 3833.00,1786.00 3833.00,1786.00
//        3833.00,1786.00 3834.00,1770.00 3834.00,1770.00
//        3834.00,1770.00 3836.00,1731.00 3836.00,1731.00
//        3836.00,1731.00 3837.00,1714.00 3837.00,1714.00
//        3837.00,1714.00 3839.00,1673.00 3839.00,1673.00
//        3839.00,1673.00 3840.00,1657.00 3840.00,1657.00
//        3840.00,1657.00 3841.00,1645.00 3841.00,1645.00
//        3841.00,1645.00 3841.00,1633.00 3841.00,1633.00
//        3841.00,1633.00 3842.00,1620.00 3842.00,1620.00
//        3842.00,1620.00 3843.00,1605.00 3843.00,1605.00
//        3843.00,1605.00 3844.00,1581.00 3844.00,1581.00
//        3844.00,1581.00 3845.00,1567.00 3845.00,1567.00
//        3845.00,1567.00 3846.00,1547.00 3846.00,1547.00 Z" data-front="81" data-rear="81" data-left="166" data-lot="9" data-street="Collective Lane" data-right="172" data-sqft="13,828" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_95" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3759.00,1536.00
//         C 3759.00,1536.00 3793.00,1540.58 3793.00,1540.58
//         3793.00,1540.58 3847.00,1548.00 3847.00,1548.00
//         3847.00,1548.00 3845.91,1566.00 3845.91,1566.00
//         3845.91,1566.00 3845.00,1576.00 3845.00,1576.00
//         3845.00,1576.00 3843.00,1614.00 3843.00,1614.00
//         3843.00,1614.00 3842.00,1630.00 3842.00,1630.00
//         3842.00,1630.00 3841.00,1647.00 3841.00,1647.00
//         3841.00,1647.00 3839.00,1686.00 3839.00,1686.00
//         3839.00,1686.00 3838.00,1700.00 3838.00,1700.00
//         3838.00,1700.00 3836.00,1736.00 3836.00,1736.00
//         3836.00,1736.00 3835.00,1752.00 3835.00,1752.00
//         3835.00,1752.00 3834.00,1774.00 3834.00,1774.00
//         3834.00,1774.00 3833.00,1788.00 3833.00,1788.00
//         3833.00,1788.00 3832.00,1807.00 3832.00,1807.00
//         3832.00,1807.00 3808.00,1804.83 3808.00,1804.83
//         3808.00,1804.83 3771.00,1799.34 3771.00,1799.34
//         3771.00,1799.34 3710.00,1782.28 3710.00,1782.28
//         3710.00,1782.28 3686.00,1775.00 3686.00,1775.00
//         3686.00,1775.00 3706.98,1706.00 3706.98,1706.00
//         3706.98,1706.00 3743.72,1586.00 3743.72,1586.00
//         3743.72,1586.00 3752.34,1558.00 3752.34,1558.00
//         3752.34,1558.00 3759.00,1536.00 3759.00,1536.00 Z" data-front="98" data-rear="58" data-left="172" data-lot="10" data-street="10" data-right="167" data-sqft="13,381" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_97" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3639.00,1519.00
//            C 3639.00,1519.00 3682.00,1525.15 3682.00,1525.15
//              3682.00,1525.15 3714.00,1529.72 3714.00,1529.72
//              3714.00,1529.72 3759.00,1536.00 3759.00,1536.00
//              3759.00,1536.00 3752.72,1558.00 3752.72,1558.00
//              3752.72,1558.00 3739.02,1603.00 3739.02,1603.00
//              3739.02,1603.00 3704.34,1717.00 3704.34,1717.00
//              3704.34,1717.00 3691.66,1759.00 3691.66,1759.00
//              3690.69,1762.04 3688.36,1772.26 3685.58,1773.34
//              3683.46,1774.16 3678.24,1772.35 3676.00,1771.71
//              3676.00,1771.71 3654.00,1765.34 3654.00,1765.34
//              3654.00,1765.34 3572.00,1742.00 3572.00,1742.00
//              3572.00,1742.00 3578.58,1717.00 3578.58,1717.00
//              3578.58,1717.00 3595.28,1662.00 3595.28,1662.00
//              3595.28,1662.00 3622.28,1573.00 3622.28,1573.00
//              3622.28,1573.00 3639.00,1519.00 3639.00,1519.00 Z" data-front="80" data-rear="81" data-left="154" data-lot="11" data-street="Collective Lane" data-right="167" data-sqft="12,878" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_99" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3456.00,1705.00
//            C 3456.00,1705.00 3460.28,1691.00 3460.28,1691.00
//              3460.28,1691.00 3470.98,1657.00 3470.98,1657.00
//              3470.98,1657.00 3502.98,1555.00 3502.98,1555.00
//              3502.98,1555.00 3514.86,1517.00 3514.86,1517.00
//              3514.86,1517.00 3520.70,1502.22 3520.70,1502.22
//              3520.70,1502.22 3530.00,1502.22 3530.00,1502.22
//              3530.00,1502.22 3552.00,1505.73 3552.00,1505.73
//              3552.00,1505.73 3640.00,1519.00 3640.00,1519.00
//              3640.00,1519.00 3613.02,1607.00 3613.02,1607.00
//              3613.02,1607.00 3572.00,1740.00 3572.00,1740.00
//              3572.00,1740.00 3531.00,1727.72 3531.00,1727.72
//              3531.00,1727.72 3456.00,1705.00 3456.00,1705.00 Z" data-front="80" data-rear="81" data-left="154" data-lot="12" data-street="Collective Lane" data-right="141" data-sqft="11,863" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_100" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3335.00,1668.00
//            C 3335.00,1668.00 3351.67,1617.00 3351.67,1617.00
//              3351.67,1617.00 3379.33,1532.00 3379.33,1532.00
//              3379.33,1532.00 3390.67,1498.00 3390.67,1498.00
//              3390.67,1498.00 3395.56,1486.17 3395.56,1486.17
//              3395.56,1486.17 3405.00,1486.17 3405.00,1486.17
//              3405.00,1486.17 3429.00,1489.58 3429.00,1489.58
//              3429.00,1489.58 3494.00,1498.73 3494.00,1498.73
//              3494.00,1498.73 3524.00,1503.00 3524.00,1503.00
//              3524.00,1503.00 3499.67,1578.00 3499.67,1578.00
//              3499.67,1578.00 3458.00,1706.00 3458.00,1706.00
//              3458.00,1706.00 3406.00,1690.02 3406.00,1690.02
//              3406.00,1690.02 3335.00,1668.00 3335.00,1668.00 Z" data-front="80" data-rear="81" data-left="141" data-lot="13" data-street="Collective Lane" data-right="129" data-sqft="11,234" data-type="Single Family" data-unit_type="Sqft" data-block="4" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_102" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3212.00,1458.00
//            C 3212.00,1458.00 3239.00,1461.73 3239.00,1461.73
//              3239.00,1461.73 3288.00,1468.73 3288.00,1468.73
//              3288.00,1468.73 3358.00,1478.73 3358.00,1478.73
//              3358.00,1478.73 3394.00,1484.00 3394.00,1484.00
//              3394.00,1484.00 3371.02,1559.00 3371.02,1559.00
//              3371.02,1559.00 3337.00,1669.00 3337.00,1669.00
//              3337.00,1669.00 3313.00,1664.61 3313.00,1664.61
//              3313.00,1664.61 3258.00,1654.43 3258.00,1654.43
//              3258.00,1654.43 3209.00,1648.00 3209.00,1648.00
//              3209.00,1648.00 3209.00,1624.00 3209.00,1624.00
//              3209.00,1624.00 3210.00,1607.00 3210.00,1607.00
//              3210.00,1607.00 3210.00,1561.00 3210.00,1561.00
//              3210.00,1561.00 3211.00,1544.00 3211.00,1544.00
//              3211.00,1544.00 3211.00,1495.00 3211.00,1495.00
//              3211.00,1495.00 3212.00,1480.00 3212.00,1480.00
//              3212.00,1480.00 3212.00,1458.00 3212.00,1458.00 Z" data-front="86" data-rear="101" data-left="129" data-lot="14" data-street="Collective Lane" data-right="126" data-sqft="13,056" data-type="Single family" data-unit_type="Sqft" data-block="4" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_106" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3213.00,1732.00
//            C 3213.00,1732.00 3257.00,1735.29 3257.00,1735.29
//              3257.00,1735.29 3323.00,1748.48 3323.00,1748.48
//              3323.00,1748.48 3356.00,1760.00 3356.00,1760.00
//              3356.00,1760.00 3338.71,1821.00 3338.71,1821.00
//              3338.71,1821.00 3305.29,1937.00 3305.29,1937.00
//              3305.29,1937.00 3293.28,1979.00 3293.28,1979.00
//              3291.34,1985.46 3291.41,1992.48 3283.00,1991.83
//              3283.00,1991.83 3269.00,1991.00 3269.00,1991.00
//              3269.00,1991.00 3252.00,1990.00 3252.00,1990.00
//              3252.00,1990.00 3238.00,1989.00 3238.00,1989.00
//              3238.00,1989.00 3196.00,1987.00 3196.00,1987.00
//              3196.00,1987.00 3197.83,1965.00 3197.83,1965.00
//              3197.83,1965.00 3199.83,1941.00 3199.83,1941.00
//              3199.83,1941.00 3204.17,1884.00 3204.17,1884.00
//              3204.17,1884.00 3209.00,1816.00 3209.00,1816.00
//              3209.00,1816.00 3210.04,1803.00 3210.04,1803.00
//              3210.04,1803.00 3210.04,1789.00 3210.04,1789.00
//              3210.04,1789.00 3211.04,1777.00 3211.04,1777.00
//              3211.04,1777.00 3211.04,1764.00 3211.04,1764.00
//              3211.04,1764.00 3212.00,1753.00 3212.00,1753.00
//              3212.00,1753.00 3213.00,1732.00 3213.00,1732.00 Z" data-front="129" data-rear="60" data-left="115" data-lot="1" data-street="Collective Lane" data-right="161" data-sqft="13,125" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_108" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3357.00,1761.00
//      C 3357.00,1761.00 3382.00,1767.98 3382.00,1767.98
//        3382.00,1767.98 3409.00,1776.02 3409.00,1776.02
//        3409.00,1776.02 3436.00,1784.00 3436.00,1784.00
//        3436.00,1784.00 3429.34,1808.00 3429.34,1808.00
//        3429.34,1808.00 3417.67,1845.00 3417.67,1845.00
//        3417.67,1845.00 3386.00,1945.00 3386.00,1945.00
//        3386.00,1945.00 3373.67,1984.00 3373.67,1984.00
//        3373.67,1984.00 3368.30,1998.01 3368.30,1998.01
//        3368.30,1998.01 3361.00,1998.83 3361.00,1998.83
//        3361.00,1998.83 3345.00,1997.42 3345.00,1997.42
//        3345.00,1997.42 3287.00,1993.00 3287.00,1993.00
//        3287.00,1993.00 3294.98,1965.00 3294.98,1965.00
//        3294.98,1965.00 3309.28,1918.00 3309.28,1918.00
//        3309.28,1918.00 3357.00,1761.00 3357.00,1761.00 Z" data-front="54" data-rear="56" data-left="161" data-lot="2" data-street="Collective Lane" data-right="149" data-sqft="8,568" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_110" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3436.00,1785.00
//      C 3436.00,1785.00 3462.00,1792.33 3462.00,1792.33
//        3462.00,1792.33 3510.00,1807.00 3510.00,1807.00
//        3510.00,1807.00 3499.42,1843.00 3499.42,1843.00
//        3499.42,1843.00 3467.28,1951.00 3467.28,1951.00
//        3467.28,1951.00 3451.00,2006.00 3451.00,2006.00
//        3451.00,2006.00 3441.00,2005.09 3441.00,2005.09
//        3441.00,2005.09 3410.00,2002.83 3410.00,2002.83
//        3410.00,2002.83 3391.00,2001.04 3391.00,2001.04
//        3391.00,2001.04 3377.00,2000.00 3377.00,2000.00
//        3375.19,1999.94 3372.27,2000.12 3371.11,1998.40
//        3369.42,1995.90 3373.05,1986.87 3374.00,1984.00
//        3374.00,1984.00 3386.28,1945.00 3386.28,1945.00
//        3386.28,1945.00 3420.02,1837.00 3420.02,1837.00
//        3420.02,1837.00 3436.00,1785.00 3436.00,1785.00 Z" data-front="53" data-rear="54" data-left="149" data-lot="3" data-street="Collective Lane" data-right="138" data-sqft="7,631" data-type="Twin Home " data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_112" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3510.00,1806.00
//            C 3510.00,1806.00 3568.00,1825.34 3568.00,1825.34
//              3568.00,1825.34 3582.00,1830.33 3582.00,1830.33
//              3583.80,1830.95 3586.87,1831.79 3587.89,1833.51
//              3589.35,1835.99 3585.98,1844.08 3585.14,1847.00
//              3585.14,1847.00 3573.33,1884.00 3573.33,1884.00
//              3573.33,1884.00 3541.98,1982.00 3541.98,1982.00
//              3541.98,1982.00 3527.00,2029.00 3527.00,2029.00
//              3527.00,2029.00 3503.00,2022.42 3503.00,2022.42
//              3503.00,2022.42 3453.00,2007.00 3453.00,2007.00
//              3453.00,2007.00 3460.58,1980.00 3460.58,1980.00
//              3460.58,1980.00 3479.58,1913.00 3479.58,1913.00
//              3479.58,1913.00 3498.58,1846.00 3498.58,1846.00
//              3498.58,1846.00 3510.00,1806.00 3510.00,1806.00 Z" data-front="53" data-rear="53" data-left="149" data-lot="4" data-street="Collective Lane" data-right="138" data-sqft="7,342" data-type="Single family" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_114" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3528.00,2030.00
//            C 3528.00,2030.00 3542.28,1982.00 3542.28,1982.00
//              3542.28,1982.00 3572.28,1883.00 3572.28,1883.00
//              3572.28,1883.00 3583.14,1847.00 3583.14,1847.00
//              3583.95,1844.19 3586.30,1834.45 3588.51,1833.11
//              3591.67,1831.19 3605.14,1836.78 3609.00,1837.98
//              3609.00,1837.98 3669.00,1857.00 3669.00,1857.00
//              3669.00,1857.00 3663.72,1875.00 3663.72,1875.00
//              3663.72,1875.00 3654.72,1905.00 3654.72,1905.00
//              3654.72,1905.00 3623.72,2008.00 3623.72,2008.00
//              3623.72,2008.00 3612.98,2044.00 3612.98,2044.00
//              3612.11,2046.82 3610.65,2054.31 3607.72,2055.22
//              3605.91,2055.78 3602.77,2054.58 3601.00,2054.00
//              3601.00,2054.00 3586.00,2049.00 3586.00,2049.00
//              3586.00,2049.00 3528.00,2030.00 3528.00,2030.00 Z" data-front="58" data-rear="58" data-left="138" data-lot="5" data-street="Collective Lane" data-right="139" data-sqft="8,082" data-type="Single Family" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_115" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="-1" data-placement="left" d="M 3669.00,1854.00
//            C 3669.00,1854.00 3692.00,1860.58 3692.00,1860.58
//              3692.00,1860.58 3729.00,1871.29 3729.00,1871.29
//              3729.00,1871.29 3744.00,1875.66 3744.00,1875.66
//              3745.79,1876.23 3748.85,1876.97 3749.96,1878.56
//              3751.63,1880.94 3749.29,1889.92 3748.75,1893.00
//              3748.75,1893.00 3741.20,1932.00 3741.20,1932.00
//              3741.20,1932.00 3722.42,2030.00 3722.42,2030.00
//              3722.42,2030.00 3711.00,2089.00 3711.00,2089.00
//              3704.45,2087.99 3690.96,2082.99 3684.00,2080.67
//              3684.00,2080.67 3635.00,2064.33 3635.00,2064.33
//              3635.00,2064.33 3616.00,2058.00 3616.00,2058.00
//              3614.01,2057.33 3610.23,2056.35 3609.11,2054.49
//              3607.48,2051.78 3610.71,2043.96 3611.66,2041.00
//              3611.66,2041.00 3622.98,2004.00 3622.98,2004.00
//              3622.98,2004.00 3656.98,1893.00 3656.98,1893.00
//              3656.98,1893.00 3669.00,1854.00 3669.00,1854.00 Z" data-front="56" data-rear="71" data-left="139" data-lot="6" data-street="Collective Lane" data-right="143" data-sqft="9,001" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_117" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3711.00,2089.00
//            C 3711.00,2089.00 3742.60,1933.00 3742.60,1933.00
//              3742.60,1933.00 3750.80,1893.00 3750.80,1893.00
//              3750.80,1893.00 3754.73,1879.03 3754.73,1879.03
//              3754.73,1879.03 3770.00,1879.95 3770.00,1879.95
//              3770.00,1879.95 3816.00,1886.00 3816.00,1886.00
//              3816.00,1886.00 3814.09,1913.00 3814.09,1913.00
//              3814.09,1913.00 3811.00,1958.00 3811.00,1958.00
//              3811.00,1958.00 3811.00,1967.00 3811.00,1967.00
//              3811.00,1967.00 3806.09,2034.00 3806.09,2034.00
//              3806.09,2034.00 3804.10,2063.00 3804.10,2063.00
//              3804.10,2063.00 3804.10,2073.00 3804.10,2073.00
//              3804.10,2073.00 3801.96,2095.00 3801.96,2095.00
//              3801.96,2095.00 3801.96,2104.00 3801.96,2104.00
//              3801.14,2111.30 3800.52,2117.65 3791.00,2116.39
//              3791.00,2116.39 3761.00,2106.34 3761.00,2106.34
//              3761.00,2106.34 3711.00,2089.00 3711.00,2089.00 Z" data-front="41" data-rear="60" data-left="143" data-lot="7" data-street="Collective Lane" data-right="156" data-sqft="7,441" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_119" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3816.00,1886.04
//            C 3816.00,1886.04 3830.00,1886.04 3830.00,1886.04
//              3830.00,1886.04 3843.00,1887.00 3843.00,1887.00
//              3843.00,1887.00 3860.00,1888.00 3860.00,1888.00
//              3860.00,1888.00 3874.00,1889.00 3874.00,1889.00
//              3874.00,1889.00 3890.96,1890.00 3890.96,1890.00
//              3890.96,1890.00 3890.96,1903.00 3890.96,1903.00
//              3890.96,1903.00 3890.00,1915.00 3890.00,1915.00
//              3890.00,1915.00 3889.00,1964.00 3889.00,1964.00
//              3889.00,1964.00 3888.00,1980.00 3888.00,1980.00
//              3888.00,1980.00 3886.00,2060.00 3886.00,2060.00
//              3886.00,2060.00 3885.00,2075.00 3885.00,2075.00
//              3885.00,2075.00 3885.00,2093.00 3885.00,2093.00
//              3885.00,2093.00 3884.00,2108.00 3884.00,2108.00
//              3884.00,2108.00 3884.00,2124.00 3884.00,2124.00
//              3884.00,2124.00 3882.98,2131.36 3882.98,2131.36
//              3882.98,2131.36 3877.00,2132.08 3877.00,2132.08
//              3877.00,2132.08 3863.00,2129.75 3863.00,2129.75
//              3863.00,2129.75 3801.00,2120.00 3801.00,2120.00
//              3801.00,2120.00 3801.96,2107.00 3801.96,2107.00
//              3801.96,2107.00 3805.00,2061.00 3805.00,2061.00
//              3805.00,2061.00 3805.00,2052.00 3805.00,2052.00
//              3805.00,2052.00 3812.00,1952.00 3812.00,1952.00
//              3812.00,1952.00 3812.00,1943.00 3812.00,1943.00
//              3812.00,1943.00 3815.00,1905.00 3815.00,1905.00
//              3815.00,1905.00 3815.00,1896.00 3815.00,1896.00
//              3815.00,1896.00 3816.00,1886.04 3816.00,1886.04 Z" data-front="54" data-rear="57" data-left="153" data-lot="8" data-street="Collective Lane" data-right="163" data-sqft="8,802" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_121" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3894.00,1889.00
//            C 3894.00,1889.00 3920.00,1890.83 3920.00,1890.83
//              3920.00,1890.83 3979.00,1896.00 3979.00,1896.00
//              3979.00,1896.00 3979.00,1908.00 3979.00,1908.00
//              3979.00,1908.00 3978.00,1927.00 3978.00,1927.00
//              3978.00,1927.00 3977.00,1943.00 3977.00,1943.00
//              3977.00,1943.00 3976.00,1967.00 3976.00,1967.00
//              3976.00,1967.00 3975.04,1977.00 3975.04,1977.00
//              3975.04,1977.00 3975.04,1986.00 3975.04,1986.00
//              3975.04,1986.00 3974.00,2005.00 3974.00,2005.00
//              3974.00,2005.00 3973.00,2018.00 3973.00,2018.00
//              3973.00,2018.00 3972.00,2044.00 3972.00,2044.00
//              3972.00,2044.00 3971.00,2061.00 3971.00,2061.00
//              3971.00,2061.00 3970.00,2078.00 3970.00,2078.00
//              3970.00,2078.00 3969.00,2095.00 3969.00,2095.00
//              3969.00,2095.00 3968.00,2121.00 3968.00,2121.00
//              3968.00,2121.00 3967.00,2131.00 3967.00,2131.00
//              3967.00,2131.00 3967.00,2143.00 3967.00,2143.00
//              3967.00,2143.00 3966.00,2155.00 3966.00,2155.00
//              3966.00,2155.00 3902.00,2141.00 3902.00,2141.00
//              3902.00,2141.00 3881.99,2135.28 3881.99,2135.28
//              3881.99,2135.28 3881.00,2126.00 3881.00,2126.00
//              3881.00,2126.00 3882.00,2105.00 3882.00,2105.00
//              3882.00,2105.00 3883.00,2091.00 3883.00,2091.00
//              3883.00,2091.00 3885.00,2050.00 3885.00,2050.00
//              3885.00,2050.00 3885.96,2040.00 3885.96,2040.00
//              3885.96,2040.00 3885.96,2032.00 3885.96,2032.00
//              3885.96,2032.00 3887.00,2014.00 3887.00,2014.00
//              3887.00,2014.00 3888.00,1995.00 3888.00,1995.00
//              3888.00,1995.00 3889.00,1978.00 3889.00,1978.00
//              3889.00,1978.00 3890.00,1963.00 3890.00,1963.00
//              3890.00,1963.00 3892.00,1922.00 3892.00,1922.00
//              3892.00,1922.00 3893.00,1912.00 3893.00,1912.00
//              3893.00,1912.00 3893.00,1901.00 3893.00,1901.00
//              3893.00,1901.00 3894.00,1889.00 3894.00,1889.00 Z" data-front="56" data-rear="56" data-left="163" data-lot="9" data-street="Collective Lane" data-right="174" data-sqft="9,465" data-type="Single Family" data-unit_type="Sqft" data-block="5" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_123" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3979.00,1895.00
//            C 3979.00,1895.00 4031.00,1897.91 4031.00,1897.91
//              4031.00,1897.91 4071.00,1900.09 4071.00,1900.09
//              4071.00,1900.09 4096.00,1902.00 4096.00,1902.00
//              4096.00,1902.00 4096.00,1914.00 4096.00,1914.00
//              4096.00,1914.00 4095.00,1932.00 4095.00,1932.00
//              4095.00,1932.00 4094.00,1948.00 4094.00,1948.00
//              4094.00,1948.00 4093.00,1970.00 4093.00,1970.00
//              4093.00,1970.00 4092.04,1980.00 4092.04,1980.00
//              4092.04,1980.00 4092.04,1989.00 4092.04,1989.00
//              4092.04,1989.00 4091.00,2008.00 4091.00,2008.00
//              4091.00,2008.00 4090.04,2018.00 4090.04,2018.00
//              4090.04,2018.00 4090.04,2026.00 4090.04,2026.00
//              4090.04,2026.00 4089.00,2045.00 4089.00,2045.00
//              4089.00,2045.00 4088.00,2062.00 4088.00,2062.00
//              4088.00,2062.00 4087.00,2078.00 4087.00,2078.00
//              4087.00,2078.00 4085.00,2119.00 4085.00,2119.00
//              4085.00,2119.00 4084.00,2134.00 4084.00,2134.00
//              4084.00,2134.00 4083.00,2151.00 4083.00,2151.00
//              4083.00,2151.00 4082.00,2181.00 4082.00,2181.00
//              4082.00,2181.00 3994.00,2161.58 3994.00,2161.58
//              3994.00,2161.58 3968.00,2156.00 3968.00,2156.00
//              3968.00,2156.00 3970.00,2096.00 3970.00,2096.00
//              3970.00,2096.00 3971.00,2083.00 3971.00,2083.00
//              3971.00,2083.00 3972.00,2053.00 3972.00,2053.00
//              3972.00,2053.00 3973.00,2040.00 3973.00,2040.00
//              3973.00,2040.00 3974.00,2011.00 3974.00,2011.00
//              3974.00,2011.00 3975.00,1994.00 3975.00,1994.00
//              3975.00,1994.00 3976.00,1973.00 3976.00,1973.00
//              3976.00,1973.00 3977.00,1944.00 3977.00,1944.00
//              3977.00,1944.00 3978.00,1929.00 3978.00,1929.00
//              3978.00,1929.00 3979.00,1909.00 3979.00,1909.00
//              3979.00,1909.00 3979.00,1895.00 3979.00,1895.00 Z" data-front="80" data-rear="81" data-left="174" data-lot="10" data-street="Collective Lane" data-right="188" data-sqft="14,520" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_126" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3711.00,2089.00
//            C 3711.00,2089.00 3762.00,2106.31 3762.00,2106.31
//              3762.00,2106.31 3790.00,2116.00 3790.00,2116.00
//              3790.00,2116.00 3770.86,2160.00 3770.86,2160.00
//              3770.86,2160.00 3738.86,2233.00 3738.86,2233.00
//              3738.86,2233.00 3713.86,2290.00 3713.86,2290.00
//              3713.86,2290.00 3701.00,2319.00 3701.00,2319.00
//              3701.00,2319.00 3625.00,2288.00 3625.00,2288.00
//              3625.00,2288.00 3645.58,2240.00 3645.58,2240.00
//              3645.58,2240.00 3680.14,2160.00 3680.14,2160.00
//              3680.14,2160.00 3699.58,2115.00 3699.58,2115.00
//              3699.58,2115.00 3711.00,2089.00 3711.00,2089.00 Z" data-front="55" data-rear="55" data-left="149" data-lot="11" data-street="Collective Lane" data-right="149" data-sqft="8,110" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_128" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3632.00,2064.00
//      C 3632.00,2064.00 3674.00,2077.33 3674.00,2077.33
//        3674.00,2077.33 3711.00,2090.00 3711.00,2090.00
//        3711.00,2090.00 3692.85,2133.00 3692.85,2133.00
//        3692.85,2133.00 3647.72,2237.00 3647.72,2237.00
//        3647.72,2237.00 3626.00,2288.00 3626.00,2288.00
//        3626.00,2288.00 3595.00,2276.20 3595.00,2276.20
//        3595.00,2276.20 3551.00,2259.00 3551.00,2259.00
//        3551.00,2259.00 3570.58,2211.00 3570.58,2211.00
//        3570.58,2211.00 3613.01,2110.00 3613.01,2110.00
//        3613.01,2110.00 3632.00,2064.00 3632.00,2064.00 Z" data-front="55" data-rear="55" data-left="149" data-lot="12" data-street="Collective Lane" data-right="140" data-sqft="7,839" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_130" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3553.00,2039.00
//            C 3553.00,2039.00 3584.00,2048.98 3584.00,2048.98
//              3584.00,2048.98 3633.00,2065.00 3633.00,2065.00
//              3633.00,2065.00 3617.42,2103.00 3617.42,2103.00
//              3617.42,2103.00 3595.42,2156.00 3595.42,2156.00
//              3595.42,2156.00 3566.42,2226.00 3566.42,2226.00
//              3566.42,2226.00 3553.00,2258.00 3553.00,2258.00
//              3553.00,2258.00 3474.00,2229.00 3474.00,2229.00
//              3474.00,2229.00 3487.58,2196.00 3487.58,2196.00
//              3487.58,2196.00 3509.58,2143.00 3509.58,2143.00
//              3509.58,2143.00 3536.99,2077.00 3536.99,2077.00
//              3536.99,2077.00 3553.00,2039.00 3553.00,2039.00 Z" data-front="54" data-rear="54" data-left="140" data-lot="13" data-street="Collective Lane" data-right="135" data-sqft="7,488" data-type="Twin Home " data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_132" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3450.00,2006.00
//      C 3458.69,2006.78 3479.64,2014.56 3489.00,2017.67
//        3489.00,2017.67 3555.00,2039.00 3555.00,2039.00
//        3555.00,2039.00 3535.01,2088.00 3535.01,2088.00
//        3535.01,2088.00 3492.99,2188.00 3492.99,2188.00
//        3492.99,2188.00 3476.00,2229.00 3476.00,2229.00
//        3476.00,2229.00 3458.00,2222.68 3458.00,2222.68
//        3458.00,2222.68 3399.00,2200.00 3399.00,2200.00
//        3399.00,2200.00 3404.58,2177.00 3404.58,2177.00
//        3404.58,2177.00 3412.58,2147.00 3412.58,2147.00
//        3412.58,2147.00 3436.88,2056.00 3436.88,2056.00
//        3436.88,2056.00 3450.00,2006.00 3450.00,2006.00 Z" data-front="53" data-rear="72" data-left="135" data-lot="14" data-street="Collective Lane" data-right="133" data-sqft="8,397" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_135" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3195.00,1985.00
//            C 3195.00,1985.00 3205.00,1985.91 3205.00,1985.91
//              3205.00,1985.91 3229.00,1987.83 3229.00,1987.83
//              3229.00,1987.83 3277.00,1991.83 3277.00,1991.83
//              3277.00,1991.83 3333.00,1996.17 3333.00,1996.17
//              3333.00,1996.17 3449.00,2006.00 3449.00,2006.00
//              3449.00,2006.00 3435.37,2060.00 3435.37,2060.00
//              3435.37,2060.00 3429.28,2079.98 3429.28,2079.98
//              3429.28,2079.98 3413.00,2079.98 3413.00,2079.98
//              3413.00,2079.98 3391.00,2078.09 3391.00,2078.09
//              3391.00,2078.09 3364.00,2076.28 3364.00,2076.28
//              3364.00,2076.28 3341.00,2074.91 3341.00,2074.91
//              3341.00,2074.91 3271.00,2069.91 3271.00,2069.91
//              3271.00,2069.91 3214.00,2065.91 3214.00,2065.91
//              3214.00,2065.91 3190.00,2064.00 3190.00,2064.00
//              3190.00,2064.00 3190.91,2052.00 3190.91,2052.00
//              3190.91,2052.00 3193.09,2012.00 3193.09,2012.00
//              3193.09,2012.00 3195.00,1985.00 3195.00,1985.00 Z" data-front="52" data-rear="82" data-left="161" data-lot="15" data-street="Collective Lane" data-right="170" data-sqft="8,637" data-type="Twin Home" data-unit_type="Sqft" data-block="5" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_137" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3189.00,2065.00
//            C 3189.00,2065.00 3232.00,2067.91 3232.00,2067.91
//            3232.00,2067.91 3319.00,2074.09 3319.00,2074.09
//            3319.00,2074.09 3371.00,2078.01 3371.00,2078.01
//            3371.00,2078.01 3416.00,2081.42 3416.00,2081.42
//            3419.16,2081.84 3428.11,2081.36 3429.34,2084.43
//            3430.17,2086.51 3428.34,2091.79 3427.71,2094.00
//            3427.71,2094.00 3421.88,2115.00 3421.88,2115.00
//            3421.88,2115.00 3406.58,2171.00 3406.58,2171.00
//            3406.58,2171.00 3399.00,2199.00 3399.00,2199.00
//            3399.00,2199.00 3342.00,2189.27 3342.00,2189.27
//            3342.00,2189.27 3254.00,2178.83 3254.00,2178.83
//            3254.00,2178.83 3220.00,2176.00 3220.00,2176.00
//            3220.00,2176.00 3204.00,2175.00 3204.00,2175.00
//            3204.00,2175.00 3200.00,2175.00 3200.00,2175.00
//            3200.00,2175.00 3183.00,2174.00 3183.00,2174.00
//            3183.00,2174.00 3184.00,2159.00 3184.00,2159.00
//            3184.00,2159.00 3185.00,2142.00 3185.00,2142.00
//            3185.00,2142.00 3186.00,2127.00 3186.00,2127.00
//            3186.00,2127.00 3189.00,2065.00 3189.00,2065.00 Z" data-front="73" data-rear="80" data-left="147" data-lot="16" data-street="Collective Lane" data-right="161" data-sqft="11,493" data-type="Single family" data-unit_type="Sqft" data-block="5" data-grade="3" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_139" class="has-hover greenery" data-toggle="popover" data-trigger="hover focus" data-price="1" data-placement="left" d="M 4235.00,1533.00
//            C 4235.00,1533.00 4252.00,1534.00 4252.00,1534.00
//              4252.00,1534.00 4276.00,1535.00 4276.00,1535.00
//              4276.00,1535.00 4286.00,1535.96 4286.00,1535.96
//              4286.00,1535.96 4294.00,1535.96 4294.00,1535.96
//              4294.00,1535.96 4312.00,1537.00 4312.00,1537.00
//              4312.00,1537.00 4322.00,1537.96 4322.00,1537.96
//              4322.00,1537.96 4330.00,1537.96 4330.00,1537.96
//              4330.00,1537.96 4348.00,1539.00 4348.00,1539.00
//              4348.00,1539.00 4362.00,1540.00 4362.00,1540.00
//              4362.00,1540.00 4404.00,1542.00 4404.00,1542.00
//              4404.00,1542.00 4417.00,1543.00 4417.00,1543.00
//              4417.00,1543.00 4443.00,1544.00 4443.00,1544.00
//              4443.00,1544.00 4459.00,1545.00 4459.00,1545.00
//              4459.00,1545.00 4476.00,1546.00 4476.00,1546.00
//              4476.00,1546.00 4503.00,1547.00 4503.00,1547.00
//              4503.00,1547.00 4518.00,1548.00 4518.00,1548.00
//              4518.00,1548.00 4536.00,1549.00 4536.00,1549.00
//              4536.00,1549.00 4562.00,1550.00 4562.00,1550.00
//              4562.00,1550.00 4577.00,1551.00 4577.00,1551.00
//              4577.00,1551.00 4595.00,1552.00 4595.00,1552.00
//              4595.00,1552.00 4621.00,1553.00 4621.00,1553.00
//              4621.00,1553.00 4634.00,1554.00 4634.00,1554.00
//              4634.00,1554.00 4661.00,1555.00 4661.00,1555.00
//              4661.00,1555.00 4676.00,1556.00 4676.00,1556.00
//              4676.00,1556.00 4696.00,1557.00 4696.00,1557.00
//              4696.00,1557.00 4713.00,1558.00 4713.00,1558.00
//              4713.00,1558.00 4739.00,1559.00 4739.00,1559.00
//              4739.00,1559.00 4753.00,1560.00 4753.00,1560.00
//              4753.00,1560.00 4778.00,1561.00 4778.00,1561.00
//              4778.00,1561.00 4794.00,1562.00 4794.00,1562.00
//              4794.00,1562.00 4794.00,1622.00 4794.00,1622.00
//              4794.00,1622.00 4793.00,1639.00 4793.00,1639.00
//              4793.00,1639.00 4793.00,1758.00 4793.00,1758.00
//              4793.00,1758.00 4792.00,1773.00 4792.00,1773.00
//              4792.00,1773.00 4792.00,1892.00 4792.00,1892.00
//              4792.00,1892.00 4791.00,1907.00 4791.00,1907.00
//              4791.00,1907.00 4791.00,1965.00 4791.00,1965.00
//              4791.00,1965.00 4775.00,1964.00 4775.00,1964.00
//              4775.00,1964.00 4752.00,1963.00 4752.00,1963.00
//              4752.00,1963.00 4737.00,1962.00 4737.00,1962.00
//              4737.00,1962.00 4700.00,1960.00 4700.00,1960.00
//              4700.00,1960.00 4685.00,1959.00 4685.00,1959.00
//              4685.00,1959.00 4662.00,1958.00 4662.00,1958.00
//              4662.00,1958.00 4648.00,1957.00 4648.00,1957.00
//              4648.00,1957.00 4630.00,1956.00 4630.00,1956.00
//              4630.00,1956.00 4614.00,1955.00 4614.00,1955.00
//              4614.00,1955.00 4592.00,1954.00 4592.00,1954.00
//              4592.00,1954.00 4575.00,1953.00 4575.00,1953.00
//              4575.00,1953.00 4560.00,1952.00 4560.00,1952.00
//              4560.00,1952.00 4542.00,1951.00 4542.00,1951.00
//              4542.00,1951.00 4519.00,1950.00 4519.00,1950.00
//              4519.00,1950.00 4502.00,1949.00 4502.00,1949.00
//              4502.00,1949.00 4487.00,1948.00 4487.00,1948.00
//              4487.00,1948.00 4449.00,1946.00 4449.00,1946.00
//              4449.00,1946.00 4433.00,1945.00 4433.00,1945.00
//              4433.00,1945.00 4393.00,1943.00 4393.00,1943.00
//              4393.00,1943.00 4383.00,1942.04 4383.00,1942.04
//              4383.00,1942.04 4375.00,1942.04 4375.00,1942.04
//              4375.00,1942.04 4357.00,1941.00 4357.00,1941.00
//              4357.00,1941.00 4347.00,1940.04 4347.00,1940.04
//              4347.00,1940.04 4339.00,1940.04 4339.00,1940.04
//              4339.00,1940.04 4321.00,1939.00 4321.00,1939.00
//              4321.00,1939.00 4307.00,1938.00 4307.00,1938.00
//              4307.00,1938.00 4267.00,1936.00 4267.00,1936.00
//              4267.00,1936.00 4253.00,1935.00 4253.00,1935.00
//              4253.00,1935.00 4231.00,1934.00 4231.00,1934.00
//              4231.00,1934.00 4216.00,1933.00 4216.00,1933.00
//              4216.00,1933.00 4217.00,1916.00 4217.00,1916.00
//              4217.00,1916.00 4219.00,1876.00 4219.00,1876.00
//              4219.00,1876.00 4219.96,1866.00 4219.96,1866.00
//              4219.96,1866.00 4219.96,1858.00 4219.96,1858.00
//              4219.96,1858.00 4221.00,1840.00 4221.00,1840.00
//              4221.00,1840.00 4222.00,1822.00 4222.00,1822.00
//              4222.00,1822.00 4223.00,1807.00 4223.00,1807.00
//              4223.00,1807.00 4225.00,1770.00 4225.00,1770.00
//              4225.00,1770.00 4226.00,1755.00 4226.00,1755.00
//              4226.28,1730.38 4229.79,1704.59 4232.17,1680.00
//              4232.17,1680.00 4234.00,1659.00 4234.00,1659.00
//              4234.00,1659.00 4234.00,1605.00 4234.00,1605.00
//              4234.00,1605.00 4235.00,1588.00 4235.00,1588.00
//              4235.00,1588.00 4235.00,1533.00 4235.00,1533.00 Z" data-front="201" data-rear="502" data-left="282" data-lot="20" data-street="Collective Circle" data-right="371" data-sqft="100,832" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="3" data-original-title="1"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_142" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4239.00,1131.00
//            C 4239.00,1131.00 4326.00,1131.00 4326.00,1131.00
//              4326.00,1131.00 4341.00,1132.00 4341.00,1132.00
//              4341.00,1132.00 4516.00,1132.00 4516.00,1132.00
//              4516.00,1132.00 4516.00,1148.00 4516.00,1148.00
//              4516.00,1148.00 4515.00,1169.00 4515.00,1169.00
//              4515.00,1169.00 4514.00,1192.00 4514.00,1192.00
//              4514.00,1192.00 4513.04,1204.00 4513.04,1204.00
//              4513.04,1204.00 4513.04,1216.00 4513.04,1216.00
//              4513.04,1216.00 4512.00,1229.00 4512.00,1229.00
//              4512.00,1229.00 4510.09,1255.00 4510.09,1255.00
//              4509.78,1259.40 4509.85,1265.95 4508.07,1270.00
//              4506.91,1272.64 4501.64,1279.32 4499.63,1282.00
//              4497.45,1284.91 4493.82,1290.28 4490.91,1292.15
//              4488.18,1293.90 4474.34,1296.07 4471.00,1295.63
//              4468.30,1295.28 4463.80,1293.31 4461.00,1292.34
//              4461.00,1292.34 4410.00,1274.05 4410.00,1274.05
//              4410.00,1274.05 4371.00,1260.52 4371.00,1260.52
//              4366.18,1259.49 4350.50,1259.01 4345.00,1259.00
//              4345.00,1259.00 4333.00,1258.04 4333.00,1258.04
//              4333.00,1258.04 4321.00,1258.04 4321.00,1258.04
//              4321.00,1258.04 4307.00,1257.00 4307.00,1257.00
//              4307.00,1257.00 4255.00,1255.00 4255.00,1255.00
//              4255.00,1255.00 4236.00,1254.00 4236.00,1254.00
//              4236.00,1254.00 4236.00,1169.00 4236.00,1169.00
//              4236.00,1169.00 4239.00,1131.00 4239.00,1131.00 Z" data-front="79" data-rear="88" data-left="187" data-lot="1" data-street="Sonora Way" data-right="187" data-sqft="16,631" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_144" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4235.00,1061.00
//            C 4235.00,1061.00 4522.00,1061.00 4522.00,1061.00
//              4522.00,1061.00 4521.00,1080.00 4521.00,1080.00
//              4521.00,1080.00 4520.00,1094.00 4520.00,1094.00
//              4520.00,1094.00 4518.00,1133.00 4518.00,1133.00
//              4518.00,1133.00 4235.00,1133.00 4235.00,1133.00
//              4235.00,1133.00 4235.00,1061.00 4235.00,1061.00 Z" data-front="47" data-rear="47" data-left="187" data-lot="2" data-street="Sonora Way" data-right="190" data-sqft="8,931" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_146" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4235.00,1062.00
//            C 4235.00,1062.00 4235.00,1002.00 4235.00,1002.00
//              4235.02,993.36 4235.36,993.02 4244.00,993.00
//              4244.00,993.00 4422.00,993.00 4422.00,993.00
//              4422.00,993.00 4437.00,992.00 4437.00,992.00
//              4437.00,992.00 4526.00,992.00 4526.00,992.00
//              4526.00,992.00 4525.00,1011.00 4525.00,1011.00
//              4525.00,1011.00 4523.00,1051.00 4523.00,1051.00
//              4522.98,1059.64 4522.64,1059.98 4514.00,1060.00
//              4514.00,1060.00 4415.00,1060.00 4415.00,1060.00
//              4415.00,1060.00 4400.00,1061.00 4400.00,1061.00
//              4400.00,1061.00 4300.00,1061.00 4300.00,1061.00
//              4300.00,1061.00 4285.00,1062.00 4285.00,1062.00
//              4285.00,1062.00 4235.00,1062.00 4235.00,1062.00 Z" data-front="47" data-rear="47" data-left="190" data-lot="3" data-street="Sonora Way" data-right="193" data-sqft="9,075" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_148" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4235.00,917.00
//            C 4235.00,917.00 4272.00,917.00 4272.00,917.00
//              4272.00,917.00 4289.00,918.00 4289.00,918.00
//              4289.00,918.00 4353.00,918.00 4353.00,918.00
//              4353.00,918.00 4370.00,919.00 4370.00,919.00
//              4370.00,919.00 4439.00,919.00 4439.00,919.00
//              4439.00,919.00 4454.00,920.00 4454.00,920.00
//              4454.00,920.00 4529.00,920.00 4529.00,920.00
//              4529.00,920.00 4527.00,993.00 4527.00,993.00
//              4527.00,993.00 4235.00,993.00 4235.00,993.00
//              4235.00,993.00 4235.00,917.00 4235.00,917.00 Z" data-front="50" data-rear="45" data-left="193" data-lot="4" data-street="Sonora Way" data-right="196" data-sqft="9,294" data-type="Twin Home" data-unit_type="Sqft" data-block="1" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_150" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4244.00,834.00
//            C 4244.00,834.00 4280.00,837.84 4280.00,837.84
//              4280.00,837.84 4354.00,845.83 4354.00,845.83
//              4354.00,845.83 4457.00,856.83 4457.00,856.83
//              4457.00,856.83 4533.00,865.00 4533.00,865.00
//              4533.00,865.00 4532.00,877.00 4532.00,877.00
//              4532.00,877.00 4532.00,886.00 4532.00,886.00
//              4532.00,886.00 4530.00,921.00 4530.00,921.00
//              4530.00,921.00 4513.00,921.00 4513.00,921.00
//              4513.00,921.00 4497.00,920.00 4497.00,920.00
//              4497.00,920.00 4467.00,920.00 4467.00,920.00
//              4467.00,920.00 4450.00,919.00 4450.00,919.00
//              4450.00,919.00 4374.00,919.00 4374.00,919.00
//              4374.00,919.00 4359.00,918.00 4359.00,918.00
//              4359.00,918.00 4235.00,918.00 4235.00,918.00
//              4235.00,918.00 4236.83,901.00 4236.83,901.00
//              4236.83,901.00 4244.00,834.00 4244.00,834.00 Z" data-front="57" data-rear="38" data-left="196" data-lot="5" data-street="Sonora Way" data-right="195" data-sqft="9,447" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_152" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4252.00,760.00
//            C 4252.00,760.00 4322.00,767.72 4322.00,767.72
//              4322.00,767.72 4378.00,774.28 4378.00,774.28
//              4378.00,774.28 4469.00,785.16 4469.00,785.16
//              4469.00,785.16 4537.00,793.00 4537.00,793.00
//              4537.00,793.00 4535.00,829.00 4535.00,829.00
//              4535.00,829.00 4535.00,838.00 4535.00,838.00
//              4535.00,838.00 4533.00,864.00 4533.00,864.00
//              4533.00,864.00 4515.00,862.17 4515.00,862.17
//              4515.00,862.17 4463.00,857.17 4463.00,857.17
//              4463.00,857.17 4297.00,839.83 4297.00,839.83
//              4297.00,839.83 4244.00,834.00 4244.00,834.00
//              4244.00,834.00 4252.00,760.00 4252.00,760.00 Z" data-front="47" data-rear="47" data-left="195" data-lot="6" data-street="Sonora Way" data-right="192" data-sqft="9,179" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_154" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4260.00,690.00
//            C 4260.00,690.00 4330.00,697.84 4330.00,697.84
//              4330.00,697.84 4483.00,716.16 4483.00,716.16
//              4483.00,716.16 4541.00,723.00 4541.00,723.00
//              4541.00,723.00 4540.00,746.00 4540.00,746.00
//              4540.00,746.00 4538.00,793.00 4538.00,793.00
//              4538.00,793.00 4499.00,789.16 4499.00,789.16
//              4499.00,789.16 4437.00,782.16 4437.00,782.16
//              4437.00,782.16 4346.00,771.28 4346.00,771.28
//              4346.00,771.28 4252.00,760.00 4252.00,760.00
//              4252.00,760.00 4260.00,690.00 4260.00,690.00 Z" data-front="47" data-rear="47" data-left="192" data-lot="7" data-street="Sonora Way" data-right="189" data-sqft="9,043" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_156" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4268.00,618.00
//            C 4268.00,618.00 4283.00,619.85 4283.00,619.85
//              4283.00,619.85 4338.00,626.85 4338.00,626.85
//              4338.00,626.85 4475.00,644.28 4475.00,644.28
//              4475.00,644.28 4521.00,650.15 4521.00,650.15
//              4521.00,650.15 4546.00,653.00 4546.00,653.00
//              4546.00,653.00 4545.00,673.00 4545.00,673.00
//              4545.00,673.00 4544.00,690.00 4544.00,690.00
//              4544.00,690.00 4543.00,709.00 4543.00,709.00
//              4543.00,709.00 4543.00,723.00 4543.00,723.00
//              4543.00,723.00 4474.00,715.28 4474.00,715.28
//              4474.00,715.28 4410.00,707.72 4410.00,707.72
//              4410.00,707.72 4328.00,697.84 4328.00,697.84
//              4328.00,697.84 4260.00,690.00 4260.00,690.00
//              4260.00,690.00 4266.15,636.00 4266.15,636.00
//              4266.15,636.00 4268.00,618.00 4268.00,618.00 Z" data-front="47" data-rear="47" data-left="189" data-lot="8" data-street="Sonora Way" data-right="187" data-sqft="8,907" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_158" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4275.00,550.00
//            C 4275.00,550.00 4291.00,551.84 4291.00,551.84
//              4291.00,551.84 4350.00,558.72 4350.00,558.72
//              4350.00,558.72 4416.00,566.16 4416.00,566.16
//              4416.00,566.16 4486.00,574.17 4486.00,574.17
//              4486.00,574.17 4549.00,581.00 4549.00,581.00
//              4549.00,581.00 4549.00,595.00 4549.00,595.00
//              4549.00,595.00 4548.00,615.00 4548.00,615.00
//              4548.00,615.00 4547.00,632.00 4547.00,632.00
//              4547.00,632.00 4546.00,653.00 4546.00,653.00
//              4546.00,653.00 4488.00,646.16 4488.00,646.16
//              4488.00,646.16 4432.00,639.15 4432.00,639.15
//              4432.00,639.15 4340.00,627.72 4340.00,627.72
//              4340.00,627.72 4267.00,619.00 4267.00,619.00
//              4267.00,619.00 4275.00,550.00 4275.00,550.00 Z" data-front="47" data-rear="47" data-left="187" data-lot="9" data-street="Sonora Way" data-right="184" data-sqft="8,771" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_161" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="59,900" data-placement="left" d="M 4276.00,550.00
//            C 4276.00,550.00 4278.83,523.00 4278.83,523.00
//              4278.83,523.00 4283.16,480.00 4283.16,480.00
//              4283.16,480.00 4284.84,465.00 4284.84,465.00
//              4284.84,465.00 4286.60,459.02 4286.60,459.02
//              4286.60,459.02 4294.00,458.00 4294.00,458.00
//              4294.00,458.00 4395.00,458.00 4395.00,458.00
//              4395.00,458.00 4410.00,457.00 4410.00,457.00
//              4410.00,457.00 4484.00,457.00 4484.00,457.00
//              4484.00,457.00 4499.00,456.00 4499.00,456.00
//              4499.00,456.00 4558.00,456.00 4558.00,456.00
//              4558.00,456.00 4557.00,472.00 4557.00,472.00
//              4557.00,472.00 4556.00,495.00 4556.00,495.00
//              4556.00,495.00 4555.00,513.00 4555.00,513.00
//              4555.00,513.00 4554.00,529.00 4554.00,529.00
//              4554.00,529.00 4553.00,545.00 4553.00,545.00
//              4553.00,545.00 4552.00,567.00 4552.00,567.00
//              4552.00,567.00 4551.00,581.00 4551.00,581.00
//              4551.00,581.00 4510.00,577.17 4510.00,577.17
//              4510.00,577.17 4437.00,569.28 4437.00,569.28
//              4437.00,569.28 4276.00,550.00 4276.00,550.00 Z" data-front="61" data-rear="83" data-left="184" data-lot="10" data-street="Sonora Way" data-right="180" data-sqft="13,176" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_163" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4558.00,456.00
//      C 4558.00,456.00 4610.00,456.00 4610.00,456.00
//        4610.00,456.00 4627.00,455.00 4627.00,455.00
//        4627.00,455.00 4734.00,455.00 4734.00,455.00
//        4734.00,455.00 4749.00,454.00 4749.00,454.00
//        4749.00,454.00 4802.96,454.00 4802.96,454.00
//        4802.96,454.00 4802.96,469.00 4802.96,469.00
//        4802.96,469.00 4802.00,481.00 4802.00,481.00
//        4802.00,481.00 4802.00,572.00 4802.00,572.00
//        4802.00,572.00 4801.00,590.00 4801.00,590.00
//        4801.00,590.00 4799.98,597.44 4799.98,597.44
//        4799.98,597.44 4786.00,602.58 4786.00,602.58
//        4786.00,602.58 4744.00,614.42 4744.00,614.42
//        4744.00,614.42 4607.00,653.15 4607.00,653.15
//        4607.00,653.15 4569.00,664.00 4569.00,664.00
//        4569.00,664.00 4545.00,670.00 4545.00,670.00
//        4545.00,670.00 4545.96,652.00 4545.96,652.00
//        4545.96,652.00 4548.91,605.00 4548.91,605.00
//        4548.91,605.00 4550.09,580.00 4550.09,580.00
//        4550.09,580.00 4555.00,508.00 4555.00,508.00
//        4555.00,508.00 4555.00,499.00 4555.00,499.00
//        4555.00,499.00 4557.00,476.00 4557.00,476.00
//        4557.00,476.00 4557.00,467.00 4557.00,467.00
//        4557.00,467.00 4558.00,456.00 4558.00,456.00 Z" data-front="95" data-rear="143" data-left="163" data-lot="11" data-street="Collective Circle" data-right="177" data-sqft="19,827" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_165" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4801.00,597.00
//            C 4802.76,601.43 4802.00,610.90 4802.00,616.00
//              4802.00,616.00 4802.00,655.00 4802.00,655.00
//              4802.00,655.00 4801.00,670.00 4801.00,670.00
//              4801.00,670.00 4801.00,728.00 4801.00,728.00
//              4801.00,736.53 4801.92,760.23 4800.00,767.00
//              4800.00,767.00 4770.00,764.91 4770.00,764.91
//              4770.00,764.91 4716.00,761.09 4716.00,761.09
//              4716.00,761.09 4691.00,759.91 4691.00,759.91
//              4691.00,759.91 4622.00,755.09 4622.00,755.09
//              4622.00,755.09 4597.00,753.91 4597.00,753.91
//              4597.00,753.91 4551.00,750.96 4551.00,750.96
//              4551.00,750.96 4539.00,750.00 4539.00,750.00
//              4539.00,750.00 4540.17,736.00 4540.17,736.00
//              4540.17,736.00 4543.72,692.00 4543.72,692.00
//              4543.72,692.00 4546.02,671.56 4546.02,671.56
//              4546.02,671.56 4564.00,665.29 4564.00,665.29
//              4564.00,665.29 4614.00,650.86 4614.00,650.86
//              4614.00,650.86 4738.00,615.42 4738.00,615.42
//              4738.00,615.42 4801.00,597.00 4801.00,597.00 Z" data-front="112" data-rear="53" data-left="177" data-lot="12" data-street="Collective Circle" data-right="173" data-sqft="14,163" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_167" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4539.00,750.00
//            C 4539.00,750.00 4551.00,750.91 4551.00,750.91
//              4551.00,750.91 4581.00,753.00 4581.00,753.00
//              4581.00,753.00 4590.00,753.00 4590.00,753.00
//              4590.00,753.00 4611.00,755.00 4611.00,755.00
//              4611.00,755.00 4620.00,755.00 4620.00,755.00
//              4620.00,755.00 4680.00,759.09 4680.00,759.09
//              4680.00,759.09 4726.00,762.09 4726.00,762.09
//              4726.00,762.09 4738.00,763.01 4738.00,763.01
//              4738.00,763.01 4747.00,763.91 4747.00,763.91
//              4747.00,763.91 4786.00,766.09 4786.00,766.09
//              4786.00,766.09 4793.00,766.91 4793.00,766.91
//              4800.22,767.46 4800.95,767.10 4801.00,775.00
//              4801.00,775.00 4801.00,792.00 4801.00,792.00
//              4801.00,792.00 4800.00,805.00 4800.00,805.00
//              4800.00,805.00 4800.00,885.00 4800.00,885.00
//              4800.00,885.00 4745.00,883.00 4745.00,883.00
//              4745.00,883.00 4729.00,882.00 4729.00,882.00
//              4729.00,882.00 4709.00,880.96 4709.00,880.96
//              4709.00,880.96 4696.00,880.96 4696.00,880.96
//              4696.00,880.96 4685.00,880.00 4685.00,880.00
//              4685.00,880.00 4662.00,879.00 4662.00,879.00
//              4662.00,879.00 4640.00,878.00 4640.00,878.00
//              4640.00,878.00 4619.00,877.00 4619.00,877.00
//              4619.00,877.00 4590.00,876.00 4590.00,876.00
//              4590.00,876.00 4574.00,875.00 4574.00,875.00
//              4574.00,875.00 4553.00,874.00 4553.00,874.00
//              4553.00,874.00 4535.00,873.00 4535.00,873.00
//              4535.00,873.00 4536.00,825.00 4536.00,825.00
//              4536.00,825.00 4537.00,808.00 4537.00,808.00
//              4537.00,808.00 4537.00,793.00 4537.00,793.00
//              4537.00,793.00 4538.00,777.00 4538.00,777.00
//              4538.00,777.00 4539.00,750.00 4539.00,750.00 Z" data-front="80" data-rear="80" data-left="173" data-lot="13" data-street="Collective Circle" data-right="177" data-sqft="14,050" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_168" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4533.00,872.00
//            C 4533.00,872.00 4574.00,874.00 4574.00,874.00
//              4574.00,874.00 4589.00,875.00 4589.00,875.00
//              4589.00,875.00 4607.00,876.00 4607.00,876.00
//              4607.00,876.00 4629.00,877.00 4629.00,877.00
//              4629.00,877.00 4646.00,878.00 4646.00,878.00
//              4646.00,878.00 4671.00,879.00 4671.00,879.00
//              4671.00,879.00 4681.00,879.96 4681.00,879.96
//              4681.00,879.96 4690.00,879.96 4690.00,879.96
//              4690.00,879.96 4709.00,881.00 4709.00,881.00
//              4709.00,881.00 4719.00,882.00 4719.00,882.00
//              4719.00,882.00 4730.00,882.00 4730.00,882.00
//              4730.00,882.00 4745.00,883.00 4745.00,883.00
//              4745.00,883.00 4761.00,884.00 4761.00,884.00
//              4761.00,884.00 4785.00,885.00 4785.00,885.00
//              4785.00,885.00 4800.00,886.00 4800.00,886.00
//              4800.00,886.00 4800.00,923.00 4800.00,923.00
//              4800.00,923.00 4799.00,940.00 4799.00,940.00
//              4799.00,940.00 4799.00,1006.00 4799.00,1006.00
//              4799.00,1006.00 4781.00,1005.00 4781.00,1005.00
//              4781.00,1005.00 4753.00,1004.00 4753.00,1004.00
//              4753.00,1004.00 4736.00,1003.00 4736.00,1003.00
//              4736.00,1003.00 4716.00,1001.96 4716.00,1001.96
//              4716.00,1001.96 4705.00,1001.96 4705.00,1001.96
//              4705.00,1001.96 4694.00,1001.00 4694.00,1001.00
//              4694.00,1001.00 4672.00,1000.00 4672.00,1000.00
//              4672.00,1000.00 4650.00,999.00 4650.00,999.00
//              4650.00,999.00 4628.00,998.00 4628.00,998.00
//              4628.00,998.00 4581.00,996.00 4581.00,996.00
//              4581.00,996.00 4564.00,995.00 4564.00,995.00
//              4564.00,995.00 4544.00,994.00 4544.00,994.00
//              4544.00,994.00 4526.00,993.00 4526.00,993.00
//              4526.00,993.00 4527.00,973.00 4527.00,973.00
//              4527.00,973.00 4528.00,958.00 4528.00,958.00
//              4528.00,958.00 4530.00,917.00 4530.00,917.00
//              4530.00,917.00 4531.00,904.00 4531.00,904.00
//              4531.00,904.00 4533.00,872.00 4533.00,872.00 Z" data-front="80" data-rear="80" data-left="177" data-lot="14" data-street="Collective Circle" data-right="181" data-sqft="14,382" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_169" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4526.00,994.00
//            C 4526.00,994.00 4571.00,996.00 4571.00,996.00
//              4571.00,996.00 4587.00,997.00 4587.00,997.00
//              4587.00,997.00 4608.00,998.00 4608.00,998.00
//              4608.00,998.00 4627.00,999.00 4627.00,999.00
//              4627.00,999.00 4655.00,1000.00 4655.00,1000.00
//              4655.00,1000.00 4668.00,1001.00 4668.00,1001.00
//              4668.00,1001.00 4696.00,1002.00 4696.00,1002.00
//              4696.00,1002.00 4709.00,1003.00 4709.00,1003.00
//              4709.00,1003.00 4735.00,1004.00 4735.00,1004.00
//              4735.00,1004.00 4751.00,1005.00 4751.00,1005.00
//              4751.00,1005.00 4800.00,1007.00 4800.00,1007.00
//              4800.00,1007.00 4800.00,1029.00 4800.00,1029.00
//              4800.00,1029.00 4799.00,1045.00 4799.00,1045.00
//              4799.00,1045.00 4799.00,1086.00 4799.00,1086.00
//              4799.00,1086.00 4798.00,1101.00 4798.00,1101.00
//              4798.00,1101.00 4798.00,1123.00 4798.00,1123.00
//              4798.00,1123.00 4778.00,1122.00 4778.00,1122.00
//              4778.00,1122.00 4766.00,1121.04 4766.00,1121.04
//              4766.00,1121.04 4753.00,1121.04 4753.00,1121.04
//              4753.00,1121.04 4741.00,1120.04 4741.00,1120.04
//              4741.00,1120.04 4727.00,1120.04 4727.00,1120.04
//              4727.00,1120.04 4711.00,1118.96 4711.00,1118.96
//              4711.00,1118.96 4699.00,1118.96 4699.00,1118.96
//              4699.00,1118.96 4687.00,1118.00 4687.00,1118.00
//              4687.00,1118.00 4653.00,1117.00 4653.00,1117.00
//              4653.00,1117.00 4637.00,1116.00 4637.00,1116.00
//              4637.00,1116.00 4580.00,1114.00 4580.00,1114.00
//              4580.00,1114.00 4565.00,1112.96 4565.00,1112.96
//              4565.00,1112.96 4551.00,1112.96 4551.00,1112.96
//              4551.00,1112.96 4540.00,1112.00 4540.00,1112.00
//              4540.00,1112.00 4519.00,1111.00 4519.00,1111.00
//              4519.00,1111.00 4521.00,1064.00 4521.00,1064.00
//              4521.00,1064.00 4522.83,1041.00 4522.83,1041.00
//              4522.83,1041.00 4524.09,1018.00 4524.09,1018.00
//              4524.09,1018.00 4526.00,994.00 4526.00,994.00 Z" data-front="80" data-rear="80.41" data-left="181" data-lot="15" data-street="Collective Lane" data-right="186" data-sqft="14,792" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_170" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4519.00,1111.00
//      C 4519.00,1111.00 4539.00,1112.00 4539.00,1112.00
//        4539.00,1112.00 4562.00,1113.00 4562.00,1113.00
//        4562.00,1113.00 4574.00,1113.96 4574.00,1113.96
//        4574.00,1113.96 4587.00,1113.96 4587.00,1113.96
//        4587.00,1113.96 4603.00,1115.00 4603.00,1115.00
//        4603.00,1115.00 4625.00,1116.04 4625.00,1116.04
//        4625.00,1116.04 4637.00,1116.04 4637.00,1116.04
//        4637.00,1116.04 4649.00,1117.00 4649.00,1117.00
//        4649.00,1117.00 4680.00,1118.00 4680.00,1118.00
//        4680.00,1118.00 4695.00,1119.00 4695.00,1119.00
//        4695.00,1119.00 4724.00,1120.00 4724.00,1120.00
//        4724.00,1120.00 4741.00,1121.00 4741.00,1121.00
//        4741.00,1121.00 4797.00,1123.00 4797.00,1123.00
//        4797.00,1123.00 4797.00,1208.00 4797.00,1208.00
//        4797.00,1216.63 4797.90,1242.32 4796.00,1249.00
//        4796.00,1249.00 4751.00,1245.91 4751.00,1245.91
//        4751.00,1245.91 4675.00,1240.91 4675.00,1240.91
//        4675.00,1240.91 4568.00,1233.91 4568.00,1233.91
//        4568.00,1233.91 4522.00,1230.91 4522.00,1230.91
//        4522.00,1230.91 4511.00,1230.00 4511.00,1230.00
//        4511.00,1230.00 4513.09,1198.00 4513.09,1198.00
//        4513.09,1198.00 4516.91,1142.00 4516.91,1142.00
//        4516.91,1142.00 4519.00,1111.00 4519.00,1111.00 Z" data-front="80" data-rear="80" data-left="186" data-lot="16" data-street="Collective Circle" data-right="190" data-sqft="15,049" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_171" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4512.00,1230.00
//      C 4512.00,1230.00 4541.00,1232.00 4541.00,1232.00
//        4541.00,1232.00 4550.00,1232.00 4550.00,1232.00
//        4550.00,1232.00 4589.00,1234.91 4589.00,1234.91
//        4589.00,1234.91 4700.00,1241.91 4700.00,1241.91
//        4700.00,1241.91 4725.00,1243.09 4725.00,1243.09
//        4725.00,1243.09 4749.00,1245.00 4749.00,1245.00
//        4749.00,1245.00 4772.00,1246.09 4772.00,1246.09
//        4772.00,1246.09 4798.00,1248.00 4798.00,1248.00
//        4798.00,1248.00 4798.00,1287.00 4798.00,1287.00
//        4798.00,1287.00 4797.00,1304.00 4797.00,1304.00
//        4797.00,1304.00 4797.00,1384.00 4797.00,1384.00
//        4797.00,1384.00 4796.00,1399.00 4796.00,1399.00
//        4796.00,1399.00 4796.00,1478.00 4796.00,1478.00
//        4796.00,1478.00 4795.00,1493.00 4795.00,1493.00
//        4795.00,1493.00 4795.00,1561.00 4795.00,1561.00
//        4788.26,1557.81 4778.75,1546.63 4773.00,1541.01
//        4773.00,1541.01 4755.00,1524.00 4755.00,1524.00
//        4755.00,1524.00 4729.00,1498.28 4729.00,1498.28
//        4729.00,1498.28 4693.00,1463.04 4693.00,1463.04
//        4693.00,1463.04 4683.00,1453.99 4683.00,1453.99
//        4683.00,1453.99 4653.00,1424.04 4653.00,1424.04
//        4653.00,1424.04 4643.00,1414.99 4643.00,1414.99
//        4643.00,1414.99 4614.00,1386.04 4614.00,1386.04
//        4614.00,1386.04 4604.00,1376.99 4604.00,1376.99
//        4604.00,1376.99 4575.00,1348.04 4575.00,1348.04
//        4575.00,1348.04 4565.00,1338.99 4565.00,1338.99
//        4565.00,1338.99 4535.00,1309.04 4535.00,1309.04
//        4535.00,1309.04 4525.00,1299.99 4525.00,1299.99
//        4521.44,1296.51 4507.77,1283.77 4506.69,1280.00
//        4505.68,1276.48 4509.26,1269.83 4510.24,1266.00
//        4511.43,1261.35 4510.94,1254.02 4511.00,1249.00
//        4511.00,1249.00 4512.00,1230.00 4512.00,1230.00 Z" data-front="38" data-rear="260" data-left="190" data-lot="17" data-street="Collective Circle" data-right="266" data-sqft="23,682" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_172" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4415.00,1277.00
//      C 4415.00,1277.00 4455.00,1289.08 4455.00,1289.08
//        4466.18,1292.92 4469.56,1297.25 4482.00,1294.63
//        4497.21,1291.43 4496.69,1286.98 4506.00,1280.00
//        4506.00,1280.00 4565.00,1337.04 4565.00,1337.04
//        4565.00,1337.04 4590.00,1361.96 4590.00,1361.96
//        4590.00,1361.96 4600.00,1371.01 4600.00,1371.01
//        4600.00,1371.01 4657.00,1426.96 4657.00,1426.96
//        4657.00,1426.96 4666.00,1435.04 4666.00,1435.04
//        4666.00,1435.04 4691.00,1459.91 4691.00,1459.91
//        4691.00,1459.91 4757.00,1523.96 4757.00,1523.96
//        4766.64,1533.18 4786.84,1551.00 4794.00,1561.00
//        4794.00,1561.00 4764.00,1560.00 4764.00,1560.00
//        4764.00,1560.00 4749.00,1559.00 4749.00,1559.00
//        4749.00,1559.00 4728.00,1558.00 4728.00,1558.00
//        4728.00,1558.00 4710.00,1557.00 4710.00,1557.00
//        4710.00,1557.00 4682.00,1556.00 4682.00,1556.00
//        4682.00,1556.00 4667.00,1555.00 4667.00,1555.00
//        4667.00,1555.00 4649.00,1554.00 4649.00,1554.00
//        4649.00,1554.00 4621.00,1553.00 4621.00,1553.00
//        4621.00,1553.00 4608.00,1552.00 4608.00,1552.00
//        4608.00,1552.00 4580.00,1551.00 4580.00,1551.00
//        4580.00,1551.00 4567.00,1550.00 4567.00,1550.00
//        4567.00,1550.00 4539.00,1549.00 4539.00,1549.00
//        4539.00,1549.00 4521.00,1548.00 4521.00,1548.00
//        4521.00,1548.00 4504.00,1547.00 4504.00,1547.00
//        4504.00,1547.00 4485.00,1546.00 4485.00,1546.00
//        4485.00,1546.00 4457.00,1545.00 4457.00,1545.00
//        4457.00,1545.00 4443.00,1544.00 4443.00,1544.00
//        4443.00,1544.00 4417.00,1543.00 4417.00,1543.00
//        4417.00,1543.00 4400.00,1542.00 4400.00,1542.00
//        4400.00,1542.00 4401.00,1525.00 4401.00,1525.00
//        4401.00,1525.00 4403.00,1485.00 4403.00,1485.00
//        4403.00,1485.00 4403.96,1475.00 4403.96,1475.00
//        4403.96,1475.00 4403.96,1466.00 4403.96,1466.00
//        4403.96,1466.00 4405.00,1448.00 4405.00,1448.00
//        4405.00,1448.00 4406.00,1430.00 4406.00,1430.00
//        4406.00,1430.00 4407.00,1413.00 4407.00,1413.00
//        4407.00,1413.00 4408.00,1398.00 4408.00,1398.00
//        4408.00,1398.00 4410.00,1359.00 4410.00,1359.00
//        4410.00,1359.00 4411.00,1344.00 4411.00,1344.00
//        4411.00,1344.00 4413.00,1305.00 4413.00,1305.00
//        4413.00,1305.00 4415.00,1277.00 4415.00,1277.00 Z" data-front="77" data-rear="170" data-left="266" data-lot="18" data-street="Collective Circle" data-right="170" data-sqft="24,055" data-type="Single family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_173" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="129,900" data-placement="left" d="M 4236.00,1255.00
//      C 4236.00,1255.00 4250.00,1255.00 4250.00,1255.00
//        4250.00,1255.00 4266.00,1256.00 4266.00,1256.00
//        4266.00,1256.00 4328.00,1256.00 4328.00,1256.00
//        4342.79,1256.02 4370.72,1260.80 4385.00,1265.04
//        4385.00,1265.04 4415.00,1275.00 4415.00,1275.00
//        4413.93,1283.13 4414.09,1281.92 4414.00,1290.00
//        4414.00,1290.00 4413.00,1304.00 4413.00,1304.00
//        4413.00,1304.00 4412.00,1324.00 4412.00,1324.00
//        4412.00,1324.00 4411.00,1338.00 4411.00,1338.00
//        4411.00,1338.00 4410.00,1356.00 4410.00,1356.00
//        4410.00,1356.00 4408.00,1394.00 4408.00,1394.00
//        4408.00,1394.00 4407.00,1404.00 4407.00,1404.00
//        4407.00,1404.00 4407.00,1414.00 4407.00,1414.00
//        4407.00,1414.00 4406.00,1428.00 4406.00,1428.00
//        4406.00,1428.00 4405.00,1445.00 4405.00,1445.00
//        4405.00,1445.00 4404.00,1460.00 4404.00,1460.00
//        4404.00,1460.00 4402.00,1498.00 4402.00,1498.00
//        4402.00,1498.00 4401.00,1508.00 4401.00,1508.00
//        4401.00,1508.00 4401.00,1518.00 4401.00,1518.00
//        4401.00,1518.00 4400.00,1531.00 4400.00,1531.00
//        4400.00,1533.50 4400.38,1538.30 4398.26,1540.01
//        4396.95,1541.07 4394.59,1540.97 4393.00,1541.00
//        4393.00,1541.00 4375.00,1540.00 4375.00,1540.00
//        4375.00,1540.00 4371.00,1540.00 4371.00,1540.00
//        4371.00,1540.00 4358.00,1539.00 4358.00,1539.00
//        4358.00,1539.00 4317.00,1537.00 4317.00,1537.00
//        4317.00,1537.00 4302.00,1536.00 4302.00,1536.00
//        4302.00,1536.00 4278.00,1535.00 4278.00,1535.00
//        4278.00,1535.00 4263.00,1534.00 4263.00,1534.00
//        4263.00,1534.00 4237.00,1533.00 4237.00,1533.00
//        4237.00,1533.00 4237.00,1475.00 4237.00,1475.00
//        4237.00,1475.00 4236.00,1458.00 4236.00,1458.00
//        4236.00,1458.00 4236.00,1255.00 4236.00,1255.00 Z" data-front="146" data-rear="132" data-left="170" data-lot="19" data-street="Collective Circle" data-right="185" data-sqft="25,313" data-type="Single Family" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_174" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="54,900" data-placement="left" d="M 4218.00,1933.00
//      C 4218.00,1933.00 4246.00,1935.00 4246.00,1935.00
//        4246.00,1935.00 4283.00,1937.00 4283.00,1937.00
//        4283.00,1937.00 4301.00,1938.00 4301.00,1938.00
//        4301.00,1938.00 4332.00,1940.00 4332.00,1940.00
//        4332.00,1940.00 4345.00,1941.00 4345.00,1941.00
//        4345.00,1941.00 4354.00,1941.00 4354.00,1941.00
//        4354.00,1941.00 4364.00,1942.00 4364.00,1942.00
//        4364.00,1942.00 4375.43,1943.17 4375.43,1943.17
//        4375.43,1943.17 4377.00,1950.00 4377.00,1950.00
//        4377.00,1950.00 4376.00,1969.00 4376.00,1969.00
//        4376.00,1969.00 4375.00,1986.00 4375.00,1986.00
//        4375.00,1986.00 4374.00,2000.00 4374.00,2000.00
//        4374.00,2000.00 4372.00,2039.00 4372.00,2039.00
//        4372.00,2039.00 4371.00,2052.00 4371.00,2052.00
//        4371.00,2052.00 4369.00,2083.00 4369.00,2083.00
//        4369.00,2083.00 4310.00,2078.91 4310.00,2078.91
//        4310.00,2078.91 4234.00,2073.91 4234.00,2073.91
//        4234.00,2073.91 4209.00,2072.00 4209.00,2072.00
//        4209.00,2072.00 4210.91,2039.00 4210.91,2039.00
//        4210.91,2039.00 4212.09,2014.00 4212.09,2014.00
//        4212.09,2014.00 4213.96,1990.00 4213.96,1990.00
//        4213.96,1990.00 4215.00,1968.00 4215.00,1968.00
//        4215.00,1968.00 4215.96,1957.00 4215.96,1957.00
//        4215.96,1957.00 4218.00,1933.00 4218.00,1933.00 Z" data-front="94" data-rear="94" data-left="187" data-lot="21" data-street="Sonora Way" data-right="187" data-sqft="17,644" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_175" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="54,900" data-placement="left" d="M 4205.00,2122.00
//         C 4205.00,2122.00 4207.91,2086.00 4207.91,2086.00
//         4208.40,2078.84 4206.05,2073.33 4214.02,2073.02
//         4214.02,2073.02 4229.00,2074.00 4229.00,2074.00
//         4229.00,2074.00 4259.00,2076.00 4259.00,2076.00
//         4259.00,2076.00 4268.00,2076.00 4268.00,2076.00
//         4268.00,2076.00 4307.00,2078.91 4307.00,2078.91
//         4307.00,2078.91 4369.00,2083.00 4369.00,2083.00
//         4369.00,2083.00 4368.00,2099.00 4368.00,2099.00
//         4368.00,2099.00 4367.00,2116.00 4367.00,2116.00
//         4367.00,2116.00 4366.00,2133.00 4366.00,2133.00
//         4366.00,2133.00 4334.00,2131.09 4334.00,2131.09
//         4334.00,2131.09 4259.00,2126.09 4259.00,2126.09
//         4259.00,2126.09 4236.00,2124.91 4236.00,2124.91
//         4236.00,2124.91 4228.00,2124.04 4228.00,2124.04
//         4228.00,2124.04 4205.00,2122.00 4205.00,2122.00 Z" data-front="41" data-rear="41" data-left="187" data-lot="22" data-street="Sonora Way" data-right="187" data-sqft="7,696" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_176" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="54,900" data-placement="left" d="M 4205.00,2123.00
//          C 4205.00,2123.00 4250.00,2125.91 4250.00,2125.91
//          4250.00,2125.91 4328.00,2131.09 4328.00,2131.09
//          4328.00,2131.09 4366.00,2134.00 4366.00,2134.00
//          4366.00,2134.00 4364.00,2171.00 4364.00,2171.00
//          4364.00,2171.00 4363.00,2187.00 4363.00,2187.00
//          4363.00,2187.00 4362.00,2207.00 4362.00,2207.00
//          4362.00,2207.00 4346.00,2205.91 4346.00,2205.91
//          4346.00,2205.91 4323.00,2204.00 4323.00,2204.00
//          4323.00,2204.00 4314.00,2204.00 4314.00,2204.00
//          4314.00,2204.00 4275.00,2201.00 4275.00,2201.00
//          4275.00,2201.00 4266.00,2201.00 4266.00,2201.00
//          4266.00,2201.00 4242.00,2199.04 4242.00,2199.04
//          4242.00,2199.04 4212.00,2197.17 4212.00,2197.17
//          4212.00,2197.17 4202.06,2195.40 4202.06,2195.40
//          4202.06,2195.40 4202.06,2181.00 4202.06,2181.00
//          4202.06,2181.00 4203.00,2167.00 4203.00,2167.00
//          4203.00,2167.00 4205.00,2123.00 4205.00,2123.00 Z" data-front="41" data-rear="41" data-left="187" data-lot="23" data-street="Sonora Way" data-right="187" data-sqft="7,696" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_177" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="54,900" data-placement="left" d="M 4202.00,2197.00
//          C 4202.00,2197.00 4227.00,2199.00 4227.00,2199.00
//          4227.00,2199.00 4241.00,2199.91 4241.00,2199.91
//          4241.00,2199.91 4274.00,2201.91 4274.00,2201.91
//          4274.00,2201.91 4321.00,2204.96 4321.00,2204.96
//          4321.00,2204.96 4331.00,2204.96 4331.00,2204.96
//          4331.00,2204.96 4352.00,2206.83 4352.00,2206.83
//          4352.00,2206.83 4361.94,2208.60 4361.94,2208.60
//          4361.94,2208.60 4361.94,2224.00 4361.94,2224.00
//          4361.94,2224.00 4361.00,2239.00 4361.00,2239.00
//          4361.00,2239.00 4360.00,2256.00 4360.00,2256.00
//          4360.00,2256.00 4359.00,2283.00 4359.00,2283.00
//          4359.00,2283.00 4325.00,2281.09 4325.00,2281.09
//          4325.00,2281.09 4284.00,2278.91 4284.00,2278.91
//          4284.00,2278.91 4254.00,2277.00 4254.00,2277.00
//          4254.00,2277.00 4228.00,2275.09 4228.00,2275.09
//          4228.00,2275.09 4197.00,2273.00 4197.00,2273.00
//          4197.00,2273.00 4198.00,2257.00 4198.00,2257.00
//          4198.00,2257.00 4199.00,2240.00 4199.00,2240.00
//          4199.00,2240.00 4200.00,2220.00 4200.00,2220.00
//          4200.00,2220.00 4202.00,2197.00 4202.00,2197.00 Z" data-front="51" data-rear="51" data-left="187" data-lot="24" data-street="Sonora Way" data-right="187" data-sqft="9,573" data-type="Twin Home" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_178" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 4362.00,2207.00
//          C 4362.00,2207.00 4389.00,2208.00 4389.00,2208.00
//          4389.00,2208.00 4404.00,2209.00 4404.00,2209.00
//          4404.00,2209.00 4421.00,2210.00 4421.00,2210.00
//          4421.00,2210.00 4439.00,2211.00 4439.00,2211.00
//          4439.00,2211.00 4478.00,2213.00 4478.00,2213.00
//          4478.00,2213.00 4488.00,2213.96 4488.00,2213.96
//          4488.00,2213.96 4497.00,2213.96 4497.00,2213.96
//          4497.00,2213.96 4514.00,2215.00 4514.00,2215.00
//          4514.00,2215.00 4531.00,2216.00 4531.00,2216.00
//          4531.00,2216.00 4546.00,2217.00 4546.00,2217.00
//          4546.00,2217.00 4586.00,2219.00 4586.00,2219.00
//          4586.00,2219.00 4617.00,2221.00 4617.00,2221.00
//          4617.00,2221.00 4639.00,2222.00 4639.00,2222.00
//          4639.00,2222.00 4653.00,2223.00 4653.00,2223.00
//          4653.00,2223.00 4675.00,2224.00 4675.00,2224.00
//          4675.00,2224.00 4692.00,2225.00 4692.00,2225.00
//          4692.00,2225.00 4706.00,2226.00 4706.00,2226.00
//          4706.00,2226.00 4746.00,2228.00 4746.00,2228.00
//          4746.00,2228.00 4759.00,2229.00 4759.00,2229.00
//          4759.00,2229.00 4789.00,2231.00 4789.00,2231.00
//          4789.00,2231.00 4789.00,2308.00 4789.00,2308.00
//          4789.00,2308.00 4769.00,2307.00 4769.00,2307.00
//          4769.00,2307.00 4752.00,2306.00 4752.00,2306.00
//          4752.00,2306.00 4738.00,2305.00 4738.00,2305.00
//          4738.00,2305.00 4699.00,2303.00 4699.00,2303.00
//          4699.00,2303.00 4685.00,2302.00 4685.00,2302.00
//          4685.00,2302.00 4648.00,2300.00 4648.00,2300.00
//          4648.00,2300.00 4634.00,2299.00 4634.00,2299.00
//          4634.00,2299.00 4595.00,2297.00 4595.00,2297.00
//          4595.00,2297.00 4585.00,2296.04 4585.00,2296.04
//          4585.00,2296.04 4577.00,2296.04 4577.00,2296.04
//          4577.00,2296.04 4560.00,2295.00 4560.00,2295.00
//          4560.00,2295.00 4543.00,2294.00 4543.00,2294.00
//          4543.00,2294.00 4528.00,2293.00 4528.00,2293.00
//          4528.00,2293.00 4490.00,2291.00 4490.00,2291.00
//          4490.00,2291.00 4472.00,2290.00 4472.00,2290.00
//          4472.00,2290.00 4455.00,2289.00 4455.00,2289.00
//          4455.00,2289.00 4441.00,2288.00 4441.00,2288.00
//          4441.00,2288.00 4403.00,2286.00 4403.00,2286.00
//          4403.00,2286.00 4390.00,2285.00 4390.00,2285.00
//          4390.00,2285.00 4359.00,2283.00 4359.00,2283.00
//          4359.00,2283.00 4360.00,2249.00 4360.00,2249.00
//          4360.00,2249.00 4361.00,2226.00 4361.00,2226.00
//          4361.00,2226.00 4362.00,2207.00 4362.00,2207.00 Z" data-front="51" data-rear="51" data-left="204" data-lot="25" data-street="Sonora Way" data-right="206" data-sqft="10,484" data-type="Townhome" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_179" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 4362.00,2206.00
//        C 4362.00,2206.00 4363.91,2179.00 4363.91,2179.00
//          4363.91,2179.00 4365.19,2163.00 4365.19,2163.00
//          4365.19,2163.00 4365.19,2152.00 4365.19,2152.00
//          4367.11,2139.20 4363.40,2135.17 4373.00,2135.00
//          4373.00,2135.00 4377.00,2135.00 4377.00,2135.00
//          4377.00,2135.00 4388.00,2135.96 4388.00,2135.96
//          4388.00,2135.96 4398.00,2135.96 4398.00,2135.96
//          4398.00,2135.96 4413.00,2137.00 4413.00,2137.00
//          4413.00,2137.00 4457.00,2139.00 4457.00,2139.00
//          4457.00,2139.00 4474.00,2140.00 4474.00,2140.00
//          4474.00,2140.00 4489.00,2141.00 4489.00,2141.00
//          4489.00,2141.00 4517.00,2142.00 4517.00,2142.00
//          4517.00,2142.00 4532.00,2143.00 4532.00,2143.00
//          4532.00,2143.00 4550.00,2144.00 4550.00,2144.00
//          4550.00,2144.00 4577.00,2145.00 4577.00,2145.00
//          4577.00,2145.00 4590.00,2146.00 4590.00,2146.00
//          4590.00,2146.00 4616.00,2147.00 4616.00,2147.00
//          4616.00,2147.00 4629.00,2148.00 4629.00,2148.00
//          4629.00,2148.00 4655.00,2149.00 4655.00,2149.00
//          4655.00,2149.00 4671.00,2150.00 4671.00,2150.00
//          4671.00,2150.00 4689.00,2151.00 4689.00,2151.00
//          4689.00,2151.00 4716.00,2152.00 4716.00,2152.00
//          4716.00,2152.00 4726.00,2152.96 4726.00,2152.96
//          4726.00,2152.96 4734.00,2152.96 4734.00,2152.96
//          4734.00,2152.96 4754.00,2154.00 4754.00,2154.00
//          4754.00,2154.00 4765.00,2154.96 4765.00,2154.96
//          4765.00,2154.96 4775.00,2154.96 4775.00,2154.96
//          4775.00,2154.96 4790.00,2156.00 4790.00,2156.00
//          4790.00,2156.00 4790.00,2231.00 4790.00,2231.00
//          4790.00,2231.00 4761.00,2229.00 4761.00,2229.00
//          4761.00,2229.00 4741.00,2228.00 4741.00,2228.00
//          4741.00,2228.00 4728.00,2227.00 4728.00,2227.00
//          4728.00,2227.00 4672.00,2224.00 4672.00,2224.00
//          4672.00,2224.00 4662.00,2223.04 4662.00,2223.04
//          4662.00,2223.04 4654.00,2223.04 4654.00,2223.04
//          4654.00,2223.04 4637.00,2222.00 4637.00,2222.00
//          4637.00,2222.00 4620.00,2221.00 4620.00,2221.00
//          4620.00,2221.00 4606.00,2220.00 4606.00,2220.00
//          4606.00,2220.00 4568.00,2218.00 4568.00,2218.00
//          4568.00,2218.00 4554.00,2217.00 4554.00,2217.00
//          4554.00,2217.00 4516.00,2215.00 4516.00,2215.00
//          4516.00,2215.00 4506.00,2214.04 4506.00,2214.04
//          4506.00,2214.04 4498.00,2214.04 4498.00,2214.04
//          4498.00,2214.04 4481.00,2213.00 4481.00,2213.00
//          4481.00,2213.00 4471.00,2212.00 4471.00,2212.00
//          4471.00,2212.00 4461.00,2212.00 4461.00,2212.00
//          4461.00,2212.00 4447.00,2211.00 4447.00,2211.00
//          4447.00,2211.00 4432.00,2210.00 4432.00,2210.00
//          4432.00,2210.00 4394.00,2208.00 4394.00,2208.00
//          4394.00,2208.00 4362.00,2206.00 4362.00,2206.00 Z" data-front="41" data-rear="41" data-left="202" data-lot="26" data-street="Collective Circle" data-right="204" data-sqft="8,330" data-type="Townhome" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_180" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 4369.00,2083.00
//          C 4369.00,2083.00 4403.00,2085.00 4403.00,2085.00
//          4403.00,2085.00 4412.00,2085.00 4412.00,2085.00
//          4412.00,2085.00 4451.00,2088.00 4451.00,2088.00
//          4451.00,2088.00 4460.00,2088.00 4460.00,2088.00
//          4460.00,2088.00 4488.00,2090.00 4488.00,2090.00
//          4488.00,2090.00 4514.00,2091.91 4514.00,2091.91
//          4514.00,2091.91 4570.00,2095.09 4570.00,2095.09
//          4570.00,2095.09 4601.00,2097.09 4601.00,2097.09
//          4601.00,2097.09 4611.00,2098.00 4611.00,2098.00
//          4611.00,2098.00 4640.00,2099.91 4640.00,2099.91
//          4640.00,2099.91 4680.00,2102.04 4680.00,2102.04
//          4680.00,2102.04 4693.00,2103.00 4693.00,2103.00
//          4693.00,2103.00 4719.00,2104.91 4719.00,2104.91
//          4719.00,2104.91 4766.00,2107.96 4766.00,2107.96
//          4766.00,2107.96 4776.00,2107.96 4776.00,2107.96
//          4776.00,2107.96 4783.00,2108.91 4783.00,2108.91
//          4785.16,2109.08 4788.49,2108.72 4789.99,2110.60
//          4792.02,2113.17 4790.01,2125.92 4789.99,2130.00
//          4789.99,2130.00 4789.99,2156.00 4789.99,2156.00
//          4789.99,2156.00 4773.00,2155.00 4773.00,2155.00
//          4773.00,2155.00 4746.00,2154.00 4746.00,2154.00
//          4746.00,2154.00 4728.00,2153.00 4728.00,2153.00
//          4728.00,2153.00 4712.00,2152.00 4712.00,2152.00
//          4712.00,2152.00 4694.00,2151.00 4694.00,2151.00
//          4694.00,2151.00 4667.00,2150.00 4667.00,2150.00
//          4667.00,2150.00 4652.00,2149.00 4652.00,2149.00
//          4652.00,2149.00 4634.00,2148.00 4634.00,2148.00
//          4634.00,2148.00 4607.00,2147.00 4607.00,2147.00
//          4607.00,2147.00 4594.00,2146.00 4594.00,2146.00
//          4594.00,2146.00 4568.00,2145.00 4568.00,2145.00
//          4568.00,2145.00 4555.00,2144.00 4555.00,2144.00
//          4555.00,2144.00 4528.00,2143.00 4528.00,2143.00
//          4528.00,2143.00 4515.00,2142.00 4515.00,2142.00
//          4515.00,2142.00 4489.00,2141.00 4489.00,2141.00
//          4489.00,2141.00 4473.00,2140.00 4473.00,2140.00
//          4473.00,2140.00 4456.00,2139.00 4456.00,2139.00
//          4456.00,2139.00 4429.00,2138.00 4429.00,2138.00
//          4429.00,2138.00 4414.00,2137.00 4414.00,2137.00
//          4414.00,2137.00 4396.00,2136.00 4396.00,2136.00
//          4396.00,2136.00 4367.00,2135.00 4367.00,2135.00
//          4367.00,2135.00 4369.00,2083.00 4369.00,2083.00 Z" data-front="41" data-rear="41" data-left="199" data-lot="27" data-street="Collective Circle" data-right="202" data-sqft="8,243" data-type="Townhome" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_181" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 4377.00,1942.00
//            C 4377.00,1942.00 4415.00,1944.00 4415.00,1944.00
//              4415.00,1944.00 4447.00,1946.00 4447.00,1946.00
//              4447.00,1946.00 4460.00,1947.00 4460.00,1947.00
//              4460.00,1947.00 4469.00,1947.00 4469.00,1947.00
//              4469.00,1947.00 4479.00,1948.00 4479.00,1948.00
//              4479.00,1948.00 4502.00,1949.00 4502.00,1949.00
//              4502.00,1949.00 4516.00,1950.00 4516.00,1950.00
//              4516.00,1950.00 4536.00,1951.00 4536.00,1951.00
//              4536.00,1951.00 4550.00,1952.00 4550.00,1952.00
//              4550.00,1952.00 4588.00,1954.00 4588.00,1954.00
//              4588.00,1954.00 4598.00,1954.91 4598.00,1954.91
//              4598.00,1954.91 4623.00,1956.00 4623.00,1956.00
//              4623.00,1956.00 4633.00,1957.00 4633.00,1957.00
//              4633.00,1957.00 4643.00,1957.00 4643.00,1957.00
//              4643.00,1957.00 4657.00,1958.00 4657.00,1958.00
//              4657.00,1958.00 4667.00,1958.96 4667.00,1958.96
//              4667.00,1958.96 4675.00,1958.96 4675.00,1958.96
//              4675.00,1958.96 4692.00,1960.00 4692.00,1960.00
//              4692.00,1960.00 4709.00,1961.00 4709.00,1961.00
//              4709.00,1961.00 4723.00,1962.00 4723.00,1962.00
//              4723.00,1962.00 4761.00,1964.00 4761.00,1964.00
//              4761.00,1964.00 4792.00,1966.00 4792.00,1966.00
//              4792.00,1966.00 4792.00,1993.00 4792.00,1993.00
//              4792.00,1993.00 4791.00,2010.00 4791.00,2010.00
//              4791.00,2010.00 4791.00,2064.00 4791.00,2064.00
//              4791.00,2064.00 4790.00,2080.00 4790.00,2080.00
//              4790.00,2080.00 4790.00,2107.00 4790.00,2107.00
//              4790.00,2107.00 4778.00,2106.00 4778.00,2106.00
//              4778.00,2106.00 4768.00,2106.00 4768.00,2106.00
//              4768.00,2106.00 4754.00,2105.00 4754.00,2105.00
//              4754.00,2105.00 4744.00,2104.00 4744.00,2104.00
//              4744.00,2104.00 4734.00,2104.00 4734.00,2104.00
//              4734.00,2104.00 4720.00,2103.00 4720.00,2103.00
//              4720.00,2103.00 4706.00,2102.00 4706.00,2102.00
//              4706.00,2102.00 4693.00,2101.00 4693.00,2101.00
//              4693.00,2101.00 4684.00,2101.00 4684.00,2101.00
//              4684.00,2101.00 4671.00,2100.00 4671.00,2100.00
//              4671.00,2100.00 4653.00,2099.00 4653.00,2099.00
//              4653.00,2099.00 4639.00,2098.00 4639.00,2098.00
//              4639.00,2098.00 4602.00,2096.00 4602.00,2096.00
//              4602.00,2096.00 4592.00,2095.00 4592.00,2095.00
//              4592.00,2095.00 4583.00,2095.00 4583.00,2095.00
//              4583.00,2095.00 4570.00,2094.00 4570.00,2094.00
//              4570.00,2094.00 4552.00,2093.00 4552.00,2093.00
//              4552.00,2093.00 4542.00,2092.00 4542.00,2092.00
//              4542.00,2092.00 4532.00,2092.00 4532.00,2092.00
//              4532.00,2092.00 4518.00,2091.00 4518.00,2091.00
//              4518.00,2091.00 4501.00,2090.00 4501.00,2090.00
//              4501.00,2090.00 4487.00,2089.00 4487.00,2089.00
//              4487.00,2089.00 4474.00,2088.00 4474.00,2088.00
//              4474.00,2088.00 4465.00,2088.00 4465.00,2088.00
//              4465.00,2088.00 4452.00,2087.00 4452.00,2087.00
//              4452.00,2087.00 4434.00,2086.00 4434.00,2086.00
//              4434.00,2086.00 4404.00,2084.00 4404.00,2084.00
//              4404.00,2084.00 4383.00,2083.00 4383.00,2083.00
//              4383.00,2083.00 4369.00,2082.00 4369.00,2082.00
//              4369.00,2082.00 4371.00,2044.00 4371.00,2044.00
//              4371.00,2044.00 4372.00,2027.00 4372.00,2027.00
//              4372.00,2027.00 4373.00,2010.00 4373.00,2010.00
//              4373.00,2010.00 4374.00,1995.00 4374.00,1995.00
//              4374.00,1995.00 4376.00,1958.00 4376.00,1958.00
//              4376.00,1958.00 4377.00,1942.00 4377.00,1942.00 Z" data-front="94" data-rear="94" data-left="195" data-lot="28" data-street="Collective Circle" data-right="199" data-sqft="18,568" data-type="Townhome" data-unit_type="Sqft" data-block="6" data-grade="Flat" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_183" class="has-hover greenery" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 934.00,1274.00
//           C 934.00,1274.00 1027.00,1274.00 1027.00,1274.00
//             1027.00,1274.00 1042.00,1273.00 1042.00,1273.00
//             1042.00,1273.00 1228.00,1273.00 1228.00,1273.00
//             1228.00,1273.00 1243.00,1272.00 1243.00,1272.00
//             1243.00,1272.00 1429.00,1272.00 1429.00,1272.00
//             1429.00,1272.00 1444.00,1271.00 1444.00,1271.00
//             1444.00,1271.00 1630.00,1271.00 1630.00,1271.00
//             1630.00,1271.00 1645.00,1270.00 1645.00,1270.00
//             1645.00,1270.00 1738.00,1270.00 1738.00,1270.00
//             1738.00,1270.00 1738.00,1355.00 1738.00,1355.00
//             1738.00,1355.00 1739.00,1370.00 1739.00,1370.00
//             1739.00,1370.00 1739.00,1539.00 1739.00,1539.00
//             1739.00,1539.00 1740.00,1554.00 1740.00,1554.00
//             1740.00,1554.00 1740.00,1724.00 1740.00,1724.00
//             1740.00,1724.00 1741.00,1739.00 1741.00,1739.00
//             1741.00,1739.00 1741.00,1906.00 1741.00,1906.00
//             1741.00,1906.00 1742.00,1923.00 1742.00,1923.00
//             1742.00,1923.00 1742.00,2001.00 1742.00,2001.00
//             1741.99,2003.68 1742.15,2006.46 1741.15,2009.00
//             1741.15,2009.00 1721.42,2038.00 1721.42,2038.00
//             1721.42,2038.00 1664.71,2118.00 1664.71,2118.00
//             1664.71,2118.00 1649.91,2137.96 1649.91,2137.96
//             1649.91,2137.96 1609.00,2171.20 1609.00,2171.20
//             1609.00,2171.20 1543.00,2224.20 1543.00,2224.20
//             1543.00,2224.20 1364.00,2367.60 1364.00,2367.60
//             1364.00,2367.60 1303.00,2416.40 1303.00,2416.40
//             1303.00,2416.40 1271.01,2442.17 1271.01,2442.17
//             1265.35,2447.40 1256.07,2462.82 1251.58,2470.00
//             1251.58,2470.00 1222.58,2515.00 1222.58,2515.00
//             1222.58,2515.00 1194.69,2558.00 1194.69,2558.00
//             1194.69,2558.00 1134.69,2651.00 1134.69,2651.00
//             1134.69,2651.00 1108.22,2693.00 1108.22,2693.00
//             1108.22,2693.00 1100.58,2726.00 1100.58,2726.00
//             1100.58,2726.00 1086.58,2789.00 1086.58,2789.00
//             1086.58,2789.00 1060.21,2909.00 1060.21,2909.00
//             1060.21,2909.00 1049.42,2960.00 1049.42,2960.00
//             1049.42,2960.00 1046.75,2973.00 1046.75,2973.00
//             1045.22,2978.87 1037.64,2990.27 1034.20,2996.00
//             1034.20,2996.00 1008.80,3038.00 1008.80,3038.00
//             1008.80,3038.00 950.00,3135.00 950.00,3135.00
//             950.00,3135.00 931.20,3166.00 931.20,3166.00
//             928.11,3171.16 924.13,3178.95 920.00,3183.00
//             920.00,3183.00 920.00,3123.00 920.00,3123.00
//             920.00,3123.00 921.00,3106.00 921.00,3106.00
//             921.00,3106.00 921.00,2984.00 921.00,2984.00
//             921.00,2984.00 922.00,2969.00 922.00,2969.00
//             922.00,2969.00 922.00,2848.00 922.00,2848.00
//             922.00,2848.00 923.00,2833.00 923.00,2833.00
//             923.00,2833.00 923.00,2711.00 923.00,2711.00
//             923.00,2711.00 924.00,2696.00 924.00,2696.00
//             924.00,2696.00 924.00,2575.00 924.00,2575.00
//             924.00,2575.00 925.00,2560.00 925.00,2560.00
//             925.00,2560.00 925.00,2439.00 925.00,2439.00
//             925.00,2439.00 926.00,2424.00 926.00,2424.00
//             926.00,2424.00 926.00,2302.00 926.00,2302.00
//             926.00,2302.00 927.00,2287.00 927.00,2287.00
//             927.00,2287.00 927.00,2166.00 927.00,2166.00
//             927.00,2166.00 928.00,2151.00 928.00,2151.00
//             928.00,2151.00 928.00,2029.00 928.00,2029.00
//             928.00,2029.00 929.00,2014.00 929.00,2014.00
//             929.00,2014.00 929.00,1893.00 929.00,1893.00
//             929.00,1893.00 930.00,1878.00 930.00,1878.00
//             930.00,1878.00 930.00,1756.00 930.00,1756.00
//             930.00,1756.00 931.00,1741.00 931.00,1741.00
//             931.00,1741.00 931.00,1620.00 931.00,1620.00
//             931.00,1620.00 932.00,1605.00 932.00,1605.00
//             932.00,1605.00 932.00,1484.00 932.00,1484.00
//             932.00,1484.00 933.00,1469.00 933.00,1469.00
//             933.00,1469.00 933.00,1347.00 933.00,1347.00
//             933.00,1347.00 934.00,1332.00 934.00,1332.00
//             934.00,1332.00 934.00,1274.00 934.00,1274.00 Z" data-front="1275" data-rear="491" data-left="430" data-lot="15" data-street="15TH STREET NW" data-right="535" data-sqft="425,410" data-type="Single Family" data-unit_type="Sqft" data-block="1" data-grade="1" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_186" class="has-hover greenery" data-toggle="popover" data-trigger="hover focus" data-price="1" data-placement="left" d="M 940.00,444.00
//       C 940.00,444.00 1065.00,444.00 1065.00,444.00
//         1065.00,444.00 1080.00,443.00 1080.00,443.00
//         1080.00,443.00 1327.00,443.00 1327.00,443.00
//         1327.00,443.00 1344.00,442.00 1344.00,442.00
//         1344.00,442.00 1515.00,442.00 1515.00,442.00
//         1515.00,442.00 1593.00,442.00 1593.00,442.00
//         1593.00,442.00 1608.00,441.00 1608.00,441.00
//         1608.00,441.00 1733.00,441.00 1733.00,441.00
//         1733.00,441.00 1733.00,618.00 1733.00,618.00
//         1733.00,618.00 1734.00,633.00 1734.00,633.00
//         1734.00,633.00 1734.00,803.00 1734.00,803.00
//         1734.00,803.00 1735.00,818.00 1735.00,818.00
//         1735.00,818.00 1735.00,987.00 1735.00,987.00
//         1735.00,987.00 1736.00,1002.00 1736.00,1002.00
//         1736.00,1002.00 1736.00,1172.00 1736.00,1172.00
//         1736.00,1172.00 1737.00,1187.00 1737.00,1187.00
//         1737.00,1187.00 1737.00,1272.00 1737.00,1272.00
//         1737.00,1272.00 1563.00,1272.00 1563.00,1272.00
//         1563.00,1272.00 1476.00,1272.00 1476.00,1272.00
//         1476.00,1272.00 1461.00,1273.00 1461.00,1273.00
//         1461.00,1273.00 933.00,1273.00 933.00,1273.00
//         933.00,1273.00 933.00,1225.00 933.00,1225.00
//         933.00,1225.00 934.00,1208.00 934.00,1208.00
//         934.00,1208.00 934.00,1113.00 934.00,1113.00
//         934.00,1113.00 935.00,1098.00 935.00,1098.00
//         935.00,1098.00 935.00,1002.00 935.00,1002.00
//         935.00,1002.00 936.00,987.00 936.00,987.00
//         936.00,987.00 936.00,892.00 936.00,892.00
//         936.00,892.00 937.00,877.00 937.00,877.00
//         937.00,877.00 937.00,781.00 937.00,781.00
//         937.00,781.00 938.00,766.00 938.00,766.00
//         938.00,766.00 938.00,670.00 938.00,670.00
//         938.00,670.00 939.00,655.00 939.00,655.00
//         939.00,655.00 939.00,560.00 939.00,560.00
//         939.00,560.00 940.00,545.00 940.00,545.00
//         940.00,545.00 940.00,444.00 940.00,444.00 Z" data-front="555" data-rear="555" data-left="535" data-lot="14" data-street="15TH STREET NW" data-right="538" data-sqft="294,953" data-type="Single family" data-unit_type="Sqft" data-block="1" data-grade="1" data-original-title="Heritage Park 2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_28" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3992.00,439.00
//         C 3992.00,439.00 4005.00,440.17 4005.00,440.17
//           4005.00,440.17 4048.00,443.91 4048.00,443.91
//           4048.00,443.91 4068.00,445.17 4068.00,445.17
//           4068.00,445.17 4130.00,450.17 4130.00,450.17
//           4130.00,450.17 4155.00,452.09 4155.00,452.09
//           4155.00,452.09 4167.00,453.00 4167.00,453.00
//           4167.00,453.00 4160.28,509.00 4160.28,509.00
//           4160.28,509.00 4145.85,622.00 4145.85,622.00
//           4145.85,622.00 4139.00,677.00 4139.00,677.00
//           4139.00,677.00 4093.00,675.00 4093.00,675.00
//           4093.00,675.00 4076.00,674.00 4076.00,674.00
//           4076.00,674.00 3993.00,674.00 3993.00,674.00
//           3993.00,674.00 3993.00,584.00 3993.00,584.00
//           3993.00,584.00 3992.00,569.00 3992.00,569.00
//           3992.00,569.00 3992.00,509.00 3992.00,509.00
//           3992.00,509.00 3992.00,469.00 3992.00,469.00
//           3991.99,459.60 3989.41,448.12 3992.00,439.00 Z" data-front="97" data-rear="116" data-left="151" data-lot="12" data-street="Community Loop" data-right="156" data-sqft="16,394" data-type="Single" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Pak2"></path>
                
                
        
//                 <path onclick="getvalue(this.id)" id="unit_25" class="has-hover parkProperty" data-toggle="popover" data-trigger="hover focus" data-price="0" data-placement="left" d="M 3278.00,643.00
//         C 3278.00,643.00 3258.00,648.52 3258.00,648.52
//           3239.89,654.42 3223.55,661.78 3208.00,672.86
//           3208.00,672.86 3191.00,685.92 3191.00,685.92
//           3188.87,687.65 3185.99,690.47 3183.00,689.68
//           3180.26,688.96 3168.00,677.36 3165.00,674.72
//           3165.00,674.72 3120.00,634.17 3120.00,634.17
//           3120.00,634.17 2966.00,495.83 2966.00,495.83
//           2966.00,495.83 2934.00,467.02 2934.00,467.02
//           2930.60,463.86 2923.87,458.59 2921.60,455.00
//           2919.49,451.67 2919.97,445.17 2919.59,441.00
//           2919.44,439.25 2919.05,437.31 2920.61,436.02
//           2922.14,434.77 2926.06,435.00 2928.00,435.00
//           2928.00,435.00 3090.00,435.00 3090.00,435.00
//           3090.00,435.00 3105.00,434.00 3105.00,434.00
//           3105.00,434.00 3204.00,434.00 3204.00,434.00
//           3204.00,434.00 3225.31,495.00 3225.31,495.00
//           3225.31,495.00 3257.69,586.00 3257.69,586.00
//           3257.69,586.00 3278.00,643.00 3278.00,643.00 Z" data-front="70" data-rear="188" data-left="148" data-lot="5" data-street="Community Loop" data-right="248" data-sqft="20,039" data-type="Single family" data-unit_type="Sqft" data-block="2" data-grade="Flat" data-original-title="Unit 1"></path>
                
                
//                     Sorry, your browser does not support inline SVG.
//                   </svg>
//                 </div>
//         </div>
//     </div>
    

//     <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
//     <!-- <script src="https://code.jquery.com/jquery-3.1.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script> -->
//     <!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.0.4/popper.js"></script> -->
//     <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta/js/bootstrap.min.js" integrity="sha384-h0AbiXch4ZDo7tp9hKZ4TsHbi047NrKGLO3SEJAg45jXxnGIfYzk4Si90RDIqNm1" crossorigin="anonymous"></script>
//     <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js" integrity="sha384-b/U6ypiBEHpOf/4+1nzFpr53nxSS+GLCkfwBdFNTxtclqqenISfwAzpKaMNFNmj4" crossorigin="anonymous"></script>
   


// </div>

//     <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script> 
//     <script>
//       var pagePath = "heritage/property-unit/?property=Park2";
//       var containerId = 'spotmink_container';
//   </script>
//   <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js" crossorigin="anonymous"></script>
//   <script src="https://spotmink.com/static/js/embed.js"></script>
// <script src="https://spotmink.com//static/js/script.js"></script></body></html>