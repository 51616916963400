import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


import SecondaryBtn from '../../button-component/SecondaryBtn';
import PrimaryBtn from '../../button-component/PrimaryBtn';

export default function Confirm({
  open,
  close,
  onSubmit=()=>{},
  description,
  isSecondary=false,
  removeText
}) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         
        <DialogContent className='flex justify-between !min-h-28 !h-28'>
          <DialogContentText id="alert-dialog-description" className='!text-[#353349]'>
          {description}
         
          </DialogContentText>
          <span style={{padding:'0px 16px 16px',cursor:'pointer'}} onClick={close}>&#10005;</span>
        </DialogContent>
        <DialogActions>
          {isSecondary &&(
            <SecondaryBtn
              title='Cancel'
              onClick={close} 
            />
          )}

        <PrimaryBtn 
          title={removeText || 'Update'} 
          onClick={onSubmit}
          style={{
            padding:'5px 14px',
            boxShadow:'none'
          }}
           />
        </DialogActions>
      </Dialog>
    </div>
  );
}