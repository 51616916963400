import { Typography } from "@mui/material";
import React from "react";

function StatusLabel({status='',color={color},header=false,title=''}){
    return(
        <>
        {header?
        <Typography color='secondary' sx={{
            fontWeight:300
        }}>
            {title}
        </Typography> 
        :''   
        }
            <Typography varient='h3' color={color} sx={{
            fontWeight:300
        }}>
                {status}
            </Typography>
        </>
    )
}
export default StatusLabel;