// ----------------- Documentation ---------------------------
// this component used for show the data from channel partner list within the table the table handels edit and delete the data from the firebase database


import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import DataGridTable from "../../shared-component/table-component/DataGridTable";
import StatusLabel from "../../shared-component/OverviewPage/overview-used-components/StatusLabel";
import TextBtn from "../../shared-component/button-component/TextBtn";
import BreadCrumbWithActions from "../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import SecondaryBtn from "../../shared-component/button-component/SecondaryBtn";
import PrimaryBtn from "../../shared-component/button-component/PrimaryBtn";
import EmptyCard from "../../shared-component/card-component/EmptyCard";
import { BoxEffects, headerButton } from "../../../Config/Constants";
import AddChannelPartner from "./AddChannelPartner";


const columns = [
  { field: "user_id", headerName: "User ID", minWidth: 200 },
  { field: "name", headerName: "Name", minWidth: 200 },
  { field: "position", headerName: "Position", minWidth: 200 },
  { field: "email", headerName: "Email", minWidth: 200 },
  { field: "role", headerName: "Role", minWidth: 200 },
  {
    field: "status",
    headerName: "Status",
    minWidth: 200,
    renderCell: (params) => (
      <>
        <StatusLabel status={params.formattedValue} color="success.main" />
      </>
    ),
  },
  {
    field: "actions",
    headerName: "",
    minWidth: 200,
    align: "right",
    renderCell: (params) => (
      <>
        <TextBtn title="Invite" />
      </>
    ),
  },
];
function ChannelPartner() {
  const [openChannelPartner, setOpenChannelPartner] = useState(false);
  const [channelPartnerList, setChannelPartnerList] = useState([]);
  const arr1 = [{ title: "Channel Partner", url: "/" }];
  function handelAddModal() {
    setOpenChannelPartner(!openChannelPartner);
  }


  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: "secondary.main",
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          <SecondaryBtn style={{backgroundColor:'#EDECF9'}} title="Filter" />
          <PrimaryBtn title="Add New" onClick={handelAddModal} />
        </BreadCrumbWithActions>
      </Box>
      {channelPartnerList.length === 0 ? (
        <EmptyCard
          icon={<ContentPasteGoIcon fontSize="large" />}
          title="No Plans Added"
          subtitle="Click “Add New” to add the plan"
        />
      ) : (
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            margin: BoxEffects.margin,
            position: "relative",
          }}
        >
          <DataGridTable columns={columns} rows={channelPartnerList} />
        </Box>
      )}
      {openChannelPartner && (
        <AddChannelPartner
          title="Add Channel  Partner"
          open={openChannelPartner}
          onClose={handelAddModal}
          setOpenChannelPartner={setOpenChannelPartner}
        />
      )}
    </>
  );
}
export default ChannelPartner;
