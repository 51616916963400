import React, { useContext, useEffect, useState } from 'react';
 
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../utils/useExit';
import axios from 'axios';
import { TokenStorage } from '../../../../constant/AuthConstant';
import UserContext from '../../../../context/UserContext';
import { CircularProgress } from '@mui/material';
import Compressor from 'compressorjs';

const DefaultImagesWithProgress = ({
    image,
    imageList,
    setImagesList,
    imagesToUpload,
    setImagesToUpload,
    setUploadedImages,
    uploadedImage,

}) =>{
    const { exitFromPlatform } = useExit()
    const [uploadProgress, setUploadProgress] = useState(0);
    const [updatedImage, setUpdatedImage] = useState(URL.createObjectURL(image?.file))
    const { organization } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false)
    
    
    const uploadImage = (item) =>{
        setLoading(true)
       if(item?.file?.type === 'video/mp4'){
        const formData = new FormData();
        formData.append('media', item?.file)
        formData.append('name', item?.file?.name);
        formData.append('alt_text', item?.file?.name);
        formData.append('tags', JSON.stringify([]))
        formData.append('org', organization?.id)
        formData.append('filetype', "video")

        axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}${`organization/gallery/`}`,
            data: formData,
            headers: { 
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${TokenStorage.getToken()}`
            },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percentCompleted = Math.round((loaded * 100) / total);
                setUploadProgress(percentCompleted);
            }
        }).then((res) =>{
            if(res?.data){
                setImagesList((prev) =>{
                    return [...prev, res?.data]
                })
                const saveUploaded = [...uploadedImage, item]
                setUploadedImages(saveUploaded)

                setIsUploaded(true)
            }
            setLoading(false)
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
                setLoading(false)
            }
            }
        })
       }
       else{
        new Compressor(item?.file, {
            quality: 0.6,
            success(result) {
                const formData = new FormData();
                formData.append('media', result)
                formData.append('name', result?.name);
                formData.append('alt_text', result?.name);
                formData.append('tags', JSON.stringify([]))
                formData.append('org', organization?.id)
                formData.append('filetype', "image")

                axios({
                    method: "post",
                    url: `${process.env.REACT_APP_BASE_URL}${`organization/gallery/`}`,
                    data: formData,
                    headers: { 
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${TokenStorage.getToken()}`
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        const percentCompleted = Math.round((loaded * 100) / total);
                        setUploadProgress(percentCompleted);
                    }
                }).then((res) =>{
                    if(res?.data){
                        const saveUploaded = [...uploadedImage, item]
                        setUploadedImages(saveUploaded)
                        
                        setImagesList((prev) =>{
                            return [...prev, res?.data]
                        })
                        setIsUploaded(true)
                       
                    }
                    setLoading(false)
                }).catch((error) =>{
                    if(error.response?.data){
                        const returnedError = returnError(error.response?.data)
                        if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                        exitFromPlatform()
                    }else{
                        enqueueSnackbar(returnedError, {variant:'error'})
                        setLoading(false)
                    }
                    }
                })
            },
            error(err) {
              enqueueSnackbar(err.message, {variant:'error'})
              setLoading(true)
            },
          });
       }
       
      
    }

    useEffect(() =>{
       if(image?.file?.name){
          const check = [...imagesToUpload]?.some((e) =>{
            return e?.id === image?.id
          })

          const checkInUploaded = [...uploadedImage]?.some((e) =>{
            return e?.id === image?.id
          })


          if(checkInUploaded === false){
             if(check === true){
                uploadImage(image)
             }
          }
       }
    }, [image])

    useEffect(() =>{
       if(isUploaded){
        const allImages = [...imagesToUpload]?.filter((e) =>{
            return e?.id !== image?.id
        })

        setImagesToUpload(allImages)
       }
    }, [isUploaded])


    return (
        <div className='flex flex-col items-center justify-center relative' style={{
            width:'150px',
            height:'150px',
            background: "#B8B7C5",
            borderRadius:'8px'
        }}>
          {image?.file?.type === 'video/mp4' ?
           <video
           src={updatedImage}
           alt="invalid image"
           style={{
            width:'150px',
            height:'150px',
            objectFit:'contain',
            borderRadius:'4px'
           }}
       />
          :
          <img src={updatedImage} style={{
            width:'150px',
            height:'150px',
            objectFit:'cover',
            borderRadius:'4px'
         }} />}
          {loading && (
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-100 flex items-center justify-center">
                <CircularProgress size={25} sx={{ color: '#24D315' }} />
            </div>
           )}
        </div>
    )
}

export default DefaultImagesWithProgress