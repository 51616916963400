import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import {themes} from '../../../Config/themesConfig'
// import { navLogo, stack, customBox } from '../../Config/Constants';
import { IconButton, Stack } from '@mui/material';
// import logoo from '../../asset/Images/article1.png';
import { navLogo, stack, customBox } from '../../../Config/Constants';
import {PrimaryButton} from '@assystant/mui-buttons';
import CloseIcon from '@mui/icons-material/Close';
// import PopoverPopupState from './PopoverfornavIcon';



function MapPageNavBar({logo,onCancel}) {
  return (
    <AppBar position="fixed" sx={{background: themes.palette.secondary.main}}>
        <Toolbar disableGutters className='flex justify-between items-center mr-5'>
            <PrimaryButton title='Save' style={{
               fontWeight:400}}/>
          <Stack direction="row" spacing={stack.space}>
            {/* <Avatar /> */}
            {/* <PopoverPopupState /> */}
            <div className="flex justify-end items-center">
                <IconButton onClick={onCancel} aria-label="filter" size="large">
                    <CloseIcon htmlColor="white" />
                </IconButton>
            </div>
          </Stack>
        </Toolbar>
        
    </AppBar>
  );
}
export default MapPageNavBar;