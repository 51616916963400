
import React from 'react';
import FormModal from '../../shared-component/formmodal-component/FormModal';
import Form from '../../shared-component/formrelated-component/FormManager';

function AddProperty({
  open,
  data,
  title,
  loading,
  onClose,
  handelAddProperty,
}) {
  const defaultValues = {
    property_name: '',
    description: '',
    price: '',
    token_price: ''
  };

  const fieldMap = [
    {
      name: 'property_name',
      label: 'Property Name',
      placeholder: 'Property Name',
      type: 'text',
      fieldProps: {
        required: true,
      },
    },
    {
      name: 'description',
      label: 'Description',
      placeholder: 'Description',
      type: 'textarea',
      fieldProps: {
        required: true,
      },
    },
    {
      name: 'price',
      label: 'Base Unit Price',
      placeholder: 'Base Unit Price',
      type: 'text',
    },
    {
      name: 'token_price',
      label: 'Token Amount',
      placeholder: 'Token Amount',
      type: 'text',
    },
  ];
  return (
    <>
      <FormModal title={title} open={open} onClose={onClose}>
        <div className="mt-4">
          <Form
            defaultValues={defaultValues}
            fieldMap={fieldMap}
            onCancel={onClose}
            formSubmit={handelAddProperty}
            primaryBtnTitle="Create"
            data={data}
            loading={loading}
          />
        </div>
      </FormModal>
    </>
  );
}
export default AddProperty;
