import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';

const SignatureComponent = ({
    open,
    onSave,
    onClose,
}) => {
  const sigCanvas = useRef();

  return (
    <FormModal open={open} onClose={onClose}>
    <div className='m-6'>
      <div className='flex justify-center'>
      <SignatureCanvas penColor="black" ref={sigCanvas} canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} backgroundColor='rgb(225, 235, 254)' onBegin={(e) =>{
         e.stopPropagation()
      }} onEnd={(e) =>{
        e.stopPropagation()
     }} />
      </div>
      <div className='flex justify-end mt-6'>
          <SecondaryBtn title="Cancel" onClick={onClose} />
          <PrimaryBtn title="Save" onClick={() =>{
            onSave(sigCanvas.current.toDataURL())
          }} />
      </div>
    </div>
    </FormModal>
  );
}

export default SignatureComponent
