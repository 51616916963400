import React, { useEffect, useState, useContext } from 'react'
import { Checkbox } from '@mui/material'
import DataGridTable from '../../../shared-component/table-component/DataGridTable'
import Loading from '../../../shared-component/Loading/Loading';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../utils/useExit';
import returnError from '../../../../utils/ExportError';
import ProfileContainer from '../../../shared-component/table-component/Row/ProfileContainer';
import formatISOString from '../../../../utils/formatISOString';
import { apiService } from '../../../../constant/AuthConstant';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import UserContext from '../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import FilterConversion from '../component/FilterConversion';
import DetailSideDrawer from '../../ParentComponents/SideDrawer/DetailSideDrawer';

const sampleData = [
    {
      id:1,
      name:'John Doe',
      email:'johndoe@gmail.com',
      type:'Lead',
      reward:'$500',
      date:"2024-04-23T05:47:03.605761Z",
      _action:'Mark as Paid | Reject',
      status:'paid'
    },
    {
      id:2,
      name:'Jamie Doe',
      email:'jamiedoe@gmail.com',
      type:'Booking',
      reward:'$500',
      date:"2024-04-23T05:47:03.605761Z",
      _action:'Accept',
      status:'unpaid'
    },
  ]


const Conversations = ({
      openFilter,
      handleFilter,
      affiliateList,
}) =>{
    const navigate = useNavigate()
    const { exitFromPlatform } = useExit()
    const [loading, setLoading] = useState(false);
    const [conversationsList, setConversationsList] = useState([])
    const [selectedConversion, setSelectedConversion] = useState(null)

    const [affiliate, setAffiliate] = useState({name: '', id: ''})
    const [type, setType] = useState({name: '', id: ''});
    

    const {
      selectedCurrency, 
      returnFormattedCurrency
  } = useContext(UserContext)

  const [sideDrawer, setSideDrawer] = useState(false)
  const [selectedForm, setSelectedForm] = useState(null);

  const [propertyLoading, setPropertyLoading] = useState(false)
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [unitLoading, setUnitLoading] = useState(false)
  const [unitDetails, setUnitDetails] = useState(null)
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [unitStatus, setUnitStatus] = useState(null)
  
  const handleSideDrawer = () => {
    setSideDrawer(!sideDrawer)
  }
  
  const openDrawerWithPropertyDetails = (pro) =>{
    setSelectedInventory(null)
    setSelectedProperty(pro)
    handleSideDrawer()
  }
  
  const openDrawerWithUnitDetails = (uni) =>{
    setSelectedProperty(null)
    setSelectedInventory(uni)
    handleSideDrawer()
  }

    const getConversionData = () =>{
        setLoading(true)
        apiService.get(`affiliates/conversions`).then((res) =>{
          const list = [...res?.data]?.sort((a,b) => (a.modified < b.modified) ? 1 : ((b.modified < a.modified) ? -1 : 0))
           
          setConversationsList(list)
          setSelectedConversion(null)
          setLoading(false)
        }).catch((error) =>{
          if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setLoading(false)
          }
        }
        })
      }
    
    const changeConversionStatus = (status, data) =>{
      let formData;

      if(data?.lead?.id){
           formData = {
            ...data,
            conversion_status: status,
            affiliate: data?.affiliate?.referral_code,
            lead: data?.lead?.id,
            form: data?.form?.id
          }
      }else{
        formData = {
          ...data,
          conversion_status: status,
          affiliate: data?.affiliate?.referral_code,
          booking: data?.booking?.id,
          form: data?.form?.id
        }
      }
      apiService.put(`affiliates/conversions/${data?.id}/`, formData).then((res) =>{
        if(res.status){
            const list = [...conversationsList];

            const updateList = list?.map((item) =>{
              if(item?.id === res?.data?.id){
                return {
                  ...res?.data
                }
              }else{
                return item
              }
            })?.sort((a,b) => (a.modified < b.modified) ? 1 : ((b.modified < a.modified) ? -1 : 0))
            setConversationsList(updateList)
            enqueueSnackbar("Status Updated", { variant: 'success' });
        }
      }).catch((error) =>{
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoading(false)
        }
      }
      })
    }
    
    const returnButtons = (status, mode, data) =>{
       const callStatus = (selectedStatus) =>{
        if(mode === ''){
          changeConversionStatus(selectedStatus, data)
        }else{
          return;
        }
       }
       if(status === 'paid'){
         return (
          <>
            <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
            onClick={(e) =>{
              e?.stopPropagation()
              callStatus('approved')
            }}
            style={{
                fontFamily: 'Rubik',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '23.7px',
                letterSpacing: '-0.022em',
                color:'#004FE8'
            }}>Mark as Unpaid</div>
          </>
         )
       }else if(status === 'rejected'){
        return (
          <>
            <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
              onClick={(e) =>{
                e?.stopPropagation()
                callStatus('approved')
              }}
            style={{
                fontFamily: 'Rubik',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '23.7px',
                letterSpacing: '-0.022em',
                color:'#004FE8'
            }}>Accept</div>
          </>
         )
       }
       else if(status === 'approved'){
        return (
          <>
            <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
              onClick={(e) =>{
                e?.stopPropagation()
                callStatus('paid')
              }}
            style={{
                fontFamily: 'Rubik',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '23.7px',
                letterSpacing: '-0.022em',
                color:'#004FE8'
            }}>Mark as Paid</div>
            <div style={{
                width:'2px',
                height:'14px',
                background:'#8E8CA3',
                margin: '0px 10px'
            }}></div>
            <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
              onClick={(e) =>{
                e?.stopPropagation()
                callStatus('rejected')
              }}
            style={{
                fontFamily: 'Rubik',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '23.7px',
                letterSpacing: '-0.022em',
                color:'#004FE8'
            }}>Reject</div>
          </>
         )
       }else if(status === 'pending'){
        return (
          <>
            <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
              onClick={(e) =>{
                e?.stopPropagation()
                callStatus('approved')
              }}
            style={{
                fontFamily: 'Rubik',
                fontSize: '15px',
                fontWeight: 400,
                lineHeight: '23.7px',
                letterSpacing: '-0.022em',
                color:'#004FE8'
            }}>Accept</div>
            <div style={{
                width:'2px',
                height:'15px',
                background:'#8E8CA3',
                margin: '0px 10px'
            }}></div>
            <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
              onClick={(e) =>{
                e?.stopPropagation()
                callStatus('rejected')
              }}
            style={{
                fontFamily: 'Rubik',
                fontSize: '15px',
                fontWeight: 400,
                lineHeight: '23.7px',
                letterSpacing: '-0.022em',
                color:'#004FE8'
            }}>Reject</div>
          </>
         )
       }
    }

    const returnStatus = (status) => {
      if(status === 'appoved'){
        return "Unpaid"
      }else if(status === 'rejected'){
        return "Rejected"
      }else if(status === 'approved'){
        return "Unpaid"
      }else if(status === 'pending'){
        return "Pending"
      }else{
        return "Paid"
      }
    }

    const returnColor = (status) => {
      if(status === 'approved'){
        return "#FF5C5C"
      }else if(status === 'rejected'){
        return "#FF5C5C"
      }else if(status === 'pending'){
        return "#FAD200"
      }else{
        return "#24D315"
      }
    }

    useEffect(() =>{
      getConversionData()
    }, [])

    const returnReward = (row) =>{
     return `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, row?.reward)}`
   }
    const columns = [
      { field: 'lead', headerName: 'Customer Details',minWidth: 250, renderCell: (params) =>{
          if(params?.row?.lead?.id){
              return <ProfileContainer profile={{
                  name: params?.row?.lead?.name,
                  email: params?.row?.lead?.email,
              }}/>
          }else{
              return <ProfileContainer profile={{
                  name: params?.row?.booking?.name,
                  email: params?.row?.booking?.email,
              }}/>
          }
      }},
      { field: 'reward', headerName: 'Reward',minWidth: 200, renderCell: (params) =>{
        return returnReward(params?.row)
      }},
      { field: 'booking', headerName: 'Conversion Type',minWidth: 200, renderCell: (params) =>{
        return params?.row?.booking !== null ? "Booking" : "Lead"
      }},
      { field: 'property', headerName: 'Property',minWidth: 200, renderCell: (params) =>{
      
        return <div onClick={(e) =>{
          e.stopPropagation()
          if(params?.row?.booking?.property?.name){
            openDrawerWithPropertyDetails(params?.row?.booking?.property)
           }
           else if(params?.row?.lead?.property?.name){
            openDrawerWithPropertyDetails(params?.row?.lead?.property)
           }
        }}>
          {params?.row?.booking?.property?.name ?  params?.row?.booking?.property?.name : params?.row?.lead?.property?.name}
        </div>
      }},
      { field: 'unit', headerName: 'Unit',minWidth: 200, renderCell: (params) =>{
        return <div onClick={(e) =>{
          e.stopPropagation()
          if(params?.row?.booking?.unit?.name){
            openDrawerWithUnitDetails(params?.row?.booking)
           }
           else if(params?.row?.lead?.unit?.name){
            openDrawerWithUnitDetails(params?.row?.lead)
           }
        }}>
          {params?.row?.booking?.unit?.name ?  params?.row?.booking?.unit?.name : params?.row?.lead?.unit?.name}
        </div>
      }},
      { field: 'created', headerName: 'Date',minWidth: 200, renderCell: (params) =>{
          return formatISOString(params?.row?.created)
      }},
      { field: 'affiliate', headerName: 'Affiliate',minWidth: 250, renderCell: (params) =>{
        if(params?.row?.affiliate?.user?.id){
          return <ProfileContainer profile={{
              name: params?.row?.affiliate?.user?.full_name,
              email: params?.row?.affiliate?.user?.email,
              picture: params?.row?.affiliate?.user?.avatar,
          }}/>
      }else{
          return <ProfileContainer profile={{
              name: params?.row?.affiliate?.invitation?.name,
              email: params?.row?.affiliate?.invitation?.email,
          }}/>
      }
    }},
      { field: '_action', headerName: 'Action',minWidth: 200, renderCell: (params) =>{
          return returnButtons(params.row.conversion_status, '', params?.row)
      }},
      { field: 
          'conversion_status', 
          headerName: 'Status',
          minWidth: 200,
          renderCell: (params) => (
              <>
                <div style={{
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '23.7px',
                  letterSpacing: '-0.022em',
                  color: returnColor(params?.row?.conversion_status),
                }}>

                {returnStatus(params?.row?.conversion_status)}
                </div>
              </>
              ),
      },
  ]

  const handleBookingClick = (e) =>{
     if(e.row?.booking?.id){
      navigate(`/booking/${e.row?.booking?.id}`)
     }
  }

  const onSubmit = (data) =>{
    setLoading(true)
    const newData = {...data}
    Object.entries(newData)?.map((e) =>{
      if(e[1] === '' || e[1] === null || e[1] === undefined){
        delete newData[e[0]]
      }
    })
    const searchParams = new URLSearchParams(newData).toString();
    apiService.get(`affiliates/conversions/?${searchParams}`).then((res) =>{
      const list = [...res?.data]?.sort((a,b) => (a.modified < b.modified) ? 1 : ((b.modified < a.modified) ? -1 : 0))
           
      setConversationsList(list)
      handleFilter()
      setLoading(false)
    }).catch((error) =>{
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
          setLoading(false)
      }
    }
    })
 }

 const fetchInventory = (unitId) => {
  setUnitLoading(true);
  apiService
    .get(`property/unit/${unitId}`)
    .then((res) => {
      if (res?.data?.output_specs?.groups) {
        const newData = [...res?.data?.output_specs?.groups];

        const updateSpecsGroups = newData?.map((e) => {
          const findGroup = res?.data?.specs?.groups?.find((f) => {
            return f?.grp_id === e?.grp_id;
          });

          return {
            grp_id: e?.grp_id,
            items: e?.items?.map((f) => {
              const findItem = findGroup?.items?.find((g) => {
                return g?.item_id === f?.item_id;
              });

              return {
                ...f,
                [f?.item?.name]: findItem?.item_value,
              };
            }),
          };
        });

        let newArray = [...updateSpecsGroups];
        const newData1 = {
          ...res?.data,
          images: res?.data?.images?.map((e) =>{
            return {
              ...e,
              tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
            }
          }),
          booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),
          specs: {
            template_id: res?.data?.specs?.template_id,
            groups: newArray,
          },
        }
        setUnitDetails(newData1);
      } else {
      setUnitDetails({
        ...res?.data,
        booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),});
       }
      setBookingData(res?.data)
      setUnitStatus(res?.data?.status);
      setUnitLoading(false);
    })
    .catch((error) => {
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setUnitLoading(false);
        }
      }
    });
};


const fetchPropertyDetails = (propertyId) => {
  setPropertyLoading(true);
  apiService
    .get(`property/prop/${propertyId}/`)
    .then(async (res) => {
      setPropertyDetails({
        ...res?.data,
        image_list: res?.data?.image_list?.map((e) =>{
          return {
            ...e,
            tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
          }
        }),
      });
      setPropertyLoading(false);
    })
    .catch((error) => {
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setPropertyLoading(false);
        }
      }
  });
}

const returnFormData = () =>{
  let dataObj = {}
  if(selectedProperty?.id){
    dataObj = {
        propertyData: propertyDetails,
      }
  }
  else if(selectedInventory?.id){
    dataObj = {
      unitStatus: unitStatus,
      inventoryData: unitDetails
    }
  }
  return dataObj
}

const navigateToForm = (navigateTo) =>{
  navigate(navigateTo)
}
    return(
       <>
       { loading ? <Loading />
        :
        <> 
         {conversationsList?.length > 0 ?
         <div className='relative'>
          <DataGridTable 
            rows={conversationsList}
            columns={columns}
            handleEvent={handleBookingClick}
          />
         </div>
      :
      <EmptyCard title='There are no Conversions' />}
        </>}
       {openFilter && (
         <FilterConversion 
         title="Filter"
         open={openFilter}
         onClose={handleFilter}
         affiliate={affiliate}
         setAffiliate={setAffiliate}
         type={type}
         setType={setType}
         affiliateList={affiliateList}
         onSubmit={onSubmit}
      />
       )}

      {sideDrawer && (
           <DetailSideDrawer 
              sideTitle={selectedInventory?.unit?.id ? `See in Units` : (selectedProperty?.id ? `See in Properties` : "")}
              sideButtonClickHandler={() =>{
                if(selectedProperty?.id){
                    navigateToForm(`/properties/${selectedProperty?.id}`)
                }else if(selectedInventory?.unit?.id){
                    localStorage.setItem('property_name', JSON.stringify(selectedInventory?.property?.name))
                    navigateToForm(`/properties/${unitDetails?.id}/inventory/${selectedInventory?.unit?.id}`)
                }
              }}
              title={selectedInventory?.unit?.id ? `Unit - ${unitDetails?.name || ''}` : `Property - ${propertyDetails?.name || ''}`}
              open={sideDrawer}
              onClose={handleSideDrawer}
              formData={returnFormData()}
              isPropertyComponent={selectedProperty?.id ? true : false}
              loading={selectedProperty?.id ? propertyLoading : unitLoading}
              fetchCallback={() =>{
                 if(selectedProperty?.id){
                    fetchPropertyDetails(selectedProperty?.id)
                 }else{
                    if(selectedInventory?.unit?.id){
                      fetchInventory(selectedInventory?.unit?.id)
                    }
                 }
              }}
              isUnitComponent={selectedInventory?.unit?.id ? true : false}
           />
       )}
       </>
    )
}

export default Conversations
