// ----------------- Documentation ---------------------------
// this component used for show the data from object group list within the table the table handels edit and delete the data from the firebase database


import { Link } from "@mui/material";
import React from "react";
import TextBtn from "../../../shared-component/button-component/TextBtn";
import DataGridTable from "../../../shared-component/table-component/DataGridTable";


const columns = [
    { field: 'object_Name', headerName: 'Group Name',minWidth: 200},
    {
      field: 'linked_Properties',
      headerName: 'Linked Properties',
    //   type: 'number',
        minWidth: 800,
        renderCell: (params) => (
        <>
            {/* <StatusLabel 
            status={params.formattedValue} 
            color='primary' /> */}
            <Link href="#" underline="none">
            {params.formattedValue} 
            </Link>
        </>
        ),
    },
    {
        field: 'edit',
        headerName: '',
        sortable: false,
        width: 160,
        renderCell: (params) => (
            <>
                <TextBtn title='edit' />
            </>
            ),
      },
  ];
  const rows = [
    { id: 1, object_Name: 'Size',  linked_Properties:'Apartments, Villa' },
    { id: 2, object_Name: 'Distance From Airport', linked_Properties:'Apartments, Villa, Land, Commercial' },
    { id: 3, object_Name: 'Size',  linked_Properties:'Apartments, Villa' },
    { id: 4, object_Name: 'Distance From Airport', linked_Properties:'Apartments, Villa, Land, Commercial' },
    { id: 5, object_Name: 'Size',  linked_Properties:'Apartments, Villa' },
    { id: 6, object_Name: 'Distance From Airport', linked_Properties:'Apartments, Villa, Land, Commercial' },
    { id: 7, object_Name: 'Size',  linked_Properties:'Apartments, Villa' },
    { id: 8, object_Name: 'Distance From Airport', linked_Properties:'Apartments, Villa, Land, Commercial' },
    
  ];

function ObjectGroup(){
    return(
        <>
            <DataGridTable rows={rows} columns={columns} />
        </>
    )
}
export default ObjectGroup;