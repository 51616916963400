import React, { useEffect, useState, useContext } from 'react'
import { 
    Box,
    Grid,
    Avatar
 } from '@mui/material';
 import BreadCrumbWithActions from '../../../../shared-component/breadcrumb-component/BreadCrumbWithActions'
import { BoxEffects, headerButton, grids } from '../../../../../Config/Constants'
import GridComponent from '../../../../shared-component/grid-component/GridComponent';
import LabelAndValue from '../../../../shared-component/OverviewPage/overview-used-components/LabelAndValue';
import DetailsCard from '../../../../shared-component/card-component/DetailsCard';
import { BASE_URI, apiService } from '../../../../../constant/AuthConstant';
import useExit from '../../../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import returnError from '../../../../../utils/ExportError';
import Loading from '../../../../shared-component/Loading/Loading';
import { PrimaryButton } from '@assystant/mui-buttons';
import EditModal from './EditModal';
import UserContext from '../../../../../context/UserContext';
import PrimaryBtn from '../../../../shared-component/button-component/PrimaryBtn';



const arr1 = [
    { title: 'Profile', url: '/' },
  ]

const AffiliateProfile= () =>{
    const { exitFromPlatform } = useExit()
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openEditProfile, setOpenEditProfile] = useState(false);

    const { 
      currencies,
      selectedCurrency,
      affiliateOrganizations,
      returnFormattedCurrency
     } = useContext(UserContext);

    const handleOpenEdit = () =>{
      setOpenEditProfile(!openEditProfile)
    }

    const getDetailsForAffiliate = () =>{
        setLoading(true)
        apiService.get(`account/user/get_details/`).then((res) =>{
            if(res?.data !== null){
              const newDetails = {
                ...res?.data
              }
              newDetails.avatar = `${BASE_URI}${newDetails?.avatar}`
              setDetails(newDetails)
            }else{
              setDetails(res?.data)
            }
            
            setLoading(false)
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setLoading(false)
              }
            }
        })
    }

    useEffect(() =>{
        getDetailsForAffiliate()
    }, [])

    const contactDetails = [
        {label:"", 
          value:
          <>
            <Avatar 
                src={`${details?.avatar}`}
                sx={{
                width:'60px',
                height:'60px', 
                background: '#EDECF9'
            }}/>
        </>
        },
        {label:'Name', value: details?.full_name || 'Not Provided'},
        {label:'Email', value: details?.email || 'Not Provided'},
    ]

    const commissionDetails = [
        {label:'Booking Commission', value: details?.booking_commission_type === "percent" ? `${`Fixed Reward - ${details?.booking_commission_rate}%`}` : `${`Fixed Reward - ${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, details?.booking_commission_rate)}`}`},
        {label:'Lead Commission', value: details?.lead_commission_type === "percent" ?  `${`Fixed Reward - ${details?.lead_commission_rate}%`}` : `${`Fixed Reward - ${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, details?.lead_commission_rate)}`}`}
    ]
    
    const otherDetails = [
        {label:'Cookie Duration', value: `${details?.cookie_duration} Days`},
        {label:'Payout Information', value:`${details?.payout_address}`}
    ]
    return(
        <>
          <Box
            sx={{ 
            borderBottom: BoxEffects.borderBottom, 
            borderColor: 'secondary.main', 
            padding: BoxEffects.padding, 
            marginBottom: BoxEffects.marginButton, 
            paddingLeft: BoxEffects.margin, 
            paddingRight: headerButton.marginRigt,position:'sticky',
            top:'65px',
            background:'white',
            zIndex:99
            }}
        >
        <BreadCrumbWithActions propArr={arr1}>
          <div className='flex justify-between items-center'>
            <div style={{
              marginLeft:'20px'
            }}>
            
            <PrimaryBtn 
               title="Edit"
               style={{
                   boxShadow: 'none', 
                   marginRight:'0px !important',
                   fontWeight:300,
                   padding:'6px 16px'
               }}
               disabled={loading}
               onClick={handleOpenEdit}
            />
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>

      {loading ? 
        <Loading />
        :
         <div className='m-6'>
          <GridComponent>
        <Grid item xs={grids.parrentgrid} lg={grids.parrentgrid}>
            <DetailsCard cardHeader='Contact Information'> 
                <Grid item xs={grids.parrentgrid} lg={grids.halfGrid} sx={{paddingLeft:0,paddingRight:0}} >
                    {contactDetails.map((e,i)=>{
                        return(
                            <LabelAndValue 
                              label={e.label} 
                              value={e.value}  
                              valueStyles={{
                                fontFamily: 'Rubik',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28.44px',
                              }}
                            />
                        )
                    })}
                    
                </Grid>
            </DetailsCard>
        </Grid>
        
    </GridComponent>
          </div>}

    {openEditProfile &&(
       <EditModal 
         open={openEditProfile}
         title="Edit Profile"
         onClose={handleOpenEdit}
         data={details}
         successCallback={getDetailsForAffiliate}
       />
    )}
    </>
    )
}

export default AffiliateProfile