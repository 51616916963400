
import React, { useContext, useEffect, useState } from "react";
import { apiService } from "../../../constant/AuthConstant";
import FormModal from "../../shared-component/formmodal-component/FormModal";
import Form from "../../shared-component/formrelated-component/FormManager";
import { useParams } from "react-router-dom";

import { useSnackbar } from "notistack";
import UserContext from "../../../context/UserContext";
import returnError from "../../../utils/ExportError";
import useExit from "../../../utils/useExit";
import Confirm from "../../shared-component/formrelated-component/ConfirmModal/Confirm";


function EditProperty({
    title,
    open,
    onClose,
    data={},
    fetchProperty
}){
    const defaultValues = {
        property_name:'',
        description:'',
        image_list:[],
        price:'',
        token_price: '',
        ...data
    }
const { id } = useParams()
const {enqueueSnackbar} = useSnackbar()
const [loading, setLoading] = useState(false)
const { setFiles,setFilesUrl, filesUrl } = useContext(UserContext)
const { exitFromPlatform } = useExit();
const [isPriceUpdated, setIsPriceUpdated] = useState(false)
const [formUpdates, setFormUpdates] = useState({});
   
    async function handelEditProperty(e) {
       setLoading(true)
       if(e?.property_name === ''){
        enqueueSnackbar('Property name is required!', {variant:'error'})
        setLoading(false)
        return
      }else if(e?.description === ''){
        enqueueSnackbar('Description is required!', {variant:'error'})
        setLoading(false)
        return
      }else if(e?.price === ''){
        enqueueSnackbar('Price is required!', {variant:'error'})
        setLoading(false)
        return
      }
      else{
        if(data?.price !== e?.price){
            const newData = {
                name:e?.property_name,
                description: e?.description,
                images: filesUrl?.map((e)=> e.id) || [],
                price: e?.price,
                token_price: e?.token_price ? e?.token_price : 0,
              }
             setFormUpdates(newData)
             setLoading(false)
             setIsPriceUpdated(true)
        }else{
            const newData = {
                name:e?.property_name,
                description: e?.description,
                images: [],
                price: e?.price
              }
              console.log('payload', newData)
              apiService.put(`property/prop/${id}/`, newData).then((res)=>{
                console.log(res)
                enqueueSnackbar('Property added!', {variant:'success'})
                  setFiles([])
                  setFilesUrl([])
                 
                  setLoading(false)
                  setTimeout(() => {
                    fetchProperty()
                    setIsPriceUpdated(false)
                     onClose()
                  }, 500)
                
              }).catch((error)=>{
                setLoading(false)
                if(error.response?.data){
                    const returnedError = returnError(error.response?.data)
                    if(returnedError === 'Given token not valid for any token type'){
                      exitFromPlatform()
                  }else{
                      enqueueSnackbar(returnedError, {variant:'error'})
                  }
                }
              })
        }
      }
      }

      const onConfirmSubmit = () =>{
        if(formUpdates?.name){
            setLoading(true)
          apiService.put(`property/prop/${id}/`, formUpdates).then((res)=>{
            console.log(res)
            enqueueSnackbar('Property Updated!', {variant:'success'})
              setFiles([])
              setFilesUrl([])
              setLoading(false)
              setTimeout(() => {
                fetchProperty()
                 onClose()
              }, 500)
            
          }).catch((error)=>{
            setLoading(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
              }
            }
          })
        }
      }

    const fieldMap = [
    {
        name:'property_name',
        label:'Property Name',
        placeholder:'Property Name',
        type:'text',
        fieldProps: {
          required: true,
        },
    },
    {
        name:'description',
        label:'Description',
        placeholder:'Description',
        type:'textarea',
        fieldProps: {
          required: true,
        },
    },
    {
        name:'price',
        label:'Base Unit Price',
        placeholder:'Base Unit Price',
        type:'text',
        fieldProps: {
          required: true,
        },
       
    },
    {
      name:'token_price',
      label:'Token Amount',
      placeholder:'Token Amount',
      type:'text',
      fieldProps: {
        required: true,
      },
     
  },
   ]

    return(
        <>
            <FormModal title={title} open={open} onClose={onClose}>
                <Form
                    defaultValues={defaultValues}
                    fieldMap={fieldMap}
                    onCancel={onClose}
                    formSubmit={handelEditProperty}
                    primaryBtnTitle='Update'
                    loading={loading}
                />
            </FormModal>

            {isPriceUpdated && (
                <Confirm 
                   open={isPriceUpdated}
                   close={()=> setIsPriceUpdated(false)}
                   description="The change will be applied to new units."
                   onSubmit={onConfirmSubmit}
                   isSecondary
                />
            )}
        </>
    )
}
export default EditProperty;