import React, { useEffect, useState } from "react";
import Loading from "../../shared-component/Loading/Loading";
import { apiService } from "../../../constant/AuthConstant";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import returnError from "../../../utils/ExportError";
import useExit from "../../../utils/useExit";
import LabelCheckbox from "../../shared-component/formrelated-component/LabelCheckbox";
import { Typography } from "@mui/material";
import ColorSelectionModal from "./Modal/ColorSelectionModal";

function PropertySettings({
    propertyData,
    setPropertyData,
    userFromOrganization
}){
    const { id } = useParams()
    const { exitFromPlatform } = useExit()
    const [openColorSelectionModal, setOpenColorSelectionModal] = useState(false);
    const [loadSelection, setLoadSelection] = useState(false)

    const handleModal = () =>{
        setOpenColorSelectionModal(!openColorSelectionModal)
    }

    const settingList = [
        {
            label:'Show availability only after submission of contact details',
            key:'always_display_availability', 
            checked:propertyData?.always_display_availability
        },
        {
            label:'Show price only after submission of contact details',
            key:'always_display_price',
            checked:propertyData?.always_display_price
        },
        {
            label:'Show inventory in new web',
            key:'display_mode',
            checked:propertyData?.display_mode === 'NEWPAGE' ? true : false
        },
        {
            label: 'Show buy now option',
            key:'buy_now',
            checked: propertyData?.buy_now === true ? true : false
        },
        {
            label: 'Show unit specification after submission of contact details',
            key:'always_display_specification',
            checked: propertyData?.always_display_specification === true ? true : false
        },
        {
            label: 'Show neighbor details in interactive map',
            key:'always_display_neighbor_details',
            checked: propertyData?.always_display_neighbor_details === true ? true : false
        }
    ]

   const [loading, setLoading] = useState(false)
   const [settings, setSettings] = useState(settingList)
   

const updateSettings = (data) =>{
    apiService.patch(`property/prop/${id}/`, data).then((res)=>{
        if(res.data){
            enqueueSnackbar('Settings Updated', {variant:'success'})
            const newData = {
                ...propertyData,
                ...data
            }
            setPropertyData(newData)
        }
       
    }).catch((error)=>{
        if(error.response?.data){
            const returnedError = returnError(error.response.data)
            if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
            }
        }
    })

}



const handleCheckboxChange = (e, val)=>{
    if(val?.key === 'always_display_availability'){
        const newData = settings.map((value,i)=>{
            if(value.key === 'always_display_availability'){
                return{
                    ...value,
                    checked: e.target.checked
                }
            }
            return value
         })
         updateSettings({always_display_availability: e.target.checked})
            setSettings(newData)
    }else if(val?.key === 'always_display_price'){
        const newData = settings.map((value,i)=>{
            if(value.key === 'always_display_price'){
                return{
                    ...value,
                    checked: e.target.checked
                }
            }
            return value
         })
         updateSettings({always_display_price: e.target.checked})
            setSettings(newData)
    }
    else if(val?.key === 'display_mode'){
        const newData = settings.map((value,i)=>{
            if(value.key === 'display_mode'){
                return{
                    ...value,
                    checked: e.target.checked
                }
            }
            return value
         })
         updateSettings({display_mode: e.target.checked === true ? "NEWPAGE" : "POPUP"})
            setSettings(newData)
    }
    else if(val?.key === 'buy_now'){
        const newData = settings.map((value,i)=>{
            if(value.key === 'buy_now'){
                return{
                    ...value,
                    checked: e.target.checked
                }
            }
            return value
         })
         updateSettings({buy_now: e.target.checked})
            setSettings(newData)
    }
    else if(val?.key === 'always_display_specification'){
        const newData = settings.map((value,i)=>{
            if(value.key === 'always_display_specification'){
                return{
                    ...value,
                    checked: e.target.checked
                }
            }
            return value
         })
         updateSettings({always_display_specification: e.target.checked})
            setSettings(newData)
    }
    else if(val?.key === 'always_display_neighbor_details'){
        const newData = settings.map((value,i)=>{
            if(value.key === 'always_display_neighbor_details'){
                return{
                    ...value,
                    checked: e.target.checked
                }
            }
            return value
         })
         updateSettings({always_display_neighbor_details: e.target.checked})
            setSettings(newData)
    }
  }

  useEffect(() =>{
    console.log('from settings', settings)
  }, [settings])

  const onSelectionSubmit = (data) =>{
    setLoadSelection(true)
    apiService.patch(`property/prop/${id}/`, data)
    .then((res) =>{
        if(res.data){
            enqueueSnackbar('Colors Updated', {variant:'success'})
            setLoadSelection(false)
            handleModal()
        }
    }).catch((error)=>{
        if(error.response?.data){
            const returnedError = returnError(error.response.data)
            if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
                setLoadSelection(false)
            }
        }
    })
  }

    return(
            loading ? <Loading /> 
            :

           <>
            <div className="flex flex-col">
                <div className="mb-8">
                     <Typography className="!mb-6" sx={{
                        fontFamily: 'Rubik',
                        fontSize: '24px',
                        fontWeight: 400,
                        lineHeight: '28.44px',
                        color:'#353349'
                     }}>
                          Campaign Gate Settings
                     </Typography>
                    <div className="flex flex-col">
                        {settings.map((val,i)=>{
                        return(
                            <LabelCheckbox 
                            handleCheckbox={handleCheckboxChange}
                            label={val.label} 
                            defaultChecked={val.checked}
                            row={val}
                            disabled={userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
                            userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true}
                            />
                        )
                    })}
                    </div>
                </div>
                <div>
                  <Typography className="!mb-6" sx={{
                        fontFamily: 'Rubik',
                        fontSize: '24px',
                        fontWeight: 400,
                        lineHeight: '28.44px',
                        color:'#353349'
                     }}>
                         Inventory Color Settings
                     </Typography>
                    <div className="flex flex-col cursor-pointer" style={{ width: '215px'}} onClick={handleModal}>
                    <Typography sx={{
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '23.7px',
                        color:'#004FE8'
                     }}>
                          Available and Sold Unit Color
                     </Typography>
                    </div>
                </div>
            </div>
            <ColorSelectionModal 
              propertyData={propertyData}
              title="Available & Sold Unit Color"
              open={openColorSelectionModal}
              handleClose={handleModal}
              onSubmit={onSelectionSubmit}
              loading={loading}
              disabled={userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
              userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true}
             />
           </>
    )
}
export default PropertySettings;