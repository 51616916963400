import React from 'react';

const PinterestIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0546 28.7201C13.3346 29.1067 14.628 29.3334 16.0013 29.3334C19.5375 29.3334 22.9289 27.9287 25.4294 25.4282C27.9299 22.9277 29.3346 19.5363 29.3346 16.0001C29.3346 14.2491 28.9898 12.5153 28.3197 10.8976C27.6496 9.27996 26.6675 7.81011 25.4294 6.57199C24.1913 5.33388 22.7214 4.35175 21.1037 3.68169C19.4861 3.01162 17.7523 2.66675 16.0013 2.66675C14.2503 2.66675 12.5165 3.01162 10.8989 3.68169C9.28118 4.35175 7.81133 5.33388 6.57321 6.57199C4.07273 9.07248 2.66797 12.4639 2.66797 16.0001C2.66797 21.6667 6.22797 26.5334 11.2546 28.4534C11.1346 27.4134 11.0146 25.6934 11.2546 24.5067L12.788 17.9201C12.788 17.9201 12.4013 17.1467 12.4013 15.9201C12.4013 14.0801 13.548 12.7067 14.8546 12.7067C16.0013 12.7067 16.5346 13.5467 16.5346 14.6267C16.5346 15.7734 15.7746 17.4134 15.388 18.9867C15.1613 20.2934 16.0813 21.4401 17.4146 21.4401C19.788 21.4401 21.628 18.9067 21.628 15.3334C21.628 12.1334 19.3346 9.94675 16.0413 9.94675C12.2813 9.94675 10.068 12.7467 10.068 15.6934C10.068 16.8401 10.4413 18.0001 11.0546 18.7601C11.1746 18.8401 11.1746 18.9467 11.1346 19.1467L10.748 20.6001C10.748 20.8267 10.6013 20.9067 10.3746 20.7467C8.66797 20.0001 7.6813 17.5734 7.6813 15.6134C7.6813 11.4001 10.668 7.57341 16.428 7.57341C21.0146 7.57341 24.588 10.8667 24.588 15.2401C24.588 19.8267 21.748 23.5067 17.6813 23.5067C16.388 23.5067 15.1213 22.8134 14.668 22.0001L13.7746 25.1601C13.468 26.3067 12.628 27.8401 12.0546 28.7601V28.7201Z"
        fill="#004FE8"
      />
    </svg>
  );
};

export default PinterestIcon;
