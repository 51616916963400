import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const Loading = ({
  label = "",
  className = "",
}) => {
  return (
    <div className={`flex flex-col justify-center items-center ${className}`} style={{width:'95%', height:'80vh'}}>
        <CircularProgress />
        <span className='my-4 font-normal'>{label === "" ? "Loading..." : label}</span>
    </div>
  )
}

export default Loading