// ----------------- Documentation ---------------------------
// this component used for add and edit the data from property type list

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup

import React, { useState } from "react";
import FormModal from "../../../shared-component/formmodal-component/FormModal";
import Form from "../../../shared-component/formrelated-component/FormManager";
import { useSnackbar } from "notistack";
import axios from "axios";
import { TokenStorage } from "../../../../constant/AuthConstant";
import returnError from "../../../../utils/ExportError";
import useExit from "../../../../utils/useExit";
 
 


function EditOrganization({
    title,
    open,
    onClose,
    data,
    currencies,
    successCallback,
}){
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const { exitFromPlatform } = useExit()
    
    const handleformSubmit = async (formD) => {
      if(formD?.legal_name === ''){
          enqueueSnackbar('Legal name is required', { variant: 'error' })
      }
      else if(formD?.name === ''){
        enqueueSnackbar('Company name is required', { variant: 'error' })
      }
      else if(formD?.email === ''){
        enqueueSnackbar('Email is required', { variant: 'error' })
      }
      else if(formD?.default_currency === ''){
        enqueueSnackbar('Currency is required', { variant: 'error' })
      }
      else{
        setLoading(true)
        const formData = new FormData();
        if(formD.brand_logo?.name){
        formData.append('brand_logo', formD.brand_logo);
        formData.append('legal_name', formD.legal_name)
        formData.append('name', formD.name)
        formData.append('email', formD.email)
        formData.append('address', formD.address)
        formData.append('phone_number', formD.phone_number)
        formData.append('description', formD.description)
        formData.append('default_currency', formD?.default_currency?.currencyCode ? formD?.default_currency?.currencyCode : formD?.default_currency)
        }else{
        formData.append('legal_name', formD.legal_name)
        formData.append('name', formD.name)
        formData.append('email', formD.email)
        formData.append('address', formD.address)
        formData.append('phone_number', formD.phone_number)
        formData.append('description', formD.description)
        formData.append('default_currency', formD?.default_currency?.currencyCode ? formD?.default_currency?.currencyCode : formD?.default_currency)
        }
        axios({
            method: formD?.brand_logo?.name ? "put" : "patch",
            url: `${process.env.REACT_APP_BASE_URL}organization/org/${data?.id}/`,
            data: formData,
            headers: { 
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${TokenStorage.getToken()}`
             },
          }).then((response)=>{
        console.log('from org subs', response)
        enqueueSnackbar('Organization updated', { variant: 'success'})
        successCallback(response.data)
        setLoading(false)
        onClose()
       }).catch((error)=>{
        setLoading(false)
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
          }
          }
       })
      }
    }

    const defaultValues={
        name:'',
        brand_logo:'',
        description:'',
        email:'',
        phone_number:'',
        address:'',
        legal_name:'',
        default_currency:''
    }
    const fieldMap=[
            {
                name:'',
                label:'General Information',
                type:'box_layout',
                fieldProps:{
                    arrayFields:[
                        {
                            name:"brand_logo",
                            type:'avtar',
                        },
                        {
                            name:'legal_name',
                            label:'Legal Name',
                            placeholder:'Legal Name',
                            type:'text',
                            fieldProps: {
                                required: true
                              }
                        },
                        {
                            name:'name',
                            label:'Company Name',
                            placeholder:'Brand Name',
                            type:'text',
                            fieldProps: {
                                required: true
                              }
                        },
                        {
                            name:'description',
                            label:'Description',
                            placeholder:'Description',
                            type:'text',
                        },
                        {
                            name:'default_currency',
                            label:'Currency',
                            placeholder:'Description',
                            type:'select',
                            fieldProps: {
                                defaultOptions: currencies,
                                titleKey: 'currencyCode',
                                required: true
                            }
                        },
                    ]
                }
            },
            {
                name:'',
                label:'Contact Details',
                type:'box_layout',
                fieldProps:{
                    arrayFields:[
                        {
                            name:'email',
                            label:'Email ID',
                            placeholder:'example@gmail.com',
                            type:'text',
                            fieldProps: {
                                required: true
                            }
                        },
                        {
                            name:'phone_number',
                            label:'Phone',
                            placeholder:'XXXXXXXXXX',
                            type:'text',
                        },
                        {
                            name:'address',
                            label:'Address',
                            placeholder:'Address',
                            type:'text',
                        },
                       
                       
                       
                    ]
                }
            },
           
    ]

    return(
        <>
        <FormModal title={title} open={open} onClose={onClose}>
           <div className="mt-6">
            <Form
                defaultValues={defaultValues}
                fieldMap={fieldMap}
                onCancel={onClose}
                primaryBtnTitle="Save"
                data={data}
                formSubmit={handleformSubmit}
                loading={loading}
                />
           </div>
        </FormModal>
        </>
    )
}
export default EditOrganization;