import { Typography } from '@mui/material'
import React from 'react'

const Legends = ({
    color,
    title
}) =>{
    return(
        <div className='flex items-center my-2.5'>
            <div className={`w-4 h-4 mx-2`} style={{
                background: `${color}`
            }}></div>
            <Typography
             sx={{
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '18.96px',
                letterSpacing: '-0.022em',
             }}
            >{title}</Typography>
        </div>
    )
}

export default Legends