import React from 'react'
import { Box, Grid, MenuItem } from '@mui/material';
import { grids } from '../../../Config/Constants';
import DetailsCard from '../../shared-component/card-component/DetailsCard';
import GridComponent from '../../shared-component/grid-component/GridComponent';
import Status from '../../shared-component/statusDropDown/Status';
import { useMediaQuery } from 'react-responsive';
import LabelAndValue from '../../shared-component/OverviewPage/overview-used-components/LabelAndValue';
import EmptyCard from '../../shared-component/card-component/EmptyCard';
import ProfileContainer from '../../shared-component/table-component/Row/ProfileContainer';

const BookingDetailComponent = ({
    booking,
    setBooking,
    neighbourIcon,
    updateBooking,
    handleOpenEditModal,
    isReadOnly = false
}) =>{
    const isMidScreen = useMediaQuery({ query: '(max-width: 1199px)' });
    const bookingDetails = [
        {label:'Property', value: booking?.property?.name ? booking?.property?.name : "Not Provided"},
        {label:'Unit', value: booking?.unit?.name ? booking?.unit?.name : "Not Provided"},
        {label:'Source', value: booking?.booking_source?.id ? <ProfileContainer profile={booking?.booking_source} /> : (typeof booking?.booking_source === 'string' ? booking?.booking_source : "Not Provided")},
{label: 'Updated By', value: booking?.source === 'USER_SUBMITTED' && booking?.employee?.id ? (
            <ProfileContainer profile={booking?.employee} />
         ) : booking?.source === 'USER_SUBMITTED' ? (
            <div
            className='w-56'
            style={{
              background: '#24D315',
              borderRadius: '25px',
              padding: '5px 10px',
              color: '#fff',
              textAlign: 'center',
              fontFamily: 'Rubik',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: 'normal',
            }}
          >
            {`User Completed`}
          </div>
        ) : (
          <div
            className='w-56'
            style={{
              background: '#FAD200',
              borderRadius: '25px',
              padding: '5px ',
              color: '#fff',
              textAlign: 'center',
              fontFamily: 'Rubik',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: 'normal',
            }}
          >
            {`AI Found`}
          </div>
        )}
    ]

   console.log('from booking', booking)
    return(
        booking?.id ?
        <GridComponent>
            <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid}>
            <DetailsCard
                cardHeader="Unit Details"
                style={{
                minWidth: '100px',
                }}
            >
                <Grid container spacing={0} columns={grids.parrentgrid}>
               
                {bookingDetails?.map((e, i) =>{
                    return (
                        <Grid
                        item
                        key={i}
                        xs={grids.parrentgrid}
                        lg={grids.halfGrid}
                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                        <LabelAndValue
                        label={e?.label}
                        value={e?.value}
                        />
                    </Grid>
                    )
                })}
                </Grid>
            </DetailsCard>
            <Box sx={{ marginTop: '20px' }}>
                <DetailsCard cardHeader="Applicant Details">
                <Grid container spacing={0} columns={grids.parrentgrid}>
                    <Grid
                    item
                    xs={grids.parrentgrid}
                    lg={grids.halfGrid}
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                    <LabelAndValue label={'Name'} value={booking?.name || "Not Provided"} />
                    </Grid>

                    <Grid
                    item
                    xs={grids.parrentgrid}
                    lg={grids.halfGrid}
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                    <LabelAndValue label={'Phone'} value={booking?.phone || "Not Provided"} />
                    </Grid>

                    <Grid
                    item
                    xs={grids.parrentgrid}
                    lg={grids.halfGrid}
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                    <LabelAndValue label={'Email'} value={booking?.email || "Not Provided"} />
                    </Grid>

                    <Grid
                    item
                    xs={grids.parrentgrid}
                    lg={grids.halfGrid}
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                    <LabelAndValue
                        label={'Current Address'}
                        value={booking.address || "Not Provided"}
                    />
                    </Grid>
                </Grid>
                </DetailsCard>
            </Box>
            </Grid>

            <Grid
            item
            xs={grids.childgrid}
            lg={grids.childgrid}
            className={isMidScreen ? '' : '!pl-5'}
            sx={
                isMidScreen
                ? {
                    maxWidth: '50% !important',
                    }
                : {}
            }
            >
            <Box
                sx={{ marginBottom: '20px' }}
                className="flex justify-start items-center gap-[8px]"
            >
                <div className="mr-2 w-full">
                <Status
                    unitStatus={booking.booking_status || 'inactive'}
                    successStatus={['opened', 'confirmed']}
                    handleStatusChange={(e) => {
                    console.log(e);
                    setBooking({...booking, booking_status: e.target.value})
                    updateBooking({...booking, booking_status: e.target.value})
                    }}
                    disabled={isReadOnly || booking?.source === 'USER_DRAFT'}
                    style={{
                    paddingTop: '27px',
                    paddingBottom: '30px',
                    // width: '140px',
                    }}
                >
                    <MenuItem value='opened' sx={{color:'#24D315 !important', fontWeight:300,}} >Booking Received</MenuItem>
                    <MenuItem value='confirmed' sx={{color: '#24D315 !important', fontWeight: 300}}>Booking Confirmed</MenuItem>
                    <MenuItem value='inactive' sx={{color:'#FF5C5C !important', fontWeight:300,}}>Booking Cancelled</MenuItem>
                    <MenuItem value='waitlist' sx={{color:'#FF5C5C !important', fontWeight: 300}}>Waitlist</MenuItem>
                </Status>
                </div>
                <div className="w-full">
                <Status
                    unitStatus={booking.payment_status || 'unpaid'}
                    successStatus='paid'
                    handleStatusChange={(e) => {
                    console.log(e);
                    setBooking({...booking, payment_status: e.target.value})
                    updateBooking({...booking, payment_status: e.target.value});
                    }}
                    style={{
                    paddingTop: '27px',
                    paddingBottom: '30px',
                    // width: '140px',
                    }}
                    disabled={isReadOnly || booking?.source === 'USER_DRAFT'}
                >
                    <MenuItem
                    value="paid"
                    sx={{ color: '#24D315 !important', fontWeight: 300 }}
                    >
                    Paid
                    </MenuItem>
                    <MenuItem
                    value="unpaid"
                    sx={{ color: '#FF5C5C !important', fontWeight: 300 }}
                    >
                    Unpaid
                    </MenuItem>
                </Status>
                </div>
            </Box>
                {booking?.profile_icon && booking?.booking_status === "confirmed" && (
                <DetailsCard 
                cardHeader="Neighbour setting"
                isAdditionalButton={isReadOnly ? false : true}
                onButtonClick={handleOpenEditModal}
                >
                <Grid container spacing={0} columns={grids.parrentgrid}>
                    <div className='flex items-center w-full h-16'>
                        <div className='bg-[#EDECF9] w-12 h-12 rounded-full flex items-center justify-center'>
                        {neighbourIcon?.icon}
                        </div>
                        <div className='ml-2 flex items-center'>
                        <LabelAndValue
                            label={'Anonymous User'}
                            value={booking?.profile || "Not Provided"}
                        />
                        </div>
                    </div>
                </Grid>
            </DetailsCard>
                )}
            </Grid>
              
        </GridComponent>
        :
        <EmptyCard title='Booking details are not available' />
    )
}

export default BookingDetailComponent
