import React from "react";
import FormModal from "../../shared-component/formmodal-component/FormModal";
import Form from "../../shared-component/formrelated-component/FormManager";

function ShowFields({
    title,
    open,
    onClose,
}){

    const propetyvalue= {
        fields_template:'',
        unit_name:'',
        unit_price:'',
        total_sqft:'',
        block:'',
        floor:'',
        facing:'',
        unit_type_opt:'',
        carpet_area_opt:'',
        balcony_opt:'',
        bathroom_opt:'',
        color:'',
        balcony_over_looking_opt:''
    }
    const defaultValues={
        fields_template:'',
        unit_name:'',
        unit_price:'',
        total_sqft:'',
        block:'',
        floor:'',
        facing:'',
        unit_type_opt:'',
        carpet_area_opt:'',
        balcony_opt:'',
        bathroom_opt:'',
        color:'',
        balcony_over_looking_opt:'',
        ...propetyvalue,
    }
    const fieldMap=[
        {
            name:'fields_template',
            label:'Fields template',
            placeholder:'Fields template',
            type:'select',
            cols: 2,
            fieldProps:{
                defaultOptions: [
                    {title:'Fields template', id:''},
                ]
            }
        },
        {
            name:'unit',
            label:'Select all fields',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'unit_name',
            label:'Unit Name',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'unit_price',
            label:'Unit Price',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'total_sqft',
            label:'Total Sqft.',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'block',
            label:'Block',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'floor',
            label:'Floor',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'facing',
            label:'Facing',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'unit_type_opt',
            label:'Unit Type (Optional)',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'carpet_area_opt',
            label:'Carpet Area (Optional)',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'balcony_opt',
            label:'Balcony (Optional)',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'bathroom_opt',
            label:'Bathroom (Optional)',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'color',
            label:'Color',
            type:'checkbox',
            cols: 2,
           
        },
        {
            name:'balcony_over_looking_opt',
            label:'Balcony Over Looking (Optional)',
            type:'checkbox',
            cols: 2,
           
        },
    ]
    return(
        <>
            <FormModal title={title} open={open} onClose={onClose}>
                <Form
                defaultValues={defaultValues}
                fieldMap={fieldMap}
                primaryBtnTitle='Apply'
                />
            </FormModal>
        </>
    )
}
export default ShowFields;