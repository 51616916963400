// -----------Documentation-------------
// Forgot Password component used for forgot password

// props           type                    usedfor
// -------         -------                 ---------
// companyLogo     path                    logo image for authantication page
// publicSideImage path                    logo image for side authantication page
// fields          array                   array for fields
// formValues      object                  for formvalues
// onSubmit        function                for submit useCallback
// actionTitle     string                  action button label
// pageTitle       string                  label for forms


import React, { useContext, useState } from "react";
import SpotMink from '../../../asset/Images/SpotMink.png'
import PublicGroup from '../../../asset/Images/PublicGroup.png'
import AuthLayout from "../../shared-component/AuthLayoutPage/AuthLayout";
import userContext from "../../../context/UserContext";
import { apiService } from "../../../constant/AuthConstant";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useSnackbar } from "notistack";
import returnError from "../../../utils/ExportError";
import useExit from "../../../utils/useExit";

function CreateNewPassword(){
    console.log('rendered')
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const { userResetpassword } = useContext(userContext);
    const { exitFromPlatform } = useExit()
    const formValues={
        new_password:'', re_new_password:'',
    }
    const schema = yup.object().shape({
        new_password: yup
          .string()
          .required('Please enter your password.')
          .min(8, 'Must contain min 8 characters with alphabets and numbers')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
            "Must contain min 8 characters with alphabets and numbers"
          ),
          re_new_password: yup.string().test('passwords-match', 'Passwords must match', function (value) {
          return this.parent.new_password === value;
        }),
      });
    const field = [
        {id:1,label:'New Password', type:"password", value:'',name:'new_password'},
        {id:2,label:'Confirm New Password', type:"password", value:'',name:'re_new_password'},
    ]
    function onSubmit(data){
        userResetpassword(data.email)
      }

      const onSubmitForChange = (data) =>{
        setLoading(true)
        const em = localStorage.getItem("forgot-email")
        console.log({
            ...data,
            email: JSON.parse(em).email
        })
        apiService.post('account/change-password/', {
            ...data,
            email: JSON.parse(em).email
        }).then((response)=>{
            setLoading(false)
            enqueueSnackbar("Password changed successfully", {variant:'success'})
            setTimeout(() =>{
                navigate('/')
            },2000)
        }).catch((error)=>{
            setLoading(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
        })
    }
    return(
        <>
            <AuthLayout 
            companyLogo={SpotMink} 
            publicSideImage={PublicGroup} 
            fields={field} 
            formValues={formValues} 
            onSubmit={onSubmitForChange} 
            actionTitle='Submit'
            pageTitle='Change Password'
            schema={schema}
            isSubmitting={loading}
            // checkboxAction
            /> 
        </>
    )
}
export default CreateNewPassword;