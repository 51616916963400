import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { themes } from "../../../Config/themesConfig";
const DeleteButton = ({ onCancel, className, iconClassName = "" }) =>{
    return(
        <>
         <IconButton className={className} onClick={onCancel} size="small" sx={{
          boxShadow:'none'
          }}>
            <DeleteOutlineOutlinedIcon className={iconClassName} htmlColor={themes.palette.error.main} />
          </IconButton>
        </>
    )
}
export default DeleteButton