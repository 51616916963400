import React, { useCallback } from 'react'
import { 
    TextField,
 } from '@mui/material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '../../../../../asset/icons/DeleteIocn';


const DraggableComponent = ({
    list,
    index,
    fieldItem,
    formState,
    setFormState,
    deleteOptions,
    sectionIndex,
    isSection,
}) =>{

    const handleChange = (newValue, fieldIndex) => {
       if(isSection === true){
        let cloned = {...formState}
        cloned = {
        ...cloned,
        sections: cloned?.sections?.map((s, i) =>{
            if(i === sectionIndex){
            return {
                ...s,
                fields: s?.fields?.map((f, o) =>{
                    if(o === index){
                        return {
                          ...f,
                          field_options: f?.field_options?.map((option, ind) =>{
                            if(ind === fieldIndex){
                              return {
                                ...option,
                                value: newValue?.target?.value
                              }
                            }else{
                              return option
                            }
                          })
                        }
                      }else{
                        return f
                      }
                })
            }
            }else{
                return s
            }
        })
      }
      setFormState(cloned)
       }else{
        let cloned = {...formState};
        
        cloned = {
          ...cloned,
          fields: cloned?.fields?.map((e, i) =>{
            if(i === index){
              return {
                ...e,
                field_options: e?.field_options?.map((option, ind) =>{
                  if(ind === fieldIndex){
                    return {
                      ...option,
                      value: newValue?.target?.value
                    }
                  }else{
                    return option
                  }
                })
              }
            }else{
              return e
            }
          })
        }

        setFormState(cloned)
       }
    };
    
    function handleOnDragEnd(result) {
      if (!result.destination) return;
      if(isSection === true){
        const items = Array.from(formState?.sections[sectionIndex]?.fields[index]?.field_options);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        

        let cloned = {...formState}
        cloned = {
        ...cloned,
        sections: cloned?.sections?.map((s, i) =>{
            if(i === sectionIndex){
            return {
                ...s,
                fields: s?.fields?.map((f, o) =>{
                    if(o === index){
                        return {
                          ...f,
                          field_options: items
                        }
                      }else{
                        return f
                      }
                })
            }
            }else{
                return s
            }
        })
      }
      setFormState(cloned)
      }
      else{
        const items = Array.from(formState?.fields[index]?.field_options);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        let cloned = {...formState};
        cloned = {
            ...cloned,
            fields: cloned?.fields?.map((e, i) =>{
              if(index === i){
                return {
                  ...e,
                  field_options: items
                }
              }else{
                return e
              }
            })
        }
        setFormState(cloned)
      }
    }

    const renderDraggableList = useCallback(() =>{
        return (
        <DragDropContext 
            onDragEnd={handleOnDragEnd}
            >
            <Droppable droppableId={`droppable-${index}`}>
                {(droppableProvider) => (
                    <>
                    <ul className="getStage" {...droppableProvider.droppableProps} ref={droppableProvider.innerRef}>
                         {list?.map((field, i) =>{
                            return (
                            <Draggable key={field.id} draggableId={field.id} index={index}>
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex items-center mx-4"
                              >
                                <DragIndicatorIcon className='flex items-center m-4 !fill-[#B8B7C5]'/>
                                 <TextField
                                   defaultValue={field?.value} 
                                   onChange={(e) =>{
                                      handleChange(e, i)
                                   }}
                                   sx={{
                                    background: 'white'
                                   }}
                                 />
                                 <div className='flex items-center mx-2 cursor-pointer' onClick={() =>{
                                       deleteOptions(fieldItem, field)
                                 }}>
                                    <DeleteIcon />
                                 </div>
                                 {provided.placeholder}
                               </li>
                             )}
                            </Draggable>
                            )
                         })}
                         {droppableProvider.placeholder}
                    </ul>
                    </>
                )}
              </Droppable>
        </DragDropContext>
        )
    }, [list])

    return renderDraggableList()
}

export default DraggableComponent
