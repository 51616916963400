import React from 'react';

const PercentIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 4L4 20" stroke={color} stroke-width="2" />
      <path
        d="M6.5 11C8.433 11 10 9.433 10 7.5C10 5.567 8.433 4 6.5 4C4.567 4 3 5.567 3 7.5C3 9.433 4.567 11 6.5 11Z"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M17.5 20C19.433 20 21 18.433 21 16.5C21 14.567 19.433 13 17.5 13C15.567 13 14 14.567 14 16.5C14 18.433 15.567 20 17.5 20Z"
        stroke={color}
        stroke-width="2"
      />
    </svg>
  );
};

export default PercentIcon;
