// ----------------- Documentation ---------------------------
// this component used for show the label and value pair for the imtegration page


import { Box, Typography } from "@mui/material";
import React from "react";

function IntegrationContent({
    label='',
    value='',
}){
    return(
        <>
            <Box>
                <Typography>
                    {label}
                </Typography>
                <Typography color='secondary'>
                    {value}
                </Typography>
            </Box>
            <br />
        </>
    )
}
export default IntegrationContent;