import React from 'react'
import FormModal from '../../../../shared-component/formmodal-component/FormModal'
import { Typography } from '@mui/material'


const doc = [
    {
        title:'container-root',
        content:'This class represents the root container element of a structured component. It ensures that the container has a minimum height of 75 pixels, providing a base size for its content.'
    },
    {
        title:'container-header',
        content:'This class defines the styling for the header section of the container. It utilizes flexbox to center its contents both vertically and horizontally, creating a visually appealing header with a height of 30 pixels.'
    },
    {
        title:'container-header-title',
        content:'This class styles the title text within the containers header. It uses the Rubik font family and specifies various text properties such as font weight, size, color, and alignment to ensure the title is legible and aesthetically consistent.'
    },
    {
        title:'container-body',
        content:'This class defines the styles for the main body content of the container. It sets the font family, weight, and size, as well as margin properties to create an appropriate layout for displaying content within the container body.'
    },
    {
        title:'container-body-group',
        content:`This class represents a group of content within the container's body. It utilizes CSS grid to structure the content into two columns, with the first column occupying 70% of the width and the second column adjusting automatically.`
    },
    {
        title:'container-body-group-label',
        content:`These classes style the labels and values within the body group respectively. They ensure consistent font styles and spacing for displaying key-value pairs or similar structured data.`
    },
    {
        title:'container-separator',
        content:`This class defines the styling for a separator element within the container, creating a horizontal line to visually separate different sections of the container's content.`
    },
    {
        title:'container-footer',
        content:'This class represents the footer section of the container. Similar to the header, it utilizes flexbox to center its contents vertically and horizontally, providing a height of 35 pixels for displaying additional information or actions related to the container content.'
    },
    {
        title:'container-footer-title',
        content:`This class styles the label text within the container's footer. It ensures that the label text is legible against the footer background and maintains consistency with the overall design of the container.`
    }
]

const jsDoc = [
    {
        title:'spotminkscriptinitialized',
        content:`This event occurs when the script associated with a webpage or application is successfully initialized, indicating that it's ready to execute further actions or respond to user interactions.`
    },
    {
        title:'spotminkpreapicall',
        content:`This event happens just before an application programming interface (API) call is made. It's typically used for setting up any necessary configurations or parameters before sending the request to the API endpoint.`
    },
    {
        title:'spotminkapifailure',
        content:`This event occurs when an API call made by the application encounters an error or fails to complete successfully. It often triggers error handling routines or messages to inform users about the issue.`
    },
    {
        title:'spotminkapisuccess',
        content:`This event signifies the successful completion of an API call. It indicates that the requested data or operation was executed without encountering any errors, allowing the application to proceed with processing the API response.`
    },
    {
        title:'spotminkpremaprender',
        content:`This event occurs before a map component is rendered on a webpage or within an application. It's commonly used for setting up map configurations or preparing data to be displayed on the map.`
    },
    {
        title:'spotminkpostmaprender',
        content:`This event happens after a map component has been successfully rendered on the webpage or within the application. It may trigger additional actions or interactions related to the displayed map, such as adding markers or updating map layers.`
    },
    {
        title:'spotminkpopovershown',
        content:`This event occurs when a popover or tooltip element is displayed on the screen, often in response to a user action like hovering over a specific UI element. It's typically used for executing actions or animations related to the popover display.`
    },
    {
        title:'spotminkpopoverhidden',
        content:`This event happens when a popover or tooltip element is hidden or closed, either due to a user action or a predefined time-out. It's commonly used for cleanup tasks or resetting the UI state after the popover is no longer visible.`
    },
    {
        title:'spotminkctacall',
        content:`This event occurs when a "Express Intresent" (CTA) element, such as a button, is clicked by the user. It's used to trigger actions or processes associated with the CTA, such as submitting a form, navigating to another page, or initiating a transaction.`
    },
    {
        title:'spotminkformsubmission',
        content:`This event occurs when a form is submitted by the user, typically by clicking a submit button. It's used to process the form data, validate input, and perform any necessary actions, such as saving data to a database or triggering further interactions based on the submitted information.`
    },
]

const Documentation = ({
    open,
    title,
    onClose
}) => {
    return (
       <FormModal
         open={open}
         title={title}
         onClose={onClose}
       >
         <div className='m-4 h-96 overflow-auto'>
             <div className='mb-8'>
             <Typography sx={{
                fontFamily:'Rubik',
                fontSize:'16px',
                fontWeight:600,
                lineHeight:'19px',
             }}>CSS</Typography>
             <div className='my-4'>
             <Typography sx={{
                fontFamily:'Rubik',
                fontSize:'14px',
                fontWeight:600,
                lineHeight:'19px',
            }}>Developers can add these classes to their CSS to override the default styles.</Typography>
             </div>
            <div className='my-4'>
                {doc.map((item, index) =>{
                    return (
                        <div className='my-4'>
                            <Typography sx={{
                                fontFamily:'Rubik',
                                fontSize:'14px',
                                fontWeight:500,
                                lineHeight:'19px',
                            }}>{item?.title}</Typography>
                            <Typography sx={{
                                fontFamily:'Rubik',
                                fontSize:'12px',
                                fontWeight:300,
                                lineHeight:'17px',
                            }}>{item?.content}</Typography>
                        </div>
                    )
                })}
               </div>
             </div>
             <div className='mb-8'>
             <Typography sx={{
                fontFamily:'Rubik',
                fontSize:'16px',
                fontWeight:500,
                lineHeight:'19px',
             }}>Javascript</Typography>
             <div className='my-4'>
             <Typography sx={{
                fontFamily:'Rubik',
                fontSize:'14px',
                fontWeight:500,
                lineHeight:'19px',
            }}>These are the custom events to customize the script according to the developer's need.</Typography>
             </div>
            <div className='my-4'>
                {jsDoc.map((item, index) =>{
                    return (
                        <div className='my-4'>
                            <Typography sx={{
                                fontFamily:'Rubik',
                                fontSize:'14px',
                                fontWeight:500,
                                lineHeight:'19px',
                            }}>{item?.title}</Typography>
                            <Typography sx={{
                                fontFamily:'Rubik',
                                fontSize:'12px',
                                fontWeight:300,
                                lineHeight:'17px',
                            }}>{item?.content}</Typography>
                        </div>
                    )
                })}
               </div>
             </div>
         </div>
       </FormModal>
    )
}

export default Documentation