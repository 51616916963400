import React, { useEffect, useState } from 'react'
import FormModal from '../../../shared-component/formmodal-component/FormModal'
import LabelCheckbox from '../../../shared-component/formrelated-component/LabelCheckbox'
import { SecondaryButton, PrimaryButton } from '@assystant/mui-buttons'
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn'
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn'


const ManageColumns = ({
    open,
    fields,
    options,
    onClose,
    setFields,
    handleAllColumns,
    handleCheckboxChange,
}) =>{

    const updateFields = (fields) =>{
      const allFields = [...fields];
     const newFields = allFields.map((e) =>{
        return {
            ...e,
            checked: true
        }
      })
      setFields(newFields)
    }
    
    return (
        <FormModal title={"Manage Columns"} onClose={onClose} open={open}>
             <div className='flex flex-col m-8'>
                <div className='mb-4' style={{
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '18.96px',
                        color:'#004FE8',
                        cursor:'pointer'
                }} onClick={() =>{
                    updateFields(fields)
                }}>Select all Fields</div>
                <div className='flex flex-col'>
                {fields.map((val,i)=>{
                        return(
                            <LabelCheckbox 
                                handleCheckbox={handleCheckboxChange}
                                label={val.label} 
                                defaultChecked={val.checked}
                                row={val}
                                styles={{
                                    fontFamily: 'Rubik',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '18.96px',
                                    color:'#8E8CA3'
                                }}
                            />
                        )
                    })}
                </div>
                <div className='flex mt-4 justify-between'>
                    <div></div>
                    <div className='flex'>
                        <SecondaryBtn 
                        title='Cancel' 
                        onClick={onClose}
                        style={{ 
                            backgroundColor: '#EDECF9',
                            boxShadow: 'none', 
                            fontWeight:300,
                            padding:'6px 16px'}}
                        />
                        <PrimaryBtn 
                        title='Apply'
                        style={{ 
                            boxShadow: 'none', 
                            fontWeight:300,
                            padding:'6px 16px',
                            marginRight: '0px !important'
                        }}
                        onClick={() =>{
                          console.log('from fields', fields)
                          handleAllColumns(fields)
                        }}
                        />
                    </div>
                </div>
             </div>
             
        </FormModal>
    )
}

export default ManageColumns