import React, { useState } from 'react';
import { Checkbox, Typography } from '@mui/material';


function RegularCheckbox({ 
  onClick = () => { }, 
  defaultChecked, 
  disabled = false, 
  inputProps, 
  title, 
  testid,
  checked,
  row,
  handleChange,
  isSettings,
  istable
 }) {
   const [isChecked, setIsChecked] = useState(defaultChecked)
  return (
   
    <div className="flex justify-start items-center">
    
  <input onClick={(e)=> {
    e.stopPropagation()
   if(onClick){
    onClick(row)
   }
  }} style={{height:'18px', width:'18px'}} className='m-2 ' type='checkbox' defaultChecked={checked} checked={checked} onChange={(e) =>{
    e.stopPropagation()
    if(isSettings){
      handleChange(e, row)
    }else if(istable){
      onClick(row, e)
    }
    else{
      onClick(e)
    }
  }}  disabled={disabled} />
      <div onClick={() =>{
        if(isSettings){
          setIsChecked(!isChecked)
          handleChange({target:{checked: !isChecked}}, row)
        }
      }}>
      <Typography className="flex justify-center text-14 items-center cursor-pointer" sx={{
        fontFamily: 'Rubik',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '24px',
        letterSpacing: '0em',
        textAlign: 'left'
        
      }} 
     
      >
       {title}
      </Typography>
      </div>
    </div>
  );
}

export default RegularCheckbox;