export const renderScript = (uuid, url) =>{
    return `<div id="spotmink_container" style="width: 100%;position: relative;"> 
         </div> 
         <script defer src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script> 
<script defer> 
var pagePath = "heritage/property-unit/?property=${uuid}"; 
var containerId = 'spotmink_container'; 
</script> 
<script defer src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js" crossorigin="anonymous"></script> 
<script defer src="${url}static/js/embed.js"></script>`
}

export const renderMapScript = (uuid, url) =>{
    return `<div id="embeded_container" style="width: 100%; position: relative;"> 
    </div> 
    <script defer src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script> 
    <script defer> 
    var map_container_id = 'embeded_map_container'; 
    var mapPath = 'property/geo_map_and_amenities/${uuid}/'
    </script> 
    <script defer src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js" crossorigin="anonymous"></script> 
    <script defer src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
    integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
    crossorigin=""></script>
    <script defer src="${url}static/js/embeddedmap.js"></script>`
}

export const renderGallaryScript = (url, uid) =>{
    return `<div id="gallary_container" style="width: 100%; position: relative;"> 
    </div> 
    <script defer src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script> 
    <script defer> 
    var gallary_container_id = 'gallary_container'; 
    var gallaryPath = 'organization/public-gallery/?property=${uid}'
    </script> 
    <script defer src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js" crossorigin="anonymous"></script> 
    <script defer src="${url}static/js/gallary.js"></script>`
}

export const renderUpdatesScript = (url, uid) =>{
    return `<div id="updates_container" style="width: 100%; position: relative;"> 
    </div> 
    <script defer src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script> 
    <script defer> 
    var updates_container_id = 'updates_container'; 
    var updatesPath = 'property/public_property_updates/?property=${uid}'
    </script> 
    <script defer src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.11.0/umd/popper.min.js" crossorigin="anonymous"></script> 
    <script defer src="${url}static/js/timeline.js"></script>`
}

