// ----------------- Documentation ---------------------------
// this component used for show the data from analysis list filter based on unit name and property name


import React from "react";
import FormModal from "../../shared-component/formmodal-component/FormModal";
// import FormModal from "../../../shared-component/formmodal-component/FormModal";
import Form from "../../shared-component/formrelated-component/FormManager";

function AnalysisFilter({
    title,
    open,
    onClose,
    fieldMap,
    defaultValues,
    onSubmit,
    loading,
    onReset,
}){
    return(
        <>
            <FormModal title={title} open={open} onClose={onClose} >
                <Form
                defaultValues={defaultValues}
                fieldMap={fieldMap}
                primaryBtnTitle="Filter"
                onCancel={onClose}
                formSubmit={onSubmit}
                loading={loading}
                onReset={onReset}
                />
            </FormModal>
        </>
    )
}
export default AnalysisFilter;