import { Checkbox } from '@mui/material'
import React from 'react'
import RegularCheckbox from '../../../shared-component/OverviewPage/overview-used-components/RegularCheckbox'

function TempDesign3({ 
    data = [] , 
    val,
    popupPosition,
    popupClose,
    isTemplateScreen,
    onCheckSubmit = () =>{},
    selectedTemplate,
    isSubmitting
}) {
    return (
        <div key={val?.id} className='mx-6 my-4'>
            {val && <div
                className='mb-6 p-2 flex justify-between items-center'
                style={{
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    color: 'black',
                    width: '350px',
                    height: 'auto',
                    border: '2px solid #E1EBFE'
                }}
            >Template - SM3 <div><Checkbox disabled={isSubmitting} checked={selectedTemplate?.template_chosen === "TEMPLATE3"} onChange={(e)=>{
                e.preventDefault()
                onCheckSubmit('TEMPLATE3')
            }}/></div></div>}
            <div
                style={{
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    width: '350px',
                    height: 'auto',
                    border: '2px solid #E1EBFE',
                    position: !val && 'absolute',
                    left:!val && `${popupPosition.x}px`,
                    top: !val && `${popupPosition.y}px`,
                    zIndex:  !val && 9999,
                    borderRadius: '8px'
                }}
            >
                <div className='flex items-center justify-between  p-2' style={{ borderBottom: '2px solid #E1EBFE', 
                background: '#004FE8', 
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                borderTopLeftRadius:'8px',
                borderTopRightRadius:'8px'
                      }}>
                    <span style={{
                        fontFamily: 'Rubik',
                        fontSize: '18px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        textAlign: 'left',
                        color: 'white',
                    }}>Linked details</span>
                    <span onClick={popupClose} style={{ color: 'white', cursor: 'pointer' }}>&#10006;</span>
                </div>
                <div className='p-4'>
                    <div className='my-3 flex justify-between' style={{ width: '60%' }}>
                        <div style={{
                            fontWeight: 'bold',
                            color: 'gray'
                        }}>Unit Name :</div>
                        <div style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>A101</div>
                    </div>
                    <div className='my-3 flex justify-between' style={{ width: '60%' }}>
                        <div style={{
                            fontWeight: 'bold',
                            color: 'gray'
                        }}>Unit Price :</div>
                        <div style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>₹ 1.20 Cr *</div>
                    </div><div className='my-3 flex justify-between' style={{ width: '60%' }}>
                        <div style={{
                            fontWeight: 'bold',
                            color: 'gray'
                        }}>Total Sqft. :</div>
                        <div style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>1450 sqft.</div>
                    </div>
                    <div className='my-3 flex justify-between' style={{ width: '60%' }}>
                        <div style={{
                            fontWeight: 'bold',
                            color: 'gray'
                        }}>Block :</div>
                        <div style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>A Block</div>
                    </div>


                    <div className='my-3 flex justify-between' style={{ width: '60%' }}>
                        <div style={{
                            fontWeight: 'bold',
                            color: 'gray'
                        }}>Floor :</div>
                        <div style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>1st Floor</div>
                    </div>
                    <div className='my-3 flex justify-between' style={{ width: '60%' }}>
                        <div style={{
                            fontWeight: 'bold',
                            color: 'gray'
                        }}>Facing :</div>
                        <div style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>East</div>
                    </div>
                    <div className='my-3 flex justify-between' style={{ width: '60%' }}>
                        <div style={{
                            fontWeight: 'bold',
                            color: 'gray'
                        }}>Unit Type :</div>
                        <div style={{
                            color: 'black',
                            fontWeight: 'bold'
                        }}>2 BHK</div>
                    </div>


                </div>
                <div className='flex items-center justify-center  p-2' style={{ borderBottom: '2px solid #E1EBFE', background: '#004FE8' }}>
                    <span style={{
                        fontFamily: 'Rubik',
                        fontSize: '18px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        textAlign: 'center',
                        color: 'white',
                    }}>Available</span>

                </div>
            </div>
        </div>
    )
}

export default TempDesign3