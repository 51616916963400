import React, { useEffect, useState, useContext } from "react";
import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions'
import { BoxEffects, headerButton, grids, stack } from '../../../../Config/Constants'
 
import Box from '@mui/material/Box';
import { 
    Grid, 
    Card,
    Stack,
    Select,
    Avatar,
    MenuItem,
    FormControl,
    Checkbox,
    Skeleton
 } from "@mui/material";
import { PrimaryButton } from '@assystant/mui-buttons';
import GridComponent from "../../../shared-component/grid-component/GridComponent";
import DetailsCard from "../../../shared-component/card-component/DetailsCard";
import DataGridTable from "../../../shared-component/table-component/DataGridTable";
import ProfileContainer from "../../../shared-component/table-component/Row/ProfileContainer";
import formatISOString from "../../../../utils/formatISOString";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LabelAndValue from "../../../shared-component/OverviewPage/overview-used-components/LabelAndValue";
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useNavigate, useParams } from "react-router-dom";
import { apiService } from '../../../../constant/AuthConstant'
import { enqueueSnackbar } from "notistack";
import useExit from '../../../../utils/useExit';
import returnError from '../../../../utils/ExportError'
import Loading from "../../../shared-component/Loading/Loading";
import AddAffiliate from '../component/AddAffiliate'
import EmptyCard from "../../../shared-component/card-component/EmptyCard";
import UserContext from "../../../../context/UserContext";
import DetailSideDrawer from "../../ParentComponents/SideDrawer/DetailSideDrawer";
import PrimaryBtn from "../../../shared-component/button-component/PrimaryBtn";


const sampleData = [
    {
      id:1,
      name:'John Doe',
      email:'johndoe@gmail.com',
      type:'Lead',
      reward:'$500',
      date:"2024-04-23T05:47:03.605761Z",
      _action:'Mark as Paid | Reject',
      status:'paid'
    },
    {
      id:2,
      name:'Jamie Doe',
      email:'jamiedoe@gmail.com',
      type:'Booking',
      reward:'$500',
      date:"2024-04-23T05:47:03.605761Z",
      _action:'Accept',
      status:'unpaid'
    },
  ]

  const arr1 = [
    { title: 'Affiliate', url: '/affiliate' },
  ]

const AffiliateDetails = () =>{
    const { id } = useParams();
    const navigate = useNavigate()
    const { exitFromPlatform } = useExit()
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dashboardStats, setDashboardStats] = useState(null);
    const [loadStats, setLoadStats] = useState(false);
    const [status, setStatus] = useState("Active");
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedConversion, setSelectedConversion] = useState(null)
    const [conversationsList, setConversationsList] = useState([])
    const [loadConversion, setLoadConversion] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState(arr1)

    const {
      selectedCurrency, 
      returnFormattedCurrency
  } = useContext(UserContext)


  const [sideDrawer, setSideDrawer] = useState(false)
  const [selectedForm, setSelectedForm] = useState(null);

  const [propertyLoading, setPropertyLoading] = useState(false)
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [unitLoading, setUnitLoading] = useState(false)
  const [unitDetails, setUnitDetails] = useState(null)
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [unitStatus, setUnitStatus] = useState(null)
  
  const handleSideDrawer = () => {
    setSideDrawer(!sideDrawer)
  }
  
  const openDrawerWithPropertyDetails = (pro) =>{
    setSelectedInventory(null)
    setSelectedProperty(pro)
    handleSideDrawer()
  }
  
  const openDrawerWithUnitDetails = (uni) =>{
    setSelectedProperty(null)
    setSelectedInventory(uni)
    handleSideDrawer()
  }

    const handleEditModal = () =>{
        setOpenEdit(!openEdit)
    }

    const getAffiliateStats = () =>{
        setLoadStats(true)
         apiService.get(`affiliates/affiliates/${id}/affiliate_stats/`).then((res) =>{
          setDashboardStats(res?.data)
          setLoadStats(false)
         }).catch((error) =>{
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setLoadStats(false)
          }
        }
        )
      }

      const getConversionData = (response) =>{
        setLoadConversion(true)
        apiService.get(`affiliates/conversions/`).then((res) =>{
            console.log('from res data', res?.data)
            const filterCon = res?.data?.filter((e) =>{
                return e?.affiliate?.referral_code === response?.referral_code
            })?.sort((a,b) => (a.modified < b.modified) ? 1 : ((b.modified < a.modified) ? -1 : 0))
          setConversationsList(filterCon)
          setSelectedConversion(null)
          setLoadConversion(false)
        }).catch((error) =>{
          if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setLoadConversion(false)
          }
        }
        })
      }

    const getDetailsForAffiliate = () =>{
        setLoading(true)
        apiService.get(`affiliates/affiliates/${id}`).then((res) =>{
            if(res?.data?.active === true){
                setStatus('Active')
            }else{
                setStatus('Inactive')
            }
            setDetails(res?.data)
            const bread = [
              ...arr1,
              {title: `${res?.data?.user?.name ? res?.data?.user?.name : res?.data?.invitation?.name }`, url:'/' }
            ]
            setBreadcrumb(bread)
            setLoading(false)
            getConversionData(res?.data)
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setLoading(false)
              }
            }
        })
    }

    const updateStatusForAffiliate = (e) =>{
        setLoading(true)
        const newDetails = {...details};
        delete newDetails?.photo;
        apiService.put(`affiliates/affiliates/${id}/`, {
            ...newDetails,
            affiliate_type: newDetails?.affiliate_type?.id,
            invitation: newDetails?.invitation?.id,
            organization: newDetails?.organization?.id,
            active: e?.target.value === 'Active' ? true : false,
            user: newDetails?.user?.id ? newDetails?.user?.id : null
        }).then((res) =>{
            if(res){
                getDetailsForAffiliate()
                
            }
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setLoading(false)
              }
            }
        })
    }

    useEffect(() =>{
        getAffiliateStats()
        getDetailsForAffiliate()
    }, [])


    const [customerList, setCustomerList] = useState(sampleData)
    
    const affiliateStats = [
      {
          label:'Total Payouts',
          stat: dashboardStats?.total_earnings ? `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, dashboardStats?.total_earnings)}` : `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, '0')}`
      },
      {
          label:'Total Leads',
          stat: dashboardStats?.total_leads ? `${dashboardStats?.total_leads}` : '0'
      },
      {
          label:'Total Bookings',
          stat: dashboardStats?.total_bookings ? `${dashboardStats?.total_bookings}` : '0'
      },
      {
          label:'Paid',
          stat: dashboardStats?.total_paid ? `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, dashboardStats?.total_paid)}` : `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, '0')}`
      },
      {
          label:'Unpaid',
          stat: dashboardStats?.total_unpaid ? `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, dashboardStats?.total_unpaid)}` : `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, '0')}`
      },
  ]

    const contactDetails = [
        {label:"", 
          value:
          <>
            <Avatar 
                src={details?.user?.avatar}
                sx={{
                width:'60px',
                height:'60px', 
            }}>{details?.user?.name?.substring(0, 1) 
                ? details?.user?.name?.substring(0, 1) 
                : details?.invitation?.name?.substring(0, 1)}</Avatar>
        </>
        },
        {label:'Name', value: details?.user?.name ? details?.user?.name : details?.invitation?.name},
        {label:'Email', value: details?.user?.email ? details?.user?.email : details?.invitation?.email}
    ]

    const commissionDetails = [
        {label:'Booking Commission', value: details?.booking_commission_type === "percent" ? `${`Percentage - ${Number(details?.booking_commission_rate).toFixed(2)}%`}` : `${`Fixed Reward - ${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, details?.booking_commission_rate)}`}`},
        {label:'Lead Commission', value: details?.lead_commission_type === "percent" ?  `${`Percentage - ${Number(details?.lead_commission_rate).toFixed(2)}%`}` : `${`Fixed Reward - ${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, details?.lead_commission_rate)}`}`}
    ]
    
    const otherDetails = [
        {label:'Cookie Duration', value: `${details?.cookie_duration} Days`},
        {label:'Payout Information', value:`${details?.payout_address}`}
    ]

    useEffect(() =>{
         console.log('from selection', selectedConversion)
    }, [selectedConversion])

    const changeConversionStatus = (status, data) =>{
        let formData;
  
        if(data?.lead?.id){
             formData = {
              ...data,
              conversion_status: status,
              affiliate: data?.affiliate?.referral_code,
              lead: data?.lead?.id,
              form: data?.form?.id
            }
        }else{
          formData = {
            ...data,
            conversion_status: status,
            affiliate: data?.affiliate?.referral_code,
            booking: data?.booking?.id,
            form: data?.form?.id
          }
        }
        apiService.put(`affiliates/conversions/${data?.id}/`, formData).then((res) =>{
          if(res.status){
            const list = [...conversationsList];

            const updateList = list?.map((item) =>{
              if(item?.id === res?.data?.id){
                return {
                  ...res?.data
                }
              }else{
                return item
              }
            })?.sort((a,b) => (a.modified < b.modified) ? 1 : ((b.modified < a.modified) ? -1 : 0))
            setConversationsList(updateList)
            enqueueSnackbar("Status Updated", { variant: 'success' });
            getAffiliateStats()
          }
        }).catch((error) =>{
          if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setLoading(false)
          }
        }
        })
      }
      
      const returnButtons = (status, mode, data) =>{
         const callStatus = (selectedStatus) =>{
          if(mode === ''){
            changeConversionStatus(selectedStatus, data)
          }else{
            return;
          }
         }
         if(status === 'paid'){
           return (
            <>
              <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
              onClick={() =>{
                callStatus('approved')
              }}
              style={{
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '23.7px',
                  letterSpacing: '-0.022em',
                  color:'#004FE8'
              }}>Mark as Unpaid</div>
            </>
           )
         }else if(status === 'rejected'){
          return (
            <>
              <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
                onClick={() =>{
                  callStatus('approved')
                }}
              style={{
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '23.7px',
                  letterSpacing: '-0.022em',
                  color:'#004FE8'
              }}>Accept</div>
            </>
           )
         }
         else if(status === 'approved'){
          return (
            <>
              <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
                onClick={() =>{
                  callStatus('paid')
                }}
              style={{
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '23.7px',
                  letterSpacing: '-0.022em',
                  color:'#004FE8'
              }}>Mark as Paid</div>
              <div style={{
                  width:'2px',
                  height:'14px',
                  background:'#8E8CA3',
                  margin: '0px 10px'
              }}></div>
              <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
                onClick={() =>{
                  callStatus('rejected')
                }}
              style={{
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '23.7px',
                  letterSpacing: '-0.022em',
                  color:'#004FE8'
              }}>Reject</div>
            </>
           )
         }else if(status === 'pending'){
          return (
            <>
              <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
                onClick={() =>{
                  callStatus('approved')
                }}
              style={{
                  fontFamily: 'Rubik',
                  fontSize: '15px',
                  fontWeight: 400,
                  lineHeight: '23.7px',
                  letterSpacing: '-0.022em',
                  color:'#004FE8'
              }}>Accept</div>
              <div style={{
                  width:'2px',
                  height:'15px',
                  background:'#8E8CA3',
                  margin: '0px 10px'
              }}></div>
              <div className={`${mode === '' ? "cursor-pointer" : ""}`} 
                onClick={() =>{
                  callStatus('rejected')
                }}
              style={{
                  fontFamily: 'Rubik',
                  fontSize: '15px',
                  fontWeight: 400,
                  lineHeight: '23.7px',
                  letterSpacing: '-0.022em',
                  color:'#004FE8'
              }}>Reject</div>
            </>
           )
         }
      }
  
      const returnStatus = (status) => {
        if(status === 'appoved'){
          return "Unpaid"
        }else if(status === 'rejected'){
          return "Rejected"
        }else if(status === 'approved'){
          return "Unpaid"
        }else if(status === 'pending'){
          return "Pending"
        }else{
          return "Paid"
        }
      }
  
      const returnColor = (status) => {
        if(status === 'approved'){
          return "#FF5C5C"
        }else if(status === 'rejected'){
          return "#FF5C5C"
        }else if(status === 'pending'){
          return "#FAD200"
        }else{
          return "#24D315"
        }
      }
    

    // const returnAward = (row) =>{
    
    //    if(row?.booking !== null){
        
    //     if(details?.booking_commission_type === 'fixed'){
    //       return `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, row?.reward)}`
    //     }
    //     else{
    //       return `${Number(row?.reward)}%`
    //     }
    //    }
    //    else{
    //     if(details?.lead_commission_type === 'fixed'){
    //       return `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, row?.reward)}`
    //     }
    //     else{
    //       return `${Number(row?.reward)}%`
    //     }
    //    }
    // }

    const returnReward = (row) =>{
      return `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, row?.reward)}`
    }


    const columns = [
        { field: 'lead', headerName: 'Customer Details',minWidth: 250, renderCell: (params) =>{
            if(params?.row?.lead?.id){
                return <ProfileContainer profile={{
                    name: params?.row?.lead?.name,
                    email: params?.row?.lead?.email,
                }}/>
            }else{
                return <ProfileContainer profile={{
                    name: params?.row?.booking?.name,
                    email: params?.row?.booking?.email,
                }}/>
            }
        }},
        { field: 'reward', headerName: 'Reward',minWidth: 200, renderCell: (params) => {
          return returnReward(params?.row)
        }},
       
        { field: 'booking', headerName: 'Conversion Type',minWidth: 200, renderCell: (params) =>{
            return params?.row?.booking !== null ? "Booking" : "Lead"
        }},
        { field: 'property', headerName: 'Property',minWidth: 200, renderCell: (params) =>{
      
          return <div onClick={(e) =>{
            e.stopPropagation()
            if(params?.row?.booking?.property?.name){
              openDrawerWithPropertyDetails(params?.row?.booking?.property)
             }
             else if(params?.row?.lead?.property?.name){
              openDrawerWithPropertyDetails(params?.row?.lead?.property)
             }
          }}>
            {params?.row?.booking?.property?.name ?  params?.row?.booking?.property?.name : params?.row?.lead?.property?.name}
          </div>
        }},
        { field: 'unit', headerName: 'Unit',minWidth: 200, renderCell: (params) =>{
          return <div onClick={(e) =>{
            e.stopPropagation()
            if(params?.row?.booking?.unit?.name){
              openDrawerWithUnitDetails(params?.row?.booking)
             }
             else if(params?.row?.lead?.unit?.name){
              openDrawerWithUnitDetails(params?.row?.lead)
             }
          }}>
            {params?.row?.booking?.unit?.name ?  params?.row?.booking?.unit?.name : params?.row?.lead?.unit?.name}
          </div>
        }},
        { field: 'created', headerName: 'Date',minWidth: 200, renderCell: (params) =>{
            return formatISOString(params?.row?.created)
        }},
        { field: '_action', headerName: 'Action',minWidth: 200, renderCell: (params) =>{
          return returnButtons(params.row.conversion_status, '', params?.row)
        }},
        { field: 
            'conversion_status', 
            headerName: 'Status',
            minWidth: 200,
            renderCell: (params) => (
                <>
                  <div style={{
                    fontFamily: 'Rubik',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '23.7px',
                    letterSpacing: '-0.022em',
                    color: returnColor(params?.row?.conversion_status),
                  }}>
  
                  {returnStatus(params?.row?.conversion_status)}
                  </div>
                </>
                ),
        },
    ]


     const link = "https://dev.spotmink.com/my_affiliate_link";

     const copyLinks = () =>{
      if(details?.referral_links?.length > 0){
        navigator.clipboard.writeText(details?.referral_links.join(','))
        enqueueSnackbar('Links Copied')
      }
     }

     const fetchInventory = (unitId) => {
      setUnitLoading(true);
      apiService
        .get(`property/unit/${unitId}`)
        .then((res) => {
          if (res?.data?.output_specs?.groups) {
            const newData = [...res?.data?.output_specs?.groups];
    
            const updateSpecsGroups = newData?.map((e) => {
              const findGroup = res?.data?.specs?.groups?.find((f) => {
                return f?.grp_id === e?.grp_id;
              });
    
              return {
                grp_id: e?.grp_id,
                items: e?.items?.map((f) => {
                  const findItem = findGroup?.items?.find((g) => {
                    return g?.item_id === f?.item_id;
                  });
    
                  return {
                    ...f,
                    [f?.item?.name]: findItem?.item_value,
                  };
                }),
              };
            });
    
            let newArray = [...updateSpecsGroups];
            const newData1 = {
              ...res?.data,
              images: res?.data?.images?.map((e) =>{
                return {
                  ...e,
                  tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
                }
              }),
              booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),
              specs: {
                template_id: res?.data?.specs?.template_id,
                groups: newArray,
              },
            }
            setUnitDetails(newData1);
          } else {
          setUnitDetails({
            ...res?.data,
            booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),});
           }
          setBookingData(res?.data)
          setUnitStatus(res?.data?.status);
          setUnitLoading(false);
        })
        .catch((error) => {
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (
              returnedError === 'Given token not valid for any token type' ||
              returnedError === 'Request failed with status code 401'
            ) {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
              setUnitLoading(false);
            }
          }
        });
    };
    
    
    const fetchPropertyDetails = (propertyId) => {
      setPropertyLoading(true);
      apiService
        .get(`property/prop/${propertyId}/`)
        .then(async (res) => {
          setPropertyDetails({
            ...res?.data,
            image_list: res?.data?.image_list?.map((e) =>{
              return {
                ...e,
                tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
              }
            }),
          });
          setPropertyLoading(false);
        })
        .catch((error) => {
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (
              returnedError === 'Given token not valid for any token type' ||
              returnedError === 'Request failed with status code 401'
            ) {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
              setPropertyLoading(false);
            }
          }
      });
    }
    
    const returnFormData = () =>{
      let dataObj = {}
      if(selectedProperty?.id){
        dataObj = {
            propertyData: propertyDetails,
          }
      }
      else if(selectedInventory?.id){
        dataObj = {
          unitStatus: unitStatus,
          inventoryData: unitDetails
        }
      }
      return dataObj
    }

    const navigateToForm = (navigateTo) =>{
      navigate(navigateTo)
    }
    return(
        <>
         {loading ? 
          <Loading />
         :
          <>
             <Box
            sx={{ 
                borderBottom: BoxEffects.borderBottom, 
                borderColor: 'secondary.main', 
                padding: BoxEffects.padding, 
                marginBottom: BoxEffects.marginButton, 
                paddingLeft: BoxEffects.margin, 
                paddingRight: headerButton.marginRigt,position:'sticky',
                top:'65px',
                background:'white',
            zIndex:99
            }}
            >
            <BreadCrumbWithActions propArr={breadcrumb}>
            <div className='flex justify-between items-center'>
                <div style={{
                marginLeft:'20px'
                }}>
                <PrimaryBtn 
                    title="Edit"
                    style={{
                        boxShadow: 'none', 
                        marginRight:'0px !important',
                        fontWeight:300,
                        padding:'6px 16px'
                    }}
                 onClick={handleEditModal}
                />
                </div>
            </div>
            </BreadCrumbWithActions>
        </Box>
        <Box 
            sx={{
                //borderBottom: BoxEffects.borderBottom, 
                borderColor: 'secondary.main',
                padding:BoxEffects.padding,
                marginBottom:BoxEffects.marginButton,
                paddingLeft:BoxEffects.margin,
                paddingRight:headerButton.marginRigt 
            }}
        >
            <GridComponent>
            <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid} >
                <DetailsCard cardHeader='Contact Information'> 
                    <Grid item xs={grids.parrentgrid} lg={grids.halfGrid} sx={{paddingLeft:0,paddingRight:0}} >
                        {contactDetails.map((e,i)=>{
                            return(
                                <LabelAndValue 
                                  label={e.label} 
                                  value={e.value}  
                                  valueStyles={{
                                    fontFamily: 'Rubik',
                                    fontSize: '20px',
                                    fontWeight: 400,
                                    lineHeight: '28.44px',
                                  }}
                                />
                            )
                        })}
                        
                    </Grid>
                    {/* <LabelAndValue label={details.label} value={details.value}  /> */}
                </DetailsCard>
                <div className="mt-4">
                <DetailsCard 
                  cardHeader='Referral Links' 
                  isCopy={details?.referral_links?.length > 0}
                  onCopyClick={copyLinks}>
                    <Grid item >
                       <div style={{
                        maxHeight: '150px',
                        overflowY:'scroll'
                       }}>
                       {details?.referral_links?.length > 0 ? (
                           details?.referral_links?.map((link) =>{
                            return <div className="my-2" style={{
                              fontFamily: 'Rubik',
                              fontSize: '16px',
                              fontWeight: 400,
                              lineHeight: '18.96px',
                              letterSpacing: '-0.022em',
                              color:'#004FE8'
                          }}>
                              {link?.length > 50 ? `${link?.substring(0, 50)}...` : `${link?.substring(0, 50)}`}
                          </div>
                           })
                       )
                       :
                       (
                        <div style={{
                          fontFamily: 'Rubik',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '18.96px',
                          letterSpacing: '-0.022em',
                          color:'#004FE8'
                      }}>
                          Links are not Available
                      </div>
                       )}
                       </div>
                    </Grid>
                </DetailsCard>
                </div>
            </Grid>
            <Grid  item xs={grids.childgrid} lg={grids.childgrid}>
               <FormControl fullWidth>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={status}
                        label=""
                        data-role='select-status'
                        IconComponent={(props) =>{
                            if(props?.className?.includes('MuiSelect-iconOpen')){
                                return <ExpandLess {...props} className='!fill-[#8E8CA3]' sx={{
                                    pointerEvents:'none !important',
                                    position:'absolute !important',
                                    right:'5px !important'
                                }}/>
                            }
                            return <ExpandMore {...props} sx={{
                                pointerEvents:'none !important',
                                position:'absolute !important',
                                right:'5px !important'
                            }} className='!fill-[#8E8CA3]'/>
                            
                        }}
                        onChange={(e)=>{
                            updateStatusForAffiliate(e)
                        }}
                            sx={{height:'40px',
                            background: 'white !important',
                            color: status === 'Active' ?  '#24D315' : '#FF5C5C',
                            fontWeight:300,
                        }}
                        >
                        <MenuItem value='Active' sx={{color:'#24D315 !important', fontWeight:300,}} >Active</MenuItem>
                        <MenuItem value='Inactive' sx={{color:'#FF5C5C !important', fontWeight:300,}}>Inactive</MenuItem>
                        </Select>
                   </FormControl>
                <div className="mt-4">
               <DetailsCard cardHeader='Commission Information'>
                    <Grid item >
                        {commissionDetails.map((e,i)=>{
                            return(
                                <LabelAndValue 
                                  label={e.label} 
                                  value={e.value}  
                                  valueStyles={{
                                    fontFamily: 'Rubik',
                                    fontSize: '20px',
                                    fontWeight: 400,
                                    lineHeight: '28.44px',
                                  }}
                                />
                            )
                        })}
                    </Grid>
                </DetailsCard>
               </div>
                <div className="mt-4">
                <DetailsCard cardHeader='Other Information'>
                    <Grid item >
                        {otherDetails.map((e,i)=>{
                            return(
                                <LabelAndValue 
                                label={e.label} 
                                value={e.value}  
                                valueStyles={{
                                  fontFamily: 'Rubik',
                                  fontSize: '20px',
                                  fontWeight: 400,
                                  lineHeight: '28.44px',
                                }}
                              />
                            )
                        })}
                    </Grid>
                </DetailsCard>
                </div>
            </Grid>
        </GridComponent>
        <Stack 
            className='!grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 mt-8'  
            direction="row" 
            spacing={stack.space}
            sx={{
                marginBottom: BoxEffects.margin,
                position: "relative",
                rowGap: '20px',
                columnGap: '20px',
            }}
            
            >
                {affiliateStats?.map((e, i) =>{
                    return (
                        <>
                          {loadStats ? 
                          <Card sx={{height:"125px", boxShadow:'none'}}>
                          <Skeleton animation="wave" variant="rectangular" className='w-full !h-full' />
                      </Card>
                        :
                          <div className='p-6 rounded-lg' style={{ minHeight: '125px', border:'2px solid #E1EBFE' }}>
                              <div className='flex flex-col'>
                                   <div style={{
                                      fontFamily: 'Rubik',
                                      fontSize: '40px',
                                      fontWeight: 500,
                                      lineHeight: '47.4px',
                                      letterSpacing: '-0.022em',
                                      color:'#353349'
                                   }}>{e?.stat}</div>
                                   <div style={{
                                      fontFamily: 'Rubik',
                                      fontSize: '24px',
                                      fontWeight: 400,
                                      lineHeight: '28.44px',
                                      letterSpacing: '-2.2%',
                                      color:'#353349'
                                   }}>{e?.label}</div>
                              </div>
                          </div>}
                        </>
                     )
                })}
        </Stack>

        <div className="mt-8">
            {loadConversion ? <Loading/>
            :
             <>
              {conversationsList?.length > 0 ?
              <DataGridTable 
              columns={columns}
              rows={conversationsList}
            />
        :
         <EmptyCard 
           title="There are no conversions available"
         />}
             </>}
        </div>
        </Box>
          </>
         }

         {openEdit && (
            <AddAffiliate 
              title="Edit"
              open={openEdit}
              onClose={handleEditModal}
              data={details}
              successCallback={getDetailsForAffiliate}
            />
         )}

         {sideDrawer && (
           <DetailSideDrawer 
              sideTitle={selectedInventory?.unit?.id ? `See in Units` : (selectedProperty?.id ? `See in Properties` : "")}
              sideButtonClickHandler={() =>{
                if(selectedProperty?.id){
                    navigateToForm(`/properties/${selectedProperty?.id}`)
                }else if(selectedInventory?.unit?.id){
                    localStorage.setItem('property_name', JSON.stringify(selectedInventory?.property?.name))
                    navigateToForm(`/properties/${unitDetails?.id}/inventory/${selectedInventory?.unit?.id}`)
                }
              }}
              title={selectedInventory?.unit?.id ? `Unit - ${unitDetails?.name || ''}` : `Property - ${propertyDetails?.name || ''}`}
              open={sideDrawer}
              onClose={handleSideDrawer}
              formData={returnFormData()}
              isPropertyComponent={selectedProperty?.id ? true : false}
              loading={selectedProperty?.id ? propertyLoading : unitLoading}
              fetchCallback={() =>{
                 if(selectedProperty?.id){
                    fetchPropertyDetails(selectedProperty?.id)
                 }else{
                    if(selectedInventory?.unit?.id){
                      fetchInventory(selectedInventory?.unit?.id)
                    }
                 }
              }}
              isUnitComponent={selectedInventory?.unit?.id ? true : false}
           />
       )}
       
        </>
    )
}

export default AffiliateDetails