import React, { useContext, useState } from 'react';
import FormModal from '../../../../shared-component/formmodal-component/FormModal';
import FormManager from '../../../../shared-component/formrelated-component/FormManager';
import { apiService } from '../../../../../constant/AuthConstant';
import returnError from '../../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../../utils/useExit';
import UserContext from '../../../../../context/UserContext';

const AddWebsiteTitle = ({ open, data, title, onClose, successHandler }) => {
  const { exitFromPlatform } = useExit();
  const [loading, setLoading] = useState(false)
  const { organization } = useContext(UserContext)
  const defaultValues = {
    title: '',
  };

  const fieldMap = [
    {
      name: 'title',
      label: 'Title',
      placeholder: 'Website Title',
      type: 'text',
      fieldProps: {
        required: true
      }
    },
  ];

  const onSubmitHandler = (payload) => {
     if(payload?.title === ''){
       enqueueSnackbar("Please add title", { variant: 'error'});
     }
     else{
      setLoading(true)
      let service;
      let apiUrl;
  
      if (data.id) {
        service = apiService.put;
        apiUrl = `website/site/${data?.id}/`;
      } else {
        service = apiService.post;
        apiUrl = `website/site/`;
      }
      service(apiUrl, {
        ...payload,
        org: organization?.id,
        template: 1,
        code: '<!DOCTYPE html><html></html>',
      })
        .then((res) => {
          if (res?.data?.id) {
            if (successHandler) {
              successHandler(res?.data);
            }
            setLoading(false)
            if (data?.id) {
              enqueueSnackbar('Website Updated', { variant: 'success' });
              onClose();
            } else {
              enqueueSnackbar('Website Added', { variant: 'success' });
              onClose();
            }
          }
        })
        .catch((error) => {
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (
              returnedError === 'Given token not valid for any token type' ||
              returnedError === 'Request failed with status code 401'
            ) {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
              setLoading(false)
            }
          }
        });
     }
  };
  return (
    <FormModal open={open} title={title} onClose={onClose}>
      <div className="mt-4">
        <FormManager
          onCancel={onClose}
          fieldMap={fieldMap}
          data={data?.id ? data : {}}
          defaultValues={defaultValues}
          formSubmit={onSubmitHandler}
          loading={loading}
        />
      </div>
    </FormModal>
  );
};

export default AddWebsiteTitle;
