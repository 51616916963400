import React, { useEffect, useState } from 'react'
import {
    Typography,
    } from '@mui/material'
 
import FormModal from '../../../../shared-component/formmodal-component/FormModal'
 
import { apiService } from '../../../../../constant/AuthConstant';
import returnError from '../../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../../utils/useExit';
import SessionAccordian from './SessionAccordian';
import { ErrorButton } from '@assystant/mui-buttons';
import ErrorBtn from '../../../../shared-component/button-component/ErrorBtn';


const sampleData = {
  "lead_device": "4e009b4c-4110-49b6-bc2f-b0c15a556af9",
  "lead_details": {
      "id": 95,
      "name": "shantanu",
      "email": "shantanu.yadav+21@assystant.com",
      "phone": "9898989898",
      "source": "USER_SUBMITTED"
  },
  "lead_session_analytics": {
      "9edcc7c0-dda4-4bdc-8b18-6d1b5b1739f2": {
          "nodes": [
              {
                  "unit": {
                      "id": 358,
                      "name": "K1"
                  },
                  "releated_nested_events": [
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:29:45.217654Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:36:36.715872Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:37:41.278534Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:40:12.019907Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:41:07.030182Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:42:07.471894Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:42:40.742197Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:43:28.074649Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:46:00.368707Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:48:29.458448Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:49:05.154621Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:11:16.681413Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:11:33.569998Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:11:40.771759Z"
                      },
                      {
                          "event": "ENGAGEMENT",
                          "created": "2024-04-18T11:11:47.099477Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:12:13.863377Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:13:25.592242Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:13:29.162634Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:13:54.881340Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:21:24.575830Z"
                      }
                  ]
              },
              {
                  "unit": {
                      "id": 359,
                      "name": "K2"
                  },
                  "releated_nested_events": [
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:40:10.013961Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:43:26.019530Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T10:45:58.266957Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:11:39.757780Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:13:17.944214Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:13:27.939297Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:13:31.479419Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:15:18.639500Z"
                      },
                      {
                          "event": "ENGAGEMENT",
                          "created": "2024-04-18T11:15:24.996265Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T11:21:40.217568Z"
                      }
                  ]
              }
          ],
          "links": []
      },
      "527e343e-483b-473e-b255-71e2cb107622": {
          "nodes": [
              {
                  "unit": {
                      "id": 358,
                      "name": "K1"
                  },
                  "releated_nested_events": [
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T06:21:28.790861Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-18T06:25:43.776440Z"
                      }
                  ]
              },
              {
                  "unit": {
                      "id": 359,
                      "name": "K2"
                  },
                  "releated_nested_events": []
              }
          ],
          "links": []
      },
      "e7f9f133-844f-42e9-99fa-ce0033798cf8": {
          "nodes": [
              {
                  "unit": {
                      "id": 358,
                      "name": "K1"
                  },
                  "releated_nested_events": [
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T19:47:24.647203Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T19:49:03.963552Z"
                      }
                  ]
              },
              {
                  "unit": {
                      "id": 359,
                      "name": "K2"
                  },
                  "releated_nested_events": []
              }
          ],
          "links": []
      },
      "67097648-52e4-4c2a-afac-53c191b17ae7": {
          "nodes": [
              {
                  "unit": {
                      "id": 358,
                      "name": "K1"
                  },
                  "releated_nested_events": [
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:35:32.244404Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:37:58.557024Z"
                      },
                      {
                          "event": "ENGAGEMENT",
                          "created": "2024-04-17T18:38:04.346881Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:38:35.629445Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:39:11.440370Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:40:18.231114Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:41:40.277307Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:42:45.331271Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:49:39.880902Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:52:41.323356Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:53:22.311099Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:54:43.628668Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:56:54.582549Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T19:00:13.944706Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T19:07:03.944650Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T19:10:54.192848Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T19:12:11.127626Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T19:14:36.578605Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T19:16:55.465385Z"
                      }
                  ]
              },
              {
                  "unit": {
                      "id": 359,
                      "name": "K2"
                  },
                  "releated_nested_events": [
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:35:40.768808Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:36:51.986980Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T18:54:41.636061Z"
                      },
                      {
                          "event": "INTERACTION",
                          "created": "2024-04-17T19:14:35.053750Z"
                      }
                  ]
              }
          ],
          "links": []
      }
  }
}

const SessionModal = ({
    open,
    title,
    handleClose,
    data
}) =>{
      const { exitFromPlatform } = useExit();
      const [deviceData, setDeviceData] = useState(null);
      const [nodesList, setNodeList] = useState([])

      const returnUpdatedLinks = (data) =>{
        const connections = [];
        const newData = [...data];

        for (let i = 0; i < newData.length - 1; i++) {
          connections.push({ "source": newData[i]?.id, "target": newData[i + 1]?.id });
        }

        return connections
      }

      const returnUpdatedData = (data) =>{
        const updatedData = [];
        const groupedData = {};

        data.forEach(item => {
            const key = item.event + '-' + item.hotspot_name;
            if (!groupedData[key]) {
                groupedData[key] = [];
            }
            groupedData[key].push(item);
        });
        console.log('from grouped data', groupedData)
        for (const key in groupedData) {
            if (groupedData[key].length > 1) {
                const parent = groupedData[key][0];
                parent.same_as_parent = groupedData[key].slice(1);
                updatedData.push(parent);
          } else {
                updatedData.push(groupedData[key][0]);
        }
    }
    console.log('from updated data', updatedData)
       return updatedData
      }

      const returnTimeSpent = (time1, time2) =>{
        const date1 = new Date(time1);
        const date2 = new Date(time2);
      
        const timeDifferenceMs = Math.abs(date2 - date1);

        var timeDifferenceMinutes = timeDifferenceMs / (1000 * 60);
        
        if(!isNaN(timeDifferenceMinutes)){
           if(timeDifferenceMinutes < 1){
            return 1
           }else{
            return timeDifferenceMinutes.toFixed(0)
           }
        }else if(isNaN(timeDifferenceMinutes)){
            return 0
        }
        else{
            return 0
        }
      }
      
      const getSessionsListFormDevice = () =>{
        apiService.get(`analytic/user-session/${data?.lead_device}/`).then((res) =>{
          setNodeList()
          const newData = {
            ...res?.data,
            lead_session_analytics: Object.entries(res?.data?.lead_session_analytics)?.map((e) =>{
             
              const nFormattedData = e[1]?.nodes?.map((f, i) =>{
                return {
                   ...f,
                   releated_nested_events: f?.releated_nested_events?.map((x) =>{
                   const randomNumber = Math.floor(Math.random() * 10000) + 1;
                   return {
                     ...x,
                     hotspot_name: f?.unit?.name,
                     id: `${f?.unit.id}/${randomNumber}`
                   }
                   })
                }
              })?.map((a) =>{
                return a?.releated_nested_events
              })
             
            const concatAndReturn = [].concat(...nFormattedData).sort((a, b) => new Date(a.created) - new Date(b.created))
            
            return {
                session_name: e[0],
                relatedData: concatAndReturn,
                time_spent: `${returnTimeSpent(concatAndReturn[0]?.created, concatAndReturn[concatAndReturn?.length - 1]?.created)}m`
            }
            
            //  const getFormattedData = returnUpdatedData(concatAndReturn)
             
            //   const formattedData = {
            //     ...e[1],
            //     nodes: getFormattedData,
            //     links: returnUpdatedLinks(getFormattedData)
            //   }
            //   return {
            //     session_name: e[0],
            //     nodes: formattedData?.nodes,
            //     links: formattedData?.links
            //   }
            })
          }
        //   const filterdata = newData.lead_session_analytics?.filter((e) =>{
        //     return e?.links?.length !== 0;
        //   })
         
        //   const nnData = {
        //     ...newData,
        //     lead_session_analytics: filterdata
        //   }
        
          setDeviceData(newData)
        }).catch((error) =>{
          if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
          }
          }
        })
      }

      const updateSampleData = (data) =>{
          const allData = Object.entries(data.lead_session_analytics).map((e) =>{
            const nFormattedData = e[1]?.nodes?.map((f, i) =>{
              return {
                 ...f,
                 releated_nested_events: f?.releated_nested_events?.map((x) =>{
                 const randomNumber = Math.floor(Math.random() * 10000) + 1;
                 return {
                   ...x,
                   hotspot_name: f?.unit?.name,
                   id: `${f?.unit.id}/${randomNumber}`
                 }
                 })
              }
            })?.map((a) =>{
              return a?.releated_nested_events
            })?.flat()?.sort((a, b) => new Date(a.created) - new Date(b.created));

            return {
              session_name: e[0],
              nodes: returnUpdatedData(nFormattedData),
            }
          })
          
          console.log('from aggre data', allData)
      }

      useEffect(() =>{
        updateSampleData(sampleData)
        getSessionsListFormDevice()
      }, [])

    return (
        <FormModal 
          title={`Device ID #${title.substring(title.length, title.length - 6).toUpperCase()}`}
          open={open}
          onClose={handleClose}
          isCustomization={true}
          additionalDetails={<>
           <div className='w-full flex flex-col justify-end items-end'>
           {deviceData?.lead_details?.name &&(
                  <Typography sx={{
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '18.96px',
                    letterSpacing: '-0.022em',
                    color:'#353349'
                  }}>
                    {deviceData?.lead_details?.name}
                    
                  </Typography>
                 )}
                 {deviceData?.lead_details?.email &&(
                  <Typography sx={{
                    fontFamily: 'Rubik',
                    fontSize: '14px',
                    fontWeight: 300,
                    lineHeight: '16.59px',
                    letterSpacing: '-2.2%',
                    color:'#8E8CA3'
                  }}>
                    {deviceData?.lead_details?.email}
                  </Typography>
                 )}
                  
              </div>
          </>}
        >
           <div className='m-8'>
              {deviceData?.lead_device && (
                <>
                   {deviceData?.lead_session_analytics?.map((e, i) =>{
                return (
                    <SessionAccordian index={i} session={e} />
                )
              })}
                </>
              )}
           </div>
           <div className='flex justify-end mx-8 mb-4'>
               <ErrorBtn title={"Close"} style={{
                 marginRight: '0px !important',
                 boxShadow: 'none', 
                 fontWeight:300,
                 padding:'6px 16px'
               }} 
                onClick={handleClose}
               />
           </div>
        </FormModal>
    )
}

export default SessionModal


