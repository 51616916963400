import { useEffect } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import FieldWrapper from '../FieldWrapper';
import { uniqueId } from 'lodash';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
// import DeleteButton from '../../button-component/DeleteButton';
import { commonText } from '../../../Config/themesConfig';
// import FormFieldMapper from '../FormFieldMapper';
import FormField from './FormField';
import RegularTextField from './RegularTextField';
// import { getValue } from '@mui/system';
import FieldWrapper from './FieldWrapper';
import DeleteButton from '../button-component/DeleteButton';
const DraggableField = ({
  name,
  label,
  fieldProps = {},
  cols = 1,
  defaultValue,
  ...props
}) => {
  
  let { arrayFields, isEdit } = fieldProps;
  let { control, register, getValues, setValue } = useFormContext();
  let { fields, append, remove, update } = useFieldArray({
    control,  
    name,  
  });
  function handleOnDragEnd(result) {
    
    if (!result.destination) return;
    const items = Array.from(fields);
   
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
   
    setValue(name, items)
    console.log('bhui',items, getValues(name))
  }
  console.log('bhui',getValues(name), fields)

  useEffect(() =>{
    if(isEdit === false){
      append({id:uniqueId('File_'), ...defaultValue})
    }
    
  }, [append])
 
  return (
    <FieldWrapper cols={cols}>
      <Box
        className="mt-4 mb-4"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        width="100%"
      >
        <Typography className="">{label}</Typography>
        <DragDropContext onDragEnd={handleOnDragEnd}>
        {fields.map((field, ind) => (
            <div
              className="w-full"
              key={`dynamic-fields-${ind}`}
              style={{
                position: 'relative',
              }}
            >
              <Grid container spacing={2} columns={16} style={{ paddingTop: 16 }}>
                <Droppable droppableId={field.id}>
                {(provided) => (
                    <>
                    <ul className="getStage" {...provided.droppableProps} ref={provided.innerRef}>
                         <Draggable key={field.id} draggableId={field.id} index={ind}>
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex items-center mx-4 bg-white"
                              >
                                <DragIndicatorIcon className='flex items-center m-4' htmlColor={commonText.grey}/>
                                    <FormField Component={RegularTextField} update={update} name= {`${name}.${ind}.${arrayFields.name}`} {...props} />
                                 <div className='flex items-center'><DeleteButton className='mx-0 p-0' onCancel={() => remove(ind)}/></div>
                               </li>
                             )}
                        </Draggable>
                        </ul>
                    </>
                )}
              </Droppable>
              </Grid>
            </div>
        ))}
          </DragDropContext>
        <div>
          <Button onClick={() => append({id:uniqueId('File_'), ...defaultValue})}>Add option</Button>
        </div>
      </Box>
     </FieldWrapper>
  );
};
export default DraggableField;