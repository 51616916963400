import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ToolTipComponent from '../Tooltip/ToolTipComponent';
import UploadIcon from '../../../asset/icons/UploadIcon';
import { BASE_URI, apiService } from '../../../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import CardComponent from '../../components/forms/component/CardComponent';
import { useFormContext } from 'react-hook-form';

const mimeTypes = [
  {
    name: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    value: 'docx',
  },
  { name: 'application/pdf', value: 'pdf' },
  { name: 'image/jpeg', value: 'jpeg' },
  { name: 'application/vnd.ms-powerpoint', value: 'ppt' },
  {
    name: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    value: 'xlsx',
  },
  { name: 'video/mp4', value: 'mp4' },
  { name: 'audio/mpeg', value: 'mp3' },
  { name: 'application/dwg', value: 'dwg' },
];

const SelectedFiles = ({ index, name, file, setValue, getValues, addedFiles, setAddedFiles, setIsUploading }) => {

  const [loading, setLoading] = useState(false);

  const uploadDocuments = () => {
     if(file?.name){
        setLoading(true)
        setIsUploading(true)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('title', file.name);
    
        axios
          .post(`${BASE_URI}doc_manager/document/upload/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response?.data) {
    
                const allfiles = getValues(name);
                setValue(name, [...allfiles, response?.data])
                const filterItems = addedFiles?.filter((e, ind) =>{
                    return ind !== index
                })
                setAddedFiles([])
            }
            setLoading(false)
            setIsUploading(false)
          })
          .catch((error) => {
            setLoading(false)
            setIsUploading(false)
            if (error.response?.data) {
              enqueueSnackbar(error.response?.data, { variant: 'error' });
            }
          });
     }
  };

  useEffect(() => (
    uploadDocuments()
  ), [file])
 
  return (
   
     <CardComponent 
       loading={loading}
       title={file?.name}
       fileType={file?.type}
     />
  );
};

const CustomizedFileUpload = ({ name, fileTypes, fieldProps, tooltip, error, helperText }) => {
  const [addedFiles, setAddedFiles] = useState([]);
  const { setValue, getValues } = useFormContext();
  const [isUploading, setIsUploading] = useState(false)

  const renderFile = (file) => {
    const fileType = file.title.split('.').pop().toLowerCase();

    switch (fileType) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return (
          <CardComponent
            name={file?.title}
            url={file.file}
            fileType={fileType}
          />
        );
      case 'pdf':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      case 'doc':
      case 'docx':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );

      case 'ppt':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      case 'xlsx':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      case 'dwg':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      case 'mp3':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      case 'mp4':
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
      default:
        return (
          <CardComponent
            name={file?.title}
            fileUrl={file.file}
            fileType={fileType}
          />
        );
    }
  };

  console.log('from file error', error, helperText)

  const onDrop = (acceptedFile) => {
    const file = acceptedFile[0]
    if(isUploading === false){
        if (getValues(name)?.length >= fieldProps?.maximum_files) {
            enqueueSnackbar(`Maximum file limit is ${fieldProps?.maximum_files}`, {
              variant: 'error',
            });
          }
          if (file) {
            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > fieldProps?.maximum_size) {
              enqueueSnackbar(`File size should not exceed ${fieldProps?.maximum_size} MB`, {
                variant: 'error',
              });
            } else {
              setAddedFiles([...addedFiles, file])
            }
          }
    }else{
        enqueueSnackbar(`Please wait file upload is in progress`, {
            variant: 'error',
          });
    }
  };
   console.log('from added files', addedFiles)
  const definedFileTypes = () => {
    const types = {};
    if (fieldProps.field?.file_types?.length === 0) {
      return 'image/*';
    } else {
      fieldProps.field?.file_types?.forEach((e) => {
        const mi = mimeTypes?.find((m) => {
          return m?.value === e;
        });
        types[mi?.name] = [];
      });
    }
    return types;
  };

  useEffect(() => {
    definedFileTypes();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: definedFileTypes(),
  });

  const updateSelectedFiles = useCallback(() =>{
    return (
        <div className="flex w-[40%] items-center flex-wrap gap-[10px]">
            {addedFiles?.map((file, index) =>{
        return (
            <SelectedFiles 
               key={index}
               name={name}
               file={file}
               setValue={setValue}
               getValues={getValues}
               addedFiles={addedFiles}
               setAddedFiles={setAddedFiles}
               setIsUploading={setIsUploading}
            />
        )
      })}
        </div>
    )
  }, [name, setValue, getValues, addedFiles, setAddedFiles, setIsUploading])

  return (
    <>
      <div className="flex items-center" {...getRootProps()}>
        <div className="flex items-center bg-[#EDECF9] rounded-lg p-3 my-2">
          <input {...getInputProps()} />
          <UploadIcon />
          <div
            className="text-[#8E8CA3] ml-2"
            style={{
              fontFamily: 'Rubik',
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '23.7px',
            }}
          >
            Add File
          </div>
          {fieldProps?.field?.file_types?.length > 0 ? (
            <div
              className="mx-2 text-[#8E8CA3]"
              style={{
                fontFamily: 'Rubik',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '23.7px',
              }}
            >
              {`( ${fieldProps.field?.file_types?.join(', ')} )`}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="ml-2">
          {tooltip !== '' && (
            <ToolTipComponent title={tooltip} offset={[50, -50]}>
              <ErrorOutlineIcon
                color="#FFFFFF"
                className="!fill-[#8E8CA3]"
                fontSize="20"
              />
            </ToolTipComponent>
          )}
        </div>
      </div>
      {error && helperText && (
        <div className='text-[#FF5C5C] text-[12px] font-normal'>
            {helperText}
        </div>
      )}
      {updateSelectedFiles()}
      {getValues(name)?.length > 0 ? (
        <>
          <div className="flex items-center flex-wrap gap-[10px]">
            {getValues(name).map((file, index) => (
              <div key={index}>{renderFile(file)}</div>
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomizedFileUpload;
