import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Typography } from '@mui/material';

const ProfileContainer = ({
  profile,
  cancellable = false,
  onCancel = () => {},
  onLabelClick = () => {},
  containerClassName = '',
}) => {
  return (
    <div className={`flex items-center ${containerClassName}`}>
      <Avatar src={profile?.picture}>{profile?.name?.substring(0, 1)}</Avatar>
      <div className="flex flex-col">
        {onLabelClick ?
         <div className='cursor-pointer' onClick={onLabelClick}>
         <Typography
          variant="subtitle2"
          className="!mx-2 !w-full"
          sx={{
            fontWeight: 300,
          }}
        >
          {profile?.name}
        </Typography>
         </div>
         :
         <Typography
         variant="subtitle2"
         className="!mx-2 !w-full"
         sx={{
           fontWeight: 300,
         }}
       >
         {profile?.name}
       </Typography>}
        {profile?.email ? (
          <Typography
            variant="subtitle2"
            className="!mx-2 !w-full"
            sx={{
              fontWeight: 300,
              color: '#8E8CA3',
              fontSize: '14px',
            }}
          >
            {profile?.email || ''}
          </Typography>
        ) : profile?.position ? (
          <Typography
            variant="subtitle2"
            className="!mx-2 !w-full"
            sx={{
              fontWeight: 300,
              color: '#8E8CA3',
              fontSize: '14px',
            }}
          >
            {profile?.position || ''}
          </Typography>
        ) : (
          ''
        )}
      </div>
      {cancellable ? (
        <div style={{ cursor: 'pointer' }}>
          <CloseIcon
            onClick={onCancel}
            sx={{ cursor: 'pointer', color: '#FF5C5C' }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProfileContainer;
