import React from 'react'
 

const LinkIt = ({
    label,
    navigate
}) =>{
    return(
        <div className='flex items-center justify-end w-full'onClick={(e) =>{
            e.stopPropagation()
            navigate()
        }} >
            <div className='text-[#004FE8]' style={{
                fontFamily:'Rubik',
                fontWeight:400,
                fontSize:'13px'
            }}>{label}</div>
        </div>
    )
}

export default LinkIt