import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { BASE_URI, apiService } from '../../../constant/AuthConstant';
import { convertStringToKeys } from '../../../utils/helper';
import FormManager from '../../shared-component/formrelated-component/FormManager';
import FormModal from '../../shared-component/formmodal-component/FormModal';
import FilterFields from '../leads/Fields/FilterFields';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import SecondaryBtn from '../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import CardComponent from '../forms/component/CardComponent';

const fieldTypes = [
  { name: 'Textfield', id: 'TEXT' },
  { name: 'Dropdown - Single Selection', id: 'DROPDOWN' },
  { name: 'Dropdown - Multiple Selection', id: 'MULTISELECT' },
  { name: 'File Upload', id: 'FILEUPLOAD' },
  { name: 'Currency', id: 'CURRENCY' },
  { name: 'Checkbox', id: 'CHECKBOX' },
  { name: 'Radio', id: 'RADIO' },
  { name: 'Phone', id: 'PHONE' },
  { name: 'Email', id: 'EMAIL' },
  { name: 'Text Area', id: 'TEXTAREA' },
  { name: 'Name', id: 'NAME' },
  { name: 'Signature', id: 'SIGNATURE' },
];

const DocumentCard = ({
  doc
}) =>{
  const fileType = doc.pdf_file.split('.').pop().toLowerCase();
  return (
    <CardComponent name={doc?.org_doc?.title} fileType={fileType} fileUrl={`${BASE_URI}${doc.pdf_file}`} />
  )
}

const RenderFormFields = ({
  title,
  formFields,
  yupSchema,
  formDetails,
  mappedFields,
}) => {
  console.log('from mapped field', mappedFields);

  const { enqueueSnackbar } = useSnackbar();
  const [property, setProperty] = useState({ name: '' });
  const [unit, setUnit] = useState({ name: '' });
  const [unitAPI, setUnitAPI] = useState('');
  const [step, setStep] = useState('1');
  const [formId, setFormId] = useState(null);
  const [payload_data, setPayload_data] = useState({});
  const [filled_documents, setFilled_documents] = useState([]);
  const [loading, setLoading] = useState(false)

  console.log('from form fields', yupSchema);

  const enquirySubmission = (payload) => {
    apiService
      .post(`leads/user-interest/`, {
        ...payload,
        source: 'USER_SUBMITTED',
        property: property?.id,
        unit: unit?.id,
      })
      .then((response) => {
        if (response?.data?.id) {
          enqueueSnackbar('Form Submitted', { variant: 'success' });
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log('from error', error);
      });
  };

  const bookingSubmission = (payload) => {
    apiService
      .post(`booking/book/`, {
        ...payload,
        source: 'USER_SUBMITTED',
        organization: formDetails?.org,
        property: property?.id,
        unit: unit?.id,
      })
      .then((response) => {
        if (response?.data?.id) {
          setLoading(false)
          enqueueSnackbar('Form Submitted', { variant: 'success' });
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log('from error', error);
      });
  };

  const onSubmit = (payload) => {
    if (!property?.id) {
      enqueueSnackbar('Please select property', { variant: 'error' });
      return;
    } else if (!unit?.id) {
      enqueueSnackbar('Please select unit', { variant: 'error' });
      return;
    }
    setLoading(true)
    let payload_with_sections = [];
    const newMappedKeys = { ...mappedFields };
    if (formDetails?.template?.sections?.length > 0) {
      const sections = formDetails?.template?.sections?.map((e) => {
        return {
          id: e?.id,
          section_name: e?.label,
          section_description: e?.description,
          fields: e?.fields?.map((field) => {
            const updated_payload = Object.entries(payload);
            const getValue = updated_payload?.find((u) => {
              return (
                convertStringToKeys(field?.label) === convertStringToKeys(u[0])
              );
            });
            return {
              name: convertStringToKeys(field?.label),
              value: getValue[1] || '',
            };
          }),
        };
      });
      payload_with_sections = sections;
    } else {
      payload_with_sections = [];
    }

    const formPayload = { ...payload };

    let uuid = localStorage.getItem('uuid');
    let session = localStorage.getItem('session_id');

    if (
      formDetails?.default_name_field?.id &&
      formDetails?.default_email_field?.id &&
      formDetails?.default_phone_field?.id
    ) {
      formPayload['name'] =
        formPayload[
          `${convertStringToKeys(formDetails?.default_name_field?.label)}`
        ];
      formPayload['email'] =
        formPayload[
          `${convertStringToKeys(formDetails?.default_email_field?.label)}`
        ];
      formPayload['phone'] =
        formPayload[
          `${convertStringToKeys(formDetails?.default_phone_field?.label)}`
        ];
      newMappedKeys['name'] =
        newMappedKeys[
          `${convertStringToKeys(formDetails?.default_name_field?.label)}`
        ];
      newMappedKeys['email'] =
        newMappedKeys[
          `${convertStringToKeys(formDetails?.default_email_field?.label)}`
        ];
      newMappedKeys['phone'] =
        newMappedKeys[
          `${convertStringToKeys(formDetails?.default_phone_field?.label)}`
        ];
    }

    const public_payload = {
      org_template: formDetails?.id,
      submission: JSON.stringify({
        ...formPayload,
        formDetails: {
          label: formDetails?.template?.label,
          description: formDetails?.template?.description,
        },
        sections: payload_with_sections || [],
      }),
      linked_property: property?.id,
      linked_unit: unit?.id,
      device: uuid !== null ? uuid : '',
      session: session !== null ? session : '',
      source: 'USER_SUBMITTED',
      mapped_fields: newMappedKeys,
      organization: formDetails?.org,
    };

    apiService
      .post(`form_integration/public_submissions/`, public_payload)
      .then((response) => {
        if (response?.data.id) {
          if (formDetails?.apply_on === 'enquiry') {
            enquirySubmission({
              ...formPayload,
              form: response?.data.id,
            });
          } else {
            bookingSubmission({
              ...formPayload,
              form: response?.data.id,
            });
          }
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log('from error', error);
      });
  };

  const onSubmitFirstStep = (payload) => {
    if (!property?.id && formDetails?.apply_on !== null) {
      enqueueSnackbar('Please select property', { variant: 'error' });
      return;
    } else if (!unit?.id && formDetails?.apply_on !== null) {
      enqueueSnackbar('Please select unit', { variant: 'error' });
      return;
    }
    setLoading(true)
    let payload_with_sections = [];
    const newMappedKeys = { ...mappedFields };
    if (formDetails?.template?.sections?.length > 0) {
      const sections = formDetails?.template?.sections?.map((e) => {
        return {
          id: e?.id,
          section_name: e?.label,
          section_description: e?.description,
          fields: e?.fields?.map((field) => {
            const updated_payload = Object.entries(payload);
            const getValue = updated_payload?.find((u) => {
              return (
                convertStringToKeys(field?.label) === convertStringToKeys(u[0])
              );
            });
            return {
              name: convertStringToKeys(field?.label),
              value: getValue[1] || '',
            };
          }),
        };
      });
      payload_with_sections = sections;
    } else {
      payload_with_sections = [];
    }

    const formPayload = { ...payload };

    let uuid = localStorage.getItem('uuid');
    let session = localStorage.getItem('session_id');

    if (
      formDetails?.default_name_field?.id &&
      formDetails?.default_email_field?.id &&
      formDetails?.default_phone_field?.id
    ) {
      formPayload['name'] =
        formPayload[
          `${convertStringToKeys(formDetails?.default_name_field?.label)}`
        ];
      formPayload['email'] =
        formPayload[
          `${convertStringToKeys(formDetails?.default_email_field?.label)}`
        ];
      formPayload['phone'] =
        formPayload[
          `${convertStringToKeys(formDetails?.default_phone_field?.label)}`
        ];
      newMappedKeys['name'] =
        newMappedKeys[
          `${convertStringToKeys(formDetails?.default_name_field?.label)}`
        ];
      newMappedKeys['email'] =
        newMappedKeys[
          `${convertStringToKeys(formDetails?.default_email_field?.label)}`
        ];
      newMappedKeys['phone'] =
        newMappedKeys[
          `${convertStringToKeys(formDetails?.default_phone_field?.label)}`
        ];
    }

    const public_payload = {
      org_template: formDetails?.id,
      submission: JSON.stringify({
        ...formPayload,
        formDetails: {
          label: formDetails?.template?.label,
          description: formDetails?.template?.description,
        },
        sections: payload_with_sections || [],
      }),
      linked_property: property?.id || null,
      linked_unit: unit?.id || null,
      device: uuid !== null ? uuid : '',
      session: session !== null ? session : '',
      source: 'USER_SUBMITTED',
      mapped_fields: newMappedKeys,
      organization: formDetails?.org,
    };

    apiService
      .post(`form_integration/public_submissions/`, public_payload)
      .then((response) => {
        if (response?.data.id) {
          setFormId(response?.data?.id);
          setPayload_data(formPayload);
          setFilled_documents(response?.data?.filled_documents)
          setStep('2');
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log('from error', error);
      });
  };

  const onSubmitAtSecondStep = () => {
    setLoading(true)
    if (formDetails?.apply_on === 'enquiry') {
      enquirySubmission({
        ...payload_data,
        form: formId,
      });
    } else {
      bookingSubmission({
        ...payload_data,
        form: formId,
      });
    }
  };

  useEffect(() => {
    if (property?.id) {
      setUnitAPI(
        `/form_integration/org_units/?form=${formDetails?.id}&property=${property?.id}`,
      );
    } else {
      setUnitAPI('noAPI');
    }
  }, [property]);
 
  return (
    <FormModal open={true} title={title} removeCloseButton>
      {formDetails?.fill_documents?.length > 0 ? (
        <>
          <div className={`${step === '1' ? 'flex flex-col' : 'hidden'}`}>
            {formDetails?.apply_on !== null && (
               <div
               className={`${step === '1' ? 'flex flex-col' : 'hidden'} mx-6 mt-4`}
             >
               <FilterFields
                 value={property}
                 setValue={setProperty}
                 choicesAPI={`/form_integration/org_properties/?form=${formDetails?.id}`}
                 label="Property"
                 placeholder="Property"
                 titleKey="name"
               />
               <FilterFields
                 value={unit}
                 setValue={setUnit}
                 choicesAPI={unitAPI}
                 label="Unit"
                 placeholder="Unit"
                 message={'Please select a property first'}
                 titleKey="name"
               />
             </div>
            )}
            <FormManager
              fieldMap={formFields?.fields}
              schema={{
                yupSchema: yupSchema,
              }}
              defaultValues={formFields?.defaultValues}
              formSubmit={onSubmitFirstStep}
              removeSecondaryButton
              primaryBtnTitle="Next"
              loading={loading}
            />
          </div>

          <div
            className={`${step === '2' ? 'flex flex-col' : 'hidden'} mx-6 my-4`}
          >
            <div className='!grid grid-cols-2 gap-[20px]'>
               {filled_documents?.map((file) =>{
                return (
                  <DocumentCard doc={file} />
                )
               })}
            </div>
            <div className="flex items-center justify-end mt-4">
              <SecondaryBtn title="Back" onClick={() => setStep('1')} />
              {formDetails?.apply_on !== null && (<PrimaryBtn title="Submit" disabled={loading} onClick={onSubmitAtSecondStep} />)}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mx-6 mt-4">
            <FilterFields
              value={property}
              setValue={setProperty}
              choicesAPI={`/form_integration/org_properties/?form=${formDetails?.id}`}
              label="Property"
              placeholder="Property"
              titleKey="name"
            />
            <FilterFields
              value={unit}
              setValue={setUnit}
              choicesAPI={unitAPI}
              label="Unit"
              placeholder="Unit"
              message={'Please select a property first'}
              titleKey="name"
            />
          </div>
          <FormManager
            fieldMap={formFields?.fields}
            schema={{
              yupSchema: yupSchema,
            }}
            defaultValues={formFields?.defaultValues}
            formSubmit={onSubmit}
            removeSecondaryButton
            loading={loading}
          />
        </>
      )}
    </FormModal>
  );
};

const PublicForm = () => {
  const { id } = useParams();
  const [formDetails, setFormDetails] = useState({});
  const [formState, setFormState] = useState({});
  let mappedFields = {};

  let uuid = localStorage.getItem('uuid');
  let organization = formDetails?.org;

  const create_session = (form) => {
    let uuid = localStorage.getItem('uuid');
    const newData = {
      organization: form?.org,
    };

    if (!uuid) {
      fetch(`${BASE_URI}leads/create-deviceid-session/`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newData),
      })
        .then((response) => {
          if (response.status === 201) {
            return response.json();
          }
        })
        .then((data) => {
          if (!uuid) {
            localStorage.setItem('uuid', data.id);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const checkUUIDAndSessionDetails = (form) => {
    if (uuid && form?.org) {
      fetch(
        `${BASE_URI}form_integration/public_submissions/${uuid}/?organization=${form?.org}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            console.log(
              "crossdomain uuid or another organization's uuid... so deleting localstorage uuid",
            );
            localStorage.removeItem('uuid');
            localStorage.removeItem('session_id');
            create_session(form);
          }
        })
        .then((data) => {
          console.log('autofilling the form', data);
          if (data?.session !== null) {
            localStorage.setItem('session_id', data.session);
          }
          if (data?.name) {
            document.querySelector('#name]').value = data.name;
            localStorage.setItem('haveData', 'yes');
          }
          if (data?.email) {
            document.querySelector('#email').value = data.email;
            localStorage.setItem('haveData', 'yes');
          }
          if (data?.phone) {
            document.querySelector('#phone').value = data.phone;
            localStorage.setItem('haveData', 'yes');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      localStorage.removeItem('uuid');
      create_session(form);
    }
  };

  const getFormFields = () => {
    apiService
      .get(`/form_integration/public_forms/retrieve_by_uuid/${id}/`)
      .then((res) => {
        if (res?.data) {
          console.log('from response', res?.data);
          setFormDetails(res?.data);
          const formDetail = {
            header: {
              form_title: res?.data?.template?.label,
              form_description: res?.data?.template?.description || '',
            },
            fields:
              res?.data?.template?.sections?.length === 0
                ? res?.data?.template?.fields?.map((e, i) => {
                    mappedFields[convertStringToKeys(e?.label)] =
                    e?.variable_name?.endsWith('_1') ? e?.variable_name : e?.variable_name?.concat('_1');
                    let newData = { ...e };
                    newData = {
                      ...e,
                      id: e?.id?.toString(),
                      name: e?.label,
                      helper_text: e?.placeholder,
                      field_options: e?.options?.map((f) => {
                        return {
                          value: f?.label,
                          id: f?.id.toString(),
                          sort_score: i,
                        };
                      }),
                      validations: [],
                      field_type: fieldTypes?.find((f) => f?.id === e?.type),
                      sort_score: i,
                      mandatory: e?.required,
                      maximum_files: e?.maximum_files || 1,
                      maximum_size: e?.maximum_size || 1,
                      file_types: e?.file_types || [],
                      allow_specific_file_type:
                      e?.allow_specific_file_type || false,
                      variable_name: e?.variable_name?.endsWith('_1') ? e?.variable_name : e?.variable_name?.concat('_1')
                    };
                    return newData;
                  })
                : [],
            sections:
              res?.data?.template?.sections?.length > 0
                ? res?.data?.template?.sections?.map((z, index) => {
                    let newData = {
                      ...z,
                      section_name: z?.label,
                      section_description: z?.description,
                      sort_score: index,
                      uuid: z?.uuid ? z?.uuid : z?.id,
                      fields: z?.fields?.map((e, i) => {
                        mappedFields[convertStringToKeys(e?.label)] =
                        e?.variable_name?.endsWith('_1') ? e?.variable_name : e?.variable_name?.concat('_1');
                        let newData = { ...e };
                        newData = {
                          ...e,
                          id: e?.id?.toString(),
                          name: e?.label,
                          helper_text: e?.placeholder,
                          field_options: e?.options?.map((f) => {
                            return {
                              value: f?.label,
                              id: f?.id.toString(),
                              sort_score: i,
                            };
                          }),
                          validations: [],
                          field_type: fieldTypes?.find(
                            (f) => f?.id === e?.type,
                          ),
                          sort_score: i,
                          mandatory: e?.required,
                          maximum_files: e?.maximum_files || 1,
                          maximum_size: e?.maximum_size || 1,
                          file_types: e?.file_types || [],
                          allow_specific_file_type:
                          e?.allow_specific_file_type || false,
                          variable_name: e?.variable_name?.endsWith('_1') ? e?.variable_name : e?.variable_name?.concat('_1')
                        };
                        return newData;
                      }),
                    };
                    delete newData?.id;
                    return newData;
                  })
                : [],
          };
          console.log('from response', formDetail);
          setFormState(formDetail);
          checkUUIDAndSessionDetails(res?.data);
        }
      })
      .catch((error) => {
        console.log('from error', error.response?.data);
        enqueueSnackbar('Not a valid UUID', { variant: 'error' });
      });
  };

  useEffect(() => {
    getFormFields();
  }, []);

  const modifyAndReturnData = () => {
    const values = {};
    if (formState?.fields?.length > 0 && formState?.sections?.length === 0) {
      const newFields = formState?.fields?.map((e) => {
        mappedFields[convertStringToKeys(e?.name)] = e?.variable_name;
        if (e?.field_type?.id === 'CHECKBOX') {
          values[convertStringToKeys(e?.name)] = false;
        } else if (e?.field_type?.id === 'RADIO') {
          values[convertStringToKeys(e?.name)] = false;
        } else if (e?.field_type?.id === 'TEXT') {
          values[convertStringToKeys(e?.name)] = '';
        } else if (e?.field_type?.id === 'NAME') {
          values[convertStringToKeys(e?.name)] = '';
        } else if (e?.field_type?.id === 'DROPDOWN') {
          values[convertStringToKeys(e?.name)] = { name: '', id: '' };
        } else if (e?.field_type?.id === 'MULTISELECT') {
          values[convertStringToKeys(e?.name)] = [];
        } else if (e?.field_type?.id === 'SIGNATURE') {
          values[convertStringToKeys(e?.name)] = '';
        } else if (e?.field_type?.id === 'FILEUPLOAD') {
          values[convertStringToKeys(e?.name)] = [];
        } else {
          values[convertStringToKeys(e?.name)] = '';
        }
        if (e?.field_type?.id === 'DROPDOWN') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'select',
            fieldProps: {
              defaultOptions: e?.field_options?.map((g) => {
                return {
                  id: g?.id,
                  name: g?.value,
                };
              }),
              titleKey: 'name',
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              isCustomizingOptions: true,
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'MULTISELECT') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'select',
            tooltip: e?.helper_text || '',
            fieldProps: {
              multiple: true,
              defaultOptions: e?.field_options?.map((g) => {
                return {
                  id: g?.id,
                  name: g?.value,
                };
              }),
              titleKey: 'name',
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              isCustomizingOptions: true,
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'CHECKBOX') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'checkbox',
            tooltip: e?.helper_text || '',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'RADIO') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'radio_groups',
            tooltip: e?.helper_text || '',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
              options: e?.field_options || [],
            },
          };
        } else if (e?.field_type?.id === 'FILEUPLOAD') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'custom_file_upload',
            tooltip: e?.helper_text || '',
            fieldProps: {
              notToUpload: true,
              maximum_files: e?.maximum_files,
              maximum_size: e?.maximum_size,
              field: e,
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'PHONE') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'phone',
            tooltip: e?.helper_text || '',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'CURRENCY') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'currency',
            tooltip: e?.helper_text || '',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'TEXTAREA') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'textarea',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
            },
          };
        } else if (e?.field_type?.id === 'SIGNATURE') {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'signature',
            tooltip: e?.helper_text,
            fieldProps: {
              tooltip: e?.helper_text,
            },
          };
        } else {
          return {
            name: convertStringToKeys(e?.name),
            label: e?.name,
            placeholder: e?.name,
            type: 'text',
            tooltip: e?.helper_text || '',
            fieldProps: {
              sx:
                e?.type === 'CHECKBOX'
                  ? {
                      marginLeft: '-10px',
                      marginTop: '20px',
                      marginBottom: '10px',
                    }
                  : {},
              tooltip: e?.helper_text || '',
            },
          };
        }
      });

      const newData = {
        fields: newFields,
        defaultValues: values,
      };
      return newData;
    } else {
      const newFields = formState?.sections?.map((e) => {
        return {
          name: '',
          type: 'box_layout',
          label: e?.section_name,
          description: e?.section_description,
          borderType: 'none',
          fieldProps: {
            arrayFields: e?.fields?.map((e) => {
              mappedFields[convertStringToKeys(e?.name)] = e?.variable_name;
              if (e?.field_type?.id === 'CHECKBOX') {
                values[convertStringToKeys(e?.name)] = false;
              } else if (e?.field_type?.id === 'TEXT') {
                values[convertStringToKeys(e?.name)] = '';
              } else if (e?.field_type?.id === 'DROPDOWN') {
                values[convertStringToKeys(e?.name)] = { name: '', id: '' };
              } else if (e?.field_type?.id === 'MULTISELECT') {
                values[convertStringToKeys(e?.name)] = [];
              } else if (e?.field_type?.id === 'SIGNATURE') {
                values[convertStringToKeys(e?.name)] = '';
              } else if (e?.field_type?.id === 'FILEUPLOAD') {
                values[convertStringToKeys(e?.name)] = [];
              } else {
                values[convertStringToKeys(e?.name)] = '';
              }
              if (e?.field_type?.id === 'DROPDOWN') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'select',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    defaultOptions: e?.field_options?.map((g) => {
                      return {
                        ...g,
                        name: g?.value,
                      };
                    }),
                    titleKey: 'name',
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    isCustomizingOptions: true,
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'MULTISELECT') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'select',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    defaultOptions: e?.field_options?.map((g) => {
                      return {
                        ...g,
                        name: g?.value,
                      };
                    }),
                    multiple: true,
                    titleKey: 'name',
                    titleKey: 'value',
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    isCustomizingOptions: true,
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'CHECKBOX') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'checkbox',
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'RADIO') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'radio_groups',
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                    options: e?.field_options || [],
                  },
                };
              } else if (e?.field_type?.id === 'FILEUPLOAD') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'custom_file_upload',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    notToUpload: true,
                    maximum_files: e?.maximum_files,
                    maximum_size: e?.maximum_size,
                    field: e,
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'PHONE') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'phone',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'CURRENCY') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'currency',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'TEXTAREA') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'textarea',
                  tooltip: e?.helper_text || '',
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                  },
                };
              } else if (e?.field_type?.id === 'SIGNATURE') {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'signature',
                  tooltip: e?.helper_text,
                  fieldProps: {
                    tooltip: e?.helper_text,
                  },
                };
              } else {
                return {
                  name: convertStringToKeys(e?.name),
                  label: e?.name,
                  placeholder: e?.name,
                  type: 'text',
                  tooltip: e?.helperText,
                  fieldProps: {
                    sx:
                      e?.type === 'CHECKBOX'
                        ? {
                            marginLeft: '-10px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }
                        : {},
                    tooltip: e?.helper_text || '',
                  },
                };
              }
            }),
          },
        };
      });

      const newData = {
        fields: newFields,
        defaultValues: values,
      };
      return newData;
    }
  };

  const getDynamicSchema = () => {
    let schemaFields = {};
    if (formState?.fields?.length > 0 && formState?.sections?.length === 0) {
      formState?.fields?.map((e) => {
        if (e?.field_type?.id === 'CHECKBOX' || e?.field_type?.id === 'RADIO') {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .boolean()
            .oneOf([true], 'You must agree to the terms and conditions')
            .required('You must agree to the terms and conditions');
        } else if (e?.field_type?.id === 'PHONE') {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .string()
            .matches(/^[+-]?\d{10,}$/, 'Phone number must be exactly 10 digits')
            .required('Phone number is required');
        } else if (e?.field_type?.id === 'TEXT') {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .string()
            .required('This field is required');
        } else if (e?.field_type?.id === 'EMAIL') {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .string()
            .email('Invalid email address')
            .required('Email is required');
        } else if (
          e?.field_type?.id === 'DROPDOWN' ||
          e?.field_type?.id === 'MULTISELECT'
        ) {
          schemaFields[convertStringToKeys(e?.name)] = yup.object().shape({
            value: yup.string().required('This field is required'),
            id: yup.string().required('This field is required'),
          });
        } else if (e?.field_type?.id === 'SIGNATURE') {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .string()
            .required('This field is required');
        } else if (e?.field_type?.id === 'FILEUPLOAD') {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .array()
            .required('Add atleast one file');
        } else {
          schemaFields[convertStringToKeys(e?.name)] = yup
            .string()
            .required('This field is required');
        }
      });
    } else {
      formState?.sections?.forEach((sec) => {
        sec?.fields?.forEach((e) => {
          if (
            e?.field_type?.id === 'CHECKBOX' ||
            e?.field_type?.id === 'RADIO'
          ) {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .boolean()
              .oneOf([true], 'You must agree to the terms and conditions')
              .required('You must agree to the terms and conditions');
          } else if (e?.field_type?.id === 'PHONE') {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .string()
              .matches(
                /^[+-]?\d{10,}$/,
                'Phone number must be exactly 10 digits',
              )
              .required('Phone number is required');
          } else if (e?.field_type?.id === 'TEXT') {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .string()
              .required('This field is required');
          } else if (e?.field_type?.id === 'EMAIL') {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .string()
              .email('Invalid email address')
              .required('Email is required');
          } else if (
            e?.field_type?.id === 'DROPDOWN' ||
            e?.field_type?.id === 'MULTISELECT'
          ) {
            schemaFields[convertStringToKeys(e?.name)] = yup.object().shape({
              value: yup.string().required('This field is required'),
              id: yup.string().required('This field is required'),
            });
          } else if (e?.field_type?.id === 'SIGNATURE') {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .string()
              .required('This field is required');
          } else if (e?.field_type?.id === 'FILEUPLOAD') {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .array()
              .min(1, 'Add atleast one file')
              .required('Add atleast one file');
          } else {
            schemaFields[convertStringToKeys(e?.name)] = yup
              .string()
              .required('This field is required');
          }
        });
      });
    }
    console.log(schemaFields);
    return yup.object().shape(schemaFields);
  };


  return (
    formDetails?.id && (
      <RenderFormFields
        title={formState?.header?.form_title}
        formFields={modifyAndReturnData()}
        yupSchema={getDynamicSchema()}
        formDetails={formDetails}
        mappedFields={mappedFields}
      />
    )
  );
};

export default PublicForm;
