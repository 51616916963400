import React from 'react'
import { Card } from '@mui/material';

const ContentWrapper = ({children}) => {
  return(
    <Card className="border-none rounded-lg" sx={{
        backgroundColor: 'rgba(184, 183, 197, 0.2)',
        boxShadow:'none'
    }}> 
       <div className='m-8'>
         {children}
       </div>
    </Card>
  )
}

export default ContentWrapper;