import React, { useState } from 'react';
import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import { BoxEffects, headerButton } from '../../../Config/Constants';
import Box from '@mui/material/Box';
import { apiService } from '../../../constant/AuthConstant';
import { useSnackbar } from 'notistack';
import returnError from '../../../utils/ExportError';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import useExit from '../../../utils/useExit';
import LabTab from '../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs';
import FormComponent from './tabs/FormsComponent';
import FormSubmissions from './tabs/FormSubmissions';
import FormModal from '../../shared-component/formmodal-component/FormModal';
import FormManager from '../../shared-component/formrelated-component/FormManager';
import { useNavigate } from 'react-router-dom';

function Forms() {
  const navigate = useNavigate()
  const arr1 = [{ title: 'Forms', url: '/' }];
  const [loading, setLoading] = useState(false);
  const [formRequest, setFormRequest] = useState(false);
  const { exitFromPlatform } = useExit();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState('1');
  const defaultValues = {
    request: '',
  };
  const fieldMap = [
    {
      name: '',
      type: 'box_layout',
      label: 'Submit your request',
      borderType: 'none',
      fieldProps: {
        arrayFields: [
          {
            name: 'request',
            label: '',
            placeholder: 'Request details',
            type: 'textarea',
          },
        ],
      },
    },
  ];

  const handleTabChange = (event, newVal) => {
    setValue(newVal);
  };
  // const openFormRequest = () => setFormRequest(true);
  const closeFormRequest = () => setFormRequest(false);
   

  const tabs = [
    {
      id: 1,
      label: 'Forms',
      value: '1',
      page: <FormComponent />,
    },
    {
      id: 2,
      label: 'Form Submissions',
      value: '2',
      page: <FormSubmissions />,
    },
  ];

  const AddNewForm = () =>{
    navigate('/forms/new-form')
  }

  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '65px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          <div className="flex justify-between items-center">
            {/* <SearchBar /> */}
            <div
              style={{
                marginLeft: '20px',
              }}
            >
              {value === '1' && (
                <>
                <PrimaryBtn
                  className="!mx-0"
                  style={{
                    boxShadow: 'none',
                    margin: '0px !important',
                    fontWeight: 300,
                  }}
                  title="Add New"
                  onClick={AddNewForm}
                />
                </>
              )}
               
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>
      <LabTab value={value} tabs={tabs} handleChange={handleTabChange} />
    </>
  );
}

export default Forms;
