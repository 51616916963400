import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const AccordianComponent = ({
    mainTitle,
    accrodianStyles,
    accordianDetailsStyles,
    component = <></>,
    accordianSummaryStyles,
}) =>{
    return (
      <div>
        <Accordion sx={{...accrodianStyles}} >
          <AccordionSummary
            sx={{
              ...accordianSummaryStyles,
              "& .MuiAccordionSummary-content.Mui-expanded": {
                 margin: '15px 0px'
              } 
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {mainTitle}
          </AccordionSummary>
          <AccordionDetails sx={{
            ...accordianDetailsStyles}}>
              {component}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }

export default AccordianComponent