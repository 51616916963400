import React, { useEffect, useState } from 'react'
import { 
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Checkbox,
    Typography,
    Grid,
    Accordion,
 } from '@mui/material'
 import { styled } from '@mui/material/styles';
 
import ModalHeader from '../../shared-component/formmodal-component/ModalHeader';
 
import NestedShowAccordian from './NestedShowAccordian';
import { enqueueSnackbar } from 'notistack';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ExpandLess } from '@mui/icons-material';
import SecondaryBtn from '../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';

const ShowFieldsModal = ({
    open = false,
    onClose = () => {},
    templateList = [],
    setTemplateList,
    updatetemplateData,
    defaultTemplate,
    setVal,
    setLoadForm,
  
}) =>{
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [templateItems, setSelectedItems] = useState([]);
    const [isAllFieldChecked, setIsAllFieldChecked] = useState(true);
    const [groupItems, setGroupItems] = useState(null);
    const [updateTemplateList, setUpdateTemplateList] = useState([]);
    const handleChange = (e) =>{
        console.log(e)
    }

    const StyledAccordion = styled(Accordion)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        color: theme.palette.text.secondary,
      }));

    useEffect(() =>{
    if(defaultTemplate !== null){
        setGroupItems(defaultTemplate)
        setSelectedTemplate(defaultTemplate)
        setSelectedItems(defaultTemplate)
        const checkTemplate = (data) =>{
            const isGroupSelectedAndFieldSelected = data.groups.every(group =>
                group.group_selected &&
                group.fields.every(field => field.selected)
            )
            console.log('from check template', isGroupSelectedAndFieldSelected)
            return isGroupSelectedAndFieldSelected
        }
        const checkResult = checkTemplate(defaultTemplate);
        setIsAllFieldChecked(checkResult)
    }
    }, [defaultTemplate])

    const handleAllFieldChecked = (e) =>{
        // const value = e.target.checked
        // console.log('from template......', groupItems, e.target.checked, value)

      if(isAllFieldChecked === false && groupItems !== null){
       
        const newGroups = groupItems?.groups?.map((e) =>{
            return {
                ...e,
                group_selected: true,
                is_all_selected: true,
                fields: e?.fields?.map((f) =>{
                    return {
                        ...f,
                        selected: true
                    }
                })
               }
         })
         setGroupItems({
          ...groupItems,
          groups: newGroups
      })
      setSelectedItems({
          ...groupItems,
          groups: newGroups
      })
      
      setIsAllFieldChecked(true)
      const updateList = templateList?.map((e) =>{
          if(e.id === groupItems?.id){
           return {
               ...groupItems,
               groups: newGroups,
           }
          }else{
           return e
          }
       })
      
    //    setTemplateList(updateList)
       setUpdateTemplateList(updateList)
      }else{
        enqueueSnackbar("Please select a template.", {variant: 'default'})
      }
       
    }

    const handleItems = (e) =>{
        console.log('from vfx', e.target.value.groups)
        setSelectedTemplate(e.target.value)
        setSelectedItems(e.target.value.fields)
        setGroupItems(e.target.value)
        const checkTemplate = (data) =>{
            const isGroupSelectedAndFieldSelected = data.groups.every(group =>
                group.group_selected &&
                group.fields.every(field => field.selected)
            )
            console.log('from check template', isGroupSelectedAndFieldSelected)
            return isGroupSelectedAndFieldSelected
        }
        const checkResult = checkTemplate(e.target.value);
        setIsAllFieldChecked(checkResult)
    }

    const handleParentField = (event, group, index) =>{
        console.log('from group selection', group, groupItems?.groups)
       const newGroups = groupItems?.groups?.map((e) =>{
          if(e?.id === group?.id){
            return {
                ...e,
                group_selected: event.target.checked,
                is_all_selected: event.target.checked,
                fields: e?.fields?.map((f) =>{
                    return {
                        ...f,
                        selected: event.target.checked
                    }
                })
               }
          }else{
            return e
          }
       })
       console.log('from temp.............', {
        ...groupItems,
        groups: newGroups
    })
       setGroupItems({
        ...groupItems,
        groups: newGroups
    })
    setSelectedItems({
        ...groupItems,
        groups: newGroups
    })
    const updateList = templateList?.map((e) =>{
        if(e.id === groupItems?.id){
         return {
             ...groupItems,
             groups: newGroups,
         }
        }else{
         return e
        }
     })
     const checkTemplate = (data) =>{
        const isGroupSelectedAndFieldSelected = data.groups.every(group =>
            group.group_selected &&
            group.fields.every(field => field.selected)
        )
        console.log('from check template', isGroupSelectedAndFieldSelected)
        return isGroupSelectedAndFieldSelected
    }
    const checkResult = checkTemplate({
        ...groupItems,
        groups: newGroups,
    });
    setIsAllFieldChecked(checkResult)
     console.log('from selected template', updateList)
     setUpdateTemplateList(updateList)
    }

    const handleFields = (k, e, item, childIndex) =>{
        e.stopPropagation();
        e.preventDefault();
        setIsAllFieldChecked(false)
        const newGroups = groupItems?.groups?.map((j) =>{
            console.log('from ids', j?.id, k?.id)
            if(j?.id === k?.id){
                return {
                    ...j,
                    fields: j?.fields?.map((l) =>{
                        if(l?.id === item?.id){
                            return {
                                ...item,
                                selected: e.target.checked
                            }
                        }else{
                            return l
                        }
                    })
                }
            }else{
                return j
            }
        })
        const checkGroupFields = (data) =>{
            const check = data?.every(group =>{
                if(group?.id === k?.id){
                    return group?.fields?.every(field => field.selected)
                }
              }
            )
            return check
        }
        const groupResult = checkGroupFields(newGroups)
        const updateNewGroups = newGroups?.map((e) =>{
            if(e?.id === k?.id){
                const checkAllFields = e?.fields?.every(field => field.selected)
                const checkAllIsDeSelected = e?.fields?.every(field => field.selected === false);
                return {
                    ...e,
                    is_all_selected: checkAllFields === true ? true : false,
                    group_selected: checkAllIsDeSelected === true ? false : true,
                }
            }else{
                return e
            }
        })

        
        console.log('from group result', groupResult, newGroups, updateNewGroups)
       
        console.log('from handle fields', {
            ...groupItems,
            groups: updateNewGroups
        })
        setGroupItems({
            ...groupItems,
            groups: updateNewGroups
        })
        setSelectedItems({
            ...groupItems,
            groups: updateNewGroups
        })
       const updateList = templateList?.map((f) =>{
           if(f.id === groupItems?.id){
            return {
                ...groupItems,
                groups: updateNewGroups,
            }
           }else{
            return f
           }
        })
        console.log('from selected template1.........', updateList)
        const checkTemplate = (data) =>{
            const isGroupSelectedAndFieldSelected = data.groups.every(group =>
                group.group_selected &&
                group.fields.every(field => field.selected)
            )
            console.log('from check template', isGroupSelectedAndFieldSelected)
            return isGroupSelectedAndFieldSelected
        }
        const checkResult = checkTemplate({
            ...groupItems,
            groups: updateNewGroups
        });
        setIsAllFieldChecked(checkResult)

        
        console.log('from selected template.........', updateList, checkResult)

        setUpdateTemplateList(updateList)
       // setTemplateList(updateList)
    }
    console.log('from selected', templateList)

    useEffect(() =>{
       
    }, [selectedTemplate, templateItems])

    const onApply = () =>{
        if(updateTemplateList?.length > 0){
            const findTemp = updateTemplateList?.find((e) =>{
                return e?.id === defaultTemplate?.id
             }) 
             setVal(findTemp)
            setTemplateList(updateTemplateList)
            updatetemplateData(updateTemplateList)
           
        }
        onClose()
    }
    
    return(
        <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        scroll="body"
        className='relative'
        sx={{
                '& .MuiDialog-container': {
                  '& .MuiPaper-root': {
                    width: '100%',
                  },
                },}
        }
        >
        <ModalHeader title={'Show Fields'} onCancel={onClose}/>
          <div className='my-8'>
             <div className='flex items-center m-8'>
                <FormControl sx={{width:'250px'}}>
                    <InputLabel id="demo-simple-select-label" style={{ minWidth: "max-content" }}>Fields templetes</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedTemplate}
                    IconComponent={(props) =>{
                        console.log('from selected', props?.className?.includes('MuiSelect-iconOpen'))
                        if(props?.className?.includes('MuiSelect-iconOpen')){
                            return <ExpandLess {...props} className='!fill-[#8E8CA3]' sx={{
                                pointerEvents:'none !important',
                                position:'absolute !important',
                                right:'5px !important'
                            }}/>
                        }
                        return <ExpandMore {...props} sx={{
                            pointerEvents:'none !important',
                            position:'absolute !important',
                            right:'5px !important'
                        }} className='!fill-[#8E8CA3]'/>
                        
                      }}
                    renderValue={(e) =>{
                        console.log('from value', e)
                        return e.template_name || e.name
                    }}
                    label="Fields templetes"
                    onChange={(e)=>{
                        handleItems(e)
                    }}
                    >
                    {templateList?.map((val)=>{
                        return <MenuItem value={val}>{val.template_name ? val.template_name : val?.name}</MenuItem>
                    })}
                    </Select>
                </FormControl>
                <div className="flex items-center justify-center">
                {/* <Checkbox defaultChecked={isAllFieldChecked} checked={isAllFieldChecked} onChange={(e) =>{
                    handleAllFieldChecked(e)
                }} /> */}
                  <div className='!ml-4 cursor-pointer' onClick={() =>{
                    handleAllFieldChecked()
                  }}>
                  <Typography 
                  variant='subtitle2' 
                  sx={{
                    //styleName: Button Large;
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                  }}
                  className={isAllFieldChecked ? "text-[#8E8CA3]" : "text-[#004FE8]"}
                  
                  >
                    Select All Fields
                </Typography>
                  </div>
                </div>
             </div>
            
             {/* {templateItems?.length > 0 ? (
                    <>
                     <Grid container className='!mx-8' spacing={2} columns={16} >
                        {templateItems?.map((itemx, index)=>{
                            return(
                                <Grid className='!flex !items-center' item xs={8} style={{ paddingTop: 0 }}>
                                    {console.log('from selected ss', itemx?.selected)}
                                    <Checkbox defaultChecked={itemx?.selected} checked={itemx?.selected} onChange={(e, item) =>{
                                           handleFields(e, itemx, index)
                                    }}/>
                                    <Typography>{itemx?.name}</Typography>
                                </Grid>
                            )
                        })}
                   </Grid>
                 
                    </>
                )
            :   
            (<></>)} */}
             <div className='mx-8'>
                  <Grid container={groupItems?.groups?.length} className='' spacing={2} columns={16} >
                       {groupItems?.groups?.map((k, i) =>{
                        return (
                           <NestedShowAccordian 
                              key={i}
                              i={i}
                              Items={k}
                              handleFields={handleFields}
                              handleParentField={handleParentField}
                           />
                        )
                       })}
                   </Grid>
                  </div>
          </div>
          <div className='flex m-8 justify-between'>
             <div></div>
             {selectedTemplate &&(
                <div className='flex'>
                <SecondaryBtn 
                  title='Cancel' 
                  onClick={onClose}
                  style={{
                    fontSize:'15px',
                    padding:'8px 16px',
                  }}
                />
                <PrimaryBtn 
                  title='Apply'
                  onClick={onApply}
                />
             </div>
             )}
          </div>
        </Dialog>
    )
}

export default ShowFieldsModal