import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import '../../../dashboard/components/tooltip.css'

const BarChart = ({ 
    data,
}) => {
  const svgRef = useRef();
  const tooltipRef = useRef();

  useEffect(() => {
    const margin = { top: 0, right: 30, bottom: 40, left: 40 };
    const width = 600;
    const height = 200;
    const svg = d3.select(svgRef.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .style("width", "100%")
      .style("height", "auto");

    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleBand()
      .domain(data.map(d => d.name))
      .range([margin.top, height - margin.bottom])
      .padding(0.4);

     
    svg.selectAll("*").remove();

    const mouseover = function (event, d) {
        console.log(d, event);
        const isPropertyExistInData = data?.every((e) =>{
          return e?.property_name
        })
        const filterData = data?.find((e) =>{
          return e?.name === d
        })
        const tooltipDiv = tooltipRef.current;
        if (tooltipDiv) {
          d3.select(tooltipDiv).transition().duration(200).style("opacity", 0.9);
          d3.select(tooltipDiv)
            .html(isPropertyExistInData ? `<span class="tooltiptext">${filterData?.property_name} - ${d}</span>` : `<span class="tooltiptext">${d}</span>`)
            .style('position', 'absolute')
            .style("left", event.layerX - 60 + "px")
            .style("top", event.layerY - 28 + "px")
            .style('font-size', '12px')
            .style('color', 'white')
            .style('font-family', 'Rubik')
            .style('padding', '5px 5px')
            .style('border-radius', '4px')
            .style('forn-weight', '400')
            .style('background', '#353349')
        }
      };
  
      const mouseout = () => {
        const tooltipDiv = tooltipRef.current;
        if (tooltipDiv) {
          d3.select(tooltipDiv).transition().duration(500).style("opacity", 0);
        }
      };

    svg
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0, ${-40})`)
      .call(
        d3.axisBottom(xScale)
        .ticks(5)
        .tickSize(height - margin.top)
        .tickFormat("") 
        )
        .style('color', "#8E8CA3")
        .attr("stroke-dasharray", "2 2")  
        .select(".domain").remove();
       
    
    svg
      .selectAll(".bar")
      .data(data)
      .join("rect")
      .attr("class", "bar")
      .attr("x", xScale(0))
      .attr("y", d => yScale(d.name))
      .attr("width", d => xScale(d.value) - xScale(0))
      .attr("height", yScale.bandwidth())
      .attr('fill', '#004FE8')

   
    svg
      .selectAll(".label")
      .data(data)
      .join("text")
      .attr("class", "label")
      .attr("x", d => xScale(d.value) + 5)
      .attr("y", d => yScale(d.name) + yScale.bandwidth() / 2)
      .attr("dy", "0.35em")
      .text(d => d.value)
      .attr('fill', '#8E8CA3')
      .attr('font-family', 'Rubik')
      .attr('font-weight', '400')
      .attr('font-size', '12px')


   
    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(
        d3.axisBottom(xScale)
          .ticks(5)
          .tickSize(0)  
      )
      .attr('color', '#B8B7C5')  
      .attr('font-family', 'Rubik')
      .attr('font-weight', '400')
      .attr('font-size', '8px')
      .call(g => g.select(".domain").remove());

    
    svg
      .append("g")
      .attr('class', 'y-axis')
      .attr("transform", `translate(${margin.left},0)`)
      .call(
        d3.axisLeft(yScale)
          .tickSize(0)  
      )
      .selectAll('text')
      .attr('fill', '#B8B7C5') 
      .attr('font-family', 'Rubik')
      .attr('font-weight', '400')
      .attr('font-size', '8px')
      .text(function (d) {
        if(d?.length > 5){
          return d.substring(0,5)+'...';
        }
        else{
          return d;           
        }
    })
    .on("mouseover", mouseover)
    .on("mouseout", mouseout)
    .call(g => g.select(".domain").remove()); 

    svg.selectAll('.y-axis path')
    .attr('color', '#8E8CA3') 
    .attr('stroke-width', '50')
    .attr('stroke', '#8E8CA3') 

    console.log('fro grid lines', svg.select(".grid"))

    svg.select(".grid")
    .selectAll('.tick line')
     .attr("stroke-dasharray", (d, i) => i === 0 ? "none" : "2,2");
        
  }, [data]);

  
    return (
       <>
         <div className="tooltip relative" ref={tooltipRef} />
         <svg ref={svgRef} className="mt-8" width="100%" height="100%"></svg>
       </>
    );
  };
  
  export default BarChart;