import { Tooltip } from '@mui/material'
import React from 'react'

const ToolTipComponent = ({ 
    title = "Add a description here", 
    placement = "top-end", 
    children,
    offset = [100, -60],
}) =>{
    return (
        <Tooltip title={title}
        placement="top-start"
        componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'white',
                color: '#353349 !important',
              },
            },
          }}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                  //  offset: offset
                  },
                },
              ],
            },
          }}
        >
            {children}
        </Tooltip>
    )
}

export default ToolTipComponent