import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function LabelCheckbox({
    row,
    label,
    defaultChecked,
    handleCheckbox,
    styles,
    disabled,
}) {
  return (
    <FormControlLabel sx={{ pointerEvents: "none" }} 
    control={
    <Checkbox 
      disabled={disabled}
      defaultChecked={defaultChecked} 
      checked={defaultChecked}
      onChange={(e) =>{
        handleCheckbox(e, row)
    }} 
    sx={{ pointerEvents: "auto", ...styles }}
    />} label={label} />
  );
}

export default LabelCheckbox;