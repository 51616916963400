import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { headerButton, BoxEffects } from '../../../Config/Constants';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import Loading from '../../shared-component/Loading/Loading';
import EmptyCard from '../../shared-component/card-component/EmptyCard';
import NoteIcon from '../../../asset/icons/NoteIcon';
import AddWebsiteTitle from './components/modals/AddWebsiteTitle';
import { BASE_URI, TokenStorage, apiService } from '../../../constant/AuthConstant';
import returnError from '../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../utils/useExit';
import WebsiteCard from './components/Cards/WebsiteCard';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IntegrateWebsiteModal from './components/modals/IntegrateWebsiteModal';
import axios from 'axios';

const arr1 = [{ title: 'Websites', url: '/' }];

const WebsiteComponent = () => {
  const navigate = useNavigate();
  const { exitFromPlatform } = useExit();
  const [loading, setLoading] = useState(false);
  const [websites, setWebsites] = useState([]);
  const [openTitleModal, setOpenTitleModal] = useState(false);
  const [selectedWebToChangeTitle, setSelectedWebToChangeTitle] = useState(null);
  const [openIntegrate, setOpenIntegrate] = useState(false);
  const [selectedWeb, setSelectedWeb] = useState(null);
  const [domainSubmissionLoading, setDomainSubmissionLoading] = useState(false);
  const [allThemesList, setAllThemesList] = useState([]);
  const [updatingImage, setUpdatingImage] = useState(false)


  const openIntegrationModal = (web) =>{
    setSelectedWeb(web)
    setOpenIntegrate(true)
    setDomainSubmissionLoading(false)
  }

  const closeIntegrateModal = () => {
    setOpenIntegrate(false)
    setDomainSubmissionLoading(false)
  }

  const navigateToThemeSelection = (id) =>{
    navigate(`/websites/${id}/themes`)
  }

  const navigateToPreview = (web) =>{
    window.open(`https://${web?.subdomain}${process.env.REACT_APP_SITE_DOMAIN}/`, '_blank', 'noopener,noreferrer');
  }

  const handleNavigationToInternalPages = (web) =>{
    localStorage.setItem('website', JSON.stringify(web?.title))
    navigate(`/websites/${web?.id}`)
  }

  const openModalToChangeTitle = (webData) =>{
    setSelectedWebToChangeTitle(webData)
    setOpenTitleModal(true)
  }

  const handleModal = () => {
    setSelectedWebToChangeTitle(null)
    setOpenTitleModal(!openTitleModal);
  };

  const apiKeys = `${process.env.REACT_APP_APIKEY}`;

  const base64ToFile = (base64String, mimeType, fileName) => {
    
    const base64Data = base64String.replace(/^data:.+;base64,/, '');
    const byteCharacters = atob(base64Data); // Decode Base64 string
    const byteNumbers = new Array(byteCharacters.length);
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new File([byteArray], fileName, { type: mimeType });
  
    return file; // Return the File object
  };
  
  const submitImageData = (imageBase64, web) =>{
    const imageData = base64ToFile(imageBase64, 'image/png', `myImage-${web?.id}.png`);
     
    const formData = new FormData();
    formData.append('title', web?.title)
    formData.append('cname', web?.cname)
    formData.append('code', web?.code)
    formData.append('draft', web?.draft)
    formData.append('org', web?.org)
    formData.append('subdomain', web?.subdomain)
    formData.append('template', web?.template)
    formData.append('website_image', imageData);
    axios({
     method: 'put',
     url: `${process.env.REACT_APP_BASE_URL}website/site/${web?.id}/`,
     headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${TokenStorage.getToken()}` },
     data: formData
    }).then((res) =>{
      setUpdatingImage(false)
      setTimeout(() =>{
        navigate(`/websites/${web?.id}/themes`)
      },1000)
    }).catch((error) =>{
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setUpdatingImage(false);
        }
      }
    })
  }

  const getScreenshot = async (web) => {
    setUpdatingImage(true)
    try {
      const apiKey = apiKeys;
      const url = encodeURIComponent(`https://${web?.subdomain}${process.env.REACT_APP_SITE_DOMAIN}/`);
      const apiUrl = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${url}&key=${apiKey}`;

      const response = await axios.get(
        apiUrl
      );
      const screenshotData =
        response.data.lighthouseResult.audits['final-screenshot'].details.data;
      if(screenshotData){
        submitImageData(screenshotData, web)
      }
    } catch (error) {
      setUpdatingImage(false)
      setTimeout(() =>{
        navigate(`/websites/${web?.id}/themes`)
      },1000)
      console.error('Error fetching the screenshot', error);
    }
  };

  const retrieveAllThemes = () => {
    apiService
      .get(`website/templates/`)
      .then((res) => {
        if (res?.data) {
          setAllThemesList(res?.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  const retrieveWebsites = () =>{
      setLoading(true)
      apiService.get(`/website/site/`).then((res) =>{
        if(res?.data?.length){
          setWebsites(res?.data)
          localStorage.setItem('website-tabs', null)
        }
        setUpdatingImage(false)
        retrieveAllThemes()
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false)
            setUpdatingImage(false)
          }
        }
      });
  }

  const updateWebsiteStatus = (webData) =>{
    apiService.put(`website/site/${webData?.id}/`, {
      ...webData,
      draft: webData?.draft === true ? false : true,
    }).then((res) =>{
      if(res?.data){
        const updateItemInWebsite = [...websites].map((e) =>{
          if(e?.id === webData?.id){
            return {
              ...e,
              draft: webData?.draft === true ? false : true,
            }
          }
          return e
        })
        setWebsites(updateItemInWebsite)
        enqueueSnackbar('Updated', { variant: 'success' })
      }
    }).catch((error) => {
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setLoading(false)
        }
      }
    });
  }



  useEffect(() =>{
    const allScripts = document.querySelectorAll('body script');
    allScripts.forEach(script => script.remove())
    retrieveWebsites()
  }, [])

  const updateDomain = (domain) =>{
    setDomainSubmissionLoading(true)
    apiService.put(`website/site/${selectedWeb?.id}/`, {
      ...selectedWeb,
     cname: domain,
    }).then((res) =>{
      if(res?.data){
        const updateItemInWebsite = [...websites].map((e) =>{
          if(e?.id === selectedWeb?.id){
            return {
              ...e,
              cname: domain,
            }
          }
          return e
        })
        setWebsites(updateItemInWebsite)
        setDomainSubmissionLoading(false)
        enqueueSnackbar('Updated', { variant: 'success' })
        closeIntegrateModal()
       
      }
    }).catch((error) => {
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setDomainSubmissionLoading(false)
        }
      }
    });
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  return (
    <div className="overflow-x-hidden">
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '0px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          <div className="flex justify-between items-center">
            <div
              style={{
                marginLeft: '20px',
              }}
            >
              <div className="relative">
                <PrimaryBtn
                  className="!mx-0"
                  style={{
                    boxShadow: 'none',
                    margin: '0px !important',
                    fontWeight: 300,
                  }}
                  disabled={updatingImage || loading}
                  title="Add New Website"
                  onClick={handleModal}
                />
              </div>
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>

      <div className="m-6">
        {loading || updatingImage ? (
          <Loading label={updatingImage ? "Please wait updating website data..." : ""} />
        ) : websites?.length === 0 ? (
          <EmptyCard
            icon={
              <div className="w-full flex items-center justify-center">
                <NoteIcon width={80} height={80} />
              </div>
            }
            title="No Websites Available"
            subtitle="Click “Add New” to add a website"
          />
        ) : (
          <>
             <div
                className="!overflow-y-scroll"
                style={{
                  maxHeight: '75vh',
                }}
              >
                <Stack
                  className="mx-0 !grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
                  direction="row"
                  spacing={2}
                  sx={{
                    rowGap: '20px',
                    columnGap: '20px',
                  }}
                >
                  {websites?.map((theme) => {
                    return (
                      <WebsiteCard
                        website={theme}
                        allThemesList={allThemesList}
                        renameClickHandler={openModalToChangeTitle}
                        changeThemeClickHandler={navigateToThemeSelection}
                        clickToShowPreviewHandler={navigateToPreview}
                        clickToOpenIntegrationHandler={openIntegrationModal}
                        cardClickHandler={handleNavigationToInternalPages}
                        clickToChangeStatus={updateWebsiteStatus}
                       
                      />
                    );
                  })}
                </Stack>
              </div>
          </>
        )}
      </div>

      {openTitleModal && (
        <AddWebsiteTitle
          title="Title"
          open={openTitleModal}
          onClose={handleModal}
          data={selectedWebToChangeTitle?.id ? selectedWebToChangeTitle : {}}
          successHandler={(createdWeb) =>{
            getScreenshot(createdWeb)
          }}
        />
      )}

      {openIntegrate && (
        <IntegrateWebsiteModal
           open={openIntegrate}
           onClose={closeIntegrateModal}
           title="Integrate" 
           loading={domainSubmissionLoading}
           onSubmit={updateDomain}
           currentWebData={selectedWeb}
        />
      )}
    </div>
  );
};

export default WebsiteComponent;
