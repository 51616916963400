import { Box, Typography } from "@mui/material";
import React from "react";

function LabelAndValue({
    label='',
    value='',
    labelStyles = {},
    valueStyles = {},
    isCustomizing = false,
    isCustomizingLabel = false
}){
    return(
        <>
            <Box className={isCustomizing ? "mb-2" : ""}>
                <Typography sx={{color:'#B1AED1', fontWeight:300, ...labelStyles}}>
                    {label}
                </Typography>
                <Typography sx={{
                    fontWeight: isCustomizingLabel ? 500 : 300,
                    color:'#353349',
                    fontSize: isCustomizingLabel ? "20px" : "1rem",
                    whiteSpace: 'break-spaces',
                    ...valueStyles
                }}>
                    {value}
                </Typography>
            </Box>
             {!isCustomizing && (
                <br />
             )}
        </>
    )
}
export default LabelAndValue;