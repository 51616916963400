import React from 'react';

const Artical = ({color = "#8E8CA3"}) =>{
    return(
        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_6924_8128" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
            <rect x="0.0195312" y="0.3125" width="32" height="32" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_6924_8128)">
            <path d="M9.35286 22.9792H18.6862V20.3125H9.35286V22.9792ZM9.35286 17.6458H22.6862V14.9792H9.35286V17.6458ZM9.35286 12.3125H22.6862V9.64583H9.35286V12.3125ZM6.6862 28.3125C5.95286 28.3125 5.32509 28.0514 4.80286 27.5292C4.28064 27.0069 4.01953 26.3792 4.01953 25.6458V6.97917C4.01953 6.24583 4.28064 5.61806 4.80286 5.09583C5.32509 4.57361 5.95286 4.3125 6.6862 4.3125H25.3529C26.0862 4.3125 26.714 4.57361 27.2362 5.09583C27.7584 5.61806 28.0195 6.24583 28.0195 6.97917V25.6458C28.0195 26.3792 27.7584 27.0069 27.2362 27.5292C26.714 28.0514 26.0862 28.3125 25.3529 28.3125H6.6862ZM6.6862 25.6458H25.3529V6.97917H6.6862V25.6458Z" fill={color}/>
            </g>
        </svg>

    )
}

export default Artical