import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar/Avatar';
import { Apartment } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import ImageIcon from '@mui/icons-material/Image';
import IconForUpload from '../../../asset/icons/IconForUpload';

function FormAvtar({ name }) {
  console.log('from name', name);
  const [image, setImage] = useState(null);
  const onDrop = (acceptedFiles) => {
    console.log('from acceptedFiles', acceptedFiles);
    const newImage = URL.createObjectURL(acceptedFiles[0]);
    setImage(newImage);
    setValue(name, acceptedFiles[0]);
  };
  const { setValue, getValues } = useFormContext();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*', // Specify the file types you want to accept
  });

  useEffect(() => {
    if (getValues(name) !== '' || getValues(name) !== null) {
      setImage(getValues(name));
    }
  }, []);
  return (
    <>
      <div
        data-testid="avtar"
        className="flex flex-col ml-4"
        {...getRootProps()}
        style={{
          width: '50px',
        }}
      >
        {image === null ? (
          <IconForUpload />
        ) : (
          <Avatar
            size="large"
            sx={{
              borderWidth: 4,
              borderStyle: 'solid',
              borderColor: 'background.default',
              width: '100px',
              height: '100px',
              '&:hover': {
                cursor: 'pointer',
              },
              background: '#EDECF9',
            }}
            src={image !== null ? image : null}
            children={
              image === null ? (
                <Apartment
                  fontSiz="large"
                  sx={{
                    width: '50px',
                    height: '50px',
                  }}
                  className="!fill-[#004FE8]"
                />
              ) : null
            }
          />
        )}
        <input {...getInputProps()} style={{ opacity: 0 }} />
      </div>
    </>
  );
}
export default FormAvtar;
