// ----------------- Documentation ---------------------------
// this component used for add and edit the data from inventory overview
// this form used the firebase database to store the data

import { Box, Button, Grid, Paper, styled, Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';

import GridComponent from '../../shared-component/grid-component/GridComponent';
import DetailsCard from '../../shared-component/card-component/DetailsCard';
import StandardImageList from '../../shared-component/OverviewPage/overview-used-components/StandardImageList';
import { BoxEffects, grids, headerButton } from '../../../Config/Constants';
import LabelAndValue from '../../shared-component/OverviewPage/overview-used-components/LabelAndValue';

import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import { ErrorButton, PrimaryButton } from '@assystant/mui-buttons';

import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../shared-component/Loading/Loading';

import { apiService, BASE_URI } from '../../../constant/AuthConstant';
import AddUnit from './AddUnit';
import UserContext from '../../../context/UserContext';
import { enqueueSnackbar } from 'notistack';
import RemovePopup from '../../shared-component/RemovePopup/RemovePopup';
import Status from '../../shared-component/statusDropDown/Status';
import { useMediaQuery } from 'react-responsive';
import returnError from '../../../utils/ExportError';
import useExit from '../../../utils/useExit';
import ProfileContainer from '../../shared-component/table-component/Row/ProfileContainer';
import AddBooking from '../booking/component/AddBooking';
import FormControl from '@mui/material/FormControl';
import {
  Select,
  MenuItem
} from '@mui/material';
import GallaryModal from '../../shared-component/OverviewPage/components/GallaryModal';
import ActionButton from '../../shared-component/button-component/ActionButton';
import ImageGridLayout from '../../shared-component/OverviewPage/components/ImageGiridLayout';
import GallaryDetailModal from '../../shared-component/OverviewPage/components/GallaryDetailModal';
import EmptyCard from '../../shared-component/card-component/EmptyCard';
import DataGridTable from '../../shared-component/table-component/DataGridTable';
import { iconsList } from '../../../Config/Constants';
import DeleteIcon from '../../../asset/icons/DeleteIocn';
import DetailSideDrawer from '../ParentComponents/SideDrawer/DetailSideDrawer';
import { neighboursIcons } from '../../../Config/Constants';
import UnitOverviewComponent from '../ParentComponents/UnitOverviewComponent';


const StatusComponent = ({
  booking_status,
  changeStatus,
  data,
 }) =>{
    const [status, setStatus] = useState(booking_status);

    return(
      <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={status}
        label=""
        onChange={(e)=>{
          setStatus(e?.target.value)
          changeStatus({
            ...data?.confirmed_booking,
            unit: data?.id,
            property: data?.property
          }, e)
        }}
        sx={{height:'40px',
        background: 'white !important',
        color: data?.confirmed_booking.booking_status === 'confirmed' ? '#24D315' : '#FF5C5C',
        fontWeight:300,
      }}
      >
          <MenuItem value='opened' sx={{color:'#24D315 !important', fontWeight:300,}} >Booking Received</MenuItem>
          <MenuItem value='confirmed' sx={{color: '#24D315 !important', fontWeight: 300}}>Booking Confirmed</MenuItem>
          <MenuItem value='inactive' sx={{color:'#FF5C5C !important', fontWeight:300,}}>Booking Cancelled</MenuItem>
          <MenuItem value='waitlist' sx={{color:'#FF5C5C !important', fontWeight: 300}}>Waitlist</MenuItem>
      </Select>
    </FormControl>
    )
 }

 const updateDate = (dateString) =>{
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getUTCFullYear();

   
  const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
      }
  };

  const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;
  const formattedDate = `${dayWithSuffix} ${month} ${year}`;
  return formattedDate
}


function InventoryOverview({ inv }) {
  const { id } = useParams();
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const [inventoryData, setInventoryData] = useState(null);
  const [openEditInventory, setOpenEditInventory] = useState(false);

  const [items, setItems] = useState([]);
  const [groups, setGroups] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [formatted, setFormatted] = useState(null);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [unitStatus, setUnitStatus] = useState('');
  const [loadForm, setLoadForm] = useState(null);
  const isMidScreen = useMediaQuery({ query: '(max-width: 1199px)' });
  const { exitFromPlatform } = useExit();
  const [status, setStatus] = useState(null);
  const [confirmCancel, setConfirmCancel] = useState(false);
  localStorage.setItem('tabValue', '3');
  console.log(localStorage)
  const { selectedCurrency, returnFormattedCurrency, userFromOrganization } = useContext(UserContext);

  const [confirmStatus, setConfirmStatus] = useState(false);
  const [bookingData, setBookingData] = useState({});

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [analyticTags, setAnalyticTags] = useState([]);

  const [updateLoading, setUpdatesLoading] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [timelineList, setTimeLineList] = useState([])

  const [sideDrawer, setSideDrawer] = useState(false)
  const [selectedForm, setSelectedForm] = useState(null);

  const [booking, setBooking] = useState({});
  const [neighbourIcon, setNeighbourIcon] = useState(neighboursIcons[0])
  const [loading, setLoading] = useState(false)

  const handleSideDrawer = () => {
    setSideDrawer(!sideDrawer)
  }

  const handleRemoveModal = () =>{
    setRemoveModal(!removeModal)
}

  const handleOpenRemoveModal = (id) =>{
    setSelectedId(id)
    setRemoveModal(true)
  }

  const columns = [
    { field: 'id', headerName: 'ID',minWidth: 100},
    { field: 'title', headerName: 'Title',minWidth: 300},
    {
        field: 'units',
        headerName: 'Units',
        sortable: false,
        minWidth: 300,
        align:'left',
        renderCell: (params) => (
           params?.row?.units?.map((e) =>{
            return e?.name
           }).join(", ") || "-"
        ),
      },
      {
        field: 'publish_date',
        headerName: 'Date',
        sortable: false,
        minWidth: 300,
        align:'left',
        renderCell: (params) => {
          return params?.row?.publish_date?.split("-").reverse().join("/")
        },
      },
      { 
      field: 'actions', 
      headerName: '',
      width: 80,
      align:'center',
      renderCell : (params) =>{
         return <div className="cursor-pointer align-center" onClick={(e)=>{
              e.stopPropagation()
              e.preventDefault()
              openRemoveModal(params?.row?.id)
             }}>
                    <DeleteIcon color="error" className="!fill-[#FF5C5C]"  />
            </div>
      }},

  ];

  const handleOpenDetailModal = () =>{
    setOpenDetailModal(!openDetailModal)
}

const onClickEdit = (index) =>{
  setActiveIndex(index)
  handleOpenDetailModal()
}


  const handleConfirmStatus = () =>{
    setConfirmStatus(!confirmStatus)
  }

  const toggleRemoveModal = () => {
    setOpenRemoveModal(!openRemoveModal);
  };
  const nameString = localStorage.getItem('property_name');
  const invDetails = inv?.find((val) => val.id === id);
  const [openBooking, setOpenBooking] = useState(false);
  const [organization, setOrganization] = useState({});

  const handleBookingModal = () => {
    setOpenBooking(!openBooking);
  };

  const [openGallaryModal, setOpenGallaryModal] = useState(false);
  const [gallaryItems, setGallaryItems] = useState([]);
  const [gallaryLoading, setGallaryLoading] = useState(false)

  
  const handleGallaryModal = () =>{
      setOpenGallaryModal(!openGallaryModal)
  }

  const arr1 = [
    { title: 'Properties', url: '/properties' },
    {
      title: `${JSON.parse(nameString)}`,
      url: `/properties/${inventoryData?.property}`,
    },
    { title: `Inventory`, url: `/properties/${inventoryData?.property}` },
    { title: `${inventoryData?.name}` || 'Untitled' },
  ];

  const itemData = inventoryData?.upload_list?.map((val, index) => {
    const string = val?.image || val?.media;
    const y = string?.substring(string?.lastIndexOf('.'));
    return {
      id: val.id,
      image: val?.image || val.media,
      type:
        y === '.mp4'
          ? 'video'
          : y === '.jpg' || y === '.jpeg' || y === '.png'
            ? 'image'
            : 'image',
    };
  });

  const updateMasonaryData = (newData) => {
    if (newData?.output_specs) {
      const data = { ...newData.specs };
      delete data?.template_id;
      delete data?.template_name;

      const getDataWithHeader = data?.groups?.map((e) => {
        return {
          header: newData?.output_specs?.groups?.find(
            (f) => f.grp_id === e?.grp_id,
          )?.group?.name
            ? newData?.output_specs?.groups?.find((f) => f.grp_id === e?.grp_id)
                ?.group?.name
            : groups?.find((f) => f.id === e?.grp_id)?.name,
          data: e?.items?.map((f) => {
            delete f?.item_id;

            return {
              name: f?.item?.name,
              value: Object?.entries(f)[0][1]?.id
                ? Object?.entries(f)[0][1]?.id
                : Object?.entries(f)[0][1],
            };
          }),
        };
      });
      const removeHeaders = getDataWithHeader?.map((val) => {
        return val?.data;
      });
      const concat = [].concat(...removeHeaders);
      setFormatted(concat);
    } else {
      return [];
    }
  };

  const retrievePropertyUpdates = (propertyId) =>{
    setUpdatesLoading(true)
    apiService.get(`property/property_updates/`).then((res) =>{
      if(res?.data){
        const newData = res?.data?.map((e) =>{
          const findIcon = iconsList?.find((f) =>{
            return f?.name === e?.icon
          })
          return {
            ...e,
            icon: findIcon,
            images: e?.images?.length > 0 ? e?.images?.map((r) =>{
              return {
                ...r,
                media: `${BASE_URI}${r?.media}`
              }
            }) : [],
            date: updateDate(e?.publish_date)
          }
        })?.filter((e) =>{
          return e?.linked_property === Number(propertyId) && res?.data?.images?.some((s) => s?.id === id)
        })
  
        setTimeLineList(newData)
        setUpdatesLoading(false)
      }
    }).catch((error) =>{
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
          setUpdatesLoading(false)
      }
      }
    })
  }

  const fetchInventory = () => {
    setLoad(true);
    apiService
      .get(`property/unit/${id}`)
      .then((res) => {
        if (res?.data?.output_specs?.groups) {
          const newData = [...res?.data?.output_specs?.groups];

          const updateSpecsGroups = newData?.map((e) => {
            const findGroup = res?.data?.specs?.groups?.find((f) => {
              return f?.grp_id === e?.grp_id;
            });

            return {
              grp_id: e?.grp_id,
              items: e?.items?.map((f) => {
                const findItem = findGroup?.items?.find((g) => {
                  return g?.item_id === f?.item_id;
                });

                return {
                  ...f,
                  [f?.item?.name]: findItem?.item_value,
                };
              }),
            };
          });

          let newArray = [...updateSpecsGroups];
          const newData1 = {
            ...res?.data,
            images: res?.data?.images?.map((e) =>{
              return {
                ...e,
                tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
              }
            }),
            booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),
            specs: {
              template_id: res?.data?.specs?.template_id,
              groups: newArray,
            },
          }
          setInventoryData(newData1);
          updateMasonaryData({
            ...res?.data,
            specs: {
              template_id: res?.data?.specs?.template_id,
              groups: newArray,
            },
          });
        } else {
          setInventoryData({
            ...res?.data,
            booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),});
        }
        setBookingData(res?.data)
        setUnitStatus(res?.data?.status);
        setLoad(false);
        retrievePropertyUpdates(res?.data?.property)
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoad(false);
          }
        }
      });
  };

  const getTemplates = () => {
    apiService
      .get('specs/template/')
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };
  const getItems = () => {
    apiService
      .get('specs/item/')
      .then((res) => {
        setItems(res.data);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };
  const getGroups = () => {
    apiService
      .get('specs/group/')
      .then((res) => {
        setGroups(res.data);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  async function fetchDataFromCollection() {
    apiService
      .get(`organization/get-user-org/`)
      .then((res) => {
        if (res.data) {
          setOrganization(res.data);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  }

  useEffect(() => {
    getTemplates();
    getGroups();
    getItems();
    fetchInventory();
    fetchDataFromCollection();
  }, []);

  const toggleEditInventoryModal = () => {
    if (
      templates?.length === 0 ||
      groups?.length === 0 ||
      items?.length === 0
    ) {
      enqueueSnackbar('Please update the specification settings.', {
        variant: 'default',
      });
    } else {
      setOpenEditInventory(!openEditInventory);
    }
  };

  const handleDeleteInventory = () => {
    const propertyName = localStorage.getItem('property_name');
    setLoad(true);
    apiService
      .delete(`property/unit/${id}`)
      .then((res) => {
        toggleRemoveModal();
        navigate(`/properties/${inventoryData?.property}`);
      })
      .catch((error) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      })
      .finally(() => {
        setLoad(false);
      });
  };

  function handleStatusChange(e) {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (e.target?.value) {
      apiService
        .patch(`property/unit/${inventoryData?.id}/`, {
          status: e.target?.value,
        })
        .then((res) => {
          enqueueSnackbar('Inventory status changed!', { variant: `success` });
          fetchInventory();
          setLoad(false);
        })
        .catch((err) => {
          enqueueSnackbar(err.message, { variant: 'error' });
          if (error.response?.data) {
            const returnedError = returnError(error.response?.data);
            if (
              returnedError === 'Given token not valid for any token type' ||
              returnedError === 'Request failed with status code 401'
            ) {
              exitFromPlatform();
            } else {
              enqueueSnackbar(returnedError, { variant: 'error' });
            }
          }
        });
    }
  }

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const returnLinked = (data) => {
    const mapRelation = data?.map((e) => {
      return e?.name;
    });
    return mapRelation?.join(', ');
  };

  const unitdetails1 = [
    { label: 'Unit Name', value: inventoryData?.name || 'none' },
    {
      label: 'Price',
      value: inventoryData?.sellable
        ? `${returnFormattedCurrency(
            selectedCurrency?.locale,
            selectedCurrency?.currencyCode,
            inventoryData?.price,
          )}` || 'Not Provided'
        : 'Not Applicable',
    },
    {
      label: 'Map Relation',
      value: returnLinked(inventoryData?.linked_hotspots) || 'none',
    },
  ];
  console.log('sellable', inventoryData);
  const unitdetails2 = [];

  const updateGroups = () => {
    const getTemplates = templates?.map((e) => {
      return {
        ...e,
        groups: groups?.filter((val) => {
          const checkId = val?.templates?.some((s) => {
            return s === e?.id;
          });
          if (checkId === true) {
            return val;
          }
        }),
      };
    });

    const updateItemsInTemplate = getTemplates?.map((e) => {
      return {
        ...e,
        groups: e?.groups?.map((val) => {
          return {
            ...val,
            items: items?.filter((d) => {
              const checkId = d?.groups?.some((s) => {
                return s === val?.id;
              });
              if (checkId === true) {
                return d;
              }
            }),
          };
        }),
      };
    });

    return updateItemsInTemplate;
  };

  const modifyData = (data) => {
    const updatedTempGroups = updateGroups();
    let newData = {};
    const newImages = data?.upload_list?.map((val) => {
      const string = val?.image || val?.media;
      const y = string?.substring(string?.lastIndexOf('.'));
      if (y === '.jpg' || y === '.jpeg' || y === '.png') {
        return {
          id: val.id,
          media: val?.media,
          type: 'image',
        };
      } else {
        return {
          id: val.id,
          media: val?.media,
          type: 'video',
        };
      }
    });
    newData = {
      ...data,
      images: newImages,
      unit_name: data?.name,
      description: data?.description,
      sellable: data?.sellable === true ? false : true,
    };

    if (data?.output_specs?.template_id && data?.specs?.groups?.length !== 0) {
      const findTemplate = updatedTempGroups?.find((e) => {
        return e?.id === newData?.specs?.template_id;
      });

      const originalSpecsData = newData?.specs?.groups?.map((e) => {
        return {
          ...e,
          items: e?.items?.map((f) => {
            return {
              item_id: f?.item?.id,
              [f?.item?.name]:
                f?.item?.type === 'TEXTFIELD'
                  ? f?.item_value
                  : { title: f?.item_value, id: f?.item_value },
            };
          }),
        };
      });
      const newDa = findTemplate?.groups?.map((e) => {
        const findGroup = originalSpecsData?.find((f) => {
          return f?.grp_id === e?.id;
        });
        return {
          grp_id: e?.id,
          items: e?.items?.map((f) => {
            const checkItem = findGroup?.items?.some((g) => {
              return g?.item_id === f?.id;
            });

            if (checkItem === true) {
              const findItem = findGroup?.items?.find((g) => {
                return g?.item_id === f?.id;
              });
              return findItem;
            } else {
              return {
                item_id: f?.id,
                [f?.name]: '',
              };
            }
          }),
        };
      });

      newData = {
        ...newData,
        price: Number(newData?.price).toFixed(0).toString(),
        specs: {
          template_id: newData?.specs?.template_id,
          groups: newDa,
        },
      };
    }

    return newData;
  };
  const confirmCancellation = () => {
    setConfirmCancel(true);
  }
  const closeCancel = () => setConfirmCancel(false);
  const cancelBooking = () => {
    apiService.put(
      `/booking/entries/${inventoryData?.booking_unit[0].id}/`,
      {
        ...inventoryData?.booking_unit[0],
        booking_status: 'inactive'}
    ).then((res) => {
      setUnitStatus('AVAILABLE')
      setInventoryData(
        {
          ...inventoryData,
          booking_unit: inventoryData.booking_unit.slice(1)
        }
      )
      setConfirmCancel(false);
    })
    .catch((error) => {
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setLoad(false);
        }
      }
    });
  }

  async function changeBookingStatus(data, e) {
    apiService.put(`booking/entries/${data?.id}/`, {
      ...data,
      booking_status: e.target.value,
      property: data?.property,
      unit: data?.unit,
    }).then((res) =>{
      enqueueSnackbar('Inventory status changed!', { variant: `success` })
      fetchInventory()
      handleConfirmStatus()
     
     }).catch((error) =>{
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
      }
      }
     })
}

const retrieveGallary = () =>{
  setGallaryLoading(true)
  apiService.get(`organization/gallery/`).then((res) =>{
      if(res?.data){
          setGallaryItems(res?.data)
      }
      setGallaryLoading(false)
  }).catch((error) =>{
      if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setGallaryLoading(false)
        }
        }
  })
}

const unlinkImageFromUnit = (fileId, imageSubmitLoading) =>{
  imageSubmitLoading(true)
  apiService.post(`property/unit/${id}/remove_media/`, {
      image: fileId
  }).then((res) =>{
      enqueueSnackbar('Removed image from unit', { variant: 'success' })
      imageSubmitLoading(false)
      fetchInventory()
      handleGallaryModal()
  }).catch((error) =>{
      if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            imageSubmitLoading(false)
        }
        }
  })
}

const submitSelectedFilesForUnits = (files, imageSubmitLoading) =>{
  imageSubmitLoading(true)
  apiService.post(`property/unit/${id}/assign_media/`, {
    images: files?.map((e) =>{
      return e?.id
    })
  }).then((res) =>{
    imageSubmitLoading(false)
    fetchInventory()
    handleGallaryModal()
  }).catch((error) =>{
    if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
          imageSubmitLoading(false)
      }
      }
})
}

const getAnalyticTags = () =>{
  apiService.get(`property/prop/get_spec_choices/`).then((res) =>{
  console.log('from analytic tags', res?.data)
  if(res?.data?.data?.length > 0){
     const updateOptions = res?.data?.data?.map((e) =>{
      return {name: e, id: e}
     })
     setAnalyticTags(updateOptions)
  }else{
    setAnalyticTags([])
  }
  }).catch((error) =>{
    if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
          setSubmitLoading(false)
      }
      }
})
}

useEffect(() =>{
  getAnalyticTags()
}, [])


const handleChange = (event, newValue) => {
  localStorage.setItem('property-update-id', `${id}`)
  navigate(`/updates/${event?.id}`)
};

const getBooking = (bookingId) => {
  if (bookingId) {
    setLoading(true);
    apiService
      .get(`booking/entries/${bookingId}/`)
      .then((res) => {
        if (res?.data) {
          if(res?.data?.profile_icon){
            const findIcon = neighboursIcons?.find((e) =>{
              return e?.name?.toLowerCase() === res?.data?.profile_icon
            })
            setNeighbourIcon(findIcon)
            setBooking({...res?.data, profile_icon: findIcon});
          }else{
            setBooking({...res?.data});
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  }
};

const navigateToForm = (navigateTo) =>{
  navigate(navigateTo)
}

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <Box
            sx={{
              borderColor: 'secondary.main',
              padding: BoxEffects.padding,
              marginBottom: BoxEffects.marginButton,
              paddingLeft: BoxEffects.margin,
              paddingRight: headerButton.marginRigt,
            }}
          >
            <BreadCrumbWithActions propArr={arr1} />
          </Box>
          <Box
            sx={{
              borderBottom: BoxEffects.borderBottom,
              borderColor: 'secondary.main',
              padding: BoxEffects.padding,
              marginBottom: BoxEffects.marginButton,
              paddingLeft: BoxEffects.margin,
              paddingRight: headerButton.marginRigt,
            }}
          >
            <UnitOverviewComponent 
              setStatus={setStatus}
              unitStatus={unitStatus}
              onClickEdit={onClickEdit}
              inventoryData={inventoryData}
              handleSideDrawer={handleSideDrawer}
              toggleRemoveModal={toggleRemoveModal}
              handleBookingModal={handleBookingModal}
              handleStatusChange={handleStatusChange}
              handleGallaryModal={handleGallaryModal}
              handleConfirmStatus={handleConfirmStatus}
              confirmCancellation={confirmCancellation}
              toggleEditInventoryModal={toggleEditInventoryModal}
              isReadOnly={userFromOrganization?.role?.name.toLowerCase() === 'admin' || userFromOrganization?.role?.name.toLowerCase() === 'developer' ? false : true }
            />
          </Box>

          {/* <div className='m-6'>
             {updateLoading ? <Loading />
              :
              <>
                {timelineList?.length === 0 ?
                    <EmptyCard  title='No Plans Added' subtitle='Click “Add New” to add the plan' /> 
                    :
                    <Box>
                        <DataGridTable 
                          columns={columns}
                          rows={timelineList}
                          handleEvent={handleChange}
                          actionWidth={80}
                        />
                    </Box>
                }
              </>}
          </div> */}
        </>
      )}
      {openEditInventory && (
        <AddUnit
          title={'Edit Unit'}
          open={openEditInventory}
          onClose={toggleEditInventoryModal}
          data={modifyData(inventoryData)}
          successCallback={() => {
            fetchInventory();
          }}
          templates={templates}
          groups={groups}
          items={items}
          editForm
        />
      )}
      {openRemoveModal && (
        <RemovePopup
          description={`Are you sure you want to delete this unit?`}
          open={openRemoveModal}
          close={toggleRemoveModal}
          onSubmit={handleDeleteInventory}
        />
      )}
      {confirmCancel && (
        <RemovePopup
          description={`Are you sure you want to cancel this booking?`}
          open={confirmCancel}
          close={closeCancel}
          onSubmit={cancelBooking}
        />
      )}

      {openBooking && (
        <AddBooking
          title={'Add Booking'}
          open={openBooking}
          onClose={handleBookingModal}
          isUnit={true}
          data={{
            property: {
              name: JSON.parse(nameString),
              id: inventoryData?.property,
            },
            unit: { name: inventoryData?.name, id: inventoryData?.id },
          }}
          successCallback={() => {
            handleStatusChange(status);
          }}
          organization={organization}
        />
      )}

        {confirmStatus && bookingData?.confirmed_booking?.booking_status && (
              <RemovePopup 
               open={confirmStatus}
               close={handleConfirmStatus}
               description="Are you sure you want to make this unit available? If so, please change the status below."
               isSecondary={handleConfirmStatus}
               isNotRemove={true}
               isAdditionalComponent={true}
               component={
                <>
                  <div className="my-8 w-52">
                    <StatusComponent 
                     data={bookingData}
                     changeStatus={changeBookingStatus}
                     booking_status={bookingData?.confirmed_booking?.booking_status}
                    />
                  </div>
                </>
               }
              />
            )}

           {openGallaryModal && (
                <GallaryModal 
                  open={openGallaryModal}
                  loading={gallaryLoading}
                  onClose={handleGallaryModal}
                  gallaryItems={gallaryItems}
                  fetchProperty={fetchInventory}
                  retrieveGallary={retrieveGallary}
                  image_list={inventoryData?.images}
                  isUnit={true}
                  submitImagesForUnit={submitSelectedFilesForUnits}
                  unlinkingForUnit={unlinkImageFromUnit}
                />
            )}

            {openDetailModal && (
                <GallaryDetailModal 
                    isUnit={true}
                    open={openDetailModal}
                    onClose={handleOpenDetailModal}
                    gallaryItems={inventoryData?.images}
                    activeIndex={activeIndex}
                    retrieveGallary={fetchInventory}
                    analyticTags={analyticTags}
                    setSelectedTags={setSelectedTags}
                    selectedTags={selectedTags}
                    unlinkingForUnit={unlinkImageFromUnit}
                />
           )}

         {removeModal && (
            <RemovePopup 
              open={removeModal}
              close={handleRemoveModal}
              description={"Are you sure, you want to remove this update or want to remove entire property update?"}
              onSubmit={deleteUpdate}
            />
        )}  
        
        {sideDrawer && inventoryData?.confirmed_booking?.id > 0 && (
            <DetailSideDrawer 
              sideTitle={"See in Bookings"}
              sideButtonClickHandler={() =>{
                if(inventoryData?.confirmed_booking?.id){
                    navigateToForm(`/booking/${inventoryData?.confirmed_booking?.id}`)
              }
              }}
              isBookingComponent={true}
              formData={{
                booking: booking,
                neighbourIcon: neighbourIcon
              }}
              open={sideDrawer}
              loading={loading}
              onClose={handleSideDrawer}
              title={`Booking ${`- ${inventoryData?.confirmed_booking?.name}`}`}
              fetchCallback={() =>{
                getBooking(inventoryData?.confirmed_booking?.id)
              }}
            />
        )}

    </>
  );
}
export default InventoryOverview;
