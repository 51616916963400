import { Checkbox } from '@mui/material'
import React, { useState } from 'react'
import RegularCheckbox from '../../../shared-component/OverviewPage/overview-used-components/RegularCheckbox'


function TempDesign4({
    data=[],
    val,
    popupPosition,
    popupClose,
    isTemplateScreen,
    onCheckSubmit = () =>{},
    selectedTemplate,
    isSubmitting
}) {
    const [primaryColor, setPrimaryColor] = useState('#8E8CA3');
    const [secondaryColor, setSecondaryColor] = useState('#353349');
  return (
    <div key={val?.id} className='mx-6 my-4'>
       {val &&  <div
                    className='mb-6 p-2 flex justify-between items-center'
                    style={{
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        color: 'black',
                        width: '350px',
                        height: 'auto',
                        border: '2px solid #E1EBFE'
                    }}
                >Template - SM4 <div><Checkbox disabled={isSubmitting} checked={selectedTemplate?.template_chosen === "TEMPLATE4"} onChange={(e)=>{
                    e.preventDefault()
                    onCheckSubmit('TEMPLATE4')
                }}/></div></div>}
                <div
                    style={{
                        backgroundColor: '#EDECF9',
                        width: '350px',
                        height: 'auto',
                        border: '2px solid #E1EBFE',
                        position: !val && 'absolute',
                        left:!val && `${popupPosition.x}px`,
                        top: !val && `${popupPosition.y}px`,
                        zIndex:  !val && 9999,
                        borderTopLeftRadius:'8px',
                        borderTopRightRadius:'8px'
                    }}
                >
                    <div className='flex items-center justify-between p-4' 
                         style={{ 
                         borderBottom: '2px solid #E1EBFE',
                         background:'#004FE8',
                         borderTopLeftRadius:'8px',
                         borderTopRightRadius:'8px' 
                          }}>
                        <span style={{
                            fontFamily: 'Rubik',
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '24px',
                            textAlign: 'left',
                            color:'white'
                        }}>Linked details</span>
                        <span onClick={popupClose} style={{ color: 'gray', cursor: 'pointer',color:'white' }}>&#10006;</span>
                    </div>
                    <div className='p-4'>
                    <div className='p-4'>
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Unit Name</div>
                            <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                color:`${secondaryColor}`,
                                fontWeight:'bold',
                                width:'150px',
                                textAlign:'center'
                            }}>A101</div>
                    </div>
                    <hr className='bg-[#FFFFFF] h-0.5' />
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Unit Price</div>
                             <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                color:`${secondaryColor}`,
                                fontWeight:'bold',
                                width:'150px',
                                textAlign:'center'
                            }}>1.2Cr</div>
                    </div>
                    <hr className='bg-[#FFFFFF] h-0.5' />
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Total Sqft.</div>
                             <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                color:`${secondaryColor}`,
                                fontWeight:'bold',
                                width:'150px',
                                textAlign:'center'
                            }}>1450 sqft.</div>
                    </div>
                    <hr className='bg-[#FFFFFF] h-0.5' />
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Block</div>
                             <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                 color:`${secondaryColor}`,
                                fontWeight:'bold',
                                width:'150px',
                                textAlign:'center'
                            }}>A Block</div>
                    </div>
                    <hr className='bg-[#FFFFFF] h-0.5' />
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Floor</div>
                             <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                 color:`${secondaryColor}`,
                                fontWeight:'bold',
                                width:'150px',
                                textAlign:'center'
                            }}>1st Floor</div>
                    </div>
                    <hr className='bg-[#FFFFFF] h-0.5' />
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Facing</div>
                             <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                 color:`${secondaryColor}`,
                                fontWeight:'bold',
                                width:'150px',
                                textAlign:'center'
                            }}>East</div>
                    </div>
                    <hr className='bg-[#FFFFFF] h-0.5' />
                    <div className='my-2 flex items-center justify-between'> 
                            <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`,
                                width:'150px'
                            }}>Unit Type</div>
                             <div style={{
                                fontWeight:'bold',
                                color:`${primaryColor}`
                            }}>:</div>
                            <div style={{
                                 color:`${secondaryColor}`,
                                fontWeight:'bold',
                                width:'150px',
                                textAlign:'center'
                            }}>2 BHK</div>
                    </div>
                   </div>
                    </div>

                </div>
    </div>
  )
}

export default TempDesign4