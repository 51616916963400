import React, { useState } from 'react'
import LinkedPopup from './LinkedPopup'

function PathSvg({ 
     val, 
     anchorEl, 
     onclick, 
     triggerRef, 
     setOpenPopup,
     available_color = '#004FE8',
     sold_color = '#FF5C5C'
     }) {
 
    const hotspotClick = (e, data) =>{
        onclick(e, data)
    }
    console.log('from path', val)
    return (
        <path  onClick={(e)=>{
            setOpenPopup(false)
            hotspotClick(e, val)
        }} d={val.path} fill={
            val?.link_type === "GREENSPACE" ? "#24D315" : (val?.unit_status === 'SOLD' ? sold_color : available_color)
           
        } fill-opacity={val?.link_type === "GREENSPACE" ? "1" : "1"} />
    )
}

export default PathSvg