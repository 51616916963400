// ----------------- Documentation ---------------------------
// this component used for add and edit the data from specification list
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup
// edit                    boolead             used for the edit form
// edittitle               string              to maintain the edit form title of the popup
// data                    array               to show the default form value in the edit form

import React, { useContext, useEffect, useState } from "react";
import FormModal from "../../shared-component/formmodal-component/FormModal";
import Form from "../../shared-component/formrelated-component/FormManager";

import UserContext from "../../../context/UserContext";
import { apiService } from "../../../constant/AuthConstant";
import Info from "./Info";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";

import returnError from "../../../utils/ExportError";
import useExit from "../../../utils/useExit";


function AddSpecifications2({
    title,
    open,
    onClose,
    submit,
    templates,
    groups,
    items,
    data,
    successCallback,
    inventorySelection,
    setInventorySelection
}){ 
    const { id } = useParams()
    const [showFields, setShowFields] = useState(false)
    const [templatesList, setTemplateList] = useState(templates);
    const [selectTemplate, setSelectTemplate] = useState(null);
    const [defaultValues, setDefaultValues] = useState({});
    const [fields, setFields] = useState([]);
    const { status } = useContext(UserContext)
    const [templateListWithFields, setTemplateListWithFields] = useState([])
    const [loading, setLoading] = useState(false);
    const [loadForm, setLoadForm] = useState(null);
    const { exitFromPlatform } = useExit()

    const handleShowFields = () =>{
        setShowFields(!showFields)
    }

    const onCloseModal = () =>{
        setTemplateList([])
        setSelectTemplate([])
        setDefaultValues({})
        setFields([])
        setInventorySelection([])
        onClose()
    }


    
    const onSubmit = (mdata) =>{
        const updateFormData = {
            ...mdata,
            groups: mdata?.groups?.filter((e) =>{
                return e.items !== undefined;
            })
        }
        const isValidData = updateFormData?.groups.every(group =>
            group.items.every(item =>
              Object.values(item).every(value =>
                value !== null && value !== undefined && value !== ''
              )
            )
          );
        if(isValidData === false){
            enqueueSnackbar('Please fill the fields', { variant: 'error'})
            return
        }else{
            setLoading(true)
            const newUpdatedData = {
                          ...updateFormData,
                          template_id: selectTemplate?.id,
                          groups: updateFormData?.groups?.map(group => ({
                            ...group,
                            items: group.items.map(item => ({
                              ...item,
                              [Object.keys(item)[1]]: typeof item[Object.keys(item)[1]] === 'object'
                                ? item[Object.keys(item)[1]].id
                                : item[Object.keys(item)[1]],
                            })),
                          })).map((e) =>{
                            return {
                                ...e,
                                items: e?.items?.map((d) =>{
                                    return {
                                        item_id: d?.item_id,
                                        item_value: Object.values(d)[1]
                                    }
                                })
                            }
                          })
                    }
                
                    console.log('from new units', {
                        units: inventorySelection,
                        specs:newUpdatedData
                    })
                    apiService.post(`property/unit/assign_bulk_specs/`, {
                        units: inventorySelection,
                        specs:newUpdatedData
                    }).then((res) =>{
                        enqueueSnackbar('Specifications Assigned to the selected units', { variant: 'success'})
                        setLoading(false)
                        successCallback()
                        onCloseModal()
                    }).catch((error) =>{
                        setLoading(false)
                        if(error.response?.data){
                            const returnedError = returnError(error.response?.data)
                            if(returnedError === 'Given token not valid for any token type'){
                                exitFromPlatform()
                            }else{
                                enqueueSnackbar(returnedError, {variant:'error'})
                            }
                        }
                    })
        }
    }

    const updateGroups = () =>{
        const getTemplates = templates?.map((e) =>{
            return {
                ...e,
                groups : groups?.filter((val) =>{
                    const checkId = val?.templates?.some((s) =>{
                        return s === e?.id
                    })
                    if(checkId === true){
                        return val
                    }
                })
            }
        })
        

        const updateItemsInTemplate = getTemplates?.map((e) =>{
            return {
                ...e,
                groups: e?.groups?.map((val) =>{
                    return {
                        ...val,
                        group_selected: true,
                        is_all_selected: true,
                        fields: items?.filter((d) =>{
                            const checkId = d?.groups?.some((s) =>{
                                return s === val?.id
                            })
                            if(checkId === true){
                                return d
                            }
                        }),
                        items: items?.filter((d) =>{
                            const checkId = d?.groups?.some((s) =>{
                                return s === val?.id
                            })
                            if(checkId === true){
                                return d
                            }
                        }),
                    }
                })
            }
        })
        const updatesItemsWithBoolean = updateItemsInTemplate?.map((e) =>{
            return {
                ...e,
                groups: e?.groups?.map((val) =>{
                    return {
                        ...val,
                        fields: val?.fields?.map((d) =>{
                            return {
                                ...d,
                                selected: true
                            }
                        }),
                        items: val?.items?.map((d) =>{
                            return {
                                ...d,
                                selected: true
                            }
                        }),
                    }
                })
            }
        })
        console.log('from Updated templates', updatesItemsWithBoolean)
       
        return updatesItemsWithBoolean
    }
    
    const updateFieldsAndDefaultValues2 = (temp) =>{
        console.log('from update fields and default values', temp)
        setDefaultValues({})
        setFields([])
        const getDefaultValues = temp?.groups?.filter((e) =>{
            return e?.group_selected === true
        
        })?.map((e) =>{
            return {
                ...e,
                fields: e?.fields?.filter((f) =>{
                    return f?.selected === true
                })
            }
        }).map((e) =>{
            return {
                grp_id: e?.id,
                items: e?.fields?.map((f) =>{
                    return {
                        item_id: f?.id,
                        [f?.name]:""
                    }
                })
            }
        })
        console.log()
        setDefaultValues({
            groups: getDefaultValues
        })
        console.log('from update fields and default values', getDefaultValues)
        const getFieldValues = temp?.groups?.filter((e) =>{
            return e?.group_selected === true
        
        })?.map((e) =>{
            return {
                ...e,
                fields: e?.fields?.filter((f) =>{
                    return f?.selected === true
                })
            }
        })?.map((e, index) =>{
            return {
                name: e?.name,
                label: e?.name,
                type:'box_layout',
                fieldProps:{
                    arrayFields: e?.fields?.map((f,i) =>{
                        if(f?.type === 'DROPDOWN'){
                            return {
                                name: `groups[${index}].items[${[i]}].${f?.name}`,
                                label: f?.name,
                                type: f?.type === "DROPDOWN" ? 'select' : 'text',
                                fieldProps: {
                                    defaultOptions: JSON.parse(f?.dropdown_values).map((r) =>{
                                        return {title: r, id: r}
                                    }),
                                    titleKey: 'title',
                                    tooltip: f?.tooltip || ''
                                }
                            }
                        }else{
                            return {
                                name: `groups[${index}].items[${[i]}].${f?.name}`,
                                label: f?.name,
                                type: f.type === "DROPDOWN" ? 'select' : 'text',
                                fieldProps:{
                                    tooltip: f.tooltip || ''
                                }
                            }
                        }
                    })
                }
            }
        })
        setFields(getFieldValues)
        if(loadForm === null){
            setLoadForm(1)
        }else{
            setLoadForm(loadForm + 1)
        }
    }

    const updateFieldsAndDefaultValues = (temp) =>{
        setDefaultValues({})
        setFields([])
        const getDefaultValues = temp?.groups?.map((e) =>{
            return {
                grp_id: e?.id,
                items: e?.fields?.map((f) =>{
                    return {
                        item_id: f?.id,
                        [f?.name]:""
                    }
                })
            }
        })
        setDefaultValues({
            groups: getDefaultValues
        })

        const getFieldValues = temp?.groups?.map((e, index) =>{
            return {
                name: e?.name,
                label: e?.name,
                type:'box_layout',
                fieldProps:{
                    arrayFields: e?.fields?.map((f,i) =>{
                        if(f?.type === 'DROPDOWN'){
                            return {
                                name: `groups[${index}].items[${[i]}].${f?.name}`,
                                label: f?.name,
                                type: f?.type === "DROPDOWN" ? 'select' : 'text',
                                fieldProps: {
                                    defaultOptions: JSON.parse(f?.dropdown_values).map((r) =>{
                                        return {title: r, id: r}
                                    }),
                                    titleKey: 'title',
                                    tooltip: f?.tooltip || ''
                                }
                            }
                        }else{
                            return {
                                name: `groups[${index}].items[${[i]}].${f?.name}`,
                                label: f?.name,
                                type: f.type === "DROPDOWN" ? 'select' : 'text',
                                fieldProps:{
                                    tooltip: f.tooltip || ''
                                }
                            }
                        }
                    })
                }
            }
        })
        setFields(getFieldValues)
    }

    const updateTemplateAfterUpdate = (list) =>{
        setDefaultValues({})
        setFields([])
        if(list?.length > 0){
            const updateTemplate = list?.map((e) =>{
                return {
                    ...e,
                    name: e?.name,
                }
            })
            console.log('from updated fields', updateTemplate)
            if(selectTemplate?.name){
                
                const find = updateTemplate?.find((e) =>{
                    return e.name === selectTemplate?.name
                }) 
                const filterData = find?.groups?.filter((e) =>{
                    return e?.group_selected === true
                
                })?.map((e) =>{
                    return {
                        ...e,
                        fields: e?.fields?.filter((f) =>{
                            return f?.selected === true
                        })
                    }
                })
                console.log('from filter data', {
                    ...find,
                    groups: filterData
                })
                updateFieldsAndDefaultValues2({
                    ...find,
                    groups: filterData
                })
            }
            setTemplateList(updateTemplate)
          }
    }

    const updateAndCreateFields = () =>{
        const returnedTemplate = updateGroups();
        setTemplateList(returnedTemplate)
       
        if(data?.template_id || data?.template){
            const find = returnedTemplate?.find((e) =>{
                if(data?.tempale_name){
                    return e.template_name === data?.template
                }else{
                    return e.id === data?.template_id
                }
            })
            console.log('from returned template', find)
            setSelectTemplate(find)
            updateFieldsAndDefaultValues(find)
        }else{
            setSelectTemplate(returnedTemplate[returnedTemplate?.length - 1])
            updateFieldsAndDefaultValues(returnedTemplate[returnedTemplate?.length - 1])
          }
        if(selectTemplate?.template_name){
            updateFieldsAndDefaultValues(selectTemplate)
        }
    }
    
   
    useEffect(()=>{
        updateAndCreateFields()
    }, [])

    const returnFieldMap = (list) =>{
        const check = list?.some((e) =>{
            if(e?.fieldProps){
                return true
            }else{
                return false
            }
        })
        return check
    }

    useEffect(() =>{
      console.log('fffffffff', fields)
    }, [selectTemplate, fields, defaultValues])

    const returnKeys = (d) => {
        let keys = Object.keys(d);
          if (keys.length > 0) {
              if(keys.length > 2){
              let lastKey = keys[keys.length - 1];
              delete d[lastKey];
              }
          }
        return d
      }
    
      const checkkeys = (item1, item2) =>{
        if(item2?.length > 0){
          item2.forEach((item2Obj) => {
            const item1Obj = item1.find((item1Obj) => item1Obj.item_id === item2Obj.item_id);
            if (item1Obj) {
              Object.keys(item2Obj).forEach((key) => {
                if (key !== 'item_id') { 
                  item2Obj[key] = item1Obj[key];
                }
              });
            }
          });
          return item2
        }
      }

      const updateAndReturnNewValues = (values, defaultV) =>{
        const updateValues = values?.groups?.map((e) =>{
            const fields = Object.entries(e?.items).map((f) =>{
              return [f[0], returnKeys(f[1])]
          })
            return {
              ...e,
              items: Object.values(Object.fromEntries(fields))
            }
          })
          
          const newValues = {
            ...values,
            groups: updateValues?.map((e) =>{
              const findGroups = defaultV?.groups?.find((f) =>{
                return f?.grp_id === e?.grp_id
              })
              return {
                ...e,
                items: checkkeys(e?.items, findGroups?.items)
              }
            })?.filter((e) =>{
                console.log('from new valuses', e)
                return e?.items !== undefined
            })
          }
           
          return newValues
      }
    return(
        <>
            <FormModal 
              title={title} 
              open={open} 
              onClose={onCloseModal} 
              data={data}
              showFields={showFields}
              headerSelect
              onDefaultOptionClick={handleShowFields}
              templates={templatesList}
              setVal={setSelectTemplate}
              updateFields={updateFieldsAndDefaultValues2}
              withFields={templatesList}
              setTemplateList={setTemplateList}
              updatetemplateData={updateTemplateAfterUpdate}
              defaultTemplate={selectTemplate}
              setFields={setFields}
              setDefaultValues={setDefaultValues}
              setLoadForm={setLoadForm}
              >
              {fields?.length > 0 && returnFieldMap(fields) === true ? (
                <div className="mt-5">
               <Form
                    defaultValues={defaultValues}
                    fieldMap={fields}
                    data={data}
                    onCancel={onCloseModal}
                    formSubmit={onSubmit}
                    //loadForm={loadForm}
                    sx={{
                        marginTop: '15px'
                    }}
                     loadForm={loadForm}
                     primaryBtnTitle="Update"
                     updateValues={updateAndReturnNewValues}
                   // isUnitForm={true}
                />
                </div>
              ): (
                <Info info={`Please select a template`} />
              )}
            </FormModal>
        </>
    )
}
export default AddSpecifications2;