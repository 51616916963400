import React, { useCallback } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DraggableItems from './DraggableItems';
import DraggableComponent from './DraggableComponent';
import { v4 } from 'uuid';

 



const FieldComponent = ({
    formState,
    formTitle,
    setFormState,
    sectionIndex,
    onClickFormSection,
    isSection = false
}) =>{
  
    function handleOnDragEnd(result) {
        if (!result.destination) return;
        if(isSection === true){
            const items = Array.from(formState?.sections[sectionIndex]?.fields);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            
            let cloned = {...formState};
            cloned = {
              ...cloned,
              sections: cloned?.sections?.map((s, index) =>{
                if(index === sectionIndex){
                   return {
                       ...s,
                       fields: items
                   }
                }else{
                    return s
                }
              })
            }
            setFormState(cloned)
        }else{
            const items = Array.from(formState?.fields);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            
            let cloned = {...formState};
            cloned = {
                ...cloned,
                fields: items
            }
            setFormState(cloned)
        }
      }
    
    const onDeleteItem = (target) =>{
        if(isSection === true){

            let cloned = {...formState}
                const newFieldsAfterDelete = cloned?.sections[sectionIndex]?.fields?.filter((e) =>{
                    console.log('from id', target?.id, e?.id)
                    return e?.id !== target?.id
                })
                cloned = {
                ...cloned,
                sections: cloned?.sections?.map((s, index) =>{
                    if(index === sectionIndex){
                    return {
                        ...s,
                        fields: newFieldsAfterDelete
                    }
                    }else{
                        return s
                    }
                })
                
            }
        setFormState(cloned)
        }else{
        
            let cloned = {...formState}
            const newFieldsAfterDelete = cloned?.fields?.filter((e) =>{
                return e?.id !== target?.id
            })
            cloned = {
                ...cloned,
                fields: newFieldsAfterDelete
            }
            setFormState(cloned)
        }
    }

    const deleteOptions = (field, option) =>{
      if(isSection === true){
        let cloned = {...formState}
        const newFieldsOptionsAfterDelete = cloned?.sections[sectionIndex]?.fields?.map((e) =>{
            if(field?.id === e?.id){
              const allOptions = [...e?.field_options];
              return {
                ...e,
                field_options: allOptions?.filter((f) =>{
                  return f?.id !== option?.id
                })
              }
            }else{
              return e
            }
        })
        cloned = {
          ...cloned,
          sections: cloned?.sections?.map((s, index) =>{
            if(index === sectionIndex){
               return {
                   ...s,
                   fields: newFieldsOptionsAfterDelete
               }
            }else{
                return s
            }
          })
          
      }

        setFormState(cloned)
       }
       else{
        let cloned = {...formState}
        const newFieldsOptionsAfterDelete = cloned?.fields?.map((e) =>{
            if(field?.id === e?.id){
              const allOptions = [...e?.field_options];
              return {
                ...e,
                field_options: allOptions?.filter((f) =>{
                  return f?.id !== option?.id
                })
              }
            }else{
              return e
            }
        })
        cloned = {
            ...cloned,
            fields: newFieldsOptionsAfterDelete
        }

        setFormState(cloned)
       }
    }

    const addOptions = (field) =>{
      if(isSection === true){
        let cloned = {...formState}
         
          const newFieldsOptions = cloned?.sections[sectionIndex]?.fields?.map((e) =>{
            
              if(field?.id === e?.id){
                 const allOptions = [...e?.field_options];
               
                 return {
                  ...e,
                  field_options: [...allOptions, { value: '', id: v4()}]
                 }
              }else{
                return e
              }
          })
           
          cloned = {
            ...cloned,
            sections: cloned?.sections?.map((s, index) =>{
              if(index === sectionIndex){
                 return {
                     ...s,
                     fields: newFieldsOptions
                 }
              }else{
                return s
            }
            })
            
        }
          setFormState(cloned)
       }
       else{
        let cloned = {...formState}
       
          const newFieldsOptions = cloned?.fields?.map((e) =>{
            
              if(field?.id === e?.id){
                 const allOptions = [...e?.field_options];
             
                 return {
                  ...e,
                  field_options: [...allOptions, { value: '', id: v4()}]
                 }
              }else{
                return e
              }
          })
         
          cloned = {
              ...cloned,
              fields: newFieldsOptions
          }
          setFormState(cloned)
       }
    }

    const renderDraggableOptions = useCallback((field, index) =>{
      if(isSection === true){
      
        return(
            formState?.sections[sectionIndex]?.fields[index]?.field_options?.length > 0 && (
              <DraggableComponent 
                index={index}
                fieldItem={field}
                formState={formState}
                setFormState={setFormState}
                deleteOptions={deleteOptions}
                list={formState?.sections[sectionIndex]?.fields[index]?.field_options}
                sectionIndex={sectionIndex}
                isSection={true}
                formTitle={formTitle}
            />
            )
            
          )
       }
       else{
        return(
            formState?.fields[index]?.field_options?.length > 0 && (
              <DraggableComponent 
              index={index}
              fieldItem={field}
              formState={formState}
              setFormState={setFormState}
              deleteOptions={deleteOptions}
              list={formState?.fields[index]?.field_options}
              isSection={false}
            />
            )
          )
       }
    }, [formState])

    const renderFormState = useCallback(() =>{
     
        return formState?.fields?.length > 0 ? 
        <DragDropContext 
              onDragEnd={handleOnDragEnd}
            >
            <Droppable droppableId="droppable">
                {(provided) => (
                    <>
                    <div className="getStage" {...provided.droppableProps} ref={provided.innerRef}>
                         {formState?.fields?.map((field, index) =>{
                            return (
                            <Draggable key={field.id} draggableId={field.id} index={index}>
                            {(provided) => (
                               <DraggableItems 
                               index={index}
                               provided={provided}
                               field={field}
                               formState={formState}
                               setFormState={setFormState}
                               renderDraggableOptions={renderDraggableOptions}
                               addOptions={addOptions}
                               onDeleteItem={onDeleteItem}
                               onClickFormSection={onClickFormSection}
                               isSection={false}
                               formTitle={formTitle}
                               />
                             
                             )}
                            </Draggable>
                            )
                         })}
                    </div>
                    </>
                )}
              </Droppable>
        </DragDropContext>
       :
       <></>
      }, [formState])

      const renderSectionFormState = useCallback(() =>{
        return formState?.sections[sectionIndex]?.fields?.length > 0 ? 
        <DragDropContext 
              onDragEnd={handleOnDragEnd}
            >
            <Droppable droppableId="droppable">
                {(provided) => (
                    <>
                    <div className="getStage" {...provided.droppableProps} ref={provided.innerRef}>
                         {formState?.sections[sectionIndex]?.fields?.map((field, index) =>{
                            return (
                            <Draggable key={field.id} draggableId={field.id} index={index}>
                            {(provided) => (
                               <DraggableItems 
                               index={index}
                               provided={provided}
                               field={field}
                               formState={formState}
                               setFormState={setFormState}
                               renderDraggableOptions={renderDraggableOptions}
                               addOptions={addOptions}
                               onDeleteItem={onDeleteItem}
                               sectionIndex={sectionIndex}
                               onClickFormSection={onClickFormSection}
                               isSection={isSection}
                               formTitle={formTitle}
                               />
                             
                             )}
                            </Draggable>
                            )
                         })}
                    </div>
                    </>
                )}
              </Droppable>
        </DragDropContext>
       :
       <></>
      }, [formState])
      
    return  isSection === true ? renderSectionFormState() : renderFormState()
}

export default FieldComponent