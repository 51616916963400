import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import Loading from '../../Loading/Loading';
import SecondaryBtn from '../../button-component/SecondaryBtn';
import PrimaryBtn from '../../button-component/PrimaryBtn';
import CloseIcon from '@mui/icons-material/Close';

 
import { useDropzone } from 'react-dropzone';
import GallaryDetailModal from './GallaryDetailModal';
 
import EditIcon from '@mui/icons-material/Edit';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DefaultImagesWithProgress from './DefaultImagesWithProgress';
import Info from '../../../components/properties/Info';
import { apiService } from '../../../../constant/AuthConstant';
import { useParams } from 'react-router-dom';
import useExit from '../../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import returnError from '../../../../utils/ExportError';
import { v4 } from 'uuid';

const GallaryModal = ({
    open,
    onClose,
    loading,
    gallaryItems,
    retrieveGallary,
    fetchProperty,
    image_list,
    isTimeline = false,
    onTimelineSubmit,
    onTimelineUnlinking,
    isUnit = false,
    submitImagesForUnit,
    unlinkingForUnit
}) =>{
    const { id } = useParams()
    const { exitFromPlatform } = useExit()
    const [activeIndex, setActiveIndex] = useState(null);
    const [selectedFile, setSelectedFile] = useState([]);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [imagesToUpload, setImagesToUpload] = useState([]);
    const [imageList, setImagesList] = useState(gallaryItems);
    const [submitLoading, setSubmitLoading] = useState(false)
    const [uploadedImage, setUploadedImages] = useState([]);
    const [analyticTags, setAnalyticTags] = useState({name: '', id: ''});
    const [selectedTags, setSelectedTags] = useState([])

    const handleOpenDetailModal = () =>{
        setOpenDetailModal(!openDetailModal)
    }

    const onDrop = (files) =>{
      const newFiles = files?.map((e) =>{
        return {
          file: e,
          id: v4()
        }
      })
      setImagesToUpload(newFiles)
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
          'image/png': ['.png'],
          'image/jpeg': ['.jpeg'],
          'image/jpg': ['.jpg'],
          'image/webp': ['.webp'],
          'image/apng': ['.apng'],
          'video/mp4': ['.mp4'],
        }
    });

    useEffect(() =>{
       if(gallaryItems?.length > 0){
           const list = [];
           image_list?.forEach((item) =>{
            const checkIfExist = gallaryItems?.some((e) =>{
              return e?.id === item?.id
            })
            if(checkIfExist === true){
                list.push(item)
            }
          })
          setSelectedFile(list)
          setImagesList(gallaryItems)
       }
    }, [gallaryItems])

    useEffect(() =>{
      if(imagesToUpload?.length === 0){
        retrieveGallary()
      }
    }, [imagesToUpload])

    useEffect(() =>{
      if(imagesToUpload?.length === 0){
        retrieveGallary()
      }
    }, [imagesToUpload])

    const chooseImages = (image) =>{
      const check = selectedFile?.some((e) => e?.id === image?.id);
      console.log('from check to select', check, image?.id, selectedFile)
      if(check === true){
        const filterImages = selectedFile?.filter((e) =>{
            return e?.id !== image?.id
        })
        setSelectedFile(filterImages)
      }else{
        const cloneImages = [...selectedFile, image]
        setSelectedFile(cloneImages)
      }
    }

    const submitSelectedFiles = () =>{
      setSubmitLoading(true)
      apiService.post(`property/prop/${id}/assign_media/`, {
        images: selectedFile?.map((e) =>{
          return e?.id
        })
      }).then((res) =>{
        setSubmitLoading(false)
        fetchProperty()
        onClose()
      }).catch((error) =>{
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setSubmitLoading(false)
          }
          }
    })
    }


    const getAnalyticTags = () =>{
      apiService.get(`property/prop/get_spec_choices/`).then((res) =>{
      console.log('from analytic tags', res?.data)
      if(res?.data?.data?.length > 0){
         const updateOptions = res?.data?.data?.map((e) =>{
          return {name: e, id: e}
         })
         setAnalyticTags(updateOptions)
      }else{
        setAnalyticTags([])
      }
      }).catch((error) =>{
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setSubmitLoading(false)
          }
          }
    })
    }
   
    useEffect(() =>{
      getAnalyticTags()
   }, [])

    useEffect(() =>{
       console.log('from property', selectedFile)
    }, [selectedFile])

    

    const onClickEdit = (index) =>{
        console.log(index)
         setActiveIndex(index)
         handleOpenDetailModal()
    }

    const imagesContainer = useCallback(() =>{
       return imageList?.map((e, i) =>{
        return(
          <div key={i} className="relative group">
           {e?.filetype === 'video' ?
                    <video className='object-cover cursor-pointer' 
                    src={e?.media} 
                    style={{
                        width: '150px', 
                        height:'150px', 
                        border: selectedFile?.some((s) => s?.id === e?.id) === true ? "2px solid #24D315" : "none", 
                        borderRadius:'4px'
                    }} 
                  />
                  :
                  <img className='object-cover cursor-pointer' 
                  src={e?.media} 
                  style={{
                      width: '150px', 
                      height:'150px', 
                      border: selectedFile?.some((s) => s?.id === e?.id) === true ? "2px solid #24D315" : "none", 
                      borderRadius:'4px'
                  }} 
              />}
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                  <div className='flex items-center justify-between m-1.5'>
                      <div className='cursor-pointer' onClick={() =>{
                          chooseImages(e)
                      }}>
                          {selectedFile?.some((s) => s?.id === e?.id) === true ? 
                           <CheckCircleOutlineIcon className='!fill-[#24D315]' />
                            :
                           <PanoramaFishEyeIcon className='!fill-white' />
                          }
                      </div>
                      <div className='cursor-pointer' onClick={() =>{
                          onClickEdit(i)
                      }}>
                           <EditIcon className='!fill-white' />
                      </div>
                  </div>
            </div>
          </div>
        )
     })
    }, [imageList, selectedFile])

    console.log('from selected file', selectedFile, imageList)

    const imageToUploadContainer = useCallback(() =>{
      return imagesToUpload?.map((e) =>{
        return (
            <DefaultImagesWithProgress 
              key={e?.id}
              image={e} 
              imageList={imageList}
              setImagesList={setImagesList}
              imagesToUpload={imagesToUpload}
              setImagesToUpload={setImagesToUpload}
              setUploadedImages={setUploadedImages}
              uploadedImage={uploadedImage}
            />
        )
      })
    }, [imagesToUpload])

    return(
       <>
          <Dialog
          open={open}
          onClose={onClose}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
               width: '95%',
               maxWidth: '95%',
               height: '100%',
            },
          }}
        }
        >
           <div className='bg-white relative h-full'>
                 <div className='w-full absolute'>
                    <div className='flex items-center justify-between mx-8 my-4'>
                        <div className='' style={{
                            fontFamily: 'Rubik',
                            fontSize: '24px',
                            fontWeight: 400,
                            lineHeight: '37.92px',
                            letterSpacing: '-0.022em',
                        }}>
                            Add From Gallery
                        </div>
                        <div className='' onClick={onClose}>
                            <CloseIcon className='!fill-[#000000] cursor-pointer' />
                        </div>
                    </div>
                 </div>
                  
                {loading ? <Loading />
                    :
                    <>
                     {gallaryItems?.length > 0 ?
                      <div className='h-[90%] mx-8 mt-16'>
                      <div className='max-h-[80%] overflow-y-scroll flex flex-wrap gap-[15px]'>
                         {imagesContainer()}
                         {imagesToUpload?.length > 0 && (
                            imageToUploadContainer()
                          )}
                      </div>
                      <div className='flex items-center justify-end my-8 absolute bottom-0 right-[26px]'>
                          <SecondaryBtn 
                            title="Cancel" 
                            onClick={onClose}
                            style={{ boxShadow: 'none', fontWeight: 300, color: "#004FE8", background: 'transparent' }} 
                            />
                           <div {...getRootProps()} className='relative'>
                              <SecondaryBtn 
                              title="Upload New" 
                              style={{ boxShadow: 'none', fontWeight: 300 }} 
                              />
                              <input className='absolute' {...getInputProps()} style={{ opacity:0 }} />
                           </div>
                           {isTimeline ? 
                            <PrimaryBtn 
                            title="Save" 
                            disabled={submitLoading}
                            onClick={() =>{
                              onTimelineSubmit(selectedFile)
                            }}
                            style={{ boxShadow: 'none', fontWeight: 300 }} 
                          />
                          :
                          (
                            isUnit === true ?
                            <PrimaryBtn 
                            title="Save" 
                            disabled={submitLoading}
                            onClick={() =>{
                              submitImagesForUnit(selectedFile, setSubmitLoading)
                            }}
                            style={{ boxShadow: 'none', fontWeight: 300 }} 
                          />
                          :
                          <PrimaryBtn 
                          title="Save" 
                          disabled={submitLoading}
                          onClick={submitSelectedFiles}
                          style={{ boxShadow: 'none', fontWeight: 300 }} 
                        />
                          )}
                      </div>
                  </div>
                    :
                    <Info 
                      info="There are no images in the gallary"
                    />
                    }
                    </>}
              
           </div>
        </Dialog>
         
        {openDetailModal && (
            <GallaryDetailModal 
              open={openDetailModal}
              onClose={handleOpenDetailModal}
              gallaryItems={gallaryItems}
              activeIndex={activeIndex}
              retrieveGallary={retrieveGallary}
              fetchProperty={fetchProperty}
              analyticTags={analyticTags}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              isTimeline={isTimeline}
              onTimelineUnlinking={onTimelineUnlinking}
              isUnit={isUnit}
              submitImagesForUnit={submitImagesForUnit}
              unlinkingForUnit={unlinkingForUnit}
            />
        )}


       </>
    )
}

export default GallaryModal
