import React, { useState } from 'react';
import AuthLayout from '../../shared-component/AuthLayoutPage/AuthLayout';
import SpotMink from '../../../asset/Images/SpotMink.png'
import PublicGroup from '../../../asset/Images/PublicGroup.png'
import { apiService } from '../../../constant/AuthConstant';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

const OrganizationSetup = () =>{
    const navigate = useNavigate();
    const [step, setStep] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false)
    const formValues={
        legal_name:'',
        brand_name:'',
        description:'',
        brand_logo:'',
        email:'',
        phone:'',
        address:''
    }
    const schema = yup.object().shape({
        legal_name: yup.string().required('Please enter first name'),
        brand_name: yup.string().required('Please enter last name'),
        description: yup.string().required('Please enter description'),
       
      });
    
      const schema2 = yup.object().shape({
        email: yup.string().email('You must enter a valid email').required('You must enter a email'),
        phone: yup.string().max(10, "Please add valid phone number").matches( /^(?=.*?[0-9]).{10,}$/,
        "Must contain min 10 characters"
      ),
       address: yup.string().required("Please add address")
      });


      
    const field = [
        {id:1,label:'Legal Name', type:"text", value:'',name:'legal_name'},
        {id:2,label:'Brand Name', type:"text", value:'',name:'brand_name'},
        {id:3,label:'Description', type:"textarea", value:'',name:'description'},
        {id:4,label:'Brand Logo', type:"file", value:'',name:'brand_logo'},
    ];

    const field2 = [
        {id:6,label:'Email', type:"text", value:'',name:'email'},
        {id:7,label:'Phone', type:"text", value:'',name:'phone'},
        {id:8,label:'Address', type:"textarea", value:'',name:'address'},
    ]

    const onContinue = () =>{
       if(selectedFile !== null){
        setStep("step2")
       }else{
        enqueueSnackbar('Please add Brand Logo', {
            variant: 'danger',
           });
       }
    }

    const onFileSelection = (file) =>{
        
        console.log('from preview', file[0])
        const image = URL.createObjectURL(file[0])
        setPreview(image)
        setSelectedFile(file[0]);
    }

    const onSubmit = (data) =>{
        setLoading(true)
        console.log(data)
        const formData = new FormData();
        formData.append('brand_logo', selectedFile);
        formData.append('legal_name', data.legal_name)
        formData.append('name', data.brand_name)
        formData.append('email', data.email)
        formData.append('address', data.address)
        formData.append('phone_number', data.phone)
        formData.append('description', data.description)
        console.log('from data', formData)
        apiService.post('/organization/org/', formData).then(()=>{
            setLoading(false)
             navigate("/")
             console.log('Worked')
        }).catch((error)=>{
            setLoading(false)
            enqueueSnackbar(error.message, {
                variant: 'error',
               });
            console.log('from organization', error)
        })
    }
    return(
        <>
          <AuthLayout
              companyLogo={SpotMink} 
              publicSideImage={PublicGroup} 
              fields={step === "step2" ? field2 : field}
              actionTitle={step === "step2" ? "Submit" : "Continue"}
              pageTitle={step === "step2" ? 'Contact Details' : 'Organization Profile'}
              onSubmit={step === "step2" ? onSubmit : onContinue}
              additionalEndLink={step === "step2" ? '' : 'Login'}
              addtionaEndText={step === "step2" ? '' : 'Go back to'}
              endLink={"/"}
              preview={preview}
              onBackPress={()=>{
                setStep('step1')
              }}
              onFileSelection={onFileSelection}
              schema={step === "step2" ? schema2 : schema}
              isSubmitting={loading}
          />
        </>
    )
}

export default OrganizationSetup