import React, { useState } from 'react';
import { TokenStorage } from '../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import usePersistantTimer from './Timer';

const useExit = () => {
    const IDLE_TIMEOUT = 2 * 60 * 60 * 1000; // 2 hours
    const [count, start, pause, reset] = usePersistantTimer(IDLE_TIMEOUT);
    const [notified, setNotified] = useState(false);
    const navigate = useNavigate();

    const exitFromPlatform = () => {
        if (!notified) {
            setNotified(true);
           
            pause();
            reset();
            TokenStorage.clear();
            localStorage.clear();
            // enqueueSnackbar('Session Expired', { variant: 'success' });
            localStorage.setItem('isloggedOut', 'yes')
            navigate('/');
        }
    };

    return {
        exitFromPlatform
    };
};

export default useExit;
