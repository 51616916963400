import React from 'react'
import {
    Select,
    MenuItem,
    FormControl,
    Autocomplete,
    TextField,
    InputAdornment,
  } from '@mui/material';
  import ExpandMore from '@mui/icons-material/ExpandMore';
  import { styled } from '@mui/material/styles';

const UrlSelection = ({
    list, 
    open,
    setOpen,
    value, 
    onChange,
}) =>{
    
    const StyledListItem = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between !important',
        width:'100%',
        padding: theme.spacing(1),
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
          '& .icon-container svg': {
            fill: '#8E8CA3 !important',
          },
        },
      }));
     console.log('from list', list)
    return(
        <Autocomplete
          open={open}
          value={value}
          options={list}
          getOptionLabel={(option) => option.page_title}
          onChange={(e, newValue, reason) => {
            onChange(newValue);
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onBlur={(event, newValue) =>{
             onChange({
              id: list?.length + 1,
              page_title: event.target.value,
              url: event.target.value,
              linked_page: event.target.value
             });
          }}
          disablePortal={false}
          isOptionEqualToValue={(option, val) => option.id === val.id}
          popupIcon={<ExpandMore className="!fill-[#8E8CA3]" />}
          renderOption={(props, option) => (
            <StyledListItem {...props}>
               <div>{option.page_title?.length > 20 ? `${option.page_title?.substring(0, 30)}...` : option?.page_title}</div>
                <div className="icon-container">{option.url}</div>
            </StyledListItem>
          )}
          
          renderInput={(params) => (
            <TextField
              {...params}
              label="URL"
              placeholder="Select"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                background:'white'
              }}
            //   InputProps={{
            //     ...params.InputProps,
            //     startAdornment: icon ? (
            //       <InputAdornment position="end">{icon.icon}</InputAdornment>
            //     ) : null,
            //   }}
            />
          )}
        />
    )
}

export default UrlSelection