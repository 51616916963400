import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { API_URL, apiService } from '../../../../constant/AuthConstant'
import LinkedPopup from './LinkedPopup/LinkedPopup';
import PathSvg from './LinkedPopup/PathSvg';

import { useNavigate } from 'react-router-dom';


function MapViewComp({ 
    mapData, 
    allHotSpot, 
    template, 
    hotspotPaths,
    templateConfig
   }) {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [data, setData] = useState(null);
  const [hotsSpotIdGetMap, setHotspotIdGetMap] = useState('')
  const [templateMode, setTemplateMode] = useState(null);
  const [unitData, setUnitData] = useState(null);
  const [loading, setLoading] = useState(false)
  const [formatted, setFormatted] = useState([]);
  const propertyData = JSON.parse(localStorage.getItem('propertyData'))
  // Function to open the popup at the clicked position

  const updateMasonaryData = (newData) =>{
   
    const updatedNewData = {
      ...newData
    }
    delete updatedNewData?.template
    delete updatedNewData?.template_id 
    delete updatedNewData?.template_name 
    // delete updatedNewData['Lot']
    // delete updatedNewData['Block']
    // delete updatedNewData['Location']
    // delete updatedNewData['price']
    console.log('from formatted data', newData)
    const getDataWithHeader = updatedNewData?.groups?.map((e) =>{
      console.log('from formatted data', e)
      return {
        header: e?.group_name || '',
        data: e?.items?.map((f) =>{
         delete f?.item_id;
         console.log('from f', f, Object?.entries(f)[0][0])
         return {
          name: f?.item?.name,
          value: Object?.entries(f)[0][1]?.id ? Object?.entries(f)[0][1]?.id : Object?.entries(f)[0][1]
         }
      })
      }
    })?.filter((e)=>{
      return e?.data?.length !== 0
    })
    setFormatted(getDataWithHeader)
   }

  const popupOpen = (event, data) => {
    // setHotspotIdGetMap(id)
    
    if(typeof data?.linked_unit?.id !== 'number' && data?.linked_url === null && data?.linked_map === null){
      setPopupPosition({ x: event.pageX, y: event.pageY }); // Set the position based on click coordinates
      setTemplateMode(5)
      setOpenPopup(true);
    }
    
    if(data?.linked_unit !== null){
      if(typeof data?.linked_unit?.id === 'number'){
        setTemplateMode(2)
        setLoading(true)
        setPopupPosition({ x: event.pageX, y: event.pageY }); // Set the position based on click coordinates
        setOpenPopup(true);
        
        apiService.get(`property/unit/${data?.linked_unit?.id}`).then((res) =>{
        
        if(res?.data?.specs){
          if(res?.data?.output_specs?.groups){
            const newData = [...res?.data?.output_specs?.groups]
           
            const updateSpecsGroups = newData?.map((e) =>{
                  const findGroup = res?.data?.specs?.groups?.find((f) =>{
                    return f?.grp_id === e?.grp_id
                  })
                  console.log('from itemNameg', findGroup)
                  return {
                    "grp_id": e?.grp_id,
                    "items": e?.items?.map((f) =>{
                      const findItem = findGroup?.items?.find((g) =>{
                        return g?.item_id === f?.item_id
                      })
                      console.log('from itemName', findItem)
                      return {
                         ...f,
                        [f?.item?.name]: findItem?.item_value,
                      }
                    })
                  }
            })
            
            let newArray = [...updateSpecsGroups];
            console.log('from arrayueruiwqoeiuowi', newArray)
            if(propertyData?.popup_config?.length > 0){
              console.log('from unit template2', propertyData?.popup_config?.length > 0)
              const unitTemplate = propertyData?.popup_config?.find((val) =>{
                return val?.template_id === res?.data?.specs?.template_id
            })
           
            const updateData = newArray?.map((val) =>{
              const findGroup = unitTemplate?.groups?.find((e) =>{
                return e?.grp_id === val?.grp_id
              })
              console.log('from unit template2.....', findGroup)
              return {
                ...val,
                group_name: res?.data?.output_specs?.groups?.find((e) =>{
                  return e?.grp_id === val?.grp_id
                })?.group?.name,
                items: val?.items?.filter((e) =>{
                  console.log('from unit template2..', e?.item_id, findGroup?.item_ids)
                  const check = findGroup?.item_ids?.some((f) =>{
                    return f === e?.item_id
                  })
                  if(check === true){
                    return e
                  }
                })
              }
            })
            console.log('from unit template2')
            updateMasonaryData({
              ...res?.data?.specs,
              groups: updateData
            })
             }else{
              updateMasonaryData({
                ...res?.data?.specs,
                groups: newArray
              })
             }
           
          }
        }
        setLoading(false)
        }).catch((error) =>{
          console.log('from unit error', error)
        })
      }
    }
    
    if(data?.linked_map !== null){
      const url = document.location.href;
      const regex = /\/properties\/(\d+)\/plan\//;
      const match = url.match(regex);

      const ids = match ? match[1] : null;
      navigate(`/properties/${ids}/map/${data?.linked_map?.id}`)
    }

    if(data?.linked_url !== null){
      window.open(data?.linked_url);
    }

    if(data?.greenspace_label !== null){
      setTemplateMode(6)
      setData(data)
      setPopupPosition({ x: event.pageX, y: event.pageY }); // Set the position based on click coordinates
      setOpenPopup(true);
    }
   
  };

 
  // Function to close the popup
  const popupClose = () => {
    setOpenPopup(false);
  };

  return (
    <>
      <div className='flex justify-center w-full' style={{
        height: '500px',
        position:"relative",
      }} >
        <svg style={{ 
          zIndex: 9,
          position:'absolute',
          width:'100%',
          height:'auto',
          marginLeft:'2px'
           }} viewBox={mapData?.viewbox} xmlns="http://www.w3.org/2000/svg">
             {hotspotPaths?.map((val) => {
              console.log('from unit hotspots', val)
              return (
                <PathSvg 
                 val={val} 
                 onclick={popupOpen} 
                 setOpenPopup={setOpenPopup} 
                 available_color={mapData?.property?.available_color}
                 sold_color={mapData?.property?.sold_color}
                />
              )
             })}
      </svg>
      <img  
        style={{ 
          position: 'absolute',
          padding:0,
          width:'100%',         
          height:'auto',
        }} 
        src={mapData?.image || 'https://spotmink.com//category-backgrounds/V4_jq8wL3w.jpg'} 
        alt="map_image" /></div>
     
      {openPopup && (
       <LinkedPopup 
         template={templateMode} 
         popupClose={popupClose} 
         popupPosition={popupPosition} 
         data={data} 
         hotspotId={hotsSpotIdGetMap}
         loading={loading}
         formatted={formatted}
         unitData={unitData}
         selectedTemplate={templateConfig}
         propertyName={propertyData?.name}
         />
      )}
    </>
  )
}

export default MapViewComp