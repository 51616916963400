import { createTheme } from "@mui/material/styles";


  const lightText = {
    primary: 'rgb(17, 24, 39)',
    secondary: 'rgb(107, 114, 128)',
    disabled: 'rgb(149, 156, 169)',
  };
  export const commonText = {
    black: 'rgb(17, 24, 39)',
    white: 'rgb(255, 255, 255)',
    grey: '#8E8CA3'
  };
  const mainPrimary = {
    primary:'#004FE8',
    subPrimary:'#1061CC'
  }
  const mainSecondary = {
    secondary: '#EDECF9',
  }
  const fontColor = {
    black: "#353349",
  }
  const modalColor = {
    primary:'#EDECF9'
  }
  const stepColor = {
    primary:"#24D315",
    stepPrimary: "#8E8CA3",
  }
  const errorColor = {
    main: '#FF5C5C',
  }

   
export const themes = createTheme({
  typography: {
    fontFamily: 'Rubik',
    h6 :{
        fontWeight: 500,
        fontSize: "32px",
        lineHeight: "38px",
        color:fontColor.black,
     }
  },
  status: {
    main: "#004FE8",
},
  palette: {
    mode: 'light',
    text: lightText,
    common: commonText,
    action: {
      disabledBackground: 'rgb(149, 156, 169)',
      disabled: '#fff',
      disabledOpacity:0.38,
    },
    default: {
      light: '#f1f6ff',
      main: '#004FE8',
      dark: 'rgb(156, 163, 175)',
      contrastText: lightText.primary,
    },
    primary: {
      light: '#97C4FF',
      main: '#004FE8',
      dark: '#1061CC',
      contrastText: '#fff',
    },
    secondary: {
      light: '#EDECF9',
      main: '#EDECF9',
      dark: '#D9D7EF',
      contrastText: '#000',
    },
    success: {
      light: '#9CE496',
      main: '#24D315',
      dark: '#1EAF11',
      contrastText: '#fff',
    },
    error: {
      light: '#FFA7A7',
      main: '#FF5C5C',
      dark: '#E32424',
      contrastText: '#fff',
    },
    warning: {
      light: '#FFF2B1',
      main: '#FAD200',
      dark: '#BA9E0E',
      contrastText: '#fff',
    },
    inherit: {
      light: '#FFF2B1',
      main: '#8E8CA3',
      dark: '#BA9E0E',
      contrastText: '#fff',
    }
   
  },
 
  components:{
    MuiButton:{
      styleOverrides:{
        root:{
          marginLeft: 0,
          marginRight: 0,
          textTransform:'capitalize',
        },
        sizeSmall: {
          "&.MuiButton-sizeSmall:hover": {
            border: '1px solid #8E8CA3'
          }
        }
      }
    },
   
      MuiSwitch: {
        styleOverrides:{
          root:{
            padding: 8,
            '& .MuiSwitch-track': {
              borderRadius: 22 / 2,
              '&:before, &:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
              },
              
            },
            '& .MuiSwitch-thumb': {
                    boxShadow: 'none',
                    width: 16,
                    height: 16,
                    margin: 2,
                  },
          },
        }
        
      },
    
    MuiToggleButton: {
      styleOverrides: {
        root: {
          transition: "",
          width: "6.30rem",
          height: "2.3rem",
          textTransform: "capitalize",
          "&:hover": { backgroundColor: "transparent" },
          "&.MuiButtonBase-root": { 
            borderRadius: "50px !important",
            color: "#000",
            backgroundColor: mainSecondary.secondary,
            border:'none'
          },
          "&.MuiToggleButtonGroup-grouped": {
            borderRadius: "50px !important",
            color: "#000",
            backgroundColor: mainSecondary.secondary,
            border:'none'
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            color: "#fff",
            backgroundColor: mainPrimary.primary,
            border:'none',
          },
        }
      },
    },
    MuiDataGrid: {
      styleOverrides: {
          root: {
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
                },
                '&.MuiDataGrid-root': {
                border: 'none',
                },
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus':{
                  outline: 'none'
                },
              },
            },
      },
    MuiList: {
        styleOverrides: {
            root: {
                color:commonText.grey,
              },
        },
      },
      MuiTab: {
        styleOverrides: {
            root: {
                textTransform:'capitalize',
                fontWeight: 400
              },
        },
      },
      
      MuiListItem: {
        styleOverrides: {
            root: {
                margin:"10px 0px",
                "&Mui-selected": {
                    backgroundColor: mainPrimary.primary,
                    color:"#fff"
                },
                "&Mui-selected:hover": {
                    backgroundColor: mainPrimary.primary,
                }
              },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
            root: {
                borderRadius:"4px",
                margin:"0 10px",
                "&.Mui-selected": {
                    backgroundColor: mainPrimary.primary,
                    color: "white",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: mainPrimary.primary,
                  },
                },
              },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    borderRadius:"4px",
                    },
                  },
            },
          MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color:commonText.grey,
                  },
            },
          },
          MuiListItemText: {
            styleOverrides: {
                root: {},
            },
          },
          MuiAppBar: {
            styleOverrides: {
                root: {
                    color:fontColor.black,
                    backgroundColor: modalColor.primary
                  },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                borderRadius: 4,
                maxWidth:650,
                width:650,
              },
            },
          },
          MuiDialogContent: {
            styleOverrides: {
              root: {
                paddingTop: 16,
                paddingBottom: 12,
                paddingLeft: 24,
                paddingRight: 24,
              },
            },
          },
          MuiDialogActions: {
            styleOverrides: {
              root: {
                justifyContent: 'flex-end',
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 0,
                paddingBottom: 24,
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              rounded: {
                borderRadius: 8,
              },
              "& .MuiCard-root": {
                 margin: '0px !important'
              }
            },
          },
          MuiDrawer:{
            styleOverrides:{
                paperAnchorRight:{
                    borderBottomLeftRadius:8,
                    borderTopLeftRadius:8,
                    width:472,
                    padding: 20
                }
            }
        },
          MuiPopover: {
            styleOverrides: {
              paper: {
                borderRadius: 8,
              },
            },
          },
          MuiFormControl: {
            styleOverrides: {
              root: {
                backgroundColor: 'background.paper',
               // border: null,
                "&.MuiTextField-root":{
                  marginTop:"12px",
                  marginBottom:"12px"
              }
              },
            },
          },
          MuiFilledInput: {
            styleOverrides: {
              root: {
                borderRadius: 4,
                '&:before, &:after': {
                  display: 'none',
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
                root:{
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                    "& .MuiOutlinedInput-notchedOutline": {
                        border:"1px solid rgba(0, 0, 0, 0.23)",
                      },
                      "&.Mui-disabled":{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border:"1px solid #EDECF9",
                        
                        },
                      },
                      "&:hover":{
                        "& .MuiOutlinedInput-notchedOutline": {
                            border:"1px solid rgba(0, 0, 0, 0.23)",
                            color:'green'
                          },
                      },
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            border:"1px solid rgba(0, 0, 0, 0.23)",
                        },
                },
            }
            }
        },
        MuiInputBase:{
            styleOverrides:{
                root:{
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    '&:hover fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                },
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    color:"#FFFFFF",
                    backgroundColor:mainPrimary.primary,
                  },
                  colorPrimary: {
                    backgroundColor: mainPrimary.primary,
                  },
                  colorSecondary: {
                    backgroundColor: mainSecondary.secondary,
                    color: "#000"
                  },
                  colorPrimary:mainPrimary.primary,
                deleteIcon:{
                    color:"#FFFFFF"
                }
                  
            },
          },
          MuiFormLabel:{
            styleOverrides:{
                root:{
                    '&.MuiInputLabel-root': {
                        '&.Mui-focused': {
                            color: 'rgb(107, 114, 128)',
                          },
                      },
                },
                asterisk: {
                  color: errorColor?.main
                }
            }
          },
          MuiInputLabel:{
            styleOverrides:{
                root:{
                    '&.Mui-focused': {
                        color: 'rgb(107, 114, 128)',
                      },
                }
            }
          },
          MuiStepIcon:{
            styleOverrides:{
                root:{
                    border:`1px solid ${stepColor.stepPrimary}`,
                    borderRadius:"50%",
                    color: "#B8B7C5",
                    "&.Mui-active":{
                       color: "#353349",
                       border:"none",
                       "& .MuiStepIcon-text":{
                        color: "#353349",
                        fill: "#353349",
                       }
                    },
                    "&.Mui-completed" :{
                        color: "#353349",
                        border:"none",
                        "& .MuiStepIcon-text":{
                            color: "#FFFFFF",
                            fill: "#FFFFFF",
                           }
                    }
                },
                text:{
                    color: "#B8B7C5",
                    fill: "#B8B7C5",
                    "&.Mui-active":{
                        color: "#B8B7C5",
                        fill: "#B8B7C5",
                     },
                }
            }
          },
          MuiStepLabel:{
            styleOverrides:{
                label:{
                  
                    "&.Mui-active":{
                        color: "#6b7280",
                        fontWeight:500
                     },
                     "&.Mui-completed" :{
                        color: "#6b7280",
                        fontWeight:500
                    }
                }
            }
          },
          MuiSvgIcon:{
           styleOverrides:{
            root:{
              color: "#004FE8",
              "& .MuiStepIcon-root" : {
                "&.Mui-active" :{
                  color: "#B8B7C5 !important"
                }
              }
            }
           }
          },
          MuiAutocomplete:{
            styleOverrides:{
              clearIndicator:{
                 display:'none'
              },
              option:{
                '&:hover': {
                  backgroundColor: mainPrimary.primary,
                  color:'#FFFFFF',
                  '& .MuiSvgIcon-root': {
                    color: 'white !important'
                  }
                },
                '&.Mui-focused': {
                  backgroundColor: `${mainPrimary.primary} !important`,
                  color:'#FFFFFF',
                },
                '&[aria-selected="true"]':{
                  backgroundColor: `${mainPrimary.subPrimary} !important`,
                  color:'#FFFFFF',
                  '& .MuiSvgIcon-root': {
                    color: 'white !important'
                  },
                  '&:hover': {
                    backgroundColor: `${mainPrimary.primary} !important`,
                    color:'#FFFFFF',
                    '& .MuiSvgIcon-root': {
                      color: 'white !important'
                    }
                  },
               },
               '&[aria-selected="true"] .Mui-focused':{
                backgroundColor: `${mainPrimary.primary} !important`,
                color:'#FFFFFF',
                '& .MuiSvgIcon-root': {
                  color: 'white !important'
                }
             },
              },
            }
          },
          MuiStack:{
            styleOverrides:{
                root:{
                    '& >:not(style)+:not(style)':{
                        marginLeft: '0px !important',
                        marginTop: '0px !important'
                    }
                }
            }
          },
          MuiTableCell:{
            styleOverrides:{
              root:{
                fontWeight: 300,
                color: '#353349 !important'
              }
            }
          },
          MuiTimelineDot: {
            styleOverrides: {
              root: {
                margin: '0px !important'
              }
            }
          },
          MuiTimelineItem: {
            styleOverrides: {
              root: {
                '&:before' :{
                  content: 'none'
                }
              }
            }
          },
      },
    },
);
