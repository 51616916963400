// ----------------- Documentation ---------------------------
// this component used for show the data from organization list

import { Avatar, Box, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import GridComponent from "../../../shared-component/grid-component/GridComponent";
import DetailsCard from "../../../shared-component/card-component/DetailsCard";
import LabelAndValue from "../../../shared-component/OverviewPage/overview-used-components/LabelAndValue";

import BreadCrumbWithActions from "../../../shared-component/breadcrumb-component/BreadCrumbWithActions";

import { BoxEffects, grids, headerButton } from "../../../../Config/Constants";
import { PrimaryButton } from "@assystant/mui-buttons";
import EditOrganization from "./EditOrganization";

import Loading from "../../../shared-component/Loading/Loading";

import { API_URL, apiService } from "../../../../constant/AuthConstant";
import ApartmentIcon from '@mui/icons-material/Apartment';
import returnError from "../../../../utils/ExportError";
import useExit from "../../../../utils/useExit";
import { enqueueSnackbar } from "notistack";
import UserContext from "../../../../context/UserContext";
import PrimaryBtn from "../../../shared-component/button-component/PrimaryBtn";


function Organization(){
    const [openEditOrganization,setOpenEditOrganization] = useState(false);
    const [organizationvalue, setOrganizationvalue] = useState({});
    const [load, setLoad] = useState(true);
    const { exitFromPlatform } = useExit()
    const { currencies, fetchOrganization } = useContext(UserContext)


    const arr1 = [
        {title: 'Organization', url: '/'},
    ]
    console.log('from org', organizationvalue?.brand_logo)
      const propArr = [
        {label:"Brand Logo", 
          value:organizationvalue?.brand_logo === null ? 
          <>
          <Avatar 
            children={organizationvalue?.brand_logo === null ? <ApartmentIcon fontSiz="large" sx={{
                width:'30px',
                height:'30px'
            }} className="!fill-[#004FE8]" /> : <></>}
            sx={{
            width:'60px',
            height:'60px', 
            background: organizationvalue?.brand_logo === null ? '#EDECF9' : ""
        }}/>
          </>
          :
          <>
          <Avatar 
            src={`${organizationvalue?.brand_logo}` || ""} sx={{
            width:'60px',
            height:'60px', 
        }}/>
          </>
          
            },
        {label:"Legal Name", value: organizationvalue?.legal_name || 'Not Provided'},
        {label:"Company Name", value: organizationvalue?.name || 'Not Provided'},
        {label:"Description", value: organizationvalue?.description || 'Not Provided'},
        {label:"Currency", value: organizationvalue?.default_currency || 'Not Provided'},
    ]
    const propArr1 = [
        {label:"Email", value:organizationvalue?.email || 'Not Provided'},
        {label:"Phone", value:organizationvalue?.phone_number || 'Not Provided'},
        {label:"Address", value:organizationvalue.address || 'Not Provided'},
    ]
    console.log('from api url', API_URL, organizationvalue.brand_logo)
    function handelEditOrganizationDetails(){
        setOpenEditOrganization(!openEditOrganization)
    }


  async function fetchDataFromCollection() {
     setLoad(true)
    apiService.get(`organization/get-user-org/`).then((res)=>{
        if(res.data){
            const apiUrl = API_URL;
           const a = apiUrl.split('')
           const b = a.pop()

            console.log('from.......', a.join(''), b)
            const newData = {
                ...res.data,
                brand_logo: res?.data?.brand_logo === null || res?.data?.brand_logo === '' ? null : `${a.join('')}${res?.data?.brand_logo}`
            }
            console.log('from new data', newData, res?.data)
            setOrganizationvalue(newData)
            setLoad(false)
        }
    }).catch((error)=>{
        if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
              setLoad(false)
          }
          }
    })
  }

  useEffect(() => {
    fetchDataFromCollection();
  }, []);

    return(
       <>
             <Box 
               sx={{borderBottom: BoxEffects.borderBottom, borderColor: 'secondary.main',padding:BoxEffects.padding,marginBottom:BoxEffects.marginButton,paddingLeft:BoxEffects.margin,paddingRight:headerButton.marginRigt }}
               >
                <BreadCrumbWithActions propArr={arr1}>
                   {!load &&(
                     <PrimaryBtn 
                        style={{
                            boxShadow:'none',
                            marginRight: '0px !important',
                            fontWeight:300,
                            padding:'6px 16px'
                        }} 
                        title='Edit' 
                        onClick={handelEditOrganizationDetails} />
                   )}
                </BreadCrumbWithActions>
            </Box>
            
            {load ? <Loading/> : <Box 
            sx={{borderBottom: BoxEffects.borderBottom, borderColor: 'secondary.main',padding:BoxEffects.padding,marginBottom:BoxEffects.marginButton,paddingLeft:BoxEffects.margin,paddingRight:headerButton.marginRigt }}
              
            // sx={{borderBottom:'1px solid'}}
            >
                <GridComponent>
                <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid} >
                    <DetailsCard cardHeader='General Information'> 
                        <Grid item xs={grids.parrentgrid} lg={grids.halfGrid} sx={{paddingLeft:0,paddingRight:0}} >
                            {propArr.map((e,i)=>{
                                return(
                                    <LabelAndValue label={e.label} value={e.value}  />
                                )
                            })}
                        </Grid>
                        {/* <LabelAndValue label={details.label} value={details.value}  /> */}
                    </DetailsCard>
                </Grid>
                <Grid  item xs={grids.childgrid} lg={grids.childgrid}>
                    <DetailsCard cardHeader='Contact Details'>
                        <Grid item >
                            {propArr1.map((e,i)=>{
                                return(
                                    <LabelAndValue label={e.label} value={e.value}  />
                                )
                            })}
                        </Grid>
                    </DetailsCard>
                </Grid>
            </GridComponent>
                
            </Box>}
            {openEditOrganization && (
                <EditOrganization 
                title='Edit Organization'
                open={openEditOrganization}
                onClose={handelEditOrganizationDetails}
                setOpenEditOrganization={setOpenEditOrganization}
                data={organizationvalue}
                successCallback={(data) =>{
                    setOrganizationvalue(data)
                    fetchOrganization()
                }}
                currencies={currencies}
                />
            )}
            
        </>
    )
  
}
export default Organization;