import React from 'react';
import NavigateIcon from '../../../asset/icons/NavigateIcon';

const ModalSideButton = ({
    sideTitle,
    className = "",
    clickHandler,
    labelClassName = ""
}) =>{
    return (
        <div 
          className={`bg-[#004FE8] rounded-lg flex items-center justify-center gap-[20px] cursor-pointer ${className}`}
          onClick={clickHandler}
          style={{
            padding: '10px 20px'
          }}
          >
            <div className={`text-[#FFFFFF] ${labelClassName}`} style={{
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '18.96px',
            }}>
                {sideTitle}
            </div>
            <NavigateIcon />
        </div>
    )
}

export default ModalSideButton