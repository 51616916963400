import React, { useEffect, useState } from 'react';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { Box } from '@mui/material';
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';

import formatISOString from '../../../../utils/formatISOString';
import DeleteIcon from '../../../../asset/icons/DeleteIocn';
import Integration from '../newMap/linkadd/Integration';
import { renderUpdatesScript } from '../newMap/Script';
import { BASE_URI, apiService } from '../../../../constant/AuthConstant';
import RemovePopup from '../../../shared-component/RemovePopup/RemovePopup';
import { useNavigate, useParams } from 'react-router-dom';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import AddUpdates from './components/AddUpdates';
import TimelinePreview from './components/TimelinePreview';
import ImageLayout from './components/ImageLayout';
import Loading from '../../../shared-component/Loading/Loading';
import { iconsList } from '../../../../Config/Constants';

const sampleData = [
  {
    id: 1,
    title: '20th Floor complete',
    modified: '2024-07-04T11:49:08.436297Z',
    units: [{ name: 'A101', name: 'A102' }],
  },
];

const rows = [
  { id: 1, title: 'Plan 1' },
  { id: 2, title: 'Plan 2' },
  { id: 3, title: 'Plan 3' },
  { id: 4, title: 'Plan 4' },
];

const Updates = ({
  timelineList,
  openAddUpdates,
  setTimeLineList,
  integrateUpdates,
  handleAddUpdates,
  openTimelinePreview,
  handleTimelinePreview,
  handleIntegrationUpdates,
  userFromOrganization,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { exitFromPlatform } = useExit();
  const [removeModal, setRemoveModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleRemoveModal = () => {
    setRemoveModal(!removeModal);
  };

  const openRemoveModal = (id) => {
    setSelectedId(id);
    setRemoveModal(true);
  };

  const openPreview = () => {
    if (timelineList?.length > 0) {
      handleTimelinePreview();
    } else {
      enqueueSnackbar('Please add an update first');
    }
  };

  const handleChange = (event, newValue) => {
    localStorage.setItem('property-update-id', `${id}`);
    navigate(`/updates/${event?.id}`);
  };

  const updateDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getUTCFullYear();

    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;
    const formattedDate = `${dayWithSuffix} ${month} ${year}`;
    return formattedDate;
  };

  const retrievePropertyUpdates = () => {
    setLoading(true);
    apiService
      .get(`property/property_updates/`)
      .then((res) => {
        if (res?.data) {
          const newData = res?.data
            ?.map((e) => {
              const findIcon = iconsList?.find((f) => {
                return f?.name === e?.icon;
              });
              return {
                ...e,
                icon: findIcon,
                images:
                  e?.images?.length > 0
                    ? e?.images?.map((r) => {
                        return {
                          ...r,
                          media: `${BASE_URI}${r?.media}`,
                        };
                      })
                    : [],
                date: updateDate(e?.publish_date),
              };
            })
            ?.filter((e) => {
              return e?.linked_property === Number(id);
            });
          const newL = newData?.sort((a, b) =>{
            return new Date(b.modified) - new Date(a.modified)
          })
          setTimeLineList(newL);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  const deleteUpdate = () => {
    apiService
      .delete(`property/property_updates/${selectedId}`)
      .then((res) => {
        if (res?.status === 204) {
          enqueueSnackbar('Update Deleted', { variant: 'success' });
          handleRemoveModal();
          retrievePropertyUpdates();
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };
  const columns = [
    { field: 'id', headerName: 'ID', minWidth: 100 },
    { field: 'title', headerName: 'Title', minWidth: 300 },
    {
      field: 'units',
      headerName: 'Units',
      sortable: false,
      minWidth: 300,
      align: 'left',
      renderCell: (params) =>
        params?.row?.units
          ?.map((e) => {
            return e?.name;
          })
          .join(', ') || '-',
    },
    {
      field: 'publish_date',
      headerName: 'Date',
      sortable: false,
      minWidth: 300,
      align: 'left',
      renderCell: (params) => {
        return params?.row?.publish_date?.split('-').reverse().join('/');
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 80,
      align: 'center',
      renderCell: (params) => {
        return userFromOrganization?.role?.name.toLowerCase() === 'admin' ||
          userFromOrganization?.role?.name.toLowerCase() === 'developer' ? (
          <div
            className="cursor-pointer align-center"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              openRemoveModal(params?.row?.id);
            }}
          >
            <DeleteIcon color="error" className="!fill-[#FF5C5C]" />
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  useEffect(() => {
    retrievePropertyUpdates();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      {timelineList?.length === 0 ? (
        <EmptyCard
          title="No Plans Added"
          subtitle="Click “Add New” to add the plan"
        />
      ) : (
        <Box>
          <DataGridTable
            columns={columns}
            rows={timelineList}
            handleEvent={handleChange}
            actionWidth={80}
          />
        </Box>
      )}
      {integrateUpdates && (
        <Integration
          open={integrateUpdates}
          onClose={handleIntegrationUpdates}
          text={renderUpdatesScript(BASE_URI, id)}
        />
      )}
      {removeModal && (
        <RemovePopup
          open={removeModal}
          close={handleRemoveModal}
          description={'Are you sure, you want to remove this update ?'}
          onSubmit={deleteUpdate}
        />
      )}

      {openAddUpdates && (
        <AddUpdates
          title="Add Updates"
          open={openAddUpdates}
          onClose={handleAddUpdates}
          onSuccess={retrievePropertyUpdates}
        />
      )}

      {openTimelinePreview && (
        <TimelinePreview
          open={openTimelinePreview}
          onClose={openPreview}
          timelineList={timelineList}
        />
      )}
    </>
  );
};
export default Updates;
