// ----------------- Documentation ---------------------------
// this component used for add and edit the data from add plan
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup
// edit                    boolead             used for the edit form
// edittitle               string              to maintain the edit form title of the popup
// data                    array               to show the default form value in the edit form

import React from "react";
import { apiService } from "../../../constant/AuthConstant";
// import FormModal from "../../share-component/FormModal/FormModal";
// import Form from "../../share-component/FormRelatedComponents/FormManager";
import FormModal from "../../shared-component/formmodal-component/FormModal";
import Form from "../../shared-component/formrelated-component/FormManager";


function AddPlan2({
    title,
    open,
    onClose,
    successCallback
}){
    const defaultValues = {
        partner_catagory:'',
        name:'',
        description:'',
        total_number:'',
        tower_1:'',
        tower_2:'',
        tower_3:'',
        tower_4:'',
    }
    const fieldMap = [
        {
            name:'partner_catagory',
            label:'Parent Category ',
            placeholder:'Parent Category',
            type:'select',
            checkbox:true,
            checkboxtitle:'Replicate',
            fieldProps:{
                defaultOptions: [
                    {title: 'B Block - A Tower, B Tower, C Tower, D Tower', id:''},
                ]
            }
        },
        {
           name:'name',
           label:'Name',
           placeholder:'Name',
           type:'text',
          
       },
       {
           name:'description',
           label:'Description',
           placeholder:'Description',
           type:'text',
          
       },
       {
           name:'total_number',
           label:'Total Number',
           placeholder:'Total Number',
           type:'text',
          
       },
       {
           name:'tower_1',
           label:'Tower 1 ',
           placeholder:'Tower 1',
           type:'text',
          
       },
       {
        name:'tower_2',
        label:'Tower 2',
        placeholder:'Tower 2',
        type:'text',
       
    },
    {
        name:'tower_3',
        label:'Tower 3',
        placeholder:'Tower 3',
        type:'text',
       
    },
    {
        name:'tower_4',
        label:'Tower 4',
        placeholder:'Tower 4',
        type:'text',
       
    },
       
   ]
   function handelAddPlan(data){
    apiService.post('api/property/map_path/',data)
    .then((res)=>{

    }).catch((err)=>{

    })
   }

   return(
       <>
       <FormModal title={title} open={open} onClose={onClose}>
           <Form
            btntitle='upload plan'
            defaultValues={defaultValues}
            fieldMap={fieldMap}
            onCancel={onClose}
            handelSubmit={handelAddPlan}
           />
       </FormModal>
       </>
   )
}
export default AddPlan2;