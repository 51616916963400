// ----------------- Documentation ---------------------------
// this component used for show the profile overview


import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import DetailsCard from "../../shared-component/card-component/DetailsCard";
import LabelAndValue from "../../shared-component/OverviewPage/overview-used-components/LabelAndValue";
import { Group } from "@mui/icons-material";
import BreadCrumbWithActions from "../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import { BoxEffects, grids, headerButton } from "../../../Config/Constants";
import { PrimaryButton } from "@assystant/mui-buttons";
import GridComponent from "../../shared-component/grid-component/GridComponent";
import EditProfile from "./EditProfile";
import PrimaryBtn from "../../shared-component/button-component/PrimaryBtn";

function ProfileOverview(){
    const [openEditProfile,setOpenEditProfile] = useState(false)

    const arr1 = [
        {title: 'Profile', url: '/'},
    ]
    
      const propArr = [
        {label:"", value:<Group sx={{width:'100px', height:'100px'}} />},
        {label:"First Name", value:"Tom"},
        {label:"Last Name", value: "Cooper"},
        {label:"Position", value: "Marketing Manager"},
        {label:"Status", value: "Active"},
    ]
    const propArr1 = [
        {label:"Email", value:"properties@terezza.com"},
        {label:"Phone", value:"+1 718 591 6530"},
    ]
  
    function handelEditProfileDetails(){
        setOpenEditProfile(!openEditProfile)
    }




    return(
        <>
            <Box 
               sx={{borderBottom: BoxEffects.borderBottom, borderColor: 'secondary.main',padding:BoxEffects.padding,marginBottom:BoxEffects.marginButton,paddingLeft:BoxEffects.margin,paddingRight:headerButton.marginRigt }}
            >
                <BreadCrumbWithActions propArr={arr1}>
                    <PrimaryBtn title='Edit' onClick={handelEditProfileDetails} style={{
                         fontWeight:300
                    }}/>
                </BreadCrumbWithActions>
            </Box>
            
            <Box 
            sx={{borderBottom: BoxEffects.borderBottom, borderColor: 'secondary.main',padding:BoxEffects.padding,marginBottom:BoxEffects.marginButton,paddingLeft:BoxEffects.margin,paddingRight:headerButton.marginRigt }}
              
            // sx={{borderBottom:'1px solid'}}
            >
            <GridComponent>
                <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid} >
                    <DetailsCard cardHeader='General Information'> 
                        <Grid item xs={grids.parrentgrid} lg={grids.halfGrid} sx={{paddingLeft:0,paddingRight:0}} >
                            {propArr.map((e,i)=>{
                                return(
                                    <LabelAndValue label={e.label} value={e.value}  />
                                )
                            })}
                        </Grid>
                        
                    </DetailsCard>
                </Grid>
                <Grid  item xs={grids.childgrid} lg={grids.childgrid}>
                    <DetailsCard cardHeader='Contact Details'>
                        <Grid item >
                            {propArr1.map((e,i)=>{
                                return(
                                    <LabelAndValue label={e.label} value={e.value}  />
                                )
                            })}
                        </Grid>
                    </DetailsCard>
                </Grid>
            </GridComponent>
                
            </Box>
            {openEditProfile && (
                <EditProfile 
                title='Edit Profile'
                open={openEditProfile}
                onClose={handelEditProfileDetails}
                />
            )}
            
        </>
    )
  
}
export default ProfileOverview;