import React, { useEffect, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { html } from '@codemirror/lang-html';
import { css } from '@codemirror/lang-css';
import { vscodeLight } from '@uiw/codemirror-theme-vscode';
import FormModal from '../../../../../../shared-component/formmodal-component/FormModal';
 
import SecondaryBtn from '../../../../../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../../../../../shared-component/button-component/PrimaryBtn';


const PageEditor = ({
  open,
  title,
  onClose,
  cssCode,
  setCssCode,
  liquidCode,
  setLiquidCode,
  javascriptCode,
  setJavascriptCode,
}) => {
  const [value, setValue] = useState('1');
  const [loading, setLoading] = useState(false);
  const [lcode, setLcode] = useState(liquidCode);
  const [cCode, setCCode] = useState(cssCode);
  const [jCode, setJCode] = useState(javascriptCode);

  const handleLiquidCode = (val) => {
    setLcode(val);
  };

  const handleCSSCode = (val) => {
    setCCode(val);
  };

  const handleJSCode = (val) => {
    setJCode(val);
  };

  const onSubmit = () => {
    setLiquidCode(lcode);
    setCssCode(cCode);
    setJavascriptCode(jCode);
    onClose();
  };

  const headStyles = {
    fontFamily: 'Rubik',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28.44px',
  };
  console.log('fffsfdfdsfsd')
  return (
    <FormModal open={open} title={title} onClose={onClose}>
      <div className="m-6">
        <div className="mb-4">
          <div className="text-[#353349] mb-2" style={{ ...headStyles }}>
            Liquid
          </div>
          <div
           className='p-2'
            style={{
              border: '1px solid #8E8CA3',
              borderRadius: '4px',
            }}
          >
            <CodeMirror
              value={lcode}
              height="300px"
              theme={vscodeLight}
              extensions={[html({ jsx: true })]}
              onChange={handleLiquidCode}
            />
          </div>
        </div>

        <div className="mb-4">
          <div className="text-[#353349] mb-2" style={{ ...headStyles }}>
            CSS
          </div>
          <div
           className='p-2'
            style={{
              border: '1px solid #8E8CA3',
              borderRadius: '4px',
            }}
          >
            <CodeMirror
              value={cCode}
              height="300px"
              theme={vscodeLight}
              extensions={[css({ jsx: true })]}
              onChange={handleCSSCode}
            />
          </div>
        </div>

        <div className="mb-4">
          <div className="text-[#353349] mb-2" style={{ ...headStyles }}>
            Javascript
          </div>
          <div
            className='p-2'
            style={{
              border: '1px solid #8E8CA3',
              borderRadius: '4px',
            }}
          >
            <CodeMirror
              value={jCode}
              height="300px"
              theme={vscodeLight}
              extensions={[javascript({ jsx: true })]}
              onChange={handleJSCode}
            />
          </div>
        </div>

        <div className="flex items-center justify-end">
          <div className="flex items-center gap-[10px]">
            <SecondaryBtn
              style={{
                boxShadow: 'none',
                margin: '0px !important',
                fontWeight: 300,
              }}
              title="Cancel"
              onClick={onClose}
            />
            <PrimaryBtn
              className="!mx-0"
              style={{
                boxShadow: 'none',
                margin: '0px !important',
                fontWeight: 300,
              }}
              title="Submit"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </FormModal>
  );
};

export default PageEditor;
