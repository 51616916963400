
export const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

export const convertStringToKeys = (str) => {
    return str
        .toLowerCase()           // Convert to lowercase
        .replace(/\s+/g, '_');   // Replace spaces with underscores
}


export const formatDate = (created) => {
  const date = new Date(created);
  if (isNaN(date.getTime())) {
      console.error('Invalid date');
      return null;
  }
 
  const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
  };
  const formattedDate = date.toLocaleDateString('en-GB', options);
  return formattedDate;
};

export const formatTime = (created) => {
  const date = new Date(created);
  if (isNaN(date.getTime())) {
      console.error('Invalid date');
      return null;
  }
 
  const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
  };
  let formattedTime = date.toLocaleTimeString('en-GB', options);
  formattedTime = formattedTime.replace(/:/g, '/');
  return formattedTime;
};