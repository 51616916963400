import React, { useState, useEffect } from 'react'
import BreadCrumbWithActions from '../../shared-component/breadcrumb-component/BreadCrumbWithActions'
import { BoxEffects, headerButton } from '../../../Config/Constants'
import { Box, CircularProgress, Typography } from "@mui/material";

import { apiService } from '../../../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import returnError from '../../../utils/ExportError';
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons';
import PopoverComponent from '../../shared-component/Popover/PopoverComponent';
import { ChromePicker } from 'react-color'
import LabTabs from '../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs';

import useExit from '../../../utils/useExit';
import PopoverTemplates from './Tabs/PopoverTemplates';
import MapTemplates from './Tabs/MapTemplates';
import SpecificationTemplate from './Tabs/SpecificationTemplate';
import GallaryTemplates from './Tabs/GallaryTemplates';
import SecondaryBtn from '../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import hexToRgba from 'hex-to-rgba';
import rgbHex from 'rgb-hex';

const colorDetails = [
    {id:1, 
    name: 'Brand',
    backgroundColor: '#004FE8',
    },
    {id:3, 
        name: 'Item Name',
        backgroundColor: '#8E8CA3',
    },
    {id:2, 
        name: 'Item Detail',
        backgroundColor: '#353349',
    },
    {id:4, 
        name: 'Background',
        backgroundColor: '#FFFFFF',
    }
    ]

function Templates({ 
    inventoryData = [], 
    load = false, 
    templates,
    organization
 }) {
    const arr1 = [{ title: "Design Template", url: "/" }];
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIssubmitting] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElColor, setAnchorElColor] = useState(null);
    const openColor = Boolean(anchorElColor);
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedColor, setSelectedColor] = useState(null)
    // const [selectedBrandColor, setSelectedBrandColor] = useState('#004FE8')
    // const [selectedItemDetailColor, setSelectedItemDetailColor] = useState('#353349')
    // const [selectedItemNameColor, setSelectedItemNameColor] = useState('#8E8CA3')
    // const [selectedBackgroundColor, setSelectedBackgroundColor] = useState('#FFFFFF')
    const [colorSections, setColorSections] = useState(colorDetails);
    const [isUpdattingColor, setIsUpdattingColor] = useState(false)
    const { exitFromPlatform } = useExit()
    const [value, setValue] = useState('1');

    

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const handleClickColor = (event, color, item) =>{
        setSelectedSection(item)
        setSelectedColor(color)
        setAnchorElColor(event.currentTarget)
    }

    const updateColorList = (color, mode) =>{
         setSelectedColor(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
         const updatedList = colorSections.map((val)=>{
            if(val?.name === mode){
                return {...val, backgroundColor: `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`}
            }else{
                return val
            }
         })
         setColorSections(updatedList)
    }

    const onChangeColor = (color) =>{
        if(selectedSection === 'Brand'){
            updateColorList(color , 'Brand')
        }else if(selectedSection === 'Item Detail'){
            updateColorList(color , 'Item Detail')
        }else if(selectedSection === 'Item Name'){
            updateColorList(color , 'Item Name')
        }else if(selectedSection === 'Background'){
            updateColorList(color , 'Background')
        }
    }

    const handleCloseColor = () =>{
        setAnchorElColor(null)
    }

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    // const returnColor = () =>{
    //     if(selectedSection === 'Brand'){
    //         return selectedBrandColor
    //     }else if(selectedSection === 'Item Detail'){
    //         return selectedItemDetailColor
    //     }else if(selectedSection === 'Item Name'){
    //         return selectedItemNameColor
    //     }else if(selectedSection === 'Background'){
    //         return selectedBackgroundColor
    //     }
    // }
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getSelectedTemplate = () =>{
        setLoading(true)
        apiService.get(`specs/template-config/`)
        .then((res) =>{
            setLoading(false)
            const updateColors = colorSections?.map((e) =>{
                if(e?.name === "Brand"){
                    return {...e, backgroundColor: hexToRgba(res?.data?.title_color)}
                }else if(e?.name === 'Item Name'){
                    return {...e, backgroundColor: hexToRgba(res?.data?.item_name_color)}
                }else if(e?.name === 'Item Detail'){
                    return {...e, backgroundColor: hexToRgba(res?.data?.item_details_color)}
                }else if(e?.name === 'Background'){
                    return {...e, backgroundColor: hexToRgba(res?.data?.background_color)}
                }
                else{
                    return e
                }
            })
            setColorSections(updateColors)
            console.log('from selected template',res)
            setSelectedTemplate(res.data)
        })
        .catch((error) =>{
            setLoading(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
        })
    }
    
    const submitSelectedTemplate = (chosen_template) =>{
        setIssubmitting(true)
        console.log(chosen_template)
        apiService.put(`specs/template-config/`, {
            template_chosen: chosen_template, 
            orientation: "SINGLE_COLUMN",   
            display_mode: "NEWPAGE", 
            title_color: `#${rgbHex(colorSections[0]?.backgroundColor)}`,
            item_name_color: `#${rgbHex(colorSections[1]?.backgroundColor)}`,
            item_details_color: `#${rgbHex(colorSections[2]?.backgroundColor)}`,
            background_color: `#${rgbHex(colorSections[3]?.backgroundColor)}`,
        })
        .then((res) =>{
            setIssubmitting(false)
            enqueueSnackbar('Template Selected', {variant:"success"})
            setSelectedTemplate(res?.data)
            const updateColors = colorSections?.map((e) =>{
                if(e?.name === "Brand"){
                    return {...e, backgroundColor: hexToRgba(res?.data?.title_color)}
                }else if(e?.name === 'Item Name'){
                    return {...e, backgroundColor: hexToRgba(res?.data?.item_name_color)}
                }else if(e?.name === 'Item Detail'){
                    return {...e, backgroundColor: hexToRgba(res?.data?.item_details_color)}
                }else if(e?.name === 'Background'){
                    return {...e, backgroundColor: hexToRgba(res?.data?.background_color)}
                }
                else{
                    return e
                }
            })
            setColorSections(updateColors)
            console.log(res)
        })
        .catch((error) =>{
            setIssubmitting(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
        })
    }

    const updateColorsAtSubmission = () =>{
        setIsUpdattingColor(true)
        setLoading(true)
        apiService.put(`specs/template-config/`, {
            title_color: `#${rgbHex(colorSections[0]?.backgroundColor)}`,
            item_name_color: `#${rgbHex(colorSections[1]?.backgroundColor)}`,
            item_details_color: `#${rgbHex(colorSections[2]?.backgroundColor)}`,
            background_color: `#${rgbHex(colorSections[3]?.backgroundColor)}`,
        })
        .then((res) =>{
            const updateColors = colorSections?.map((e) =>{
                if(e?.name === "Brand"){
                    return {...e, backgroundColor: hexToRgba(res?.data?.title_color)}
                }else if(e?.name === 'Item Name'){
                    return {...e, backgroundColor: hexToRgba(res?.data?.item_name_color)}
                }else if(e?.name === 'Item Detail'){
                    return {...e, backgroundColor: hexToRgba(res?.data?.item_details_color)}
                }else if(e?.name === 'Background'){
                    return {...e, backgroundColor: hexToRgba(res?.data?.background_color)}
                }
                else{
                    return e
                }
            })
            setColorSections(updateColors)
            setSelectedTemplate(res.data)
            enqueueSnackbar('Colors are updated.', {variant:'success'})
            setAnchorElColor(null)
            setAnchorEl(null)
            //getSelectedTemplate()
            
            setIsUpdattingColor(false)
            setLoading(false)
        }).catch((error) =>{
            setIsUpdattingColor(false)
            setLoading(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
        })
    }

    

    useEffect(() =>{
        getSelectedTemplate()
    }, [])

    const tabs = [
        {
            id:1,
            value:'1',
            label:'Popover',
            page: <PopoverTemplates 
            loading={loading}
            templates={templates}
            isSubmitting={isSubmitting}
            selectedTemplate={selectedTemplate}
            submitSelectedTemplate={submitSelectedTemplate}
            />
        },
        {
            id:2,
            value:'2',
            label:'Maps',
            page: <MapTemplates organization={organization} />
        },
        {
            id: 3,
            value: '3',
            label: 'Specifications',
            page: <SpecificationTemplate organization={organization} />
        },
        {
            id: 4,
            value: '4',
            label: 'Gallary',
            page: <GallaryTemplates organization={organization} />
        }
    ]

    return (
        <>
            <Box
                sx={{
                    borderBottom: BoxEffects.borderBottom,
                    borderColor: "secondary.main",
                    padding: BoxEffects.padding,
                    marginBottom: BoxEffects.marginButton,
                    paddingLeft: BoxEffects.margin,
                    paddingRight: headerButton.marginRigt,
                    //position: 'sticky', top: '65px', backgroundColor: 'white', zIndex: 99
                }}
            >
                
                <div className='flex justify-between items-center'>
            {/* <SearchBar /> */}
            <BreadCrumbWithActions propArr={arr1}/>
            {value === '1' && (
                 <PopoverComponent 
                 id={'simple-popover'}
                 open={open}
                 anchorEl={anchorEl}
                 handleClose={handleClose}
                 handleClick={handleClick}
                 title="Edit Theme"
                 component={
                   <>
                    <div className='w-96'>
                        <div className='w-full h-14 bg-[#EDECF9]'>
                           <Typography variant='subtitle1' className='!text-xl !font-semibold !text-[#353349] !p-4'>Edit Theme</Typography>
                        </div>
                        <div className='w-full'>
                              <div style={{
                               display: "grid",
                               gridTemplateColumns: "auto auto",
                              }}>
                                {colorSections?.map((val)=>{
                                    return (
                                       <div className='flex flex-col p-4' key={val.id}>
                                            <Typography variant='subtitle1'>{val?.name}</Typography>
                                            <div className={`w-full h-10 cursor-pointer`}
                                              onClick={(e) =>{
                                               handleClickColor(e, val?.backgroundColor, val?.name)
                                              }}
                                              style={{
                                               backgroundColor: `${val?.backgroundColor}`,
                                               borderRadius: '4px',
                                               border: "1px solid #8E8CA3",
                                              }}
                                            ></div>
                                       </div>
                                    )
                                })}
                                {anchorElColor && (
                                   <PopoverComponent 
                                     id={'simple-popover1'}
                                     open={openColor}
                                     anchorEl={anchorElColor}
                                     handleClick={handleClickColor}
                                     handleClose={handleCloseColor}
                                     component={
                                       <ChromePicker
                                          color={selectedColor}
                                           onChangeComplete={(color) => {
                                               onChangeColor(color)
                                           }}
                                       />
                                     }
                                   />
                                )}
                              </div>
                        </div>
                        <div className='flex justify-between items-center my-4 mx-2'>
                           <div></div>
                           <div className='flex'>
                               <SecondaryBtn title='Cancel' onClick={()=>{
                                   handleClose()
                               }}
                               style={{
                                   fontWeight:400
                               }}
                               />
                               <PrimaryBtn disabled={isUpdattingColor} title='Update' onClick={()=>{
                                   updateColorsAtSubmission()
                               }}
                               style={{
                                   fontWeight:400
                               }}
                               />
                           </div>
                        </div>
                    </div>
                   </>
                 } 
               />
            )}
          </div>
        </Box>
        
        <LabTabs
          tabs={tabs}
          value={value}
          handleChange={handleChange} 
        />
        </>
    )
}

export default Templates
