import React, { useContext } from 'react'
import { Box, Grid } from '@mui/material'
import { grids } from '../../../Config/Constants'
import EmptyCard from '../../shared-component/card-component/EmptyCard'
import DetailsCard from '../../shared-component/card-component/DetailsCard'
import GridComponent from '../../shared-component/grid-component/GridComponent'
import ImageGridLayout from '../../shared-component/OverviewPage/components/ImageGiridLayout'
import LabelAndValue from '../../shared-component/OverviewPage/overview-used-components/LabelAndValue'
import UserContext from '../../../context/UserContext'

const PropertyOverviewComponent = ({
    isReadOnly = false,
    itemData,
    onClickEdit,
    propertyData,
    handleGallaryModal,
}) =>{

    const {
        selectedCurrency, 
        returnFormattedCurrency
    } = useContext(UserContext)
    
    const propertyDetails = [
        {label:"Property Name", value:propertyData?.name},
        {label:"Price", value:`${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, propertyData?.price)}`|| "Not Provided"},
        {label:"Token Amount", value:`${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, propertyData?.token_price)}`|| "Not Provided"},
    ]

    const details = {label:'Description', value: propertyData?.description}
    
    return(
        <GridComponent>
            <Grid item xs={grids.parrentgrid} lg={grids.overviewFirstGrid} >
                <DetailsCard cardHeader='Basic Details' style={{paddingBottom:'12px'}}> 
                <Grid container spacing={0} columns={grids.parrentgrid}>
                    {propertyDetails.map((e,i)=>{
                        return(
                            <Grid item xs={grids.parrentgrid} lg={grids.halfGrid} sx={{paddingLeft:0,paddingRight:0}} >
                               <LabelAndValue label={e.label} value={e.value}  />
                            </Grid>
                        )
                    })}
                </Grid>
                    <LabelAndValue label={details.label} value={details.value}  />
                    <Box className="flex justify-between items-center">
                        <Box>
                        </Box>
                    </Box>
                </DetailsCard>
            </Grid>

            <Grid className="!md:w-full max-[600px]:w-full" item lg={grids.childgrid}>
                <DetailsCard 
                    cardHeader='Images' 
                    isAdditionalButton={isReadOnly ? false : true}
                    buttonText="Add Images"
                    onButtonClick={handleGallaryModal}
                >
                    {itemData?.length > 0  ? 
                    <ImageGridLayout 
                        allImages={itemData} 
                        onImageClick={isReadOnly ? () => {} : onClickEdit}
                    />
                :
                <EmptyCard 
                    title="No Images Available" 
                    subtitle={`Click "Edit" and upload images`} 
                    noBorder 
                    style={{
                    height:'250px !important'
                    }}
                    />
                    }
                </DetailsCard>
            </Grid>
        </GridComponent>
    )
}

export default PropertyOverviewComponent

