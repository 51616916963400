import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Stack, Skeleton, TextField } from '@mui/material';
import {
  headerButton,
  BoxEffects,
  stack,
  grids,
} from '../../../../Config/Constants';
import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import FilterFields from '../../leads/Fields/FilterFields';
import GridComponent from '../../../shared-component/grid-component/GridComponent';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '../../../../asset/icons/FacebookIcon';
import TwitterIcon from '../../../../asset/icons/TwitterIcon';
import LinkedInIcon from '../../../../asset/icons/LinkedInIcon';
import WhatsappIcon from '../../../../asset/icons/WhatsappIcon';
import PinterestIcon from '../../../../asset/icons/PinterestIcon';
import { useMediaQuery } from 'react-responsive';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  PinterestShareButton,
} from 'react-share';
import { apiService } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../utils/useExit';
import PartnerDashboardFilter from './PartnerDashboardFilter';
import getDateAgo from '../../../../utils/getDateAgo';

const arr1 = [{ title: 'Dashboard', url: '/' }];

const buttonFields = [
  {
    id: 1,
    label: 'Today',
    value: 'today',
  },
  {
    id: 2,
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    id: 3,
    label: 'Last 7 days',
    value: '7',
  },
  {
    id: 4,
    label: 'Last 30 days',
    value: '30',
  },
  {
    id: 5,
    label: 'Last 90 days',
    value: '90',
  },
  {
    id: 6,
    label: 'Last 180 days',
    value: '180',
  },
];

const PartnerDashboard = () => {
  const { exitFromPlatform } = useExit();
  const [loadStats, setLoadStats] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inviatationLoading, setInvitationLoading] = useState(false);
  const [stats, setStats] = useState({});
  const [email, setEmail] = useState('')
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({
    name: '',
    value: '',
    id: '',
  });
  const [buttonSelected, setButtonSeleted] = useState({
    id: 3,
    label: 'Last 7 days',
    value: '7',
  });

  const mediumScreen = useMediaQuery({ query: '(max-width: 1025px)' });
  let partnerStats = [
    {
      label: 'Total Earning',
      stat: `$${stats?.total ? stats?.total : 0}`,
    },
    {
      label: 'Unpaid',
      stat: `$${stats?.unpaid ? stats?.unpaid : 0}`,
    },
  ];


  const handleFilter = () =>{
    setOpenFilter(!openFilter)
}

  const shareUrl = `${window.location?.origin}/signup/?referral=${stats?.referral_code}`; // Replace with your URL
  const title = 'Check this out!'; // Replace with your title
  const media =
    'https://liveapi.spotmink.com/media/organization_logos/Screenshot_2024-08-29_at_4.17.13_PM.png'; // Replace with your media URL for Pinterest

  const icons = [
    {
      id: 1,
      icon: (
        <FacebookShareButton url={shareUrl} quote={title}>
          <FacebookIcon />
        </FacebookShareButton>
      ),
    },
    {
      id: 2,
      icon: (
        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon />
        </TwitterShareButton>
      ),
    },
    {
      id: 3,
      icon: (
        <LinkedinShareButton url={shareUrl} title={title}>
          <LinkedInIcon />
        </LinkedinShareButton>
      ),
    },
    {
      id: 4,
      icon: (
        <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
          <WhatsappIcon />
        </WhatsappShareButton>
      ),
    },
    {
      id: 5,
      icon: (
        <PinterestShareButton url={shareUrl} media={media} description={title}>
          <PinterestIcon />
        </PinterestShareButton>
      ),
    },
  ];

  const returnEndDate = (buttonValue) =>{
    if(buttonValue === 'today'){
       return getDateAgo(0)
    }else if(buttonValue === 'yesterday'){
       return getDateAgo(1)
    }else if(buttonValue === '7'){
       return getDateAgo(7)
    }else if(buttonValue === '30'){
       return getDateAgo(30)
    }else if(buttonValue === '90'){
       return getDateAgo(90)
    }else if(buttonValue === '180'){
       return getDateAgo(180)
    }else{
     return getDateAgo(365)
    }
}

  const retreiveAllDashboardStats = () => {
    setLoadStats(true);
    apiService
      .get(`transactions/entries/stats/`)
      .then((res) => {
        if (res?.data) {
          setStats(res?.data);
        }
        setLoadStats(false);
      })
      .catch((error) => {
        setLoadStats(false);
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      });
  };

  useEffect(() => {
    retreiveAllDashboardStats();
  }, []);

  const filterDashboard = (e) => {
    setLoadStats(true);
    const newData = {
      ...e,
      start_date: returnEndDate(e?.buttonSelection),
      end_date:
        e?.buttonSelection === 'yesterday' ? getDateAgo(1) : getDateAgo(0),
    };
    Object.entries(newData)?.map((e) => {
      if (e[1] === '' || e[1] === null || e[1] === undefined) {
        delete newData[e[0]];
      }
    });
    delete newData?.buttonSelection;
    const searchParams = new URLSearchParams(newData).toString();
    if (e?.buttonSelection === '365') {
      setButtonSeleted({
        id: 8,
        label: 'Last 365 days',
        value: '365',
      });
    } else {
      const findButton = buttonFields?.find((f) => {
        return f?.value === e?.buttonSelection;
      });
      setButtonSeleted(findButton);
    }

    apiService
      .get(`transactions/entries/stats/?${searchParams}`)
      .then((res) => {
        setStats(res?.data);
        setLoadStats(false);
        handleFilter();
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (returnedError === 'Given token not valid for any token type') {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoadStats(false);
            handleFilter();
          }
        }
      });
  };

  const onChangeAtEmail = (e) =>{
    setEmail(e?.target.value);
  }

  const invitationSubmission = () =>{
    if(email === ''){
      enqueueSnackbar('Please add email', {variant: 'error'})
    }else{
      setInvitationLoading(true)
    apiService.post(`consulting_partner/profile/invite_org/`, {
      email: email
    }).then((res) =>{
      if(res?.status === 200){
        enqueueSnackbar('Invitation sent', {variant: 'success'})
        setInvitationLoading(false)
      }
    }).catch((error) =>{
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (returnedError === 'Given token not valid for any token type') {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setInvitationLoading(false);
        }
      }
    });
    }
    
  }

  const copyLink = () =>{
    if(stats?.referral_code?.length > 0){
      navigator.clipboard.writeText(`${window.location?.origin}/signup/?referral=${stats?.referral_code}`)
      enqueueSnackbar('Links Copied', {variant:'success'})
    }
   }

  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '65px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          <div className="flex justify-between items-center">
            <PrimaryBtn className="!mr-0" title="Filter" onClick={handleFilter}/>
          </div>
        </BreadCrumbWithActions>
      </Box>
      <div className="m-6">
        {/* {loadStats ? (
          <Loading />
        ) : (
          <>
            {partnerStats?.length ? (
              <>
                <Stack
                  className="!grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5"
                  direction="row"
                  spacing={stack.space}
                  sx={{
                    marginBottom: '20px',
                    position: 'relative',
                    rowGap: '20px',
                    columnGap: '20px',
                  }}
                >
                  {partnerStats?.map((e, i) => {
                    return (
                      <>
                        {loadStats ? (
                          <Card sx={{ height: '125px', boxShadow: 'none' }}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              className="w-full !h-full"
                            />
                          </Card>
                        ) : (
                          <div
                            className="p-6 rounded-lg"
                            style={{
                              minHeight: '125px',
                              border: '2px solid #E1EBFE',
                            }}
                          >
                            <div className="flex flex-col">
                              <div
                                style={{
                                  fontFamily: 'Rubik',
                                  fontSize: '40px',
                                  fontWeight: 500,
                                  lineHeight: '47.4px',
                                  letterSpacing: '-0.022em',
                                  color: '#353349',
                                }}
                              >
                                {e?.stat}
                              </div>
                              <div
                                style={{
                                  fontFamily: 'Rubik',
                                  fontSize: '24px',
                                  fontWeight: 400,
                                  lineHeight: '28.44px',
                                  letterSpacing: '-2.2%',
                                  color: '#353349',
                                }}
                              >
                                {e?.label}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
                </Stack>
                <div className={mediumScreen ? "flex flex-col w-full" : "flex items-center w-full gap-[20px]"}
                style={{ minHeight: '125px' }}
                >
                  <div
                    className={`p-6 rounded-lg w-2/4 h-full ${mediumScreen ? "w-full mb-[20px]" : 'w-2/4'}`}
                    style={{ border: '2px solid #E1EBFE' }}
                  >
                    <div className="flex flex-col">
                      <div
                        className="text-[#353349]"
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '24px',
                          fontWeight: 500,
                          lineHeight: '28.44px',
                        }}
                      >
                        Invite conversion through email
                      </div>

                      <TextField
                        className="!my-6"
                        placeholder="Enter email address"
                      />

                      <div className="w-16">
                        <PrimaryBtn
                          title="Invite"
                          className="!m-0"
                          style={{
                            margin: '0px 0px !important',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                   className={`p-6 rounded-lg w-2/4 h-full ${mediumScreen ? "w-full" : 'w-2/4'}`}
                   style={{ border: '2px solid #E1EBFE' }}
                  >
                    <div className="flex flex-col">
                      <div
                        className="text-[#353349] mb-4"
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '24px',
                          fontWeight: 500,
                          lineHeight: '28.44px',
                        }}
                      >
                        Share through social media
                      </div>
                    </div>
                    <div className="flex items-center justify-center gap-[10px] my-8">
                      <div
                        className="text-[#004FE8] my-[5px]"
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '18.96px',
                        }}
                      >
                        {window.location?.origin}
                      </div>
                      <div className="cursor-pointer">
                        <ContentCopyIcon className="!fill-[#8E8CA3]" />
                      </div>
                    </div>
                    <div className="flex items-center justify-center gap-[30px]">
                      {icons?.map((e) => {
                        return e.icon;
                      })}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <EmptyCard title="Please select a builder" />
              </>
            )}
          </>
        )} */}
        <>
          {partnerStats?.length ? (
            <>
              <Stack
                className="!grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5"
                direction="row"
                spacing={stack.space}
                sx={{
                  marginBottom: '20px',
                  position: 'relative',
                  rowGap: '20px',
                  columnGap: '20px',
                }}
              >
                {partnerStats?.map((e, i) => {
                  return (
                    <>
                      {loadStats ? (
                        <Card sx={{ height: '125px', boxShadow: 'none' }}>
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            className="w-full !h-full"
                          />
                        </Card>
                      ) : (
                        <div
                          className="p-6 rounded-lg"
                          style={{
                            minHeight: '125px',
                            border: '2px solid #E1EBFE',
                          }}
                        >
                          <div className="flex flex-col">
                            <div
                              style={{
                                fontFamily: 'Rubik',
                                fontSize: '40px',
                                fontWeight: 500,
                                lineHeight: '47.4px',
                                letterSpacing: '-0.022em',
                                color: '#353349',
                              }}
                            >
                              {e?.stat}
                            </div>
                            <div
                              style={{
                                fontFamily: 'Rubik',
                                fontSize: '24px',
                                fontWeight: 400,
                                lineHeight: '28.44px',
                                letterSpacing: '-2.2%',
                                color: '#353349',
                              }}
                            >
                              {e?.label}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </Stack>
              <div
                className={
                  mediumScreen
                    ? 'flex flex-col w-full'
                    : 'flex items-center w-full gap-[20px]'
                }
                style={{ minHeight: '125px' }}
              >
                <div
                  className={`p-6 rounded-lg w-2/4 h-full ${mediumScreen ? 'w-full mb-[20px]' : 'w-2/4'}`}
                  style={{ border: '2px solid #E1EBFE' }}
                >
                  <div className="flex flex-col">
                    <div
                      className="text-[#353349]"
                      style={{
                        fontFamily: 'Rubik',
                        fontSize: '24px',
                        fontWeight: 500,
                        lineHeight: '28.44px',
                      }}
                    >
                      Invite conversion through email
                    </div>

                    <TextField
                      className="!my-6"
                      placeholder="Enter email address"
                      value={email}
                      onChange={onChangeAtEmail}
                      
                    />

                    <div className="w-16">
                      <PrimaryBtn
                        title="Invite"
                        className="!m-0"
                        style={{
                          margin: '0px 0px !important',
                        }}
                        disabled={inviatationLoading}
                        onClick={invitationSubmission}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`p-6 rounded-lg w-2/4 h-full ${mediumScreen ? 'w-full' : 'w-2/4'}`}
                  style={{ border: '2px solid #E1EBFE' }}
                >
                  <div className="flex flex-col">
                    <div
                      className="text-[#353349] mb-4"
                      style={{
                        fontFamily: 'Rubik',
                        fontSize: '24px',
                        fontWeight: 500,
                        lineHeight: '28.44px',
                      }}
                    >
                      Share through social media
                    </div>
                  </div>
                  <div className="flex items-center justify-center gap-[10px] my-8">
                    <div
                      className="text-[#004FE8] my-[5px]"
                      style={{
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '18.96px',
                      }}
                    >
                      {`${window.location?.origin}/signup/?referral=${stats?.referral_code}`}
                    </div>
                    <div className="cursor-pointer" onClick={copyLink}>
                      <ContentCopyIcon className="!fill-[#8E8CA3]" />
                    </div>
                  </div>
                  <div className="flex items-center justify-center gap-[30px]">
                    {icons?.map((e) => {
                      return e.icon;
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <EmptyCard title="Please select a builder" />
            </>
          )}
        </>
      </div>

      {openFilter && (
        <PartnerDashboardFilter
          open={openFilter}
          handleClose={handleFilter}
          buttonFields={buttonFields}
          setButtonSeleted={setButtonSeleted}
          buttonSeleted={buttonSelected}
          onSubmit={filterDashboard}
          loading={loadStats}
        />
      )}
    </>
  );
};

export default PartnerDashboard;
