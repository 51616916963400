import React, { useEffect, useRef } from 'react';

const ShowCodeComponent = ({ codeObject, liquidCode,
  cssCode,
  javascriptCode, }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
    
    // Clear previous content
    iframeDocument.open();
    iframeDocument.close();

    // Insert HTML content
    iframeDocument.body.innerHTML = `
     <!DOCTYPE html>
     <html>
     <head>
        <link href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap" rel="stylesheet">
     </head>
     <body style="overflow-y:scroll">
        ${codeObject.liquid_code}
     </body>
     <html>
    `;

    // Inject CSS
    if (codeObject?.css_object) {
      if(iframeDocument !== null){
        const styles = iframeDocument.querySelector('style');
        if(styles !== null){
          styles.remove()
        }
      }
      const styleElement = iframeDocument.createElement('style');
      styleElement.innerHTML = codeObject.css_object;
      iframeDocument.head.appendChild(styleElement);
    }

    // Inject JavaScript
    if (codeObject?.js_object) {
      if(iframeDocument !== null){
        const scripts = iframeDocument.querySelectorAll('script');
        if(scripts?.length > 0){
          scripts.forEach(script => {
            script.parentNode.removeChild(script);
          });
        }
      }
      const scriptElement = iframeDocument.createElement('script');
      scriptElement.defer = true;
      scriptElement.innerHTML = codeObject.js_object;
      iframeDocument.body.appendChild(scriptElement);
    }
  }, [liquidCode, cssCode, javascriptCode]);

  return (
    <iframe
      ref={iframeRef}
      id="page-frame"
      style={{ width: '100%', height: '100%', border: 'none' }}
      title="code-preview"
    />
  );
};

export default ShowCodeComponent;
