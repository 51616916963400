// -----------Documentation-------------
// Forgot Password component used for forgot password

// props           type                    usedfor
// -------         -------                 ---------
// companyLogo     path                    logo image for authantication page
// publicSideImage path                    logo image for side authantication page
// fields          array                   array for fields
// formValues      object                  for formvalues
// onSubmit        function                for submit useCallback
// actionTitle     string                  action button label
// pageTitle       string                  label for forms


import React, { useContext, useState } from "react";
import SpotMink from '../../../asset/Images/SpotMink.png'
import PublicGroup from '../../../asset/Images/PublicGroup.png'
import AuthLayout from "../../shared-component/AuthLayoutPage/AuthLayout";
import userContext from "../../../context/UserContext";
import { apiService } from "../../../constant/AuthConstant";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { useSnackbar } from "notistack";
import returnError from "../../../utils/ExportError";
import useExit from "../../../utils/useExit";

function ForgotPassword(){
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const {userResetpassword} = useContext(userContext);
    const {exitFromPlatform} = useExit()
    const formValues={
        email:'',
    }
    const schema = yup.object().shape({
        email: yup.string().email('You must enter a valid email').required('You must enter a email'),
      });
    const field = [
        {id:1,label:'Email', type:"email", value:'',name:'email'},
    ]
    function onSubmit(data){
        userResetpassword(data.email)
      }

      const onSubmitForForgot = (data) =>{
        setLoading(true)
        apiService.post('account/send-otp/', data).then((response)=>{
            setLoading(false)
            localStorage.setItem('forgot-email', JSON.stringify(data))
            navigate('/otp')
        }).catch((error)=>{
            setLoading(false)
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
               
                if(returnedError === 'Given token not valid for any token type'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                }
            }
        })
    }
    return(
        <>
            <AuthLayout 
            companyLogo={SpotMink} 
            publicSideImage={PublicGroup} 
            fields={field} 
            formValues={formValues} 
            onSubmit={onSubmitForForgot} 
            actionTitle='Continue'
            pageTitle='Forgot Password'
            schema={schema}
            isSubmitting={loading}
            addtionaEndText={"Go back to"}
            additionalEndLink={"Login"}
            endLink={"/"}
            // checkboxAction
            /> 
        </>
    )
}
export default ForgotPassword;