import React, { useEffect, useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';

const ShrinkLabelTextField = styled(TextField)({
  '& label': {
    transform: 'translate(0, -1.5px) scale(0.75) !important', // Force label to shrink
    transformOrigin: 'top left',
  },
});

const DatePickerComponent = ({ name, required = false }) => {
  const [dateValue, setDateValue] = useState(dayjs(new Date().toISOString()));
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    if (getValues(name) === '') {
      setDateValue(dayjs(new Date().toISOString()));
    } else {
      setDateValue(dayjs(getValues(name)));
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          value={dateValue}
          sx={{
            width: '100%',
            '& .MuiInputLabel-root': {
              transform: 'translate(10px, -8.5px) scale(0.75) !important',
              transformOrigin: 'top left',
              background: 'white',
              padding: '0px 5px',
            },
            '& .MuiOutlinedInput-input': {
              pointerEvents: 'none',
            },
          }}
          label="Date"
          onChange={(newValue) => {
            const isoString = newValue?.$d.toISOString();
            setDateValue(newValue);
            setValue(name, isoString);
          }}
          slotProps={{
            textField: {
                readOnly: true,
                required: true,
            },
        }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              inputProps={{ ...params.inputProps, readOnly: true }}
              variant="standard"
            />
          )}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
