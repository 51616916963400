import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { useState,useEffect } from "react";
import { CustomBreadCrumb } from "../../../Config/Constants";
import { useNavigate } from "react-router-dom";

function BreadCrumb({
    propArr=[],
    actions=false,
    children
}){
    const [lastEle, setLastEle] = useState({})
    const [updateArr, SetUpdeateArr] = useState([]);
const navigate = useNavigate()

    useEffect(()=>{
        setLastEle(propArr[propArr.length - 1])
        SetUpdeateArr(propArr?.slice(0, -1))
    },[propArr])
    function handleClick(event) {
        event.preventDefault();
       
      }
     
   
    return(
        <>
            <div role="presentation" onClick={handleClick} className='w-full flex justify-between items-center'  style={{backgroundColor:'white',width:'100%'}}>
                <Breadcrumbs aria-label="breadcrumb" separator="›">
                    {updateArr.map((e,i)=>{
                        return(
                            <Typography className="cursor-pointer" sx={{fontSize:'32px', fontWeight:400}}  underline="hover" maxItems={CustomBreadCrumb.maxlink} color="inherit" onClick={()=> {
                                if(e?.onClick){
                                    e?.onClick()
                                }
                                navigate(e.url)
                            }}>
                                {e?.title}
                            </Typography>
                        )
                    })}
                    
                    <Typography sx={{fontSize:'32px', fontWeight:400, color:'#353349'}} color="text.primary"> 
                        {lastEle?.title}
                    </Typography>                
                </Breadcrumbs>
                {actions ? 
                    <Box>
                        {children}
                    </Box>
                    :''
                    
                    }
            </div>
        </>
    )
}
export default BreadCrumb;