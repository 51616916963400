import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Popover, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { API_URL } from '../../../../../constant/AuthConstant'
import TempDesign1 from '../../../setting/template_design/TempDesign1';
import TempDesign2 from '../../../setting/template_design/TempDesign2';
import TempDesign3 from '../../../setting/template_design/TempDesign3';
import TempDesign4 from '../../../setting/template_design/TempDesign4';
import TempDesign5 from '../../../setting/template_design/TempDesign5';
import GreenSpaceTemplate from '../../../setting/template_design/GreenSpaceTemplate';

function LinkedPopup({
  popupPosition,
  popupClose,
  data,
  hotspotId,
  template,
  loading,
  formatted,
  unitData,
  propertyName,
  selectedTemplate
}) {

  return (
    template === 1 ? 
    <TempDesign1 loading={loading} unitData={unitData} formatted={formatted} popupPosition={popupPosition} popupClose={popupClose} /> : 
    template === 2 ? <TempDesign2 selectedTemplate={selectedTemplate} propertyName={propertyName} loading={loading} unitData={unitData} formatted={formatted} popupPosition={popupPosition} popupClose={popupClose} /> : 
    template === 3 ? <TempDesign3 popupPosition={popupPosition} popupClose={popupClose}/> : 
    template === 4 ? <TempDesign4 popupPosition={popupPosition} popupClose={popupClose}/> : 
    template === 5 ?  <TempDesign5 popupPosition={popupPosition} popupClose={popupClose} /> : 
    template === 6 ?<GreenSpaceTemplate data={data} popupPosition={popupPosition} popupClose={popupClose} /> : 
    <TempDesign1 popupPosition={popupPosition} popupClose={popupClose} />
    // template === 1 ? 
    // <TempDesign1 loading={loading} unitData={unitData} formatted={formatted} popupPosition={popupPosition} popupClose={popupClose} /> : 
    // template === 2 ? <TempDesign2 popupPosition={popupPosition} popupClose={popupClose} /> : 
    // template === 3 ? <TempDesign3 popupPosition={popupPosition} popupClose={popupClose}/> : 
    // template === 4 ? <TempDesign4 popupPosition={popupPosition} popupClose={popupClose}/> : 
    // template === 5 ?  <TempDesign5 popupPosition={popupPosition} popupClose={popupClose} /> : 
    // template === 6 ?<GreenSpaceTemplate data={data} popupPosition={popupPosition} popupClose={popupClose} /> : 
    // <TempDesign1 popupPosition={popupPosition} popupClose={popupClose} />
  )
}

export default LinkedPopup