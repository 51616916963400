import Popover from "@mui/material/Popover";
import { IconButton } from "@mui/material";
 
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import MoreVertIcon from '@mui/icons-material/MoreVert';
 

 const SectionMenu = ({
    formState,
    onDuplicate,
    sectionIndex,
    onSectionDelete,
    onMergeWithAbove,
 }) => {
  console.log(
    'from section index', sectionIndex, formState
  )
  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <IconButton className="!px-1" {...bindTrigger(popupState)}>
                <MoreVertIcon className='!fill-[#8E8CA3]' />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Paper sx={{ 
                width: 200, 
                maxWidth: "100%",
                }}>
                  <MenuItem className="" onClick={onDuplicate}>
                    <ListItemText sx={{
                      fontFamily: "Rubik",
                      fontSize: "20px",
                      fontWeight: 300,
                      lineHeight:"23.7px",
                      letterSpacing: "-0.022em",
                      color: '#8E8CA3'
                    }}>
                      Duplicate section
                    </ListItemText>
                  </MenuItem>
                {sectionIndex !== 0 && (
                     <MenuItem onClick={onMergeWithAbove}>
                     <ListItemText sx={{
                         fontFamily: "Rubik",
                         fontSize: "20px",
                         fontWeight: 300,
                         lineHeight:"23.7px",
                         letterSpacing: "-0.022em",
                         color: '#8E8CA3'
                       }}>
                          Merge with above
                       </ListItemText>
                     </MenuItem>
                )}
                <MenuItem onClick={onSectionDelete}>
                <ListItemText sx={{
                    fontFamily: "Rubik",
                    fontSize: "20px",
                    fontWeight: 300,
                    lineHeight:"23.7px",
                    letterSpacing: "-0.022em",
                    color: '#8E8CA3'
                  }}>
                     Delete section
                  </ListItemText>
                </MenuItem>
              </Paper>
            </Popover>
          </div>
        )}
      </PopupState>
    </>
  );
}

export default SectionMenu
