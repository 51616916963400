import React from 'react'

const TrandingIcon = ({
    width,
    height
}) =>{
    return (
        <svg width={width} height={width} viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_4954_27669" maskUnits="userSpaceOnUse" x="0" y="0" width="74" height="74">
            <rect width="74" height="74" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_4954_27669)">
            <path d="M10.4827 55.5L6.16602 51.1833L28.9827 28.2125L41.316 40.5458L57.3494 24.6667H49.3327V18.5H67.8327V37H61.666V28.9833L41.316 49.3333L28.9827 37L10.4827 55.5Z" fill="#B8B7C5"/>
            </g>
        </svg>
    )
}

export default TrandingIcon