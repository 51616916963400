import React, { useEffect, useState, useContext } from 'react'
import DataGridTable from '../../../shared-component/table-component/DataGridTable'
import Loading from '../../../shared-component/Loading/Loading';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import {
    Card,
    Stack, 
    Select,
    MenuItem,
    Skeleton,
    FormControl
 } from '@mui/material';
 import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ProfileContainer from '../../../shared-component/table-component/Row/ProfileContainer';
import { stack, BoxEffects } from '../../../../Config/Constants';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../../../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../utils/useExit';
import returnError from '../../../../utils/ExportError';
import AddAffiliate from '../component/AddAffiliate';
import FilterAffiliate from '../component/FilterAffiliate';
import UserContext from '../../../../context/UserContext';

const SelectComponent = ({
  row,
  changeStatus,
  affiliate_status
}) =>{
  const [status, setStatus] = useState(affiliate_status)
  return (
    <FormControl fullWidth>
    <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={status}
    label=""
    data-role='select-status'
    IconComponent={(props) =>{
        if(props?.className?.includes('MuiSelect-iconOpen')){
            return <ExpandLess {...props} className='!fill-[#8E8CA3]' sx={{
                pointerEvents:'none !important',
                position:'absolute !important',
                right:'5px !important'
            }}/>
        }
        return <ExpandMore {...props} sx={{
            pointerEvents:'none !important',
            position:'absolute !important',
            right:'5px !important'
        }} className='!fill-[#8E8CA3]'/>
        
    }}
    onChange={(e)=>{
         setStatus(e?.target.value)
         changeStatus(e, row)
    }}
        sx={{height:'40px',
        background: 'white !important',
        color: status === 'Active' ?  '#24D315' : '#FF5C5C',
        fontWeight:300,
    }}
    >
    <MenuItem value='Active' sx={{color:'#24D315 !important', fontWeight:300,}} >Active</MenuItem>
    <MenuItem value='Inactive' sx={{color:'#FF5C5C !important', fontWeight:300,}}>Inactive</MenuItem>
    </Select>
</FormControl>
  )
}


const AffiliateDashboard = ({
  openFilter,
  handleFilter,
  openAffiliate,
  handleAffiliateModal,
  loading,
  setLoading,
  affiliateList,
  setAffiliateList,
  getAffiliateData,
  filterList
}) =>{
    const navigate = useNavigate()
    const { exitFromPlatform } = useExit();
    const [organization, setOrganization] = useState(null);
    const [dashboardStats, setDashboardStats] = useState(null);
    const [loadStats, setLoadStats] = useState(false)

    const [search, setSearch] = useState('');
    const [type, setType] = useState({name: '', id:''});
    const [active, setActive] = useState({name: 'True', id:'True'});

        
    const {
      selectedCurrency, 
      returnFormattedCurrency
  } = useContext(UserContext)
    


    function handelRowClick(e){
        navigate(`/affiliate/${e.id}`, {});
      }


    const affiliateStats = [
        {
            label:'Total Payouts',
            stat: dashboardStats?.total_earnings ? `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, dashboardStats?.total_earnings)}` : `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, '0')}`
        },
        {
            label:'Total Leads',
            stat: dashboardStats?.total_leads ? `${dashboardStats?.total_leads}` : '0'
        },
        {
            label:'Total Bookings',
            stat: dashboardStats?.total_bookings ? `${dashboardStats?.total_bookings}` : '0'
        },
        {
            label:'Paid',
            stat: dashboardStats?.total_paid ? `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, dashboardStats?.total_paid)}` : `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, '0')}`
        },
        {
            label:'Unpaid',
            stat: dashboardStats?.total_unpaid ? `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, dashboardStats?.total_unpaid)}` : `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, '0')}`
        },
    ]

    const getAffiliateStats = () =>{
      setLoadStats(true)
       apiService.get(`affiliates/affiliates/stats/`).then((res) =>{
        setDashboardStats(res?.data)
        setLoadStats(false)
       }).catch((error) =>{
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoadStats(false)
        }
      }
      )
    }

    const updateStatusForAffiliate = (e, row) =>{
      const newDetails = {...row};
      delete newDetails?.photo;
      apiService.put(`affiliates/affiliates/${row?.id}/`, {
          ...newDetails,
          affiliate_type: newDetails?.affiliate_type?.id,
          invitation: newDetails?.invitation?.id,
          organization: newDetails?.organization?.id,
          active: e?.target.value === 'Active' ? true : false,
          user: newDetails?.user?.id ? newDetails?.user?.id : null
      }).then((res) =>{
          if(res?.data?.id){
              const list = [...affiliateList];
              const newList = list?.map((f) =>{
                if(f?.id === res?.data?.id){
                  return {
                    ...f,
                    active: e?.target.value === 'Active' ? true : false,
                  }
                }else{
                  return f
                }
              }).sort((a,b) => (a.created < b.created) ? 1 : ((b.created < a.created) ? -1 : 0))
              console.log('from status', res?.data)
              setAffiliateList(newList)
             
              enqueueSnackbar('Status Updated', { variant: "success" })
          }
         
      }).catch((error) =>{
          if(error.response?.data){
              const returnedError = returnError(error.response?.data)
              if(returnedError === 'Given token not valid for any token type'){
                exitFromPlatform()
            }else{
                enqueueSnackbar(returnedError, {variant:'error'})
                
            }
          }
      })
  }

    const fetchDataFromCollection = () => {
     apiService.get(`organization/get-user-org/`).then((res)=>{
         if(res.data){
             setOrganization(res.data)
         }
     }).catch((error)=>{
         if(error.response?.data){
             const returnedError = returnError(error.response?.data)
             if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
               exitFromPlatform()
           }else{
               enqueueSnackbar(returnedError, {variant:'error'})
           }
        }
     })
   }

   const columns = [
    { field: 'name', headerName: 'Affiliate Name',minWidth: 300, renderCell: (params) =>{
        return <ProfileContainer profile={{
            name: params?.row?.name,
            email: params?.row?.email,
            picture: params?.row?.avatar
        }}/>
    }},
    // { field: 'affiliate_type', headerName: 'Type',minWidth: 200, renderCell: (params) =>{
    //   return params?.row?.affiliate_type?.name
    // }},
    { field: 'lead_commission_rate', headerName: 'Lead Commission',minWidth: 200, renderCell: (params) =>{
      return params?.row?.lead_commission_type === 'fixed' ? 
            `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, params?.row?.lead_commission_rate)}` 
            :
            `${Number(params?.row?.lead_commission_rate).toFixed(2)}%`
    }},
    { field: 'booking_commission_rate', headerName: 'Booking Commission',minWidth: 200, renderCell: (params) =>{
      return params?.row?.booking_commission_type === 'fixed' ? 
            `${returnFormattedCurrency(selectedCurrency?.locale, selectedCurrency?.currencyCode, params?.row?.booking_commission_rate)}` 
            :
            `${Number(params?.row?.booking_commission_rate).toFixed(2)}%`
    }},
    
    { field: 'cookie_duration', headerName: 'Cookie Duration',minWidth: 200, renderCell: (params) =>{
      return `${params?.row?.cookie_duration} Days`
    }},
    { field: 'leads_generated', headerName: 'Total Conversions',minWidth: 150},
  
    { field: 
        'status', 
        headerName: 'Status',
        minWidth: 200,
        renderCell: (params) => {
          return params?.row?.user === null ? 
          <div className='text-[#24D315]'>
            Invited 
          </div>
          :
           <>
             <SelectComponent 
               row={params?.row}
               changeStatus={updateStatusForAffiliate}
               affiliate_status={params?.row?.active === true ?  "Active" : "Inactive"}
             />
           </>
        },
    },
]

 

    useEffect(() =>{
      fetchDataFromCollection()
      getAffiliateStats()
    }, [])


    const onSubmit = (data) =>{
      setLoading(true)
      const newData = {...data}
      Object.entries(newData)?.map((e) =>{
        if(e[1] === '' || e[1] === null || e[1] === undefined){
          delete newData[e[0]]
        }
      })
      const searchParams = new URLSearchParams(newData).toString();
      apiService.get(`affiliates/affiliates/?${searchParams}`).then((res) =>{
        const updatedData = res?.data?.map((e) =>{
          return {
            ...e,
            name: e?.user?.full_name ? e?.user?.full_name : e?.invitation?.name,
            email: e?.user?.email ? e?.user?.email : e?.invitation?.email,
            avatar: e?.user?.avatar,
          }
        })
        setAffiliateList(updatedData)
        handleFilter()
        setLoading(false)
      }).catch((error) =>{
        if(error.response?.data){
          const returnedError = returnError(error.response?.data)
          if(returnedError === 'Given token not valid for any token type'){
            exitFromPlatform()
        }else{
            enqueueSnackbar(returnedError, {variant:'error'})
            setLoading(false)
        }
      }
      })
   }
    return (
         <>
          <Stack 
            className='!grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5'  
            direction="row" 
            spacing={stack.space}
            sx={{
                marginBottom: BoxEffects.margin,
                position: "relative",
                rowGap: '20px',
                columnGap: '20px',
            }}
            
            >
                {affiliateStats?.map((e, i) =>{
                    return (
                       <>
                         {loadStats ? 
                         <Card sx={{height:"125px", boxShadow:'none'}}>
                         <Skeleton animation="wave" variant="rectangular" className='w-full !h-full' />
                     </Card>
                       :
                         <div className='p-6 rounded-lg' style={{ minHeight: '125px', border:'2px solid #E1EBFE' }}>
                             <div className='flex flex-col'>
                                  <div style={{
                                     fontFamily: 'Rubik',
                                     fontSize: '40px',
                                     fontWeight: 500,
                                     lineHeight: '47.4px',
                                     letterSpacing: '-0.022em',
                                     color:'#353349'
                                  }}>{e?.stat}</div>
                                  <div style={{
                                     fontFamily: 'Rubik',
                                     fontSize: '24px',
                                     fontWeight: 400,
                                     lineHeight: '28.44px',
                                     letterSpacing: '-2.2%',
                                     color:'#8E8CA3'
                                  }}>{e?.label}</div>
                             </div>
                         </div>}
                       </>
                    )
                })}
            </Stack>
           {loading ? (
            <Loading />
         ) : (
            <>
              {affiliateList?.length > 0 ? (
                <DataGridTable 
                   rows={affiliateList}
                   columns={columns}
                   handleEvent={handelRowClick}
                />
              ) : (
                <EmptyCard 
                  title='There are no affiliate found'
                  subtitle='Click at "Add Affiliate" to add new affiliate'
                />
              )}
            </>
         )}
         {openAffiliate &&(
            <AddAffiliate 
              open={openAffiliate}
              title="Add Affiliate"
              onClose={handleAffiliateModal}
              organization={organization}
              successCallback={getAffiliateData}
            />
          )}

          {openFilter && (
            <FilterAffiliate 
              title="Filter"
              open={openFilter}
              onClose={handleFilter}
              search={search}
              setSearch={setSearch}
              type={type}
              setType={setType}
              active={active}
              setActive={setActive}
              onSubmit={onSubmit}
            />
          )}
         </>
    )
}

export default AffiliateDashboard