import React, { useEffect, useState } from 'react'
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import formatISOString from '../../../../utils/formatISOString';
import FormAnalyticsFilter from './component/FormAnalyticsFilter';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import getDateAgo from '../../../../utils/getDateAgo';
import { apiService } from '../../../../constant/AuthConstant';
import Loading from '../../../shared-component/Loading/Loading'
import ProfileContainer from '../../../shared-component/table-component/Row/ProfileContainer';
import { capitalizeFirstLetter } from '../../../../utils/helper';
import FilterFields from '../../leads/Fields/FilterFields';
import BarChart from './component/BarChart';
import { useNavigate } from 'react-router-dom';
 

const FormAnalytics = ({
    list,
    setList,
    barDetails,
    setBarDetails,
    buttonFields,
    openModal,
    closeModal,
    loading,
    setLoading,
    setOriginalFormAnalytics,
    selectedForm,
    setSelectedForm
}) =>{
    const navigate = useNavigate()
    const { exitFromPlatform } = useExit();
    const [buttonSeleted, setButtonSeleted] = useState({
        id:3,
        label:'Last 7 days',
        value:'7',
    });
    const [selectedProperty, setSelectedProperty] = useState({});
    
    
    const columns = [
      { field: 'device', headerName: 'Device ID',minWidth: 200, renderCell: (params) =>{
        return params?.row?.device ? `#${params?.row?.device.substring(params?.row?.device.length, params?.row?.device.length - 6).toUpperCase()}`: ""
      }},
      { field: 'session', headerName: 'Session ID',minWidth: 200, renderCell: (params) =>{
        return  params?.row?.session ? `#${params?.row?.session.substring(params?.row?.session.length, params?.row?.session.length - 6).toUpperCase()}`: ""
      }},
        { field: 'submission', headerName: 'Customer Details',minWidth: 300, renderCell: (params) =>{
          return <ProfileContainer 
                   profile={{
                    name: params?.row?.submission?.name || "Anonymous",
                    email: params?.row?.submission?.email || "-",
                   }}
                 />
        }},
        { field: 'type', headerName: 'Type',minWidth: 200, renderCell: (params) =>{
          if(params?.row?.bookings?.length > 0){
            return "Booking"
        }else{
           if(params?.row?.leads?.length > 0){
             return "Lead"
           }
        }
        }},
        { field: 'linked_property', headerName: 'Linked With',minWidth: 200, renderCell: (params) =>{
          return params?.row?.linked_property?.name || ""
        }},
        { field: 'linked_unit', headerName: 'Unit Name',minWidth: 200, renderCell: (params) =>{
          return params?.row?.linked_property?.name || ""
        }},
        { field: 'created', headerName: 'Date & Time',minWidth: 200, renderCell: (params) =>{
            return formatISOString(params?.row?.created)
        }},
    ]
   

    const retriveFormAnalyticsList = (formId) =>{
      setLoading(true)
        apiService.get(`form_integration/submissions/analytics/?form=${formId}`)
        .then((res) =>{
            if(res?.data?.data?.length > 0){
              const updateCounters = Object.entries(res?.data?.counters)?.map((e) =>{
                return {
                  name: capitalizeFirstLetter(e[0]),
                  value: e[1]
                }
              })
              const updateData = res?.data?.data?.map((e) =>{
                return {
                  ...e,
                  submission: JSON.parse(e?.submission),
                  type: e?.linked_property !== null ? "Booking" : "Lead"
                }
              })
              console.log('from ..........', updateData)
              setBarDetails(updateCounters)
              setList(updateData)
              setOriginalFormAnalytics(updateData)
              setLoading(false)
            }else{
              enqueueSnackbar('Data is not available for this form', { variant: 'default' })
              setBarDetails([])
              setList([])
              setOriginalFormAnalytics([])
              setLoading(false)
            }
        }).catch((error) =>{
          setLoading(false)
          if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
          }
       }})
    }

    useEffect(() =>{
      if(selectedForm?.id){
        retriveFormAnalyticsList(selectedForm?.id)
      }
    }, [selectedForm])

    const returnEndDate = (buttonValue) =>{
        if(buttonValue === 'today'){
           return getDateAgo(0)
        }else if(buttonValue === 'yesterday'){
           return getDateAgo(1)
        }else if(buttonValue === '7'){
           return getDateAgo(7)
        }else if(buttonValue === '30'){
           return getDateAgo(30)
        }else if(buttonValue === '90'){
           return getDateAgo(90)
        }else if(buttonValue === '180'){
           return getDateAgo(180)
        }else{
         return getDateAgo(365)
        }
   }

    const onFilterSubmitForFormAnalytics = (e) =>{
        setLoading(true)
        const newData = {
          ...e,
          property: selectedProperty?.id ? selectedProperty?.id : '',
          start_date: returnEndDate(e?.buttonSelection),
          end_date: e?.buttonSelection === 'yesterday' ? getDateAgo(1) : getDateAgo(0),
         }
          
         Object.entries(newData)?.map((e) =>{
          if(e[1] === '' || e[1] === null || e[1] === undefined){
            delete newData[e[0]]
          }
        })
       
        if(e?.buttonSelection === '365'){
            setButtonSeleted({
            id:8,
            label: 'Last 365 days',
            value: '365'
          })
        }else{
          const findButton = buttonFields?.find((f) =>{
            return f?.value === e?.buttonSelection
          })
          setButtonSeleted(findButton)
        }
         
         delete newData?.buttonSelection
         const searchParams = new URLSearchParams(newData).toString();
        
         apiService.get(`form_integration/submissions/analytics/?form=${selectedForm?.id}&${searchParams}`)
         .then((res) =>{
          
          if(res?.data?.data?.length > 0){
            const updateData = res?.data?.data?.map((e) =>{
              return {
                ...e,
                submission: JSON.parse(e?.submission),
                type: e?.linked_property !== null ? "Booking" : "Lead"
              }
            })
            setList(updateData);
            setOriginalFormAnalytics(updateData)
            closeModal()
            setLoading(false)
          }else{
            enqueueSnackbar('Data is not available for this filter', { variant: 'default' })
            setList([])
            setOriginalFormAnalytics([])
            closeModal()
            setLoading(false)
          }
        })
        .catch((error) =>{
          setLoading(false)
          if(error.response?.data){
            const returnedError = returnError(error.response?.data)
            if(returnedError === 'Given token not valid for any token type'){
              exitFromPlatform()
          }else{
              enqueueSnackbar(returnedError, {variant:'error'})
          }
          }})
      }

       

      const handleRowClick = (e) =>{
        if(e?.row?.bookings?.length > 0){
          navigate(`/booking/${e?.row?.bookings[0]?.id}`)
      }else{
         if(e?.row?.leads?.length > 0){
           navigate(`/leads/${e?.row?.leads[0]?.session}`)
         }
      }
      }
    return(
        <>
        {loading ? <Loading /> 
         :
         <div>
          <div className='w-52'>
            <FilterFields 
                value={selectedForm}
                setValue={setSelectedForm}
                choicesAPI="form_integration/forms/"
                titleKey="template_name"
                placeholder="Select"
          />
          </div>
          {list?.length > 0 && (
            <>
            <div className="w-full rounded-lg max-h-52 h-52 overflow-y-scroll" style={{
             border: '2px solid #E1EBFE',
             minHeight:'250px',
            }}>
             <BarChart data={barDetails} />
           </div>
           <div className='mt-8'>
            <DataGridTable 
              rows={list}
              columns={columns}
              handleEvent={handleRowClick}
            />
            </div>
            </>
          )}
     </div>}
        {openModal && (
            <FormAnalyticsFilter 
               open={openModal}
               handleClose={closeModal}
               buttonFields={buttonFields}
               setSelectedButton={setButtonSeleted}
               selectedButton={buttonSeleted}
               onSubmit={onFilterSubmitForFormAnalytics}
               selectedProperty={selectedProperty}
               setSelectedProperty={setSelectedProperty}
            />
        )}
        </>
    )
}

export default FormAnalytics