import React from 'react'
import {
    Box,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import EmptyCard from '../../../../shared-component/card-component/EmptyCard'
import DataGridTable from '../../../../shared-component/table-component/DataGridTable'
import NoteIcon from '../../../../../asset/icons/NoteIcon'

import ProfileContainer from '../../../../shared-component/table-component/Row/ProfileContainer'
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ExpandLess } from '@mui/icons-material';
  
const sampleData = [
    {id:1, title: 'Custom Javascript', type: 'Javascript', linked_property: 'Property A', added_by: 'John Doe', is_active: 'Active'},
    {id:2, title: 'Custom CSS', type: 'CSS', linked_property: 'Property A', added_by: 'John Doe', is_active: 'Inactive'},
    {id:3, title: 'Custom CSS', type: 'CSS', linked_property: 'Property A', added_by: 'John Doe', is_active: 'Active'},
]


/* Custom code list for created javascript and css files */
const CustomCodeList = ({
    codeList,
    changeStatus
}) =>{
  const navigate = useNavigate();
  const handelRowClick = (e) => {
    navigate(`/custom-code/code/${e?.id}`, {});
  }

    const columns = [
        { field: 'id', headerName: 'SI No.',minWidth: 50},
        { field: 'title', headerName: 'Title',minWidth: 200},
        { field: 'code_type', headerName: 'Type',minWidth: 100},
        { 
          field: 'linked_property', 
          headerName: 'Linked Property',
          minWidth: 200,
          renderCell: (params) => (
                 params?.row.properties?.join() || 'None'
          ),
        },
        { 
          field: 'added_by', 
          headerName: 'Added By',
          minWidth: 200,
          renderCell: (params) => {
            return <ProfileContainer profile={params?.row?.employee} />
          },
        },
        { field: 
          'is_active', 
          headerName: 'Active',
          minWidth: 200,
          renderCell: (params) => (
              <>
              <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={params.row.active === true ? "Active" : "Inactive"}
                    label=""
                    data-role='select-status'
                    IconComponent={(props) =>{
                      if(props?.className?.includes('MuiSelect-iconOpen')){
                          return <ExpandLess {...props} className='!fill-[#8E8CA3]' sx={{
                              pointerEvents:'none !important',
                              position:'absolute !important',
                              right:'5px !important'
                          }}/>
                      }
                      return <ExpandMore {...props} sx={{
                          pointerEvents:'none !important',
                          position:'absolute !important',
                          right:'5px !important'
                      }} className='!fill-[#8E8CA3]'/>
                      
                    }}
                    onChange={(e)=>changeStatus(params.row.id, e)}
                      sx={{height:'40px',
                      background: 'white !important',
                      color:params.row.active === true ? '#24D315' : '#FF5C5C',
                      fontWeight:300,
                    }}
                  >
                    <MenuItem value='Active' sx={{color:'#24D315 !important', fontWeight:300,}} >Active</MenuItem>
                    <MenuItem value='Inactive' sx={{color:'#FF5C5C !important', fontWeight:300,}}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </>
              ),
      },
    ];
   
   return (
       <>
         {codeList.length === 0 ? 
            <EmptyCard 
            title='No Custom Code Available' 
            icon={
                <div className='flex justify-center'>
                    <NoteIcon width={50} height={50}/>
                </div>
            }
            subtitle='Click "Add CSS" or "Add JS" to add the Custom Codes.' 
            /> 
            :
            <>
             <DataGridTable 
                columns={columns}
                rows={codeList}
                handleEvent={handelRowClick}
                />
            </>
         }
       </>
   )
}

export default CustomCodeList;