import React from 'react'

const Emoji = () => {
   return(
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_6057_6666" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect width="40" height="40" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_6057_6666)">
        <path d="M16.6666 35.5833V26.0833L19 19.4166C19.1389 19.111 19.3403 18.8541 19.6041 18.6458C19.868 18.4374 20.1944 18.3333 20.5833 18.3333H32.75C33.1389 18.3333 33.4722 18.4374 33.75 18.6458C34.0278 18.8541 34.2222 19.111 34.3333 19.4166L36.6666 26.0833V35.5833C36.6666 35.8888 36.5625 36.1458 36.3541 36.3541C36.1458 36.5624 35.8889 36.6666 35.5833 36.6666H34.4166C34.1111 36.6666 33.8541 36.5624 33.6458 36.3541C33.4375 36.1458 33.3333 35.8888 33.3333 35.5833V34.1666H20V35.5833C20 35.8888 19.8958 36.1458 19.6875 36.3541C19.4791 36.5624 19.2222 36.6666 18.9166 36.6666H17.75C17.4444 36.6666 17.1875 36.5624 16.9791 36.3541C16.7708 36.1458 16.6666 35.8888 16.6666 35.5833ZM20 24.1666H33.3333L32.1666 20.8333H21.1666L20 24.1666ZM21.6666 30.8333C22.1389 30.8333 22.5347 30.6735 22.8541 30.3541C23.1736 30.0346 23.3333 29.6388 23.3333 29.1666C23.3333 28.6944 23.1736 28.2985 22.8541 27.9791C22.5347 27.6596 22.1389 27.4999 21.6666 27.4999C21.1944 27.4999 20.7986 27.6596 20.4791 27.9791C20.1597 28.2985 20 28.6944 20 29.1666C20 29.6388 20.1597 30.0346 20.4791 30.3541C20.7986 30.6735 21.1944 30.8333 21.6666 30.8333ZM31.6666 30.8333C32.1389 30.8333 32.5347 30.6735 32.8541 30.3541C33.1736 30.0346 33.3333 29.6388 33.3333 29.1666C33.3333 28.6944 33.1736 28.2985 32.8541 27.9791C32.5347 27.6596 32.1389 27.4999 31.6666 27.4999C31.1944 27.4999 30.7986 27.6596 30.4791 27.9791C30.1597 28.2985 30 28.6944 30 29.1666C30 29.6388 30.1597 30.0346 30.4791 30.3541C30.7986 30.6735 31.1944 30.8333 31.6666 30.8333ZM9.99998 23.3333V19.9999H13.3333V23.3333H9.99998ZM18.3333 13.3333V9.99992H21.6666V13.3333H18.3333ZM9.99998 29.9999V26.6666H13.3333V29.9999H9.99998ZM9.99998 36.6666V33.3333H13.3333V36.6666H9.99998ZM3.33331 36.6666V13.3333H11.6666V3.33325H28.3333V14.9999H25V6.66659H15V16.6666H6.66665V36.6666H3.33331ZM19.1666 31.6666H34.1666V26.6666H19.1666V31.6666Z" fill="#353349"/>
        </g>
    </svg>

   )
}

export default Emoji;