import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import { themes } from "./app/Config/themesConfig";

import AppConatiner from "./app/main/AppContainer/AppContainer";
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('9c80e6c35b19aac60ee88d2422a17cadTz01NzM2OCxFPTE3MDQ5MDE2MjU1NzgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
  return(
    <>
    <ThemeProvider theme={themes}>
        <BrowserRouter>
            <AppConatiner />
        </BrowserRouter>
    </ThemeProvider>
    </>
)
}

export default App;
