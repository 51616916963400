import React from 'react'

const Analytics = ({ color = "#8E8CA3"}) =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
        <mask id="4" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="33">
            <rect x="0.0200195" y="0.312256" width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3238_1858)">
            <path  d="M5.35327 26.9788V12.3122H10.6866V26.9788H5.35327ZM13.3533 26.9788V5.64551H18.6866V26.9788H13.3533ZM21.3533 26.9788V17.6455H26.6866V26.9788H21.3533Z" fill={color} />
        </g>
    </svg>
    )
}

export default Analytics