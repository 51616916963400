import { TextField } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ToolTipComponent from '../Tooltip/ToolTipComponent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SearchIcon from '@mui/icons-material/Search';

function RegularTextField({
  value,
  onBlur,
  ref,
  name,
  id,
  autoFocus = false,
  required = false,
  label,
  fullWidth = true,
  error,
  helperText,
  type,
  hint = '',
  readOnly = false,
  disabled = false,
  multiline = false,
  rows = 1,
  autoComplete = 'off',
  placeHolder = '',
  sx,
  className = 'w-full',
  update,
  fieldType,
  tooltip = '',
  search = false
}) {
  console.log('from field name', tooltip, helperText)
  const [err, setErr] = useState(false);
  const { getValues, setValue } = useFormContext()
  return (
    <>
      <TextField
        id={id}
        name={name}
        onBlur={onBlur}
        ref={ref}
        type={type}
        autoFocus={autoFocus}
        required={required}
        autoComplete={autoComplete}
        disabled={disabled}
        readOnly={readOnly}
        label={label}
        className={className}
        value={value}
        rows={rows}
        multiline={multiline}
        onChange={(e) =>{ 
          if(fieldType === 'number'){
            setValue(name, e.target.value)
             let reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
             console.log('from fieldjhkljlkjlk', e.target.value, reg.test(e.target.value))
             if(reg.test(e.target.value) === true){
              
              setErr(false)
             }else{
              setErr(true)
             }
          }
         else{
          setValue(name, e.target.value)
         }
          if(update){
            update(name, e.target.value)
          }
      }}
        placeholder={placeHolder}
        fullWidth={fullWidth}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <>
            {tooltip !== '' && (
              <ToolTipComponent title={tooltip} offset={[50, -50]}>
              <ErrorOutlineIcon color='#FFFFFF' className='!fill-[#8E8CA3]' fontSize='20' />
          </ToolTipComponent>
            )}
          </>
          ),
          startAdornment: (
            <>
            {search && (
              <SearchIcon className='!fill-[#8E8CA3] !mr-2' />
            )}
          </>
          )
        }}
       
        // inputProps={{ type: 'search', readOnly: onlyRead }}
        error={error || err}
        helperText={!hint ? helperText : hint}
      />
    </>
  );
}

export default RegularTextField;
