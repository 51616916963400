import React from 'react';
import { Grid } from '@mui/material';
import FilterFields from '../../leads/Fields/FilterFields';
import { buttonFields } from '../../../../Config/Constants';
import FormModal from '../../../shared-component/formmodal-component/FormModal';
import ActionButton from '../../../shared-component/button-component/ActionButton';
import SecondaryBtn from '../../../shared-component/button-component/SecondaryBtn';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';

const FilterFilledDocument = ({
  open,
  title,
  onClose,
  buttonSelected = {
    id: 3,
    label: 'Last 7 days',
    value: '7',
  },
  setButtonSelected,
  form,
  setForm,
  property,
  setProperty,
  document,
  setDocument,
  onSubmit
}) => {
  const endButton = {
    label: 'Last 365 days',
    value: '365',
  };

  const onReset = () =>{
    setForm(null)
    setProperty(null)
    setDocument(null)
    setButtonSelected({
      id: 3,
      label: 'Last 7 days',
      value: '7',
    })
  }
  return (
    <FormModal open={open} title={title} onClose={onClose}>
      <div className="px-8 py-6">
        <FilterFields
          value={document}
          setValue={setDocument}
          label="Documents"
          placeholder="Select"
          choicesAPI={`documents/docs/choices/`}
          titleKey={'title'}

        />
        <FilterFields
          value={property}
          setValue={setProperty}
          label="Property"
          placeholder="Select"
          choicesAPI={'/property/prop/?filtered_data=true'}
          titleKey="name"
      
        />
        <FilterFields
          value={form}
          setValue={setForm}
          label="Forms"
          placeholder="Select"
          choicesAPI="form_integration/forms/"
          titleKey="template_name"
          
        />
        <Grid className="!mt-2" container spacing={2} columns={16}>
          {buttonFields?.map((item, index) => {
            return (
              <Grid key={index} item xs={8} style={{ paddingTop: 0 }}>
                <ActionButton
                  variant="contained"
                  color={
                    buttonSelected?.value === item?.value
                      ? 'primary'
                      : 'secondary'
                  }
                  title={item.label || 'Secondary'}
                  onClick={() => {
                    setButtonSelected(item);
                  }}
                  style={{
                    marginRight: 0,
                    marginLeft: 0,
                    width: '100%',
                    marginTop: '12px',
                    marginButtom: '12px',
                    padding: '20px',
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
        <ActionButton
          variant="contained"
          color={
            buttonSelected.value === endButton?.value ? 'primary' : 'secondary'
          }
          title={endButton.label || 'Secondary'}
          onClick={() => {
            setButtonSelected(endButton);
          }}
          style={{
            marginRight: 0,
            marginLeft: 0,
            width: '100%',
            marginTop: '12px',
            marginButtom: '12px',
            padding: '20px',
          }}
        />
      </div>

      <div className="flex justify-end mx-6 mb-6">
        <SecondaryBtn title="Cancel" onClick={onClose} />
        <SecondaryBtn style={{ backgroundColor: '#EDECF9', color:'#FF5C5C',  boxShadow: 'none', 
                                fontWeight:300,
                                padding:'6px 16px' }} onClick={onReset} title='Reset' />
        <PrimaryBtn title="Filter" onClick={onSubmit}/>
      </div>
    </FormModal>
  );
};

export default FilterFilledDocument;
