// ----------------- Documentation ---------------------------
// this component used for show the delete alert

import React from "react";
import AlertModal from "../../../shared-component/modal/AlertModal";

import { Typography } from "@mui/material";
function DeleteAlert({
    openModal,
    handelModal
}){
    return(
        <>
            <AlertModal
            openModal={openModal}
            cancelModal={handelModal}
            headerTitle="Add Modal"
            >
                <Typography sx={{marginLeft:'16px'}}>Can’t delete it. This role is linked with a user.</Typography>
            </AlertModal>
        </>
    )
}
export default DeleteAlert;