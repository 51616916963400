import React from 'react';

const WhatsappIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6831_8393"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="26"
        height="26"
      >
        <path
          d="M3.33203 3.33325H28.6654V28.6666H3.33203V3.33325Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6831_8393)">
        <path
          d="M18.3854 3.51994L17.6254 3.41328C15.3413 3.08349 13.0105 3.40975 10.9047 4.35403C8.799 5.29831 7.00505 6.8217 5.73203 8.74661C4.37757 10.5866 3.57014 12.7716 3.40267 15.0502C3.2352 17.3288 3.71448 19.6084 4.78536 21.6266C4.89496 21.8289 4.96319 22.051 4.98609 22.2799C5.00898 22.5088 4.98607 22.74 4.9187 22.9599C4.37203 24.8399 3.86536 26.7333 3.33203 28.7199L3.9987 28.5199C5.7987 28.0399 7.5987 27.5599 9.3987 27.1199C9.77862 27.041 10.1735 27.0781 10.532 27.2266C12.147 28.0148 13.9118 28.4484 15.7081 28.4983C17.5044 28.5483 19.2906 28.2134 20.9468 27.5163C22.6031 26.8191 24.091 25.7757 25.3108 24.4561C26.5306 23.1365 27.4541 21.5713 28.0192 19.8655C28.5843 18.1596 28.7779 16.3527 28.5872 14.5658C28.3965 12.779 27.8257 11.0536 26.9133 9.50548C26.0009 7.95737 24.7678 6.62234 23.297 5.58998C21.8261 4.55762 20.1514 3.85182 18.3854 3.51994ZM21.7454 21.0133C21.2608 21.4471 20.6698 21.7448 20.0328 21.876C19.3957 22.0071 18.7353 21.9671 18.1187 21.7599C15.3248 20.9732 12.9009 19.2203 11.2787 16.8133C10.6591 15.962 10.161 15.0286 9.7987 14.0399C9.6025 13.4663 9.56712 12.8499 9.6964 12.2576C9.82567 11.6653 10.1147 11.1197 10.532 10.6799C10.7352 10.4206 11.0118 10.2285 11.3257 10.1286C11.6396 10.0287 11.9764 10.0257 12.292 10.1199C12.5587 10.1866 12.7454 10.5733 12.9854 10.8666C13.18 11.4173 13.408 11.9559 13.6654 12.4799C13.8606 12.7473 13.9421 13.081 13.8922 13.4083C13.8422 13.7355 13.6648 14.0297 13.3987 14.2266C12.7987 14.7599 12.892 15.1999 13.3187 15.7999C14.262 17.1588 15.5635 18.2296 17.0787 18.8933C17.5054 19.0799 17.8254 19.1199 18.1054 18.6799C18.2254 18.5066 18.3854 18.3599 18.5187 18.1999C19.292 17.2266 19.052 17.2399 20.2787 17.7733C20.6694 17.9373 21.048 18.1293 21.412 18.3466C21.772 18.5599 22.3187 18.7866 22.3987 19.1066C22.4757 19.4538 22.4554 19.8154 22.3401 20.1518C22.2249 20.4882 22.0191 20.7863 21.7454 21.0133Z"
          fill="#004FE8"
        />
      </g>
    </svg>
  );
};

export default WhatsappIcon;
