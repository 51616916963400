import React, { useState, useContext, useEffect } from 'react';
 
import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions'
import { BoxEffects, headerButton } from '../../../../Config/Constants'
import Box from '@mui/material/Box';
import { PrimaryButton, SecondaryButton } from '@assystant/mui-buttons';
import formatISOString from '../../../../utils/formatISOString';
import ProfileContainer from '../../../shared-component/table-component/Row/ProfileContainer';
import { apiService } from '../../../../constant/AuthConstant';
import useExit from '../../../../utils/useExit';
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import Loading from '../../../shared-component/Loading/Loading';
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import UserContext from '../../../../context/UserContext';
import FilterFields from '../../leads/Fields/FilterFields';
import DetailSideDrawer from '../../ParentComponents/SideDrawer/DetailSideDrawer';

 
const arr1 = [
    { title: 'Conversions', url: '/' },
  ]

const AffiliatePortalConversions = () =>{
  const { exitFromPlatform } = useExit();
  const { 
    currencies,
    affiliateOrganizations,
    returnFormattedCurrency
   } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [conversationsList, setConversationsList] = useState([]);
  const [selectedBuilder, setSelectedBuilder] = useState(affiliateOrganizations?.length > 0 ? affiliateOrganizations[0] : null);
  const [sideDrawer, setSideDrawer] = useState(false)
  const [selectedForm, setSelectedForm] = useState(null);

  const [propertyLoading, setPropertyLoading] = useState(false)
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [unitLoading, setUnitLoading] = useState(false)
  const [unitDetails, setUnitDetails] = useState(null)
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [unitStatus, setUnitStatus] = useState(null)
  
  const handleSideDrawer = () => {
    setSideDrawer(!sideDrawer)
  }
  
  const openDrawerWithPropertyDetails = (pro) =>{
    setSelectedInventory(null)
    setSelectedProperty(pro)
    handleSideDrawer()
  }
  
  const openDrawerWithUnitDetails = (uni) =>{
    setSelectedProperty(null)
    setSelectedInventory(uni)
    handleSideDrawer()
  }

  
 
  const getConversionData = (builderId) =>{
    setLoading(true)
    apiService.get(`affiliates/affiliate_conversions/?builder=${builderId}`).then((res) =>{
      if(res?.data){
        const list = [...res?.data]?.sort((a,b) => (a.modified < b.modified) ? 1 : ((b.modified < a.modified) ? -1 : 0))
      setConversationsList(list)
      }else{
        setConversationsList([])
      }
      setLoading(false)
    }).catch((error) =>{
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
          setLoading(false)
      }
    }
    })
  }

  useEffect(() =>{
    if(selectedBuilder?.id){
         getConversionData(selectedBuilder?.organization?.id)
    }
  }, [selectedBuilder])

  const returnStatus = (status) => {
    if(status === 'appoved'){
      return "Unpaid"
    }else if(status === 'rejected'){
      return "Rejected"
    }else if(status === 'approved'){
      return "Unpaid"
    }else if(status === 'pending'){
      return "Pending"
    }else{
      return "Paid"
    }
  }

  const returnColor = (status) => {
    if(status === 'approved'){
      return "#FF5C5C"
    }else if(status === 'rejected'){
      return "#FF5C5C"
    }else if(status === 'pending'){
      return "#FAD200"
    }else{
      return "#24D315"
    }
  }

  const columns = [
    { field: 'lead', headerName: 'Customer Details',minWidth: 250, renderCell: (params) =>{
        if(params?.row?.lead?.id){
            return <ProfileContainer profile={{
                name: params?.row?.lead?.name,
                email: params?.row?.lead?.email,
            }}/>
        }else{
            return <ProfileContainer profile={{
                name: params?.row?.booking?.name,
                email: params?.row?.booking?.email,
            }}/>
        }
    }},
    { field: 'reward', headerName: 'Rewards',minWidth: 200, renderCell: (params) =>{
      const findCurrency = currencies?.find((e) =>{
        return e?.currencyCode === selectedBuilder?.organization?.default_currency
      })
       return `${returnFormattedCurrency(findCurrency?.locale, findCurrency?.currencyCode, params?.row?.reward)}`
    }},
    { field: 'booking', headerName: "Conversion Type",minWidth: 200, renderCell: (params) =>{
      return params?.row?.booking?.id ? "Booking" : "Lead"
    }},
    { field: 'property', headerName: 'Property',minWidth: 200, renderCell: (params) =>{
      
      return <div onClick={(e) =>{
        e.stopPropagation()
        if(params?.row?.booking?.property?.name){
          openDrawerWithPropertyDetails(params?.row?.booking?.property)
         }
         else if(params?.row?.lead?.property?.name){
          openDrawerWithPropertyDetails(params?.row?.lead?.property)
         }
      }}>
        {params?.row?.booking?.property?.name ?  params?.row?.booking?.property?.name : params?.row?.lead?.property?.name}
      </div>
    }},
    { field: 'unit', headerName: 'Unit',minWidth: 200, renderCell: (params) =>{
      return <div onClick={(e) =>{
        e.stopPropagation()
        if(params?.row?.booking?.unit?.name){
          openDrawerWithUnitDetails(params?.row?.booking?.unit)
         }
         else if(params?.row?.lead?.unit?.name){
          openDrawerWithUnitDetails(params?.row?.lead?.unit)
         }
      }}>
        {params?.row?.booking?.unit?.name ?  params?.row?.booking?.unit?.name : params?.row?.lead?.unit?.name}
      </div>
    }},
    // { field: 'sorce', headerName: 'Source',minWidth: 200, renderCell: (params) =>{
    //     return params?.row?.source || "-"
    // }},
    { field: 'created', headerName: 'Date & Time',minWidth: 200, renderCell: (params) =>{
        return formatISOString(params?.row?.created)
    }},
    { field: 
        'conversion_status', 
        headerName: 'Status',
        minWidth: 200,
        renderCell: (params) => (
            <>
              <div style={{
                fontFamily: 'Rubik',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '23.7px',
                letterSpacing: '-0.022em',
                color: returnColor(params?.row?.conversion_status),
              }}>

              {returnStatus(params?.row?.conversion_status)}
              </div>
            </>
            ),
    },
]

const fetchInventory = (unitId) => {
  setUnitLoading(true);
  apiService
    .get(`property/unit/${unitId}`)
    .then((res) => {
      if (res?.data?.output_specs?.groups) {
        const newData = [...res?.data?.output_specs?.groups];

        const updateSpecsGroups = newData?.map((e) => {
          const findGroup = res?.data?.specs?.groups?.find((f) => {
            return f?.grp_id === e?.grp_id;
          });

          return {
            grp_id: e?.grp_id,
            items: e?.items?.map((f) => {
              const findItem = findGroup?.items?.find((g) => {
                return g?.item_id === f?.item_id;
              });

              return {
                ...f,
                [f?.item?.name]: findItem?.item_value,
              };
            }),
          };
        });

        let newArray = [...updateSpecsGroups];
        const newData1 = {
          ...res?.data,
          images: res?.data?.images?.map((e) =>{
            return {
              ...e,
              tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
            }
          }),
          booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),
          specs: {
            template_id: res?.data?.specs?.template_id,
            groups: newArray,
          },
        }
        setUnitDetails(newData1);
      } else {
      setUnitDetails({
        ...res?.data,
        booking_unit: res?.data.booking_unit.filter(unit => unit.booking_status==='confirmed'),});
       }
      setBookingData(res?.data)
      setUnitStatus(res?.data?.status);
      setUnitLoading(false);
    })
    .catch((error) => {
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setUnitLoading(false);
        }
      }
    });
};


const fetchPropertyDetails = (propertyId) => {
  setPropertyLoading(true);
  apiService
    .get(`property/prop/${propertyId}/`)
    .then(async (res) => {
      setPropertyDetails({
        ...res?.data,
        image_list: res?.data?.image_list?.map((e) =>{
          return {
            ...e,
            tags: typeof e?.tags === "string" ? JSON.parse(e?.tags) : []
          }
        }),
      });
      setPropertyLoading(false);
    })
    .catch((error) => {
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setPropertyLoading(false);
        }
      }
  });
}

const returnFormData = () =>{
  let dataObj = {}
  if(selectedProperty?.id){
    dataObj = {
        propertyData: propertyDetails,
      }
  }
  else if(selectedInventory?.id){
    dataObj = {
      unitStatus: unitStatus,
      inventoryData: unitDetails
    }
  }
  return dataObj
}

    return(
        <>
           <Box
            sx={{ 
            borderBottom: BoxEffects.borderBottom, 
            borderColor: 'secondary.main', 
            padding: BoxEffects.padding, 
            marginBottom: BoxEffects.marginButton, 
            paddingLeft: BoxEffects.margin, 
            paddingRight: headerButton.marginRigt,position:'sticky',
            top:'65px',
            background:'white',
            zIndex:99
            }}
        >
        <BreadCrumbWithActions propArr={arr1}>
          <div className='flex justify-between items-center'>
          <div className='flex items-center w-72' style={{
              marginLeft:'20px'
            }}>
            <FilterFields 
               value={selectedBuilder}
               setValue={setSelectedBuilder}
               placeholder="Select Builder"
               isControllingHeight
               defaultOptions={affiliateOrganizations}
               titleKey="title"
            />
            </div>
          </div>
        </BreadCrumbWithActions>
      </Box>
       <div className='m-6'>
           {loading ? <Loading />
            :
            <>
             {conversationsList?.length > 0 ?
               <DataGridTable 
                 columns={columns}
                 rows={conversationsList}
                
               />
               :
               <EmptyCard 
                 title='There are no conversions available'
               />
              }
            </>}

        {sideDrawer && (
           <DetailSideDrawer 
              title={selectedInventory?.id ? `Unit ${selectedInventory?.id}` : `Property ${selectedProperty?.id}`}
              open={sideDrawer}
              onClose={handleSideDrawer}
              formData={returnFormData()}
              isPropertyComponent={selectedProperty?.id ? true : false}
              loading={selectedProperty?.id ? propertyLoading : unitLoading}
              fetchCallback={() =>{
                 if(selectedProperty?.id){
                    fetchPropertyDetails(selectedProperty?.id)
                 }else{
                    if(selectedInventory?.id){
                      fetchInventory(selectedInventory?.id)
                    }
                 }
              }}
              isUnitComponent={selectedInventory?.id ? true : false}
           />
       )}
       </div>
        </>
    )
}

export default AffiliatePortalConversions