import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box } from '@mui/material';

function RadioCells({ label = '', radioList = [] }) {
  const radioStyle = {
    width: '20px',
    height: '20px',
  };
  return (
    <RadioGroup
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
    >
      <Box className="flex justify-around items-center py-2">
        {radioList.map((e, i) => {
          return (
            <FormControlLabel
              value={e.value}
              control={
                <Radio
                 checked={e.value}
                 disabled={true}
                 />
              }
              label={e.label}
              sx={{ marginRight: 0, marginLeft: 0 }}
            />
          );
        })}
      </Box>
    </RadioGroup>
    
  );
}
export default RadioCells;
