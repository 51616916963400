import React, { useState } from 'react'
import RegularCheckbox from '../../../shared-component/OverviewPage/overview-used-components/RegularCheckbox'
import AccordianComponent from './component/AccordianComponent'
import { Checkbox, Stack } from '@mui/material'

function Template7({ 
    data = [] , 
    val,
    popupPosition,
    popupClose,
    isTemplateScreen,
    onCheckSubmit = () =>{},
    selectedTemplate,
    isSubmitting
}) {
    const [itemNameColor, setItemNameColor] = useState(selectedTemplate?.item_name_color || '#8E8CA3');
    const [brandColor, setBrandColor] = useState( selectedTemplate?.title_color|| '#004FE8');
    const [itemDetailColor, setItemDetailColor] = useState(selectedTemplate?.item_details_color|| '#004FE8');
    const [backgroundColor, setBackgroundColor] = useState(selectedTemplate?.background_color || '#F5F5F5');
    const images = [
        {image: 'https://plus.unsplash.com/premium_photo-1686090449192-4ab1d00cb735?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
        {image: 'https://images.unsplash.com/photo-1600047509807-ba8f99d2cdde?q=80&w=2792&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
        {image: 'https://images.unsplash.com/photo-1448630360428-65456885c650?q=80&w=2934&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
    ]
    const [currentImage, setCurrentImage] = useState(images[0]?.image);
    const [currentIndex, setCurrentIndex] = useState(0)
    const unitDetails = [
        {name: "Unit Name", value: "A101"},
        {name: "Unit Name", value: "AVAILABLE"}
    ]

    const specification = [
        {name: "Block", value: "A Block"},
        {name: "Facing", value: "North"},
        {name: "Carpet Area", value: "450 sqft."},
        {name: "Bathroom", value: "3"},
        {name: "Color", value: "Pale Peach"},
        {name: "Balcony", value: "1"},
    ]

    // useEffect(() =>{
    //   setInterval(() =>{
    //     let index = currentIndex
    //     setCurrentIndex(index++)
    //   }, 3000)
    // }, [])


    return (
        <div key={val?.id} className='mx-6 my-4'>
            {val && <div
                className='mb-6 p-2 flex justify-between items-center'
                style={{
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    color: 'black',
                    width: '350px',
                    height: 'auto',
                    border: '2px solid #E1EBFE',
                    fontWeight: 400,
                }}
            >Template - SM2 <div><Checkbox disabled={isSubmitting} checked={selectedTemplate?.template_chosen === "TEMPLATE3"} onChange={(e)=>{
                e.preventDefault()
                onCheckSubmit('TEMPLATE3')
            }}/></div></div>}
            <div
                style={{
                    borderRadius: '4px',
                    backgroundColor: backgroundColor,
                    width: '350px',
                    height: 'auto',
                    border: '2px solid #E1EBFE',
                    position: !val && 'absolute',
                    left:!val && `${popupPosition.x}px`,
                    top: !val && `${popupPosition.y}px`,
                    zIndex:  !val && 9999,
                    borderRadius: '8px',
                    padding:'20px'
                }}
            >
                <div>
                    <div className='w-full h-48'>
                       <img src={images[currentIndex]?.image} className='w-full h-full object-cover'/>
                    </div>
                    <div className="flex justify-center">
                       {images?.map((e, i) =>{
                        return  <div className='mx-1 my-2 w-2 h-2 rounded-full bg-[#EDECF9]'
                         style={{
                            background: currentIndex === i ? brandColor : '#EDECF9'
                         }}
                        ></div>
                       })}
                    </div>
                </div>
                <div className='mb-5'>
                <AccordianComponent 
                  mainTitle="Unit Details"
                  accrodianStyles={{
                    border: '2px solid #E1EBFE',
                    boxShadow:'none',
                }}
                accordianDetailsStyles={{
                    borderTop: '2px solid #E1EBFE',
                    color:itemNameColor,
                }}
                accordianSummaryStyles={{
                    fontSize: '15px',
                    color:itemNameColor,
                    fontWeight:400
                }}
                component={
                    <> 
                    <Stack className="!grid !grid-cols-2">
                        {unitDetails?.map((e) =>{
                            return (
                                <div className='flex flex-col'>
                                <div
                                style={{
                                    fontFamily:'Rubik',
                                    fontWeight:400,
                                    fontSize:'10px',
                                    color:itemNameColor
                                }}
                                >{e?.name}</div>
                                <div
                                style={{
                                    fontFamily:'Rubik',
                                    fontWeight:400,
                                    fontSize:'14px',
                                    color:itemDetailColor
                                }}
                                >{e?.value}</div>
                            </div>
                            )
                        })}
                    </Stack>
                    </>
                    }
                    />
                </div>
                <div className='mb-5'>
                <AccordianComponent 
                   mainTitle="Specifications"
                   accrodianStyles={{
                    border: '2px solid #E1EBFE',
                    boxShadow:'none'
                }}
                accordianSummaryStyles={{
                    fontSize: '15px',
                    color:itemNameColor,
                    fontWeight:400
                }}
                accordianDetailsStyles={{
                    borderTop: '2px solid #E1EBFE',
                    color:itemNameColor,
                }}
                component={
                    <> 
                    <Stack className="!grid !grid-cols-2">
                        {specification?.map((e) =>{
                            return (
                                <div className='flex flex-col mb-4'>
                                <div
                                style={{
                                    fontFamily:'Rubik',
                                    fontWeight:400,
                                    fontSize:'10px',
                                    color:itemNameColor
                                }}
                                >{e?.name}</div>
                                <div
                                style={{
                                    fontFamily:'Rubik',
                                    fontWeight:400,
                                    fontSize:'14px',
                                    color:itemDetailColor
                                }}
                                >{e?.value}</div>
                            </div>
                            )
                        })}
                    </Stack>
                       <div
                        style={{
                            fontFamily:'Rubik',
                            fontWeight:400,
                            fontSize:'10px',
                            color:itemNameColor
                        }}
                        >{'Description'}</div>
                        <div
                        style={{
                            fontFamily:'Rubik',
                            fontWeight:400,
                            fontSize:'14px',
                            color:itemDetailColor
                        }}
                        >Imagine a life that propvides all the comforts of a modern lifestyle.</div>
                    </>
                    }
                />
                </div>
                <div style={{
                    padding: '14px 16px 14px 16px',
                    background: brandColor,
                    borderRadius:'4px',
                    textAlign:'center',
                    //styleName: Button Large;
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '18.96px',
                    color:'white'
                }}>
                    View Details
                </div>
                
            </div>
        </div>
    )
}

export default Template7