// ----------------- Documentation ---------------------------
// this component handels the input data for the editor and store the data


import React from "react";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/xml/xml';
// import 'codemirror/mode/javascript/javascript';
import './codeeditor.css';
import { Controlled as ControlledEditor } from "react-codemirror2";

function Editor(props){
    const {
        displayName,
        language,
        value,
        onChange
    } = props;
    function handelChange(editor,data,value){
        onChange(value)
    }
    return(
       
            <ControlledEditor
                onBeforeChange={handelChange}
                value={value}
                
                className="code-mirror-wrapper"
                options={{
                    lineWrapping: true,
                    lint: true,
                    mode: language,
                    lineNumbers: true,
                    // theme: 'material'
                }}
            />
       
    )
}
export default Editor;