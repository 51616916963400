import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { headerButton, BoxEffects } from '../../../../Config/Constants';
import BreadCrumbWithActions from '../../../shared-component/breadcrumb-component/BreadCrumbWithActions';
import PrimaryBtn from '../../../shared-component/button-component/PrimaryBtn';
import Loading from '../../../shared-component/Loading/Loading';
import EmptyCard from '../../../shared-component/card-component/EmptyCard';
import DataGridTable from '../../../shared-component/table-component/DataGridTable';
import UserContext from '../../../../context/UserContext';
import formatISOString from '../../../../utils/formatISOString';
import ProfileContainer from '../../../shared-component/table-component/Row/ProfileContainer';
import { apiService } from '../../../../constant/AuthConstant';
import returnError from '../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import useExit from '../../../../utils/useExit';
import { capitalizeFirstLetter } from '../../../../utils/helper';
const arr1 = [{ title: 'Transactions', url: '/' }];

const sampleData = [
  {
    id:1,
    organization_name: 'AZ ORG',
    phone: '9090909090',
    reward: '$20',
    invoice_date: '2024-07-05T08:09:02.913879Z',
    invoice_amount: '$2500',
    joined_date: '2024-07-05T08:09:02.913879Z',
    active: true,
  },
  {
    id:2,
    organization_name: 'KL Homes',
    phone: '9090909090',
    reward: '$20',
    invoice_date: '2024-07-05T08:09:02.913879Z',
    invoice_amount: '$3000',
    joined_date: '2024-07-05T08:09:02.913879Z',
    active: true,
  },
];

const Clients = () => {
  const { exitFromPlatform } = useExit();
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const { currencies, returnFormattedCurrency } = useContext(UserContext);

  const columns = [
    {
      field: 'lead',
      headerName: 'Customer Details',
      minWidth: 300,
      renderCell: (params) => {
        return (
          <ProfileContainer
            profile={{
              name: params?.row?.organization?.name,
              email: params?.row?.organization?.phone_number,
              picture: params?.row?.organization?.brand_logo
            }}
          />
        );
      },
    },
    {
      field: 'reward_amount',
      headerName: 'Rewards',
      minWidth: 200,
      renderCell: (params) => {
        const findCurrency = currencies?.find((e) =>{
          return e?.currencyCode === params?.row?.organization?.default_currency
        })
         return `${returnFormattedCurrency(findCurrency?.locale, findCurrency?.currencyCode, params?.row?.reward_amount)}`
      },
    },
    {
      field: 'invoice_date',
      headerName: 'Invoice Date',
      minWidth: 200,
      renderCell: (params) => {
        return formatISOString(params?.row?.invoice_date, '');
      },
    },
    {
      field: 'invoice_amount',
      headerName: 'Invoice Amount',
      minWidth: 200,
      renderCell: (params) => {
        const findCurrency = currencies?.find((e) =>{
          return e?.currencyCode === params?.row?.organization?.default_currency
        })
         return `${returnFormattedCurrency(findCurrency?.locale, findCurrency?.currencyCode, params?.row?.invoice_amount)}`
      },
    },
    {
      field: 'created',
      headerName: 'Date & Time',
      minWidth: 200,
      renderCell: (params) => {
        return formatISOString(params?.row?.joined_date, '');
      },
    },
    {
      field: 'active',
      headerName: 'Status',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div
            style={{
              fontFamily: 'Rubik',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '23.7px',
              letterSpacing: '-0.022em',
              color: params?.row?.reward_status === 'unpaid' ? '#FF5C5C' : '#24D315',
            }}
          >
            {capitalizeFirstLetter(params?.row?.reward_status)}
          </div>
        );
      },
    },
  ];

  const retreiveAllTransactions = () =>{
    setLoading(true)
    apiService.get(`transactions/entries/`).then((res) =>{
      if(res?.data?.length){
        setTransactions(res?.data)
      }
      setLoading(false)
    }).catch((error) =>{
      setLoading(false)
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (returnedError === 'Given token not valid for any token type') {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
        }
      }
    });
  }

  useEffect(() =>{
    retreiveAllTransactions()
  }, [])
  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: 'secondary.main',
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.margin,
          paddingRight: headerButton.marginRigt,
          position: 'sticky',
          top: '65px',
          background: 'white',
          zIndex: 99,
        }}
      >
        <BreadCrumbWithActions propArr={arr1} />
      </Box>
      <div className="m-6">
        {loading ? (
          <Loading />
        ) : transactions?.length ? (
          <DataGridTable rows={transactions} columns={columns} />
        ) : (
          <EmptyCard
            title="There are no transaction"
          />
        )}
      </div>
    </>
  );
};

export default Clients;
