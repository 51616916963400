// ----------------- Documentation ---------------------------
// this component used for show the data from specification list 

import { useContext, useEffect, useState } from "react";
import {
  PrimaryButton,
  SecondaryButton,
} from "@assystant/mui-buttons";
import { Box } from "@mui/material";
import Tabs from "@assystant/mui-tabs";
import BreadCrumbWithActions from "../../../shared-component/breadcrumb-component/BreadCrumbWithActions";
import { BoxEffects, headerButton } from "../../../../Config/Constants";
import SpecificationFilter from "./SpecificationFilter";
import SpecificationGroups from "./groups/SpecificationGroups";
import SpecificationTemplate from "./template/SpecificationTemplate";
import AddGroup from "./groups/AddGroup";
import AddTemplate from "./template/AddTemplate";
import DeleteAlert from "./DeleteAlert";
import SpecificationAddUnit from "./AddUnit";
import Loading from "../../../shared-component/Loading/Loading";
import axios from "axios";
import { API_URL, apiService } from "../../../../constant/AuthConstant";
import SpecificationItem from "./SpecificationItem";
import { useSnackbar } from "notistack";
import UserContext from "../../../../context/UserContext";
import { uniqueId } from 'lodash';
import PrimaryBtn from "../../../shared-component/button-component/PrimaryBtn";
import LabTabs from "../../../../publishcomponents-20230906T111139Z-001/publishcomponents/tabcomponent/src/LabTabs";
import useExit from "../../../../utils/useExit";
import returnError from "../../../../utils/ExportError";
import { enqueueSnackbar } from "notistack";

function Specification() {

  const arr1 = [{ title: "Specifications", url: "/" }];
  const {enqueueSnackbar} = useSnackbar();
  const [load, setLoad] = useState(false);
  const [fetch, setFetch] = useState(false)
  const [value, setValue] = useState("1");
  const [openSpecificationFilter, setOpenSpecificationFilter] = useState(false);
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [openAddTemplate, setOpenAddTemplate] = useState(false);
  const [openAddUnit, setOpenAddUnit] = useState(false);
  const [specificationItemList, setSpecificationItemList] = useState([]);
  const [specificationGroups, setSpecificationGroups] = useState([]);
  const [specificationTemplateList, setSpecificationTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);
  const { exitFromPlatform } = useExit()

  const {orgId} = useContext(UserContext);

  const handleItemEditData = (data) =>{
    setSelectedItems(data)
    setOpenAddUnit(true)
  }

  const handleGroupEditData = (data) =>{
    setSelectedGroup(data)
    setOpenAddGroup(true)
  }

  const handleTemplateEditData = (data) =>{
    setSelectedTemplate(data)
    setOpenAddTemplate(true)
  }

  const fetItems = () => {
    setLoad(true)
    apiService.get(`specs/item/`).then((res) =>{
      setSpecificationItemList(res?.data)
      setLoad(false)
      console.log(res)
    }).catch((error) =>{
    
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
          setLoad(false)
      }
      }
    })
  }

  const fetchGroups = () => {
    setLoad(true)
    apiService.get(`specs/group/`).then((res) =>{
      setSpecificationGroups(res?.data)
      setLoad(false)
      console.log(res)
    }).catch((error) =>{
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
          setLoad(false)
      }
      }
    })
  }

  const fetchTemplates = () => {
    setLoad(true)
    apiService.get(`specs/template/`).then((res) =>{
      setSpecificationTemplateList(res?.data)
      setLoad(false)
      console.log(res)
    }).catch((error) =>{
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
          setLoad(false)
      }
      }
    })
  }

  const loadAll = () => {
    fetItems();
    fetchGroups();
    fetchTemplates();
  }

  useEffect(loadAll, [fetch])

  const callApiRequestForDelete = (apiUrl, successCallback, module) =>{
    apiService.delete(apiUrl).then((res)=>{
      enqueueSnackbar(`${module} deleted`,{variant:'success'})
      successCallback()
    }).catch((error)=>{
      if(error.response?.data){
        const returnedError = returnError(error.response?.data)
        if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
          exitFromPlatform()
      }else{
          enqueueSnackbar(returnedError, {variant:'error'})
        
      }
      }
    })
  }

  const handelDeleteModal = (row, module) =>{
    let apiUrl;
    if(module === 'Item'){
        apiUrl = `specs/item/${row?.id}/`
    }else if(module === 'Group'){
      apiUrl = `specs/group/${row?.id}/`
    }else if(module === 'Template'){
      apiUrl = `specs/template/${row?.id}/`
    }
    console.log('from delete', row)
  if(module === 'Item'){
   if(row?.group_names?.length > 0){
    enqueueSnackbar('Item is linked with group, Cannot be deleted.', {variant: 'default'})
   }else{
    callApiRequestForDelete(apiUrl, loadAll, module)
   }
  
  }
  if(module === 'Group'){
    if(row?.template_names?.length > 0){
      enqueueSnackbar('Group is linked with template, Cannot be deleted.', {variant: 'default'})
     }else{
      callApiRequestForDelete(apiUrl, loadAll, module)
     }
  }
 
  if(module === 'Template'){
    if(row?.group_names?.length > 0){
      enqueueSnackbar('Template is linked with group, Cannot be deleted.', {variant: 'default'})
     }else{
      callApiRequestForDelete(apiUrl, loadAll, module)
     }
  }
  }



  const tabs = [
    {
      label: "Items",
      value: "1",
      page: load ? <Loading /> 
       : <SpecificationItem 
          specificationItemList={specificationItemList} 
          handelDeleteModal={handelDeleteModal} 
          handleItemEditData={handleItemEditData}
          />,
    },
    { label: "Groups", value: "2", 
       page: load ? <Loading /> 
         : <SpecificationGroups 
             handelDeleteModal={handelDeleteModal} 
             specificationGroups={specificationGroups} 
             handleGroupEditData={handleGroupEditData}
             /> },
    { label: "Templates", value: "3", 
     page: load ? <Loading /> 
       : <SpecificationTemplate 
           handelDeleteModal={handelDeleteModal} 
           specificationGroups={specificationGroups} 
           specificationTemplateList={specificationTemplateList}
           handleTemplateEditData={handleTemplateEditData} 
           /> },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handelSpecificationFilter = () => {
    setOpenSpecificationFilter(!openSpecificationFilter);
  };
  const handelAddGroup = () => {
    setOpenAddGroup(!openAddGroup);
    setSelectedGroup(null)
  };
  const handelAddTemplate = () => {
    setOpenAddTemplate(!openAddTemplate);
    setSelectedTemplate(null)
  };
  const handelAddUnit = () => {
    setOpenAddUnit(!openAddUnit);
    setSelectedItems(null)
  };
  console.log('from template data', selectedTemplate)
  const modifyTemplateData = (data) =>{
     const newData = {
       ...data,
       groups: data?.group_names?.map((e) =>{
          const find = specificationGroups?.find((f) =>{
            return e === f?.name
          })
          return find
       })
     }
     console.log('from new data', newData)
     return newData
  }

  const modifyGroupData = (data) =>{
    const newData = {
      ...data,
      templates: data?.template_names?.map((e) =>{
       const find = specificationTemplateList?.find((f) =>{
        return e === f?.name
       })
       return find
      })
    }

    return newData
  }

  const modifyItemsData = (data) =>{
    const values = JSON.parse(data?.dropdown_values)?.map((e) =>{
      console.log('from drag example', e)
      return {
        id:uniqueId('File_'),
        drag_example: e
      }
    })
    const newData = {
      ...data,
      type: data?.type === 'DROPDOWN' ? { title: 'Dropdown - Single Selection', id: 'single' } : { title: 'TextField', id: 'TEXTFIELD' },
      groups: data?.group_names?.map((e) =>{
        const find = specificationGroups?.find((f) =>{
          return e === f?.name
        })
        return find
     }),
     object_choices: values,
  }
  return newData
}


  return (
    <>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: "secondary.main",
          padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
          paddingLeft: BoxEffects.padding,
          paddingRight: headerButton.marginRigt,
          position: 'sticky', top: '65px', backgroundColor: 'white', zIndex: 99
        }}
      >
        <BreadCrumbWithActions propArr={arr1}>
          {value === "1" ? (
            <>
              {/* <SecondaryButton
                style={{ boxShadow: 'none', backgroundColor: '#EDECF9' }}

                title="Filter"
                onClick={handelSpecificationFilter}
              /> */}
              <PrimaryBtn style={{ boxShadow: 'none', marginRight:'0px !important' }} title="Add New" onClick={handelAddUnit} />
            </>
          ) : (
            <>
              {value === "2" ? (
                <>
                  <PrimaryBtn style={{ boxShadow: 'none', marginRight:'0px !important' }} title="Add New" onClick={handelAddGroup} />
                </>
              ) : (
                <>
                  {value === "3" ? (
                    <>
                      <PrimaryBtn
                        style={{ boxShadow: 'none', marginRight:'0px !important' }}
                        title="Add New"
                        onClick={handelAddTemplate}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </BreadCrumbWithActions>
      </Box>
      <Box
        sx={{
          borderBottom: BoxEffects.borderBottom,
          borderColor: "secondary.main",
         // padding: BoxEffects.padding,
          marginBottom: BoxEffects.marginButton,
         // paddingLeft: BoxEffects.padding,
         // paddingRight: headerButton.marginRigt,
        }}
      // sx={{borderBottom:'1px solid'}}
      >
        <LabTabs
          tabs={tabs}
          setValue={setValue}
          value={value}
          handleChange={handleChange}
        />
      </Box>
      {openSpecificationFilter && (
        <SpecificationFilter
          title="Filter"
          open={openSpecificationFilter}
          onClose={handelSpecificationFilter}
        />
      )}
      {openAddGroup && (
        <AddGroup
          title={selectedGroup?.name ? "Edit Group" : "Add Group"}
          open={openAddGroup}
          onClose={handelAddGroup}
          setOpenAddGroup={setOpenAddGroup}
          specificationItemList={specificationItemList}
          setFetch={setFetch}
          successCallback={loadAll}
          data={selectedGroup?.name ? modifyGroupData(selectedGroup) : {}}
          setGroups={setSpecificationGroups}
          groups={specificationGroups}
        />
      )}
      {openAddTemplate && (
        <AddTemplate
          title={selectedTemplate?.name ? "Edit Template" : "Add Template"}
          open={openAddTemplate}
          onClose={handelAddTemplate}
          setOpenAddTemplate={setOpenAddTemplate}
          specificationGroups={specificationGroups}
          setFetch={setFetch}
          successCallback={loadAll}
          data={selectedTemplate?.name ? modifyTemplateData(selectedTemplate) : {}}
          setTemplate={setSpecificationTemplateList}
          templates={specificationTemplateList}
        />
      )}
      {/* {handelAlertModal && (
        <DeleteAlert
          openModal={handelAlertModal}
        handelModal={handelDeleteModal}
        />
      )} */}
      {openAddUnit && (
        <SpecificationAddUnit
          title={selectedItems?.name ? "Edit Items" : "Add Items"}
          open={openAddUnit}
          onClose={handelAddUnit}
          setOpenAddUnit={setOpenAddUnit}
          setFetch={setFetch}
          specificationItemList={specificationItemList}
          setItems={setSpecificationItemList}
          specificationGroups={specificationGroups}
          successCallback={loadAll}
          data={selectedItems?.name ? modifyItemsData(selectedItems) : {}}
        />
      )}
    </>
  );
}
export default Specification;
