import React, { useEffect, useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
const Status = ({
  style = {},
  unitStatus,
  successStatus = "AVAILABLE",
  handleStatusChange = () => {},
  children,
  disabled = false
}) => {
  const [statusColor, setStatusColor] = useState('#FF5C5C');
  console.log('from status........', unitStatus);
  useEffect(() => {
    let success = false;
    if(Array.isArray(successStatus)) {
      success = successStatus.includes(unitStatus);
    } else {
      success = unitStatus === successStatus;
    }
    setStatusColor(success ? '#24D315' : '#FF5C5C');
  }, [unitStatus, successStatus]);
  return (
    <FormControl fullWidth>
       {children ?(
      <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={unitStatus}
      label=""
      onChange={(e) => {
        handleStatusChange(e);
      }}
      sx={{
        height: '30px',
        ...style,
        background: 'white !important',
        color: statusColor,
        fontWeight: 300,
      }}
      disabled={disabled}
    >
      {children ? 
        children
       : (
        <>
          <MenuItem
            value="AVAILABLE"
            sx={{ color: '#24D315 !important', fontWeight: 300 }}
          >
            Available
          </MenuItem>
          <MenuItem
            value="SOLD"
            sx={{ color: '#FF5C5C !important', fontWeight: 300 }}
          >
            Sold
          </MenuItem>
        </>
      )}
    </Select>
       ):(
        <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={unitStatus}
        label=""
        onChange={(e) => {
          handleStatusChange(e);
        }}
        disabled={disabled}
        sx={{
          height: '30px',
          ...style,
          background: 'white !important',
          color: unitStatus === successStatus ? '#24D315' : '#FF5C5C',
          fontWeight: 300,
        }}
      >
         <MenuItem
              value="AVAILABLE"
              sx={{ color: '#24D315 !important', fontWeight: 300 }}
            >
              Available
            </MenuItem>
            <MenuItem
              value="SOLD"
              sx={{ color: '#FF5C5C !important', fontWeight: 300 }}
            >
              Sold
            </MenuItem>
      </Select>
       )}
    </FormControl>
  );
};

export default Status;
