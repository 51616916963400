import React from 'react'

const Dashboard = ({ color = "#8E8CA3"}) =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <mask id="1" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                    <rect width="32" height="32" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_3238_1842)">
                    <path d="M17.3333 12V4H28V12H17.3333ZM4 17.3333V4H14.6667V17.3333H4ZM17.3333 28V14.6667H28V28H17.3333ZM4 28V20H14.6667V28H4ZM6.66667 14.6667H12V6.66667H6.66667V14.6667ZM20 25.3333H25.3333V17.3333H20V25.3333ZM20 9.33333H25.3333V6.66667H20V9.33333ZM6.66667 25.3333H12V22.6667H6.66667V25.3333Z" fill={color} />
                </g>
            </svg>
    )
}

export default Dashboard