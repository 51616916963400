import React, { useContext, useEffect, useState } from 'react'
import FormModal from '../../../shared-component/formmodal-component/FormModal'
import FormManager from '../../../shared-component/formrelated-component/FormManager'
import { apiService } from '../../../../constant/AuthConstant';
import { enqueueSnackbar } from 'notistack';
import returnError from '../../../../utils/ExportError';
import useExit from '../../../../utils/useExit';
import UserContext from '../../../../context/UserContext';

const AddBooking = ({
    open,
    data,
    title,
    onClose,
    successCallback,
    organization,
    isUnit = false
}) =>{
    const [selectedProp, setSelectedProp] = useState({name:'', value:'', id:''});
    const [loading, setLoading] = useState(false)
    const { exitFromPlatform } = useExit()
    const {
        userFromOrganization
    } = useContext(UserContext)

    const defaultValues = {
        name:'',
        email:'',
        phone:'',
        unit:'',
        address:'',
        property:'',
        ...data,
    }

    useEffect(() =>{
     if(data?.property?.id){
        setSelectedProp(data?.property)
     }
    }, [data])

    const handleSubmitForBooking = (e) =>{
        if(e?.name === ''){
            enqueueSnackbar('Name is required', {variant:'error'})
        }else if(e?.email === ''){
            enqueueSnackbar('Email is required', {variant:'error'})
        }else if(e?.phone === ''){
            enqueueSnackbar('Phone is required', {variant:'error'})
        }else{
         let reg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
         let emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         if(reg.test(e?.phone) === false){
          enqueueSnackbar('Phone is invalid', {variant:'error'})

         }else if(emailReg.test(e?.email) === false){
          enqueueSnackbar('Email is invalid', {variant:'error'})
         }else{
            setLoading(true)

            let apiUrl = '';
            let service = '';
            let payload = {};
            if(data?.id){
                apiUrl = `booking/entries/${data?.id}/`
                service = apiService.put
                payload = {
                ...e,
                property: e?.property?.id,
                unit: e?.unit?.id,
                organization: organization?.id,
                employee: userFromOrganization?.id,
                }
                if(e?.form?.id){
                    payload['form'] = e?.form?.id
                }else{
                    payload['form'] = null
                }
                if(e?.profile_icon?.name){
                    payload['profile_icon'] = e?.profile_icon?.name
                }
            }
            else{
                apiUrl = `booking/entries/`
                service = apiService.post
                payload = {
                    ...e,
                    property: e?.property?.id,
                    unit: e?.unit?.id,
                    organization: organization?.id,
                    employee: userFromOrganization?.id
                    }
            }
            service(apiUrl, payload).then((res) =>{
                if(data?.id){
                    enqueueSnackbar('Edited', {variant:'success'})
                }else{
                    enqueueSnackbar('Booking Added', {variant:'success'})
                }
                if(successCallback){
                    successCallback()
                }
                setLoading(false)
                onClose()
            }).catch((error) =>{
                setLoading(false)
                if(error.response?.data){
                    const returnedError = returnError(error.response?.data)
                    if(returnedError === 'Given token not valid for any token type'){
                        exitFromPlatform()
                    }else{
                        enqueueSnackbar(returnedError, {variant:'error'})
                    }
                }
            })
         }
        }
       
    }

    const fieldMap = [
        {
            name:'',
            type:'box_layout',
            label:'Applicant Details',
            borderType:'none',
            fieldProps: {
                arrayFields:[
                    {
                        name:'name',
                        label:'Name',
                        placeholder:'Name',
                        type:'text',
                        fieldProps: {
                            required: true,
                        }
                    },
                    {
                        name:'phone',
                        label:'Phone',
                        placeholder:'Phone',
                        type:'text',
                        cols:2,
                        fieldProps: {
                            required: true,
                        }
                    },
                    {
                        name:'email',
                        label:'Email',
                        placeholder:'Email',
                        type:'text',
                        cols:2,
                        fieldProps: {
                            required: true,
                        }
                    },
                    {
                        name:'address',
                        label:'Address',
                        placeholder:'Address',
                        type:'text',
                        fieldProps: {
                            required: true,
                        }
                    },
                ]
            }
        },
        {
            name:'',
            type:'box_layout',
            label:'Property Details',
            borderType:'none',
            fieldProps: {
                arrayFields:[
                    {
                        name:'property',
                        label:'Property',
                        placeholder:'Property',
                        type:'select',
                        fieldProps:{
                            choicesAPI:"/property/prop/?filtered_data=true",
                            titleKey:'name',
                            setState: setSelectedProp,
                            resetFields: ['unit'],
                            disabled: isUnit === true ? true : false,
                            required: true,
                        },
                        cols:2
                    },
                    {
                        name:'unit',
                        label:'Unit',
                        placeholder:'Unit',
                        type:'select',
                        fieldProps:{
                            choicesAPI: selectedProp?.id === '' ? 'noAPI' : `/property/unit/?filtered_data=true&property=${selectedProp?.id}`,
                            titleKey:'name',
                            beforeCallMessage:'Please select property first',
                            disabled: isUnit === true ? true : false,
                            isFilteringUnit: true,
                            required: true,
                        },
                        cols:2
                    },
                ]
            }
        }
    ]
    return (
        <FormModal
          title={title}
          open={open}
          onClose={onClose}
        >
            <div className='mt-3'>
            <FormManager 
              onCancel={onClose}
              defaultValues={defaultValues}
              fieldMap={fieldMap}
              data={data ? data : {}}
              formSubmit={handleSubmitForBooking}
              secondaryBtnTitle={data?.property ? "Skip" : "Cancel"}
              loading={loading}
            />
            </div>
        </FormModal>
    )
}

export default AddBooking