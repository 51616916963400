
const returnError = (errorData) =>{
    const error = Object.values(errorData);
    const keys = Object.keys(errorData)
    
    const errorM = error.map((e) =>{
        if(Array.isArray(e)){
            return e[0]
        }else{
           return e
        }
    })
    if(keys[0] === 'new_password'){
        return `${'New Password'} - ${errorM[0]}`
    }else if(keys[0] === 're_new_password'){
        return `${'Confirm Password'} - ${errorM[0]}`
    }else if(keys[0] === 'current_password'){
        return `${'Current Password'} - ${errorM[0]}`
    }else{
        return errorM[0]
    }
}

export default returnError;