import React, { useCallback, useEffect, useRef, useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GallaryDesignOneModal from './Modals/GallaryDesignOneModal';


const GallaryDesignOne = () =>{
    const imageslist = [
        {id: 1,
        image: "https://images.unsplash.com/photo-1502672260266-1c1ef2d93688?q=80&w=2880&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {id: 2,
            image: "https://plus.unsplash.com/premium_photo-1672252617539-878656f17efe?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {id: 3,
            image: "https://images.unsplash.com/photo-1522708323590-d24dbb6b0267?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {id: 4,
            image: "https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {id: 5,
            image: "https://images.unsplash.com/photo-1512918728675-ed5a9ecdebfd?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {id: 6,
            image: "https://images.unsplash.com/photo-1628592102751-ba83b0314276?q=80&w=2897&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {id: 7,
            image: "https://images.unsplash.com/photo-1574362848149-11496d93a7c7?q=80&w=2884&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {id: 8,
            image: "https://images.unsplash.com/photo-1580041065738-e72023775cdc?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
    ]

    const [activeIndex, setActiveIndex] = useState(0);
    const [translate, setTranslate] = useState(0);
    const [carouselStyle, setCarouselStyle] = useState({});
    // const [divWidth, setDivWidth] = useState(0);
    // const carouselRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    const containerRef = useRef(null);
    const itemRefs = useRef([]);
    

    const handleOpenModal = (i) =>{
        if(i){
            setActiveIndex(i)
        }
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const onNextPress = (index) => {
       if(activeIndex !== imageslist?.length - 1){
        const translateToLeft = activeIndex + 1;
        setActiveIndex(translateToLeft)
       }
        // const translateX = translate - 150
        // setTranslate(translateX)
    }

    const onPrevPress = () => {
        if(activeIndex !== 0){
            const translateToRight = activeIndex - 1;
            setActiveIndex(translateToRight)
        }
        // const translateX = translate + 150
        // setTranslate(translateX)
    }

    useEffect(() => {
        const currentItem = itemRefs.current[activeIndex];
        if (currentItem) {
          const rect = currentItem.getBoundingClientRect();
        
          const containerRect = containerRef.current.getBoundingClientRect();
          if (rect.left < containerRect.left || rect.right > containerRect.right) {
            console.log('from rect......', -rect.left, containerRect.left, -rect.left + containerRect.left)
            const translateTo = -rect.left + containerRect.left;
           if(translateTo < 0){
            setTranslate(-rect.left + containerRect.left);
           }else{
            setTranslate(0)
           }
          }
        }
      }, [activeIndex]);

      useEffect(() =>{
       console.log('from translate', translate)
      }, [translate])



    const carousel = useCallback(() =>{
       return(
              <div className='flex gap-[20px]' style={{transition: "transform 0.5s ease-in-out", transform: `translateX(${translate}px)`}}>
                {imageslist?.map((e, i) =>{
                    return (
                        <img className='object-cover cursor-pointer' 
                         ref={(el) => (itemRefs.current[i] = el)}
                         src={e?.image} 
                         style={{
                            width: '150px', 
                            height:'150px', 
                            border: activeIndex === i ? "2px solid #E1EBFE" : "none", 
                            borderRadius:'4px'
                        }} 
                         onClick={() =>{
                            handleOpenModal(i)
                         }} />
                    )
                })}
              </div>
       )
    }, [translate, activeIndex])

 
    return (
    <>
      <div className='flex items-center justify-between'>
         <div className='w-[5%] flex items-center' onClick={onPrevPress}>
             <ArrowBackIosNewIcon className='!fill-[#000000] cursor-pointer' />
          </div>

          <div className='w-[90%] overflow-hidden' ref={containerRef}>
              {carousel()}
          </div>

          <div className='w-[5%] flex items-center' onClick={onNextPress}>
             <ArrowForwardIosIcon className='!fill-[#000000] cursor-pointer' />
          </div>
      </div>

      {openModal && (
        <GallaryDesignOneModal 
           open={openModal}
           onClose={handleCloseModal}
           list={imageslist}
           activeIndex={activeIndex}
        />
      )}
    </>
    )
}

export default GallaryDesignOne;