import React, { useState } from 'react';
import SignatureField from '../../components/Documents/components/SignatureField';
import SignatureComponent from '../../components/Documents/components/SignatureComponent';
import { useFormContext } from 'react-hook-form';
import ToolTipComponent from '../Tooltip/ToolTipComponent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const SignatureWrapper = ({
    name,
    label,
    error,
    tooltip,
}) =>{

    const { setValue, getValues } = useFormContext();
    const [signed_image, setSigned_image] = useState('');
    const [openSignatureModal, setSignatureModal] = useState(false);
    

    const handleSignatureModal = () =>{
        setSignatureModal(!openSignatureModal)
    }

    const handleSignedValue = (signed_value) =>{
        setValue(name, signed_value)
        setSigned_image(signed_value)
        handleSignatureModal()
    }

    return (
        <>
         <div className='text-md font-normal text-[#353349]'>
                {label}
        </div>
          <div className='flex gap-[10px] mt-2.5 mb-4 items-center'>
             <SignatureField 
                className={error ? `!w-full border-[#FF5C5C]` : `!w-full`}
                image={signed_image?.length > 0 ? signed_image : ''}
                onClick={handleSignatureModal}
             />
             {tooltip && <ToolTipComponent title={tooltip} offset={[50, -50]}>
                 <ErrorOutlineIcon color='#FFFFFF' className='!fill-[#8E8CA3]' fontSize='20' />
             </ToolTipComponent>}
          </div>

          {openSignatureModal && (
            <SignatureComponent 
               open={openSignatureModal}
               onSave={handleSignedValue}
               onClose={handleSignatureModal}
            />
          )}
        </>
    )
}

export default SignatureWrapper