import React from 'react';

const SignatureField = ({
    image = '',
    className,
    onClick = () => {},
}) =>{
    return( 
       
        image?.length > 0  ?(
            <div className={`border border-[#8E8CA3] rounded-md bg-[#E1EBFE] flex items-center justify-center w-3/5 h-32 p-8 ${className}`} onClick={onClick}>
            {image?.length > 0  ?(
                <img src={image} style={{
                    width: '230px'
                }} className='object-contain' />
            ) : (<div></div>)}
        </div>
        ) : ( <div className={`border border-[#8E8CA3] rounded-md bg-[#E1EBFE] flex items-end w-3/5 h-32 p-8 ${className}`} onClick={onClick}>
        <div className='h-2 border-[#8E8CA3] border-b border-dashed w-full'></div>
    </div>)
    )
}

export default SignatureField;