// ----------------- Documentation ---------------------------
// this component used for add and edit the data from assign inventory
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup
// edit                    boolead             used for the edit form
// edittitle               string              to maintain the edit form title of the popup
// data                    array               to show the default form value in the edit form

import React, { useEffect } from "react";
import { useState } from "react";
import { uid } from "react-uid";
import { useContext } from "react";
import { useSnackbar } from "notistack";
import FormModal from "../../../../shared-component/formmodal-component/FormModal";
import FormManager from "../../../../shared-component/formrelated-component/FormManager";
import UserContext from "../../../../../context/UserContext";
import { Autocomplete, InputLabel, List, ListItem, ListItemButton, ListItemText, Select, TextField, createFilterOptions } from "@mui/material";
import { FormControl, MenuItem } from "@mui/base";
import axios from "axios";
import { API_URL, apiService } from "../../../../../constant/AuthConstant";
import PrimaryBtn from "../../../../shared-component/button-component/PrimaryBtn";
import SecondaryBtn from "../../../../shared-component/button-component/SecondaryBtn";

import { useParams } from "react-router-dom";
import returnError from "../../../../../utils/ExportError";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useExit from "../../../../../utils/useExit";



function AddLinkModal({
    title,
    open,
    onClose,
    successCallback,
    setSubmit,
    data,
    mapImageData
}) {
    console.log('from url', document.location.href)
    const { id } = useParams()
    const [mapList, setMapList] = useState([]);
    const [invList, setInvList] = useState([]);
    const [value, setValue] = useState('')
    const [map, setMap] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [websiteLink, setWebsiteLink] = useState('');
    const [error, setError] = useState(false);
    const [greenLabel, setGreenLabel] = useState('')
    const { exitFromPlatform } = useExit()
    const fetchInventory = () => {
        const url = document.location.href;
        const regex = /\/properties\/(\d+)\/plan\//;
        const match = url.match(regex);

        const ids = match ? match[1] : null;
        apiService.get(`/property/unit/?filtered_data=true&property=${ids}`).then((res) =>{
            if(data?.unit_link !== null){
                const find = res?.data?.find((e) =>{
                    return e?.id === data?.row?.unit_link?.id
                })
                setValue({id: find?.id, value: find?.name})
            }
            if(res?.data?.length > 0){
                setInvList(res?.data)
            }else{
                setInvList([{id: "No Options", name: 'No Options'}])
            }
            console.log(res)
        }).catch((error) =>{
            console.log(error)
        })
    };

   
    const fetchMapList = () =>{
        const url = document.location.href;
        const regex = /\/properties\/(\d+)\/plan\//;
        const match = url.match(regex);

        const ids = match ? match[1] : null;
        apiService.get(`property/mapplan/?property=${ids}`).then((res) =>{
            const filterMaps = res?.data?.filter((e) =>{
                return e?.id !== Number(id)
            })
            if(data?.map_link !== null){
                const find = res?.data?.find((e) =>{
                    return e?.id === data?.row?.map_link?.id
                })
                console.log('from maps', find)
                setValue({id: find?.id, value: find?.name})
            }
            console.log('from maps List', id, res?.data)
            if(filterMaps?.length > 0){
                setMapList(filterMaps)
            }else{
                setMapList([{id: "No Options", name: 'No Options'}])
            }
            console.log(res)
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                  exitFromPlatform()
              }else{
                  enqueueSnackbar(returnedError, {variant:'error'})
                  setLoad(false)
              }
              }
        })
    }
    useEffect(() =>{
        console.log('from the maps data', data)
        if(data?.row?.id){
            if(data?.row?.link_type === 'MAP'){
                setInvList([])
                fetchMapList();
                setMap('Map')
            }else if(data?.row?.link_type === 'URL'){
                setMap('URL')
                setWebsiteLink(data?.row?.url_link)
            }
            else if(data?.row?.link_type === 'UNIT'){
                setMap('Unit')
                fetchInventory()
                setMapList([])
            }else if(data?.row?.link_type === 'GREENSPACE'){
                setMap('Greenspace')
                setGreenLabel(data?.row?.greenspace_label)
            }
            else{
                setMap('')
                setValue('')
            }
        }
     }, [data])


    const handleChangeSelect = (e) => {
        console.log(e)
        setError('')
        if (e.target.innerText === 'Map') {
            setMap('Map')
           
            fetchMapList();
            setInvList([])
        }else if(e.target.innerText === 'URL'){
            setMap('URL')
        }else if(e?.target?.innerText === 'Greenspace'){
            setMap('Greenspace')
        }
        else {
            setMap('Unit')
            fetchInventory()
            setMapList([])
        }
    }

    const handleLinkToSelect = (e, d) => {
        console.log('from unit value', e, d)
        setValue(d)
    }

    const checkUrl = (url) =>{
        axios.get(url).then((res) =>{
            console.log('from status url',res.status)
        }).catch((error) =>{
            console.log('from status url error',error)
        
        })
    }


    async function handelAddLink() {
        console.log('from add link', map, value)
        if(map === 'URL'){
            checkUrl(websiteLink)
        }
        setLoading(true)
        if(map === ''){
            enqueueSnackbar('Please select link type', {variant:'error'})
        }
        else if(!value?.id && map !== 'URL' && map !== 'Greenspace'){
            enqueueSnackbar('Please select link to', {variant:'error'})
        }else if(map === 'URL' && websiteLink === ''){
            enqueueSnackbar('Please add website link', {variant:'error'})
        }
        else{
            let newData = {};
            if(map === 'Unit'){
                newData = {
                    link_type: "UNIT",
                    unit_link: value?.id,
                    map_link:null,
                    url_link:''
                 }
            }else if(map === 'Map') {
                newData = {
                    link_type: "MAP",
                    map_link: value?.id,
                    unit_link:null,
                    url_link:null
                 }
            }else if(map === 'Greenspace'){
                newData = {
                    link_type: "GREENSPACE",
                    map_link: null,
                    unit_link:null,
                    url_link:null,
                    greenspace_label: greenLabel || ''
                 }

            }
            else{
                 newData = {
                    link_type: "URL",
                    map_link: null,
                    unit_link:null,
                    url_link:websiteLink
                 }
            }
            console.log('from new Daa', newData)
             apiService.patch(`property/hotspot/${data?.id}/`, newData).then((res) =>{
                if(map === 'Map'){
                    enqueueSnackbar('Hotstpot linked with map', {variant:"success"})
                }else{
                    enqueueSnackbar('Hotstpot linked with unit', {variant:"success"})
                }
                setLoading(false)
                successCallback()
                onClose()
                console.log(res)
             }).catch((error) =>{
                setLoading(false)
                if(error.response?.data){
                    const returnedError = returnError(error.response?.data)
                    if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                      exitFromPlatform()
                  }else{
                      enqueueSnackbar(returnedError, {variant:'error'})
                      setLoad(false)
                  }
                  }
             })
        }
    }

    const onWebsiteLinkChange = (e) =>{
        // /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
        const io = e.target.value;
        setWebsiteLink(io)
        const regex = /^(https?|ftp):\/\/[^\s\/$.?#]+(\.[^\s\/$.?#]+)*\.com[^\s]*$/;
        if(regex.test(io)){
            setError('')
        }else{
            setError('Please add a valid Url. eg: https://www.spotmink.com/')
        }
    }
    return (
        <>
            <FormModal title="Add Link" open={open} onClose={onClose}>
                <div className="w-full p-6">
                    <Autocomplete
                        fullWidth
                        id="1"
                        defaultValue={map}
                        value={map}
                        popupIcon={<ExpandMore className='!fill-[#8E8CA3]'/>}
                        onChange={(e) => handleChangeSelect(e)}
                        options={[{ id: 1, title: 'Map' }, { id: 2, title: 'Unit' }, {id: 3, title: 'URL'}, {id:4, title: 'Greenspace'}].map((val) => val.title)}
                        renderInput={(params) => <TextField fullWidth {...params} label="Link type" />}
                    />
                    {map === 'URL' || map === 'Greenspace' ? 
                    <>
                      <TextField
                        defaultValue={websiteLink || greenLabel}
                        onChange={(e) =>{
                            if(map === 'URL'){
                                onWebsiteLinkChange(e)
                            }else{
                                setGreenLabel(e?.target?.value)
                            }
                        }}
                        label={map === "URL" ? "Website Link" : "Description"}
                        error={error}
                        helperText={error && 'Please enter valid url'}
                        className="w-full"
                       />
                    </>
                    :
                    <Autocomplete
                        fullWidth
                        loading={mapList.length === 0 || invList.length === 0}
                        id="combo-box-demo"
                        value={value}
                        popupIcon={<ExpandMore className='!fill-[#8E8CA3]'/>}
                        options={map === 'Unit' ? 
                         invList?.map((val) => { return { id: val.id, value: val.name } })
                         : mapList?.map((val) => { return { id: val.id, value: val.name }} )}
                        renderOption={(props, option) => (
                            <li {...props} key={option?.id} >{option.value}</li>
                        )}
                        getOptionDisabled={(option)=>{
                            if(option?.value === "No Options"){
                                return true
                            }
                            console.log('from options', option)
                        }}
                        onChange={handleLinkToSelect}
                        getOptionLabel={(option) => option?.value || ''}

                        renderInput={(params) => <TextField fullWidth label="Link to" sx={{ marginBottom: '15px', marginTop: '5px' }} {...params} />}
                    />}
                </div>
                <div className="w-full flex justify-end px-8 pb-8">
                    <SecondaryBtn style={{ backgroundColor: '#EDECF9' }} onClick={onClose} title='Cancel' />
                    <PrimaryBtn title='Add' disabled={map === "URL" && error} onClick={handelAddLink} />
                </div>
            </FormModal>
        </>
    )
}
export default AddLinkModal;