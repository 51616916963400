import React from 'react'
import DataGridTable from '../../../shared-component/table-component/DataGridTable'
import { API_URL } from '../../../../constant/AuthConstant';
import StatusLabel from '../../../shared-component/OverviewPage/overview-used-components/StatusLabel';
import { IconButton } from '@mui/material';

import RegularCheckbox from '../../../shared-component/OverviewPage/overview-used-components/RegularCheckbox';
import TextBtn from '../../../shared-component/button-component/TextBtn';

import EmptyCard from '../../../shared-component/card-component/EmptyCard';

import DeleteIcon from '../../../../asset/icons/DeleteIocn';
 


function SpecificationItem({ 
    handelDeleteModal, 
    specificationItemList, 
    specificationGroups,
    handleItemEditData,

 }) {


    const columns = [
        {
            field: 'checkbox',
            headerName: '',
            minWidth: 50,
            headerClassName: 'custom-header',
            renderCell: (params) => (
                <RegularCheckbox />
            ),
        },
        {

            field: 'id',
            headerName: 'ID',
            minWidth: 80,
            headerClassName: 'custom-header',
        },

        {
            field: "name", headerName: "Item Name", width: 250, renderCell: (params) => (
                params.row.name || '-'
            )
        },
        { field: "type", headerName: "Item Type", width: 200, valueGetter: (params) => {
            if(params.row?.type === 'TEXTFIELD'){
                 return 'Text Field'
            }else if(params.row?.type === 'DROPDOWN'){
                 return 'Single Selection Field'
            }else{
               return  '-'
            }
        } },
        { field: "group_names", headerName: "Linked Groups", width: 250, valueGetter: (params) => {
            const newGroupNames = params.row?.group_names?.map((val,i)=>{
                if(val === 'f2cc91d1-6c12-4a22-af81-e4a4aa0ba929'){
                    return "Location Details"
                }else{
                    return val
                }
            })
            console.log('from group names', newGroupNames)
            if(newGroupNames?.length > 0){
                return newGroupNames
            }else{
                return '-'
            }
           
        } },

        {
            field: "template_names",
            headerName: "Linked Template",
            //   type: 'number',
            width: 250,
            renderCell: (params) => (
                <>
                  {params?.row?.template_names?.length > 0 ?
                  <StatusLabel status={params.formattedValue} color="primary" />
                  :
                  "-"
                  }
                </>
            ),
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            width: 150,
            align: "right",
            renderCell: (params) => (
                <>
                <div className='w-full flex items-center px-2 cursor-pointer text-right justify-end'>
                <TextBtn 
                 style={{
                    fontWeight: 300,
                 }}
                title="Edit" onClick={() =>{
                        handleItemEditData(params?.row)
                    }}/>
                        <div onClick={() => { handelDeleteModal(params.row, 'Item') }}>
                        <DeleteIcon color="error" className="!fill-[#FF5C5C]"  />
                        </div>
                    </div>
                </>
            ),
        },
    ];
    return (
        specificationItemList.length === 0 ? 
        <EmptyCard title="No Item Available" subtitle='Click “Add New" to add the Item'/> 
         :
        <DataGridTable
            rows={specificationItemList}
            columns={columns}
            checkboxSelection={false}
        />
    )
}

export default SpecificationItem