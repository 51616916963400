import RegularTextField from './RegularTextField';
import Select from './Select';
import FormField from './FormField';
import BoxLayout from './BoxLayout';
import QuickMenuButton from '../button-component/QuickMenuButton';
import DropZoneField from './DropZoneField';

import FilterButton from './FilterButton';
import RegularCheckbox from './RegularCheckBox';
import DraggableField from './DrageableField';

import FormAvtar from './FormAvtar';
import AssignInventory from '../../components/properties/AssignInventory';
import SingleAddImage from './SingleAddImage';
import ImageUpload from './imageUpload/ImageUpload';
import ScriptPaste from './ScriptPaste';
import SubBoxLayout from './SubBoxLayout';
import IconSelection from './IconSelection';
import CurrencyField from './CurrencyField';
import PhoneField from './PhoneField';
import RadioButton from './RadioButton';
import RadioButtonsGroup from './RadioGroup';
import DatePickerComponent from './DatePickerComponent';
import SignatureWrapper from './SignatureWrapper';
import CustomizedFileUpload from './CustomizedFileUpload';


const FormFieldMapper = ({ type, ...props }) => {
  switch (type) {
    case 'box_layout':
        return  <BoxLayout key={props.name} {...props}/>
        case 'empty':
          return  <></>
    
    case 'sub_box_layout':
      return  <SubBoxLayout key={props.name} {...props}/>
       
    case 'select':
      return (
        <FormField
          Component={Select}
          key={props.name}
          {...props}
          // fieldProps={{
          //   multiple: true,
          //   ...props.fieldProps,
          // }}
        />
      );
      case 'inventory':
      return (
        <AssignInventory
          {...props}
        />
      );
      case 'single_image':
      return (
        <SingleAddImage
          key={props.name} 
          {...props}
        />
      );
      case 'file_upload':
        return <ImageUpload key={props.name} {...props}/>;
        case 'avtar':
        return <FormAvtar key={props.name} {...props} />;
      case 'button':
        return (
          <FormField
            Component={FilterButton}
            key={props.name}
            {...props}
            // fieldProps={{
            //   multiple: true,
            //   ...props.fieldProps,
            // }}
          />
        );
        case 'draggableField':
        return(
          <DraggableField key={props.name} {...props}/>
        )
        case 'checkbox':
        return (
          <FormField
            Component={RegularCheckbox}
            key={props.name}
            {...props}
            // fieldProps={{
            //   multiple: true,
            //   ...props.fieldProps,
            // }}
          />
        );
        case 'radio':
        return (
          <FormField
            Component={RadioButton}
            key={props.name}
            {...props}
            // fieldProps={{
            //   multiple: true,
            //   ...props.fieldProps,
            // }}
          />
        );
        case 'radio_groups':
          return (
            <FormField
              Component={RadioButtonsGroup}
              key={props.name}
              {...props}
              // fieldProps={{
              //   multiple: true,
              //   ...props.fieldProps,
              // }}
            />
          );
      case 'link':
        return (
          <FormField
            Component={Select}
            key={props.name}
            {...props}
            fieldProps={{
              multiple: true,
              ...props.fieldProps,
            }}
          />
        );
        case 'image':
        return (
          <FormField
            Component={DropZoneField}
            key={props.name}
            {...props}
            fieldProps={{
              multiple: true,
              ...props.fieldProps,
            }}
          />
        );
    case 'textarea':
      return (
        <FormField
          Component={RegularTextField}
          key={props.name}
          {...props}
          fieldProps={{
            multiline: true,
            rows: 5,
            ...props.fieldProps,
          }}
        />
      );
      case 'quick_menu':
        return (
          <FormField
            Component={QuickMenuButton}
            key={props.name}
            {...props}
            fieldProps={{
              multiple: true,
              ...props.fieldProps,
            }}
          />
        );
    case 'password':
      return (
        <FormField
          Component={RegularTextField}
          key={props.name}
          {...props}
          fieldProps={{
            type: 'password',
            ...props.fieldProps,
          }}
        />
      );
      case 'icon_selection':
        return (
          <FormField
            Component={IconSelection}
            key={props.name}
            {...props}
            fieldProps={{
              ...props.fieldProps,
            }}
          />
        );
      case 'scriptPaste':
        return (
          <FormField
            Component={ScriptPaste}
            key={props.name}
            {...props}
            fieldProps={{
              ...props.fieldProps,
            }}
          />
        );
        case 'phone':
        return (
          <FormField
            Component={PhoneField}
            key={props.name}
            {...props}
            fieldProps={{
              ...props.fieldProps,
            }}
          />
        );
        case 'currency':
        return (
          <FormField
            Component={CurrencyField}
            key={props.name}
            {...props}
            fieldProps={{
              ...props.fieldProps,
            }}
          />
        );
        case 'date':
          return (
            <FormField
              Component={DatePickerComponent}
              key={props.name}
              {...props}
              fieldProps={{
                ...props.fieldProps,
              }}
            />
          );
          case 'signature':
          return (
            <FormField
              Component={SignatureWrapper}
              key={props.name}
              {...props}
              fieldProps={{
                ...props.fieldProps,
              }}
            />
          );
          case 'custom_file_upload':
            return (
              <FormField
                Component={CustomizedFileUpload}
                key={props.name}
                {...props}
                fieldProps={{
                  ...props.fieldProps,
                }}
              />
            );
    default:
      return <FormField Component={RegularTextField} key={props.name} {...props} />;
  }
};
export default FormFieldMapper;