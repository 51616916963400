import React, { useState, useEffect } from 'react';
import DeleteIcon from '../../../../../../../asset/icons/DeleteIocn';
import Loading from '../../../../../../shared-component/Loading/Loading';
import DataGridTable from '../../../../../../shared-component/table-component/DataGridTable';
import { useNavigate, useParams } from 'react-router-dom';
import useExit from '../../../../../../../utils/useExit';
import returnError from '../../../../../../../utils/ExportError';
import { enqueueSnackbar } from 'notistack';
import { apiService } from '../../../../../../../constant/AuthConstant';
import RemovePopup from '../../../../../../shared-component/RemovePopup/RemovePopup';
import EmptyCard from '../../../../../../shared-component/card-component/EmptyCard';


const sampleData = [
    {id: 1, main_menu: 'Main Menu', location: 'Header'},
]
const WebsiteMenus = () =>{
    const { webId } = useParams()
    const navigate = useNavigate()
    const { exitFromPlatform } = useExit()
    const [menus, setMenus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [removeModal, setRemoveModal] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(null);

    const handleOpenRemoveModal = (id) =>{
      setSelectedMenu(id)
      setRemoveModal(true)
    }

    const handleCloseRemoveModal = () =>{
      setRemoveModal(false)
    }

    const columns = [
        {
          field: 'id',
          headerName: 'ID',
          minWidth: 50,
        },
        { field: 'menu_title', headerName: 'Menu Title', minWidth: 300 },
        { field: 'menu_location', headerName: 'Location',minWidth: 200 },
        {
        field: 'actions',
        headerName: '',
        sortable: false,
        minWidth: 50,
        align:'center',
        renderCell: (params) => (
            <>
                <div className="cursor-pointer" onClick={(e)=>{
                e.stopPropagation()
                e.preventDefault()
                handleOpenRemoveModal(params?.row?.id)
                }}>
                    <DeleteIcon color="error" className="!fill-[#FF5C5C]"  />
                </div>
            </>
            ),
        },
      ];

    const handleRowClick = (event) =>{
        navigate(`/websites/${webId}/menu/${event?.id}`)
    }

    const getMenus = () =>{
        setLoading(true)
        apiService.get(`website/menu/?website=${webId}`).then((res) =>{
            if(res?.data?.length){
                setMenus(res?.data)
                localStorage.setItem('website-tabs', null)
            }
            setLoading(false)
        }).catch((error) =>{
            if (error.response?.data) {
              const returnedError = returnError(error.response?.data);
              if (
                returnedError === 'Given token not valid for any token type' ||
                returnedError === 'Request failed with status code 401'
              ) {
                exitFromPlatform();
              } else {
                enqueueSnackbar(returnedError, { variant: 'error' });
                setLoading(false)
              }
            }
          })
    }

    useEffect(() =>{
        getMenus()
    }, [])

    const removeMenu = () =>{
      apiService.delete(`website/menu/${selectedMenu}/?website=${webId}`).then((res) =>{
         if(res?.status === 204){
          enqueueSnackbar('Deleted', { variant: 'success' })
          const updateList = [...menus]?.filter((e) =>{
            return e?.id !== selectedMenu
          })
          setMenus(updateList)
          handleCloseRemoveModal()
         }
      }).catch((error) =>{
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
          }
        }
      })
    }



    return (
       <>
        {loading ? <Loading />
       :
        <>
          {menus?.length === 0 ?
            <EmptyCard 
               title='There are no menus'
               subtitle="Click “Add New” to add a menu"

            />
          :
          <DataGridTable
          rows={menus} 
          columns={columns}
          actionWidth={100}
          handleEvent={handleRowClick}
       />}
        </>}

       {removeModal && (
        <RemovePopup
          open={removeModal}
          close={handleCloseRemoveModal}
          description={"Are you sure, You want remove this menu?"} 
          onSubmit={removeMenu}
        />
       )}
       </>
    )
}

export default WebsiteMenus