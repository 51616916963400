import React from 'react';

const IconForUpload = ({
    color
}) =>{
    return (
        <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.9">
            <circle cx="50.5" cy="50.5" r="50.5" fill="#353349"/>
            </g>
            <mask id="mask0_8269_11318" maskUnits="userSpaceOnUse" x="34" y="32" width="34" height="32">
            <rect x="34.6562" y="32.4736" width="32.615" height="30.5635" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_8269_11318)">
            <path d="M45.5288 54.1226H56.4004V53.4222C56.4004 52.4671 55.9022 51.7081 54.9056 51.1452C53.909 50.5832 52.5953 50.3022 50.9646 50.3022C49.3338 50.3022 48.0202 50.5832 47.0236 51.1452C46.027 51.7081 45.5288 52.4671 45.5288 53.4222V54.1226ZM50.9646 49.0287C51.712 49.0287 52.3521 48.7791 52.8848 48.2799C53.4166 47.7816 53.6825 47.1822 53.6825 46.4818C53.6825 45.7814 53.4166 45.1815 52.8848 44.6823C52.3521 44.184 51.712 43.9348 50.9646 43.9348C50.2172 43.9348 49.5776 44.184 49.0457 44.6823C48.513 45.1815 48.2467 45.7814 48.2467 46.4818C48.2467 47.1822 48.513 47.7816 49.0457 48.2799C49.5776 48.7791 50.2172 49.0287 50.9646 49.0287ZM40.0929 59.2165C39.3455 59.2165 38.7059 58.9674 38.1741 58.469C37.6414 57.9698 37.375 57.37 37.375 56.6696V41.3879C37.375 40.6874 37.6414 40.0881 38.1741 39.5897C38.7059 39.0905 39.3455 38.8409 40.0929 38.8409H44.3736L46.8877 36.2939H55.0415L57.5556 38.8409H61.8363C62.5837 38.8409 63.2238 39.0905 63.7565 39.5897C64.2883 40.0881 64.5542 40.6874 64.5542 41.3879V56.6696C64.5542 57.37 64.2883 57.9698 63.7565 58.469C63.2238 58.9674 62.5837 59.2165 61.8363 59.2165H40.0929ZM61.8363 56.6696V41.3879H56.3325L53.8524 38.8409H48.0768L45.5967 41.3879H40.0929V56.6696H61.8363Z" fill="white"/>
            </g>
        </svg>
    )
}

export default IconForUpload


