import React, { useEffect, useState } from 'react'
import OpenStreetView from '../../Maps/component/OpenStreetView'
import { Checkbox } from '@mui/material'
import { apiService } from '../../../../constant/AuthConstant'
import useExit from '../../../../utils/useExit'
import returnError from '../../../../utils/ExportError'
import { enqueueSnackbar } from 'notistack'

const OpenStreetMapTemplate = ({
    label,
    mapItem,
    selectedMap,
    isSubmitting,
    updateSelectedMapTemplate
}) =>{
    return (
        <div>
            <div
            className='mb-6 p-2 flex justify-between items-center'
            style={{
                borderRadius: '4px',
                backgroundColor: 'white',
                color: '#353349',
                width: '350px',
                height: 'auto',
                border: '2px solid #E1EBFE',
                fontWeight: 400,
            }}
            >
                {label}
                <div>
                   <Checkbox 
                     disabled={true} 
                     checked={selectedMap === mapItem?.value} 
                     onChange={(e)=>{
                        e.preventDefault()
                        updateSelectedMapTemplate(mapItem?.value)
                    }}/>
                </div>
            </div>

            <OpenStreetView 
              mapView={[12.9716, 77.5946]}
              isTemplate={true}
              mapHeight="300px"
              markerPoints={[]}
              setSelectedCoordinates={() => {}}
            />
        </div>
    )
   
}

const MapTemplates = ({
    organization
}) =>{
    const { exitFromPlatform } = useExit()
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedMap, setSelectedMap] = useState('map_template_1')

    const templates = [
        {id: 1, label: 'Open Street Map', component: OpenStreetMapTemplate, value:"map_template_1" },
    ];

    const retrieveSelectedMap = () =>{
        setLoading(true)
        apiService.get(`property/map-template/`).then((response) =>{
            if(response){
                return;
            }
            setLoading(false)
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                    setLoading(false)
                }
            }
        })
    }

    // useEffect(() =>{
    //     retrieveSelectedMap()
    // }, [])

    const updateSelectedMapTemplate = (value) =>{
        setIsSubmitting(true)
        apiService.put(`organization/map-template/`, { 
            template: value, 
            org: organization?.id
        }).then((response) =>{
            if(response){
                return;
            }
            setIsSubmitting(false)
        }).catch((error) =>{
            if(error.response?.data){
                const returnedError = returnError(error.response?.data)
                if(returnedError === 'Given token not valid for any token type' || returnedError === 'Request failed with status code 401'){
                    exitFromPlatform()
                }else{
                    enqueueSnackbar(returnedError, {variant:'error'})
                    setIsSubmitting(false)
                }
            }
        })
    }
    return(
        <div className='my-6 max-h-[60vh] overflow-y-scroll'>
             {templates?.map((map) =>{
                return (
                    <map.component
                       mapItem={map}
                       label={map?.label} 
                       selectedMap={selectedMap}
                       isSubmitting={isSubmitting}
                       updateSelectedMapTemplate={updateSelectedMapTemplate}
                    />
                )
             })}
        </div>
    )
}

export default MapTemplates
