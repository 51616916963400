import React, { useEffect, useState } from 'react';
import PrimaryBtn from '../../../../shared-component/button-component/PrimaryBtn';
import SearchBar from '../../../../shared-component/searchbar/SearchBar';
import CloseIcon from '@mui/icons-material/Close';
import { BoxEffects } from '../../../../../Config/Constants';
import { Stack } from '@mui/material';
import WebsiteThemeCard from '../Cards/WebsiteThemeCard';
import { useNavigate, useParams } from 'react-router-dom';
import MobileView from '../../../../../asset/icons/MobileView';
import TabletView from '../../../../../asset/icons/TabletView';
import DesktopView from '../../../../../asset/icons/DesktopView';
import WebsitePreview from '../Preview/WebsitePreview';
import returnError from '../../../../../utils/ExportError';
import useExit from '../../../../../utils/useExit';
import { enqueueSnackbar } from 'notistack';
import { apiService, TokenStorage, BASE_URI } from '../../../../../constant/AuthConstant';
import RemovePopup from '../../../../shared-component/RemovePopup/RemovePopup';
import Loading from '../../../../shared-component/Loading/Loading';
import EmptyCard from '../../../../shared-component/card-component/EmptyCard';
import NoteIcon from '../../../../../asset/icons/NoteIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AlertModal from './AlertModal';
import axios from 'axios';

const filterList = [
  {name:'All', value:'all'},
  {name:'Single Property', value:'single-property'},
  {name:'Multi Property', value:'multi-property'},
  {name:'Marketplace', value:'marketplace'},
  {name:'MultiBrand Listing', value: 'multi-brand'},
];


const ThemesComponent = () => {
  const { webId } = useParams();
  const navigate = useNavigate();
  const { exitFromPlatform } = useExit();
  const [isShowingPreview, setIsShowingPreview] = useState(false);
  const [selectedView, setSelectedView] = useState('Desktop');
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [allThemesList, setAllThemesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [websiteDetails, setWebsiteDetails] = useState(null);
  const [originalThemes, setOriginalThemes] = useState([])
  const [templateSelectionLoading, setTemplateSelectionLocation] = useState(false);
  const [selectedType, setSelectedType] = useState({name:'All', value:'all'})
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null)

  const openAlertBox = (id) =>{
    setSelectedTheme(id)
    setOpenAlertModal(true)
  }

  const handleAlertModal = () =>{
    setOpenAlertModal(!openAlertModal)
  }

  const handleRemoveModal = () => {
    setOpenRemoveModal(!openRemoveModal);
  };


  const handlePreview = () => {
    setIsShowingPreview(true);
  };

  const goBackFromPreview = () => {
    setIsShowingPreview(false);
  };

  const handleBack = () => {
    navigate('/websites');
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  const getWebsiteDetails = () => {
    apiService
      .get(`website/site/${webId}/`)
      .then((res) => {
        if (res?.data) {
          setWebsiteDetails(res?.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };


  const retrieveAllThemes = () => {
    setLoading(true);
    apiService
      .get(`website/templates/`)
      .then((res) => {
        if (res?.data) {
          setAllThemesList(res?.data);
          setOriginalThemes(res?.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  useEffect(() => {
    getWebsiteDetails();
    retrieveAllThemes();
  }, []);

  const deleteTheme = () => {
    apiService
      .delete(`website/site/${webId}`)
      .then((res) => {
        if (res.status === 204) {
          navigate('/websites');
        }
      })
      .catch((error) => {
        if (error.response?.data) {
          const returnedError = returnError(error.response?.data);
          if (
            returnedError === 'Given token not valid for any token type' ||
            returnedError === 'Request failed with status code 401'
          ) {
            exitFromPlatform();
          } else {
            enqueueSnackbar(returnedError, { variant: 'error' });
            setLoading(false);
          }
        }
      });
  };

  const handleSearchChange = (e) => {
    setInputValue(e.target.value);
    if(e.target.value === ''){
      setAllThemesList(originalThemes)
    }else{
      const filterData = originalThemes?.filter((val) =>{
        return val?.template_name?.toLowerCase().includes(e.target.value.toLowerCase())
      })
      setAllThemesList(filterData)
    }
  };

  const handleFilterChange = (type) =>{
     if(type === 'all'){
      setAllThemesList(originalThemes)
     }else{
      const filterTemplate = originalThemes?.filter((e) =>{
        return e?.template_type === type
       })
  
       setAllThemesList(filterTemplate)
     }
  }

  const updateThemeForWebsite = () =>{
    setTemplateSelectionLocation(true);
    const formData = new FormData();
    formData.append('template', selectedTheme)
    axios({
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}website/site/${webId}/`, 
      data: formData,
      headers: { 
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${TokenStorage.getToken()}`
       },
    })
    .then(res =>{
      console.log('from response', res)
      if(res?.data){
        let clone = {...websiteDetails};
        clone = {
          ...clone,
          template: selectedTheme
        }
        setWebsiteDetails(clone)
        enqueueSnackbar('Updated', { variant: 'success' })
        handleAlertModal()
        setTemplateSelectionLocation(false);
      }
    }).catch((error) =>{
      if (error.response?.data) {
        const returnedError = returnError(error.response?.data);
        if (
          returnedError === 'Given token not valid for any token type' ||
          returnedError === 'Request failed with status code 401'
        ) {
          exitFromPlatform();
        } else {
          enqueueSnackbar(returnedError, { variant: 'error' });
          setTemplateSelectionLocation(false);
        }
      }
    })
  }

  return (
    <>
      <div className="relative h-screen">
        <div
          className="w-full bg-white flex items-center justify-between"
          style={{
            borderBottom: '1px solid #E1EBFE',
            height:'70px'
          }}
        >
          <div className="mx-6">
            <PrimaryBtn
              className="!mx-0"
              style={{
                boxShadow: 'none',
                margin: '0px !important',
                fontWeight: 300,
              }}
              title="Back"
              onClick={handleBack}
            />
          </div>
          {websiteDetails?.title && (
            <div className="mx-8 flex items-center gap-[5px] absolute left-[45%]">
              <div
                className="!text-[#8E8CA3]"
                style={{
                  fontFamily: 'Rubik',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '16.59px',
                }}
              >
                {websiteDetails?.title}
              </div>
              <KeyboardArrowDownIcon className="!fill-[#8E8CA3]" />
            </div>
          )}
          <div
            className={`flex items-center mx-6 gap-[20px]`}
          >
             <SearchBar
                searchStyles={{
                  background: '#FFFFFF',
                  border: '1px solid #E1EBFE',
                }}
                handleSearch={handleSearchChange}
              />
            <div
              className="rounded-md w-10 h-10 flex items-center justify-center cursor-pointer"
              style={{
                border: '1px solid #E1EBFE',
              }}
              onClick={handleBack}
            >
              <CloseIcon className="!fill-[#FF5C5C]" />
            </div>
          </div>
        </div>

        <div className="flex h-full">
        <>
              <div
                className="w-[20%] p-8 flex flex-col"
                style={{
                  borderRight: '1px solid #E1EBFE',
                }}
              >
                <div
                  className="text-[#353349] my-2"
                  style={{
                    fontFamily: 'Rubik',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '16.59px',
                  }}
                >
                  Filter By:
                </div>
                {filterList?.map((text) => {
                  return (
                    <div
                      className="text-[#8E8CA3] my-2 cursor-pointer"
                      onClick={() =>{
                        setSelectedType(text)
                        handleFilterChange(text?.value)
                      }}
                      style={{
                        fontFamily: 'Rubik',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '16.59px',
                        color: selectedType?.value === text?.value ? '#004FE8' : '#8E8CA3' 
                      }}
                    >
                      {text?.name}
                    </div>
                  );
                })}
              </div>

              <div className="w-[80%]">
                <div
                  className="m-4 !overflow-y-scroll"
                  style={{
                    maxHeight: '80vh',
                  }}
                >
                  {loading ? (
                    <Loading />
                  ) : allThemesList?.length !== 0 ? (
                    <Stack
                      className="!mx-4 !grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3"
                      direction="row"
                      spacing={2}
                      sx={{
                        margin: '12px',
                        rowGap: '20px',
                        columnGap: '20px',
                      }}
                    >
                      {allThemesList?.map((theme) => {
                        return (
                          <WebsiteThemeCard
                            website={theme}
                            websiteDetails={websiteDetails}
                            cardClickHandler={handlePreview}
                            updateThemeForWebsite={openAlertBox}
                            templateSelectionLoading={templateSelectionLoading}
                          />
                        );
                      })}
                    </Stack>
                  ) : (
                    <EmptyCard
                      icon={
                        <div className="w-full flex items-center justify-center">
                          <NoteIcon width={80} height={80} />
                        </div>
                      }
                      title="Themes are not available"
                    />
                  )}
                </div>
              </div>
            </>
        </div>
      </div>

      {openRemoveModal && (
        <RemovePopup
          open={openRemoveModal}
          close={handleRemoveModal}
          description={`Are you sure, you want to remove this website?`}
          onSubmit={deleteTheme}
        />
      )}


     {openAlertModal && (
        <AlertModal
          title="Alert"
          open={openAlertModal}
          close={handleAlertModal}
          isSecondary
          description={`Are you Sure you want to change the theme? The changes you have made in the existing Theme may permanently be lost.`}
          onSubmit={updateThemeForWebsite}
        />
      )}
    </>
  );
};

export default ThemesComponent;
