// ----------------- Documentation ---------------------------
// this component used for change password for profile
// this form used the firebase database to store the data

// props                   type                usedfor
// -------                 -------             --------
// title                   string              to maintain title of the popup
// open                    boolean             to check the action for open and close the popup
// onClose                 boolean             to close the popup

import React, { useState } from 'react';
import FormModal from '../../shared-component/formmodal-component/FormModal';

import { IconButton, InputAdornment, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Controller, useForm } from 'react-hook-form';
import PrimaryBtn from '../../shared-component/button-component/PrimaryBtn';
import { yupResolver } from '@hookform/resolvers/yup';
import SecondaryBtn from '../../shared-component/button-component/SecondaryBtn';

function ChangePassword({ title, open, onClose, onSubmit, schema, loading }) {
  const [showCurrent, setShowCurrent] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleCurrent = () => {
    setShowCurrent(!showCurrent);
  };
  const handleNew = () => {
    setShowNew(!showNew);
  };
  const handleConfirm = () => {
    setShowConfirm(!showConfirm);
  };
  const defaultValues = {
    current_password: '',
    new_password: '',
    re_new_password: '',
  };

  const fieldMap = [
    {
      name: 'current_password',
      label: 'Current Password',
      placeholder: 'Current Password',
      type: 'text',
    },
    {
      name: 'new_password',
      label: 'New Password',
      placeholder: 'New Password',
      type: 'text',
    },
    {
      name: 're_new_password',
      label: 'Confirm Password',
      placeholder: 'Confirm Password',
      type: 'text',
    },
  ];

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isSubmitting, isDirty, isValid } = methods?.formState;

  return (
    <>
      <FormModal title={title} open={open} onClose={onClose}>
        <div className="mx-4 my-2">
          <form onSubmit={methods?.handleSubmit(onSubmit)}>
            <Controller
              name={'current_password'}
              control={methods?.control}
              render={({ field: { onChange } }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={'Current Password'}
                  name={'current_password'}
                  type={showCurrent ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleCurrent}
                            onMouseDown={handleCurrent}
                            edge="end"
                          >
                            {showCurrent ? (
                              <VisibilityOff className="!fill-[#8E8CA3]" />
                            ) : (
                              <Visibility className="!fill-[#8E8CA3]" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                  error={!!methods?.formState.errors['current_password']}
                  helperText={
                    methods?.formState.errors['current_password']?.message
                  }
                  minRows={4}
                  autoFocus
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <Controller
              name={'new_password'}
              control={methods?.control}
              render={({ field: { onChange } }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={'New Password'}
                  name={'new_password'}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleNew}
                            onMouseDown={handleNew}
                            edge="end"
                          >
                            {showNew ? (
                              <VisibilityOff className="!fill-[#8E8CA3]" />
                            ) : (
                              <Visibility className="!fill-[#8E8CA3]" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                  type={showNew ? 'text' : 'password'}
                  error={!!methods?.formState.errors['new_password']}
                  helperText={
                    methods?.formState.errors['new_password']?.message
                  }
                  minRows={4}
                  autoFocus
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <Controller
              name={'re_new_password'}
              control={methods?.control}
              render={({ field: { onChange } }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={'Confirm Password'}
                  name={'re_new_password'}
                  type={showConfirm ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleConfirm}
                            onMouseDown={handleConfirm}
                            edge="end"
                          >
                            {showConfirm ? (
                              <VisibilityOff className="!fill-[#8E8CA3]" />
                            ) : (
                              <Visibility className="!fill-[#8E8CA3]" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                  error={!!methods?.formState.errors['re_new_password']}
                  helperText={
                    methods?.formState.errors['re_new_password']?.message
                  }
                  minRows={4}
                  autoFocus
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
            <div className="w-full flex justify-end py-4">
              <SecondaryBtn
                style={{ backgroundColor: '#EDECF9' }}
                onClick={onClose}
                title="Cancel"
              />
              <PrimaryBtn
                title="Save"
                noValidate
                disabled={!isDirty || !isValid || isSubmitting || loading}
                style={{
                  marginRight: '0px !important',
                }}
                onClick={() => {
                  methods?.handleSubmit(onSubmit);
                }}
              />
            </div>
          </form>
        </div>
      </FormModal>
    </>
  );
}
export default ChangePassword;
