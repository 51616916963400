import axios from 'axios';

export const ACCESS_TOKEN_KEY = `${process.env.REACT_APP_ACCESS_TOKEN_KEY}`;
export const PROFILE_KEY = `${process.env.REACT_APP_PROFILE_KEY}`;
export const REFRESH_TOKEN_KEY = `${process.env.REACT_APP_REFRESH_TOKEN_KEY}`;
export const BASE_URI = `${process.env.REACT_APP_BASE_URL}`;
export const BEARER_KEY = `${process.env.REACT_APP_BEARER_KEY}`;
export const API_URL = BASE_URI;
export const publicAPIService = axios.create({ baseURL: API_URL });
export const apiService = axios.create({ baseURL: API_URL });

export const TokenStorage = {
  LOCAL_STORAGE_TOKEN: ACCESS_TOKEN_KEY,

  LOCAL_STORAGE_PROFILE: PROFILE_KEY,

  LOCAL_STORAGE_REFRESH_TOKEN: REFRESH_TOKEN_KEY,

  isAuthenticated() {
    return this.getToken() !== null;
  },

  getAuthentication() {
    return {
      headers: { Authorization: `${BEARER_KEY} ${this.getToken()}` },
    };
  },

  storeToken: (token) => {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
    // apiService.interceptors.request.use(
    //   async (config) => {
    //     const requestConfig = config;
    //    // const tokens = TokenStorage.getToken();
    //     if (token) {
    //       requestConfig.headers.Authorization = `${BEARER_KEY} ${token}`;
    //       requestConfig.headers['Content-Type'] = `application/json`
    //     }
    //     return requestConfig;
    //   },
    //   (error) => {
    //     return Promise.reject(error);
    //   }
    // )
  },

  storeProfileData: (profile) => {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_PROFILE, JSON.stringify(profile));
  },

  storeRefreshToken: (refreshToken) => {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
  },


  getNewToken() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}auth/jwt/refresh`, {
          refresh_token: this.getRefreshToken(),
        })
        .then((response) => {
          this.storeToken(response.data.token);
          this.storeRefreshToken(response.data.refresh_token);

          resolve(response.data.token);
        })
        .catch((error ) => {
          reject(error);
        });
    });
  },

  
  clear() {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_PROFILE);
  },

  getRefreshToken() {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  },

  getToken() {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  },

  getProfile() {
    let profile = localStorage.getItem(TokenStorage.LOCAL_STORAGE_PROFILE);
    if (profile) {
      profile = JSON.parse(profile);
    }
    return profile;
  },
};

export const requestInterceptor = apiService.interceptors.request.use(
  async (config) => {
    const requestConfig = config;
    const tokens = TokenStorage.getToken();
    if (tokens) {
      requestConfig.headers.Authorization = `${BEARER_KEY} ${tokens}`;
      requestConfig.headers['Content-Type'] = `application/json`
    }
    return requestConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);


